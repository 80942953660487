import React from 'react';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';
import SelectTimeFrameWithCustom from 'components/controls/SelectTimeFrameWithCustom';
import ChannelIcon from 'components/common/ChannelIcon';
import SearchableDropdownPopup from 'components/pages/questions/SearchableDropdownPopup';

import { suggestionsDropdownTypes, suggestionsTypesToFilterKind, suggestionsDropdownStyle } from 'components/pages/questions/enums';
import { getChannelIcon, getChannelNickname, getChannelsWithNicknames } from 'components/utils/filters/channels';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { groupBySegmentsOptions } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getMappingOptions } from 'components/utils/logic/utils';
import { dateFormat, checkIfDate } from 'utils';
import { getConversionRateAndVelocityOptions } from 'components/pages/analyze/logic/Tabs';

import style from 'styles/questions/suggestions.css';

const styles = style.locals || {};

function SuggestionPlaceholderDropdown({
  type,
  text,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  getMetricOptions,
  leadSourcesIdToLabelMap,
  onChange,
  filtersData,
  getFilterKindOptions,
}) {
  useStyles([style]);

  const {
    dropdownControlText, dropdownIndicatorWrapper, dropdownIndicatorArrow,
  } = suggestionsDropdownStyle;

  if (type === suggestionsDropdownTypes.timeframe) {
    return (
      <div className={styles.timeframeDropdown}>
        <SelectTimeFrameWithCustom
          widgetTimeFrame={text}
          updateWidgetTimeframe={(newTimeframe) => onChange({ placeHolderValue: newTimeframe })}
          dropdownClassName={styles.suggestionDropdownLink}
          isShowShortLabel
          menuMaxWidth="240px"
          customControlTextStyles={dropdownControlText}
          customDropdownIndicatorWrapperStyles={dropdownIndicatorWrapper}
          customDropdownIndicatorArrowStyles={dropdownIndicatorArrow}
        />
      </div>
    );
  }

  if (type === suggestionsDropdownTypes.segment) {
    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    return (
      <SearchableDropdownPopup
        options={groupBySegmentsOptions({ options: segmentsOptionsFields })}
        selectedValue={text}
        onSelectValue={(newSegment) => onChange({ placeHolderValue: newSegment.value })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.channels) {
    const channelsWithNicknames = getChannelsWithNicknames();
    const channelsItemsOptions = channelsWithNicknames.map((c) => ({ value: c.value, label: getChannelNickname(c.value) }));

    return (
      <SearchableDropdownPopup
        options={channelsItemsOptions}
        selectedValue={text}
        onSelectValue={(newChannel) => onChange({ placeHolderValue: newChannel.value })}
        dropdownAsLink
        dropdownProps={{
          searchFunction: (searchValue, offset) => {
            const result = getFilterKindOptions({
              searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
            });
            return result;
          },
          menuMaxWidth: '200px',
          optionRenderer: (item) => (
            <div className={styles.channelItemWrap}>
              <ChannelIcon
                className={styles.filterItemIcon}
                channelIcon={getChannelIcon(item.value)}
                channel={item.value}
              />
              <div className={styles.channelNickname}>
                {getChannelNickname(item.label)}
              </div>
            </div>
          ),
        }}
      />
    );
  }

  const filterDataConfig = filtersData?.find((filter) => filter.kind === suggestionsTypesToFilterKind[type]) || {};
  const metricsOptions = getMetricOptions();
  const suggestionsOptionsAccordingType = {
    [suggestionsDropdownTypes.funnels]: metricsOptions?.filter((metric) => metric.value !== 'pipeline' && metric.value !== 'revenue'),
    [suggestionsDropdownTypes.kpiFocus]: metricsOptions,
    [suggestionsDropdownTypes.customFields]: getMappingOptions(customFieldsIdToLabelMap),
    [suggestionsDropdownTypes.leadSources]: getMappingOptions(leadSourcesIdToLabelMap),
    [suggestionsDropdownTypes.conversionRateOrVelocity]: getConversionRateAndVelocityOptions(),
  };

  if (type === suggestionsDropdownTypes.customFields) {
    const selectedField = text.id;
    const selectedFieldOption = text.value;
    const fieldIndex = filterDataConfig.fieldKey?.findIndex((key) => key === selectedField);
    const filterKey = filterDataConfig.fieldKey?.[fieldIndex];
    const optionsForIndex = (filterDataConfig.options?.[fieldIndex] || []).map((filterOption) => ({
      label: checkIfDate(filterOption) ? dateFormat(filterOption) : filterOption,
      value: filterOption,
    }));
    if (optionsForIndex.length === 0) {
      optionsForIndex.push({ value: selectedFieldOption, label: selectedFieldOption });
    }

    return (
      <>
        <SearchableDropdownPopup
          options={groupBySegmentsOptions({ options: suggestionsOptionsAccordingType[type] })}
          selectedValue={selectedField}
          onSelectValue={(newId) => onChange({ placeHolderValue: { value: text.value, id: newId.value } })}
          dropdownAsLink
          dropdownProps={{
            menuMaxWidth: '200px',
          }}
        />
        <div className={styles.text}>
          {' is '}
        </div>
        <SearchableDropdownPopup
          options={optionsForIndex}
          selectedValue={selectedFieldOption}
          onSelectValue={(newValue) => onChange({ placeHolderValue: { value: newValue.value, id: text.id } })}
          dropdownAsLink
          dropdownProps={{
            isAsyncPagination: filterDataConfig.isSearchable,
            searchFunction: (searchValue, offset) => getFilterKindOptions({
              searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
            }),
            menuMaxWidth: '200px',
          }}
        />
      </>
    );
  }

  let fieldIndex = filterDataConfig.fieldKey?.findIndex((key) => key === text);
  if (fieldIndex === -1) {
    fieldIndex = filterDataConfig.fieldKey?.findIndex((key) => key === type);
  }
  const filterKey = filterDataConfig.fieldKey?.[fieldIndex];
  let options = suggestionsOptionsAccordingType[type];

  if (!options) {
    options = (filterDataConfig.options?.[fieldIndex] || []).map((filterOption) => ({
      label: checkIfDate(filterOption) ? dateFormat(filterOption) : filterOption,
      value: filterOption,
    }));
    if (options.length === 0) {
      options.push({ value: text, label: text });
    }
  }

  return (
    <SearchableDropdownPopup
      options={options}
      selectedValue={text}
      onSelectValue={(newValue) => onChange({ placeHolderValue: newValue.value })}
      dropdownAsLink
      dropdownProps={{
        isAsyncPagination: filterDataConfig.isSearchable,
        searchFunction: (searchValue, offset) => {
          const result = getFilterKindOptions({
            searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
          });
          return result;
        },
        menuMaxWidth: '200px',
      }}
    />
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
        leadSourcesIdToLabelMap,
      } = {},
    } = {},
    attributionStore: {
      getMetricOptions,
    } = {},
    filterStore: {
      filtersData,
      getFilterKindOptions,
    } = {},
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    getMetricOptions,
    leadSourcesIdToLabelMap,
    filtersData,
    getFilterKindOptions,
  }),
  observer
)(SuggestionPlaceholderDropdown);
