import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import history from 'history';

import useStyles from 'hooks/useStyles';
import Suggestions from 'components/pages/questions/Suggestions';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';
import AskQuestionInput from 'components/pages/questions/AskQuestionInput';
import servicesStore from 'stores/servicesStore';

import { getWidgetConfigWithPlaceHolderValues } from 'components/pages/questions/logic/askQuestion';
import { splitSuggestionTextToObjects, getWidgetTitle } from 'components/pages/questions/logic/suggestionItem';

import { Events } from 'trackers/analytics/enums';

import style from 'styles/questions/questions.css';

const styles = style.locals || {};

function AskQuestion({
  questionText,
  setQuestionText,
  getQuestionSuggestions,
  questionSuggestions,
  shouldOpenResultsPopup = true,
  className,
  inputClassName,
  setQuestionWidgetsMetadataResults,
  displayShowMoreSuggestionsButton,
  questionResultsClassName,
  isShowHeaderTitle,
  onClickSuggestion,
  selectedSuggestions,
  isShowCheckboxes,
  onUpdatePlaceHolderValue,
}) {
  useStyles([style]);

  const [isResultsPopupOpen, setIsResultsPopupOpen] = useState(shouldOpenResultsPopup);
  const [questionTextInput, setQuestionTextInput] = useState(questionText);

  useEffect(() => {
    getQuestionSuggestions({ questionText: questionTextInput });
  }, []);

  useEffect(() => {
    if (questionTextInput !== questionText) {
      setQuestionTextInput(questionText);
    }
  }, [questionText]);

  useEffect(() => {
    if (questionTextInput !== questionText) {
      const delayDebounce = setTimeout(() => {
        getQuestionSuggestions({ questionText: questionTextInput });
        trackQuestion({ eventName: Events.questionSearched });
      }, 1500);
      return () => clearTimeout(delayDebounce);
    }
    return () => ({});
  }, [questionTextInput]);

  const askQuestionRef = useRef(null);

  useOnClickOutside(askQuestionRef, () => {
    if (!shouldOpenResultsPopup) {
      setIsResultsPopupOpen(false);
    }
  });

  function onChangeQuestionInput(event) {
    const newQuestionTextInput = event.target.value;
    setQuestionTextInput(newQuestionTextInput);
  }

  function onSelectedSuggestion({ questionId, placeHoldersValues, updateQueryText = true }) {
    const questionData = questionSuggestions.find((question) => question.questionId === questionId);

    if (onClickSuggestion) {
      onClickSuggestion({ questionData, placeHoldersValues });
      return;
    }

    const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
    if (updateQueryText) {
      const questionTextArray = splitSuggestionTextToObjects({ text: questionData.questionText, placeHoldersTypes: questionData.placeHoldersTypes, placeHoldersValues });
      const newQuestionText = getWidgetTitle({ questionTextArray });
      setQuestionText({ newQuestionText });
    }
    setQuestionWidgetsMetadataResults({ widget: widgetAsJson, questionId });
    setIsResultsPopupOpen(false);
    history.push('/questions/results');
    trackQuestion({ eventName: Events.questionAsked, additionalProperties: { question: questionData } });
  }

  function trackQuestion({ eventName, additionalProperties = {} }) {
    servicesStore.eventTracker.track({
      eventName,
      properties: {
        searchInput: questionTextInput,
        ...additionalProperties,
      },
    });
  }

  return (
    <div className={classnames(styles.askQuestion, className)} ref={askQuestionRef}>
      <AskQuestionInput
        onFocus={() => (shouldOpenResultsPopup ? null : setIsResultsPopupOpen(true))}
        onChange={(e) => onChangeQuestionInput(e)}
        onClear={() => onChangeQuestionInput({ target: { value: '' } })}
        value={questionTextInput}
        className={inputClassName}
      />

      {isResultsPopupOpen ? (
        <div className={classnames(styles.questionResultsWrapper, questionResultsClassName)} data-testid="results-popup">
          <Suggestions
            data={questionSuggestions}
            onSelectSuggestion={({ questionId, placeHoldersValues }) => onSelectedSuggestion({ questionId, placeHoldersValues })}
            displayShowMoreSuggestionsButton={displayShowMoreSuggestionsButton}
            isShowHeaderTitle={isShowHeaderTitle}
            selectedSuggestions={selectedSuggestions}
            isShowCheckboxes={isShowCheckboxes}
            onUpdatePlaceHolderValue={onUpdatePlaceHolderValue}
          />
        </div>
      ) : null}
    </div>
  );
}

export default inject(({
  questionsStore: {
    questionText,
    setQuestionText,
    getQuestionSuggestions,
    questionSuggestions,
    setQuestionWidgetsMetadataResults,
  },
}) => ({
  questionText,
  setQuestionText,
  getQuestionSuggestions,
  questionSuggestions,
  setQuestionWidgetsMetadataResults,
}),
observer)(AskQuestion);
