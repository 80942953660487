import history from 'history';

import { GROUP_BY, LABELS, SINGULAR_LABELS } from 'components/utils/users';
import { makeFilters } from 'components/utils/filters';
import { VARIANTS } from 'components/utils/filters/logic';
import { makeFunnelStagesFilter } from 'components/utils/filters/make';
import { isFiltersChanged } from 'components/pages/analyze/logic/Tabs';
import attributionStore from 'stores/attributionStore';
import analysisStore from 'stores/analysisStore';
import filterStore from 'stores/filterStore';

export const getStatsLabel = (groupBy) => {
  for (const [keys, values] of Object.entries(GROUP_BY)) {
    if (groupBy === values) {
      return {
        plural: LABELS[keys],
        singular: SINGULAR_LABELS[keys],
      };
    }
  }
  return {
    plural: 'Accounts',
    singular: 'account',
  };
};

export const getIsDealView = (groupBy) => groupBy === GROUP_BY.DEALS;

const navigateToJourneysWithFilters = async ({
  indicator: funnelStage, timeFrame, timeFrameParams = {}, attributionModel,
}, filters) => {
  const didTimeFrameChanged = timeFrame ? timeFrame !== attributionStore.timeFrame?.value : false;
  const didConversionIndicatorChanged = false;
  const didAttributionModelChanged = attributionModel ? attributionModel !== attributionStore.attributionModel : false;
  const didFiltersChanged = filters ? isFiltersChanged({ filters, prevFilters: attributionStore.filters }) : false;

  const didNavigationUpdatedConfiguration = didFiltersChanged || didTimeFrameChanged || didConversionIndicatorChanged || didAttributionModelChanged;

  await history.push({ pathname: '/analyze/journeys', state: { isRenderViaNavigation: didNavigationUpdatedConfiguration } });

  const filtersData = [...filters];
  if (funnelStage) {
    filtersData.push(makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [funnelStage]));
  }

  attributionStore.updateMultipleParameters({
    filters: makeFilters(filtersData, filterStore.filtersData), timeframeValue: timeFrame, timeframeParams: timeFrameParams, attributionModel,
  });

  if (didNavigationUpdatedConfiguration) {
    analysisStore.restIsLoadedWidgetIndication();
  }
};

export const navigateToJourneys = (...params) => {
  navigateToJourneysWithFilters(...params);
};
