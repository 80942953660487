import React from 'react';

export default (InnerComponent) => (props) => {
  const { router, isSecondaryRegion } = props;
  if (isSecondaryRegion) {
    router.push('/plan/annual/');
  }
  return (
    <InnerComponent {...props} />
  );
};
