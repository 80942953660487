import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget.css';
import BuildBudget from "./budget/BuildBudget";

export default class Budget extends Component {
  style = style;

  render() {
    return (
      <div>
        <div className={this.classes.page}>
          <div className={this.classes.closeIcon} />
          <BuildBudget />
        </div>
      </div>
    );
  }
}
