import React from "react";
import classNames from 'classnames';
import filterStyles from 'styles/users/filters.css';
const styles = filterStyles.locals;

const FilterActiveNumber = ({ count }) => {
    return (
        <div className={classNames(styles.activeNumber, !count && styles.activeNumberIcon)}>{count && count}</div>
    );
}
export default FilterActiveNumber;
