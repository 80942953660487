exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".info-marker__info-marker-1Ts{width:13px;height:13px;background-color:#8f9ab8;-webkit-mask-image:url(/assets/analyze-icons/stat-info.svg);mask-image:url(/assets/analyze-icons/stat-info.svg);-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat}.info-marker__info-marker-1Ts:hover{background-color:#4788f6}", ""]);

// exports
exports.locals = {
	"info-marker": "info-marker__info-marker-1Ts",
	"infoMarker": "info-marker__info-marker-1Ts"
};