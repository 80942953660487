exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".manual-plan__title-YJ4{font-size:24px;font-weight:600;letter-spacing:.1px;text-align:center;color:#222a41}.manual-plan__sub-title-e3f{font-size:14px;font-weight:500;letter-spacing:.1px;text-align:center;color:#222a41;margin:10px 0}.manual-plan__inner-_yl{margin:40px 0}", ""]);

// exports
exports.locals = {
	"title": "manual-plan__title-YJ4",
	"title": "manual-plan__title-YJ4",
	"sub-title": "manual-plan__sub-title-e3f",
	"subTitle": "manual-plan__sub-title-e3f",
	"inner": "manual-plan__inner-_yl",
	"inner": "manual-plan__inner-_yl"
};