// eslint-disable-next-line import/no-extraneous-dependencies
import { Bucket } from '@infinigrow/bucket-validation';

export function getOverlappingGroupIndex({ groups }) {
  for (const baseGroup of groups) {
    const baseBucket = new Bucket({ name: baseGroup.name, rules: baseGroup.rules });
    for (const targetGroup of groups) {
      if (baseGroup.id === targetGroup.id) {
        continue;
      }

      const targetBucket = new Bucket({ name: targetGroup.name, rules: targetGroup.rules });
      const isOverlap = baseBucket.validateOverlap({ otherBucket: targetBucket });

      if (isOverlap) {
        return {
          baseGroupId: baseGroup.id,
          targetGroupId: targetGroup.id,
        };
      }
    }
  }

  return null;
}
