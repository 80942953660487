import React from 'react';
import classnames from 'classnames';

import Switch from 'components/controls/Switch';

import useStyles from 'hooks/useStyles';
import infoCardStyles from 'styles/common/infoCard.css';

const styles = infoCardStyles.locals || {};

export default function InfoCard({
  title,
  onClick,
  subTitle,
  iconProps: {
    dataIcon,
    className: classNameIcon,
  } = {},
  className,
  switchProps: {
    isActive,
    disabled,
    onSwitchClick = () => null,
    isShowSwitchButton = false,
  } = {},
  isShowLockIcon = false,
}) {
  useStyles([infoCardStyles]);

  return (
    <div
      className={classnames(
        styles.cardWrapper,
        onClick && styles.cardWrapperClickable,
        className
      )}
      onClick={() => (onClick ? onClick() : null)}
    >
      <div className={styles.header}>
        <div
          className={classnames(styles.icon, classNameIcon)}
          data-icon={dataIcon}
          data-testid="test-icon"
        />

        <div className={styles.actionButtons}>
          {isShowSwitchButton ? (
            <div>
              <Switch
                isActive={isActive}
                disabled={disabled}
                onSwitchClick={onSwitchClick}
              />
            </div>
          ) : null}

          {isShowLockIcon ? (
            <div className={styles.lockIcon} data-testid="lock-icon" />
          ) : null}
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.subTitle}>
          {subTitle}
        </div>
      </div>
    </div>
  );
}
