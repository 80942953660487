import React from 'react';
import ChannelIcon from 'components/common/ChannelIcon';
import classnames from 'classnames';
import style from 'styles/settings/channels/channels-tab.css';

const styles = style.locals;

const ChannelItem = (props) => {
  const {
    selectedCategory, channelsByCategory, isActiveCategory, onClickCategoryItem, setChannelHandler, selectedChannel,
  } = props;
  return (
    <>
      <div className={styles.categoryBox} onClick={onClickCategoryItem}>
        <div className={classnames(isActiveCategory ? styles.arrowDown : styles.arrowRight, styles.arrow)} />
        <div>{selectedCategory}</div>
      </div>
      {isActiveCategory && channelsByCategory[selectedCategory].map((item) => (
        <div
          className={classnames(styles.channelBox, selectedChannel === item.channel && styles.channelBoxSelected)}
          key={item.channel}
          onClick={() => { setChannelHandler(item.channel); }}
        >
          <ChannelIcon channel={item.channel} className={styles.channelIcon} />
          {item.nickname}
        </div>
      ))}
    </>
  );
};
export default ChannelItem;
