exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".goal-status__content-Sna{display:flex;justify-content:space-between;width:100%;flex-wrap:nowrap;max-width:315px}.goal-status__borderWrap-20O{background:#fff;box-shadow:0 1px 3px rgba(54,56,64,.101647);border-radius:4px;padding:16px;width:100%;height:345px;margin:16px 0 0}.goal-status__title-3xX{font-weight:600;font-size:16px;line-height:24px;color:#182033;margin:0 0 24px}.goal-status__totalProgress-1Cq{width:100%;height:6px;display:flex;align-items:center}.goal-status__totalBlue-3We{height:6px;border-radius:3px 0 0 3px;background-color:#4d91fc}.goal-status__totalGrey-3Ir{height:6px;border-radius:0 3px 3px 0;background-color:#e6e8f0}.goal-status__interval-2FN{display:flex;justify-content:space-between;margin:4px 0 12px}.goal-status__intervalItem-1-2{font-weight:400;font-size:11px;line-height:21px;text-transform:uppercase;color:#707ea7;margin:4px 0 0}.goal-status__values-29e{display:flex;justify-content:space-between;padding:12px 0}.goal-status__labelWrap-P-D{display:flex}.goal-status__label-2Q5{font-weight:400;font-size:14px;line-height:21px;color:#707ea7}.goal-status__value-2nv{font-weight:700;font-size:14px;line-height:21px;color:#707ea7}.goal-status__info-18j{height:12px;width:12px;background:url(/assets/plan_icons/info-icon-grey.svg) 50% no-repeat;margin:5px 10px 10px}@media (max-width:851px){.goal-status__content-Sna{margin:16px auto}}@media (max-width:435px){.goal-status__content-Sna{width:auto}}", ""]);

// exports
exports.locals = {
	"content": "goal-status__content-Sna",
	"content": "goal-status__content-Sna",
	"borderWrap": "goal-status__borderWrap-20O",
	"borderWrap": "goal-status__borderWrap-20O",
	"title": "goal-status__title-3xX",
	"title": "goal-status__title-3xX",
	"totalProgress": "goal-status__totalProgress-1Cq",
	"totalProgress": "goal-status__totalProgress-1Cq",
	"totalBlue": "goal-status__totalBlue-3We",
	"totalBlue": "goal-status__totalBlue-3We",
	"totalGrey": "goal-status__totalGrey-3Ir",
	"totalGrey": "goal-status__totalGrey-3Ir",
	"interval": "goal-status__interval-2FN",
	"interval": "goal-status__interval-2FN",
	"intervalItem": "goal-status__intervalItem-1-2",
	"intervalItem": "goal-status__intervalItem-1-2",
	"values": "goal-status__values-29e",
	"values": "goal-status__values-29e",
	"labelWrap": "goal-status__labelWrap-P-D",
	"labelWrap": "goal-status__labelWrap-P-D",
	"label": "goal-status__label-2Q5",
	"label": "goal-status__label-2Q5",
	"value": "goal-status__value-2nv",
	"value": "goal-status__value-2nv",
	"info": "goal-status__info-18j",
	"info": "goal-status__info-18j"
};