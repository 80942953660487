import React from 'react';

import SearchableDropdownPopup from 'components/pages/questions/SearchableDropdownPopup';

import { groupBySegmentsOptions, getOptionsWithSegmentMapping } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { addDisabledBySegmentsToOptions, setSegmentOptionsTimeDisabled } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';

export default function SegmentsSelectorWrapper({
  segmentsFields,
  setSelectedSegments,
  isPrimarySegmentDisable,
  selectedSegments,
  disabled,
  secondSegmentsOptionsFields,
}) {
  const onSelectedSegments = (segment, type) => {
    const updatedSelectedSegments = { ...selectedSegments };
    updatedSelectedSegments[type] = segment;
    if (type === 'firstSegment') {
      updatedSelectedSegments.secondSegment = { label: 'None', value: null };
    }
    setSelectedSegments(updatedSelectedSegments);
  };

  const segmentsFieldsOptions = addDisabledBySegmentsToOptions({
    options: segmentsFields,
    selectedSegments: {
      firstSegment: selectedSegments.firstSegment.value,
      secondSegment: selectedSegments.secondSegment.value,
    },
  });

  const { firstSegmentOptions, secondSegmentOptions } = setSegmentOptionsTimeDisabled({
    selectedSegments,
    firstSegmentOptions: segmentsFieldsOptions,
    secondSegmentOptions: secondSegmentsOptionsFields || segmentsFieldsOptions,
  });

  const firstSegmentOptionsGroupBy = groupBySegmentsOptions({ options: firstSegmentOptions });
  const secondSegmentOptionsGroupBy = groupBySegmentsOptions({ options: secondSegmentOptions, withNoneValue: true });

  return (
    <>
      <SearchableDropdownPopup
        options={getOptionsWithSegmentMapping({ options: firstSegmentOptionsGroupBy })}
        selectedValue={selectedSegments.firstSegment.value}
        onSelectValue={(value) => onSelectedSegments(value, 'firstSegment')}
        disabled={isPrimarySegmentDisable}
        dropdownLabel="Primary Segment:"
        dataTestId="segmentsSelector-firstSegment"
      />

      <SearchableDropdownPopup
        options={getOptionsWithSegmentMapping({ options: secondSegmentOptionsGroupBy })}
        selectedValue={selectedSegments.secondSegment.value}
        onSelectValue={(value) => onSelectedSegments(value, 'secondSegment')}
        dropdownLabel="Secondary Segment:"
        disabled={disabled}
        dataTestId="segmentsSelector-secondSegment"
      />
    </>
  );
}
