import React from 'react';
import { groupBy } from 'lodash';

import userStore from 'stores/userStore';

import { getDisplaySign } from 'components/pages/settings/actions/logic/actions';
import { filtersUIConfig } from 'components/pages/users/Filters/FilterPopup';
import {
  percentageOperators, operatorTypes, actionTriggerTypes, notificationFrequencyLabelMapping, notificationTypes,
} from 'components/pages/settings/actions/enums';

export function getEmailSubject({ actionName, triggerType, frequency }) {
  if (triggerType === actionTriggerTypes.anomaly) {
    return `🚨 Anomaly detected: ${actionName}`;
  }

  if (triggerType === actionTriggerTypes.goalsTracking) {
    return 'Goal Tracking Alerts ({Date})';
  }

  if (triggerType === actionTriggerTypes.accountEngagement) {
    return `${notificationFrequencyLabelMapping[frequency]}'s Account Engagement ({Date})`;
  }

  return '';
}

export function getEmailBody({
  triggerType, triggerRules, toDoMessage, asString = false, frequency,
}) {
  if (triggerType === actionTriggerTypes.anomaly) {
    return getAnomalyEmailBody({ triggerRules, toDoMessage, asString });
  }

  if (triggerType === actionTriggerTypes.goalsTracking) {
    return getGoalTrackingEmailBody({ toDoMessage });
  }

  if (triggerType === actionTriggerTypes.accountEngagement) {
    return getAccountEngagementEmailBody({ toDoMessage, triggerRules, frequency });
  }
  return '';
}

function getAccountEngagementEmailBody({ toDoMessage, triggerRules, frequency }) {
  return (
    <div>
      {getHeaderEmailBody()}
      <p>
        We’ve noticed new engagements from accounts that you are currently tracking.
        <br />
        Please find more information below:
      </p>
      <p>Account segment:</p>
      <ul>
        <li>{triggerRules?.[0]?.filters?.length > 0 ? `Accounts that answer the following filter: ${getFiltersSection({ filters: triggerRules?.[0]?.filters, asString: true }).replaceAll('<p>', '').replaceAll('</p>', '')}` : 'Any account'}</li>
      </ul>
      <p>{`Accounts that engaged ${notificationFrequencyLabelMapping[frequency]}:`}</p>
      <ul>
        <li>Account1</li>
        <li>Account2</li>
        <li>Account3</li>
      </ul>
      <p>
        Explore their
        <u>Journeys in InfiniGrow</u>
        {' '}
        for more details.
      </p>
      <p>{toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</p>
      {getFooterEmailBody()}
    </div>
  );
}

function getGoalTrackingEmailBody({ toDoMessage }) {
  return (
    <div>
      {getHeaderEmailBody()}
      <p>
        We’ve noticed a change in one of your tracked goals in InfiniGrow.
        <br />
        Please find more information below:
      </p>
      <p>
        {'"{Goal}" became {Offtrack/Ontrack}'}
      </p>
      <p>
        <u>Explore InfiniGrow</u>
        {' '}
        to get back on track.
      </p>
      <p>{toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</p>
      {getFooterEmailBody()}
    </div>
  );
}

function getHeaderEmailBody() {
  return (
    <div>
      Hello
      {' {Name}'}
      ,
    </div>
  );
}

function getFooterEmailBody() {
  return (
    <div>
      <p>
        Cheers,
        <br />
        Team InfiniGrow
      </p>
    </div>
  );
}

function getAnomalyTriggerRuleForBodySection({ triggerRules }) {
  const ruleSections = [];
  for (const [ruleIndex, rule] of triggerRules.entries()) {
    const metricNickname = userStore.getMetricNickname({ metric: rule.metric });
    const ruleValue = `${rule.value}${getDisplaySign({ operator: rule.operator, metric: rule.metric })}`;
    const ruleBodyText = (
      <p>
        📊
        {' '}
        <strong>
          {' '}
          Affected Metric #
          {ruleIndex + 1}
        </strong>
        :
        {' '}
        {metricNickname}
        <br />
        <br />
        {rule.filters.length > 0 ? (
          <>
            🔍
            {' '}
            <strong>Filter Context: </strong>
            <br />
            {getFiltersSection({ filters: rule.filters })}
            <br />
          </>
        ) : ''}
        📌
        {' '}
        <strong>Anomaly Details: </strong>
        {getAnomalyText({
          metric: metricNickname, operator: rule.operator, timeframe: rule.timeframe, ruleValue,
        })}
      </p>
    );
    ruleSections.push(ruleBodyText);
  }

  return (ruleSections.map((rule, ruleIndex) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <>{rule}</>
      <>
        {ruleIndex !== ruleSections.length - 1 ? (
          <>
            AND
            <br />
          </>
        ) : ''}
      </>
    </div>
  )));
}

function getAnomalyEmailBody({ triggerRules, toDoMessage, asString }) {
  const allMetricNames = triggerRules.map((triggerRule) => userStore.getMetricNickname({ metric: triggerRule.metric }));

  if (asString) {
    const affectedMetrics = [];
    for (const [ruleIndex, rule] of triggerRules.entries()) {
      const metricNickname = userStore.getMetricNickname({ metric: rule.metric });
      const ruleValue = `${rule.value}${getDisplaySign({ operator: rule.operator, metric: rule.metric })}`;
      const ruleAsText = `<p>📊 <strong>Affected Metric #${ruleIndex + 1}:</strong> ${metricNickname}</p><p></p>${rule.filters.length > 0 ? `<p>🔍 <strong>Filter Context: </strong></p>${getFiltersSection({ filters: rule.filters, asString: true })}` : ''}<p></p><p>📌 <strong>Anomaly Details: </strong> ${getAnomalyText({
        metric: metricNickname, operator: rule.operator, timeframe: rule.timeframe, ruleValue,
      })}</p>`;
      affectedMetrics.push(ruleAsText);
    }

    return `<div style=“font-family: inherit; text-align: inherit”>Hello {Name},<p>We’ve spotted something out of the ordinary!</p><p></p><p>InfiniGrow has identified a significant shift in ${allMetricNames.join(', ')}.</p><p>Here’s what caught our attention:</p><p></p>${affectedMetrics.join('<p></p><p>AND</p><p></p>')}${toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</div>`;
  }

  const messageHeader = `Hello {Name}, 
  
  We’ve spotted something out of the ordinary! 
  
  InfiniGrow has identified a significant shift in ${allMetricNames.join(', ')}. Here's what caught our attention:
  `;

  const ruleSections = getAnomalyTriggerRuleForBodySection({ triggerRules });

  const emailBody = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <>{messageHeader}</>
      {ruleSections}
      <>{toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</>
    </div>
  );

  return emailBody;
}

export function getFiltersSection({ filters, asString }) {
  const filtersByKind = groupBy(filters, (filter) => filter.config.kind);

  const filterKindWithFilterLabels = [];
  for (const [filterKind, filterData] of Object.entries(filtersByKind)) {
    const allFiltersLabels = filterData.map((filter) => filter.label);
    filterKindWithFilterLabels.push(`${filtersUIConfig[filterKind].title}: ${allFiltersLabels.join(', ')}`);
  }

  if (asString) {
    const filterKindWithFilterLabelsAsText = [];
    for (const filterDescription of filterKindWithFilterLabels) {
      filterKindWithFilterLabelsAsText.push(`<p>${filterDescription}</p>`);
    }
    return filterKindWithFilterLabelsAsText.join(' AND ');
  }

  return filterKindWithFilterLabels.map((filterDescription) => (
    <>
      <br />
      {filterDescription}
      <br />
    </>
  ));
}

export function getAnomalyText({
  operator, metric, ruleValue, timeframe,
}) {
  const [, amountOfUnit, unit] = timeframe.rollingValue?.split('_') || [];
  if (percentageOperators.includes(operator)) {
    return `${metric} ${operator}d by ${ruleValue} over the last ${amountOfUnit} ${unit}s, compared to the previous period.`;
  }

  if (operator === operatorTypes.over || operator === operatorTypes.under) {
    return `${operator === operatorTypes.over ? 'More' : 'Less'} than ${ruleValue} ${metric} over the last ${amountOfUnit} ${unit}s`;
  }

  return '';
}

function getAccountEngagementSlackBody({
  toDoMessage, actionName, frequency, triggerRules,
}) {
  return (
    <div>
      <p>
        {`New engagements ${notificationFrequencyLabelMapping[frequency]}!`}
        <br />
      </p>
      <p>{actionName}</p>
      <p>Account segment:</p>
      <ul>
        <li>{triggerRules?.[0]?.filters?.length > 0 ? `Accounts that answer the following filter: ${getFiltersSection({ filters: triggerRules?.[0]?.filters, asString: true }).replaceAll('<p>', '').replaceAll('</p>', '')}` : 'Any account'}</li>
      </ul>
      <p>{`Accounts that engaged ${notificationFrequencyLabelMapping[frequency]}:`}</p>
      <ul>
        <li>Account1</li>
        <li>Account2</li>
        <li>Account3</li>
      </ul>
      <p>
        Explore their
        <u>Journeys in InfiniGrow</u>
        {' '}
        to learn more.
      </p>
      <p>{toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</p>
    </div>
  );
}

function getGoalTrackingSlackBody({ toDoMessage, frequency }) {
  return (
    <div>
      <p>
        {`Goal status changed ${notificationFrequencyLabelMapping[frequency]}!`}
        <br />
      </p>
      <p>
        {'"{Goal}" became {Offtrack/Ontrack}'}
      </p>
      <p>
        <u>Explore InfiniGrow</u>
        {' '}
        to learn more.
      </p>
      <p>{toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</p>
    </div>
  );
}

function getAnomalySlackBody({
  toDoMessage, triggerRules, frequency,
}) {
  const ruleSections = getAnomalyTriggerRuleForBodySection({ triggerRules });

  return (
    <div>
      <p>
        {`Anomaly detected ${notificationFrequencyLabelMapping[frequency]}!`}
        <br />
      </p>
      <p>{ruleSections}</p>
      <p>
        <u>Explore InfiniGrow </u>
        {' '}
        to learn more.
      </p>
      <p>{toDoMessage ? `What's Next? “${toDoMessage}”` : ''}</p>
    </div>
  );
}

function getSlackBody({
  triggerType, toDoMessage, actionName, frequency, triggerRules,
}) {
  if (triggerType === actionTriggerTypes.anomaly) {
    return getAnomalySlackBody({ toDoMessage, triggerRules, frequency });
  }

  if (triggerType === actionTriggerTypes.goalsTracking) {
    return getGoalTrackingSlackBody({ toDoMessage, frequency });
  }

  if (triggerType === actionTriggerTypes.accountEngagement) {
    return getAccountEngagementSlackBody({
      toDoMessage, actionName, frequency, triggerRules,
    });
  }

  return '';
}

export function getPreviewBody({
  triggerType, triggerRules, toDoMessage, notificationType, actionName, frequency,
}) {
  if (notificationType === notificationTypes.email) {
    return getEmailBody({
      triggerRules, toDoMessage, triggerType, frequency,
    });
  }

  if (notificationType === notificationTypes.slack) {
    return getSlackBody({
      triggerType, toDoMessage, actionName, frequency, triggerRules,
    });
  }

  return '';
}

export function getPreviewSubject({
  notificationType, triggerType, actionName, frequency,
}) {
  if (notificationType === notificationTypes.email) {
    return getEmailSubject({ actionName, triggerType, frequency });
  }

  return '';
}
