exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".campaign-title__campaign-icon-3Tw{background:url(/assets/arrow-right.png) 50% no-repeat;height:10px;width:10px;display:block}.campaign-title__campaign-Vge{padding-left:78px;width:100%;display:flex;flex-direction:row;align-items:center}.campaign-title__campaign-Vge:hover{cursor:pointer}.campaign-title__campaign-tip-container-zrh{font-weight:400;color:#222a41;margin-left:12px;width:calc(100% - 25px)!important}.campaign-title__campaign-tag-3HI{border-radius:4px;padding:4px 12px;font-size:14px;font-weight:400}.campaign-title__campaign-tag-wrapper-too{white-space:nowrap;text-align:center;overflow:hidden;flex-wrap:wrap;display:inline-flex;align-items:center;gap:8px}.campaign-title__campaign-tooltip-wrapper-3Kx{display:flex;flex-direction:column;gap:4px;white-space:normal;text-align:center;font-size:14px}", ""]);

// exports
exports.locals = {
	"campaign-icon": "campaign-title__campaign-icon-3Tw",
	"campaignIcon": "campaign-title__campaign-icon-3Tw",
	"campaign": "campaign-title__campaign-Vge",
	"campaign": "campaign-title__campaign-Vge",
	"campaign-tip-container": "campaign-title__campaign-tip-container-zrh",
	"campaignTipContainer": "campaign-title__campaign-tip-container-zrh",
	"campaign-tag": "campaign-title__campaign-tag-3HI",
	"campaignTag": "campaign-title__campaign-tag-3HI",
	"campaign-tag-wrapper": "campaign-title__campaign-tag-wrapper-too",
	"campaignTagWrapper": "campaign-title__campaign-tag-wrapper-too",
	"campaign-tooltip-wrapper": "campaign-title__campaign-tooltip-wrapper-3Kx",
	"campaignTooltipWrapper": "campaign-title__campaign-tooltip-wrapper-3Kx"
};