import {
  VELOCITY_MAPPING_SUFFIX,
  CONVERSION_RATE_MAPPING_SUFFIX,
  getRevenueFunnel,
  getPipelineFunnel,
  getInfluencedMapping,
} from 'components/utils/indicators';

export const getOptimizationMetricsOriginFunnel = ({ funnels, optimizationMetricValue }) => {
  for (const [index, funnel] of funnels.entries()) {
    const influencedDataKey = getInfluencedMapping()[funnel];
    if ([influencedDataKey, funnel].some((metric) => metric === optimizationMetricValue)) {
      return funnel;
    }

    if (funnels.length - 1 !== index) {
      const conversionRateKey = `funnel${index + 1}ToFunnel${index + 2}${CONVERSION_RATE_MAPPING_SUFFIX}`;
      if (conversionRateKey === optimizationMetricValue) {
        return funnel;
      }
    }

    if (index !== 0) {
      const velocityKey = `funnel${index}ToFunnel${index + 1}${VELOCITY_MAPPING_SUFFIX}`;
      if (velocityKey === optimizationMetricValue) {
        return funnel;
      }
    }

    if (optimizationMetricValue.includes('ConversionRate')) {
      const sourceFunnel = `funnel${optimizationMetricValue.match(/\d/g)[0]}`;
      return sourceFunnel;
    }

    if (optimizationMetricValue.includes('Velocity')) {
      const destFunnel = `funnel${optimizationMetricValue.match(/\d/g)[1]}`;
      return destFunnel;
    }

    if (funnel === getRevenueFunnel()) {
      if (['touchedRevenue', 'revenue', 'ARPA', 'MRR', 'ARR', 'averageSalesCycle'].some((metric) => metric === optimizationMetricValue)) {
        return funnel;
      }
    } else if (funnel === getPipelineFunnel()) {
      if (optimizationMetricValue === 'pipeline') {
        return funnel;
      }
    }
  }
};
