import React from 'react';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';
import userStore from 'stores/userStore';
import PerformanceItem from 'components/pages/analyze/OverviewTab/PerformanceItem';
import WidgetHeader from 'components/common/WidgetHeader';

import { widgetTypes } from 'components/pages/analyze/enums';
import { getFunnelNameWithoutNew, getPerformanceItemNavigationMenuParams } from 'components/pages/analyze/OverviewTab/logic/PerformanceItem';
import { CACMetrics } from 'components/pages/analyze/OverviewTab/logic/enums';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

import style from 'styles/analyze/performance-item.css';

const styles = style.locals || {};

const PerformanceItemWrapper = ({
  historicalPerformance = {},
  widgetHeaderConfig,
  indicator,
  widgetConfig,
  widgetIdToRequestId,
  isCompareToPreviousEnabled,
  widgetHeaderProps = {},
  isShowWidgetHeader = true,
  isEnableNavigationMenu,
  getWidgetRequestId,
}) => {
  useStyles([style]);

  let requestId = widgetIdToRequestId[widgetHeaderConfig.widgetId];
  if (!requestId) {
    requestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
  }

  const isLoaded = historicalPerformance?.[requestId]?.status === 'finished';
  const isFailedToLoad = historicalPerformance?.[requestId]?.status === 'failed';
  const performanceItemDataResult = historicalPerformance?.[requestId]?.result?.[indicator];

  const allUserMetrics = userStore.userMetrics || [];
  const indicatorWithoutNew = getFunnelNameWithoutNew(indicator);
  const propertiesBYIndicator = allUserMetrics.find((metric) => metric.metricName === indicatorWithoutNew);

  const performanceItemData = {
    ...propertiesBYIndicator,
    indicator: indicatorWithoutNew,
    value: get(performanceItemDataResult, ['total', 'value']),
    growth: get(performanceItemDataResult, ['total', 'growth']),
  };

  return (
    <div className={styles.reportWrapper}>
      {isShowWidgetHeader ? (
        <WidgetHeader
          {...widgetHeaderProps}
          isShowShortTimeframeLabel
          isShowPropertiesAsCollapsed
          isShowAttributionModelOptions={false}
          widgetHeaderConfig={widgetHeaderConfig}
        />
      ) : null}
      <div className={styles.performanceItemWrapper}>
        <PerformanceItem
          data={performanceItemData}
          navigationMenuPopupParams={getPerformanceItemNavigationMenuParams({ indicator, widgetHeaderConfig })}
          isLoaded={isLoaded}
          indicator={indicator}
          isFailedToLoad={isFailedToLoad}
          isCompareToPreviousEnabled={isCompareToPreviousEnabled}
          isHideComparisonValue={CACMetrics.includes(indicator)}
          isEnableNavigationMenu={isEnableNavigationMenu}
        />
      </div>
    </div>
  );
};

export default inject(({
  reportsStore: {
    widgetsOfSelectedReport,
  },
  widgetsAnalysisStore: {
    getWidgetRequestId,
    widgetIdToRequestId,
    dataPerWidget: {
      [widgetTypes.historicalPerformance]: historicalPerformance,
    },
  },
}) => ({
  getWidgetRequestId,
  historicalPerformance,
  widgetsOfSelectedReport,
  widgetIdToRequestId,
}),
observer)(PerformanceItemWrapper);
