exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".objective-notification__text-2pS{font-size:14px;color:#354052;display:inline-flex;white-space:pre;margin-right:3px}.objective-notification__text-bold-3yN{font-weight:600}.objective-notification__text-color-FmF{font-weight:600;color:#24b10e}.objective-notification__objective-icon-2u7{width:20px;height:20px;background:url(/assets/success_bltp.svg) 50% no-repeat;background-size:contain;position:relative;top:-10px;left:25px}.objective-notification__objective-icon-2u7[data-fail]{background:url(/assets/passed-objective.svg) 50% no-repeat;background-size:contain}", ""]);

// exports
exports.locals = {
	"text": "objective-notification__text-2pS",
	"text": "objective-notification__text-2pS",
	"text-bold": "objective-notification__text-bold-3yN objective-notification__text-2pS",
	"textBold": "objective-notification__text-bold-3yN objective-notification__text-2pS",
	"text-color": "objective-notification__text-color-FmF objective-notification__text-2pS",
	"textColor": "objective-notification__text-color-FmF objective-notification__text-2pS",
	"objective-icon": "objective-notification__objective-icon-2u7",
	"objectiveIcon": "objective-notification__objective-icon-2u7"
};