import React from 'react';

import JourneysCampaigns from 'components/pages/analyze/CampaignsTab/JourneysCampaigns';

export default function JourneysCampaignsWrapper({
  isLoaded,
  conversionIndicator,
  campaignJourneys = [],
  contentCampaignJourneys = [],
  widgetHeaderConfig,
  widgetHeaderProps,
}) {
  return (
    <JourneysCampaigns
      isLoaded={isLoaded}
      conversionIndicator={conversionIndicator}
      campaignJourneys={campaignJourneys}
      contentCampaignJourneys={contentCampaignJourneys}
      widgetHeaderConfig={widgetHeaderConfig}
      widgetHeaderProps={widgetHeaderProps}
    />
  );
}
