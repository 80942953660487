import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import AudienceTable from 'components/pages/journeys/AudienceTable';
import StatsSquaresJourneys from 'components/pages/journeys/StatsSquaresJourneys';

import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import { getStatsLabel, getIsDealView } from 'stores/analyze/logic/journeysStore';
import { isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';

import { getDateRange } from 'components/utils/users';
import { getMappingOptions } from 'components/utils/logic/utils';
import { compose } from 'components/utils/utils';
import { AnalyzeContext } from 'components/pages/Analyze';
import { getWidgetsData, getWidgetsDataV2, getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';
import { widgetTypes, flagsNames, queryParamsNames } from 'components/pages/analyze/enums';
import { DEFAULT_PAGE_SIZE } from 'components/controls/Table';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { getQueryParams } from 'components/utils/UrlParamsProvider';

import styles from 'styles/users/users.css';

const enhance = compose(
  inject(({
    userStore: {
      userMonthPlan: {
        region,
      },
      userAccount: {
        UID,
        leadSourcesIdToLabelMap,
        customFieldsIdToLabelMap,
      },
      getAttributionMappingRules,
    },
    attributionStore: {
      timeFrameParams,
      attributionModel,
      dateRange,
      timeFrame,
      filters,
      groupBy,
      autoFilter,
      isAccountMode,
      previousTimeframe: isCompareToPreviousEnabled,
    },
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.journeysOverview]: journeysOverview,
      },
    },
    widgetsAnalysisStore: {
      dataPerWidget: {
        [widgetTypes.journeysTable]: journeysTable,
      },
      configPerWidget: {
        [widgetTypes.journeysTable]: journeysTableConfig,
      },
      getWidgetRequestId,
    },
  }) => ({
    UID,
    region,
    dateRange,
    attributionModel,
    timeFrameParams,
    leadSourcesIdToLabelMap,
    customFieldsIdToLabelMap,
    filters,
    journeysOverview,
    groupBy,
    isCompareToPreviousEnabled,
    journeysTable,
    journeysTableConfig,
    autoFilter,
    isAccountMode,
    getWidgetRequestId,
    timeFrame,
    getAttributionMappingRules,
  })),
  observer
);

class Journeys extends React.Component {
  constructor(props) {
    super(props);

    const searchQueryParam = getQueryParams({ queryParamKey: queryParamsNames.searchQuery });
    this.state = {
      isLoadingMore: false,
      limit: DEFAULT_PAGE_SIZE,
      sort: props.journeysTableConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].sort || { id: 'lastTouchPoint', desc: true },
      clientCursor: 0,
      searchQuery: searchQueryParam?.toLowerCase() || props.location?.state?.searchQuery || props.journeysTableConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].searchQuery || '',
      parentRequestId: '',
      isInitialLoad: null,
    };
  }

  componentDidMount() {
    const {
      isCompareToPreviousEnabled, journeysOverview, flags, journeysTableConfig, location, getAttributionMappingRules,
    } = this.props;
    getAttributionMappingRules();
    const searchQuery = this.state.searchQuery.toLowerCase().trim();

    this.setState({ isInitialLoad: true });

    const journeysSearchQuery = journeysTableConfig?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.[0].searchQuery;
    const didSearchQueryChange = journeysSearchQuery && (this.state.searchQuery !== journeysSearchQuery);
    const isRenderViaNavigation = location?.state?.isRenderViaNavigation && location?.action === 'PUSH';
    if (isRenderViaNavigation) {
      return;
    }

    if (isFlagActive({ flag: flags.journeysCardsMetrics })) {
      if (!journeysOverview?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded || didSearchQueryChange) {
        getWidgetsData({
          widgets: [widgetTypes.journeysOverview],
          configPerWidget: {
            [widgetTypes.journeysOverview]: [{
              searchQuery,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      dateRange,
      region,
      autoFilter,
      isCompareToPreviousEnabled,
      attributionModel,
      flags,
      isAccountMode,
      timeFrame,
    } = this.props;
    const {
      limit, sort, searchQuery, isInitialLoad,
    } = this.state;

    const didInitialLoadChanged = isInitialLoad !== prevState.isInitialLoad;

    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didDateRangeChanged = !moment(dateRange.startDate).isSame(prevProps.dateRange.startDate)
      || !moment(dateRange.endDate).isSame(prevProps.dateRange.endDate);
    const didCompareToPreviousChanged = isCompareToPreviousEnabled && !prevProps.isCompareToPreviousEnabled;
    const didRegionChanged = region !== prevProps.region;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });

    const didSomeOfThePropsChanged = didTimeFrameChanged || didAccountModeChanged || didFiltersChanged || didDateRangeChanged || didAttributionModelChanged || didRegionChanged || didPreDefinedFiltersChanged;
    if (didInitialLoadChanged || didSomeOfThePropsChanged) {
      this.setState({
        parentRequestId: '',
        clientCursor: 0,
      }, () => {
        if (isFlagActive({ flag: flags.journeysTableWidget })) {
          getWidgetsDataV2({
            widget: widgetTypes.journeysTable,
            widgetConfig: {
              limit,
              clientCursor: this.state.clientCursor,
              sort,
              searchQuery,
            },
            isPreviousTimeFrame: false,
          });
        }
      });
    }
    if (didSomeOfThePropsChanged) {
      if (isFlagActive({ flag: flags.journeysCardsMetrics })) {
        getWidgetsData({
          widgets: [widgetTypes.journeysOverview],
          configPerWidget: {
            [widgetTypes.journeysOverview]: [{
              searchQuery,
            }],
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.journeysCardsMetrics })) {
        getWidgetsData({
          widgets: [widgetTypes.journeysOverview],
          configPerWidget: {
            [widgetTypes.journeysOverview]: [{
              searchQuery,
            }],
          },
          isPreviousTimeFrame: true,
        });
      }
    }
  }

  componentWillUnmount() {
    styles.unuse();
  }

  getDateRange = () => {
    const {
      timeFrameParams: { startDate, endDate, monthsExceptThisMonth },
    } = this.props;
    return getDateRange(startDate, endDate, monthsExceptThisMonth);
  };

  handleShowMoreClick({ parentRequestId }) {
    this.setState((prevState) => {
      const clientCursor = prevState.clientCursor + prevState.limit;

      return {
        clientCursor,
        isLoadingMore: true,
        parentRequestId,
      };
    }, async () => {
      await getWidgetsDataV2({
        widget: widgetTypes.journeysTable,
        widgetConfig: {
          limit: this.state.limit,
          clientCursor: this.state.clientCursor,
          sort: this.state.sort,
          searchQuery: this.state.searchQuery,
        },
        isPreviousTimeFrame: false,
        isConcatResult: true,
        parentRequestId,
      });
      this.setState({ isLoadingMore: false });
    });
  }

  updateTableSort({ newSort }) {
    this.setState({
      sort: newSort,
      parentRequestId: '',
      clientCursor: 0,
    });

    getWidgetsDataV2({
      widget: widgetTypes.journeysTable,
      widgetConfig: {
        limit: this.state.limit,
        clientCursor: 0,
        sort: newSort,
        searchQuery: this.state.searchQuery,
      },
      isPreviousTimeFrame: false,
    });
  }

  async updateSearchQuery({ newSearchQuery }) {
    this.setState({
      searchQuery: newSearchQuery,
      parentRequestId: '',
      clientCursor: 0,
    });

    getWidgetsData({
      widgets: [widgetTypes.journeysOverview],
      configPerWidget: {
        [widgetTypes.journeysOverview]: [{
          searchQuery: newSearchQuery,
        }],
      },
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
    getWidgetsDataV2({
      widget: widgetTypes.journeysTable,
      widgetConfig: {
        limit: this.state.limit,
        clientCursor: 0,
        sort: this.state.sort,
        searchQuery: newSearchQuery,
      },
      isPreviousTimeFrame: false,
    });
  }

  render() {
    const {
      groupBy,
      journeysOverview,
      journeysTable,
      isCompareToPreviousEnabled,
      filters,
      flags,
      getWidgetRequestId,
    } = this.props;

    const {
      isLoadingMore, limit, sort, searchQuery,
    } = this.state;

    const journeysTableFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        limit: this.state.limit,
        clientCursor: this.state.clientCursor,
        sort: this.state.sort,
        searchQuery: this.state.searchQuery,
      },
      isPreviousTimeFrame: false,
    });
    const journeysTableRequestId = this.state.parentRequestId || getWidgetRequestId({ widget: widgetTypes.journeysTable, widgetConfig: journeysTableFullWidgetConfig });

    const isJourneysTableLoaded = journeysTable?.[journeysTableRequestId]?.status === 'finished';
    const isJourneysTableFailedToLoad = journeysTable?.[journeysTableRequestId]?.status === 'failed';

    const dateRangeFrame = this.getDateRange();
    const statsLabel = getStatsLabel(groupBy);
    const isDealView = getIsDealView(groupBy);
    const isJourneyOverviewLoaded = journeysOverview?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded
      && (!isCompareToPreviousEnabled || journeysOverview?.[analyzeWidgetsResultKeyNames.previousTimeFrame]?.isLoaded);

    return (
      <AnalyzeContext.Consumer>
        {({ CRMConfig }) => (
          <>
            <div style={{ marginBottom: 20 }}>
              <FeatureFlagsWithBlur flag={flags.journeysCardsMetrics} name={flagsNames.journeysCardsMetrics}>
                <StatsSquaresJourneys
                  isLoaded={isJourneyOverviewLoaded}
                  statsLabel={statsLabel}
                  stats={journeysOverview?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data}
                  prevStats={journeysOverview?.[analyzeWidgetsResultKeyNames.previousTimeFrame]?.data}
                  isDealView={isDealView}
                />
              </FeatureFlagsWithBlur>
            </div>

            <FeatureFlagsWithBlur flag={flags.journeysTableWidget} name={flagsNames.journeysTableWidget}>
              <AudienceTable
                isLoaded={isJourneysTableLoaded}
                isFailedToLoad={isJourneysTableFailedToLoad}
                data={journeysTable?.[journeysTableRequestId]?.result}
                dateRange={dateRangeFrame}
                limit={limit}
                handleShowMoreClick={() => this.handleShowMoreClick({ parentRequestId: journeysTableRequestId })}
                dataLength={journeysOverview?.[analyzeWidgetsResultKeyNames.currentTimeFrame]?.data?.total}
                sort={sort}
                updateSorting={(newSort) => this.updateTableSort({ newSort })}
                isLoading={isLoadingMore}
                CRMConfig={CRMConfig}
                filters={filters}
                customIdToLabelMap={getMappingOptions(this.props.customFieldsIdToLabelMap)}
                searchQuery={searchQuery}
                updateSearchQuery={(newSearchQuery) => this.updateSearchQuery({ newSearchQuery })}
                timeFrame={this.props.timeFrame}
              />
            </FeatureFlagsWithBlur>
          </>
        )}
      </AnalyzeContext.Consumer>
    );
  }
}

export default withLDConsumer()(enhance(Journeys));
