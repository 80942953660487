import React from 'react';

import useStyles from 'hooks/useStyles';
import Skeleton from 'components/common/Skeleton';

import { skeletonChartHeightPattern } from 'components/common/enums';

import chartSkeletonStyle from 'styles/common/chartSkeleton.css';

const styles = chartSkeletonStyle.locals || {};

function ChartSkeleton({
  chartHeight = 400,
  chartWidth = '100%',
  numbersOfBars = 6,
  numbersLeftTicks = 4,
  tickSize = {
    width: 40,
    height: 20,
  },
  bottomTickHeight = 60,
  isEnableSkeletonAnimation,
}) {
  useStyles([chartSkeletonStyle]);

  const barsContainerHeight = chartHeight - bottomTickHeight;
  const bottomTicks = Array.from({ length: numbersOfBars }, (_, index) => index);
  const leftTicks = Array.from({ length: numbersLeftTicks }, (_, index) => index);

  return (
    <div data-testid="chart-skeleton" style={{ height: chartHeight, width: chartWidth }} className={styles.chartWrapper}>
      <div className={styles.leftTicks}>
        {leftTicks.map((tick) => (
          <Skeleton
            key={`${tick}-left-tick`}
            height={tickSize.height}
            width={tickSize.width}
            isLightTheme
            enableAnimation={isEnableSkeletonAnimation}
          />
        ))}
      </div>
      <div className={styles.chartcontainer}>
        <div style={{ height: barsContainerHeight }} className={styles.bars}>
          {bottomTicks.map((bar) => (
            <Skeleton
              key={`${bar}-bar`}
              width={32}
              height={barsContainerHeight * skeletonChartHeightPattern[bar]}
              enableAnimation={isEnableSkeletonAnimation}
            />
          ))}
        </div>
        <div className={styles.bottomTicks}>
          {bottomTicks.map((tick) => (
            <Skeleton
              key={`${tick}-bottom-tick`}
              height={tickSize.height}
              width={tickSize.width}
              isLightTheme
              enableAnimation={isEnableSkeletonAnimation}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ChartSkeleton;
