import React from 'react';
import { inject, observer } from 'mobx-react';
import history from 'history';

import useStyles from 'hooks/useStyles';

import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals || {};

function CreateReportHeaderMenu({
  reportsList,
}) {
  useStyles([reportsStyle]);

  if (!reportsList || reportsList.length === 0) {
    return null;
  }

  return (
    <div className={styles.headerMenuWrapper}>
      <div
        onClick={() => history.push('/reports')}
        className={styles.backToReport}
      >
        {'< Back to my reports'}
      </div>
    </div>
  );
}

export default inject((({
  reportsStore: {
    reportsList,
  },
}) => ({
  reportsList,
})), observer)(CreateReportHeaderMenu);
