import React from 'react';
import Component from 'components/Component';
import style from 'styles/analyze/generated-impact.css';
import GeneratedImpactChartLegendItem from 'components/pages/analyze/ImpactBoard/GeneratedImpactChartLegendItem';

class GeneratedImpactChartLegend extends Component {
  style = style;

  static defaultProps = {
    onMouseEnter: () => null,
    onMouseLeave: () => null,
    keyExtractor: (item, index) => index,
    nameFormat: (name) => name,
  }

  render() {
    const {
      dataArray, total, valuesFormatter, activeIndex, containerClass, getNavigationMenuPopupsParams,
      onMouseEnter, onMouseLeave, keyExtractor, nameFormat, prevDataArray = [],
    } = this.props;
    const prevDataLookup = prevDataArray.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
    return (
      <div className={containerClass}>
        <div className={this.classes.impactChartLegend}>
          {dataArray.map((item, index) => {
            const key = keyExtractor(item, index);
            const prevValue = prevDataLookup[item.name];
            return (
              <GeneratedImpactChartLegendItem
                keyIndex={key}
                key={key}
                item={item}
                prevValue={prevValue}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                activeIndex={activeIndex}
                nameFormat={nameFormat}
                valuesFormatter={valuesFormatter}
                total={total}
                getNavigationMenuParams={getNavigationMenuPopupsParams ? getNavigationMenuPopupsParams(dataArray[index]?.name) : null}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default GeneratedImpactChartLegend;
