exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".popup__plan-popup-1Da{display:flex;flex-direction:column;padding:24px 24px 0;border:1px solid rgba(178,187,213,.5);border-radius:4px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12)}.popup__header-1H7{flex:0 0 auto;position:relative}.popup__title-mDx{color:#222a41;font-size:14px;font-weight:500}.popup__content-2mt{flex:1 1 100%}.popup__footer-30k{flex:0 0 auto;position:relative;display:flex;justify-content:space-between;align-items:center;height:72px;padding:16px 0;border-top:1px solid rgba(178,187,213,.5);box-sizing:border-box}.popup__primaryButton-eD4{right:0;color:#fff;background-color:#4d91fc;border-radius:3px;border:none}.popup__primaryButton-eD4,.popup__secondaryButton-1MG{position:absolute;display:block;height:40px;font-size:12px;font-weight:500;padding:0 16px;cursor:pointer}.popup__secondaryButton-1MG{left:0;color:#707ea7;background-image:linear-gradient(0deg,#fafafc,#fff);border-radius:3px;border:1px solid rgba(178,187,213,.5);box-shadow:0 1px 2px 0 var(--pale-lilac)}", ""]);

// exports
exports.locals = {
	"plan-popup": "popup__plan-popup-1Da",
	"planPopup": "popup__plan-popup-1Da",
	"header": "popup__header-1H7",
	"header": "popup__header-1H7",
	"title": "popup__title-mDx",
	"title": "popup__title-mDx",
	"content": "popup__content-2mt",
	"content": "popup__content-2mt",
	"footer": "popup__footer-30k",
	"footer": "popup__footer-30k",
	"primaryButton": "popup__primaryButton-eD4",
	"primaryButton": "popup__primaryButton-eD4",
	"secondaryButton": "popup__secondaryButton-1MG",
	"secondaryButton": "popup__secondaryButton-1MG"
};