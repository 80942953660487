import React from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import Popup from 'components/Popup';

import style from 'styles/dashboard/funnel-view-popup.css';

class FunnelViewPopup extends Component {
  style = style;

  render() {
    const { funnelPopupData } = this.props;

    return (
      <Popup
        className={classnames(this.classes.popupWrapper, this.classes.popup)}
        hidden={!this.props.visible}
      >
        {Object.keys(funnelPopupData).map((indicator) => {
          const {
            groupBy, mappings, filters, reportSync: { reportUrl } = {},
          } = funnelPopupData[indicator];

          return (
            <div
              key={indicator}
              className={this.classes.popupButton}
            >
              <div className={this.classes.indicatorTitle}>
                {userStore.getMetricNickname({ metric: indicator })}
              </div>
              <div className={this.classes.popupItemContent}>
                {reportUrl ? (
                  <div>
                    Report link:
                    <div className={this.classes.popupItemValue}>
                      <a
                        href={reportUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {reportUrl}
                      </a>
                    </div>
                  </div>
                ) : null}
                <div>
                  Group by:
                  <div className={this.classes.popupItemValue}>
                    {groupBy}
                  </div>
                </div>
                {!isEmpty(mappings) && (
                  <div>
                    <div>Mappings:</div>
                    {mappings.map(({ rule, description }, index) => {
                      const mapKey = `${indicator}-${rule}-${index}`;
                      return (
                        <div key={mapKey} className={this.classes.mappingGroup}>
                          <div className={this.classes.popupItemSubValue}>
                            {rule}
                          </div>
                          {description && (
                          <div className={this.classes.subValueDescription}>
                            {description}
                          </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!isEmpty(filters) && (
                  <div>
                    <div>Filters:</div>
                    <div className={this.classes.filtersValues}>
                      {filters.map((filter, index) => {
                        const filterKey = `${indicator}-${filter}-${index}`;
                        return (
                          <div key={filterKey} className={this.classes.popupItemSubValue}>
                            {filter}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Popup>
    );
  }
}

export default FunnelViewPopup;
