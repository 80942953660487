import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget.css';
import { Link } from 'react-router';
import classnames from 'classnames';
import SetupBudgetHeader from 'components/pages/budget/SetupBudgetHeader';
import TotalAnnual from 'components/pages/budget/TotalAnnual';
import SetupBudgetDetail from 'components/pages/budget/SetupBudgetDetail';
import Goal from 'components/pages/budget/Goal';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';
import mockedData from 'components/pages/budget/mockedData';
import BudgetsTableV2 from 'components/pages/plan/BudgetTable';

export default class SetupBudget extends Component {
    style = style;

    constructor(props) {
      super(props);
      this.state = {
        showTable: false,
      };
    }

  togleShowTable = () => {
    if (this.state.showTable) {
      this.setState({ showTable: false });
    } else {
      this.setState({ showTable: true });
    }
  };

  render() {
    return (
      <div ref={(ref) => this.wrap = ref} className={this.classes.overlay}>
        <div className={this.classes.pageWrap}>
          <div className={this.classes.wrap}>
            <div className={this.classes.closeIcon} />
            <SetupBudgetHeader />
            <TotalAnnual />
            <SetupBudgetDetail
              title="Display ads - Google Adwords (Display)"
              amount={400}
              iconUrl="assets/channels-icons/display-google-ad-words-icon.svg"
            />
            <SetupBudgetDetail
              title="Organising event (Events)"
              amount={0}
              iconUrl="assets/channels-icons/organising-event-icon.svg"
              empty
            />
            <SetupBudgetDetail
              title="Paid reviews (Paid Search)"
              amount="12,000"
              iconUrl="assets/channels-icons/paid-reviews-icon.svg"
              data="data"
            />
            <SetupBudgetDetail
              title="Speaking engagement (Events)"
              amount={400}
              iconUrl="assets/channels-icons/speaking-engagement-icon.svg"
            />
            <div className={this.classes.goals}>
              <h2 className={this.classes.goalsTitle}>
                Goals
              </h2>
              <div className={this.classes.goalsWrap}>
                <Goal />
                <Goal />
                <Goal />
                <Goal />
                <Goal />
              </div>
            </div>
            <div className={this.classes.btnsGroup}>
              <Link to="/add-channels">
                <SetupBudgetButton
                  name="Previous"
                  stylesBtn={this.classes.button}
                />
              </Link>
              <SetupBudgetButton
                name="Done"
                stylesBtn={this.classes.buttonPrimary}
              />
            </div>
            <div className={classnames(this.classes.tableButtonWrap, this.state.showTable && this.classes.closed)}>
              <div className={this.classes.tableCont}>
                <div className={this.classes.tableButtonWrapDiv}>
                  <SetupBudgetButton
                    name={this.state.showTable ? 'Hide' : 'Show plan preview'}
                    stylesWrap={this.classes.showButtonWrap}
                    stylesBtn={this.state.showTable ? this.classes.showButtonHide : this.classes.showButton}
                    onClick={this.togleShowTable}
                  />
                  <div className={this.state.showTable ? this.classes.arrowTop : this.classes.arrowBottom} />
                  {!this.state.showTable
                   && (
                   <div className={this.classes.count}>
                     <p>4</p>
                   </div>
                   )}
                </div>
                <div className={classnames(this.classes.tableWrap, this.state.showTable
                  ? this.classes.opened
                  : this.classes.closed)}
                >
                  <BudgetsTableV2 {...mockedData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
