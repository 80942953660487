import React, { useEffect } from 'react';

import style from 'styles/settings/workflows/configPopup.css';

export default function LeadToAccountMatching() {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <div className={style.locals.row}>
      Match
      <div className={style.locals.tag}>
        Lead / Contact
      </div>
      to
      <div className={style.locals.tag}>
        Account
      </div>
      based on
      <div className={style.locals.tag}>
        Email domain
      </div>
    </div>
  );
}
