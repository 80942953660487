import React from 'react';
import classnames from 'classnames';

const PlanVsActualTitleCell = ({
  items, itemsType, value, toggleRows, classes, title = value, titleRender, containerClass, renderContent, titleClass
}) => (
  <div className={containerClass}>
    <button
      type="button"
      onClick={toggleRows(value, itemsType)}
      className={classnames(
        classes.collapseButton,
        items.find((item) => item === value) && classes.collapsed
      )}
    />
    <div className={titleClass}>
      { titleRender ? titleRender() : title }
    </div>
    {renderContent && renderContent()}
  </div>
);

export default PlanVsActualTitleCell;
