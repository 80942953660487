import { action, decorate, observable } from 'mobx';

import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { attributionFiltersOrder, costFiltersOrder } from 'stores/logic/enums';
import { orderConfigFilters } from 'stores/logic/attributionStore';

class FilterStore {
  constructor() {
    this.isFilterDataLoaded = false;
    this.filtersData = [];
  }

  async getUserAttributionFiltersData() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'filters/getAttribution', null, userStore.userMonthPlan.region);
      const attributionFilterDataFromResponse = await response.json();
      const updateAttributionFilterConfig = orderConfigFilters(attributionFilterDataFromResponse, attributionFiltersOrder);
      return updateAttributionFilterConfig;
    } catch (exception) {
      servicesStore.logger.error('failed to get user attribution filters data', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
      return [];
    }
  }

  async getUserCostFiltersData() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'filters/getCosts', null, userStore.userMonthPlan.region);
      const costFilterDataFromResponse = await response.json();
      const updateCostFilterConfig = orderConfigFilters(costFilterDataFromResponse, costFiltersOrder);
      return updateCostFilterConfig;
    } catch (exception) {
      servicesStore.logger.error('failed to get user costs filters data', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
      return [];
    }
  }

  async getUserFiltersData() {
    this.isFilterDataLoaded = false;
    const [attributionFiltersData, costFiltersData] = await Promise.all([this.getUserAttributionFiltersData(), this.getUserCostFiltersData()]);
    this.filtersData = [...attributionFiltersData, ...costFiltersData];
    this.isFilterDataLoaded = true;
  }

  async getFilterKindOptions({
    searchValue, filterKind, filterKey, offset, limit = 20,
  }) {
    try {
      const body = {
        filterKind,
        filterKey,
        searchValue,
        limit,
        offset,
      };
      const response = await serverCommunication.serverRequest('POST', 'filters/search', JSON.stringify(body), userStore.userMonthPlan.region);
      return response.json();
    } catch (exception) {
      servicesStore.logger.error('failed to get filter kind options data', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        filterKind,
        filterKey,
      });
      return {};
    }
  }
}

decorate(FilterStore, {
  filtersData: observable,
  isFilterDataLoaded: observable,
  getUserCostFiltersData: action.bound,
  getUserAttributionFiltersData: action.bound,
  getFilterKindOptions: action.bound,
  getUserFiltersData: action.bound,
});

export default new FilterStore();
