import React from 'react';
import Component from 'components/Component';
import ReactSelect from 'react-select-plus';
import Creatable from 'react-select-plus/lib/Creatable';
import Label from 'components/ControlsLabel';
import style from 'react-select-plus/dist/react-select-plus.css';
import selectStyle from 'styles/controls/select.css';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import VirtualizedSelect from 'react-virtualized-select';
import virtualizedStyle from 'react-virtualized-select/styles.css';

const customStyles = selectStyle.locals;

const CustomOption = ({ label }) => (
  <div className={customStyles.option}>
    <EllipsisTooltip
      text={label}
      place="bottom"
      TooltipProps={{ wide: true }}
    />
  </div>
);

export default class Select extends Component {
  style = style;

  styles = [selectStyle, virtualizedStyle];

  static defaultProps = {
    labelQuestion: false,
    withTooltip: false,
    style: {
      fontSize: '14px',
    },
    clearable: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      validationError: false,
    };
  }

  onChange() {
    this.setState({ validationError: false });
    this.props.onChange.apply(null, arguments);
  }

  iconRenderer = (item) => (
    <div style={{ display: 'flex' }}>
      <div className={selectStyle.locals.icon} data-icon={this.props.iconFromValue(item.value)} />
      <div className={selectStyle.locals.text}>
        {item.label}
      </div>
    </div>
  );

  validationError() {
    this.focus();
    this.setState({ validationError: true });
  }

  focus() {
    this.refs.input.focus();
  }

  render() {
    let label;

    const { customStyle } = this.props;

    if (this.props.label) {
      label = (
        <Label
          question={this.props.labelQuestion}
          description={this.props.description}
        >
          {this.props.label}
        </Label>
      );
    }

    const { select } = this.props;
    let Select = this.props.allowCreate ? Creatable : ReactSelect;
    if (this.props.isVirtualized) {
      Select = VirtualizedSelect;
    }
    const renderers = {};

    if (this.props.iconRendererOnValue) {
      renderers.valueRenderer = this.iconRenderer;
    }

    if (this.props.iconRendererOnOptions) {
      renderers.optionRenderer = this.iconRenderer;
    }

    if (this.props.withTooltip) {
      renderers.optionRenderer = CustomOption;
    }

    if (this.props.optionRenderer) {
      renderers.optionRenderer = this.props.optionRenderer;
    }
    if (this.props.iconRenderer) {
      renderers.optionRenderer = this.props.iconRenderer;
      renderers.valueRenderer = this.props.iconRenderer;
    }

    return (
      <div style={this.props.style} className={this.props.className}>
        {label}
        <div className={selectStyle.locals.selesctWrapper} onClick={this.props.onClick}>
          <div style={{ flex: 'auto' }} className={this.props.errorField ? selectStyle.locals.errorField : undefined}>
            <Select
              {...select}
              {...renderers}
              promptTextCreator={this.props.promptTextCreator}
              onNewOptionClick={this.props.onNewOptionClick}
              ref="input"
              openOnFocus
              value={this.props.selected}
              onChange={this.onChange.bind(this)}
              className={this.props.innerClassName}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              clearable={this.props.clearable}
              style={{
                border: '1px solid #DCE1EF',
                color: '#707EA7',
                ...customStyle,
              }}
            />
          </div>
          <div
            hidden={!this.state.validationError}
            style={{
              background: 'url(/assets/attention.svg) center center no-repeat',
              backgroundSize: 'contain',
              minWidth: '20px',
              minHeight: '20px',
              maxWidth: '20px',
              maxHeight: '20px',
              marginLeft: '15px',
              alignSelf: 'center',
            }}
          />
        </div>
      </div>
    );
  }
}
