import {
  isNil, isEmpty, isEqual,
} from 'lodash';
import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Button } from '@infinigrow/libs';

import exportData from 'modules/exportData';

import Component from 'components/Component';
import Dropdown from 'components/controls/Dropdown';
import FrequencySelect from 'components/pages/analyze/FrequencySelect';
import WidgetHeader from 'components/common/WidgetHeader';
import Inspect from 'components/pages/analyze/inspectData/Inspect';
import HorizontalBarsTable from 'components/pages/analyze/ImpactBoard/HorizontalBarsTable';
import ImpactBarChart from 'components/pages/analyze/ImpactBoard/ImpactBarChart';
import ImpactLineChart from 'components/pages/analyze/ImpactBoard/ImpactLineChart';
import CheckboxWithFrame from 'components/common/CheckboxWithFrame';
import NotSupportedWidget from 'components/common/NotSupportedWidget';
import { getParsedChartData } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegmentChartData';
import DrillDownPopup from 'components/pages/analyze/inspectData/DrillDownPopup';
import Toggle from 'components/controls/Toggle';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import MonthToDate from 'components/common/MonthToDate';
import Skeleton from 'components/common/Skeleton';
import Spinner from 'components/pages/journeys/Spinner';

import { getWidgetDefinitions } from 'widgetDefinitions';
import { getWidgetFullConfig, getWidgetsData } from 'components/pages/analyze/widgetsRequest';
import { compose } from 'components/utils/utils';
import { widgetsConfig, widgetTypes } from 'components/pages/analyze/enums';
import {
  getItemLabelWithNoValue,
  getImpactByFieldsToEnrichAccordingSegment,
  getShouldConvertShowOnlyFilter,
  filterImpactBySegmentRowsWithoutData, parseImpactBySegmentDataForExport, getImpactBySegmentExportColumns, getImpactBySegmentEventData,
} from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { nonUpDirectionMetricTypes, nonCumulativeMetricTypes } from 'components/pages/settings/goals/logic/enums';
import {
  getSegmentType,
  getFiltersForShowOnlyRequest,
  getFiltersForViewJourneysRequest,
  getFiltersWithoutFocusMode,
  getDrilldownMetricsOptions,
  groupBySegmentsOptions,
  getOptionsWithSegmentMapping,
} from 'components/pages/analyze/SegmentsTab/logic/segments';
import {
  contentTypeSegmentValue,
  contentTitleSegmentValue,
  impactChartTypes,
} from 'components/pages/analyze/SegmentsTab/logic/enums';
import { setQueryParams } from 'components/utils/UrlParamsProvider';
import { getRevenueFunnel, getPipelineFunnel } from 'components/utils/indicators';
import { skeletonSmallTextSizes } from 'components/common/enums';
import { areFiltersEqual } from 'components/utils/filters/logic';
import { createSheetAnalyze } from 'components/utils/excelExport';
import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';

import style from 'styles/analyze/analyze.css';

const enhance = compose(
  inject((stores) => {
    const {
      userStore: {
        userAccount: {
          leadSourcesIdToLabelMap,
          customFieldsIdToLabelMap,
        },
        funnels,
        getMetricNickname,
      },
      analysisStore: {
        dataPerWidget: {
          [widgetTypes.creditBySessions]: creditBySessions,
        },
      },
      filterStore: {
        filtersData,
      },
    } = stores;

    return {
      leadSourcesIdToLabelMap,
      customFieldsIdToLabelMap,
      funnels,
      getMetricNickname,
      creditBySessions,
      filtersData,
    };
  }),
  observer
);

class ImpactBySegment extends Component {
  style = style;

  static propTypes = {
    title: PropTypes.string,
    firstSegment: PropTypes.string,
    conversionIndicator: PropTypes.string,
    metricsOptions: PropTypes.arrayOf(PropTypes.shape({})),
    checkedSegments: PropTypes.arrayOf(PropTypes.string),
    uncheckedSegments: PropTypes.arrayOf(PropTypes.string),
    isShowInspectInNavigationMenu: PropTypes.bool,
    isExportLoading: PropTypes.bool,
    exportMode: PropTypes.bool,
  };

  static defaultProps = {
    title: 'Impact by Segment (CRM)',
    firstSegment: '',
    conversionIndicator: '',
    metricsOptions: [],
    isShowInspectInNavigationMenu: true,
    checkedSegments: [],
    uncheckedSegments: [],
    isExportLoading: false,
    exportMode: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      firstSegment: props.firstSegment,
      conversionIndicator: props.conversionIndicator,
      kpiFocus: props.kpiFocus,
      focusMode: null,
      sortByColumn: null,
      isShowInspectPopup: false,
      inspectPopupData: {},
      drillDownPopupEntityId: null,
      drillDownPopupTitle: '',
      toggleViewType: this.props.toggleViewType ?? impactChartTypes.bar,
      isShowSelectAllTooltip: false,
      isSelectAll: isNil(this.props.isSelectAll) ? true : this.props.isSelectAll,
      isExportLoading: false,
    };
    this.colorIndexCache = {};
    this.state.shouldConvertShowOnlyFilter = getShouldConvertShowOnlyFilter({
      filters: props.filters,
      userFunnels: props.funnels,
      conversionIndicator: props.conversionIndicator,
      filtersData: props.filtersData,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.firstSegment !== this.props.firstSegment) {
      this.setState({
        firstSegment: this.props.firstSegment,
      });
    }
    if (this.state.conversionIndicator !== this.props.conversionIndicator) {
      this.setState({
        conversionIndicator: this.props.conversionIndicator,
      });
    }
    if (this.state.kpiFocus !== this.props.kpiFocus) {
      this.setState({
        kpiFocus: this.props.kpiFocus,
      });
    }

    if (prevProps.toggleViewType !== this.props.toggleViewType) {
      this.setState({
        toggleViewType: this.props.toggleViewType,
      });
    }

    const didFiltersChanged = !areFiltersEqual({ firstFiltersArray: this.props.filters, secondFiltersArray: prevProps.filters });
    if (didFiltersChanged && !this.state.shouldConvertShowOnlyFilter) {
      const shouldConvertShowOnlyFilterAfterFilterChange = getShouldConvertShowOnlyFilter({
        filters: this.props.filters,
        userFunnels: this.props.funnels,
        conversionIndicator: this.props.conversionIndicator,
        filtersData: this.props.filtersData,
      });
      this.setState({ shouldConvertShowOnlyFilter: shouldConvertShowOnlyFilterAfterFilterChange });
    }

    if (!isEqual(this.props.segmentData, prevProps.segmentData)) {
      this.colorIndexCache = {};
    }
  }

  onChangeSelect(name) {
    const {
      customFieldsIdToLabelMap,
    } = this.props;

    return ({ value, originalFunnel }) => {
      if (name === 'groupBy') {
        const widgetTitle = getWidgetDefinitions({ widget: widgetTypes.impactBySegment }).eventsWidgetName;

        servicesStore.eventTracker.track({
          eventName: Events.selectedSegment,
          properties: {
            widget: widgetTitle,
            segmentType: getSegmentType({ segmentId: value }),
            selectorType: 'Primary',
            selectedField: customFieldsIdToLabelMap[value] || value,
          },
        });
      }

      this.props.updateCrmParam({ key: name, value, originalFunnel });
    };
  }

  getNavigationMenuPopupsParams({
    valueToNavigate, segmentLabel, itemValue, isNonCumulativeMetric,
  }) {
    const {
      widgetHeaderConfig, relatedEntitiesIndication = {}, funnels, isInsightsTab, filters, isShowInspectInNavigationMenu, isReadOnly,
    } = this.props;
    const { firstSegment, conversionIndicator } = this.state;
    const includeAttributionStoreFilters = isNil(widgetHeaderConfig) && !isInsightsTab;
    const itemToNavigationLabel = getItemLabelWithNoValue({ value: valueToNavigate, segment: firstSegment, segmentLabel });
    const valuesWithoutViewJourneysOption = [' - other'];

    const navigations = [];

    if (isShowInspectInNavigationMenu && !isNonCumulativeMetric) {
      const kpiFocusNickname = this.props.getMetricNickname({ metric: this.state.kpiFocus });
      const dateFormatForLabel = 'MM-DD-YY-hh-mm-ss';
      const queryParamValues = `${valueToNavigate}.start-${moment(this.props.timeframe.startDate).format(dateFormatForLabel)}.end-${moment(this.props.timeframe.endDate).format(dateFormatForLabel)}`;
      navigations.push({
        title: `Inspect ${kpiFocusNickname}: ${itemToNavigationLabel}`,
        subTitle: `See which ${kpiFocusNickname} make up the Touched/Attributed ${kpiFocusNickname} number.`,
        navigationFunction: () => {
          this.setState({
            isShowInspectPopup: true,
            inspectPopupData: {
              firstSegmentType: firstSegment,
              secondSegmentType: null,
              firstSegmentValue: valueToNavigate,
              secondSegmentValue: null,
              totals: {
                [this.props.metricType]: itemValue,
              },
            },
          });
          setQueryParams({ queryParamKey: 'inspect', queryParamValues });
        },
        hasNewTag: true,
      });
    }

    const addViewJourneysToNavigationOptions = (!valuesWithoutViewJourneysOption.some((value) => valueToNavigate.includes(value))
        && !([contentTitleSegmentValue, contentTypeSegmentValue].includes(firstSegment) && valueToNavigate === 'No Value'));
    if (addViewJourneysToNavigationOptions) {
      navigations.push({
        title: 'View journeys',
        subTitle: `Explore the journeys that were influenced by the ${itemToNavigationLabel} segment.`,
        navigationFunction: () => navigateBetweenAnalyzeTabs({
          filters: getFiltersForViewJourneysRequest({
            firstSegment,
            value: valueToNavigate,
            kpiFocus: conversionIndicator,
            widgetHeaderConfig,
            relatedEntitiesIndication,
            funnels,
          }),
          tabName: 'journeys',
          includeAttributionStoreFilters,
          timeFrame: this.props.timeframe,
          attributionModel: this.props.attributionModel,
        }),
      });
    }

    if (!this.state.focusMode && !isReadOnly) {
      navigations.unshift({
        title: `Focus on ${itemToNavigationLabel}`,
        subTitle: `Show only ${itemToNavigationLabel} to uncover additional insights using secondary dimensions.`,
        navigationFunction: () => {
          this.setState({ focusMode: valueToNavigate });
          this.props.innerPageNavigation({
            firstSegment,
            filters: getFiltersForShowOnlyRequest({
              firstSegment, value: valueToNavigate, filters,
            }),
            isNewFiltersUIOnly: true,
          });
        },
      });
    }

    return navigations;
  }

  isHavingMultiShowOnlyFilters() {
    const uiFilters = this.props.filters?.filter((filter) => filter.isUiOnly);
    if (isEmpty(uiFilters)) {
      return false;
    }

    if (uiFilters.length > 1) {
      return true;
    }

    for (const filter of uiFilters) {
      const isHavingMultipleSelectedOptions = filter.data.selectedOptions.length > 1;
      if (isHavingMultipleSelectedOptions) {
        return true;
      }
    }

    return false;
  }

  exitFocusMode() {
    const rawFilters = getFiltersWithoutFocusMode({ filters: this.props.filters, focusKey: this.state.focusMode });
    this.props.innerPageNavigation({
      filters: rawFilters,
      isNewFiltersUIOnly: true,
    });
    this.setState({ focusMode: null });
  }

  getDrillDownResultKeyName({ entityId = this.state.drillDownPopupEntityId }) {
    const { inspectPopupData, kpiFocus } = this.state;
    const firstSegmentType = inspectPopupData.firstSegmentType;
    const secondSegmentType = inspectPopupData.secondSegmentType;
    const firstSegmentSelectedValue = inspectPopupData.firstSegmentValue;
    return `creditBySession-${entityId}-${firstSegmentType}-${secondSegmentType}-${firstSegmentSelectedValue}-${kpiFocus}`;
  }

  getDrillDownData({ entityId, title }) {
    const { inspectPopupData } = this.state;
    const { timeframe: timeFrame, attributionModel, filters } = this.props;
    this.setState({ drillDownPopupEntityId: entityId, isShowInspectPopup: false, drillDownPopupTitle: title });
    const firstSegment = inspectPopupData.firstSegmentType;
    const secondSegment = null;

    const resultKeyName = this.getDrillDownResultKeyName({ entityId });
    let selectedFunnel = this.state.kpiFocus;
    if (this.props.metricType === 'pipeline') {
      selectedFunnel = getPipelineFunnel();
    }
    if (this.props.metricType === 'revenue') {
      selectedFunnel = getRevenueFunnel();
    }

    getWidgetsData({
      widgets: [widgetTypes.creditBySessions],
      configPerWidget: {
        [widgetTypes.creditBySessions]: [{
          entityId,
          firstSegment,
          secondSegment,
          selectedFunnel,
          timeFrame,
          attributionModel,
          filters,
          metricType: this.props.metricType,
        }],
      },
      resultKeyName,
    });
  }

  onChangeToggleView({
    newToggleViewType, isNonCumulativeMetric, data, isLowerBetter,
  }) {
    this.setState({ toggleViewType: newToggleViewType });
    if (this.props.updateWidgetPropsValues) {
      this.props.updateWidgetPropsValues({ values: { toggleViewType: newToggleViewType } });
    }
    if (isNonCumulativeMetric) {
      if (newToggleViewType === impactChartTypes.line && this.props.checkedSegments.length === 0) {
        const dataTopFiveItems = isLowerBetter ? data.reverse().slice(0, 5) : data.slice(0, 5);
        const updatedCheckedSegments = dataTopFiveItems.map((row) => row.name);
        this.props.onChangeCheckedSegments({ segments: updatedCheckedSegments });
      }

      if (newToggleViewType === impactChartTypes.bar && this.props.checkedSegments.length > 1) {
        this.props.onChangeCheckedSegments({ segments: [] });
        this.setState({ isShowSelectAllTooltip: true });
        setTimeout(() => {
          this.setState({ isShowSelectAllTooltip: false });
        }, 3000);
      }
    }
  }

  onChangeDrilldownMetric({ metric: drilldownMetric }) {
    this.props.updateDrilldownMetric({ metric: drilldownMetric });
    const drilldownMetricValue = drilldownMetric?.value;
    if (drilldownMetricValue) {
      const isNonCumulativeMetric = nonCumulativeMetricTypes.some((metric) => drilldownMetricValue.toLowerCase().includes(metric.toLowerCase()));
      if (isNonCumulativeMetric) {
        this.setState({ toggleViewType: impactChartTypes.line });
      }
    } else {
      this.setState({ toggleViewType: impactChartTypes.bar });
    }
  }

  async exportData({ selectedColumns }) {
    const dateRangeLabel = `${moment(this.props.timeframe.startDate).format('MM_DD_YY')} - ${moment(this.props.timeframe.endDate).format('MM_DD_YY')}`;
    const fileName = `impact-by-segment-${dateRangeLabel}`;
    const config = getWidgetFullConfig({
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.props.currentFrequency,
          firstSegment: this.state.firstSegment,
          shouldUseRelativeTimeframe: this.props.shouldUseRelativeTimeframe,
          selectedColumns,
        },
        conversionIndicator: this.state.conversionIndicator,
        kpiFocus: this.props.kpiFocus || this.state.conversionIndicator,
        type: widgetTypes.impactBySegment,
      },
    });

    const eventData = getImpactBySegmentEventData({ widgetConfig: config });
    try {
      await exportData.exportAnalyzeWidgetData({
        widget: widgetTypes.impactBySegment,
        config,
        selectedColumns,
        filter: filterImpactBySegmentRowsWithoutData,
        parse: parseImpactBySegmentDataForExport,
        createSheet: createSheetAnalyze,
        eventData,
        fileName,
        dataToExport: {
          impactData: this.props.segmentData,
          totalBySegment: this.props.totalBySegment,
        },
      });
    } catch (error) {
      servicesStore.logger.error('failed to get impact by segment export', {
        UID: this.props.UID,
        error,
      });
    }
  }

  async onExportData() {
    this.setState({ isExportLoading: true });

    const kpiFocusNickname = this.props.getMetricNickname({ metric: this.state.kpiFocus });
    const selectedColumns = [];
    const columns = getImpactBySegmentExportColumns({
      frequency: this.props.currentFrequency,
      segment: this.props.currentSegmentLabel,
      kpiFocus: kpiFocusNickname,
    });
    for (const column of columns) {
      selectedColumns.push({
        key: column.key,
        label: column.label,
      });
    }
    await this.exportData({ selectedColumns });
    this.setState({ isExportLoading: false });
  }

  render() {
    const {
      currentFrequency,
      metricsOptions,
      frequencyControl,
      segmentData,
      hideNoValuesRows,
      title,
      kpiFocus,
      showSegmentDropdown,
      showNoValueCheckbox,
      hideOtherValuesRows,
      isCalculateAdvancedMetrics,
      updateShouldUseRelativeTimeframe,
      shouldUseRelativeTimeframe,
      widgetHeaderConfig = {
        title,
        type: widgetTypes.impactBySegment,
        conversionIndicator: this.state.conversionIndicator,
        trendBySegmentsParams: {
          firstSegment: this.state.firstSegment,
          secondSegment: null,
        },
        sortByColumn: this.state.sortByColumn,
        hideNoValuesRows,
        kpiFocus,
        showSegmentDropdown,
        showNoValueCheckbox,
        hideOtherValuesRows,
        checkedSegments: this.state.isSelectAll ? [] : this.props.checkedSegments,
        uncheckedSegments: this.state.isSelectAll ? this.props.uncheckedSegments : [],
        toggleViewType: this.state.toggleViewType,
        isSelectAll: this.state.isSelectAll,
        impactBySegmentParams: {
          segmentFrequency: currentFrequency,
          firstSegment: this.state.firstSegment,
          fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: this.state.firstSegment } }),
          shouldUseRelativeTimeframe,
        },
        isCalculateAdvancedMetrics,
      },
      exportMode,
      updateCrmParam,
      isShowKPIFocusDropdown,
      segmentsOptionsFields,
      onChangeHideNoValuesRows,
      totalByFrequency = {},
      totalBySegment = {},
      updateFilters,
      getMetricNickname,
      creditBySessions,
      isImpactBySegmentFailedToLoad,
      isReadOnly,
      widgetHeaderProps = {},
      uncheckedSegments = [],
      checkedSegments = [],
      growthBySegment = {},
      growthByFrequency = {},
      growthImpactData = {},
      isCompareToPreviousEnabled,
      isShowWidgetHeader = true,
      isEnableSkeletonAnimation = true,
      isEnableNavigationMenu = true,
    } = this.props;

    const kpiFocusNickname = getMetricNickname({ metric: this.state.kpiFocus });
    const currentSegmentLabel = this.props.currentSegmentLabel || segmentsOptionsFields.find((item) => item.value === this.state.firstSegment)?.label;
    const isNonCumulativeMetric = nonCumulativeMetricTypes.some((metric) => kpiFocus.toLowerCase().includes(metric.toLowerCase()));
    const isLowerBetter = nonUpDirectionMetricTypes.some((metric) => kpiFocus.toLowerCase().includes(metric.toLowerCase()));

    const updatedCheckedSegments = [];
    if (isNonCumulativeMetric && this.state.toggleViewType === impactChartTypes.bar) {
      updatedCheckedSegments.push(...checkedSegments);
    } else if (this.state.isSelectAll) {
      const allSegmentsNames = Object.keys(totalBySegment);
      for (const segment of allSegmentsNames) {
        if (uncheckedSegments.includes(segment)) {
          continue;
        }
        updatedCheckedSegments.push(segment);
      }
    } else {
      for (const checkedSegment of checkedSegments) {
        const isCheckedSegmentExists = Object.keys(totalBySegment).some((item) => item === checkedSegment);
        if (isCheckedSegmentExists) {
          updatedCheckedSegments.push(checkedSegment);
        }
      }
    }

    if (updatedCheckedSegments.length && !isEqual(updatedCheckedSegments, checkedSegments)) {
      this.props.onChangeCheckedSegments({ segments: updatedCheckedSegments });
    }

    const widgetHeaderConfigsOptions = [];
    if (exportMode) {
      widgetHeaderConfigsOptions.unshift({
        label: 'Export data',
        action: () => this.onExportData(),
        disabled: !this.props.isLoaded || this.state.isExportLoading,
        tooltip: 'Loading data...',
      });
    }

    const {
      maxValue,
      dataNames,
      chartData,
      parsedData,
      colorIndexs,
      compareValueIgnoreNames,
    } = getParsedChartData({
      segmentData,
      totalBySegment,
      totalByFrequency,
      hideNoValuesRows,
      isNonCumulativeMetric,
      hideOtherValuesRows,
      toggleViewType: this.state.toggleViewType,
      colorIndexCache: this.colorIndexCache,
      checkedSegments: this.props.checkedSegments,
      growthBySegment,
      isCompareToPreviousEnabled,
    });

    const drilldownMetricsOptions = getDrilldownMetricsOptions({ indicator: this.state.conversionIndicator, isRemoveIndicatorFromOptions: true });
    const isMultiCheckView = !isNonCumulativeMetric || this.state.toggleViewType === impactChartTypes.line;

    const drillDownData = creditBySessions?.[this.getDrillDownResultKeyName({})]?.data ?? {};
    const isDrillDownDataLoaded = creditBySessions?.[this.getDrillDownResultKeyName({})]?.isLoaded;

    if (isImpactBySegmentFailedToLoad) {
      return (
        <ErrorWidgetWithBlur
          status="error"
          widgetType={widgetTypes.marketingVsSales}
        />
      );
    }

    const widgetHeaderChildren = (
      <>
        <Dropdown
          selectedKey={this.props.kpiFocus}
          options={drilldownMetricsOptions}
          onChange={(metric) => this.onChangeDrilldownMetric({ metric })}
          dropdownLabel="Metric drilldown"
          isClearable
          disabled={isReadOnly}
        />
        {isShowKPIFocusDropdown && (
        <Dropdown
          selectedKey={this.state.conversionIndicator}
          options={metricsOptions}
          onChange={this.onChangeSelect('indicator')}
          disabled={isReadOnly || !updateCrmParam}
          dropdownLabel="KPI Focus"
        />
        )}
        {showSegmentDropdown && (
        <Dropdown
          selectedKey={this.state.firstSegment}
          options={getOptionsWithSegmentMapping({ options: groupBySegmentsOptions({ options: segmentsOptionsFields }) })}
          onChange={this.onChangeSelect('groupBy')}
          disabled={isReadOnly || !updateCrmParam}
          dropdownLabel="Segment"
          isSearchable
        />
        )}
        <FrequencySelect
          disabled={isReadOnly || !frequencyControl}
          selected={currentFrequency}
          onChange={frequencyControl}
          {...frequencyControl}
          widgets={[widgetsConfig.impactBySegment.type]}
        />

        {showNoValueCheckbox && (
        <CheckboxWithFrame
          checked={hideNoValuesRows}
          onChange={onChangeHideNoValuesRows}
          text="Hide segments without values"
        />
        )}

        <MonthToDate
          frequency={currentFrequency}
          shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
          updateShouldUseRelativeTimeframe={updateShouldUseRelativeTimeframe}
          disabled={isReadOnly || !updateShouldUseRelativeTimeframe}
        />
        { this.state.isExportLoading && <Spinner />}
      </>
    );

    return (
      <div className={classNames(this.classes.widgetSpace, this.classes.impactBySegmentWrapper)}>
        {isShowWidgetHeader ? (
          <WidgetHeader
            {...widgetHeaderProps}
            cogwheelOptions={widgetHeaderConfigsOptions}
            widgetHeaderConfig={widgetHeaderConfig}
            isShowIndicatorDropdown={!isShowKPIFocusDropdown}
          >
            {widgetHeaderChildren}
          </WidgetHeader>
        ) : null}

        {parsedData.length > 0 || !this.props.isLoaded
          ? (
            <div className={classNames(this.classes.item, this.classes.splitWidget, this.classes.translateZero)}>
              <div>
                <div className={classNames(this.classes.widgetTitle, this.classes.flexRow)}>
                  {this.state.focusMode && (<span className={this.classes.arrowBackIcon} onClick={() => this.exitFocusMode()} />)}
                  {`${kpiFocusNickname} per `}
                  {currentSegmentLabel || <Skeleton {...skeletonSmallTextSizes} enableAnimation={isEnableSkeletonAnimation} isLightTheme />}

                </div>
                {this.state.isShowSelectAllTooltip && (
                  <div className={this.classes.showSelectAllTooltip}>
                    {`Stacked bars can only be calculated for a single segment or for all segment, when analyzing ${kpiFocusNickname}. Your selection has been switched to Select All.`}
                  </div>
                )}
                <HorizontalBarsTable
                  data={parsedData}
                  indicator={this.state.kpiFocus}
                  trendData={this.props.trendData}
                  isLoaded={this.props.isLoaded}
                  isLoadedTrendData={this.props.isLoadedTrendData}
                  maxValue={maxValue}
                  getNavigationMenuPopupsParams={({ valueToNavigate, itemValue }) => this.getNavigationMenuPopupsParams({
                    valueToNavigate, itemValue, segmentLabel: currentSegmentLabel, isNonCumulativeMetric,
                  })}
                  isEnableNavigationMenu={isEnableNavigationMenu}
                  metricType={this.props.metricType}
                  firstSegment={this.state.firstSegment}
                  currentSegmentLabel={currentSegmentLabel}
                  isLowerBetter={isLowerBetter}
                  dataTotals={this.props.dataTotals}
                  colorIndexs={colorIndexs}
                  sortByColumn={this.props.sortByColumn}
                  setSortByColumn={({ sortBy }) => this.setState({ sortByColumn: sortBy })}
                  onChangeCheckedSegments={({ segments }) => this.props.onChangeCheckedSegments({ segments })}
                  onChangeUncheckedSegments={({ segments }) => this.props.onChangeUncheckedSegments({ segments })}
                  checkedSegments={this.props.checkedSegments}
                  uncheckedSegments={this.props.uncheckedSegments}
                  isMultiCheckView={isMultiCheckView}
                  isNonCumulativeMetric={isNonCumulativeMetric}
                  isCompareToPreviousEnabled={isCompareToPreviousEnabled}
                  isCalculateAdvancedMetrics={this.props.isCalculateAdvancedMetrics}
                  updateSelectAll={(value) => {
                    if (this.props.updateSelectAll) {
                      this.props.updateSelectAll(value);
                    }
                    this.setState({ isSelectAll: value });
                  }}
                  isEnableSkeletonAnimation={isEnableSkeletonAnimation}
                />
              </div>
              <div>
                <div className={this.classes.widgetTitle}>
                  {`${kpiFocusNickname} Trend`}
                  <div className={this.classes.toggleView}>
                    <Toggle
                      options={[
                        {
                          icon: <div className={this.classes.barsChartIcon} />,
                          value: impactChartTypes.bar,
                          tooltip: 'Stacked Bar',
                        },
                        {
                          icon: <div className={this.classes.linesChartIcon} />,
                          value: impactChartTypes.line,
                          tooltip: 'Line',
                        },
                      ]}
                      selectedValue={this.state.toggleViewType}
                      onClick={(value) => this.onChangeToggleView({
                        newToggleViewType: value, isNonCumulativeMetric, data: parsedData, isLowerBetter,
                      })}
                      minWidth="36px"
                    />
                  </div>
                </div>
                {isNonCumulativeMetric && this.isHavingMultiShowOnlyFilters() && this.state.shouldConvertShowOnlyFilter ? (
                  <NotSupportedWidget
                    title="‘Include any of’ filter is required"
                    subTitle={`To analyze ${kpiFocusNickname} with your selected 'Show Only' filter, apply an additional filter below`}
                  >
                    <Button
                      type="primaryBlue"
                      onClick={() => {
                        this.setState({ shouldConvertShowOnlyFilter: false });
                        updateFilters();
                      }}
                    >
                      Apply filter
                    </Button>
                  </NotSupportedWidget>
                ) : (
                  <>
                    {this.state.toggleViewType === impactChartTypes.bar ? (
                      <ImpactBarChart
                        dataNames={dataNames}
                        chartData={chartData}
                        indicator={this.state.kpiFocus}
                        currentSegmentLabel={currentSegmentLabel}
                        firstSegment={this.state.firstSegment}
                        isNonCumulativeMetric={isNonCumulativeMetric}
                        colorIndexs={colorIndexs}
                        emptyStateText="Select a segment to see trend data"
                        isLoaded={this.props.isLoaded}
                        growthImpactData={growthImpactData}
                        growthByFrequency={growthByFrequency}
                        isCompareToPreviousEnabled={isCompareToPreviousEnabled}
                        isEnableSkeletonAnimation={isEnableSkeletonAnimation}
                      />
                    ) : (
                      <ImpactLineChart
                        dataNames={dataNames}
                        chartData={chartData}
                        indicator={this.state.kpiFocus}
                        currentSegmentLabel={currentSegmentLabel}
                        firstSegment={this.state.firstSegment}
                        colorIndexs={colorIndexs}
                        emptyStateText="Select a segment to see trend data"
                        isNonCumulativeMetric={isNonCumulativeMetric}
                        isLoaded={this.props.isLoaded}
                        growthImpactData={growthImpactData}
                        growthByFrequency={growthByFrequency}
                        isCompareToPreviousEnabled={isCompareToPreviousEnabled}
                        compareValueIgnoreNames={compareValueIgnoreNames}
                        isEnableSkeletonAnimation={isEnableSkeletonAnimation}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {this.props.emptyStateComponent ? (
                this.props.emptyStateComponent
              ) : (
                <div className={classNames(this.classes.item, this.classes.emptyState)}>
                  <b>0</b>
                  {kpiFocusNickname}
                </div>
              )}
            </>
          )}

        {this.state.isShowInspectPopup && (
        <Inspect
          inspectMetricData={this.state.inspectPopupData}
          setIsShowInspectPopup={(isShowPopup) => this.setState({ isShowInspectPopup: isShowPopup })}
          kpiFocus={this.state.conversionIndicator}
          timeFrame={this.props.timeframe}
          filters={this.props.filters}
          attributionModel={this.props.attributionModel}
          setDrillDownPopupEntityId={(entityId, entityTitle) => this.getDrillDownData({ entityId, title: entityTitle })}
          metricType={this.props.metricType}
        />
        )}
        {this.state.drillDownPopupEntityId && (
        <DrillDownPopup
          onClose={() => this.setState({ drillDownPopupEntityId: null })}
          entityId={this.state.drillDownPopupEntityId}
          drillDownData={drillDownData}
          isLoaded={isDrillDownDataLoaded}
          onGoBack={() => this.setState({ isShowInspectPopup: true, drillDownPopupEntityId: null })}
          title={this.state.drillDownPopupTitle}
          segments={{
            firstSegmentValue: this.state.inspectPopupData.firstSegmentValue,
            firstSegment: this.state.inspectPopupData.firstSegmentType,
            secondSegmentValue: null,
            secondSegment: null,
          }}
          metricType={this.props.metricType}
        />
        )}
      </div>
    );
  }
}

export default enhance(ImpactBySegment);
