import React from 'react';
import classnames from 'classnames';
import Component from 'components/Component';
import style from 'styles/controls/switch.css';

class Switch extends Component {
  style = style;

  render() {
    const {
      title,
      onSwitchClick,
      isActive,
      disabled,
      dataTestId,
    } = this.props;
    return (
      <div className={this.classes.row} style={this.props.style}>
        <div
          data-testid={dataTestId}
          className={classnames(this.classes.switchContainer, {
            [this.classes.switchContainerActive]: isActive,
          }, disabled ? this.classes.disabled : null)}
          onClick={() => (disabled ? null : onSwitchClick())}
        >
          <div className={classnames(this.classes.switchHandle, {
            [this.classes.switchHandleLeft]: !isActive,
            [this.classes.switchHandleRight]: isActive,
          })}
          />
        </div>
        {title && <div className={this.classes.switchTitle}>{title}</div>}
      </div>
    );
  }
}

export default Switch;
