import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import style from 'styles/settings/workflows/configPopup.css';

function CrmMultiTouch({ configuration, leadSourcesIdToLabelMap }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <div className={style.locals.row}>
      {configuration.leadSourcesWithHistory.map((leadSourceId) => (
        <div className={style.locals.tag}>
          {leadSourcesIdToLabelMap[leadSourceId]}
        </div>
      ))}
    </div>
  );
}

export default inject(({
  userStore: {
    userAccount: {
      leadSourcesIdToLabelMap,
    },
  },
}) => ({
  leadSourcesIdToLabelMap,
}),
observer)(CrmMultiTouch);
