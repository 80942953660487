exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".goal-icon__goal-icon-3ZG{width:21px;height:21px;background-size:contain;background-position:50%;background-repeat:no-repeat;margin:0 auto;opacity:.4}.goal-icon__goal-icon-3ZG:hover{opacity:1}.goal-icon__reached-icon-2j5{background-image:url(/assets/objectives-icons/goal-reached.svg)}.goal-icon__aligned-icon-HXD{background-image:url(/assets/objectives-icons/on-track.svg)}.goal-icon__not-aligned-icon-19B{background-image:url(/assets/objectives-icons/off-track.svg)}", ""]);

// exports
exports.locals = {
	"goal-icon": "goal-icon__goal-icon-3ZG",
	"goalIcon": "goal-icon__goal-icon-3ZG",
	"reached-icon": "goal-icon__reached-icon-2j5 goal-icon__goal-icon-3ZG",
	"reachedIcon": "goal-icon__reached-icon-2j5 goal-icon__goal-icon-3ZG",
	"aligned-icon": "goal-icon__aligned-icon-HXD goal-icon__goal-icon-3ZG",
	"alignedIcon": "goal-icon__aligned-icon-HXD goal-icon__goal-icon-3ZG",
	"not-aligned-icon": "goal-icon__not-aligned-icon-19B goal-icon__goal-icon-3ZG",
	"notAlignedIcon": "goal-icon__not-aligned-icon-19B goal-icon__goal-icon-3ZG"
};