import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import NotificationSection from 'components/pages/settings/actions/actionPopup/notificationSection';
import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';
import TriggerSection from 'components/pages/settings/actions/actionPopup/triggerSection';
import PreviewSection from 'components/pages/settings/actions/actionPopup/previewSection';
import Dropdown from 'components/controls/Dropdown';
import Tooltip from 'components/controls/Tooltip';

import {
  nameAlreadyInUseErrorMessage,
  sectionTypes,
  minCharToShowTooltip,
  emptyNotification,
  actionTriggerTypes,
  notificationFrequency,
  actionTriggerTypesLabels,
  notificationTypesLabels,
} from 'components/pages/settings/actions/enums';
import { getAlertUniqueTitle, isTriggerRulesAreValid } from 'components/pages/settings/actions/logic/actions';
import { getNotificationDetailsForServerRequest } from 'components/pages/settings/actions/actionPopup/notificationSection/logic/notificationSection';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function ActionPopup({
  userStore,
  onClose,
  actionData = {},
  userActionNames = [],
  updateActionRequest,
  isActionExists,
  createNewAction,
  region,
  getMetricNickname = userStore?.getMetricNickname,
  userRegionsNicknames,
  isEnableMultipleNotifications,
  getRegionValue,
}) {
  useStyles([style]);

  const [actionName, setActionName] = useState({
    name: actionData.name || getAlertUniqueTitle({ baseTitle: 'New Alert', listOfNames: userActionNames }),
    error: '',
  });

  const [trigger, setTrigger] = useState({
    region: actionData.region || region,
    triggerType: actionData.triggerType || actionTriggerTypes.accountEngagement,
    frequency: actionData.frequency || notificationFrequency.daily,
  });

  const [triggerRules, setTriggerRules] = useState(cloneDeep(actionData.triggerRules) || [{}]);

  const isNewNotification = !actionData.notifications || actionData.notifications?.length === 0;
  const [notifications, setNotifications] = useState(isNewNotification ? [emptyNotification] : cloneDeep(actionData.notifications));

  const [sectionToOpen, setSectionToOpen] = useState(sectionTypes.trigger);
  const [validationAlert, setValidationAlert] = useState({
    isTriggerRulesValid: isTriggerRulesAreValid({ triggerType: actionData.triggerType, triggerRules: actionData.triggerRules }),
    isNotificationsValid: false,
  });

  function changeActionName({ newName }) {
    const isNameAlreadyExists = userActionNames.some((name) => name.toLowerCase() === newName.toLowerCase());
    if (isNameAlreadyExists) {
      setActionName({ name: newName, error: nameAlreadyInUseErrorMessage });
    } else {
      setActionName({ name: newName, error: '' });
    }
  }

  async function onSaveAction() {
    const isNewAction = !isActionExists({ actionId: actionData.actionId });

    for (const notification of notifications) {
      const { body, subject } = getNotificationDetailsForServerRequest({
        notificationType: notification.notificationType, actionName: actionName.name, triggerType: trigger.triggerType, frequency: trigger.frequency, triggerRules, toDoMessage: notification.toDoMessage,
      });
      notification.subject = subject;
      notification.body = body;
    }

    if (isNewAction) {
      const newAction = {
        name: actionName.name,
        triggerRules,
        triggerType: trigger.triggerType,
        region: trigger.region,
        notifications,
        frequency: trigger.frequency,
      };

      await createNewAction({ newAction });
    } else {
      const updatedAction = {};
      if (actionData.name !== actionName.name) {
        updatedAction.name = actionName.name;
      }

      if (!isEqual(triggerRules, actionData.triggerRules)) {
        updatedAction.triggerRules = triggerRules;
      }

      if (actionData.triggerType !== trigger.triggerType) {
        updatedAction.triggerType = trigger.triggerType;
      }

      if (actionData.region !== trigger.region) {
        updatedAction.region = trigger.region;
      }

      if (!isEqual(actionData.notifications, notifications)) {
        updatedAction.notifications = notifications;
      }

      if (actionData.frequency !== trigger.frequency) {
        updatedAction.frequency = trigger.frequency;
      }

      if (isEmpty(updatedAction)) {
        onClose();
        return;
      }
      await updateActionRequest({ actionId: actionData.actionId, properties: updatedAction });
    }

    servicesStore.eventTracker.track({
      eventName: Events.alertsSavedAlert,
      properties: {
        triggerType: actionTriggerTypesLabels[trigger.triggerType],
        alertType: notificationTypesLabels[notifications[0].notificationType],
        frequency: trigger.frequency,
        source: isNewAction ? 'New alert' : 'Edit Alert',
      },
    });

    onClose();
  }

  const isMultiRegionsAccount = userRegionsNicknames?.length > 1;

  return (
    <Page className={styles.pageWrapper} popup>
      <div className={styles.wrapper}>
        <div className={styles.actionNameHeader}>
          <div
            className={styles.closeButton}
            onClick={onClose}
          />
          <Tooltip id="tooltip-actionName" tip={actionName.name} hidden={actionName.name.length < minCharToShowTooltip}>
            <Textfield
              dataTestId="actionNameInput"
              value={actionName.name}
              inputClassName={styles.textFieldInput}
              onChange={(event) => changeActionName({ newName: event.target.value })}
              isShowError={actionName.error !== ''}
              inputErrorMessage={actionName.error}
              placeHolder={`E.g., "Track ${getMetricNickname({ metric: 'funnel1ToFunnel2ConversionRate' })}"`}
            />
          </Tooltip>
        </div>

        {isMultiRegionsAccount ? (
          <div className={styles.row}>
            <div className={styles.text}>
              Business unit
            </div>
            <Dropdown
              selectedKey={trigger.region}
              options={userRegionsNicknames.map((userRegion) => ({ value: getRegionValue({ regionNickname: userRegion }), label: userRegion }))}
              onChange={(newRegion) => setTrigger({ ...trigger, region: newRegion.value })}
              controlWidth={354}
            />
          </div>
        ) : null}
        <TriggerSection
          triggerType={trigger.triggerType}
          setTriggerType={(newTriggerType) => setTrigger({ ...trigger, triggerType: newTriggerType })}
          triggerRules={triggerRules}
          setTriggerRules={(newTriggerRules) => setTriggerRules(newTriggerRules)}
          setNotifications={setNotifications}
          isTriggerSectionOpen={sectionToOpen === sectionTypes.trigger}
          setSectionToOpen={setSectionToOpen}
          onClickContinue={(newTriggerData) => {
            setTrigger({ ...trigger, ...newTriggerData });
            setSectionToOpen(sectionTypes.notification);
          }}
          isActionsAreValid={validationAlert.isTriggerRulesValid}
          setIsActionsAreValid={(isValid) => setValidationAlert((prev) => ({ ...prev, isTriggerRulesValid: isValid }))}
        />
        <NotificationSection
          triggerRules={triggerRules}
          notifications={notifications}
          setNotifications={setNotifications}
          isNotificationSectionOpen={sectionToOpen === sectionTypes.notification}
          setSectionToOpen={setSectionToOpen}
          onClickContinue={() => setSectionToOpen(sectionTypes.preview)}
          isActionsAreValid={validationAlert.isNotificationsValid}
          setIsActionsAreValid={(isValid) => setValidationAlert((prev) => ({ ...prev, isNotificationsValid: isValid }))}
          triggerData={trigger}
          setTriggerData={(newTriggerData) => setTrigger({ ...trigger, ...newTriggerData })}
          isEnableMultipleNotifications={isEnableMultipleNotifications}
        />
        <PreviewSection
          isPreviewSectionOpen={sectionToOpen === sectionTypes.preview}
          setSectionToOpen={setSectionToOpen}
          triggerRules={triggerRules}
          toDoMessage={notifications?.[0]?.toDoMessage}
          actionName={actionName.name}
          setNotifications={setNotifications}
          notifications={notifications}
          triggerData={trigger}
        />
        <div className={styles.alertButtons}>
          <Button
            type="secondaryBlue"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primaryBlue"
            onClick={() => onSaveAction()}
            disabled={actionName.name === '' || !validationAlert.isNotificationsValid || !validationAlert.isTriggerRulesValid}
          >
            Save
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default inject(
  ({
    userStore: {
      updateActionRequest,
      isActionExists,
      createNewAction,
      userRegionsNicknames,
      getRegionValue,
      userMonthPlan: {
        region,
      } = {},
      getMetricNickname,
    } = {},
  }) => ({
    updateActionRequest,
    isActionExists,
    createNewAction,
    region,
    getMetricNickname,
    userRegionsNicknames,
    getRegionValue,
  }),
  observer
)(ActionPopup);
