export function getReportSyncStatuses({ funnelPopupData }) {
  const reportSyncStatus = {};
  for (const [funnelItem, funnelData] of Object.entries(funnelPopupData)) {
    if (funnelData.reportSync) {
      reportSyncStatus[funnelItem] = funnelData.reportSync;
    }
  }

  return reportSyncStatus;
}
