import React from 'react';
import PropTypes from 'prop-types';
import {
  formatChannels,
  getChannelIcon,
  NON_ANALYZE_CHANNEL,
  NON_ANALYZE_CHANNEL_LABEL,
} from 'components/utils/channels';
import Component from 'components/Component';
import Select from 'components/controls/Select';
import ChannelIcon from 'components/common/ChannelIcon';
import selectStyle from 'styles/controls/select.css';

export default class ChannelsSelect extends Component {
  static propTypes = {
    isChannelDisabled: PropTypes.func,
    withOtherChannels: PropTypes.bool,
  };

  static defaultProps = {
    withOtherChannels: false,
    isNonAnalyzeChannel: false,
  };

  focus() {
    this.refs.input.focus();
  }

  render() {
    const {
      isNonAnalyzeChannel,
      isChannelDisabled,
      withOtherChannels,
      ...otherProps
    } = this.props;

    const channelOptions = formatChannels(isChannelDisabled, withOtherChannels);
    if (isNonAnalyzeChannel) {
      channelOptions.push({
        label: NON_ANALYZE_CHANNEL_LABEL,
        options: [{
          label: NON_ANALYZE_CHANNEL_LABEL,
          value: NON_ANALYZE_CHANNEL,
        }],
      });
    }

    const channels = {
      select: {
        name: 'channels',
        options: channelOptions,
      },
    };

    return (
      <Select
        {...otherProps}
        select={{
          menuTop: true,
          name: 'channels',
          onChange: (selected) => {
            update({
              selected,
            });
          },
          options: channels.select.options,
        }}
        iconRenderer={(item) => (
          <div style={{ display: 'flex' }}>
            <ChannelIcon
              className={selectStyle.locals.icon}
              channelIcon={getChannelIcon(item.value)}
              channel={item.value}
            />
            <div className={selectStyle.locals.text}>
              {item.label}
            </div>
          </div>
        )}
        allowCreate={withOtherChannels}
        ref="input"
        promptTextCreator={(value) => `Add ${value} as a channel`}
      />
    );
  }
}
