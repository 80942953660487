import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';

import style from 'styles/onboarding/onboarding.css';

export default class UnsavedPopup extends Component {
  style = style;

  callback(userAnswer) {
    this.props.callback(userAnswer);
  }

  render() {
    return (
      <div hidden={this.props.hidden}>
        <Page className={this.classes.popupIndex} popup width="350px">
          <div className={this.classes.unsavedPopupIcon} />
          <div className={this.classes.unsavedTitle}>
            Your changes have not been saved.
          </div>
          <div className={this.classes.unsavedSubTitle}>
            Are you sure you want to leave this page?
          </div>
          <div className={this.classes.footer} style={{ justifyContent: 'center' }}>
            <Button
              type="secondaryBlue"
              style={{ width: '100px', marginRight: '9px' }}
              onClick={() => this.callback(true)}
            >
              Leave
            </Button>
            <Button
              type="primaryBlue"
              style={{ width: '100px', marginLeft: '9px' }}
              onClick={() => this.callback(false)}
            >
              Stay
            </Button>
          </div>
        </Page>
      </div>
    );
  }
}
