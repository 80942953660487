exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".actionsTable__rowWrapper-1r4{color:#707ea7;font-size:14px;font-weight:400;display:flex;width:140px}.actionsTable__clickableItem-2kg:hover{cursor:pointer;color:#4d91fc;-webkit-text-decoration-line:underline;text-decoration-line:underline;border-radius:12px}.actionsTable__icon-HQ6{width:42px;height:42px;background-repeat:no-repeat;background-position:50%}.actionsTable__switchCell-1dK *{box-sizing:content-box!important}.actionsTable__syncError-1rt{height:24px;width:24px;background-size:contain;background-repeat:no-repeat}[data-icon=\"userActions:slack\"]{background-image:url(\"/assets/userActions-icons/slack.svg\")}[data-icon=\"userActions:exclamationMark\"]{background-image:url(\"/assets/userActions-icons/exclamationMark.svg\")}[data-icon=\"userActions:email\"]{background-image:url(\"/assets/userActions-icons/email.svg\")}[data-icon=\"userActions:audienceList\"]{background-image:url(\"/assets/userActions-icons/audienceList.svg\")}[data-icon=\"userActions:report\"]{background-image:url(\"/assets/userActions-icons/report.svg\")}[data-icon=\"userActions:delete\"]{background-image:url(\"/assets/userActions-icons/trash.svg\")}[data-icon=syncError]{background-image:url(\"/assets/warnning.svg\")}", ""]);

// exports
exports.locals = {
	"rowWrapper": "actionsTable__rowWrapper-1r4",
	"rowWrapper": "actionsTable__rowWrapper-1r4",
	"clickableItem": "actionsTable__clickableItem-2kg",
	"clickableItem": "actionsTable__clickableItem-2kg",
	"icon": "actionsTable__icon-HQ6",
	"icon": "actionsTable__icon-HQ6",
	"switchCell": "actionsTable__switchCell-1dK",
	"switchCell": "actionsTable__switchCell-1dK",
	"syncError": "actionsTable__syncError-1rt",
	"syncError": "actionsTable__syncError-1rt"
};