export function getFilteredOptions({ searchTerm, options }) {
  const updatedOptions = [];
  for (const option of options) {
    if (option.value) {
      if (option.value !== '' && option.label.toLowerCase().match(searchTerm.toLowerCase())) {
        updatedOptions.push(option);
      }
    } else if (option.options) {
      const filteredOptions = option.options.filter((item) => item.value !== '' && item.label.toLowerCase().match(searchTerm.toLowerCase()));
      updatedOptions.push(...filteredOptions);
    }
  }
  return updatedOptions;
}

export function flattenSelectedOptions({ options }) {
  const updatedOptions = [];
  for (const option of options) {
    if (option.value) {
      updatedOptions.push(option);
    } else if (option.options) {
      updatedOptions.push(...option.options);
    }
  }
  return updatedOptions;
}

export function filterOptionByLabel({ option, searchText }) {
  if (option.data.label.toString().toLowerCase().includes(searchText.toLowerCase())) {
    return true;
  } else {
    return false;
  }
}
