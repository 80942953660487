import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget-breakdown.css';
import { formatBudget } from 'components/utils/budget';
import classnames from 'classnames';

export default class BudgetBreakdown extends Component {
    style = style;

    render() {
      const { budgetAllocation } = this.props;
      const months = ['Jan 21', 'Feb 21', 'Mar 21', 'Apr 21', 'May 21', 'Jun 21', 'Jul 21', 'Aug 21', 'Sep 21', 'Oct 21', 'Nov 21', 'Dec 21'];
      const defaultVal = formatBudget(1000, false);
      return (
        <div className={this.classes.content}>
          <div className={this.classes.borderWrap}>
            <div className={this.classes.header}>
              <h2 className={this.classes.title}>
                Budget Breakdown
              </h2>
              <p className={this.classes.text}>
                By default, your budget will be equally divided throughout the year. You can manually change the budget allocation, either now or later.
              </p>
            </div>
            <div className={this.classes.mainWrap}>
              {months.map((month) => (
                <div className={this.classes.mainItem} key={month}>
                  <h3 className={this.classes.itemTitle}>
                    {month}
                  </h3>
                  <div className={this.classes.inputWrap}>
                    <p className={this.classes.curency}>$</p>
                    <input
                      type="text"
                      defaultValue={defaultVal}
                      onChange={(e) => {
                        e.target.value = e.target.value ? formatBudget(Number(e.target.value.replace(/\D+/ig, '')), false) : e.target.value;
                        return e;
                      }}
                      className={classnames(budgetAllocation === 'Equal' ? this.classes.inputManual : this.classes.input)}
                      maxLength={20}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
}
