import React from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
// import style from 'styles/profile/next-button.css';

import style from 'styles/onboarding/buttons.css';

export default class SaveButton extends Component {
  style = style;

  static defaultProps = {
    style: {
      width: '128px',
      letterSpacing: 0.075,
    },
  };

  render() {
    const {
      saveValidationError, success, fail, labelClass, saveButtonText = 'Save',
    } = this.props;
    return (
      <div className={classNames(this.classes.relativeContainer, this.props.className)}>
        <Button
          type="primaryBlue"
          onClick={() => this.props.onClick()}
          className={this.classes.planButton}
          style={this.props.style}
          disabled={this.props.disabled}
        >
          {saveButtonText}
        </Button>
        <div className={this.classes.absText}>
          <div className={labelClass}>
            <label hidden={!success} style={{ color: 'green' }}>Saved successfully!</label>
            <label hidden={!fail} style={{ color: 'red' }}>Failed to save</label>
            <label hidden={!saveValidationError} style={{ color: 'red' }}>
              Channel can't be saved. Please add a budget
            </label>
          </div>
        </div>
      </div>
    );
  }
}
