import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import Component from 'components/Component';
import MultiSelect from 'components/controls/MultiSelect';
import Select from 'components/controls/Select';
import Spinner from 'components/pages/journeys/Spinner';

import { compose, removeSpecialChars } from 'components/utils/utils';
import { SELECTED_FROM_OR_TO_CAMPAIGN } from 'components/utils/campaigns';

import servicesStore from 'stores/servicesStore';

import style from 'styles/onboarding/onboarding.css';
import campaignPopupStyle from 'styles/campaigns/capmaign-popup.css';
import buttonsStyle from 'styles/onboarding/buttons.css';
import mappingsStyle from 'styles/campaigns/mappings.css';

const enhance = compose(
  inject(({
    userStore: {
      userMonthPlan: {
        UID,
      },
    },
  }) => ({
    UID,
  })),
  observer
);

const MAPPING_KEYS = {
  PERFORMANCE: 'performanceToCost',
  COST: 'costToCost',
};

class Mappings extends Component {
  style = style;

  styles = [mappingsStyle, campaignPopupStyle, buttonsStyle];

  constructor(props) {
    super(props);
    this.state = {
      campaignsOptions: [],
      selectedMappingOfFrom: null,
      selectedMappingOfTo: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getCampaignsMapping();
  }

  getMappedFromCampaigns = ({ campaignName, mappingObject = {} }) => Object.keys(mappingObject).filter((key) => removeSpecialChars(mappingObject[key]) === removeSpecialChars(campaignName));

  createOptionsObject = (options, mappingKey) => options.map((option) => ({
    value: option,
    label: option,
    mappingKey,
  }));

  clearSelecedCamaigns = () => {
    this.setState({
      selectedMappingOfFrom: null,
      selectedMappingOfTo: null,
    });
  };

  setSelectedCampaigns = () => {
    const { campaign, campaignKeyToNameMapping = {} } = this.props;
    const { name } = campaign;
    const { performanceToCost = {}, costToCost = {} } = campaignKeyToNameMapping;
    const selectedPerformanceNames = this.getMappedFromCampaigns({ campaignName: name, mappingObject: performanceToCost });
    const selectedPlatformsNames = this.getMappedFromCampaigns({ campaignName: name, mappingObject: costToCost });
    const selectedPerformanceNamesOfTo = performanceToCost[name];
    const selectedPlatformNamesOfTo = costToCost[name];
    const selectedPerformanceMappingOfFrom = this.createOptionsObject(selectedPerformanceNames, MAPPING_KEYS.PERFORMANCE);
    const selectedPlatformMappingOfFrom = this.createOptionsObject(selectedPlatformsNames, MAPPING_KEYS.COST);
    campaign.newCampaignKeyToNameMapping = {
      [MAPPING_KEYS.PERFORMANCE]: {
        [SELECTED_FROM_OR_TO_CAMPAIGN.FROM]: selectedPerformanceNames,
        [SELECTED_FROM_OR_TO_CAMPAIGN.TO]: selectedPerformanceNamesOfTo,
      },
      [MAPPING_KEYS.COST]: {
        [SELECTED_FROM_OR_TO_CAMPAIGN.FROM]: selectedPlatformsNames,
        [SELECTED_FROM_OR_TO_CAMPAIGN.TO]: selectedPlatformNamesOfTo,
      },
    };
    this.setState({
      selectedMappingOfFrom: selectedPerformanceMappingOfFrom.concat(...selectedPlatformMappingOfFrom),
      selectedMappingOfTo: selectedPerformanceNamesOfTo || selectedPlatformNamesOfTo,
    });
  };

  pushIfNotIncludes = (arr, val) => {
    if (!arr.includes(val)) {
      arr.push(val);
    }
  };

  clearOptions = (updatedCampaign, fromOrTo) => {
    if (updatedCampaign.newCampaignKeyToNameMapping[MAPPING_KEYS.COST]) {
      updatedCampaign.newCampaignKeyToNameMapping[MAPPING_KEYS.COST][fromOrTo] = [];
    }
    if (updatedCampaign.newCampaignKeyToNameMapping[MAPPING_KEYS.PERFORMANCE]) {
      updatedCampaign.newCampaignKeyToNameMapping[MAPPING_KEYS.PERFORMANCE][fromOrTo] = [];
    }
  };

  handleNewOption = (newOption, updatedCampaign, fromOrTo, mappingKeyParam) => {
    if (newOption) {
      const { value } = newOption;
      const mappingKey = mappingKeyParam || newOption.mappingKey;
      if (!updatedCampaign.newCampaignKeyToNameMapping[mappingKey]) {
        updatedCampaign.newCampaignKeyToNameMapping[mappingKey] = {};
      }
      if (!updatedCampaign.newCampaignKeyToNameMapping[mappingKey][fromOrTo]) {
        updatedCampaign.newCampaignKeyToNameMapping[mappingKey][fromOrTo] = [];
      }
      this.pushIfNotIncludes(updatedCampaign.newCampaignKeyToNameMapping[mappingKey][fromOrTo], value);
    } else {
      this.clearOptions(updatedCampaign, fromOrTo);
    }
  };

  onMappingOptionSelect = (newOptions, fromOrTo, mappingKey) => {
    const { campaign: updatedCampaign } = this.props;
    if (!updatedCampaign.newCampaignKeyToNameMapping) {
      updatedCampaign.newCampaignKeyToNameMapping = {};
    }
    if (fromOrTo === SELECTED_FROM_OR_TO_CAMPAIGN.FROM) {
      if (newOptions.length) {
        updatedCampaign.newCampaignKeyToNameMapping[newOptions[0].mappingKey] = {};
        newOptions.forEach((option) => {
          this.handleNewOption(option, updatedCampaign, SELECTED_FROM_OR_TO_CAMPAIGN.FROM);
        });
      } else {
        this.clearOptions(updatedCampaign, SELECTED_FROM_OR_TO_CAMPAIGN.FROM);
      }
      this.setState({ selectedMappingOfFrom: newOptions });
    } else {
      this.handleNewOption(newOptions, updatedCampaign, SELECTED_FROM_OR_TO_CAMPAIGN.TO, mappingKey);
      this.setState({ selectedMappingOfTo: newOptions });
    }
    this.props.updateState({ campaign: updatedCampaign });
  };

  getMappingRow = (options, selectedMapped, label, fromOrTo, mappingKey) => {
    const { name } = this.props.campaign;
    const CustomSelect = fromOrTo === SELECTED_FROM_OR_TO_CAMPAIGN.FROM ? MultiSelect : Select;
    const tipText = fromOrTo === SELECTED_FROM_OR_TO_CAMPAIGN.FROM
      ? `Pull all costs and performance from the selected campaign/s into this campaign - ${name} (on top of the existing costs and performance)`
      : `Push all costs and performance from this campaign (${name}) to the selected campaign`;
    return (
      <div className={this.classes.row}>
        <div className={this.classes.cols}>
          <div className={this.classes.colLeft}>
            <CustomSelect
              style={{ width: '428px', textTransform: 'capitalize' }}
              selected={selectedMapped}
              clearable
              onChange={(newOptions) => this.onMappingOptionSelect(newOptions, fromOrTo, mappingKey)}
              label={label}
              labelQuestion={['']}
              description={[tipText]}
              select={{
                menuTop: true,
                name: 'mapping',
                options,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  getCampaignsMapping = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await serverCommunication.serverRequest('GET', 'campaignMappingOptions');
      if (response.ok) {
        const responseData = await response.json();
        const parseCampaignMappingOptionsData = this.parseCampaignMappingOptions({ data: responseData });
        this.setState({ isLoading: false, campaignsOptions: parseCampaignMappingOptionsData }, () => {
          this.setSelectedCampaigns();
        });
      }
    } catch (error) {
      servicesStore.logger.error('failed to get campaignMappingOptions', {
        error,
        UID: this.props.UID,
      });
      this.setState({ isLoading: false });
    }
  };

  parseCampaignMappingOptions = ({ data }) => {
    const { cost, performance } = data;
    const updateCost = cost.map((item) => ({ label: item, value: item, mappingKey: MAPPING_KEYS.PERFORMANCE }));
    const updatePerformance = performance.map((item) => ({ label: item, value: item, mappingKey: MAPPING_KEYS.PERFORMANCE }));

    const options = [{
      label: 'Performance',
      options: updatePerformance,
    }, {
      label: 'Ads',
      options: updateCost,
    },
    ];

    return options;
  };

  render() {
    const {
      campaignsOptions, selectedMappingOfFrom, selectedMappingOfTo,
    } = this.state;
    const { unmappedCampaignsPage = false } = this.props;
    return (
      <div className={mappingsStyle.locals.container}>
        {this.state.isLoading
          ? (
            <div className={this.classes.loaderContainer}>
              <Spinner />
            </div>
          ) : (
            <>
              {this.getMappingRow(
                campaignsOptions,
                selectedMappingOfFrom,
                'Map Costs/performance from',
                SELECTED_FROM_OR_TO_CAMPAIGN.FROM,
                unmappedCampaignsPage ? MAPPING_KEYS.PERFORMANCE : null
              )}
              {this.getMappingRow(
                campaignsOptions,
                selectedMappingOfTo,
                'Map Costs/performance to',
                SELECTED_FROM_OR_TO_CAMPAIGN.TO,
                unmappedCampaignsPage ? MAPPING_KEYS.PERFORMANCE : MAPPING_KEYS.COST
              )}
              <Button type="primaryBlue" onClick={() => this.props.save(true)}>
                Save Mapping
              </Button>
            </>
          )}
      </div>
    );
  }
}

export default enhance(Mappings);
