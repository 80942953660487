import React, { useEffect } from 'react';

import Table from 'components/controls/Table';

import style from 'styles/common/cardRowsTable.css';

const styles = style.locals || {};

export default function CardRowsTable({
  data, columns, onRowClick,
}) {
  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  return (
    <Table
      key="cardRowsTable"
      data={data}
      columns={columns}
      headerClassName={styles.header}
      headRowClassName={styles.headerRow}
      cellClassName={styles.cell}
      bodyCustomClass={styles.customBodyClass}
      rowClassName={styles.tableRowGroup}
      tableClassName={styles.table}
      onRowClick={onRowClick}
    />
  );
}
