export function isEmailToTrack(_ref) {
  var email = _ref.email,
    domainsToFilter = _ref.domainsToFilter;
  if (!email) {
    return false;
  }
  var emailSplit = email.split('@');
  var emailDomain = emailSplit[emailSplit.length - 1].toLowerCase();
  var shouldFilterDomain = domainsToFilter.includes(emailDomain);
  return !shouldFilterDomain;
}