export const salesforce = 'salesforce';
export const hubspot = 'hubspot';

export const CRMPlatformsNickname = {
  [hubspot]: 'HubSpot',
  [salesforce]: 'Salesforce',
};

export const funnelTransitionsModels = {
  boomerang: 'boomerang',
  firstValue: 'firstValue',
  lastValue: 'lastValue',
};

export const invalidReportUrlErrorMessage = 'Please enter a valid SFDC report link, or contact your CSM for assistance';

export const emptyFunnelMappingRule = {
  objectType: null,
  field: null,
  values: [],
  dateField: null,
  fallbackDateField: null,
};
