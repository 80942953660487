import Component from 'components/Component';
import ReactSelect from 'react-select-plus';
import classnames from 'classnames';
import React from 'react';

import style from 'styles/controls/chart-select.css';

export default class ChartSelect extends Component {
    style = style;

    render() {
      const { updateIndicator, indicators, activeIndicator } = this.props;
      return (
        <ReactSelect
          clearable={false}
          value=""
          onChange={updateIndicator}
          options={indicators}
          inputRenderer={() => (
            <div className={this.classes.input}>
              {activeIndicator}
            </div>
          )}
          arrowRenderer={({ isOpen }) => (
            <div className={this.classes.arrowWrapper}>
              <div style={{ marginLeft: 100 }}>
                <i
                  className={classnames(this.classes.icon, {
                    [this.classes.iconDown]: isOpen,
                  })}
                  data-icon="plan:monthNavigation"
                />
              </div>
            </div>
          )}
          style={{
            border: '1px solid #fff',
            width: 1,
            height: 1,
            fontSize: 1,
          }}
        />
      );
    }
}
