import React, { useState, useEffect } from 'react';
import { getColor, hexToRgb } from 'components/utils/colors';
import classnames from 'classnames';

import { formatNumber } from 'components/utils/budget';
import Tooltip from 'components/controls/Tooltip';
import TrendMark from 'components/pages/analyze/TrendMark';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';

import style from 'styles/analyze/generated-impact.css';

const styles = style.locals;

export default function GeneratedImpactChartLegendItem({
  keyIndex, onMouseEnter, onMouseLeave, activeIndex, total, valuesFormatter, nameFormat, item,
  prevValue, getNavigationMenuParams,
}) {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);

  return (
    <div
      className={styles.impactChartLegendItemRow}
      onMouseEnter={() => onMouseEnter(keyIndex)}
      onMouseLeave={onMouseLeave}
      onClick={getNavigationMenuParams ? () => setShowNavigationMenuPopup(true) : null}
    >
      <div className={styles.impactChartLegendItem}>
        <div
          className={styles.impactChartLegendItemThumbnail}
          style={{
            backgroundColor: getColor(keyIndex),
            boxShadow: activeIndex === keyIndex
              ? `0 4px 16px 0 rgba(${hexToRgb(getColor(keyIndex)).join(',')}, 0.3)`
              : 'none',
          }}
        />
        <div
          className={classnames(
            styles.impactChartLegendItemLabel,
            { [styles.active]: activeIndex === keyIndex || showNavigationMenuPopup, },
            getNavigationMenuParams ? styles.getNavigationMenuParamsLabel : null,
          )}
        >
          {`${nameFormat(item.name)} — ${formatNumber(valuesFormatter(item.value))} `}
          {Boolean(total) && (
            <Tooltip
              style={{
                display: 'inline', fontSize: '14px', fontWeight: '600', width: '30px',
              }}
              tip={valuesFormatter(item.value)}
              id="generatedImpact-id"
            >
              <span>
                {`(${Math.round(item.value / total * 100)}%)`}
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <TrendMark number={item.value} prevNumber={prevValue} />

      {getNavigationMenuParams && showNavigationMenuPopup && (
        <div className={styles.navigationMenu}>
          <NavigationMenuPopup
            navigationOptions={getNavigationMenuParams}
            onClosePopup={() => setShowNavigationMenuPopup(false)}
          />
        </div>
      )}
    </div>
  );
}
