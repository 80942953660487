import React from 'react';
import Component from 'components/Component';
import style from 'styles/integrations/integration.css';
import pageStyle from 'styles/page.css';

export default class PlatformsIntegration extends Component {
  style = style;

  styles = [pageStyle];

  render() {
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        ...this.props,
      }));
    return (
      <div>
        {childrenWithProps}
      </div>
    );
  }
}
