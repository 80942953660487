import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import style from 'styles/analyze/conversion-journey.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import Journey from 'components/pages/analyze/Journey';

export default class CampaignJourney extends Component {
  style = style;
  styles = [analyzeStyle];

  static propTypes = {
    conversionIndicator: PropTypes.string.isRequired,
  };

  render() {
    const { conversionIndicator, journeysData, journeysSum, widgetConfig } = this.props;

    return (
      <Journey journeysSum={journeysSum}
               conversionIndicator={conversionIndicator}
               journeysData={journeysData}
               header={'campaign to campaign'}
               widgetConfig={widgetConfig}
      />
    );
  }
}
