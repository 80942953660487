import React from 'react';
import classnames from 'classnames';
import Component from 'components/Component';
import _Popup from 'components/Popup';
import style from 'styles/common/popup-general.css';

export default class GeneralPopup extends Component {
  style = style;

  static defaultProps = {
    onClose: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.defaultVisible || false,
    };
  }

  open = () => this.setState({ visible: true });

  close = () => this.setState({ visible: false });

  toggle = () => this.setState({ visible: !this.state.visible });

  onClose = () => {
    this.close();
    this.props.onClose();
  };

  render() {
    const {
      children,
      popupClass,
      ...popupProps
    } = this.props;

    return (
      <_Popup
        {...popupProps}
        onClose={this.onClose}
        className={classnames(this.classes.popupWrapper, popupClass)}
        hidden={!this.state.visible}
      >
        {children}
      </_Popup>
    );
  }
}
