import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import classnames from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';
import JourneyLegend from 'components/widgets/optimalJourney/journeyCanvas/JourneyLegend';
import CanvasSection from 'components/widgets/optimalJourney/journeyCanvas/CanvasSection';
import CanvasPin from 'components/widgets/optimalJourney/journeyCanvas/CanvasPin';

import { getDisplaySignByMetricTypeOrDisplayType } from 'components/pages/settings/workflows/logic/compareEnrichedMetrics';
import { questionsTypesToMetricTypeMapping, stageVelocity } from 'components/widgets/optimalJourney/enums';
import {
  getTooltipData,
  segmentTooltipMock,
} from 'components/widgets/optimalJourney/journeyCanvas/enums';
import { convertDays } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { getFunnelPills } from 'components/widgets/optimalJourney/journeyCanvas/logic/journeyCanvas';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

function JourneyCanvas({
  canvasData,
  selectedBuildingBlocksStatuses,
  funnelStages,
  journeyQuestion,
  flags,
}) {
  useStyles([style]);
  const [journeySectionsValues, setJourneySectionsValues] = useState([]);

  useEffect(() => {
    if (canvasData) {
      setJourneySectionsValues(Object.keys(canvasData));
    }
  }, [canvasData]);

  function getVelocityDisplayByValue({ value }) {
    if (!value) {
      return null;
    }

    return convertDays({ days: value });
  }

  return (
    <>
      <div className={styles.container}>
        {journeySectionsValues?.map((value, index) => {
          const isFirstSection = index === 0;
          const metricType = questionsTypesToMetricTypeMapping[journeyQuestion.kind];
          const metricDisplaySign = getDisplaySignByMetricTypeOrDisplayType({ metricType });
          const journeySection = funnelStages[value];
          let previousJourneySection = {};

          if (!isFirstSection) {
            previousJourneySection = funnelStages[journeySectionsValues[index - 1]];
          }

          const velocityValue = selectedBuildingBlocksStatuses[stageVelocity] ? canvasData[value]?.velocity : null;
          const velocityDisplayValue = getVelocityDisplayByValue({ value: velocityValue });
          const { changeRate, kpiFocus } = getTooltipData({
            segment: 'velocity',
            isDemo: flags.optimalJourneysDemoOnly,
          });
          const velocityTooltip = segmentTooltipMock.velocity({
            value: velocityDisplayValue,
            firstFunnelStage: previousJourneySection?.topLabel,
            secondFunnelStage: journeySection?.topLabel,
            kpiFocus,
            changeRate,
          });
          const funnelPills = getFunnelPills({
            canvasData,
            selectedBuildingBlocksStatuses,
            stageValue: value,
            measurementUnit: metricDisplaySign,
          });

          return (
            <CanvasSection
              key={value}
              topLabel={journeySection?.topLabel}
              bottomLabel={journeySection?.bottomLabel}
              isFirstSection={isFirstSection}
            >
              {isFirstSection ? null
                : (
                  <div className={styles.sectionContent}>
                    <div className={styles.velocityWrapper}>
                      {velocityDisplayValue ? (
                        <div className={styles.velocityContainer}>
                          <div className={styles.arrow} />
                          <Tooltip
                            id="velocity-tooltip"
                            tip={ReactDOMServer.renderToStaticMarkup(velocityTooltip)}
                            html
                            TooltipProps={{
                              multiline: true,
                              clickable: true,
                              event: 'click',
                              globalEventOff: 'click',
                              className: classnames(styles.clickableTooltip, styles.tooltip),
                            }}
                          >
                            <div className={styles.velocityLabel}>{velocityDisplayValue}</div>
                          </Tooltip>
                          <div className={classnames(styles.arrow, styles.flip)} />
                        </div>
                      ) : null}
                    </div>
                    <CanvasPin
                      pills={funnelPills}
                      currentJourneySection={funnelStages[value]}
                      previousJourneySection={previousJourneySection}
                    />
                  </div>
                )}
            </CanvasSection>
          );
        })}
        <div className={styles.xAxis} />
      </div>
      <JourneyLegend selectedBuildingBlocksNames={Object.keys(selectedBuildingBlocksStatuses)} />
    </>
  );
}

export default withLDConsumer()(JourneyCanvas);
