import { cloneDeep } from 'lodash';
import { VARIANTS, EQUALS } from '../../../../../utils/filters/logic';

export const parseActiveFilter = (activeFilter) => {
  const updatedActiveFilter = cloneDeep(activeFilter);
  for (const rules of updatedActiveFilter.rules) {
    for (const rule of rules) {
      if (rule.variant === VARIANTS.DONT_INCLUDE) {
        rule.comparisonOperator = `NOT_${rule.comparisonOperator}`;
      }
    }
  }
  return updatedActiveFilter;
};

export const getFirstCostObject = (filtersConfig) => {
  if (Object.values(filtersConfig).length === 0) {
    return '';
  }
  const firstCostObject = Object.values(filtersConfig)[0];
  return Object.keys(firstCostObject)[0];
};

export const getEmptyRuleFields = (filtersConfig) => ({
  costObject: getFirstCostObject(filtersConfig),
  fieldName: null,
  selectedOptions: [],
  comparisonOperator: EQUALS,
});
