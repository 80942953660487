import React from 'react';
import classnames from 'classnames';

import Component from 'components/Component';
import Tooltip from 'components/controls/Tooltip';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { isGoalOnTrack } from 'components/utils/goals';

import style from 'styles/controls/goal-icon.css';

export default class GoalIcon extends Component {
  style = style;

  render() {
    const {
      isDirectionUp = true,
      usePacingForGoal = false,
      project,
      target,
      value,
      indicator,
      containerClass,
      isHistory,
      id,
      metricType,
    } = this.props;

    const indicatorText = `${formatIndicatorDisplay(indicator, project, true, false)}`;
    const progressText = `${Math.round((project / target) * 100)}% of target`;
    const pacingTip = `${usePacingForGoal ? `Pacing for: ${indicatorText} (${progressText})` : ''}`;

    const iconData = {
      className: this.classes.notAlignedIcon,
      tip: isHistory ? 'Unreached' : `You’re off-track to reach your goal. ${pacingTip}`,
    };

    if ((isDirectionUp && target <= value) || (!isDirectionUp && target >= value)) {
      iconData.className = this.classes.reachedIcon;
      iconData.tip = 'Goal had been reached';
    } else if (usePacingForGoal && isGoalOnTrack({ metricType, project, target })) {
      iconData.className = this.classes.alignedIcon;
      iconData.tip = `You’re on-track to reach your goal. ${pacingTip}`;
    }

    return (
      <Tooltip
        className={classnames(iconData.className, containerClass)}
        tip={iconData.tip}
        id={id || 'objective-tooltip'}
      />
    );
  }
}
