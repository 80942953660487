export const unmappedPopupSteps = {
  selectChannel: 0,
  selectRules: 1,
};

export const unmappedLocalStorageKeys = {
  shouldIncludePredictedChannel: 'unmapped-shouldIncludePredictedChannel',
};

export const attributionWeightValues = {
  noCredit: 0,
  lowCredit: 0.1,
  mediumCredit: 0.3,
  fullCredit: 1,
};

export const attributionWeightOptions = [{
  label: 'No Credit',
  value: attributionWeightValues.noCredit,
}, {
  label: 'Low Credit',
  value: attributionWeightValues.lowCredit,
}, {
  label: 'Medium Credit',
  value: attributionWeightValues.mediumCredit,
}, {
  label: 'Full Credit',
  value: attributionWeightValues.fullCredit,
}];

export const rangeWeightTooltipMessages = 'Max weight is defined by the channel’s weight';
