import moment from 'moment';
import { get } from 'lodash';

import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';

import userStore from 'stores/userStore';

import { activityStatusValues, timeframeOptions } from './enums';

export function getSelectedDates({ startDate, endDate }) {
  const timezone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);

  const areYearsDifferent = moment(endDate).tz(timezone).format('YYYY') !== moment(startDate).tz(timezone).format('YYYY');
  const formatType = areYearsDifferent ? 'MMM D YYYY' : 'MMM D';

  return `${moment(startDate).tz(timezone).format(formatType)} - ${moment(endDate).tz(timezone).format(formatType)}`;
}

export function getTimeframeOptionsByActivityStatus({ status }) {
  if (status === activityStatusValues.ALL) {
    return timeframeOptions;
  }

  if (status === activityStatusValues.ACTIVE) {
    return timeframeOptions.map((option) => ({ ...option, disabled: option.value.startsWith('prev') }));
  }

  return timeframeOptions.map((option) => ({ ...option, disabled: !option.value.startsWith('prev') }));
}
