exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".searchableDropdownPopup__displayedValue-215 .searchableDropdownPopup__displayedLabel-2Af{color:#222a41;font-weight:500}.searchableDropdownPopup__displayedValue-215 .searchableDropdownPopup__dropdownIndicator-3z5{margin-left:4px;border-top:5px solid #222a41;border-left:4px solid transparent;border-right:4px solid transparent}.searchableDropdownPopup__displayedValue-215 .searchableDropdownPopup__dropdownIndicator-3z5.searchableDropdownPopup__isShowPopup-2S-{transform:rotate(180deg)}.searchableDropdownPopup__displayedValue-215 .searchableDropdownPopup__displayedDropdownLabel-2la{display:flex;gap:8px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.searchableDropdownPopup__displayedValue-215 .searchableDropdownPopup__boldDropdownLabel-eQD{font-weight:600}.searchableDropdownPopup__dropdownAsLinkPopup-2IX{top:32px;padding:12px}.searchableDropdownPopup__dropdownPopup-DeZ{top:50px;padding:12px;min-width:220px}.searchableDropdownPopup__dropdownMenu-2b-{margin:0;border:none;box-shadow:none;position:relative}.searchableDropdownPopup__dropdownMenu-2b->div{margin:0}.searchableDropdownPopup__disabled-Nxc>div{background:var(--lightGrayBackground);color:var(--grayText)}", ""]);

// exports
exports.locals = {
	"displayedValue": "searchableDropdownPopup__displayedValue-215",
	"displayedValue": "searchableDropdownPopup__displayedValue-215",
	"displayedLabel": "searchableDropdownPopup__displayedLabel-2Af",
	"displayedLabel": "searchableDropdownPopup__displayedLabel-2Af",
	"dropdownIndicator": "searchableDropdownPopup__dropdownIndicator-3z5",
	"dropdownIndicator": "searchableDropdownPopup__dropdownIndicator-3z5",
	"isShowPopup": "searchableDropdownPopup__isShowPopup-2S-",
	"isShowPopup": "searchableDropdownPopup__isShowPopup-2S-",
	"displayedDropdownLabel": "searchableDropdownPopup__displayedDropdownLabel-2la",
	"displayedDropdownLabel": "searchableDropdownPopup__displayedDropdownLabel-2la",
	"boldDropdownLabel": "searchableDropdownPopup__boldDropdownLabel-eQD",
	"boldDropdownLabel": "searchableDropdownPopup__boldDropdownLabel-eQD",
	"dropdownAsLinkPopup": "searchableDropdownPopup__dropdownAsLinkPopup-2IX",
	"dropdownAsLinkPopup": "searchableDropdownPopup__dropdownAsLinkPopup-2IX",
	"dropdownPopup": "searchableDropdownPopup__dropdownPopup-DeZ",
	"dropdownPopup": "searchableDropdownPopup__dropdownPopup-DeZ",
	"dropdownMenu": "searchableDropdownPopup__dropdownMenu-2b-",
	"dropdownMenu": "searchableDropdownPopup__dropdownMenu-2b-",
	"disabled": "searchableDropdownPopup__disabled-Nxc",
	"disabled": "searchableDropdownPopup__disabled-Nxc"
};