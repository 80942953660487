import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import PopupComponent from 'components/Popup';
import Tooltip from 'components/controls/Tooltip';

import style from 'styles/plan/popup.css';

export default class Popup extends Component {
  style = style;

  static defaultProps = {
    onClose: () => {},
  };

  /* eslint-disable react/require-default-props */
  static propTypes = {
    title: PropTypes.string,
    titleTooltip: PropTypes.string,
    className: PropTypes.string,
    onClose: PropTypes.func,
    primaryButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    secondaryButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  };
  /* eslint-enable react/require-default-props */

  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.defaultVisible || false,
    };
  }

  open = () => {
    this.setState({
      visible: true,
    });
  };

  close = () => {
    this.setState({
      visible: false,
    });
  };

  toggle = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose();
    this.close();
  };

  render() {
    const {
      title,
      titleTooltip,
      className,
      primaryButton,
      secondaryButton,
      children,
      ...popupProps
    } = this.props;

    return (
      <PopupComponent
        {...popupProps}
        onClose={null}
        className={classnames(this.classes.planPopup, className)}
        hidden={!this.state.visible}
      >
        <div className={this.classes.header}>
          <div className={this.classes.title}>
            {titleTooltip ? (
              <Tooltip
                tip={titleTooltip}
                id="plan-popup-tooltip"
                place="top"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {title}
                {' '}
                <span className={this.classes.tooltipIcon} />
              </Tooltip>
            ) : title}
          </div>
          {/* eslint-disable jsx-a11y/control-has-associated-label */}
          <div
            className={this.classes.close}
            role="button"
            onClick={this.onClose}
            hidden={this.props.hideClose}
          />
          {/* eslint-enable jsx-a11y/control-has-associated-label */}
        </div>
        <div hidden={!this.props.hideClose}>
          <Button
            className={this.classes.hide}
            type="secondaryWhite"
            onClick={() => this.onClose()}
          >
            Hide
          </Button>
        </div>
        <div className={this.classes.content}>
          {children}
        </div>
        {(primaryButton || secondaryButton) ? (
          <div className={this.classes.footer}>
            {secondaryButton ? (
              <button
                type="button"
                className={this.classes.secondaryButton}
                onClick={secondaryButton.onClick}
                data-testid={secondaryButton.dataTestId}
              >
                {secondaryButton.text}
              </button>
            ) : null}
            {primaryButton ? (
              <button
                type="button"
                className={this.classes.primaryButton}
                onClick={primaryButton.onClick}
                data-testid={primaryButton.dataTestId}
              >
                {primaryButton.text}
              </button>
            ) : null}
          </div>
        ) : null}
      </PopupComponent>
    );
  }
}

export class TextContent extends Component {
  style = style;

  render() {
    return (
      <div className={this.classes.textContent}>
        {this.props.children}
      </div>
    );
  }
}
