import serverCommunication from 'data/serverCommunication';

export function isPopupMode() {
  return localStorage.getItem('popup') == "true";
}

export function temporaryEnablePopupMode() {
  return localStorage.setItem('popup', true);
}

export function disablePopupMode() {
  return new Promise((resolve, reject) => {
    serverCommunication.serverRequest('PUT', 'useraccount', JSON.stringify({onboarding: false}))
      .then((response) => {
        response.json()
          .then(function (data) {
            if (data){
              localStorage.setItem('popup', false);
              resolve(false);
            }
          });
      })
      .catch(function (err) {
        reject(err);
      });
  });
}

export function checkIfPopup(profile) {
  return serverCommunication.serverRequest('GET', 'useraccount')
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        localStorage.setItem('popup', data.onboarding);
        return [profile, data.onboarding];
      } else {
        localStorage.setItem('popup', true);
        return [profile, null];
      }
    });
}
