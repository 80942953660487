export const getDifferencesBetweenSaveAndSelectedFileds = (savedFields, selectedFields) => {
  const fieldsThatGotChanged = [];
  for (const savedField of savedFields) {
    const isFieldExists = selectedFields.some((item) => item.value === savedField.value);
    if (!isFieldExists) {
      fieldsThatGotChanged.push({ label: savedField.label, name: savedField.value, selected: false });
    }
  }
  for (const selectedField of selectedFields) {
    const isFieldExists = savedFields.some((item) => item.value === selectedField.value);
    if (!isFieldExists) {
      fieldsThatGotChanged.push({ label: selectedField.label, name: selectedField.value, selected: true });
    }
  }
  return fieldsThatGotChanged;
};

export const parseCampaignsFieldsOptions = (responseData) => {
  const campaignsOptions = responseData.map((item) => ({
    label: item.label,
    value: item.name,
    isDisabled: item.required,
    selected: item.required ? false : item.selected,
    required: item.required,
    tooltip: item.required ? 'This field is automatically pulled from your SFDC Campaigns.' : null,
  }));
  return campaignsOptions;
};
