import { TIMEFRAME_VALUES, TIMEFRAME_LABELS } from 'components/utils/timeframe';

export const timeFrameOptions = [
  { label: TIMEFRAME_LABELS.FULL_QUARTER, value: TIMEFRAME_VALUES.FULL_QUARTER },
  { label: TIMEFRAME_LABELS.FULL_YEAR, value: TIMEFRAME_VALUES.FULL_YEAR },
  { label: TIMEFRAME_LABELS.NEXT_QUARTER, value: TIMEFRAME_VALUES.NEXT_QUARTER },
  { label: TIMEFRAME_LABELS.NEXT_YEAR, value: TIMEFRAME_VALUES.NEXT_YEAR },
];

export const baselineScenario = 'baseline';
export const predictedBaselineScenario = 'predictedBaseline';

export const scenarioLabels = {
  [baselineScenario]: 'Baseline',
  [predictedBaselineScenario]: 'Predicted Baseline',
};

export const budgetChangeOperatorsOptions = [
  { label: 'Increase', value: 'increase' },
  { label: 'Decrease', value: 'decrease' },
];

export const defaultScenario = [{
  id: baselineScenario,
  label: scenarioLabels[baselineScenario],
  rules: [{}],
}];

export const defaultSettings = {
  sampleTimeframeMonths: '12',
  kpiFocus: 'pipeline',
};

export const confirmPopupTypes = {
  sampleTimeframeMonths: 'sampleTimeframeMonths',
  kpiFocus: 'kpiFocus',
};

export const confirmPopupLabels = {
  [confirmPopupTypes.sampleTimeframeMonths]: 'sample period',
  [confirmPopupTypes.kpiFocus]: 'Predicted KPI',
};

export const amountOfFirstFunnelsToRemove = 2;

export const unclickableItemsKeys = {
  baselineConversionRate: 'baselineConversionRate',
  channelsConversionRate: 'channelsConversionRate',
  sampleTimeframe: 'sampleTimeframe',
  supportedTimeframeThisQuarter: 'supportedTimeframeThisQuarter',
  supportedTimeframeNextQuarter: 'supportedTimeframeNextQuarter',
  supportedKpiOpps: 'supportedKpiOpps',
  supportedKpiRevenue: 'supportedKpiRevenue',
  budgetScenario: 'budgetScenario',
  addChange: 'addChange',
};

export const avgMonthlySpendTooltips = {
  default: 'InfiniGrow uses your average monthly spend to estimate your budget, since you did not input it under the Plan section. For more accurate forecasts, enter your budget in the Plan section.',
  noAvgMonthlySpend: 'The selected channel has no historical costs. As a result, InfiniGrow can’t forecast its impact.',
};
