import {
  useEffect,
} from 'react';

const useStyles = (styles = [], actions = []) => {
  useEffect(() => {
    styles.forEach((style) => {
      style.use();
    });

    actions.forEach((action) => {
      if (typeof action === 'function') {
        action();
      }
    });

    return (() => {
      styles.forEach((style) => {
        if (style.unuse) {
          style.unuse();
        }
      });
    });
  }, []);
};

export default useStyles;
