import React, { useEffect, useState } from 'react';

import GoalPopup from 'components/pages/settings/goals/popups/GoalPopup';
import { activityStatusValues } from 'components/pages/settings/goals/logic/enums';

import style from 'styles/insightsPage/goals/addGoalStats.css';

const styles = style.locals;

export default function AddGoalStats({
  onStartCreateNewGoal, onFinishCreateNewGoal, goalsList, goalsAnalysisRequestId,
}) {
  const [showGoalPopup, setShowGoalPopup] = useState(false);

  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  return (
    <>
      <GoalPopup
        status={activityStatusValues.ALL}
        hidden={!showGoalPopup}
        editMode={false}
        close={() => setShowGoalPopup(false)}
        onFinishCreateNewGoal={onFinishCreateNewGoal}
        onStartCreateNewGoal={onStartCreateNewGoal}
        goalsList={goalsList}
        goalsAnalysisRequestId={goalsAnalysisRequestId}
      />
      <div className={styles.wrapper} onClick={() => setShowGoalPopup(true)}>
        <div className={styles.icon} />
        <div className={styles.text}>Add goal</div>
      </div>
    </>
  );
}
