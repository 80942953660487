import React, { useState } from 'react';
import classnames from 'classnames';

import EditPopup from 'components/pages/users/Filters/FilterPanel/UI/EditPopup';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

function SaveFilterItem({
  item, index, savedFiltersList, handleFilterApply, popupDeleteHandler, popupEditHandler,
}) {
  const [isDeleteItemShow, setIsDeleteItemShow] = useState(false);
  const [isRenameItemShow, setIsRenameItemShow] = useState(false);
  const [isRenameExist, setIsRenameExist] = useState(false);
  const [itemRename, setItemRename] = useState(item.alias);

  const onCancel = () => {
    setIsDeleteItemShow(false);
    setIsRenameItemShow(false);
  };

  const onDelete = () => {
    setIsDeleteItemShow(true);
  };

  const onDeleteConfirm = () => {
    popupDeleteHandler(item.alias);
    onCancel();
  };

  const onEdit = () => {
    setIsRenameItemShow(true);
  };

  const onRenameHandler = ({ target: { value } }) => {
    const isFilterFound = savedFiltersList.some((savedfilter) => savedfilter.alias === value);
    const isDisabled = isFilterFound || value === '';
    setIsRenameExist(isDisabled);
    setItemRename(value);
  };

  const onRenameConfirm = () => {
    popupEditHandler(index, savedFiltersList[index].alias, itemRename);
    onCancel();
  };

  const onCancelRename = () => {
    setItemRename(savedFiltersList[index].alias);
    onCancel();
  };

  return (
    <>
      {isRenameItemShow
        && (
        <div className={styles.savedViewRenameItem}>
          <input type="text" value={itemRename} className={styles.savedViewRenameInput} onChange={onRenameHandler} />
          <button
            disabled={isRenameExist}
            type="button"
            onClick={onRenameConfirm}
            className={classnames(styles.savedViewBtn, styles.savedViewBtnFull, styles.blueButton, isRenameExist && styles.savedViewBtnDisabled)}
          >
            Save
          </button>
          <button type="button" onClick={onCancelRename} className={styles.savedViewBtn}>Discard</button>
        </div>
        )}

      {isDeleteItemShow
        && (
        <div className={styles.savedViewDeleteItem}>
          <span>
            Are you sure you want to delete “
            {item.alias}
            ” view?
          </span>
          <button type="button" onClick={onDeleteConfirm} className={classnames(styles.savedViewBtn, styles.savedViewBtnFull, styles.redButton)}>Yes, remove</button>
          <button type="button" onClick={onCancel} className={styles.savedViewBtn}>No, keep view</button>
        </div>
        )}

      {!isDeleteItemShow && !isRenameItemShow
        && (
        <div className={styles.savedViewItem} key={item.alias}>
          <div>{item.alias}</div>
          <button type="button" onClick={() => handleFilterApply({ filterName: item.alias })} data-key={item.alias} className={styles.savedViewItemButton}>Apply Filter</button>
          <EditPopup onDelete={onDelete} onEdit={onEdit} index={index} item={item.alias}>...</EditPopup>
        </div>
        )}
    </>
  );
}

export default SaveFilterItem;
