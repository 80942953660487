import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Button, Checkbox } from '@infinigrow/libs';

import userStore from 'stores/userStore';
import Component from 'components/Component';
import Toggle from 'components/controls/Toggle';
import Loader from 'components/controls/Loader';
import Switch from 'components/controls/Switch';
import SearchInput from 'components/controls/SearchInput';
import UnmappedTab from 'components/pages/settings/channels/tabs/UnmappedTab';
import ChannelsSelect from 'components/common/ChannelsSelect';
import ChannelIcon from 'components/common/ChannelIcon';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';

import {
  UNMAPPED_QUERY_TYPE, getUnmappedKeyName,
} from 'components/utils/settings';
import { formatChannelKey } from 'components/utils/utils';
import { DIRECT_CHANNEL, getNickname, getChannelsWithProps } from 'components/utils/channels';
import { getRulesConditionsBySelectedTab } from 'components/pages/settings/channels/tabs/logic/UnmappedChannelsTab';
import { getChannelIcon } from 'components/utils/filters/channels';
import { unmappedLocalStorageKeys } from 'components/pages/settings/channels/tabs/logic/enums';
import { getUtmsOptionsForMapping } from 'components/utils/mappingRules';

import style from 'styles/settings/channels/unmapped-tab.css';
import dropdownStyle from 'styles/controls/dropdown.css';

const dropdownStyles = dropdownStyle.locals || {};

export default class UnmappedChannelsTab extends Component {
  style = style;

  styles = [dropdownStyle];

  constructor(props) {
    super(props);
    this.state = {
      isURLsTab: false,
      isLoading: false,
      isShowMapPopup: false,
      selectedRowsToMap: [],
      multipleMapClickRows: [],
      isAllLoaded: {
        OFFLINE: false,
        URLS: false,
        UTMS: false,
      },
      selectedChannel: null,
      mappingRulesConditions: [],
      searchInputValue: '',
      isAllCheckboxSelected: false,
      shouldIncludePredictedChannel: localStorage.getItem(unmappedLocalStorageKeys.shouldIncludePredictedChannel) || false,
      tableData: this.getTableData({ isURLsTab: false }),
    };
    this.refSearchInput = React.createRef();
    this.debounceTimeout = null;
  }

  componentDidMount() {
    userStore.getAttributionMappingRules();
    this.props.getUnmappedData({ unmappedType: UNMAPPED_QUERY_TYPE.OFFLINE, shouldIncludePredictedChannel: this.state.shouldIncludePredictedChannel });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isOnline,
      unmappedUrls,
      unmappedUtms,
      unmappedOffline,
      getUnmappedData,
    } = this.props;

    const {
      isOnline: prevOnline,
      unmappedUrls: prevUnmappedUrls,
      unmappedUtms: prevUnmappedUtms,
      unmappedOffline: prevUnmappedOffline,
    } = prevProps;

    const {
      isURLsTab,
      isAllLoaded,
      shouldIncludePredictedChannel,
    } = this.state;

    const {
      isURLsTab: prevIsURLsTab,
    } = prevState;

    const hasIsOnlineChanged = prevOnline !== isOnline;
    const hasIsURLsTabChanged = prevIsURLsTab !== isURLsTab;
    const hasUnmappedUrlsChanged = !isEqual(prevUnmappedUrls, unmappedUrls);
    const hasUnmappedUtmsChanged = !isEqual(prevUnmappedUtms, unmappedUtms);
    const hasUnmappedOfflineChanged = !isEqual(prevUnmappedOffline, unmappedOffline);

    if (hasIsOnlineChanged && !isOnline && !unmappedOffline) {
      this.setState({ isLoading: true }, async () => {
        await getUnmappedData({ unmappedType: UNMAPPED_QUERY_TYPE.OFFLINE, shouldIncludePredictedChannel });
        isAllLoaded.OFFLINE = true;
        this.setState({ isLoading: false, isAllLoaded });
      });
    }

    const isShouldRecalculateTableData = hasIsOnlineChanged || hasUnmappedUrlsChanged || hasUnmappedUtmsChanged || hasUnmappedOfflineChanged || hasIsURLsTabChanged;

    if (isShouldRecalculateTableData) {
      this.setState((previousState) => ({
        tableData: this.getTableData({ isURLsTab: previousState.isURLsTab }),
      }));
    }
  }

  getTableData({ isURLsTab }) {
    const {
      isOnline,
      unmappedUrls,
      unmappedUtms,
      unmappedOffline,
    } = this.props;

    let tableData = unmappedOffline || [];
    if (isOnline) {
      if (isURLsTab) {
        tableData = unmappedUrls || [];
      } else {
        tableData = unmappedUtms || [];
      }
    }

    return tableData;
  }

  handleChangeChannel(selectedChannel) {
    this.setState({ selectedChannel });
  }

  onToggleMapWithAI() {
    this.setState((prevState) => {
      const shouldIncludePredictedChannel = !prevState.shouldIncludePredictedChannel;
      localStorage.setItem(unmappedLocalStorageKeys.shouldIncludePredictedChannel, shouldIncludePredictedChannel);

      return {
        isLoading: true,
        shouldIncludePredictedChannel,
        selectedRowsToMap: [],
        isAllCheckboxSelected: false,
        multipleMapClickRows: [],
      };
    }, async () => {
      const dataType = this.getDataTypes();

      await this.props.getUnmappedData({
        unmappedType: dataType,
        shouldIncludePredictedChannel: this.state.shouldIncludePredictedChannel,
        searchValue: this.state.searchInputValue,
      });

      this.setState({ isLoading: false });
    });
  }

  onClickSelectAll({ tableData, isSelectAll }) {
    const updateSelectedRowsToMap = [];
    if (isSelectAll) {
      for (const [index, itemData] of tableData.entries()) {
        updateSelectedRowsToMap.push({
          rowIndex: index,
          rowData: itemData,
        });
      }
    }
    this.setState({
      isAllCheckboxSelected: isSelectAll,
      selectedRowsToMap: updateSelectedRowsToMap,
    });
  }

  onCancelPopup() {
    this.setState({
      isShowMapPopup: false, selectedRowsToMap: [], mappingRulesConditions: [], selectedChannel: null,
    });
  }

  async onClickMapping(row, index) {
    const { isOnline } = this.props;
    const { isURLsTab, shouldIncludePredictedChannel, multipleMapClickRows } = this.state;
    const rulesConditions = getRulesConditionsBySelectedTab({ row, isOnline, isURLsTab });
    if (shouldIncludePredictedChannel) {
      const updateMultipleMapClickRows = [...multipleMapClickRows];
      const isRowIndexExists = updateMultipleMapClickRows.findIndex((rowToMap) => rowToMap.rowIndex === index) !== -1;
      if (!isRowIndexExists) {
        updateMultipleMapClickRows.push({
          rowIndex: index,
          rowData: row,
        });
      }
      this.setState({ multipleMapClickRows: updateMultipleMapClickRows });

      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        await this.onSaveAIMapping({ rowsData: updateMultipleMapClickRows });
        this.setState({ multipleMapClickRows: [] });
      }, 10000);
    } else {
      this.setState({
        isShowMapPopup: true,
        selectedRowsToMap: [{
          rowIndex: index,
          rowData: row,
        }],
        mappingRulesConditions: [rulesConditions],
      });
    }
  }

  onSelectRowForMapping({ row, index, tableData }) {
    this.setState((prevState) => {
      const updateSelectedRowsToMap = [...prevState.selectedRowsToMap];
      const selectedRowIndex = updateSelectedRowsToMap.findIndex((rowToMap) => rowToMap.rowIndex === index);
      if (selectedRowIndex !== -1) {
        updateSelectedRowsToMap.splice(selectedRowIndex, 1);
      } else {
        updateSelectedRowsToMap.push({
          rowIndex: index,
          rowData: row,
        });
      }
      return {
        selectedRowsToMap: updateSelectedRowsToMap,
        isAllCheckboxSelected: updateSelectedRowsToMap.length === tableData.length,
      };
    });
  }

  async onSaveAIMapping({ rowsData }) {
    const { isOnline } = this.props;
    const { isURLsTab } = this.state;
    const dataType = this.getDataTypes();
    const newRules = [];

    for (const row of rowsData) {
      const rulesConditions = getRulesConditionsBySelectedTab({ row: row.rowData, isOnline, isURLsTab });
      const channel = row.rowData.predictedChannel;
      const channelsWithProps = getChannelsWithProps() || {};
      const weight = channelsWithProps[channel]?.weight || 1;

      const newRule = {
        conditions: rulesConditions,
        channel,
        weight,
        id: uuidv4(),
      };

      newRules.push(newRule);
    }
    await userStore.updateAttributionMappingRulesRequest({ newRules });

    await this.props.getUnmappedData({
      unmappedType: dataType,
      shouldIncludePredictedChannel: this.state.shouldIncludePredictedChannel,
      searchValue: this.state.searchInputValue,
    });
  }

  async onClickBulkAIMapping() {
    this.setState({ isLoading: true });
    await this.onSaveAIMapping({ rowsData: this.state.selectedRowsToMap });
    this.setState({ isLoading: false, selectedRowsToMap: [], isAllCheckboxSelected: false });
  }

  onClickBulkMapped() {
    const updateaMappingRulesConditions = [];
    const { isOnline } = this.props;
    const { isURLsTab, selectedRowsToMap } = this.state;
    for (const row of selectedRowsToMap) {
      const rulesConditions = getRulesConditionsBySelectedTab({ row: row.rowData, isOnline, isURLsTab });
      updateaMappingRulesConditions.push(rulesConditions);
    }
    this.setState({
      isShowMapPopup: true,
      mappingRulesConditions: updateaMappingRulesConditions,
    });
  }

  onSearchData(searchValue) {
    this.setState({
      selectedChannel: null,
      selectedRowsToMap: [],
      mappingRulesConditions: [],
      isAllCheckboxSelected: false,
      searchInputValue: searchValue,
      isLoading: true,
    });
    const { getUnmappedData, isOnline } = this.props;
    const { isAllLoaded, isURLsTab, shouldIncludePredictedChannel } = this.state;
    let dataType = UNMAPPED_QUERY_TYPE.OFFLINE;
    if (isOnline) {
      if (isURLsTab) {
        dataType = UNMAPPED_QUERY_TYPE.URLS;
      } else {
        dataType = UNMAPPED_QUERY_TYPE.UTMS;
      }
    }
    this.setState({ isLoading: true }, async () => {
      await getUnmappedData({
        unmappedType: dataType, searchValue, shouldIncludePredictedChannel,
      });
      isAllLoaded.OFFLINE = true;
      this.setState({ isLoading: false, isAllLoaded });
    });
  }

  onToggleIsOnline(value) {
    this.resetSelectedChannel();
    this.props.setIsOnline(value);
  }

  getTableColumns({ tableData }) {
    const {
      isOnline,
      userAccount,
    } = this.props;
    const { isURLsTab } = this.state;

    const columns = [{
      id: 'map',
      width: 60,
      fixed: 'left',
      Header: () => (
        <div className={this.classes.contentBox}>
          <Checkbox
            key="header-customCheckbox"
            checked={this.state.isAllCheckboxSelected}
            onChange={() => this.onClickSelectAll({ tableData, isSelectAll: !this.state.isAllCheckboxSelected })}
          />
        </div>
      ),
      cell: (row, { index }) => {
        const selectedRowIndex = this.state.selectedRowsToMap.findIndex((rowToMap) => rowToMap.rowIndex === index);
        const isCheckboxSelected = selectedRowIndex !== -1;
        return (
          <div>
            <div className={this.classes.contentBox}>
              <Checkbox
                key={row}
                checked={isCheckboxSelected}
                onChange={() => this.onSelectRowForMapping({ row, index, tableData })}
              />
            </div>
          </div>
        );
      },
    }];

    if (isOnline) {
      if (isURLsTab) {
        const unmappedUrlsColumns = [
          {
            id: 'referrer',
            cell: 'referrer',
            header: 'Referrer',
            sortable: true,
          },
        ];
        columns.push(...unmappedUrlsColumns);
      } else {
        const utmsOptions = getUtmsOptionsForMapping({ customUtmsWhitelist: userAccount.customUtmsWhitelist, withUtmSuffix: false });
        const unmappedUtmsColumns = [];
        for (const utm of utmsOptions) {
          unmappedUtmsColumns.push({
            id: utm.value,
            header: utm.label,
            cell: (item) => item[utm.value] || '',
            sortable: true,
          });
        }
        columns.push(...unmappedUtmsColumns);
      }
    } else {
      const unmappedLeadSourcesColumns = [];
      for (const [leadSourceId, label] of Object.entries(userAccount.leadSourcesIdToLabelMap || {})) {
        unmappedLeadSourcesColumns.push({
          id: leadSourceId,
          header: label,
          cell: (item) => item[leadSourceId] || '',
          sortable: true,
        });
      }
      columns.push(...unmappedLeadSourcesColumns);
    }

    if (this.state.shouldIncludePredictedChannel) {
      columns.push({
        id: 'predictedChannel',
        accessor: 'predictedChannel',
        header: 'Channel',
        minWidth: 200,
        cell: (item) => (
          <>
            <ChannelIcon
              channelIcon={getChannelIcon(item)}
              channel={item}
            />
            <div className={this.classes.channelEllipsisTooltip}>
              <EllipsisTooltip text={getNickname(item)} />
            </div>
          </>
        ),
        fixed: 'right',
        sortable: true,
      }, {
        id: 'probability',
        accessor: 'probability',
        header: 'Confidence score',
        cell: (item) => (
          item ? `${item}%` : ''
        ),
        fixed: 'right',
        sortable: true,
      });
    }

    columns.push(
      {
        id: 'lastActivityTime',
        header: 'Last Activity Date',
        cell: (row) => moment(row.lastActivityTime).format('ll'),
        sortable: true,
        sortMethod: (a, b) => new Date(a.lastActivityTime) - new Date(b.lastActivityTime),
      },
      {
        id: 'count',
        header: 'Count',
        accessor: 'count',
        fixed: 'right',
        sortable: true,
        width: 110,
        defaultSortDesc: true,
      },
      {
        id: 'map',
        width: 110,
        cell: (row, { index }) => {
          if (this.state.selectedRowsToMap.length > 1) {
            return null;
          }
          const selectedRowIndex = this.state.multipleMapClickRows.findIndex((rowToMap) => rowToMap.rowIndex === index);
          return (
            <>
              <div
                className={classNames(
                  this.classes.mapBtn,
                  selectedRowIndex !== -1 ? this.classes.clicked : null
                )}
                onClick={() => this.onClickMapping(row, index)}
              >
                Map
              </div>
            </>
          );
        },
        fixed: 'right',
      }
    );
    return columns;
  }

  setMappingRulesConditions(updatedMappingRulesConditions) {
    this.setState({ mappingRulesConditions: updatedMappingRulesConditions });
  }

  getDataTypes() {
    if (this.props.isOnline) {
      if (this.state.isURLsTab) {
        return UNMAPPED_QUERY_TYPE.URLS;
      }
      return UNMAPPED_QUERY_TYPE.UTMS;
    }
    return UNMAPPED_QUERY_TYPE.OFFLINE;
  }

  getAllDataLoaded() {
    const {
      isURLsTab,
      isAllLoaded,
    } = this.state;

    if (this.props.isOnline) {
      if (isURLsTab) {
        return isAllLoaded.URLS;
      }
      return isAllLoaded.UTMS;
    }
    return isAllLoaded.OFFLINE;
  }

  setFocus() {
    this.channelsSelect.focus();
  }

  addUnknownChannel(channel) {
    const channelKey = formatChannelKey(channel);
    this.props.addUnknownChannel(channelKey, channel);
    this.handleChangeChannel(channel);
  }

  urlUtmTab(value) {
    const { unmappedUrls, getUnmappedData } = this.props;
    const { isAllLoaded, shouldIncludePredictedChannel } = this.state;
    this.resetSelectedChannel();
    if (!unmappedUrls) {
      this.setState({ isLoading: true, isURLsTab: value, searchInputValue: '' }, async () => {
        await getUnmappedData({ unmappedType: UNMAPPED_QUERY_TYPE.URLS, shouldIncludePredictedChannel });
        isAllLoaded.URLS = true;
        this.setState({ isLoading: false, isAllLoaded });
      });
    } else {
      this.setState({ isURLsTab: value, searchInputValue: '' });
    }
  }

  resetSelectedChannel() {
    this.refSearchInput.current.handleSearchQueryClear();
    this.setState({
      selectedChannel: null,
      selectedRowsToMap: [],
      mappingRulesConditions: [],
      isAllCheckboxSelected: false,
      searchInputValue: '',
      shouldIncludePredictedChannel: false,
      multipleMapClickRows: [],
    });
  }

  async saveMapping(channel, rulesConditions, dataType) {
    const unmappedKey = getUnmappedKeyName(dataType);
    const unmappedResults = structuredClone(this.state.tableData);

    for (const { rowIndex } of this.state.selectedRowsToMap) {
      unmappedResults.splice(rowIndex, 1);
    }

    this.props.updateState({
      [unmappedKey]: unmappedResults,
    });

    const newRules = [];
    const channelsWithProps = getChannelsWithProps() || {};
    const weight = channelsWithProps[channel]?.weight || 1;

    for (const conditions of rulesConditions) {
      const newRule = {
        conditions,
        channel,
        weight,
        id: uuidv4(),
      };
      newRules.push(newRule);
    }

    await userStore.updateAttributionMappingRulesRequest({ newRules });
  }

  render() {
    const {
      isOnline,
      unmappedOffline,
      unmappedUrls,
      unmappedUtms,
      pageHeight,
    } = this.props;
    const {
      isLoading, isURLsTab, selectedChannel, shouldIncludePredictedChannel, tableData,
    } = this.state;

    if (!unmappedUtms || (isURLsTab && !unmappedUrls) || (!isOnline && !unmappedOffline)) {
      return (
        <Loader newStyle />
      );
    }

    const dataType = this.getDataTypes();
    const allDataLoaded = this.getAllDataLoaded();

    const containerHeight = `${pageHeight - 244}px`;
    const unmappedTableHeight = isOnline ? `${pageHeight - 470}px` : `${pageHeight - 410}px`;
    const columns = this.getTableColumns({ tableData });

    return (
      <div className={this.classes.mappingContainer} style={{ maxHeight: containerHeight }}>
        <div className={this.classes.mappingHeaderWrapper}>
          <SearchInput
            onSearch={(value) => this.onSearchData(value)}
            placeholder="Search"
            classes={{
              input: this.classes.mappingSearchInput,
            }}
            debounce={1000}
            ref={this.refSearchInput}
          />

          {this.state.selectedRowsToMap.length > 1 ? (
            <Button type="primaryBlue" className={this.classes.mappingHeaderButton} onClick={() => (shouldIncludePredictedChannel ? this.onClickBulkAIMapping() : this.onClickBulkMapped())}>
              Map touchpoints
            </Button>
          ) : null}

          <div className={classNames(this.classes.mapWithAI, shouldIncludePredictedChannel ? this.classes.mapWithAIActive : null)}>
            {'Map with AI '}
            <Switch
              isActive={shouldIncludePredictedChannel}
              onSwitchClick={() => this.onToggleMapWithAI()}
              dataTestId="mapWithAI"
            />
          </div>

          <div className={this.classes.mappingTopToggle}>
            <Toggle
              options={[
                {
                  text: 'CRM Data',
                  value: false,
                },
                {
                  text: 'Web Traffic',
                  value: true,
                },
              ]}
              selectedValue={isOnline}
              onClick={(value) => this.onToggleIsOnline(value)}
            />
          </div>
        </div>

        {isOnline ? (
          <div className={this.classes.toggleAlign}>
            <Toggle
              style={{ justifyContent: 'left' }}
              options={[{
                text: 'UTMs',
                value: false,
              }, {
                text: 'URLs',
                value: true,
              },
              ]}
              selectedValue={isURLsTab}
              onClick={(value) => this.urlUtmTab(value)}
            />
          </div>
        ) : null}

        <UnmappedTab
          isShowMapPopup={this.state.isShowMapPopup}
          selectedRowsToMap={this.state.selectedRowsToMap}
          tableData={tableData}
          columns={columns}
          dataType={dataType}
          allDataLoadedFromProp={allDataLoaded}
          mappingRulesConditions={this.state.mappingRulesConditions}
          setMappingRulesConditions={(conditions) => this.setMappingRulesConditions(conditions)}
          selectedChannelForMapping={selectedChannel}
          endpoint="getUnmappedData"
          saveMapping={(channel, rulesConditions, type) => this.saveMapping(channel, rulesConditions, type)}
          setFocus={() => this.setFocus()}
          onCancelPopup={() => this.onCancelPopup()}
          resetSelectedChannel={() => this.resetSelectedChannel()}
          unmappedType="CHANNELS"
          unmappedTableHeight={unmappedTableHeight}
          isLoaded={!isLoading}
          searchInputValue={this.state.searchInputValue}
          defaultSorted={[{ id: 'count', desc: true }, { id: 'probability', desc: true }]}
          shouldIncludePredictedChannel={this.state.shouldIncludePredictedChannel}
        >
          <div className={dropdownStyles.channelsSelectSize}>
            <ChannelsSelect
              selected={selectedChannel}
              withOtherChannels
              ref={(ref) => {
                this.channelsSelect = ref;
              }}
              onChange={(e) => this.handleChangeChannel(e.value)}
              onNewOptionClick={({ value: ch }) => this.addUnknownChannel(ch)}
              isChannelDisabled={(channel) => channel === DIRECT_CHANNEL}
            />
          </div>
        </UnmappedTab>
      </div>
    );
  }
}
