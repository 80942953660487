import { getNickname } from 'components/utils/channels';
import { legendItems, segmentTooltipMock } from 'components/widgets/optimalJourney/journeyCanvas/enums';

export function getPills({ pills, currentJourneySection, previousJourneySection }) {
  const pillsObjects = [];
  for (const pill in pills) {
    const pillSegments = pills[pill].fields;
    const currentPill = pills[pill];
    const segmentsValue = currentPill.value;
    for (const value of pillSegments) {
      const object = legendItems.find((legendItem) => Object.keys(legendItem)[0] === pill);
      pillsObjects.push({
        label: getNickname(value),
        color: object?.[pill]?.color,
        value: segmentsValue,
        measurementUnit: currentPill.measurementUnit,
        tooltip: segmentTooltipMock[pill]({
          value: getNickname(value),
          firstFunnelStage: previousJourneySection?.topLabel,
          secondFunnelStage: currentJourneySection?.topLabel,
        }),
      });
    }
  }

  return pillsObjects;
}
