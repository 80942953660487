import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { nonUpDirectionMetricTypes } from 'components/pages/settings/goals/logic/enums';

export function isTrendPositive({ metric, trendValue }) {
  const trendFormatValue = formatNumberWithDecimalPoint(trendValue, 4);
  const trendFormatValueAsNumber = parseFloat(trendFormatValue);
  const isLowerBetter = nonUpDirectionMetricTypes.some((metricType) => metric.toLowerCase().includes(metricType.toLowerCase()));
  return isLowerBetter ? trendFormatValueAsNumber <= 0 : trendFormatValueAsNumber >= 0;
}

export function checkIfChartDataIsEmpty({ chartData, keyToSkip }) {
  return !chartData.some((item) => Object.keys(item).some((key) => {
    if (key === keyToSkip) {
      return false;
    }
    const value = item[key];
    return value !== 0 && value !== undefined && value !== null;
  }));
}
