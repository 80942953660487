import React from 'react';
import { inject, observer } from 'mobx-react';

import GoalsAnalysis from 'components/pages/insights/overviewTab/goals/GoalsAnalysis';

import { getGoalTimeframe } from 'components/utils/goals';
import { pickerTimeframeToFrequencyType, activityStatusValues } from 'components/pages/settings/goals/logic/enums';
import { getTimeframeOptionsByActivityStatus } from 'components/pages/settings/goals/logic/PropertiesTab';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';

function GoalsAnalysisWrapper({
  goalsAnalysis,
  widgetHeaderConfig,
  widgetHeaderProps,
  selectedReport,
  updateGoalsAnalysisParams,
  fiscalYearFirstMonth,
  timeFrame,
  getWidgetRequestId,
  widgetConfig,
}) {
  const reportId = selectedReport.value;
  const { widgetId } = widgetHeaderConfig;

  const goalsAnalysisFullConfig = getWidgetFullConfig({
    widgetConfig,
    addBaseAnalyzeConfig: false,
  });

  const goalsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.goalsAnalysis, widgetConfig: getWidgetConfigForServerRequestId({ widget: widgetTypes.goalsAnalysis, widgetConfig: goalsAnalysisFullConfig, widgetHeaderConfig }) });
  const goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];

  const goalsSupportedTimeframe = getTimeframeOptionsByActivityStatus({ status: activityStatusValues.ALL }).map((option) => option.value);
  const isGoalsSupportSelectedTimeframe = goalsSupportedTimeframe.includes(timeFrame?.value);

  const isGoalsAnalysisFailedToLoad = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'failed';
  const isGoalAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished' || !isGoalsSupportSelectedTimeframe || isGoalsAnalysisFailedToLoad;

  function updateGoalsAnalysisTimeframe({ newTimeframe }) {
    const goalsAnalysisParams = {
      frequencyType: pickerTimeframeToFrequencyType[newTimeframe?.value],
      timeframe: getGoalTimeframe({ timeframe: newTimeframe, fiscalYearFirstMonth }),
      supportFilters: true,
    };
    updateGoalsAnalysisParams({
      goalsAnalysisParams, timeframe: newTimeframe, widgetId, reportId,
    });
  }

  return (
    <GoalsAnalysis
      isLoaded={isGoalAnalysisLoaded}
      goals={goalsAnalysisData}
      widgetHeaderConfig={widgetHeaderConfig}
      widgetHeaderProps={widgetHeaderProps}
      customTimeframeUpdate={({ newTimeframe }) => updateGoalsAnalysisTimeframe({ newTimeframe })}
      isGoalsSupportSelectedTimeframe={isGoalsSupportSelectedTimeframe}
      isHavingApplyingFilters={false}
      isShowAddAlertInPopupNavigationMenu
      isFailedToLoad={isGoalsAnalysisFailedToLoad}
      goalsAnalysisRequestId={goalsAnalysisRequestId}
    />
  );
}

export default inject(
  ({
    reportsStore: {
      selectedReport,
      updateGoalsAnalysisParams,
    },
    userStore: {
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
      },
    },
  }) => ({
    selectedReport,
    updateGoalsAnalysisParams,
    fiscalYearFirstMonth,
    goalsAnalysis,
    getWidgetRequestId,
  }),
  observer
)(GoalsAnalysisWrapper);
