import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import { ConfigPropType, FilterPropType } from 'components/utils/filters';
import filterStyles from 'styles/users/filters.css';
import FilterActions from 'components/pages/users/Filters/FilterActions';

const styles = filterStyles.locals;

export const CommonPropTypes = {
  className: PropTypes.string,
  config: ConfigPropType.isRequired,
  currentFilter: FilterPropType,
  onCancel: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
};

export default class CommonFilter extends Component {
  static propTypes = {
    ...CommonPropTypes,
    children: PropTypes.func.isRequired,
  }

  onChangeComparison = ({ value }) => {
    this.updateFilter({ selectedOptions: [] });
    this.props.changeComparisonHandler(value);
  }

  containsValueHandler = (values = []) => {
    const containsItems = values.map((item) => item.label);
    this.updateFilter({ selectedOptions: containsItems });
  }

  handleFilterApply = () => this.props.onAddFilter();

  setFilterVariant = (e) => this.updateFilter({ variant: e.value });

  addItem = (e) => {
    this.updateFilter({ selectedOptions: [...this.props.selectedOptions, e.value] });
  };

  selectOptions = (selectedOptions) => {
    const selected = selectedOptions !== null ? selectedOptions.map((opt) => opt.value) : [];
    this.updateFilter({ selectedOptions: selected });
  };

  removeItem = (item) => {
    this.updateFilter({ selectedOptions: this.props.selectedOptions.filter((i) => i !== item) });
  };

  updateFilter = (newData) => {
    const {
      config, selectedOptions, variant, comparisonOperator,
    } = this.props;
    const data = {
      selectedOptions,
      variant,
      ...newData,
    };
    const filterObj = {
      kind: config.kind,
      data: {
        variant: data.variant,
        selectedOptions: data.selectedOptions,
        fieldIndex: data.fieldIndex,
        comparisonOperator,
      },
    };
    this.props.addFilterToSelection(filterObj);
    this.props.updateFiltersItems(filterObj);
  };

  render() {
    const {
      config, onCancel, children, selectedOptions, variant,
    } = this.props;

    return (
      <div className={styles.filterConfig}>
        <div className={styles.filterContent}>
          {children({
            config,
            variant,
            selectedOptions,
            onAddItem: this.addItem,
            onRemoveItem: this.removeItem,
            onSetVariant: this.setFilterVariant,
            onSelectOptions: this.selectOptions,
            onChangeComparison: this.onChangeComparison,
            containsValueHandler: this.containsValueHandler,
          })}
        </div>
        <FilterActions
          onApply={this.handleFilterApply}
          onCancel={onCancel}
          filtersItems={this.props.filtersItems}
        />
      </div>
    );
  }
}
