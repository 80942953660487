exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".budget-header__content-3u7{display:flex;justify-content:space-between;width:100%;flex-wrap:nowrap}.budget-header__header-10a{flex:1;display:flex;flex-direction:column}.budget-header__search-Buu{flex:1;display:flex;width:100%;justify-content:flex-end;align-items:center}.budget-header__headerLabel-1Tc{font-weight:500;font-size:12px;line-height:16px;color:#99a4c2;margin-right:8px}.budget-header__headerSelectWrap-21x{padding:40px 0 22px;display:flex}.budget-header__headerSelectWrapChannel-q_e{padding:40px 0 2px;display:flex}.budget-header__select-ZBd{width:159px;height:40px;margin-top:1px;box-shadow:0 1px 2px #e6e8f0;border-radius:4px}.budget-header__select-ZBd span{color:#222a41!important;line-height:32px;border-color:#b2bbd5 transparent transparent}.budget-header__select-ZBd span [role=option]{font-weight:500;font-size:14px;color:#222a41!important;line-height:36px}.budget-header__select-ZBd>div>span>div{padding-left:16px!important}.budget-header__select-ZBd>div>span{padding-right:16px!important}.budget-header__title-1Mm{font-weight:600;font-size:24px;line-height:33px;color:#182033;margin-top:0;margin-bottom:9px;letter-spacing:-.3px}.budget-header__subtitle-3EK{font-weight:600;font-size:18px;line-height:25px;color:#182033;margin:8px 0}.budget-header__text-1qw{font-size:14px;line-height:21px;margin:0;color:#99a4c2}label.budget-header__search-label-1vj{margin:0;height:40px;border:1px solid #e6e8f0;box-shadow:inset 0 1px 3px rgba(112,126,167,.202304);border-radius:3px;min-width:226px}div.budget-header__searchIcon-1JG{width:18px;height:18px;margin-left:16px;background:url(/assets/input-search.svg) 50% no-repeat}div.budget-header__closeIcon-3Wd{width:12px;height:12px;background:url(/assets/input-close.svg) 50% no-repeat}input.budget-header__searchInput-18I{flex-grow:1}@media (max-width:600px){.budget-header__content-3u7{flex-direction:column}.budget-header__search-Buu{justify-content:center;align-items:center;padding:16px}}", ""]);

// exports
exports.locals = {
	"content": "budget-header__content-3u7",
	"content": "budget-header__content-3u7",
	"header": "budget-header__header-10a",
	"header": "budget-header__header-10a",
	"search": "budget-header__search-Buu",
	"search": "budget-header__search-Buu",
	"headerLabel": "budget-header__headerLabel-1Tc",
	"headerLabel": "budget-header__headerLabel-1Tc",
	"headerSelectWrap": "budget-header__headerSelectWrap-21x",
	"headerSelectWrap": "budget-header__headerSelectWrap-21x",
	"headerSelectWrapChannel": "budget-header__headerSelectWrapChannel-q_e",
	"headerSelectWrapChannel": "budget-header__headerSelectWrapChannel-q_e",
	"select": "budget-header__select-ZBd",
	"select": "budget-header__select-ZBd",
	"title": "budget-header__title-1Mm",
	"title": "budget-header__title-1Mm",
	"subtitle": "budget-header__subtitle-3EK",
	"subtitle": "budget-header__subtitle-3EK",
	"text": "budget-header__text-1qw",
	"text": "budget-header__text-1qw",
	"search-label": "budget-header__search-label-1vj",
	"searchLabel": "budget-header__search-label-1vj",
	"searchIcon": "budget-header__searchIcon-1JG",
	"searchIcon": "budget-header__searchIcon-1JG",
	"closeIcon": "budget-header__closeIcon-3Wd",
	"closeIcon": "budget-header__closeIcon-3Wd",
	"searchInput": "budget-header__searchInput-18I",
	"searchInput": "budget-header__searchInput-18I"
};