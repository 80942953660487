import ColorMix from 'colormix/src';
import isNaN from 'lodash/isNaN';
import Gradient from 'javascript-color-gradient';

const COLORS = [
  '#2196f3',
  '#ff5722',
  '#673ab7',
  '#00bcd4',
  '#e91e63',
  '#009688',
  '#3f51b5',
  '#ffc107',
  '#8bc34a',
  '#03a9f4',
  '#59DB95',
  '#cddc39',
  '#ff9800',
  '#795548',
  '#4caf50',
  '#ffeb3b',
  '#4e342e',
  '#707ea7',
  '#222a41',
  '#DDA0DD',
  '#EE82EE',
  '#DA70D6',
  '#FF00FF',
  '#FF00FF',
  '#BA55D3',
  '#9370DB',
  '#8A2BE2',
  '#800000',
  '#8B0000',
  '#A52A2A',
  '#B22222',
  '#DC143C',
  '#FF0000',
  '#FF6347',
  '#FF7F50',
  '#CD5C5C',
  '#F08080',
];

const BLUE = '#4D91FC';
const LIGHT_BLUE = '#D2E0FF';
const GREEN = '#59DB95';

export function getMixOfColors(midpoint) {
  const colorGradient = new Gradient();

  const color1 = BLUE;
  const color2 = GREEN;

  colorGradient.setGradient(color1, color2);
  colorGradient.setMidpoint(midpoint);

  return colorGradient.getArray();
}

export function getBlueColor() {
  return BLUE;
}

export function getLightBlueColor() {
  return LIGHT_BLUE;
}

export function getColor(index = 0) {
  return COLORS[index % COLORS.length];
}

export function hexToRgb(hex) {
  return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1).match(/.{2}/g)
    .map((x) => parseInt(x, 16));
}

export const GOAL_RED = new ColorMix.Color(255, 69, 0);
export const GOAL_GREEN = new ColorMix.Color(60, 202, 63);

export const mixColors = (percentRate) => {
  let resulting = GOAL_GREEN;
  if (!isNaN(percentRate) && percentRate >= 0 && percentRate <= 100) {
    try {
      resulting = ColorMix.mix([GOAL_GREEN, GOAL_RED], [percentRate, 100 - percentRate]);
    } catch (e) {
      console.log('Error mixing colors: ', e);
    }
  }
  const { red, green, blue } = resulting;
  return `rgb(${red}, ${green}, ${blue})`;
};
