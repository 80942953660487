import React from 'react';
import classnames from 'classnames';
import userStore from 'stores/userStore';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';
import Label from 'components/ControlsLabel';
import Textfield from 'components/controls/Textfield';
import Toggle from 'components/controls/Toggle';
import Select from 'components/controls/Select';
import OnboardingAddPreset from 'components/pages/account/OnboardingAddPreset';

import { inviteMemberSteps } from 'components/pages/account/enum';

import style from 'styles/onboarding/onboarding.css';
import popupStyle from 'styles/welcome/add-member-popup.css';

export default class AddMemberPopup extends Component {
  style = style;

  styles = [popupStyle];

  pagePermissions = [
    {
      key: 'settings',
      label: 'Account Settings',
      isDisabled: false,
    },
    {
      key: 'dashboard',
      label: 'Dashboard',
      isDisabled: false,
    },
    {
      key: 'analyze',
      label: 'Analyze',
      isDisabled: false,
    },
    {
      key: 'plan',
      label: 'Plan',
      isDisabled: false,
    },
    {
      key: 'campaigns',
      label: 'Campaigns',
      isDisabled: true,
    },
  ];

  getInitialPermissions = () => {
    const initialPermissions = {};
    this.pagePermissions.forEach((item) => {
      initialPermissions[item.key] = true;
    });
    return initialPermissions;
  };

  getInitialState({ selectedMember }) {
    return ({
      firstName: selectedMember?.firstName || '',
      lastName: selectedMember?.lastName || '',
      email: selectedMember?.email || '',
      role: selectedMember?.role || '',
      isAdmin: selectedMember ? selectedMember.isAdmin : true,
      specificChannels: selectedMember?.specificChannels || [],
      isSpecificChannels: selectedMember ? selectedMember?.isSpecificChannels : false,
      pagePermissions: selectedMember?.pagePermissions || this.getInitialPermissions(),
      emailError: '',
      emailErrorMessage: '',
      progressCurrentStep: inviteMemberSteps.basicDetails,
      selectedPreset: selectedMember?.onboardingPresetConfigId || null,
      isShowGettingStartedPage: selectedMember?.isShowGettingStartedPage || true,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState({ selectedMember: props.selectedMember }),
      isPresetsLoading: false,
      presetOptions: [],
    };
    this.handleChangeChannels = this.handleChangeChannels.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentDidMount() {
    this.getAccountOnboardingPreset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMember !== this.props.selectedMember) {
      this.setState({ ...this.getInitialState({ selectedMember: this.props.selectedMember }) });
    }
  }

  getAccountOnboardingPreset() {
    const onboardingPresetConfigs = userStore.userAccount?.onboardingPresetConfigs || {};
    if (onboardingPresetConfigs) {
      const userPresetOptions = [];
      for (const [presetId, presetConfig] of Object.entries(onboardingPresetConfigs)) {
        userPresetOptions.push({ label: presetConfig.name, value: presetId });
      }
      this.setState({ presetOptions: userPresetOptions });
    }
  }

  handleChangeChannels(event) {
    const update = event.map((obj) => obj.value);
    this.setState({ specificChannels: update });
  }

  async inviteOrUpdatedMember() {
    this.setState({ isPresetsLoading: true });
    let blockedPages = null;
    if (this.state.pagePermissions) {
      blockedPages = Object.keys(this.state.pagePermissions).filter(
        (pageKey) => !this.state.pagePermissions[pageKey]
      );
    }

    await this.props.onInviteOrUpdatedMember({
      member: {
        ...this.props.selectedMember,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        role: this.state.role,
        isAdmin: this.state.isAdmin,
        specificChannels: this.state.specificChannels,
        isSpecificChannels: this.state.isSpecificChannels,
        pagePermissions: this.state.pagePermissions,
        blockedPages,
        onboardingPresetConfigId: this.state.selectedPreset,
        isShowGettingStartedPage: this.state.isShowGettingStartedPage,
        isSSO: this.props.isSSO,
      },
      isNewMember: !this.props.selectedMember,
    });
    this.setState({ isPresetsLoading: false });
  }

  handleEmailChange(event) {
    const enteredEmail = event.target.value;
    let emailErrorMessage = '';
    const isEmailExists = this.props.teamMembers.some(
      (member) => member.email === enteredEmail.trim().toLowerCase()
    );
    if (isEmailExists) {
      emailErrorMessage = 'This email is already signed up to InfiniGrow.';
    }

    this.setState({
      email: enteredEmail,
      emailError: isEmailExists,
      emailErrorMessage,
    });
  }

  isInviteButtonDisabled() {
    const {
      firstName, lastName, email, role, emailError,
    } = this.state;
    return !firstName || !lastName || !email || !role || emailError;
  }

  renderBasicDetails() {
    const isEditMode = this.props.selectedMember;

    return (
      <>
        <div className={this.classes.row}>
          <Label>First Name</Label>
          <Textfield
            value={this.state.firstName}
            onChange={(event) => {
              this.setState({ firstName: event.target.value });
            }}
          />
        </div>
        <div className={this.classes.row}>
          <Label>Last Name</Label>
          <Textfield
            value={this.state.lastName}
            onChange={(event) => {
              this.setState({ lastName: event.target.value });
            }}
          />
        </div>
        <div className={this.classes.row}>
          <Label>Email</Label>
          <Textfield
            value={this.state.email}
            onChange={this.handleEmailChange}
            isShowError={this.state.emailError}
            inputErrorMessage={this.state.emailErrorMessage}
            disabled={isEditMode}
          />
        </div>
        <div className={this.classes.row}>
          <Label>Role</Label>
          <Select
            select={this.props.roleOptions.select}
            selected={this.state.role}
            onChange={(event) => this.setState({ role: event.value })}
          />
        </div>
        <div className={this.classes.row} style={{ display: 'inline-block' }}>
          <Label>Permissions</Label>
          <Toggle
            disabled={isEditMode}
            options={[
              {
                text: 'Admin',
                value: true,
              },
              {
                text: 'User',
                value: false,
              },
            ]}
            selectedValue={this.state.isAdmin}
            onClick={(value) => {
              this.setState({ isAdmin: value });
            }}
          />
        </div>
        <div className={this.classes.footerButtons}>
          <Button
            type="secondaryBlue"
            onClick={() => this.props.close()}
          >
            Cancel
          </Button>
          <Button
            type="primaryBlue"
            disabled={this.isInviteButtonDisabled()}
            onClick={() => this.setState({ progressCurrentStep: inviteMemberSteps.customizeOnboarding })}
          >
            Next
          </Button>
        </div>
      </>
    );
  }

  render() {
    const isBasicDetailsStep = this.state.progressCurrentStep === inviteMemberSteps.basicDetails;
    const isCustomizeOnboardingStep = this.state.progressCurrentStep === inviteMemberSteps.customizeOnboarding;
    const isEditMode = this.props.selectedMember;

    return (
      <Page
        popup
        width="600px"
        contentClassName={popupStyle.locals.content}
        innerClassName={popupStyle.locals.inner}
      >
        <div className={popupStyle.locals.title}>
          {isEditMode ? 'Update User ' : 'Invite User '}
          and Customize Onboarding
        </div>

        <div className={this.classes.progressSteps}>
          <div className={classnames(
            this.classes.stepItem,
            this.classes.stepItemLine,
            isBasicDetailsStep && this.classes.stepActive
          )}
          >
            <div className={this.classes.stepCounter} />
            <div>Basic Details</div>
          </div>
          <div className={classnames(
            this.classes.stepItem,
            isCustomizeOnboardingStep && this.classes.stepActive
          )}
          >
            <div className={this.classes.stepCounter} />
            <div>Customize Onboarding</div>
          </div>
        </div>

        {isBasicDetailsStep ? (
          this.renderBasicDetails()
        ) : null}

        {isCustomizeOnboardingStep ? (
          <OnboardingAddPreset
            selectedPreset={this.state.selectedPreset}
            setSelectedPreset={(selectedPreset) => this.setState({ selectedPreset })}
            onGoBack={() => this.setState({ progressCurrentStep: inviteMemberSteps.basicDetails })}
            inviteOrUpdatedMember={() => this.inviteOrUpdatedMember()}
            isEditMode={isEditMode}
            isPresetsLoading={this.state.isPresetsLoading}
            presetOptions={this.state.presetOptions}
            setPresetOptions={(presetOptions) => this.setState({ presetOptions })}
          />
        ) : null}

        <div className={popupStyle.locals.errorMessage}>
          {this.props.inviteMessage}
        </div>
      </Page>
    );
  }
}
