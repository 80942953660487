exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".popup-general__popup-wrapper-1QZ{display:flex;flex-direction:column;padding:5px;border:1px solid rgba(178,187,213,.5);border-radius:4px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12)}", ""]);

// exports
exports.locals = {
	"popup-wrapper": "popup-general__popup-wrapper-1QZ",
	"popupWrapper": "popup-general__popup-wrapper-1QZ"
};