import { theme } from '@infinigrow/libs';

import { keyChannels, keyJobTitles, stageVelocity } from 'components/widgets/optimalJourney/enums';

import velocityIcon from 'assets/analyze-icons/velocity-arrow-icon.svg';

export const firstJourneySection = {
  first: {
    topLabel: '1ST',
    bottomLabel: 'Start of Journey',
  },
};

export const funnelStagesObjectMock = {
  first: {
    topLabel: '1ST',
    bottomLabel: 'Start of Journey',
  },
  funnel1: {
    topLabel: 'LEAD',
  },
  funnel2: {
    topLabel: 'MQL',
  },
  funnel3: {
    topLabel: 'SQL',
  },
  funnel4: {
    topLabel: 'OPP',
  },
  funnel5: {
    topLabel: 'CW',
    bottomLabel: 'Closed Won',
  },
};

export const legendItems = [
  {
    [stageVelocity]: {
      label: 'Stage velocity',
      icon: <img src={velocityIcon} alt="velocity" />,
    },
  },
  {
    [keyChannels]: {
      label: 'Channel',
      color: theme.colors.pills.purple,
    },
  },
  {
    [keyJobTitles]: {
      label: 'Job Title',
      color: theme.colors.pills.lightBlue,
    },
  },
];

export function getTooltipData({ segment, isDemo }) {
  if (isDemo) {
    if (segment === 'velocity') {
      return getVelocityTooltipMockData();
    }
  }

  return {};
}

export function getVelocityTooltipMockData() {
  return {
    changeRate: '+27% faster',
    kpiFocus: 'Closed Won',
  };
}

export const segmentTooltipMock = {
  velocity: ({
    value, firstFunnelStage, secondFunnelStage, kpiFocus, changeRate,
  }) => (
    <div style={{ fontSize: '16px', lineHeight: '24px' }}>
      <div>
        Velocity:
        {' '}
        <b>{value}</b>
      </div>
      <div>
        between
        {' '}
        <b>{firstFunnelStage}</b>
        {' '}
        and
        {' '}
        <b>{secondFunnelStage}</b>
      </div>
      <div style={{ fontSize: '12px' }}>
        <ul>
          <li>
            {kpiFocus}
            {' '}
            journeys convert from
            {' '}
            {firstFunnelStage}
            {' '}
            to
            {' '}
            {secondFunnelStage}
            {' '}
            {changeRate}
            {' '}
            than Closed Lost journeys
          </li>
        </ul>
      </div>
    </div>
  ),
  channel: ({
    value,
  }) => (
    <div style={{ fontSize: '16px', lineHeight: '24px' }}>
      <div>
        Channel:
        {' '}
        <b>{value}</b>
      </div>
      <div>
        at the
        {' '}
        <b>start of the journey</b>
      </div>
      <div style={{ fontSize: '12px' }}>
        <ul>
          <li>
            Best converting Channel: 38% conversion rate to Closed Wons
          </li>
          <li>
            30% of your Closed Wons
          </li>
          <li>
            +15% conversion rate to Closed Wons compared to Closed Lost journeys
          </li>
          <li>
            +25% faster journey to Closed Wons compared to Closed Lost journeys
          </li>
        </ul>
      </div>
    </div>
  ),
  jobTitle: ({
    value, firstFunnelStage, secondFunnelStage,
  }) => (
    <div style={{ fontSize: '16px', lineHeight: '24px' }}>
      <div>
        Job Title:
        {' '}
        <b>{value}</b>
      </div>
      <div>
        between
        {' '}
        <b>{firstFunnelStage}</b>
        {' '}
        and
        {' '}
        <b>{secondFunnelStage}</b>
      </div>
      <div style={{ fontSize: '12px' }}>
        <ul>
          <li>
            Best converting
            {' '}
            {value}
            {' '}
            : 36% conversion rate to Closed Wons
          </li>
          <li>
            30% of your Closed Wons
          </li>
          <li>
            +15% conversion rate to Closed Wons compared to Closed Lost journeys
          </li>
          <li>
            +25% faster journey to Closed Wons compared to Closed Lost journeys
          </li>
        </ul>
      </div>
    </div>
  ),
};
