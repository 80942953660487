import React from 'react';
import PropTypes from 'prop-types';
import { throttle, uniqueId } from 'lodash';
import classnames from 'classnames';
import Component from 'components/Component';
import style from 'styles/common/channel-list.css';
import Tooltip from 'components/controls/Tooltip';
import { getChannelIcon } from 'components/utils/filters/channels';
import { getChannelNicknameWithDirect } from 'components/utils/channels';
import ChannelIcon from 'components/common/ChannelIcon';

export default class Campaigns extends Component {
  style = style;

  static propTypes = {
    channels: PropTypes.arrayOf(PropTypes.string).isRequired,
    width: PropTypes.number.isRequired,
    defaultChannelShown: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
  };

  static defaultProps = {
    defaultChannelShown: 5,
    min: 3,
    max: 6,
  };

  constructor(props) {
    super(props);

    this.state = {
      channelShown: props.defaultChannelShown,
    };
    this.setChannelShown = throttle(this.setChannelShown, 300);
  }

  componentDidMount() {
    this.setChannelShown();
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;

    if (prevProps.width !== width) {
      this.setChannelShown();
    }
  }

  setChannelShown = () => {
    const { width, min, max } = this.props;

    const CHANNEL_ICON_WIDTH = 44;
    const availableSlot = Math.floor(width / CHANNEL_ICON_WIDTH);
    const channelShown = Math.max(min, Math.min(availableSlot, max));
    this.setState({ channelShown });
  };

  getChannelNode = (channel, index) => (
    <Tooltip
      key={index}
      tip={getChannelNicknameWithDirect(channel)}
      className={this.classes.channelIcon}
      id={uniqueId('channel-')}
    >
      <ChannelIcon
        className={this.classes.channelIcon}
        channelIcon={getChannelIcon(channel)}
        channel={channel}
      />
    </Tooltip>
  );

  render() {
    const { channels = [] } = this.props;
    const { channelShown } = this.state;

    if (channels.length <= channelShown) {
      return (
        <>
          {channels.map(this.getChannelNode)}
        </>
      );
    }

    const showChannels = channels.slice(0, channelShown - 1);
    const tooltipChannels = channels.slice(channelShown - 1, channels.length);

    return (
      <>
        {showChannels.map(this.getChannelNode)}
        <Tooltip
          id={uniqueId('channel-')}
          html={false}
          content={(
            <>
              { tooltipChannels.map((item) => (
                <div key={`channel-others-${item}`} className={this.classes.tooltipContent}>
                  <ChannelIcon
                    className={this.classes.channelIcon}
                    channelIcon={getChannelIcon(item)}
                    channel={item}
                  />
                  {getChannelNicknameWithDirect(item)}
                </div>
              ))}
            </>
        )}
        >
          <div className={classnames(this.classes.channelIcon, this.classes.otherIcon)}>
            +
            {channels.length - channelShown + 1}
          </div>
        </Tooltip>
      </>
    );
  }
}
