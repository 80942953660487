import React from 'react';

import userStore from 'stores/userStore';

export const numberWithZeroDefault = (getter) => (arg, ...other) => getter(arg, ...other) || 0;

export const getMappingOptions = (idToLabelMap = {}) => {
  const mappingOptions = [];
  for (const [id, label] of Object.entries(idToLabelMap)) {
    const optionObject = { value: id, label };
    const customSegmentMapping = userStore.userAccount?.customSegmentsMapping?.[id];
    if (customSegmentMapping) {
      optionObject.disabled = !customSegmentMapping.enabled;
      optionObject.icon = (<img alt="customSegment" src="/assets/analyze-icons/custom-segment.svg" />);
    }
    mappingOptions.push(optionObject);
  }

  return mappingOptions;
};

export function getGroupedUserMetrics({ userMetrics = [] } = {}) {
  const groupLabels = {};
  const groups = {};
  const { isPipelineStageHidden } = userStore.userMonthPlan || {};

  for (const metricData of userMetrics) {
    const {
      relevantFor, title, metricName, isRelevantForTarget, nickname, enabled, metricType,
    } = metricData;

    if (isPipelineStageHidden && metricName.toLowerCase().includes('pipeline')) {
      continue;
    }

    const optionItem = {
      label: nickname,
      value: metricName,
      enabled,
      metricType,
      relevantFor,
    };

    const relevantForValue = relevantFor || metricName;
    if (!groups[relevantForValue]) {
      groups[relevantForValue] = [];
    }

    groups[relevantForValue].push(optionItem);

    if (isRelevantForTarget || relevantForValue === metricName) {
      groupLabels[relevantForValue] = title;
    }
  }

  const groupedMetrics = Object.entries(groups).map(([groupName, options]) => {
    const label = groupLabels[groupName] || groupName;
    return {
      label,
      groupName,
      options,
    };
  });
  return groupedMetrics;
}
