import React from 'react';
import Component from 'components/Component';
import Page from 'components/Page';
import style from 'styles/plan/plan.css';
import pageStyle from 'styles/page.css';

export default class Goals extends Component {
  style = style;

  styles = [pageStyle];

  render() {
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        ...this.props,
      }));
    return (
      <div>
        <Page width="100%">
          <div className={pageStyle.locals.container} style={{ padding: '0 40px 0 100px' }}>
            {childrenWithProps}
          </div>
        </Page>
      </div>
    );
  }
}
