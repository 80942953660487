import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Label from 'components/ControlsLabel';
import Textfield from 'components/controls/Textfield';
import Select from 'components/controls/Select';
import Calendar from 'components/controls/Calendar';
import SaveButton from 'components/pages/profile/SaveButton';
import AssetsPopup from 'components/pages/campaigns/AssetsPopup';
import MultiSelect from 'components/controls/MultiSelect';
import Toggle from 'components/controls/Toggle';

import { getChannelsWithTitles, getTitle } from 'components/utils/channels';
import { compose } from 'components/utils/utils';

import saveButtonIcon from 'assets/save.svg';
import style from 'styles/onboarding/onboarding.css';
import campaignPopupStyle from 'styles/campaigns/capmaign-popup.css';
import buttonsStyle from 'styles/onboarding/buttons.css';

import {
  exchangeToUSD, extractNumberFromBudget, formatBudget, getCurrencySymbol,
} from '../../utils/budget';

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
    } = stores;
    const {
      funnels,
      getMetricNickname,
    } = userStore;
    return {
      funnels,
      getMetricNickname,
    };
  }),
  observer
);

class Brief extends Component {
  style = style;

  styles = [campaignPopupStyle, buttonsStyle];

  static defaultProps = {
    teamMembers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      showAdvanced: false,
      startIndex: props.startIndex,
      endIndex: props.endIndex,
    };
    this.getEmailBody = this.getEmailBody.bind(this);
    this.getEmailHeader = this.getEmailHeader.bind(this);
    this.getEmailTo = this.getEmailTo.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.startIndex !== this.props.startIndex || nextProps.endIndex !== this.props.endIndex) {
      this.setState({ startIndex: nextProps.startIndex, endIndex: nextProps.endIndex });
    }
  }

  handleChangeSource = (event) => {
    const update = { ...this.props.campaign };
    update.source = event.map((obj) => obj.value);
    this.props.updateState({ campaign: update });
  };

  handleChangeBudget(parameter, event) {
    const { campaign } = this.props;
    const newValue = exchangeToUSD(extractNumberFromBudget(event.target.value));
    if (parameter === 'budgetAmount') {
      campaign.budgetAmount = newValue;
      return this.props.updateState({
        campaign: {
          ...campaign,
        },
      });
    } else {
      const update = { ...this.props.campaign };
      update.costAmount = newValue;
      this.props.updateState({ campaign: update });
    }
  }

  handleChangeBool(bool) {
    const update = { ...this.props.campaign };
    update.isOneTime = bool;
    this.props.updateState({ campaign: update });
  }

  handleChangeText(parameter, event) {
    const update = { ...this.props.campaign };
    update[parameter] = event.target.value;
    this.props.updateState({ campaign: update });
  }

  handleChangeTime(parameter, event) {
    const update = { ...this.props.campaign };
    update.time[parameter] = parseInt(event.target.value.replace(/[-h.]/g, ''));
    this.props.updateState({ campaign: update });
  }

  handleChangeSelect(parameter, event) {
    const update = { ...this.props.campaign };
    update[parameter] = event.value;
    this.props.updateState({ campaign: update });
  }

  // handleChangeTracking(parameter, event) {
  //   const update = { ...this.props.campaign };
  //   update.tracking[parameter] = event.target.value;
  //   this.props.updateState({ campaign: update });
  // }

  handleChangeDate(parameter, value) {
    const update = { ...this.props.campaign };
    update[parameter] = value;
    this.props.updateState({ campaign: update });
  }

  get isCampaignCreated() {
    return this.props.campaign.index !== undefined;
  }

  get actualCostNotActive() {
    return this.isCampaignCreated && this.props.campaign.isAutomatedChannel;
  }

  getEmailTo() {
    return (this.props.campaign.owner
      ? this.props.teamMembers
        .filter((item) => item.userId === this.props.campaign.owner)
        .map((item) => item.email)
      : '');
  }

  getEmailHeader() {
    return `InfiniGrow  - New Marketing Campaign - ${this.props.campaign.name}`;
  }

  getEmailBody() {
    const newLine = '\r\n';
    const sourceTitles = this.props.campaign.source.map((source) => getTitle(source) || source);
    const linksCategories = this.props.campaign.assets
      ? [...new Set(this.props.campaign.assets.map((item) => item.category))]
      : [];
    const links = linksCategories.map((category) => `${category}:${newLine
    }${this.props.campaign.assets.filter((asset) => asset.category === category)
      .map((asset) => (asset.name ? `${asset.name} - ${asset.link}` : asset.link))}`);
    return `Congrats! you have been assigned to a new marketing campaign through InfiniGrow. Let's have a look at the brief:${newLine
    }${newLine
    }- Sources: ${sourceTitles.join()}${newLine
    }- Campaign Name: ${this.props.campaign.name}${newLine
    }- Campaign Budget: ${this.props.campaign.costAmount || this.props.campaign.budgetAmount}${newLine
    }- Status: ${this.props.campaign.status}${newLine
    }${newLine
    }${this.props.campaign.startDate ? (`Start date: ${this.props.campaign.startDate}${newLine}${newLine}`) : ''
    }${this.props.campaign.endDate ? (`Due date: ${this.props.campaign.endDate}${newLine}${newLine}`) : ''
    }${newLine
    }${this.props.campaign.time && this.props.campaign.time.marketing ? (`- Expected marketing time: ${this.props.campaign.time.marketing} hours${newLine}`) : ''
    }${this.props.campaign.time && this.props.campaign.time.development ? (`- Expected development time: ${this.props.campaign.time.development} hours${newLine}`) : ''
    }${this.props.campaign.time && this.props.campaign.time.design ? (`- Expected design time: ${this.props.campaign.time.design} hours${newLine}`) : ''
    }Campaign objectives:${newLine
    }${this.props.campaign.focus ? `Campaign focus: ${this.props.campaign.focus}${newLine}` : ''
    }${this.props.campaign.objectives && this.props.campaign.objectives.kpi[0] ? (`- KPI: ${this.props.campaign.objectives.kpi[0]}, Expected Growth: ${this.props.campaign.objectives.growth[0]}, Actual Growth: ${this.props.campaign.objectives.actualGrowth[0]}${newLine}`) : ''
    }${this.props.campaign.objectives && this.props.campaign.objectives.kpi[1] ? (`- KPI: ${this.props.campaign.objectives.kpi[1]}, Expected Growth: ${this.props.campaign.objectives.growth[1]}, Actual Growth: ${this.props.campaign.objectives.actualGrowth[0]}${newLine}`) : ''
    }${this.props.campaign.objectives && this.props.campaign.objectives.kpi[2] ? (`- KPI: ${this.props.campaign.objectives.kpi[2]}, Expected Growth: ${this.props.campaign.objectives.growth[2]}, Actual Growth: ${this.props.campaign.objectives.actualGrowth[0]}${newLine}`) : ''
    }${newLine
    }${this.props.campaign.targetAudience ? (`Target audience:${newLine}${this.props.campaign.targetAudience}${newLine}${newLine}`) : ''
    }${this.props.campaign.description ? (`Campaign description:${newLine}${this.props.campaign.description}${newLine}${newLine}`) : ''
    }${this.props.campaign.referenceProjects ? (`Reference projects:${newLine}${this.props.campaign.referenceProjects}${newLine}${newLine}`) : ''
    }${this.props.campaign.keywords ? (`Keywords:${newLine}${this.props.campaign.keywords}${newLine}${newLine}`) : ''
    }${this.props.campaign.additionalInformation ? (`Notes:${newLine}${this.props.campaign.additionalInformation}${newLine}${newLine}`) : ''
    }${links ? (`Links:${newLine}${links.join()}${newLine}${newLine}`) : ''
    }${newLine
    }Thanks!`;
  }

  exportCampaign() {
    const win = window.open(encodeURI(`mailto:${this.getEmailTo()}?&subject=${this.getEmailHeader()}&body=${this.getEmailBody()}`));
    this.props.save();
  }

  addObjectivesRow() { // TODO: refactor
    const update = { ...this.props.campaign };
    update.objectives.push({
      growth: '',
      kpi: '',
      actualGrowth: '',
    });
    this.props.updateState({ campaign: update });
  }

  render() {
    const {
      campaign, channelsSources, setRefDueDate, setRefName, setRefSource, openAddTemplatePopup, save, updateState, updateCampaign, funnels, getMetricNickname,
    } = this.props;

    const {
      assetsPopup, startIndex, endIndex,
    } = this.state;
    const objectivesIndicatorsMap = [...funnels, 'MRR', 'ARR']
      .map((value) => ({ value, label: getMetricNickname({ metric: value }) }));
    const objectivesOptions = [
      { value: 'impressions', label: 'Impressions' },
      { value: 'clicks', label: 'Clicks' },
      { value: 'ctr', label: 'CTR' },
      { value: 'cpc', label: 'CPC' },
      { value: 'conversions', label: 'Conversions' },
      { value: 'allConversions', label: 'All Conversions' },
      { value: 'revenue', label: 'Revenue' },
      { value: 'webVisits', label: 'Web Visits' },
      { value: 'pageViews', label: 'Page Views' },
    ];
    objectivesIndicatorsMap.forEach((item) => objectivesOptions.push(item));

    const selects = {
      source: {
        label: 'Source*',
        select: {
          name: 'source',
          options: getChannelsWithTitles(),
        },
      },
      status: {
        label: 'Status',
        select: {
          name: 'status',
          options: [
            { value: 'New', label: 'New' },
            { value: 'Assigned', label: 'Assigned' },
            { value: 'In Progress', label: 'In Progress' },
            { value: 'In Review', label: 'In Review' },
            { value: 'Approved', label: 'Approved' },
            { value: 'Completed', label: 'Completed' },
            { value: 'On Hold', label: 'On Hold' },
            { value: 'Rejected', label: 'Rejected' },
          ],
        },
      },
      // focus: {
      //   label: 'Focus',
      //   select: {
      //     name: 'focus',
      //     options: [
      //       { value: 'Acquisition', label: 'Acquisition' },
      //       { value: 'Activation', label: 'Activation' },
      //       { value: 'Retention', label: 'Retention' },
      //       { value: 'Revenue', label: 'Revenue' },
      //       { value: 'Referral', label: 'Referral' },
      //     ],
      //   },
      // },
      objectives: {
        select: {
          name: 'objective',
          options: objectivesOptions,
        },
      },
    };
    // Handle manual channels
    if (channelsSources) {
      channelsSources.forEach((source) => {
        if (!selects.source.select.options.find((item) => item.value === source)) {
          selects.source.select.options.push({ value: source, label: source });
        }
      });
    }

    const objectiveRows = (
      <div className={this.classes.cols}>
        <div className={this.classes.colLeft}>
          <Label style={{
            width: '166px',
          }}
          >
            KPI
          </Label>
          {Object.keys(campaign.indicators || {}).map((indicatorName) => (
            <Textfield
              key={indicatorName}
              value={indicatorName}
              style={{ width: '166px', marginTop: '8px' }}
            />
          ))}
        </div>
        <div className={this.classes.colCenter} />
        <div className={this.classes.colRight}>
          <Label style={{
            width: '166px',
          }}
          >
            Actual Growth
          </Label>
          {Object.values(campaign.indicators || {}).map((indicatorAmount) => (
            <Textfield
              key={indicatorAmount}
              readOnly
              value={indicatorAmount}
              style={{
                width: '166px',
                marginTop: '8px',
              }}
            />
          ))}
        </div>
      </div>
    );

    return (
      <div>
        <div className={this.classes.row}>
          <div className={this.classes.cols}>
            <div className={this.classes.colLeft}>
              <Label>Campaign Name*</Label>
              <Textfield
                disabled={this.props.openFromAnalyze}
                value={campaign.name}
                required
                onChange={this.handleChangeText.bind(this, 'name')}
                ref={setRefName}
                style={{ width: '428px' }}
              />
            </div>
          </div>
        </div>
        <div className={this.classes.row}>
          <div className={this.classes.cols}>
            <div className={this.classes.colLeft}>
              <Label>{campaign.isOneTime ? 'Budget' : 'Monthly Budget'}</Label>
              <Textfield
                disabled={this.props.openFromAnalyze}
                readOnly={startIndex || endIndex}
                value={campaign.budgetAmount ? formatBudget(campaign.budgetAmount) : getCurrencySymbol()}
                onChange={() => this.handleChangeBudget.bind(this, 'budgetAmount')}
                style={{
                  width: '166px',
                }}
              />
            </div>
            <div className={this.classes.colCenter}>
              <Label>Actual Spent</Label>
              <Textfield
                disabled={this.actualCostNotActive || startIndex || endIndex || this.props.openFromAnalyze}
                value={campaign.costAmount ? formatBudget(campaign.costAmount) : getCurrencySymbol()}
                onChange={() => this.handleChangeBudget.bind(this, 'costAmount')}
                style={{
                  width: '166px',
                }}
              />
            </div>
            <div className={this.classes.colRight}>
              <Label>Budget Type</Label>
              <Toggle
                options={[{
                  text: 'Recurrent',
                  value: false,
                },
                {
                  text: 'One time',
                  value: true,
                },
                ]}
                selectedValue={campaign.isOneTime}
                onClick={(value) => {
                  this.handleChangeBool(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={this.classes.row}>
          <div className={this.classes.cols}>
            <div className={this.classes.colLeft}>
              <MultiSelect
                {...selects.source}
                style={{ width: '428px', textTransform: 'capitalize' }}
                selected={campaign.source}
                onChange={this.handleChangeSource}
                ref={setRefSource}
              />
            </div>
            <div className={this.classes.colRight}>
              <Select
                {...selects.status}
                style={{ width: '166px' }}
                selected={campaign.status}
                onChange={() => this.handleChangeSelect.bind(this, 'status')}
              />
            </div>
          </div>
        </div>
        <div className={this.classes.row}>
          <div className={this.classes.cols}>
            <div className={this.classes.colLeft}>
              <div style={{ width: '166px' }}>
                <Label>Start Date</Label>
                <Calendar value={campaign.startDate} onChange={() => this.handleChangeDate.bind(this, 'startDate')} />
              </div>
            </div>
            <div className={this.classes.colCenter}>
              <div style={{ width: '166px' }}>
                <Label>Due Date</Label>
                <Calendar
                  value={campaign.endDate}
                  onChange={() => this.handleChangeDate.bind(this, 'endDate')}
                  ref={setRefDueDate}
                />
              </div>
            </div>
            <div className={this.classes.colRight} style={{ width: '166px' }} />
          </div>
        </div>
        <div className={this.classes.row}>
          <div
            className={campaignPopupStyle.locals.advanced}
            onClick={() => {
              this.setState((prevState) => ({ showAdvanced: !prevState.showAdvanced }));
            }}
          >
            Advanced
          </div>
        </div>
        <div hidden={!this.state.showAdvanced}>
          <div className={this.classes.row}>
            <Label style={{ fontSize: '18px', fontWeight: 'bold' }}>Campaign Objectives</Label>
            {/* <Select */}
            {/*  {...selects.focus} */}
            {/*  selected={campaign.focus} */}
            {/*  onChange={this.handleChangeSelect.bind(this, 'focus')} */}
            {/*  style={{ width: '166px', marginBottom: '14px' }} */}
            {/* /> */}
            {objectiveRows}
            <Button
              type="secondary"
              style={{ width: '100px', marginTop: '8px' }}
              onClick={() => this.addObjectivesRow()}
            >
              Add
            </Button>
          </div>
        </div>
        <div className={this.classes.footer} style={{ marginBottom: '1px' }}>
          <div className={this.classes.footerLeft}>
            <Button
              type="secondary"
              style={{ width: '165px', marginLeft: '30px' }}
              onClick={openAddTemplatePopup}
            >
              Save
              as a template
            </Button>
          </div>
          <div className={this.classes.footerRight}>
            <Button
              type="secondary"
              style={{ width: '100px', marginRight: '30px' }}
              onClick={() => this.exportCampaign()}
            >
              Export
            </Button>
            {this.isCampaignCreated
              ? <SaveButton onClick={() => save(false)} />
              : (
                <Button
                  disabled={this.props.openFromAnalyze || campaign.isAutomatic}
                  type="primaryBlue"
                  icon={saveButtonIcon}
                  className={buttonsStyle.locals.planButton}
                  onClick={() => save(false)}
                >
                  Create
                </Button>
              )}
          </div>
        </div>
        <AssetsPopup
          hidden={!assetsPopup}
          updateCampaign={() => {
            updateCampaign(campaign);
          }}
          updateState={updateState}
          campaign={campaign}
          close={() => {
            this.setState({ assetsPopup: false });
          }}
        />
      </div>
    );
  }
}

export default enhance(Brief);
