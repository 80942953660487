import React from 'react';
import { inject, observer } from 'mobx-react';

import Dropdown from 'components/controls/Dropdown';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

const FilterVariantsWithFunnelStage = ({
  onChangeVariantHandler,
  defaultIndex = 0,
  getMetricNickname,
  variantsOptions,
  funnels,
  onChangeFunnelHandler,
  selectedFunnels,
}) => {
  const getFunnelsOptions = () => {
    const userFunnels = funnels.map((funnel) => ({ value: funnel, label: getMetricNickname({ metric: funnel }) }));
    return [
      { value: 'accounts', label: 'Accounts' },
      { label: 'funnels', options: userFunnels },
    ];
  };

  return (
    <div>
      <div className={styles.filterHeaderStage}>
        <strong>Find</strong>
        <MultiCheckSelect
          options={getFunnelsOptions()}
          isDropDownSelect
          selected={selectedFunnels}
          onChange={onChangeFunnelHandler}
          placeholder="Accounts"
          controlWidth={175}
          isSearchable={false}
          isClearable={false}
          isSearchIconPlaceholder={false}
          isDropdownIndicator
          isGroupCheckedAsRadio
          isMultiLabelAsText
        />
        <strong>that</strong>
        <Dropdown
          onChange={onChangeVariantHandler}
          options={variantsOptions}
          selectedKey={defaultIndex}
          controlWidth={175}
        />
      </div>
    </div>
  );
};

export default inject(({
  userStore: {
    funnels,
    getMetricNickname,
  },
}) => ({
  funnels,
  getMetricNickname,
}),
observer)(FilterVariantsWithFunnelStage);
