import { camelCase } from 'lodash';
import userStore from 'stores/userStore';

import { indicatorsToNewMapping } from 'components/utils/indicators';

export function calcCostPerFunnel({ indicatorsCount = {}, totalCost = 0 }) {
  const costPerFunnel = {};
  Object.keys(indicatorsToNewMapping()).forEach((indicator) => {
    const indicatorSum = indicatorsCount[indicator];

    if (indicatorSum) {
      costPerFunnel[indicator] = {
        cost: Math.round(totalCost / indicatorSum),
        funnelCount: indicatorSum,
      };
    } else {
      costPerFunnel[indicator] = {
        cost: 0,
        funnelCount: 0,
      };
    }
  });

  return costPerFunnel;
}

export function getSelectedMetricsForStatsSquare({ userFunnels }) {
  const selectedMetrics = userFunnels.map((funnel) => camelCase(`new ${funnel}`));
  selectedMetrics.push('marketingAttributedRevenue', 'marketingAttributedPipeline', 'newBookings', 'newPipeline', 'totalCost', 'marketingPipelineROI', 'marketingROI');
  return selectedMetrics;
}

export function parseHistoricalPerformanceDataToStatsSquares({ historicalPerformance, isCompareToPreviousEnabled }) {
  const parsedData = {
    indicatorsCount: {},
    revenueAndPipelineOverview: {},
    totalCost: 0,
    growthValues: {
      costPer: {},
      revenueAndPipeline: {},
    },
  };

  if (!historicalPerformance) {
    return parsedData;
  }

  for (const funnel of userStore.userFunnels) {
    const newFunnelName = camelCase(`new ${funnel}`);
    parsedData.indicatorsCount[funnel] = historicalPerformance[newFunnelName]?.total?.value || 0;
    if (isCompareToPreviousEnabled) {
      const costPerFunnelName = camelCase(`costPer ${funnel}`);
      parsedData.growthValues.costPer[funnel] = historicalPerformance[costPerFunnelName]?.total?.growth;
    }
  }

  parsedData.revenueAndPipelineOverview = {
    marketingRevenue: historicalPerformance.marketingAttributedRevenue?.total?.value || 0,
    marketingPipeline: historicalPerformance.marketingAttributedPipeline?.total?.value || 0,
    marketingPipelineROI: historicalPerformance.marketingPipelineROI?.total?.value || 0,
    marketingROI: historicalPerformance.marketingROI?.total?.value || 0,
    totalRevenue: historicalPerformance.newBookings?.total?.value || 0,
    totalPipeline: historicalPerformance.newPipeline?.total?.value || 0,
  };

  parsedData.totalCost = historicalPerformance.totalCost?.total?.value;

  if (isCompareToPreviousEnabled) {
    parsedData.growthValues.revenueAndPipeline = {
      marketingRevenue: historicalPerformance.marketingAttributedRevenue?.total?.growth,
      marketingPipeline: historicalPerformance.marketingAttributedPipeline?.total?.growth,
      marketingPipelineROI: historicalPerformance.marketingPipelineROI?.total?.growth,
      marketingROI: historicalPerformance.marketingROI?.total?.growth,
    };
  }

  return parsedData;
}
