import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import InfoMarker from 'components/pages/InfoMarker';
import Tooltip from 'components/controls/Tooltip';

import { spotlightTypesLabels } from 'components/pages/insights/logic/enums';
import {
  makeChannelsFilter, makeCampaignsFilter, makeCustomFieldsFilter, VARIANTS,
} from 'components/utils/filters/make';
import { getRevenueFunnel, formatIndicatorDisplay } from 'components/utils/indicators';
import { formatBudget, formatNumber } from 'components/utils/budget';
import { getNickname } from 'components/utils/channels';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { getDaysPluralName } from 'components/utils/utils';

import userStore from 'stores/userStore';

import spotlightStyle from 'styles/insightsPage/spotlight.css';

const styles = spotlightStyle.locals;

export default function SpotlightCard({
  spotlightType,
  spotlightData,
  customFieldsIdToLabelMap,
  selectedOptimizationMetric,
  innerPageNavigation,
  isSelected,
  setSelectedCard,
  children,
  tagId,
}) {
  const spotlightTypeLabel = spotlightTypesLabels[spotlightType].label;
  const isPositiveSpotlight = spotlightTypesLabels[spotlightType].isPositive;
  const spotlightTooltip = spotlightTypesLabels[spotlightType].tooltip;
  const isTrending = spotlightTypesLabels[spotlightType].isTrending;
  const spotlightIcon = spotlightTypesLabels[spotlightType].icon;

  function getExploreButtonLabel({ value }) {
    if (value === 'channel') {
      return 'Channels';
    }
    if (value === 'campaign') {
      return 'Campaigns';
    }
    return value;
  }

  const getSegmentLabel = useMemo(() => {
    if (spotlightData.segmentType === 'customField' && customFieldsIdToLabelMap[spotlightData.segmentId]) {
      return customFieldsIdToLabelMap[spotlightData.segmentId];
    }
    return spotlightData.segmentType;
  }, [spotlightData]);

  const spotlightSegmentId = spotlightData.segmentType === 'customField' ? spotlightData.segmentId : spotlightData.segmentType;
  const spotlightSegmentValueLabel = spotlightData.segmentType === 'channel' ? getNickname(spotlightData.segmentValue) : spotlightData.segmentValue;

  const getFiltersForRequest = () => {
    const filters = [];
    if (spotlightData.segmentType === 'customField') {
      filters.push(makeCustomFieldsFilter(spotlightSegmentId, spotlightSegmentId, VARIANTS.INCLUDE_ANY_OF_EXACT, [spotlightData.segmentValue], false));
    } else if (spotlightData.segmentType === 'channel') {
      filters.push(makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [spotlightData.segmentValue]));
    } else if (spotlightData.segmentType === 'campaign') {
      filters.push(makeCampaignsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [spotlightData.segmentValue]));
    }
    return filters;
  };

  const isCostMetric = getRevenueFunnel() === selectedOptimizationMetric.originFunnel || selectedOptimizationMetric.value.includes('Pipeline');

  const getTooltipValueByMetric = useMemo(() => {
    if (isTrending) {
      return `${formatNumber(formatNumberWithDecimalPoint(spotlightData.value, 1))}%`;
    }

    const metricSchema = userStore.getMetricSchema({ metric: selectedOptimizationMetric.value });
    const isVelocity = metricSchema.metricType === 'velocity';
    if (isVelocity) {
      return `${spotlightData.value} ${getDaysPluralName(spotlightData.value)}`;
    }

    const isConversionRate = metricSchema.metricType === 'conversionRate';
    const valueFormatByMetric = isCostMetric ? formatBudget(spotlightData.value, true) : formatNumber(spotlightData.value);
    const valueFormatBySegment = isConversionRate ? `${formatNumberWithDecimalPoint(valueFormatByMetric, 1)}%` : valueFormatByMetric;

    return `${valueFormatBySegment} ${userStore.getMetricNickname({ metric: selectedOptimizationMetric.value })}`;
  }, [
    isTrending,
    isCostMetric,
    spotlightData.value,
    selectedOptimizationMetric.value,
  ]);

  return (
    <div id={tagId} className={classNames(styles.spotlightCard, isSelected && styles.spotlightCardClicked, tagId && styles.showButtons)}>
      <div className={styles.spotlightContent}>
        {children}

        <div className={styles.spotlightSegmentLabel}>
          {getSegmentLabel}
        </div>

        <div className={styles.spotlightTag}>
          <EllipsisTooltip text={spotlightSegmentValueLabel} />
        </div>

        is

        <div className={classNames(styles.spotlightTypeLabel, isPositiveSpotlight ? styles.spotlightPositive : null)}>
          <div className={styles.spotlightIcon} data-icon={spotlightIcon} />
          {spotlightTypeLabel}
        </div>

        in

        <div className={styles.spotlightTag}>
          <EllipsisTooltip
            text={userStore.getMetricNickname({ metric: selectedOptimizationMetric.value })}
          />
        </div>

        <div className={styles.spotlightAverageRatio}>
          <Tooltip
            tip={getTooltipValueByMetric}
            className={styles.averageRatioTooltip}
            id={spotlightTypeLabel}
          >
            {`by ${spotlightData.averageRatio > 0 ? '+' : ''}${formatNumber(spotlightData.averageRatio)}%`}
          </Tooltip>
        </div>

        compared to the average of

        <div>
          {isTrending ? (
            <>
              {spotlightData.averageBySegment > 0 && '+'}
              {formatNumber(spotlightData.averageBySegment)}
              %
            </>
          ) : (
            <>
              {formatIndicatorDisplay(selectedOptimizationMetric.value, spotlightData.averageBySegment, false, true, true, 2)}
            </>
          )}
        </div>

        {spotlightTooltip ? (
          <InfoMarker tooltipText={spotlightTooltip} />
        ) : null}
      </div>

      <div className={styles.spotlightCardButtons}>
        <Button
          type="primaryBlue"
          onClick={() => {
            setSelectedCard();
            innerPageNavigation({
              tabName: 'overview',
              anchor: 'impact-by-segment',
              firstSegment: spotlightSegmentId,
              filters: getFiltersForRequest(),
            });
          }}
        >
          <EllipsisTooltip
            text={`Explore ${spotlightSegmentValueLabel}`}
          />
        </Button>

        <Button
          type="secondaryBlue"
          onClick={() => {
            setSelectedCard();
            innerPageNavigation({
              tabName: 'overview',
              anchor: 'impact-by-segment',
              firstSegment: spotlightSegmentId,
            });
          }}
        >
          <EllipsisTooltip
            text={`Explore ${getExploreButtonLabel({ value: getSegmentLabel })}`}
          />
        </Button>
      </div>
    </div>
  );
}
