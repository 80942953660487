import icons from 'styles/icons/plan.css';
import {
  get, isEmpty, mapValues, merge, uniq, groupBy,
} from 'lodash';
import { isMasterRegion, isValidUrl } from 'components/utils/utils';

import servicesStore from 'stores/servicesStore';

icons.use();

let schema = {};
let isInitialized = false;

export const UNKNOWN_CHANNEL = 'unmapped';
export const DIRECT_CHANNEL = 'direct';
export const UNKNOWN_LABEL = 'Unmapped';
export const DIRECT_LABEL = 'Direct';
export const NON_ANALYZE_CHANNEL_LABEL = 'Don’t show in Analyze';
export const NON_ANALYZE_CHANNEL = 'nonAnalyzeChannel';

export function initialize(channelsSchema = {}, userChannelsSchema = {}) {
  schema = merge(schema, channelsSchema, userChannelsSchema);
  schema = mapValues(schema, (channel) => {
    const { category, nickname, isUnknownChannel } = channel;
    return {
      department: 'marketing',
      ...channel,
      title: `${category} / ${nickname}`,
      isUnknownChannel: !!isUnknownChannel,
    };
  });
  isInitialized = true;
}

export function getTitle(channel) {
  if (isInitialized && channel) {
    return schema[channel] && schema[channel].title;
  } else {
    servicesStore.logger.error('channels schema is not initialized', { channel, function: 'getTitle' });
    return '';
  }
}

export function getChannelCategory({ channel }) {
  return schema[channel] && schema[channel].category;
}

export function getNickname(channel) {
  if (!isInitialized) {
    servicesStore.logger.error('channels schema is not initialized', { channel, function: 'getNickname' });
    return '';
  }
  if (!channel) {
    return '';
  }
  const nickname = get(schema, [channel, 'nickname'], channel);
  const deletedIndication = schema[channel]?.isDeleted ? ' (deleted)' : '';
  return nickname + deletedIndication;
}

export const getDirectNickname = (directChannel) => (directChannel.includes(UNKNOWN_CHANNEL) ? UNKNOWN_LABEL : DIRECT_LABEL);

export const getDirectIcon = (channel) => (channel === UNKNOWN_CHANNEL ? 'plan:unknown_offline' : `plan:${channel}`);

export const isUnknown = (channel) => channel === UNKNOWN_CHANNEL;

export const isDirectOrUnknown = (channel) => channel === DIRECT_CHANNEL || isUnknown(channel);

export const getChannelNicknameWithDirect = (channel) => (isDirectOrUnknown(channel) ? getDirectNickname(channel) : getNickname(channel));

export const EXTRA_CHANNEL = 'Multi Channel Campaigns';

export const archiveChannel = 'archive';

export function getChannelIcon(channel) {
  if (isInitialized && channel) {
    const channelMetadata = schema[channel];

    if (channel === archiveChannel) {
      return 'plan:archiveChannel';
    }
    if (channel === EXTRA_CHANNEL) {
      return 'plan:multiChannel';
    }
    if (schema[channel] && !schema[channel].isUnknownChannel) {
      return `plan:${channel}`;
    } else if (!isEmpty(channelMetadata) && isValidUrl(channelMetadata.channelIconURL)) {
      return channelMetadata.channelIconURL;
    } else {
      return 'plan:other';
    }
  } else {
    servicesStore.logger.error('channels schema is not initialized', { channel, function: 'getChannelIcon' });
    return '';
  }
}

export function getChannelsWithTitles() {
  if (isInitialized) {
    return Object.keys(schema).map((item) => ({ value: item, label: schema[item].title }));
  }

  return [];
}

export function getChannelsWithNicknames() {
  if (isInitialized) {
    return Object.keys(schema).map((item) => ({
      value: item,
      label: schema[item].nickname,
      category: schema[item].category,
      isDeleted: schema[item].isDeleted,
      channelType: schema[item].channelType,
    }));
  }

  return [];
}

export function getChannelsWithProps() {
  if (isInitialized) {
    let channelsWithProps = schema;
    if (!(DIRECT_CHANNEL in channelsWithProps)) {
      channelsWithProps = {
        ...channelsWithProps,
        [DIRECT_CHANNEL]: {
          nickname: DIRECT_LABEL,
          category: DIRECT_LABEL,
          isUnknownChannel: true,
        },
      };
    }
    if (!(UNKNOWN_CHANNEL in channelsWithProps)) {
      channelsWithProps = {
        ...channelsWithProps,
        [UNKNOWN_CHANNEL]: {
          nickname: UNKNOWN_LABEL,
          category: UNKNOWN_LABEL,
          isUnknownChannel: true,
          isCreatedAutomatic: true,
        },
      };
    }
    return channelsWithProps;
  }

  return {};
}

export const categoriesToChannelsLookup = () => {
  const channelsWithProps = getChannelsWithProps();
  const channelsArray = Object.keys(channelsWithProps).map((k) => ({
    category: channelsWithProps[k].category,
    value: k,
  }));
  channelsArray.push({ value: 'direct', category: 'Direct' });

  const groupedByCategory = groupBy(channelsArray, (channel) => channel.category);
  return Object.keys(groupedByCategory).reduce((acc, categoryKey) => {
    const categoryChannels = groupedByCategory[categoryKey];
    acc[categoryKey] = categoryChannels.map((ch) => ch.value);
    return acc;
  }, {});
};

export function formatChannels(isDisabled = () => false, withOtherChannels = false) {
  const channels = [];
  const categories = Object.keys(schema).map((channel) => schema[channel].category);
  categories.forEach((category) => channels.push({ label: category, options: [] }));
  Object.keys(schema)
    .filter((channel) => !schema[channel].isDeleted
      && (!schema[channel].isUnknownChannel || withOtherChannels))
    .forEach((channel) => {
      const { nickname, category } = schema[channel];
      const categoryObject = channels.find((item) => item.label === category);
      categoryObject.options.push({ label: nickname, value: channel, disabled: isDisabled(channel) });
    });

  return channels;
}

export function output() {
  const channels = schema;
  const filteredChannels = Object.fromEntries(
    Object.entries(channels).filter(([, props]) => !props.isDeleted)
  );

  const result = {
    root: {
      children: [],
    },
  };

  result.root.children.push('other?');
  result['other?'] = {
    channelId: 'other?',
    level: 1,
    title: 'Other*',
    path: undefined,
    isLeaf: true,
    isOther: true,
    id: 'other?',
    minBudget: 0,
    children: null,
  };

  Object.keys(filteredChannels).forEach((key) => {
    const channel = filteredChannels[key];
    const pathTitles = (`${channel.category} / ${channel.nickname}`)
      .split('/')
      .map((item) => item.trim());
    const pathIds = pathTitles.map((item, index) => pathTitles.slice(0, index + 1)
      .map((it) => it.toLowerCase())
      .join('_'));

    pathIds.forEach((id, index) => {
      if (index !== 0) {
        result[pathIds[index - 1]].children.push(id);
        result[pathIds[index - 1]].children.push(`${pathTitles[index - 1]}_other?`);
      } else {
        result.root.children.push(id);
      }

      const isLeaf = index === pathIds.length - 1;

      if (!result[id]) {
        result[id] = {
          channelId: id,
          level: index + 1,
          title: pathTitles[index],
          path: isLeaf ? `${channel.category} / ${channel.nickname}` : null,
          isLeaf,
          id: isLeaf ? key : null,
          minBudget: isLeaf ? channel.minMonthBudget : 0,
          children: !isLeaf ? [] : null,
        };
      }
      if (!result[`${pathTitles[index - 1]}_other?`]) {
        const title = pathTitles.slice(1, index).reduce((a, b) => `${a} / ${b}`, pathTitles[0]);
        result[`${pathTitles[index - 1]}_other?`] = {
          channelId: `${pathTitles[index - 1]}_other?`,
          level: index + 1,
          title: 'Other*',
          path: title,
          isLeaf: true,
          isOther: true,
          id: `${pathTitles[index - 1]}_other?`,
          minBudget: 0,
          children: null,
        };
      }
    });
  });

  Object.keys(result).forEach((key) => {
    const channel = result[key];

    if (channel.children) {
      channel.children = uniq(channel.children)
        .sort((a, b) => {
          const channelA = result[a];
          const channelB = result[b];

          if (!channelA.isLeaf || channelB.isLeaf) {
            return -1;
          }

          return 1;
        });
    }
  });

  return result;
}

export const getUniqRows = (monthsByRegion, region) => {
  const formatData = (data) => [...uniq(data), EXTRA_CHANNEL].sort((a, b) => a.localeCompare(b));

  const isMasterReg = isMasterRegion(region);
  if (!isMasterReg) {
    monthsByRegion = {
      [region]: get(monthsByRegion, [region], []),
    };
  }

  const channelsProps = getChannelsWithProps();
  const result = [];
  for (const months of Object.values(monthsByRegion)) {
    const rowsForRegion = months
      .map(({ channels = {} }) => Object.keys(channels)
        .filter((ch) => channelsProps[ch])
        .map((chKey) => ({
          channel: chKey,
          category: channelsProps[chKey].category,
        })))
      .flat();

    result.push(...rowsForRegion);
  }

  return {
    uniqChannels: formatData(result.map((obj) => obj.channel)),
    uniqCategories: formatData(result.map((obj) => obj.category)),
  };
};
