exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".notice__notice-30U{font-size:16px;font-style:italic;font-weight:300;line-height:18px;letter-spacing:.025em;-webkit-font-smoothing:auto;-moz-osx-font-smoothing:auto}.notice__warning-3i6{color:red}.notice__accent-KMq{color:#24b10e}", ""]);

// exports
exports.locals = {
	"notice": "notice__notice-30U",
	"notice": "notice__notice-30U",
	"warning": "notice__warning-3i6 notice__notice-30U",
	"warning": "notice__warning-3i6 notice__notice-30U",
	"accent": "notice__accent-KMq notice__notice-30U",
	"accent": "notice__accent-KMq notice__notice-30U"
};