import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';
import WidgetHeader from 'components/common/WidgetHeader';
import ButtonWithOptions from 'components/common/ButtonWithOptions';
import Dropdown from 'components/controls/Dropdown';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import ScenariosLineChart from 'components/widgets/whatIf/ScenariosLineChart';
import ScenariosSettings from 'components/widgets/whatIf/ScenariosSettings';
import ConfirmPopup from 'components/ConfirmPopup';

import { widgetTypes } from 'components/pages/analyze/enums';
import {
  timeFrameOptions, defaultScenario, confirmPopupLabels, confirmPopupTypes, amountOfFirstFunnelsToRemove, unclickableItemsKeys,
} from 'components/widgets/whatIf/enums';
import { getAlertUniqueTitle } from 'components/pages/settings/actions/logic/actions';
import { getColor } from 'components/utils/colors';
import { getBaseConfigWithSampleTimeframeMonths, getSelectedMetricsForRequest } from 'components/widgets/whatIf/logic/whatIfScenarios';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { checkIfChartDataIsEmpty } from 'components/utils/logic/trend';

import style from 'styles/analyze/what-if-scenarios.css';

const styles = style.locals || {};

function WhatIfScenarios({
  widgetHeaderConfig,
  widgetHeaderProps = {},
  metricsOptions,
  isLoaded,
  isLoadedBaseData,
  isFailedToLoad,
  scenariosData,
  scenarios,
  sampleTimeframeMonths,
  onSaveScenarioSettings,
  kpiFocus,
  updateKpiFocus,
  timeframe,
  updateTimeframe,
  scenariosBaseData,
  onSaveSampleTimeframeMonths,
  flags,
  emptyStateComponent,
}) {
  useStyles([style]);

  const [isSettingsOpen, setIsSettingsOpen] = useState(true);
  const [isEditingScenarios, setIsEditingScenarios] = useState({});
  const [confirmPopup, setConfirmPopup] = useState({ isShow: false, type: null, selectedValue: null });
  const [scenariosSettings, setScenariosSettings] = useState(cloneDeep(scenarios));
  const [sampleTimeframeMonthsSettings, setSampleTimeframeMonthsSettings] = useState(sampleTimeframeMonths);

  useEffect(() => {
    setScenariosSettings(cloneDeep(scenarios));
  }, [scenarios]);

  if (isFailedToLoad) {
    return (
      <ErrorWidgetWithBlur
        status="error"
        widgetType={widgetTypes.trendAnalysis}
      />
    );
  }

  function onAddNewScenarioType({ type }) {
    const scenariosLabels = scenariosSettings.map((scenario) => scenario.label);
    const uniqueScenarioTitle = getAlertUniqueTitle({ baseTitle: 'Scenario', listOfNames: scenariosLabels });
    const scenarioId = uuidv4();
    setIsEditingScenarios({ ...isEditingScenarios, [scenarioId]: true });
    setScenariosSettings([{
      id: scenarioId,
      label: uniqueScenarioTitle,
      type,
      rules: [{}],
      borderColor: getColor(scenariosSettings.length),
    },
    ...scenariosSettings,
    ]);

    servicesStore.eventTracker.track({
      eventName: Events.whatIfAddedNewScenario,
      properties: {
        scenarioType: type,
      },
    });
  }

  function onChangeKpiFocus({ newKpiFocus }) {
    if (newKpiFocus.value === 'funnel3' && flags.whatIfUnclickableItems[unclickableItemsKeys.supportedKpiOpps]) {
      return;
    }
    if (newKpiFocus.value === 'revenue' && flags.whatIfUnclickableItems[unclickableItemsKeys.supportedKpiRevenue]) {
      return;
    }

    if (newKpiFocus.value === kpiFocus) {
      return;
    }

    if (!isEmpty(unsavedChangesByScenarioId) || scenariosSettings.length > 1) {
      setConfirmPopup({
        ...confirmPopup, isShow: true, selectedValue: newKpiFocus.value, type: confirmPopupTypes.kpiFocus,
      });

      servicesStore.eventTracker.track({
        eventName: Events.whatIfWarningMessageShown,
        properties: {
          changeType: 'KPI',
        },
      });
      return;
    }
    setIsEditingScenarios({});
    updateKpiFocus(newKpiFocus.value);

    servicesStore.eventTracker.track({
      eventName: Events.whatIfChangedPredictedKPI,
      properties: {
        KPI: newKpiFocus.label,
      },
    });
  }

  function confirmDiscardPopupCallback({ abortAction }) {
    if (!abortAction) {
      setConfirmPopup({ isShow: false, selectedValue: null, type: null });
      if (confirmPopup.type === confirmPopupTypes.sampleTimeframeMonths) {
        servicesStore.eventTracker.track({
          eventName: Events.whatIfChangedSamplePeriod,
          properties: {
            monthCount: sampleTimeframeMonthsSettings,
          },
        });

        onSaveSampleTimeframeMonths({ newSampleTimeframeMonths: sampleTimeframeMonthsSettings });
      }
      if (confirmPopup.type === confirmPopupTypes.kpiFocus) {
        const newKpiFocus = confirmPopup.selectedValue;
        servicesStore.eventTracker.track({
          eventName: Events.whatIfChangedPredictedKPI,
          properties: {
            KPI: userStore.getMetricNickname({ metric: newKpiFocus }),
          },
        });

        onSaveScenarioSettings({ newScenarios: defaultScenario, newKpiFocus });
        setScenariosSettings(defaultScenario);
      }
      setIsEditingScenarios({});
      return;
    }

    if (confirmPopup.type === confirmPopupTypes.sampleTimeframeMonths) {
      setSampleTimeframeMonthsSettings(confirmPopup.selectedValue);
    }
    setConfirmPopup({ ...confirmPopup, isShow: false });
  }

  function onSaveSampleTimeframeMonthsSetting() {
    if (sampleTimeframeMonths !== sampleTimeframeMonthsSettings) {
      setConfirmPopup({ isShow: true, selectedValue: sampleTimeframeMonths, type: confirmPopupTypes.sampleTimeframeMonths });
      servicesStore.eventTracker.track({
        eventName: Events.whatIfWarningMessageShown,
        properties: {
          changeType: 'Sample period',
        },
      });
    }
  }

  function onChangesSampleTimeframeMonths({ newSampleTimeframeMonths }) {
    let newSampleTimeframeMonthsValue = newSampleTimeframeMonths;
    if (newSampleTimeframeMonthsValue > 24) {
      newSampleTimeframeMonthsValue = 24;
    }
    if (newSampleTimeframeMonthsValue < 1) {
      newSampleTimeframeMonthsValue = 1;
    }
    setSampleTimeframeMonthsSettings(newSampleTimeframeMonthsValue);
  }

  function resetScenarioSettingsById({ scenarioId }) {
    const scenarioIndex = scenariosSettings.findIndex((scenario) => scenario.id === scenarioId);
    const originalScenarioIndex = scenarios.findIndex((scenario) => scenario.id === scenarioId);
    const newScenarios = [...scenariosSettings];

    if (originalScenarioIndex === -1) {
      newScenarios.splice(scenarioIndex, 1);
    } else {
      newScenarios[scenarioIndex].rules = cloneDeep(scenarios[originalScenarioIndex].rules);
    }
    setScenariosSettings(newScenarios);
  }

  function onChangeTimeframe({ newTimeframe }) {
    if (TIMEFRAME_VALUES.FULL_QUARTER === newTimeframe.value && flags.whatIfUnclickableItems[unclickableItemsKeys.supportedTimeframeThisQuarter]) {
      return;
    }
    if (TIMEFRAME_VALUES.NEXT_QUARTER === newTimeframe.value && flags.whatIfUnclickableItems[unclickableItemsKeys.supportedTimeframeNextQuarter]) {
      return;
    }

    if (newTimeframe.value === timeframe.value) {
      return;
    }
    updateTimeframe({ newTimeframe });

    servicesStore.eventTracker.track({
      eventName: Events.whatIfChangedTimeframe,
      properties: {
        timeframe: newTimeframe.label,
      },
    });
  }

  function onChangeIsSettingsOpen() {
    const newIsSettingsOpen = !isSettingsOpen;
    setIsSettingsOpen(newIsSettingsOpen);

    servicesStore.eventTracker.track({
      eventName: newIsSettingsOpen ? Events.whatIfOpenConfigurationPanel : Events.whatIfClosedConfigurationPanel,
      properties: {
        scenarioCount: scenariosSettings.length,
      },
    });
  }

  function onSaveScenarios() {
    setIsEditingScenarios({});
    const scenariosSettingsForRequest = cloneDeep(scenariosSettings);
    for (const scenario of scenariosSettingsForRequest) {
      for (const rule of scenario.rules) {
        const avgMonthlySpend = scenariosBaseData[rule.channel]?.avgMonthlySpend;
        if (rule.budgetChange && avgMonthlySpend) {
          rule.avgMonthlySpend = avgMonthlySpend;
        }
      }
    }
    onSaveScenarioSettings({ newScenarios: scenariosSettingsForRequest });
  }

  const unsavedChangesByScenarioId = useMemo(() => {
    const unsavedChanges = {};
    if (scenariosSettings.length !== scenarios.length) {
      unsavedChanges.all = true;
    }
    for (const scenarioSetting of scenariosSettings) {
      const scenario = scenarios.find((item) => item.id === scenarioSetting.id);
      if (!scenario || !isEqual(scenario.rules, scenarioSetting.rules)) {
        unsavedChanges[scenarioSetting.id] = true;
      }
    }
    return unsavedChanges;
  }, [scenariosSettings]);

  const widgetHeaderConfigParams = {
    title: 'What If Scenarios',
    type: widgetTypes.whatIf,
    whatIfParams: {
      timeframe,
      sampleTimeframeMonths: sampleTimeframeMonthsSettings,
      kpiFocus,
      scenarios: scenariosSettings,
    },
    ...getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths: sampleTimeframeMonthsSettings }),
    historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
    selectedMetrics: getSelectedMetricsForRequest(),
    segmentsAnalysisParams: { firstSegment: 'channel', secondSegment: null },
    conversionIndicator: kpiFocus,
    ...(widgetHeaderConfig || {}),
  };

  const predictedKPIOptions = metricsOptions.slice(amountOfFirstFunnelsToRemove);
  const isChartDataIsEmpty = checkIfChartDataIsEmpty({ chartData: scenariosData, keyToSkip: 'name' });

  return (
    <div>
      <WidgetHeader
        {...widgetHeaderProps}
        widgetHeaderConfig={widgetHeaderConfigParams}
        isShowTimeframeSelect={false}
        isShowFilterContainer={false}
        isShowAttributionModel={false}
      >
        <Dropdown
          selectedKey={kpiFocus}
          options={predictedKPIOptions}
          onChange={(newKpiFocus) => onChangeKpiFocus({ newKpiFocus })}
          dropdownLabel="Predicted KPI"
        />
        <Dropdown
          selectedKey={timeframe.value}
          options={timeFrameOptions}
          onChange={(newTimeframe) => onChangeTimeframe({ newTimeframe })}
          dropdownLabel="Timeframe"
        />
        <ButtonWithOptions
          options={[{
            label: 'Channel-specific',
            action: () => {
              onAddNewScenarioType({ type: 'channel' });
            },
          }, {
            label: 'Overall budget',
            action: () => {
              if (flags.whatIfUnclickableItems[unclickableItemsKeys.budgetScenario]) {
                return;
              }
              onAddNewScenarioType({ type: 'budget' });
            },
          }]}
          renderMenuButton={() => (
            <Button type="secondaryBlue" dataTestId="add-scenario-button">
              + Add Scenario
            </Button>
          )}
          popupClassName={styles.optionPopupMenu}
        />
      </WidgetHeader>

      {emptyStateComponent && isChartDataIsEmpty && isLoaded ? (
        emptyStateComponent
      ) : (
        <div className={styles.wrapper}>
          <ScenariosLineChart
            isSettingsOpen={isSettingsOpen}
            chartData={scenariosData}
            kpiFocus={kpiFocus}
            scenarios={scenariosSettings}
            isLoaded={isLoaded}
          />
          <ScenariosSettings
            isSettingsOpen={isSettingsOpen}
            setIsSettingsOpen={() => onChangeIsSettingsOpen()}
            scenarios={scenariosSettings}
            setScenarios={(newScenarios) => setScenariosSettings(newScenarios)}
            scenariosBaseData={scenariosBaseData}
            onSaveScenarioSettings={() => onSaveScenarios()}
            kpiFocus={kpiFocus}
            totalAmountScenariosData={scenariosData[scenariosData.length - 1]}
            isLoaded={isLoaded}
            isLoadedBaseData={isLoadedBaseData}
            sampleTimeframeMonths={sampleTimeframeMonthsSettings}
            setSampleTimeframeMonths={(newSampleTimeframeMonths) => onChangesSampleTimeframeMonths({ newSampleTimeframeMonths })}
            saveSampleTimeframeMonths={() => onSaveSampleTimeframeMonthsSetting()}
            isEditingScenarios={isEditingScenarios}
            setIsEditingScenarios={setIsEditingScenarios}
            unsavedChangesByScenarioId={unsavedChangesByScenarioId}
            resetScenarioSettingsById={({ scenarioId }) => resetScenarioSettingsById({ scenarioId })}
            timeframe={timeframe}
          />
        </div>
      )}

      <ConfirmPopup
        hidden={!confirmPopup.isShow}
        callback={(abortAction) => confirmDiscardPopupCallback({ abortAction })}
        confirmBtn="I understand"
        title={`Are you sure you want to change the ${confirmPopupLabels[confirmPopup.type]}?`}
        description={`Changing the ${confirmPopupLabels[confirmPopup.type]} will remove all scenario cards and reset your baseline settings.`}
        isTitleBold
      />
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    attributionStore: {
      metricsOptions,
    },
  }) => ({
    metricsOptions,
  }),
  observer
)(WhatIfScenarios));
