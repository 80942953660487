import React from 'react';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import Select from 'components/controls/Select';
import FilterActions from 'components/pages/users/Filters/FilterActions';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

export default class PreDefinedFilter extends Component {
  handleFilterApply = () => this.props.onAddFilter();

  setFilterVariant = ({ value: indicator }) => {
    const { metricsCustomFilters, addFilterToSelection } = this.props;
    const filtersToApply = metricsCustomFilters[indicator];
    addFilterToSelection(filtersToApply, indicator);
  };

  render() {
    const { onCancel, preDefinedIndicator, metricsCustomFilters } = this.props;

    const indicators = Object.keys(metricsCustomFilters)
      .map((indicator) => ({ value: indicator, label: userStore.getMetricNickname({ metric: indicator, isSingular: true }) }));
    return (
      <div className={styles.filterConfig}>
        <div className={styles.filterContent}>
          <header className={styles.filterHeader}>Find journeys with pre-defined filter</header>
          <div style={{ marginBottom: 10 }} className={styles.filterVariantDesc}>Select funnel stage:</div>
          <div className={styles.filterVariant}>
            <Select
              selected={preDefinedIndicator}
              className={styles.filterVariantSelect}
              select={{ options: indicators }}
              onChange={this.setFilterVariant}
            />
          </div>
        </div>
        <FilterActions
          onApply={this.handleFilterApply}
          onCancel={onCancel}
          filtersItems={this.props.filtersItems}
        />
      </div>
    );
  }
}
