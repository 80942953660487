import React from 'react';
import classnames from 'classnames';
import { cloneDeep, isEmpty, throttle } from 'lodash';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import TopBar from 'components/pages/dashboard/dashboardNavigate/TopBar';
import GoalPlaceholder from 'components/pages/dashboard/GoalPlaceholder';
import Goal from 'components/pages/dashboard/Goal';
import Skeleton from 'components/common/Skeleton';

import { PACING_METHODS, compose } from 'components/utils/utils';
import { widgetTypes } from 'components/pages/analyze/enums';
import { isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { pickerTimeframeToFrequencyType, activityStatusValues, goalSkeletonId } from 'components/pages/settings/goals/logic/enums';
import { getTimeFrameInAccountTZ } from 'components/utils/goals';
import { getWidgetsDataV2, getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';

import style from 'styles/dashboard/top-goals.css';

const enhance = compose(
  inject(({
    widgetsAnalysisStore: {
      dataPerWidget: {
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
      },
      getWidgetRequestId,
      resetWidgetData,
    },
  }) => ({
    goalsAnalysis,
    getWidgetRequestId,
    resetWidgetData,
  })),
  observer
);

class TopGoalsChart extends Component {
  style = style;

  constructor(props) {
    super(props);

    this.state = { startIndex: 0, isInitialLoad: null };
    this.updateWidth = throttle(this._updateWidth, 500);
  }

  componentDidMount() {
    this.setState({ isInitialLoad: true });
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    const didInitialLoadChanged = this.state.isInitialLoad !== prevState.isInitialLoad;
    const didRegionChanged = this.props.region !== prevProps.region;
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame: this.props.timeframe, prevTimeFrame: prevProps.timeframe });

    if (didInitialLoadChanged || didTimeFrameChanged || didRegionChanged) {
      const timeframeTZ = getTimeFrameInAccountTZ({ timeframe: this.props.timeframe });
      getWidgetsDataV2({
        widget: widgetTypes.goalsAnalysis,
        widgetConfig: {
          goalsAnalysisParams: {
            frequencyType: pickerTimeframeToFrequencyType[this.props.timeframe?.value],
            timeframe: timeframeTZ,
            supportFilters: true,
            status: activityStatusValues.ALL,
          },
        },
        addBaseAnalyzeConfig: false,
      });
    }
  }

  get shownItems() {
    return this.state.width < 1240 ? 1 : 2;
  }

  get showLeft() {
    return this.state.startIndex > 0;
  }

  showRight({ shownGoals }) {
    return this.state.startIndex < (shownGoals.length - this.shownItems);
  }

  setIndex = (startIndex) => this.setState({ startIndex });

  _updateWidth = () => this.setState({ width: window.innerWidth });

  render() {
    const { classes } = this;
    let { startIndex } = this.state;
    const {
      goalsAnalysis,
      containerClass,
      redirect,
      pacingMethod,
      getWidgetRequestId,
      timeframe,
    } = this.props;

    const goalsAnalysisFullConfig = getWidgetFullConfig({
      widgetConfig: {
        goalsAnalysisParams: {
          frequencyType: pickerTimeframeToFrequencyType[timeframe?.value],
          timeframe: getTimeFrameInAccountTZ({ timeframe: this.props.timeframe }),
          supportFilters: true,
          status: activityStatusValues.ALL,
        },
      },
      addBaseAnalyzeConfig: false,
    });
    const goalsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.goalsAnalysis, widgetConfig: goalsAnalysisFullConfig });

    const goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];
    const isGoalAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished';

    const usePacingForGoal = [PACING_METHODS.PACING_FOR, PACING_METHODS.PACING_FOR_ON_LAST_DAY].includes(pacingMethod);
    const outOfRange = startIndex + this.shownItems > goalsAnalysisData.length;
    startIndex = outOfRange ? goalsAnalysisData.length - this.shownItems : startIndex;
    const shownGoals = cloneDeep(goalsAnalysisData).splice(startIndex, this.shownItems);

    if (!isGoalAnalysisLoaded && goalsAnalysisData.length < 2) {
      shownGoals.push({ id: goalSkeletonId });
    }

    const isGoalsEmpty = isEmpty(shownGoals);

    return (
      <div className={classnames(containerClass, classes.topGoals)}>
        <TopBar titleText="Goals" />
        {this.showLeft && (
        <div
          className={classnames(classes.control, classes.controlLeft)}
          onClick={() => this.setIndex(startIndex - 1)}
        >
          <div className={classes.arrowWrapper}>
            <div className={classnames(classes.arrow, classes.arrowInverted)} />
          </div>
        </div>
        )}
        {this.showRight && (
        <div
          className={classnames(classes.control, classes.controlRight)}
          onClick={() => this.setIndex(startIndex + 1)}
        >
          <div className={classes.arrowWrapper}>
            <div className={classnames(classes.arrow)} />
          </div>
        </div>
        )}
        <div className={classes.topGoalsChart}>
          {isGoalsEmpty ? (
            <GoalPlaceholder redirect={redirect} />
          ) : shownGoals.map((goal) => (goal.id === goalSkeletonId ? (
            <div className={classes.skeleton}>
              <Skeleton borderRadius="100px 100px 0 0" height={100} width={200} isLightTheme />
              <Skeleton height={10} width={200} isLightTheme />
              <div className={classes.skeletonFooter}>
                <Skeleton height={18} width={34} isLightTheme />
                <Skeleton height={18} width={34} isLightTheme />
              </div>
            </div>
          ) : (
            <Goal
              key={goal.id}
              goal={goal}
              usePacingForGoal={usePacingForGoal}
              container={classes.goalItemContainer}
            />
          )))}
        </div>
      </div>
    );
  }
}

export default enhance(TopGoalsChart);
