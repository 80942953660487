import React from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import filterContainerStyle from 'styles/users/filters.css';

import { useCampaignsContext } from './CampaignsContext';
import RulesFields from './RulesFields';

const styles = filterContainerStyle.locals;

function RulesItem({
  selectedPlatform, rulesItemData, rulesGroupIndex, isSingleRulesGroup, isLastGroupItem,
}) {
  const { onAddOrRulesFieldHandel, isPreDefineFilter } = useCampaignsContext();

  const isHideDeleteButton = isSingleRulesGroup && rulesItemData.length === 1;

  return (
    <div className={classNames(styles.ruleItemWrapper, !isLastGroupItem && styles.addOrTextOnBorder)}>
      {rulesItemData.map((rulesItem, rowIndex) => {
        const isLastRulesItem = rulesItemData.length === rowIndex + 1;
        return (
          <RulesFields
            key={`RulesFields${rowIndex}`}
            rulesGroupIndex={rulesGroupIndex}
            rowIndex={rowIndex}
            isLastRulesItem={isLastRulesItem}
            isHideDeleteButton={isPreDefineFilter ? false : isHideDeleteButton}
            selectedPlatform={selectedPlatform}
            rulesItem={rulesItem}
          />
        );
      })}
      {isLastGroupItem
        && (
        <Button
          onClick={() => onAddOrRulesFieldHandel()}
          type="secondaryBlue"
        >
          Add New Rule (OR)
        </Button>
        )}
    </div>
  );
}

export default RulesItem;
