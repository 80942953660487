import React from 'react';
import { inject, observer } from 'mobx-react';

import ImpactByIndicator from 'components/pages/analyze/OverviewTab/ImpactByIndicator';
import attributionStore from 'stores/attributionStore';

const ImpactByIndicatorWrapper = ({
  isLoaded,
  impactIndicator,
  widgetHeaderConfig,
  updateWidgetImpactByIndicator,
  selectedReport,
  marketingVsSalesFrequency,
  marketingVsSales,
  prevMarketingVsSales,
  updateWidgetImpactByIndicatorFrequency,
  widgetHeaderProps,
}) => {
  const metricsOptions = attributionStore.getMetricOptions();
  const reportId = selectedReport.value;
  const { widgetId } = widgetHeaderConfig;

  const updateImpactIndicator = (value) => {
    updateWidgetImpactByIndicator({ reportId, widgetId, impactIndicator: value });
  };

  const onChangeFrequency = (event) => {
    updateWidgetImpactByIndicatorFrequency({ reportId, widgetId, marketingVsSalesFrequency: event.value });
  };

  return (
    <ImpactByIndicator
      metricsOptions={metricsOptions}
      isLoaded={isLoaded}
      frequencyControl={onChangeFrequency}
      currentFrequency={marketingVsSalesFrequency}
      impactIndicator={impactIndicator}
      updateImpactIndicator={updateImpactIndicator}
      impactData={marketingVsSales}
      impactPrevData={prevMarketingVsSales}
      widgetHeaderConfig={widgetHeaderConfig}
      widgetHeaderProps={widgetHeaderProps}
      isReadOnly={widgetHeaderConfig.isReadOnly}
    />
  );
};

export default inject(({
  reportsStore: {
    updateWidgetImpactByIndicator,
    updateWidgetImpactByIndicatorFrequency,
    selectedReport,
  },

}) => ({
  updateWidgetImpactByIndicator,
  updateWidgetImpactByIndicatorFrequency,
  selectedReport,
}),
observer)(ImpactByIndicatorWrapper);
