import React from 'react';
import { Button } from '@infinigrow/libs';

import style from 'styles/controls/button.css';

const styles = style.locals;

export default function ToggleButton({ toggleExpand, isExpanded, title = 'Summary' }) {
  return (
    <Button
      style={{ marginLeft: '15px', border: 'solid 1px rgba(178, 187, 213, 0.5)', color: '#212a42' }}
      onClick={() => toggleExpand}
      containerClassName={styles.buttonControls}
    >
      {`${isExpanded ? 'Hide' : 'Show'} ${title}`}
    </Button>
  );
}
