export function getTableColumnsNames({ tableData = [] }) {
  const tableColumnsNames = new Set();
  for (const row of tableData) {
    for (const key of Object.keys(row)) {
      if (key === 'entityId') {
        continue;
      }
      tableColumnsNames.add(key);
    }
  }
  return Array.from(tableColumnsNames);
}
