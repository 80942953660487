import React, { useState } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import history from 'history';
import { Slide, toast, ToastContainer } from 'react-toastify';

import Loader from 'components/controls/Loader';
import Report from 'components/pages/reports/Report';
import FolderCard from 'components/pages/reports/FolderCard';
import FolderActionsPopups from 'components/pages/reports/FolderActionsPopups';
import ConfirmPopup from 'components/ConfirmPopup';
import CreateNewReportPopup from 'components/pages/reports/CreateNewReportPopup';

import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { parseReportsByFoldersKey, searchFoldersAndReports } from 'components/pages/reports/logic/reports';
import { folderActionsKeys } from 'components/pages/reports/enums';

import appStyle from 'styles/app.css';
import pageStyle from 'styles/page.css';
import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals;

const Reports = ({
  isReportsRequestLoaded,
  foldersList,
  reportsList,
  searchQuery,
  deleteFolderRequest,
  deleteReportRequest,
  setSelectedReport,
  location: {
    state: locationState = {},
  } = {},
}) => {
  if (!isReportsRequestLoaded) {
    return (
      <Loader newStyle />
    );
  }

  if (reportsList.length === 0 && foldersList.length === 1) {
    history.push({
      pathname: '/reports/new',
      state: {
        isNoReports: true,
      },
    });
  }

  if (getQueryParams({ queryParamKey: 'reportId' })) {
    return (
      <Report isOnboarding={!!locationState.isOnboarding} />
    );
  }

  function onSelectedReportFromFolder({ report }) {
    setSelectedReport(report);
    history.push(`/reports?reportId=${report.value}`);
  }

  const [folderActionsPopupType, setFolderActionsPopupType] = useState(null);
  const [selectedCardItem, setSelectedCardItem] = useState({ report: null, folder: null });
  const [showConfirmPopup, setShowConfirmPopup] = useState(null);
  const [showCreateNewReportPopup, setShowCreateNewReportPopup] = useState({ isShow: false, isRename: false, isDuplicate: false });

  const { foldersResults, reportsResults } = searchFoldersAndReports({ searchValue: searchQuery, folders: foldersList, reports: reportsList });
  const reportsByFolderId = parseReportsByFoldersKey({ folders: foldersResults, reportsList: reportsResults });

  function getOptionMenuByFolderAndReport({ folder, report, type }) {
    if (type === 'report') {
      return [
        {
          label: 'Edit report name',
          action: () => {
            setSelectedCardItem({ folder: null, report });
            setShowCreateNewReportPopup({ isShow: true, isRename: true, isDuplicate: false });
          },
        },
        {
          label: 'Move to folder',
          action: () => {
            setSelectedCardItem({ folder, report });
            setFolderActionsPopupType(folderActionsKeys.moveToFolder);
          },
        },
        {
          label: 'Delete report',
          action: () => {
            setSelectedCardItem({ folder: null, report });
            setShowConfirmPopup(folderActionsKeys.deleteReport);
          },
        },
      ];
    }
    return [
      {
        label: 'Create new report',
        action: () => {
          history.push({
            pathname: '/reports/new',
            state: { originFolderId: folder.value },
          });
        },
      },
      {
        label: 'Edit folder name',
        action: () => {
          setFolderActionsPopupType(folderActionsKeys.renameFolder);
          setSelectedCardItem({ folder, report: null });
        },
      },
      {
        label: 'Delete folder',
        action: () => {
          setSelectedCardItem({ folder, report: null });
          setShowConfirmPopup(folderActionsKeys.deleteFolder);
        },
      },
    ];
  }

  function confirmDeletePopupCallBack({ abortAction, type }) {
    if (!abortAction) {
      if (type === folderActionsKeys.deleteReport) {
        deleteReportRequest({ reportId: selectedCardItem.report.value });
        toastSuccessMessage('Report deleted successfully!');
      }

      if (type === folderActionsKeys.deleteFolder) {
        deleteFolderRequest({ folderId: selectedCardItem.folder.value });
        toastSuccessMessage('Folder deleted successfully!');
      }
    }
    setShowConfirmPopup(null);
  }

  function toastSuccessMessage(message = 'Changes saved successfully!') {
    toast.success(message, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: message,
      style: { width: '320px', fontSize: '14px' },
    });
  }

  return (
    <>
      <div className={appStyle.locals.wrap}>
        <div
          className={classNames(pageStyle.locals.container, pageStyle.locals.analyzeContainer, styles.reportContainer)}
        >
          <div className={styles.foldersWrapper}>
            <div className={styles.foldersHeader}>
              <div className={styles.folderName}>Name</div>
              <div className={styles.reportCount}>Reports</div>
              <div className={styles.modifiedDate}>Last modified</div>
              <div className={styles.optionMenu} />
            </div>
            <div className={styles.foldersTable}>
              {foldersResults.map((folder) => {
                const reportsData = reportsByFolderId[folder.value];
                return (
                  <FolderCard
                    folder={folder}
                    reportsData={reportsData}
                    optionsMenu={({ folder: currentFolder, report, type }) => getOptionMenuByFolderAndReport({ folder: currentFolder, report, type })}
                    onSelectedReport={(report) => onSelectedReportFromFolder({ report })}
                    key={`folder-${folder.value}-${folder.label}`}
                    searchQuery={searchQuery}
                  />
                );
              })}
              {foldersResults.length === 0 && searchQuery !== '' && (
              <div className={styles.noResults}>No results. Try a different search</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {(showConfirmPopup === folderActionsKeys.deleteFolder || showConfirmPopup === folderActionsKeys.deleteReport) && (
      <ConfirmPopup
        callback={(abortAction) => confirmDeletePopupCallBack({ abortAction, type: showConfirmPopup })}
        confirmBtn="Delete"
        title={
          showConfirmPopup === folderActionsKeys.deleteReport
            ? `Are you sure you want to delete ${selectedCardItem.report.label} report?`
            : `Are you sure you want to delete ${selectedCardItem.folder.label} folder?`
          }
      />
      )}

      {folderActionsPopupType && (
        <FolderActionsPopups
          updateActionType={(action) => setFolderActionsPopupType(action)}
          type={folderActionsPopupType}
          selectedFolder={selectedCardItem.folder}
          selectedReport={selectedCardItem.report}
          toastSuccessMessage={(message) => toastSuccessMessage(message)}
        />
      )}

      {showCreateNewReportPopup.isShow && (
      <CreateNewReportPopup
        title={showCreateNewReportPopup.isRename ? 'Edit report name' : 'Create new report'}
        actionText={showCreateNewReportPopup.isRename ? 'Done' : 'Create'}
        onClosePopup={() => setShowCreateNewReportPopup({ isShow: false, isRename: false })}
        isRenameMode={showCreateNewReportPopup.isRename}
        toastSuccessMessage={(message) => toastSuccessMessage(message)}
        folderId={selectedCardItem.folder?.value}
        selectedReport={selectedCardItem.report}
      />
      )}

      <ToastContainer
        style={{ width: '400px', fontSize: '12px' }}
        position="bottom-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />
    </>

  );
};

export default inject(({
  reportsStore: {
    isReportsRequestLoaded,
    foldersList,
    reportsList,
    searchQuery,
    deleteFolderRequest,
    deleteReportRequest,
    setSelectedReport,
  },
}) => ({
  isReportsRequestLoaded,
  foldersList,
  reportsList,
  searchQuery,
  deleteFolderRequest,
  deleteReportRequest,
  setSelectedReport,
}),
observer)(Reports);
