import React from 'react';
import copy from 'copy-to-clipboard';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import history from 'history';
import { disablePopupMode } from 'modules/popup-mode';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';
import AttributionSetupContent from 'components/pages/attributionSetup/AttributionSetupContent';
import PlanPopup, { TextContent as PopupTextContent } from 'components/pages/plan/Popup';
import { StepByStepGuide } from 'components/pages/attributionSetup/StepByStepGuide';
import TagManagerAutomaticPopup from 'components/pages/attributionSetup/TagManagerAutomaticPopup';
import { compose } from 'components/utils/utils';

import style from 'styles/attribution/attribution-setup.css';
import onBoardingStyle from 'styles/onboarding/onboarding.css';
import planStyles from 'styles/plan/plan.css';
import textFieldStyles from 'styles/controls/textfield.css';

const SELECTED_METHODS = {
  over: 'over',
  code: 'code',
};

const enhance = compose(
  inject((stores) => {
    const {
      userMonthPlan: {
        sixsenseapi,
      },
    } = stores.userStore;

    return {
      sixsenseapi,
    };
  }),
  observer
);

class AttributionSetup extends Component {
  style = style;

  styles = [onBoardingStyle, planStyles];

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      selectedMethod: '',
    };
  }

  componentDidMount() {
    const code = this.getCode({ UID: this.props.UID, sixSenseApiToken: this.props.sixsenseapi?.tokens.apiKey });
    this.setState({ code });
  }

  getCode({ UID, sixSenseApiToken }) {
    let tokenDeclaration = '';
    let version = '2.0.0';
    if (sixSenseApiToken) {
      tokenDeclaration = `p.GlobalInfinigrowObject.sixSenseToken = '${sixSenseApiToken}';`;
      version = '2.1.0';
    }

    return `<script type="text/javascript" async=1>
      ;(function (p, l, o, w, i, n, g) {
        if (!p[i]) {
          p.GlobalInfinigrowObject = {};
          p.GlobalInfinigrowObject.userId = '${UID}';
          p.GlobalInfinigrowObject.InfinigrowNamespace = i;
          ${tokenDeclaration}
    
          p[i] = function () {
            (p[i].q = p[i].q || []).push(arguments);
          };
          p[i].q = p[i].q || [];
    
          n = l.createElement(o);
          g = l.getElementsByTagName(o)[0];
          n.async = 1;
          n.src = w;
          g.parentNode.insertBefore(n, g);
        }
      }(window, document, 'script', "https://ddzuuyx7zj81k.cloudfront.net/${version}/attributionSnippet.js", 'infinigrow'));
    </script>`;
  }

  finishAttributionSetup = async () => {
    await disablePopupMode();
    this.setState({ currentStep: 0 });
    window.location.href = '/plan/annual';
  };

  getPopupTitleText(step) {
    return step === 0 ? 'Choose method' : step === 1 ? 'Copy snippet' : 'Great work!';
  }

  getSelectedMethod(id) {
    this.setState({ selectedMethod: id });
  }

  getPopupTitle = () => (
    <div className={style.locals.popupTitleWrapper} data-testid="copy-snippet-label">
      <h3 className={style.locals.popupTitle}>{this.getPopupTitleText(this.state.currentStep)}</h3>
      { // REMOVE COMMENT AFTER FIXING THE VALIDATOR REQUEST
      /* {this.state.currentStep === 1 && (
        <div>
          <Button
            className={`${style.locals.popupButton} ${style.locals.testConnectionButton}`}
            onClick={() => {
              this.props.getScriptValidator().then((res) => {
                let success = false;
                if (res) {
                  success = true;
                }
                this.setState({ show: true, success });
              });
            }}
          >
            Test my site connection
          </Button>
          <EmbeddedScriptValidation show={this.state.show} success={this.state.success} close={() => this.setState({ show: false, currentStep: 2 })} />
        </div>
      )} */}
      <div className={style.locals.popupSteps}>
        <div className={style.locals.popupStepWrapper}>
          {this.state.currentStep + 1 > 1 && (
            <div
              data-icon="icons:check-circled-blue"
              className={style.locals.popupStepIcon}
            />
          )}
          <span className={this.state.currentStep === 0 ? style.locals.popupStepCurrent : style.locals.popupStep}>1</span>
        </div>
        <div className={style.locals.popupStepWrapper}>
          {this.state.currentStep + 1 > 2 && (
            <div
              data-icon="icons:check-circled-blue"
              className={style.locals.popupStepIcon}
            />
          )}
          <span className={this.state.currentStep === 1 ? style.locals.popupStepCurrent : style.locals.popupStep}>2</span>
        </div>
      </div>
    </div>
  );

  getPopupContent = () => (this.state.currentStep === 0 ? (
    <div className={style.locals.chooseMethodStep}>
      <div
        className={`${style.locals.popupButtonOption} ${this.state.selectedMethod === SELECTED_METHODS.over ? style.locals.popupButtonOptionCurrent : ''}`}
        id={SELECTED_METHODS.over}
        onClick={this.getSelectedMethod.bind(this, SELECTED_METHODS.over)}
        data-testid="google-tag-manager-label"
      >
        <div className={style.locals.overIcon} />
        <span className={style.locals.popupButtonLabel}>+ Google Tag Manager</span>
      </div>
      <div
        className={`${style.locals.popupButtonOption} ${this.state.selectedMethod === SELECTED_METHODS.code ? style.locals.popupButtonOptionCurrent : ''}`}
        id={SELECTED_METHODS.code}
        onClick={this.getSelectedMethod.bind(this, SELECTED_METHODS.code)}
        data-testid="copy-code-snippet-label"
      >
        <div className={style.locals.codeIcon} />
        <span className={style.locals.popupButtonLabel}>Copy code snippet to website</span>
      </div>
    </div>
  ) : this.state.currentStep === 1 ? (
    <div className={style.locals.popupSnippetWrapper}>
      <pre className={style.locals.snippetPopup}>
        {this.state.code}
      </pre>
      <div className={style.locals.popupSnippetButtons}>
        {this.props.sendSnippetEmail
          ? (
            <div>
              <Button
                className={style.locals.sendEmailButton}
                onClick={() => this.refs.sendSnippetPopup.open()}
              >
                Send instructions via email
              </Button>
              <PlanPopup
                onClose={() => {
                  this.setState({ to: null });
                }}
                ref="sendSnippetPopup"
                style={{
                  width: 'max-content',
                  left: '253px',
                }}
                title="Send Script"
              >
                <PopupTextContent>
                  <div style={{ display: 'inline-flex' }}>
                    <input
                      type="email"
                      value={this.state.to || ''}
                      onChange={(e) => this.setState({ to: e.target.value })}
                      placeholder="email"
                      className={textFieldStyles.locals.input}
                    />
                    <Button
                      type="primaryBlue"
                      onClick={() => {
                        this.props.sendSnippetEmail(this.props.senderEmail, this.props.UID, this.state.to);
                        this.refs.sendSnippetPopup.close();
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </PopupTextContent>
              </PlanPopup>
            </div>
          )
          : null}
        <Button
          type="secondaryWhite"
          onClick={() => copy(this.state.code)}
        >
          Copy
        </Button>
      </div>
    </div>
  ) : (
    <p className={style.locals.successMessage}>Success!</p>
  ));

  onPopupButtonClick() {
    if (this.state.currentStep === 1) {
      this.setState({ currentStep: 0 });
    } else {
      history.push('/settings/profile/integrations');
    }
  }

  render() {
    const {
      UID, senderEmail, isPopup, sendSnippetEmail, isStaticPage,
    } = this.props;

    return (
      <Page
        popup={isPopup}
        className={!isPopup ? this.classes.static : null}
        width={isPopup ? '685px' : '100%'}
      >
        {isPopup ? this.getPopupTitle() : null}
        {isPopup ? <TagManagerAutomaticPopup ref="popup" snippetScript={this.state.code} /> : null}
        {isPopup
          ? <div className={style.locals.popupContentWrapper}>{this.getPopupContent()}</div>
          : (
            <AttributionSetupContent
              UID={UID}
              senderEmail={senderEmail}
              sendSnippetEmail={sendSnippetEmail}
              isStaticPage={isStaticPage}
              code={this.state.code}
              getScriptValidator={this.props.getScriptValidator}
            />
          )}
        {isPopup
          ? (
            <div className={style.locals.popupFooter}>
              <div className={style.locals.popupFooterButtons} data-testid="finish-button">
                <Button
                  type="secondaryWhite"
                  className={style.locals.popupButton}
                  onClick={() => this.onPopupButtonClick()}
                >
                  Back
                </Button>
                {this.state.currentStep !== 1
                  && (
                    <div data-testid="next-snippet-button">
                      <Button
                        type="secondaryWhite"
                        className={style.locals.popupButton}
                        onClick={() => {
                          if (this.state.selectedMethod === SELECTED_METHODS.over) {
                            this.refs.popup.open();
                            return;
                          }
                          this.setState((prevState) => ({ ...prevState, currentStep: prevState.currentStep + 1 }));
                        }}
                        disabled={!this.state.selectedMethod}
                      >
                        Next
                      </Button>
                    </div>
                  )}
              </div>
              {this.state.currentStep === 1
              && (
                <div data-testid="finish-snippet-button">
                  <Button
                    type="primaryBlue"
                    className={classNames(style.locals.popupButton, this.state.currentStep !== 1 ? style.locals.popupButtonLeft : style.locals.popupButtonRight)}
                    onClick={() => this.finishAttributionSetup()}
                  >
                    Finish
                  </Button>
                </div>
              )}
              {this.state.currentStep !== 2 && <StepByStepGuide />}
            </div>
          )
          : <div style={{ paddingBottom: '60px' }} />}
      </Page>
    );
  }
}

export default enhance(AttributionSetup);
