import { ruleOperators } from 'components/pages/settings/customSegments/enums';

function checkIfGroupPropertiesAreFilled({ group }) {
  for (const orRulesGroup of group.rules) {
    for (const rule of orRulesGroup) {
      if (!rule.operation) {
        return false;
      }
      const isOperationEqualsToIsEmpty = rule.operation === ruleOperators.isEmpty;
      if (isOperationEqualsToIsEmpty) {
        continue;
      }

      const isEmptyValue = rule.value == null || rule.value === '';
      const isEmptyOperation = rule.operation == null || rule.operation === '';
      if ((isEmptyValue && !isOperationEqualsToIsEmpty) || isEmptyOperation) {
        return false;
      }
    }
  }
  return true;
}

export function checkIfAllPropertiesAreFilled({ segmentsProperties }) {
  const segmentGroups = segmentsProperties.groups;

  if (!segmentsProperties.name || !segmentsProperties.crmFieldId) {
    return false;
  }

  for (const segmentGroup of segmentGroups) {
    if (!segmentGroup.name) {
      return false;
    }
    const isGroupFilled = checkIfGroupPropertiesAreFilled({ group: segmentGroup });
    if (!isGroupFilled) {
      return false;
    }
  }
  return true;
}

export function getAllFilledGroupsRules({ groups }) {
  const filledGroupsRules = [];
  for (const group of groups) {
    const isGroupFilled = checkIfGroupPropertiesAreFilled({ group });
    if (isGroupFilled) {
      filledGroupsRules.push(group);
    }
  }
  return filledGroupsRules;
}
