import { inject, observer } from 'mobx-react';
import React, { useMemo } from 'react';
import classnames from 'classnames';

import Dropdown from 'components/controls/Dropdown';
import Textfield from 'components/controls/Textfield';
import ConditionTypeDropdown from 'components/pages/settings/customSegments/ConditionTypeDropdown';

import { suggestionsTypesToFilterKind } from 'components/pages/questions/enums';
import { isNumericRuleOperators, ruleOperators } from 'components/pages/settings/customSegments/enums';

import useStyles from 'hooks/useStyles';
import style from 'styles/settings/custom-segments/custom-segments.css';
import channelsTabStyle from 'styles/settings/channels/channels-tab.css';

const styles = style.locals || {};
const channelsTabStyles = channelsTabStyle.locals || {};

function MappingRule({
  rule,
  onAddRule,
  canBeDeleted,
  onDeleteRule,
  bucketOptions,
  onAddRuleGroup,
  onChangeBucketRule,
  shouldEnableNewRule,
  filtersData,
  getFilterKindOptions,
  filterType,
  originalFieldId,
}) {
  useStyles([style, channelsTabStyle]);

  const filterDataConfig = useMemo(() => {
    const filterDataByType = filtersData?.find(
      (filter) => filter.kind === suggestionsTypesToFilterKind[filterType]
    );
    return filterDataByType || {};
  }, [filterType, originalFieldId, filtersData]);

  const shouldDisableDropdown = !filterDataConfig.isSearchable;

  return (
    <div
      className={styles.bucketRuleItem}
      data-testid="mapping-rule"
    >
      {canBeDeleted ? (
        <div
          className={classnames(channelsTabStyles.minusIcon, styles.minusIcon)}
          onClick={() => onDeleteRule()}
          data-testid="delete-rule"
        />
      ) : null}

      <Dropdown
        options={bucketOptions}
        className={channelsTabStyles.fieldHalf}
        selectedKey={rule.operation}
        onChange={(event) => {
          onChangeBucketRule({ value: event.value, type: 'operation' });
          onChangeBucketRule({ value: '', type: 'value' });
        }}
      />

      {rule.operation === ruleOperators.equals
        ? (
          <Dropdown
            id={originalFieldId}
            selectedKey={rule.value || null}
            placeholder={rule.value || 'Search...'}
            onChange={(event) => {
              onChangeBucketRule({ value: event.value, type: 'value' });
            }}
            searchFunction={(searchValue, offset) => getFilterKindOptions({
              searchValue, filterKind: filterDataConfig.kind, filterKey: originalFieldId, offset,
            })}
            disabled={shouldDisableDropdown}
            isAsyncPagination
            isSearchable
          />
        )
        : (
          <Textfield
            placeHolder="Enter value"
            value={rule.value}
            onChange={(event) => {
              const inputValue = isNumericRuleOperators.includes(rule.operation) && event.target.value !== '' ? Number(event.target.value) : event.target.value;
              onChangeBucketRule({ value: inputValue, type: 'value' });
            }}
            disabled={rule.operation === ruleOperators.isEmpty}
            dataTestId="mapping-rule-value-input"
          />
        )}

      <ConditionTypeDropdown
        onAddRule={() => onAddRule()}
        onAddRuleGroup={() => onAddRuleGroup()}
        shouldEnableNewRule={shouldEnableNewRule}
      />
    </div>
  );
}

export default inject(({
  filterStore: {
    filtersData,
    getFilterKindOptions,
  } = {},
}) => ({
  filtersData,
  getFilterKindOptions,
}),
observer)(MappingRule);
