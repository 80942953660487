import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/build-budget.css';
import { Link } from 'react-router';

export default class BuildBudget extends Component {
    style = style;

    render() {
      return (
        <div>
          <div className={this.classes.header}>
            <h1 className={this.classes.title}>
              Build your first budget plan
            </h1>
            <p className={this.classes.text}>
              Choose between these 2 options
            </p>

          </div>
          <div className={this.classes.main}>
            <Link to="/import-budget">
              <div className={this.classes.borderWrap}>
                <div className={this.classes.importIco} />
                <div className={this.classes.mainTitle}>
                  Import an existing plan
                </div>
                <p className={this.classes.mainText}>
                  Upload an existing budget plan (Excel)
                </p>
              </div>
            </Link>
            <Link to="/add-channels">
              <div className={this.classes.borderWrap}>
                <div className={this.classes.buildIco} />
                <div className={this.classes.mainTitle}>
                  Build your budget plan
                </div>
                <p className={this.classes.mainText}>
                  Start by adding channels and
                  <br />
                  setting up budgets for each one
                </p>
              </div>
            </Link>
          </div>
        </div>
      );
    }
}
