import { round, isString, get } from 'lodash';
import { delete_cookie } from 'sfcookies';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';

export function userPermittedToPage(page) {
  const userProfile = servicesStore.authService.getProfileSync();
  if (!userProfile) { return false; }

  if (userProfile.isAdmin) {
    return true;
  } else {
    const { blockedPages } = userProfile.app_metadata;
    return !blockedPages || blockedPages.findIndex((blockedPage) => page === blockedPage) === -1;
  }
}

export function precisionFormat(number, precision = 2) {
  return round(number, precision);
}

export function getParameterByName(name, url) {
  if (!url) { url = window.location.href; }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) { return null; }
  if (!results[2]) { return ''; }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const makeUnit = (value, fixedOrConvert = 0) => ({ value, fixed: fixedOrConvert });

const timeConverter = (base = 60) => (time) => {
  let afterSeparator = Math.round((time - Math.floor(time)) * base);
  if (afterSeparator < 10) {
    afterSeparator = `0${afterSeparator}`;
  }
  return `${Math.floor(time)}:${afterSeparator}`;
};

const timeStrUnits = {
  Y: makeUnit(1000 * 1000 * 60 * 60 * 24 * 30 * 12, 2),
  M: makeUnit(1000 * 1000 * 60 * 60 * 24 * 30, 2),
  D: makeUnit(1000 * 1000 * 60 * 60 * 24, timeConverter(24)),
  h: makeUnit(1000 * 1000 * 60 * 60, timeConverter()),
  m: makeUnit(1000 * 1000 * 60, timeConverter()),
  s: makeUnit(1000 * 1000, 1),
  ms: makeUnit(1000),
  μs: makeUnit(0),
};

export function timeStr(milliseconds) {
  return unitStr(milliseconds * 1000, timeStrUnits);
}

export function timeStrSince(timestamp) {
  return timeStr(Date.now() - timestamp);
}

function unitStr(size, unitDef) {
  let unit;
  let sizeForDisplay;

  for (const unit_ in unitDef) {
    const unitSize = unitDef[unit_].value;
    if (size >= unitSize) {
      unit = unit_;
      sizeForDisplay = size / (unitSize || 1);
      break;
    }
  }

  // Possible undefined
  if (!unit) {
    return size;
  }

  return unitDef[unit].fixed instanceof Function
    ? `${unitDef[unit].fixed(sizeForDisplay)} ${unit}`
    : `${sizeForDisplay.toFixed(unitDef[unit].fixed)} ${unit}`;
}

export const tidxSorter = (item1, item2) => item1.tidx.h - item2.tidx.h;

export const toUpper = (str) => {
  if (!str || !isString(str)) {
    return '';
  }
  return str.split(' ')
    .map((word) => word[0].toUpperCase() + word.substr(1))
    .join(' ');
};

export const cleanAuthCookies = () => {
  try {
    document.cookie.split(';').forEach((cookie) => {
      const AUTH_COOKIES = ['co%', 'com.auth0.auth'];
      const [key] = cookie.split('=');
      if (AUTH_COOKIES.some((c) => key.includes(c))) {
        delete_cookie(key);
      }
    });
  } catch (e) {
    console.log('Cookie removal error', e);
  }
};

export const dateFormat = (item) => {
  const timeZone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);
  return new Date(Number(item)).toLocaleString('en-GB', { dateStyle: 'medium', timeStyle: 'medium', timeZone });
};

export const checkIfDate = (date) => date && new Date(Number(date)) instanceof Date && !!new Date(Number(date)).getDate() && date.length === 13;
