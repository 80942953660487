import { sumBy, maxBy, orderBy } from 'lodash';
import {
  impactChartTypes, chartOthersIndexColor, maxItemsInChartTooltip, othersKey,
} from 'components/pages/analyze/SegmentsTab/logic/enums';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { averageLine } from 'components/pages/analyze/ImpactBoard/enums';
import { onboardingTagsIds } from 'components/pages/analyze/enums';
import { UNKNOWN_CHANNEL, DIRECT_CHANNEL } from 'components/utils/channels';

export function reorderArrayByArray({ orderByArray, arrayToOrder }) {
  return arrayToOrder.sort((a, b) => {
    const indexOfA = orderByArray.indexOf(a);
    const indexOfB = orderByArray.indexOf(b);
    if (indexOfA === -1) { return 1; }
    if (indexOfB === -1) { return -1; }
    return indexOfA - indexOfB;
  });
}

export function getParsedChartData({
  segmentData,
  totalBySegment,
  totalByFrequency,
  hideNoValuesRows,
  isNonCumulativeMetric,
  hideOtherValuesRows,
  colorIndexCache,
  checkedSegments,
  toggleViewType,
  growthBySegment,
  isCompareToPreviousEnabled,
}) {
  const filteredTotalBySegment = {};
  for (const segmentDataItem of segmentData) {
    const segmentDataItemKeys = Object.keys(segmentDataItem);
    for (const segmentKey of segmentDataItemKeys) {
      if (segmentKey === 'name') {
        continue;
      }
      const totalBySegmentItem = totalBySegment[segmentKey];
      if ((isNonCumulativeMetric || totalBySegmentItem)) {
        filteredTotalBySegment[segmentKey] = totalBySegmentItem;
      }
    }
  }

  const parseDataArray = [];
  for (const [segmentKey, segmentValue] of Object.entries(filteredTotalBySegment)) {
    if (hideNoValuesRows && segmentKey === 'No Value') {
      continue;
    }
    if (hideOtherValuesRows && segmentKey.includes('- other')) {
      continue;
    }

    if (segmentValue === undefined) {
      continue;
    }

    const itemToPush = {
      name: segmentKey,
      value: segmentValue,
      bar: { name: segmentKey, value: segmentValue },
    };

    if (isCompareToPreviousEnabled) {
      itemToPush.bar.growth = segmentValue !== undefined ? growthBySegment[segmentKey] : '';
    }

    parseDataArray.push(itemToPush);
  }

  const orderDataArray = orderBy(parseDataArray, ['value', 'name'], ['desc', 'asc']);

  const colorIndexs = {};
  for (const [index, item] of orderDataArray.entries()) {
    let colorIndex = index % orderDataArray.length;
    if (item.name in colorIndexCache) {
      colorIndex = colorIndexCache[item.name];
    } else {
      colorIndexCache[item.name] = index;
    }

    colorIndexs[item.name] = colorIndex;
  }
  colorIndexs[othersKey] = chartOthersIndexColor;

  const filteredOrderDataArray = orderDataArray.filter((item) => checkedSegments.includes(item.name));
  const maxValuesNamesArrayFromData = filteredOrderDataArray.slice(0, maxItemsInChartTooltip).map((item) => item.name);

  const maxValue = maxBy(parseDataArray, 'value')?.value || 0;
  const dataNames = new Set();
  const chartDataWithTotalAndOthers = [];
  const isRadioButtonAndAllSelected = isNonCumulativeMetric && checkedSegments.length === 0 && toggleViewType === impactChartTypes.bar;
  if (isRadioButtonAndAllSelected) {
    for (const [frequency, totalValue] of Object.entries(totalByFrequency)) {
      chartDataWithTotalAndOthers.push({
        name: frequency,
        total: totalValue,
      });
    }
    dataNames.add('total');
  } else {
    for (const itemData of segmentData) {
      const filterDataItem = { ...itemData };

      if (hideNoValuesRows) {
        delete filterDataItem['No Value'];
      }

      if (checkedSegments.length > 0) {
        for (const itemName of Object.keys(filterDataItem)) {
          if (itemName === 'name') {
            continue;
          }
          const shouldDeleteItemFromObject = !checkedSegments.includes(itemName) || (hideOtherValuesRows && itemName.includes('- other'));
          if (shouldDeleteItemFromObject) {
            delete filterDataItem[itemName];
          }
        }
      }

      const itemTotal = sumBy(Object.values(filterDataItem), (value) => !Number.isNaN(Number(value)) && value);
      const itemObject = {};

      for (const [dataItemKey, dataItemValue] of Object.entries(filterDataItem)) {
        if (dataItemKey !== 'name' && !maxValuesNamesArrayFromData.includes(dataItemKey)) {
          itemObject[othersKey] = (itemObject[othersKey] || 0) + dataItemValue;
        } else {
          itemObject[dataItemKey] = dataItemValue;
          dataNames.add(dataItemKey);
        }
      }

      if (itemTotal) {
        itemObject.total = itemTotal;
      }

      if (isNonCumulativeMetric) {
        itemObject[averageLine.key] = totalByFrequency[itemData.name] ?? 0;
      } else {
        let totalRowLength = Object.values(itemData).length;
        let sumAllItems = 0;
        for (const [dataItemKey, dataItemValue] of Object.entries(itemData)) {
          if (dataItemKey === 'name') {
            totalRowLength -= 1;
            continue;
          }
          sumAllItems += dataItemValue;
          if (dataItemValue === 0) {
            totalRowLength -= 1;
          }
        }
        itemObject[averageLine.key] = formatNumberWithDecimalPoint(sumAllItems / totalRowLength, 2);
      }
      chartDataWithTotalAndOthers.push(itemObject);
    }
  }
  dataNames.delete('name');

  let dataNamesArray = Array.from(dataNames);
  if (checkedSegments.length > 0) {
    dataNamesArray = dataNamesArray.filter((name) => checkedSegments.includes(name));
    const isDataHasMoreItemsThanMaxItems = checkedSegments.length > maxItemsInChartTooltip;
    if (isDataHasMoreItemsThanMaxItems && toggleViewType === impactChartTypes.bar) {
      dataNamesArray.push(othersKey);
    }
  }
  if ((!isNonCumulativeMetric || toggleViewType === impactChartTypes.line) && checkedSegments.length === 0) {
    dataNamesArray = [];
  }

  const compareValueIgnoreNames = {};
  if (toggleViewType === impactChartTypes.line) {
    for (const itemData of chartDataWithTotalAndOthers) {
      for (const dataName of dataNamesArray) {
        if (!itemData[dataName]) {
          itemData[dataName] = 0;
          if (!compareValueIgnoreNames[itemData.name]) {
            compareValueIgnoreNames[itemData.name] = [];
          }
          compareValueIgnoreNames[itemData.name].push(dataName);
        }
      }
    }
    dataNamesArray.push(averageLine.key);
  }

  if (toggleViewType === impactChartTypes.bar) {
    const totalOthers = chartDataWithTotalAndOthers.reduce((acc, item) => acc + (item[othersKey] || 0), 0);
    let cloneDataArray = [...orderDataArray];
    if (totalOthers) {
      cloneDataArray.push({
        name: othersKey,
        value: totalOthers,
      });
      cloneDataArray = orderBy(cloneDataArray, ['value', 'name'], ['desc', 'asc']);
    }
    const orderByDataNames = cloneDataArray.map((item) => item.name);
    dataNamesArray = reorderArrayByArray({ orderByArray: orderByDataNames, arrayToOrder: dataNamesArray });
  }

  const mostEffectiveSegmentIndex = orderDataArray.findIndex((item) => item.name !== UNKNOWN_CHANNEL && item.name !== DIRECT_CHANNEL);
  if (mostEffectiveSegmentIndex !== -1) {
    orderDataArray[mostEffectiveSegmentIndex].bar.tagId = onboardingTagsIds.impactByMostEffectiveSegment;
  }

  return {
    maxValue,
    dataNames: dataNamesArray,
    chartData: chartDataWithTotalAndOthers,
    parsedData: orderDataArray,
    colorIndexs,
    compareValueIgnoreNames,
  };
}
