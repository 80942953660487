import React from 'react';
import classnames from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';

import MetricItem from 'components/widgets/optimalJourney/MetricItem';
import { metricsTooltipsTypes } from 'components/widgets/optimalJourney/enums';
import { getMetricTooltipMockData } from 'components/pages/analyze/optimalJourneyTab/logic/optimalJourneysTab';
import { formatBudget } from 'components/utils/budget';
import { convertDaysToWeeks } from 'stores/analyze/logic/timeUtils';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function TopMetricsBar({
  metrics = {},
  kpiFocus,
  isLoaded = true,
  flags,
}) {
  useStyles([style]);

  function getTooltipData({ type }) {
    if (flags.optimalJourneysDemoOnly) {
      return getMetricTooltipMockData({ type, metrics });
    }
    return '';
  }

  function getMetricNickname({ metric }) {
    if (flags.optimalJourneysDemoOnly) {
      return 'Closed Wons';
    }
    return userStore.getMetricNickname({ metric });
  }

  return (
    <div className={classnames(styles.box, styles.topMetricsBar)}>
      <MetricItem
        headerLabel={`# of ${getMetricNickname({ metric: kpiFocus })}`}
        headerTooltip={getTooltipData({ type: metricsTooltipsTypes.journeys, metrics })}
        metricValue={metrics.numberOfJourneys}
        footerLabel={flags.optimalJourneysDemoOnly ? 'Closed Lost:' : ''}
        footerValue={flags.optimalJourneysDemoOnly ? metrics.numberOfClosedLost : null}
        isLoaded={isLoaded}
        formatValue={(value) => formatBudget(value, false, true)}
      />
      <MetricItem
        headerLabel="# of Touchpoints"
        headerTooltip={getTooltipData({ type: metricsTooltipsTypes.touchpoints, metrics })}
        metricValue={metrics.numberOfTouchpoints}
        footerLabel={flags.optimalJourneysDemoOnly ? 'Closed Lost:' : 'Avg:'}
        footerValue={metrics.avgNumberOfTouchpoints}
        isLoaded={isLoaded}
      />
      <MetricItem
        headerLabel="# of Contacts"
        headerTooltip={getTooltipData({ type: metricsTooltipsTypes.contacts, metrics })}
        metricValue={metrics.numberOfContacts}
        footerLabel={flags.optimalJourneysDemoOnly ? 'Closed Lost:' : 'Avg:'}
        footerValue={metrics.avgNumberOfContacts}
        isLoaded={isLoaded}
      />
      <MetricItem
        headerLabel="Sales Cycle Length"
        headerTooltip={getTooltipData({ type: metricsTooltipsTypes.avgSalesCycle, metrics })}
        metricValue={metrics.numberOfSalesCycle}
        footerLabel={flags.optimalJourneysDemoOnly ? 'Closed Lost:' : ''}
        footerValue={flags.optimalJourneysDemoOnly ? metrics.avgSalesCycle : null}
        isLoaded={isLoaded}
        formatValue={(value) => convertDaysToWeeks({ days: value })}
      />
      <MetricItem
        headerLabel="Avg. Deal Size"
        headerTooltip={getTooltipData({ type: metricsTooltipsTypes.avgDealSize, metrics })}
        metricValue={metrics.numberOfDealSize}
        formatValue={(value) => formatBudget(value, true, true)}
        footerLabel={flags.optimalJourneysDemoOnly ? 'Closed Lost:' : ''}
        footerValue={flags.optimalJourneysDemoOnly ? metrics.avgDealSize : null}
        isLoaded={isLoaded}
      />
    </div>
  );
}

export default withLDConsumer()(TopMetricsBar);
