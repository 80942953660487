import { decorate, observable, action } from 'mobx';
import {
  AuthService, Logger, EventTracker, ServerCommunication,
} from '@infinigrow/libs';

import config from 'components/utils/Configuration';

class ServicesStore {
  constructor() {
    const protocol = config.overrideProtocol || window.location.protocol;
    this.serverCommunication = new ServerCommunication({
      authClientID: config.authClientId,
      authDomain: config.authDomain,
      overrideProtocol: config.overrideProtocol,
      overrideURL: config.overrideURL,
      port: config.port,
      logApplicationName: config.logServer.applicationName,
      urlSuffix: protocol === 'http:' ? '' : 'api/',
    });
    this.authService = new AuthService({ clientID: config.authClientId, authDomain: config.authDomain });
    this.logger = new Logger({ subsystemName: config.logServer.subsystemName, applicationName: config.logServer.applicationName });
  }

  async createEventTracker({ userAccount }) {
    const profile = await this.authService.getProfile();
    const eventTracker = new EventTracker({
      sendEvents: config.eventTracking.sendEvents,
      applicationName: config.eventTracking.applicationName,
      domainsToFilter: config.eventTracking.domainsToFilter,
      profile,
      userAccount,
    });
    eventTracker.identify();
    eventTracker.group();

    this.eventTracker = eventTracker;
  }
}

decorate(ServicesStore, {
  serverCommunication: observable,
  authService: observable,
  logger: observable,
  eventTracker: observable,
  createEventTracker: action.bound,
});

export default new ServicesStore();
