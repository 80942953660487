import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/insights-forecasting.css';
import toggleStyle from 'styles/budget/budget-toggle.css';
import Select from 'components/controls/Select';
import Toggle from 'components/controls/Toggle';
import InsightsCard from 'components/pages/budget/InsightsCard';
import GoalStatus from 'components/pages/budget/GoalStatus';
import GoalForecast from 'components/pages/budget/GoalForecast';

export default class InsightsForecasting extends Component {
    style = style;

  styles = [toggleStyle]

  constructor() {
    super();
    this.state = {
      timeFrame: '',
      selectLabel: '',
      month: 1,
    };
  }

  selectValue = ({ value, type }) => {
    this.setState({
      timeFrame: type,
      selectLabel: value,
    });
  }

  render() {
    const customStyles = {
      background: 'linear-gradient(360deg, #FAFAFC 0%, #FFFFFF 100%)',
      border: '1px solid rgba(178, 187, 213, 0.5)',
      height: 40,
    };

    const options = [
      { value: 'new SQL', label: '[New SQL’s]' },
      { value: 'next option', label: 'Next Option' },
    ];
    return (
      <div className={this.classes.content}>
        <div className={this.classes.borderWrap}>
          <div className={this.classes.headerWrap}>
            <div className={this.classes.header}>
              <h2 className={this.classes.title}>
                Insights & Forecasting
              </h2>
              <p className={this.classes.text}>
                Get AI-Driven insights that will help you set your budget, and forecast the outcomes
                {' '}
                <br />
                {' '}
                of your budget allocation.
              </p>
            </div>
            <div className={this.classes.actionGroup}>
              <div className={this.classes.buttonsGroup}>
                <p className={this.classes.actionGroupText}>
                  Past data timeframe
                </p>
                <Toggle
                  boxClass={toggleStyle.locals.box}
                  frameClass={toggleStyle.locals.frame}
                  options={[{
                    text: '1 month',
                    value: 1,
                  },
                  {
                    text: '3 month',
                    value: 3,
                  },
                  ]}
                  selectedValue={this.state.month}
                  onClick={(tab) => {
                    this.setState({ month: tab });
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
              <div className={this.classes.selectGroup}>
                <p className={this.classes.actionGroupText}>
                  Select goal
                </p>
                <Select
                  select={{ options }}
                  selected={options[0]}
                  customStyle={customStyles}
                  onChange={this.selectValue}
                  innerClassName={this.classes.select}
                />
              </div>
            </div>
          </div>
          <div className={this.classes.mainContainer}>
            <div className={this.classes.insightsContainer}>
              <h3 className={this.classes.subTitle}>
                Insights (Based on past data)
              </h3>
              <div className={this.classes.insightsWrap}>
                <InsightsCard
                  title="Spend"
                  months={3}
                  paid="Paid Review"
                  amount={150}
                />
                <InsightsCard
                  title="Effectiveness"
                  months={3}
                  paid="Paid Review"
                  sumGoals={23}
                  goals="SQL"
                />
                <InsightsCard
                  title="Efficiency"
                  months={3}
                  paid="Paid Review"
                  amount={200}
                  goals="SQL"
                />
                <InsightsCard
                  title="Velocity"
                  months={3}
                  paid="Paid Review"
                  days={60}
                  goals="SQL"
                />
              </div>
            </div>
            <div className={this.classes.forecastContainer}>
              <h3 className={this.classes.subTitle}>
                Forecast
              </h3>
              <div className={this.classes.forecastWrap}>
                <GoalStatus
                  target={666}
                  current={251}
                  parsing={800}
                  sql={200}
                  total={785}
                  month="March"
                  year={2021}
                />
                <GoalForecast />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
