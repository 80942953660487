import React from 'react';
import Component from 'components/Component';
import style from 'styles/header/notifications.css';
import objectiveStyle from 'styles/header/objective-notification.css';
import { getIndicatorIcon } from 'components/utils/indicators';
import icons from 'styles/icons/indicators.css';

export default class IntegrationsNotification extends Component {

  style = style;
  styles = [objectiveStyle, icons];

  render() {
    const { notification } = this.props;
    return <div>
      <div>
        <div className={this.classes.picture} data-icon={getIndicatorIcon(notification.indicator)}/>
        <div className={objectiveStyle.locals.objectiveIcon} data-fail={notification.isSuccess ? null : true}/>
      </div>
      <div className={this.classes.textWrap}>
        <div className={objectiveStyle.locals.textColor}
             style={{ color: notification.isSuccess ? '#24b10e' : '#c62b36' }}>
          {notification.isSuccess ? `You've integrated successfully to ${notification.platform}!` : `An error occurred while trying to integrate ${notification.platform}.
We're currently checking it for you!`}
        </div>
        <div className={this.classes.time}>
          {this.props.timeSince}
        </div>
      </div>
    </div>;
  }
}
