import { v4 as uuidv4 } from 'uuid';
import React, { useEffect } from 'react';
import { Button } from '@infinigrow/libs';

import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { DEFAULT_PLATFORMS_IMPORT_FIELDS } from 'components/utils/indicators';

import integrationStyle from 'styles/integrations/integration.css';

import CRMImportFieldsGroup from './CRMImportFieldsGroup';
import { getCRMCustomFieldsFromSavedMapping, getCRMLeadsSourcesFromSavedMapping } from './logic/CRMImportFields';

const styles = integrationStyle.locals;

function CRMImportFields() {
  const {
    salesforceUserMappings, hubspotUserMappings, selectedCRMItems, CRMImportFieldsMapping, setParentState,
  } = useCRMCContext();

  const getDefaultEmptyGroup = (label = '', crmPlatform = selectedCRMItems[0]) => ({
    label,
    isAttribution: true,
    mergingConfiguration: [{ crmPlatform, objectName: null, fieldsToMerge: [''] }],
  });

  const addCrmGroupFieldsRows = () => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    const mappingLengthIndex = updatedCRMImportFieldsMapping.length;
    const newLabelName = `Field name ${mappingLengthIndex}`;
    updatedCRMImportFieldsMapping.push(getDefaultEmptyGroup(newLabelName));
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const getAllCRMImportFieldsFromAllCrms = () => {
    const allCustomFields = [];
    const allLeadSources = [];
    for (const crmPlatform of selectedCRMItems) {
      const customFields = getCRMCustomFieldsFromSavedMapping(crmPlatform, salesforceUserMappings, hubspotUserMappings);
      allCustomFields.push(...customFields);
      const leadSources = getCRMLeadsSourcesFromSavedMapping(crmPlatform, salesforceUserMappings, hubspotUserMappings);
      allLeadSources.push(...leadSources);
    }

    if (allCustomFields.length === 0 && allLeadSources.length === 0) {
      for (const crmPlatform of selectedCRMItems) {
        const defaultCrmImportFields = DEFAULT_PLATFORMS_IMPORT_FIELDS[crmPlatform];
        for (const importField of defaultCrmImportFields) {
          importField.id = uuidv4();
          allCustomFields.push(importField);
        }
      }
    }

    const mergedCustomFieldsObjects = [];
    const mergedCustomFields = [];
    for (const customField of allCustomFields) {
      const customFieldFoundIndex = mergedCustomFields.findIndex((merged) => merged.id === customField.id);
      if (customFieldFoundIndex === -1) {
        mergedCustomFields.push(customField);
      } else {
        mergedCustomFields[customFieldFoundIndex].mergingConfiguration.push(...customField.mergingConfiguration);
      }
    }
    mergedCustomFieldsObjects.push(...mergedCustomFields);

    const mergedLeadSources = [];
    for (const leadSource of allLeadSources) {
      const leadSourceFoundIndex = mergedLeadSources.findIndex((merged) => merged.id === leadSource.id);
      if (leadSourceFoundIndex === -1) {
        mergedLeadSources.push(leadSource);
      } else {
        mergedLeadSources[leadSourceFoundIndex].mergingConfiguration.push(...leadSource.mergingConfiguration);
      }
    }
    mergedCustomFieldsObjects.push(...mergedLeadSources);

    setParentState({ CRMImportFieldsMapping: mergedCustomFieldsObjects });
  };

  useEffect(() => {
    getAllCRMImportFieldsFromAllCrms();
  }, []);

  return (
    <>
      <h1>Import CRM Fields</h1>
      <h3>
        Add additional fields from your CRM and use them for segmentation and data mapping.
        <br />
        Note that you can merge several fields from different objects into a single field.
      </h3>
      <div className={styles.configCustomFields}>
        {CRMImportFieldsMapping.map((fieldsGroupData, fieldsGroupIndex) => {
          const fieldsGroupKey = `fieldsGroupIndex${fieldsGroupIndex + 1}`;
          return (
            <CRMImportFieldsGroup
              key={fieldsGroupKey}
              fieldGroupIndex={fieldsGroupIndex}
              fieldsGroupData={fieldsGroupData}
            />
          );
        })}
        <Button onClick={addCrmGroupFieldsRows} type="primaryBlue" className={styles.btnCrmFieldsRow}>
          <div className={styles.addFieldButtonIcon} data-icon="platform:addFieldIcon" />
          Add new custom field
        </Button>
      </div>
    </>
  );
}

export default CRMImportFields;
