import moment from 'moment';
import { get } from 'lodash';

import timeFrameModule from 'modules/timeframe';

import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import { nonUpDirectionMetricTypes } from 'components/pages/settings/goals/logic/enums';

import userStore from 'stores/userStore';

import { getFiscalQuarterForMonth, getFiscalYearForMonth } from './dates';

function getMetricsBased2Funnels({
  funnels, suffix, type, firstToLastName,
}) {
  const lastFunnelIndex = funnels.length;
  const metrics = funnels
    .filter((funnel, index) => index !== 0)
    .map((funnel, index) => ({ value: `funnel${index + 1}ToFunnel${index + 2}${suffix}`, type }));

  metrics.push({ value: firstToLastName || `funnel1ToFunnel${lastFunnelIndex}${suffix}`, type });
  return metrics;
}

export function getFunnelStagesMetrics({ funnels }) {
  return funnels.map((funnel, index) => ({ value: `funnel${index + 1}`, type: 'funnelStage' }));
}

export function getVelocityMetrics({ funnels }) {
  return getMetricsBased2Funnels({
    funnels,
    suffix: 'Velocity',
    type: 'velocity',
    firstToLastName: 'averageSalesCycle',
  });
}

export function getConversionRatesMetrics({ funnels }) {
  return getMetricsBased2Funnels({
    funnels,
    suffix: 'ConversionRate',
    type: 'conversionRate',
  });
}

export function getMoneyMetrics({ type = 'revenue' }) {
  return [
    { value: 'newPipeline', type },
    { value: 'marketingAttributedPipeline', type },
    { value: 'newBookings', type },
    { value: 'marketingAttributedRevenue', type },
    { value: 'MRR', type },
    { value: 'ARR', type },
    { value: 'ARPA', type },
  ];
}

export function getMonthTitle({ startDate }) {
  const timezone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);

  return timezone === DEFAULT_TIME_ZONE ? `${moment(startDate).startOf('month').format('MMMM, YYYY')}` : `${moment(startDate).tz(timezone).startOf('month').format('MMMM, YYYY')}`;
}

export function getQuarterTitle({ startDate, fiscalYearFirstMonth = 0 }) {
  const timezone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);

  const fiscalMonth = timezone === DEFAULT_TIME_ZONE ? moment(startDate).format('M') : moment(startDate).tz(timezone).format('M');
  const fiscalQuarter = getFiscalQuarterForMonth({ month: fiscalMonth, fiscalYearFirstMonth });
  const fiscalYear = getFiscalYearForMonth(fiscalMonth, fiscalYearFirstMonth + 1, moment(startDate).tz(timezone).format('YYYY'));

  return `Quarter ${fiscalQuarter}, ${fiscalYear}`;
}

function safeDivide({ value, divider }) {
  return divider !== 0 ? value / divider : 1;
}

export function getGoalProgress({ metricType, planned, actual }) {
  const isUp = !nonUpDirectionMetricTypes.includes(metricType);
  return isUp
    ? safeDivide({ value: actual, divider: planned })
    : safeDivide({ value: planned, divider: actual });
}

export function getGoalPacing({ timeframe, actual }) {
  const startDate = moment(timeframe.startDate);
  const endDate = moment(timeframe.endDate);

  const daysSinceStarted = moment().diff(startDate, 'days') || 1;
  const totalDuration = Math.round(endDate.diff(startDate, 'days', true)) || 1;
  const extrapolateConst = Math.min(daysSinceStarted, totalDuration) / totalDuration;

  return Math.round(actual / extrapolateConst);
}

export function getTimeFrameInAccountTZ({ timeframe }) {
  const startDateTimestamp = moment(timeframe.startDate).toDate().getTime();
  const endDateTimestamp = moment(timeframe.endDate).toDate().getTime();

  const startDateTZ = getTSForTimezone(startDateTimestamp);
  const endDateTZ = getTSForTimezone(endDateTimestamp);

  return {
    ...timeframe,
    startDate: new Date(startDateTZ),
    endDate: new Date(endDateTZ),
  };
}

export const getTimeData = (start, finish) => {
  const today = moment();
  const isCompleted = today.isAfter(finish);
  const isOngoing = today.isBetween(start, finish);
  const leftToFinish = finish.from(isOngoing ? today : start, true);
  return {
    isCompleted, isOngoing, leftToFinish, startDate: start, finishDate: finish,
  };
};

export function isGoalOnTrack({ metricType, project, target }) {
  const isDirectionUp = !nonUpDirectionMetricTypes.includes(metricType);
  return (isDirectionUp && project >= target) || (!isDirectionUp && project <= target);
}

export function getGoalTimeframe({ timeframe = {}, fiscalYearFirstMonth }) {
  const parsedTimeframe = timeFrameModule.getTimeframeParams({ ...timeframe, fiscalYearFirstMonth, useEndOfToday: false });
  return getTimeFrameInAccountTZ({
    timeframe: {
      value: timeframe.value,
      ...parsedTimeframe,
    },
  });
}
