import React from 'react';
import Component from 'components/Component';
import Select from 'components/controls/Select';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import { FilterItemTag } from 'components/pages/users/Filters/FilterParts';
import CommonFilter, { CommonPropTypes } from 'components/pages/users/Filters/CommonFilter';
import { includeVariants } from 'components/utils/filters';
import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

export default class ProductFilter extends Component {
  static propTypes = CommonPropTypes

  render() {
    return (
      <CommonFilter {...this.props}>
        {({ config, variant, onSetVariant, selectedOptions, onSelectOptions, onAddItem, onRemoveItem }) => (
          <>
            <header className={styles.filterHeader}>Find journeys which</header>
            <div className={styles.filterVariant}>
              <Select
                className={styles.filterVariantSelect}
                selected={variant}
                select={{ options: includeVariants }}
                onChange={onSetVariant}
              />
              <span className={styles.filterVariantDesc}>the following product:</span>
            </div>
            <MultiCheckSelect
              className={styles.optionSelect}
              selected={selectedOptions.map(option => ({ label: option, value: option }))}
              options={config.options.map(option => ({ label: option, value: option }))}
              placeholder={'Search...'}
              onChange={onSelectOptions} />
          </>
        )}
      </CommonFilter>
    )
  }
}
