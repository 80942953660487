import React from 'react';

const WithHoverEffect = (WrappedComponent) => class extends React.Component {
  state = {
    isHovered: false,
  };

  onHoverChange = (isHovered) => () => this.setState({ isHovered });

  render() {
    const { isHovered } = this.state;

    return (
      <div
        onMouseEnter={this.onHoverChange(true)}
        onMouseLeave={this.onHoverChange(false)}
        className={this.props.hoverContainerClass}
      >
        <WrappedComponent
          {...this.props}
          isHovered={isHovered}
        />
      </div>
    );
  }
};

export default WithHoverEffect;
