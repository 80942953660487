import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import style from 'styles/number-with-arrow.css';
import { isNil } from 'lodash';
import classnames from 'classnames';

export default class NumberWithArrow extends Component {
  style = style;

  static propTypes = {
    stat: PropTypes.any,
    isNegative: PropTypes.bool,
    arrowStyle: PropTypes.object,
    statStyle: PropTypes.object,
    background: PropTypes.any,
  };

  render() {
    const { stat, isNegative, arrowStyle, statStyle, background } = this.props;
    const notExist = isNil(stat);
    return notExist ? null : (
      <div
        className={classnames(this.classes.inner, {
          [this.classes.innerBackground]: background,
          [this.classes.innerBackgroundNegative]: background && isNegative,
        })}
        data-negative={isNegative ? true : null}
      >
        <div className={this.classes.arrow} style={arrowStyle} />
        <div className={this.classes.stat} style={statStyle}>
          {stat}
        </div>
      </div>
    );
  }
}
