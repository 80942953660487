import { sumBy } from 'lodash';
import { numberWithZeroDefault } from 'components/utils/logic/utils';
import { crossFunnel } from 'components/pages/analyze/AttribuitonTable/enums';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { getInfluencedMapping } from '../../../utils/indicators';

export function getStagesAmountsForSegments({ data = {}, influencedMapping = getInfluencedMapping() }) {
  const funnels = Object.keys(influencedMapping);
  const stageDataKeys = [crossFunnel, 'webVisits', 'pageViews', ...Object.keys(influencedMapping), ...Object.values(influencedMapping)];

  const stagesAmountMap = {};
  for (const dataKey of stageDataKeys) {
    if (dataKey === crossFunnel) {
      stagesAmountMap[dataKey] = '';
    }
    const dataForStagesAmounts = data[dataKey];
    if (dataForStagesAmounts) {
      const numberWithDecimalPoint = formatNumberWithDecimalPoint(sumBy(dataForStagesAmounts, numberWithZeroDefault((item) => item.attributed)));
      stagesAmountMap[dataKey] = +numberWithDecimalPoint;
      continue;
    }
    if (funnels.includes(dataKey)) {
      stagesAmountMap[dataKey] = 0;
    }
  }
  return stagesAmountMap;
}
