exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".table-cell__secondary-value-3hJ{font-size:10px;font-weight:500;color:#b2bbd5;margin-top:6px;position:absolute}.table-cell__secondary-value-3hJ[data-in-edit]{margin-left:2px}.table-cell__cell-item-2gI{align-items:center;display:inline-flex}.table-cell__cell-item-2gI[data-in-edit]{margin-left:-8px}.table-cell__icon-22V{width:18px;height:18px;background-size:contain;cursor:pointer;background-position:50%;background-repeat:no-repeat;padding-left:5px}.table-cell__buttons-2Zn{display:flex;margin-left:3px}.table-cell__inner-buttons-3uI{display:flex}.table-cell__edit-cell-13R{width:74px;border-radius:3px;box-shadow:0 0 3px 0 #e6e8f0;border:1px solid #e6e8f0;color:#4d91fc;padding:10px 0 7px 8px;font-size:inherit;font-weight:inherit;letter-spacing:.1px;box-sizing:border-box}[data-icon=\"plan:none\"]{background-image:url(\"/assets/plan_icons/clear-state.svg\")}[data-icon=\"plan:like\"]{background-image:url(\"/assets/plan_icons/like.svg\")}[data-icon=\"plan:lock\"]{background-image:url(\"/assets/plan_icons/lockChannel.svg\")}[data-icon=\"plan:acceptSuggestion\"]{background-image:url(\"/assets/plan_icons/redo.svg\")}[data-icon=\"plan:edit\"]{background-image:url(\"/assets/plan_icons/edit-per-cell.svg\")}[data-icon=\"plan:approveEdit\"]{background-image:url(\"/assets/plan_icons/accept.svg\")}[data-icon=\"plan:declineEdit\"]{background-image:url(\"/assets/plan_icons/decline.svg\")}", ""]);

// exports
exports.locals = {
	"secondary-value": "table-cell__secondary-value-3hJ",
	"secondaryValue": "table-cell__secondary-value-3hJ",
	"cell-item": "table-cell__cell-item-2gI",
	"cellItem": "table-cell__cell-item-2gI",
	"icon": "table-cell__icon-22V",
	"icon": "table-cell__icon-22V",
	"buttons": "table-cell__buttons-2Zn",
	"buttons": "table-cell__buttons-2Zn",
	"inner-buttons": "table-cell__inner-buttons-3uI",
	"innerButtons": "table-cell__inner-buttons-3uI",
	"edit-cell": "table-cell__edit-cell-13R",
	"editCell": "table-cell__edit-cell-13R"
};