import React, { useEffect } from 'react';

import InfoMarker from 'components/pages/InfoMarker';
import Skeleton from 'components/common/Skeleton';

import style from 'styles/insightsPage/goals/footerItem.css';
import classNames from 'classnames';

const styles = style.locals;

export default function FooterItem({
  title, bodyText, bodyColorClass, tooltipText, bodyTextDetails, isLoaded = true,
}) {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {isLoaded ? (
          <>
            {title}
          </>
        ) : (
          <Skeleton height={16} width={40} isLightTheme />
        )}
      </div>
      <div className={classNames(styles.body, bodyColorClass)}>
        {isLoaded ? (
          <>
            {bodyText}
            {bodyTextDetails && (<div className={styles.textWithoutColor}>{bodyTextDetails}</div>)}
            {tooltipText && (<InfoMarker tooltipText={tooltipText} />)}
          </>
        ) : (
          <Skeleton height={16} width={80} />
        )}
      </div>
    </div>
  );
}
