import moment from 'moment';
import {
  range, groupBy, sumBy, isEmpty,
} from 'lodash';

import { getChannelCategory } from 'components/utils/channels';

export function parsCampaignsCostsForPlanVsActual({
  campaignsCostsResponse = [], startDate, endDate,
}) {
  const parsedCampaigns = [];

  for (const campaignData of campaignsCostsResponse) {
    if (campaignData.name === 'General (non-campaign)' || campaignData.isPlaceholder) {
      parsedCampaigns.unshift({
        ...campaignData,
        title: campaignData.name,
        campaign: campaignData.name,
        isAutomatedChannels: campaignData.isAutomatic,
        spending: {
          actual: campaignData.costAmount,
          planned: campaignData.budgetAmount,
          planVsActual: campaignData.costAmount - campaignData.budgetAmount,
          pacing: campaignData.pacing,
          pacingVsPlanned: campaignData.budgetAmount - campaignData.pacing,
        },
      });
      continue;
    }
    parsedCampaigns.push({
      ...campaignData,
      title: campaignData.name,
      campaign: campaignData.name,
      isAutomatedChannels: campaignData.isAutomatic,
      spending: {
        actual: campaignData.costAmount,
        planned: campaignData.budgetAmount,
        planVsActual: campaignData.costAmount - campaignData.budgetAmount,
        pacing: campaignData.pacing,
        pacingVsPlanned: campaignData.budgetAmount - campaignData.pacing,
      },
    });
  }

  const parsedChannels = getChannelsCostsForPlanVsActual({ parsedCampaigns, startDate, endDate });

  return { parsedChannels, parsedCampaigns };
}

function getChannelsCostsForPlanVsActual({ parsedCampaigns, startDate, endDate }) {
  const parsedChannels = [];
  const isCurrentMonth = moment().month() === moment(startDate).month() && moment().year() === moment(startDate).year();

  const campaignsDataPerRegion = groupBy(parsedCampaigns, (campaign) => campaign.region);
  for (const [region, campaignRegionData] of Object.entries(campaignsDataPerRegion)) {
    const campaignsDataPerChannel = groupBy(campaignRegionData, (campaign) => campaign.channel);

    for (const [channel, channelCampaignsData] of Object.entries(campaignsDataPerChannel)) {
      let actual = sumBy(channelCampaignsData, (campaign) => campaign.costAmount);
      const planned = sumBy(channelCampaignsData, (campaign) => campaign.budgetAmount);
      const pacing = sumBy(channelCampaignsData, (campaign) => campaign.pacing);
      const isChannelAutomatic = channelCampaignsData.some((campaign) => campaign.isAutomatic);
      const isEstimated = isCurrentMonth && !isChannelAutomatic && actual === 0;

      if (isEstimated) {
        const daysInTimeframe = moment(endDate).diff(moment(startDate), 'days');
        const budgetPerDay = planned ? planned / daysInTimeframe : 0;
        const daysPastSinceStartDate = moment().diff(moment(startDate), 'days');
        actual = budgetPerDay * daysPastSinceStartDate;
      }

      const channelData = {
        category: getChannelCategory({ channel }),
        region,
        channel,
        children: channelCampaignsData,
        isAutomatic: isChannelAutomatic,
        budgetAmount: planned,
        costAmount: actual,
        pacing,
        spending: {
          actual,
          planned,
          planVsActual: actual - planned,
          pacing,
          pacingVsPlanned: planned - pacing,
          isEstimated,
        },
      };
      parsedChannels.push(channelData);
    }
  }

  return parsedChannels;
}

export function getChannelsAndCampaignsCostsAccordingSelectedTags({
  campaignsData, startDate, endDate, selectedCampaignTags,
}) {
  const parsedCampaigns = campaignsData.filter((campaign) => {
    const isCampaignTagIncludeInSelectedTags = selectedCampaignTags.every((selectedTag) => campaign.tags.some((tagName) => selectedTag.label === tagName));
    return isCampaignTagIncludeInSelectedTags;
  });

  const parsedChannels = getChannelsCostsForPlanVsActual({ parsedCampaigns, startDate, endDate });

  return { parsedChannels, parsedCampaigns };
}

export function parseChannelsAndCampaignsBudgetsForAnnualTab({ campaignsBudgetsResponse }) {
  const parsedCampaignsBudgets = {};

  const currentYear = moment().year();
  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1;
  const years = range(previousYear, nextYear + 2);
  const months = range(1, 13);

  let dateDetails;
  const groupedCampaignsByRegionData = groupBy(campaignsBudgetsResponse, 'region');
  for (const [regionName, regionData] of Object.entries(groupedCampaignsByRegionData)) {
    parsedCampaignsBudgets[regionName] = [];
    const dataGroupedByCampaign = groupBy(regionData, 'id');
    for (const campaignData of Object.values(dataGroupedByCampaign)) {
      const campaignActualSpent = [];
      const campaignBudget = [];
      dateDetails = [];
      for (const year of years) {
        for (const month of months) {
          const budgetDate = moment(new Date(`${year}-${month}-01`));
          const currentDate = moment();
          const isHistoryMonth = budgetDate.year() < currentDate.year() || (budgetDate.year() === currentDate.year() && budgetDate.month() < currentDate.month());
          const dateDetail = {
            isHistoryMonth,
            month: budgetDate.clone().format('MMM'),
            date: budgetDate,
          };
          const campaignWithData = campaignData.find((campaign) => campaign.year === year.toString() && campaign.month === month.toString());
          const campaignActualSpentForMonth = campaignWithData ? campaignWithData.actual_cost : 0;
          const campaignBudgetForMonth = campaignWithData ? campaignWithData.budgeted_cost : 0;
          campaignActualSpent.push(campaignActualSpentForMonth);
          campaignBudget.push(campaignBudgetForMonth);
          dateDetails.push(dateDetail);
        }
      }

      const campaign = campaignData[0];
      if (campaign.name === 'General (non-campaign)' || campaign.isPlaceholder) {
        parsedCampaignsBudgets[regionName].unshift({
          id: campaign.id,
          region: campaign.region,
          name: campaign.name,
          channel: campaign.channel,
          source: [campaign.channel],
          actualSpent: campaignActualSpent,
          budget: campaignBudget,
          isAutomated: {},
          labels: [],
          tags: campaign.tags,
        });

        continue;
      }
      parsedCampaignsBudgets[regionName].push({
        id: campaign.id,
        region: campaign.region,
        name: campaign.name,
        channel: campaign.channel,
        source: [campaign.channel],
        actualSpent: campaignActualSpent,
        budget: campaignBudget,
        isAutomated: {},
        labels: [],
        tags: campaign.tags,
      });
    }
    parsedCampaignsBudgets[regionName].map((campaign, index) => {
      campaign.index = index;
      return campaign;
    });
  }

  const parsedChannelsBudgets = getChannelsBudgetsDataFromCampaigns({ parsedCampaignsBudgets, dateDetails });

  return { parsedChannelsBudgets, parsedCampaignsBudgets, campaignsDateDetails: dateDetails };
}

function getChannelsBudgetsDataFromCampaigns({ parsedCampaignsBudgets, dateDetails }) {
  const parsedChannelsBudgets = [];
  for (const [region, regionCampaignsData] of Object.entries(parsedCampaignsBudgets)) {
    if (isEmpty(regionCampaignsData)) {
      continue;
    }
    const amountOfMonths = regionCampaignsData[0].actualSpent.length;
    const campaignsDataPerChannel = groupBy(regionCampaignsData, (campaign) => campaign.channel);

    for (let monthIndex = 0; monthIndex < amountOfMonths; monthIndex++) {
      const channels = {};
      for (const [channel, campaignChannels] of Object.entries(campaignsDataPerChannel)) {
        const channelActualSpent = sumBy(Object.values(campaignChannels), (campaign) => campaign.actualSpent[monthIndex]);
        const channelBudget = sumBy(Object.values(campaignChannels), (campaign) => campaign.budget[monthIndex]);
        let primaryBudget = channelBudget;
        let secondaryBudget = channelBudget;
        if (channelActualSpent > 0) {
          secondaryBudget = channelActualSpent;
        }
        if (dateDetails[monthIndex].isHistoryMonth) {
          primaryBudget = channelActualSpent;
          secondaryBudget = null;
        }
        channels[channel] = {
          primaryBudget,
          secondaryBudget,
        };
      }
      parsedChannelsBudgets.push({
        channels,
        isHistory: dateDetails[monthIndex].isHistoryMonth,
        region,
        month: dateDetails[monthIndex].month,
        date: dateDetails[monthIndex].date,
      });
    }
  }

  return parsedChannelsBudgets;
}

export function getChannelsAndCampaignsBudgetsAccordingSelectedTags({ campaignsBudgets, selectedCampaignTags, campaignsDateDetails }) {
  const parsedCampaignsBudgets = {};

  for (const [region, regionData] of Object.entries(campaignsBudgets)) {
    parsedCampaignsBudgets[region] = regionData.filter((campaignData) => {
      const isCampaignTagIncludeInSelectedTags = selectedCampaignTags.every((selectedTag) => campaignData.tags.some((tagName) => selectedTag.label === tagName));
      return isCampaignTagIncludeInSelectedTags;
    });
  }

  const parsedChannelsBudgets = getChannelsBudgetsDataFromCampaigns({ parsedCampaignsBudgets, dateDetails: campaignsDateDetails });

  return { parsedChannelsBudgets, parsedCampaignsBudgets };
}
