import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Toggle from 'components/controls/Toggle';
import EmbeddedScriptValidation from 'components/pages/attributionSetup/EmbeddedScriptValidation';
import TagManagerAutomaticPopup from 'components/pages/attributionSetup/TagManagerAutomaticPopup';
import PlanPopup, { TextContent as PopupTextContent } from 'components/pages/plan/Popup';

import editButtonIcon from 'assets/edit-blue.svg';
import style from 'styles/attribution/attribution-setup.css';
import onBoardingStyle from 'styles/onboarding/onboarding.css';
import planStyles from 'styles/plan/plan.css';
import textFieldStyles from 'styles/controls/textfield.css';

export default class AttributionSetupContent extends Component {
  style = style;

  styles = [onBoardingStyle, planStyles];

  static propTypes = {
    UID: PropTypes.string,
    senderEmail: PropTypes.string,
    sendSnippetEmail: PropTypes.func,
    isStaticPage: PropTypes.bool,
    code: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    };
  }

  renderStep = (stepNumber, stepTitle, stepSubTitle, stepContent) => (
    <div className={this.classes.step}>
      <div className={this.classes.stepHead}>
        <div className={this.classes.stepNumberWrapper}>
          <div className={this.classes.stepNumber}>{stepNumber}</div>
        </div>
        <div className={this.classes.titleWrapper}>
          <div className={this.classes.contentTitle}>{stepTitle}</div>
          {stepSubTitle ? <div className={this.classes.contentSubTitle}>{stepSubTitle}</div> : null}
        </div>
      </div>
      <div className={this.classes.stepContent}>
        {stepContent}
      </div>
    </div>
  );

  render() {
    const {
      UID, senderEmail, sendSnippetEmail, isStaticPage, code,
    } = this.props;

    const {
      to, tab, show, success,
    } = this.state;

    return (
      <>
        {!isStaticPage ? <TagManagerAutomaticPopup ref="popup" snippetScript={code} /> : null}
        <>
          <div className={this.classes.title}>Add the tracking script to your website</div>
          <div className={this.classes.subTitle}>
            Setting up InfiniGrow&apos;s tracking is easy and takes about a minute. This is
            the first and last time you&apos;ll be asked to use code.
          </div>
        </>
        {
        this.renderStep(
          1,
          'Copy your code',
          'or get it sent by email with a step-by-step guide',
          <div className={this.classes.firstStepCont}>
            <div className={this.classes.snippetBox}>
              <div className={this.classes.snippetWrapper}>
                <pre className={this.classes.snippet}>
                  {code}
                </pre>
              </div>
              <div className={this.classes.buttons}>
                {sendSnippetEmail
                  ? (
                    <div style={{ position: 'relative' }}>
                      <Button
                        type="secondaryWhite"
                        onClick={() => this.refs.sendSnippetPopup.open()}
                      >
                        Email this script and instructions
                      </Button>
                      <PlanPopup
                        onClose={() => {
                          this.setState({ to: null });
                        }}
                        ref="sendSnippetPopup"
                        style={{
                          width: 'max-content',
                          left: '253px',
                        }}
                        title="Send Script"
                      >
                        <PopupTextContent>
                          <div style={{ display: 'inline-flex' }}>
                            <input
                              type="email"
                              value={to || ''}
                              onChange={(e) => this.setState({ to: e.target.value })}
                              placeholder="email"
                              className={textFieldStyles.locals.input}
                            />
                            <Button
                              type="primaryBlue"
                              onClick={() => {
                                sendSnippetEmail(senderEmail, UID, to);
                                this.refs.sendSnippetPopup.close();
                              }}
                            >
                              Send
                            </Button>
                          </div>
                        </PopupTextContent>
                      </PlanPopup>
                    </div>
                  )
                  : null}
                <Button
                  type="primaryBlue"
                  icon={editButtonIcon}
                  onClick={() => copy(code)}
                >
                  Copy
                </Button>
              </div>
            </div>
            {
              !isStaticPage ? (
                <div className={this.classes.firstStepCont}>
                  <div className={this.classes.or}>OR</div>
                  <Button
                    className={this.classes.secondaryButton}
                    type="primaryBlue"
                    onClick={() => {
                      this.refs.popup.open();
                    }}
                  >
                    Add To Tag Manager
                  </Button>
                </div>
              )
                : null
            }
          </div>
        )
      }
        <div>
          <Button
            style={{ margin: '40px', marginLeft: '65px', boxSizing: 'border-box' }}
            className={this.classes.secondaryButton}
            onClick={() => {
              this.props.getScriptValidator().then((res) => {
                let isSuccess = false;
                if (res) {
                  isSuccess = true;
                }
                this.setState({ show: true, success: isSuccess });
              });
            }}
          >
            Test my site connection
          </Button>
          <EmbeddedScriptValidation show={show} success={success} close={() => this.setState({ show: false })} />
        </div>
        {this.renderStep(
          2,
          'Add it to your website',
          null, (
            <div>
              <Toggle
                options={
                  [{
                    text: 'HTML',
                    value: 0,
                  },
                  {
                    text: 'Google Tag Manager',
                    value: 1,
                  },
                  {
                    text: 'WordPress',
                    value: 2,
                  }]
                }
                selectedValue={tab}
                style={{ width: '345px', justifyContent: 'left' }}
                onClick={(value) => {
                  this.setState({ tab: value });
                }}
              />
              {tab === 1 && !isStaticPage
                ? (
                  <div className={this.classes.secondStepContainer}>
                    <Button
                      className={this.classes.secondaryButton}
                      type="primaryBlue"
                      onClick={() => {
                        this.refs.popup.open();
                      }}
                    >
                      Add To Tag Manager
                    </Button>
                    <div style={{ marginTop: '5px' }} className={this.classes.secondStepText}>
                      <div>
                        Or
                        <a
                          href="https://intercom.help/infinigrow/analyze/attribution-setup/add-the-tracking-script-to-your-website"
                          target="_blank"
                          rel="noreferrer"
                        >
                          learn more
                        </a>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className={this.classes.secondStepContainer}>
                    {tab === 0
                      ? (
                        <div className={this.classes.secondStepText}>
                          {'Place the script into the head (before the </head> tag) of every page of your site (or site template).'}
                        </div>
                      ) : null}
                    <Button
                      type="secondaryWhite"
                      className={this.classes.secondaryButton}
                      onClick={() => window.open(
                        'https://intercom.help/infinigrow/analyze/attribution-setup/add-the-tracking-script-to-your-website',
                        '_blank'
                      )}
                    >
                      Read the step-by-step guide
                    </Button>
                  </div>
                )}
            </div>
          )
        )}
      </>
    );
  }
}
