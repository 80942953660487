import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/setup-budget-detail.css';
import SetupBudgetDetailWrap from 'components/pages/budget/SetupBudgetDetailWrap';
import DeletePopup from 'components/pages/budget/DeletePopup';

export default class SetupBudgetDetail extends Component {
    style = style;

    constructor() {
      super();
      this.state = {
        isOpen: false,
        isRemoved: false,
        isExpanded: false,
      };
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({
          isOpen: false,
        });
      }
    }

    setWrapperRef(node) {
      this.wrapperRef = node;
    }

  onActionsClick = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    } else {
      this.setState({
        isOpen: true,
      });
    }
  }

  onRemoveClick = () => {
    this.setState({
      isOpen: false,
      isRemoved: true,
    });
  }

  onCloseClick = () => {
    this.setState({
      isRemoved: false,
    });
  }

 togleTabClick = () => {
   if (this.state.isExpanded) {
     this.setState({ isExpanded: false });
   } else {
     this.setState({ isExpanded: true });
   }
 };

 render() {
   const {
     iconUrl,
     title,
     amount,
     data,
     empty,
   } = this.props;
   return (
     <div>
       <div className={this.classes.content}>
         <div
           className={this.state.isExpanded ? this.classes.borderWrapExp : this.classes.borderWrap}
           style={empty ? { background: '#FFFFFF' } : { background: '#F6F7FB' }}
         >
           <div className={this.classes.detailWrap}>
             <div className={this.classes.titleWrap}>
               <div
                 className={this.state.isExpanded ? this.classes.arrowRotate : this.classes.arrow}
                 onClick={() => this.togleTabClick()}
               />
               <div
                 style={{ backgroundImage: `url(${iconUrl})` }}
                 className={this.classes.icon}
               />
               <h2 className={this.classes.detailTitle}>
                 {title}
               </h2>
             </div>
             <div className={this.classes.amountWrap}>
               <div className={this.classes.amount}>
                 {`$${amount} p/y`}
               </div>
               {amount !== 0 ? (
                 <div className={this.classes.iconSuccess} />
               ) : (
                 <div className={this.classes.iconWarningWrap}>
                   <p className={this.classes.noBudget}>No Budget</p>
                   <div className={this.classes.iconWarning} />
                 </div>
               )}
               <div className={this.classes.actions} onClick={() => this.onActionsClick()}>
                 {this.state.isOpen && (
                 <div className={this.classes.menuActions} ref={this.setWrapperRef}>
                   <div className={this.classes.editActions}>
                     Edit
                   </div>
                   <div className={this.classes.removeActions} onClick={() => this.onRemoveClick()}>
                     Remove
                   </div>
                 </div>
                 )}
               </div>
             </div>
           </div>
         </div>
       </div>
       {data && this.state.isExpanded && (
         <div>
           <SetupBudgetDetailWrap />
         </div>
       )}
       {this.state.isRemoved
        && (
        <DeletePopup
          title="Are you sure you want to remove the channel"
          deleteName="[channel name]"
          onCloseClick={() => this.onCloseClick()}
        />
        )}
     </div>
   );
 }
}
