export const parseOptionsFromResponseResults = (response) => {
  const searchOptions = response.options?.map((option) => {
    const label = typeof option === 'boolean' ? option.toString() : option;
    return {
      label,
      value: option,
    };
  }) || [];
  return {
    options: searchOptions,
    hasMore: response.hasMore || false,
  };
};

export const selectLoadMoreOptions = async ({ searchValue, prevOptions, searchFunction }) => {
  const offset = prevOptions.length;
  const responseResults = await searchFunction(searchValue, offset);
  const parseOptionsFromResponse = parseOptionsFromResponseResults(responseResults);
  return parseOptionsFromResponse;
};
