import React from 'react';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import GoalsList from 'components/pages/settings/goals/GoalsList';
import Title from 'components/onboarding/Title';
import ObjectiveInput from 'components/pages/settings/goals/controls/ObjectiveInput';
import ActionPopup from 'components/pages/settings/actions/actionPopup';

import { isPopupMode } from 'modules/popup-mode';
import { compose } from 'components/utils/utils';
import { activityStatusOptions } from 'components/pages/settings/goals/logic/enums';
import { actionTriggerTypes } from 'components/pages/settings/actions/enums';

import selectStyle from 'styles/controls/select.css';
import pageStyle from 'styles/page.css';
import loaderStyle from 'styles/plan/planned-actual-tab.css';
import style from 'styles/settings/goals/goals.css';

const enhance = compose(
  inject((stores) => {
    const {
      funnels,
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
      actions,
      userRegionsNicknames,
    } = stores.userStore;

    return {
      funnels,
      fiscalYearFirstMonth,
      actions,
      userRegionsNicknames,
    };
  }),
  observer
);

class GoalsContainer extends Component {
  style = style;

  styles = [pageStyle, loaderStyle, selectStyle];

  constructor(props) {
    super(props);
    this.state = {
      expanded: {},
      showActionPopupWithData: null,
    };
  }

  render() {
    const { expanded } = this.state;
    const {
      addGoalPopup,
      editGoalPopup,
      goalsList,
      status,
      userRegionsNicknames,
      actions,
      goalsAnalysisRequestId,
      isLoaded,
    } = this.props;

    const popupMode = isPopupMode();

    return (
      <>
        {popupMode ? (
          <Title title="Goals" subTitle="" />
        ) : (
          <div className={this.classes.rowBetween}>
            <div className={this.classes.row}>
              <div className={pageStyle.locals.contentHeadTitle}>Goals</div>
            </div>
            <ObjectiveInput
              containerClass={this.classes.dateSelect}
              width="100%"
              selectProps={{
                selectedKey: status,
                options: activityStatusOptions,
                onChange: (selected) => this.props.handleChangeStatus(selected),
              }}
            />
          </div>
        )}
        <div className={this.classes.newGoalContainer} onClick={() => addGoalPopup()}>
          <div className={this.classes.addGoal} />
          Create new KPI
        </div>
        <GoalsList
          goals={goalsList}
          expanded={expanded}
          onChange={(newExpanded) => this.setState({ expanded: newExpanded })}
          editGoalPopup={editGoalPopup}
          isLoaded={isLoaded}
          status={status}
          isRoot
          createAlertPopup={(goalId) => this.setState({
            showActionPopupWithData: {
              triggerType: actionTriggerTypes.goalsTracking,
              triggerRules: [{ goalId }],
            },
          })}
          goalsAnalysisRequestId={goalsAnalysisRequestId}
        />

        {this.state.showActionPopupWithData ? (
          <ActionPopup
            onClose={() => this.setState({ showActionPopupWithData: null })}
            actionData={this.state.showActionPopupWithData}
            isMultiRegionsAccount={userRegionsNicknames?.length > 1}
            userActionNames={actions?.map((action) => action.name)}
          />
        ) : null}
      </>
    );
  }
}

export default enhance(GoalsContainer);
