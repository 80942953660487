import React from 'react';
import { cloneDeep } from 'lodash';
import { Button } from '@infinigrow/libs';

import InfoMarker from 'components/pages/InfoMarker';
import CRMfunnelRuleItem from 'components/pages/integrations/CRMfunnelRuleItem';

import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { emptyFunnelMappingRule } from 'components/pages/createIntegration/logic/enums';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals || {};

function CRMfunnelRules({ isAdvancedOptions, selectedFunnelId, disabled }) {
  const {
    funnelMapping, setParentState,
  } = useCRMCContext();

  const funnelRules = funnelMapping[selectedFunnelId]?.rules;

  const addFunnelRule = () => {
    if (disabled) {
      return;
    }
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    updatedFunnelMapping[selectedFunnelId].rules.push(emptyFunnelMappingRule);
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const deleteFunnelRule = (ruleIndex) => {
    if (disabled) {
      return;
    }
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    updatedFunnelMapping[selectedFunnelId].rules.splice(ruleIndex, 1);
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  return (
    <>
      <div className={styles.configFunnelRightTitle}>
        CRM rules
        <InfoMarker tooltipText={'Select the funnel stage\'s CRM object, field, and the field\'s value.'} TooltipProps={{ place: 'right' }} />
      </div>
      <div className={styles.whiteBox}>
        { isAdvancedOptions
          ? (
            <>
              {funnelRules.map((ruleItem, ruleIndex) => {
                const ruleIndexKey = `rule-${ruleIndex}`;
                return (
                  <div key={ruleIndexKey} className={styles.configFunnelOrDivider}>
                    <CRMfunnelRuleItem
                      selectedFunnelId={selectedFunnelId}
                      ruleIndex={ruleIndex}
                      ruleItem={ruleItem}
                      isAdvancedOptions={isAdvancedOptions}
                      deleteFunnelRule={deleteFunnelRule}
                      disabled={disabled}
                    />
                  </div>
                );
              })}
              <Button
                type="secondaryBlue"
                className={styles.configFunnelOrButton}
                onClick={() => addFunnelRule(selectedFunnelId)}
                disabled={disabled}
              >
                + OR
              </Button>
            </>
          )
          : (
            <CRMfunnelRuleItem
              selectedFunnelId={selectedFunnelId}
              ruleIndex={0}
              isAdvancedOptions={isAdvancedOptions}
              disabled={disabled}
            />
          )}
      </div>
    </>
  );
}

export default CRMfunnelRules;
