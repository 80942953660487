import {
  get, isEmpty, isFinite, sortBy, union, sumBy, last,
} from 'lodash';
import React from 'react';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import {
  formatSpecificDate,
  getAnnualOffset,
  getQuarterOffset,
  getRawDatesSpecific,
} from 'components/utils/date';
import { getFiscalYearForMonth } from 'components/utils/dates';
import BudgetsTableV2 from 'components/pages/plan/BudgetTable';
import FloatingComponent from 'components/controls/FloatingComponent';
import { formatAndAddExtraData, compose } from 'components/utils/utils';
import CommonScroll from 'components/pages/plan/CommonScroll';
import { getUniqRows } from 'components/utils/channels';
import ForecastGraph from 'components/pages/plan/forecastGraph/forecastGraph';
import FeatureFlags from 'components/common/FeatureFlags';

import style from 'styles/plan/annual-tab.css';
import planStyles from 'styles/plan/plan.css';
import icons from 'styles/icons/plan.css';
import Loader from '../../controls/Loader';

const CELL_WIDTH = 140;

const enhance = compose(
  inject((stores) => {
    const {
      planStore,
      userStore,
      forecastStore,
    } = stores;
    const {
      getCampaignProperties,
      campaignsMonthlyBudgets,
      isBudgetLoadingFromServer,
      addChannel,
    } = planStore;
    const {
      formattedGraphExplainableForecast,
    } = forecastStore;
    const {
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
    } = userStore;
    return {
      addChannel,
      fiscalYearFirstMonth,
      getCampaignProperties,
      campaignsMonthlyBudgets,
      isBudgetLoadingFromServer,
      formattedGraphExplainableForecast,
    };
  }),
  observer
);

class AnnualTab extends Component {
  style = style;

  styles = [planStyles, icons];

  static defaultProps = {
    actualIndicators: {},
    events: [],
    objectives: [],
    annualBudgetArray: [],
    campaignsTemplates: {},
  };

  addExtraSumDataAndFormatDates = (dates, quarterOffset, annualOffset, formatDateFunc, showQuarters) => {
    const { fiscalYearFirstMonth } = this.props;
    const quarterDate = (quarterData) => {
      const date = quarterData[0];

      let month = date.getMonth();
      let quarterNumber = 0;
      let yearStr = date.getFullYear().toString().substr(2, 2);
      if (month < fiscalYearFirstMonth) {
        month += 12;
        yearStr = (date.getFullYear() - 1).toString().substr(2, 2);
      }

      quarterNumber = Math.floor((month - fiscalYearFirstMonth) / 3) + 1;

      return { value: `Q${quarterNumber} ${yearStr}`, isQuarter: true };
    };

    const annualDate = (annualData) => {
      const date = annualData[annualData.length - 1];
      let yearStr = date.getFullYear().toString();
      if (date.getMonth() < fiscalYearFirstMonth) {
        yearStr = (date.getFullYear() - 1).toString();
      }
      return { value: `FY ${yearStr}`, isAnnual: true };
    };

    return this.addQuartersAndYearSumData(
      dates,
      quarterDate,
      annualDate,
      quarterOffset,
      annualOffset,
      formatDateFunc,
      showQuarters
    );
  };

  addQuartersAndYearSumData = (
    array,
    quarterSumFunc,
    annualSumFunc,
    quarterOffset,
    annualOffset,
    itemParseFunc,
    showSumData
  ) => {
    if (isEmpty(array)) {
      return [];
    } else {
      const chunkFormattingData = [{
        offset: quarterOffset,
        itemsInChunk: 3,
        chunkAdditionFormatter: quarterSumFunc,
      }, {
        offset: annualOffset,
        itemsInChunk: 12,
        chunkAdditionFormatter: annualSumFunc,
      }];
      return formatAndAddExtraData(array, (this.props.showSumData || showSumData) ? chunkFormattingData : [], itemParseFunc);
    }
  };

  // eslint-disable-next-line default-param-last
  updateCampaign = (oldCampaign, campaignValues, monthIndex, isGeneral, channel = {}, currentMon) => {
    const { updateCampaign, channelsParsedPerMonth, region } = this.props;
    let channelData = {};

    if (isFinite(campaignValues.actualSpent[monthIndex]) && !isEmpty(channel)) {
      const channelFromActuals = get(channelsParsedPerMonth, [currentMon, 'parsedChannels'], [])
        .find((c) => c.channel === channel.channel && c.region === channel.region);

      if (channelFromActuals) {
        const channelActual = get(channelFromActuals, 'spending.actual', null);

        if (isFinite(channelActual)) {
          const oldActual = isFinite(oldCampaign.actualSpent[monthIndex]) ? oldCampaign.actualSpent[monthIndex] : 0;
          const diffCampaign = campaignValues.actualSpent[monthIndex] - oldActual;

          const channelNewActual = diffCampaign + channelActual;

          if (channelNewActual >= 0) {
            channelData = {
              channelValue: channelNewActual,
              channel: channel.channel,
            };
          } else {
            return;
          }
        }
      } else {
        channelData = {
          channelValue: campaignValues.actualSpent[monthIndex],
          channel: channel.channel,
        };
      }
    }
    updateCampaign(oldCampaign, campaignValues, monthIndex, isGeneral, currentMon, channelData, null, channel.region || region);
  };

  render() {
    const {
      budgetsData = {}, budgetType, editMode, interactiveMode, showSumData,
      calculatedData,
      fiscalYearFirstMonth, editCommittedBudget, currentYear,
      deleteChannel, openAddChannelPopup, campaignsTemplates, region, regions, isMergedView,
      isCustomYear, campaignKeyToNameMapping, flags, campaignsMonthlyBudgets,
    } = this.props;

    const numberOfFutureDates = 12;

    let dates = [];
    let numberOfPastDates = 0;
    if (!isEmpty(budgetsData)) {
      const oldestBudget = sortBy(budgetsData[region], (month) => month.date)[0];
      const oldestBudgetDate = oldestBudget && oldestBudget.date;
      numberOfPastDates = moment().diff(oldestBudgetDate, 'months');
      dates = [...getRawDatesSpecific(numberOfPastDates, numberOfFutureDates)];
    }
    const quarterOffset = getQuarterOffset(dates, fiscalYearFirstMonth);
    const annualOffset = getAnnualOffset(dates, fiscalYearFirstMonth);

    const datesWithAddition = dates
      && this.addExtraSumDataAndFormatDates(dates, quarterOffset, annualOffset, (item) => formatSpecificDate(item, false));

    const datesMapped = datesWithAddition && datesWithAddition.map((month) => {
      const { realIndex, value } = month;
      if (realIndex >= 0) {
        const momentDate = moment(value, 'MMM YY');
        const year = momentDate.format('YYYY');
        const mon = momentDate.format('M');
        month.fiscalYear = getFiscalYearForMonth(mon, fiscalYearFirstMonth + 1, year);
        month.date = momentDate;
      }
      return month;
    });
    const currentYStartIdx = datesMapped && datesMapped.findIndex((m) => m.fiscalYear === `${currentYear}`);
    const nextYStartIdx = datesMapped && datesMapped.findIndex((m) => m.fiscalYear === `${currentYear + 1}`);
    const currentMonIdx = datesMapped && datesMapped.findIndex((m) => m.value === moment().format('MMM YY'));

    const sumBudgetsData = (chunk) => {
      const channelsInChunk = union(...chunk.map((month) => Object.keys(month.channels)));
      const chunkSummedChannels = {};
      channelsInChunk.forEach((channel) => {
        const primaryBudget = sumBy(chunk, (month) => get(month, ['channels', channel, 'primaryBudget'], 0));
        const secondaryBudget = sumBy(chunk, (month) => get(month, ['channels', channel, 'secondaryBudget'], 0));

        const regionsInChannel = union(...chunk.map(
          (month) => Object.keys(get(month, ['channels', channel, 'regions'], {}))
        ));

        const summedRegions = {};
        if (regionsInChannel) {
          regionsInChannel.forEach((regionInChannel) => {
            summedRegions[regionInChannel] = sumBy(chunk, (month) => get(month, ['channels', channel, 'regions', regionInChannel], 0));
          });
        }

        chunkSummedChannels[channel] = { primaryBudget, secondaryBudget, regions: summedRegions };
      });

      return { channels: chunkSummedChannels, isHistory: last(chunk).isHistory };
    };

    const addBudgetQuarterData = (chunk) => ({ ...sumBudgetsData(chunk), isQuarter: true });

    const addAnnualBudgetData = (chunk) => ({ ...sumBudgetsData(chunk), isAnnual: true });

    const dataWithSumAddition = {};
    if (budgetsData) {
      Object.keys(budgetsData).forEach((regionOfBudget) => {
        dataWithSumAddition[regionOfBudget] = this.addQuartersAndYearSumData(
          budgetsData[regionOfBudget],
          addBudgetQuarterData,
          addAnnualBudgetData,
          quarterOffset,
          annualOffset
        );
      });
    }

    const numberOfPastDatesWithSumAddition = get(dataWithSumAddition, region, [])
      .filter((item) => item.isHistory).length;

    const currentDate = get(dataWithSumAddition, [region, numberOfPastDatesWithSumAddition, 'realIndex']);

    const { uniqChannels, uniqCategories } = getUniqRows(dataWithSumAddition, region);
    return (
      <div>
        {this.props.isBudgetLoadingFromServer && (
          <div className={planStyles.locals.loader}>
            <Loader newStyle />
          </div>
        )}
        <div className={this.classes.wrap}>
          <div className={this.classes.innerBox}>
            {!isEmpty(dataWithSumAddition) && (
              <CommonScroll
                calculatedData={calculatedData}
                cellWidth={CELL_WIDTH}
                addQuartersAndYearSumData={this.addQuartersAndYearSumData}
                scrolledYear={budgetType}
                showSumData={showSumData}
                currentYStartIdx={currentYStartIdx}
                nextYStartIdx={nextYStartIdx}
                currentMonIdx={currentMonIdx}
                isCustomYear={isCustomYear}
              >
                {(scrollPosition, changeScrollPosition) => (
                  <>
                    <BudgetsTableV2
                      cellWidth={CELL_WIDTH}
                      changeScrollPosition={changeScrollPosition}
                      data={dataWithSumAddition}
                      allCategories={uniqCategories}
                      dates={datesWithAddition}
                      datesInitial={dates}
                      deleteChannel={deleteChannel}
                      editCommittedBudget={editCommittedBudget}
                      isEditMode={editMode}
                      numberOfPastDates={numberOfPastDates}
                      openAddChannelPopup={openAddChannelPopup}
                      scrollPosition={scrollPosition}
                      isConstraintsEnabled={interactiveMode}
                      campaigns={campaignsMonthlyBudgets}
                      updateCampaign={this.updateCampaign}
                      campaignsTemplates={campaignsTemplates}
                      currentDate={currentDate}
                      interactiveMode={interactiveMode}
                      region={region}
                      regions={regions}
                      showSumData={showSumData}
                      quarterOffset={quarterOffset}
                      annualOffset={annualOffset}
                      addQuartersAndYearSumData={this.addQuartersAndYearSumData}
                      isMergedView={isMergedView}
                      uniqChannels={uniqChannels}
                      campaignKeyToNameMapping={campaignKeyToNameMapping}
                    />
                    <div className={this.classes.indicatorsGraph}>
                      <FeatureFlags flag={flags.forecastingPlanBudget}>
                        <FloatingComponent popup={interactiveMode} shownText="Forecast">
                          <ForecastGraph
                            budgetEditMode={this.props.budgetEditMode}
                            scrollPosition={scrollPosition}
                            changeScrollPosition={changeScrollPosition}
                            cellWidth={CELL_WIDTH}
                            onUpdateAlternativeForecast={this.props.onUpdateAlternativeForecast}
                          />
                        </FloatingComponent>
                      </FeatureFlags>
                    </div>
                  </>
                )}
              </CommonScroll>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(AnnualTab));
