import React from 'react';
import classnames from 'classnames';
import 'moment-timezone';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import timeFrameModule from 'modules/timeframe';

import Component from 'components/Component';
import Page from 'components/Page';
import ObjectiveInput from 'components/pages/settings/goals/controls/ObjectiveInput';
import FiltersPanel from 'components/pages/users/Filters/FilterPanel/FiltersPanel';
import Dropdown from 'components/controls/Dropdown';

import { compose } from 'components/utils/utils';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { frequencyTypes, pickerTimeframeToFrequencyType } from 'components/pages/settings/goals/logic/enums';
import { extractNumberFromBudget } from 'components/utils/budget';
import { getTimeFrameInAccountTZ } from 'components/utils/goals';
import { makeFilters } from 'components/utils/filters';
import { getSelectedDates, getTimeframeOptionsByActivityStatus } from 'components/pages/settings/goals/logic/PropertiesTab';

import popupStyle from 'styles/welcome/add-member-popup.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import selectStyle from 'styles/controls/select.css';
import style from 'styles/onboarding/onboarding.css';
import navStyle from 'styles/profile/market-fit-popup.css';
import objectivePopup from 'styles/welcome/objective-popup.css';

const objClasses = objectivePopup.locals;

const enhance = compose(
  inject(({
    filterStore: {
      filtersData,
    },
    userStore: {
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
      getMetricNickname,
    },
  }) => ({
    filtersData,
    fiscalYearFirstMonth,
    getMetricNickname,
  })),
  observer
);

class PropertiesTab extends Component {
  style = style;

  styles = [objectivePopup, popupStyle, navStyle, analyzeStyle, selectStyle];

  constructor(props) {
    super(props);

    const timeframeOptions = getTimeframeOptionsByActivityStatus({ status: props.status });
    const defaultTimeframeOption = timeframeOptions.filter((option) => !option.disabled)[0];
    const defaultTimeframeTZ = getTimeFrameInAccountTZ({ timeframe: timeFrameModule.getTimeframeParams({ ...defaultTimeframeOption, fiscalYearFirstMonth: props.fiscalYearFirstMonth, useEndOfToday: false }) });
    const updateFilters = makeFilters(props.filters, this.props.filtersData);

    this.state = {
      metricName: props.metricName,
      metricType: props.metricType,
      cumulativeMetric: props.cumulativeMetric,
      title: props.title,
      description: props.description,
      priority: props.priority,
      pickerTimeframe: props.pickerTimeframe || defaultTimeframeOption.value,
      timeframe: props.timeframe || defaultTimeframeTZ,
      frequencyType: props.frequencyType || pickerTimeframeToFrequencyType[defaultTimeframeOption.value],
      targetValue: props.targetValue,
      filters: updateFilters,
    };
  }

  changeTimeFrame({ timeKey }) {
    const timeframeTZ = getTimeFrameInAccountTZ({ timeframe: timeFrameModule.getTimeframeParams({ ...timeKey, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth, useEndOfToday: false }) });

    this.setState({
      timeframe: timeframeTZ,
      pickerTimeframe: timeKey.value,
      frequencyType: pickerTimeframeToFrequencyType[timeKey.value],
    });

    this.props.resetMilestones();
  }

  getFiltersForRequest = () => {
    const filtersForRequest = this.state.filters.map((filter) => ({ data: filter.data, kind: filter.config.kind, isUiOnly: filter.isUiOnly }));
    return filtersForRequest;
  };

  render() {
    const {
      moveToMilestonesTab,
      resetMilestones,
      milestonesConfig,
      metricsOptions,
      priorities,
      status,
      editMode,
      close,
      submit,
      getMetricNickname,
    } = this.props;

    const {
      metricName,
      cumulativeMetric,
      priority,
      targetValue,
      title,
      description,
      frequencyType,
      timeframe,
      pickerTimeframe,
    } = this.state;

    const titleText = 'Create a KPI';
    const descriptionText = description || metricName
      ? `Generate ${formatIndicatorDisplay(metricName, targetValue, true, false)} ${description || ''} ${getMetricNickname({ metric: metricName })}`
      : '';

    const timeframeOptions = getTimeframeOptionsByActivityStatus({ status });
    const selectedTimeframeOption = pickerTimeframe || timeframeOptions.filter((option) => !option.disabled)[0];
    return (
      <Page
        popup
        width="875px"
        className={objClasses.externalContainer}
        contentClassName={objClasses.content}
        innerClassName={objClasses.inner}
      >
        <div className={objClasses.contentContainer}>
          <div className={objClasses.closeIcon} onClick={() => close()} />
          <div className={objClasses.centerCol}>
            <div className={objClasses.title}>
              {titleText}
            </div>
            <div className={objClasses.titleSecond}>
              {descriptionText}
            </div>
          </div>
          <div className={`${objClasses.row} ${objClasses.flexRows}`}>
            <ObjectiveInput
              title="What is your Key result?"
              description="Add a descriptive title for your goal."
              width="100%"
              inputProps={{
                value: title,
                onChange: ({ target: { value } }) => this.setState({ title: value }),
                inputClassName: objClasses.input,
              }}
            />
            <ObjectiveInput
              title="What is your KPI?"
              description="Choose the metric that is closest to your Goal"
              width="100%"
              selectProps={{
                selectedKey: metricName,
                options: metricsOptions,
                onChange: (selected) => {
                  this.setState({
                    metricName: selected.value,
                    metricType: selected.type,
                    cumulativeMetric: selected.cumulative,
                    title: '',
                  });
                  resetMilestones();
                },
              }}
            />
          </div>
          <ObjectiveInput
            containerClass={classnames(objClasses.row)}
            title="Description"
            optional
            inputProps={{
              value: description,
              onChange: ({ target: { value } }) => this.setState({ description: value }),
              inputClassName: objClasses.input,
            }}
          />
          <div className={`${objClasses.row} ${objClasses.flexRows}`}>
            <ObjectiveInput
              title="Priority"
              selectProps={{
                selectedKey: priority,
                options: priorities,
                onChange: (selected) => this.setState({ priority: selected.value }),
              }}
            />
            {editMode ? (
              <ObjectiveInput
                title="Selected Dates"
              >
                <div style={{ marginTop: 10 }}>
                  {getSelectedDates({ startDate: timeframe.startDate, endDate: timeframe.endDate })}
                </div>
              </ObjectiveInput>
            ) : (
              <ObjectiveInput
                title="Time Frame"
              >
                <Dropdown
                  disabled={editMode}
                  selectedKey={selectedTimeframeOption}
                  options={timeframeOptions}
                  onChange={(timeKey) => this.changeTimeFrame({ timeKey })}
                />
              </ObjectiveInput>
            )}
          </div>
          <div className={`${objClasses.halfRow} ${objClasses.halfRowflexDirection}`}>
            <ObjectiveInput
              title="Target"
              inputProps={{
                placeHolder: '',
                value: metricName ? formatIndicatorDisplay(metricName, targetValue, false, false) : targetValue,
                onChange: ({ target: { value } }) => {
                  this.setState({ targetValue: extractNumberFromBudget(value) });
                  resetMilestones();
                },
                icon: 'objective:target',
                inputClassName: objClasses.inputClassName,
                className: objClasses.inputBoxClassName,
                iconClass: objClasses.inputIconClass,
              }}
            />
            {(frequencyType && frequencyType !== frequencyTypes.MONTH) && cumulativeMetric ? (
              <div
                className={objClasses.milestoneLink}
                onClick={
                    () => moveToMilestonesTab({
                      metricName: this.state.metricName,
                      metricType: this.state.metricType,
                      cumulativeMetric: this.state.cumulativeMetric,
                      title: this.state.title,
                      description: this.state.description,
                      priority: this.state.priority,
                      timeframe: this.state.timeframe,
                      pickerTimeframe: this.state.pickerTimeframe,
                      frequencyType: this.state.frequencyType,
                      targetValue: this.state.targetValue,
                    })
                  }
              >
                {milestonesConfig ? 'Edit milestones' : 'Break target into milestones'}
              </div>
            ) : (
              <div className={objClasses.milestoneUnlink}>
                Milestones are not available for
                {' '}
                {!cumulativeMetric ? 'non cumulative KPIs' : 'monthly goals'}
              </div>
            )}
          </div>
          <div className={`${objClasses.row} ${objClasses.addFilters}`}>
            <FiltersPanel
              filters={this.state.filters}
              setFilters={(updateFilters) => this.setState({ filters: updateFilters })}
              isSaveFiltersButton
              filtersPopupStyle={objClasses.filtersPopup}
              clearTagsDebounce={0}
            />
          </div>
        </div>

        <div style={{ height: 50 }} />
        <div className={objClasses.footer}>
          <Button
            type="secondaryBlue"
            style={{ width: 71 }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            type="primaryBlue"
            style={{ width: 71, marginLeft: '10px' }}
            onClick={() => submit({
              metricName: this.state.metricName,
              metricType: this.state.metricType,
              cumulativeMetric: this.state.cumulativeMetric,
              title: this.state.title,
              description: this.state.description,
              priority: this.state.priority,
              timeframe: this.state.timeframe,
              frequencyType: this.state.frequencyType,
              targetValue: this.state.targetValue,
              filters: this.getFiltersForRequest(),
            })}
            disabled={!metricName}
          >
            Save
          </Button>
        </div>
      </Page>
    );
  }
}

export default enhance(PropertiesTab);
