exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".marketing-app__square-39C{width:120px;height:100px;border-radius:4px;background-color:#fff;border:1px solid #e3e6f4;padding:15px;margin:15px;display:inline-block}.marketing-app__inner-UXw{display:grid}.marketing-app__checkbox-16z{margin:0 auto;width:15px;height:15px;display:flex}.marketing-app__platform-icon-3yX{background-size:contain;background-position:50%;background-repeat:no-repeat;width:80px;height:40px;margin:12px auto 0}.marketing-app__platform-text-2MZ{font-size:15px;font-weight:500;color:#4b515e;height:40px;display:flex;align-items:center;justify-content:center;text-align:center}", ""]);

// exports
exports.locals = {
	"square": "marketing-app__square-39C",
	"square": "marketing-app__square-39C",
	"inner": "marketing-app__inner-UXw",
	"inner": "marketing-app__inner-UXw",
	"checkbox": "marketing-app__checkbox-16z",
	"checkbox": "marketing-app__checkbox-16z",
	"platform-icon": "marketing-app__platform-icon-3yX",
	"platformIcon": "marketing-app__platform-icon-3yX",
	"platform-text": "marketing-app__platform-text-2MZ",
	"platformText": "marketing-app__platform-text-2MZ"
};