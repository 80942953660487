exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".feature-flags-with-blur__header-2iV{display:flex;gap:6px;align-items:center}.feature-flags-with-blur__header-2iV h2{font-weight:600;font-size:16px;line-height:26px;color:#000;margin-top:6px}.feature-flags-with-blur__wrapper-2oX{border-radius:12px;box-shadow:0 4px 12px 0 #e6e8f0;margin-bottom:40px;display:flex;align-items:center;justify-content:center;flex-direction:column;gap:24px;padding:32px;background-color:#fff;background-position:50%;background-size:contain;background-repeat:no-repeat}.feature-flags-with-blur__text-3rp{font-size:24px;max-width:800px;font-weight:600;text-align:center;color:#222a41}", ""]);

// exports
exports.locals = {
	"header": "feature-flags-with-blur__header-2iV",
	"header": "feature-flags-with-blur__header-2iV",
	"wrapper": "feature-flags-with-blur__wrapper-2oX",
	"wrapper": "feature-flags-with-blur__wrapper-2oX",
	"text": "feature-flags-with-blur__text-3rp",
	"text": "feature-flags-with-blur__text-3rp"
};