import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import TrendArrow from 'components/common/TrendArrow';

import style from 'styles/common/comparisonValue.css';

const styles = style.locals || {};

function ComparisonValue({
  value,
  className,
  isGrowthDesired,
  overrideTextColor,
  isShowTrendArrow = true,
}) {
  useStyles([style]);

  if (isNil(value) || value === '') {
    return null;
  }

  const iconUrl = `/assets/infinity-icon-${isGrowthDesired ? 'green' : 'red'}.svg`;
  let desiredStyle = '';
  if (value !== 0) {
    if ((value > 0 && isGrowthDesired) || (value < 0 && !isGrowthDesired)) {
      desiredStyle = 'isDesired';
    } else {
      desiredStyle = 'isUndesired';
    }
  }

  const displayValue = `${value <= 0 ? '' : '+'}${value}%`;

  return (
    <div
      className={classNames(
        styles.comparisonValue,
        styles[desiredStyle],
        className
      )}
      style={overrideTextColor && { color: overrideTextColor }}
    >
      {value !== 0 && isShowTrendArrow ? (
        <TrendArrow
          width={6}
          height={6}
          isNegative={value < 0}
          isGrowthDesired={desiredStyle === 'isDesired'}
        />
      ) : null}

      <div data-testid="value">
        {value === 'Infinity' ? (
          <img
            alt="infinity icon"
            src={iconUrl}
            className={styles.infinityIcon}
          />
        ) : displayValue}
      </div>
    </div>
  );
}

export default ComparisonValue;
