import React, { useEffect, useMemo } from 'react';

import { widgetTypeToBlurImage } from 'components/pages/analyze/enums';
import useWindowDimensions from 'components/utils/getWindowDimensions';
import ErrorIndication from 'components/common/ErrorIndication';

import style from 'styles/common/errorWidgetWithBlur.css';

const styles = style.locals || {};

export default function ErrorWidgetWithBlur({ status, widgetType }) {
  useEffect(() => {
    style.use();

    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  const { width: screenWidth } = useWindowDimensions();

  const imageSize = useMemo(() => {
    if (screenWidth > 1600) {
      return '-x3';
    }
    if (screenWidth > 1400) {
      return '-x2';
    }
    return '';
  }, [screenWidth]);

  const blurImage = widgetTypeToBlurImage[widgetType];
  return (
    <div
      data-testid="ErrorWidgetWithBlur"
      className={styles.wrapper}
      style={{
        backgroundImage: `url(/assets/blured-widgets/${blurImage}${imageSize}.svg)`,
        height: 400,
      }}
    >
      <ErrorIndication status={status} />
    </div>
  );
}
