import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import WhatIfScenarios from 'components/widgets/whatIf/WhatIfScenarios';

import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';
import { widgetTypes } from 'components/pages/analyze/enums';
import {
  getParsedScenarioChartData, getParsedAccountBaseData, getParsedChannelBaseData, getParsedAvgMonthlySpendData,
} from 'components/widgets/whatIf/logic/whatIfScenarios';
import { getWidgetHashKey } from 'stores/logic/widgetsAnalysisStore';

function WhatIfWrapper({
  whatIf,
  widgetHeaderConfig,
  selectedReport,
  getWidgetRequestId,
  widgetConfig,
  updateWhatIfParams,
  historicalPerformance,
  segmentsAnalysis,
  widgetHeaderProps,
  requestAvgMonthlySpendData,
  avgMonthlySpendData,
  emptyStateComponent,
}) {
  const reportId = selectedReport.value;
  const { widgetId } = widgetHeaderConfig;
  const { whatIfParams } = widgetConfig;

  useEffect(() => {
    requestAvgMonthlySpendData({ sampleTimeframeMonths: whatIfParams.sampleTimeframeMonths, timeframe: whatIfParams.timeframe });
  }, []);

  function getScenariosBaseResponseData() {
    const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
    const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const channelsBaseData = getParsedChannelBaseData({ data: segmentAnalysisData, kpiFocus: widgetConfig.conversionIndicator });

    const avgMonthlySpendKey = getWidgetHashKey({ widgetConfig: { sampleTimeframeMonths: whatIfParams.sampleTimeframeMonths, timeframe: whatIfParams.timeframe } });
    const channelsBaseDataWithAvgMonthlySpend = getParsedAvgMonthlySpendData({ data: avgMonthlySpendData[avgMonthlySpendKey], channelsBaseData });

    const historicalPerformanceRequestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
    const historicalPerformanceData = historicalPerformance?.[historicalPerformanceRequestId]?.result;
    const isHistoricalPerformanceLoaded = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'finished';
    const accountBaseData = getParsedAccountBaseData({ data: historicalPerformanceData });

    return {
      scenariosBaseData: { ...accountBaseData, ...channelsBaseDataWithAvgMonthlySpend },
      isLoadedBaseData: isSegmentAnalysisLoaded && isHistoricalPerformanceLoaded,
    };
  }

  function onUpdateKpiFocus({ newKpiFocus }) {
    if (newKpiFocus === whatIfParams.kpiFocus) {
      return;
    }
    const updatedWhatIfParams = { ...whatIfParams, kpiFocus: newKpiFocus };
    updateWhatIfParams({ whatIfParams: updatedWhatIfParams, widgetId, reportId });
  }

  function onUpdateTimeframe({ newTimeframe }) {
    if (newTimeframe === whatIfParams.timeframe) {
      return;
    }

    requestAvgMonthlySpendData({ sampleTimeframeMonths: whatIfParams.sampleTimeframeMonths, timeframe: newTimeframe });
    const updatedWhatIfParams = { ...whatIfParams, timeframe: newTimeframe };
    updateWhatIfParams({ whatIfParams: updatedWhatIfParams, widgetId, reportId });
  }

  function onSaveSampleTimeframeMonths({ newSampleTimeframeMonths }) {
    if (newSampleTimeframeMonths === whatIfParams.sampleTimeframeMonths) {
      return;
    }
    requestAvgMonthlySpendData({ sampleTimeframeMonths: newSampleTimeframeMonths, timeframe: whatIfParams.timeframe });
    const updatedWhatIfParams = { ...whatIfParams, sampleTimeframeMonths: newSampleTimeframeMonths };
    updateWhatIfParams({ whatIfParams: updatedWhatIfParams, widgetId, reportId });
  }

  function onSaveScenarioSettings({ newScenarios, newKpiFocus }) {
    const updatedWhatIfParams = { ...whatIfParams, scenarios: newScenarios };
    if (newKpiFocus) {
      updatedWhatIfParams.kpiFocus = newKpiFocus;
    }
    updateWhatIfParams({ whatIfParams: updatedWhatIfParams, widgetId, reportId });
  }

  const { scenariosBaseData, isLoadedBaseData } = getScenariosBaseResponseData();
  const whatIfRequestId = getWidgetRequestId({ widget: widgetTypes.whatIf, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
  const whatIfDataScenarios = whatIf?.[whatIfRequestId]?.result?.data?.scenarios ?? [];
  const whatIfScenariosData = getParsedScenarioChartData({ data: whatIfDataScenarios, timeFrameLabel: whatIfParams.timeframe.value });
  const isWhatIfFailedToLoad = whatIf?.[whatIfRequestId]?.status === 'failed';
  const isWhatIfLoaded = whatIf?.[whatIfRequestId]?.status === 'finished';

  return (
    <WhatIfScenarios
      isLoaded={isWhatIfLoaded}
      isLoadedBaseData={isLoadedBaseData}
      isFailedToLoad={isWhatIfFailedToLoad}
      scenariosData={whatIfScenariosData}
      kpiFocus={whatIfParams.kpiFocus}
      updateKpiFocus={(newKpiFocus) => onUpdateKpiFocus({ newKpiFocus })}
      scenarios={whatIfParams.scenarios}
      timeframe={whatIfParams.timeframe}
      updateTimeframe={({ newTimeframe }) => onUpdateTimeframe({ newTimeframe })}
      sampleTimeframeMonths={whatIfParams.sampleTimeframeMonths}
      onSaveSampleTimeframeMonths={({ newSampleTimeframeMonths }) => onSaveSampleTimeframeMonths({ newSampleTimeframeMonths })}
      onSaveScenarioSettings={({ newScenarios, newKpiFocus }) => onSaveScenarioSettings({ newScenarios, newKpiFocus })}
      scenariosBaseData={scenariosBaseData}
      widgetHeaderProps={widgetHeaderProps}
      widgetHeaderConfig={widgetHeaderConfig}
      emptyStateComponent={emptyStateComponent}
    />
  );
}

export default inject(
  ({
    reportsStore: {
      selectedReport,
      updateWhatIfParams,
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.whatIf]: whatIf,
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.historicalPerformance]: historicalPerformance,
      },
    },
    forecastStore: {
      requestAvgMonthlySpendData,
      avgMonthlySpendData,
      isLoadedAvgMonthlySpendData,
    },
  }) => ({
    whatIf,
    selectedReport,
    updateWhatIfParams,
    getWidgetRequestId,
    segmentsAnalysis,
    historicalPerformance,
    requestAvgMonthlySpendData,
    avgMonthlySpendData,
    isLoadedAvgMonthlySpendData,
  }),
  observer
)(WhatIfWrapper);
