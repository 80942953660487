exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".template-box__frame-1CY{width:170px;height:140px;border-radius:5px;margin:15px 10px;cursor:pointer;border:1px solid #4a90e2;background-color:#ecf4fd;display:inline-block;overflow:hidden}.template-box__frame-1CY[data-selected]{border:4px solid #4a90e2}.template-box__frame-1CY[data-white]{background-color:#fff}.template-box__inner-ZUw{padding:15px;position:relative}.template-box__icon-position-tsF{justify-content:center;display:flex}.template-box__back-icon-3gQ{width:30px;height:23px;background:url(\"/assets/back.svg\") 50% no-repeat;background-size:contain;position:absolute}.template-box__frame-text-i30{font-weight:700;color:#0564a5}.template-box__frame-text-center-3yx{display:flex;justify-content:center;margin-top:45px;text-transform:uppercase}.template-box__number-3ol{top:107px;left:122px;position:absolute}.template-box__icons-1Tw{top:105px;left:19px;position:absolute;display:inline-flex}.template-box__icon-3jp{width:20px;height:20px;background-size:contain;background-position:50%;background-repeat:no-repeat;margin-right:10px}", ""]);

// exports
exports.locals = {
	"frame": "template-box__frame-1CY",
	"frame": "template-box__frame-1CY",
	"inner": "template-box__inner-ZUw",
	"inner": "template-box__inner-ZUw",
	"icon-position": "template-box__icon-position-tsF",
	"iconPosition": "template-box__icon-position-tsF",
	"back-icon": "template-box__back-icon-3gQ",
	"backIcon": "template-box__back-icon-3gQ",
	"frame-text": "template-box__frame-text-i30",
	"frameText": "template-box__frame-text-i30",
	"frame-text-center": "template-box__frame-text-center-3yx template-box__frame-text-i30",
	"frameTextCenter": "template-box__frame-text-center-3yx template-box__frame-text-i30",
	"frame-text-top": "template-box__frame-text-top-1KO template-box__frame-text-i30",
	"frameTextTop": "template-box__frame-text-top-1KO template-box__frame-text-i30",
	"number": "template-box__number-3ol template-box__frame-text-i30",
	"number": "template-box__number-3ol template-box__frame-text-i30",
	"icons": "template-box__icons-1Tw",
	"icons": "template-box__icons-1Tw",
	"icon": "template-box__icon-3jp",
	"icon": "template-box__icon-3jp"
};