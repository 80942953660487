import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';

import style from 'styles/onboarding/onboarding.css';
import assetsStyle from 'styles/campaigns/assets-popup.css';

export default class AssetsPopup extends Component {
  style = style;

  styles = [assetsStyle];

  static defaultProps = {
    campaign: {
      assets: [],
    },
    hidden: true,
  };

  handleChange(index, paramerter, event) {
    const update = { ...this.props.campaign };
    update.assets[index][paramerter] = event.target.value;
    this.props.updateState({ campaign: update });
  }

  addRow() {
    const update = { ...this.props.campaign };
    if (!update.assets) {
      update.assets = [];
    }
    update.assets.push({ name: '', link: 'http://', category: '' });
    this.props.updateState({ campaign: update });
  }

  saveAssets() {
    this.props.updateCampaign();
    this.props.close();
  }

  render() {
    const rows = this.props.campaign.assets && this.props.campaign.assets.length > 0
      ? this.props.campaign.assets.map((asset, index) => (
        <tr key={index} className={assetsStyle.locals.tableCell}>
          <td>
            <input value={asset.name} className={assetsStyle.locals.input} onChange={this.handleChange.bind(this, index, 'name')} />
          </td>
          <td>
            <input value={asset.link} className={assetsStyle.locals.input} onChange={this.handleChange.bind(this, index, 'link')} />
          </td>
          <td>
            <input value={asset.category} className={assetsStyle.locals.input} onChange={this.handleChange.bind(this, index, 'category')} />
          </td>
        </tr>
      ))
      : null;
    return (
      <div hidden={this.props.hidden}>
        <Page popup width="570px">
          <table className={assetsStyle.locals.table}>
            <thead>
              <tr className={assetsStyle.locals.tableHeader}>
                <th>Name</th>
                <th>Link</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <div className={assetsStyle.locals.add}>
            <Button
              type="primaryBlue"
              className={assetsStyle.locals.addButton}
              containerClassName={assetsStyle.locals.addButtonCont}
              onClick={() => this.addRow.bind(this)}
            >
              Add+
            </Button>
          </div>
          <div className={this.classes.footer}>
            <div className={this.classes.footerLeft}>
              <Button type="secondaryWhite" style={{ width: '100px' }} onClick={this.props.close}>Cancel</Button>
            </div>
            <div className={this.classes.footerRight}>
              <Button type="primaryBlue" style={{ width: '100px' }} onClick={() => this.saveAssets}>Done</Button>
            </div>
          </div>
        </Page>
      </div>
    );
  }
}
