import React from 'react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';

import style from 'styles/plan/add-channel-popup.css';

export default class AddChannelPopup extends Component {
  style = style;

  initialState = (props) => ({
    visibleChannels: props.channels.root.children,
    expandedChannels: [],
  });

  constructor(props) {
    super(props);
    this.state = { ...this.initialState(props) };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({ ...this.initialState(nextProps) });

      if (nextProps.initialExpandedChannel) {
        this.toggleChannel(nextProps.initialExpandedChannel);
      }
    }
  }

  toggleChannel(channelId) {
    const { channels } = this.props;
    const { visibleChannels, expandedChannels } = this.state;
    const channelExpandedIndex = expandedChannels.indexOf(channelId);
    const channelVisibleIndex = visibleChannels.indexOf(channelId);
    const channel = channels[channelId];

    if (!channel) {
      return;
    }

    let newExpandedChannels;
    let newVisibleChannels;

    if (channelExpandedIndex === -1) {
      newVisibleChannels = visibleChannels.slice();
      newVisibleChannels.splice(channelVisibleIndex + 1, 0, ...channel.children);
      newExpandedChannels = expandedChannels.concat(channelId);
    } else {
      newExpandedChannels = expandedChannels.slice();
      newExpandedChannels.splice(channelExpandedIndex, 1);
      newVisibleChannels = visibleChannels.slice();

      const sameOrLowerLevelIndex = visibleChannels
        .slice(channelVisibleIndex + 1)
        .findIndex((chId) => channels[chId].level <= channels[channelId].level);
      const hiddenChannels = newVisibleChannels.splice(channelVisibleIndex + 1, sameOrLowerLevelIndex);

      newExpandedChannels = newExpandedChannels.filter((ch) => !hiddenChannels.includes(ch));
    }

    this.setState({
      expandedChannels: newExpandedChannels,
      visibleChannels: newVisibleChannels,
    });
  }

  handleChannelClick(channelId) {
    const channel = this.props.channels[channelId];

    if (channel.isLeaf) {
      this.props.onChannelChoose(channel.id, channel.isOther);
      return;
    }

    this.toggleChannel(channelId);
  }

  render() {
    const { visibleChannels, expandedChannels } = this.state;
    return (
      <div hidden={this.props.hidden}>
        <Page popup width="400px">
          <div className={this.classes.title}>
            Add a channel
          </div>
          <ul className={this.classes.channels}>
            {
              visibleChannels
                .map((channelId) => {
                  if (channelId === 'other?') {
                    return null;
                  }
                  const channel = this.props.channels[channelId];
                  const className = classnames(this.classes.channel, {
                    [this.classes.expandedChannel]: expandedChannels.includes(channelId),
                    [this.classes.leafChannel]: channel.isLeaf,
                  });

                  return (
                    <li
                      role="menuitem"
                      key={channelId}
                      style={{ marginLeft: `${20 * (channel.level - 1)}px` }}
                      className={className}
                      onClick={() => this.handleChannelClick(channelId)}
                    >
                      {channel.title}
                    </li>
                  );
                })
            }
          </ul>
          <Button
            type="secondaryWhite"
            style={{ width: '72px' }}
            onClick={() => this.props.close()}
          >
            Cancel
          </Button>
        </Page>
      </div>
    );
  }
}
