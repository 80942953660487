import React, { useEffect } from 'react';
import humanizeDuration from 'humanize-duration';

import { getNickname as getChannelNickname } from 'components/utils/channels';
import Dropdown from 'components/controls/Dropdown';

import style from 'styles/settings/workflows/configPopup.css';

export default function TouchPointsTimestamp({ configuration, isViewOnly }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  function convertTimestamp(mapping) {
    const convertedMapping = {};
    for (const [channel, timeInMinutes] of Object.entries(mapping)) {
      const duration = humanizeDuration(timeInMinutes * 60000).split(' ');
      convertedMapping[channel] = {
        time: duration[0],
        unit: duration[1],
      };
    }
    return convertedMapping;
  }

  return (
    <>
      {Object.entries(convertTimestamp(configuration.mapping)).map(([channel, channelConfig]) => (
        <div className={style.locals.row}>
          When channel is
          <Dropdown
            disabled={isViewOnly}
            selectedKey={channel}
            options={[{ label: getChannelNickname(channel), value: channel }]}
            controlWidth={240}
          />
          ,move touchpoint in
          <Dropdown
            disabled={isViewOnly}
            selectedKey={channelConfig.time}
            options={[{ label: channelConfig.time, value: channelConfig.time }]}
            controlWidth={54}
          />
          <Dropdown
            disabled={isViewOnly}
            selectedKey={channelConfig.unit}
            options={[{ label: channelConfig.unit, value: channelConfig.unit }]}
            controlWidth={140}
          />
        </div>
      ))}
    </>
  );
}
