import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  get,
  isEqual,
  isInteger,
} from 'lodash';
import classnames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import Component from 'components/Component';
import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';
import FilterActions from 'components/pages/users/Filters/FilterActions';
import CustomDateSelect from 'components/pages/users/Filters/CustomDateSelect';
import Toggle from 'components/controls/Toggle';
import FilterVariantsWithFunnelStage from 'components/pages/users/Filters/FilterVariantsWithFunnelStage';

import { CommonPropTypes } from 'components/pages/users/Filters/CommonFilter';
import {
  getInitState,
  includeVariants,
  beforeStageMap,
  funnelStagesVariants,
  POSITION_TYPES, POSITION_OPTIONS,
} from 'components/utils/filters';
import { FUNNEL1 } from 'components/utils/indicators';
import attributionStore from 'stores/attributionStore';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { filtersUIConfig } from 'components/pages/users/Filters/FilterPopup';
import { getUpdateSelectedFunnels } from 'components/pages/users/Filters/logic/FilterVariantsWithFunnelStage';

import { filterKinds, VARIANTS } from 'components/utils/filters/logic';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

export default class BaseFilter extends Component {
  static propTypes = CommonPropTypes;

  constructor(props) {
    super(props);
    this.state = {
      ...getInitState(props.activeFilter, props.kind),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFunnelFilter,
    } = this.props;
    const {
      relatedEntities,
      selectedFunnels,
    } = this.state;

    const didIsFunnelFilterChange = !isEqual(isFunnelFilter, prevProps.isFunnelFilter);
    const didRelatedEntitiesChange = !isEqual(relatedEntities, prevState.relatedEntities);
    const didSelectedFunnelsChange = !isEqual(selectedFunnels, prevState.selectedFunnels);

    if (didIsFunnelFilterChange || didRelatedEntitiesChange || didSelectedFunnelsChange) {
      if (relatedEntities && (isFunnelFilter || !this.isFunnelStageSelected)) {
        this.changeRelatedEntitiesCheckbox(false);
      }
    }
  }

  get isFunnelStageSelected() {
    return this.state.selectedFunnels != null && this.state.selectedFunnels.length > 0;
  }

  onChangeComparison = ({ value }) => {
    const { activeFilter } = this.props;
    if (get(activeFilter, 'data.comparisonOperator') === value) {
      this.updateFilter({ selectedOptions: activeFilter.data.selectedOptions });
    } else {
      this.updateFilter({ selectedOptions: [] });
    }
    this.props.changeComparisonHandler(value);
  };

  containsValueHandler = (values = []) => {
    const containsItems = values.map((item) => item.label);
    this.updateFilter({ selectedOptions: containsItems });
  };

  get selectedOptions() {
    const { selectedFilter } = this.state;
    return get(selectedFilter, 'data.selectedOptions', []);
  }

  handleFilterApply = () => {
    this.props.addFilterToSelection(this.state.selectedFilter, null, null, () => this.props.onAddFilter());
  };

  setFilterVariant = (variant) => {
    const newState = { variant: variant.value };
    if (!this.props.isFunnelFilter) {
      const isPositionInVariants = POSITION_OPTIONS.some((option) => option === variant.label);
      newState.isPositionVariantDisable = !isPositionInVariants;
      newState.touchpointPosition = isPositionInVariants ? this.state.touchpointPosition : POSITION_TYPES.anywhere;
    }
    this.setState(newState, this.updateFilter);
  };

  setPositionItem = (position) => {
    this.setState({ isFunnelMode: false }, () => this.updateFilter({ position }));
  };

  addItem = (e) => this.updateFilter({ selectedOptions: [...this.selectedOptions, e.value] });

  removeItem = (item) => {
    this.updateFilter({ selectedOptions: this.selectedOptions.filter((i) => i !== item) });
  };

  updateFilter = (newData) => {
    const { kind, comparisonOperator } = this.props;
    const data = {
      selectedOptions: this.selectedOptions,
      position: get(this.state.selectedFilter, 'data.position'),
      timeFrame: get(this.state.selectedFilter, 'data.timeFrame'),
      selectedFunnels: get(this.state.selectedFilter, 'data.selectedFunnels'),
      relatedEntities: get(this.state.selectedFilter, 'data.relatedEntities'),
      ...newData,
    };

    const { isFunnelMode, variant, fieldIndex } = this.state;

    const filterKind = this.getKind(isFunnelMode, kind, fieldIndex);
    const filterObj = {
      kind: filterKind,
      data: {
        variant,
        selectedOptions: data.selectedOptions,
        fieldIndex,
        comparisonOperator,
        position: data.position,
        selectedFunnels: data.selectedFunnels,
        relatedEntities: data.relatedEntities,
      },
    };
    if (this.state.isFunnelMode) {
      filterObj.data.selectedFunnel = this.state.selectedFunnel;
    }
    if (isInteger(this.state.fieldIndex)) {
      filterObj.data.fieldIndex = this.state.fieldIndex;
    }
    if (this.state.touchpointPosition) {
      filterObj.data.position = this.state.touchpointPosition;
    }
    if (data.timeFrame) {
      filterObj.data.timeFrame = {
        startTS: new Date(getTSForTimezone(new Date(data.timeFrame.startTS))),
        endTS: new Date(getTSForTimezone(new Date(data.timeFrame.endTS))),
        value: data.timeFrame.value,
      };
    }
    const shouldRemoveBeforeFunnelFilter = filterKind !== filterKinds.CAMPAIGN_TAGS && filterKind !== filterKinds.FUNNEL_STAGES && !isFunnelMode && this.selectedOptions.length > 0;

    this.props.updateFiltersItems(filterObj, shouldRemoveBeforeFunnelFilter);
    this.setState({ selectedFilter: filterObj });
  };

  setFunnel = (f) => this.setState({ selectedFunnel: f.value }, this.updateFilter);

  setCheckbox = () => this.setState((prevState) => ({ isFunnelMode: !prevState.isFunnelMode }), this.updateFilter);

  setCheckboxCustomDate = () => this.setState((prevState) => ({ isCustomDate: !prevState.isCustomDate }), this.updateFilter);

  selectOptions = (selectedOptions = []) => {
    const selected = selectedOptions.map((opt) => opt.value);
    if (selected.length === 0 && this.state.isFunnelMode) {
      this.setState({ isFunnelMode: false }, () => this.updateFilter({ selectedOptions: selected }));
    } else {
      this.updateFilter({ selectedOptions: selected });
    }
  };

  setFilterFieldIndex = (e) => {
    const { fieldKey } = this.props.config;
    this.setState({
      fieldIndex: Array.isArray(fieldKey) ? fieldKey.indexOf(e.value) : e.value,
      isShowBeforeFunnelConversion: e.value !== filterKinds.CAMPAIGN_TAGS,
    }, () => this.updateFilter({ selectedOptions: [] }));
  };

  setSelectedFunnels = (items = []) => {
    const selectedFunnels = getUpdateSelectedFunnels(items);
    const selectedFunnelsForRequest = selectedFunnels.map((funnel) => funnel.value).filter((a) => a !== 'accounts');
    this.setState({
      selectedFunnels,
    }, () => this.updateFilter({ selectedFunnels: selectedFunnelsForRequest }));
  };

  changeRelatedEntitiesCheckbox = (value) => {
    this.setState({
      relatedEntities: value,
    }, () => this.updateFilter({ relatedEntities: value }));
  };

  getKind(isFunnelMode, kind, fieldIndex) {
    if (kind === filterKinds.CAMPAIGNS && fieldIndex === 1) {
      return filterKinds.CAMPAIGN_TAGS;
    }

    if (isFunnelMode) {
      return beforeStageMap[kind]();
    }

    return kind;
  }

  render() {
    const {
      onCancel,
      children,
      kind,
      isFunnelFilter,
      stageOptions,
    } = this.props;
    const {
      fieldIndex,
      variant,
      selectedFunnel = FUNNEL1,
      isFunnelMode,
      isCustomDate,
      selectedFunnels,
      relatedEntities,
      touchpointPosition,
      isPositionVariantDisable,
      isShowBeforeFunnelConversion,
    } = this.state;

    const followingKindFunnelTitle = filtersUIConfig[kind].title.toLowerCase();
    const userFunnels = selectedFunnels.map((funnel) => funnel.label);
    const userFunnelsStr = userFunnels.join(', ');
    const checkboxTooltipText = `Apply to track filtered ${userFunnelsStr} progress through the funnel`;
    const isPositionFirstLast = touchpointPosition === POSITION_TYPES.first || touchpointPosition === POSITION_TYPES.last;
    const isVariantShowOnly = variant === VARIANTS.INCLUDE_ANY_OF_EXACT;

    return (
      <div className={styles.filterConfig}>
        <div className={classnames(styles.filterContent, this.isFunnelStageSelected && !isFunnelFilter ? styles.filterContentCustomFields : null)}>
          <div className={styles.fullHeightCol}>
            <div>
              <div className={styles.filterVariant}>
                <FilterVariantsWithFunnelStage
                  onChangeVariantHandler={this.setFilterVariant}
                  variantsOptions={isFunnelFilter ? funnelStagesVariants : includeVariants}
                  defaultIndex={variant}
                  showBottomInfo
                  followingKind={`the following ${followingKindFunnelTitle}:`}
                  onChangeFunnelHandler={this.setSelectedFunnels}
                  selectedFunnels={selectedFunnels}
                />
              </div>

              {children({
                addItem: this.addItem,
                selectedOptions: this.selectedOptions,
                removeItem: this.removeItem,
                selectOptions: this.selectOptions,
                setFilterFieldIndex: this.setFilterFieldIndex,
                onChangeComparison: this.onChangeComparison,
                containsValueHandler: this.containsValueHandler,
                fieldIndex,
              })}
            </div>

            {(!isFunnelFilter && isShowBeforeFunnelConversion && !isVariantShowOnly) ? (
              <div className={classnames(styles.positionRow, isPositionVariantDisable && styles.disabledRow)}>
                <div className={styles.filterSubTitle}>
                  Touchpoint position
                  <InfoMarker
                    containerClass={styles.tooltipIcon}
                    TooltipProps={{ place: 'top' }}
                    tooltipText="The position in the journey where the touchpoint occurred."
                  />
                </div>

                <Toggle
                  style={{ justifyContent: 'flex-start' }}
                  disabled={isPositionVariantDisable}
                  options={[
                    {
                      text: 'Anywhere',
                      value: POSITION_TYPES.anywhere,
                    },
                    {
                      text: 'First',
                      value: POSITION_TYPES.first,
                    },
                    {
                      text: 'Last',
                      value: POSITION_TYPES.last,
                    },
                  ]}
                  selectedValue={this.state.touchpointPosition}
                  onClick={(position) => this.setState({ touchpointPosition: position }, () => this.setPositionItem(position))}
                />
              </div>
            ) : null}

            {(isShowBeforeFunnelConversion && !isVariantShowOnly) ? (
              <div className={styles.funnelRow}>
                <Checkbox
                  key="Before funnel conversion"
                  checked={isFunnelMode && !isPositionFirstLast}
                  onChange={this.setCheckbox}
                  disabled={isEmpty(this.selectedOptions) || isPositionFirstLast}
                />
                <span className={styles.funnelRowText}>Before funnel conversion</span>
                <InfoMarker containerClass={styles.tooltipIcon} TooltipProps={{ place: 'top' }} tooltipText="Add a constraint to the filter that will only show the filtered results that also converted to a selected funnel stage afterwards. " />
                {isFunnelMode && !isPositionFirstLast && !isVariantShowOnly && (
                  <div className={styles.filterActionsCustomDate}>
                    <Dropdown
                      selectedKey={selectedFunnel}
                      options={stageOptions ? stageOptions(fieldIndex) : attributionStore.metricsOptions}
                      onChange={this.setFunnel}
                      controlWidth={160}
                    />
                  </div>
                )}
              </div>
            ) : null}

            {(isShowBeforeFunnelConversion && !isVariantShowOnly) ? (
              <div className={styles.funnelRow}>
                <Checkbox
                  key="Custom date"
                  checked={isCustomDate}
                  onChange={this.setCheckboxCustomDate}
                  disabled={isEmpty(this.selectedOptions)}
                />
                <span className={styles.funnelRowText}>Custom date</span>
                <InfoMarker containerClass={styles.tooltipIcon} TooltipProps={{ place: 'top' }} tooltipText="Select a custom timeframe for the filtered values. Use Custom Date to filter activities or stage transitions that took place during a custom timeframe, rather than the global timeframe." />

                {isCustomDate && (
                  <CustomDateSelect
                    timeFrame={get(this.state.selectedFilter, 'data.timeFrame')}
                    updateFilter={this.updateFilter}
                    isTitleShow={false}
                    customDatePopupClass={styles.customDatePopup}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>

        {(this.isFunnelStageSelected && !isFunnelFilter) ? (
          <div className={styles.relatedObjects}>
            <Checkbox
              key="Use Associated objects"
              checked={relatedEntities}
              onChange={() => this.changeRelatedEntitiesCheckbox(!relatedEntities)}
            >
              { `Track filtered ${userFunnelsStr} through all funnel stages` }
            </Checkbox>
            <InfoMarker containerClass={styles.tooltipIcon} tooltipText={checkboxTooltipText} />
          </div>
        ) : null}

        <FilterActions
          onApply={this.handleFilterApply}
          onCancel={onCancel}
          filtersItems={this.props.filtersItems}
        />
      </div>
    );
  }
}
