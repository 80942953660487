import { orderBy } from 'lodash';
import { spotlightTypes } from 'components/pages/insights/logic/enums';

export function getReorderedSpotlightData({ data }) {
  const parsedDataToArray = [];
  for (const [type, dataObject] of Object.entries(data)) {
    if (type === 'hasMore' || dataObject.length === 0) {
      continue;
    }
    const itemData = [];
    for (const item of dataObject) {
      itemData.push({ ...item, type });
    }
    const orderedItemData = orderBy(itemData, ['rank']);
    parsedDataToArray.push(orderedItemData);
  }
  const reorderedData = orderBy(parsedDataToArray, (item) => Math.abs(item[0].averageRatio), ['desc']);

  return reorderedData;
}

export function concatSpotlightDataResult({ currentWidgetData, newWidgetData }) {
  const updateWidgetData = { ...currentWidgetData };
  for (const [widgetItemKey, widgetItemValue] of Object.entries(newWidgetData)) {
    if (Array.isArray(widgetItemValue)) {
      if (currentWidgetData[widgetItemKey]) {
        updateWidgetData[widgetItemKey] = [...currentWidgetData[widgetItemKey], ...widgetItemValue];
      } else {
        updateWidgetData[widgetItemKey] = widgetItemValue;
      }
    } else {
      updateWidgetData.hasMore = widgetItemValue;
    }
  }
  return updateWidgetData;
}

export function getSpotlightTypeToTag({ data = {} }) {
  const spotlightTypesNames = Object.keys(data);
  const typesNamesWithoutHasMore = spotlightTypesNames.filter((type) => type !== 'hasMore');

  if (typesNamesWithoutHasMore.length === 0) {
    return '';
  }

  if (typesNamesWithoutHasMore.includes(spotlightTypes.underPerforming) && data[spotlightTypes.underPerforming]?.length > 0) {
    return spotlightTypes.underPerforming;
  }

  if (typesNamesWithoutHasMore.includes(spotlightTypes.mostDownTrending) && data[spotlightTypes.mostDownTrending]?.length > 0) {
    return spotlightTypes.mostDownTrending;
  }

  return typesNamesWithoutHasMore[0];
}
