exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".journey-stats__userJourneyStatsWrapper-2Lr{display:flex;gap:40px;flex-direction:column;overflow:hidden;flex-grow:1;flex-shrink:1;width:100%;max-height:80vh;overflow-y:auto;padding:86px 32px 24px;background:#fff;border-radius:4px 0 0 4px}.journey-stats__title-1Sy{color:#182033;font-size:16px;font-weight:600;padding-bottom:12px}.journey-stats__accountEngagementDropdowns-2fB{display:flex;gap:12px;flex-direction:row}.journey-stats__metricStatsCardWrapper-3rF{display:grid;grid-template-columns:repeat(3,1fr);gap:12px;margin-top:12px}.journey-stats__tooltipForMetric-2IN{text-align:left;display:flex;gap:12px;flex-direction:column;padding:6px 0 8px;line-height:18px}.journey-stats__tooltipForMetric-2IN ul{padding:0;margin:0}.journey-stats__tooltipForMetric-2IN li{padding:0;margin:0;list-style-position:inside}.journey-stats__tooltipCapitalized-348{text-transform:capitalize}.journey-stats__tooltipForMetricBenchmark-250{text-align:left;padding:6px 0 8px;line-height:18px}.journey-stats__tooltipForMetricBenchmark-250 b{display:inline-block;padding-left:6px}.journey-stats__journeyImpactByChartWrapper-1o5{border-radius:0 12px 12px;background:#fff;box-shadow:0 0 20px 0 #edeef2;color:#182033;padding:12px;margin-top:12px;position:relative;transform:translate(0)}.journey-stats__journeyImpactByChartLoading-1kN{padding:24px;justify-content:center;height:300px;display:flex;align-items:center}.journey-stats__hidden-1SG{display:none}", ""]);

// exports
exports.locals = {
	"userJourneyStatsWrapper": "journey-stats__userJourneyStatsWrapper-2Lr",
	"userJourneyStatsWrapper": "journey-stats__userJourneyStatsWrapper-2Lr",
	"title": "journey-stats__title-1Sy",
	"title": "journey-stats__title-1Sy",
	"accountEngagementDropdowns": "journey-stats__accountEngagementDropdowns-2fB",
	"accountEngagementDropdowns": "journey-stats__accountEngagementDropdowns-2fB",
	"metricStatsCardWrapper": "journey-stats__metricStatsCardWrapper-3rF",
	"metricStatsCardWrapper": "journey-stats__metricStatsCardWrapper-3rF",
	"tooltipForMetric": "journey-stats__tooltipForMetric-2IN",
	"tooltipForMetric": "journey-stats__tooltipForMetric-2IN",
	"tooltipCapitalized": "journey-stats__tooltipCapitalized-348",
	"tooltipCapitalized": "journey-stats__tooltipCapitalized-348",
	"tooltipForMetricBenchmark": "journey-stats__tooltipForMetricBenchmark-250",
	"tooltipForMetricBenchmark": "journey-stats__tooltipForMetricBenchmark-250",
	"journeyImpactByChartWrapper": "journey-stats__journeyImpactByChartWrapper-1o5",
	"journeyImpactByChartWrapper": "journey-stats__journeyImpactByChartWrapper-1o5",
	"journeyImpactByChartLoading": "journey-stats__journeyImpactByChartLoading-1kN",
	"journeyImpactByChartLoading": "journey-stats__journeyImpactByChartLoading-1kN",
	"hidden": "journey-stats__hidden-1SG",
	"hidden": "journey-stats__hidden-1SG"
};