import React, { useMemo } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';
import Textfield from 'components/controls/Textfield';

import { sectionTypes } from 'components/pages/settings/actions/enums';
import { getPreviewBody, getPreviewSubject } from 'components/pages/settings/actions/actionPopup/previewSection/logic/previewSection';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function PreviewSection({
  setSectionToOpen,
  isPreviewSectionOpen,
  triggerRules,
  toDoMessage,
  actionName,
  setNotifications,
  notifications,
  triggerData,
}) {
  useStyles([style]);

  const notificationType = notifications[0].notificationType;

  const previewBody = useMemo(() => getPreviewBody({
    notificationType, triggerRules, toDoMessage, triggerType: triggerData.triggerType, frequency: triggerData.frequency, actionName,
  }), [triggerRules, triggerData.triggerType, triggerData.frequency, notifications, toDoMessage, actionName]);

  const previewSubject = useMemo(() => getPreviewSubject({
    notificationType, actionName, triggerType: triggerData.triggerType, frequency: triggerData.frequency,
  }), [triggerRules, triggerData.triggerType, triggerData.frequency, notifications, toDoMessage, actionName]);

  function updatedToDoMessage({ event }) {
    const updatedNotifications = [...notifications];
    updatedNotifications[0].toDoMessage = event.target.value;
    setNotifications(updatedNotifications);
  }

  return (
    <div className={classnames(styles.bodySection, isPreviewSectionOpen && styles.bodySectionOpen)}>
      <div
        className={styles.bodyTitleWrapper}
        onClick={() => (isPreviewSectionOpen ? setSectionToOpen(null) : setSectionToOpen(sectionTypes.preview))}
      >
        <div
          className={classnames(styles.bodyTitle, isPreviewSectionOpen ? null : styles.collapsedTitle)}
        >
          3. Preview
          <i className={styles.optionaltext}> (optional)</i>
        </div>
      </div>

      {isPreviewSectionOpen ? (
        <>
          {previewSubject !== '' ? (
            <div className={styles.row}>
              <div className={classnames(styles.text, styles.titleMinWidth)}>
                Subject
              </div>
              <div className={styles.colorWrapper}>{previewSubject}</div>
            </div>
          ) : null}
          <div className={styles.row}>
            <div className={classnames(styles.text, styles.titleMinWidth)}>
              Body
            </div>
            <div className={classnames(styles.colorWrapper, styles.emailBody)}>{previewBody}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.text}>
              To do
              <i>(optional)</i>
            </div>
            <InfoMarker tooltipText="What is your planned action item once the above conditions are met?" />
            <Textfield
              value={toDoMessage}
              className={styles.textFieldInput}
              onChange={(event) => updatedToDoMessage({ event })}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default PreviewSection;
