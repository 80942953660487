import { cloneDeep, merge } from 'lodash';
import { checkAllFieldsAreFilledForSpecificPlatforms } from './CampaignCustomFieldsFilter';
import { EQUALS, NOT_EQUALS } from '../../../../utils/filters/logic';

export const checkAllFieldsAreFilledForAllPlatforms = (predefinedFilters) => {
  for (const filter of predefinedFilters) {
    const allFieldsAreFilledForAllPlatforms = checkAllFieldsAreFilledForSpecificPlatforms(filter.data);
    if (!allFieldsAreFilledForAllPlatforms) {
      return false;
    }
  }
  return true;
};

export const getFilterConfigsFromSavedFilter = (filters, costFilterConfigs) => {
  const updatedCostFilterConfigs = cloneDeep(costFilterConfigs);
  for (const filter of filters) {
    const filterPlatform = filter.data.platform;
    let updateCostObject = new Set();
    let updateFieldName = new Set();
    let updateSelectedOptions = new Set();
    const filterRules = filter.data.rules;
    for (const rules of filterRules) {
      for (const rule of rules) {
        updateCostObject.add(rule.costObject);
        updateFieldName.add(rule.fieldName);
        if ([NOT_EQUALS, EQUALS].includes(rule.comparisonOperator)) {
          updateSelectedOptions.add(rule.selectedOptions);
        }
      }
    }
    updateCostObject = Array.from(updateCostObject).flat();
    updateFieldName = Array.from(updateFieldName).flat();
    updateSelectedOptions = Array.from(updateSelectedOptions).flat();
    if (updateCostObject.length && updateFieldName.length) {
      const savedFieldsConfigs = {
        [updateCostObject]: {
          [updateFieldName]: updateSelectedOptions,
        },
      };
      updatedCostFilterConfigs[filterPlatform] = merge(updatedCostFilterConfigs[filterPlatform], savedFieldsConfigs);
    }
  }
  return updatedCostFilterConfigs;
};

export const getPredefinedFiltersForRequest = (predefinedFilters) => {
  const predefinedFiltersForRequest = [];
  for (const filter of predefinedFilters) {
    const allFieldsFormPlatformAreFilled = checkAllFieldsAreFilledForSpecificPlatforms(filter.data);
    if (!filter.removeFormRequest && allFieldsFormPlatformAreFilled) {
      delete filter.removeFormRequest;
      predefinedFiltersForRequest.push(filter);
    }
  }
  return predefinedFiltersForRequest;
};
