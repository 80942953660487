exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".infiniteScrollTable__loadingMore-1v4{display:flex;align-items:center;justify-content:center;position:absolute;bottom:12px;right:24px;z-index:1}.infiniteScrollTable__inputSearch-_2p{width:280px}.infiniteScrollTable__tableWrapper-10G{display:flex;gap:12px;flex-direction:column}.infiniteScrollTable__backTable-1gE{border-radius:12px;background:#fff;box-shadow:0 4px 12px 0 #e6e8f0;position:relative}.infiniteScrollTable__noValues-1YV,.infiniteScrollTable__tableLoading-1Wr{display:flex;align-items:center;justify-content:center;height:440px}.infiniteScrollTable__noValues-1YV{font-size:24px;font-weight:600;text-align:center}", ""]);

// exports
exports.locals = {
	"loadingMore": "infiniteScrollTable__loadingMore-1v4",
	"loadingMore": "infiniteScrollTable__loadingMore-1v4",
	"inputSearch": "infiniteScrollTable__inputSearch-_2p",
	"inputSearch": "infiniteScrollTable__inputSearch-_2p",
	"tableWrapper": "infiniteScrollTable__tableWrapper-10G",
	"tableWrapper": "infiniteScrollTable__tableWrapper-10G",
	"backTable": "infiniteScrollTable__backTable-1gE",
	"backTable": "infiniteScrollTable__backTable-1gE",
	"tableLoading": "infiniteScrollTable__tableLoading-1Wr",
	"tableLoading": "infiniteScrollTable__tableLoading-1Wr",
	"noValues": "infiniteScrollTable__noValues-1YV",
	"noValues": "infiniteScrollTable__noValues-1YV"
};