import { groupBy } from 'lodash';
import { getFiscalYearForMonth } from '../../../../utils/dates';
import moment from 'moment';

export const alternative = 'alternative';

export const cumulative = 'cumulative';

export const alternativeCumulative = 'alternativeCumulative';

export function addNewForecastDataToExplainableForecastData(dataKey, originalForecastData, forecastDataToAdd, fiscalYearFirstMonth, isCumulative) {
  const updateFormattedGraphExplainableForecast = [];

  for (const [index, forecastData] of forecastDataToAdd.entries()) {
    const mappedForecastData = {};
    for (const [forecastKey, forecastValue] of Object.entries(forecastData)) {
      if (forecastKey !== 'timeText' && forecastKey !== 'timestamp') {
        let mappedKey = `${dataKey}_${forecastKey}`;
        if (isCumulative) {
          const todayDate = new Date();
          todayDate.setMonth(fiscalYearFirstMonth - 1);
          const fiscalYearLastMonth = moment(todayDate).format('MMM').toUpperCase();
          const itemDate = new Date(forecastData.timeText);
          const yearKey = getFiscalYearForMonth(itemDate.getMonth(), fiscalYearFirstMonth, itemDate.getYear());
          const forecastDataMonth = moment(new Date(forecastData.timeText)).format('MMM').toUpperCase();
          const isEndOfTheYear = forecastDataMonth === fiscalYearLastMonth && Object.keys(forecastDataToAdd).length !== index + 1;
          if (isEndOfTheYear) {
            const prevYearKey = +yearKey + 1;
            mappedForecastData[`${prevYearKey}_${mappedKey}`] = 0;
          }
          mappedKey = `${yearKey}_${dataKey}_${forecastKey}`;
        }
        mappedForecastData[mappedKey] = forecastValue;
      }
    }
    const updateForecastData = { ...originalForecastData[index], ...mappedForecastData };
    updateFormattedGraphExplainableForecast.push(updateForecastData);
  }
  return updateFormattedGraphExplainableForecast;
}

export function getForecastDataGroupByFiscalYear(forecastData, fiscalYearFirstMonth) {
  const forecastDataByFiscalYear = groupBy(forecastData, (item) => {
    const itemDate = new Date(item.timeText);
    const fiscalY = getFiscalYearForMonth(itemDate.getMonth(), fiscalYearFirstMonth, itemDate.getYear());
    return fiscalY;
  });
  return forecastDataByFiscalYear;
}

export const getPastDatesForForecast = (graphData) => {
  const startDate = graphData[0].timeText;
  const todayDate = new Date();
  const endDate = moment(todayDate).endOf('month').format('D MMM YY').toUpperCase();
  const pastDatesMonths = { startDate: startDate, endDate: endDate };
  return pastDatesMonths;
}