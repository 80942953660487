import React from 'react';

import { formatNumberWithDecimalPoint, formatNumberWithCommas } from 'components/utils/logic/budget';

import attributionStyle from 'styles/analyze/analyze-attribution-table.css';

const style = attributionStyle.locals;

export default function FooterExpendCell({
  total,
  average = 0,
  trendAverage,
  showShareOfTotal = true,
  showTotal = true,
  formatter = (value) => formatNumberWithDecimalPoint(value, 1),
}) {
  const expendValue = total ? formatter(total) : formatter(average);
  const showExpendTotal = showTotal && (total || average);
  const expendTotal = showExpendTotal ? expendValue : '-';

  return (
    <div className={style.expendOpen}>
      <div className={style.expendWidthValues}>
        <div className={style.expendValue}>
          { formatNumberWithCommas({ number: expendTotal }) }
        </div>
        <div className={style.expendCellTagsWrapper}>
          {showShareOfTotal && <div className={style.expendFooterCell}>100%</div>}
          {total ? `${formatter(average)} average` : '-'}
        </div>
      </div>
      <div className={style.expendWidthTrends}>
        {trendAverage && `${formatNumberWithDecimalPoint(trendAverage, 1)}% average` }
      </div>
    </div>
  );
}
