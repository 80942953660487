import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ChannelsSelect from 'components/common/ChannelsSelect';
import userStore from 'stores/userStore';
import dropdownStyle from 'styles/controls/dropdown.css';
import UnmappedPopup from 'components/pages/settings/channels/tabs/UnmappedPopup';
import planStore from 'stores/plan/planStore';

import {
  DIRECT_CHANNEL,
  initialize as initializeChannels,
  getChannelsWithProps,
} from 'components/utils/channels';
import { formatChannelKey } from 'components/utils/utils';
import { getAllMappingOptions } from 'components/utils/mappingRules';
import { unmappedPopupSteps } from 'components/pages/settings/channels/tabs/logic/enums';

const styles = dropdownStyle.locals;

const UnmappedPopupFromJourneys = ({
  mappingPopupObject,
  setMappingPopupObject,
  leadSourcesIdToLabelMap,
  customUtmsWhitelist,
  onFinishSaveMapping,
}) => {
  const [selectedChannel, setSelectedChannel] = useState();
  const [mappingRulesConditions, setMappingRulesConditions] = useState([]);
  const [unmappedPopupStep, setUnmappedPopupStep] = useState(unmappedPopupSteps.selectChannel);
  const [isSaving, setIsSaving] = useState(false);

  const updateSelectedChannel = (channel) => {
    setSelectedChannel(channel);
  };

  useEffect(() => {
    dropdownStyle.use();

    return () => {
      dropdownStyle.unuse();
    };
  }, []);

  useEffect(() => {
    const conditions = [getConditionsRules()];
    setMappingRulesConditions(conditions);
  }, [selectedChannel]);

  const getConditionsRules = () => {
    const conditions = [];
    if (!mappingPopupObject?.touchpointFields) {
      return conditions;
    }
    for (const [fieldKey, fieldValue] of Object.entries(mappingPopupObject.touchpointFields)) {
      conditions.push({
        value: fieldValue,
        param: fieldKey,
        operation: 'equals',
      });
    }
    return conditions;
  };

  const resetSelectedChannel = () => {
    updateSelectedChannel(null);
    setUnmappedPopupStep(unmappedPopupSteps.selectChannel);
    setIsSaving(false);
  };

  const onCancelMappingPopup = () => {
    resetSelectedChannel();
    setMappingPopupObject({ isShow: false });
  };

  const saveMapping = async () => {
    setIsSaving(true);
    const channelsWithProps = getChannelsWithProps() || {};
    const channelWeight = channelsWithProps[selectedChannel]?.weight || 1;
    const newRules = [{
      conditions: mappingRulesConditions[0],
      channel: selectedChannel,
      weight: channelWeight,
      id: uuidv4(),
    }];

    await userStore.updateAttributionMappingRulesRequest({ newRules });

    onCancelMappingPopup();
    onFinishSaveMapping(mappingPopupObject.index);
  };

  if (!mappingPopupObject.isShow) {
    return null;
  }

  function onNewOptionClick({ value: channel }) {
    const channelName = formatChannelKey(channel);
    const category = 'Other';

    const body = {
      channelName,
      channel: {
        category,
      },
    };

    planStore.updateChannelsSchema({ body });

    initializeChannels({
      ...userStore.userChannelsSchema,
      [channelName]: {
        category,
        nickname: channelName,
        isUnknownChannel: true,
        isDeleted: false,
      },
    });

    updateSelectedChannel(channelName);
  }

  return (
    <UnmappedPopup
      hidden={!mappingPopupObject.isShow}
      columns={mappingPopupObject.columns || []}
      unmappedType="CHANNELS"
      mappingRulesConditions={mappingRulesConditions}
      setMappingRulesConditions={setMappingRulesConditions}
      unmappedPopupStep={unmappedPopupStep}
      setUnmappedPopupStep={setUnmappedPopupStep}
      resetSelectedChannel={resetSelectedChannel}
      onCancel={onCancelMappingPopup}
      selectedRowsToMap={[{
        rowIndex: 0,
        rowData: mappingPopupObject.touchpointFields,
      }]}
      selectedChannelForMapping={selectedChannel}
      paramsOptions={getAllMappingOptions({ leadSourcesIdToLabelMap, customUtmsWhitelist })}
      endpoint="getUnmappedData"
      isSaving={isSaving}
      saveMapping={saveMapping}
    >
      <div className={styles.channelsSelectSize}>
        <ChannelsSelect
          selected={selectedChannel}
          withOtherChannels
          onChange={(e) => updateSelectedChannel(e?.value || '')}
          onNewOptionClick={({ value }) => onNewOptionClick({ value })}
          isChannelDisabled={(channel) => channel === DIRECT_CHANNEL}
        />
      </div>
    </UnmappedPopup>
  );
};

export default UnmappedPopupFromJourneys;
