import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Switch from 'components/controls/Switch';
import Textfield from 'components/controls/Textfield';
import InfoMarker from 'components/pages/InfoMarker';
import Loading3Dots from 'components/controls/Loading3Dots';

import { getIsValidReportUrl } from 'components/pages/createIntegration/logic/CRMReportSyncSection';
import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';

import integrationStyle from 'styles/integrations/integration.css';

import { invalidReportUrlErrorMessage } from './logic/enums';

const styles = integrationStyle.locals || {};

function SyncIndicator({
  hasError,
  isSyncing,
  reportUrl,
  sendReportLink,
  reportSyncStatus,
  existingReportUrl,
  isReportSyncActive,
}) {
  if (!isReportSyncActive) {
    return null;
  }

  if (isSyncing) {
    return (
      <Loading3Dots />
    );
  }

  const isLinkChanged = reportUrl !== existingReportUrl;

  if (isLinkChanged || !reportSyncStatus?.isSynced) {
    return (
      <Button
        type="primaryBlue"
        onClick={() => sendReportLink()}
        disabled={hasError || !reportUrl}
      >
        Sync
      </Button>
    );
  }

  return (
    <div className={styles.reportSyncIconGreen} data-icon="platform:reportSyncSuccess" />
  );
}

function CRMReportSyncSection({
  isSyncing,
  sendReportLink,
  reportSyncStatus,
  selectedFunnelId,
  existingReportUrl,
  onUpdateReportUrl,
  isReportSyncActive,
  setIsReportSyncActive,
}) {
  useStyles([integrationStyle]);

  const {
    funnelMapping,
  } = useCRMCContext();
  const currentFunnelMapping = funnelMapping[selectedFunnelId];

  const { crmReport } = currentFunnelMapping || {};
  const { reportUrl = '' } = crmReport || {};

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const isValidReportUrl = !reportUrl || getIsValidReportUrl({ reportUrl });
    setErrorMessage(isValidReportUrl ? '' : invalidReportUrlErrorMessage);
  }, [
    reportUrl,
    isReportSyncActive,
  ]);

  return (
    <>
      <div className={styles.configFunnelRightTitle}>
        Report Sync
        <Switch
          isActive={isReportSyncActive}
          onSwitchClick={() => setIsReportSyncActive()}
          disabled={isSyncing}
          dataTestId="switch"
        />
      </div>

      <div className={styles.whiteBox}>
        <div className={classNames(styles.flexRow, styles.reportSyncLinkContainer)}>
          <div className={classNames(styles.fieldText, styles.reportSyncLinkTitle)}>
            Link to report
            <InfoMarker
              tooltipText={'InfiniGrow will automatically match this report\'s configuration.'}
              TooltipProps={{ place: 'bottom' }}
              containerClass={styles.reportSyncTooltip}
            />
          </div>

          <Textfield
            className={styles.reportUrlInputField}
            value={reportUrl}
            onChange={(e) => {
              onUpdateReportUrl({ reportUrl: e.target.value });
            }}
            isShowError={!!errorMessage}
            inputErrorMessage={errorMessage}
            disabled={isSyncing}
          />

          <SyncIndicator
            hasError={errorMessage}
            isSyncing={isSyncing}
            reportUrl={reportUrl}
            sendReportLink={sendReportLink}
            reportSyncStatus={reportSyncStatus}
            existingReportUrl={existingReportUrl}
            isReportSyncActive={isReportSyncActive}
          />
        </div>
      </div>
    </>
  );
}

export default CRMReportSyncSection;
