import React from 'react';
import classnames from 'classnames';
import useStyles from 'hooks/useStyles';

import Skeleton from 'components/common/Skeleton';
import Tooltip from 'components/controls/Tooltip';

import style from 'styles/analyze/scenario-box.css';

const styles = style.locals || {};

export default function ScenarioUplift({
  value,
  isDesired = true,
  isLoaded,
  tooltip,
}) {
  useStyles([style]);

  if (!isLoaded) {
    return (
      <Skeleton
        width={352}
        height={40}
        borderRadius={20}
      />
    );
  }

  return (
    <Tooltip
      tip={tooltip}
      hidden={!tooltip}
      id={`scenario-uplift-${tooltip}-${value}`}
    >
      <div className={classnames(styles.uplift, isDesired && styles.upliftIsDesired)}>
        <div className={styles.upliftIcon} />
        {`${value}% Uplift${isDesired ? '!' : ''}`}
      </div>
    </Tooltip>
  );
}
