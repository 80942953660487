import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import servicesStore from 'stores/servicesStore';

import { reorderItemToTopOfList } from 'components/pages/settings/actions/actionPopup/notificationSection/logic/email';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function Email({
  emailData,
  updateEmailData,
  teamMembers = [],
}) {
  const userProfile = servicesStore.authService.getProfileSync();

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  function getNameAccordingEmail({ email }) {
    const member = teamMembers.find((teamMember) => teamMember.email === email);
    return `${member.firstName} ${member.lastName}`;
  }

  const membersEmails = teamMembers.map((member) => ({
    value: member.email,
    label: member.email,
  }));

  const updatedMembersOptions = reorderItemToTopOfList({
    items: membersEmails,
    targetItem: userProfile.email,
    keySelector: (item) => item.value,
  });

  return (
    <>
      <div className={styles.row}>
        <div className={classnames(styles.text, styles.titleMinWidth)}>
          Recipient(s)
        </div>
        <MultiCheckSelect
          selected={emailData.recipients?.map((recipient) => ({ label: recipient.email, value: recipient.email }))}
          options={updatedMembersOptions}
          onChange={(newEmails) => updateEmailData({
            actionKey: 'recipients',
            actionValue: newEmails.map((newEmail) => ({
              email: newEmail.value,
              name: getNameAccordingEmail({ email: newEmail.value }),
            })),
          })}
          selectAll
          isDropDownSelect
          isSearchable
          isClearable={false}
          isSearchIconPlaceholder={false}
          controlWidth={280}
          className={styles.text}
          isDropdownIndicator
        />
      </div>
    </>
  );
}

export default inject(({
  userStore: {
    userAccount: {
      teamMembers,
    } = {},
  } = {},
}) => ({
  teamMembers,
}),
observer)(Email);
