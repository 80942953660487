exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".goal__inner-2HR{padding:15px 25px;display:inline-block;position:relative}.goal__bottom-2Dv{display:flex;flex-direction:column;justify-content:flex-end;margin-top:-80px}.goal__center-1ha{display:flex;justify-content:center;align-items:center}.goal__title-3p2{font-size:14px;font-weight:600;letter-spacing:.1px;fill:#99a4c2;height:12px;text-align:center;color:#99a4c2;max-width:150px;margin-top:-235px}.goal__current-3zE{font-size:24px;font-weight:600;fill:#222a41;letter-spacing:.1px}.goal__target-ns2{font-size:11px;font-weight:600;letter-spacing:.1px;fill:#b2bbd5}.goal__time-left-2Eb{font-size:12px;font-weight:400;letter-spacing:.1px;color:#99a4c2}.goal__time-left--bold-WFC{font-weight:600}.goal__circle-background-yBy{fill:none;stroke:#e6e8f0}.goal__circle-progress-1Op{fill:none}.goal__progress-f30{width:200px;height:6px;border-radius:4px;background-color:#e6e8f0;margin:8px auto 0}.goal__progress-fill-3Da{width:136px;height:6px;border-radius:4px}.goal__description-row-2x4{margin-top:7px;display:flex;width:200px;flex-direction:row;justify-content:space-between;align-items:center}.goal__wrapper-2Cv{display:flex;flex-direction:column;align-items:center}.goal__milestone-flag-3aJ{background:#e6e7ee url(/assets/objectives-icons/milestone-flag.svg) no-repeat 50%;border-radius:2px;width:17px;height:18px;margin-bottom:-35px}.goal__milestone-flag-wrapper-35X{display:flex;justify-content:center;align-items:center;margin-top:-10px;padding-bottom:10px}", ""]);

// exports
exports.locals = {
	"inner": "goal__inner-2HR",
	"inner": "goal__inner-2HR",
	"bottom": "goal__bottom-2Dv",
	"bottom": "goal__bottom-2Dv",
	"center": "goal__center-1ha",
	"center": "goal__center-1ha",
	"title": "goal__title-3p2",
	"title": "goal__title-3p2",
	"current": "goal__current-3zE",
	"current": "goal__current-3zE",
	"target": "goal__target-ns2",
	"target": "goal__target-ns2",
	"time-left": "goal__time-left-2Eb",
	"timeLeft": "goal__time-left-2Eb",
	"time-left--bold": "goal__time-left--bold-WFC",
	"timeLeftBold": "goal__time-left--bold-WFC",
	"circle-background": "goal__circle-background-yBy",
	"circleBackground": "goal__circle-background-yBy",
	"circle-progress": "goal__circle-progress-1Op",
	"circleProgress": "goal__circle-progress-1Op",
	"progress": "goal__progress-f30",
	"progress": "goal__progress-f30",
	"progress-fill": "goal__progress-fill-3Da",
	"progressFill": "goal__progress-fill-3Da",
	"description-row": "goal__description-row-2x4",
	"descriptionRow": "goal__description-row-2x4",
	"wrapper": "goal__wrapper-2Cv",
	"wrapper": "goal__wrapper-2Cv",
	"milestone-flag": "goal__milestone-flag-3aJ",
	"milestoneFlag": "goal__milestone-flag-3aJ",
	"milestone-flag-wrapper": "goal__milestone-flag-wrapper-35X",
	"milestoneFlagWrapper": "goal__milestone-flag-wrapper-35X"
};