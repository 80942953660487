import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/setup-budget-detail-wrap.css';
import toggleStyle from 'styles/budget/budget-toggle.css';
import Select from 'components/controls/Select';
import Toggle from 'components/controls/Toggle';
import classnames from 'classnames';
import { formatBudget } from 'components/utils/budget';
import BudgetBreakdown from 'components/pages/budget/BudgetBreakdown';
import Tooltip from 'components/controls/Tooltip';
import DataStructure from 'components/pages/budget/DataStructure';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';
import InsightsForecasting from 'components/pages/budget/InsightsForecasting';

export default class SetupBudgetDetailWrap extends Component {
    style = style;

    styles = [toggleStyle]

    constructor() {
      super();
      this.state = {
        comparisonSelectLabel: { value: '+', label: '+' },
        currencySelectLabel: { value: '$', label: '$' },
        frequencySelectLabel: { value: 'Annually', label: 'Annually' },
        mOrQSelectLabel: { value: 'MoM', label: 'MoM' },
        percentSelectLabel: { value: '10%' },
        budgetAllocation: 'Equal',
      };
    }

  selectComparisonValue = (val) => {
    this.setState({
      comparisonSelectLabel: val,
    });
  };

  selectCurrencyValue = (val) => {
    this.setState({
      currencySelectLabel: val,
    });
  };

  selectFrequencyValue = (val) => {
    this.setState({
      frequencySelectLabel: val,
    });
  };

  selectMorQValue = (val) => {
    this.setState({
      mOrQSelectLabel: val,
    });
  };

  selectPercentValue = (val) => {
    this.setState({
      percentSelectLabel: val,
    });
  };

  render() {
    const {
      value,
      total,
    } = this.props;

    const frequencyOptions = [
      { value: 'Annually', label: 'Annually' },
      { value: 'Monthly', label: 'Monthly' },
      { value: 'Quarterly', label: 'Quarterly' },
    ];

    const currencyOptions = [
      { value: '$', label: '$' },
      { value: '%', label: '%' },
    ];

    const comparisonOptions = [
      { value: '+', label: '+' },
      { value: '–', label: '–' },
    ];

    const mOrQOptions = [
      { value: 'MoM', label: 'MoM' },
      { value: 'QoQ', label: 'QoQ' },
    ];

    const customStyles = {
      background: 'linear-gradient(360deg, #FAFAFC 0%, #FFFFFF 100%)',
      border: '1px solid rgba(178, 187, 213, 0.5)',
      height: 40,
    };

    const customCurrencyStyles = {
      background: 'transparent',
      border: '1px solid rgba(178, 187, 213, 0.5)',
      height: 40,
    };

    const defaultVal = formatBudget(12000, false);

    const allocationTip = { tip: 'Decide how to distribute your yearly budget across months: <span style="font-weight: bold">Equally</span> (equal monthly budget), <span style="font-weight: bold">Manually</span> (manually set a monthly budget), or by <span style="font-weight: bold">Growth Rate</span> (increase monthly budget by a set percentage)' };

    return (
      <div className={this.classes.content}>
        <div className={this.classes.borderWrap}>
          <div className={this.classes.header}>
            <div className={this.classes.headerItem}>
              <div className={this.classes.titleWrap}>
                <h3 className={this.classes.title}>
                  Budget Frequency
                </h3>
                <Tooltip
                  tip="You can set a yearly, quarterly, or monthly budget for this channel"
                  id="budgetFrequency"
                  place="top"
                  TooltipProps={{ sm: true }}
                >
                  <div className={this.classes.info} />
                </Tooltip>
              </div>
              <div className={this.classes.selectWrap}>
                <Select
                  select={{ options: frequencyOptions }}
                  selected={this.state.frequencySelectLabel}
                  customStyle={customStyles}
                  onChange={(e) => this.selectFrequencyValue(e.value)}
                  innerClassName={classnames(this.classes.annuallySelect, this.classes.budgetSelect)}
                />
              </div>
            </div>
            <div className={this.classes.headerItem}>
              <div className={this.classes.titleWrap}>
                <h3 className={this.classes.title}>
                  Baseline [Annual] Budget
                </h3>
                <Tooltip
                  tip="Set an annual budget for this channel - you'll be able to break it down to months in the following step."
                  id="baselineBudget"
                  place="top"
                  TooltipProps={{ sm: true }}
                >
                  <div className={this.classes.info} />
                </Tooltip>
              </div>
              <div className={this.classes.selectWrap}>
                <div className={this.classes.selectContainer}>
                  <Select
                    select={{ options: currencyOptions }}
                    selected={this.state.currencySelectLabel}
                    customStyle={customCurrencyStyles}
                    onChange={(e) => this.selectCurrencyValue(e.value)}
                    innerClassName={classnames(this.classes.budgetSelect, this.classes.currencySelect)}
                  />
                  <input
                    type="text"
                    defaultValue={defaultVal}
                    onChange={(e) => {
                      e.target.value = e.target.value ? formatBudget(Number(e.target.value.replace(/\D+/ig, '')), false) : e.target.value;
                      return e;
                    }}
                    className={classnames(this.state.budgetAllocation === 'Manual' ? this.classes.inputManual : this.classes.input)}
                    maxLength={20}
                  />
                </div>
                <p className={this.classes.selectWrapText}>
                  Equal to
                  {' '}
                  {' '}
                  {value ? (
                    <span>
                      {formatBudget((value / total * 100), false)}
                      %
                    </span>
                  ) : (
                    <span>1,2%</span>
                  )}
                  {' '}
                  of the Total Annual Budget
                </p>
              </div>
            </div>
            <div className={this.classes.headerItem}>
              <div className={this.classes.titleWrap}>
                <h3 className={this.classes.title}>
                  Budget Allocation
                </h3>
                <Tooltip
                  tip={allocationTip.tip}
                  id="budgetAllocation"
                  place="top"
                  TooltipProps={{ sm: true }}
                >
                  <div className={this.classes.info} />
                </Tooltip>
              </div>

              <Toggle
                boxClass={toggleStyle.locals.box}
                frameClass={toggleStyle.locals.frame}
                options={[{
                  text: 'Equal',
                  value: 'Equal',
                  style: this.state.budgetAllocation === 'Growth Rate' ? this.classes.toggleLeft : '',
                },
                {
                  text: 'Manual',
                  value: 'Manual',
                },
                {
                  text: 'Growth Rate',
                  value: 'Growth Rate',
                  style: this.state.budgetAllocation === 'Equal' ? this.classes.toggleRight : '',
                },
                ]}
                selectedValue={this.state.budgetAllocation || 'Equal'}
                onClick={(tab) => {
                  this.setState({ budgetAllocation: tab });
                }}
              />
            </div>

            {this.state.budgetAllocation === 'Growth Rate' && (
            <div className={this.classes.headerItem}>
              <div className={this.classes.titleWrap}>
                <h3 className={this.classes.title}>
                  Growth Rate
                </h3>
                <Tooltip
                  tip="Increase monthly budget by a set percentage."
                  id="growthRate"
                  place="top"
                  TooltipProps={{ sm: true }}
                >
                  <div className={this.classes.info} />
                </Tooltip>
              </div>
              <div className={this.classes.selectWrap}>
                <div className={this.classes.selectContainer}>
                  <Select
                    select={{ options: comparisonOptions }}
                    selected={this.state.comparisonSelectLabel}
                    customStyle={customCurrencyStyles}
                    onChange={(e) => this.selectComparisonValue(e.value)}
                    innerClassName={this.classes.comparisonSelect}
                  />
                  <input
                    type="text"
                    value="10%"
                    className={classnames(this.classes.input, this.classes.growthInput)}
                    maxLength={20}
                  />
                  <Select
                    select={{ options: mOrQOptions }}
                    selected={this.state.mOrQSelectLabel}
                    customStyle={customCurrencyStyles}
                    onChange={(e) => this.selectMorQValue(e.value)}
                    innerClassName={this.classes.mOrQSelect}
                  />
                </div>
                <div className={this.classes.rateBtnsGroup}>
                  <SetupBudgetButton
                    name="5%"
                    stylesBtn={this.classes.percentButton}
                    stylesWrap={this.classes.percentButtonWrap}
                    onClick={() => this.selectPercentValue()}
                  />
                  <SetupBudgetButton
                    id="focused"
                    name="10%"
                    stylesBtn={this.classes.percentButton}
                    stylesWrap={this.classes.percentButtonWrap}
                    onClick={() => this.selectPercentValue()}
                  />
                  <SetupBudgetButton
                    name="15%"
                    stylesBtn={this.classes.percentButton}
                    stylesWrap={this.classes.percentButtonWrap}
                    onClick={() => this.selectPercentValue()}
                  />
                  <SetupBudgetButton
                    name="20%"
                    stylesBtn={this.classes.percentButton}
                    stylesWrap={this.classes.percentButtonWrap}
                    onClick={() => this.selectPercentValue()}
                  />
                </div>
              </div>
            </div>
            )}

          </div>
          <BudgetBreakdown
            budgetAllocation={this.state.budgetAllocation}
          />
          <InsightsForecasting />
          <div className={this.classes.border} />
          <DataStructure />
        </div>
      </div>
    );
  }
}
