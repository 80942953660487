exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "body:has(.Popup-module__wrapper___3Vxw5){margin:0;height:100%;overflow:hidden}.Popup-module__wrapper___3Vxw5{width:100%;height:100%;display:flex;align-items:center;justify-content:center;position:fixed;top:0;left:0;z-index:1000;background-color:rgba(24,32,51,.6)}.Popup-module__withoutOverlay___UWFeK{background-color:transparent}.Popup-module__content___Pw6wW{background-color:var(--white);border-radius:4px;padding:40px;position:relative;margin-bottom:40px;box-sizing:border-box}.Popup-module__centered___3ECgJ{text-align:center}.Popup-module__close___29JSg{position:absolute;top:16px;right:16px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"wrapper": "Popup-module__wrapper___3Vxw5",
	"withoutOverlay": "Popup-module__withoutOverlay___UWFeK",
	"content": "Popup-module__content___Pw6wW",
	"centered": "Popup-module__centered___3ECgJ",
	"close": "Popup-module__close___29JSg"
};