import { metricsTooltipsTypes, questionsTypesLabels } from 'components/widgets/optimalJourney/enums';
import { formatBudget } from 'components/utils/budget';
import { convertDaysToWeeks } from 'stores/analyze/logic/timeUtils';

export function getInitializeMockData() {
  return {
    overview: {
      numberOfJourneys: 1100,
      numberOfClosedLost: 8300,
      numberOfTouchpoints: 47,
      numberOfMarketingTouchpoints: 32,
      numberOfSalesTouchpoints: 15,
      avgNumberOfTouchpoints: 12,
      numberOfContacts: 4,
      avgNumberOfContacts: 2,
      numberOfSalesCycle: 196, // days
      avgSalesCycle: 273, // days
      numberOfDealSize: 75000,
      avgDealSize: 89000,
    },
    journeyCanvas: {
      first: {},
      funnel1: {
        velocity: 90,
        segments: {
          channel: {
            fields: ['advertising_searchMarketing_SEO'],
            value: 7,
            additionalData: {
              amountFromConversionIndicator: 30,
              conversionRate: 15,
              velocityCompareOthers: 25,
            },
          },
          jobTitle: {
            fields: ['Financial Analyst'],
            value: 5,
            additionalData: {
              amountFromConversionIndicator: 30,
              conversionRate: 15,
              velocityCompareOthers: 25,
            },
          },
        },
      },
      funnel2: {
        velocity: 1,
        segments: {
          channel: {
            fields: ['advertising_searchMarketing_SEM_googleAdwords'],
            value: 13,
            additionalData: {},
          },
          jobTitle: {
            fields: ['Financial Analyst'],
            value: 16,
            additionalData: {},
          },
        },
      },
      funnel3: {
        velocity: 60,
        segments: {
          channel: {
            fields: ['events_offlineEvents_showcase'],
            value: 27,
            additionalData: {},
          },
          jobTitle: {
            fields: ['CFO'],
            value: 26,
            additionalData: {},
          },
        },
      },
      funnel4: {
        velocity: 30,
        segments: {
          channel: {
            fields: ['events_offlineEvents_showcase'],
            value: 31,
            additionalData: {},
          },
          jobTitle: {
            fields: ['CFO'],
            value: 36,
            additionalData: {},
          },
        },
      },
      funnel5: {
        velocity: 30,
        segments: {
          channel: {
            fields: ['Sales'],
            value: 48,
            additionalData: {},
          },
          jobTitle: {
            fields: ['CFO'],
            value: 41,
            additionalData: {},
          },
        },
      },
    },
    insights: {
      channel: (
        <>
          <b>Events</b>
          {' '}
          is the top converting channel when touched between
          {' '}
          <b>MQL</b>
          {' '}
          to
          {' '}
          <b>Opp.</b>
        </>
      ),
      jobTitle: (
        <>
          <b>Financial Analyst</b>
          {' '}
          is more influential at the start of the journey, but when it comes to mid-to-bottom of the funnel,
          <b>CFO</b>
          {' '}
          is more influential.
        </>
      ),
      velocity: (
        <>
          Leads convert to MQLs quickly (
          <b>1 day</b>
          ), while the longer part of the journey (
          <b>3 Months</b>
          ) is from 1st to MQL.
        </>
      ),
    },
  };
}

export function getMetricTooltipMockData({ type, metrics = {} }) {
  if (type === metricsTooltipsTypes.journeys) {
    return (`
      <div>
        <b>${formatBudget(metrics.numberOfJourneys, false, true)}</b>
        <b>Closed Won</b>
        journeys were analyzed based on the sample timeframe, 
        compared to <b>8.3K Closed Lost</b> journeys
      </div>
    `);
  }

  if (type === metricsTooltipsTypes.touchpoints) {
    return (`
      <div>
        On average, it takes <b>${metrics.numberOfTouchpoints} touchpoints</b> to convert from first touch to 
        <b>Closed Won</b>
        (<b>3.9x</b> more than Closed Lost journeys).
        <ul>
            <li><b>${metrics.numberOfMarketingTouchpoints} marketing</b> touchpoints</li>
            <li><b>${metrics.numberOfTouchpoints - metrics.numberOfMarketingTouchpoints} sales</b> touchpoints</li>
        </ul>
      </div>
    `);
  }

  if (type === metricsTooltipsTypes.contacts) {
    return (`
      <div>
        On average, <b>${metrics.numberOfContacts} contacts</b> are involved in a conversion from first touch to <b>Closed Won</b>.
        <br />
        Closed Lost journeys had only <b>${metrics.avgNumberOfContacts} contacts</b> involved.
      </div>
    `);
  }

  if (type === metricsTooltipsTypes.avgSalesCycle) {
    return (`
      <div>
        On average, it takes <b>${convertDaysToWeeks({ days: metrics.avgSalesCycle, isShortLabel: false })}</b> to convert from first touch to 
        <b>Closed Won</b>.
        Closed Lost journeys are <b>40% longer</b>.
      </div>
    `);
  }

  if (type === metricsTooltipsTypes.avgDealSize) {
    return (`
      <div>
        The average deal size of a Closed Won deal is 
        <b>${formatBudget(metrics.numberOfDealSize, true, true)}</b>.
        Closed Lost deals have a <b>15% lower</b> deal size.
      </div>
    `);
  }

  return '';
}

export function getJourneyQuestionTooltipMockData({ question }) {
  return (`
      <div>
        You’ve defined optimal as a <b>${questionsTypesLabels[question.kind]}</b> to <b>Closed Won</b>. 
        As a result, InfiniGrow shows you:
        <ol>
            <li>Stats behind the accounts that converted to <b>Closed Won</b> over the <b>last 12 months</b>.</li>
            <li>Which segments and touchpoints have the <b>highest Closed Won ${questionsTypesLabels[question.kind]}</b> in each part of the journey.</li>
        </ol>
      </div>
    `);
}
