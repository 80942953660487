import React, { useEffect, useState } from 'react';
import ChannelIcon from 'components/common/ChannelIcon';
import Tooltip from 'components/controls/Tooltip';
import { getCategoryIcon } from 'components/utils/filters/channels';
import { hexToRgb } from 'components/utils/colors';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';

import style from 'styles/analyze/conversion-journey.css';

const styles = style.locals;

export default function JourneyWithNavigationMenu({
  item, index, isCategoryView, getNavigationMenuParams,
}) {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);

  const { color, nickname } = item;

  return (
    <div
      key={index}
      className={styles.journeyItem}
      style={{
        backgroundColor: `rgba(${hexToRgb(item.color).join(',')}, 0.2)`,
      }}
      onClick={() => setShowNavigationMenuPopup(true)}
    >
      {isCategoryView ? (
        <div
          className={styles.journeyItemIcon}
          data-icon={getCategoryIcon(nickname)}
        />
      ) : (
        <ChannelIcon
          className={styles.journeyItemIcon}
          channelIcon={item.icon}
        />
      )}
      <div className={styles.journeyItemLabelContainer}>
        {item.uniqTitle ? (
          <div className={styles.journeyItemLabel} style={{ color }}>
            {item.uniqTitle}
          </div>
        ) : (
          <Tooltip
            id={`item-tooltip-${index}`}
            className={styles.journeyItemLabel}
            tip={nickname}
            style={{ color }}
          >
            {nickname}
          </Tooltip>
        )}
      </div>

      {/*{showNavigationMenuPopup && (*/}
      {/*  <div className={styles.navigationMenuPopup}>*/}
      {/*    <NavigationMenuPopup*/}
      {/*      navigationOptions={getNavigationMenuParams}*/}
      {/*      onClosePopup={() => setShowNavigationMenuPopup(false)}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
}
