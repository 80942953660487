import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/goal-forecast.css';
import Chart from 'components/pages/budget/Chart';

export default class GoalForecast extends Component {
    style = style;

    constructor() {
      super();
      this.state = {
        isOpen: false,
      };
    }

    onOpenClick = () => {
      this.setState({
        isOpen: true,
      });
    }

    onCloseClick = () => {
      this.setState({
        isOpen: false,
      });
    }

    render() {
      return (
        <div className={this.classes.content}>
          <div className={this.classes.borderWrap}>
            <h2 className={this.classes.title}>
              Goal Forecast
            </h2>
            <div className={this.classes.chart} onClick={() => this.onOpenClick()}>
              <div className={this.classes.chartBg}>
                <div className={this.classes.arrowsBg} />
              </div>
            </div>
            <div className={this.classes.valuesWrap}>
              <div className={this.classes.items}>
                <div className={this.classes.iconSolid} />
                <div className={this.classes.value}>
                  Plan forecast
                </div>
              </div>
              <div className={this.classes.items}>
                <div className={this.classes.iconDotted} />
                <div className={this.classes.value}>
                  EST. marginal impact
                </div>
              </div>
              <div className={this.classes.items}>
                <div className={this.classes.iconGoal} />
                <div className={this.classes.value}>
                  Goal
                </div>
              </div>
            </div>
          </div>
          {this.state.isOpen
            && (
            <Chart
              onCloseClick={() => this.onCloseClick()}
            />
            )}
        </div>
      );
    }
}
