exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".budget-toggle__inner-3TI{display:flex;align-items:flex-end;justify-content:center}.budget-toggle__box-3DS{border-radius:4px;border:1px solid rgba(178,187,213,.32);display:inline-flex;cursor:pointer;-webkit-user-select:none;user-select:none;background-color:#f5f6fa}.budget-toggle__frame-1Ai{min-width:100px;height:38px;background-color:#f5f6fa;line-height:38px;padding:0 6.5px;box-sizing:border-box;box-shadow:none}.budget-toggle__frame-1Ai:hover{box-shadow:0 6px 6px 0 rgba(54,56,64,.04)}.budget-toggle__frame-1Ai[data-active]{background-color:#fff;border-radius:4px;border:1px solid rgba(178,187,213,.32);z-index:1;height:40px;margin-top:-1px;margin-bottom:-1px}.budget-toggle__frame-text-Zr9{font-size:12px;font-weight:500;letter-spacing:.1px;color:#b2bbd5;text-align:center}.budget-toggle__frame-1Ai:hover .budget-toggle__frame-text-Zr9{color:#99a4c2}.budget-toggle__frame-1Ai[data-active] .budget-toggle__frame-text-Zr9{color:#182033;font-weight:500}.budget-toggle__frame-1Ai:first-child{border-radius:4px 0 0 4px}.budget-toggle__frame-1Ai[data-active]:first-child{border-radius:4px;border-left:0}.budget-toggle__frame-1Ai:last-child{border-radius:0 4px 4px 0}.budget-toggle__frame-1Ai[data-active]:last-child{border-radius:4px;border-right:0}.budget-toggle__frame-bordered-1u1{border-radius:4px!important}.budget-toggle__frame-1Ai:last-child{margin-left:-4px!important}", ""]);

// exports
exports.locals = {
	"inner": "budget-toggle__inner-3TI",
	"inner": "budget-toggle__inner-3TI",
	"box": "budget-toggle__box-3DS",
	"box": "budget-toggle__box-3DS",
	"frame": "budget-toggle__frame-1Ai",
	"frame": "budget-toggle__frame-1Ai",
	"frame-text": "budget-toggle__frame-text-Zr9",
	"frameText": "budget-toggle__frame-text-Zr9",
	"frame-bordered": "budget-toggle__frame-bordered-1u1",
	"frameBordered": "budget-toggle__frame-bordered-1u1"
};