exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".select-timeframe-with-custom__customMenuButton-KqQ{display:flex;align-items:center;gap:6px;cursor:pointer;text-decoration:underline}.select-timeframe-with-custom__customMenuButton-KqQ:hover{text-decoration:none}.select-timeframe-with-custom__dropdownIndicator-34W{display:inline-block;border-top:5px solid #222a41;border-left:4px solid transparent;border-right:4px solid transparent}.select-timeframe-with-custom__dropdownIndicator-34W.select-timeframe-with-custom__isPopupOpen-2kp{transform:rotate(180deg)}", ""]);

// exports
exports.locals = {
	"customMenuButton": "select-timeframe-with-custom__customMenuButton-KqQ",
	"customMenuButton": "select-timeframe-with-custom__customMenuButton-KqQ",
	"dropdownIndicator": "select-timeframe-with-custom__dropdownIndicator-34W",
	"dropdownIndicator": "select-timeframe-with-custom__dropdownIndicator-34W",
	"isPopupOpen": "select-timeframe-with-custom__isPopupOpen-2kp",
	"isPopupOpen": "select-timeframe-with-custom__isPopupOpen-2kp"
};