exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".capmaign-popup__content-1gL{padding:15px 30px;overflow:auto;min-height:500px}.capmaign-popup__top-right-1pA{display:flex;justify-content:flex-end;margin-top:1px}.capmaign-popup__close-3pA{height:25px;width:25px;background:url(/assets/x.svg) 50% no-repeat;cursor:pointer;min-width:25px;min-height:25px;margin-left:20px}.capmaign-popup__title-ycu{display:inline-block!important;margin-bottom:0!important;white-space:normal!important}.capmaign-popup__text-area-1PZ{width:100%;height:72px;resize:none;border:none;box-shadow:0 0 0 1px #ced0da;background:#fff;padding:5px 14px;font-size:16px;font-weight:600;color:#354052;border-radius:4px;box-sizing:border-box;font-family:inherit}.capmaign-popup__sub-head-3pg{color:#273142;font-size:16px;line-height:14px;font-weight:800;text-transform:uppercase;height:20px;display:flex;align-items:center;margin-bottom:12px;letter-spacing:.05em;white-space:nowrap}.capmaign-popup__inner-1GK{margin-top:45px;padding:0 10px}.capmaign-popup__assets-box-2Oc{width:100%;height:72px;border-radius:5px;box-shadow:0 0 0 1px #ced0da;margin-bottom:32px;box-sizing:border-box;padding:5px 14px;overflow:auto}.capmaign-popup__assets-category-1e2{font-size:16px;font-weight:600;line-height:.75;color:#979797;margin-bottom:2px}.capmaign-popup__category-assets-3CT{margin-bottom:12px}.capmaign-popup__assets-link-2yZ{font-size:12px;margin-left:10px}.capmaign-popup__assets-link-2yZ:first-child{margin-left:0}.capmaign-popup__assets-icon-3Vp{background:url(/assets/link.svg) 50% no-repeat;background-size:contain;height:25px;width:25px;transform:rotate(35deg);margin-left:15px;cursor:pointer}.capmaign-popup__advanced-18l{font-weight:700;cursor:pointer;font-size:16px;color:#1165a3;text-decoration:underline}.capmaign-popup__head-tabs-12M{display:flex;justify-content:center;background-color:#fff;box-shadow:0 2px 0 0 hsla(0,0%,91%,.5);height:85px}", ""]);

// exports
exports.locals = {
	"content": "capmaign-popup__content-1gL",
	"content": "capmaign-popup__content-1gL",
	"top-right": "capmaign-popup__top-right-1pA",
	"topRight": "capmaign-popup__top-right-1pA",
	"close": "capmaign-popup__close-3pA",
	"close": "capmaign-popup__close-3pA",
	"title": "capmaign-popup__title-ycu",
	"title": "capmaign-popup__title-ycu",
	"text-area": "capmaign-popup__text-area-1PZ",
	"textArea": "capmaign-popup__text-area-1PZ",
	"sub-head": "capmaign-popup__sub-head-3pg",
	"subHead": "capmaign-popup__sub-head-3pg",
	"inner": "capmaign-popup__inner-1GK",
	"inner": "capmaign-popup__inner-1GK",
	"assets-box": "capmaign-popup__assets-box-2Oc",
	"assetsBox": "capmaign-popup__assets-box-2Oc",
	"assets-category": "capmaign-popup__assets-category-1e2",
	"assetsCategory": "capmaign-popup__assets-category-1e2",
	"category-assets": "capmaign-popup__category-assets-3CT",
	"categoryAssets": "capmaign-popup__category-assets-3CT",
	"assets-link": "capmaign-popup__assets-link-2yZ",
	"assetsLink": "capmaign-popup__assets-link-2yZ",
	"assets-icon": "capmaign-popup__assets-icon-3Vp",
	"assetsIcon": "capmaign-popup__assets-icon-3Vp",
	"advanced": "capmaign-popup__advanced-18l",
	"advanced": "capmaign-popup__advanced-18l",
	"head-tabs": "capmaign-popup__head-tabs-12M",
	"headTabs": "capmaign-popup__head-tabs-12M"
};