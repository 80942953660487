import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import Textfield from 'components/controls/Textfield';
import InfoMarker from 'components/pages/InfoMarker';

import style from 'styles/controls/editable-label.css';

const styles = style.locals || {};

export default function EditableLabel({
  value,
  defaultValue,
  onChange,
  tooltip,
  inputClassName,
  type,
  rightLabel,
  leftLabel,
  isShowLabelOnEdit = false,
  disabledEditMode,
  formatValue,
  onClose,
  dataTestId,
  gap,
  disableClicks,
}) {
  useStyles([style]);

  const [isEditMode, setIsEditMode] = useState(false);

  const editableLabelRef = useRef();

  useOnClickOutside(editableLabelRef, () => {
    setIsEditMode(false);
    if (onClose) {
      onClose();
    }
  });

  if (isEditMode) {
    return (
      <div ref={editableLabelRef} className={styles.wrapper} data-testid={dataTestId}>
        {isShowLabelOnEdit ? (
          <span>{leftLabel}</span>
        ) : null}
        <Textfield
          value={value}
          onChange={onChange}
          className={classnames(styles.textfield, inputClassName)}
          type={type}
          dataTestId="textfield"
        />
        {isShowLabelOnEdit ? (
          <span>{rightLabel}</span>
        ) : null}
      </div>
    );
  }

  const displayValue = value || defaultValue;
  return (
    <div className={styles.wrapper} data-testid={dataTestId}>
      <div className={styles.label} style={{ gap }}>
        {leftLabel ? (
          <span data-testid="left-label">{leftLabel}</span>
        ) : null}
        <span data-testid="value">{formatValue ? formatValue(displayValue) : displayValue}</span>
        {rightLabel ? (
          <span data-testid="right-label">{rightLabel}</span>
        ) : null}
      </div>

      {tooltip ? (
        <InfoMarker tooltipText={tooltip} dataTestId="info-marker" />
      ) : null}

      {!disabledEditMode ? (
        <div data-testid="edit-icon" className={styles.editIcon} onClick={() => (disableClicks ? null : (setIsEditMode(true)))} />
      ) : null}
    </div>
  );
}
