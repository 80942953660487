import React from 'react';
import PropTypes from 'prop-types';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import Tooltip from 'components/controls/Tooltip';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import getChannelColor from 'components/utils/getChannelColor';
import { hexToRgb } from 'components/utils/colors';
import { getChannelIcon } from 'components/utils/filters/channels';
import style from 'styles/analyze/conversion-journey.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import ChannelJourney from 'components/pages/analyze/ChannelsTab/ChannelJourney';
import ContentJourney from 'components/pages/analyze/ChannelsTab/ContentJourney';
import CampaignJourney from 'components/pages/analyze/CampaignsTab/CampaignJourney';
import ContentCampaignJourney from 'components/pages/analyze/CampaignsTab/ContentCampaignJourney';

export default class ConversionJourney extends Component {
  style = style;

  styles = [analyzeStyle];

  static defaultProps = {
    isCategoryView: false,
    isCampaignsJourney: false,
  };

  static propTypes = {
    conversionIndicator: PropTypes.string.isRequired,
    isCategoryView: PropTypes.bool.isRequired,
    isCampaignsJourney: PropTypes.bool,
  };

  getJourneysColumns = (journeysData, journeysSum) => {
    const { conversionIndicator } = this.props;
    const conversionColumnWidth = 125;
    const percentageColumnWidth = 120;
    const availableSpace = this.tableContainer
      ? this.tableContainer.clientWidth - conversionColumnWidth - percentageColumnWidth
      : 0;

    // set journey column width based on
    // the width of longest journey item
    let journeyColumnWidth = 0;
    journeysData.forEach((item) => {
      const horizontalPadding = 48;
      const journeysItemWidth = item.channels.length * 125;
      const journeysItemMargin = Math.max(item.channels.length - 1, 0) * 32;
      const width = horizontalPadding + journeysItemWidth + journeysItemMargin;
      if (width > journeyColumnWidth) {
        journeyColumnWidth = width;
      }
    });

    return [
      {
        id: 'channels',
        header: 'Journey',
        cell: ({ channels }) => (
          <div className={this.classes.journeyRow}>
            {
              channels.map((channel, index) => {
                const channelNickname = getChannelNickname(channel);
                return (
                  <div
                    key={index}
                    className={this.classes.journeyItem}
                    style={{
                      backgroundColor: `rgba(${hexToRgb(getChannelColor(channel)).join(',')}, 0.2)`,
                    }}
                  >
                    <div
                      className={this.classes.journeyItemIcon}
                      data-icon={getChannelIcon(channel)}
                    />
                    <div className={this.classes.journeyItemLabelContainer}>
                      <Tooltip
                        id={`channel-tooltip-${index}`}
                        className={this.classes.journeyItemLabel}
                        tip={channelNickname}
                        style={{
                          color: getChannelColor(channel),
                        }}
                      >
                        {channelNickname}
                      </Tooltip>
                    </div>
                  </div>
                );
              })
            }
          </div>
        ),
        ...(journeyColumnWidth > 0 && journeyColumnWidth > availableSpace && { width: journeyColumnWidth }),
      },
      {
        id: 'conversion',
        header: `${userStore.getMetricNickname({ metric: conversionIndicator })} Conversion`,
        className: this.classes.sticky,
        headerClassName: this.classes.sticky,
        cell: ({ count }) => count,
        width: 125,
      },
      {
        id: 'percentage',
        header: '% of Total',
        className: this.classes.sticky,
        headerClassName: this.classes.sticky,
        cell: ({ count }) => `${(count / journeysSum * 100).toFixed(1)}%`,
        width: 120,
      },
    ];
  };

  getJourneys = (journeyObject) => {
    const {
      journeysData,
      formattedJourneys,
      journeysSum,
    } = journeyObject;

    return {
      journeysData,
      journeysColumns: this.getJourneysColumns(formattedJourneys, journeysSum),
      journeysSum,
      formattedJourneys,
    };
  };

  render() {
    const {
      conversionIndicator,
      isCategoryView,
      channelJourneys,
      contentJourneys,
      campaignJourneys,
      contentCampaignJourneys,
      isCampaignsJourney,
      widgetConfig,
    } = this.props;

    return (
      <div className={analyzeStyle.locals.rows}>
        <div className={analyzeStyle.locals.item}>
          <div className={this.classes.container}>
            {isCampaignsJourney ? (
              <>
                <ContentCampaignJourney
                  conversionIndicator={conversionIndicator}
                  widgetConfig={widgetConfig}
                  {...contentCampaignJourneys}
                />
                <CampaignJourney
                  conversionIndicator={conversionIndicator}
                  widgetConfig={widgetConfig}
                  {...campaignJourneys}
                />
              </>
            ) : (
              <>
                <ContentJourney
                  conversionIndicator={conversionIndicator}
                  widgetConfig={widgetConfig}
                  {...contentJourneys}
                  isCategoryView={isCategoryView}
                />
                <ChannelJourney
                  conversionIndicator={conversionIndicator}
                  widgetConfig={widgetConfig}
                  {...channelJourneys}
                  isCategoryView={isCategoryView}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
