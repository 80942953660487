import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import _Popup from 'components/Popup';
import style from 'styles/integrations/popup.css';

export default class Popup extends Component {
  style = style;
  state = {
    visible: this.props.defaultVisible || false
  };

  static defaultProps = {
      onClose: () => {}
  };

  static propTypes = {
    title: PropTypes.string,
    titleTooltip: PropTypes.string,
    className: PropTypes.string,
    onClose: PropTypes.func,
    primaryButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    }),
    secondaryButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  };

  open = () => {
    this.setState({
      visible: true
    });
  };

  close = () => {
    this.setState({
      visible: false
    });
  };

  toggle = () => this.setState({
    visible: !this.state.visible,
  });

  onClose = () => {
      const { onClose } = this.props;

      onClose();
      this.close();
  };

  render() {
    const {
      title,
      className,
      primaryButton,
      secondaryButton,
      children,
      ...popupProps
    } = this.props;
    const {visible} = this.state;

    return (
      <_Popup
        {...popupProps}
        onClose={null}
        className={classnames(this.classes.planPopup, className)}
        hidden={!visible}
      >
        <div className={this.classes.header}>
          <div className={this.classes.title}>
            {title}
          </div>
        </div>
        <div className={this.classes.content}>
          {children}
        </div>
        {(primaryButton || secondaryButton) && (
          <div className={this.classes.footer}>
            {secondaryButton &&
              <button
                type='button'
                className={this.classes.secondaryButton}
                onClick={secondaryButton.onClick}
              >
                {secondaryButton.text}
              </button>
            }
            {primaryButton &&
              <button
                type='button'
                className={this.classes.primaryButton}
                onClick={primaryButton.onClick}
              >
                {primaryButton.text}
              </button>
            }
          </div>
        )}
      </_Popup>
    );
  }
}
