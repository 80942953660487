import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Component from 'components/Component';
import style from 'styles/controls/custom-radio.css';

export default class CustomRadio extends Component {
    style = style;

    static propTypes = {
      children: PropTypes.node,
      onChange: PropTypes.func.isRequired,
      style: PropTypes.object,
      radioStyle: PropTypes.object,
      radio: PropTypes.bool,
      className: PropTypes.string,
      radioClassName: PropTypes.string,
      disabled: PropTypes.bool,
    };

    static defaultProps = {
      radio: false,
      disabled: false,
    };

    render() {
      const {
        style,
        radioStyle,
        checked,
        children,
        onChange,
        className,
        radioClassName,
        childrenClassName,
        disabled,
        indicator,
      } = this.props;

      return (
        <label
          className={classnames(this.classes.container, className)}
          style={style}
          onClick={() => onChange(indicator)}
        >
          <div
            className={classnames(this.classes.radio, radioClassName)}
            style={radioStyle}
            data-checked={checked ? true : null}
            data-disabled={disabled ? true : null}
          />
          <div className={classnames(this.classes.children, childrenClassName)} data-checked={checked ? true : null}>
            {children}
          </div>
        </label>
      );
    }
}
