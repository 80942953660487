import Tooltip from 'components/controls/Tooltip';
import {uniqueId} from 'lodash';
import React from 'react';

const CellWrapper = ({ children, showToolTip }) => (
  <div style={{ maxWidth: 80 }}>
    {showToolTip ? (
      <Tooltip
        id={`plan-cell-tip-${uniqueId()}`}
        tip="Estimated Actual Cost"
      >
        {children}
      </Tooltip>
    ) : children}
  </div>
);

export default CellWrapper;
