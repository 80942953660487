import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';

import style from 'styles/controls/toggle-with-icons.css';

const styles = style.locals || {};

function ToggleWithIcons({
  options, onChange, selected, disabled,
}) {
  useStyles([style]);

  return (
    <div className={styles.wrapper}>
      {options.map((option) => {
        const { value, icon, tooltip } = option;
        return (
          <Tooltip className={styles.tooltip} id={`toogle-${value}`} key={value} tip={tooltip}>
            <div
              data-testid={`${selected === value ? 'toggleSelected' : 'toggleItem'}-${value}`}
              className={classnames(styles.item, selected === value && styles.active, disabled && styles.disabled)}
              onClick={() => !disabled && onChange(value)}
            >
              <img alt={tooltip} src={icon} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ToggleWithIcons;
