import React, { useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import serverCommunication from 'data/serverCommunication';

import useOnClickOutside from 'components/pages/users/useOnClickOutside';
import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';

import { regionNameAlreadyExistsErrorMessage } from 'components/enums';

import servicesStore from 'stores/servicesStore';

import style from 'styles/integrations/business-unit-popup.css';

const styles = style.locals || {};

function EditBusinessUnitNickname({
  regionNickname,
  userBusinessUnitsWithIds,
  onClose,
  UID,
  setUserBusinessUnitsWithIds,
  setUserRegionsNicknames,
  userRegionsNicknames,
  getRegionValue,
}) {
  useStyles([style]);
  const popupRef = useRef();

  const [regionData, setRegionData] = useState({ name: '', isShowError: false });

  const region = getRegionValue({ regionNickname });

  useOnClickOutside(popupRef, () => {
    onClose();
  });

  function onChangeRegionName(value) {
    const regionName = value.target.value;
    const isShowError = userRegionsNicknames.some((name) => name.toLowerCase() === regionName.toLowerCase());
    setRegionData({ ...regionData, name: regionName, isShowError });
  }

  async function updateRegionNickname() {
    try {
      const updatedUserBusinessUnitsWithIds = [...userBusinessUnitsWithIds];
      const selectedBusinessUnitsWithIdsIndex = updatedUserBusinessUnitsWithIds.findIndex((businessUnit) => businessUnit.region === region);
      updatedUserBusinessUnitsWithIds[selectedBusinessUnitsWithIdsIndex].name = regionData.name;
      setUserBusinessUnitsWithIds({ businessUnit: updatedUserBusinessUnitsWithIds });
      const updatedUserBusinessUnitNames = updatedUserBusinessUnitsWithIds.map((businessUnit) => businessUnit.name);
      setUserRegionsNicknames({ userRegions: updatedUserBusinessUnitNames });

      await serverCommunication.serverRequest('PUT', 'region', JSON.stringify({ properties: { nickname: regionData.name } }), region);
    } catch (error) {
      servicesStore.logger.error('failed to update region nickname', {
        error,
        UID,
        region,
      });
    }
    onClose();
  }

  return (
    <Page popup width="400px">
      <div ref={popupRef} className={styles.wrapper}>
        <div className={styles.header}>
          <b>Edit business unit name</b>
        </div>
        <div className={styles.content}>
          <Textfield
            dataTestId="regionNicknameInput"
            isShowError={regionData.isShowError}
            inputErrorMessage={regionNameAlreadyExistsErrorMessage}
            placeHolder={regionNickname}
            onChange={(value) => onChangeRegionName(value)}
          />
        </div>
        <div className={styles.footerOneButton}>
          <div>
            <Button
              dataTestId="save-region-name"
              disabled={regionData.isShowError || regionData.name === ''}
              type="primaryBlue"
              onClick={() => updateRegionNickname()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default inject(({
  userStore: {
    userBusinessUnitsWithIds,
    setUserBusinessUnitsWithIds,
    setUserRegionsNicknames,
    userRegionsNicknames,
    getRegionValue,
    userMonthPlan: {
      UID,
    },
  },
}) => ({
  userBusinessUnitsWithIds,
  setUserBusinessUnitsWithIds,
  setUserRegionsNicknames,
  userRegionsNicknames,
  getRegionValue,
  UID,
}), observer)(EditBusinessUnitNickname);
