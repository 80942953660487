import { EQUALS } from 'components/utils/filters/logic';
import { HIGHER, LOWER, CONTAINS } from 'components/utils/filters';

export const IS_EMPTY = 'IS_EMPTY';

export const ruleOperators = {
  equals: EQUALS,
  contains: CONTAINS,
  largerThan: HIGHER,
  smallerThan: LOWER,
  isEmpty: IS_EMPTY,
};

export const bucketOptionsTypes = {
  [ruleOperators.equals]: 'Equals to',
  [ruleOperators.contains]: 'Contains',
  [ruleOperators.largerThan]: 'Larger than',
  [ruleOperators.smallerThan]: 'Smaller than',
  [ruleOperators.isEmpty]: 'Is empty',
};

export const isNumericRuleOperators = [ruleOperators.largerThan, ruleOperators.smallerThan];

export const duplicateNameErrorMessage = 'This field name is already in use, please select another name';

export const overlappingErrorMessage = 'Make sure mapping rules do not overlap.';

export const emptyStateText = {
  title: 'Create a Custom Segment',
  subTitle: 'Create new custom fields based on existing CRM fields, and group values to spark new analysis capabilities.',
};

export const addNewSegmentPopupText = {
  title: 'Name your new field',
  subTitle: 'This field will act like any CRM field, and will be available in filters and segment analysis',
};
