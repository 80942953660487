import { isNil } from 'lodash';
import moment from 'moment';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import { getFiscalYearForMonth } from 'components/utils/dates';

export const NUMBER_OF_FUTURE_MONTHS = 12;

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const budgetTypeMapping = {
  fiscalCurrent: 0,
  fiscalNext: 1,
  agile: 2,
};

// Get the number of fiscal year which includes planDate
export function getCurrentFiscalYear(fiscalYearFirstMonth) {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  if (fiscalYearFirstMonth < currentMonth) {
    return currentYear;
  }
  return currentYear - 1;
}

// Get start & end Index of currently selected fiscal year's array in two years data array
export function getMonthRange(budgetType, fiscalYearFirstMonth) {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;

  const startMonthIndex = () => {
    if (budgetType === budgetTypeMapping.fiscalCurrent || budgetType === budgetTypeMapping.agile) {
      return 0;
    } else {
      if (currentMonth > fiscalYearFirstMonth) {
        return NUMBER_OF_FUTURE_MONTHS - currentMonth + fiscalYearFirstMonth + 1;
      }
      return fiscalYearFirstMonth - currentMonth + 1;
    }
  };

  const endMonthIndex = () => {
    if (budgetType === budgetTypeMapping.fiscalNext || budgetType === budgetTypeMapping.agile) {
      return NUMBER_OF_FUTURE_MONTHS + startMonthIndex() - 1;
    } else {
      if (currentMonth <= fiscalYearFirstMonth) {
        return fiscalYearFirstMonth - currentMonth;
      }
      return NUMBER_OF_FUTURE_MONTHS - currentMonth + fiscalYearFirstMonth;
    }
  };

  return { startMonthIndex: startMonthIndex(), endMonthIndex: endMonthIndex() };
}

export function formatDate(dateStr) {
  if (dateStr) {
    const [monthNum, yearNum] = dateStr.split('/');
    return `${monthNames[monthNum - 1]} ${yearNum.substr(2, 2)}`;
  } else {
    return null;
  }
}

export function getDates(includingPast = false, includingFuture = true) {
  const dateStr = getCurrentDateStr();
  return getDatesSpecific(dateStr,
    includingPast ? NUMBER_OF_FUTURE_MONTHS : 0,
    includingFuture ? NUMBER_OF_FUTURE_MONTHS : 0);
}

export function getCurrentDateStr() {
  const date = new Date();
  return `${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
}

export function getTwoYearDates(currentMonthIndex = 0) {
  const currentPlanDate = getCurrentDateStr();
  return getDatesSpecific(currentPlanDate, currentMonthIndex, 2 * NUMBER_OF_FUTURE_MONTHS - currentMonthIndex);
}

function getDateOffset(dates, division, fiscalYearFirstMonth) {
  const lastIndexOfFirstQuarter = dates && dates.findIndex((date) => {
    const month = date.getMonth();
    return (month + 1 - fiscalYearFirstMonth) % division === 0;
  });

  return (!isNil(lastIndexOfFirstQuarter) && lastIndexOfFirstQuarter >= 0) ? lastIndexOfFirstQuarter + 1 : null;
}

export function getQuarterOffset(dates, fiscalYearFirstMonth) {
  return getDateOffset(dates, 3, fiscalYearFirstMonth);
}

export function getAnnualOffset(dates, fiscalYearFirstMonth) {
  return getDateOffset(dates, 12, fiscalYearFirstMonth);
}

export function formatSpecificDate(date, isSystemDate) {
  const monthStr = isSystemDate ? date.getMonth() + 1 : monthNames[date.getMonth()];
  const yearStr = isSystemDate ? date.getFullYear().toString() : date.getFullYear().toString().substr(2, 2);
  const delimiter = isSystemDate ? '/' : ' ';
  return monthStr + delimiter + yearStr;
}

export function getRawDatesSpecific(numberOfPast, numberOfFuture) {
  const dateStr = getCurrentDateStr();
  const dates = [];
  const planDate = dateStr.split('/');
  for (let i = -numberOfPast; i < numberOfFuture; i++) {
    const date = new Date(planDate[1], planDate[0] - 1);
    date.setMonth(date.getMonth() + i);
    dates.push(new Date(date));
  }
  return dates;
}

export function getDatesSpecific(dateStr, numberOfPast, numberOfFuture, isSystemDates = false) {
  const rawDates = getRawDatesSpecific(numberOfPast, numberOfFuture);
  return rawDates.map((date) => formatSpecificDate(date, isSystemDates));
}

export function getNumberOfDaysBetweenDates(toDate, fromDate = new Date()) {
  return Math.max(Math.ceil((toDate.getTime() - fromDate.getTime()) / (24 * 60 * 60 * 1000)), 0);
}

export const getMonthsBetweenDates = (start, end) => {
  const dateStart = moment(start);
  const dateEnd = moment(end);
  const interim = dateStart.clone();
  const timeValues = [];

  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    timeValues.push(interim.format('MMM YY'));
    interim.add(1, 'month');
  }
  return timeValues;
};

export const getMonthName = (date) => moment(date).format('MMM YY');
export const getDayName = (date) => moment(date).format('D MMM');
export const getWeekName = getDayName;

export const getFiscalQuarterForMonth = (m, fiscalYearFirstMonth) => {
  const quarter = (m) => Math.ceil(m / 3);

  const month = Number(m);
  const diff = month - fiscalYearFirstMonth;
  const monthIndexWithOffset = diff < 1 ? diff + 12 : diff;

  return quarter(monthIndexWithOffset);
};

export const fiscalYearStartDate = (fiscalYearFirstMonth) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const fiscalMonth = fiscalYearFirstMonth < 0 ? 12 + fiscalYearFirstMonth : fiscalYearFirstMonth;
  const fiscalY = currentDate.getMonth() >= fiscalMonth ? year : year - 1;

  return moment(`${fiscalY} ${fiscalMonth + 1}`, 'YYYY M');
};

export const fiscalQuarterStartDate = (fiscalYearStart, fiscalYearFirstMonth) => {
  const currentFiscalQuarter = getFiscalQuarterForMonth(moment().format('M'), fiscalYearFirstMonth);

  return fiscalYearStart.add((currentFiscalQuarter - 1) * 3, 'M');
};

export const fiscalData = (fiscalYearFirstMonth) => {
  const fiscalYearStart = fiscalYearStartDate(fiscalYearFirstMonth);
  const fiscalQuarterStart = fiscalQuarterStartDate(fiscalYearStart.clone(), fiscalYearFirstMonth);

  const fiscalQuarterEnd = fiscalQuarterStart.clone().add(3, 'month');
  const daysOfQuarterTotal = fiscalQuarterEnd.diff(fiscalQuarterStart, 'days');
  const daysPassedYear = moment().diff(fiscalYearStart, 'days');
  const daysPassedQuarter = moment().diff(fiscalQuarterStart, 'days');

  const date = moment();
  const year = date.format('YYYY');
  const mon = date.format('M');
  const currentFiscalYear = getFiscalYearForMonth(mon, fiscalYearFirstMonth + 1, year);

  let currentQuarter = getFiscalQuarterForMonth(moment().format('M'), fiscalYearFirstMonth);
  currentQuarter = `Q${currentQuarter} ${currentFiscalYear}`;
  return {
    currentQuarter,
    currentFiscalYear,
    fiscalYearStart,
    fiscalQuarterStart,
    daysPassedQuarter,
    daysPassedYear,
    daysOfQuarterTotal,
  };
};

export const stringifyDateWithTimeZone = (timeZone) => (dateString, format = 'MMM DD YYYY [at] hh:mm a') => {
  const date = moment(dateString).tz(timeZone || DEFAULT_TIME_ZONE);
  return date.format(format);
};

export const formatDates = (unitOfTime, ISOUnitOfTime) => {
  const endDate = moment().startOf(ISOUnitOfTime || unitOfTime);
  const startDate = endDate.clone().subtract(1, unitOfTime);
  return {
    startDate: startDate.toDate().toString(),
    endDate: endDate.subtract(1, 's').toDate().toString(),
  };
};

export const daysInMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
};
