import userStore from 'stores/userStore';

export function getIsLightningForceUrl({ reportUrlItem }) {
  if (!reportUrlItem) {
    return false;
  }

  const {
    host,
    pathname,
  } = reportUrlItem;

  if (!host) {
    return false;
  }

  const firstDotIndex = host.indexOf('.');

  if (host.slice(firstDotIndex) !== '.lightning.force.com') {
    return false;
  }

  const splitPathname = pathname.split('/lightning/r/Report/');
  if (splitPathname[0] !== '' || splitPathname[1] === '') {
    return false;
  }

  if (splitPathname[1].startsWith('/')) {
    return false;
  }

  return true;
}

export function getIsSalesforceUrl({ reportUrlItem }) {
  if (!reportUrlItem) {
    return false;
  }

  const {
    host,
    pathname,
  } = reportUrlItem;

  if (!host) {
    return false;
  }

  const firstDotIndex = host.indexOf('.');

  if (host.slice(firstDotIndex) !== '.my.salesforce.com') {
    return false;
  }

  const reportId = pathname.split('/')[1];
  const comparePathName = pathname.replace(reportId, 'reportId');

  return comparePathName === '/reportId';
}

export function getIsValidReportUrl({ reportUrl }) {
  if (!reportUrl) {
    return false;
  }

  try {
    const userDomain = userStore.userMonthPlan.salesforceapi.tokens.instance_url || '';
    const userDomainUrlItem = new URL(userDomain);
    const domainPrefix = userDomainUrlItem.host.split('.')[0];

    const reportUrlItem = new URL(reportUrl);

    if (reportUrlItem.protocol !== 'https:') {
      return false;
    }

    const reportDomainPrefix = reportUrlItem.host.split('.')[0];

    if (reportDomainPrefix !== domainPrefix) {
      return false;
    }

    return getIsLightningForceUrl({ reportUrlItem }) || getIsSalesforceUrl({ reportUrlItem });
  } catch (error) {
    return false;
  }
}
