exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".table-cell-2__root-17E{display:flex;align-items:center}.table-cell-2__root-17E:active .table-cell-2__actions-102>*,.table-cell-2__root-17E:focus .table-cell-2__actions-102>*,.table-cell-2__root-17E:hover .table-cell-2__actions-102>*{opacity:1}.table-cell-2__root-17E button{overflow:hidden}.table-cell-2__input-2t1{font-size:inherit;letter-spacing:.1px;line-height:34px;max-width:75px;vertical-align:middle;font-weight:inherit;border:1px solid transparent}.table-cell-2__input--editable-XZX{border-color:#e6e8f0;border-radius:3px;box-shadow:0 0 3px 0 #e6e8f0;color:#4d91fc;padding-left:10px;margin-left:-10px}.table-cell-2__secondary-value-O6i{bottom:-2px;color:#b2bbd5;font-size:10px;font-weight:500;opacity:0;position:absolute;transition:opacity .15s;left:10px}.table-cell-2__secondary-value--show-13L{opacity:1}.table-cell-2__button-1kf{background:none;border:none;cursor:pointer;display:inline-block;outline:none;padding:0;font-size:0}.table-cell-2__button--edit-362{opacity:0}.table-cell-2__icon-1Bm{background-position:50%;background-repeat:no-repeat;background-size:contain;display:inline-block;height:18px;width:18px}.table-cell-2__actions-102{font-size:0;margin-left:3px;display:flex;align-items:center}.table-cell-2__actions-102 button+button{margin-left:3px}[data-icon=\"plan:none\"]{background-image:url(\"/assets/plan_icons/clear-state.svg\")}[data-icon=\"plan:like\"]{background-image:url(\"/assets/plan_icons/like.svg\")}[data-icon=\"plan:lock\"]{background-image:url(\"/assets/plan_icons/lockChannel.svg\")}[data-icon=\"plan:edit\"]{background-image:url(\"/assets/plan_icons/edit-per-cell.svg\")}[data-icon=\"plan:acceptSuggestion\"]{background-image:url(\"/assets/plan_icons/redo.svg\")}[data-icon=\"plan:approveEdit\"]{background-image:url(\"/assets/plan_icons/accept.svg\")}[data-icon=\"plan:declineEdit\"]{background-image:url(\"/assets/plan_icons/decline.svg\")}", ""]);

// exports
exports.locals = {
	"root": "table-cell-2__root-17E",
	"root": "table-cell-2__root-17E",
	"actions": "table-cell-2__actions-102",
	"actions": "table-cell-2__actions-102",
	"input-wrapper": "table-cell-2__input-wrapper-3rw",
	"inputWrapper": "table-cell-2__input-wrapper-3rw",
	"input": "table-cell-2__input-2t1",
	"input": "table-cell-2__input-2t1",
	"input--editable": "table-cell-2__input--editable-XZX",
	"inputEditable": "table-cell-2__input--editable-XZX",
	"secondary-value": "table-cell-2__secondary-value-O6i",
	"secondaryValue": "table-cell-2__secondary-value-O6i",
	"secondary-value--show": "table-cell-2__secondary-value--show-13L",
	"secondaryValueShow": "table-cell-2__secondary-value--show-13L",
	"button": "table-cell-2__button-1kf",
	"button": "table-cell-2__button-1kf",
	"button--edit": "table-cell-2__button--edit-362",
	"buttonEdit": "table-cell-2__button--edit-362",
	"icon": "table-cell-2__icon-1Bm",
	"icon": "table-cell-2__icon-1Bm"
};