export function getFunnelPills({
  canvasData, selectedBuildingBlocksStatuses, stageValue, measurementUnit,
}) {
  const allFunnelPills = canvasData[stageValue]?.segments || {};
  const funnelPillsBySelectedBuildingBlocks = {};
  for (const funnelPillSegment in allFunnelPills) {
    if (selectedBuildingBlocksStatuses[funnelPillSegment]) {
      funnelPillsBySelectedBuildingBlocks[funnelPillSegment] = {
        ...allFunnelPills[funnelPillSegment],
        measurementUnit,
      };
    }
  }

  return funnelPillsBySelectedBuildingBlocks;
}
