import React from 'react';
import { inject, observer } from 'mobx-react';

import StatsSquares from 'components/pages/analyze/OverviewTab/StatsSquares';

import { widgetTypes } from 'components/pages/analyze/enums';
import { parseHistoricalPerformanceDataToStatsSquares, getSelectedMetricsForStatsSquare } from 'components/pages/analyze/OverviewTab/logic/statsSquares';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

function StatsSquaresWrapper({
  historicalPerformance,
  getWidgetRequestId,
  widgetHeaderConfig,
  widgetConfig,
  widgetHeaderProps,
  funnels,
}) {
  const { isCompareToPreviousEnabled } = widgetHeaderConfig;

  const requestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
  const historicalPerformanceData = historicalPerformance?.[requestId]?.result;

  const isHistoricalPerformanceLoaded = historicalPerformance?.[requestId]?.status === 'finished';
  const isHistoricalPerformanceFailedToLoad = historicalPerformance?.[requestId]?.status === 'failed';

  const requireKeysForStatsSquare = getSelectedMetricsForStatsSquare({ userFunnels: funnels });

  const isHistoricalPerformanceForStatsSquareLoaded = requireKeysForStatsSquare.every((key) => Object.keys(historicalPerformanceData || {}).includes(key)) || isHistoricalPerformanceLoaded;
  let statsSquaresData = {};
  if (isHistoricalPerformanceForStatsSquareLoaded) {
    statsSquaresData = parseHistoricalPerformanceDataToStatsSquares({ historicalPerformance: historicalPerformanceData, isCompareToPreviousEnabled });
  }

  return (
    <StatsSquares
      indicatorsCount={statsSquaresData.indicatorsCount}
      revenueAndPipeline={statsSquaresData.revenueAndPipelineOverview}
      totalCost={statsSquaresData.totalCost}
      growthValues={statsSquaresData.growthValues || {}}
      isCompareToPreviousEnabled={isCompareToPreviousEnabled}
      isLoaded={isHistoricalPerformanceForStatsSquareLoaded}
      isFailedToLoad={isHistoricalPerformanceFailedToLoad}
      isReadOnly={widgetHeaderConfig.isReadOnly}
      widgetHeaderProps={widgetHeaderProps}
      widgetHeaderConfig={widgetHeaderConfig}
    />
  );
}

export default inject(({
  widgetsAnalysisStore: {
    getWidgetRequestId,
    dataPerWidget: {
      [widgetTypes.historicalPerformance]: historicalPerformance,
    },
  },
  userStore: {
    funnels,
  },
}) => ({
  getWidgetRequestId,
  historicalPerformance,
  funnels,
}),
observer)(StatsSquaresWrapper);
