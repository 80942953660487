import React, { useState, useRef } from 'react';

import useStyles from 'hooks/useStyles';

import Popup from 'components/Popup';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';
import Tooltip from 'components/controls/Tooltip';

import style from 'styles/analyze/widgetHeader.css';
import buttonWithOptionsStyle from 'styles/controls/buttonWithOptions.css';

const styles = style.locals || {};
const optionsStyle = buttonWithOptionsStyle.locals || {};

export default function CogwheelWithOptions({ options }) {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const refPopupFilter = useRef();

  useStyles([style, buttonWithOptionsStyle]);

  const closePopup = () => {
    setIsShowPopup(false);
  };

  useOnClickOutside(refPopupFilter, () => {
    closePopup();
  });

  const onClickCogwheelPopup = () => {
    setIsShowPopup((prev) => !prev);
  };

  const onClickAction = (option) => {
    if (!option.disabled) {
      option.action();
      closePopup();
    }
  };

  return (
    <div ref={refPopupFilter} className={styles.widgetCogwheelWrapper}>
      <div
        className={styles.widgetCogwheel}
        onClick={onClickCogwheelPopup}
      />
      <Popup
        className={styles.widgetCogwheelPopup}
        hidden={!isShowPopup}
      >
        <div className={optionsStyle.optionsMenu}>
          {options.map((option) => (
            <div
              key={`option-${option.label}`}
              onClick={() => onClickAction(option)}
              className={
                option.disabled && optionsStyle.disabledButton
              }
            >
              <Tooltip
                tip={option.tooltip}
                id={`cogwheel-option-${option.label}`}
                hidden={!option.disabled}
                place="top"
              >
                {option.label}
              </Tooltip>
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
}
