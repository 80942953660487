import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/setup-budget.css';

export default class ProgressBar extends Component {
    style = style;

    render() {
      const {
        total,
        invested,
        planned,
        allocated,
        unallocated,
        data,
      } = this.props;
      return (
        <div>
          <div className={this.classes.totalProgress}>
            <div className={this.classes.totalRed} style={{ width: `${invested / total * 100}%` }} />
            <div className={this.classes.totalGreen} style={{ width: `${unallocated / total * 100}%` }} />
            <div className={this.classes.totalGrey} style={{ width: `${100 - ((invested / total * 100) + (invested / total * 100))}%` }} />
          </div>
          <div className={this.classes.totalItemsWrap}>
            <div className={this.classes.valueWrap}>
              <div className={this.classes.valueWrapItem}>
                <p className={this.classes.valueWrapLabel}>
                  Invested
                </p>
                <p className={this.classes.labelValue}>
                  { data ? `$ ${invested} (${invested / total * 100}%)` : '$ 100.000 (10%)'}
                </p>
              </div>
              <div className={this.classes.valueWrapItemMid}>
                <p className={this.classes.valueWrapLabel}>
                  Planned
                </p>
                <p className={this.classes.labelValue}>
                  {`$ ${planned} (${planned / total * 100}%)`}
                </p>
              </div>
              <div className={this.classes.valueWrapItem}>
                <p className={this.classes.valueWrapLabel}>
                  Allocated
                </p>
                <p className={this.classes.labelValue}>
                  {data ? `$ ${allocated} (${allocated / total * 100}%)` : '$ 750.000 (75%)'}
                </p>
              </div>
            </div>
            <div className={this.classes.unalloc}>
              <div className={this.classes.valueWrapItem}>
                <p className={this.classes.valueWrapLabel} style={{ textAlign: 'right' }}>
                  Unallocated
                </p>
                <p className={this.classes.labelValue}>
                  {data ? `$ ${unallocated} (${unallocated / total * 100}%)` : '$ 250.000 (25%)'}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
