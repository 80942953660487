import classnames from 'classnames';
import React from 'react';

import useStyles from 'hooks/useStyles';

import { askQuestionInputPlaceholderText } from 'components/pages/questions/enums';

import style from 'styles/questions/questions.css';

const styles = style.locals || {};

function AskQuestionInput({
  value = '',
  onFocus,
  onChange,
  onClear,
  className,
  placeholder = askQuestionInputPlaceholderText,
}) {
  useStyles([style]);

  return (
    <div className={styles.askQuestionInputWrapper}>
      {value.length > 0 && onClear ? (
        <div
          className={styles.closeIcon}
          onClick={() => onClear()}
          data-testid="close-icon"
        />
      ) : null}
      <input
        className={classnames(styles.questionInput, className)}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        onFocus={onFocus ? () => onFocus() : null}
      />
    </div>
  );
}

export default AskQuestionInput;
