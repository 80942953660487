import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget-channels.css';
import ChannelCard from 'components/pages/budget/ChannelCard';
import BudgetButtons from 'components/pages/budget/BudgetButtons';

const CHANNELS = [
  {
    title: 'Business Directories',
    channels: [
      {
        iconUrl: 'assets/channels-icons/paid-reviews-icon.svg',
        title: 'Paid reviews',
      },
      {
        iconUrl: 'assets/channels-icons/ppc-online-directories-icon.svg',
        title: 'PPC - online directories',
        tooltip: true,
      },
    ],
  },
  {
    title: 'Display',
    channels: [
      {
        iconUrl: 'assets/channels-icons/display-google-ad-words-icon.svg',
        title: 'Display ads - Google Adwords',
      },
    ],
  },
  {
    title: 'Events',
    channels: [
      {
        iconUrl: 'assets/channels-icons/organising-event-icon.svg',
        title: 'Organising event',
      },
      {
        iconUrl: 'assets/channels-icons/speaking-engagement-icon.svg',
        title: 'Speaking engagement',
        tooltip: true,
      },
    ],
  },
  {
    title: 'Paid Search',
    channels: [
      {
        iconUrl: 'assets/channels-icons/ppc-bing-icon.svg',
        title: 'PPC - Bing',
      },
      {
        iconUrl: 'assets/channels-icons/ppc-generic-icon.svg',
        title: 'PPC - generic',
      },
      {
        iconUrl: 'assets/channels-icons/ppc-google-ad-words-icon.svg',
        title: 'PPC - Google AdWords',
      },
    ],
  },
];

export default class Channels extends Component {
  style = style;

  defaultChose = (channel) => channel;

  render() {
    const {
      channels, onChoose, selectedChannels,
    } = this.props;

    return (
      <div className={this.classes.root}>
        <div className={this.classes.item}>
          <p className={this.classes.title}>Custom</p>
          <div className={this.classes.cards}>
            <span className={this.classes.card}>
              <ChannelCard
                title="Other"
                classes={{ root: this.classes.dashed }}
                defaultImg
                onChoose={this.defaultChose}
              />
            </span>
            <span className={this.classes.card}>
              <ChannelCard
                title="+"
                classes={{ root: this.classes.dashed, title: this.classes.title }}
                defaultImg
                noImage
                onChoose={this.defaultChose}
              />
            </span>
          </div>
        </div>
        {channels && channels.map((channel) => (
          <div key={_.uniqueId(channel.label)} className={this.classes.item}>
            <p className={this.classes.title}>{channel.label}</p>
            <div className={this.classes.cards}>
              {channel?.options.map((option) => (
                <span key={option?.label} className={this.classes.card}>
                  <ChannelCard
                    title={option.label}
                    tooltip
                    channels={option.value}
                    channelsWithCategory={channel}
                    onChoose={onChoose}
                  />
                </span>
              ))}
            </div>
          </div>
        ))}
        <BudgetButtons
          selectedChannels={selectedChannels}
        />
      </div>
    );
  }
}
