import { legendItems } from 'components/widgets/optimalJourney/journeyCanvas/enums';

export function getLegendItems({ selectedBuildingBlocksNames }) {
  const legendItemsArray = selectedBuildingBlocksNames.map((buildingBlockName) => {
    const legendItem = legendItems.find((item) => buildingBlockName in item) || {};
    const legendItemValue = legendItem[buildingBlockName];

    if (!legendItemValue) {
      return null;
    }
    return legendItemValue;
  });

  return legendItemsArray;
}
