exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".budget-breakdown__content-2Vl{display:flex;justify-content:space-between;width:100%;flex-wrap:nowrap}.budget-breakdown__borderWrap-2Vu{background:#f5f6fa;border:1px solid rgba(178,187,213,.5);border-radius:4px;padding:24px;margin:0 0 12px;width:100%;max-width:1080px;padding-bottom:7px}.budget-breakdown__header-3FJ{display:flex;flex-direction:column}.budget-breakdown__title-3Im{font-weight:600;font-size:16px;line-height:24px;color:#182033;margin:0 0 8px}.budget-breakdown__text-2S1{font-weight:400;font-size:14px;line-height:21px;color:#99a4c2;margin:0 0 16px}.budget-breakdown__mainWrap-Dco{display:flex;flex-wrap:wrap;justify-content:space-between}.budget-breakdown__mainItem-244{display:flex;flex-direction:column}.budget-breakdown__itemTitle-1hO{font-weight:400;font-size:14px;line-height:21px;color:#2f3b66;margin:8px 0}.budget-breakdown__input-3MO{position:relative;height:40px;width:160px;background:linear-gradient(1turn,#fafafc,#fff);border:1px solid rgba(178,187,213,.5);box-sizing:border-box;box-shadow:0 1px 2px #e6e8f0;border-radius:3px;padding-left:33px;margin:0 2px 16px 0;color:#99a4c2}.budget-breakdown__input-3MO:focus{outline:none}.budget-breakdown__inputManual-1TP{position:relative;height:40px;width:160px;background:linear-gradient(1turn,#fafafc,#fff);border:1px solid rgba(178,187,213,.5);box-sizing:border-box;box-shadow:0 1px 2px #e6e8f0;border-radius:3px;padding-left:33px;margin:0 2px 16px 0;color:#2a3558}.budget-breakdown__inputManual-1TP:focus{outline:none}input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{-webkit-appearance:none}.budget-breakdown__inputWrap-3d6{position:relative}.budget-breakdown__curency-15P{position:absolute;top:-4px;left:17px;font-weight:500;font-size:14px;line-height:21px;color:#2a3558;z-index:1}", ""]);

// exports
exports.locals = {
	"content": "budget-breakdown__content-2Vl",
	"content": "budget-breakdown__content-2Vl",
	"borderWrap": "budget-breakdown__borderWrap-2Vu",
	"borderWrap": "budget-breakdown__borderWrap-2Vu",
	"header": "budget-breakdown__header-3FJ",
	"header": "budget-breakdown__header-3FJ",
	"title": "budget-breakdown__title-3Im",
	"title": "budget-breakdown__title-3Im",
	"text": "budget-breakdown__text-2S1",
	"text": "budget-breakdown__text-2S1",
	"mainWrap": "budget-breakdown__mainWrap-Dco",
	"mainWrap": "budget-breakdown__mainWrap-Dco",
	"mainItem": "budget-breakdown__mainItem-244",
	"mainItem": "budget-breakdown__mainItem-244",
	"itemTitle": "budget-breakdown__itemTitle-1hO",
	"itemTitle": "budget-breakdown__itemTitle-1hO",
	"input": "budget-breakdown__input-3MO",
	"input": "budget-breakdown__input-3MO",
	"inputManual": "budget-breakdown__inputManual-1TP",
	"inputManual": "budget-breakdown__inputManual-1TP",
	"inputWrap": "budget-breakdown__inputWrap-3d6",
	"inputWrap": "budget-breakdown__inputWrap-3d6",
	"curency": "budget-breakdown__curency-15P",
	"curency": "budget-breakdown__curency-15P"
};