import React from 'react';

import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Component from 'components/Component';

import { campaignTagsColors } from 'components/pages/plan/logic/enums';

import style from 'styles/plan/campaign-title.css';

class CampaignTitle extends Component {
  style = style;

  render() {
    return (
      <div
        className={this.classes.campaign}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        <div className={this.classes.campaignIcon} />
        <EllipsisTooltip
          className={this.classes.campaignTipContainer}
          text={this.props.cellValue}
          place="top"
          content={(
            <div className={this.classes.campaignTooltipWrapper}>
              {this.props.cellValue}
              <div className={this.classes.campaignTagWrapper}>
                {this.props.campaignTags?.map((tag, tagIndex) => (<div className={this.classes.campaignTag} style={{ ...campaignTagsColors[tagIndex % campaignTagsColors.length] }}>{tag}</div>))}
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default CampaignTitle;
