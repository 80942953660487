import _ from 'lodash';
import moment from 'moment';
import {
  decorate, observable, action, computed,
} from 'mobx';

import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { costDateFormat } from 'stores/plan/logic/enums';
import {
  parsCampaignsCostsForPlanVsActual,
  parseChannelsAndCampaignsBudgetsForAnnualTab,
  getChannelsAndCampaignsBudgetsAccordingSelectedTags,
  getChannelsAndCampaignsCostsAccordingSelectedTags,
} from 'stores/plan/logic/planStore';

class PlanStore {
  constructor() {
    this.campaignListToUpdate = [];
    this.campaignPropertiesListToUpdate = [];
    this.campaignsCostsData = [];
    this.channelsCostsData = [];
    this.channelsMonthlyBudgets = [];
    this.campaignsMonthlyBudgets = {};
    this.isLoadingPlannedVsActualDataFromServer = false;
    this.isBudgetLoadingFromServer = false;
    this.selectedCampaignTagsArray = [];
    this.originalParsedCampaignsBudgets = {};
    this.originalCampaignsCostsData = [];
    this.originalParsedChannelsBudgets = [];
    this.originalChannelsCostsData = [];
  }

  resetCampaignsToUpdate() {
    this.campaignListToUpdate = [];
  }

  resetCampaignsPropertiesListToUpdate() {
    this.campaignPropertiesListToUpdate = [];
  }

  addCampaignToCampaignListToUpdate({ campaignToUpdate }) {
    const campaignToUpdateIndex = this.campaignListToUpdate.findIndex((campaign) => campaign.id === campaignToUpdate.id
      && campaign.startDate === campaignToUpdate.startDate
      && campaign.amountType === campaignToUpdate.amountType
      && campaign.endDate === campaignToUpdate.endDate
      && campaign.region === campaignToUpdate.region);

    if (campaignToUpdateIndex !== -1) {
      this.campaignListToUpdate[campaignToUpdateIndex] = campaignToUpdate;
    } else {
      this.campaignListToUpdate.push(campaignToUpdate);
    }
  }

  addCampaignToCampaignPropertiesListToUpdate({ campaignToUpdate }) {
    const campaignToUpdateIndex = this.campaignPropertiesListToUpdate.findIndex((campaign) => campaign.id === campaignToUpdate.id
      && campaign.channel === campaignToUpdate.channel);

    if (campaignToUpdateIndex !== -1) {
      this.campaignPropertiesListToUpdate[campaignToUpdateIndex] = campaignToUpdate;
    } else {
      this.campaignPropertiesListToUpdate.push(campaignToUpdate);
    }
  }

  updateCampaignAmount({
    region = userStore.userMonthPlan?.region, channel, amount, campaignName, amountType, id, startDate, endDate,
  }) {
    const campaignId = id || `${campaignName}_${channel}`;

    const campaignToUpdate = {
      startDate: moment(startDate).format(costDateFormat),
      endDate: moment(endDate).format(costDateFormat),
      id: campaignId,
      amount,
      amountType,
      region,
      currency: 'USD',
    };

    this.addCampaignToCampaignListToUpdate({ campaignToUpdate });
  }

  updateCampaignProperties({
    name, channel, sources, startDate, endDate, status, id, region = userStore.userMonthPlan?.region, isPlaceholder,
  }) {
    const campaignId = id || `${name}_${channel}`;

    const campaign = {
      id: campaignId,
      channel,
      name,
      sources,
      status,
      startDate: moment(startDate).format(costDateFormat),
      endDate: !_.isEmpty(endDate) ? moment(endDate).format(costDateFormat) : null,
      region,
      isPlaceholder,
    };

    this.addCampaignToCampaignPropertiesListToUpdate({ campaignToUpdate: campaign });
  }

  async sendUpdateCampaignsAmountRequestToServer({ startDate, endDate }) {
    if (this.campaignListToUpdate.length > 0) {
      this.isLoadingPlannedVsActualDataFromServer = true;
      this.isBudgetLoadingFromServer = true;
      const body = {
        campaigns: this.campaignListToUpdate,
      };

      try {
        await serverCommunication.serverRequest('PUT', 'cost/campaigns/amounts', JSON.stringify(body), userStore.userMonthPlan?.region);
      } catch (exception) {
        servicesStore.logger.error('failed to send campaigns update costs request', {
          UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
        });
      }
      this.resetCampaignsToUpdate();
      this.resetBudgetData();
      await Promise.all([
        this.getCampaignsAndChannelsCostsBetweenDates({ startDate, endDate }),
        this.getCampaignsAndChannelBudgetsGroupedByMonth({}),
      ]);
      this.isLoadingPlannedVsActualDataFromServer = false;
      this.isBudgetLoadingFromServer = false;
    }
  }

  async sendUpdateCampaignsPropertiesRequestToServer({
    startDate = moment().startOf('month'), endDate = moment().endOf('month'),
  }) {
    if (this.campaignPropertiesListToUpdate.length > 0) {
      this.isLoadingPlannedVsActualDataFromServer = true;
      this.isBudgetLoadingFromServer = true;
      const body = {
        campaigns: this.campaignPropertiesListToUpdate,
      };
      try {
        await serverCommunication.serverRequest('PUT', 'cost/campaigns/properties', JSON.stringify(body), userStore.userMonthPlan?.region);
      } catch (exception) {
        this.isLoadingPlannedVsActualDataFromServer = false;
        this.isBudgetLoadingFromServer = false;
        servicesStore.logger.error('failed to send campaigns update costs request', {
          UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
        });
      }
      this.resetCampaignsPropertiesListToUpdate();
      await Promise.all([
        this.getCampaignsAndChannelsCostsBetweenDates({ startDate, endDate }),
        this.getCampaignsAndChannelBudgetsGroupedByMonth({}),
      ]);
      this.isLoadingPlannedVsActualDataFromServer = false;
      this.isBudgetLoadingFromServer = false;
    }
  }

  async getCampaignsAndChannelsCostsBetweenDates({
    startDate = moment().startOf('month'), endDate = moment().endOf('month'), region = userStore.userMonthPlan?.region,
  }) {
    const body = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
    try {
      this.isLoadingPlannedVsActualDataFromServer = true;
      let response = await serverCommunication.serverRequest('POST', 'planVsActual/costs', JSON.stringify(body), region);
      response = await response.json();
      const { parsedChannels, parsedCampaigns } = parsCampaignsCostsForPlanVsActual({
        campaignsCostsResponse: response,
        startDate,
        endDate,
      });
      this.channelsCostsData = parsedChannels;
      this.originalChannelsCostsData = parsedChannels;
      this.campaignsCostsData = parsedCampaigns;
      this.originalCampaignsCostsData = parsedCampaigns;

      if (this.selectedCampaignTagsArray.length > 0) {
        const dataAccordingCampaignTags = getChannelsAndCampaignsCostsAccordingSelectedTags({
          campaignsData: parsedCampaigns, startDate, endDate, selectedCampaignTags: this.selectedCampaignTagsArray,
        });
        this.channelsCostsData = dataAccordingCampaignTags.parsedChannels;
        this.campaignsCostsData = dataAccordingCampaignTags.parsedCampaigns;
      }

      this.startDate = startDate;
      this.endDate = endDate;
      this.isLoadingPlannedVsActualDataFromServer = false;
    } catch (exception) {
      this.isLoadingPlannedVsActualDataFromServer = false;
      servicesStore.logger.error('failed to get campaigns and channels costs between dates', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
    }
  }

  async getCampaignsAndChannelBudgetsGroupedByMonth({ region = userStore.userMonthPlan?.region }) {
    try {
      this.isBudgetLoadingFromServer = true;
      let response = await serverCommunication.serverRequest('GET', 'planVsActual/budgets', null, region);
      response = await response.json();

      this.setCampaignsAndChannelBudgetsGroupedByMonthResponse({ serverResponse: response, region });

      this.isBudgetLoadingFromServer = false;
    } catch (exception) {
      this.isBudgetLoadingFromServer = false;
      servicesStore.logger.error('failed to get campaigns and channels monthly budgets', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
    }
  }

  async getCampaignsAndChannelBudgetsGroupedByMonthMultipleRegions({ regions }) {
    this.resetBudgetData();
    try {
      this.isBudgetLoadingFromServer = true;
      const tasks = [];
      for (const region of regions) {
        tasks.push(serverCommunication.serverRequest('GET', 'planVsActual/budgets', null, region));
      }
      const allResponse = await Promise.all(tasks);

      for (const [index, response] of allResponse.entries()) {
        const region = regions[index];
        const responseAsJson = await response.json();

        this.setCampaignsAndChannelBudgetsGroupedByMonthResponse({ serverResponse: responseAsJson, region });
      }

      this.isBudgetLoadingFromServer = false;
    } catch (exception) {
      this.isBudgetLoadingFromServer = false;
      servicesStore.logger.error('failed to get campaigns and channels monthly budgets for all regions', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
    }
  }

  setCampaignsAndChannelBudgetsGroupedByMonthResponse({ serverResponse, region }) {
    const { parsedChannelsBudgets, parsedCampaignsBudgets, campaignsDateDetails } = parseChannelsAndCampaignsBudgetsForAnnualTab({ campaignsBudgetsResponse: serverResponse });

    this.originalParsedChannelsBudgets.push(...parsedChannelsBudgets);
    this.originalParsedCampaignsBudgets[region] = parsedCampaignsBudgets[region];
    this.channelsMonthlyBudgets = [...this.channelsMonthlyBudgets, ...parsedChannelsBudgets];
    this.campaignsMonthlyBudgets[region] = parsedCampaignsBudgets[region];
    this.campaignsDateDetails = campaignsDateDetails;

    if (this.selectedCampaignTagsArray.length > 0) {
      const filteredDataAccordingCampaignTags = getChannelsAndCampaignsBudgetsAccordingSelectedTags({
        campaignsBudgets: this.originalParsedCampaignsBudgets,
        selectedCampaignTags: this.selectedCampaignTagsArray,
        campaignsDateDetails,
      });
      this.channelsMonthlyBudgets = filteredDataAccordingCampaignTags.parsedChannelsBudgets;
      this.campaignsMonthlyBudgets[region] = filteredDataAccordingCampaignTags.parsedCampaignsBudgets[region];
    }
  }

  resetBudgetData() {
    this.originalParsedChannelsBudgets = [];
    this.originalParsedCampaignsBudgets = {};
    this.channelsMonthlyBudgets = [];
    this.campaignsMonthlyBudgets = {};
  }

  async getCampaignProperties({
    id, source, startDate, endDate, region = userStore.userMonthPlan?.region,
  }) {
    this.isLoadingPlannedVsActualDataFromServer = true;
    this.isBudgetLoadingFromServer = true;
    const body = {
      campaignId: id,
      source,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };

    try {
      const response = await serverCommunication.serverRequest('POST', 'cost/campaigns/properties', JSON.stringify(body), region);
      const responseJson = await response.json();
      this.isLoadingPlannedVsActualDataFromServer = false;
      this.isBudgetLoadingFromServer = false;
      return responseJson;
    } catch (exception) {
      this.isLoadingPlannedVsActualDataFromServer = false;
      this.isBudgetLoadingFromServer = false;
      servicesStore.logger.error('failed to get campaigns properties', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region, campaignId: id,
      });
      return null;
    }
  }

  addChannel({ channel, channelCategory, region }) {
    this.channelsCostsData.push({
      region,
      channel,
      category: channelCategory,
      children: [],
      isAutomatic: false,
      spending: {
        actual: 0,
        planned: 0,
        planVsActual: 0,
        pacing: 0,
        pacingVsPlanned: 0,
        isEstimated: true,
      },
    });
  }

  setChannelsMonthlyBudgets({ channelsMonthlyBudgets }) {
    this.channelsMonthlyBudgets = channelsMonthlyBudgets;
  }

  async updateChannelsSchema({ body }) {
    return serverCommunication.serverRequest('PUT', 'usersConfiguration/channel', JSON.stringify(body));
  }

  async getChannelBudgetAndCost({ body }) {
    try {
      const response = await serverCommunication.serverRequest('POST', 'cost/channel', JSON.stringify(body));
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      servicesStore.logger.error('Failed to get cost & budget of a channel', {
        error,
        UID: userStore.userMonthPlan.UID,
      });
      return null;
    }
  }

  async deleteChannel({ body }) {
    try {
      await serverCommunication.serverRequest('DELETE', 'usersConfiguration/channel', JSON.stringify(body));
      await userStore.getAttributionMappingRules();
    } catch (error) {
      servicesStore.logger.error('failed to delete a channel', {
        error,
        UID: userStore.userMonthPlan.UID,
      });
    }
  }

  async getAllCampaignTags() {
    let response = await serverCommunication.serverRequest('GET', 'campaignTags', null, userStore.userMonthPlan?.region);
    response = await response.json();
    this.campaignTagsArray = response;
  }

  updateSelectedCampaignTags({ campaignTag }) {
    this.selectedCampaignTagsArray.push(campaignTag);

    const filteredDataAccordingCampaignTags = getChannelsAndCampaignsBudgetsAccordingSelectedTags({
      campaignsBudgets: this.originalParsedCampaignsBudgets,
      selectedCampaignTags: this.selectedCampaignTagsArray,
      campaignsDateDetails: this.campaignsDateDetails,
    });
    this.channelsMonthlyBudgets = filteredDataAccordingCampaignTags.parsedChannelsBudgets;
    this.campaignsMonthlyBudgets = filteredDataAccordingCampaignTags.parsedCampaignsBudgets;

    const dataAccordingCampaignTags = getChannelsAndCampaignsCostsAccordingSelectedTags({
      campaignsData: this.originalCampaignsCostsData,
      startDate: this.startDate,
      endDate: this.endDate,
      selectedCampaignTags: this.selectedCampaignTagsArray,
    });
    this.channelsCostsData = dataAccordingCampaignTags.parsedChannels;
    this.campaignsCostsData = dataAccordingCampaignTags.parsedCampaigns;
  }

  removeFromSelectedCampaignTags({ campaignTag }) {
    const selectedCampaignIndex = this.selectedCampaignTagsArray.findIndex((selectedCampaign) => selectedCampaign.value === campaignTag.value);
    this.selectedCampaignTagsArray.splice(selectedCampaignIndex, 1);

    if (this.selectedCampaignTagsArray.length > 0) {
      const filteredDataAccordingCampaignTags = getChannelsAndCampaignsBudgetsAccordingSelectedTags({
        campaignsBudgets: this.originalParsedCampaignsBudgets,
        selectedCampaignTags: this.selectedCampaignTagsArray,
        campaignsDateDetails: this.campaignsDateDetails,
      });
      this.channelsMonthlyBudgets = filteredDataAccordingCampaignTags.parsedChannelsBudgets;
      this.campaignsMonthlyBudgets = filteredDataAccordingCampaignTags.parsedCampaignsBudgets;

      const dataAccordingCampaignTags = getChannelsAndCampaignsCostsAccordingSelectedTags({
        campaignsData: this.originalCampaignsCostsData,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedCampaignTags: this.selectedCampaignTagsArray,
      });
      this.channelsCostsData = dataAccordingCampaignTags.parsedChannels;
      this.campaignsCostsData = dataAccordingCampaignTags.parsedCampaigns;
    } else {
      this.channelsMonthlyBudgets = this.originalParsedChannelsBudgets;
      this.campaignsMonthlyBudgets = this.originalParsedCampaignsBudgets;

      this.channelsCostsData = this.originalChannelsCostsData;
      this.campaignsCostsData = this.originalCampaignsCostsData;
    }
  }

  get selectedCampaignTags() {
    return this.selectedCampaignTagsArray;
  }

  get campaignTags() {
    return this.campaignTagsArray;
  }

  async updateCampaignTag({ campaignTagProperties }) {
    try {
      const updatedArray = [...this.campaignTagsArray];
      let response = await serverCommunication.serverRequest('PUT', 'campaignTags', JSON.stringify({ tag: campaignTagProperties }), userStore.userMonthPlan?.region);
      response = await response.json();
      const tagIndex = updatedArray.findIndex((tag) => tag.id === campaignTagProperties.id);
      const selectedTagIndex = this.selectedCampaignTagsArray.findIndex((tag) => tag.value === campaignTagProperties.id);
      const campaignTag = {
        label: response.name,
        value: response.id,
      };
      if (tagIndex === -1) {
        updatedArray.push(response);
      } else {
        updatedArray[tagIndex] = response;
      }
      if (selectedTagIndex === -1) {
        this.updateSelectedCampaignTags({ campaignTag });
      }
      this.campaignTagsArray = updatedArray;
    } catch (exception) {
      servicesStore.logger.error('failed to update campaign tag server request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        tagId: campaignTagProperties.id,
        tagName: campaignTagProperties.name,
      });
    }
  }

  async removeCampaignTag({ tagId }) {
    try {
      const tagIndex = this.campaignTagsArray.findIndex((tag) => tag.id === tagId);
      this.campaignTagsArray.splice(tagIndex, 1);
      await serverCommunication.serverRequest('DELETE', 'campaignTags', JSON.stringify({ tagId }), userStore.userMonthPlan?.region);
    } catch (exception) {
      servicesStore.logger.error('failed to delete campaign tag server request', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        tagId,
      });
    }
  }
}

decorate(PlanStore, {
  generalCampaignName: observable,
  channelsCostsData: observable,
  campaignsCostsData: observable,
  campaignsMonthlyBudgets: observable,
  channelsMonthlyBudgets: observable,
  budgetStartYear: observable,
  budgetEndYear: observable,
  isLoadingPlannedVsActualDataFromServer: observable,
  isBudgetLoadingFromServer: observable,
  startDate: observable,
  endDate: observable,
  campaignTagsArray: observable,
  campaignTags: computed,
  selectedCampaignTagsArray: observable,
  selectedCampaignTags: computed,
  addChannel: action.bound,
  updateCampaignAmount: action.bound,
  sendUpdateCampaignsAmountRequestToServer: action.bound,
  sendUpdateCampaignsPropertiesRequestToServer: action.bound,
  updateCampaignProperties: action.bound,
  getCampaignProperties: action.bound,
  getCampaignsAndChannelsCostsBetweenDates: action.bound,
  getCampaignsAndChannelBudgetsGroupedByMonth: action.bound,
  getCampaignsAndChannelBudgetsGroupedByMonthMultipleRegions: action.bound,
  resetCampaignsToUpdate: action.bound,
  setChannelsMonthlyBudgets: action.bound,
  getAllCampaignTags: action.bound,
  updateSelectedCampaignTags: action.bound,
  removeFromSelectedCampaignTags: action.bound,
  updateCampaignTag: action.bound,
  removeCampaignTag: action.bound,
});

export default new PlanStore();
