import React from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@infinigrow/libs';

import Popup from 'components/Popup';
import Component from 'components/Component';

import filterStyles from 'styles/users/filters.css';

class SaveFiltersButton extends Component {
  style = filterStyles;

  static defaultProps = {
    onClick: () => Promise.resolve(),
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      filterName: '',
      isDisabled: false,
    };
  }

  open = () => this.setState({ visible: true });

  close = () => this.setState({ visible: false, filterName: '' });

  saveFilter = () => {
    const { onClick, activeFilters } = this.props;
    const { filterName } = this.state;
    if (!isEmpty(filterName)) {
      onClick(activeFilters, filterName).then(this.close);
    }
  };

  onEnterFilterName = ({ target: { value } }) => {
    const isFilterFound = this.props.savedFilters.filter((item) => item.alias.trim() === value.trim());
    const isDisabled = isFilterFound.length > 0 || value === '';
    this.setState({
      filterName: value,
      isDisabled,
    });
  };

  render() {
    const { popupProps } = this.props;
    const { filterName, isDisabled } = this.state;
    return (
      <div className={classnames(this.classes.filterPlainLabel)}>
        <div onClick={this.open}>
          Save Filters
        </div>
        <Popup
          {...popupProps}
          onClose={this.close}
          className={classnames(this.classes.popupWrapper, this.classes.popupContainer)}
          hidden={!this.state.visible}
        >
          <div className={this.classes.popupContent}>
            <div className={this.classes.popupText}>
              Name your filter selection:
            </div>
            <label className={this.classes.inputContainer}>
              <input
                className={this.classes.input}
                value={filterName}
                onChange={this.onEnterFilterName}
              />
              <div
                className={this.classes.inputClear}
                onClick={() => this.setState({ filterName: '' })}
              />
            </label>
            <Button
              type="primaryBlue"
              onClick={() => this.saveFilter()}
              className={this.classes.popupButton}
              style={this.props.style}
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
        </Popup>
      </div>
    );
  }
}

export default SaveFiltersButton;
