import userStore from 'stores/userStore';

const typeSortMapping = {
  funnelStage: 0,
  conversionRate: 1,
  velocity: 2,
  costPerFunnelStage: 3,
  revenue: 4,
  ROI: 5,
  webVisits: 6,
  pageViews: 7,
};

function sortMetricsInPreset(metricA, metricB) {
  const typeSortValueA = typeSortMapping[metricA.metricType];
  const typeSortValueB = typeSortMapping[metricB.metricType];

  if (typeSortValueA === typeSortValueB) {
    return metricA.orderInGroup - metricB.orderInGroup;
  }

  return typeSortValueA - typeSortValueB;
}

const presetsProps = [
  {
    label: 'Funnel',
    filterMethod: (metric) => {
      const relevantTypes = new Set([
        'funnelStage',
        'conversionRate',
        'velocity',
      ]);
      const { enabled, metricType } = metric;
      return enabled && relevantTypes.has(metricType);
    },
  },
  {
    label: 'Financials',
    filterMethod: (metric) => {
      const relevantTypes = new Set([
        'revenue',
        'ROI',
      ]);
      const { enabled, metricType } = metric;
      return enabled && relevantTypes.has(metricType);
    },
  },
  {
    label: 'Conversion Rates',
    filterMethod: (metric) => {
      const relevantTypes = new Set([
        'conversionRate',
      ]);
      const { metricType } = metric;
      return relevantTypes.has(metricType);
    },
    sortMethod: (metricA, metricB) => metricA.orderInGroup - metricB.orderInGroup,
  },
  {
    label: 'Velocities',
    filterMethod: (metric) => {
      const relevantTypes = new Set([
        'velocity',
      ]);
      const { metricType } = metric;
      return relevantTypes.has(metricType);
    },
  },
  {
    label: 'Cost-Efficiency',
    filterMethod: (metric) => {
      const relevantTypes = new Set([
        'costPerFunnelStage',
        'ROI',
      ]);
      const { metricType } = metric;
      return relevantTypes.has(metricType);
    },
  },
  {
    label: 'All Metrics',
    filterMethod: ({ metricName }) => {
      const { isPipelineStageHidden } = userStore.userMonthPlan || {};
      if (isPipelineStageHidden && metricName.toLowerCase().includes('pipeline')) {
        return false;
      }
      return true;
    },
  },
];

export function getPresets({ userMetrics }) {
  const presets = presetsProps.map((presetProps) => {
    const { label, filterMethod } = presetProps;

    const filteredOptions = userMetrics.filter(filterMethod);

    filteredOptions.sort(sortMetricsInPreset);

    return {
      label,
      options: filteredOptions.map(({ metricName }) => metricName),
    };
  });

  return presets.filter(({ options }) => options.length > 0);
}

export function metricExceptionFilter({ metricData }) {
  const exceptionMetrics = new Set([
    'CAC',
    'webVisits',
    'pageViews',
  ]);

  const {
    metricName,
    relevantFor,
  } = metricData;

  if (exceptionMetrics.has(metricName) || exceptionMetrics.has(relevantFor)) {
    return false;
  }

  return true;
}
