exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".upload-offline-popup__content-aVP{margin:0;padding:40px 38px}.upload-offline-popup__inner-3Fh{overflow:initial}.upload-offline-popup__title-1LF{font-size:22px;font-weight:700;color:#646973;text-align:center;margin-bottom:20px}.upload-offline-popup__center-1RZ{display:flex;justify-content:center}.upload-offline-popup__file-name-2xd{margin-top:10px;margin-bottom:20px}.upload-offline-popup__row-wKQ{display:flex;align-items:center;margin-top:7px}.upload-offline-popup__field-kid{width:160px;font-size:14px;font-weight:700;color:#646973}.upload-offline-popup__buttons--87{display:flex;margin-top:70px}", ""]);

// exports
exports.locals = {
	"content": "upload-offline-popup__content-aVP",
	"content": "upload-offline-popup__content-aVP",
	"inner": "upload-offline-popup__inner-3Fh",
	"inner": "upload-offline-popup__inner-3Fh",
	"title": "upload-offline-popup__title-1LF",
	"title": "upload-offline-popup__title-1LF",
	"center": "upload-offline-popup__center-1RZ",
	"center": "upload-offline-popup__center-1RZ",
	"file-name": "upload-offline-popup__file-name-2xd",
	"fileName": "upload-offline-popup__file-name-2xd",
	"row": "upload-offline-popup__row-wKQ",
	"row": "upload-offline-popup__row-wKQ",
	"field": "upload-offline-popup__field-kid",
	"field": "upload-offline-popup__field-kid",
	"buttons": "upload-offline-popup__buttons--87",
	"buttons": "upload-offline-popup__buttons--87"
};