import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty, orderBy } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { userConfigurationTypes } from 'stores/logic/enums';
import { configurationTypeProperties } from 'components/pages/settings/workflows/enums';
import FeatureFlags from 'components/common/FeatureFlags';
import ConfigStats from 'components/common/ConfigStats';
import Loader from 'components/controls/Loader';
import CompareEnrichedMetrics from 'components/pages/settings/workflows/CompareEnrichedMetrics';

import workflowsStyle from 'styles/settings/workflows/workflows.css';
import generalAppStyle from 'styles/app.css';

const Workflows = ({ workflows, requestUserWorkflows, flags }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userWorkflows, setUserWorkflows] = useState([]);
  const [libraryWorkflows, setLibraryWorkflows] = useState([]);

  useEffect(() => {
    workflowsStyle.use();
    generalAppStyle.use();
    if (isEmpty(workflows)) {
      requestUserWorkflows();
    } else {
      const active = [];
      const library = [];
      for (const [workflowType, workflow] of Object.entries(workflows)) {
        if (workflow.isActive) {
          active.push({
            workflowType,
            ...workflow,
          });
          continue;
        }
        library.push({
          workflowType,
          ...workflow,
        });
      }
      setUserWorkflows(active);
      setLibraryWorkflows(library);
      setIsLoading(false);
    }

    return () => {
      workflowsStyle.unuse();
      generalAppStyle.unuse();
    };
  }, [workflows]);

  if (isLoading) {
    return (
      <div className={generalAppStyle.locals.loader}>
        <Loader newStyle />
      </div>
    );
  }

  const filteredUserWorkflows = userWorkflows.filter((workflow) => configurationTypeProperties[workflow.workflowType]).map((workflow) => ({
    ...workflow,
    order: configurationTypeProperties[workflow.workflowType].order,
  }));
  const orderedUserWorkflows = orderBy(filteredUserWorkflows, ['order'], ['asc']);

  return (
    <div className={workflowsStyle.locals.pageWrap}>
      <FeatureFlags flag={flags.opsEnrichedValueVsCrmValuesDemoOnly}>
        <CompareEnrichedMetrics />
      </FeatureFlags>

      <div className={workflowsStyle.locals.title}>
        Active workflows
      </div>
      <div className={workflowsStyle.locals.workflowsWrap}>
        {orderedUserWorkflows.map((userWorkflow) => (
          <FeatureFlags flag={flags[configurationTypeProperties[userWorkflow.workflowType].featureFlag]}>
            <ConfigStats
              isClickable={userWorkflow.workflowType !== userConfigurationTypes.conversionRate}
              isActive={userWorkflow.isActive}
              type={userWorkflow.workflowType}
              title={configurationTypeProperties[userConfigurationTypes[userWorkflow.workflowType]].title}
              subTitle={configurationTypeProperties[userConfigurationTypes[userWorkflow.workflowType]].subTitle}
              popupSubTitle={configurationTypeProperties[userConfigurationTypes[userWorkflow.workflowType]].popupSubTitle}
              icon={`userConfigs:${configurationTypeProperties[userWorkflow.workflowType].icon}`}
              PopupComponent={configurationTypeProperties[userConfigurationTypes[userWorkflow.workflowType]].popupComponent}
              configuration={userWorkflow.config}
              isShowSwitchButton
              isViewOnly
              withPopupBodyWrapper
            />
          </FeatureFlags>
        ))}
      </div>
      {libraryWorkflows.length > 0 && (
      <div className={workflowsStyle.locals.newSection}>
        <div className={workflowsStyle.locals.title}>
          Additional workflows
        </div>
        <div className={workflowsStyle.locals.workflowsWrap}>
          {libraryWorkflows.filter((workflow) => configurationTypeProperties[workflow.workflowType]).map((libraryWorkflow) => (
            <FeatureFlags flag={flags[configurationTypeProperties[libraryWorkflow.workflowType].featureFlag]}>
              <ConfigStats
                isClickable={false}
                icon={`userConfigs:${configurationTypeProperties[libraryWorkflow.workflowType].icon}`}
                isActive={libraryWorkflow.isActive}
                subTitle={configurationTypeProperties[userConfigurationTypes[libraryWorkflow.workflowType]].subTitle}
                title={configurationTypeProperties[userConfigurationTypes[libraryWorkflow.workflowType]].title}
                PopupComponent={configurationTypeProperties[userConfigurationTypes[libraryWorkflow.workflowType]].popupComponent}
                configuration={libraryWorkflow.config}
                isShowSwitchButton={false}
                isViewOnly
                withPopupBodyWrapper
                isShowLockIcon
              />
            </FeatureFlags>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default withLDConsumer()(inject(({
  userStore: {
    workflows,
    requestUserWorkflows,
  },
}) => ({
  workflows,
  requestUserWorkflows,
}),
observer)(Workflows));
