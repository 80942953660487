import base from './base';

function processResponseFromServer({ result }) {
  if (!result) {
    return [];
  }

  const {
    journeys = [],
    journeyCustomFields = {},
  } = result;

  return journeys.map((journey) => {
    const { identityId } = journey;
    const customFields = journeyCustomFields[identityId];
    return {
      ...journey,
      customFields,
    };
  });
}

export default {
  ...base,
  processResponseFromServer,
};
