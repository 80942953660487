import Component from 'components/Component';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

class PardotPopupContainer extends Component {
  afterAuthStep = (code) => {
    if (!code) {
      return Promise.resolve({});
    }
    return serverCommunication.serverRequest(
      'POST',
      this.props.apiEndpoint,
      code && JSON.stringify({ code }),
      userStore.userMonthPlan.region
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          history.push('/');
          return false;
        } else {
          throw new Error('Error getting Pardot data');
        }
      }).catch((error) => {
        servicesStore.logger.error('Could not verify code from Pardot', { error });
      });
  };

  afterDataRetrieved = () => new Promise((resolve) => {
    resolve(true);
  });

  render() {
    const { children } = this.props;
    return children({
      state: this.state,
      afterAuthStep: this.afterAuthStep,
      afterDataRetrieved: this.afterDataRetrieved,
      makeServerRequest: this.sendPardotBusinessUID,
    });
  }
}

export default PardotPopupContainer;
