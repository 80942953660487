exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".not-sure__box-2Pg{font-size:16px;line-height:16px;font-weight:600;color:#09c;cursor:pointer;align-self:center;text-transform:capitalize;letter-spacing:normal;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"box": "not-sure__box-2Pg",
	"box": "not-sure__box-2Pg"
};