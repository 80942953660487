import React, { useState, useRef, useEffect } from 'react';
import { Checkbox } from '@infinigrow/libs';

import useOnClickOutside from 'components/pages/users/useOnClickOutside';
import MenuButton from 'components/common/MenuButton';
import PlanPopup, { TextContent as PopupTextContent } from 'components/pages/plan/Popup';

import style from 'styles/analyze/trend-analysis.css';

const styles = style.locals;

function TrendAnalysisSelectMetricsPopup({
  performanceListItems,
  spendListItems,
  selectedMetrics,
  onSaveSelectedMetrics,
}) {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [activeItems, setActiveItems] = useState({ performance: selectedMetrics.performance, spend: selectedMetrics.spend });

  const refSelectMetricPopupWrapper = useRef();
  const refSelectMetricPopup = useRef();

  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  useEffect(() => {
    resetSelection();
  }, [selectedMetrics]);

  const resetSelection = () => {
    setActiveItems({ performance: selectedMetrics.performance, spend: selectedMetrics.spend });
  };

  const onCancelSelectedMetrics = () => {
    resetSelection();
    closePopup();
  };

  const closePopup = () => {
    refSelectMetricPopup.current.close();
    setIsShowPopup(false);
  };

  useOnClickOutside(refSelectMetricPopupWrapper, () => {
    onCancelSelectedMetrics();
  });

  const onSaveSelectedMetricsForRequest = () => {
    onSaveSelectedMetrics({ performance: activeItems.performance, spend: activeItems.spend });
    closePopup();
  };

  const onClickSelectMetricsButton = () => {
    if (isShowPopup) {
      closePopup();
    } else {
      refSelectMetricPopup.current.open();
      setIsShowPopup(true);
    }
  };

  const onChangeMetrics = ({ metric, key }) => {
    const updateActivePerformanceItems = [...activeItems[key]];
    const metricIndex = updateActivePerformanceItems.indexOf(metric);
    if (metricIndex === -1) {
      updateActivePerformanceItems.push(metric);
    } else {
      updateActivePerformanceItems.splice(metricIndex, 1);
    }
    setActiveItems({ ...activeItems, [key]: updateActivePerformanceItems });
  };

  const getPerformanceMetrics = () => performanceListItems.map((performanceMetric) => {
    const isPerformanceItemChecked = activeItems.performance.includes(performanceMetric.metricName);
    const isSpendInActiveItems = activeItems.spend.length > 0;
    const allowedPerformanceCount = isSpendInActiveItems ? 1 : 2;
    const isLimitPerformance = activeItems.performance.length >= allowedPerformanceCount;
    const isDisabledCheckBox = !isPerformanceItemChecked && isLimitPerformance;
    return (
      <Checkbox
        key={`customCheckbox-${performanceMetric.metricName}`}
        checked={isPerformanceItemChecked}
        onChange={() => onChangeMetrics({ metric: performanceMetric.metricName, key: 'performance' })}
        disabled={isDisabledCheckBox}
      >
        {performanceMetric.metricLabel}
      </Checkbox>
    );
  });

  const getSpendMetrics = () => spendListItems.map((spendMetric) => {
    const isCheckedSpendItem = activeItems.spend.includes(spendMetric.metricName);
    const isTotalSelected = spendMetric.metricName === 'total' && activeItems.spend.length > 0;
    const disabledAllExceptTotal = activeItems.spend.includes('total');
    const isLimitPerformance = activeItems.performance.length > 1;
    const isDisabledCheckBox = !isCheckedSpendItem && (isLimitPerformance || isTotalSelected || disabledAllExceptTotal);
    return (
      <Checkbox
        key={`customCheckbox-${spendMetric.metricName}`}
        checked={isCheckedSpendItem}
        onChange={() => onChangeMetrics({ metric: spendMetric.metricName, key: 'spend' })}
        disabled={isDisabledCheckBox}
      >
        {spendMetric.metricLabel}
      </Checkbox>
    );
  });

  return (
    <div ref={refSelectMetricPopupWrapper} className={styles.selectMetricsWrapper}>
      <MenuButton
        title="Select metrics"
        onClick={onClickSelectMetricsButton}
        isOpen={isShowPopup}
      />
      <PlanPopup
        ref={refSelectMetricPopup}
        title="Select metrics"
        className={styles.selectMetricsPopup}
        onClose={closePopup}
        primaryButton={{
          text: 'Save',
          onClick: onSaveSelectedMetricsForRequest,
        }}
        secondaryButton={{
          text: 'Cancel',
          onClick: onCancelSelectedMetrics,
        }}
      >
        <PopupTextContent>
          <div className={styles.selectMetricsContainer}>
            <div className={styles.selectMetricsColumn}>
              <div className={styles.selectMetricsColumnTitle}>
                Performance
              </div>
              <div className={styles.selectMetricsColumnContent}>
                {getPerformanceMetrics()}
              </div>
            </div>
            <div className={styles.selectMetricsColumn}>
              <div className={styles.selectMetricsColumnTitle}>
                Spend
              </div>
              <div className={styles.selectMetricsColumnContent}>
                {getSpendMetrics()}
              </div>
            </div>
          </div>
        </PopupTextContent>
      </PlanPopup>
    </div>
  );
}

export default TrendAnalysisSelectMetricsPopup;
