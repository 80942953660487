import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

const ContainsSelect = (props) => {
  const [value, setValue] = useState(props.containsValue ? [...props.containsValue] : []);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(props.containsValue ? [...props.containsValue] : []);
  }, [props.containsValue]);

  const handleChange = (enteredValue = [], actionMeta) => {
    const actionIsRemoveOrClear = actionMeta.action === 'remove-value' || actionMeta.action === 'clear';
    if (actionIsRemoveOrClear) {
      const newValue = enteredValue !== null ? enteredValue : [];
      props.containsValueHandler([...newValue]);
      setValue(newValue);
    }
  };

  const handleInputChange = (typeValue) => {
    setInputValue(typeValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        setValue([...value, createOption(inputValue)]);
        props.containsValueHandler([...value, createOption(inputValue)]);
        event.preventDefault();
    }
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      border: 'solid 1px rgb(178, 187, 213, 0.5)',
      boxShadow: '0px 0px 5px 0 rgb(0 0 0 / 10%)',
    }),

    control: (provided) => ({
      ...provided,
      width: props.controlWidth ? props.controlWidth : 445,
      paddingRight: '3px',
      background: 'white',
      border: '1px solid rgb(206, 208, 218)',
      color: 'rgb(83, 91, 105)',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#4D91FC',
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#99a4c2',
    }),

    multiValueLabel: (base) => ({
      ...base,
      backgroundColor: '#eef4ff',
      color: '#4D91FC',
      fontSize: '14px',
      border: 'none',
    }),

    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: '#eef4ff',
      color: '#4D91FC',
      cursor: 'pointer',
    }),
  };

  const DropdownIndicator = {
    DropdownIndicator: null,
  };

  return (
    <CreatableSelect
      components={DropdownIndicator}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={value}
      styles={customStyles}
    />
  );
};

export default ContainsSelect;
