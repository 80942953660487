import classNames from 'classnames';
import React from 'react';

import useStyles from 'hooks/useStyles';

import style from 'styles/analyze/attributionTable/inlineColumnFilters.css';

const styles = style.locals || {};

function SortByColumn({
  isNumeric, setSortByColumn, columnKey, isSortActive, isSortArrowUp,
}) {
  useStyles([style]);

  return (
    <div className={styles.sortBy} data-testid="sort-by">
      <div
        className={classNames(styles.sortByAToZ, (isSortActive && !isSortArrowUp) && styles.sortByActive)}
        onClick={() => setSortByColumn({ id: columnKey, desc: !!isNumeric })}
        data-testid="sort-by-a-to-z"
      >
        {'Sort '}
        {isNumeric ? '100' : 'A'}
        <div className={styles.sortByArrow} />
        {isNumeric ? '1' : 'Z'}
      </div>
      <div
        className={classNames(styles.sortByZToA, (isSortActive && isSortArrowUp) && styles.sortByActive)}
        onClick={() => setSortByColumn({ id: columnKey, desc: !isNumeric })}
        data-testid="sort-by-z-to-a"
      >
        {'Sort '}
        {isNumeric ? '1' : 'Z'}
        <div className={styles.sortByArrow} />
        {isNumeric ? '100' : 'A'}
      </div>
    </div>
  );
}

export default SortByColumn;
