exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--blue:#4d91fc;--pacificBlue:#09c;--lightBlue:#e4eeff;--white:#fff;--black:#222a41;--red:#e43a54;--grayText:#707ea7;--grayLine:#dce1ef;--lightGrayBackground:#f6f7fb;--lightBlueBackground:#e1edff}.app__wrap-2w4{padding-top:140px}.app__no-sub-warp-2cd{padding-top:80px}.app__wrap-2w4[data-loading]{filter:blur(8px)}.app__loader-XUn{background-color:rgba(24,32,51,.6);bottom:0;left:0;position:fixed;right:0;top:0;z-index:9999}::placeholder{color:var(--grayText);opacity:.5}", ""]);

// exports
exports.locals = {
	"wrap": "app__wrap-2w4",
	"wrap": "app__wrap-2w4",
	"no-sub-warp": "app__no-sub-warp-2cd",
	"noSubWarp": "app__no-sub-warp-2cd",
	"loader": "app__loader-XUn",
	"loader": "app__loader-XUn"
};