function equalValues({ amount, array }) {
  const len = array.length;
  const fraction = Math.floor(amount / len);
  const remainder = amount % len;
  return array.map((_, index) => (index === 0 ? fraction + remainder : fraction));
}

export function getMilestonesWithEqualAmounts({ milestones, amount }) {
  const milestoneAmounts = equalValues({ amount, array: milestones });
  return milestones.map((milestone, i) => ({
    ...milestone,
    targetValue: milestoneAmounts[i],
  }));
}
