import config from 'components/utils/Configuration';

export function isEmailToTrack({ email }) {
  if (!email) {
    return false;
  }

  const emailSplit = email.split('@');
  const emailDomain = emailSplit[emailSplit.length - 1].toLowerCase();

  const shouldFilterDomain = config.eventTracking.domainsToFilter.includes(emailDomain);
  return !shouldFilterDomain;
}
