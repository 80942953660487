import { groupBy, cloneDeep, isEmpty } from 'lodash';
import { makeFilter } from '../../../utils/filters';

export function flattenDuplicateMergingConfigurationsByObject(mergingConfigurations) {
  const updateMergingConfigurations = [];
  const mergingConfigurationsByObjectName = groupBy(cloneDeep(mergingConfigurations), 'objectName');

  for (const mergingConfig of Object.values(mergingConfigurationsByObjectName)) {
    if (mergingConfig.length > 1) {
      const updatedMergingConfig = mergingConfig[0];
      const fieldsToMerge = mergingConfig.map((item) => item.fieldsToMerge);
      updatedMergingConfig.fieldsToMerge = fieldsToMerge.flat();
      updateMergingConfigurations.push(updatedMergingConfig);
    } else {
      updateMergingConfigurations.push(...mergingConfig);
    }
  }

  return updateMergingConfigurations;
}

export function getParsedPredefinedFiltersConfig({ predefinedFiltersConfig = {}, filterConfigs = {} }) {
  const parsedFiltersConfig = {};
  for (const [funnelStageName, predefinedConfig] of Object.entries(predefinedFiltersConfig)) {
    parsedFiltersConfig[funnelStageName] = {
      filters: predefinedConfig.filters?.map((filter) => (filter.id ? filter : makeFilter(filter, filterConfigs))),
      logicExpression: predefinedConfig.logicExpression,
    };
  }
  return parsedFiltersConfig;
}

export function fixPredefineFiltersWithOriginalCustomFieldId({ predefinedFiltersConfig = {}, oldCustomFieldIdToMergedId = {} }) {
  for (const funnelPredefineFiltersData of Object.values(predefinedFiltersConfig)) {
    for (const filter of funnelPredefineFiltersData.filters) {
      const filterCustomFieldId = filter.data?.fieldId;
      const isHavingNewFieldId = Object.keys(oldCustomFieldIdToMergedId).includes(filterCustomFieldId);
      if (isHavingNewFieldId) {
        filter.data.fieldId = oldCustomFieldIdToMergedId[filterCustomFieldId];
      }
    }
  }
}

export function fixPredefinedFiltersAccordingToFunnelChanges({ predefinedFiltersConfig, funnelsMappingIdToFunnelKey, initialFunnelsMappingIdToFunnelKey }) {
  if (isEmpty(funnelsMappingIdToFunnelKey)) {
    return predefinedFiltersConfig;
  }

  const updatedPredefinedFilters = {};
  for (const [funnelStageId, funnelName] of Object.entries(funnelsMappingIdToFunnelKey)) {
    const oldFunnelName = initialFunnelsMappingIdToFunnelKey[funnelStageId];
    if (!predefinedFiltersConfig[oldFunnelName]) {
      continue;
    }
    updatedPredefinedFilters[funnelName] = predefinedFiltersConfig[oldFunnelName];
  }

  return updatedPredefinedFilters;
}
