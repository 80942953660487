import { isEmpty, set } from 'lodash';

export const calculateAutomaticIntegration = (data) => {
  const isTwitterAdsLoading = !!(data.twitteradsapi
    && data.twitteradsapi.oauthAccessToken
    && data.twitteradsapi.oauthAccessTokenSecret
    && data.twitteradsapi.accountId);
  const isTwitterAdsAuto = isTwitterAdsLoading && data.twitteradsapi.lastUpdateTime;

  const isLinkedinAdsLoading = !!(data.linkedinadsapi && data.linkedinadsapi.tokens && data.linkedinadsapi.accountId);
  const isLinkedinAdsAuto = isLinkedinAdsLoading && data.linkedinadsapi.lastUpdateTime;
  const isLinkedinAdsExpired = isLinkedinAdsAuto && data.linkedinadsapi.tokenExpired;

  const isQuoraAdsLoading = !!(data.quoraadsapi && data.quoraadsapi.tokens && data.quoraadsapi.accountId);
  const isQuoraAdsAuto = isQuoraAdsLoading && data.quoraadsapi.lastUpdateTime;

  const isBingAdsLoading = !!(data.bingadsapi && data.bingadsapi.tokens && data.bingadsapi.accountId);
  const isBingAdsAuto = isBingAdsLoading && data.bingadsapi.lastUpdateTime;
  const isBingAdsExpired = isBingAdsAuto && data.bingadsapi.tokenExpired;

  const isFacebookAdsLoading = !!(data.facebookadsapi && data.facebookadsapi.accountId && data.facebookadsapi.tokens);
  const isFacebookAdsAuto = isFacebookAdsLoading && data.facebookadsapi.lastUpdateTime;
  const isFacebookAdsExpired = isFacebookAdsAuto && data.facebookadsapi.tokenExpired;

  const isAdwordsLoading = !!(data.adwordsapi && data.adwordsapi.tokens && data.adwordsapi.customerId);
  const isAdwordsAuto = isAdwordsLoading && data.adwordsapi.lastUpdateTime;
  const isAdwordsExpired = isAdwordsAuto && data.adwordsapi.tokenExpired;

  const isSalesforceCampaignsLoading = !!(data.salesforceapi && data.salesforceapi.isCampaignsIntegrationRunning);
  const isSalesforceTasksLoading = !!(data.salesforceapi && data.salesforceapi.isTasksIntegrationRunning);
  const isSalesforceTasksAuto = !isSalesforceTasksLoading && data.salesforceapi && data.salesforceapi.tokens
    && data.salesforceapi.tasksMapping && data.salesforceapi.tasksLastUpdateTime;
  const isSalesforceLoading = !!(data.salesforceapi && data.salesforceapi.isCRMIntegrationRunning);
  const isSalesforceAuto = !isSalesforceLoading && data.salesforceapi && data.salesforceapi.tokens && data.salesforceapi.mapping && data.salesforceapi.lastUpdateTime;

  const isHubspotLoading = !!(data.hubspotapi && data.hubspotapi.isCRMIntegrationRunning);
  const isHubspotAuto = !isHubspotLoading && data.hubspotapi && data.hubspotapi.mapping && data.hubspotapi.tokens && data.hubspotapi.lastUpdateTime;

  const isDriftTaskLoading = !!(data.driftapi?.tokens);
  const isDriftTasksAuto = isDriftTaskLoading;

  const isGoogleAuto = !!(data.googleapi && data.googleapi.tokens && data.googleapi.profileId);
  const isLinkedinAuto = !!(data.linkedinapi && data.linkedinapi.accountId && data.linkedinapi.tokens);
  const isFacebookAuto = !!(data.facebookapi && data.facebookapi.pageId);
  const isTwitterAuto = !!(data.twitterapi && data.twitterapi.accountName);
  const isYoutubeAuto = !!(data.youtubeapi && data.youtubeapi.type && data.youtubeapi.id) || isAdwordsAuto;
  const isGoogleSheetsAuto = !!(data.googlesheetsapi && data.googlesheetsapi.tokens && data.googlesheetsapi.mapping);
  const isMozAuto = !!(data.mozapi && data.mozapi.url);

  return {
    isDriftTaskLoading,
    isDriftTasksAuto,
    isTwitterAdsAuto,
    isTwitterAdsLoading,
    isLinkedinAdsAuto,
    isLinkedinAdsLoading,
    isQuoraAdsAuto,
    isQuoraAdsLoading,
    isFacebookAdsAuto,
    isFacebookAdsLoading,
    isBingAdsAuto,
    isBingAdsLoading,
    isAdwordsAuto,
    isAdwordsLoading,
    isSalesforceCampaignsLoading,
    isSalesforceAuto,
    isSalesforceLoading,
    isMozAuto,
    isHubspotAuto,
    isHubspotLoading,
    isGoogleAuto,
    isLinkedinAuto,
    isFacebookAuto,
    isTwitterAuto,
    isYoutubeAuto,
    isGoogleSheetsAuto,
    isSalesforceTasksAuto,
    isSalesforceTasksLoading,
    isFacebookAdsExpired,
    isLinkedinAdsExpired,
    isBingAdsExpired,
    isAdwordsExpired,
  };
};

const isEmptyCondition = (cond) => !cond.value || !cond.param || !cond.operation;
const isMetaEmpty = (meta) => !meta.useAsCampaign && !meta.useAsTouchPoint;

export const validateConditions = (currentMapping, isTask) => currentMapping
  .reduce((mainAcc, group, groupIndex) => {
    const { rules } = group;
    const groupErrors = rules.reduce((acc, rule, ruleIndex) => {
      const { conditions, metadata } = rule;
      const metadataIsEmpty = !isTask && metadata && isMetaEmpty(metadata);
      if (metadataIsEmpty) {
        set(acc, [ruleIndex, 'metaError'], true);
        acc[ruleIndex].metaError = true;
      }
      conditions.forEach((condition, conditionIndex) => {
        const emptyCondition = isEmptyCondition(condition);
        if (emptyCondition) {
          set(acc, [ruleIndex, conditionIndex], true);
        }
      });
      return acc;
    }, {});

    if (!isEmpty(groupErrors)) {
      mainAcc[groupIndex] = groupErrors;
    }

    return mainAcc;
  }, {});
