import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Button } from '@infinigrow/libs';

import Dropdown from 'components/controls/Dropdown';
import GoalPopup from 'components/pages/settings/goals/popups/GoalPopup';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import { activityStatusValues } from 'components/pages/settings/goals/logic/enums';
import { getPipelineFunnel } from 'components/utils/indicators';

import style from 'styles/insightsPage/goals/emptyStateGoalsAnalysis.css';

const styles = style.locals;

function EmptyStateGoalsAnalysis({
  getMetricOptions,
  volumeMetric,
  updateVolumeMetric,
  onFinishCreateNewGoal,
  onStartCreateNewGoal,
  isLoaded,
  goalsList,
  goalsAnalysisRequestId,
}) {
  const [isShowKPIDropdown, setIsShowKPIDropdown] = useState(!isEmpty(volumeMetric));
  const [showGoalPopup, setShowGoalPopup] = useState(false);

  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  useEffect(() => {
    const isShowKPIDropdownAfterChange = !isEmpty(volumeMetric);
    if (isShowKPIDropdownAfterChange !== isShowKPIDropdown) {
      setIsShowKPIDropdown(isShowKPIDropdownAfterChange);
    }
  }, [volumeMetric]);

  useEffect(() => {
    if (isLoaded && isEmpty(volumeMetric)) {
      updateVolumeMetric({ value: getPipelineFunnel() });
    }
  }, [isLoaded]);

  return (
    <>
      <GoalPopup
        status={activityStatusValues.ALL}
        hidden={!showGoalPopup}
        editMode={false}
        close={() => setShowGoalPopup(false)}
        onFinishCreateNewGoal={onFinishCreateNewGoal}
        onStartCreateNewGoal={onStartCreateNewGoal}
        goalsList={goalsList}
        goalsAnalysisRequestId={goalsAnalysisRequestId}
      />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Goal analysis
        </div>
        <div className={styles.subTitle}>
          Set at least one goal for the selected timeframe to activate Goal Analysis.
        </div>
        <Button
          type="primaryBlue"
          onClick={() => setShowGoalPopup(true)}
        >
          Set Goal
        </Button>
        <div className={styles.subTitle}>
          {'Or '}
          <span
            className={classnames(styles.temporaryKpi, isEmpty(volumeMetric) ? styles.kpiNotSelected : null)}
            onClick={() => (isEmpty(volumeMetric) ? setIsShowKPIDropdown((prev) => !prev) : null)}
          >
            select a temporary KPI
          </span>
        </div>
        {isShowKPIDropdown && (
          <Dropdown
            options={getMetricOptions()}
            onChange={(newMetric) => updateVolumeMetric(newMetric)}
            selectedKey={volumeMetric.value}
            controlWidth={184}
          />
        )}
      </div>
    </>
  );
}

export default WithPersonalSpinner()(inject(
  ({
    attributionStore: {
      getMetricOptions,
    },
  }) => ({
    getMetricOptions,
  }),
  observer
)(EmptyStateGoalsAnalysis));
