import { filterKinds } from '../../../../../utils/filters/logic';

export const getFilterTagTooltip = (selectedOptions, kind, getter, dateFilter) => {
  let tooltipContent = [];
  const isCostCustomFields = kind === filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS;
  if (isCostCustomFields) {
    const flatSelectedOptions = selectedOptions.flat();
    for (const selectedRules of flatSelectedOptions) {
      const items = selectedRules.selectedOptions.map((item) => `<div>${item}</div>`);
      const tooltipRulesRow = `
          <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; padding: 3px 2px">
              ${items.join('')}
          </div>`;
      tooltipContent.push(tooltipRulesRow);
    }
    return tooltipContent.join('');
  }

  tooltipContent = selectedOptions.map((item) => (
    `<div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; padding: 3px 2px">
          ${dateFilter ? dateFilter(getter, item) : item}
      </div>`
  ));

  return tooltipContent.join('');
};
