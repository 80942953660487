import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import attributionStore from 'stores/attributionStore';
import timeFrameModule from 'modules/timeframe';
import userStore from 'stores/userStore';

import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { getFilteredImpactBySegmentRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { parseWidgetFiltersToFiltersConfig } from 'components/pages/reports/logic/reports';
import { convertShowOnlyFilters } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

function ImpactBySegmentWrapper({
  conversionIndicator,
  impactBySegmentParams = {},
  impactBySegment,
  widgetConfig,
  widgetHeaderConfig,
  selectedReport,
  updateWidgetImpactByCRMSegment,
  updateWidgetImpactByCRMIndicator,
  updateWidgetImpactByCRMFrequency,
  updateShouldUseRelativeTimeframe,
  trendBySegments,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  hideNoValuesRows,
  kpiFocus,
  updateWidgetFilters,
  userFunnels,
  accountOptimizationMetrics,
  filtersData,
  showNoValueCheckbox,
  showSegmentDropdown,
  hideOtherValuesRows,
  sortByColumn,
  toggleViewType,
  getWidgetRequestId,
  widgetHeaderProps,
  checkedSegments = [],
  uncheckedSegments = [],
  isSelectAll,
  isCalculateAdvancedMetrics = attributionStore.isCalculateAdvancedMetrics,
  updateWidgetConfig,
  isShowWidgetHeader,
  isEnableSkeletonAnimation = true,
  updateWidgetPropsValues,
  isLoaded,
  emptyStateComponent,
}) {
  const [hideNoValuesRowsState, setHideNoValuesRowsState] = useState(hideNoValuesRows);
  const [drilldownMetric, setDrilldownMetric] = useState(null);
  const [checkedSegmentsState, setCheckedSegmentsState] = useState(checkedSegments);
  const [uncheckedSegmentsState, setUncheckedSegmentsState] = useState(uncheckedSegments);

  function updateCheckedSegments({ segments }) {
    setCheckedSegmentsState(segments);
    if (updateWidgetPropsValues) {
      updateWidgetPropsValues({ values: { checkedSegments: segments } });
      return;
    }

    if (!widgetHeaderConfig.isReadOnly && !isSelectAll) {
      updateWidgetConfig({
        configKey: 'checkedSegments', configValue: segments, reportId, widgetId, shouldPullDataFromServer: false,
      });
    }
  }

  function updateUncheckedSegments({ segments }) {
    setUncheckedSegmentsState(segments);
    if (updateWidgetPropsValues) {
      updateWidgetPropsValues({ values: { uncheckedSegments: segments } });
      return;
    }
    if (!widgetHeaderConfig.isReadOnly && isSelectAll) {
      updateWidgetConfig({
        configKey: 'uncheckedSegments', configValue: segments, reportId, widgetId, shouldPullDataFromServer: false,
      });
    }
  }

  function getMappedValue(value, ...keys) {
    if (!value) {
      return null;
    }
    if (keys.length > 0) {
      const key = keys.shift();
      return getMappedValue(value[key], ...keys);
    }
    return value;
  }

  const { segmentFrequency, firstSegment, shouldUseRelativeTimeframe } = impactBySegmentParams;

  const timeFrameParams = timeFrameModule.getTimeframeParams({ ...widgetHeaderConfig.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
  const { startDate, endDate } = timeFrameParams;

  const impactByRequestId = getWidgetRequestId({ widget: widgetTypes.impactBySegment, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
  const relatedEntitiesIndicationImpactBySegment = getMappedValue(impactBySegment, 'shouldUseRelatedEntities');

  const metricsOptions = attributionStore.getMetricOptions();
  const filters = parseWidgetFiltersToFiltersConfig(widgetHeaderConfig.filters);
  const metadataBySegment = impactBySegment?.[impactByRequestId]?.result?.metadataBySegment;
  const filteredData = getFilteredImpactBySegmentRecords({
    data: impactBySegment?.[impactByRequestId]?.result?.impactData, filters, segment: firstSegment, metadataBySegment,
  });

  const trendBySegmentsData = trendBySegments?.[conversionIndicator]?.segments;
  const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });

  const reportId = selectedReport.value;
  const { widgetId } = widgetHeaderConfig;
  const updateCrmParam = ({ key, value, originalFunnel }) => {
    switch (key) {
      case 'groupBy':
        updateWidgetImpactByCRMSegment({ reportId, widgetId, firstSegment: value });
        break;
      case 'indicator':
        setDrilldownMetric(null);
        updateWidgetImpactByCRMIndicator({
          reportId, widgetId, crmIndicator: value, originalFunnel,
        });
        break;
      default:
        break;
    }
  };

  const onChangeFrequency = (event) => {
    updateWidgetImpactByCRMFrequency({ reportId, widgetId, segmentFrequency: event.value });
  };

  function updateDrilldownMetric({ metric }) {
    const metricValue = metric?.value || conversionIndicator;
    setDrilldownMetric(metric?.value);
    updateWidgetImpactByCRMIndicator({
      reportId, widgetId, crmIndicator: metricValue, originalFunnel: conversionIndicator,
    });
  }

  function updateFilters() {
    const newFilters = convertShowOnlyFilters({
      filters: [...widgetHeaderConfig.filters],
      userFunnels,
      conversionIndicator,
      filtersData,
    });
    updateWidgetFilters({ reportId, widgetId, filters: newFilters });
  }

  function onUpdateSelectAll({ value }) {
    if (updateWidgetPropsValues) {
      updateWidgetPropsValues({ values: { isSelectAll: value } });
      return;
    }
    if (!widgetHeaderConfig.isReadOnly) {
      updateWidgetConfig({
        configKey: 'isSelectAll', configValue: value, reportId, widgetId, shouldPullDataFromServer: false,
      });
    }
  }

  const isImpactBySegmentLoaded = impactBySegment?.[impactByRequestId]?.status === 'finished';
  const isImpactBySegmentFailedToLoad = impactBySegment?.[impactByRequestId]?.status === 'failed';

  return (
    <ImpactBySegment
      isShowWidgetHeader={isShowWidgetHeader}
      hideOtherValuesRows={hideOtherValuesRows}
      showNoValueCheckbox={showNoValueCheckbox}
      showSegmentDropdown={showSegmentDropdown}
      onChangeHideNoValuesRows={() => setHideNoValuesRowsState((prevState) => !prevState)}
      hideNoValuesRows={hideNoValuesRowsState}
      isShowKPIFocusDropdown
      isLoaded={isImpactBySegmentLoaded}
      isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
      metricsOptions={metricsOptions}
      segmentsOptionsFields={segmentsOptionsFields}
      frequencyControl={{ selected: segmentFrequency, onChange: onChangeFrequency }}
      currentFrequency={segmentFrequency}
      conversionIndicator={conversionIndicator}
      firstSegment={firstSegment}
      segmentData={filteredData || []}
      segmentPrevData={[]}
      totalByFrequency={impactBySegment?.[impactByRequestId]?.result?.totalByFrequency}
      totalBySegment={impactBySegment?.[impactByRequestId]?.result?.totalBySegment}
      widgetHeaderConfig={widgetHeaderConfig}
      relatedEntitiesIndication={relatedEntitiesIndicationImpactBySegment}
      updateCrmParam={updateCrmParam}
      trendData={trendBySegmentsData}
      isLoadedTrendData={isLoaded}
      metricType={impactBySegment?.[impactByRequestId]?.result?.segmentKey ?? 'attributed'}
      kpiFocus={drilldownMetric || kpiFocus}
      updateFilters={() => updateFilters()}
      innerPageNavigation={({ filters: newFilters, isNewFiltersUIOnly }) => {
        updateWidgetFilters({
          reportId, widgetId, filters: newFilters, isNewFiltersUIOnly,
        });
      }}
      filters={filters}
      dataTotals={accountOptimizationMetrics ?? {}}
      sortByColumn={sortByColumn}
      checkedSegments={checkedSegmentsState}
      toggleViewType={toggleViewType}
      timeframe={{
        ...widgetHeaderConfig.timeFrame, startDate, endDate,
      }}
      attributionModel={widgetHeaderConfig.attributionModel}
      updateDrilldownMetric={({ metric }) => updateDrilldownMetric({ metric })}
      isReadOnly={widgetHeaderConfig.isReadOnly}
      isEnableNavigationMenu={widgetHeaderConfig.isEnableNavigationMenu}
      widgetHeaderProps={widgetHeaderProps}
      growthBySegment={impactBySegment?.[impactByRequestId]?.result?.growthBySegment}
      growthImpactData={impactBySegment?.[impactByRequestId]?.result?.growthImpactData}
      isCompareToPreviousEnabled={widgetHeaderConfig.isCompareToPreviousEnabled}
      isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
      onChangeCheckedSegments={({ segments }) => updateCheckedSegments({ segments })}
      onChangeUncheckedSegments={({ segments }) => updateUncheckedSegments({ segments })}
      uncheckedSegments={uncheckedSegmentsState}
      isSelectAll={isSelectAll}
      updateSelectAll={(value) => onUpdateSelectAll({ value })}
      shouldUseRelativeTimeframe={shouldUseRelativeTimeframe}
      updateShouldUseRelativeTimeframe={() => updateShouldUseRelativeTimeframe({ reportId, widgetId, shouldUseRelativeTimeframe: !shouldUseRelativeTimeframe })}
      isEnableSkeletonAnimation={isEnableSkeletonAnimation}
      updateWidgetPropsValues={updateWidgetPropsValues}
      emptyStateComponent={emptyStateComponent}
    />
  );
}

export default inject(
  ({
    reportsStore: {
      updateWidgetImpactByCRMSegment,
      updateWidgetImpactByCRMIndicator,
      updateWidgetImpactByCRMFrequency,
      updateShouldUseRelativeTimeframe,
      selectedReport,
      updateWidgetFilters,
      updateWidgetConfig,
    },
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
      userFunnels,
    },
    filterStore: {
      filtersData,
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.impactBySegment]: impactBySegment,
      },
    },
  }) => ({
    updateWidgetImpactByCRMSegment,
    updateWidgetImpactByCRMIndicator,
    updateWidgetImpactByCRMFrequency,
    updateShouldUseRelativeTimeframe,
    selectedReport,
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    updateWidgetFilters,
    userFunnels,
    filtersData,
    getWidgetRequestId,
    impactBySegment,
    updateWidgetConfig,
  }),
  observer
)(ImpactBySegmentWrapper);
