export function parseCustomSegmentsData({ data, customFieldsIdToLabelMap }) {
  if (!data) {
    return {};
  }
  const parsedData = {};
  for (const [segmentId, segmentData] of Object.entries(data)) {
    parsedData[segmentId] = segmentData;
    parsedData[segmentId].id = segmentId;
    parsedData[segmentId].name = customFieldsIdToLabelMap[segmentId] || segmentId;
  }
  return parsedData;
}
