exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".goal-forecast__content-3g8{display:flex;justify-content:space-between;width:100%;flex-wrap:nowrap;max-width:315px}.goal-forecast__borderWrap-3Gj{background:#fff;box-shadow:0 1px 3px rgba(54,56,64,.101647);border-radius:4px;padding:16px;width:100%;height:345px;margin:16px 0 0}.goal-forecast__title-2VP{font-weight:600;font-size:16px;line-height:24px;color:#182033;margin:0 0 24px}.goal-forecast__chartBg-3kp{height:125px;width:282px;background:#000;opacity:0;padding-bottom:8px}.goal-forecast__chartBg-3kp:hover{opacity:.43;cursor:pointer}.goal-forecast__arrowsBg-2Zi{height:125px;width:282px;background:url(/assets/plan_icons/chart_arrows.svg) 50% no-repeat;opacity:0}.goal-forecast__arrowsBg-2Zi:hover{opacity:1}.goal-forecast__chart-2Ex{height:133px;width:282px;background:url(/assets/plan_icons/chart.svg) 50% no-repeat}.goal-forecast__items-2mn{display:flex;justify-content:flex-end;padding:4px 0}.goal-forecast__iconSolid-3Da{width:30px;height:14px;margin:auto 8px auto 0;background:url(/assets/plan_icons/lineSolid.svg) 50% no-repeat}.goal-forecast__iconDotted-LBh{width:30px;height:14px;margin:auto 8px auto 0;background:url(/assets/plan_icons/lineDotted.svg) 50% no-repeat}.goal-forecast__iconGoal-3Z0{width:14px;height:14px;margin:auto 8px auto 0;background:url(/assets/plan_icons/goal.svg) 50% no-repeat}.goal-forecast__value-32H{font-weight:400;font-size:11px;line-height:15px;text-transform:uppercase;color:#707ea7}@media (max-width:851px){.goal-forecast__content-3g8{margin:16px auto}}@media (max-width:435px){.goal-forecast__content-3g8{width:auto}.goal-forecast__chart-2Ex,.goal-forecast__chartBg-3kp{width:100%;min-width:225px}}", ""]);

// exports
exports.locals = {
	"content": "goal-forecast__content-3g8",
	"content": "goal-forecast__content-3g8",
	"borderWrap": "goal-forecast__borderWrap-3Gj",
	"borderWrap": "goal-forecast__borderWrap-3Gj",
	"title": "goal-forecast__title-2VP",
	"title": "goal-forecast__title-2VP",
	"chartBg": "goal-forecast__chartBg-3kp",
	"chartBg": "goal-forecast__chartBg-3kp",
	"arrowsBg": "goal-forecast__arrowsBg-2Zi",
	"arrowsBg": "goal-forecast__arrowsBg-2Zi",
	"chart": "goal-forecast__chart-2Ex",
	"chart": "goal-forecast__chart-2Ex",
	"items": "goal-forecast__items-2mn",
	"items": "goal-forecast__items-2mn",
	"iconSolid": "goal-forecast__iconSolid-3Da",
	"iconSolid": "goal-forecast__iconSolid-3Da",
	"iconDotted": "goal-forecast__iconDotted-LBh",
	"iconDotted": "goal-forecast__iconDotted-LBh",
	"iconGoal": "goal-forecast__iconGoal-3Z0",
	"iconGoal": "goal-forecast__iconGoal-3Z0",
	"value": "goal-forecast__value-32H",
	"value": "goal-forecast__value-32H"
};