import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import userStore from 'stores/userStore';

import Table from 'components/controls/Table';
import HorizontalBarsItem from 'components/pages/analyze/ImpactBoard/HorizontalBarsItem';
import InfoMarker from 'components/pages/InfoMarker';
import IndicationTag from 'components/common/IndicationTag';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { formatNumberWithDecimalPoint, formatNumberAvgRatioWithDecimalPoint, formatNumberWithCommas } from 'components/utils/logic/budget';
import { calculateAverage, getTrendValuesForCell } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { roundToCeilNumber, generateTicksArray } from 'components/pages/analyze/ImpactBoard/logic/HorizontalBarsTable';
import { isTrendPositive } from 'components/utils/logic/trend';
import { skeletonSmallTableCellSizes } from 'components/common/enums';
import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';

import horizontalBarsTableStyle from 'styles/analyze/impactBySegment/horizontalBarsTable.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import attributionStyle from 'styles/analyze/analyze-attribution-table.css';
import multiCheckStyle from 'styles/controls/multi-check-select.css';
import Skeleton from 'components/common/Skeleton';

const styles = horizontalBarsTableStyle.locals;
const multiCheckStyles = multiCheckStyle.locals;

export default function HorizontalBarsTable({
  data = [],
  indicator,
  isLoadedTrendData,
  trendData,
  maxValue,
  getNavigationMenuPopupsParams,
  metricType,
  firstSegment,
  currentSegmentLabel,
  isLowerBetter,
  dataTotals = {},
  colorIndexs,
  sortByColumn,
  setSortByColumn,
  onChangeCheckedSegments,
  onChangeUncheckedSegments,
  checkedSegments,
  uncheckedSegments,
  isMultiCheckView,
  isLoaded,
  numberOfSkeletonEmptyRows = 6,
  isCompareToPreviousEnabled,
  isCalculateAdvancedMetrics,
  updateSelectAll,
  isEnableSkeletonAnimation,
  isEnableNavigationMenu,
}) {
  const allSegmentsNames = data.map((row) => row.name);
  const checkedSegmentsOfGivenData = checkedSegments.filter((segment) => allSegmentsNames.includes(segment));

  useEffect(() => {
    horizontalBarsTableStyle.use();
    analyzeStyle.use();
    attributionStyle.use();
    return () => {
      horizontalBarsTableStyle.unuse();
      analyzeStyle.unuse();
      attributionStyle.unuse();
    };
  }, []);

  useEffect(() => {
    if (isLoaded && isMultiCheckView && checkedSegmentsOfGivenData.length === 0) {
      const updatedCheckedSegments = data.map((row) => row.name);
      onChangeCheckedSegments({ segments: updatedCheckedSegments });
    }
  }, [
    data.length,
    isLoaded,
  ]);

  const averageValue = dataTotals[indicator] ? formatNumberWithDecimalPoint(dataTotals[indicator]) : calculateAverage(Object.values(data), 'value', 1);
  const updatedMaxValue = dataTotals[indicator] > maxValue ? dataTotals[indicator] : maxValue;
  const maxTick = roundToCeilNumber({ value: updatedMaxValue });
  const averageWidthValue = (averageValue / maxTick) * 100 || 0;
  const ticksForBars = generateTicksArray({ maxValue: updatedMaxValue });
  const defaultSorted = [{
    id: sortByColumn || 'indicator',
    desc: !isLowerBetter,
  }];

  function onChangeSelectAllSegments() {
    if (isMultiCheckView) {
      const updatedCheckedSegments = [];
      const shouldSelectAllSegments = checkedSegmentsOfGivenData.length < data.length;
      if (shouldSelectAllSegments) {
        updatedCheckedSegments.push(...allSegmentsNames);
        onChangeUncheckedSegments({ segments: [] });
      }
      onChangeCheckedSegments({ segments: updatedCheckedSegments });
    } else {
      onChangeCheckedSegments({ segments: [] });
    }
    updateSelectAll(!getSelectAllCheckboxIndication());
  }

  function onChangeCheckedSegmentItem({ segment }) {
    if (isMultiCheckView) {
      const segmentIndex = checkedSegments.findIndex((checkedSegment) => checkedSegment === segment);
      const updatedCheckedSegments = [...checkedSegments];
      const uncheckedSegmentIndex = uncheckedSegments.findIndex((checkedSegment) => checkedSegment === segment);
      const updatedUncheckedSegments = [...uncheckedSegments];

      if (segmentIndex > -1) {
        updatedCheckedSegments.splice(segmentIndex, 1);
        updatedUncheckedSegments.push(segment);
      } else {
        updatedCheckedSegments.push(segment);
        if (uncheckedSegmentIndex > -1) {
          updatedUncheckedSegments.splice(uncheckedSegmentIndex, 1);
        }
      }

      onChangeCheckedSegments({ segments: updatedCheckedSegments });
      onChangeUncheckedSegments({ segments: updatedUncheckedSegments });
    } else {
      onChangeCheckedSegments({ segments: [segment] });
    }
  }

  function getSelectAllCheckboxIndication() {
    return (isMultiCheckView ? checkedSegmentsOfGivenData.length === data.length : checkedSegmentsOfGivenData.length === 0);
  }

  function getHorizontalTableColumns() {
    const columns = [{
      id: 'bar',
      accessor: 'bar',
      header: () => {
        const isDataLoaded = data.length > 0 || isLoaded;
        return (
          <div>
            {isDataLoaded && (
              <Checkbox
                key="Checkbox"
                checked={getSelectAllCheckboxIndication()}
                onChange={() => onChangeSelectAllSegments()}
                type={isMultiCheckView ? 'checkbox' : 'radio'}
                className={styles.checkboxCell}
              />
            )}
            Select all
          </div>
        );
      },
      Footer: () => {
        if (!(data.length > 0 || isLoaded)) {
          return null;
        }

        return (
          <div className={styles.horizontalBarFooter}>
            <div className={styles.averageLine} style={{ left: `${averageWidthValue}%` }} />
            <div className={styles.averageTitle} style={{ left: `${averageWidthValue}%` }}>
              {`Average ${formatIndicatorDisplay(indicator, averageValue, false, true, true, 0)}`}
            </div>
            <div className={styles.horizontalBarFooterTickes}>
              {ticksForBars.map((tick) => (
                <React.Fragment key={`bar-ticks-${tick}`}>
                  <div
                    className={styles.tickLine}
                    style={{ left: `${(tick / maxTick) * 100}%` }}
                  />
                  <div
                    className={styles.tickTitle}
                    style={{ left: `${(tick / maxTick) * 100}%` }}
                  >
                    {tick}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      },
      cell: (item, props) => {
        const barWidth = item?.value ? ((item.value / maxTick) * 100) : 0;
        if (!(item !== undefined || isLoaded)) {
          return (
            <div>
              <div className={styles.checkboxCell}>
                <Skeleton height={20} width={20} isLightTheme enableAnimation={isEnableSkeletonAnimation} />
              </div>
              <div style={{ width: '90%' }}>
                <Skeleton height={36} enableAnimation={isEnableSkeletonAnimation} />
              </div>
            </div>
          );
        }
        return (
          <>
            <Checkbox
              key="Checkbox"
              checked={checkedSegmentsOfGivenData.includes(item?.name)}
              onChange={() => onChangeCheckedSegmentItem({ segment: item.name })}
              className={styles.checkboxCell}
              type={isMultiCheckView ? 'checkbox' : 'radio'}
              disabled={!(item !== undefined || isLoaded)}
            />
            <HorizontalBarsItem
              averageWidth={averageWidthValue}
              barWidth={barWidth}
              value={item.name}
              getNavigationMenuParams={getNavigationMenuPopupsParams ? getNavigationMenuPopupsParams({ valueToNavigate: item.name, itemValue: props.value?.value }) : null}
              firstSegment={firstSegment}
              currentSegmentLabel={currentSegmentLabel}
              colorIndexs={colorIndexs}
              isEnableNavigationMenu={isEnableNavigationMenu}
              tagId={item?.tagId}
            />
          </>
        );
      },
    }, {
      accessor: 'value',
      id: 'indicator',
      header: () => (
        <div className={styles.cellIndicatorValue}>
          {userStore.getMetricNickname({ metric: indicator })}
        </div>
      ),
      sortable: true,
      defaultSortDesc: true,
      cell: (item, props) => {
        if (item == null && !isLoaded) {
          return (
            <div>
              <Skeleton {...skeletonSmallTableCellSizes} isLightTheme enableAnimation={isEnableSkeletonAnimation} />
            </div>
          );
        }

        const averagePercent = ((item - averageValue) / averageValue) * 100;
        const itemValue = formatNumberWithDecimalPoint(item);
        const formatAveragePercent = formatNumberWithDecimalPoint(averagePercent, 0);
        const isValuePositive = isLowerBetter ? formatAveragePercent <= 0 : formatAveragePercent >= 0;
        const segmentName = props.original.bar.name;
        const growthValue = props.original.bar.growth;

        return (
          <div className={classNames(styles.cellValue, styles.cellIndicatorValue)} data-styleOverride={isCompareToPreviousEnabled && styles.cellWithCompare}>
            <div className={classNames(styles.cellMinWidth, isCompareToPreviousEnabled && styles.valueWithCompare)}>
              {formatIndicatorDisplay(indicator, itemValue, false, true, true, 2)}
              {isCompareToPreviousEnabled ? (
                <div>
                  {growthValue === undefined ? (
                    <Loading3Dots />
                  ) : (
                    <ComparisonValue
                      value={getGrowthValue({
                        metricName: segmentName,
                        growthPerMetric: { [segmentName]: growthValue },
                      })}
                      isGrowthDesired={getIsGrowthDesired({ metricName: segmentName })}
                    />
                  )}
                </div>
              ) : null}
            </div>

            {isCalculateAdvancedMetrics ? (
              <div className={styles.cellValueTag}>
                {itemValue > 0 && (
                  <div
                    className={classNames(
                      attributionStyle.locals.expendCellShareOfTotalTag,
                      isValuePositive ? null : attributionStyle.locals.expendCellShareOfTotalNegativeTag
                    )}
                  >
                    <b>{`${formatAveragePercent > 0 ? '+' : ''}${formatNumberWithCommas({ number: formatAveragePercent })}%`}</b>
                    {' vs. avg'}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        );
      },
    }];

    if (isCalculateAdvancedMetrics) {
      columns.push({
        accessor: 'name',
        header: () => (
          <div className={attributionStyle.locals.trendInfo}>
            Trend
            <InfoMarker
              place="right"
              tooltipText="A trendline based on the last three months of your selected time frame, represented in average growth, month over month."
            />
          </div>
        ),
        id: 'trend',
        sortable: true,
        sortMethod: (aValue, bValue) => {
          const trendValuesA = getTrendValuesForCell({
            trendData,
            columnKey: metricType,
            firstSegment: aValue,
          });
          const trendValuesB = getTrendValuesForCell({
            trendData,
            columnKey: metricType,
            firstSegment: bValue,
          });
          if (trendValuesA?.trendValue == null) { return -1; }
          if (trendValuesB?.trendValue == null) { return 1; }
          return trendValuesA.trendValue - trendValuesB.trendValue;
        },
        cell: (item) => {
          const trendValues = getTrendValuesForCell({
            trendData,
            columnKey: metricType,
            firstSegment: item,
          });
          const trendFormatValue = formatNumberWithDecimalPoint(trendValues?.trendValue, 1);
          const trendFormatValueAsNumber = parseFloat(trendFormatValue);
          const trendFormatAvgRatioValue = formatNumberAvgRatioWithDecimalPoint({ value: trendValues?.trendAvgRatioValue, numberOfDigitsAfterPoint: 1 });
          const trendPositive = isTrendPositive({ metric: indicator, trendValue: trendValues?.trendValue });
          return (
            <div>
              {isLoadedTrendData && isLoaded ? (
                <div>
                  {trendValues ? (
                    <div className={classNames(styles.trendItem, trendPositive ? attributionStyle.locals.expendCellTrendTagUp : attributionStyle.locals.expendCellTrendTagDown)}>
                      <b>{`${trendFormatValueAsNumber > 0 ? '+' : ''}${trendFormatValueAsNumber}%`}</b>
                      {` (${trendFormatAvgRatioValue}x vs. avg)`}
                    </div>
                  ) : <IndicationTag text="No trend" tooltip="This metric’s trendline could not be calculated. Trends require 3 months of non-zero values." tooltipPosition="right" />}
                </div>
              ) : (
                <div>
                  <Skeleton {...skeletonSmallTableCellSizes} isLightTheme enableAnimation={isEnableSkeletonAnimation} />
                </div>
              )}
            </div>
          );
        },
      });
    }

    return columns;
  }

  let tableData = [...data];
  if (data?.length === 0 && !isLoaded) {
    tableData = Array(numberOfSkeletonEmptyRows).fill({ value: null, name: null });
  }

  const horizontalTableColumns = getHorizontalTableColumns();

  return (
    <Table
      withFixedColumns={false}
      key="impactBySegmentTable"
      noPadding
      style={{ height: 440 }}
      data={tableData}
      defaultSorted={defaultSorted}
      columns={horizontalTableColumns}
      infiniteScroll
      showPagination
      infiniteScrollSelector="div > div.rt-table > div.rt-tbody"
      bodyCustomClass={styles.customBodyClass}
      rowGroupClassName={styles.rowGroup}
      rowClassName={styles.row}
      headerClassName={styles.header}
      headRowClassName={styles.headerRow}
      footerClassName={styles.footer}
      footRowClassName={styles.footerRow}
      cellClassName={styles.cell}
      setSortByColumn={setSortByColumn}
    />
  );
}
