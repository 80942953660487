import React from 'react';
import Component from 'components/Component';
import style from 'styles/plan/manage-columns-button.css';
import classnames from 'classnames';

class ManageColumnsButton extends Component {
  style = style;

  render() {
    return (
      <button
        type="button"
        className={classnames(this.classes.manageColumnsButton)}
        onClick={this.props.onClick}
      >
        Select Columns
        <div className={this.classes.dropdownIndicator} />
      </button>
    );
  }
}

export default ManageColumnsButton;
