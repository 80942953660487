import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/questions/ai-answers.css';

const styles = style.locals || {};

function AIFloatSuggestionBox({
  content, iconPosition, onClick,
}) {
  useStyles([style]);

  return (
    <div
      onClick={onClick}
      className={classnames(styles.answersSuggestionBox, iconPosition && styles[iconPosition])}
    >
      <div className={styles.answersIcon} />
      <div>{content}</div>
    </div>
  );
}

export default AIFloatSuggestionBox;
