exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".mention-notification__text--l1{font-size:14px;color:#354052;display:inline-flex;white-space:pre;margin-right:3px}.mention-notification__tagger-vQ7{font-weight:600}.mention-notification__campaign-2a_{text-decoration:underline;font-weight:600}", ""]);

// exports
exports.locals = {
	"text": "mention-notification__text--l1",
	"text": "mention-notification__text--l1",
	"tagger": "mention-notification__tagger-vQ7 mention-notification__text--l1",
	"tagger": "mention-notification__tagger-vQ7 mention-notification__text--l1",
	"campaign": "mention-notification__campaign-2a_ mention-notification__text--l1",
	"campaign": "mention-notification__campaign-2a_ mention-notification__text--l1"
};