import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';
import Skeleton from 'components/common/Skeleton';

import { skeletonBigTextSizes, skeletonSmallTextSizes } from 'components/common/enums';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function MetricItem({
  className,
  metricValue,
  formatValue,
  headerLabel,
  headerTooltip,
  footerLabel,
  footerValue,
  isLoaded,
}) {
  useStyles([style]);

  return (
    <div className={classnames(styles.metricItem, className)}>
      {isLoaded ? (
        <>
          <div className={styles.headerLabel}>
            {headerLabel}
            {headerTooltip ? (
              <InfoMarker
                tooltipText={headerTooltip}
                TooltipProps={{
                  className: styles.tooltip,
                }}
              />
            ) : null}
          </div>
          <div className={styles.metricValue}>
            {formatValue ? formatValue(metricValue) : metricValue}
          </div>
          <div className={styles.footer}>
            {footerLabel ? (
              <div className={styles.footerLabel}>
                {footerLabel}
              </div>
            ) : null}
            {footerValue ? (
              <div className={styles.footerValue}>
                {formatValue ? formatValue(footerValue) : footerValue}
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <div data-testid="skeleton-metric-item">
          <Skeleton {...skeletonSmallTextSizes} isLightTheme />
          <Skeleton {...skeletonBigTextSizes} isLightTheme />
          <Skeleton {...skeletonSmallTextSizes} isLightTheme />
        </div>
      )}
    </div>
  );
}

export default MetricItem;
