import PropTypes from 'prop-types';
import React from 'react';
import {
  camelCase, get, isEqual,
} from 'lodash';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import FrequencySelect from 'components/pages/analyze/FrequencySelect';
import HistoricalPerformanceItem from 'components/pages/analyze/OverviewTab/HistoricalPerformanceItem';
import PerformanceItem from 'components/pages/analyze/OverviewTab/PerformanceItem';
import WidgetHeader from 'components/common/WidgetHeader';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import AddToReportPopup from 'components/pages/reports/AddToReportPopup';
import CheckListWithPresets from 'components/controls/CheckListWithPresets';

import { FREQUENCY_MAP, FREQUENCY_OPTIONS } from 'components/utils/frequency';
import { compose } from 'components/utils/utils';
import {
  indicatorsToNewMapping, newIndicatorMapping, VELOCITY_MAPPING_SUFFIX, CONVERSION_RATE_MAPPING_SUFFIX,
} from 'components/utils/indicators';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getPerformanceItemNavigationMenuParams, getFunnelNameWithoutNew } from 'components/pages/analyze/OverviewTab/logic/PerformanceItem';
import { getPresets, metricExceptionFilter } from 'components/pages/analyze/OverviewTab/logic/HistoricalPerformance';
import { CACMetrics } from 'components/pages/analyze/OverviewTab/logic/enums';
import { getGroupedUserMetrics } from 'components/utils/logic/utils';

import analyzeStyles from 'styles/analyze/analyze.css';
import style from 'styles/analyze/historical-performance.css';

const PAGE_SIZE = 6;

const enhance = compose(
  inject(({
    attributionStore: {
      data: attributionStoreData,
    },
    userStore: {
      getMetricNickname,
      getUserMetricsWithNew,
    },
  }) => ({
    userMetrics: getUserMetricsWithNew({
      exceptionFilter: metricExceptionFilter,
    }),
    getMetricNickname,
    attributionStoreData,
  })),
  observer
);

class HistoricalPerformance extends Component {
  style = style;

  static propTypes = {
    getMetricNickname: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const isActiveIndicatorIdFunnel = this.props.activeIndicator?.startsWith('funnel') && this.props.activeIndicator?.length === 7;
    this.state = {
      activePage: this.props.activePage || 0,
      activeIndicator: !this.props.activeIndicator ? null : (isActiveIndicatorIdFunnel ? camelCase(`new ${this.props.activeIndicator}`) : this.props.activeIndicator),
      widgetHeaderConfigForMetric: null,
      selectedMetricsData: [],
      metricsOptions: [],
    };
    this.presets = [];
  }

  componentDidMount() {
    const { userMetrics, isReadOnly } = this.props;
    this.getMetricOptions();
    this.getSelectedMetricsData();
    this.presets = getPresets({ userMetrics });

    if (!isReadOnly && (!this.props.selectedMetrics || this.props.selectedMetrics.length === 0)) {
      this.props.setSelectedMetrics({ selectedMetrics: this.presets[0].options, isOnMount: true });
    }
  }

  componentDidUpdate(prevProps) {
    const responseDataHasUpdated = !isEqual(this.props.historicalPerformance, prevProps.historicalPerformance);
    const selectedItemsHaveChanged = !isEqual(this.props.selectedMetrics, prevProps.selectedMetrics);
    if (selectedItemsHaveChanged) {
      this.setState({ activePage: this.props.activePage || 0 });
    }
    if (responseDataHasUpdated || selectedItemsHaveChanged) {
      this.getSelectedMetricsData();
    }
  }

  getPerformanceItemData({ metricName }) {
    const {
      userMetrics,
      historicalPerformance,
    } = this.props;

    const foundMetric = userMetrics.find((metric) => metric.metricName === metricName) || {};

    return {
      indicator: metricName,
      ...foundMetric,
      ...get(historicalPerformance, [metricName], {}),
      value: get(historicalPerformance, [metricName, 'total', 'value']),
      growth: get(historicalPerformance, [metricName, 'total', 'growth']),
    };
  }

  setPrevPage = () => {
    const { activePage } = this.state;

    if (activePage - 1 >= 0) {
      this.setState({ activePage: activePage - 1 });
    }
  };

  setNextPage = () => {
    const {
      activePage, selectedMetricsData,
    } = this.state;

    if (activePage * PAGE_SIZE + PAGE_SIZE < selectedMetricsData.length) {
      this.setState({ activePage: activePage + 1 });
    }
  };

  updateHistoricalPerformanceActiveIndicator(activeIndicator) {
    this.setState({ activeIndicator });
  }

  getNavigationMenuParamsByIndicator({ indicator }) {
    const { getMetricNickname, widgetHeaderConfig } = this.props;
    const isVelocityIndicator = indicator.includes(VELOCITY_MAPPING_SUFFIX);
    const isConversionRateIndicator = indicator.includes(CONVERSION_RATE_MAPPING_SUFFIX);
    let sourceFunnel;
    let destFunnel;

    let subTitle = `Focus on ${getMetricNickname({ metric: indicator })} and see ${getMetricNickname({ metric: indicator })} trend over time`;
    if (isVelocityIndicator || isConversionRateIndicator) {
      const sourceFunnelNumber = indicator.match(/\d/g)[0];
      const destFunnelNumber = indicator.match(/\d/g)[1];
      sourceFunnel = `funnel${sourceFunnelNumber}`;
      destFunnel = `funnel${destFunnelNumber}`;
      subTitle = `Focus on ${getMetricNickname({ metric: sourceFunnel })} to ${getMetricNickname({ metric: destFunnel })} Velocity and analyze it over time`;
      if (isConversionRateIndicator) {
        subTitle = `Focus on ${getMetricNickname({ metric: sourceFunnel })} to ${getMetricNickname({ metric: destFunnel })} Conversion Rate and analyze it over time`;
      }
    }
    const navigationParams = getPerformanceItemNavigationMenuParams({ indicator, widgetHeaderConfig });
    const widgetHeaderConfigForMetric = {
      title: 'Historical performance for metric',
      type: widgetTypes.performanceItem,
      selectedMetrics: [getFunnelNameWithoutNew(indicator)],
      indicator,
    };

    const isCaCIndicator = CACMetrics.includes(indicator);
    if (!isCaCIndicator) {
      navigationParams.unshift({
        title: 'Add to report',
        subTitle: 'Add this metric to a report',
        navigationFunction: () => this.setState({ widgetHeaderConfigForMetric }),
      }, {
        title: 'Drill down',
        subTitle,
        navigationFunction: () => this.setState({ activeIndicator: indicator }),
      });
    }

    return navigationParams;
  }

  getSelectedMetricsData() {
    const { selectedMetrics = [] } = this.props;

    const metricsWithProps = [];
    for (const metricName of selectedMetrics) {
      metricsWithProps.push(this.getPerformanceItemData({ metricName }));
    }

    this.setState({ selectedMetricsData: metricsWithProps });
  }

  getMetricOptions() {
    const metricsOptions = getGroupedUserMetrics({ userMetrics: this.props.userMetrics });

    this.setState({ metricsOptions });
  }

  render() {
    const {
      activePage,
      activeIndicator,
      selectedMetricsData,
      metricsOptions,
    } = this.state;

    const {
      isLoaded,
      isFailedToLoad,
      historicalPerformanceFrequency,
      updateHistoricalPerformanceFrequency,
      widgetHeaderConfig = {
        title: 'Historical performance',
        type: widgetTypes.historicalPerformance,
        historicalPerformanceFrequency,
        displayedMetrics: this.props.selectedMetrics,
        activePage,
        activeIndicator,
      },
      isReadOnly,
      widgetHeaderProps = {},
      isCompareToPreviousEnabled,
      selectedMetrics,
      setSelectedMetrics,
    } = this.props;

    const itemsToShow = selectedMetricsData.slice(
      activePage * PAGE_SIZE,
      activePage * PAGE_SIZE + PAGE_SIZE
    );
    const gotNextPage = activePage * PAGE_SIZE + PAGE_SIZE < selectedMetricsData.length;

    let activeJourney = null;
    if (activeIndicator) {
      activeJourney = selectedMetricsData?.find((item) => item.metricName === activeIndicator);
    }
    const isKeyIndicator = activeJourney && Object.values(indicatorsToNewMapping()).includes(activeJourney.indicator);

    if (isFailedToLoad) {
      return (
        <ErrorWidgetWithBlur
          status="error"
          widgetType={widgetTypes.historicalPerformance}
        />
      );
    }

    const isAllValuesAreEmpty = itemsToShow.length > 0 && itemsToShow.every((item) => item.value === 0 || item.value === undefined);

    const widgetHeaderChildren = (
      <>
        <div className={this.classes.headerSelectHolder}>
          <CheckListWithPresets
            options={metricsOptions}
            presets={this.presets}
            checkListTitle="Manage columns and metrics"
            selectedOptions={selectedMetrics}
            setSelectedOptions={(selectedOptions) => {
              setSelectedMetrics({ selectedMetrics: selectedOptions });
            }}
            isDisabled={isReadOnly}
            presetLabel="Preset"
          />
        </div>

        {activeJourney ? (
          <FrequencySelect
            onChange={({ value }) => updateHistoricalPerformanceFrequency(value)}
            selected={historicalPerformanceFrequency}
            options={Object.values(FREQUENCY_OPTIONS)}
            disabled={!isLoaded || isReadOnly}
          />
        ) : null}

        {isKeyIndicator ? (
          <div>
            <Button
              type="secondaryBlue"
              disabled={!isLoaded}
              onClick={() => this.props.navigateToJourneys(newIndicatorMapping()[activeJourney.indicator], activeJourney.value)}
            >
              Show journeys
            </Button>
          </div>
        ) : null}
      </>
    );

    return (
      <div className={analyzeStyles.locals.widgetSpace}>
        <WidgetHeader
          {...widgetHeaderProps}
          widgetHeaderConfig={widgetHeaderConfig}
        >
          {widgetHeaderChildren}
        </WidgetHeader>

        {isLoaded && isAllValuesAreEmpty && this.props.emptyStateComponent ? (
          this.props.emptyStateComponent
        ) : (
          <div style={this.props.customStyle}>
            <div className={this.classes.rowBox}>
              <div className={this.classes.boxHeader}>
                {activeJourney && (
                  <div
                    className={this.classes.backButton}
                    onClick={() => this.updateHistoricalPerformanceActiveIndicator(null)}
                  >
                    {'< Back'}
                  </div>
                )}
              </div>
              {activeJourney ? (
                <HistoricalPerformanceItem
                  activeJourney={{
                    ...activeJourney,
                    performanceData: get(activeJourney, [FREQUENCY_MAP[historicalPerformanceFrequency], 'performanceData'], []),
                  }}
                  isLoaded={isLoaded}
                  isCompareToPreviousEnabled={isCompareToPreviousEnabled}
                />
              ) : (
                <div className={this.classes.navHolder}>
                  <div className={this.classes.itemGridHolder}>
                    {itemsToShow.length > 0 ? (
                      <div className={this.classes.itemGrid}>
                        {itemsToShow.map((item) => (
                          <div key={item.indicator}>
                            <PerformanceItem
                              data={item}
                              navigationMenuPopupParams={this.getNavigationMenuParamsByIndicator({ indicator: item.indicator })}
                              isLoaded={isLoaded}
                              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
                              indicator={item.indicator}
                              isHideComparisonValue={CACMetrics.includes(item.indicator)}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className={this.classes.noMetrics}>
                        Select metrics or preset to view data
                      </div>
                    )}
                  </div>
                  <button
                    aria-label="leftIcon"
                    type="button"
                    className={classnames(
                      this.classes.gridButton,
                      this.classes.left
                    )}
                    disabled={activePage === 0}
                    onClick={this.setPrevPage}
                  >
                    <div className={this.classes.iconLeft} />
                  </button>
                  <button
                    aria-label="rightIcon"
                    type="button"
                    className={classnames(
                      this.classes.gridButton,
                      this.classes.right
                    )}
                    disabled={!gotNextPage}
                    onClick={this.setNextPage}
                  >
                    <div className={this.classes.iconRight} />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.widgetHeaderConfigForMetric ? (
          <AddToReportPopup
            onClosePopup={() => this.setState({ widgetHeaderConfigForMetric: null })}
            isInReports={false}
            widgetHeaderConfig={this.state.widgetHeaderConfigForMetric}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
          />
        ) : null}
      </div>
    );
  }
}

export default withLDConsumer()(enhance(HistoricalPerformance));
