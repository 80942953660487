import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import memoize from 'memoize-one';
import get from 'lodash/get';

import Component from 'components/Component';
import Popup from 'components/Popup';
import Kinds from 'components/pages/users/Filters';
import FilterAccordion from 'components/pages/users/Filters/FilterPanel/FilterAccordion';
import FilterHeader from 'components/pages/users/Filters/FilterPanel/FilterHeader';

import {
  ConfigPropType,
  funnelStagesVariants,
  funnelStagesKeyIndexes,
  CRMSourcesVariants, customCRMVariants,
} from 'components/utils/filters';
import { filterKinds } from 'components/utils/filters/logic';
import { uniqBy } from 'lodash';
import { clientConfigs } from 'components/utils/filters/configs';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

export const filtersCategoryConfig = [
  {
    id: 1,
    title: 'Filter by journeys',
    tooltip: 'Filter prospect and customer journeys based on their attributes using the filters below. Attributes consist of: funnel stage transitions, activities (touchpoints), or segmentation data.',
  },
  {
    id: 2,
    title: 'Filter cost data',
    tooltip: 'Use the filters below to filter activities based on their costs.',
  },
];

export const filtersUIConfig = {
  [filterKinds.CHANNELS]: {
    title: 'Channels',
    component: Kinds.ChannelsFilter,
    categotyId: 1,
    isSegmentFilter: true,
    supportsSelecetedFunnel: true,
  },
  [filterKinds.CAMPAIGNS]: {
    title: 'Campaigns',
    component: Kinds.CampaignsFilter,
    categotyId: 1,
    isSegmentFilter: true,
    supportsSelecetedFunnel: true,
  },
  [filterKinds.MARKETING_VS_SALES]: {
    title: 'Department',
    component: Kinds.MarketingVsSalesFilter,
    categotyId: 1,
    isSegmentFilter: true,
  },
  [filterKinds.PRODUCT]: {
    title: 'Product',
    component: Kinds.ProductFilter,
    categotyId: 1,
  },
  [filterKinds.REGION]: {
    title: 'Region',
    component: Kinds.RegionFilter,
    categotyId: 1,
  },
  [filterKinds.LEAD_STATUS]: {
    title: 'Lead Status',
    component: Kinds.LeadStatusFilter,
    categotyId: 1,
  },
  [filterKinds.CONTENT]: {
    title: 'Content',
    component: Kinds.ContentFilter,
    categotyId: 1,
    isSegmentFilter: true,
    supportsSelecetedFunnel: true,
  },
  [filterKinds.FORMS]: {
    title: 'Forms',
    component: Kinds.FormsFilter,
    categotyId: 1,
  },
  [filterKinds.CUSTOM_FIELDS]: {
    title: 'CRM Fields',
    component: Kinds.MultiFieldFilter,
    categotyId: 1,
    variantsOptions: customCRMVariants,
    isSegmentFilter: true,
    supportsSelecetedFunnel: true,
  },
  [filterKinds.CRMSource]: {
    title: 'CRM Sources',
    component: Kinds.MultiFieldFilter,
    categotyId: 1,
    variantsOptions: CRMSourcesVariants,
    supportsSelecetedFunnel: true,
  },
  [filterKinds.FUNNEL_STAGES]: {
    title: 'Funnel stages',
    component: Kinds.FunnelStagesFilter,
    categotyId: 1,
    supportsSelecetedFunnel: true,
  },
  [filterKinds.PRE_DEFINED]: {
    title: 'Pre-defined',
    component: Kinds.PreDefinedFilter,
    categotyId: 1,
  },
  [filterKinds.SAVED]: {
    title: 'Saved',
    component: Kinds.SavedFilter,
    categotyId: 1,
  },
  [filterKinds.VELOCITY]: {
    title: 'Velocity',
    component: Kinds.VelocityFilter,
    categotyId: 1,
  },
  [filterKinds.REVENUE]: {
    title: 'Revenue',
    component: Kinds.RevenueFilter,
    categotyId: 1,
  },
  [filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS]: {
    title: 'Campaigns',
    component: Kinds.CampaignCustomFieldsFilter,
    categotyId: 2,
  },
  [filterKinds.CAMPAIGN_COST]: {
    title: 'Campaign Costs',
    component: Kinds.CampaignCostFilter,
    categotyId: 2,
  },
};

export const sortByKeys = (unordered = {}) => {
  const ordered = {};
  Object.keys(unordered).sort().forEach((key) => {
    ordered[key] = unordered[key];
  });
  return ordered;
};

export default class FilterPopup extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]),
    onFilterAdd: PropTypes.func.isRequired,
    filterConfigs: PropTypes.arrayOf(ConfigPropType).isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);
    const { filterFromTag, filterConfigs, filtersItems = [] } = props;
    let activeFilter = [];
    if (filterFromTag) {
      activeFilter = [filterFromTag];
    } else if (filtersItems.length > 0) {
      activeFilter = filtersItems.filter((filter) => filter.kind === get(filterConfigs, '0.kind', 'channels'));
    }

    this.state = {
      activeFilterKind: filterFromTag ? filterFromTag.kind : get(filterConfigs, '0.kind', 'channels'),
      selectedFilters: activeFilter,
      preDefinedIndicator: '',
      selectedSavedFilter: '',
      preDefinedFilters: [],
      savedFilters: [],
      tabActiveIndex: 0,
      popupHeight: 0,
      menuLeftPanelItems: filterConfigs,
    };
  }

  updateWindowDimensions = () => {
    this.setState({ popupHeight: window.innerHeight });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleActiveFilterClick = (e) => {
    const { filtersItems } = this.props;
    const { kind } = e;
    const getFilterByKind = filtersItems.filter((filter) => filter.kind === kind);
    this.setState({
      activeFilterKind: kind,
      selectedFilters: getFilterByKind || [],
    });
  };

  handleAddFilter = () => {
    const { selectedFilters, preDefinedFilters, savedFilters } = this.state;
    const { filtersItems } = this.props;
    const merged = [...selectedFilters, ...preDefinedFilters, ...savedFilters, ...filtersItems]
      .map((item) => ({ ...item, data: sortByKeys(item.data) }));
    this.props.onFilterAdd(uniqBy(merged, (i) => JSON.stringify(i)));
  };

  getConfig = memoize((activeFilterKind, filterConfigs, data, localSavedFilters) => {
    let config = filterConfigs.find((filterConfig) => filterConfig.kind === activeFilterKind);
    if (!config) {
      return null;
    }
    if (clientConfigs[activeFilterKind]) {
      config = {
        ...config,
        ...clientConfigs[activeFilterKind],
      };
      return {
        ...config,
        options: config.getOptions ? config.getOptions(data, config.options) : config.options,
      };
    }
    if (localSavedFilters?.length && activeFilterKind === filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS) {
      const selectedFilterCofig = localSavedFilters.find((item) => item.config.kind === activeFilterKind);
      config = get(selectedFilterCofig, 'config', {});
      return config;
    }
    return config;
  });

  addFilterToSelection = (filter, preDefinedIndicator, selectedSavedFilter, callback = () => { }) => {
    if (Array.isArray(filter) && preDefinedIndicator) {
      this.setState({
        preDefinedFilters: [...filter],
        preDefinedIndicator,
      }, callback);
    } else if (Array.isArray(filter) && selectedSavedFilter) {
      this.setState({
        savedFilters: [...filter],
        selectedSavedFilter,
      }, callback);
    } else {
      const { selectedFilters } = this.state;
      const index = selectedFilters.findIndex((f) => f.kind === filter.kind);
      if (index === -1) {
        selectedFilters.push(filter);
      } else {
        selectedFilters.splice(index, 1, filter);
      }
      this.setState({
        selectedFilters: [...selectedFilters],
      }, callback);
    }
  };

  getKind = (kind) => {
    const map = {
      channelBeforeStages: 'channels',
      campaignBeforeStages: 'campaigns',
      campaignTags: 'campaigns',
      contentBeforeStages: 'content',
      contentChannelBeforeStages: 'content',
    };
    return map[kind] || kind;
  };

  tabChangeHandler = (index) => {
    if (index === 0) {
      this.setState({
        activeFilterKind: [filterKinds.FUNNEL_STAGES][0],
        tabActiveIndex: index,
      });
    }
    if (index === 1) {
      this.setState({
        activeFilterKind: [filterKinds.SAVED][0],
        tabActiveIndex: index,
      });
    }
  };

  render() {
    const {
      className, opened, onClose, data = [], filterConfigs: configs,
      savedFilters, isTagPopup, filtersItems, localSavedFilters,
    } = this.props;
    const {
      activeFilterKind,
      selectedFilters,
      preDefinedIndicator,
      selectedSavedFilter,
      tabActiveIndex,
      menuLeftPanelItems,
      popupHeight,
    } = this.state;

    const defaultFunnelVariant = funnelStagesVariants[0].value;
    const isFunnelFilter = activeFilterKind === 'funnelStages';
    const filterKind = this.getKind(activeFilterKind);
    const currentFilter = filtersUIConfig[filterKind];
    const Filter = currentFilter.component;
    const variantOptions = currentFilter.variantsOptions;
    const activeFilter = selectedFilters.find(({ kind }) => kind === activeFilterKind);
    const filterConfig = this.getConfig(filterKind, configs, data, isTagPopup && localSavedFilters);
    const popupHeightPx = `${popupHeight - 340}px`;
    const savedHeightPx = `${popupHeight - 270}px`;

    return (
      <Popup
        className={classnames(
          styles.popup,
          className,
          tabActiveIndex === 0 && styles.popupPeddingBottom,
          isTagPopup && styles.popupFromTag
        )}
        hidden={!opened}
        onClose={onClose}
      >
        {!isTagPopup ? (
          <FilterHeader
            tabChangeHandler={this.tabChangeHandler}
            tabActiveIndex={tabActiveIndex}
            isShowSavedFilterTab={savedFilters?.length > 0}
          />
        ) : null}

        {tabActiveIndex === 0
          && (
            <>
              {!isTagPopup
                && (
                  <div className={styles.navPopupRap} style={{ height: popupHeightPx }}>
                    <FilterAccordion
                      items={menuLeftPanelItems}
                      filterCategory={filtersCategoryConfig}
                      filterConfigs={configs}
                      filtersUIConfig={filtersUIConfig}
                      getKind={this.getKind}
                      activeFilterKind={activeFilterKind}
                      handleActiveFilterClick={this.handleActiveFilterClick}
                      filtersItems={filtersItems}
                    />
                  </div>
                )}
              {filterConfig && (
                <div className={styles.filterPopupRap} style={{ height: popupHeightPx }}>
                  <Filter
                    key={activeFilterKind}
                    config={filterConfig}
                    onCancel={onClose}
                    onAddFilter={this.handleAddFilter}
                    updateSavedFiltersList={this.props.updateSavedFiltersList}
                    addFilterToSelection={this.addFilterToSelection}
                    selectedOptions={get(activeFilter, 'data.selectedOptions', [])}
                    variant={get(activeFilter, 'data.variant', isFunnelFilter ? defaultFunnelVariant : 0)}
                    fieldIndex={get(activeFilter, 'data.fieldIndex', isFunnelFilter ? funnelStagesKeyIndexes[defaultFunnelVariant] : 0)}
                    preDefinedIndicator={preDefinedIndicator}
                    activeFilter={activeFilter}
                    updateFiltersItems={this.props.setFiltersItems}
                    filtersItems={filtersItems}
                    variantOptions={variantOptions}
                  />
                </div>
              )}
            </>
          )}

        {tabActiveIndex === 1
          && filterConfig && (
            <div className={styles.strachFlex} style={{ height: savedHeightPx }}>
              <Filter
                key={activeFilterKind}
                config={filterConfig}
                onCancel={onClose}
                onAddFilter={this.handleAddFilter}
                updateSavedFiltersList={this.props.updateSavedFiltersList}
                addFilterToSelection={this.addFilterToSelection}
                selectedOptions={get(activeFilter, 'data.selectedOptions', [])}
                variant={get(activeFilter, 'data.variant', isFunnelFilter ? defaultFunnelVariant : 0)}
                fieldIndex={get(activeFilter, 'data.fieldIndex', isFunnelFilter ? funnelStagesKeyIndexes[defaultFunnelVariant] : 0)}
                preDefinedIndicator={preDefinedIndicator}
                selectedSavedFilter={selectedSavedFilter}
                savedFilters={savedFilters}
                activeFilter={activeFilter}
              />
            </div>
        )}

        <div className={styles.popupClose} onClick={onClose} />
      </Popup>
    );
  }
}
