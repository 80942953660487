import React from 'react';
import Component from 'components/Component';
import FilterActions from 'components/pages/users/Filters/FilterActions';
import Textfield from 'components/controls/Textfield';
import Range from 'components/controls/Range';
import { COMPARISON_OPERATORS } from 'components/utils/filters';
import { EQUALS } from 'components/utils/filters/logic';
import { filterKinds } from 'components/utils/filters/logic';
import { exchangeToUSD, extractNumberFromBudget, formatBudget } from 'components/utils/budget';
import filterStyles from 'styles/users/filters.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import Dropdown from 'components/controls/Dropdown';

const styles = filterStyles.locals;
const MAX = 10000;
const BETWEEN = 'between';

export default class RevenueFilter extends Component {
  static restoredFilter = (data) => {
    const { revenue } = data;
    if (Array.isArray(revenue)) {
      const [rangeFrom, rangeTo] = revenue;
      return { rangeFrom, rangeTo, comparisonOperator: BETWEEN };
    }
    return { ...data };
  }

  styles = [checkboxStyle];

  constructor(props) {
    super(props);
    const { activeFilter } = props;
    this.state = {
      comparisonOperator: EQUALS,
      revenue: 0,
      rangeFrom: 0,
      rangeTo: MAX / 2,
    };
    if (activeFilter) {
      const restored = RevenueFilter.restoredFilter(activeFilter.data);
      this.state = {
        ...this.state,
        ...restored,
      };
    }
  }

  handleFilterApply = () => {
    const {
      revenue, comparisonOperator, rangeFrom, rangeTo,
    } = this.state;
    let conditions;
    if (!this.isBetweenView) {
      conditions = { revenue, comparisonOperator };
    } else {
      conditions = { revenue: [rangeFrom, rangeTo] };
    }
    const payload = {
      kind: filterKinds.REVENUE,
      data: {
        selectedOptions: [''],
        ...conditions,
      },
    };
    this.props.addFilterToSelection(payload, null, null, () => this.props.onAddFilter());
  };

  updateFilter = () => {
    const {
      revenue, comparisonOperator, rangeFrom, rangeTo,
    } = this.state;
    let conditions;
    if (!this.isBetweenView) {
      conditions = { revenue, comparisonOperator };
    } else {
      conditions = { revenue: [rangeFrom, rangeTo] };
    }
    const filterObj = {
      kind: filterKinds.REVENUE,
      data: {
        selectedOptions: [''],
        ...conditions,
      },
    };
    this.props.updateFiltersItems(filterObj);
  }

  handleRangeChange = ({ min, max }) => {
    this.setState({
      rangeFrom: min,
      rangeTo: max,
    },
      this.updateFilter
    );
  }

  setComparisonOperator = ({ value }) => {
    this.setState({
      comparisonOperator: value
    },
      this.updateFilter
    );
  }

  setRevenue = ({ target }) => {
    const newRevenue = extractNumberFromBudget(target.value);
    const formattedNewRevenue = exchangeToUSD(newRevenue);
    this.setState({
      revenue: formattedNewRevenue
    },
      this.updateFilter
    );
  }

  get isBetweenView() {
    return this.state.comparisonOperator === BETWEEN;
  }

  render() {
    const { onCancel } = this.props;
    const {
      comparisonOperator, revenue, rangeFrom, rangeTo,
    } = this.state;
    const formattedRevenue = formatBudget(revenue);
    return (
      <div className={styles.filterConfig}>
        <div className={styles.filterContent}>
          <header className={styles.filterHeader}>
            Find journeys which
          </header>
          <div style={{ marginBottom: 10 }}>
            Revenue
          </div>
          <div className={styles.rowCenter}>
            <Dropdown
              onChange={this.setComparisonOperator}
              options={Object.entries({ ...COMPARISON_OPERATORS, [BETWEEN]: 'Is between' })
                .map(([value, label]) => ({ value, label }))}
              selectedKey={comparisonOperator}
              controlWidth={210}
            />
            {!this.isBetweenView && (
              <Textfield
                value={formattedRevenue}
                style={{ marginLeft: 25, width: 100 }}
                onChange={this.setRevenue}
              />
            )}
          </div>
          {this.isBetweenView && (
            <Range
              step={10}
              minValue={0}
              maxValue={MAX}
              value={{ min: rangeFrom, max: rangeTo }}
              onChange={this.handleRangeChange}
              outerStyle={{
                marginTop: 15,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          )}
        </div>
        <FilterActions
          onApply={this.handleFilterApply}
          onCancel={onCancel}
          filtersItems={this.props.filtersItems}
        />
      </div>
    );
  }
}
