exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".suggestions__toggle-2fn{display:inline-flex;margin-right:16px;align-self:flex-start;margin-top:-4px}.suggestions__head-5WK{display:flex;gap:12px;align-items:center;padding:0 16px;margin-bottom:12px}.suggestions__suggestionsIcon-2L5{background:#ffeee9 url(/assets/questions/suggestions.svg) 50% no-repeat;height:32px;width:32px;border-radius:4px}.suggestions__title-1QI{color:#99a4c2;font-size:16px;font-style:normal;font-weight:600;display:flex;align-items:center;gap:8px}.suggestions__suggestionItemWrapper-1UZ{display:flex;align-items:start;padding:12px 16px;gap:8px}.suggestions__suggestionItemWrapper-1UZ .suggestions__active-3VM,.suggestions__suggestionItemWrapper-1UZ:hover{background-color:var(--lightGrayBackground)}.suggestions__suggestionItem-1uh{cursor:pointer;font-size:14px;display:inline-flex;gap:4px;flex-wrap:wrap;width:calc(100% - 32px)}.suggestions__suggestionItem-1uh>div{padding:4px 0;position:relative}.suggestions__suggestionItem-1uh>div:last-child{flex-grow:1}.suggestions__showMore-3ln{margin:12px 16px}.suggestions__loadingWrapper-MAk{display:flex;min-height:200px;justify-content:center;align-items:center}.suggestions__timeframeDropdown-1QE{position:relative}.suggestions__channelItemWrap-1zn{display:flex;align-items:center}.suggestions__channelNickname-3JW{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:block}.suggestions__text-14-{white-space:nowrap}.suggestions__suggestionItem-1uh .suggestions__segmentsSelectorPopupPopup-Hj-{top:32px}.suggestions__betaTag-2CE{background:#5e8ff4;border-radius:4px;color:#fff;font-size:12px;padding:4px 8px;align-items:center}.suggestions__suggestionItemWrapper-1UZ .suggestions__checkbox-1JM{margin:4px 0 0}.suggestions__suggestionsSkeletonList-nw1{display:flex;gap:20px;flex-direction:column;padding:12px 16px}", ""]);

// exports
exports.locals = {
	"toggle": "suggestions__toggle-2fn",
	"toggle": "suggestions__toggle-2fn",
	"head": "suggestions__head-5WK",
	"head": "suggestions__head-5WK",
	"suggestionsIcon": "suggestions__suggestionsIcon-2L5",
	"suggestionsIcon": "suggestions__suggestionsIcon-2L5",
	"title": "suggestions__title-1QI",
	"title": "suggestions__title-1QI",
	"suggestionItemWrapper": "suggestions__suggestionItemWrapper-1UZ",
	"suggestionItemWrapper": "suggestions__suggestionItemWrapper-1UZ",
	"active": "suggestions__active-3VM",
	"active": "suggestions__active-3VM",
	"suggestionItem": "suggestions__suggestionItem-1uh",
	"suggestionItem": "suggestions__suggestionItem-1uh",
	"showMore": "suggestions__showMore-3ln",
	"showMore": "suggestions__showMore-3ln",
	"loadingWrapper": "suggestions__loadingWrapper-MAk",
	"loadingWrapper": "suggestions__loadingWrapper-MAk",
	"timeframeDropdown": "suggestions__timeframeDropdown-1QE",
	"timeframeDropdown": "suggestions__timeframeDropdown-1QE",
	"channelItemWrap": "suggestions__channelItemWrap-1zn",
	"channelItemWrap": "suggestions__channelItemWrap-1zn",
	"channelNickname": "suggestions__channelNickname-3JW",
	"channelNickname": "suggestions__channelNickname-3JW",
	"text": "suggestions__text-14-",
	"text": "suggestions__text-14-",
	"segmentsSelectorPopupPopup": "suggestions__segmentsSelectorPopupPopup-Hj-",
	"segmentsSelectorPopupPopup": "suggestions__segmentsSelectorPopupPopup-Hj-",
	"betaTag": "suggestions__betaTag-2CE",
	"betaTag": "suggestions__betaTag-2CE",
	"checkbox": "suggestions__checkbox-1JM",
	"checkbox": "suggestions__checkbox-1JM",
	"suggestionsSkeletonList": "suggestions__suggestionsSkeletonList-nw1",
	"suggestionsSkeletonList": "suggestions__suggestionsSkeletonList-nw1"
};