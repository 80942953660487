import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Spinner from 'components/pages/journeys/Spinner';

import { getGoalsOptionsList } from 'components/pages/settings/actions/logic/actions';
import { widgetTypes } from 'components/pages/analyze/enums';
import { activityStatusValues } from 'components/pages/settings/goals/logic/enums';
import { getWidgetsDataV2, getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function GoalsTrigger({
  triggerRules,
  updateTriggerRules,
  goalsAnalysis,
  getWidgetRequestId,
}) {
  useStyles([style]);

  const [goalsListOptions, setGoalsListOptions] = useState([]);
  const [isGoalsListOptionsLoaded, setIsGoalsListOptionsLoaded] = useState(false);

  useEffect(() => {
    getWidgetsDataV2({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: {
        goalsAnalysisParams: {
          configOnly: true,
          status: activityStatusValues.ACTIVE,
          timeframe: {},
        },
      },
      addBaseAnalyzeConfig: false,
    });
  }, []);

  useEffect(() => {
    const goalsAnalysisFullConfig = getWidgetFullConfig({
      widgetConfig: {
        goalsAnalysisParams: {
          configOnly: true,
          status: activityStatusValues.ACTIVE,
          timeframe: {},
        },
      },
      addBaseAnalyzeConfig: false,
    });
    const goalsAnalysisRequestId = getWidgetRequestId({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: goalsAnalysisFullConfig,
    });

    const goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];
    const isGoalAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished';
    if (isGoalAnalysisLoaded) {
      const listOptions = getGoalsOptionsList({ goals: goalsAnalysisData });
      setGoalsListOptions(listOptions);
      setIsGoalsListOptionsLoaded(true);
    }
  }, [goalsAnalysis]);

  function updateTriggerRuleData({ goalId }) {
    const updatedTriggerRules = [{ goalId }];
    updateTriggerRules(updatedTriggerRules);
  }

  const selectedGoalId = triggerRules[0]?.goalId;

  return (
    <div className={styles.ruleWrapper}>
      <div className={classNames(styles.row, styles.alignTop)}>
        <div className={styles.innerRow}>
          <div className={styles.text}>
            If
          </div>
          <Dropdown
            selectedKey={selectedGoalId}
            options={goalsListOptions || []}
            onChange={(value) => updateTriggerRuleData({ goalId: value.value })}
            controlWidth={300}
            disabled={!isGoalsListOptionsLoaded}
            isSearchable
          />
          {!isGoalsListOptionsLoaded ? (
            <Spinner />
          ) : null}
          <div className={styles.text}>
            changes to ‘On track’ or ‘Off track’
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject(
  ({
    widgetsAnalysisStore: {
      dataPerWidget: {
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
      } = {},
      getWidgetRequestId,
    } = {},
  }) => ({
    goalsAnalysis,
    getWidgetRequestId,
  }),
  observer
)(GoalsTrigger);
