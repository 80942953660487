import React from 'react';
import ReactDOM from 'react-dom';
import stores from 'stores';
import { Provider } from 'mobx-react';
import { Route, Router } from 'react-router';
import history from 'history';

import DashboardNavigate from 'components/pages/dashboard/dashboardNavigate/index';
import Overview from 'components/pages/analyze/OverviewTab';
import Content from 'components/pages/analyze/ContentTab/Content';
import Channels from 'components/pages/analyze/ChannelsTab/Channels';
import CampaignsMeasure from 'components/pages/analyze/CampaignsTab/Campaigns';
import Segments from 'components/pages/analyze/SegmentsTab';
import Setup from 'components/pages/attribution/Setup';
import AttributionLink from 'components/pages/AttributionLink';
import Workflows from 'components/pages/settings/workflows';
import Actions from 'components/pages/settings/actions';
import SiteStructure from 'components/pages/attribution/SiteStructure';
import AnnualTab from 'components/pages/plan/AnnualTab';
import Settings from 'components/pages/Settings';
import Login from 'components/pages/signIn/Login';
import ForgotPassword from 'components/pages/signIn/ForgotPassword';
import ManualPlan from 'components/pages/ManualPlan';
import NoAnalyzeData from 'components/pages/analyze/NoAnalyzeData';
import ChannelsSettings from 'components/pages/settings/channels/Channels';
import Goals from 'components/pages/settings/goals/Goals';
import ChannelsTab from 'components/pages/settings/channels/tabs/ChannelsTab';
import UnmappedChannelsTab from 'components/pages/settings/channels/tabs/UnmappedChannelsTab';
import Journeys from 'components/pages/journeys';
import GoalsTab from 'components/pages/settings/goals/GoalsTab';
import dashboardPermissions from 'components/pages/campaigns/dashboardPermissions';
import Analyze from 'components/pages/Analyze';
import PlannedVsActual from 'components/pages/PlannedVsActual';
import App from 'components/App';
import Platforms from 'components/pages/Platforms';
import PlatformsIntegration from 'components/pages/PlatformsIntegration';
import CustomSegments from 'components/pages/settings/customSegments/CustomSegments';
import Plan from 'components/pages/Plan';
import Attribution from 'components/pages/Attribution';
import SignIn from 'components/pages/SignIn';
import Manual from 'components/pages/Manual';
import TechnologyStack from 'components/pages/TechnologyStack';
import TargetAudience from 'components/pages/TargetAudience';
import Preferences from 'components/pages/Preferences';
import Budget from 'components/pages/Budget';
import Welcome from 'components/pages/Welcome';
import Product from 'components/pages/Product';
import Dashboard from 'components/pages/Dashboard';
import SetupBudget from 'components/pages/budget/SetupBudget';
import AddChannels from 'components/pages/budget/AddChannels';
import ImportBudget from 'components/pages/budget/ImportBudget';
import Reports from 'components/pages/reports';
import InsightsOverview from 'components/pages/insights/overviewTab/InsightsOverview';
import AiPrompts from 'components/pages/insights/aiPromptsTab';
import Insights from 'components/pages/insights/Insights';
import Questions from 'components/pages/questions/Questions';
import AIAnswers from 'components/pages/questions/AIAnswers';
import QuestionResults from 'components/pages/questions/QuestionResults';
import WidgetBuilder from 'components/pages/widgetBuilder/WidgetBuilder';
import NewReport from 'components/pages/reports/NewReport';
import GettingStarted from 'components/pages/onboarding';
import WhatIfTab from 'components/pages/analyze/whatIfTab';
import OptimalJourneyTab from 'components/pages/analyze/optimalJourneyTab';

import { userPermittedToPage } from 'utils';
import servicesStore from 'stores/servicesStore';

import style from 'styles/global/main.css';

style.use();

const loginRedirect = (...routeArgs) => {
  const [nextState, replace] = routeArgs;
  const { query, pathname } = nextState.location;
  replace({
    pathname: '/',
    query: {
      ...query,
      redirect_to: encodeURI(pathname),
    },
  });
};

// validate authentication for private routes
const requireAdminAuth = (...routeArgs) => {
  if (!servicesStore.authService.isAuthenticated() || !servicesStore.authService.getProfileSync()?.app_metadata?.isAdmin) {
    servicesStore.authService.logout();
    loginRedirect(...routeArgs);
  }
};

// validate authentication for private routes
const requireAuth = (...routeArgs) => {
  if (!servicesStore.authService.isAuthenticated()) {
    loginRedirect(...routeArgs);
  }
};

// Validate permission on the page
const requirePermission = (page, ...routeArgs) => {
  if (!servicesStore.authService.isAuthenticated() || !servicesStore.authService.getProfileSync().app_metadata) {
    loginRedirect(...routeArgs);
  } else if (!userPermittedToPage(page)) {
    const replace = routeArgs[1];
    replace({ pathname: '/campaigns/online-performance' });
  }
};

// only unauthenticated users may access these routes
const redirectIfAuthenticated = (...routeArgs) => {
  if (servicesStore.authService.isAuthenticated()) {
    const replace = routeArgs[1];
    replace({ pathname: '/' });
  }
};

const onUpdate = () => {
  window.scrollTo(0, 0);
  servicesStore.eventTracker?.page();
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider {...stores}>
    <Router onUpdate={onUpdate} history={history}>
      <Route path="/login" component={Login} onEnter={redirectIfAuthenticated} />
      <Route path="/forgotPassword" component={ForgotPassword} onEnter={redirectIfAuthenticated} />
      <Route path="/" component={SignIn} />
      <Route path="/loginCallBack" onEnter={() => servicesStore.authService.crossOriginVerification()} />
      <Route component={App} onEnter={requireAuth}>
        <Route
          component={dashboardPermissions(Dashboard)}
          onEnter={(...routeArgs) => requirePermission('dashboard', ...routeArgs)}
        >
          <Route path="/dashboard/navigate" component={DashboardNavigate} onEnter={requireAuth} tabName="Navigate" />
        </Route>
        <Route path="/profile/technology-stack" component={TechnologyStack} onEnter={requireAdminAuth} />
        <Route path="/manual" component={Manual} onEnter={requireAdminAuth} />
        <Route path="/build-first-plan" component={ManualPlan} onEnter={requireAdminAuth} />
        <Route path="/build-budget" component={Budget} onEnter={requireAdminAuth} />
        <Route path="/import-budget" component={ImportBudget} onEnter={requireAdminAuth} />
        <Route path="/add-channels" component={AddChannels} onEnter={requireAdminAuth} />
        <Route path="/build-budget-setup" component={SetupBudget} onEnter={requireAdminAuth} />
        <Route component={Plan} onEnter={(...routeArgs) => requirePermission('plan', ...routeArgs)}>
          <Route path="/plan/annual" component={AnnualTab} onEnter={requireAuth} tabName="Budget" />
          <Route
            path="/plan/plans-vs-actuals"
            component={PlannedVsActual}
            onEnter={requireAuth}
            tabName="Plans vs Actuals"
          />
        </Route>
        <Route component={Settings} onEnter={requireAuth}>
          <Route path="/settings/account" component={Welcome} onEnter={requireAuth} />
          <Route component={Attribution} onEnter={requireAuth}>
            <Route path="/settings/attribution/setup" component={Setup} onEnter={requireAuth} tabName="Setup" />
            <Route
              path="/settings/attribution/site-structure"
              component={SiteStructure}
              onEnter={requireAuth}
              tabName="Site Structure"
            />
          </Route>
          <Route component={Goals} onEnter={requireAuth}>
            <Route
              path="/settings/goals/goals-main"
              component={GoalsTab}
              onEnter={requireAuth}
              tabName="Goals"
            />
          </Route>
          <Route path="/settings/profile/product" component={Product} onEnter={requireAuth} />
          <Route
            path="/settings/profile/preferences"
            component={Preferences}
            onEnter={requireAuth}
          />
          <Route
            path="/settings/profile/target-audience"
            component={TargetAudience}
            onEnter={requireAuth}
          />
          <Route component={PlatformsIntegration} onEnter={requireAuth}>
            <Route
              path="/settings/profile/integrations"
              component={Platforms}
              onEnter={requireAuth}
              tabName="Integrations"
            />
          </Route>
          <Route component={ChannelsSettings} onEnter={requireAuth}>
            <Route
              path="/settings/ops/workflows"
              component={Workflows}
              onEnter={requireAuth}
              tabName="Workflows"
            />
            <Route
              path="/settings/ops/channels"
              component={ChannelsTab}
              onEnter={requireAuth}
              tabName="Channels"
            />
            <Route
              path="/settings/ops/custom-segments"
              component={CustomSegments}
              onEnter={requireAuth}
              tabName="Custom Segments"
            />
            <Route
              path="/settings/ops/unmapped"
              component={UnmappedChannelsTab}
              onEnter={requireAuth}
              tabName="Unmapped Attribution"
            />
            <Route
              path="/settings/ops/alerts"
              component={Actions}
              onEnter={requireAuth}
              tabName="Alerts"
            />
          </Route>
        </Route>
        <Route component={Analyze} onEnter={(...routeArgs) => requirePermission('analyze', ...routeArgs)}>
          <Route path="/analyze/overview" component={Overview} onEnter={requireAuth} tabName="Overview" />
          <Route path="/analyze/channels" component={Channels} onEnter={requireAuth} tabName="Channels" />
          <Route path="/analyze/campaigns" component={CampaignsMeasure} onEnter={requireAuth} tabName="Campaigns" />
          <Route path="/analyze/content" component={Content} onEnter={requireAuth} tabName="Content" />
          <Route path="/analyze/segments" component={Segments} onEnter={requireAuth} tabName="Segments" />
          <Route path="/analyze/journeys" component={Journeys} onEnter={requireAuth} tabName="Journeys" />
          <Route path="/analyze/what-if" component={WhatIfTab} onEnter={requireAuth} tabName="What If" />
          <Route path="/analyze/optimal-journey" component={OptimalJourneyTab} onEnter={requireAuth} tabName="Optimal Journey" />
        </Route>
        <Route path="/no-analyze-data" component={NoAnalyzeData} onEnter={requireAdminAuth} />
        <Route path="/reports/new" component={NewReport} onEnter={requireAuth} tabName="Reports" />
        <Route path="/reports" component={Reports} onEnter={requireAuth} tabName="Reports" />
        <Route path="/widget-builder" component={WidgetBuilder} onEnter={requireAuth} tabName="Reports" />
        <Route component={Insights} onEnter={requireAuth}>
          <Route path="/insights/overview" component={InsightsOverview} onEnter={requireAuth} tabName="Overview" />
          <Route path="/insights/ai-prompts" component={AiPrompts} onEnter={requireAuth} tabName="AI Prompts" />
        </Route>
        <Route path="/questions" component={Questions} onEnter={requireAuth} />
        <Route path="/questions/results" component={QuestionResults} onEnter={requireAuth} />
        <Route path="/answers" component={AIAnswers} onEnter={requireAuth} />
        <Route path="/getting-started" component={GettingStarted} onEnter={requireAuth} />
      </Route>
      <Route component={AttributionLink} path="/attribution/:UID" />
    </Router>
  </Provider>,
  document.querySelector('#main')
);
