import React, { useRef } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';
import MultiSelectPopup from 'components/pages/integrations/MultiSelectPopup';

import { SALESFORCE, CRM_OBJECT, getFieldOptionsFromCRM } from 'components/utils/indicators';
import { onUpdateFunnelRuleSelectValue } from 'components/pages/integrations/logic/CRMfunnelRuleItem';
import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals || {};

function CRMfunnelRuleItem({
  selectedFunnelId, ruleIndex, isAdvancedOptions, deleteFunnelRule, disabled,
}) {
  const {
    mappingData = {}, funnelMapping = {}, setParentState, selectedCRMItems,
  } = useCRMCContext();
  const popupRef = useRef();

  const onUpdateSelectValue = (ruleKey, value) => {
    if (disabled) {
      return;
    }

    const updateFunnelMapping = onUpdateFunnelRuleSelectValue(ruleKey, value, ruleIndex, funnelMapping, selectedFunnelId);
    setParentState({ funnelMapping: updateFunnelMapping, isFunnelMappingChanged: true });
  };

  const onUpdatesaveSettings = (funnelKey, options) => {
    if (disabled) {
      return;
    }

    const selectedValues = options.map((option) => option.value);
    onUpdateSelectValue(funnelKey, selectedValues);
  };

  const truncText = (str, n = 15) => ((str && str.length) > n ? `${str.substr(0, n - 1)}...` : str);

  const getMultiSelectButtonText = (optionValues, defaultText = '') => {
    if (optionValues) {
      if (optionValues.length > 1) {
        return `${truncText(optionValues[0])}, +${optionValues.length - 1}`;
      } else if (optionValues.length === 1) {
        return `${truncText(optionValues[0])}`;
      } else {
        return `${truncText(defaultText)}`;
      }
    } else {
      return '';
    }
  };

  const currentFunnelMappingRule = get(funnelMapping, [selectedFunnelId, 'rules', ruleIndex], {});
  const {
    field, objectType, values = [], dateField, fallbackDateField,
  } = currentFunnelMappingRule;

  const crmPlatform = get(funnelMapping, [selectedFunnelId, 'crmPlatform'], selectedCRMItems[0]);
  const crmPlatformObjectsOptions = CRM_OBJECT[crmPlatform].map((item) => ({ value: item, label: item }));
  const fieldOptionsFromCRM = getFieldOptionsFromCRM(crmPlatform, mappingData, objectType);
  const funnelFieldOptionsForSelect = fieldOptionsFromCRM.map(({ name: value, label }) => ({ value, label }));

  let funnelValueOptions = [];
  const fieldData = fieldOptionsFromCRM.length && fieldOptionsFromCRM.find(({ name }) => name === field);
  if (fieldData) {
    const options = [];
    if (crmPlatform === SALESFORCE) {
      if (fieldData.type === 'boolean') {
        options.push({
          label: 'False', value: false,
        }, {
          label: 'True', value: true,
        });
      } else {
        options.push(...get(fieldData, 'picklistValues', []));
      }
    } else {
      options.push(...get(fieldData, 'options', []));
    }

    funnelValueOptions = [...options, { label: 'Any', value: 'ANY' }];
  }
  const optionsValueToLabelLookup = funnelValueOptions.reduce((acc, opt) => {
    acc[opt.value] = opt.label;
    return acc;
  }, {});

  const getFunnelValueOptions = (vals) => vals.reduce((acc, selectedVal) => {
    if (optionsValueToLabelLookup[selectedVal]) {
      acc.push({
        value: selectedVal,
        label: optionsValueToLabelLookup[selectedVal],
      });
    }
    return acc;
  }, []);
  const selectedFunnelValueOptions = Array.isArray(values) ? getFunnelValueOptions(values) : [];

  return (
    <>
      <div className={styles.flexRow} data-testid="object-type">
        <div className={classNames(styles.fieldText, styles.fieldHalf)}>
          Object
          <InfoMarker tooltipText={'Choose the CRM object that stores this funnel stage\'s data.'} TooltipProps={{ place: 'right' }} />
        </div>
        <Dropdown
          isClearable
          className={styles.fieldHalf}
          selectedKey={objectType}
          placeholder="Choose Object"
          options={crmPlatformObjectsOptions}
          onChange={(e) => onUpdateSelectValue('objectType', e && e.value)}
          disabled={disabled}
          isSearchable
        />
      </div>

      <div className={styles.flexRow} data-testid="field-type">
        <div className={classNames(styles.fieldText, styles.fieldHalf)}>
          Field
          <InfoMarker tooltipText={'Choose the CRM field that stores this funnel stage\'s data.'} TooltipProps={{ place: 'right' }} />
        </div>
        <Dropdown
          isClearable
          className={styles.fieldHalf}
          selectedKey={field}
          placeholder="Choose Field"
          options={funnelFieldOptionsForSelect}
          onChange={(e) => onUpdateSelectValue('field', e && e.value)}
          disabled={disabled}
          isSearchable
        />
      </div>

      <div className={styles.flexRow}>
        <div className={classNames(styles.fieldText, styles.fieldHalf)}>
          Value/s
          <InfoMarker tooltipText={'Choose the field\'s values that represents this funnel stage.'} TooltipProps={{ place: 'right' }} />
        </div>
        <div className={classNames(styles.fieldHalf, styles.relative)}>
          <Button
            containerClassName={styles.contClassName}
            className={styles.arrowButton}
            onClick={() => popupRef.current.open()}
            disabled={disabled}
          >
            {getMultiSelectButtonText(selectedFunnelValueOptions.map((o) => o.label), 'Select...')}
          </Button>
          <MultiSelectPopup
            ref={popupRef}
            title=""
            className={styles.multiSelectPopupPopup}
            options={funnelValueOptions}
            selected={selectedFunnelValueOptions}
            saveSettings={(selectValues) => onUpdatesaveSettings('values', selectValues)}
            maxHeight={250}
            disabled={disabled}
          />
        </div>
      </div>
      {isAdvancedOptions
        && (
        <>
          <div className={styles.flexRow} data-testid="date-field">
            <div className={classNames(styles.fieldText, styles.fieldHalf)}>
              Date
              <InfoMarker tooltipText={'Choose the date field that stores information about this funnel stage\'s transition date.'} TooltipProps={{ place: 'right' }} />
            </div>
            <Dropdown
              isClearable
              className={styles.fieldHalf}
              selectedKey={dateField}
              placeholder="Choose date"
              options={funnelFieldOptionsForSelect}
              onChange={(e) => onUpdateSelectValue('dateField', e && e.value)}
              disabled={disabled}
              isSearchable
            />
          </div>

          <div className={styles.flexRow} data-testid="fallback-date-field">
            <div className={classNames(styles.fieldText, styles.fieldHalf)}>
              Fallback date
              <InfoMarker tooltipText="If the above Date field is empty, which date field should be used by default?" TooltipProps={{ place: 'right' }} />
            </div>
            <Dropdown
              isClearable
              className={styles.fieldHalf}
              selectedKey={fallbackDateField}
              placeholder="Choose fallback date"
              options={funnelFieldOptionsForSelect}
              onChange={(e) => onUpdateSelectValue('fallbackDateField', e && e.value)}
              disabled={disabled}
              isSearchable
            />
          </div>
          {ruleIndex
            ? (
              <Button
                type="primaryRed"
                onClick={() => deleteFunnelRule(ruleIndex)}
                disabled={disabled}
              >
                Remove rule
              </Button>
            )
            : null }
        </>
        )}
    </>
  );
}

export default CRMfunnelRuleItem;
