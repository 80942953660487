import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import history from 'history';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { injectStyle } from 'react-toastify/dist/inject-style';

import Component from 'components/Component';
import Page from 'components/Page';
import dashboardStore from 'stores/dashboardStore';
import { EXTRA_CHANNEL } from 'components/pages/PlannedVsActual';
import { fiscalData } from 'components/utils/date';
import { getChannelsWithProps } from 'components/utils/channels';
import { compose } from '../utils/utils';

import style from 'styles/page.css';

const enhance = compose(
  inject(({
    filterStore: {
      isFilterDataLoaded,
      getUserFiltersData,
    },
  }) => ({
    isFilterDataLoaded,
    getUserFiltersData,
  })),
  observer
);

class Dashboard extends Component {
  style = style;

  componentDidMount() {
    injectStyle();
    if (!this.props.isFilterDataLoaded) {
      this.props.getUserFiltersData();
    }
  }

  toastMessageAccountWithoutData = () => (
    <>
      To start seeing actual data, please
      {' '}
      <span className={this.classes.toastWarningLink} onClick={() => history.push({ pathname: '/settings/profile/integrations' })}>connect your CRM</span>
    </>
  );

  toastWarningAccountWithoutData = () => {
    toast(this.toastMessageAccountWithoutData(), {
      className: this.classes.toastInfoWarning,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      toastId: 'toastWarning',
      icon: () => <img src="/assets/analyze-icons/info-warning-icon.svg" />,
    });
  }

  render() {
    if (dashboardStore.accountWithoutData) {
      this.toastWarningAccountWithoutData();
    }

    const { fiscalYearFirstMonth } = this.props;
    const { pathname } = this.props.router.location;
    const isNavigate = pathname === '/dashboard/navigate';
    const fiscalTimeData = fiscalData(fiscalYearFirstMonth);

    const channelsPropsObject = {
      ...getChannelsWithProps(),
      [EXTRA_CHANNEL]: {
        category: EXTRA_CHANNEL,
        department: 'test',
        nickname: 'test',
        title: 'test',
        isUnknownChannel: false,
      },
    };
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        ...this.props,
        ...fiscalTimeData,
        channelsPropsObject,
        fiscalYearFirstMonth,
      }));
    return (
      <div>
        <Page width="100%">
          <div
            className={classnames(this.classes.container, {
              [this.classes.containerNavigate]: isNavigate,
            })}
          >
            {dashboardStore.accountWithoutData
              && (
                <ToastContainer
                  limit={1}
                  style={{ width: '400px', fontSize: '12px' }}
                  position="bottom-center"
                  autoClose={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable={false}
                  transition={Slide}
                />
              )}
            {!isNavigate && (
              <div className={this.classes.contentHead}>
                <div className={this.classes.contentHeadTitle}>
                  Dashboard
                </div>
              </div>
            )}
            {childrenWithProps}
          </div>
        </Page>
      </div>
    );
  }
}

export default enhance(Dashboard);
