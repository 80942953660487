import classNames from 'classnames';
import React from 'react';
import attributionStyle from 'styles/analyze/analyze-attribution-table.css';
import ComparisonValue from 'components/common/ComparisonValue';
import Skeleton from 'components/common/Skeleton';
import IndicationTag from 'components/common/IndicationTag';

import { skeletonSmallTableCellSizes } from 'components/common/enums';
import { formatNumberWithDecimalPoint, formatNumberAvgRatioWithDecimalPoint, formatNumberWithCommas } from 'components/utils/logic/budget';

const style = attributionStyle.locals;

export default function ExpendCell({
  itemValue,
  shareOfTotal,
  average,
  itemValueFormatter,
  isLowerBetter,
  trendValues = {},
  isLoaded,
  isLoadedTrendData,
  withTrend = true,
  showShareOfTotal = true,
  growthValue,
  isGrowthDesired,
}) {
  if (itemValue == null) {
    return null;
  }

  const shareOfTotalPercent = shareOfTotal ? formatNumberWithDecimalPoint((itemValue / shareOfTotal) * 100, 1) : itemValue;
  const averagePercent = ((itemValue - average) / average) * 100;
  const formatAveragePercent = formatNumberWithDecimalPoint(averagePercent, 0);
  const isValuePositive = isLowerBetter ? formatAveragePercent <= 0 : formatAveragePercent >= 0;
  const trendFormatAvgRatioValue = formatNumberAvgRatioWithDecimalPoint({ value: trendValues?.trendAvgRatioValue, numberOfDigitsAfterPoint: 1 });
  const trendFormatValue = formatNumberWithDecimalPoint(trendValues?.trendValue, 1);
  const isTrendPositive = isLowerBetter ? trendFormatValue <= 0 : trendFormatValue >= 0;
  const itemValueFormatted = itemValueFormatter ? itemValueFormatter(itemValue) : itemValue;

  return (
    <div className={style.expendOpen}>
      <div className={style.expendWidthValues}>
        {!isLoaded ? (
          <Skeleton {...skeletonSmallTableCellSizes} />
        ) : (
          <>
            <div className={style.expendValue}>
              {formatNumberWithCommas({ number: itemValueFormatted })}

              <ComparisonValue
                value={growthValue}
                className={style.comparisonValue}
                isGrowthDesired={isGrowthDesired}
              />
            </div>

            <div className={style.expendCellTagsWrapper}>
              {shareOfTotal >= 0 && showShareOfTotal && (
              <div className={style.expendCellTag}>
                {`${shareOfTotalPercent}%`}
              </div>
              )}

              {(shareOfTotalPercent > 0) && (
              <div
                className={classNames(
                  style.expendCellShareOfTotalTag,
                  isValuePositive ? null : style.expendCellShareOfTotalNegativeTag
                )}
              >
                <b>{`${formatAveragePercent > 0 ? '+' : ''}${formatAveragePercent}%`}</b>
                {' vs. avg'}
              </div>
              )}
            </div>
          </>
        )}
      </div>

      {withTrend && (
        <div className={style.expendWidthTrends}>
          {isLoadedTrendData
            ? (
              <>
                {(trendValues && formatNumberWithDecimalPoint(itemValue, 1) > 0) ? (
                  <div className={isTrendPositive ? style.expendCellTrendTagUp : style.expendCellTrendTagDown}>
                    <b>{`${trendFormatValue > 0 ? '+' : ''}${trendFormatValue}%`}</b>
                    {` (${trendFormatAvgRatioValue}x vs. avg)`}
                  </div>
                ) : <IndicationTag text="No trend" tooltip="This metric’s trendline could not be calculated. Trends require 3 months of non-zero values." tooltipPosition="right" />}
              </>
            )
            : <Skeleton {...skeletonSmallTableCellSizes} />}
        </div>
      )}
    </div>
  );
}
