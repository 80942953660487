exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".insights-card__content-1E7{display:flex;justify-content:space-between;width:100%;max-width:260px;flex-wrap:nowrap}.insights-card__borderWrap-1FB{background:#fff;box-shadow:0 1px 3px rgba(54,56,64,.101647);border-radius:4px;padding:16px;width:100%;margin:16px 0 0}.insights-card__title-3ml{font-weight:600;font-size:11px;line-height:21px;text-transform:uppercase;color:#707ea7;margin:0}.insights-card__text-3ka{font-weight:400;font-size:14px;line-height:21px;color:#707ea7;margin:4px 0 16px}.insights-card__text-3ka span{font-weight:700}.insights-card__staticGroup-3fl{display:flex;flex-direction:column}.insights-card__arrowUpGroup-2qO{display:flex;margin-bottom:12px}.insights-card__arrowUp-2Uc{background:url(/assets/plan_icons/arrowUp.svg) 50% no-repeat;width:10px;height:8px;margin:auto 4px auto 0}.insights-card__arrowDown-m-N{background:url(/assets/plan_icons/arrowDown.svg) 50% no-repeat;width:10px;height:8px;margin:auto 4px auto 0}.insights-card__percent-2zl{color:#2fcf5f}.insights-card__percent-2zl,.insights-card__percentNegative-2j3{font-weight:700;font-size:12px;line-height:16px;letter-spacing:.0545455px}.insights-card__percentNegative-2j3{color:#ee2032}.insights-card__arrowGroupText-2G8{font-weight:400;font-size:10px;line-height:14px;color:#707ea7;margin:auto 0 auto 16px;text-align:left}.insights-card__arrowDownGroup-1YA{display:flex}@media (max-width:741px){.insights-card__content-1E7{margin:16px auto}}", ""]);

// exports
exports.locals = {
	"content": "insights-card__content-1E7",
	"content": "insights-card__content-1E7",
	"borderWrap": "insights-card__borderWrap-1FB",
	"borderWrap": "insights-card__borderWrap-1FB",
	"title": "insights-card__title-3ml",
	"title": "insights-card__title-3ml",
	"text": "insights-card__text-3ka",
	"text": "insights-card__text-3ka",
	"staticGroup": "insights-card__staticGroup-3fl",
	"staticGroup": "insights-card__staticGroup-3fl",
	"arrowUpGroup": "insights-card__arrowUpGroup-2qO",
	"arrowUpGroup": "insights-card__arrowUpGroup-2qO",
	"arrowUp": "insights-card__arrowUp-2Uc",
	"arrowUp": "insights-card__arrowUp-2Uc",
	"arrowDown": "insights-card__arrowDown-m-N",
	"arrowDown": "insights-card__arrowDown-m-N",
	"percent": "insights-card__percent-2zl",
	"percent": "insights-card__percent-2zl",
	"percentNegative": "insights-card__percentNegative-2j3",
	"percentNegative": "insights-card__percentNegative-2j3",
	"arrowGroupText": "insights-card__arrowGroupText-2G8",
	"arrowGroupText": "insights-card__arrowGroupText-2G8",
	"arrowDownGroup": "insights-card__arrowDownGroup-1YA",
	"arrowDownGroup": "insights-card__arrowDownGroup-1YA"
};