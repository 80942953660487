import React, { useEffect, useState } from 'react';
import Page from 'components/Page';
import integrationsStyle from 'styles/integrations/integration.css';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import SaveButton from 'components/pages/profile/SaveButton';
import Spinner from 'components/pages/journeys/Spinner';
import serverCommunication from 'data/serverCommunication';
import UnsavedPopup from 'components/UnsavedPopup';
import { getDifferencesBetweenSaveAndSelectedFileds, parseCampaignsFieldsOptions } from './logic/SalesforceCampaignsSettingsPopup';

const styles = integrationsStyle.locals;

export default function SalesforceCampaignsSettingsPopup({ close, region, isActiveFlag, setIsActiveSalesforceCampaignsFlag }) {
  const [isLoading, setIsLoading] = useState({ loadingPage: false, loadingSubmit: false });
  const [salesforceCampaignsOptions, setSalesforceCampaignsOptions] = useState([]);
  const [selectedSalesforceCampaignsFields, setSelectedSalesforceCampaignsFields] = useState([]);
  const [savedSalesforceCampaignsFields, setSavedSalesforceCampaignsFields] = useState([]);
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState({ isUnsaved: false, showUnsavedPopup: false });

  const closePopup = () => {
    if (unsavedChanges.isUnsaved) {
      setUnsavedChanges({ ...unsavedChanges, showUnsavedPopup: true });
    } else {
      close();
    }
  };

  const unsavedPopupCallBack = (closeWithoutSaveChanges) => {
    if (closeWithoutSaveChanges) {
      close();
    } else {
      setUnsavedChanges({ ...unsavedChanges, showUnsavedPopup: false });
    }
  };

  const getSalesforceCampaignsFieldsOptionsRequest = async () => {
    setIsLoading({ ...isLoading, loadingPage: true });
    try {
      const response = await serverCommunication.serverRequest('GET', 'crm/salesforceCampaigns/fields', null, region);
      if (response.ok) {
        const responseData = await response.json();
        const fieldsOptions = parseCampaignsFieldsOptions(responseData.campaignsFields);
        setSalesforceCampaignsOptions(fieldsOptions);
        const filterdSelectedFields = fieldsOptions.filter((item) => item.selected);
        setSelectedSalesforceCampaignsFields(filterdSelectedFields);
        setSavedSalesforceCampaignsFields(filterdSelectedFields);
        setIsLoading({ ...isLoading, loadingPage: false });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, loadingPage: false });
      throw error;
    }
  };

  const updateSavedSalesforceCampaignsFieldsRequest = async (fieldsForRequest) => {
    try {
      await serverCommunication.serverRequest('PUT', 'crm/salesforceCampaigns/fields', JSON.stringify({
        fields: fieldsForRequest,
      }), region);
    } catch (error) {
      throw error;
    }
  };

  const updateSalesforceCampaignsFlagRequest = async (flagValue) => {
    try {
      const response = await serverCommunication.serverRequest('PUT', 'crm/salesforceCampaigns/flag', JSON.stringify({
        value: flagValue,
      }), region);
      if (response.ok) {
        setIsActiveSalesforceCampaignsFlag(flagValue);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getSalesforceCampaignsFieldsOptionsRequest();
  }, []);

  const onSelectSalesforceCampaignsFields = (selectedOptions) => {
    setSelectedSalesforceCampaignsFields(selectedOptions || []);
    setIsApplyButtonDisabled(false);
    if (!unsavedChanges.isUnsaved) {
      setUnsavedChanges({ ...unsavedChanges, isUnsaved: true });
    }
  };

  const handleSalesforceCampaignsApply = async () => {
    setIsLoading({ ...isLoading, loadingSubmit: true });
    const filedsItemsForRequest = getDifferencesBetweenSaveAndSelectedFileds(savedSalesforceCampaignsFields, selectedSalesforceCampaignsFields);
    if (filedsItemsForRequest.length) {
      await updateSavedSalesforceCampaignsFieldsRequest(filedsItemsForRequest);
    }
    if (!isActiveFlag) {
      await updateSalesforceCampaignsFlagRequest(true);
    }
    setIsLoading({ ...isLoading, loadingSubmit: false });
    setUnsavedChanges({ isUnsaved: false, showUnsavedPopup: false });
    close();
  };

  return (
    <>
      <Page popup width={500}>
        <div>
          <div className={styles.closePopup} onClick={closePopup} />
          <div className={styles.headTitle}>
            SFDC Campaigns Settings
          </div>
          <div className={styles.headSubTitle}>
            <p>
              Add the SFDC Campaigns fields you want to import into InfiniGrow.
              These fields will be used to map channels, campaigns and content.

            </p>
            <b><i>Note that the new fields you add will be available after the next CRM update.</i></b>
          </div>

          {isLoading.loadingPage ? (
            <div className={styles.salesforceCampaignsLoadingWrap}>
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                <MultiCheckSelect
                  selected={selectedSalesforceCampaignsFields}
                  options={salesforceCampaignsOptions}
                  placeholder="Select fields"
                  onChange={onSelectSalesforceCampaignsFields}
                  isDropDownSelect
                />
              </div>
              <div className={styles.salesforceCampaignsPopupApply}>
                {isLoading.loadingSubmit && <Spinner />}
                <SaveButton
                  style={{ padding: '0 24px' }}
                  saveButtonText={!isActiveFlag ? 'Activate' : 'Save'}
                  disabled={!isActiveFlag ? false : isApplyButtonDisabled}
                  onClick={handleSalesforceCampaignsApply}
                />
              </div>
            </>
          )}
        </div>
      </Page>
      <UnsavedPopup hidden={!unsavedChanges.showUnsavedPopup} callback={unsavedPopupCallBack} />
    </>
  );
}
