import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { getComponentAccordingWidgetType } from 'components/pages/reports/logic/reports';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function WidgetBuilderPreview({
  onSelectedWidgetProperties,
  selectedWidgetProperties,
  widgetConfig,
  isAllFilledAreFilledForRender,
  isAllFilledAreFilledForRequest,
  widgetProps,
  widgetDataResults,
}) {
  useStyles([style]);

  if (!isAllFilledAreFilledForRender) {
    return (
      <div className={classnames(styles.widgetPreview, styles.emptyWidgetPreview)}>
        Select parameters to display the widget
      </div>
    );
  }

  const WidgetComponent = getComponentAccordingWidgetType({ widgetType: widgetConfig.widgetType });
  const timeFrameLabel = getParseDateRangeLabel({ timeFrame: selectedWidgetProperties.timeFrame });

  return (
    <div className={styles.widgetPreview}>
      <WidgetComponent
        key={`widget-${widgetProps.type}`}
        {...widgetProps}
        {...widgetDataResults}
        isShowWidgetHeader={false}
        isEnableSkeletonAnimation={isAllFilledAreFilledForRequest}
        updateWidgetPropsValues={({ values }) => onSelectedWidgetProperties({ values, isRequestServerData: false })}
      />
      <div className={styles.timeFrameAsText}>
        {`Preview date range: ${timeFrameLabel}`}
      </div>
    </div>
  );
}

export default WidgetBuilderPreview;
