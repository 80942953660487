import React from 'react';
import Component from 'components/Component';
import classnames from 'classnames';

import Tooltip from 'components/controls/Tooltip';

import toggleStyle from 'styles/controls/toggle.css';

export default class Toggle extends Component {
  style = toggleStyle;

  static defaultProps = {
    options: [],
    selectedValue: null,
  };

  render() {
    const {
      options, selectedValue, onClick, style, bordered, frameClass, boxClass, disabled, minWidth = '100px', dataTestId = 'toggle',
    } = this.props;

    const toggles = options.map((item, index) => {
      const toggleKey = `toggle-${item.value}-${index}`;
      return (
        <Tooltip
          hidden={disabled}
          tip={item.tooltip}
          id={toggleKey}
          key={toggleKey}
          className={disabled ? this.classes.disabledTooltip : null}
        >
          <div
            key={item.value}
            className={classnames(this.classes.frame, item.style, frameClass, {
              [this.classes.frameBordered]: bordered,
            })}
            style={{ minWidth }}
            data-active={item.value === selectedValue ? true : null}
            onClick={() => !disabled && onClick(item.value)}
            data-testid={`${dataTestId}-${item.text}`}
          >
            <div className={this.classes.frameText}>
              {item.text}
              {item.icon}
              {item.isBetaVersion && <div className={this.classes.betaTag}>Beta</div>}
            </div>
          </div>
        </Tooltip>
      );
    });

    return (
      <div className={this.classes.inner} style={style}>
        <div className={classnames(this.classes.box, boxClass, disabled && this.classes.boxDisabled)}>
          {toggles}
        </div>
      </div>
    );
  }
}
