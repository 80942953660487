import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';

// import style from 'styles/profile/back-button.css';
import backButtonIcon from 'assets/prev-icon.svg';
import style from 'styles/profile/profile.css';

export default class BackButton extends Component {
  style = style;

  render() {
    return (
      <Button
        type="secondaryWhite"
        style={{
          width: '120px',
          letterSpacing: 0.075,
        }}
        onClick={() => this.props.onClick()}
        icon={backButtonIcon}
      >
        BACK
      </Button>
    );
  }
}
