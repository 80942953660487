export const UNMAPPED_QUERY_TYPE = {
  OFFLINE: 0,
  URLS: 1,
  UTMS: 2,
  ONLINE_CAMPAIGNS: 3,
};

export const getUnmappedKey = dataType => Object.keys(UNMAPPED_QUERY_TYPE).find(key => UNMAPPED_QUERY_TYPE[key] === dataType);

export const getUnmappedKeyName = (dataType) => {
  const key = getUnmappedKey(dataType);
  switch (key) {
    case 'OFFLINE':
      return 'unmappedOffline';
    case 'URLS':
      return 'unmappedUrls';
    case 'UTMS':
      return 'unmappedUtms';
    case 'ONLINE_CAMPAIGNS':
      return 'unmappedOnlineCampaigns';
  }
};

export const QUERY_LIMIT = 50;
