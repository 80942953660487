exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".insights__insighitsContainer-2wy{display:flex;flex-direction:column;gap:40px;padding:65px 80px 52px}.insights__relative-1rH{position:relative}.insights__widgetSpace-1FS{margin-top:26px}.insights__widgetSpace-1FS .insights__widgetTitle-1r2{margin-top:25px}.insights__widgetSpace-1FS .insights__widgetTitle-1r2 h2{color:#192032;font-size:30px;font-weight:600;line-height:20px;margin-bottom:16px}.insights__timeFrameDropdown-2C3{position:absolute;right:0;top:0;display:flex;gap:6px;align-items:center;z-index:10;top:12px}.insights__insightsPageDropdown-3KL>div{width:fit-content}.insights__grayBackground-3o7{background:#edeff5;min-height:calc(100vh - 400px);transition:all .6s ease-in-out}.insights__grayBackground-3o7>.insights__insighitsContainer-2wy{padding-bottom:80px}", ""]);

// exports
exports.locals = {
	"insighitsContainer": "insights__insighitsContainer-2wy",
	"insighitsContainer": "insights__insighitsContainer-2wy",
	"relative": "insights__relative-1rH",
	"relative": "insights__relative-1rH",
	"widgetSpace": "insights__widgetSpace-1FS",
	"widgetSpace": "insights__widgetSpace-1FS",
	"widgetTitle": "insights__widgetTitle-1r2",
	"widgetTitle": "insights__widgetTitle-1r2",
	"timeFrameDropdown": "insights__timeFrameDropdown-2C3",
	"timeFrameDropdown": "insights__timeFrameDropdown-2C3",
	"insightsPageDropdown": "insights__insightsPageDropdown-3KL",
	"insightsPageDropdown": "insights__insightsPageDropdown-3KL",
	"grayBackground": "insights__grayBackground-3o7",
	"grayBackground": "insights__grayBackground-3o7"
};