exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".addGoalStats__wrapper-1WK{max-width:300px;flex-basis:0;flex-grow:1;display:flex;padding:20px;flex-direction:column;gap:16px;background:#fff;box-shadow:0 4px 12px 0 #e6e8f0;border:2px dashed #99a4c2;border-radius:8px;justify-content:center;align-items:center;cursor:pointer}.addGoalStats__wrapper-1WK:hover{border:2px dashed #4a94ec;box-shadow:0 4px 34px 0 rgba(36,42,64,.13)}.addGoalStats__icon-1XV{width:44px;height:44px;background:url(/assets/plus-with-background.svg) 50% no-repeat}.addGoalStats__text-PQc{color:#182033;text-align:center;font-size:24px;font-weight:600}", ""]);

// exports
exports.locals = {
	"wrapper": "addGoalStats__wrapper-1WK",
	"wrapper": "addGoalStats__wrapper-1WK",
	"icon": "addGoalStats__icon-1XV",
	"icon": "addGoalStats__icon-1XV",
	"text": "addGoalStats__text-PQc",
	"text": "addGoalStats__text-PQc"
};