import React, { Component } from 'react';

import WidgetHeader from 'components/common/WidgetHeader';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import { getConversionTooltip } from 'components/pages/analyze/utils/ConversionJourney';
import { widgetTypes } from 'components/pages/analyze/enums';
import ConversionJourney from '../ConversionJourney';

// eslint-disable-next-line react/prefer-stateless-function
class JourneysCampaigns extends Component {
  render() {
    const {
      conversionIndicator,
      campaignJourneys,
      contentCampaignJourneys,
      prefix = '',
      widgetHeaderConfig = {
        title: 'Top Conversion Journeys',
        type: widgetTypes.topConversionCampaignJourneys,
        titleTooltip: getConversionTooltip('campaign'),
        conversionIndicator,
      },
      widgetHeaderProps = {},
    } = this.props;
    return (
      <>
        <WidgetHeader
          {...widgetHeaderProps}
          widgetHeaderConfig={widgetHeaderConfig}
        />
        <ConversionJourney
          prefix={prefix}
          conversionIndicator={conversionIndicator}
          campaignJourneys={campaignJourneys}
          contentCampaignJourneys={contentCampaignJourneys}
          isCampaignsJourney
          widgetConfig={widgetHeaderConfig}
        />
      </>
    );
  }
}

export default WithPersonalSpinner()(JourneysCampaigns);
