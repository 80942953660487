import Component from 'components/Component';
import menuStyle from 'styles/sidebar.css';
import { userPermittedToPage } from 'utils';
import Tooltip from 'components/controls/Tooltip';
import { Link } from 'react-router';
import React from 'react';
import style from 'styles/header.css';


const HIGHLITED_SUFFIX = '-selected';

class HeaderMenuItem extends Component {
  style = style;

  styles = [menuStyle];

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  static defaultProps = {
    isHidden: false,
  };

  render() {
    const icon = this.props.icon + HIGHLITED_SUFFIX;
    let className = menuStyle.locals.menuItem;

    if (this.props.isHighlighted) {
      className += ` ${menuStyle.locals.menuItemSelected}`;
    }

    return (!this.props.isHidden) && (!this.props.pagePermission || userPermittedToPage(this.props.pagePermission))
      ? (
        <Tooltip
          tip={this.props.text}
          id={`nav-${this.props.text}`}
          data-tip-disable={this.props.width > 1300}
          style={{ height: '100%' }}
        >
          <Link
            to={this.props.link || '/'}
            activeClassName={menuStyle.locals.menuItemSelected}
            className={className}
            onClick={this.props.onClick}
            style={this.props.style}
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <div className={menuStyle.locals.menuItemIcon} data-icon={icon} />
            <div className={menuStyle.locals.menuItemText}>
              {this.props.text}
              {this.props.isBetaVersion && <div className={menuStyle.locals.betaTag}>Beta</div>}
            </div>
          </Link>
        </Tooltip>
      ) : null;
  }
}

export default HeaderMenuItem;
