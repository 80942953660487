exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".button-with-sure-popup__container-2K5{position:relative;display:flex;justify-content:center}.button-with-sure-popup__popup-1m-{left:103px;border:none;top:-1px;cursor:pointer}.button-with-sure-popup__tooltip-1Mm{position:absolute;width:76px;background:linear-gradient(0deg,#24b00d 0,#27b210);border:1px solid #dbdce3;border-radius:4px;box-shadow:0 4px 12px rgba(24,32,51,.07);padding:3px 9px;z-index:2;text-transform:none;white-space:pre-wrap;line-height:140%;height:34px;box-sizing:border-box;font-size:16px;font-weight:600;display:flex;align-items:center;justify-content:center;flex-grow:1;color:#fff}.button-with-sure-popup__tooltip-1Mm:before{left:-7px;z-index:1;border-color:transparent #dbdce3 transparent transparent}.button-with-sure-popup__tooltip-1Mm:after,.button-with-sure-popup__tooltip-1Mm:before{content:\"\";display:block;position:absolute;top:10px;width:0;height:0;border-style:solid;border-width:6px 6px 6px 0}.button-with-sure-popup__tooltip-1Mm:after{left:-6px;z-index:2;border-color:transparent #24b00d transparent transparent}.button-with-sure-popup__button-b_2{height:32px;width:90%;overflow:hidden}", ""]);

// exports
exports.locals = {
	"container": "button-with-sure-popup__container-2K5",
	"container": "button-with-sure-popup__container-2K5",
	"popup": "button-with-sure-popup__popup-1m-",
	"popup": "button-with-sure-popup__popup-1m-",
	"tooltip": "button-with-sure-popup__tooltip-1Mm",
	"tooltip": "button-with-sure-popup__tooltip-1Mm",
	"button": "button-with-sure-popup__button-b_2",
	"button": "button-with-sure-popup__button-b_2"
};