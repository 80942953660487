import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/setup-budget.css';
import ProgressBar from 'components/pages/budget/ProgressBar';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';

export default class TotalAnnual extends Component {
    style = style;

    constructor(props) {
      super(props);
      this.state = {
        showProgress: false,
      };
    }

  togleShowProgress = () => {
    if (this.state.showProgress) {
      this.setState({ showProgress: false });
    } else {
      this.setState({ showProgress: true });
    }
  };

  render() {
    const {
      total,
      invested,
      planned,
      allocated,
      unallocated,
    } = this.props;

    return (
      <div className={this.classes.content}>
        <div className={this.classes.borderWrap}>
          <div className={this.classes.headWrap}>
            <h2 className={this.classes.totalTitle}>
              Total Annual Budget Allocation
            </h2>
            <SetupBudgetButton
              name={this.state.showProgress ? 'Hide Summary' : 'Show Summary'}
              stylesBtn={this.classes.buttonHide}
              onClick={this.togleShowProgress}
            />
          </div>
          <h3 className={this.classes.title}>
            $
            {total || '1.000.000'}
          </h3>
          {this.state.showProgress
            && (
            <ProgressBar
              total={total || 1000000}
              invested={invested || 100000}
              planned={planned || 0}
              allocated={allocated || 750000}
              unallocated={unallocated || 250000}
            />
            )}
        </div>
      </div>
    );
  }
}
