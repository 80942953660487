exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".PopupMenu-module__menuStyle___1aWi0{position:absolute;top:100%;left:0;background-color:var(--white);border:1px solid var(--grayText);border-radius:4px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12);z-index:10}.PopupMenu-module__optionStyle___sSsXG{padding:8px 12px;cursor:pointer;color:var(--grayText);border-radius:4px}.PopupMenu-module__optionStyle___sSsXG:hover{background-color:var(--grayHover)}.PopupMenu-module__menu___Y7mGo{position:relative}", ""]);

// exports
exports.locals = {
	"menuStyle": "PopupMenu-module__menuStyle___1aWi0",
	"optionStyle": "PopupMenu-module__optionStyle___sSsXG",
	"menu": "PopupMenu-module__menu___Y7mGo"
};