export const TIMEFRAME_VALUES = {
  PREV_DAY: 'prev_day',
  WEEK: 'week',
  PREV_WEEK: 'prev_week',
  MONTH: 'month',
  PREV_MONTH: 'prev_month',
  PREV_QUARTER: 'prev_quarter',
  QUARTER: 'quarter',
  YEAR: 'year',
  FULL_YEAR: 'full_year',
  FULL_QUARTER: 'full_quarter',
  PREV_YEAR: 'prev_year',
  ALL_TIME: 'alltime',
  CUSTOM: 'custom',
  LAST: 'last',
  NEXT_MONTH: 'next_month',
  NEXT_QUARTER: 'next_quarter',
  NEXT_YEAR: 'next_year',
  ROLLING: 'rolling',
  PREV_3_MONTH: 'last_3_month_month',
  PREV_6_MONTH: 'last_6_month_month',
  PREV_12_MONTH: 'last_12_month_month',
};

export const TIMEFRAME_LABELS = {
  PREV_DAY: 'Previous day',
  WEEK: 'This week',
  PREV_WEEK: 'Previous week',
  MONTH: 'This month',
  PREV_MONTH: 'Previous month',
  PREV_QUARTER: 'Previous quarter',
  QUARTER: 'This quarter',
  YEAR: 'This year',
  FULL_YEAR: 'This year',
  FULL_QUARTER: 'This quarter',
  PREV_YEAR: 'Previous year',
  ALL_TIME: 'All time',
  CUSTOM: 'Custom',
  NEXT_MONTH: 'Next month',
  NEXT_QUARTER: 'Next quarter',
  NEXT_YEAR: 'Next year',
  ROLLING: 'Rolling',
  PREV_3_MONTH: 'Previous 3 months',
  PREV_6_MONTH: 'Previous 6 months',
  PREV_12_MONTH: 'Previous 12 months',
};

export const TIME_FRAME = {
  MONTH: 'month',
  QUARTER: 'quarter',
  ANNUAL: 'year',
};

export const getTimeframeOptions = () => {
  const options = [
    { label: TIMEFRAME_LABELS.CUSTOM, value: TIMEFRAME_VALUES.CUSTOM },
    { label: TIMEFRAME_LABELS.ROLLING, value: TIMEFRAME_VALUES.ROLLING },
    { label: TIMEFRAME_LABELS.WEEK, value: TIMEFRAME_VALUES.WEEK },
    { label: TIMEFRAME_LABELS.MONTH, value: TIMEFRAME_VALUES.MONTH },
    { label: TIMEFRAME_LABELS.QUARTER, value: TIMEFRAME_VALUES.QUARTER },
    { label: TIMEFRAME_LABELS.YEAR, value: TIMEFRAME_VALUES.YEAR },
    {
      label: 'Previous',
      options: [
        { label: TIMEFRAME_LABELS.PREV_DAY, value: TIMEFRAME_VALUES.PREV_DAY },
        { label: TIMEFRAME_LABELS.PREV_WEEK, value: TIMEFRAME_VALUES.PREV_WEEK },
        { label: TIMEFRAME_LABELS.PREV_MONTH, value: TIMEFRAME_VALUES.PREV_MONTH },
        { label: TIMEFRAME_LABELS.PREV_QUARTER, value: TIMEFRAME_VALUES.PREV_QUARTER },
        { label: TIMEFRAME_LABELS.PREV_YEAR, value: TIMEFRAME_VALUES.PREV_YEAR },
      ],
    },
  ];

  return options;
};

export function getInsightsTimeframeOptions() {
  const options = [
    { label: TIMEFRAME_LABELS.MONTH, value: TIMEFRAME_VALUES.MONTH },
    { label: TIMEFRAME_LABELS.QUARTER, value: TIMEFRAME_VALUES.QUARTER },
    { label: TIMEFRAME_LABELS.YEAR, value: TIMEFRAME_VALUES.YEAR },
    {
      label: 'Previous',
      options: [
        { label: TIMEFRAME_LABELS.PREV_MONTH, value: TIMEFRAME_VALUES.PREV_MONTH },
        { label: TIMEFRAME_LABELS.PREV_QUARTER, value: TIMEFRAME_VALUES.PREV_QUARTER },
        { label: TIMEFRAME_LABELS.PREV_YEAR, value: TIMEFRAME_VALUES.PREV_YEAR },
        {
          label: TIMEFRAME_LABELS.PREV_3_MONTH,
          value: TIMEFRAME_VALUES.ROLLING,
          rollingValue: TIMEFRAME_VALUES.PREV_3_MONTH,
          excludeThisMonthData: true,
        },
        {
          label: TIMEFRAME_LABELS.PREV_6_MONTH,
          value: TIMEFRAME_VALUES.ROLLING,
          rollingValue: TIMEFRAME_VALUES.PREV_6_MONTH,
          excludeThisMonthData: true,
        },
        {
          label: TIMEFRAME_LABELS.PREV_12_MONTH,
          value: TIMEFRAME_VALUES.ROLLING,
          rollingValue: TIMEFRAME_VALUES.PREV_12_MONTH,
          excludeThisMonthData: true,
        },
      ],
    },
  ];
  return options;
}

export const defaultInsightTimeframe = {
  label: TIMEFRAME_LABELS.PREV_12_MONTH,
  value: TIMEFRAME_VALUES.ROLLING,
  rollingValue: TIMEFRAME_VALUES.PREV_12_MONTH,
  excludeThisMonthData: true,
};

export const DEFAULT_TIME_ZONE = 'UTC';

export const diffWithCurrentMon = (date) => {
  const currentMon = new Date().getMonth();
  const monthOfDate = date.getMonth();
  return (date.getYear() * 12 + monthOfDate) - (new Date().getYear() * 12 + currentMon);
};

export const rollingTimeframeTypesOptions = [
  {
    label: 'Months',
    value: 'month',
    type: 'month',
    isRelative: false,
    tooltip: 'For example, if today is September 6th, selecting \'1 Month\' will set the timeframe to August 1st - August 31st.',
  },
  {
    label: 'Months, relative',
    value: 'monthRelative',
    isRelative: true,
    type: 'month',
    tooltip: 'For example, if today is September 6th, selecting \'1 Month, relative\' will set the timeframe to August 5th - September 5th.',
  },
  {
    label: 'Days',
    value: 'day',
    type: 'day',
    tooltip: 'For example, if today is September 6th, selecting \'3 Days\' will set the timeframe to September 3rd - September 5th.',
  },
];

export const includeTimeframeCheckboxTooltips = {
  day: 'For example, if today is September 6th, selecting \'3 Days\' and \'Including Today\' will set the timeframe to September 4th-September 6th.',
  month: 'For example, if today is September 6th, selecting \'1 Month\' and \'Add this month\' will set the timeframe to August 1st - September 6th.',
  monthRelative: 'For example, if today is September 6th, selecting \'1 Month, relative\' and \'Including today\' will set the timeframe to August 6th - September 6th.',
};
