import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import AccountEngagementCards from 'components/pages/users/journeyStatsTab/AccountEngagementCards';
import JourneyImpactByChart from 'components/pages/users/journeyStatsTab/JourneyImpactByChart';
import Dropdown from 'components/controls/Dropdown';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';

import style from 'styles/users/journey-stats.css';

const styles = style.locals || {};

function JourneyStatsTab({
  attributionModelsOptions = [],
  journeyStatsEngagementData,
  isLoadingJourneyStats,
  isLoadingJourneyImpactBy,
  getJourneyStatsData,
  getJourneyImpactByData,
  attributionModel,
  setJourneyStatsState,
  selectedKpiFocus,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  selectedSegment,
  journeyImpactByData,
  kpiFocusOptions = [],
  hidden = false,
}) {
  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  function onChangeSelectedKpiFocus({ option }) {
    setJourneyStatsState({ state: 'journeyStatsSelectedKpiFocus', value: option.value });
    getJourneyStatsData({ kpiFocus: option.value, attributionModel });
    getJourneyImpactByData({ segment: selectedSegment, kpiFocus: option.value });
  }

  function onChangeSelectedAttributionModel({ option }) {
    setJourneyStatsState({ state: 'journeyStatsSelectedAttributionModel', value: option.value });
    getJourneyStatsData({ kpiFocus: selectedKpiFocus, attributionModel: option.value });
  }

  function onChangeSelectedSegment({ option }) {
    setJourneyStatsState({ state: 'journeyStatsSelectedSegment', value: option.value });
    getJourneyImpactByData({ segment: option.value });
  }

  const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });

  return (
    <div className={classnames(styles.userJourneyStatsWrapper, hidden ? styles.hidden : null)}>
      <div>
        <div className={styles.title}>Total account engagement</div>
        <div className={styles.accountEngagementDropdowns}>
          <Dropdown
            selectedKey={selectedKpiFocus}
            options={kpiFocusOptions}
            onChange={(option) => onChangeSelectedKpiFocus({ option })}
            dropdownLabel="KPI"
          />
          <Dropdown
            selectedKey={attributionModel}
            options={attributionModelsOptions}
            onChange={(option) => onChangeSelectedAttributionModel({ option })}
            dropdownLabel="Attribution model"
          />
        </div>
        <AccountEngagementCards
          selectedKpiFocus={kpiFocusOptions.find((option) => option.value === selectedKpiFocus)}
          kpiFocusOptions={kpiFocusOptions}
          data={journeyStatsEngagementData}
          isLoaded={!isLoadingJourneyStats}
        />
      </div>
      <div>
        <div className={styles.title}>Weekly Interactions</div>
        <div className={styles.accountEngagementDropdowns}>
          <Dropdown
            selectedKey={selectedSegment}
            options={segmentsOptionsFields}
            onChange={(option) => onChangeSelectedSegment({ option })}
            dropdownLabel="Select segment"
            isSearchable
          />
        </div>
        <JourneyImpactByChart
          kpiFocus={selectedKpiFocus}
          isLoaded={!isLoadingJourneyImpactBy}
          data={journeyImpactByData}
          selectedSegment={selectedSegment}
          currentSegmentLabel={segmentsOptionsFields.find((segment) => segment.value === selectedSegment)?.label}
        />
      </div>
    </div>
  );
}

export default inject(({
  userStore: {
    userAccount: {
      customFieldsIdToLabelMap,
      customUtmsWhitelist,
    },
  },
}) => ({
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
}),
observer)(JourneyStatsTab);
