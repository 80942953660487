import { useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/common/drawer.css';

const styles = style.locals || {};

export default function Drawer({
  title, children, contentMaxHeight = 'fit-content', onClickCollapse = () => {}, isDrawerClosed = false,
}) {
  useStyles([style]);
  const [isClosed, setIsClosed] = useState(isDrawerClosed);

  function onChangeCollapse() {
    setIsClosed((prevState) => !prevState);
    onClickCollapse();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          {title}
        </div>
        <div
          onClick={() => onChangeCollapse()}
          className={classnames(styles.collapse, isClosed && styles.rotate)}
          data-testid={isClosed ? 'drawer-collapse-rotate' : 'drawer-collapse'}
        />
      </div>
      <div
        className={classnames(styles.content, isClosed && styles.collapsed)}
        style={{ maxHeight: isClosed ? 0 : contentMaxHeight }}
      >
        {children}
      </div>
    </div>
  );
}
