import analysisStore from 'stores/analysisStore';
import widgetsAnalysisStore from 'stores/widgetsAnalysisStore';
import attributionStore from 'stores/attributionStore';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import { widgetToServerRequest } from 'components/pages/analyze/enums';

export async function getWidgetsData({
  widgets, configPerWidget = {}, isPreviousTimeFrame = false, resultKeyName, isConcatResult,
}) {
  await getWidgetsDataFromStore({
    widgets, configPerWidget, resultKeyName, isConcatResult,
  });
  if (isPreviousTimeFrame) {
    await getWidgetsDataFromStore({
      widgets, configPerWidget, isPreviousTimeFrame, resultKeyName, isConcatResult,
    });
  }
}

export async function getWidgetsDataFromStore({
  widgets, configPerWidget = {}, isPreviousTimeFrame = false, resultKeyName, isConcatResult,
} = {}) {
  const baseConfig = attributionStore.getAnalyzeBaseConfig({ isPreviousTimeFrame });

  const config = {};
  for (const widget of widgets) {
    if (!config[widget]) {
      config[widget] = [];
    }

    const customConfigPerWidget = configPerWidget[widget];
    if (customConfigPerWidget) {
      for (const customConfig of customConfigPerWidget) {
        config[widget].push({
          ...baseConfig,
          ...customConfig,
        });
      }
      continue;
    }

    config[widget].push(baseConfig);
  }

  let resultKeyNameForRequest = resultKeyName;
  if (resultKeyNameForRequest && isPreviousTimeFrame) {
    resultKeyNameForRequest = `${resultKeyName}${analyzeWidgetsResultKeyNames.previousTimeFrame}`;
  }
  if (!resultKeyNameForRequest) {
    resultKeyNameForRequest = isPreviousTimeFrame ? analyzeWidgetsResultKeyNames.previousTimeFrame : analyzeWidgetsResultKeyNames.currentTimeFrame;
  }

  await analysisStore.getWidgetsData({
    widgets,
    config,
    resultKeyName: resultKeyNameForRequest,
    isConcatResult,
  });
}

export async function getWidgetsDataV2({
  widget, widgetConfig, isPreviousTimeFrame = false, addBaseAnalyzeConfig = true, configKeyName, useAccountViewLogic, isConcatResult, parentRequestId,
}) {
  await getWidgetsDataFromStoreV2({
    widget, widgetConfig, isPreviousTimeFrame, addBaseAnalyzeConfig, configKeyName, useAccountViewLogic, isConcatResult, parentRequestId,
  });
}

export async function getWidgetsDataFromStoreV2({
  widget, widgetConfig, isPreviousTimeFrame = false, addBaseAnalyzeConfig = true, configKeyName, useAccountViewLogic, isConcatResult, parentRequestId,
} = {}) {
  const widgetForServerRequest = widgetToServerRequest[widget] || widget;
  widgetConfig.type = widgetForServerRequest;
  const config = getWidgetFullConfig({ widgetConfig, isPreviousTimeFrame, addBaseAnalyzeConfig });

  await widgetsAnalysisStore.getWidgetData({
    widget: widgetForServerRequest,
    widgetConfig: config,
    configKeyName,
    useAccountViewLogic,
    isConcatResult,
    parentRequestId,
  });
}

export function getWidgetFullConfig({ widgetConfig, isPreviousTimeFrame = false, addBaseAnalyzeConfig = true }) {
  const defaultConfig = {
    filters: [],
    attributionModel: {},
    timeFrame: {},
  };

  if (!addBaseAnalyzeConfig) {
    return {
      ...defaultConfig,
      ...widgetConfig,
    };
  }

  const baseConfig = attributionStore.getAnalyzeBaseConfig({ isPreviousTimeFrame });
  return {
    ...defaultConfig,
    ...baseConfig,
    ...widgetConfig,
  };
}
