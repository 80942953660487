import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/goal-status.css';
import Tooltip from 'components/controls/Tooltip';

export default class GoalStatus extends Component {
    style = style;

    render() {
      const {
        target,
        current,
        parsing,
        sql,
        total,
        month,
        year,
      } = this.props;
      return (
        <div className={this.classes.content}>
          <div className={this.classes.borderWrap}>
            <h2 className={this.classes.title}>
              Goal Status
            </h2>
            <div className={this.classes.totalProgress}>
              <div className={this.classes.totalBlue} style={{ width: `${current / total * 100}%` }} />
              <div className={this.classes.totalGrey} style={{ width: `${100 - (current / total * 100)}%` }} />
            </div>
            <div className={this.classes.interval}>
              <p className={this.classes.intervalItem}>
                {`${month} 1, ${year}`}
              </p>
              <p className={this.classes.intervalItem}>
                {`${month} 31, ${year}`}
              </p>
            </div>
            <div className={this.classes.values}>
              <div className={this.classes.label}>
                Target
              </div>
              <div className={this.classes.value}>
                {target}
              </div>
            </div>
            <div className={this.classes.values}>
              <div className={this.classes.label}>
                Current
              </div>
              <div className={this.classes.value}>
                {`${current} (${Math.round(current / total * 100)}% of target)`}
              </div>
            </div>
            <div className={this.classes.values}>
              <div className={this.classes.label}>
                Pacing for
              </div>
              <div className={this.classes.value}>
                {`${parsing} (+134 of target)`}
              </div>
            </div>
            <div className={this.classes.values}>
              <div className={this.classes.labelWrap}>
                <div className={this.classes.label}>
                  Est. marginal impact
                </div>
                <Tooltip
                  tip="The estimated marginal impact is related to this specific channel"
                  id="marginalImpact"
                  place="bottom"
                  TooltipProps={{ sm: true }}
                >
                  <div className={this.classes.info} />
                </Tooltip>
              </div>
              <div className={this.classes.value}>
                {`${sql} SQLs`}
              </div>
            </div>
          </div>
        </div>
      );
    }
}
