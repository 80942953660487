import React, { useState } from 'react';
import classNames from 'classnames';
import Typewriter from 'typewriter-effect';
import { renderToString } from 'react-dom/server';

import useStyles from 'hooks/useStyles';

import style from 'styles/AI-analysis/AIAnalysis.css';

const styles = style.locals || {};

function AIAnalysis({
  disabled,
  data,
  maxHeight,
  sizes,
  position,
  hidden,
}) {
  useStyles([style]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const isButtonDisabled = !data || disabled;

  if (hidden) {
    return null;
  }

  const dataString = renderToString(data);

  return (
    <div className={styles.aiAnalysis}>
      <div
        onClick={() => (isButtonDisabled ? null : setIsPopupOpen(!isPopupOpen))}
        className={classNames(styles.aiButton, isButtonDisabled && styles.disabled)}
      >
        <div className={styles.aiButtonIcon} />
      </div>

      {isPopupOpen ? (
        <div className={styles.aiAnalysisPopup} style={{ maxHeight, ...sizes, ...position }}>
          <div className={styles.closeButton} onClick={() => setIsPopupOpen(false)} />
          <div className={styles.popupTitle}>
            <div className={styles.aiTitleIcon} />
            AI Analysis
          </div>
          <div className={styles.popupContent}>
            <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString(dataString).start();
              }}
              options={{
                delay: 10,
                cursor: '',
                cursorClassName: styles.aiCursor,
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AIAnalysis;
