exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".metrics__quarter-1FH{display:flex;flex-direction:column;justify-content:center}.metrics__quarter-1FH:nth-child(2n){padding-left:47px;border-left:1px solid #f2f2f2}.metrics__quarter-1FH:nth-child(n+3){border-top:1px solid #f2f2f2}.metrics__quarter-text-1ZQ{color:#636872;font-weight:500;font-size:14px;text-transform:capitalize;height:17px}.metrics__quarter-number-2Ir{display:flex;justify-content:flex-start;flex-direction:row;color:#354052;margin:3px 0;font-size:22px;font-weight:500;width:100%}.metrics__center-2q6{display:flex;justify-content:center;flex-direction:row;align-items:center;margin-left:6px}.metrics__history-arrow-1V-{content:\"\";width:0;height:0;border-style:solid;border-width:0 6px 9px;border-color:transparent transparent #24b10e;align-self:center}.metrics__history-arrow-1V-[data-decline]{transform:rotate(180deg);border-color:transparent transparent red}.metrics__history-grow-1on{font-size:13px;font-weight:500;color:#24b10e;margin-right:10px;margin-left:2px;align-self:center}.metrics__history-grow--big-2iZ{font-size:16px;font-weight:600}.metrics__history-grow-1on[data-decline]{color:red}.metrics__metrics-1cT{height:86px;display:flex;flex:1;flex-direction:row;justify-content:space-around;align-items:center}.metrics__metrics-text-3kD{margin-top:5px;font-weight:600;color:#707ea7}.metrics__picture-361{width:65px;height:65px;background-position:50%;background-repeat:no-repeat;background-size:contain;margin-bottom:10px}.metrics__flex-center-12_{display:flex;justify-content:center;align-items:center}", ""]);

// exports
exports.locals = {
	"quarter": "metrics__quarter-1FH",
	"quarter": "metrics__quarter-1FH",
	"quarter-text": "metrics__quarter-text-1ZQ",
	"quarterText": "metrics__quarter-text-1ZQ",
	"quarter-number": "metrics__quarter-number-2Ir",
	"quarterNumber": "metrics__quarter-number-2Ir",
	"center": "metrics__center-2q6",
	"center": "metrics__center-2q6",
	"history-arrow": "metrics__history-arrow-1V-",
	"historyArrow": "metrics__history-arrow-1V-",
	"history-grow": "metrics__history-grow-1on",
	"historyGrow": "metrics__history-grow-1on",
	"history-grow--big": "metrics__history-grow--big-2iZ",
	"historyGrowBig": "metrics__history-grow--big-2iZ",
	"metrics": "metrics__metrics-1cT",
	"metrics": "metrics__metrics-1cT",
	"metrics-text": "metrics__metrics-text-3kD",
	"metricsText": "metrics__metrics-text-3kD",
	"picture": "metrics__picture-361",
	"picture": "metrics__picture-361",
	"flex-center": "metrics__flex-center-12_",
	"flexCenter": "metrics__flex-center-12_"
};