import React from 'react';
import groupBy from 'lodash/groupBy';
import Component from 'components/Component';
import { FilterItemTag } from 'components/pages/users/Filters/FilterParts';
import ChannelIcon from 'components/common/ChannelIcon';
import BaseFilter from 'components/pages/users/Filters/BaseFilter';
import { CONTAINS } from 'components/utils/filters';
import { EQUALS, filterKinds } from 'components/utils/filters/logic';
import { CommonPropTypes } from 'components/pages/users/Filters/CommonFilter';
import {
  getCategoryIcon,
  getChannelIcon,
  getChannelNickname,
  getChannelsWithNicknames,
} from 'components/utils/filters/channels';

import selectStyle from 'styles/controls/select.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import filterStyles from 'styles/users/filters.css';
import Dropdown from 'components/controls/Dropdown';
import ContainsSelect from './FilterPanel/UI/ContainsSelect';

const styles = filterStyles.locals;

class ChannelsFilter extends Component {
  static propTypes = CommonPropTypes;

  styles = [checkboxStyle];

  constructor(props) {
    super(props);
    const comparisonOperatorLabel = props.activeFilter ? props.activeFilter.data.comparisonOperator : EQUALS;
    this.state = {
      comparisonOperator: comparisonOperatorLabel,
      isContainsSelected: comparisonOperatorLabel === CONTAINS,
    };
  }

  changeComparisonHandler = (value) => {
    this.setState({
      comparisonOperator: value,
      isContainsSelected: value === CONTAINS,
    });
  };

  render() {
    const { config } = this.props;
    const fieldOptions = config.fieldKey.map((fKey, index) => ({
      label: config.fieldNames ? config.fieldNames[index] : fKey,
      value: fKey,
    }));

    return (
      <BaseFilter
        comparisonOperator={this.state.comparisonOperator}
        changeComparisonHandler={this.changeComparisonHandler}
        kind={filterKinds.CHANNELS}
        {...this.props}
      >
        {({
          addItem, selectedOptions, removeItem, fieldIndex, setFilterFieldIndex, containsValueHandler,
        }) => {
          const isCategory = fieldIndex === 1;

          const channelsWithNicknames = getChannelsWithNicknames();
          const channelsItemsWithoutContent = channelsWithNicknames.filter((item) => item.channelType !== 'content');
          const channelsItemsOptions = isCategory
            ? Object.keys(groupBy(channelsItemsWithoutContent, (c) => c.category))
            : channelsItemsWithoutContent.map((c) => c.value);

          const filterExitsChannels = channelsItemsOptions.filter((value) => !selectedOptions.includes(value));
          const mappedChannelsOptions = filterExitsChannels.map((value) => ({ value, label: getChannelNickname(value) }));
          const channelsOptionsWithoutDeleted = mappedChannelsOptions.filter((item) => (!item.label.includes(' (deleted)')));

          return (
            <>
              <div className={styles.filterVariant}>
                <Dropdown
                  onChange={setFilterFieldIndex}
                  options={fieldOptions}
                  selectedKey={config.fieldKey[fieldIndex]}
                  controlWidth={210}
                  isSearchable
                />
              </div>

              {/*
              //HIDE CONTAINS ON CHANNELS > MUST BE FIXED
              <div className={styles.filterVariant}>
                <Dropdown
                  style={{ width: 200 }}
                  onChangeHandler={onChangeComparison}
                  variantsOptions={Object.entries(COMPARISON_TOGGLE).map(([value, label]) => ({ value, label }))}
                  selectedKey={this.state.comparisonOperator}
                  placeholder="Select condition"
                />
              </div> */}

              {this.state.isContainsSelected ? (
                <div className={styles.rowCenter} style={{ marginBottom: 15, position: 'relative' }}>
                  <ContainsSelect containsValue={selectedOptions.map((item) => ({ label: item, value: item }))} containsValueHandler={containsValueHandler} />
                </div>
              ) : (
                <>
                  <Dropdown
                    onChange={addItem}
                    selectedKey={-1}
                    options={channelsOptionsWithoutDeleted}
                    optionRenderer={(item) => (
                      <div className={styles.channelItemWrap}>
                        {isCategory ? (
                          <div
                            className={selectStyle.locals.categoryIcon}
                            data-icon={getCategoryIcon(item.value)}
                          />
                        ) : (
                          <ChannelIcon
                            className={styles.filterItemIcon}
                            channelIcon={getChannelIcon(item.value)}
                            channel={item.value}
                          />
                        )}
                        <div className={selectStyle.locals.text}>
                          {item.label}
                        </div>
                      </div>
                    )}
                    placeholder="Select channel..."
                    isSearchable
                    withTooltip
                  />
                  {!this.state.isContainsSelected
                    && (
                      <div className={styles.filterItemTags}>
                        {selectedOptions.map((channel) => (
                          <FilterItemTag
                            key={channel}
                            channel={channel}
                            title={getChannelNickname(channel)}
                            onRemove={() => removeItem(channel)}
                            iconRenderer={() => (isCategory ? (
                              <div
                                className={selectStyle.locals.categoryIcon}
                                data-icon={getCategoryIcon(channel)}
                              />
                            ) : (
                              <ChannelIcon
                                className={styles.filterItemIcon}
                                channelIcon={getChannelIcon(channel)}
                                channel={channel}
                              />
                            ))}
                          />
                        ))}
                      </div>
                    )}
                </>
              )}
            </>
          );
        }}
      </BaseFilter>
    );
  }
}

export default ChannelsFilter;
