import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';

import ChartSkeleton from 'components/common/ChartSkeleton';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { getColor } from 'components/utils/colors';
import { baselineScenario, predictedBaselineScenario, scenarioLabels } from 'components/widgets/whatIf/enums';
import { formatIndicatorDisplay, getIndicatorDisplaySign } from 'components/utils/indicators';

import useStyles from 'hooks/useStyles';

import style from 'styles/analyze/what-if-scenarios.css';

const styles = style.locals || {};

export default function ScenariosLineChart({
  isSettingsOpen,
  chartData,
  isLoaded,
  kpiFocus,
  scenarios = [],
}) {
  useStyles([style]);

  const [activeLine, setActiveLine] = useState(null);

  const chartLabel = useMemo(() => {
    const labelSign = getIndicatorDisplaySign(kpiFocus);
    return `${userStore.getMetricNickname({ metric: kpiFocus })} ${labelSign ? `(${labelSign})` : ''}`;
  }, [kpiFocus]);

  const scenariosPropertiesByIds = useMemo(() => {
    const scenariosProperties = {};
    for (const scenario of scenarios) {
      scenariosProperties[scenario.id] = {
        label: scenario.label,
        borderColor: scenario.borderColor,
      };
    }
    scenariosProperties[baselineScenario] = { label: scenarioLabels[baselineScenario], borderColor: getColor(0) };
    scenariosProperties[predictedBaselineScenario] = { label: scenarioLabels[predictedBaselineScenario], borderColor: getColor(0) };
    return scenariosProperties;
  }, [scenarios, chartData]);

  function renderChartTooltip({ payload, label, active }) {
    if (!active || !payload || !activeLine) {
      return null;
    }
    const currentPayload = payload.find((item) => item.name === activeLine);
    if (!currentPayload) {
      return null;
    }

    const currentPayloadName = scenariosPropertiesByIds[currentPayload.name] ? scenariosPropertiesByIds[currentPayload.name].label : currentPayload.name;
    return (
      <div className={styles.chartTooltip}>
        <div className={styles.chartTooltipHeader}>
          {`${label} - ${userStore.getMetricNickname({ metric: kpiFocus })}`}
        </div>
        <div className={styles.chartTooltipContent} key={currentPayload.name}>
          <div className={styles.chartTooltipColor} style={{ backgroundColor: currentPayload.color }} />
          <div>{currentPayloadName}</div>
          <div>{formatIndicatorDisplay(kpiFocus, currentPayload.value, true)}</div>
        </div>
      </div>
    );
  }

  const renderLegend = useCallback(({ payload }) => {
    const legendsWithValues = payload.filter((item) => chartData.some((entry) => entry[item.value] !== undefined));
    return (
      <div className={styles.chartLegend}>
        {legendsWithValues.map((item) => {
          const isStrokeDashed = item.value !== 'baseline';

          let legendLabel = item.value;
          if (scenariosPropertiesByIds[item.value]) {
            legendLabel = scenariosPropertiesByIds[item.value].label;
          }
          if (item.value === 'baseline') {
            legendLabel = 'Actual';
          }

          return (
            <div key={`item-${item.value}`} className={styles.chartLegendItem}>
              <div
                className={classnames(styles.chartLegendItemLine, isStrokeDashed && styles.dashedLine)}
                style={{ borderColor: item.color }}
              />
              {legendLabel}
            </div>
          );
        })}
      </div>
    );
  }, [scenariosPropertiesByIds, chartData]);

  if (!isLoaded) {
    return (
      <div className={classnames(styles.box, isSettingsOpen ? styles.minimizeChart : styles.maxLineChart)}>
        <ChartSkeleton chartHeight={508} />
      </div>
    );
  }

  return (
    <div className={classnames(styles.box, isSettingsOpen ? styles.minimizeChart : styles.maxLineChart)}>
      {chartLabel ? (
        <div className={styles.chartLabel}>
          {chartLabel}
        </div>
      ) : null}
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          onMouseLeave={() => {
            setActiveLine(null);
          }}
          cursor="pointer"
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="3 3"
            strokeWidth={1}
            stroke="rgba(54, 56, 64, 0.1)"
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tick={{ fontSize: '12px', color: '#707ea7' }}
            tickLine
            tickMargin={10}
            height={40}
            minTickGap={4}
          />
          <YAxis
            axisLine={false}
            tick={{ fontSize: '12px', color: '#707ea7' }}
            tickLine={false}
            tickMargin={15}
            tickFormatter={(value) => formatIndicatorDisplay(kpiFocus, value, true)}
          />
          <Legend content={renderLegend} />
          {Object.keys(scenariosPropertiesByIds).map((scenarioId) => {
            const borderColor = scenariosPropertiesByIds[scenarioId]?.borderColor;
            const isDashed = scenarioId !== baselineScenario;
            return (
              <Line
                key={scenarioId}
                dataKey={scenarioId}
                stroke={borderColor}
                dot={false}
                strokeWidth={2}
                isAnimationActive={false}
                strokeDasharray={isDashed ? '5 5' : '0'}
                onMouseEnter={() => {
                  setActiveLine(scenarioId);
                }}
                activeDot={activeLine === scenarioId}
              />
            );
          })}
          <Tooltip
            cursor={false}
            wrapperStyle={{ zIndex: 100 }}
            offset={0}
            content={(props) => renderChartTooltip(props)}
            animationDuration={1}
            allowEscapeViewBox={{ x: true, y: true }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
