exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".delete-popup__overlay-1WI{position:fixed;top:0;left:0;display:flex;width:100%;height:100%;justify-content:center;align-items:center;background:rgba(0,0,0,.63);z-index:5}.delete-popup__popup-3Ss{width:450px;height:208px;background:#fff;border-radius:4px;z-index:5;position:relative}.delete-popup__popupWrap-2aQ{display:flex;flex-direction:column;align-items:center}.delete-popup__popupTitle-1dp{font-weight:400;font-size:16px;line-height:24px;text-align:center;color:#182033;margin:56px 0 0}.delete-popup__popupTitle-1dp span{font-weight:700}.delete-popup__popupBtnsContainer-aTF{width:100%;max-width:170px;display:flex;justify-content:space-between;text-align:center}.delete-popup__popupButtonWrap-1ac{width:100%;padding:32px 0}.delete-popup__popupButtonCancel-3xd{width:70px;background:linear-gradient(1turn,#fafafc,#fff);border:1px solid rgba(178,187,213,.5);box-shadow:0 1px 2px #e6e8f0;border-radius:3px;text-align:center;color:#707ea7}.delete-popup__popupButtonCancel-3xd,.delete-popup__popupButtonDelete-3N0{height:40px;font-family:Avenir Next,museo-sans,Source Sans Pro,sans-serif;font-style:normal;font-weight:500;font-size:12px;line-height:16px}.delete-popup__popupButtonDelete-3N0{width:69px;background:#ee2032;border-radius:3px;color:#fff;border:none}.delete-popup__popupButtonCancel-3xd:focus,.delete-popup__popupButtonCancel-3xd:hover,.delete-popup__popupButtonDelete-3N0:focus,.delete-popup__popupButtonDelete-3N0:hover{cursor:pointer;box-shadow:0 6px 6px 0 rgb(27 35 56/10%)}.delete-popup__close-icon-1pu{position:absolute;cursor:pointer;right:18px;top:18px;width:15px;height:15px;background:url(/assets/close-icon.svg)}", ""]);

// exports
exports.locals = {
	"overlay": "delete-popup__overlay-1WI",
	"overlay": "delete-popup__overlay-1WI",
	"popup": "delete-popup__popup-3Ss",
	"popup": "delete-popup__popup-3Ss",
	"popupWrap": "delete-popup__popupWrap-2aQ",
	"popupWrap": "delete-popup__popupWrap-2aQ",
	"popupTitle": "delete-popup__popupTitle-1dp",
	"popupTitle": "delete-popup__popupTitle-1dp",
	"popupBtnsContainer": "delete-popup__popupBtnsContainer-aTF",
	"popupBtnsContainer": "delete-popup__popupBtnsContainer-aTF",
	"popupButtonWrap": "delete-popup__popupButtonWrap-1ac",
	"popupButtonWrap": "delete-popup__popupButtonWrap-1ac",
	"popupButtonCancel": "delete-popup__popupButtonCancel-3xd",
	"popupButtonCancel": "delete-popup__popupButtonCancel-3xd",
	"popupButtonDelete": "delete-popup__popupButtonDelete-3N0",
	"popupButtonDelete": "delete-popup__popupButtonDelete-3N0",
	"close-icon": "delete-popup__close-icon-1pu",
	"closeIcon": "delete-popup__close-icon-1pu"
};