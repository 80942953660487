import React, { useEffect, useState } from 'react';

import InfoCard from 'components/common/InfoCard';
import ConfigPopup from 'components/common/ConfigPopup';

import configStatsStyle from 'styles/settings/workflows/configStats.css';

export default function ConfigStats({
  icon,
  type,
  title,
  isActive,
  subTitle,
  className,
  isViewOnly,
  isClickable,
  configuration,
  popupSubTitle,
  isShowLockIcon,
  PopupComponent,
  isShowSwitchButton,
  withPopupBodyWrapper,
}) {
  const [isShowConfigPopup, setIsShowConfigPopup] = useState(false);

  useEffect(() => {
    configStatsStyle.use();
    return () => {
      configStatsStyle.unuse();
    };
  }, []);

  return (
    <>
      {isShowConfigPopup && (
        <ConfigPopup
          onClose={() => setIsShowConfigPopup(false)}
          title={title}
          subTitle={popupSubTitle}
          isViewOnly={isViewOnly}
          withPopupBodyWrapper={withPopupBodyWrapper}
        >
          <PopupComponent
            configuration={configuration}
            isViewOnly={isViewOnly}
            type={type}
          />
        </ConfigPopup>
      )}

      <InfoCard
        title={title}
        onClick={isClickable ? () => setIsShowConfigPopup((prev) => !prev) : null}
        subTitle={subTitle}
        iconProps={{
          dataIcon: icon,
          className: configStatsStyle.locals.icon,
        }}
        className={className}
        switchProps={{
          isActive,
          disabled: true,
          isShowSwitchButton,
        }}
        isShowLockIcon={isShowLockIcon}
      />
    </>
  );
}
