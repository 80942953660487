import { operators } from 'components/pages/plan/campaignPopups/enums';

export function getAssociatedTags({ campaignName, campaignTags }) {
  const associatedTags = [];

  for (const campaignTag of campaignTags) {
    const isRulePass = campaignTag.rules.every((rule) => {
      if (rule.fieldName !== 'name') {
        return false;
      }

      if (rule.operator === operators.equals) {
        return campaignName.toLowerCase() === rule.value.toLowerCase();
      }

      if (rule.operator === operators.contains) {
        return campaignName.toLowerCase().includes(rule.value.toLowerCase());
      }

      if (rule.operator === operators.notEquals) {
        return campaignName.toLowerCase() !== rule.value.toLowerCase();
      }

      if (rule.operator === operators.notContains) {
        return !campaignName.toLowerCase().includes(rule.value.toLowerCase());
      }

      return false;
    });

    if (isRulePass) {
      associatedTags.push(campaignTag);
    }
  }

  return associatedTags;
}
