import {
  exchangeUSDTo,
  formatNumber,
  getCurrencySymbol,
  roiFormatter,
} from 'components/utils/budget';
import { formatBudgetShortened } from 'components/utils/budgetFormat';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';

import {
  get, mapValues, merge, sumBy,
} from 'lodash';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { VARIANTS_CONFIGS } from 'components/utils/filters';
import { funnelTransitionsModels } from 'components/pages/createIntegration/logic/enums';

const schema = { properties: {} };
let isInitialized = false;
const FUNNEL_MAPPING_PATH = 'mapping.funnelMapping';
export const FUNNEL1 = 'funnel1';
export const FUNNEL2 = 'funnel2';
export const FUNNEL3 = 'funnel3';
export const FUNNEL4 = 'funnel4';
export const FUNNEL5 = 'funnel5';
export const LOST_FUNNEL = 'lost';
export const BLOG_SUBSCRIBER_FUNNEL = 'blogSubscribers';
export const NEW_FUNNEL_PREFIX = 'newFunnel';
export const INFLUENCED_MAPPING_PREFIX = 'influencedFunnel';
export const VELOCITY_MAPPING_SUFFIX = 'Velocity';
export const CONVERSION_RATE_MAPPING_SUFFIX = 'ConversionRate';
export const VELOCITY_FIRST_TO_LAST_MAP = 'averageSalesCycle';

export const CUSTOM_FUNNEL = 'lost';

const getFunnelsMapping = (funnels, prefix) => {
  const funnelsMapping = {};
  funnels.forEach((funnel, index) => {
    funnelsMapping[funnel] = `${prefix}${index + 1}`;
  });
  return funnelsMapping;
};

export const getNewFunnelsMapping = (funnels, prefix) => {
  const funnelsMapping = {};
  funnels.forEach((funnel, index) => {
    funnelsMapping[`${prefix}${index + 1}`] = funnel;
  });
  return funnelsMapping;
};

const getMappingBased2Funnels = (funnels, suffix, firstToLastMap) => {
  const mapping = {};
  const funnelsAmount = funnels.length;
  const lastMap = firstToLastMap || `funnel1ToFunnel${funnelsAmount}${suffix}`;
  const velocityName = (funnelIndex) => `funnel${funnelIndex}ToFunnel${funnelIndex + 1}${suffix}`;
  funnels.forEach((funnel, index) => {
    if (!index) {
      mapping[funnel] = [];
    } else if (index) {
      mapping[funnel] = [velocityName(index)];
    }
    if (index + 1 === funnelsAmount) {
      mapping[funnel].push(lastMap);
    }
  });
  return mapping;
};

const PIPELINE = 'pipeline';
const REVENUE = 'revenue';

const moneyIndicators = [PIPELINE, REVENUE];

const indicatorMapping = {
  [PIPELINE]: 'newPipeline',
  [REVENUE]: 'newBookings',
};

export const isMoneyIndicator = (i) => moneyIndicators.includes(i);

export const mapIndicator = (i) => (isMoneyIndicator(i) ? indicatorMapping[i] : i);

export const mapChartIndicator = (i) => {
  const chartIndicatorMapping = {
    [PIPELINE]: getPipelineFunnel(),
    [REVENUE]: getRevenueFunnel(),
  };
  return (isMoneyIndicator(i) ? chartIndicatorMapping[i] : i);
};

export function initialize(indicatorsSchema = {}, userIndicatorsSchema = {}) {
  schema.properties = merge(schema.properties, indicatorsSchema.properties, userIndicatorsSchema);
  schema.properties = mapValues(schema.properties, (props) => {
    const { isCustomMetic, ...otherProps } = props;
    return {
      ...otherProps,
      isCustom: !!isCustomMetic,
    };
  });
  isInitialized = true;
}

export function getIndicatorIcon(indicator) {
  if (isInitialized && indicator) {
    if (schema.properties[indicator] && !schema.properties[indicator].isCustom) {
      return `indicator:${indicator}`;
    } else {
      return 'indicator:other';
    }
  } else {
    servicesStore.logger.error('indicators schema is not initialized', { indicator, function: 'getIndicatorIcon' });
    return '';
  }
}

export function formatIndicatorDisplay(ind, valueToDisplay, withShortened = false, withExchange = true, round, decimalCount) {
  let formattedValue = formatNumber(valueToDisplay, true, decimalCount);

  if (isInitialized) {
    let indicator = mapIndicator(ind);
    indicator = typeof indicator !== 'string' ? indicator[0] : indicator;
    const displaySign = getIndicatorDisplaySign(indicator);
    const metricSchema = userStore.getMetricSchema({ metric: indicator });

    if (metricSchema.metricType === 'ROI') {
      return roiFormatter(formattedValue);
    }

    switch (metricSchema.displayType) {
      case 'percentage':
        return `${formattedValue}${displaySign}`;
      case 'dollar':
        if (withExchange) {
          const convertedValue = exchangeUSDTo(valueToDisplay);
          if (withShortened) {
            formattedValue = formatBudgetShortened(Math.round(convertedValue || 0));
          } else {
            formattedValue = formatNumber(convertedValue, round, decimalCount);
          }
        } else if (withShortened) {
          formattedValue = formatBudgetShortened(Math.round(valueToDisplay || 0));
        }
        return `${displaySign}${formattedValue}`;
      case 'days':
      case 'months':
        return `${formattedValue} ${displaySign}`;
      default:
        return formattedValue;
    }
  } else {
    servicesStore.logger.error('indicators schema is not initialized', { indicator: ind, function: 'getIndicatorIcon' });
    return formattedValue;
  }
}

export function getIndicatorDisplaySign(indicator) {
  const metricSchema = userStore.getMetricSchema({ metric: indicator });
  switch (metricSchema.displayType) {
    case 'percentage':
      return '%';
    case 'dollar':
      return getCurrencySymbol();
    case 'days':
      return 'days';
    case 'months':
      return 'months';
    default:
      return '';
  }
}

export function getTitle(indicator) {
  if (isInitialized) {
    return schema.properties[indicator] && schema.properties[indicator].title;
  } else {
    servicesStore.logger.error('indicators schema is not initialized', { indicator, function: 'getTitle' });
    return '';
  }
}

export function getMetadata(type, indicator) {
  if (isInitialized) {
    return schema.properties[indicator] && schema.properties[indicator][type];
  } else {
    servicesStore.logger.error('indicators schema is not initialized', { indicator, function: 'getMetadata' });
    return {};
  }
}

export function getIndicatorsWithProps() {
  if (isInitialized) {
    return schema.properties;
  } else {
    servicesStore.logger.error('indicators schema is not initialized', { function: 'getIndicatorsWithProps' });
    return {};
  }
}

export const newIndicatorMapping = () => getNewFunnelsMapping(userStore.userFunnels, NEW_FUNNEL_PREFIX);

export const indicatorsToNewMapping = () => getFunnelsMapping(userStore.userFunnels, NEW_FUNNEL_PREFIX);

export const getNewFunnels = () => Object.keys(getNewFunnelsMapping(userStore.userFunnels, NEW_FUNNEL_PREFIX));

export const getInfluencedMapping = () => getFunnelsMapping(userStore.userFunnels, INFLUENCED_MAPPING_PREFIX);

export const getVelocityMapping = () => getMappingBased2Funnels(userStore.userFunnels, VELOCITY_MAPPING_SUFFIX, VELOCITY_FIRST_TO_LAST_MAP);

export const getIndicators = () => ['spending', ...getNewFunnels()];

export function getRevenueFunnel() {
  return userStore.userMonthPlan?.revenueFunnel;
}

export function getPipelineFunnel() {
  return userStore.userMonthPlan?.pipelineFunnel;
}

function parsedFilterToLabel({ filter, customFieldsIdToLabelMap }) {
  const { data: { variant, fieldId, selectedOptions = [] } } = filter;
  const type = customFieldsIdToLabelMap[fieldId] || '';
  const variantLabel = get(VARIANTS_CONFIGS, [variant, 'label'], '');

  return `${variantLabel} ${type}: ${selectedOptions.join(', ')}`;
}

export const indicatorOptions = () => getIndicators().reduce((acc, i) => {
  const indicatorsProperties = getIndicatorsWithProps();

  if (i === 'spending') {
    return acc;
  }
  acc = [...acc, {
    value: i,
    label: indicatorsProperties[i] ? indicatorsProperties[i].nickname : i,
  }];
  return acc;
}, []);

export const getFunnelPopupData = ({
  salesforceapi,
  hubspotapi,
  funnelStagesToTheirGroupByType,
  predefinedFiltersConfig,
  customFieldsIdToLabelMap,
  reportSyncStatus,
}) => {
  const getMapping = (obj) => get(obj, 'mapping.funnelMapping', {});
  const APIFunnelMapping = {
    ...getMapping(salesforceapi),
    ...getMapping(hubspotapi),
  };

  const mapGroupBy = {
    contacts: 'people',
    companies: 'accounts',
    deals: 'deals',
  };
  return Object.keys(indicatorsToNewMapping()).reduce((acc, indicator) => {
    const filters = get(predefinedFiltersConfig, [indicator, 'filters'], []);
    const logicExpression = get(predefinedFiltersConfig, [indicator, 'logicExpression'], '');
    const logicExpressionParts = logicExpression.split(/(\s+|\(|\))/);
    const filtersMapped = [];

    for (const logicExpressionPart of logicExpressionParts) {
      if (filters.length === 1) {
        const filterLabel = parsedFilterToLabel({ filter: filters[0], customFieldsIdToLabelMap });
        filtersMapped.push(filterLabel);
        continue;
      }

      if (logicExpressionPart.trim() === '') {
        continue;
      }

      if (!isNaN(logicExpressionPart)) {
        const logicExpressionIndex = parseInt(logicExpressionPart, 10) - 1;
        const isValidLogicExpressionIndex = logicExpressionIndex >= 0 && logicExpressionIndex < filters.length;
        if (isValidLogicExpressionIndex) {
          const filterByIndex = filters[logicExpressionIndex];
          const filterLabel = parsedFilterToLabel({ filter: filterByIndex, customFieldsIdToLabelMap });
          filtersMapped.push(filterLabel);
        } else {
          filtersMapped.push(logicExpressionPart);
        }
      } else {
        filtersMapped.push(logicExpressionPart);
      }
    }

    const rules = get(APIFunnelMapping, [indicator, 'rules'], []);
    const rulesMapped = rules.map(({
      objectType, values = [], field, dateField,
    }) => ({
      rule: `- ${objectType} ${field} includes: ${values.join(', ')}`,
      description: dateField && `custom date: ${dateField}`,
    }));

    const groupBy = funnelStagesToTheirGroupByType[indicator];

    acc[indicator] = {
      groupBy: mapGroupBy[groupBy] || '',
      filters: filtersMapped,
      mappings: rulesMapped,
    };

    if (reportSyncStatus[indicator]) {
      const { isSynced, reportUrl } = reportSyncStatus[indicator];
      acc[indicator].reportSync = {
        reportUrl,
        isSynced,
      };
    }

    return acc;
  }, {});
};

const sortFunnels = (a, b) => a.localeCompare(b, undefined, {
  numeric: true,
  sensitivity: 'base',
});

export const getFunnelsFromMapping = (salesforceapi, hubspotapi, withFilter = true, commbined = false) => {
  const isLostOrBlogSubscriberFunnel = (funnel) => funnel === LOST_FUNNEL || funnel === BLOG_SUBSCRIBER_FUNNEL;
  let salesforceFunnels = Object.keys(get(salesforceapi, FUNNEL_MAPPING_PATH, {}));
  let hubspotFunnels = Object.keys(get(hubspotapi, FUNNEL_MAPPING_PATH, {}));
  if (withFilter) {
    salesforceFunnels = salesforceFunnels.filter((funnel) => !isLostOrBlogSubscriberFunnel(funnel));
    hubspotFunnels = hubspotFunnels.filter((funnel) => !isLostOrBlogSubscriberFunnel(funnel));
  }
  if (commbined) {
    const commbinedFunnel = salesforceFunnels.filter((funnel) => !hubspotFunnels.includes(funnel));
    return [...new Set([...commbinedFunnel])].sort(sortFunnels);
  }
  return [...new Set([...salesforceFunnels, ...hubspotFunnels])].sort(sortFunnels);
};

export const SALESFORCEAPI = 'salesforceapi';
export const HUBSPOTAPI = 'hubspotapi';
export const SALESFORCE = 'salesforce';
export const HUBSPOT = 'hubspot';
export const DEFAULT_FUNNELS = [FUNNEL1, FUNNEL2, FUNNEL3, LOST_FUNNEL];
export const CRMIntegrationsOptions = [SALESFORCE, HUBSPOT];

export const getFieldOptionsFromCRM = (crmPlatform, mappingData, objectType) => {
  if (crmPlatform === SALESFORCE) {
    return get(mappingData[SALESFORCEAPI], [objectType, 'fields'], []);
  }
  if (crmPlatform === HUBSPOT) {
    return get(mappingData[HUBSPOTAPI], objectType, []);
  }
  return [];
};

export const CRM_OBJECT = {
  [SALESFORCE]: ['account', 'lead', 'opportunity', 'contact', 'campaignMember', 'task', 'event', 'OpportunityContactRole', 'campaign'],
  [HUBSPOT]: ['contacts', 'companies', 'deals'],
};

export const MRRFieldOptionsMonths = [
  { value: 1, label: '1 month' },
  { value: 12, label: '12 months' },
  { value: 24, label: '24 months' },
];

export const funnelGroupByOptions = [
  { label: 'People', value: 'contacts' },
  { label: 'Company', value: 'companies' },
  { label: 'Deal', value: 'deals' },
];

export const funnelsNicknamesToIgnor = [BLOG_SUBSCRIBER_FUNNEL, LOST_FUNNEL];

const hubspotDefaultsValues = () => ({
  [FUNNEL1]: {
    rules: [{
      objectType: 'contacts',
      field: 'createdate',
      values: ['ANY'],
    }],
    groupBy: 'contacts',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
    isPipeline: false,
  },
  [FUNNEL2]: {
    rules: [{
      objectType: 'deals',
      field: 'createdate',
      values: ['ANY'],
    }],
    groupBy: 'deals',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
    isPipeline: true,
  },
  [FUNNEL3]: {
    rules: [{
      objectType: 'deals',
      field: 'dealstage',
      values: ['closedwon'],
    }],
    groupBy: 'deals',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isPipeline: false,
    isRevenue: true,
  },
  [LOST_FUNNEL]: {
    rules: [{
      objectType: 'deals',
      field: 'dealstage',
      values: ['closedlost'],
    }],
    groupBy: 'deals',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
  },
});

const salesforceDefalutsImportFields = () => ([
  {
    label: 'Country (lead)',
    mergingConfiguration: [
      { crmPlatform: SALESFORCE, objectName: 'lead', fieldsToMerge: ['Country'] },
    ],
    isAttribution: false,
  },
  {
    label: 'Lead Source (lead)',
    mergingConfiguration: [
      { crmPlatform: SALESFORCE, objectName: 'lead', fieldsToMerge: ['LeadSource'] },
    ],
    isAttribution: true,
  },
  {
    label: 'Role (OpportunityContactRole)',
    mergingConfiguration: [
      { crmPlatform: SALESFORCE, objectName: 'OpportunityContactRole', fieldsToMerge: ['Role'] },
    ],
    isAttribution: false,
  },
]);

const hubspotDefaultsImportFields = () => ([
  {
    label: 'Country (contacts)',
    mergingConfiguration: [
      { crmPlatform: HUBSPOT, objectName: 'contacts', fieldsToMerge: ['country'] },
    ],
    isAttribution: false,
  },
  {
    label: 'Original Source (contacts)',
    mergingConfiguration: [
      { crmPlatform: HUBSPOT, objectName: 'contacts', fieldsToMerge: ['hs_analytics_source'] },
    ],
    isAttribution: true,
  },
]);

export const DEFAULT_PLATFORMS_IMPORT_FIELDS = {
  salesforce: salesforceDefalutsImportFields(),
  hubspot: hubspotDefaultsImportFields(),
};

const salesforceDefaultsValues = () => ({
  [FUNNEL1]: {
    rules: [{
      objectType: 'contact',
      field: 'CreatedDate',
      values: ['ANY'],
    }],
    groupBy: 'contacts',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
    isPipeline: false,
  },
  [FUNNEL2]: {
    rules: [{
      objectType: 'opportunity',
      field: 'CreatedDate',
      values: ['ANY'],
    }],
    groupBy: 'deals',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
    isPipeline: true,
  },
  [FUNNEL3]: {
    rules: [{
      objectType: 'opportunity',
      field: 'StageName',
      values: ['Closed Won'],
    }],
    groupBy: 'deals',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isPipeline: false,
    isRevenue: true,
  },
  [LOST_FUNNEL]: {
    rules: [{
      objectType: 'opportunity',
      field: 'StageName',
      values: ['Closed Lost'],
    }],
    groupBy: 'deals',
    funnelTransitionsModel: funnelTransitionsModels.boomerang,
    isRevenue: false,
  },
});

export const DEFAULT_PLATFORMS_MAPPING = {
  salesforce: salesforceDefaultsValues(),
  hubspot: hubspotDefaultsValues(),
};

export const getNewFunnelDescription = ({ funnelNickname }) => `${funnelNickname} created in the given time frame.`;

export const getVelocityDescription = ({ sourceFunnelNickname, destFunnelNickname }) => `Average velocity between ${sourceFunnelNickname} and ${destFunnelNickname} transitions. In other words, how many days it takes (on average) for a ${sourceFunnelNickname} to became an ${destFunnelNickname}.`;

export const getConversionRateDescription = ({ sourceFunnelNickname, destFunnelNickname }) => `The percentage of ${sourceFunnelNickname} that get converted to ${destFunnelNickname}. This metric will be adjusted according to your velocity between stage transitions (Cohort analysis).`;

export const makeCustomFunnel = ({
  title, orderInGroup, isNew, group, nickname = title, description,
}) => ({
  title,
  nickname,
  activationChannel: '',
  budgetBEP: 0,
  calculationBasedOn: [],
  declineRate: 0.13,
  description,
  displayType: 'number',
  formula: '',
  group,
  importance: 30,
  improveDelta: 27,
  isCalculated: false,
  isDirectionUp: true,
  isInteger: true,
  isObjective: true,
  isPercentage: false,
  isRefreshed: !!isNew,
  logoUrl: '/icons/users_indicator.svg',
  orderInGroup,
  powerFactor: 0.8,
  range: {
    max: 7000, min: 0,
  },
  referenceBudget: 120000,
  timeframe: isNew ? 'This month' : 'Present',
  type: 'number',
});

export const CROSS_FUNNEL_TOGGLE = {
  EFFECTIVENESS: 0,
  EFFICIENCY: 1,
};

export const getVelocityKey = (conversionIndicator) => {
  const currentStage = mapChartIndicator(conversionIndicator);
  return getVelocityMapping()[currentStage] || [];
};

export const getStagesAmounts = (data) => {
  const withZeroDefault = (getter) => (arg, ...other) => getter(arg, ...other) || 0;

  const influencedMapping = getInfluencedMapping();
  const stageDataKeys = ['cross-funnel', 'webVisits', 'pageViews', ...Object.keys(influencedMapping), ...Object.values(influencedMapping)];

  const stagesAmountMap = {};
  stageDataKeys.forEach((dataKey) => {
    const number = formatNumberWithDecimalPoint(sumBy(data, withZeroDefault((item) => item[dataKey])));
    stagesAmountMap[dataKey] = dataKey === 'cross-funnel' ? '' : +number;
  });
  return stagesAmountMap;
};
