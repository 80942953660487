import React from 'react';
import ReactDOMServer from 'react-dom/server';

import MetricStatsCard from 'components/common/MetricStatsCard';
import { formatBudget, formatNumber } from 'components/utils/budget';
import { getRevenueFunnel, getPipelineFunnel, FUNNEL1 } from 'components/utils/indicators';

import style from 'styles/users/journey-stats.css';

const styles = style.locals || {};

function AccountEngagementCards({
  selectedKpiFocus = {},
  data,
  revenueFunnel = getRevenueFunnel(),
  pipelineFunnel = getPipelineFunnel(),
  isLoaded,
  kpiFocusOptions,
}) {
  function getTouchpointsTooltip({ tooltipData = {} }) {
    const touchpointsTooltip = Object.entries(tooltipData).map(([key, value]) => `${key}: ${value}`);
    return ReactDOMServer.renderToStaticMarkup(
      <div className={styles.tooltipForMetric}>
        Average number of touchpoints before your selected KPI, in this account.
        {touchpointsTooltip.length > 0 && (
          <>
            <br />
            <ul>
              {touchpointsTooltip.map((touchpoint, index) => {
                const touchpointKey = `touchpoint-${index}`;
                return (
                  <li className={styles.tooltipCapitalized} key={touchpointKey}>{touchpoint}</li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    );
  }

  function getBenchmarkTooltip() {
    return ReactDOMServer.renderToStaticMarkup(
      <div className={styles.tooltipForMetricBenchmark}>
        {`The average number of touchpoints before ${selectedKpiFocus.label} across`}
        <b>all accounts</b>
        {' in the selected timeframe.'}
      </div>
    );
  }

  function getContactsTooltip({ tooltipData = {} }) {
    const contactsTooltip = tooltipData.list?.filter((item) => item !== tooltipData.firstTouch && item !== tooltipData.lastTouch) || [];
    const isAdditionalContacts = parseInt(tooltipData.additionalContacts, 10) > 0;
    if (tooltipData.firstTouch) {
      contactsTooltip.unshift(`${tooltipData.firstTouch} (First)`);
    }
    if (tooltipData.lastTouch) {
      contactsTooltip.unshift(`${tooltipData.lastTouch} (Last)`);
    }
    const contactsPercentage = Math.round((tooltipData.engaged / tooltipData.total) * 100);
    return ReactDOMServer.renderToStaticMarkup(
      <div className={styles.tooltipForMetric}>
        {`${contactsPercentage}% of this account’s contacts had an interaction in the selected timeframe.`}
        {contactsTooltip.length > 0 && (
          <>
            <div>
              <b>Contacts:</b>
              <ul>
                {contactsTooltip.map((contact) => (
                  <li key={`contact-${contact}`}>{contact}</li>
                ))}
              </ul>
            </div>
            {isAdditionalContacts && (
            <div>{`+${tooltipData.additionalContacts} additional contacts`}</div>
            )}
          </>
        )}
      </div>
    );
  }

  function getVelocityBottomLabel({ kpiFocus }) {
    if (FUNNEL1 === kpiFocus.value) {
      return `First touch -> ${kpiFocus.label}`;
    }
    const firstFunnelLabel = kpiFocusOptions.find((option) => option.value === FUNNEL1).label;
    return ` ${firstFunnelLabel} -> ${kpiFocus.label}`;
  }

  const isKpiFocusIsPipeline = selectedKpiFocus.value === pipelineFunnel;
  const isKpiFocusIsRevenue = selectedKpiFocus.value === revenueFunnel;

  let revenueOrPipelineKey = null;
  if (isKpiFocusIsPipeline) {
    revenueOrPipelineKey = 'pipeline';
  }
  if (isKpiFocusIsRevenue) {
    revenueOrPipelineKey = 'revenue';
  }

  return (
    <div data-testid="metricStatsCard-wrapper" className={styles.metricStatsCardWrapper}>
      <MetricStatsCard
        metricValue={data?.touchpoints?.count}
        metricLabel="Touchpoints"
        metricTooltip={getTouchpointsTooltip({ tooltipData: data?.touchpoints?.countPerDepartment })}
        bottomMetricValue={data?.touchpoints?.benchmark}
        bottomMetricLabel="Benchmark: "
        bottomMetricTooltip={getBenchmarkTooltip()}
        isLoaded={isLoaded}
      />
      <MetricStatsCard
        metricValue={data?.contacts?.engaged}
        metricLabel="Engaged Contacts"
        metricTooltip={getContactsTooltip({ tooltipData: data?.contacts })}
        bottomMetricValue={data?.contacts?.total}
        bottomMetricLabel="Total Contacts: "
        isLoaded={isLoaded}
      />
      <MetricStatsCard
        metricValue={`${formatNumber(data?.velocity, true, 1)} Days`}
        metricLabel={FUNNEL1 === selectedKpiFocus.value ? 'Research time' : 'Velocity'}
        bottomMetricLabel={getVelocityBottomLabel({ kpiFocus: selectedKpiFocus })}
        isLoaded={isLoaded}
      />
      {(isKpiFocusIsPipeline || isKpiFocusIsRevenue) && (
      <>
        <MetricStatsCard
          metricValue={`${formatBudget(data?.[revenueOrPipelineKey]?.total || 0, true, true)}`}
          metricLabel={`Total ${revenueOrPipelineKey}`}
          dataTestId="metricStatsCard-total"
          isLoaded={isLoaded}
        />
        <MetricStatsCard
          metricValue={`${formatBudget(data?.[revenueOrPipelineKey]?.marketing || 0, true, true)}`}
          metricLabel={`Marketing Attributed ${revenueOrPipelineKey}`}
          dataTestId="metricStatsCard-MarketingAttributed"
          isLoaded={isLoaded}
        />
        <MetricStatsCard
          metricValue={`${formatBudget(data?.[revenueOrPipelineKey]?.sales || 0, true, true)}`}
          metricLabel={`Sales Attributed ${revenueOrPipelineKey}`}
          dataTestId="metricStatsCard-SalesAttributed"
          isLoaded={isLoaded}
        />
      </>
      )}
    </div>
  );
}

export default AccountEngagementCards;
