export const widgetBuilderTypes = {
  table: 'table',
  chart: 'chart',
  metric: 'metric',
};

export const widgetSelectionToggleIcons = [{
  value: widgetBuilderTypes.table,
  icon: 'assets/table-icon.svg',
  tooltip: 'Table',
}, {
  value: widgetBuilderTypes.chart,
  icon: 'assets/linesChart.svg',
  tooltip: 'Trend Breakdown',
}, {
  value: widgetBuilderTypes.metric,
  icon: 'assets/single-metric-icon.svg',
  tooltip: 'Metric',
}];

export const widgetNameErrorMessage = {
  nameIsMissing: 'Please enter widget name',
  duplicateName: 'This name is already in use, please select another name',
};

export const propertiesToKeepOnChangeKind = ['filters', 'timeFrame'];
