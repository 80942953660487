import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import userStore from 'stores/userStore';

import { getIndicatorsWithProps } from 'components/utils/indicators';
import {
  getChannelsWithNicknames,
  getChannelsWithProps,
  getNickname as getChannelNickname,
} from 'components/utils/channels';
import { rightY, leftY, columnTypesForExport } from 'components/pages/analyze/OverviewTab/logic/enums';
import { FREQUENCY_OPTIONS } from 'components/utils/frequency';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';

const getMeasureUnitByKey = ({ key, chartData }) => {
  const dataFirstItem = chartData[0];
  const dataMetrics = dataFirstItem?.metrics || [];
  const metricItem = dataMetrics.find((item) => item.metricName === key);
  return metricItem?.measureUnit || null;
};

export function prepareChartDataForParse({ chartData = {} }) {
  const dateItems = {};
  const datesOrder = new Set();

  for (const [metricName, metricItem] of Object.entries(chartData)) {
    const { metricType, measureUnit, metricData = [] } = metricItem;
    for (const { date, value, growth } of metricData) {
      datesOrder.add(date);
      if (!dateItems[date]) {
        dateItems[date] = {
          date,
          metrics: [],
        };
      }
      dateItems[date].metrics.push({
        metricName,
        metricType,
        measureUnit,
        value,
        growth,
      });
    }
  }

  return [...datesOrder].map((date) => dateItems[date]);
}

export const perseChartDataUnitsForChartYAxis = ({
  chartData, selectedMetrics, isShowOnlyLines,
}) => {
  const unitsForYAxis = {
    [leftY]: null,
    [rightY]: null,
  };
  if (isShowOnlyLines) {
    const firstItemKey = selectedMetrics.performance[0];
    const secondItemKey = selectedMetrics.performance[1];
    unitsForYAxis[leftY] = getMeasureUnitByKey({ key: firstItemKey, chartData });
    unitsForYAxis[rightY] = secondItemKey ? getMeasureUnitByKey({ key: secondItemKey, chartData }) : null;
  } else {
    const leftItemKey = selectedMetrics.spend[0];
    const rightItemKey = selectedMetrics.performance[0];
    unitsForYAxis[leftY] = getMeasureUnitByKey({ key: leftItemKey, chartData });
    unitsForYAxis[rightY] = rightItemKey ? getMeasureUnitByKey({ key: rightItemKey, chartData }) : null;
  }
  return unitsForYAxis;
};

export const perseDataForChart = ({ chartData }) => {
  const updatedChartData = [];
  for (const item of chartData) {
    const chartDataItem = {};
    chartDataItem.date = item.date;
    for (const metric of item.metrics) {
      chartDataItem[metric.metricName] = metric.value;
      chartDataItem[`${metric.metricName}Growth`] = metric.growth;
    }
    updatedChartData.push(chartDataItem);
  }
  return updatedChartData;
};

export const getSelectedMetricsFromSavedMetrics = ({ metrics }) => {
  if (!metrics) {
    return null;
  }
  const performance = [];
  const spend = [];
  for (const metric of metrics) {
    if (metric.metricType === 'channelCost') {
      spend.push(metric.metricName);
    } else {
      performance.push(metric.metricName);
    }
  }
  return {
    performance,
    spend,
  };
};

function isGroupIdRelevant({ groupId, flags }) {
  const numericGroupId = +groupId;
  if (flags.historicalPerformanceWebMetricsAnalyzeOverview) {
    const groupsToShowInHistoricalPerformance = [1, 2, 4];
    return groupsToShowInHistoricalPerformance.includes(numericGroupId);
  }
  return numericGroupId !== 4 && numericGroupId < 5 && numericGroupId !== 3;
}

export const getPerformanceMetricsListItems = ({
  metricsOptions, flags,
}) => {
  const performanceMetrics = [
    {
      metricLabel: 'Visits',
      metricName: 'Visits',
      metricType: 'webVisits',
    },
    {
      metricLabel: 'Pageviews',
      metricName: 'Pageviews',
      metricType: 'pageViews',
    },
  ];

  const funnelsOptions = metricsOptions.map((funnel) => {
    const revenueOrPipeline = ['revenue', 'pipeline'];
    const type = revenueOrPipeline.includes(funnel.value) ? 'revenue' : 'funnelStage';
    return {
      metricLabel: funnel.label,
      metricName: funnel.value,
      metricType: type,
    };
  });

  const properties = getIndicatorsWithProps() || {};

  const velocityOptions = [];
  const conversionsOptions = [];

  for (const [metric, metricData] of Object.entries(properties)) {
    const group = metricData.group || 0;
    if (!isGroupIdRelevant({ groupId: group, flags })) {
      continue;
    }

    const metricLabel = userStore.getMetricNickname({ metric, isSingular: true });
    const metricName = metric;
    const metricType = userStore.getMetricType({ metric });

    if (metricType === 'velocity') {
      velocityOptions.push({
        metricLabel,
        metricName,
        metricType,
      });
      continue;
    }
    if (metricType === 'conversionRate') {
      conversionsOptions.push({
        metricLabel,
        metricName,
        metricType,
      });
    }
  }

  performanceMetrics.push(...funnelsOptions, ...velocityOptions, ...conversionsOptions);
  return performanceMetrics;
};

export const getSpendMetricsListItems = () => {
  const sepndOptions = [{
    metricLabel: 'Total spend',
    metricName: 'total',
    metricType: 'channelCost',
  }];
  const channels = getChannelsWithNicknames();
  const channelsOptions = channels.map((channel) => ({
    metricLabel: channel.label,
    metricName: channel.value,
    metricType: 'channelCost',
  }));
  sepndOptions.push(...channelsOptions);
  return sepndOptions;
};

export const getColorsLookupFromChannels = () => {
  const channelKeysWithTotals = [...Object.keys(getChannelsWithProps())];
  const colorsLookup = {};
  for (const [index, channel] of Object.entries(channelKeysWithTotals)) {
    colorsLookup[channel] = index;
  }
  return colorsLookup;
};

export function getLegendTitleByChannel({ channel }) {
  const legendPrefix = 'Spend:';

  const channelNickname = getChannelNickname(channel);
  if (!channelNickname) {
    return '';
  }
  return `${legendPrefix} ${channelNickname}`;
}

export function getTrendAnalysisExportColumns({ frequency, metrics }) {
  const metricsObjects = metrics.map((metric) => ({
    key: columnTypesForExport.metric,
    value: metric.value,
    label: metric.label,
  }));

  const frequencyObject = Object.values(FREQUENCY_OPTIONS).find(
    (option) => option.value === frequency
  );
  return ([
    {
      key: columnTypesForExport.frequency,
      value: frequencyObject.value,
      label: frequencyObject.label,
    },
    ...metricsObjects,
  ]);
}

export function getTrendAnalysisEventData({ widgetConfig }) {
  if (isEmpty(widgetConfig?.trendAnalysisParams)) {
    return {};
  }

  const selectedColumns = widgetConfig.trendAnalysisParams.selectedColumns;
  const timeFrame = widgetConfig.timeFrameLabel || `${moment(widgetConfig.timeFrame.startDate).toISOString()} - ${moment(widgetConfig.timeFrame.endDate).toISOString()}`;
  const metricLabels = [];
  for (const selectedColumn of selectedColumns) {
    if (selectedColumn.key === columnTypesForExport.metric) {
      metricLabels.push(selectedColumn.label);
    }
  }

  return {
    widget: widgetConfig.type,
    primaryDimension: null,
    secondaryDimension: null,
    metric: metricLabels,
    frequency: selectedColumns.find((column) => column.key === columnTypesForExport.frequency)?.label,
    timeFrame,
    isToDate: widgetConfig.trendAnalysisParams.shouldUseRelativeTimeframe,
  };
}

export function parseTrendAnalysisDataForExport({ data: chartData, selectedColumns }) {
  const headers = [];
  const metricValues = [];
  for (const selectedColumn of selectedColumns) {
    headers.push(selectedColumn.label);
    if (selectedColumn.key === columnTypesForExport.metric) {
      metricValues.push(selectedColumn.value);
    }
  }

  const parsedObj = [];

  for (const barData of chartData) {
    const row = [];
    row.push(barData.date);
    for (const metricValue of metricValues) {
      const formattedNumber = Number(formatNumberWithDecimalPoint(barData[metricValue]));
      row.push(formattedNumber);
    }
    parsedObj.push(row);
  }

  parsedObj.unshift(headers);
  return parsedObj;
}
