import React from 'react';
import Component from 'components/Component';
import { getColor } from 'components/utils/colors';

import style from 'styles/dashboard/goal.css';

class GoalProgress extends Component {
  style = style;

  static defaultProps = {
    sqSize: 200, // size of the enclosing square
    strokeWidth: 16,
    color: getColor(0),
  };

  render() {
    const {
      sqSize,
      strokeWidth,
      fillValue,
      children,
      style: customStyle,
      goalColor,
    } = this.props;

    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2; // Radius in px
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * Math.min(1, fillValue)) / 2;
    return (
      <svg
        style={customStyle}
        width={sqSize * 1.2}
        height={sqSize}
        viewBox={viewBox}
      >
        <circle
          className={this.classes.circleBackground}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={strokeWidth}
          transform={`rotate(-180 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashArray / 2,
          }}
        />
        <circle
          className={this.classes.circleProgress}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-180 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
            stroke: goalColor,
          }}
        />
        {children}
      </svg>
    );
  }
}

export default GoalProgress;
