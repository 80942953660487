import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import InfoMarker from 'components/pages/InfoMarker';
import Select from 'components/controls/Select';

import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { CRM_OBJECT, getFieldOptionsFromCRM } from 'components/utils/indicators';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals;
const WIDTH = '100%';

const CRMImportFieldsRow = ({
  crmFields, fieldGroupIndex, rowIndex, onDeleteMergingConfiguration, fieldToMergeLabel, fieldToMergeIndex,
  showMergedFields,
}) => {
  const {
    selectedCRMItems, mappingData = {}, CRMImportFieldsMapping, setParentState,
  } = useCRMCContext();

  const selectedCRMItemsToOptions = selectedCRMItems.map((item) => ({ value: item, label: item }));

  const onCRMFieldChange = ({ value = '' }) => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    const updatedMergingConfiguration = updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration;
    updatedMergingConfiguration[rowIndex].crmPlatform = value;
    updatedMergingConfiguration[rowIndex].objectName = null;
    updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration = updatedMergingConfiguration;
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const getLabelFromFieldToMerge = (mergingConfiguration) => {
    const crmPlatform = get(mergingConfiguration, 'crmPlatform');
    const objectName = get(mergingConfiguration, 'objectName');
    const fieldsToMerge = get(mergingConfiguration, 'fieldsToMerge', [])[0];
    const fieldOptionsFromCRM = getFieldOptionsFromCRM(crmPlatform, mappingData, objectName);
    const selectedFieldOptions = fieldOptionsFromCRM.find((option) => option.name === fieldsToMerge);
    const mergingFieldsLabel = selectedFieldOptions?.label || fieldsToMerge;
    return mergingFieldsLabel;
  };

  const getNewFieldsGroupLabel = (mergingConfiguration) => {
    const mergingObjects = new Set();
    for (const mergingConfig of mergingConfiguration) {
      mergingObjects.add(mergingConfig.objectName);
    }
    const mergingFieldsLabel = getLabelFromFieldToMerge(mergingConfiguration[0]);
    const mergingObjectsLabel = Array.from(mergingObjects).join(' & ');
    return `${mergingFieldsLabel} (${mergingObjectsLabel})`;
  };

  const onObjectNameChange = ({ value = '' }) => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    const updatedMergingConfiguration = updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration;

    if (updatedMergingConfiguration[rowIndex].fieldsToMerge.length > 1) {
      updatedMergingConfiguration[rowIndex].fieldsToMerge.splice(fieldToMergeIndex, 1);
      const newMergingConfig = { ...updatedMergingConfiguration[rowIndex] };
      newMergingConfig.fieldsToMerge = [''];
      newMergingConfig.objectName = value;
      updatedMergingConfiguration.splice(rowIndex + 1, 0, newMergingConfig);
    } else {
      updatedMergingConfiguration[rowIndex].fieldsToMerge[fieldToMergeIndex] = '';
      updatedMergingConfiguration[rowIndex].objectName = value;
    }
    updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration = updatedMergingConfiguration;
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const onfieldsToMergeChange = ({ value = '' }) => {
    const updatedCRMImportFieldsMapping = [...CRMImportFieldsMapping];
    const updatedMergingConfiguration = updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration;
    updatedMergingConfiguration[rowIndex].fieldsToMerge[fieldToMergeIndex] = value;
    updatedCRMImportFieldsMapping[fieldGroupIndex].mergingConfiguration = updatedMergingConfiguration;

    const isRenameFieldsGroup = get(updatedCRMImportFieldsMapping, [fieldGroupIndex, 'isRenameFieldsGroup']);
    if (!isRenameFieldsGroup) {
      const newFieldsGroupLabel = getNewFieldsGroupLabel(updatedMergingConfiguration);
      updatedCRMImportFieldsMapping[fieldGroupIndex].label = newFieldsGroupLabel;
    }
    setParentState({ CRMImportFieldsMapping: updatedCRMImportFieldsMapping });
  };

  const crmPlatform = get(CRMImportFieldsMapping, [fieldGroupIndex, 'mergingConfiguration', rowIndex, 'crmPlatform'], selectedCRMItems[0]);
  const { objectName } = crmFields;
  const crmPlatformObjectsOptions = CRM_OBJECT[crmPlatform].map((item) => ({ value: item, label: item }));
  const fieldOptionsFromCRM = getFieldOptionsFromCRM(crmPlatform, mappingData, objectName);
  const fieldOptionsForSelect = fieldOptionsFromCRM.map(({ name: value, label }) => ({ value, label }));

  if (!showMergedFields && (rowIndex !== 0 || fieldToMergeIndex !== 0)) {
    return <></>;
  }

  return (
    <div className={styles.customFieldItemSetting}>
      <div className={styles.selectGroup}>
        <div className={classNames(styles.fieldText)}>
          CRM
        </div>
        <Select
          selected={crmPlatform}
          select={{ options: selectedCRMItemsToOptions }}
          customStyle={{ height: '40px', width: WIDTH }}
          onChange={onCRMFieldChange}
        />
      </div>
      <div className={styles.selectGroup}>
        <div className={classNames(styles.fieldText)}>
          {'Object '}
          <InfoMarker tooltipText={'Choose the CRM object that stores this field\'s data'} TooltipProps={{ place: 'right' }} />
        </div>
        <Select
          selected={objectName}
          customStyle={{ height: '40px', width: WIDTH }}
          select={{ options: crmPlatformObjectsOptions }}
          onChange={onObjectNameChange}
        />
      </div>
      <div className={styles.selectGroup}>
        <div className={classNames(styles.fieldText)}>
          {'Field '}
          <InfoMarker tooltipText="Choose the CRM field that you would like to import" TooltipProps={{ place: 'right' }} />
        </div>
        <Select
          selected={fieldToMergeLabel}
          customStyle={{ height: '40px', width: WIDTH }}
          select={{ options: fieldOptionsForSelect }}
          onChange={onfieldsToMergeChange}
        />
      </div>
      <div>
        <div onClick={() => onDeleteMergingConfiguration(rowIndex)} className={classNames(styles.funnelItemEdit)}>
          <div className={styles.funnelItemEditIcon} data-icon="platform:trashIcon" />
        </div>
      </div>
    </div>
  );
};

export default CRMImportFieldsRow;
