exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".insights__inner-3jm{padding:42px 38px}.insights__popup-content-1PR{padding:0!important;margin:0!important}.insights__popup-inner-2XI{overflow:initial!important}.insights__channel-icons-3lm{background-color:#fff;border-radius:50%;position:relative;width:40px;height:40px;box-shadow:-2px 4px 4px 0 hsla(0,0%,59%,.5)}.insights__icon-2iE{width:15px;height:15px;background-repeat:no-repeat;background-size:contain;position:absolute}.insights__upper-icon-2ai{left:7px;top:8px;z-index:2}.insights__lower-icon-KXt{left:17px;top:18px;z-index:1}.insights__close-popup-2oq{background:url(/assets/x-wight.svg) 50% no-repeat;background-size:contain;width:15px;height:15px;position:absolute;left:230px;top:1px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"inner": "insights__inner-3jm",
	"inner": "insights__inner-3jm",
	"popup-content": "insights__popup-content-1PR",
	"popupContent": "insights__popup-content-1PR",
	"popup-inner": "insights__popup-inner-2XI",
	"popupInner": "insights__popup-inner-2XI",
	"channel-icons": "insights__channel-icons-3lm",
	"channelIcons": "insights__channel-icons-3lm",
	"icon": "insights__icon-2iE",
	"icon": "insights__icon-2iE",
	"upper-icon": "insights__upper-icon-2ai insights__icon-2iE",
	"upperIcon": "insights__upper-icon-2ai insights__icon-2iE",
	"lower-icon": "insights__lower-icon-KXt insights__icon-2iE",
	"lowerIcon": "insights__lower-icon-KXt insights__icon-2iE",
	"close-popup": "insights__close-popup-2oq",
	"closePopup": "insights__close-popup-2oq"
};