exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".reason-popup__center-39f{display:flex;justify-content:center}.reason-popup__content-1QC{margin:0;padding:0}.reason-popup__inner-uxt{margin:50px 65px;width:100%}.reason-popup__title-1AU{font-size:40px;color:#24b10e;text-align:center;margin-bottom:18px}.reason-popup__sub-title-2_U{font-size:20px;color:#53626b;margin-bottom:9px;white-space:pre-line}.reason-popup__sub-title-bold-khn{font-size:20px;color:#53626b;text-align:center;margin-bottom:9px;font-weight:700}.reason-popup__boxes-LaY{margin:24px 0;display:inline-flex}.reason-popup__box-19q{width:182px;height:181px;border-radius:8px;background-color:#fff;border:2px solid rgba(0,0,0,.4);margin:0 12px;cursor:pointer}.reason-popup__scratch-1Rz{background:url(/assets/reason-icons/scratch.svg) center 10% no-repeat;background-size:95px 85px}.reason-popup__optimize-CIU{background:url(/assets/reason-icons/optimize.svg) center 10% no-repeat;background-size:95px 85px}.reason-popup__control-c39{background:url(/assets/reason-icons/control.svg) center 10% no-repeat;background-size:95px 85px}.reason-popup__end-to-end-2ok{background:url(/assets/reason-icons/end-to-end.svg) center 10% no-repeat;background-size:95px 85px}.reason-popup__box-text-2Og{font-size:14px;text-align:center;color:#53626b;margin:110px 17px 0}.reason-popup__checkbox-i-Y{width:20px;height:20px}.reason-popup__other-box-raJ{width:262px;height:64px;border-radius:5px;background-color:#fff;border:2px solid rgba(0,0,0,.4);font-size:20px;margin-bottom:9px;text-align:center}", ""]);

// exports
exports.locals = {
	"center": "reason-popup__center-39f",
	"center": "reason-popup__center-39f",
	"content": "reason-popup__content-1QC",
	"content": "reason-popup__content-1QC",
	"inner": "reason-popup__inner-uxt",
	"inner": "reason-popup__inner-uxt",
	"title": "reason-popup__title-1AU",
	"title": "reason-popup__title-1AU",
	"sub-title": "reason-popup__sub-title-2_U",
	"subTitle": "reason-popup__sub-title-2_U",
	"sub-title-bold": "reason-popup__sub-title-bold-khn",
	"subTitleBold": "reason-popup__sub-title-bold-khn",
	"boxes": "reason-popup__boxes-LaY",
	"boxes": "reason-popup__boxes-LaY",
	"box": "reason-popup__box-19q",
	"box": "reason-popup__box-19q",
	"scratch": "reason-popup__scratch-1Rz reason-popup__box-19q",
	"scratch": "reason-popup__scratch-1Rz reason-popup__box-19q",
	"optimize": "reason-popup__optimize-CIU reason-popup__box-19q",
	"optimize": "reason-popup__optimize-CIU reason-popup__box-19q",
	"control": "reason-popup__control-c39 reason-popup__box-19q",
	"control": "reason-popup__control-c39 reason-popup__box-19q",
	"end-to-end": "reason-popup__end-to-end-2ok reason-popup__box-19q",
	"endToEnd": "reason-popup__end-to-end-2ok reason-popup__box-19q",
	"box-text": "reason-popup__box-text-2Og",
	"boxText": "reason-popup__box-text-2Og",
	"checkbox": "reason-popup__checkbox-i-Y",
	"checkbox": "reason-popup__checkbox-i-Y",
	"other-box": "reason-popup__other-box-raJ",
	"otherBox": "reason-popup__other-box-raJ"
};