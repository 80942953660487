exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".aiPrompts__pageWrap-3Pq{padding:40px 90px}.aiPrompts__promptsWrap-1yj{gap:12px;padding:20px 0;display:grid;grid-template-columns:1fr 1fr 1fr}.aiPrompts__title-X2j{color:#182033;font-size:20px;font-weight:600;line-height:21px}.aiPrompts__subTitle-1Ua{color:#707ea7;font-size:16px;font-weight:400;padding-top:12px;max-width:882px}", ""]);

// exports
exports.locals = {
	"pageWrap": "aiPrompts__pageWrap-3Pq",
	"pageWrap": "aiPrompts__pageWrap-3Pq",
	"promptsWrap": "aiPrompts__promptsWrap-1yj",
	"promptsWrap": "aiPrompts__promptsWrap-1yj",
	"title": "aiPrompts__title-X2j",
	"title": "aiPrompts__title-X2j",
	"subTitle": "aiPrompts__subTitle-1Ua",
	"subTitle": "aiPrompts__subTitle-1Ua"
};