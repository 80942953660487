import React, { Children, cloneElement } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import { ReactTableDefaults } from 'react-table';

const TbodyComponent = (props) => {
  const { children, ...restProps } = props;
  const rows = Children.toArray(children);
  return (
    <ReactTableDefaults.TbodyComponent {...restProps}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            width={width}
            height={height}
            rowCount={rows.length}
            rowHeight={props.rowHeight}
            rowRenderer={({ key, index, style }) => (
              <div key={key} style={style} className="virtualRow">
                {rows[index]}
              </div>
            )}
          />
        )}
      </AutoSizer>
    </ReactTableDefaults.TbodyComponent>
  );
};

export default (Component) => class RTSlowVirtualizedTable extends React.Component {
  render() {
    return (
      <Component
        {...this.props}
        TbodyComponent={(tableProps) => TbodyComponent({
          ...tableProps,
          rowHeight: this.props.rowHeight,
        })}
        showPagination={false}
        minRows={0}
      />
    );
  }
};
