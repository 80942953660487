export function validateFilterLogicTextInput({ input, filtersCount }) {
  input = input.toUpperCase();
  const inputWithCorrectSpacing = input.replace(/\(/g, ' ( ').replace(/\)/g, ' ) ').replace(/\s+/g, ' ').trim();
  const expressions = inputWithCorrectSpacing.split(/\s+/);

  if (expressions.length === 0) {
    return { isValid: false, errorMessage: 'The expression is empty.' };
  }

  let parenthesesDepth = 0;
  let expectNumber = true;
  const isNumber = (token) => /^\d+$/.test(token);
  const isOperator = (token) => /^(AND|OR)$/.test(token);
  const numberSet = new Set();
  let lastCharIsOperatorOrOpeningParam = false;
  const andOrMixedWithoutGrouping = /(\d+\s+AND\s+\d+\s+OR\s+\d+)|(\d+\s+OR\s+\d+\s+AND\s+\d+)/;

  // Ensure that 'AND' and 'OR' aren't used in a mixed way without explicit grouping
  if (andOrMixedWithoutGrouping.test(inputWithCorrectSpacing)) {
    return { isValid: false, errorMessage: 'Group \'AND\' and \'OR\' with parentheses for clarity, like \'(1 AND 2) OR 3\'.' };
  }

  for (let i = 0; i < expressions.length; i++) {
    const expression = expressions[i];

    if (isNumber(expression)) {
      if (!expectNumber) {
        return { isValid: false, errorMessage: 'Insert \'AND\' or \'OR\' between numbers.' };
      }
      const num = parseInt(expression, 10);
      if (num < 1 || num > filtersCount) {
        return { isValid: false, errorMessage: `Filter ${num} is invalid. Use an existing filter number.` };
      }
      if (numberSet.has(num)) {
        return { isValid: false, errorMessage: `Filter ${num} is used more than once. Remove duplicates.` };
      }
      numberSet.add(num);
      expectNumber = false;
      lastCharIsOperatorOrOpeningParam = false;
    } else if (isOperator(expression)) {
      if (expectNumber) {
        return { isValid: false, errorMessage: `Operator "${expression}" cannot follow an operator or an opening parenthesis.` };
      }
      expectNumber = true;
      lastCharIsOperatorOrOpeningParam = true;
    } else if (expression === '(') {
      parenthesesDepth++;
      if (!expectNumber) {
        return { isValid: false, errorMessage: 'Check the input order: it should be number, then \'AND\'/\'OR\'/parenthesis' };
      }
      expectNumber = true;
      lastCharIsOperatorOrOpeningParam = true;
      const nextTowExpressionChar = expressions[i + 2];
      if (nextTowExpressionChar === ')') {
        return { isValid: false, errorMessage: 'Don\'t use parentheses around a single filter without \'AND\'/\'OR\'.' };
      }
    } else if (expression === ')') {
      parenthesesDepth--;
      if (parenthesesDepth < 0) {
        return { isValid: false, errorMessage: 'Pair every \'(\' with a \')\'.' };
      }
      if (expectNumber || lastCharIsOperatorOrOpeningParam) {
        return { isValid: false, errorMessage: 'Don\'t place \'(\' right after \')\'. Use an operator in between.' };
      }
      expectNumber = false;
      lastCharIsOperatorOrOpeningParam = false;
      const previousTwoExpressionChar = expressions[i - 2];
      if (previousTwoExpressionChar === '(') {
        return { isValid: false, errorMessage: 'Don\'t use parentheses around a single filter without \'AND\'/\'OR\'.' };
      }
    } else {
      return { isValid: false, errorMessage: `Remove unsupported character: ${expression}.` };
    }
  }

  if (parenthesesDepth !== 0) {
    return { isValid: false, errorMessage: 'Pair every \'(\' with a \')\'.' };
  }

  if (expectNumber) {
    return { isValid: false, errorMessage: 'Finish with a number or close all parentheses.' };
  }

  if (numberSet.size !== filtersCount) {
    const missingNumbers = [...Array(filtersCount).keys()].map((x) => x + 1).filter((x) => !numberSet.has(x));
    return { isValid: false, errorMessage: `All filters must be used. Missing filter: ${missingNumbers.join(', ')}.` };
  }

  return { isValid: true, errorMessage: null };
}

export function getFilterLogicDefaultValue({ filtersCount, startIndex = 1 }) {
  if (filtersCount <= 1 && startIndex === 1) {
    return '';
  }
  const numbers = [];
  for (let i = startIndex; i <= (filtersCount + startIndex - 1); i++) {
    numbers.push(i);
  }
  return numbers.join(' AND ');
}
