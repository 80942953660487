exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".chart-select__input-zwT{color:#707ea7;width:95px;height:30px;padding:5px;font-size:11px;font-weight:600;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;cursor:default}.chart-select__icon-h5y{background-position:50%;background-repeat:no-repeat;background-size:contain;display:inline-block;height:10px;transition:transform .15s;width:10px;transform:rotate(-90deg)}.chart-select__icon__down-11i{transform:rotate(90deg)}.chart-select__arrow-wrapper-BXP{width:110px;height:15px;margin-left:-100px;z-index:100}[data-icon=\"plan:monthNavigation\"]{background-image:url(\"/assets/plan_icons/shape.svg\")}", ""]);

// exports
exports.locals = {
	"input": "chart-select__input-zwT",
	"input": "chart-select__input-zwT",
	"icon": "chart-select__icon-h5y",
	"icon": "chart-select__icon-h5y",
	"icon__down": "chart-select__icon__down-11i",
	"iconDown": "chart-select__icon__down-11i",
	"arrow-wrapper": "chart-select__arrow-wrapper-BXP",
	"arrowWrapper": "chart-select__arrow-wrapper-BXP"
};