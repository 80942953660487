import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Spinner from 'components/pages/journeys/Spinner';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import FilterPopup from 'components/pages/users/Filters/FilterPopup';
import FilterLogicExpressionPopup from 'components/pages/integrations/FilterLogicExpressionPopup';

import { makeFilter } from 'components/utils/filters';
import { isCostCampaignsFilter } from 'components/utils/filters/logic';
import { getUpdatedPredefinedFilterForStage, getRemovedPredefinedFilterForStage } from 'components/pages/integrations/logic/PreDefinedFiltersStep';

import onBoardingStyle from 'styles/onboarding/onboarding.css';
import styles from 'styles/settings/goals/add-custom-funnel.css';
import filterStyles from 'styles/users/filters.css';

import { checkIfFilterDataExists } from '../users/Filters/FilterPanel/logic/FiltersPanel';

class PreDefinedFiltersStep extends Component {
  style = filterStyles;

  styles = [styles];

  constructor(props) {
    super(props);
    this.state = {
      showFilterPopup: false,
      filter: null,
      currentFunnel: '',
      filtersItems: [],
      isTagPopup: false,
      selectedFunnelRow: null,
      showFilterLogicUpdateMessageForStage: null,
    };
  }

  processFilter = ({ config: { kind }, data }) => ({ kind, data });

  onTagClick = (filter, currentFunnel) => () => this.setState({
    filter,
    currentFunnel,
    showFilterPopup: true,
    isTagPopup: true,
  });

  openPopup = (currentFunnel) => this.setState({
    showFilterPopup: true, currentFunnel, filtersItems: [], isTagPopup: false,
  });

  hidePopup = () => this.setState({
    showFilterPopup: false, filter: null, currentFunnel: '', filtersItems: [], isTagPopup: false,
  });

  setFiltersItems = (filter) => {
    const { filtersItems } = this.state;
    const index = filtersItems.findIndex((element) => element.kind === filter.kind);
    const isFilterDataExists = checkIfFilterDataExists(filter.data);
    if (isFilterDataExists) {
      if (index === -1) {
        filtersItems.push(filter);
      } else {
        filtersItems[index] = filter;
      }
    } else if (index !== -1) {
      filtersItems.splice(index, 1);
    }
    this.setState({ filtersItems });
  };

  addFilter = (selectedFilters) => {
    const { predefinedFiltersConfig, configs: filterConfigs } = this.props;
    const predefinedForStage = predefinedFiltersConfig[this.state.currentFunnel] || [];
    const filtersForStage = predefinedForStage.filters || [];

    const validFilters = selectedFilters.filter(({ data, kind }) => {
      if (data.comparisonOperator || isCostCampaignsFilter(kind)) {
        return true;
      }
      return !isEmpty(data.selectedOptions);
    });
    const selectedNewFilters = [];
    for (const filter of validFilters) {
      const newFilter = makeFilter(filter, filterConfigs);
      if (newFilter && !filtersForStage.find((f) => f.id === newFilter.id)) {
        selectedNewFilters.push(newFilter);
      }
    }

    const updatedPredefinedForStage = getUpdatedPredefinedFilterForStage({
      predefinedForStage,
      selectedNewFilters,
      updateFilterId: this.state.filter?.id,
    });
    this.props.updateField(updatedPredefinedForStage, this.state.currentFunnel);
    this.hidePopup();
  };

  removeFilter = (id, stage) => {
    const { predefinedFiltersConfig } = this.props;
    const predefinedForStage = predefinedFiltersConfig[stage];
    const updatedRemovedFilterForStage = getRemovedPredefinedFilterForStage({ predefinedForStage, filterId: id });
    this.props.updateField(updatedRemovedFilterForStage, stage);
    this.setState({ showFilterLogicUpdateMessageForStage: stage });
    setTimeout(() => {
      this.setState({ showFilterLogicUpdateMessageForStage: null });
    }, 2000);
  };

  updateFilterLogic({ stage, value }) {
    const { predefinedFiltersConfig } = this.props;
    const predefinedForStage = predefinedFiltersConfig[stage];
    predefinedForStage.logicExpression = value.toUpperCase();
    this.props.updateField(predefinedForStage, stage);
  }

  render() {
    const { filter } = this.state;
    const {
      configs, predefinedFiltersConfig, getFunnelTitle, renderLoader, saveFilter, metrics,
    } = this.props;

    return (
      <div style={{ display: 'flex', position: 'relative' }}>
        {renderLoader && (
          <div className={onBoardingStyle.locals.loaderContainer}>
            <Spinner />
          </div>
        )}
        <div className={this.classes.popupContent}>
          {metrics.map(({ value: funnel }) => {
            const filterTagsByMetrics = predefinedFiltersConfig[funnel]?.filters?.filter(Boolean) || [];
            const isShowFilterLogicButton = filterTagsByMetrics.length > 1;
            const isShowFilterTagsNumbers = this.state.selectedFunnelRow === funnel;
            const filterLogicValue = predefinedFiltersConfig[funnel]?.logicExpression;
            return (
              <div key={funnel} className={styles.locals.preDefinedFiltersRow}>
                <div className={classnames(this.classes.rowCenter, styles.locals.filterTagsRow)}>
                  <div className={styles.locals.filterTagsTitle}>
                    {getFunnelTitle(funnel)}
                  </div>
                  <div className={classnames(this.classes.filterTags, styles.locals.filterTagsGroup)}>
                    <div className={this.classes.addFilterButtonsGroup}>
                      <Button
                        type="primaryBlue"
                        onClick={() => this.openPopup(funnel)}
                        className={isShowFilterLogicButton ? this.classes.addFilterButtonIconNoRadius : null}
                      >
                        <div className={this.classes.addFilterButtonIcon} />
                        Add Filter
                      </Button>
                      {isShowFilterLogicButton && (
                      <FilterLogicExpressionPopup
                        funnel={funnel}
                        setSelectedFunnelRow={(selectedFunnel) => this.setState({ selectedFunnelRow: selectedFunnel })}
                        filtersCount={filterTagsByMetrics.length}
                        updateFilterLogic={({ value }) => this.updateFilterLogic({ stage: funnel, value })}
                        filterLogicValue={filterLogicValue}
                        isShowFilterLogicUpdateMessage={this.state.showFilterLogicUpdateMessageForStage === funnel}
                      />
                      )}
                    </div>
                    {filterTagsByMetrics.map((filterTag, index) => (
                      <FilterTag
                        key={filterTag.id}
                        label={filterTag.label}
                        selectedOptions={get(filterTag, 'data.selectedOptions', [])}
                        kind={get(filterTag, 'config.kind', '')}
                        onRemove={() => this.removeFilter(filterTag.id, funnel)}
                        onClick={this.onTagClick(filterTag, funnel)}
                        className={styles.locals.tag}
                        tagNumber={isShowFilterTagsNumbers ? index + 1 : null}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}

          {this.state.showFilterPopup && (
            <FilterPopup
              opened
              onClose={this.hidePopup}
              filterConfigs={configs}
              className={styles.locals.filterPopupIntegration}
              onFilterAdd={(selectedFilters) => this.addFilter(selectedFilters, configs)}
              filterFromTag={filter ? this.processFilter(filter) : null}
              isTagPopup={this.state.isTagPopup}
              setFiltersItems={this.setFiltersItems}
              filtersItems={this.state.filtersItems}
            />
          )}
          <div className={this.classes.rowBetween}>
            <Button
              type="primaryBlue"
              onClick={saveFilter}
              className={this.classes.popupButton}
              disabled={!this.props.isFiltersChanged}
            >
              Save Filters
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PreDefinedFiltersStep;
