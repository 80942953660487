import {
  action, computed, decorate, observable, runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { isEmpty, cloneDeep } from 'lodash';

import hydrate from 'stores/hydrate';
import userStore from 'stores/userStore';
import analysisStore from 'stores/analysisStore';

import {
  FUNNEL1, isMoneyIndicator, getVelocityKey, getRevenueFunnel, getPipelineFunnel, NEW_FUNNEL_PREFIX,
} from 'components/utils/indicators';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { attributionModelsItems } from 'attribution/models';
import { filterGivenRecords } from 'components/utils/filters';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import timeFrameModule from 'modules/timeframe';
import { mapObjToSelectOptions } from 'components/utils/utils';
import { isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { getTimeframeFromQueryParams } from 'stores/logic/attributionStore';

const INIT_GROUP_BY = 'companies';

class AttributionStore {
  metricsOptions = [];

  constructor() {
    Object.assign(this, AttributionStore.initialVals);

    this.filters = [];
    this.data = {};
    this.conversionIndicator = 'funnel1';
    this.previousTimeframe = false;
    this.autoFilter = true;
    this.isOtherCampaignsHidden = false;
    this.isAccountMode = false;
  }

  resetToDefaultData() {
    Object.assign(this, AttributionStore.initialVals);
  }

  get isCalculateAdvancedMetrics() {
    return this.isCalculateAdvancedMetricsValue ?? analysisStore.LDFlags?.headerSettingsCalculateAdvancedMetrics;
  }

  get velocityKey() {
    return getVelocityKey(this.conversionIndicator);
  }

  get customDateMode() {
    const prevTimeFrames = Object.values(TIMEFRAME_VALUES).filter((str) => str.includes('prev'));
    return [...prevTimeFrames, TIMEFRAME_VALUES.CUSTOM, TIMEFRAME_VALUES.WEEK].includes(this.timeFrame.value);
  }

  get timeFrameParams() {
    return timeFrameModule.getTimeframeParams({
      ...this.timeFrame,
      fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    });
  }

  get dateRange() {
    return this.timeFrameParams;
  }

  get tsRange() {
    const {
      startDate, endDate, previousStartDate, previousEndDate,
    } = this.timeFrameParams;
    return {
      startTS: startDate.getTime(),
      endTS: endDate.getTime(),
      previousStartDate,
      previousEndDate,
    };
  }

  get formattedTimeParams() {
    const {
      startTS, endTS, previousStartDate, previousEndDate,
    } = this.tsRange;

    const startDate = new Date(getTSForTimezone(startTS));
    const endDate = new Date(getTSForTimezone(endTS));

    let retval = { startDate, endDate };
    if (this.previousTimeframe) {
      retval = {
        ...retval,
        previousStartDate: new Date(getTSForTimezone(previousStartDate.getTime())),
        previousEndDate: new Date(getTSForTimezone(previousEndDate.getTime())),
      };
    }
    return retval;
  }

  get isMoneyFormat() {
    return isMoneyIndicator(this.conversionIndicator);
  }

  get rawFilters() {
    return this.filters.map(({ config, data }) => ({ data, kind: config.kind }));
  }

  updateMultipleParameters({
    filters, timeframeValue, timeframeParams, attributionModel, conversionIndicator,
  }) {
    runInAction(() => {
      if (timeframeValue) {
        this.setTimeFrame(timeframeValue, timeframeParams, false);
      }
      if (filters) {
        this.setFilters(filters, false);
      }
      if (attributionModel) {
        this.setAttributionModel(attributionModel, false);
      }
      if (conversionIndicator) {
        this.setConversionIndicator(conversionIndicator);
      }
    });
  }

  updateStoreByQueryParams({ params }) {
    if (isEmpty((params))) {
      return;
    }

    const updatedParams = getTimeframeFromQueryParams({ params });

    if (params.kpiFocus) {
      updatedParams.conversionIndicator = params.kpiFocus;
    }

    if (params.filters) {
      const encodedFilters = Buffer.from(params.filters, 'base64');
      const decodedFilters = JSON.parse(encodedFilters.toString('utf8'));
      for (const filter of decodedFilters) {
        if (filter.data.timeFrame) {
          const { startDate: startTS, endDate: endTS } = timeFrameModule.getTimeframeParams({ ...filter.data.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
          filter.data.timeFrame = {
            value: filter.data.timeFrame.value,
            startTS,
            endTS,
          };
        }
      }
      updatedParams.filters = decodedFilters;
    }

    this.updateMultipleParameters(updatedParams);
  }

  setFilters(filters = [], resetLoadingIndication = true) {
    this.filters = cloneDeep(filters);

    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }
  }

  setPreviousCheckbox(newIsCompareToPrevious) {
    this.previousTimeframe = newIsCompareToPrevious;
  }

  setAutoFilter(resetLoadingIndication = true) {
    this.autoFilter = !this.autoFilter;
    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }
  }

  uiFilters = ({ filters = this.filters }) => filters.filter(({ isUiOnly }) => isUiOnly);

  getFilteredRecords = ({ records, getData = ([key]) => key, filters }) => filterGivenRecords(records, this.uiFilters({ filters }), getData);

  setAttributionModel(model, resetLoadingIndication = true) {
    this.attributionModel = {
      ...model,
    };
    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }
  }

  setTimeFrame(value, additionalData, resetLoadingIndication = true) {
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame: { value, ...additionalData }, prevTimeFrame: this.timeFrame });

    this.timeFrame = {
      value,
      ...additionalData,
    };

    if (resetLoadingIndication && didTimeFrameChanged) {
      analysisStore.restIsLoadedWidgetIndication();
    }
  }

  updateOtherCampaignsHidden() {
    this.isOtherCampaignsHidden = !this.isOtherCampaignsHidden;
  }

  get originalFunnelFromIndicator() {
    const metricByIndicator = this.metricsOptions.find((option) => option.value === this.conversionIndicator);
    return metricByIndicator?.originalFunnel || this.conversionIndicator;
  }

  setConversionIndicator(conversionIndicator = FUNNEL1) {
    const metricByIndicator = this.metricsOptions.find((option) => option.value === conversionIndicator);
    if (!metricByIndicator) {
      this.conversionIndicator = FUNNEL1;
      return;
    }
    this.conversionIndicator = conversionIndicator;
  }

  setZeroWeightsNotInTimeFrame(zeroWeightsNotInTimeFrame = false) {
    this.zeroWeightsNotInTimeFrame = zeroWeightsNotInTimeFrame;
  }

  getMetricOptions() {
    const metrics = {};
    userStore.userFunnels.forEach((funnel) => {
      metrics[funnel] = userStore.getMetricNickname({ metric: funnel });
    });
    this.isLoadedOnce = false;
    const metricsOptions = mapObjToSelectOptions(metrics);
    const { isPipelineStageHidden } = userStore.userMonthPlan || {};
    if (!isPipelineStageHidden) {
      metricsOptions.push({
        label: 'Pipeline',
        value: 'pipeline',
        originalFunnel: getPipelineFunnel(),
      });
    }
    metricsOptions.push({
      label: 'Revenue',
      value: 'revenue',
      originalFunnel: getRevenueFunnel(),
    });
    return metricsOptions;
  }

  setMetricOptions() {
    this.metricsOptions = this.getMetricOptions();
    this.setConversionIndicator(`${NEW_FUNNEL_PREFIX}1`);
  }

  setIsAccountMode() {
    this.isAccountMode = !this.isAccountMode;
    analysisStore.restIsLoadedWidgetIndication();
  }

  setIsCalculateAdvancedMetrics({ isCalculateAdvancedMetrics }) {
    this.isCalculateAdvancedMetricsValue = isCalculateAdvancedMetrics;
  }

  getAnalyzeBaseConfig = ({ isPreviousTimeFrame = false }) => {
    const {
      attributionModel, filters, formattedTimeParams, autoFilter, conversionIndicator,
    } = this;

    const timeFrame = {
      endDate: formattedTimeParams.endDate,
      startDate: formattedTimeParams.startDate,
    };

    const payload = {
      attributionCredit: false,
      attributionModel,
      filters,
      timeFrame,
      shouldUsePredefinedFilters: autoFilter,
      conversionIndicator,
    };

    if (isPreviousTimeFrame) {
      payload.previousTimeFrame = {
        startDate: formattedTimeParams.previousStartDate,
        endDate: formattedTimeParams.previousEndDate,
      };

      payload.compareToPreviousTimeFrame = isPreviousTimeFrame;
    }

    return payload;
  };

  static initialVals = {
    attributionModel: attributionModelsItems[0],
    timeFrame: {
      value: TIMEFRAME_VALUES.QUARTER,
    },
    filters: [],
    zeroWeightsNotInTimeFrame: false,
    conversionIndicator: FUNNEL1,
    groupBy: INIT_GROUP_BY,
  };
}

decorate(AttributionStore, {
  data: observable.ref,
  prevData: observable.ref,
  timeFrame: observable.ref,
  timeFrameParams: computed,
  customDateMode: computed,
  zeroWeightsNotInTimeFrame: observable,
  attributionModel: observable.ref,
  conversionIndicator: observable.ref,
  metricsOptions: observable.ref,
  tsRange: computed,
  dateRange: computed,
  formattedTimeParams: computed,
  setAttributionModel: action.bound,
  setMetricOptions: action.bound,
  setTimeFrame: action.bound,
  setConversionIndicator: action.bound,
  originalFunnelFromIndicator: computed,
  setZeroWeightsNotInTimeFrame: action.bound,
  setFilters: action.bound,
  updateMultipleParameters: action.bound,
  groupBy: observable,
  previousTimeframe: observable,
  autoFilter: observable,
  setPreviousCheckbox: action.bound,
  setAutoFilter: action.bound,
  getMetricOptions: action.bound,
  updateOtherCampaignsHidden: action.bound,
  isOtherCampaignsHidden: observable,
  filters: observable.ref,
  isAccountMode: observable,
  setIsAccountMode: action.bound,
  isCalculateAdvancedMetrics: computed,
  isCalculateAdvancedMetricsValue: observable,
  setIsCalculateAdvancedMetrics: action.bound,
  updateStoreByQueryParams: action.bound,
  resetToDefaultData: action.bound,
});

const schema = {
  attributionModel: {
    type: 'object',
  },
  timeFrame: {
    type: 'object',
  },
  filters: {
    type: 'list',
  },
  zeroWeightsNotInTimeFrame: true,
  conversionIndicator: true,
  groupBy: true,
  isOtherCampaignsHidden: true,
  isCalculateAdvancedMetricsValue: true,
};

const store = persist(schema)(new AttributionStore());

hydrate('attributionStore', store);

export default store;
