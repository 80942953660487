import React from 'react';
import { inject, observer } from 'mobx-react';

import FunnelView from 'components/pages/analyze/OverviewTab/FunnelView';

import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

import funnelViewStyles from 'styles/analyze/funnel-view.css';

const styles = funnelViewStyles.locals;

function FunnelViewWrapper({
  historicalPerformance = {},
  widgetHeaderConfig,
  timeFrame,
  funnels,
  widgetConfig,
  getWidgetRequestId,
  widgetHeaderProps,
  emptyStateComponent,
}) {
  const requestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig }) });
  const historicalPerformanceData = historicalPerformance?.[requestId]?.result;

  const isLoaded = historicalPerformance?.[requestId]?.status === 'finished';
  const isFailedToLoad = historicalPerformance?.[requestId]?.status === 'failed';

  return (
    <div className={styles.reportWrapper}>
      <FunnelView
        navigateToJourneys={() => navigateToJourneys({ indicator: null, timeFrame: timeFrame?.value, timeFrameParams: timeFrame }, widgetHeaderConfig.filters)}
        isLoaded={isLoaded}
        isFailedToLoad={isFailedToLoad}
        funnels={funnels}
        data={historicalPerformanceData}
        timeFrame={timeFrame}
        widgetHeaderConfig={widgetHeaderConfig}
        widgetHeaderProps={widgetHeaderProps}
        emptyStateComponent={emptyStateComponent}
      />
    </div>
  );
}

export default inject(
  ({
    userStore: {
      funnels,
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.historicalPerformance]: historicalPerformance,
      },
    },
  }) => ({
    funnels,
    getWidgetRequestId,
    historicalPerformance,
  }),
  observer
)(FunnelViewWrapper);
