import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import ActionPopup from 'components/pages/settings/actions/actionPopup';
import ActionsTable from 'components/pages/settings/actions/actionsTable';
import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import Loader from 'components/controls/Loader';
import SearchInput from 'components/controls/SearchInput';

import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { widgetTypes } from 'components/pages/analyze/enums';
import { activityStatusValues } from 'components/pages/settings/goals/logic/enums';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

import style from 'styles/settings/actions/actions.css';
import generalAppStyle from 'styles/app.css';

const styles = style.locals || {};
const generalAppStyles = generalAppStyle.locals || {};

function Actions({
  requestUserActions,
  actions,
  userRegionsNicknames,
  isLoadingActions,
  requestUserIntegrationsConfig,
  resetWidgetData,
}) {
  useStyles([style, generalAppStyle]);

  const [userActions, setUserActions] = useState(actions);
  const [selectedAction, setSelectedAction] = useState();

  const refSearchInput = useRef();

  useEffect(() => {
    requestUserActions();
    requestUserIntegrationsConfig();
    resetWidgetData({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: {
        goalsAnalysisParams: {
          configOnly: true,
          status: activityStatusValues.ACTIVE,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (actions !== undefined) {
      setUserActions(actions);
    }

    const actionId = getQueryParams({ queryParamKey: 'actionId' });
    if (actionId && actions?.length > 0) {
      const foundAction = actions.find((action) => action.actionId === Number(actionId));
      if (foundAction) {
        setSelectedAction(foundAction);
      }
    }
  }, [actions]);

  function onSearchData({ searchInput }) {
    const filteredActions = actions.filter((action) => action.name.toLowerCase().includes(searchInput.toLowerCase()));
    setUserActions(filteredActions);
  }

  if (isLoadingActions) {
    return (
      <div className={generalAppStyles.loader}>
        <Loader newStyle />
      </div>
    );
  }

  const actionNames = actions?.map((action) => action.name);
  const isActionsEmpty = actions ? actions.length === 0 : true;
  const isMultiRegionsAccount = userRegionsNicknames?.length > 1;

  return (
    <>
      {selectedAction && (
        <ActionPopup
          onClose={() => setSelectedAction(null)}
          actionData={selectedAction}
          isMultiRegionsAccount={isMultiRegionsAccount}
          userActionNames={actionNames}
        />
      )}

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={classNames(styles.title, isActionsEmpty ? styles.spaceBetween : null)}>
            Alerts
          </div>
          {!isActionsEmpty && (
          <div className={styles.headerActions}>
            <SearchInput
              onSearch={(searchInput) => onSearchData({ searchInput })}
              placeholder="Search"
              debounce={1000}
              ref={refSearchInput}
            />
            <Button
              type="primaryBlue"
              onClick={() => {
                servicesStore.eventTracker.track({
                  eventName: Events.alertsNewAlertClicked,
                });
                setSelectedAction({});
              }}
            >
              + New Alert
            </Button>
          </div>
          )}
        </div>
        {isActionsEmpty ? (
          <EmptyStateWithImage
            title="Create your first alert"
            buttonName="+ New Alert"
            imageClassName={styles.emptyStateImage}
            onClick={() => {
              servicesStore.eventTracker.track({
                eventName: Events.alertsNewAlertClicked,
              });
              setSelectedAction({});
            }}
          />
        ) : (
          <ActionsTable
            actions={userActions}
            setSelectedAction={setSelectedAction}
            isMultiRegionsAccount={isMultiRegionsAccount}
          />
        )}
      </div>
    </>
  );
}

export default inject(
  ({
    userStore: {
      actions,
      requestUserActions,
      userRegionsNicknames,
      isLoadingActions,
      requestUserIntegrationsConfig,
    } = {},
    widgetsAnalysisStore: {
      resetWidgetData,
    } = {},
  }) => ({
    actions,
    requestUserActions,
    userRegionsNicknames,
    isLoadingActions,
    requestUserIntegrationsConfig,
    resetWidgetData,
  }),
  observer
)(Actions);
