export default {
  cellWidth: 140,
  data: {
    Western: [],
  },
  allCategories: [
    'Business Directories',
    'Display',
    'Email Marketing',
    'Events',
    'Multi Channel Campaigns',
    'Organic Search',
    'Organic Social',
    'Outbound',
    'Paid Search',
    'Paid Social',
    'Partners',
    'PR',
    'Website',
  ],
  dates: [
    {
      value: 'Jul 20',
      realIndex: 0,
      fiscalYear: '2020',
      date: '2020-06-30T21:00:00.000Z',
    },
    {
      value: 'Aug 20',
      realIndex: 1,
      fiscalYear: '2020',
      date: '2020-07-31T21:00:00.000Z',
    },
    {
      value: 'Sep 20',
      realIndex: 2,
      fiscalYear: '2020',
      date: '2020-08-31T21:00:00.000Z',
    },
    {
      value: 'Oct 20',
      realIndex: 3,
      fiscalYear: '2020',
      date: '2020-09-30T21:00:00.000Z',
    },
    {
      value: 'Nov 20',
      realIndex: 4,
      fiscalYear: '2020',
      date: '2020-10-31T22:00:00.000Z',
    },
    {
      value: 'Dec 20',
      realIndex: 5,
      fiscalYear: '2020',
      date: '2020-11-30T22:00:00.000Z',
    },
    {
      value: 'Jan 21',
      realIndex: 6,
      fiscalYear: '2021',
      date: '2020-12-31T22:00:00.000Z',
    },
    {
      value: 'Feb 21',
      realIndex: 7,
      fiscalYear: '2021',
      date: '2021-01-31T22:00:00.000Z',
    },
    {
      value: 'Mar 21',
      realIndex: 8,
      fiscalYear: '2021',
      date: '2021-02-28T22:00:00.000Z',
    },
    {
      value: 'Apr 21',
      realIndex: 9,
      fiscalYear: '2021',
      date: '2021-03-31T21:00:00.000Z',
    },
    {
      value: 'May 21',
      realIndex: 10,
      fiscalYear: '2021',
      date: '2021-04-30T21:00:00.000Z',
    },
    {
      value: 'Jun 21',
      realIndex: 11,
      fiscalYear: '2021',
      date: '2021-05-31T21:00:00.000Z',
    },
    {
      value: 'Jul 21',
      realIndex: 12,
      fiscalYear: '2021',
      date: '2021-06-30T21:00:00.000Z',
    },
    {
      value: 'Aug 21',
      realIndex: 13,
      fiscalYear: '2021',
      date: '2021-07-31T21:00:00.000Z',
    },
    {
      value: 'Sep 21',
      realIndex: 14,
      fiscalYear: '2021',
      date: '2021-08-31T21:00:00.000Z',
    },
    {
      value: 'Oct 21',
      realIndex: 15,
      fiscalYear: '2021',
      date: '2021-09-30T21:00:00.000Z',
    },
    {
      value: 'Nov 21',
      realIndex: 16,
      fiscalYear: '2021',
      date: '2021-10-31T22:00:00.000Z',
    },
    {
      value: 'Dec 21',
      realIndex: 17,
      fiscalYear: '2021',
      date: '2021-11-30T22:00:00.000Z',
    },
    {
      value: 'Jan 22',
      realIndex: 18,
      fiscalYear: '2022',
      date: '2021-12-31T22:00:00.000Z',
    },
    {
      value: 'Feb 22',
      realIndex: 19,
      fiscalYear: '2022',
      date: '2022-01-31T22:00:00.000Z',
    },
    {
      value: 'Mar 22',
      realIndex: 20,
      fiscalYear: '2022',
      date: '2022-02-28T22:00:00.000Z',
    },
    {
      value: 'Apr 22',
      realIndex: 21,
      fiscalYear: '2022',
      date: '2022-03-31T21:00:00.000Z',
    },
    {
      value: 'May 22',
      realIndex: 22,
      fiscalYear: '2022',
      date: '2022-04-30T21:00:00.000Z',
    },
  ],
  datesInitial: [
    '2020-06-30T21:00:00.000Z',
    '2020-07-31T21:00:00.000Z',
    '2020-08-31T21:00:00.000Z',
    '2020-09-30T21:00:00.000Z',
    '2020-10-31T22:00:00.000Z',
    '2020-11-30T22:00:00.000Z',
    '2020-12-31T22:00:00.000Z',
    '2021-01-31T22:00:00.000Z',
    '2021-02-28T22:00:00.000Z',
    '2021-03-31T21:00:00.000Z',
    '2021-04-30T21:00:00.000Z',
    '2021-05-31T21:00:00.000Z',
    '2021-06-30T21:00:00.000Z',
    '2021-07-31T21:00:00.000Z',
    '2021-08-31T21:00:00.000Z',
    '2021-09-30T21:00:00.000Z',
    '2021-10-31T22:00:00.000Z',
    '2021-11-30T22:00:00.000Z',
    '2021-12-31T22:00:00.000Z',
    '2022-01-31T22:00:00.000Z',
    '2022-02-28T22:00:00.000Z',
    '2022-03-31T21:00:00.000Z',
    '2022-04-30T21:00:00.000Z',
  ],
  isEditMode: false,
  numberOfPastDates: 0,
  isConstraintsEnabled: false,
  campaigns: {
    Western: [],
  },
  processedChannels: {
    titles: {
      advertising_displayAds_googleAdwords: 'Google Ads - Display',
      advertising_searchMarketing_SEM_onlineDirectories: 'PPC - Online Directories',
      advertising_searchMarketing_SEO: 'SEO',
      advertising_socialAds_facebookAdvertising: 'Facebook - Paid',
      advertising_socialAds_linkedinAdvertising: 'LinkedIn - Paid',
      email: 'Email',
      events_offlineEvents_running: 'Organising (Event)',
      events_offlineEvents_speakingEngagements: 'Speaking Engagement (Event)',
      paid_quora: 'Quora - Paid',
      social_facebookPage: 'Facebook - Organic',
      telemarketing: 'Outbound Call',
      web_companyWebsite: 'Company’s Website',
    },
    icons: {
      advertising_displayAds_googleAdwords: 'plan:advertising_displayAds_googleAdwords',
      advertising_searchMarketing_SEM_onlineDirectories: 'plan:advertising_searchMarketing_SEM_onlineDirectories',
      advertising_searchMarketing_SEO: 'plan:advertising_searchMarketing_SEO',
      advertising_socialAds_facebookAdvertising: 'plan:advertising_socialAds_facebookAdvertising',
      advertising_socialAds_linkedinAdvertising: 'plan:advertising_socialAds_linkedinAdvertising',
      email: 'plan:email',
      events_offlineEvents_running: 'plan:events_offlineEvents_running',
      events_offlineEvents_speakingEngagements: 'plan:events_offlineEvents_speakingEngagements',
      paid_quora: 'plan:paid_quora',
      social_facebookPage: 'plan:social_facebookPage',
      telemarketing: 'plan:telemarketing',
      web_companyWebsite: 'plan:web_companyWebsite',
    },
    budgets: {
      social_facebookPage: 4050,
      events_offlineEvents_speakingEngagements: 11000,
      events_offlineEvents_running: 4900,
      advertising_searchMarketing_SEO: 20000,
      advertising_displayAds_googleAdwords: 2100,
      advertising_socialAds_facebookAdvertising: 40,
      telemarketing: 3600,
      web_companyWebsite: 5400,
      email: 9756,
      advertising_searchMarketing_SEM_onlineDirectories: 29875,
      advertising_socialAds_linkedinAdvertising: 7300,
      paid_quora: 0,
    },
    names: [
      'advertising_displayAds_googleAdwords',
      'advertising_searchMarketing_SEM_onlineDirectories',
      'advertising_searchMarketing_SEO',
      'advertising_socialAds_facebookAdvertising',
      'advertising_socialAds_linkedinAdvertising',
      'email',
      'events_offlineEvents_running',
      'events_offlineEvents_speakingEngagements',
      'paid_quora',
      'social_facebookPage',
      'telemarketing',
      'web_companyWebsite',
    ],
  },
  campaignsTemplates: {},
  currentDate: 11,
  interactiveMode: false,
  region: 'Western',
  regions: [
    'Western',
  ],
  showSumData: false,
  quarterOffset: 3,
  annualOffset: 6,
  isMergedView: false,
  uniqChannels: [],
  campaignKeyToNameMapping: {},
  months: [
    'Jul 20',
    'Aug 20',
    'Sep 20',
    'Oct 20',
    'Nov 20',
    'Dec 20',
    'Jan 21',
    'Feb 21',
    'Mar 21',
    'Apr 21',
    'May 21',
    'Jun 21',
  ],
};
