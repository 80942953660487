import { cloneDeep } from 'lodash';
import { VARIANTS } from '../../../../utils/filters/logic';

export const checkAllFieldsAreFilledForSpecificPlatforms = (platformFilterData) => {
    for (const filterGroupOfRules of platformFilterData.rules) {
        for (const filterRuleItem of filterGroupOfRules) {
            const allFieldsAreFilled = Object.values(filterRuleItem).every((item) => (Array.isArray(item) ? item.length > 0 : item != null || item !== ''));
            if (!allFieldsAreFilled) {
                return false;
            }
        }
    }
    return true;
};

export const addVariantToSelectedFilter = (selectedFilter) => {
    const updatedSelectedFilter = cloneDeep(selectedFilter);
    for (const filterGroupOfRules of updatedSelectedFilter.data.rules) {
        for (const filterRuleItem of filterGroupOfRules) {
            const isNotComparisonOperator = filterRuleItem.comparisonOperator.includes('NOT');
            if (isNotComparisonOperator) {
                filterRuleItem.comparisonOperator = filterRuleItem.comparisonOperator.split('_').pop();
                filterRuleItem.variant = VARIANTS.DONT_INCLUDE;
            } else {
                filterRuleItem.variant = VARIANTS.INCLUDE_ANY_OF;
            }
        }
    }
    return updatedSelectedFilter;
};