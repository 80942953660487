import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/build-budget.css';
import { Link } from 'react-router';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';

export default class ImportBudget extends Component {
    style = style;

    render() {
      return (
        <div className={this.classes.page}>
          <div className={this.classes.closeIcon} />
          <div className={this.classes.header}>
            <h1 className={this.classes.title}>
              Build your first budget plan
            </h1>
            <p className={this.classes.text}>
              Import your existing plan
            </p>
          </div>
          <div className={this.classes.mainImport}>
            <div className={this.classes.borderImportWrap}>
              <div className={this.classes.importIco} />
              <div className={this.classes.mainTitle}>
                Import your existing plan
              </div>
              <p className={this.classes.mainText}>
                Drag & drop your plan (CSV) here, or browse
              </p>
            </div>
          </div>
          <div className={this.classes.btnsGroup}>
            <Link to="/build-budget">
              <SetupBudgetButton
                name="Previous"
                stylesBtn={this.classes.button}
              />
            </Link>
            <SetupBudgetButton
              name="Continue"
              stylesBtn={this.classes.buttonPrimary}
            />
          </div>
        </div>
      );
    }
}
