import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget.css';
import classnames from 'classnames';
import { Link } from 'react-router';

export default class BudgetButtons extends Component {
  style = style;

  render() {
    const {
      selectedChannels,
    } = this.props;
    return (
      <div className={this.classes.buttons}>
        <Link to="/build-budget">
          <button type="button" className={this.classes.button}>
            Previous
          </button>
        </Link>
        <div className={this.classes.buttonContainer}>
          <span className={this.classes.info}>
            {`${selectedChannels.length} channels selected`}
          </span>
          <Link to="/build-budget-setup">
            <button type="button" className={classnames(this.classes.button, this.classes.primary)}>
              Continue
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
