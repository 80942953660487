import { cloneDeep, isNil, isEqual } from 'lodash';

import { attributionWeightValues } from 'components/pages/settings/channels/tabs/logic/enums';
import { isRuleConditionFilled, getNewCondition } from 'components/utils/mappingRules';

export function areAllFieldsFilled({ attributionMappingRules }) {
  for (const rule of attributionMappingRules) {
    for (const condition of rule.conditions) {
      if (!isRuleConditionFilled({ condition })) {
        return false;
      }
    }
  }

  return true;
}

export function getRulesCountByTypes({ rules }) {
  let noWeightCount = 0;
  let lowWeightCount = 0;
  let mediumWeightCount = 0;
  let fullWeightCount = 0;

  for (const rule of rules) {
    const ruleWeight = rule.weight;
    if (ruleWeight === attributionWeightValues.noCredit) {
      noWeightCount++;
      continue;
    }

    if (ruleWeight === attributionWeightValues.lowCredit) {
      lowWeightCount++;
      continue;
    }

    if (ruleWeight === attributionWeightValues.mediumCredit) {
      mediumWeightCount++;
      continue;
    }

    if (ruleWeight === attributionWeightValues.fullCredit) {
      fullWeightCount++;
      continue;
    }
  }
  return {
    noWeightCount,
    lowWeightCount,
    mediumWeightCount,
    fullWeightCount,
  };
}

export function getAllUpdatedMappingRulesObjects({
  conditionIndex, key, value, ruleId, weight, attributionMappingRules, updatedMappingRules, deletedRuleIds, newMappingRules,
}) {
  const newUpdatedMappingRules = cloneDeep(updatedMappingRules);
  const newDeletedRuleIds = cloneDeep(deletedRuleIds);
  const updatedNewMappingRules = cloneDeep(newMappingRules);

  const updatedExistingRuleIndex = newUpdatedMappingRules.findIndex((rule) => rule.id === ruleId);
  const updatedNewMappingRulesIndex = updatedNewMappingRules.findIndex((rule) => rule.id === ruleId);
  const attributionMappingRuleIndex = attributionMappingRules.findIndex((rule) => rule.id === ruleId);

  let updatedRule;
  if (updatedExistingRuleIndex > -1) {
    updatedRule = newUpdatedMappingRules[updatedExistingRuleIndex];
  } else if (updatedNewMappingRulesIndex > -1) {
    updatedRule = updatedNewMappingRules[updatedNewMappingRulesIndex];
  } else if (attributionMappingRuleIndex > -1) {
    updatedRule = cloneDeep(attributionMappingRules[attributionMappingRuleIndex]);
    newUpdatedMappingRules.push(updatedRule);
  }

  const isAddingNewCondition = !isNil(conditionIndex) && !updatedRule.conditions[conditionIndex];
  if (isAddingNewCondition) {
    updatedRule.conditions.push(getNewCondition());
  } else if (key) {
    updatedRule.conditions[conditionIndex][key] = value;
  } else if (!isNil(weight)) {
    updatedRule.weight = weight;
  } else {
    updatedRule.conditions.splice(conditionIndex, 1);
    if (updatedRule.conditions.length === 0) {
      newDeletedRuleIds.push(updatedRule.id);
      const updatedRuleIndex = newUpdatedMappingRules.findIndex((rule) => rule.id === ruleId);
      const shouldRemoveFromUpdatedRules = updatedRuleIndex !== -1;
      if (shouldRemoveFromUpdatedRules) {
        newUpdatedMappingRules.splice(updatedRuleIndex, 1);
      }
      const updatedNewRuleIndex = updatedNewMappingRules.findIndex((rule) => rule.id === ruleId);
      const shouldRemoveFromNewRules = updatedNewRuleIndex !== -1;
      if (shouldRemoveFromNewRules) {
        updatedNewMappingRules.splice(updatedNewRuleIndex, 1);
      }
    }
  }

  const existingRuleFromAttributionMappingRule = attributionMappingRules.find((rule) => rule.id === updatedRule.id);
  if (existingRuleFromAttributionMappingRule && updatedExistingRuleIndex > -1) {
    const isRuleGotChanged = !isEqual(existingRuleFromAttributionMappingRule, newUpdatedMappingRules[updatedExistingRuleIndex]);
    if (!isRuleGotChanged) {
      newUpdatedMappingRules.splice(updatedExistingRuleIndex, 1);
    }
  }

  return { newDeletedRuleIds, newUpdatedMappingRules, updatedNewMappingRules };
}
