export const Events = {
  addToReport: 'addToReport',
  questionSearched: 'questionSearched',
  questionAsked: 'questionAsked',
  widgetCreated: 'Created a widget',
  widgetExported: 'Exported data from a widget',
  customSegmentNewSegmentClicked: 'Clicked on Add New Segment (Custom Segments)',
  customSegmentNewBucketCreated: 'Created a segment bucket (Custom Segments)',
  customSegmentSaved: 'Saved custom segment (Custom Segments)',
  reportSyncToggled: 'Report-sync was toggled',
  reportSentToSync: 'Report was sent to be synced',
  createReportFailed: 'Report creation failed',
  onboardingDemoClicked: 'Clicked to Watch Demo (Onboarding)',
  onboardingGuideClicked: 'Clicked on use case (Onboarding)',
  onboardingGuideCompleted: 'Completed use case(Onboarding)',
  reportsStartFromScratch: 'Clicked on Start from scratch (Reports)',
  reportsTemplateSelected: 'Clicked on a template (Reports)',
  reportsNewReportCreated: 'New report created (Reports)',
  reportsReportViewed: 'Existing report viewed (Reports)',
  reportsNewReportPageVisit: 'New report page visit',
  selectedSegment: 'Selected segment',
  filtersAddFilter: 'Clicked on Add Filters',
  filtersAppliedFilter: 'Applied filter',
  alertsNewAlertClicked: 'Clicked to create New Alert (Alerts)',
  alertsSavedAlert: 'Saved alert (Alerts)',
  alertsDeletedAlert: 'Deleted an alert (Alerts)',
  alertsToggleStatus: 'Toggled alert status (Alerts)',
  ChannelTabShowAdvancedSettings: 'Clicked show all settings (Channel config)',
  channelTabDeleteChannel: 'Deleted channel (Channel config)',
  channelSaveChannelSettings: 'Saved channel settings (Channel config)',
  channelMappingRulesWeights: 'Saved mapping rules weights (Channel config)',
  channelMappingRulesSort: 'Sorted by attribution weight (Channel config)',
  whatIfChangedPredictedKPI: 'Changed predicted KPI (What if)',
  whatIfWarningMessageShown: 'Warning message shown (What if)',
  whatIfChangedTimeframe: 'Changed timeframe (What if)',
  whatIfChangedSamplePeriod: 'Changed sample period (What if)',
  whatIfAddedNewScenario: 'Added a new scenario (What if)',
  whatIfClosedConfigurationPanel: 'Closed configuration panel (What if)',
  whatIfOpenConfigurationPanel: 'Opened configuration panel (What if)',
  whatIfClickedAddChangeInScenario: 'Clicked add change in scenario (What if)',
  whatIfSavedScenario: 'Saved scenario (What if)',
  whatIfSavedRuleInScenario: 'Saved rule in scenario (What if)',
  whatIfDeletedScenario: 'Deleted scenario (What if)',
  whatIfExpandedCard: 'Expanded card (What if)',
  whatIfCollapsedCard: 'Collapsed card (What if)',
};
