const CHANNEL_COLORS = {
  'advertising_searchMarketing_SEM_googleAdwords': '#FF705A',
  'advertising_displayAds_other': '#EF7EAB',
  'advertising_displayAds_googleAdwords': '#52A4FF',
  'advertising_searchMarketing_SEM_other': '#96DE49',
  'General': '#8B572A',
  'Any': '#8B572A',
  'advertising_searchMarketing_SEO': '#66D99B',
  'advertising_socialAds_facebookAdvertising': '#58A1F5',
  'advertising_socialAds_twitterAdvertising': '#4CC8FF',
  'advertising_socialAds_linkedinAdvertising': '#0085C4',
  'advertising_socialAds_instagramAdvertising': '#FF7E47',
  'advertising_socialAds_pinterestAdvertising': '#b93936',
  'advertising_socialAds_GooglePlusAdvertising': '#FF705A',
  'advertising_socialAds_youtubeAdvertising': '#FF1D1D',
  'advertising_offlineAds_TV': '#1899FF',
  'advertising_offlineAds_radio': '#1899FF',
  'advertising_offlineAds_newspaper': '#8B572A',
  'advertising_offlineAds_billboard': '#D6975E',
  'advertising_offlineAds_SMS': '#7ED321',
  'advertising_mobile_incentivizedCPI': '#428AFF',
  'advertising_mobile_nonIncentivizedCPI': '#FF7474',
  'advertising_mobile_ASO': '#FF8A74',
  'advertising_mobile_inAppAds': '#1899FF',
  'advertising_magazines_consumers': '#1899FF',
  'advertising_magazines_professional': '#1899FF',
  'advertising_paidReviews': '#FF9602',
  'advertising_celebrityEndorsements': '#F8D61C',
  'content_contentPromotion_targetingBlogs': '#7BC400',
  'content_contentPromotion_contentDiscovery_outbrain': '#F28520',
  'content_contentPromotion_contentDiscovery_taboola': '#4162C8',
  'content_contentPromotion_contentDiscovery_other': '#FF7E7E',
  'content_contentPromotion_forums_other': '#FF7E7E',
  'social_reddit': '#FF9602',
  'paid_reddit': '#FF9602',
  'social_quora': '#B92B27',
  'paid_quora': '#B92B27',
  'content_contentCreation_companyBlog': '#39D98D',
  'content_contentCreation_imagesAndInfographics': '#FF7E47',
  'content_contentCreation_presentations': '#50E3C2',
  'content_contentCreation_reportSponsorship': '#FF6868',
  'content_contentCreation_researchPaper': '#52A4FF',
  'content_contentCreation_eBook': '#7ED321',
  'content_contentCreation_videos': '#BA81EF',
  'content_contentCreation_caseStudies': '#39D98D',
  'email': '#F5A623',
  'engineeringAsMarketing': '#FF1D1D',
  'events_offlineEvents_sponsorship': '#6780FF',
  'events_offlineEvents_speakingEngagements': '#4BBAFF',
  'events_offlineEvents_showcase': '#FF9602',
  'events_offlineEvents_running': '#BA81EF',
  'events_onlineEvents_webinar': '#F5A623',
  'events_onlineEvents_podcast': '#F56262',
  'events_onlineEvents_workshop': '#EF7EAB',
  'mobile_mobileApp': '#6AE18F',
  'mobile_mobileSite': '#6A7FE1',
  'partners_affiliatePrograms': '#90E0DA',
  'PR_unconventionalPR_publicityStunts': '#66D99B',
  'PR_unconventionalPR_customerAppreciation': '#BC8352',
  'PR_publicity_pressReleases': '#BA81EF',
  'social_facebookPage': '#58A1F5',
  'social_twitterAccount': '#4CC8FF',
  'social_youtubeChannel': '#FF1D1D',
  'social_instagramAccount': '#FF7E47',
  'social_googlePlusPage': '#FF705A',
  'social_pinterestPage': '#b93936',
  'social_linkedinCompanyProfile': '#0085C4',
  'social_influencerOutreach': '#1899FF',
  'social_communityBuilding': '#66D99B',
  'social_productHunt': '#FF8A74',
  'telemarketing': '#7BC400',
  'viral_recommendAFriend_referralProgram': '#6780FF',
  'web_companyWebsite': '#FF7E7E',
  'web_landingPages': '#39D98D',
  'other': '#8B572A',
  'advertising_searchMarketing_SEM_onlineDirectories': '#6A7FE1',
  'advertising_searchMarketing_SEM_bing': '#FF9602',
  'multiChannel': '#4785b4',
  'direct': '#09C'
}

export default function getChannelColor(channel) {
  return CHANNEL_COLORS[channel] || '#3e7de3';
}
