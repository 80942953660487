export const compareMetricsKinds = {
  averageTouchpoints: 'averageTouchpoints',
  topChannel: 'topChannel',
  percentageOfMetric: 'percentageOfMetric',
  conversionRate: 'conversionRate',
};

export const displayTypes = {
  percentage: 'percentage',
  days: 'days',
  dollars: 'dollars',
  months: 'months',
};

export const metricTypes = {
  conversionRate: 'conversionRate',
  velocity: 'velocity',
};

export const compareMetricsTooltips = {
  [compareMetricsKinds.averageTouchpoints]: `
    <p><b>The average number of touchpoints before a deal becomes an Opportunity.</b></p>
    <p><b>InfiniGrow</b> applies several automations to fix data gaps in your CRM, resulting in 37 touchpoints being associated to your Opps, on average.</p>
    <p><b>SalesForce</b> Opps have only 4 touchpoints before these automations are applied.</p>
 `,
  [compareMetricsKinds.topChannel]: `
    <p><b>Top 3 channels by Attributed Opps</b></p>
    <p><b>InfiniGrow’s</b> top channels are based on Multi-Touch Attribution with the Full Journey model.</p>
    <p><b>SalesForce’s</b> top channels are based on the Original Lead Source field (a Single-Touch analysis).</p>
 `,
  [compareMetricsKinds.percentageOfMetric]: `
    <p><b>What is the percentage of Opps that show up as Direct?</b></p>
    <p><b>InfiniGrow</b> minimizes the percentage of Direct Opps using automated processes that ensure every touchpoint is captured and associated correctly.</p>
    <p><b>SalesForce</b> Opps tend to show as “Direct” due to manual Contact associations, which do not capture leads and historical activities.</p>
 `,
  [compareMetricsKinds.conversionRate]: `
    <p><b>InfiniGrow’s Conversion Rate Calculation is cohort and account based.</b></p>
    <p><b>For example:</b></p>
    <ul>
        <li><b>100 Leads</b> created during previous Q</li>
        <li>These leads come from <b>38 Accounts</b></li>
        <li><b>6 Opps</b> created during previous Q</li>
        <li>Only <b>5 Opps</b> are from the previous Q’s 38 accounts</li>
    </ul>
    <ul>
        <li>Standard conversion rate = <b>6%</b></li>
        <li>Account-Based, Cohort conversion rate = <b>13%</b></li>
    </ul>
`,
};
