import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose } from 'components/utils/utils';

import Component from 'components/Component';
import ChannelIcon from 'components/common/ChannelIcon';
import BaseFilter from 'components/pages/users/Filters/BaseFilter';
import { FilterItemTag } from 'components/pages/users/Filters/FilterParts';
import {
  ConfigPropType, FilterPropType, COMPARISON_TOGGLE, CONTAINS,
} from 'components/utils/filters';
import { EQUALS, filterKinds } from 'components/utils/filters/logic';
import { getChannelIcon, getChannelNickname } from 'components/utils/filters/channels';
import { isContentTypeField } from 'components/utils/filters/content';
import filterStyles from 'styles/users/filters.css';
import selectStyle from 'styles/controls/select.css';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import Dropdown from 'components/controls/Dropdown';
import ContainsSelect from './FilterPanel/UI/ContainsSelect';

const styles = filterStyles.locals;

const enhance = compose(
  inject(({
    filterStore: {
      getFilterKindOptions,
    },
  }) => ({
    getFilterKindOptions,
  })),
  observer
);

class ContentFilter extends Component {
  static propTypes = {
    className: PropTypes.string,
    config: ConfigPropType.isRequired,
    currentFilter: FilterPropType,
    onCancel: PropTypes.func.isRequired,
    onAddFilter: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const comparisonOperatorLabel = props.activeFilter ? props.activeFilter.data.comparisonOperator : EQUALS;
    this.state = {
      comparisonOperator: comparisonOperatorLabel,
      isContainsSelected: comparisonOperatorLabel === CONTAINS,
    };
  }

  changeComparisonHandler = (value) => {
    this.setState({
      comparisonOperator: value,
      isContainsSelected: value === CONTAINS,
    });
  }

  isContentTypeField = (fieldIndex) => isContentTypeField(this.props.config.fieldKey[fieldIndex]);

  renderSelect = ({
    options, selectOptions, selectedOptions, fieldIndex, addItem,
  }) => {
    const { config, getFilterKindOptions } = this.props;
    const optionsList = options.filter((item) => item !== '');

    if (this.isContentTypeField(fieldIndex)) {
      return (
        <Dropdown
          onChange={addItem}
          selectedKey={-1}
          options={optionsList.map((item) => ({ label: item, value: item }))}
          optionRenderer={(item) => (
            <div className={styles.channelItemWrap}>
              <ChannelIcon
                className={styles.filterItemIcon}
                channelIcon={getChannelIcon(item.value)}
                channel={item.value}
              />
              <div className={selectStyle.locals.text}>
                {getChannelNickname(item.label)}
              </div>
            </div>
          )}
          placeholder="Select channel..."
          isAsyncPagination={config.isSearchable}
          searchFunction={(searchValue, offset) => getFilterKindOptions({
            searchValue, filterKind: config.kind, filterKey: config.fieldKey[fieldIndex], offset,
          })}
        />
      );
    }
    return (
      <MultiCheckSelect
        className={styles.optionSelect}
        options={optionsList.map((item) => ({ label: getChannelNickname(item), value: item }))}
        selected={selectedOptions.map((content) => ({
          label: content, value: content,
        }))}
        placeholder="Search item... "
        onChange={selectOptions}
        isAsyncPagination={config.isSearchable}
        searchFunction={(searchValue, offset) => getFilterKindOptions({
          searchValue, filterKind: config.kind, filterKey: config.fieldKey[fieldIndex], offset,
        })}
        key={config.fieldKey[fieldIndex]}
        selectAll
      />
    );
  };

  render() {
    const { config } = this.props;
    const fieldOptions = config.fieldKey.map((fKey, index) => ({
      label: config.fieldNames[index] || fKey,
      value: fKey,
    }));

    return (
      <BaseFilter comparisonOperator={this.state.comparisonOperator} changeComparisonHandler={this.changeComparisonHandler} {...this.props} kind={filterKinds.CONTENT}>
        {({
          selectOptions, selectedOptions, removeItem, fieldIndex, setFilterFieldIndex, onChangeComparison, containsValueHandler, addItem,
        }) => (
          <>
            <div className={styles.filterVariant}>
              <Dropdown
                onChange={setFilterFieldIndex}
                options={fieldOptions}
                selectedKey={config.fieldKey[fieldIndex]}
                controlWidth={210}
                isSearchable
              />
            </div>

            <div className={styles.filterVariant}>
              <Dropdown
                onChange={onChangeComparison}
                options={Object.entries(COMPARISON_TOGGLE).map(([value, label]) => ({ value, label }))}
                selectedKey={this.state.comparisonOperator}
                placeholder="Select condition"
                controlWidth={210}
              />
            </div>
            {this.state.isContainsSelected ? (
              <div className={styles.rowCenter} style={{ marginBottom: 15, position: 'relative' }}>
                <ContainsSelect
                  containsValue={selectedOptions.map((item) => ({ label: item, value: item }))}
                  containsValueHandler={containsValueHandler}
                />
              </div>
            ) : (
              <>
                {this.renderSelect({
                  fieldIndex,
                  selectOptions,
                  addItem,
                  selectedOptions,
                  options: config.options[fieldIndex]
                    .filter((option) => !selectedOptions.includes(option)),
                })}
              </>
            )}
            {!this.state.isContainsSelected && this.isContentTypeField(fieldIndex)
              && (
                <div className={styles.filterItemTags}>
                  {selectedOptions.map((customField) => (this.isContentTypeField(fieldIndex) ? (
                    <FilterItemTag
                      key={customField}
                      channel={customField}
                      title={getChannelNickname(customField)}
                      onRemove={() => removeItem(customField)}
                      iconRenderer={() => (
                        <ChannelIcon
                          className={styles.filterItemIcon}
                          channelIcon={getChannelIcon(customField)}
                          channel={customField}
                        />
                      )}
                    />
                  ) : (
                    <FilterItemTag
                      key={customField}
                      title={customField}
                      onRemove={() => removeItem(customField)}
                    />
                  )))}
                </div>
              )}
          </>
        )}
      </BaseFilter>
    );
  }
}

export default enhance(ContentFilter);
