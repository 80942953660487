import React from 'react';
import classNames from 'classnames';

const TitleCell = ({
  onClick, classes, isExpanded, title, renderContent, container, titleRender,
}) => (
  <div
    className={container}
    onClick={onClick}
  >
    <button className={classes.buttonUp}>
      <i
        className={classNames(
          classes.icon,
          classes.iconUp,
          {
            [classes.iconDown]: isExpanded,
          }
        )}
        data-icon="plan:monthNavigation"
      />
    </button>
    <div className={classes.channel}>
      {titleRender ? titleRender() : title}
    </div>
    {renderContent && renderContent()}
  </div>
);

export default TitleCell;
