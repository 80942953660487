import React from 'react';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import WidgetHeader from 'components/common/WidgetHeader';
import ConversionJourney from 'components/pages/analyze/ConversionJourney';
import { getConversionTooltip } from 'components/pages/analyze/utils/ConversionJourney';
import { widgetTypes } from 'components/pages/analyze/enums';

const JourneysChannels = (props) => {
  const {
    channelJourneys,
    contentJourneys,
    conversionIndicator,
    isCategoryView,
    prefix = '',
    widgetHeaderConfig = {
      title: 'Top Conversion Journeys',
      titleTooltip: getConversionTooltip('channel'),
      type: widgetTypes.topConversionChannelJourneys,
      isCategoryView,
      conversionIndicator,
    },
    widgetHeaderProps = {},
  } = props;

  return (
    <>
      <WidgetHeader
        {...widgetHeaderProps}
        widgetHeaderConfig={widgetHeaderConfig}
      />
      <ConversionJourney
        prefix={prefix}
        conversionIndicator={conversionIndicator}
        contentJourneys={contentJourneys}
        channelJourneys={channelJourneys}
        isCategoryView={isCategoryView}
        widgetConfig={widgetHeaderConfig}
      />
    </>
  );
};

export default WithPersonalSpinner()(JourneysChannels);
