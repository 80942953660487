exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".checkListWithPresets__checkListDropdownContainer-Gu4{position:relative}.checkListWithPresets__checkListWithPresets-1o2{display:flex;align-items:center;gap:4px}.checkListWithPresets__checkListWithPresets-1o2 .checkListWithPresets__checkListPopup-9Kr{top:48px;left:0}.checkListWithPresets__checkListWithPresets-1o2 .checkListWithPresets__draggableContainer-3LJ{margin-left:0}.checkListWithPresets__optionsGroup-Eii{padding-block:8px}.checkListWithPresets__groupLabel-9Ih{font-size:14px;color:var(--black);font-weight:600;margin-bottom:12px}", ""]);

// exports
exports.locals = {
	"checkListDropdownContainer": "checkListWithPresets__checkListDropdownContainer-Gu4",
	"checkListDropdownContainer": "checkListWithPresets__checkListDropdownContainer-Gu4",
	"checkListWithPresets": "checkListWithPresets__checkListWithPresets-1o2",
	"checkListWithPresets": "checkListWithPresets__checkListWithPresets-1o2",
	"checkListPopup": "checkListWithPresets__checkListPopup-9Kr",
	"checkListPopup": "checkListWithPresets__checkListPopup-9Kr",
	"draggableContainer": "checkListWithPresets__draggableContainer-3LJ",
	"draggableContainer": "checkListWithPresets__draggableContainer-3LJ",
	"optionsGroup": "checkListWithPresets__optionsGroup-Eii",
	"optionsGroup": "checkListWithPresets__optionsGroup-Eii",
	"groupLabel": "checkListWithPresets__groupLabel-9Ih",
	"groupLabel": "checkListWithPresets__groupLabel-9Ih"
};