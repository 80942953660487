import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';

import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import navigationMenuPopupStyle from 'styles/analyze/navigationMenuPopup.css';

const styles = navigationMenuPopupStyle.locals || {};

const NavigationMenuPopup = ({ navigationOptions, onClosePopup, isFilterDataLoaded }) => {
  const refPopupFilter = useRef();

  useStyles([navigationMenuPopupStyle]);

  useOnClickOutside(refPopupFilter, () => {
    onClosePopup(false);
  });

  const onClickAction = (optionAction) => {
    optionAction();
    onClosePopup(false);
  };

  return (
    <div ref={refPopupFilter} className={styles.menuPopup}>
      <div className={styles.headerTitle}> What&lsquo;s Next?</div>
      {navigationOptions.map((option) => (
        <div
          key={`navOptions-${option.title}`}
          className={classNames(styles.menuPopupOption, isFilterDataLoaded ? null : styles.menuPopupOptionDisabled)}
          onClick={isFilterDataLoaded ? () => onClickAction(option.navigationFunction) : null}
        >
          <div className={styles.menuPopupOptionTitle}>
            {option.title}
            {option.hasNewTag && <div className={styles.navigationNewTag}>New</div>}
          </div>
          <div
            className={styles.optionSubtitle}
            key={`option-${option.subTitle}`}
          >
            {option.subTitle}
          </div>
        </div>
      ))}
    </div>
  );
};

export default inject(({
  filterStore: {
    isFilterDataLoaded,
  } = {},
}) => ({
  isFilterDataLoaded,
}),
observer)(NavigationMenuPopup);
