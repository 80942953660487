import React from 'react';
import history from 'history';

import useStyles from 'hooks/useStyles';

import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals || {};

function WidgetBuilderHeaderMenu() {
  useStyles([reportsStyle]);

  return (
    <div className={styles.headerMenuWrapper}>
      <div
        onClick={() => { history.goBack(); }}
        className={styles.backToReport}
        data-testid="back-to-report"
      >
        {'< Back to report'}
      </div>
    </div>
  );
}

export default WidgetBuilderHeaderMenu;
