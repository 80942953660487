import React, { useState, useEffect, useRef } from 'react';
import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

const EditPopup = (props) => {
  const [isEditPopup, setIsEditPopup] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isEditPopup && ref.current && !ref.current.contains(e.target)) {
        setIsEditPopup(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isEditPopup]);

  const editPopupHandler = () => {
    setIsEditPopup((prev) => !prev);
  };

  const onDelete = () => {
    setIsEditPopup(false);
    props.onDelete(props.item, props.index);
  };

  const onEditRename = () => {
    setIsEditPopup(false);
    props.onEdit(props.item, props.index);
  };

  return (
    <div className={styles.savedViewDots} ref={ref}>
      <div className={styles.savedView3Dots} onClick={editPopupHandler}>
        {props.children}
      </div>
      {isEditPopup
        && (
        <div className={styles.savedViewDotsPopup}>
          <ul>
            <li onClick={onEditRename}>Rename</li>
            <li onClick={onDelete} className={styles.savedViewDotsDelete}>Delete</li>
          </ul>
        </div>
        )}
    </div>

  );
};
export default EditPopup;
