exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".funnel-view-popup__popup-f_G{top:0;left:15px;min-height:0!important;box-sizing:border-box;z-index:100;max-width:600px}.funnel-view-popup__popup-wrapper-2gl{display:flex;flex-direction:column;padding:6px;border:1px solid rgba(178,187,213,.5);border-radius:4px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12);min-width:500px}.funnel-view-popup__popup-button-3wV{min-height:40px;display:flex;flex-direction:column;font-size:14px;font-weight:500;color:#182033;position:relative}.funnel-view-popup__indicator-title-2lq{padding-left:5px;padding-top:5px;font-weight:500;font-size:14px;color:#182033}.funnel-view-popup__popup-item-content-22-{padding:5px 15px 6px;font-weight:400;font-size:13px;color:#222a41}.funnel-view-popup__popup-item-content-22->div{padding-bottom:5px}.funnel-view-popup__mapping-group-24d{margin-left:10px}.funnel-view-popup__popup-item-base-2-J{font-weight:500;color:#182033;display:inline-block;overflow-wrap:break-word}.funnel-view-popup__popup-item-value-NgC{margin-left:4px}.funnel-view-popup__filtersValues-KZe{margin-left:6px}.funnel-view-popup__filtersValues-KZe .funnel-view-popup__popup-item-sub-value-1r8{margin-left:4px}.funnel-view-popup__popup-item-sub-value-1r8+div{margin-left:10px}", ""]);

// exports
exports.locals = {
	"popup": "funnel-view-popup__popup-f_G",
	"popup": "funnel-view-popup__popup-f_G",
	"popup-wrapper": "funnel-view-popup__popup-wrapper-2gl",
	"popupWrapper": "funnel-view-popup__popup-wrapper-2gl",
	"popup-button": "funnel-view-popup__popup-button-3wV",
	"popupButton": "funnel-view-popup__popup-button-3wV",
	"indicator-title": "funnel-view-popup__indicator-title-2lq",
	"indicatorTitle": "funnel-view-popup__indicator-title-2lq",
	"popup-item-content": "funnel-view-popup__popup-item-content-22-",
	"popupItemContent": "funnel-view-popup__popup-item-content-22-",
	"mapping-group": "funnel-view-popup__mapping-group-24d",
	"mappingGroup": "funnel-view-popup__mapping-group-24d",
	"popup-item-base": "funnel-view-popup__popup-item-base-2-J",
	"popupItemBase": "funnel-view-popup__popup-item-base-2-J",
	"popup-item-value": "funnel-view-popup__popup-item-value-NgC funnel-view-popup__popup-item-base-2-J",
	"popupItemValue": "funnel-view-popup__popup-item-value-NgC funnel-view-popup__popup-item-base-2-J",
	"filtersValues": "funnel-view-popup__filtersValues-KZe",
	"filtersValues": "funnel-view-popup__filtersValues-KZe",
	"popup-item-sub-value": "funnel-view-popup__popup-item-sub-value-1r8 funnel-view-popup__popup-item-base-2-J",
	"popupItemSubValue": "funnel-view-popup__popup-item-sub-value-1r8 funnel-view-popup__popup-item-base-2-J"
};