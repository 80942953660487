import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import FrequencySelect from 'components/pages/analyze/FrequencySelect';
import { isMoneyIndicator } from 'components/utils/indicators';
import GeneratedImpact from 'components/pages/analyze/ImpactBoard/GeneratedImpact';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import { widgetsConfig, widgetTypes } from 'components/pages/analyze/enums';
import WidgetHeader from 'components/common/WidgetHeader';
import Dropdown from 'components/controls/Dropdown';

import styles from 'styles/analyze/analyze.css';

const classes = styles.locals;

class ImpactByIndicator extends Component {
  onIndicatorChange = ({ value: impactIndicator }) => this.props.updateImpactIndicator(impactIndicator, true, ['marketingVsSales']);

  render() {
    const {
      impactData,
      impactPrevData,
      metricsOptions,
      frequencyControl,
      currentFrequency,
      impactIndicator,
      updateImpactIndicator,
      isReadOnly,
      widgetHeaderConfig = {
        title: 'Impact by Department',
        type: widgetTypes.marketingVsSales,
        impactIndicator,
        marketingVsSalesFrequency: currentFrequency,
      },
      widgetHeaderProps = {},
    } = this.props;

    const isMoneyFormat = isMoneyIndicator(impactIndicator);

    return (
      <>
        <WidgetHeader
          widgetHeaderConfig={widgetHeaderConfig}
          {...widgetHeaderProps}
        >
          <Dropdown
            selectedKey={impactIndicator}
            options={metricsOptions}
            disabled={isReadOnly || !updateImpactIndicator}
            onChange={this.onIndicatorChange}
            dropdownLabel="KPI Focus"
          />
          <FrequencySelect
            disabled={isReadOnly || !frequencyControl}
            selected={currentFrequency}
            onChange={frequencyControl}
            {...frequencyControl}
            widgets={[widgetsConfig.marketingVsSales.type]}
          />
        </WidgetHeader>
        <div className={classes.rows}>
          <GeneratedImpact
            data={impactData}
            prevData={impactPrevData}
            isMoneyFormat={isMoneyFormat}
            conversionIndicator={impactIndicator}
            currentFrequency={currentFrequency}
          />
        </div>
      </>
    );
  }
}

ImpactByIndicator.defaultProps = {
  impactData: [],
  metricsOptions: [],
};

ImpactByIndicator.propTypes = {
  impactData: PropTypes.arrayOf(PropTypes.shape({})),
  metricsOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withLDConsumer()(WithPersonalSpinner()(ImpactByIndicator));
