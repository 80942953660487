import moment from 'moment';

import { getFiscalQuarterForMonth, getFiscalYearForMonth } from '../../../utils/dates';
import { TIMEFRAME_VALUES } from '../../../utils/timeframe';

export function getMonthsBetweenDates({ startDate, endDate }) {
  let startDateParsed = moment(startDate, 'YYYY-M-DD');
  const endDateParsed = moment(endDate, 'YYYY-M-DD').endOf('month');

  const allMonthsInPeriod = [];

  while (startDateParsed.isBefore(endDateParsed)) {
    allMonthsInPeriod.push(startDateParsed.format('MMM YY'));
    startDateParsed = startDateParsed.add(1, 'month');
  }

  return allMonthsInPeriod;
}

export function getNewTitleAccordingDateAndTimeframe({ startDate, timeFrame, fiscalYearFirstMonth = 0 }) {
  const fiscalQuarterNumber = getFiscalQuarterForMonth({ month: moment(startDate).format('M'), fiscalYearFirstMonth });
  const fiscalYear = getFiscalYearForMonth(new Date(startDate).getMonth(), fiscalYearFirstMonth, moment(startDate).format('YYYY'));

  if (timeFrame === TIMEFRAME_VALUES.QUARTER || timeFrame === TIMEFRAME_VALUES.PREV_QUARTER) {
    return `Q${fiscalQuarterNumber} ${fiscalYear}`;
  }

  if (timeFrame === TIMEFRAME_VALUES.YEAR || timeFrame === TIMEFRAME_VALUES.PREV_YEAR) {
    return `${fiscalYear}`;
  }

  return moment(startDate).format('MMM YY');
}
