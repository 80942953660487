import React from 'react';

function FeatureFlags({ flag, children }) {
  if (flag) {
    return children;
  }
  return null;
}

export default FeatureFlags;
