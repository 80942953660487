export const segmentsAnalysisKey = 'overview-segmentsAnalysis';

export const crossFunnel = 'crossFunnel';
export const webVisits = 'webVisits';
export const pageViews = 'pageViews';

export const attributionColumnsLocalStorageKeys = {
  inactive: 'inactive',
  reorder: 'reorder',
};

export const nonFunnelStages = [webVisits, pageViews];

export const metricWithoutShareOfTotal = ['ConversionRate', 'averageSalesCycle', 'Velocity', 'ARPA', 'touched', 'touchedRevenue', 'touchedPipeline'];

export const commonInlineFiltersColumns = ['firstSegment', 'secondSegment', 'cost', 'channels'];

export const inlineFiltersKind = {
  numeric: 'numeric',
  multiCheck: 'multiCheck',
};

export const columnsBlackList = ['marketingPipelineROI', 'marketingROI', 'marketingAttributedRevenue', 'marketingAttributedPipeline', 'CAC', 'churnRate', 'webVisitsToFunnel1Velocity', 'pageViewsToFunnel1Velocity', 'ARR', 'MRR'];

export const attributionMetrics = ['attributed', 'ARR', 'MRR', 'ARPA', 'pipeline', 'revenue', 'ROI', 'LTV', 'pipelineROI', 'efficiency'];

export const defaultColumnOrderPerMetricType = ['funnelStage', 'velocity', 'conversionRate', 'costPerFunnelStage'];

export const segmentsKeys = {
  firstSegment: 'firstSegment',
  secondSegment: 'secondSegment',
};

export const segmentsTimeOptions = [
  { label: 'Day', value: 'groupByDay' },
  { label: 'Week', value: 'groupByWeek' },
  { label: 'Month', value: 'groupByMonth' },
  { label: 'Quarter', value: 'groupByQuarter' },
];
