import React from 'react';
import { Link } from 'react-router';

import Component from 'components/Component';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import style from 'styles/header/settings.css';

class Settings extends Component {
  style = style;

  render() {
    const tabs = [
      {
        name: 'Account',
        path: '/settings/account',
        representivePath: '/settings/account',
      },
      {
        name: 'Goals',
        path: '/settings/goals/goals-main',
        representivePath: '/settings/goals/goals-main',
      },
      {
        name: 'Preferences',
        path: '/settings/profile/preferences',
        representivePath: '/settings/profile/preferences',
      },
      {
        name: 'Integrations',
        path: '/settings/profile/integrations',
        representivePath: '/settings/profile/integrations',
      },
      {
        name: 'Website Traffic Settings',
        path: '/settings/attribution/setup',
        representivePath: '/settings/attribution',
      },
      {
        name: 'Ops',
        path: '/settings/ops/workflows',
        representivePath: '/settings/ops',
      },
    ];

    const targetAudience = {
      name: 'Target Audience',
      path: '/settings/profile/target-audience',
      representivePath: '/settings/profile/target-audience',
    };

    const product = {
      name: 'Product',
      path: '/settings/profile/product',
      representivePath: '/settings/profile/product',
    };

    const { flags } = this.props;
    if (flags.productHeaderSettingsMenu) { tabs.splice(1, 0, product); }
    if (flags.targetAudienceHeaderSettingsMen) { tabs.splice(4, 0, targetAudience); }

    return (
      <div className={this.classes.frame}>
        <div className={this.classes.title}>Settings</div>

        <div className={this.classes.subMenu}>
          {tabs.map(({ name, path }) => <Link to={path} className={this.classes.subTitle} key={name}>{name}</Link>)}
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(Settings);
