import ChannelsFilter from 'components/pages/users/Filters/ChannelsFilter';
import FunnelStagesFilter from 'components/pages/users/Filters/FunnelStagesFilter';
import CampaignsFilter from 'components/pages/users/Filters/CampaignsFilter';
import MultiFieldFilter from 'components/pages/users/Filters/MultiFieldFilter';
import ContentFilter from 'components/pages/users/Filters/ContentFilter';
import FormsFilter from 'components/pages/users/Filters/FormsFilter';
import MarketingVsSalesFilter from 'components/pages/users/Filters/MarketingVsSalesFilter';
import ProductFilter from 'components/pages/users/Filters/ProductFilter';
import RegionFilter from 'components/pages/users/Filters/RegionFilter';
import PreDefinedFilter from 'components/pages/users/Filters/PreDefinedFilter';
import SavedFilter from 'components/pages/users/Filters/SavedFilter';
import CampaignCostFilter from 'components/pages/users/Filters/CampaignCostFilter';
import CampaignCustomFieldsFilter from 'components/pages/users/Filters/CampaignCustomFieldsFilter';
import VelocityFilter from 'components/pages/users/Filters/VelocityFilter';
import LeadStatusFilter from 'components/pages/users/Filters/LeadStatusFilter';
import RevenueFilter from 'components/pages/users/Filters/RevenueFilter';

export default {
  ChannelsFilter,
  FunnelStagesFilter,
  CampaignsFilter,
  MultiFieldFilter,
  ContentFilter,
  FormsFilter,
  MarketingVsSalesFilter,
  ProductFilter,
  RegionFilter,
  PreDefinedFilter,
  SavedFilter,
  CampaignCostFilter,
  CampaignCustomFieldsFilter,
  VelocityFilter,
  LeadStatusFilter,
  RevenueFilter,
};
