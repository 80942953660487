import React from 'react';
import CompanyLogo from 'components/pages/users/CompanyLogo';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import useStyles from 'hooks/useStyles';

import style from 'styles/users/users.css';

const styles = style.locals || {};

function UserInfoCell({
  emails,
  displayName,
  domain,
  classes,
  identityId,
  id,
}) {
  useStyles([style]);

  const clearbitUrl = 'https://logo.clearbit.com';

  return (
    <div className={styles.flex} id={id}>
      <CompanyLogo
        src={`${clearbitUrl}/${domain}`}
        fallbackSrc={`${clearbitUrl}/${displayName}`}
        className={classNames(classes.icon, classes.logo)}
      />
      <div className={classes.account}>
        {displayName}
        <div className={classes.email}>
          {!isEmpty(emails)
            ? emails.length === 1
              ? emails[0]
              : 'multiple users'
            : ''}
        </div>
      </div>
      { id ? (
        <div id="selectedJourney" hidden>
          {identityId}
        </div>
      ) : null}
    </div>
  );
}

export default UserInfoCell;
