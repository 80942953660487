import React, { useEffect } from 'react';

import ChannelIcon from 'components/common/ChannelIcon';

import { getCategoryIcon, getChannelIcon } from 'components/utils/filters/channels';
import { getNickname } from 'components/utils/channels';

import style from 'styles/common/segment-tag.css';

const styles = style.locals || {};

function SegmentTag({ segment, type }) {
  if (!segment) {
    return null;
  }

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  if (type === 'channel') {
    return (
      <div data-testid="segment-tag" className={styles.segmentTag}>
        <ChannelIcon
          className={styles.channelIcon}
          channelIcon={getChannelIcon(segment)}
          channel={segment}
        />
        <div>
          {getNickname(segment)}
        </div>
      </div>
    );
  }

  if (type === 'category') {
    return (
      <div data-testid="segment-tag" className={styles.segmentTag}>
        <div
          className={styles.categoryIcon}
          data-icon={getCategoryIcon(segment)}
        />
        <div>
          {getNickname(segment)}
        </div>
      </div>
    );
  }

  return (
    <div data-testid="segment-tag" className={styles.segmentTag}>
      {segment}
    </div>
  );
}

export default SegmentTag;
