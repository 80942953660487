import React, {
  useMemo,
  useEffect,
} from 'react';
import { inject, observer } from 'mobx-react';

import ChannelIcon from 'components/common/ChannelIcon';
import SearchableDropdownPopup from 'components/pages/questions/SearchableDropdownPopup';
import SelectTimeFrameWithCustom from 'components/controls/SelectTimeFrameWithCustom';
import TemplateConfigChannelsMultiSelect from 'components/pages/reports/TemplateConfigChannelsMultiSelect';
import {
  getChannelIcon,
  getChannelNickname,
  getChannelsWithNicknames,
} from 'components/utils/filters/channels';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { groupBySegmentsOptions } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getMappingOptions } from 'components/utils/logic/utils';
import { dateFormat, checkIfDate } from 'utils';
import { getConversionRateAndVelocityOptions } from 'components/pages/analyze/logic/Tabs';
import { suggestionsDropdownTypes, suggestionsTypesToFilterKind } from 'components/pages/questions/enums';
import { filterKinds } from 'components/utils/filters/logic';

import useStyles from 'hooks/useStyles';
import reportLibraryStyle from 'styles/reports/reportLibrary.css';

const styles = reportLibraryStyle.locals || {};

function PopupInput({
  value,
  onChange,
  formField,
  inputProps,
  setIsValid,
  filtersData,
  getMetricOptions,
  customUtmsWhitelist,
  getFilterKindOptions,
  leadSourcesIdToLabelMap,
  customFieldsIdToLabelMap,
}) {
  useStyles([reportLibraryStyle]);

  const {
    id,
    type,
    shouldShowFieldValues,
    shouldEnableMultiSelect,
    channelType,
  } = formField;

  const {
    getIsValid,
    placeholder,
    placeholder2,
  } = inputProps;

  useEffect(() => {
    setIsValid({ id, isValid: getIsValid ? getIsValid({ value }) : true });
  }, [
    id,
    value,
    getIsValid,
  ]);

  const filterDataConfig = useMemo(() => filtersData?.find((filter) => filter.kind === suggestionsTypesToFilterKind[type]) || {}, [type]);

  const metricsOptions = useMemo(() => getMetricOptions(), []);

  const suggestionsOptionsAccordingType = useMemo(() => ({
    [suggestionsDropdownTypes.funnels]: metricsOptions?.filter((metric) => metric.value !== 'pipeline' && metric.value !== 'revenue'),
    [suggestionsDropdownTypes.kpiFocus]: metricsOptions,
    [suggestionsDropdownTypes.customFields]: getMappingOptions(customFieldsIdToLabelMap),
    [suggestionsDropdownTypes.leadSources]: getMappingOptions(leadSourcesIdToLabelMap),
    [suggestionsDropdownTypes.conversionRateOrVelocity]: getConversionRateAndVelocityOptions(),
  }), [
    metricsOptions,
    leadSourcesIdToLabelMap,
    customFieldsIdToLabelMap,
  ]);

  const fieldIndex = useMemo(() => {
    if (type === suggestionsDropdownTypes.customFields) {
      return filterDataConfig.fieldKey?.findIndex((key) => key === value.id);
    }

    const foundIndex = filterDataConfig.fieldKey?.findIndex((key) => key === value);
    if (foundIndex === -1) {
      return filterDataConfig.fieldKey?.findIndex((key) => key === type);
    }

    return foundIndex;
  }, [
    type,
    value,
    filterDataConfig,
  ]);

  let filterKey = filterDataConfig.fieldKey?.[fieldIndex];
  const options = useMemo(() => {
    let typeOptions = suggestionsOptionsAccordingType[type];

    if (!typeOptions) {
      typeOptions = (filterDataConfig.options?.[fieldIndex] || []).map((filterOption) => ({
        label: checkIfDate(filterOption) ? dateFormat(filterOption) : filterOption,
        value: filterOption,
      }));

      if (typeOptions.length === 0) {
        typeOptions.push({ value, label: value });
      }
    }

    return typeOptions;
  }, [
    type,
    value,
    filterDataConfig,
  ]);

  if (type === suggestionsDropdownTypes.timeframe) {
    return (
      <div className={styles.timeframeDropdown}>
        <SelectTimeFrameWithCustom
          fallbackLabel={placeholder}
          menuMaxWidth="240px"
          widgetTimeFrame={value}
          isDropdownAsLink={false}
          isShowShortLabel
          updateWidgetTimeframe={(newTimeframe) => {
            onChange({ id, value: newTimeframe });
          }}
        />
      </div>
    );
  }

  if (type === suggestionsDropdownTypes.segment) {
    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    return (
      <SearchableDropdownPopup
        options={groupBySegmentsOptions({ options: segmentsOptionsFields })}
        selectedValue={value}
        onSelectValue={(newSegment) => onChange({ id, value: newSegment.value })}
        displayPlaceholder={placeholder}
        classNameLabelContainer={styles.fieldContainer}
      />
    );
  }

  if (type === suggestionsDropdownTypes.channels) {
    const channelsWithNicknames = getChannelsWithNicknames();
    const channelsItemsOptions = channelsWithNicknames.map((c) => ({ value: c.value, label: getChannelNickname(c.value) }));

    if (shouldEnableMultiSelect) {
      let filteredOptions;
      let isAsyncPagination;
      let searchFunction;

      if (channelType) {
        const channelConfig = useMemo(
          () => {
            const contentFilters = filtersData?.find((filter) => filter.kind === filterKinds.CONTENT);
            return contentFilters || {};
          },
          [type, filtersData]
        );

        filteredOptions = [];
        isAsyncPagination = channelConfig.isSearchable;
        searchFunction = (searchValue, offset) => getFilterKindOptions({
          searchValue, filterKind: channelConfig.kind, filterKey: channelType, offset,
        });
      } else {
        filteredOptions = channelsItemsOptions.filter((channel) => !value.includes(channel.value));
      }

      return (
        <TemplateConfigChannelsMultiSelect
          addItem={(itemToAdd) => {
            onChange({
              id,
              value: [
                ...value,
                itemToAdd.value,
              ],
            });
          }}
          removeItem={(itemToRemove) => {
            onChange({
              id,
              value: value.filter((item) => item !== itemToRemove),
            });
          }}
          selectedOptions={value}
          channelsOptionsWithoutDeleted={filteredOptions}
          isAsyncPagination={isAsyncPagination}
          searchFunction={searchFunction}
        />
      );
    }

    return (
      <SearchableDropdownPopup
        options={channelsItemsOptions}
        selectedValue={value}
        onSelectValue={(newChannel) => onChange({ id, value: newChannel.value })}
        dropdownProps={{
          searchFunction: (searchValue, offset) => {
            const result = getFilterKindOptions({
              searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
            });
            return result;
          },
          menuMaxWidth: '200px',
          optionRenderer: (item) => (
            <div className={styles.channelItemWrap}>
              <ChannelIcon
                className={styles.filterItemIcon}
                channelIcon={getChannelIcon(item.value)}
                channel={item.value}
              />
              <div className={styles.channelNickname}>
                {getChannelNickname(item.label)}
              </div>
            </div>
          ),
        }}
        displayPlaceholder={placeholder}
        classNameLabelContainer={styles.fieldContainer}
      />
    );
  }

  if (type === suggestionsDropdownTypes.customFields) {
    const selectedField = value.id;
    const selectedFieldOption = value.value;
    filterKey = filterDataConfig.fieldKey?.[fieldIndex];
    const optionsForIndex = (filterDataConfig.options?.[fieldIndex] || []).map((filterOption) => ({
      label: checkIfDate(filterOption) ? dateFormat(filterOption) : filterOption,
      value: filterOption,
    }));
    if (optionsForIndex.length === 0) {
      optionsForIndex.push({ value: selectedFieldOption, label: selectedFieldOption });
    }

    return (
      <div className={styles.customFieldsWrapper}>
        <SearchableDropdownPopup
          options={groupBySegmentsOptions({ options: suggestionsOptionsAccordingType[type] })}
          selectedValue={selectedField}
          onSelectValue={(newId) => onChange({ id, value: { value: value.value, id: newId.value } })}
          dropdownProps={{
            menuMaxWidth: '200px',
          }}
          controlWidth="172px"
          classNameLabelContainer={styles.fieldContainer}
        />

        {shouldShowFieldValues ? (
          <div className={styles.customFieldsIsEquals}>
            <div>Is equal to</div>
            <SearchableDropdownPopup
              options={optionsForIndex}
              selectedValue={selectedFieldOption || null}
              onSelectValue={(newValue) => onChange({ id, value: { value: newValue.value, id: value.id } })}
              dropdownProps={{
                isAsyncPagination: filterDataConfig.isSearchable,
                searchFunction: (searchValue, offset) => getFilterKindOptions({
                  searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
                }),
                menuMaxWidth: '200px',
              }}
              controlWidth="172px"
              displayPlaceholder={placeholder2}
              classNameLabelContainer={styles.fieldContainer}
            />
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <SearchableDropdownPopup
      options={options}
      selectedValue={value}
      onSelectValue={(newValue) => onChange({ id, value: newValue.value })}
      dropdownProps={{
        isAsyncPagination: filterDataConfig.isSearchable,
        searchFunction: (searchValue, offset) => {
          const result = getFilterKindOptions({
            searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
          });
          return result;
        },
        menuMaxWidth: '200px',
      }}
      displayPlaceholder={placeholder}
      classNameLabelContainer={styles.fieldContainer}
    />
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
        leadSourcesIdToLabelMap,
      } = {},
    } = {},
    attributionStore: {
      getMetricOptions,
    } = {},
    filterStore: {
      filtersData,
      getFilterKindOptions,
    } = {},
  }) => ({
    filtersData,
    getMetricOptions,
    customUtmsWhitelist,
    getFilterKindOptions,
    leadSourcesIdToLabelMap,
    customFieldsIdToLabelMap,
  }),
  observer
)(PopupInput);
