import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import copy from 'copy-to-clipboard';
import { Button } from '@infinigrow/libs';

import exportData from 'modules/exportData';

import Spinner from 'components/pages/journeys/Spinner';
import { createSheetAnalyze } from 'components/utils/excelExport';
import { blobToBase64 } from 'components/importCampaignsPopups/DriftPopupContainer';

import style from 'styles/insightsPage/promptPopups.css';

export default function PromptPopup({ configuration, type }) {
  const [isDisableUploadCSV, setIsDisableUploadCSV] = useState(true);
  const [isLoadingExportData, setIsLoadingExportData] = useState(false);
  const [isExportDataError, setIsExportDataError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDownloadDecryptedFile, setIsLoadingDownloadDecryptedFile] = useState(false);
  const [isUploadFileError, setIsUploadFileError] = useState(false);

  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  async function onExportData() {
    setIsLoadingExportData(true);
    if (isExportDataError) {
      setIsExportDataError(false);
    }
    try {
      await exportData.exportPromptData({
        createSheet: createSheetAnalyze,
        promptType: type,
        isEncryptedMode: configuration.isEncryptedMode,
        fileName: configuration.title,
      });
      setIsLoadingExportData(false);
    } catch (exception) {
      setIsLoadingExportData(false);
      setIsExportDataError(true);
    }
  }

  function onUploadCSVFile({ file }) {
    setIsLoadingUploadFile(true);
    setUploadedFile(file);
    if (isUploadFileError) {
      setIsUploadFileError(false);
    }
    setIsLoadingUploadFile(false);
  }

  async function onDownloadDecryptedFile() {
    setIsLoadingDownloadDecryptedFile(true);
    try {
      const fileInBase64 = await blobToBase64(uploadedFile);
      await exportData.decryptedPromptData({
        createSheet: createSheetAnalyze,
        promptType: type,
        fileName: `${configuration.title} decrypted`,
        file: fileInBase64,
      });
      setIsLoadingDownloadDecryptedFile(false);
    } catch (error) {
      setIsUploadFileError(true);
      setIsLoadingDownloadDecryptedFile(false);
    }
  }

  return (
    <>
      <div className={style.locals.stepsTitle}>
        Steps:
      </div>
      <div className={style.locals.stepWrap}>
        <div className={style.locals.stepRow}>
          <div className={style.locals.stepNumber}>
            1
          </div>
          <div className={style.locals.stepDescription}>
            Export this file and drag to a
            {' '}
            <a className={style.locals.underlineWord} href="https://chat.openai.com/?model=gpt-4-code-interpreter" target="_blank" rel="noreferrer">ChatGPT</a>
            {' '}
            Code Interpreter conversation.
          </div>
        </div>
        <div className={style.locals.stepRow}>
          <Button
            type="secondaryBlue"
            className={style.locals.exportButton}
            onClick={() => onExportData()}
          >
            Export data
          </Button>
          {isLoadingExportData && (
          <div className={style.locals.loader}>
            <Spinner />
          </div>
          )}
          {configuration.isEncryptedMode && (
            <div className={style.locals.exportDescription}>
              <div className={style.locals.validatedIcon} data-icon="promptPopup:verified" />
              The export is encrypted to prevent customer data from being sent to ChatGPT.
            </div>
          )}
        </div>
        {isExportDataError && (
        <div className={style.locals.stepRow}>
          <div className={style.locals.errorText}>
            Something went wrong. Please try again
          </div>
        </div>
        )}
      </div>
      <div className={style.locals.stepWrap}>
        <div className={style.locals.stepRow}>
          <div className={style.locals.stepNumber}>
            2
          </div>
          <div className={style.locals.stepDescription}>
            Next, copy this prompt, paste into the same
            {' '}
            <a className={style.locals.underlineWord} href="https://chat.openai.com/?model=gpt-4-code-interpreter" target="_blank" rel="noreferrer">ChatGPT</a>
            {' '}
            Code Interpreter conversation, and click Enter.
          </div>
        </div>
        <div className={style.locals.copyArea}>
          <div id="content-to-copy" className={style.locals.copyTextArea}>
            {configuration.promptDescriptionToCopy}
          </div>
          <div className={style.locals.copyToClipboardRow}>
            <div
              className={style.locals.copyToClipboardIcon}
              data-icon="promptPopup:copyToClipboard"
              onClick={() => {
                copy(configuration.promptDescriptionToCopy);
                setIsDisableUploadCSV(false);
              }}
            />
          </div>
        </div>
      </div>
      {configuration.isEncryptedMode && (
      <>
        <div className={style.locals.stepWrap}>
          <div className={style.locals.stepRow}>
            <div className={style.locals.stepNumber}>
              3
            </div>
            <div className={style.locals.stepDescription}>
              Once ChatGPT has provided you with an insight, ask it to export a CSV of the results.
            </div>
          </div>
        </div>
        <div className={classnames(style.locals.stepWrap, isDisableUploadCSV ? style.locals.disableStep : null)}>
          <div className={style.locals.stepRow}>
            <div className={style.locals.stepNumber}>
              4
            </div>
            <div className={style.locals.stepDescription}>
              Upload the CSV here to decrypt it:
            </div>
          </div>
          <div className={style.locals.stepRow}>
            {!uploadedFile ? (
              <Dropzone
                onDropAccepted={([file]) => onUploadCSVFile({ file })}
                onDropRejected={() => setIsUploadFileError(true)}
                className={classnames(style.locals.dropZone, isDisableUploadCSV ? style.locals.disableStep : null)}
                activeClassName={style.locals.dropZoneActive}
                rejectClassName={style.locals.dropZoneReject}
                accept=".csv"
                disableClick={isDisableUploadCSV}
              >
                <div className={classnames(style.locals.dropZone, isDisableUploadCSV ? style.locals.disableStep : null)}>
                  {isLoadingUploadFile ? (
                    <>
                      <div className={style.locals.loader}>
                        <Spinner />
                      </div>
                      <div className={style.locals.dropZonInnerText}>Uploading...</div>
                    </>
                  ) : (
                    <>
                      <div className={style.locals.iconWrap}>
                        <div className={style.locals.uploadIcon} />
                      </div>
                      <div className={style.locals.dropZonInnerText}>Drag file or upload.</div>
                    </>
                  )}
                </div>
              </Dropzone>
            )
              : (
                <div className={style.locals.uploadedFileRow}>
                  <div className={style.locals.fileNameRow}>
                    <div className={style.locals.fileName}>
                      {uploadedFile.name}
                    </div>
                    <div
                      className={style.locals.deleteFileIcon}
                      onClick={() => setUploadedFile(null)}
                    />
                  </div>
                  <div
                    className={style.locals.downloadEncryptedRow}
                    onClick={() => onDownloadDecryptedFile()}
                  >
                    Download decrypted file
                  </div>
                  {isLoadingDownloadDecryptedFile && (
                  <div className={style.locals.loader}>
                    <Spinner />
                  </div>
                  )}
                </div>
              )}
          </div>
          {isUploadFileError && (
          <div className={style.locals.stepRow}>
            <div className={style.locals.errorText}>
              Something went wrong. Please try again.
            </div>
          </div>
          )}
        </div>
      </>
      )}
    </>
  );
}
