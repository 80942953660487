import React from 'react';
import classnames from 'classnames';
import {
  isEmpty, isEqual, times, uniqBy,
} from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import AddNewCampaignSelection from 'components/pages/plan/campaignPopups/AddNewCampaignSelection';
import CostPlaceholder from 'components/pages/plan/campaignPopups/CostPlaceholder';
import CampaignTag from 'components/pages/plan/campaignPopups/CampaignTag';
import CampaignPopup from 'components/pages/campaigns/CampaignPopup';
import ChannelsSelect from 'components/common/ChannelsSelect';
import ChooseExistingTemplate from 'components/pages/campaigns/ChooseExistingTemplate';
import Component from 'components/Component';
import CampaignTagsOptions from 'components/pages/plan/campaignPopups/CampaignTagsOptions';
import FeatureFlags from 'components/common/FeatureFlags';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import Loader from 'components/controls/Loader';
import Paging from 'components/Paging';
import PlannedVsActualChart from 'components/pages/plan/PlannedVsActualChart';
import PlannedVsActualOffTrack from 'components/pages/plan/PlannedVsActualOffTrack';
import PlannedVsActualTable from 'components/pages/plan/PlannedVsActualTable';
import Toggle from 'components/controls/Toggle';
import { TIMEFRAME_VALUES, TIME_FRAME } from 'components/utils/timeframe';
import { compose } from 'components/utils/utils';
import { fiscalQuarterStartDate, fiscalYearStartDate } from 'components/utils/date';
import { getChannelsWithProps } from 'components/utils/channels';
import { viewStyleAccordingDataType } from 'components/pages/plan/logic/enums';
import { addNewOptions } from 'components/pages/plan/campaignPopups/enums';

import planStyle from 'styles/plan/plan.css';
import style from 'styles/plan/planned-actual-tab.css';

import { getMonthsBetweenDates, getNewTitleAccordingDateAndTimeframe } from './plan/logic/plan';

export const EXTRA_CHANNEL = 'Multi Channel Campaigns';

const enhance = compose(
  inject((stores) => {
    const {
      planStore,
      userStore,
    } = stores;
    const {
      getCampaignsAndChannelsCostsBetweenDates,
      getCampaignProperties,
      channelsCostsData,
      campaignsCostsData,
      isLoadingPlannedVsActualDataFromServer,
      addChannel,
      startDate,
      endDate,
      campaignTags,
      updateSelectedCampaignTags,
      selectedCampaignTags,
      removeFromSelectedCampaignTags,
    } = planStore;
    const {
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
    } = userStore;
    return {
      addChannel,
      fiscalYearFirstMonth,
      getCampaignsAndChannelsCostsBetweenDates,
      getCampaignProperties,
      channelsCostsData,
      campaignsCostsData,
      isLoadingPlannedVsActualDataFromServer,
      startDate,
      endDate,
      campaignTags,
      updateSelectedCampaignTags,
      selectedCampaignTags,
      removeFromSelectedCampaignTags,
    };
  }),
  observer
);

class PlannedVsActual extends Component {
  style = style;

  styles = [planStyle];

  constructor(props) {
    super(props);
    const savedOffTrack = JSON.parse(localStorage.getItem('isExpandedOffTrack'));
    this.state = {
      viewStyle: viewStyleAccordingDataType.CHANNEL,
      showPopup: false,
      timeFrame: TIME_FRAME.MONTH,
      expanded: {},
      campaign: null,
      isAddNew: false,
      isAddNewCampaign: false,
      isAddNewCostPlaceholder: false,
      sourceChannel: null,
      campaignTagProperties: null,
      isExpandedOffTrack: savedOffTrack === null ? true : savedOffTrack,
      title: getNewTitleAccordingDateAndTimeframe({ startDate: props.startDate, timeFrame: TIME_FRAME.MONTH, fiscalYearFirstMonth: props.fiscalYearFirstMonth }),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      const { timeFrame } = this.state;
      this.setState({
        title: getNewTitleAccordingDateAndTimeframe({ startDate: this.props.startDate, timeFrame, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth }),
      });
    }
    if (!isEqual(this.props.campaignTags, prevProps.campaignTags)) {
      this.props.getCampaignsAndChannelsCostsBetweenDates({
        startDate: this.props.startDate, endDate: this.props.startDate, region: this.props.region,
      });
    }
  }

  componentDidMount() {
    if (this.props.channelsCostsData?.length === 0) {
      this.props.getCampaignsAndChannelsCostsBetweenDates({
        startDate: this.props.startDate, endDate: this.props.startDate, region: this.props.region,
      });
    }
  }

  setAsyncState = (newState) => new Promise((resolve) => this.setState(newState, () => resolve()));

  updateDateRange = async (timeDiffInMonths) => {
    const { timeFrame } = this.state;
    const { startDate, endDate } = this.props;
    const newStartDate = moment(startDate).add(timeDiffInMonths, timeFrame).startOf(timeFrame);
    const newEndDate = moment(endDate).add(timeDiffInMonths, timeFrame).endOf(timeFrame);
    const title = getNewTitleAccordingDateAndTimeframe({ startDate: newStartDate, timeFrame, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth });
    this.setState({ title });
    await this.props.getCampaignsAndChannelsCostsBetweenDates({
      startDate: newStartDate, endDate: newEndDate, region: this.props.region,
    });
  };

  showPopup = async (campaign, sourceChannel) => {
    const campaignProperties = await this.props.getCampaignProperties({
      id: campaign.id, source: sourceChannel.channel, startDate: this.props.startDate, endDate: this.props.endDate, region: this.props.region,
    });

    await this.setAsyncState({ campaign: { ...campaignProperties, sourceChannel } });
    this.setState({ showPopup: true });
  };

  onExpand = () => {
    const { regions = [] } = this.props;
    const channelsWithProps = getChannelsWithProps() || {};
    const categories = uniqBy(Object.keys(channelsWithProps).map((key) => channelsWithProps[key]), (c) => c.category);
    const channels = Object.keys(channelsWithProps);
    const rowsCount = channels.length + categories.length + regions.length;
    const expandObj = {};
    // eslint-disable-next-line no-return-assign
    times(rowsCount, (i) => (expandObj[i] = true));
    this.setState({ expanded: expandObj });
  };

  showCampaign = (campaign) => {
    this.setState({ showPopup: true, campaign: campaign || {} });
  };

  addCampaign = (channel) => this.setState({ isAddNew: true, sourceChannel: channel });

  updateTimeframeToggle = async (timeFrame) => {
    const { fiscalYearFirstMonth, startDate } = this.props;

    const fiscalYearStart = fiscalYearStartDate(fiscalYearFirstMonth);
    const fiscalQuarterStart = fiscalQuarterStartDate(fiscalYearStart.clone(), fiscalYearFirstMonth);

    let newStartDate = startDate;
    let newEndDate = moment(startDate).endOf('month');

    if (timeFrame === TIMEFRAME_VALUES.QUARTER) {
      newStartDate = moment(fiscalQuarterStart);
      newEndDate = moment(newStartDate).add(2, 'months').endOf('month');
    }

    if (timeFrame === TIMEFRAME_VALUES.YEAR) {
      newStartDate = moment(fiscalYearStart);
      newEndDate = moment(newStartDate).add(11, 'months').endOf('month');
    }

    const title = getNewTitleAccordingDateAndTimeframe({ startDate: newStartDate, timeFrame, fiscalYearFirstMonth });
    this.setState({
      timeFrame, title,
    });

    await this.props.getCampaignsAndChannelsCostsBetweenDates({
      startDate: newStartDate, endDate: newEndDate, region: this.props.region,
    });
  };

  addChannel({ channel }) {
    const channelsWithProps = getChannelsWithProps() || {};
    const channelCategory = channelsWithProps[channel].category;
    this.props.addChannel({ channel, channelCategory, region: this.props.region });
  }

  render() {
    const {
      isMergedView,
      isMasterRegion,
      region,
      regions,
      campaignKeyToNameMapping,
      flags,
      channelsCostsData,
      campaignsCostsData,
      startDate,
      endDate,
      selectedCampaignTags,
      removeFromSelectedCampaignTags,
      campaignTags,
    } = this.props;
    const {
      viewStyle, campaign, expanded, isExpandedOffTrack, campaignTagProperties,
    } = this.state;

    const today = moment().unix();
    const isCurrentTimeFrame = moment(startDate).unix() <= today && today <= moment(endDate).unix();

    const channelsSources = channelsCostsData.map((channel) => channel.channel);
    const monthsBetweenDates = getMonthsBetweenDates({ startDate, endDate });

    const { sourceChannel } = this.state;
    return (
      <div>
        {this.props.isLoadingPlannedVsActualDataFromServer && (
        <div className={this.classes.loader}>
          <Loader newStyle />
        </div>
        )}
        <div className={this.classes.pagingRow}>
          <div className={planStyle.locals.headTitle} style={{ marginRight: 20 }}>
            Plan
          </div>
          <div className={this.classes.row}>
            <div className={this.classes.pagingColumn}>
              <CampaignTagsOptions
                onEditClick={(campaignTag = {}) => this.setState({ campaignTagProperties: campaignTags.find((tag) => tag.id === campaignTag.value) || {} })}
              />
            </div>
            <div className={this.classes.pagingColumn}>
              <Paging
                title={this.state.title}
                onBack={() => this.updateDateRange(-1)}
                onNext={() => this.updateDateRange(1)}
              />
            </div>
            <div className={this.classes.pagingColumn}>
              <div className={classnames(this.classes.row, this.classes.togglesRow)}>
                <Toggle
                  options={[
                    {
                      text: 'Channels',
                      value: viewStyleAccordingDataType.CHANNEL,
                    },
                    {
                      text: 'Campaigns',
                      value: viewStyleAccordingDataType.CAMPAIGN,
                    },
                  ]}
                  selectedValue={this.state.viewStyle}
                  onClick={(newViewStyle) => this.setState({ viewStyle: newViewStyle }, () => (newViewStyle === viewStyleAccordingDataType.CAMPAIGN ? this.onExpand() : this.setState({ expanded: {} })))}
                />
                <Toggle
                  options={[
                    {
                      text: 'Monthly',
                      value: TIME_FRAME.MONTH,
                    },
                    {
                      text: 'Quarter',
                      value: TIME_FRAME.QUARTER,
                    },
                    {
                      text: 'Annual',
                      value: TIME_FRAME.ANNUAL,
                    },
                  ]}
                  selectedValue={this.state.timeFrame}
                  onClick={(timeFrame) => this.updateTimeframeToggle(timeFrame)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={this.classes.filterTagsRow}>
          {selectedCampaignTags.map((selectedCampaignTag) => (
            <FilterTag
              onClick={() => this.setState({ campaignTagProperties: campaignTags.find((tag) => tag.id === selectedCampaignTag.value) })}
              key={`filter-tag-planVsActual-${selectedCampaignTag.value}`}
              label={selectedCampaignTag.label}
              onRemove={() => removeFromSelectedCampaignTags({ campaignTag: selectedCampaignTag })}
            />
          ))}
        </div>
        <FeatureFlags flag={flags.offTrackPlansVsPacingForPlanPlanVsActual}>
          <PlannedVsActualOffTrack
            channels={channelsCostsData}
            campaigns={campaignsCostsData}
            initialViewType={viewStyleAccordingDataType.CATEGORY}
            options={[viewStyleAccordingDataType.CATEGORY, viewStyleAccordingDataType.CHANNEL, viewStyleAccordingDataType.CAMPAIGN]}
            container={this.classes.offTrackContainer}
            chartContainer={this.classes.offTrackChart}
            isMasterRegion={isMasterRegion}
            isCurrentMonth={isCurrentTimeFrame}
            itemTopContainer={this.classes.offTrackTopContainer}
            wrapperClass={this.classes.rowOffTrack}
            isExpanded={isExpandedOffTrack}
            toggleExpand={(newVal) => {
              localStorage.setItem('isExpandedOffTrack', newVal);
              this.setState({ isExpandedOffTrack: newVal });
            }}
          />
        </FeatureFlags>

        <div className={this.classes.loaderContainer}>
          <PlannedVsActualTable
              /* eslint-disable-next-line no-return-assign */
            ref={(ref) => this.tableRef = ref}
            isCurrentMonth={isCurrentTimeFrame}
            showPopup={this.showPopup}
            viewStyle={viewStyle}
            expanded={expanded}
            onExpand={(expand) => this.setState({ expanded: expand })}
            timeFrame={this.state.timeFrame}
            region={region}
            regions={regions}
            isMasterRegion={isMasterRegion}
            isMergedView={isMergedView}
            addCampaign={this.addCampaign}
          />

        </div>
        <div>
          <div className={this.classes.bottom}>
            <div className={this.classes.channelsRow}>
              <div style={{ width: '460px' }}>
                <ChannelsSelect
                  className={this.classes.channelsSelect}
                  withOtherChannels
                  selected={-1}
                  isChannelDisabled={(channel) => channelsCostsData.find((data) => data.channel === channel)}
                  onChange={(event) => this.addChannel({ channel: event.value })}
                  label="Add a channel"
                  labelQuestion={['']}
                  description={['Are there any channels you invested in the last month that weren’t recommended by InfiniGrow? It is perfectly fine; it just needs to be validated so that InfiniGrow will optimize your planning effectively.\nPlease choose only a leaf channel (a channel that has no deeper hierarchy under it). If you can’t find the channel you’re looking for, please choose “other” at the bottom of the list, and write the channel name/description clearly.']}
                />
              </div>
            </div>
            <FeatureFlags flag={flags.planVsActualSpendingPlanPlanVsActual}>
              <PlannedVsActualChart
                data={channelsCostsData}
                months={monthsBetweenDates}
              />
            </FeatureFlags>
          </div>
        </div>
        {!isEmpty(channelsSources) && (
          <div hidden={!this.state.showPopup}>
            <CampaignPopup
              campaign={campaign}
              channelTitle="Main channel"
              closePopup={() => this.setState({ showPopup: false })}
              teamMembers={[]}
              campaignsTemplates={{}}
              updateCampaignsTemplates={() => {
              }}
              channelsSources={channelsSources}
              campaignKeyToNameMapping={campaignKeyToNameMapping}
              openFromAnalyze
            />
          </div>
        )}
        <div>
          {this.state.isAddNew && (
          <AddNewCampaignSelection
            onClose={() => this.setState({ isAddNew: false })}
            onSave={(selectedOption) => (selectedOption === addNewOptions.newCampaign ? this.setState({ isAddNewCampaign: true, isAddNew: false }) : this.setState({ isAddNewCostPlaceholder: true, isAddNew: false }))}
          />
          )}
          {this.state.isAddNewCampaign && (
            <ChooseExistingTemplate
              showCampaign={(template) => this.showCampaign({
                ...template,
                status: 'New',
                source: [sourceChannel.channel],
              })}
              close={() => this.setState({ isAddNewCampaign: false })}
              campaignsTemplates={this.props.campaignsTemplates || {}}
            />
          )}
          {this.state.isAddNewCostPlaceholder && (
          <CostPlaceholder
            onClose={() => this.setState({ isAddNewCostPlaceholder: false })}
            channel={sourceChannel.channel}
          />
          )}
        </div>
        {campaignTagProperties && (<CampaignTag tagProperties={campaignTagProperties} onClose={() => this.setState({ campaignTagProperties: null })} />)}
      </div>
    );
  }
}

export default withLDConsumer()(enhance(PlannedVsActual));
