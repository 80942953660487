import React from 'react';
import Component from 'components/Component';
import serverCommunication from 'data/serverCommunication';
import { inject, observer } from 'mobx-react';

import PreDefinedFiltersStep from 'components/pages/integrations/PreDefinedFiltersStep';
import userStore from 'stores/userStore';
import { CRMContext } from 'components/pages/createIntegration/CRMContext';
import { compose } from 'components/utils/utils';
import { extendedData } from 'dataExtenders/calculatedDataExtender';
import { getParsedPredefinedFiltersConfig } from 'components/pages/createIntegration/logic/CreateIntegration';

import style from 'styles/integrations/integration.css';

const enhance = compose(
  inject(({
    userStore: {
      userMonthPlan,
      userMonthPlan: {
        region,
      },
    },
  }) => ({
    region,
    userMonthPlan,
  })),
  observer
);

class PreDefinedFiltersTab extends Component {
  style = style;

  static contextType = CRMContext;

  constructor(props) {
    super(props);
    this.state = {
      ...PreDefinedFiltersStep.initState,
      errors: {},
      isLoading: false,
      isFiltersChanged: false,
      funnelList: [],
    };
  }

  updateField = (newVal, currentMetric) => {
    const { updateStateShowUnsavedPopup } = this.context;
    const { predefinedFiltersConfig } = this.state;
    updateStateShowUnsavedPopup(true);
    this.setState({
      predefinedFiltersConfig: {
        ...predefinedFiltersConfig,
        [currentMetric]: newVal,
      },
      isFiltersChanged: true,
    });
  };

  saveFilter = async () => {
    const { predefinedFiltersConfig } = this.state;
    this.setState({ isLoading: true });
    const { updateStateShowUnsavedPopup } = this.context;
    updateStateShowUnsavedPopup(false);

    const updatedPredefinedFiltersConfig = this.props.onAddPredefineFilters({ predefinedFiltersConfig });
    const predefinedFiltersBody = JSON.stringify({ predefinedFiltersConfig: updatedPredefinedFiltersConfig });
    const response = await serverCommunication.serverRequest('PUT', 'predefinedfilters', predefinedFiltersBody, this.props.region);

    if (response.ok) {
      const updatedUserMonthPlan = { ...this.props.userMonthPlan };
      updatedUserMonthPlan.CRMConfig.predefinedFiltersConfig = predefinedFiltersConfig;
      const { userMonthPlanDataFromState } = extendedData(updatedUserMonthPlan);
      userStore.setUserMonthPlan(userMonthPlanDataFromState);

      this.setState({ isLoading: false, isFiltersChanged: false });
    }
  };

  componentDidMount() {
    this.getPredefinedFiltersRequest();
  }

  getPredefinedFiltersRequest = async () => {
    this.setState({ isLoading: true });
    const response = await serverCommunication.serverRequest('GET', 'crm/predefinedFilters', null, this.props.region);
    if (response.ok) {
      const responseData = await response.json();
      this.setState({ isLoading: false, funnelList: Object.keys(responseData), predefinedFiltersConfig: getParsedPredefinedFiltersConfig({ predefinedFiltersConfig: responseData, filterConfigs: this.props.filterConfigs }) });
    }
  };

  render() {
    const {
      filterConfigs,
    } = this.props;
    const { isLoading, isFiltersChanged, funnelList } = this.state;
    const { getFunnelTitle, closePopup } = this.context;
    const metrics = funnelList.map((value) => ({ value, label: getFunnelTitle(value) }));

    return (
      <div className={this.classes.tabContent}>
        <div className={this.classes.closePopup} onClick={closePopup} />
        <div className={this.classes.headTitle}>
          Predefined filters
        </div>
        <div className={this.classes.headSubTitle}>
          Set up default filters to further customize specific funnel stages,
          <br />
          or to avoid applying the same filter repeatedly.
        </div>
        <PreDefinedFiltersStep
          {...this.state}
          getFunnelTitle={getFunnelTitle}
          updateField={this.updateField}
          saveFilter={this.saveFilter}
          configs={filterConfigs}
          metrics={metrics}
          isFiltersChanged={isFiltersChanged}
          renderLoader={isLoading}
        />
      </div>
    );
  }
}

export default enhance(PreDefinedFiltersTab);
