import React from 'react';
import PropTypes from 'prop-types';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import StatSquare from 'components/common/StatSquare';
import WidgetHeader from 'components/common/WidgetHeader';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import { averageFormatter } from 'components/utils/users';
import { formatNumber } from 'components/utils/budget';
import { widgetTypes } from 'components/pages/analyze/enums';

import styles from 'styles/users/users.css';

const classes = styles.locals;

class StatsSquaresJourneys extends Component {
  getStatsItems() {
    const {
      stats = {}, statsLabel, isDealView, prevStats = {},
    } = this.props;
    const {
      total,
      engaged,
      totalInteractions,
      totalChannels,
      totalDeals,
      totalTransitions,
      transitionsAccordingTo,
    } = stats;
    return [{
      title: 'Total Accounts',
      formatter: formatNumber,
      value: total,
      prevValue: prevStats.total,
      tooltip: `Total ${statsLabel.plural.toLowerCase()} for the selected query. By default, see all ${statsLabel.plural.toLowerCase()} that had any funnel stage transition in the selected time frame.`,
      context: {
        stat: isDealView ? '' : `Total number of deals: ${totalDeals}`,
        funnelTouched: (totalTransitions && totalTransitions !== total) ? `Total ${userStore.getMetricNickname({ metric: transitionsAccordingTo })}: ${totalTransitions}` : '',
      },
    }, {
      title: 'Engaged Accounts',
      formatter: formatNumber,
      value: engaged,
      prevValue: prevStats.engaged,
      tooltip: `# of ${statsLabel.plural.toLowerCase()} that had any interaction with your brand in the selected time frame. An interaction could be any online/offline touchpoint with any of your assets, regardless of that interaction's source/channel.`,
    }, {
      title: 'Average Number of Interactions per Account',
      value: averageFormatter(totalInteractions, total),
      prevValue: averageFormatter(prevStats.totalInteractions, prevStats.total),
      tooltip: '# of total interactions / touchpoints, divided by the # of total accounts in the currently selected query.',
    }, {
      title: 'Average Number of Channels per Account',
      value: averageFormatter(totalChannels, total),
      prevValue: averageFormatter(prevStats.totalChannels, prevStats.total),
      tooltip: `Average # of unique channels shown in ${statsLabel.plural.toLowerCase()[0] === 'a' ? 'an' : 'a'} ${statsLabel.plural.toLowerCase()}'s journey within the ${statsLabel.plural.toLowerCase()} in the selected query.`,
    }];
  }

  componentDidMount() {
    styles.use();
  }

  componentWillUnmount() {
    styles.unuse();
  }

  render() {
    const statsItems = this.getStatsItems().map((item) => {
      const { value, formatter = (i) => i } = item;
      return {
        ...item,
        stat: formatter(value),
        funnelTouched: formatter(value),
      };
    });

    const {
      widgetHeaderConfig = {
        type: widgetTypes.journeysOverview,
      },
      widgetHeaderProps = {},
    } = this.props;

    return (
      <>
        <WidgetHeader
          {...widgetHeaderProps}
          widgetHeaderConfig={widgetHeaderConfig}
        />
        <div className={classes.stats}>
          {statsItems.map(({
            title, stat, funnelTouched, context, tooltip, ...rest
          }) => (
            <StatSquare
              className={classes.statSquare}
              containerClassName={classes.statSquareContainer}
              key={title}
              stat={stat}
              funnelTouched={funnelTouched}
              title={title}
              context={context}
              tooltipText={tooltip}
              squareTooltip={classes.squareTooltip}
              {...rest}
              withoutHover
            />
          ))}
        </div>
      </>
    );
  }
}

StatsSquaresJourneys.propTypes = {
  statsLabel: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
};

export default WithPersonalSpinner()(StatsSquaresJourneys);
