import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { classes } from 'components/pages/plan/BudgetTable';
import Controls from 'components/pages/plan/BudgetTable/partials/Controls';

class ScrollableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFixed: false,
    };
    this.scrollableRef = React.createRef();
    this.handleScroll = throttle(this._handleScroll, 50);
  }

  componentDidMount() {
    const { scrollPosition } = this.props;
    this.scrollTo(scrollPosition);
    this.header = document.querySelector('div.rt-table > div.rt-thead.-header.budget-table-v2__thead-1pj > div');
    document.addEventListener('scroll', this.handleScroll, { passive: true });
    this.tableHorizontalScroll = document.getElementById('containerHorizontalScroll');
    if (this.tableHorizontalScroll) {
      this.tableHorizontalScroll.addEventListener('scroll', this.handleScrollHorizontal);
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollPosition } = this.props;
    if (prevProps.scrollPosition !== scrollPosition) {
      this.scrollTo(scrollPosition);
    }

    if (this.props.scrollPosition !== prevProps.scrollPosition) {
      if (this.header) {
        this.header.scrollLeft = this.props.scrollPosition;
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
    if (this.tableHorizontalScroll) {
      this.tableHorizontalScroll.removeEventListener('scroll', this.handleScrollHorizontal);
    }
  }

  handleScrollHorizontal = ({ target }) => {
    this.props.changeScrollPosition(target.scrollLeft);
  };

  _handleScroll = () => {
    const isHeaderFixed = this.container.getBoundingClientRect().top <= 141;
    this.setState({ isFixed: isHeaderFixed });

    if (!this.header) {
      return;
    }
    if (isHeaderFixed) {
      if (this.header) {
        this.header.scrollLeft = this.props.scrollPosition;
      }
      this.header.classList.add(classes.theadFixed);
    } else {
      this.header.classList.remove(classes.theadFixed);
    }
  };

  scrollMonth = (event) => {
    const { cellWidth, changeScrollPosition, scrollPosition } = this.props;
    const { direction = 'next' } = event.currentTarget.dataset;
    const residual = scrollPosition % cellWidth;
    const stepBack = residual || cellWidth;
    const subtraction = scrollPosition - stepBack;

    const move = {
      next: scrollPosition + cellWidth - residual,
      back: subtraction > 0 ? subtraction : 0,
    };

    changeScrollPosition(move[direction]);
  };

  scrollTo(x) {
    if (this.scrollableRef.current) {
      this.scrollableRef.current.scrollLeft = x;
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div
        className={classes.root}
        ref={ref => this.container = ref}
      >
        <Controls
          onClick={this.scrollMonth}
          isFixed={this.state.isFixed}
        />
        <div
          className={classes.scrollableContainer}
          ref={this.scrollableRef}
          id="containerHorizontalScroll"
        >
          {children}
        </div>
      </div>
    );
  }
}

ScrollableContainer.defaultProps = {
  changeScrollPosition: () => {},
};

ScrollableContainer.propTypes = {
  children: PropTypes.node,
  scrollPosition: PropTypes.number,
  changeScrollPosition: PropTypes.func,
};

export default ScrollableContainer;
