exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".insights__box-1cu{width:270px;height:200px;background:linear-gradient(0deg,#1991eb 0,#2ea1f8);border-radius:4px;position:relative;overflow:hidden}.insights__inner-3jm{width:100%;height:100%;background:url(/assets/bulb-icon.png) -30px no-repeat}.insights__text-2Nh{padding:20px 20px 20px 97px;color:#fff;font-size:16px;line-height:26px}.insights__highlight-19M{position:absolute;top:0;left:0;width:100%;height:100%;box-shadow:inset 0 0 0 5px #24b10e,inset 0 0 0 10px #d5ecfd}", ""]);

// exports
exports.locals = {
	"box": "insights__box-1cu",
	"box": "insights__box-1cu",
	"inner": "insights__inner-3jm",
	"inner": "insights__inner-3jm",
	"text": "insights__text-2Nh",
	"text": "insights__text-2Nh",
	"highlight": "insights__highlight-19M",
	"highlight": "insights__highlight-19M"
};