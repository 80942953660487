import React from 'react';
import PropTypes from 'prop-types';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import { inject, observer } from 'mobx-react';
import {
  isEmpty, isEqual, union, sortBy, sumBy, isFinite, cloneDeep, groupBy,
} from 'lodash';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import ChannelIcon from 'components/common/ChannelIcon';
import DeleteChannelPopup from 'components/pages/plan/DeleteChannelPopup';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Popup from 'components/Popup';
import SubTable from 'components/pages/plan/BudgetTable/partials/SubTable';
import TableCell from 'components/pages/plan/TableCell-2';
import TitleCell from 'components/pages/plan/BudgetTable/partials/TitleCell';

import { classes } from 'components/pages/plan/BudgetTable';
import { formatBudget } from 'components/utils/budget';
import { getChannelsWithProps } from 'components/utils/channels';
import {
  isMasterRegion, modArray, sortRegions, compose,
} from 'components/utils/utils';

import addButtonIcon from 'assets/plus-blue.svg';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const COLLAPSE = {
  ALL: 'ALL',
  CATEGORY: 'CATEGORY',
  NONE: 'NONE',
};

const enhance = compose(
  inject(({
    planStore: {
      selectedCampaignTags,
    },
    userStore: {
      getRegionNickname,
    },
  }) => ({
    selectedCampaignTags,
    getRegionNickname,
  })),
  observer
);

class Table extends React.PureComponent {
  static defaultProps = {
    data: {},
    isEditMode: false,
    isConstraintsEnabled: false,
    deleteChannel: () => {},
    editCommittedBudget: () => {},
  };

  static propTypes = {
    data: PropTypes.object,
    isEditMode: PropTypes.bool,
    deleteChannel: PropTypes.func,
    editCommittedBudget: PropTypes.func,
    isConstraintsEnabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: {},
      deletePopup: '',
      collapseStatus: COLLAPSE.NONE,
      pageSize: undefined,
      expandedCategories: props.allCategories,
      expandedRegions: this.allRegions(),
      expandedChannels: props.uniqChannels,
    };
  }

  componentDidUpdate(prevProps) {
    const { allCategories } = this.props;
    if (!isEqual(prevProps.allCategories, allCategories)) {
      this.setState({ expandedCategories: allCategories });
    }
  }

  allRegions = () => this.props.regions;

  closeDeletePopup = () => this.setState({ deletePopup: '' });

  toggleAllCategory = () => {
    if (!isEmpty(this.state.expandedCategories)) {
      this.setState({ expandedCategories: [] });
    } else {
      this.setState({ expandedCategories: this.props.allCategories });
    }
  };

  toggleRows = (rowToExpland, type) => () => {
    const currentArr = this.state[type];
    const expandedArr = [...currentArr];
    modArray(expandedArr, rowToExpland);
    this.setState({ [type]: expandedArr });
  };

  getTableData = () => {
    const {
      data, region, campaigns,
    } = this.props;
    let dataByRegion = data;
    if (!isMasterRegion(region)) {
      dataByRegion = {
        [region]: data[region],
      };
    }

    const channelsProps = getChannelsWithProps();
    const filteredChannelsProps = Object.fromEntries(Object.entries(channelsProps).filter(([, props]) => !props.isDeleted));

    const allRegionsTableData = Object.keys(dataByRegion).map((regionVal) => {
      const regionsData = dataByRegion[regionVal];
      const allRegionChannels = union(...regionsData.map((month) => Object.keys(month.channels)));

      const notSorted = allRegionChannels.map((channel) => {
        const row = {};

        for (const [index, monthData] of regionsData.entries()) {
          const {
            channels, isQuarter, isAnnual, realIndex,
          } = monthData;
          row[index] = {
            primaryBudget: 0,
            secondaryBudget: 0,
            isConstraint: false,
            region: regionVal,
          };
          row[index].isQuarter = isQuarter;
          row[index].isAnnual = isAnnual;
          row[index].updateIndex = realIndex;
          row[index].channel = channel;
          if (channels[channel]) {
            row[index] = {
              ...row[index],
              ...channels[channel],
              isConstraint: channels[channel].isConstraint
                ? channels[channel].isConstraint
                : false,
              primaryBudget: channels[channel].primaryBudget
                ? channels[channel].primaryBudget
                : 0,
            };
          }
        }
        row.nickname = filteredChannelsProps[channel].nickname;
        row.category = filteredChannelsProps[channel].category;
        row.channel = channel;
        row.region = regionVal;
        const regionCampaigns = campaigns[regionVal] || [];

        row.children = regionCampaigns.filter((campaign) => campaign.channel === channel);
        return row;
      });
      return sortBy(notSorted, [(row) => row.category?.toLowerCase(), 'nickname']);
    });
    return allRegionsTableData.flat();
  };

  getTableColumns(tableData) {
    const {
      dates,
      numberOfPastDates,
      editCommittedBudget,
      isEditMode,
      isConstraintsEnabled,
      deleteChannel,
      interactiveMode,
      showSumData,
      isMergedView,
      selectedCampaignTags,
    } = this.props;
    const {
      deletePopup, expandedCategories, expandedRegions, expandedChannels,
    } = this.state;
    const pivotRows = tableData.filter((row) => row.isTopLevelRow);
    const columnsData = dates.map(({
      value: dateValue, isAnnual, isQuarter, date, ...restData
    }, index) => {
      const isHistory = index < numberOfPastDates;
      const isCurrentMonth = index === numberOfPastDates;
      return {
        Header: dateValue,
        accessor: String(index),
        aggregate: (values) => ({
          isQuarter: values.some((val) => val.isQuarter),
          sum: sumBy(values, 'primaryBudget'),
        }),
        Aggregated: (row) => formatBudget(row.value.sum),
        Cell: ({ value = {}, original }) => {
          const {
            channel,
            isConstraint,
            isSoft,
            primaryBudget,
            secondaryBudget,
            updateIndex,
            region,
          } = value;

          if (isAnnual || isQuarter || isHistory || original.isPivotRow || selectedCampaignTags.length > 0) {
            return formatBudget(primaryBudget);
          }
          return (
            <TableCell
              date={date}
              format={formatBudget}
              value={primaryBudget}
              secondaryValue={secondaryBudget}
              isConstraint={isConstraint}
              isConstraintsEnabled={isConstraintsEnabled}
              isSoft={isSoft}
              onEdit={editCommittedBudget}
              isEditMode={isEditMode}
              updateIndex={updateIndex}
              channel={channel}
              region={region}
              interactiveMode={interactiveMode}
              showSumData={showSumData}
            />
          );
        },
        width: 140,
        Footer: () => formatBudget(sumBy(pivotRows, (row) => row[index].primaryBudget)),
        getProps: (cellProps, row = {}) => {
          const { original = {} } = row;
          return {
            className: classNames({
              [classes.cellCategory]: original.isPivotRow,
              [classes.cellAnnual]: isAnnual,
              [classes.cellQuarter]: isQuarter,
              [classes.cellHistory]: isHistory,
              [classes.cellRegion]: original.isTopLevelRow,
              [classes.cellNotEditable]: isAnnual || isQuarter || isHistory || selectedCampaignTags.length > 0,
            }),
          };
        },
        getHeaderProps: () => ({
          className: classNames(
            {
              [classes.cellAnnual]: isAnnual,
              [classes.cellQuarter]: isQuarter,
              [classes.cellHistory]: isHistory,
              [classes.cellActive]: isCurrentMonth,
            },
            classes.cellHeader
          ),
        }),
        isAnnual,
        ...restData,
      };
    });

    columnsData.unshift({
      Header: '',
      width: 0,
      filterable: false,
      resizable: false,
      sortable: false,
      expander: true,
      show: false,
    });

    const leftColumn = {
      id: 'channel',
      accessor: (item) => item,
      Header: () => (
        <>
          <button
            type="button"
            aria-label="Expand category"
            className={classNames(
              classes.buttonUp,
              classes.buttonUpAction
            )}
            onClick={this.toggleAllCategory}
          >
            <i
              className={classNames(classes.icon, classes.iconUp, {
                [classes.iconDown]: !isEmpty(expandedCategories),
              })}
              data-icon="plan:monthNavigationWhite"
            />
          </button>
          Marketing Channel
        </>
      ),
      fixed: 'left',
      width: 335,
      Expander: (cellData) => {
        const { original, value } = cellData;
        if (value.isPivotRow) {
          return null;
        }
        return (
          <div
            className={classes.channel}
            data-channel={original.channel}
          >
            <i
              className={classNames(classes.icon, classes.iconChannel, classes.iconUp, {
                [classes.iconDown]: cellData.isExpanded,
              })}
              data-icon="plan:monthNavigation"
            />
            {!isMergedView && (
              <ChannelIcon
                className={classNames(classes.icon, classes.iconLarge)}
                channel={original.channel}
              />
            )}
            <div
              className={classNames(classes.channelNickname, {
                [classes.channelNicknameExpanded]: cellData.isExpanded,
              })}
            >
              <EllipsisTooltip
                text={isMergedView ? original.region : original.nickname}
                place="top"
                TooltipProps={{
                  className: classes.channelNicknameTip,
                }}
              />
            </div>
            {cellData.isExpanded && (
              <div
                className={classes.addCampaign}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.addCampaign(original);
                }}
              >
                Add
              </div>
            )}
            {isEditMode && (
              <>
                <button
                  type="button"
                  aria-label="Edit mode"
                  onClick={() => this.setState({
                    deletePopup: original.channel,
                  })}
                  className={classes.buttonIcon}
                >
                  <i
                    className={classes.icon}
                    data-icon="plan:removeChannel"
                  />
                </button>
                <Popup
                  hidden={original.channel !== deletePopup}
                  style={{
                    top: '0',
                    left: '130px',
                    cursor: 'initial',
                  }}
                >
                  <DeleteChannelPopup
                    onNext={() => {
                      deleteChannel(original.channel);
                      this.closeDeletePopup();
                    }}
                    onBack={this.closeDeletePopup}
                  />
                </Popup>
              </>
            )}
          </div>
        );
      },
      Cell: (cellData) => {
        const { value } = cellData;
        const {
          isPivotRow, isRegionRow, isMergedChannel, isTopLevelRow,
        } = value;
        const cellClass = isTopLevelRow ? classes.cellRegion : classes.cellCategory;
        if (!isPivotRow) {
          cellData.expander = true;
          return null;
        }
        if (isMergedChannel) {
          return (
            <TitleCell
              container={cellClass}
              classes={classes}
              isExpanded={expandedChannels.find((reg) => reg === value.channel)}
              onClick={this.toggleRows(value.channel, 'expandedChannels')}
              title={value.nickname}
              titleRender={() => (
                <div className={classes.mergedChannelRow}>
                  <ChannelIcon
                    className={classNames(classes.icon, classes.iconLarge)}
                    channel={value.channel}
                  />
                  <div>{value.nickname}</div>
                </div>
              )}
            />
          );
        }
        if (isRegionRow) {
          return (
            <TitleCell
              container={cellClass}
              classes={classes}
              isExpanded={expandedRegions.find((reg) => reg === value.region)}
              onClick={this.toggleRows(value.region, 'expandedRegions')}
              title={this.props.getRegionNickname({ region: value.region })}
            />
          );
        }
        return (
          <TitleCell
            container={cellClass}
            classes={classes}
            isExpanded={expandedCategories.find((category) => category === value.category)}
            onClick={this.toggleRows(value.category, 'expandedCategories')}
            title={value.category}
            renderContent={() => isEditMode && (
              <Button
                container={classes.cellCategory}
                icon={addButtonIcon}
                type="primaryBlue"
                onClick={() => this.openAddChannelPopup(value.category)}
                className={classes.buttonAdd}
              >
                Add
              </Button>
            )}
          />
        );
      },
      Footer: () => (
        <div className={classes.cellTitleOffset}>
          Total
        </div>
      ),
      getProps: (cellProps, row = {}) => {
        const { original = {} } = row;
        const { channel } = original;

        return {
          className: classNames(classes.cellDefault, {
            [classes.cellCategoryWrapper]: original.isPivotRow,
            [classes.cellCategoryEditable]: isEditMode,
          }),
          style: {
            zIndex: channel === deletePopup && 2,
          },
        };
      },
      getHeaderProps: () => ({
        className: classNames(
          classes.cellDefault,
          classes.cellHeader,
          classes.cellTitleOffset
        ),
      }),
    };

    columnsData.unshift(leftColumn);
    return columnsData;
  }

  getTrProps = (cellProps, rowInfo = {}) => ({
    className: classNames({
      [classes.rowCategory]: rowInfo.aggregated,
    }),
  });

  getTdProps = (cellProps, rowInfo = {}, column = {}) => ({
    className: classNames({
      [classes.cellAnnualCategory]: rowInfo.aggregated && column.isAnnual,
    }),
  });

  handleExpand = (expanded) => this.setState({ expanded });

  openAddChannelPopup = (channel) => (event) => {
    event.stopPropagation();
    const { openAddChannelPopup } = this.props;
    return openAddChannelPopup(channel);
  };

  makeSort = (key) => (data) => sortBy(data, [(ch) => ch[key].toLowerCase()]);

  addCategories = (channels, isTopLevelRow) => {
    const { expandedCategories } = this.state;
    return this.aggregateBy(
      channels,
      expandedCategories,
      'category',
      this.props.isMergedView ? this.addMergedChannels : this.makeSort('channel'),
      { isTopLevelRow },
      this.makeSort('category')
    );
  };

  addRegions = (channels) => {
    const { expandedRegions: regions } = this.state;
    return this.aggregateBy(
      channels,
      regions,
      'region',
      this.addCategories,
      { isRegionRow: true, isTopLevelRow: true },
      sortRegions
    );
  };

  addMergedChannels = (channels) => {
    const { expandedChannels } = this.state;
    return this.aggregateBy(
      channels,
      expandedChannels,
      'channel',
      sortRegions,
      { isMergedChannel: true }
    );
  };

  aggregateBy = (channels = [], substances, dataKey, processChunk, additionalData = {}, customSort) => {
    let aggregated = channels.reduce((result, channel) => {
      const index = result.findIndex((item) => item[dataKey] === channel[dataKey]);

      if (index >= 0) {
        Object.keys(channel)
          .filter((key) => isFinite(parseInt(key, 10)))
          .forEach((columnKey) => {
            result[index][columnKey].primaryBudget += channel[columnKey].primaryBudget;
            result[index][columnKey].secondaryBudget += channel[columnKey].secondaryBudget;
          });
      } else {
        result.push({
          ...cloneDeep(channel),
          isPivotRow: true,
          children: [],
          channel: channel[dataKey],
          ...additionalData,
        });
      }
      return result;
    }, []);
    if (customSort) {
      aggregated = customSort(aggregated);
    }
    const groupedData = groupBy(channels, (channel) => channel[dataKey]);

    substances.forEach((type) => {
      const index = aggregated.findIndex((item) => item[dataKey] === type);
      if (index < 0) {
        return;
      }
      const chunk = groupedData[type];
      const processedChunk = processChunk(chunk);
      aggregated.splice(index + 1, 0, ...processedChunk);
    });
    return aggregated;
  };

  render() {
    const { expanded, collapseStatus } = this.state;
    const {
      numberOfPastDates, showPopup, updateCampaign, dates, datesInitial, currentDateIndex, region,
      historyCampaigns, quarterOffset, annualOffset, showSumData, addQuartersAndYearSumData, isMergedView,
    } = this.props;
    const tableData = this.getTableData();
    const data = (isMasterRegion(region) && !isMergedView)
      ? this.addRegions(tableData)
      : this.addCategories(tableData, true);

    return (
      <ReactTableFixedColumns
        className={classNames(classes.table, collapseStatus === COLLAPSE.ALL && classes.collapseAll)}
        data={data}
        columns={this.getTableColumns(data)}
        pageSize={data.length}
        expanded={expanded}
        getTdProps={this.getTdProps}
        getTrProps={this.getTrProps}
        getTheadProps={() => ({
          className: classes.thead,
        })}
        getTheadTrProps={() => ({
          className: classes.theadRowContainer,
        })}
        onExpandedChange={this.handleExpand}
        resizable={false}
        showPageSizeOptions={false}
        showPagination={false}
        sortable={false}
        showFooterTop={collapseStatus !== COLLAPSE.ALL}
        NoDataComponent={() => null}
        SubComponent={(row) => (
          <SubTable
            dates={dates}
            datesInitial={datesInitial}
            row={row}
            classes={classes}
            numberOfPastDates={numberOfPastDates}
            showPopup={showPopup}
            updateCampaign={updateCampaign}
            currentDateIndex={currentDateIndex}
            historyCampaigns={historyCampaigns}
            region={region}
            quarterOffset={quarterOffset}
            annualOffset={annualOffset}
            showSumData={showSumData}
            addQuartersAndYearSumData={addQuartersAndYearSumData}
          />
        )}
      />
    );
  }
}

export default enhance(Table);
