import React from 'react';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import { CommonPropTypes } from 'components/pages/users/Filters/CommonFilter';
import filterStyles from 'styles/users/filters.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import BaseFilter from 'components/pages/users/Filters/BaseFilter';
import {
  COMPARISON_TOGGLE, CONTAINS, CAMPAIGN, CAMPAIGN_TAGS,
} from 'components/utils/filters';
import { EQUALS, filterKinds } from 'components/utils/filters/logic';
import Dropdown from 'components/controls/Dropdown';
import { compose } from 'components/utils/utils';
import ContainsSelect from './FilterPanel/UI/ContainsSelect';

const styles = filterStyles.locals;

const enhance = compose(
  inject(({
    filterStore: {
      getFilterKindOptions,
    },
  }) => ({
    getFilterKindOptions,
  })),
  observer
);

class CampaignsFilter extends Component {
  static propTypes = CommonPropTypes;

  styles = [checkboxStyle];

  constructor(props) {
    super(props);
    const comparisonOperatorLabel = props.activeFilter ? props.activeFilter.data.comparisonOperator : EQUALS;
    this.state = {
      comparisonOperator: comparisonOperatorLabel,
      isContainsSelected: comparisonOperatorLabel === CONTAINS,
    };
  }

  changeComparisonHandler = (value) => {
    this.setState({
      comparisonOperator: value,
      isContainsSelected: value === CONTAINS,
    });
  };

  render() {
    const { config, getFilterKindOptions } = this.props;
    return (
      <BaseFilter comparisonOperator={this.state.comparisonOperator} changeComparisonHandler={this.changeComparisonHandler} {...this.props} kind={filterKinds.CAMPAIGNS}>
        {({
          selectedOptions, selectOptions, onChangeComparison, containsValueHandler, setFilterFieldIndex, fieldIndex,
        }) => {
          const fieldOptions = config.fieldKey.map((fieldKeyName, index) => ({
            label: config.fieldNames ? config.fieldNames[index] : fieldKeyName,
            value: fieldKeyName,
          }));

          let searchText = 'Search campaign tag...';
          let relevantOptions = config.options.filter((option) => option.key === CAMPAIGN_TAGS);
          const isCampaignTypeSelected = fieldIndex === 0;
          if (isCampaignTypeSelected) {
            searchText = 'Search campaign...';
            relevantOptions = config.options.filter((option) => option.key === CAMPAIGN);
          }

          return (
            <>
              <div className={styles.filterVariant}>
                <Dropdown
                  onChange={setFilterFieldIndex}
                  options={fieldOptions}
                  selectedKey={config.fieldKey[fieldIndex]}
                  placeholder="Select type"
                  controlWidth={210}
                />
              </div>
              <div className={styles.filterVariant}>
                <Dropdown
                  onChange={onChangeComparison}
                  options={Object.entries(COMPARISON_TOGGLE).map(([value, label]) => ({ value, label }))}
                  selectedKey={this.state.comparisonOperator}
                  placeholder="Select condition"
                  controlWidth={210}
                />
              </div>
              {this.state.isContainsSelected ? (
                <div className={styles.rowCenter} style={{ marginBottom: 15, position: 'relative' }}>
                  <ContainsSelect containsValue={selectedOptions.map((item) => ({ label: item, value: item }))} containsValueHandler={containsValueHandler} />
                </div>
              ) : (
                <MultiCheckSelect
                  className={styles.optionSelect}
                  selected={selectedOptions.map((campaign) => ({
                    label: campaign, value: campaign,
                  }))}
                  placeholder={searchText}
                  onChange={selectOptions}
                  isAsyncPagination={config.isSearchable}
                  searchFunction={(searchValue, offset) => getFilterKindOptions({
                    searchValue, filterKind: config.fieldKey[fieldIndex], filterKey: config.fieldKey[fieldIndex], offset,
                  })}
                  selectAll
                  key={config.fieldKey[fieldIndex]}
                  options={relevantOptions.map((campaign) => ({ label: campaign, value: campaign }))}
                />
              )}
            </>
          );
        }}
      </BaseFilter>
    );
  }
}

export default enhance(CampaignsFilter);
