import { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';

import OptimalJourney from 'components/widgets/optimalJourney/OptimalJourney';

import { getInitializeMockData } from 'components/pages/analyze/optimalJourneyTab/logic/optimalJourneysTab';
import {
  journeyBuildingBlocksButtonNamesMock,
  keyChannels,
  stageVelocity,
} from 'components/widgets/optimalJourney/enums';

function OptimalJourneyTab({ flags }) {
  if (!flags.optimalJourneysAnalyzeTab) {
    history.push('/analyze/overview');
    return null;
  }

  const [widgetData, setWidgetData] = useState({});
  const [insights, setInsights] = useState([]);
  const [selectedBuildingBlocksStatuses, setSelectedBuildingBlocksStatuses] = useState({
    [keyChannels]: true,
    [stageVelocity]: true,
  });

  const buildingBlocks = getBuildingBlocksOptions();

  useEffect(() => {
    requestOptimalJourneysData();
  }, []);

  useEffect(() => {
    setSelectedBuildingBlocksStatuses(getSelectedBuildingBlocks());
  }, [widgetData]);

  useEffect(() => {
    setInsights(getInsights());
  }, [selectedBuildingBlocksStatuses, widgetData]);

  function getSelectedBuildingBlocks() {
    if (flags.optimalJourneysDemoOnly) {
      return {
        [keyChannels]: true,
        [stageVelocity]: true,
      };
    }

    const canvasSections = Object.values(widgetData?.journeyCanvas);
    const selectedBuildingBlocks = {};
    for (const section in canvasSections) {
      for (const segment in section) {
        if (segment in buildingBlocks) {
          selectedBuildingBlocks[segment] = true;
        }
      }
    }
    return selectedBuildingBlocks;
  }

  function requestOptimalJourneysData() {
    if (flags.optimalJourneysDemoOnly) {
      const data = getInitializeMockData();
      setWidgetData(data);
    }
    // getWidgetsDataV2
  }

  function getBuildingBlocksOptions() {
    // getSegments
    if (flags.optimalJourneysDemoOnly) {
      return journeyBuildingBlocksButtonNamesMock;
    }

    return [];
  }

  function getInsights() {
    const selectedBuildingBlocksArray = Object.keys(selectedBuildingBlocksStatuses);
    if (widgetData?.insights) {
      const insightsList = selectedBuildingBlocksArray?.map((selectedBlock) => widgetData.insights[selectedBlock]);
      const insightsListFiltered = insightsList.filter((insight) => insight);
      return insightsListFiltered;
    }

    return [];
  }

  return (
    <OptimalJourney
      widgetData={widgetData}
      insightsOptions={insights}
      buildingBlocksOptions={buildingBlocks}
      selectedBuildingBlocksStatuses={selectedBuildingBlocksStatuses}
      setSelectedBuildingBlocksStatuses={setSelectedBuildingBlocksStatuses}
    />
  );
}

export default withLDConsumer()(OptimalJourneyTab);
