import React from 'react';
import WithHoverEffect from 'components/common/WithHoverEffect';
import PlannedVsActualOffTrack from 'components/pages/plan/PlannedVsActualOffTrack';
import AbsoluteButton from 'components/pages/dashboard/dashboardNavigate/AbsoluteButton';
import { viewStyleAccordingDataType } from 'components/pages/plan/logic/enums';

const OffTrackWithButton = (props) => {
  const {
    parsedCampsOffTrack,
    channelsParsed,
    classes,
    isPrev,
    isHovered,
    redirect,
  } = props;

  return (
    <>
      <PlannedVsActualOffTrack
        campaigns={parsedCampsOffTrack}
        channels={channelsParsed}
        initialViewType={viewStyleAccordingDataType.CHANNEL}
        options={[viewStyleAccordingDataType.CHANNEL, viewStyleAccordingDataType.CAMPAIGN]}
        spendingOnly
        container={classes.offTrackContainer}
        itemTopContainer={classes.offTrackTopContainer}
        chartContainer={classes.offTrackChart}
        isCurrentMonth={!isPrev}
      />
      <AbsoluteButton
        text="Plans vs Actuals"
        isHovered={isHovered}
        onClick={() => redirect('/plan/plans-vs-actuals')}
        contClassName={classes.absoluteButtonContainer}
      />
    </>
  );
};

export default WithHoverEffect(OffTrackWithButton);
