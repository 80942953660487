import moment from 'moment';
import { TIMEFRAME_VALUES, TIMEFRAME_LABELS } from '../../../components/utils/timeframe';

export function getParseDateRangeLabel({ timeFrame, startDate, endDate }) {
  const dateFormatForLabel = 'MMM DD, YYYY';
  const isShowShortLabel = !startDate || !endDate;

  if (timeFrame.value === TIMEFRAME_VALUES.ROLLING && timeFrame.rollingValue && timeFrame.type) {
    const [, amountOfUnit] = timeFrame.rollingValue.split('_');
    const rollingType = amountOfUnit > 1 ? `${timeFrame.type}s` : timeFrame.type;
    const rollingDateLabel = `last ${amountOfUnit} ${rollingType}`;
    if (isShowShortLabel) {
      return rollingDateLabel;
    }
    return `Date range: ${rollingDateLabel} (${moment(startDate).format(dateFormatForLabel)} - ${moment(endDate).format(dateFormatForLabel)})`;
  }

  if (timeFrame.value === TIMEFRAME_VALUES.CUSTOM && isShowShortLabel) {
    return `${moment(timeFrame.startDate).format(dateFormatForLabel)} - ${moment(timeFrame.endDate).format(dateFormatForLabel)}`;
  }

  const timeFrameMatchingKey = Object.keys(TIMEFRAME_VALUES).find((key) => TIMEFRAME_VALUES[key] === timeFrame.value);
  if (isShowShortLabel) {
    return TIMEFRAME_LABELS[timeFrameMatchingKey];
  }
  return `Date range: ${TIMEFRAME_LABELS[timeFrameMatchingKey]} (${moment(startDate).format(dateFormatForLabel)} - ${moment(endDate).format(dateFormatForLabel)})`;
}

export function convertDaysToWeeks({ days, appendLabel = true, isShortLabel = true }) {
  const weeks = days / 7;
  if (appendLabel) {
    return `${weeks} ${isShortLabel ? 'W' : 'weeks'}`;
  }

  return `${weeks}`;
}
