exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".controls-label__label-3IV{color:#273142;font-size:14px;line-height:17px;font-weight:600;height:20px;display:flex;align-items:center;margin-bottom:12px}.controls-label__question-box-3MP{position:relative;height:20px}.controls-label__icon-margin-X-w{margin-left:12px}.controls-label__question-qO8{width:20px;height:20px;background:url(/assets/question-icon.png) 50% no-repeat}.controls-label__tooltip-1X5{position:absolute;left:100%;top:-8px;min-height:60px;margin-left:12px;width:380px;background:#fff;border:1px solid #dbdce3;border-radius:4px;box-shadow:0 4px 12px rgba(24,32,51,.07);padding:0 9px;z-index:10;text-transform:none;white-space:pre-wrap;line-height:140%}.controls-label__tooltip-1X5:before{left:-7px;z-index:1;border-color:transparent #dbdce3 transparent transparent}.controls-label__tooltip-1X5:after,.controls-label__tooltip-1X5:before{content:\"\";display:block;position:absolute;top:10px;width:0;height:0;border-style:solid;border-width:6px 6px 6px 0}.controls-label__tooltip-1X5:after{left:-6px;z-index:2;border-color:transparent #fff transparent transparent}.controls-label__tt-label-28k{height:34px;border-bottom:1px solid #dbdce3;line-height:34px;text-align:center;font-size:14px;font-weight:600;color:#273142}.controls-label__tt-content-oas{margin:12px 0}.controls-label__tt-sub-title-1sn{font-size:15px;font-weight:600;color:#354052}.controls-label__tt-sub-text-4Gn{font-size:13px;font-weight:400;font-style:italic;color:#354052;margin-top:4px;margin-bottom:10px;-webkit-font-smoothing:auto;-moz-osx-font-smoothing:auto}", ""]);

// exports
exports.locals = {
	"label": "controls-label__label-3IV",
	"label": "controls-label__label-3IV",
	"question-box": "controls-label__question-box-3MP",
	"questionBox": "controls-label__question-box-3MP",
	"icon-margin": "controls-label__icon-margin-X-w",
	"iconMargin": "controls-label__icon-margin-X-w",
	"question": "controls-label__question-qO8 controls-label__icon-margin-X-w",
	"question": "controls-label__question-qO8 controls-label__icon-margin-X-w",
	"tooltip": "controls-label__tooltip-1X5",
	"tooltip": "controls-label__tooltip-1X5",
	"tt-label": "controls-label__tt-label-28k",
	"ttLabel": "controls-label__tt-label-28k",
	"tt-content": "controls-label__tt-content-oas",
	"ttContent": "controls-label__tt-content-oas",
	"tt-sub-title": "controls-label__tt-sub-title-1sn",
	"ttSubTitle": "controls-label__tt-sub-title-1sn",
	"tt-sub-text": "controls-label__tt-sub-text-4Gn",
	"ttSubText": "controls-label__tt-sub-text-4Gn"
};