import React from 'react';
import { inject, observer } from 'mobx-react';
import { sortBy } from 'lodash';

import Component from 'components/Component';
import Page from 'components/Page';
import GoalsContainer from 'components/pages/settings/goals/GoalsContainer';
import GoalPopup from 'components/pages/settings/goals/popups/GoalPopup';

import { compose } from 'components/utils/utils';
import { activityStatusValues } from 'components/pages/settings/goals/logic/enums';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetsDataV2, getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';

import selectStyle from 'styles/controls/select.css';
import pageStyle from 'styles/page.css';
import loaderStyle from 'styles/plan/planned-actual-tab.css';
import style from 'styles/settings/goals/goals.css';

const enhance = compose(
  inject(({
    attributionStore,
    dashboardStore,
    widgetsAnalysisStore,
    filterStore,
  }) => {
    const {
      isLoaded,
    } = attributionStore;

    const {
      dataPerWidget: {
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
      },
      getWidgetRequestId,
    } = widgetsAnalysisStore;

    const {
      filtersData,
    } = filterStore;

    return {
      filtersData,
      isLoaded,
      dashboardActualIndicators: dashboardStore.actualIndicators,
      goalsAnalysis,
      getWidgetRequestId,
    };
  }),
  observer
);

class GoalsTab extends Component {
  style = style;

  styles = [pageStyle, loaderStyle, selectStyle];

  constructor(props) {
    super(props);

    this.state = {
      status: activityStatusValues.ACTIVE,
      goalPopupData: {
        hidden: true,
        editMode: false,
      },
    };
  }

  componentDidMount() {
    getWidgetsDataV2({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: {
        goalsAnalysisParams: {
          status: this.state.status,
          mainGoalsWithMilestones: true,
          timeframe: {},
        },
      },
      addBaseAnalyzeConfig: false,
    });
  }

  addGoalPopup = () => {
    this.setState({
      goalPopupData: {
        hidden: false,
        editMode: false,
        selectedGoal: null,
      },
    });
  };

  editGoalPopup = (selectedGoal) => {
    this.setState({
      goalPopupData: {
        hidden: false,
        editMode: true,
        selectedGoal,
      },
    });
  };

  closePopup = (callback) => {
    this.setState({
      goalPopupData: {
        hidden: true,
        editMode: false,
        selectedGoal: null,
      },
    }, () => callback && callback());
  };

  handleChangeStatus({ status }) {
    this.setState({ status });
    getWidgetsDataV2({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: {
        goalsAnalysisParams: {
          status,
          mainGoalsWithMilestones: true,
          timeframe: {},
        },
      },
      addBaseAnalyzeConfig: false,
    });
  }

  render() {
    const {
      isLoaded,
      actualIndicators,
      forecastedIndicators,
      filtersData,
      getWidgetRequestId,
      goalsAnalysis,
    } = this.props;

    const goalsAnalysisFullConfig = getWidgetFullConfig({
      widgetConfig: {
        goalsAnalysisParams: {
          status: this.state.status,
          mainGoalsWithMilestones: true,
          timeframe: {},
        },
      },
      addBaseAnalyzeConfig: false,
    });
    const goalsAnalysisRequestId = getWidgetRequestId({
      widget: widgetTypes.goalsAnalysis,
      widgetConfig: goalsAnalysisFullConfig,
    });
    let goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];
    const isGoalAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished';

    goalsAnalysisData = sortBy(goalsAnalysisData, 'priority');

    return (
      <Page
        popup={false}
        contentClassName={this.classes.container}
        width="100%"
      >
        <div>
          <GoalsContainer
            goalsList={goalsAnalysisData || []}
            addGoalPopup={this.addGoalPopup}
            editGoalPopup={this.editGoalPopup}
            filtersData={filtersData}
            status={this.state.status}
            handleChangeStatus={(newStatus) => this.handleChangeStatus({ status: newStatus.value })}
            isLoaded={isGoalAnalysisLoaded}
            goalsAnalysisRequestId={goalsAnalysisRequestId}
          />
        </div>
        <GoalPopup
          selectedGoal={this.state.goalPopupData.selectedGoal}
          status={this.state.status}
          hidden={this.state.goalPopupData.hidden}
          editMode={this.state.goalPopupData.editMode}
          close={this.closePopup}
          actualIndicators={actualIndicators}
          forecastedIndicators={forecastedIndicators}
          goalsList={goalsAnalysisData || []}
          goalsAnalysisRequestId={goalsAnalysisRequestId}
        />
      </Page>
    );
  }
}

export default enhance(GoalsTab);
