import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Label from 'components/ControlsLabel';
import Textfield from 'components/controls/Textfield';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import AskQuestion from 'components/pages/questions/AskQuestion';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import Spinner from 'components/pages/journeys/Spinner';
import servicesStore from 'stores/servicesStore';

import { parseReportsByFoldersKey } from 'components/pages/reports/logic/reports';
import { splitSuggestionTextToObjects, getWidgetTitle } from 'components/pages/questions/logic/suggestionItem';
import { presetDuplicateMessages } from 'components/pages/account/enum';

import onboardingStyle from 'styles/onboarding/onboarding.css';

const styles = onboardingStyle.locals || {};

function OnboardingAddPreset({
  onGoBack,
  selectedPreset,
  setSelectedPreset,
  foldersList,
  reportsList,
  UID,
  inviteOrUpdatedMember,
  isEditMode,
  presetOptions = [],
  setPresetOptions,
  isPresetsLoading,
  updateUserAccountByKey,
  onboardingPresetConfigs,
}) {
  useStyles([onboardingStyle]);

  const [isCreateNewPresetPopup, setIsCreateNewPresetPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [presetError, setPresetError] = useState({ isShow: false, message: presetDuplicateMessages });
  const [presetData, setPresetData] = useState({
    presetName: '',
    presetReports: [],
    presetQuestions: [],
  });

  const reportsOptions = useMemo(() => {
    const reportsByFolderId = parseReportsByFoldersKey({ folders: foldersList, reportsList });

    return foldersList.map((folder) => ({
      label: folder.label,
      options: reportsByFolderId[folder.value].map((report) => ({
        label: report.label,
        value: report.value,
      })),
    }));
  }, []);

  function onClickQuestionSuggestion({ questionData, placeHoldersValues }) {
    const questionTextArray = splitSuggestionTextToObjects({ text: questionData.questionText, placeHoldersTypes: questionData.placeHoldersTypes, placeHoldersValues });
    const questionText = getWidgetTitle({ questionTextArray });

    const isQuestionAlreadyAdded = presetData.presetQuestions.some((question) => question.questionId === questionData.questionId);
    if (isQuestionAlreadyAdded) {
      setPresetData({ ...presetData, presetQuestions: presetData.presetQuestions.filter((question) => question.questionId !== questionData.questionId) });
      return;
    }
    setPresetData({ ...presetData, presetQuestions: [...presetData.presetQuestions, { title: questionText, questionData, questionId: questionData.questionId }] });
  }

  function onRemoveQuestion({ questionId }) {
    setPresetData({ ...presetData, presetQuestions: presetData.presetQuestions.filter((question) => question.questionId !== questionId) });
  }

  function onUpdatePlaceHolderValue({ questionData, placeHolders }) {
    const isQuestionAlreadyAdded = presetData.presetQuestions.some((question) => question.questionId === questionData.questionId);
    if (isQuestionAlreadyAdded) {
      const questionTextArray = splitSuggestionTextToObjects({ text: questionData.questionText, placeHoldersTypes: questionData.placeHoldersTypes, placeHoldersValues: placeHolders });
      const questionText = getWidgetTitle({ questionTextArray });
      const updatedPresetQuestions = presetData.presetQuestions.filter((question) => question.questionId !== questionData.questionId);
      setPresetData({ ...presetData, presetQuestions: [...updatedPresetQuestions, { title: questionText, questionData, questionId: questionData.questionId }] });
    }
  }

  async function onCreateNewPreset() {
    setIsLoading(true);
    try {
      const reportIds = presetData.presetReports.map((report) => report.value);
      const questions = presetData.presetQuestions.map((question) => question.title);

      const response = await serverCommunication.serverRequest('PUT', 'onboarding/preset', JSON.stringify(
        {
          presetId: uuidv4(),
          presetConfig: {
            name: presetData.presetName,
            reportIds,
            questions,
          },
        }
      ));
      if (response.ok) {
        const responseData = await response.json();
        const presetConfig = Object.values(responseData)[0];
        const presetId = Object.keys(responseData)[0];

        const newPresetOptions = [...presetOptions, { label: presetConfig.name, value: presetId }];
        setPresetOptions(newPresetOptions);
        setSelectedPreset(presetId);

        const updatedOnboardingPresetConfigs = onboardingPresetConfigs || {};
        updatedOnboardingPresetConfigs[presetId] = presetConfig;
        updateUserAccountByKey({ key: 'onboardingPresetConfigs', value: updatedOnboardingPresetConfigs });
      }
    } catch (error) {
      servicesStore.logger.error('failed to set new onboarding preset', {
        error,
        UID,
      });
    }
    setIsCreateNewPresetPopup(false);
    setIsLoading(false);
  }

  function onChangePresetsName({ value }) {
    const isPresetsNameAlreadyExist = presetOptions.some((preset) => preset.label.toLowerCase() === value.toLowerCase());
    let isShowError = false;
    if (isPresetsNameAlreadyExist) {
      isShowError = true;
    }
    setPresetError({ ...presetError, isShow: isShowError });
    setPresetData({ ...presetData, presetName: value });
  }

  const isAllFieldsFilledForNewPreset = !presetData.presetName || !presetData.presetReports.length || !presetData.presetQuestions.length;

  return (
    <div className={styles.presetWrapper}>
      {isCreateNewPresetPopup ? (
        <>
          <div>
            <Label>Preset Name</Label>
            <Textfield
              placeHolder="Enter Preset Name..."
              onChange={(e) => onChangePresetsName({ value: e.target.value })}
              value={presetData.presetName}
              isShowError={presetError.isShow}
              inputErrorMessage={presetError.message}
            />
          </div>
          <div>
            <Label>Preset Reports</Label>
            <MultiCheckSelect
              selected={presetData.presetReports}
              options={reportsOptions}
              placeholder="Select Reports..."
              onChange={(selected) => setPresetData({ ...presetData, presetReports: selected })}
              isDropDownSelect
              isSearchIconPlaceholder={false}
              isDropdownIndicator
              selectAll
              className={styles.dropdownReports}
            />
          </div>
          <div className={styles.dropdownQuestionsWrapper}>
            <Label>Preset Business Questions</Label>
            <AskQuestion
              isShowHeaderTitle={false}
              shouldOpenResultsPopup={false}
              displayShowMoreSuggestionsButton={false}
              className={styles.dropdownQuestions}
              inputClassName={styles.dropdownQuestionsInput}
              questionResultsClassName={styles.dropdownQuestionsResults}
              onClickSuggestion={({ questionData, placeHoldersValues }) => onClickQuestionSuggestion({ questionData, placeHoldersValues })}
              selectedSuggestions={presetData.presetQuestions}
              isShowCheckboxes
              onUpdatePlaceHolderValue={({ placeHolders, questionData }) => onUpdatePlaceHolderValue({ placeHolders, questionData })}
            />

            <div className={styles.presetQuestionsTags}>
              {presetData.presetQuestions?.map((question) => (
                <FilterTag
                  key={`question-tag-${question.questionId}`}
                  label={question.title}
                  onRemove={() => onRemoveQuestion({ questionId: question.questionId })}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div>
          <Label>Choose from preset</Label>
          <Dropdown
            disabled={isPresetsLoading}
            onChange={(e) => setSelectedPreset(e.value)}
            options={presetOptions}
            selectedKey={selectedPreset}
            bottomButton={{
              label: '+ Create New',
              action: () => setIsCreateNewPresetPopup(true),
            }}
          />
        </div>
      )}

      <div className={styles.footerButtons}>
        <Button
          type="secondaryBlue"
          onClick={() => (
            isCreateNewPresetPopup ? setIsCreateNewPresetPopup(false) : onGoBack()
          )}
        >
          Back
        </Button>
        {(isLoading || isPresetsLoading) ? (
          <Spinner />
        ) : null}
        <Button
          onClick={() => (isCreateNewPresetPopup ? onCreateNewPreset() : inviteOrUpdatedMember())}
          disabled={isCreateNewPresetPopup ? isAllFieldsFilledForNewPreset : !selectedPreset}
          type="primaryBlue"
        >
          {isCreateNewPresetPopup ? 'Create preset' : isEditMode ? 'Update user' : 'Invite user'}
        </Button>
      </div>
    </div>
  );
}

export default inject(
  ({
    userStore: {
      updateUserAccountByKey,
      userAccount: {
        onboardingPresetConfigs,
      } = {},
      userMonthPlan: {
        UID,
      },
    },
    reportsStore: {
      foldersList,
      reportsList,
    },
  }) => ({
    foldersList,
    reportsList,
    UID,
    onboardingPresetConfigs,
    updateUserAccountByKey,
  }),
  observer
)(OnboardingAddPreset);
