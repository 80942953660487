export function getMetricAbbreviation({ metric }) {
  if (!metric) {
    return '';
  }

  const words = metric.trim().split(/\s+/);
  if (words.length <= 1) {
    return metric.toUpperCase();
  }

  return words
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
}

export function convertDays({ days }) {
  if (days >= 30) {
    const months = Math.floor(days / 30);
    return `${months} ${months === 1 ? 'Month' : 'Months'}`;
  } else if (days >= 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks} ${weeks === 1 ? 'Week' : 'Weeks'}`;
  } else {
    return `${days} ${days === 1 ? 'Day' : 'Days'}`;
  }
}
