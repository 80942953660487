import React from 'react';
import filterStyles from 'styles/users/filters.css';
import getChannelColor from 'components/utils/getChannelColor';
import { hexToRgb } from 'components/utils/colors';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';

const styles = filterStyles.locals;

export const FilterItemTag = ({
  channel, title, icon, onRemove, iconRenderer,
}) => (
  <div className={styles.filterItemTagWrapper}>
    <div
      className={styles.filterItemTag}
      style={{
        color: getChannelColor(channel),
        backgroundColor: `rgba(${hexToRgb(getChannelColor(channel)).join(',')}, 0.2)`,
      }}
    >
      {icon && <div className={styles.filterItemIcon} data-icon={icon} />}
      {iconRenderer && iconRenderer()}
      <EllipsisTooltip
        text={title}
        className={styles.filterItemTitle}
        TooltipProps={{ wide: true }}
      />
      <button className={styles.filterItemRemoveButton} onClick={onRemove} />
    </div>
  </div>
);
