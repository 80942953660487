import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import Select from 'components/controls/Select';
import style from 'styles/onboarding/onboarding.css';
import serverCommunication from 'data/serverCommunication';
import salesForceStyle from 'styles/indicators/salesforce-automatic-popup.css';
import Title from 'components/onboarding/Title';
import CRMStyle from 'styles/indicators/crm-popup.css';
import AuthorizationIntegrationPopup from 'components/common/AuthorizationIntegrationPopup';
import { isEmpty } from 'lodash';
import userStore from 'stores/userStore';

export default class CampaignsImportPopup extends Component {
  style = style;

  styles = [salesForceStyle, CRMStyle];

  static propTypes = {
    close: PropTypes.func,
    updateState: PropTypes.func,
    api: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.node,
    loadingStarted: PropTypes.func.isRequired,
    loadingFinished: PropTypes.func.isRequired,
    platformTitle: PropTypes.string.isRequired,
    multi: PropTypes.bool,
    accountIdPropertyName: PropTypes.string,
    accountLabelPropertyName: PropTypes.string,
    serverAccountIDPropertyName: PropTypes.string,
  };

  static defaultProps = {
    close: () => {},
    updateState: () => {},
    multi: false,
    accountIdPropertyName: 'id',
    accountLabelPropertyName: 'name',
    serverAccountIDPropertyName: 'accountId',
    message: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.authPopup = React.createRef();
  }

  getUserData = () => new Promise((resolve, reject) => {
    const objectToSend = {};
    objectToSend[this.props.serverAccountIDPropertyName] = this.state.selectedAccount;

    serverCommunication.serverRequest('put',
      this.props.api,
      JSON.stringify(objectToSend),
      userStore.userMonthPlan.region)
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((data) => {
              if (!isEmpty(data)) {
                this.props.updateState(data, undefined, true);
              }
              this.props.getUserMonthPlan(userStore.userMonthPlan.region);
              this.props.close();
              resolve(false);
            });
        } else if (response.status === 401) {
          history.push('/');
        } else {
          reject(new Error(`Error getting ${this.props.platformTitle} data`));
        }
      });
  });

  open = () => {
    this.authPopup.current.open();
  };

  afterDataRetrieved = (data) => new Promise((resolve) => {
    this.setState({ accounts: data });
    resolve(true);
  });

  handleChange(e) {
    if (Array.isArray(e)) {
      this.setState({ selectedAccount: e.map(({ value }) => value) });
    } else {
      this.setState({ selectedAccount: e.value });
    }
  }

  render() {
    const selects = {
      accounts: {
        select: {
          name: 'accounts',
          options: this.state.accounts
            .map((account) => {
              const accountId = account[this.props.accountIdPropertyName];
              return { value: accountId, label: `${account[this.props.accountLabelPropertyName]}(${accountId})` };
            }),
          multi: this.props.multi,
        },
      },
    };

    const isEmptyAccountList = this.state.accounts ? this.state.accounts.length === 0 : true;
    return (
      <AuthorizationIntegrationPopup
        ref={this.authPopup}
        api={this.props.api}
        afterDataRetrieved={this.afterDataRetrieved}
        makeServerRequest={this.getUserData}
        width="680px"
        innerClassName={salesForceStyle.locals.inner}
        contentClassName={salesForceStyle.locals.content}
        loadingStarted={this.props.loadingStarted}
        loadingFinished={this.props.loadingFinished}
        platformTitle={this.props.platformTitle}
        shouldDisableDoneButton={isEmptyAccountList}
      >
        <Title title={this.props.title} />
        <div className={this.classes.row}>
          <div className={salesForceStyle.locals.message}>
            {isEmptyAccountList ? this.props.message : null}
          </div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Select {...selects.accounts} selected={this.state.selectedAccount} onChange={this.handleChange} />
        </div>
      </AuthorizationIntegrationPopup>
    );
  }
}
