exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".carouselArrowNavigation__wrapper-smF{width:100%;position:relative}.carouselArrowNavigation__arrow-2Ce{all:unset;width:26px;height:26px;opacity:.3;transition:all .2s ease-in-out}.carouselArrowNavigation__arrowAbsolute-Ax4{position:absolute;top:calc(50% - 13px)}.carouselArrowNavigation__arrowsOnHover-xhf .carouselArrowNavigation__arrow-2Ce{opacity:0}.carouselArrowNavigation__arrowsOnHover-xhf:hover .carouselArrowNavigation__arrow-2Ce{opacity:.7}.carouselArrowNavigation__arrowsOnHover-xhf .carouselArrowNavigation__arrow-2Ce:hover{opacity:1}.carouselArrowNavigation__arrowLeft-1ak{background:url(/assets/arrow-left-grey.svg) 50% no-repeat;left:-40px}.carouselArrowNavigation__arrowRight-1rZ{background:url(/assets/arrow-right-grey.svg) 50% no-repeat;right:-40px}.carouselArrowNavigation__arrow-2Ce:hover{opacity:1;cursor:pointer}.carouselArrowNavigation__loaderWrapper-dXN{position:absolute;right:-40px;top:calc(50% - 16px)}.carouselArrowNavigation__arrowsBlock-2h-{display:flex;gap:8px;margin-right:6px}.carouselArrowNavigation__arrowsBlock-2h- .carouselArrowNavigation__arrow-2Ce{opacity:1}.carouselArrowNavigation__arrowsBlock-2h- .carouselArrowNavigation__arrow-2Ce:disabled{opacity:.5;cursor:default}.carouselArrowNavigation__arrowsBlock-2h- .carouselArrowNavigation__loadMoreArrow-2Mh{opacity:.5}.carouselArrowNavigation__arrowsBlock-2h- .carouselArrowNavigation__loadMoreArrow-2Mh:hover{opacity:1}.carouselArrowNavigation__arrowTooltip-1Uj{max-width:100px}.carouselArrowNavigation__tooltipPlaceholder-Are{height:26px;width:26px}", ""]);

// exports
exports.locals = {
	"wrapper": "carouselArrowNavigation__wrapper-smF",
	"wrapper": "carouselArrowNavigation__wrapper-smF",
	"arrow": "carouselArrowNavigation__arrow-2Ce",
	"arrow": "carouselArrowNavigation__arrow-2Ce",
	"arrowAbsolute": "carouselArrowNavigation__arrowAbsolute-Ax4",
	"arrowAbsolute": "carouselArrowNavigation__arrowAbsolute-Ax4",
	"arrowsOnHover": "carouselArrowNavigation__arrowsOnHover-xhf",
	"arrowsOnHover": "carouselArrowNavigation__arrowsOnHover-xhf",
	"arrowLeft": "carouselArrowNavigation__arrowLeft-1ak",
	"arrowLeft": "carouselArrowNavigation__arrowLeft-1ak",
	"arrowRight": "carouselArrowNavigation__arrowRight-1rZ",
	"arrowRight": "carouselArrowNavigation__arrowRight-1rZ",
	"loaderWrapper": "carouselArrowNavigation__loaderWrapper-dXN",
	"loaderWrapper": "carouselArrowNavigation__loaderWrapper-dXN",
	"arrowsBlock": "carouselArrowNavigation__arrowsBlock-2h-",
	"arrowsBlock": "carouselArrowNavigation__arrowsBlock-2h-",
	"loadMoreArrow": "carouselArrowNavigation__loadMoreArrow-2Mh",
	"loadMoreArrow": "carouselArrowNavigation__loadMoreArrow-2Mh",
	"arrowTooltip": "carouselArrowNavigation__arrowTooltip-1Uj",
	"arrowTooltip": "carouselArrowNavigation__arrowTooltip-1Uj",
	"tooltipPlaceholder": "carouselArrowNavigation__tooltipPlaceholder-Are",
	"tooltipPlaceholder": "carouselArrowNavigation__tooltipPlaceholder-Are"
};