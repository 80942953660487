exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".channel-icon__channel-icon-3dS{height:32px;width:32px;min-height:32px;min-width:32px;background-size:contain;background-position:50%;background-repeat:no-repeat;margin-right:10px;border-radius:4px;background-color:#edf3fe}", ""]);

// exports
exports.locals = {
	"channel-icon": "channel-icon__channel-icon-3dS",
	"channelIcon": "channel-icon__channel-icon-3dS"
};