exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".budget-channels__root-3o8{flex:1;padding:0;margin-left:-8px}.budget-channels__item-27d{width:100%;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;padding:8px 0}.budget-channels__title-2LL{padding-left:8px;font-size:16px;line-height:24px;font-weight:600;margin:4px 0;text-transform:capitalize}.budget-channels__cards-1uJ{display:flex;flex-wrap:wrap}.budget-channels__card-3wq{padding:8px}button.budget-channels__dashed-2X7{border-style:dashed}span.budget-channels__title-2LL{padding:0 4px;font-weight:600;font-size:16px;color:#707ea7}", ""]);

// exports
exports.locals = {
	"root": "budget-channels__root-3o8",
	"root": "budget-channels__root-3o8",
	"item": "budget-channels__item-27d",
	"item": "budget-channels__item-27d",
	"title": "budget-channels__title-2LL",
	"title": "budget-channels__title-2LL",
	"cards": "budget-channels__cards-1uJ",
	"cards": "budget-channels__cards-1uJ",
	"card": "budget-channels__card-3wq",
	"card": "budget-channels__card-3wq",
	"dashed": "budget-channels__dashed-2X7",
	"dashed": "budget-channels__dashed-2X7"
};