import React from 'react';
import classnames from 'classnames';
import Component from 'components/Component';
import style from 'styles/dashboard/navigate-funnel-view.css';
import CohortChart from 'components/pages/dashboard/CohortChart';
import MetricsItem from 'components/pages/dashboard/dashboardNavigate/MetricsItem';
import { formatBudget } from 'components/utils/budget';
import Tooltip from 'components/controls/Tooltip';
import { get, uniqueId } from 'lodash';
import FunnelViewPopup from 'components/pages/dashboard/dashboardNavigate/FunnelViewPopup';
import TopBar from 'components/pages/dashboard/dashboardNavigate/TopBar';
import WithHoverEffect from 'components/common/WithHoverEffect';
import { getReportSyncStatuses } from 'components/pages/dashboard/dashboardNavigate/logic/FunnelView';
import { inject, observer } from 'mobx-react';
import { compose } from 'components/utils/utils';

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
      dashboardStore,
    } = stores;
    const {
      funnels,
      userMonthPlan: {
        isPipelineStageHidden,
      },
    } = userStore;
    const {
      actualIndicators,
      funnelStagesToTheirGroupByType,
      isSimpleConversionRate,
      timeFrameValue,
      startDate,
      endDate,
    } = dashboardStore;
    return {
      funnels,
      actualIndicators,
      funnelStagesToTheirGroupByType,
      isSimpleConversionRate,
      timeFrameValue,
      startDate,
      endDate,
      isPipelineStageHidden,
    };
  }),
  observer
);

class FunnelView extends Component {
  style = style;

  static defaultProps = {
    titleText: 'Funnel View',
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  tooltip = (topString, string) => (
    `
      <div style="border-radius: 5px; display: flex; flex-direction: column; align-items: center; padding: 5px 0">
        <div style="font-size: 18px; color: #99a4c2; marginTop: '16px' margin-bottom: 7px">
          ${topString}
        </div>
        <span style="font-size: 16px; font-weight: normal; text-transform: none">
          ${string}
        </span>
      </div>
    `
  );

  togglePopup = () => this.setState((state) => ({
    visible: !state.visible,
  }));

  render() {
    const {
      pastMarketingPipeline,
      pastMarketingRevenue,
      currentTimeFrame,
      timeIndicator,
      funnelPopupData,
      titleText,
      isPipelineStageHidden,
    } = this.props;

    const totalPipeline = currentTimeFrame.newPipeline || 1;
    const { totalRevenue } = currentTimeFrame;
    const marketingPipeline = get(currentTimeFrame, 'marketingPipeline', 0);
    const marketingRevenue = get(currentTimeFrame, 'marketingRevenue', 0);
    const timeFrame = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      value: this.props.timeFrameValue,
    };

    return (
      <>
        <div className={this.classes.topBarFunnelViewTitle}>
          <TopBar tooltipProps={{ onClick: this.togglePopup }} titleText={titleText}>
            <FunnelViewPopup funnelPopupData={funnelPopupData} visible={this.state.visible} />
          </TopBar>
        </div>
        <div className={this.classes.row}>
          <CohortChart
            withVelocity
            textClass={this.classes.cohortText}
            funnels={this.props.funnels}
            actualIndicators={this.props.actualIndicators}
            funnelStagesToTheirGroupByType={this.props.funnelStagesToTheirGroupByType}
            isSimpleConversionRate={this.props.isSimpleConversionRate}
            timeFrame={timeFrame}
            isLoaded
            reportSyncStatuses={getReportSyncStatuses({ funnelPopupData: this.props.funnelPopupData })}
          />
          <div className={classnames(this.classes.contentColumn, this.classes.contentColumnRight)}>
            <MetricsItem
              formatter={(v) => formatBudget(v, true, true)}
              value={isPipelineStageHidden ? marketingRevenue : marketingPipeline}
              metricName={isPipelineStageHidden ? 'Marketing-Attributed Revenue' : 'Marketing-Attributed Pipeline'}
              pastValue={isPipelineStageHidden ? pastMarketingRevenue : pastMarketingPipeline}
              containerClass={this.classes.metricsItem}
              textClass={this.classes.metricsText}
              numberClass={this.classes.metricsNumber}
              metric
              growthRenderer={(growthValue) => (
                <Tooltip
                  id={uniqueId('chart-tooltip-')}
                  tip={this.tooltip('Compared to', `Previous ${timeIndicator}`)}
                  place="bottom"
                >
                  {growthValue}
                  %
                </Tooltip>
              )}
              big
            />
            <div className={this.classes.statDescription}>
              <Tooltip
                tip={this.tooltip(isPipelineStageHidden ? 'Total Revenue' : 'Total Pipeline', formatBudget(isPipelineStageHidden ? totalRevenue : totalPipeline, true, true))}
                id="navigate-funnel-view"
              >
                {isPipelineStageHidden ? Math.floor((marketingRevenue / totalRevenue) * 100) : Math.floor((marketingPipeline / totalPipeline) * 100)}
                % of total
                {isPipelineStageHidden ? ' Revenue' : ' Pipeline'}
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithHoverEffect(enhance(FunnelView));
