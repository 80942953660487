import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import Drawer from 'components/common/Drawer';
import Spinner from 'components/pages/journeys/Spinner';

import {
  buildingBlocksButtonsNameToTextMapping,
  journeyBuildingBlocksTitle,
  journeyInsightsTitle,
  unclickableBuildingBlocksToButtonValue,
} from 'components/widgets/optimalJourney/enums';

import iconX from 'assets/x.svg';
import iconXBlue from 'assets/x-blue.svg';
import lightBulbIcon from 'assets/analyze-icons/light-bulb-icon.svg';
import insightsEmptyStateImage from 'assets/empty-states/insights-list-empty-state.svg';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function SettingsRightPanelMenu({
  buildingBlocksOptions,
  selectedBuildingBlocksStatuses,
  setSelectedBuildingBlocksStatuses,
  insightsOptions,
  flags,
}) {
  useStyles([style]);

  function onClickBuildingBlockButton({ buttonName }) {
    for (const [unclickableBuildingBlocks, matchedButtonValue] of Object.entries(unclickableBuildingBlocksToButtonValue)) {
      if (matchedButtonValue === buttonName && flags.optimalJourneysUnclickableItems[unclickableBuildingBlocks]) {
        return;
      }
    }

    const isButtonCurrentlySelected = selectedBuildingBlocksStatuses[buttonName];
    const updatedSelectedBuildingBlock = { ...selectedBuildingBlocksStatuses };
    if (isButtonCurrentlySelected) {
      delete updatedSelectedBuildingBlock[buttonName];
    } else {
      updatedSelectedBuildingBlock[buttonName] = true;
    }
    setSelectedBuildingBlocksStatuses(updatedSelectedBuildingBlock);
  }

  const insightsBulletPoints = insightsOptions?.map((option) => (
    <div className={styles.optionItem}>
      <img alt="insight point" src={lightBulbIcon} />
      <div className={styles.optionText}>{option}</div>
    </div>
  ));

  const insightsEmptyState = (
    <div className={styles.emptyStateContainer}>
      <img src={insightsEmptyStateImage} alt="No insights" />
      <div>Select a building block to generate an insight.</div>
    </div>
  );

  return (
    <div className={styles.rightPanel}>
      <Drawer
        title={journeyBuildingBlocksTitle}
        contentMaxHeight="202px"
      >
        <div className={styles.buildingBlocksContainer}>
          {buildingBlocksOptions?.map((buttonName) => (
            <Button
              type="secondaryWhiteWithIcon"
              onClick={() => onClickBuildingBlockButton({ buttonName })}
              selected={selectedBuildingBlocksStatuses[buttonName]}
              icon={iconX}
              iconClassName={selectedBuildingBlocksStatuses[buttonName] ? styles.buttonIcon : styles.buttonIconRotated}
            >
              {buildingBlocksButtonsNameToTextMapping[buttonName]}
            </Button>
          ))}
          <Button
            type="secondaryBlue"
            icon={iconXBlue}
            iconClassName={styles.buttonIconRotated}
          >
            Add block
          </Button>
        </div>
      </Drawer>
      <div className={styles.separator} />
      <Drawer
        title={journeyInsightsTitle}
        contentMaxHeight="186px"
      >
        <div className={styles.journeyInsightsContainer}>
          {insightsOptions
            ? insightsOptions.length > 0
              ? insightsBulletPoints
              : insightsEmptyState
            : <Spinner />}
        </div>
      </Drawer>
    </div>
  );
}

export default withLDConsumer()(SettingsRightPanelMenu);
