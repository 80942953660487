import React, { useState } from 'react';
import FilterActions from 'components/pages/users/Filters/FilterActions';
import { filterKinds } from 'components/utils/filters/logic';
import filterStyles from 'styles/users/filters.css';
import CampaignsRules from './CampaignsRules/CampaignsRules';

import { checkAllFieldsAreFilledForSpecificPlatforms, addVariantToSelectedFilter } from './logic/CampaignCustomFieldsFilter';

const styles = filterStyles.locals;

const CampaignCustomFieldsFilter = ({
  onCancel, filtersItems, updateFiltersItems, activeFilter, config, addFilterToSelection, onAddFilter,
}) => {
  const [isDisableFilterActions, setIsDisableFilterActions] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);

  const handleFilterApply = () => {
    const selectedFilterWithVariant = addVariantToSelectedFilter(selectedFilter);
    addFilterToSelection(selectedFilterWithVariant, null, null, () => onAddFilter());
  };

  const updateFilter = (filterData) => {
    let filterObj = {
      kind: filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS,
      data: {
        platform: filterData.platform,
        rules: filterData.rules,
      },
    };
    const allFieldsAreFilled = checkAllFieldsAreFilledForSpecificPlatforms(filterData);
    setIsDisableFilterActions(!allFieldsAreFilled);
    const hasFilterItemWithTheSameKind = filtersItems.find(item => item.kind === filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS);
    if (allFieldsAreFilled) {
      const selectedFilterWithVariant = addVariantToSelectedFilter(filterObj);
      updateFiltersItems(selectedFilterWithVariant);
    } else if (hasFilterItemWithTheSameKind) {
      filterObj.data.rules = [];
      updateFiltersItems(filterObj);
    }
    setSelectedFilter(filterObj);
  };

  return (
    <div className={styles.filterConfig}>
      <div className={styles.filterContent}>
        <CampaignsRules
          rulesSubTitle="Add campaign rules:"
          platformSubTitle="Select platform to filter:"
          updateFilter={updateFilter}
          filtersConfig={config.options}
          activeFilter={activeFilter ? activeFilter.data : { platform: null, rules: [] }}
        />
      </div>
      <FilterActions
        onApply={handleFilterApply}
        onCancel={onCancel}
        filtersItems={filtersItems}
        disabled={isDisableFilterActions}
      />
    </div>
  );
};

export default CampaignCustomFieldsFilter;
