import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Component from 'components/Component';
import Dropdown from 'components/controls/Dropdown';
import Textfield from 'components/controls/Textfield';
import Tooltip from 'components/controls/Tooltip';
import ConditionTypeDropdown from 'components/pages/settings/customSegments/ConditionTypeDropdown';

import { operationOptions, operationTimeOptions } from 'components/utils/mappingRules';
import { CalendarWithTimePicker, RangeCalendarWithTime } from 'components/controls/CalendarWithTime';

import platformsStyle from 'styles/indicators/platforms.css';
import style from 'styles/settings/channels/channels-tab.css';

export default class MappingRule extends Component {
  style = style;

  styles = [platformsStyle];

  static propTypes = {
    updateParam: PropTypes.func,
    updateOperation: PropTypes.func,
    updateValue: PropTypes.func,
    handleDelete: PropTypes.func,
    handleAdd: PropTypes.func,
    onAddRuleGroup: PropTypes.func,
  };

  static defaultProps = {
    updateParam: () => {
    },
    updateOperation: () => {
    },
    updateValue: () => {
    },
    handleDelete: () => {
    },
    handleAdd: () => {
    },
    onAddRuleGroup: () => {
    },
  };

  resetValueFieldAndDate = async () => {
    const { updateValue } = this.props;
    await updateValue({ target: { value: '' } });
  };

  onUpdateParamHandler = async (e) => {
    const { updateParam, updateOperation } = this.props;
    const isTimeOprationSelected = e.value === 'startTime';
    if (isTimeOprationSelected) {
      await updateOperation({ value: 'between' });
    } else {
      await updateOperation({ value: 'equals' });
    }
    await updateParam(e);
    await this.resetValueFieldAndDate();
  };

  onUpdateOperationHandler = async (e) => {
    const { updateOperation } = this.props;
    await updateOperation(e);
    await this.resetValueFieldAndDate();
  };

  render() {
    const {
      param, operation, value, isLastAddButton, disabledDelete = false,
      updateValue, handleDelete, handleAdd, onAddRuleGroup, paramsOptions, disabled, options, isMappingDisabled = false,
      valueOptions, error, textFieldProps = {}, isConditionTypeAsDropdown,
    } = this.props;

    const paramNotSelected = !param;
    const paramTooltipOption = paramsOptions.find((option) => option.value === param);
    const paramTooltipLabel = _.get(paramTooltipOption, ['label'], '');
    const isTimeParam = param === 'startTime';
    const operationsOptions = options || isTimeParam ? operationTimeOptions : operationOptions;
    const isTimeEquals = operation === 'equals';
    const isTimeIsBetween = operation === 'between';
    const valueIsNotRelevantToOperation = ['empty', 'not_empty'].includes(operation);

    return (
      <div className={classnames(this.classes.mappingRule, error && this.classes.error)}>
        {!disabled && !disabledDelete && (
          <div
            className={this.classes.minusIcon}
            onClick={handleDelete}
          />
        )}
        <div className={this.classes.mappingRuleInput}>
          <Tooltip
            hidden={paramTooltipLabel.length < 30}
            place="top"
            tip={paramTooltipLabel}
            id={`toolttip-${paramTooltipLabel}`}
          >
            <Dropdown
              options={paramsOptions}
              selectedKey={param}
              onChange={this.onUpdateParamHandler}
              disabled={disabled}
              isSearchable
            />
          </Tooltip>
        </div>
        <div className={this.classes.mappingRuleInput}>
          <Dropdown
            options={operationsOptions}
            selectedKey={operation}
            onChange={this.onUpdateOperationHandler}
            disabled={disabled}
            isSearchable
          />
        </div>
        {isTimeParam && isTimeEquals
          && (
            <div className={classnames(this.classes.mappingRuleInput, this.classes.mappingRuleSelectDate)}>
              <CalendarWithTimePicker
                onUpdateDate={updateValue}
                value={value}
              />
            </div>
          )}
        {isTimeParam && isTimeIsBetween
          && (
            <div className={classnames(this.classes.mappingRuleInput, this.classes.mappingRuleSelectDate)}>
              <RangeCalendarWithTime
                onUpdateDate={updateValue}
                value={value}
              />
            </div>
          )}

        {valueOptions
          && (
            <div className={this.classes.mappingRuleInput}>
              <Dropdown
                options={valueOptions}
                selectedKey={value}
                onChange={updateValue}
                disabled={disabled || paramNotSelected}
                allowCreateNewOption
                onNewOptionClick={(e) => {
                  if (param) {
                    this.props.onNewOptionClick(e.value, param);
                  }
                }}
                isSearchable
              />
            </div>
          )}
        {!valueOptions && !isTimeParam
          && (
            <div className={this.classes.mappingRuleInput}>
              <Textfield
                value={value}
                onChange={updateValue}
                disabled={valueIsNotRelevantToOperation || disabled}
                {...textFieldProps}
              />
            </div>
          )}

        {disabled && !isMappingDisabled ? (
          <div className={this.classes.ruleIcon} data-icon="platform:salesforce" />
        ) : (
          <>
            {isConditionTypeAsDropdown ? (
              <ConditionTypeDropdown
                onAddRule={handleAdd}
                onAddRuleGroup={onAddRuleGroup}
                shouldEnableNewRule={!isLastAddButton}
              />
            ) : (
              <>
                {isLastAddButton ? (
                  <div className={this.classes.plusAddButtonDisabled}>
                    AND
                  </div>
                ) : null}
                {!isLastAddButton && !isMappingDisabled ? (
                  <div className={this.classes.plusAddButton} onClick={handleAdd}>
                    <span className={this.classes.plusIconAdd}>+</span>
                    {' Add'}
                  </div>
                ) : null}
                {isMappingDisabled && !isLastAddButton ? <div className={this.classes.disabledAddButton} /> : null}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
