import React from 'react';

import { widgetTypes } from 'components/pages/analyze/enums';

export function getAIPropsByType({ type }) {
  if (type === widgetTypes.segmentsAnalysis) {
    const data = getAttributionTableAIAnalysisMockData();
    const maxHeight = '516px';
    return { data, maxHeight };
  }

  if (type === widgetTypes.journeyPopup) {
    const data = getJourneyPopupAIAnalysisMockData();
    const sizes = { width: '320px', height: '80vh' };
    const position = { top: '-26px', left: '55px' };
    return { data, sizes, position };
  }

  if (type === widgetTypes.goalsAnalysis) {
    const hidden = true;
    return { hidden };
  }

  return {};
}

function getJourneyPopupAIAnalysisMockData() {
  return (
    <>
      <h3>The story:</h3>
      <p>JFrog journey started in October 2023, when Oprah Winfrey (our AE) reached out to Matt (JFrog’s CFO).</p>
      <p>In December 2023, Ashley (JFrog’s Head of Finance) was exposed to a Google Ad, had 2 following sessions on our website, which resulted in a demo request.</p>
      <p>Ashley, Matt and John attended “NMKI - mega event 2022” on March 14, 2024, followed by an “Email - Sales” and a sales meeting after 6 days, which led to an Opp.</p>
      <p>2 weeks after JFrog converted into Closed Won, with a deal size of $30K.</p>
      <br />
      <h3>Insights:</h3>
      <ul>
        <li>
          <i>10 Total touchpoints</i>
          {' (First Touch to Closed Won) across '}
          <i>5 Months</i>
          {' (4 months from Lead to Closed Won). '}
        </li>
        <ul>
          <li>This is a quick sales cycle, vs avg. of 6 months, but involved 2x more touchpoints vs avg.</li>
        </ul>
        <li>
          {'Although the first touchpoint was '}
          <i>Sales</i>
          {' outreach, it seems that the'}
          <i>Google Ads</i>
          {' campaign was meaningful in getting the first demo to happen. '}
        </li>
        <li>
          <i>Marketing</i>
          {' initiatives involved in key moments across the entire journey, from creating the lead to opening the opportunity. Good job!'}
        </li>
        <li>
          {'Most active contact was '}
          <i>Ashley</i>
          {', JFrog’s '}
          <i>Head of Finance</i>
          {' . That’s the typical champion’s title in larger deals (vs CFO).'}
        </li>
        <li>
          <i>3 stakeholders</i>
          {' were involved as part of the process vs an avg. of 2 in similar deals.'}
        </li>
        <li>
          {'Deal Owner - '}
          <i>Oprah Winfrey</i>
        </li>
      </ul>

    </>
  );
}

function getAttributionTableAIAnalysisMockData() {
  return (
    <>
      <h3>Key Optimization Opportunities:</h3>
      <ul>
        <li>
          <i>Google Ads</i>
          {' is your top channel in driving Opps efficiently - it drives a '}
          <b>high amount of Opps</b>
          {' with '}
          <b>great Cost per Opp and Pipeline ROI.</b>
        </li>
        <ul>
          <li>
            <i>Google Ads</i>
            {' performs well in the '}
            <i>US</i>
            {' (3x vs avg.) and performs poorly in '}
            <i>Europe</i>
            {' (0.7x vs avg.).'}
          </li>
        </ul>
        <li>
          <i>Events</i>
          {' bring a '}
          <b>high # of leads</b>
          {' based on SFDC lead source Attribution, but most are of low quality and '}
          <b>not converting to Opps.</b>
        </li>
        <ul>
          <li>
            {'Worth cutting '}
            <i>Events</i>
            {' budget and re-allocating it to one of the opportunities shown here, depending on your focus KPI.'}
          </li>
        </ul>
        <li>
          <i>LinkedIn - Paid</i>
          {' drives Opps with a '}
          <b>high cost per Opp</b>
          , but the Opps sourced by it convert to Closed Wons the fastest (1.6x faster vs avg.).
        </li>
        <ul>
          <li>
            <i>LinkedIn - Paid</i>
            {' performs well especially when targeting '}
            <br />
            <i>Top Finance Leadership</i>
            {' titles, with '}
            <b>Opp to Closed Wons CR</b>
            {' 2x than avg.'}
          </li>
        </ul>
      </ul>

    </>
  );
}
