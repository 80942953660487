import React from 'react';
import get from 'lodash/get';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import FilterActions from 'components/pages/users/Filters/FilterActions';
import Textfield from 'components/controls/Textfield';
import { COMPARISON_OPERATORS } from 'components/utils/filters';
import { EQUALS, filterKinds } from 'components/utils/filters/logic';
import { getVelocityMapping } from 'components/utils/indicators';
import { extractNumberFromBudget } from 'components/utils/budget';
import Dropdown from 'components/controls/Dropdown';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

export default class VelocityFilter extends Component {
  constructor(props) {
    super(props);
    const { activeFilter } = props;
    this.state = {
      comparisonOperator: EQUALS,
      velocityValue: 0,
      velocityType: 'funnel1ToFunnel2Velocity',
    };

    if (activeFilter) {
      const { data } = activeFilter;
      const [velocityType] = get(data, 'selectedOptions', []);
      this.state = {
        ...this.state,
        ...data,
        velocityType,
      };
    }
  }

  handleFilterApply = () => {
    const {
      velocityType, velocityValue, comparisonOperator,
    } = this.state;
    this.props.addFilterToSelection({
      kind: filterKinds.VELOCITY,
      data: {
        selectedOptions: [velocityType],
        velocityValue,
        comparisonOperator,
      },
    }, null, null, () => this.props.onAddFilter());
  };

  updateFilter = () => {
    const {
      velocityType, comparisonOperator, velocityValue,
    } = this.state;

    const filterObj = {
      kind: filterKinds.VELOCITY,
      data: {
        selectedOptions: [velocityType],
        velocityValue,
        comparisonOperator,
      },
    };
    this.props.updateFiltersItems(filterObj);
  };

  setVelocityType = ({ value }) => {
    this.setState({
      velocityType: value,
    },
    this.updateFilter);
  };

  setComparisonOperator = ({ value }) => {
    this.setState({
      comparisonOperator: value,
    },
    this.updateFilter);
  };

  setNewVelocity = ({ target }) => {
    const newVelocity = extractNumberFromBudget(target.value);
    this.setState({ velocityValue: newVelocity },
      this.updateFilter);
  };

  render() {
    const { onCancel } = this.props;
    const { velocityType, comparisonOperator, velocityValue } = this.state;
    const velocityLookup = getVelocityMapping();
    const velocityOptions = Object.keys(velocityLookup).reduce((acc, key) => {
      const [velocityIndicator] = get(velocityLookup, [key], []);
      if (velocityIndicator) {
        acc.push(velocityIndicator);
      }
      return acc;
    }, []);

    const mappedOptions = velocityOptions.map((key) => ({ label: userStore.getMetricNickname({ metric: key, isSingular: true }), value: key }));
    return (
      <div className={styles.filterConfig}>
        <div className={styles.filterContent}>
          <header className={styles.filterHeader}>Find journeys which</header>
          <div style={{ marginBottom: 10 }} className={styles.filterVariantDesc}>
            Select velocity type:
          </div>
          <div className={styles.filterVariant}>
            <Dropdown
              onChange={this.setVelocityType}
              options={mappedOptions}
              selectedKey={velocityType}
              controlWidth={210}
              isSearchable
            />
          </div>
          {velocityType && (
            <>
              <div style={{ marginBottom: 10 }}>
                Select condition:
              </div>
              <div className={styles.rowCenter}>
                <Dropdown
                  onChange={this.setComparisonOperator}
                  options={Object.entries(COMPARISON_OPERATORS).map(([value, label]) => ({ value, label }))}
                  selectedKey={comparisonOperator}
                  controlWidth={210}
                />
                <Textfield
                  value={velocityValue}
                  style={{ marginLeft: 25, width: 72 }}
                  onChange={this.setNewVelocity}
                />
                <div style={{ marginLeft: 15 }}>
                  {`day${velocityValue === 1 ? '' : 's'}`}
                </div>
              </div>
            </>
          )}
        </div>
        <FilterActions
          onApply={this.handleFilterApply}
          onCancel={onCancel}
          filtersItems={this.props.filtersItems}
        />
      </div>
    );
  }
}
