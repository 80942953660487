import React, { useEffect } from 'react';

import useStyles from 'hooks/useStyles';
import AskQuestion from 'components/pages/questions/AskQuestion';

import { getQueryParams } from 'components/utils/UrlParamsProvider';

import style from 'styles/questions/questions.css';
import { inject, observer } from 'mobx-react';

const styles = style.locals || {};

function Questions({ setQuestionText }) {
  useStyles([style]);

  useEffect(() => {
    const questionText = getQueryParams({ queryParamKey: 'questionText' });
    if (questionText) {
      setQuestionText({ newQuestionText: questionText });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.askQuestionWrapper}>
        <AskQuestion />
      </div>
    </div>
  );
}

export default inject(({
  questionsStore: {
    setQuestionText,
  },
}) => ({
  setQuestionText,
}),
observer)(Questions);
