exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".progress__box-1Pu{background:#273142;border-radius:4px;width:270px;height:300px;padding:20px 18px;box-sizing:border-box}.progress__line-box-1KM{height:18px;width:100%;position:relative}.progress__line-empty-3WK{width:100%;background:#e2e7ee}.progress__line-empty-3WK,.progress__line-filled-6z-{margin-top:6px;height:6px;position:absolute;top:0;left:0}.progress__line-filled-6z-{width:0;background:#3aa3f2}.progress__dot-empty-2uz{background:#e2e7ee}.progress__dot-empty-2uz,.progress__dot-filled-2RG{position:absolute;height:18px;width:18px;border-radius:50%}.progress__dot-filled-2RG{background:#3aa3f2;top:0}.progress__image-BxC{width:230px;height:160px;background:#fff bottom no-repeat;background-size:contain;border-radius:4px;margin-top:20px;overflow:hidden}.progress__text-1fJ{font-size:16px;line-height:26px;color:#fff;text-align:center;height:80px;display:flex;align-items:center;align:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"box": "progress__box-1Pu",
	"box": "progress__box-1Pu",
	"line-box": "progress__line-box-1KM",
	"lineBox": "progress__line-box-1KM",
	"line-empty": "progress__line-empty-3WK",
	"lineEmpty": "progress__line-empty-3WK",
	"line-filled": "progress__line-filled-6z-",
	"lineFilled": "progress__line-filled-6z-",
	"dot-empty": "progress__dot-empty-2uz",
	"dotEmpty": "progress__dot-empty-2uz",
	"dot-filled": "progress__dot-filled-2RG",
	"dotFilled": "progress__dot-filled-2RG",
	"image": "progress__image-BxC",
	"image": "progress__image-BxC",
	"text": "progress__text-1fJ",
	"text": "progress__text-1fJ"
};