import React from 'react';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import classNames from 'classnames';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';

import { formatBudget, formatNumber, sign } from 'components/utils/budget';
import EditableBudgetCell from 'components/pages/plan/EditableBudgetCell';
import NumberWithArrow from 'components/NumberWithArrow';
import CellWrapper from 'components/pages/plan/CellWrapper';
import CampaignTitle from 'components/pages/plan/CampaignTitle';
import { amountTypes, generalCampaignName } from 'stores/plan/logic/enums';
import { compose } from 'components/utils/utils';
import { TIME_FRAME } from 'components/utils/timeframe';
import { viewStyleAccordingDataType, planVsActualColumns } from 'components/pages/plan/logic/enums';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const enhance = compose(
  inject((stores) => {
    const {
      planStore,
    } = stores;
    const {
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      updateCampaignAmount,
      updateCampaignProperties,
    } = planStore;
    return {
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      updateCampaignAmount,
      updateCampaignProperties,
    };
  }),
  observer
);

class SubTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      deletePopup: '',
    };
  }

  onCampaignClick = (campaign, channel) => {
    if (campaign.mocked) {
      return;
    }
    this.props.showPopup(campaign, channel);
  };

  getTableColumns() {
    const {
      columns, classes, viewStyle, startDate, endDate, activeColumns, row, isCurrentMonth, timeFrame,
      setEditingChannel, onEditEnd, updateCampaignProperties, updateCampaignAmount,
      sendUpdateCampaignsAmountRequestToServer, sendUpdateCampaignsPropertiesRequestToServer,
    } = this.props;
    const { channel: currentChannelKey, isAutomatic } = row.original;
    const { deletePopup } = this.state;

    const columnsData = columns.map(({ column, indicator }, index) => ({
      Header: undefined,
      accessor: String(index),
      Cell: (cell) => {
        const disabled = viewStyle !== viewStyleAccordingDataType.CAMPAIGN;
        const isSpending = indicator === 'spending';
        const format = isSpending ? formatBudget : (v) => formatNumber(v, true);
        const className = classNames(classes.subCell, {
          [classes.subCellActive]: !disabled,
        });
        let cellData;
        const {
          planned = 0, actual = 0, planVsActual = 0, pacing = 0, estimatedActual,
        } = get(cell.original, indicator, {});
        const campaignName = cell.original.campaign;
        const campaignChannel = row.original.channel;
        const campaignRegion = row.original.region;
        const { id } = cell.original;

        switch (column) {
          case planVsActualColumns.planned.value:
            cellData = isSpending ? (
              <CellWrapper>
                <EditableBudgetCell
                  value={planned}
                  formatter={formatBudget}
                  dontUpdateCell
                  save={async (budgetAmount) => {
                    updateCampaignAmount({
                      campaignName,
                      amount: budgetAmount,
                      amountType: amountTypes.budget,
                      channel: campaignChannel,
                      region: campaignRegion,
                      id,
                      startDate,
                      endDate,
                    });
                    updateCampaignProperties({
                      name: campaignName,
                      channel: campaignChannel,
                      sources: [campaignChannel],
                      startDate,
                      endDate,
                      status: 'In Progress',
                      id,
                      region: campaignRegion,
                    });
                    await Promise.all([
                      sendUpdateCampaignsAmountRequestToServer({ startDate, endDate }),
                      sendUpdateCampaignsPropertiesRequestToServer({ startDate, endDate }),
                    ]);
                  }}
                  disabled={campaignName === generalCampaignName || timeFrame !== TIME_FRAME.MONTH}
                  onEdit={() => setEditingChannel(currentChannelKey)}
                  onEditEnd={() => onEditEnd(currentChannelKey)}
                />
              </CellWrapper>
            ) : planned;
            break;
          case planVsActualColumns.actual.value:
            cellData = isSpending ? (
              <CellWrapper showToolTip={estimatedActual}>
                <EditableBudgetCell
                  value={actual}
                  formatter={formatBudget}
                  dontUpdateCell
                  save={async (costAmount) => {
                    updateCampaignAmount({
                      campaignName,
                      amount: costAmount,
                      amountType: amountTypes.actualSpent,
                      channel: campaignChannel,
                      region: campaignRegion,
                      startDate,
                      endDate,
                      id,
                    });
                    await sendUpdateCampaignsAmountRequestToServer({ startDate, endDate });
                  }}
                  disabled={isAutomatic || campaignName === generalCampaignName || timeFrame !== TIME_FRAME.MONTH}
                  onEdit={() => setEditingChannel(currentChannelKey)}
                  onEditEnd={() => onEditEnd(currentChannelKey)}
                />
              </CellWrapper>
            ) : formatNumber(actual, true);
            break;
          case planVsActualColumns.planVsActual.value:
            cellData = `${sign(planVsActual)}${formatNumber(planVsActual, true)}`;
            break;
          case planVsActualColumns.pacingFor.value:
            if (!isCurrentMonth) {
              cellData = '-';
            } else {
              const diff = pacing !== 0 ? pacing - planned : 0;
              cellData = diff === 0 ? pacing : (
                <div style={{ display: 'flex' }}>
                  {format(pacing)}
                  <div style={{ marginLeft: 10 }}>
                    <NumberWithArrow
                      stat={diff > 0 ? `+${format(diff)}` : format(diff)}
                      isNegative={diff <= 0}
                      arrowStyle={{ display: 'none' }}
                      statStyle={{ alignSelf: 'center', fontWeight: '500', paddingTop: 1 }}
                    />
                  </div>
                </div>
              );
            }
            break;
          default:
            cellData = 0;
            break;
        }
        return (
          <div className={className}>
            {(isNaN(cellData) || !isSpending) ? cellData : formatBudget(cellData)}
          </div>
        );
      },
      headerStyle: {
        display: 'none',
      },
      getProps: () => ((index % activeColumns.length === 0) ? { className: classes.separator } : {}),
    }));

    columnsData.unshift({
      width: 300,
      accessor: 'campaign',
      Cell: (cellData) => (
        <CampaignTitle
          cellValue={cellData.value}
          onClick={() => this.onCampaignClick(cellData.original, row.original)}
          style={{ paddingLeft: 50 }}
          campaignTags={get(cellData, 'original.tags', [])}
        />
      ),
      headerStyle: {
        display: 'none',
      },
      getProps: (_, rowData = {}) => {
        const { groupedByPivot, original = {} } = rowData;
        const { channel } = original;

        return {
          className: classNames(classes.cellDefault, {
            [classes.cellCategoryWrapper]: groupedByPivot,
          }),
          style: {
            zIndex: channel === deletePopup && 2,
          },
        };
      },
      fixed: 'left',
    });

    return columnsData;
  }

  render() {
    const { children: data = [] } = this.props.row.original;
    return (
      <ReactTableFixedColumns
        header
        columns={this.getTableColumns()}
        data={data}
        pageSize={data.length}
        getTdProps={this.getTdProps}
        getTrProps={this.getTrProps}
        onExpandedChange={this.handleExpand}
        resizable={false}
        showPageSizeOptions={false}
        showPagination={false}
        sortable={false}
        NoDataComponent={() => null}
        style={{ border: 'none' }}
        {...this.props.getDecorators()}
      />
    );
  }
}

export default enhance(SubTable);
