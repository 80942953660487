import { getFilterLogicDefaultValue } from 'components/pages/integrations/logic/FilterLogicExpressionPopup';

export function getUpdatedPredefinedFilterForStage({ predefinedForStage, selectedNewFilters, updateFilterId }) {
  const updatedPredefinedForStage = { ...predefinedForStage };
  const filtersForStage = updatedPredefinedForStage.filters || [];

  if (selectedNewFilters.length === 0) {
    return updatedPredefinedForStage;
  }

  const filterIndex = filtersForStage.findIndex((f) => f.id === updateFilterId);
  if (filtersForStage.length > 0 && filterIndex !== -1) {
    const selectedNewFilterFirstItem = selectedNewFilters[0];
    filtersForStage[filterIndex] = selectedNewFilterFirstItem;
    updatedPredefinedForStage.filters = filtersForStage;
    return updatedPredefinedForStage;
  }

  const newFilterLogicExpression = getFilterLogicDefaultValue({ filtersCount: selectedNewFilters.length, startIndex: filtersForStage.length + 1 });
  let updateFilterLogicExpression = '';
  if (filtersForStage?.length > 1) {
    updateFilterLogicExpression = updatedPredefinedForStage.logicExpression;
    const logicExpressionParts = updateFilterLogicExpression.split(/\s+/);
    const lastFilterNumberInExpression = logicExpressionParts.map((item) => !isNaN(item.replaceAll(')', ''))).lastIndexOf(true);
    const isLastOperatorIsAnd = logicExpressionParts[lastFilterNumberInExpression - 1].toUpperCase() === 'AND';
    const isAndExpressionIsLast = lastFilterNumberInExpression > 0 && isLastOperatorIsAnd;

    if (updateFilterLogicExpression.includes('OR') && !isAndExpressionIsLast) {
      updateFilterLogicExpression = `(${updatedPredefinedForStage.logicExpression}) AND ${newFilterLogicExpression}`;
    } else {
      updateFilterLogicExpression = `${updatedPredefinedForStage.logicExpression} AND ${newFilterLogicExpression}`;
    }
  } else if (filtersForStage?.length === 1) {
    updateFilterLogicExpression = `1 AND ${newFilterLogicExpression}`;
  } else {
    updateFilterLogicExpression = newFilterLogicExpression;
  }

  updatedPredefinedForStage.filters = [...filtersForStage, ...selectedNewFilters];
  updatedPredefinedForStage.logicExpression = updateFilterLogicExpression.toUpperCase();
  return updatedPredefinedForStage;
}

export function getRemovedPredefinedFilterForStage({ predefinedForStage, filterId }) {
  const updatedRemovedForStage = { ...predefinedForStage };
  const filtersForStage = updatedRemovedForStage.filters?.filter((f) => f.id !== filterId);
  updatedRemovedForStage.filters = filtersForStage;
  const isLogicExpressionUpdatedNeeded = filtersForStage.length > 1;
  if (isLogicExpressionUpdatedNeeded) {
    const filterLogicValue = getFilterLogicDefaultValue({ filtersCount: updatedRemovedForStage.filters.length });
    updatedRemovedForStage.logicExpression = filterLogicValue.toUpperCase();
  } else {
    updatedRemovedForStage.logicExpression = '';
  }
  return updatedRemovedForStage;
}
