import React from 'react';
import BluredWidget from 'components/common/BluredWidget';

export default function FeatureFlagsWithBlur({ flag, name, children }) {
  if (flag === 'show') {
    return children;
  }

  if (flag === 'blur') {
    return (
      <BluredWidget flagName={name} />
    );
  }

  return null;
}
