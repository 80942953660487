import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import style from 'styles/analyze/conversion-journey.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import Journey from 'components/pages/analyze/Journey';
import Toggle from 'components/controls/Toggle';

const OPTIONS = {
  CONTENT_TYPE: 'contentType',
  CONTENT: 'content',
};

export default class ContentJourney extends Component {
  style = style;

  styles = [analyzeStyle];

  constructor(props) {
    super(props);
    this.state = {
      toggleViewType: props.widgetConfig?.contentJourneyViewType || OPTIONS.CONTENT,
    };
  }

  static propTypes = {
    conversionIndicator: PropTypes.string.isRequired,
    isCategoryView: PropTypes.bool.isRequired,
  };

  get isContentTypeView() {
    return this.state.toggleViewType === OPTIONS.CONTENT_TYPE;
  }

  groupedJourneys = (journeysData = []) => {
    const groupedByContentType = journeysData.reduce((acc, journey) => {
      const { items, count } = journey;
      const [channel, content] = items;
      const key = `${channel.nickname}-$${content.contentChannel}`;
      if (!(key in acc)) {
        acc[key] = {
          count,
          items: [channel, {
            ...content,
            uniqTitle: content.contentChannel,
          }],
        };
      } else {
        acc[key].count += count;
      }
      return acc;
    }, {});

    return Object.values(groupedByContentType).sort((a, b) => b.count - a.count);
  };

  render() {
    const {
      conversionIndicator, isCategoryView, journeysData, journeysSum, widgetConfig,
    } = this.props;
    return (
      <Journey
        journeysSum={journeysSum}
        conversionIndicator={conversionIndicator}
        journeysData={this.isContentTypeView ? this.groupedJourneys(journeysData) : journeysData}
        isCategoryView={isCategoryView}
        widgetConfig={widgetConfig}
        toggleViewType={this.state.toggleViewType}
        header={() => (
          <div className={this.classes.headerRow}>
            <div>
              {`${isCategoryView ? 'category' : 'channel'} to`}
            </div>
            <div className={this.classes.toggleWrapper}>
              <Toggle
                options={[{
                  text: 'Content',
                  value: OPTIONS.CONTENT,
                }, {
                  text: 'Content Type',
                  value: OPTIONS.CONTENT_TYPE,
                }]}
                onClick={(toggleViewType) => this.setState({ toggleViewType })}
                selectedValue={this.state.toggleViewType}
              />
            </div>
          </div>
        )}
      />
    );
  }
}
