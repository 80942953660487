import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/delete-popup.css';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';

export default class DeletePopup extends Component {
    style = style;

    render() {
      const {
        title,
        deleteName,
        onCloseClick,
      } = this.props;
      return (

        <div className={this.classes.overlay}>
          <div className={this.classes.popup}>
            <div className={this.classes.closeIcon} onClick={onCloseClick} />
            <div className={this.classes.popupWrap}>
              <h3 className={this.classes.popupTitle}>
                {title}
                <br />
                <span>{deleteName}</span>
                ?
              </h3>
              <div className={this.classes.popupBtnsContainer}>
                <SetupBudgetButton
                  name="Cancel"
                  stylesWrap={this.classes.popupButtonWrap}
                  stylesBtn={this.classes.popupButtonCancel}
                  onClick={onCloseClick}
                />
                <SetupBudgetButton
                  name="Delete"
                  stylesWrap={this.classes.popupButtonWrap}
                  stylesBtn={this.classes.popupButtonDelete}
                  onClick={onCloseClick}
                />
              </div>
            </div>
          </div>
        </div>

      );
    }
}
