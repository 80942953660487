import React from 'react';
import moment from 'moment';

import Component from 'components/Component';
import PerformanceItem from 'components/pages/analyze/OverviewTab/PerformanceItem';
import PerformanceChart from 'components/pages/analyze/OverviewTab/PerformanceChart';
import ChartSkeleton from 'components/common/ChartSkeleton';

import style from 'styles/analyze/historical-performance-item.css';

export default class HistoricalPerformanceItem extends Component {
  style = style;

  render() {
    const {
      activeJourney = {},
      isLoaded,
      isCompareToPreviousEnabled,
    } = this.props;
    const { performanceData = [] } = activeJourney;

    return (
      <div className={this.classes.itemGrid}>
        <div>
          <PerformanceItem
            isLoaded={isLoaded}
            data={activeJourney}
            mode="large"
            showInfo
            withoutHover
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            indicator={activeJourney.indicator}
          />
        </div>
        <div>
          {isLoaded ? (
            <PerformanceChart
              performanceData={performanceData.slice().sort((a, b) => moment(a.timePeriod) - moment(b.timePeriod))}
              indicator={activeJourney.indicator}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            />
          ) : (
            <ChartSkeleton chartHeight={280} chartWidth="90%" />
          )}
        </div>
      </div>
    );
  }
}
