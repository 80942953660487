import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';

import Tooltip from 'components/controls/Tooltip';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';
import Skeleton from 'components/common/Skeleton';

import { skeletonSmallTextSizes } from 'components/common/enums';

import style from 'styles/dashboard/dashboard.css';

const styles = style.locals;

export default function CohortChartLink({
  getNavigationMenuParams, indicator, textClass, isLoaded, isOutOfSync,
}) {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);

  if (!isLoaded) {
    return (
      <div key={`indicator-${indicator}`} className={classnames(styles.miniFunnelRow, styles.skeletonMiniFunnelRow)}>
        <Skeleton {...skeletonSmallTextSizes} isLightTheme />
      </div>
    );
  }

  return (
    <div key={`indicator-${indicator}`} className={classnames(styles.miniFunnelRow)}>
      <div
        className={styles.wrapperTitle}
        onClick={() => setShowNavigationMenuPopup(true)}
      >
        <div className={classnames(textClass, showNavigationMenuPopup && styles.popupIsOpen, styles.indicatorName)}>
          {`New ${userStore.getMetricNickname({ metric: indicator })}`}
          {isOutOfSync ? (
            <Tooltip id="outOfSync" tip="Funnel report is out of sync.">
              <div className={styles.reportSyncIcon} data-icon="platform:reportSyncError" />
            </Tooltip>
          ) : null}
        </div>
        <span className={styles.funnelIcon} />
        {showNavigationMenuPopup && (
        <div className={styles.navigationMenu}>
          <NavigationMenuPopup
            navigationOptions={getNavigationMenuParams(indicator)}
            onClosePopup={(showNavigationPopup) => setShowNavigationMenuPopup(showNavigationPopup)}
          />
        </div>
        )}
      </div>
    </div>
  );
}
