import React, { useEffect } from 'react';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';

import analysisStore from 'stores/analysisStore';

function LDIdentify({ userAccount }) {
  const ldClient = useLDClient();
  const LDFlags = useFlags();

  analysisStore.LDFlags = LDFlags;

  useEffect(() => {
    if (userAccount && ldClient) {
      const currentUser = {
        key: userAccount.UID,
        name: userAccount.companyName,
      };
      ldClient.identify(currentUser);
    }
  }, [userAccount, ldClient]);

  return null;
}
export default LDIdentify;
