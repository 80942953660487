import React, { useEffect } from 'react';
import classNames from 'classnames';

import textAreaStyles from 'styles/controls/textArea.css';

const styles = textAreaStyles.locals;

function TextArea({
  className,
  handleKeyDown,
  onChange,
  value,
  errorMessage,
}) {
  useEffect(() => {
    textAreaStyles.use();
    return () => {
      textAreaStyles.unuse();
    };
  }, []);

  return (
    <>
      <textarea
        value={value}
        className={classNames(styles.textArea, className)}
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => onChange(event)}
      />
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </>
  );
}

export default TextArea;
