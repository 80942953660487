import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/insights-card.css';

export default class InsightsCard extends Component {
    style = style;

    render() {
      const {
        title,
        months,
        paid,
        goals,
        sumGoals,
        days,
        amount,
      } = this.props;
      return (
        <div className={this.classes.content}>
          <div className={this.classes.borderWrap}>
            <h3 className={this.classes.title}>
              {title}
            </h3>

            {title === 'Spend' && (
            <p className={this.classes.text}>
              Over the previous
              {` ${months} `}
              months, you’ve invested
              <span>
                {` $${amount} p/m `}
              </span>
              in
              <span>{` ${paid}`}</span>
              , on average
            </p>
            )}

            {title === 'Effectiveness' && (
              <p className={this.classes.text}>
                Over the previous
                {` ${months} `}
                months,
                <span>{` ${paid} `}</span>
                created
                <span>{` ${sumGoals} ${goals}`}</span>
                's on average
              </p>
            )}

            {title === 'Efficiency' && (
              <p className={this.classes.text}>
                Over the previous
                {` ${months} `}
                months,
                <span>{` ${paid} `}</span>
                had a cost per
                <span>{` ${goals} `}</span>
                of $
                <span>{`${amount} `}</span>
                on average
              </p>
            )}

            {title === 'Velocity' && (
              <p className={this.classes.text}>
                Over the previous
                {` ${months} `}
                months,
                <span>{` ${paid} `}</span>
                created
                <span>{` ${goals}`}</span>
                's in avg. of
                <span>{` ${days} days`}</span>
              </p>
            )}
            <div className={this.classes.staticGroup}>
              <div className={this.classes.arrowUpGroup}>
                <div className={this.classes.arrowUp} />
                <div className={this.classes.percent}>
                  26%
                </div>
                <p className={this.classes.arrowGroupText}>
                  compared to the prev
                  {` ${months} `}
                  months
                </p>
              </div>

              <div className={this.classes.arrowDownGroup}>
                <div className={this.classes.arrowDown} />
                <div className={this.classes.percentNegative}>
                  26%
                </div>
                <p className={this.classes.arrowGroupText}>
                  vs. the avg. cost per SQL
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
