import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import timeFrameModule from 'modules/timeframe';

import Popup from 'components/Popup';
import Component from 'components/Component';
import Dropdown from 'components/controls/Dropdown';

import { getTimeframeOptions, TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { RangeCalendar } from 'components/controls/Calendar';
import { compose } from 'components/utils/utils';
import { disabledDate } from 'stores/analyze/timeUtils';

import analyzeStyle from 'styles/analyze/analyze.css';
import selectStyle from 'styles/controls/select.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

const formatDate = (d) => moment(new Date(d)).format('MMM D');

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
    } = stores;
    const {
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
    } = userStore;

    return {
      fiscalYearFirstMonth,
    };
  }),
  observer
);

class CustomDateSelect extends Component {
  styles = [checkboxStyle, analyzeStyle, selectStyle];

  constructor(props) {
    super(props);

    this.state = {
      chooseCustomDate: false,
      timeFrameLabel: props.timeFrameLabel || props.timeFrame?.value || '',
    };
  }

  selectedPeriod = (timeFrame) => (timeFrame ? `${formatDate(timeFrame.startTS)} - ${formatDate(timeFrame.endTS)}` : '');

  showCustomDatePopup = () => this.setState({ chooseCustomDate: true });

  hideCustomDatePopup = () => this.setState({ chooseCustomDate: false });

  handleChangeTimeFrame = (timeFrame) => {
    if (timeFrame.value === TIMEFRAME_VALUES.CUSTOM) {
      this.showCustomDatePopup();
      return;
    }

    const timeFrameParams = timeFrameModule.getTimeframeParams({ ...timeFrame, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth });
    const {
      startDate: startTS, endDate: endTS,
    } = timeFrameParams;

    this.setState({
      timeFrameLabel: timeFrame.value,
    }, () => {
      this.updateFilter({ startTS, endTS, value: timeFrame.value }, timeFrame.value);
    });
  };

  handleChangeCustomTimeFrame = ({ startDate: startTS, endDate: endTS }) => {
    this.setState({
      timeFrameLabel: TIMEFRAME_VALUES.CUSTOM,
    }, () => {
      this.updateFilter({ startTS, endTS, value: TIMEFRAME_VALUES.CUSTOM });
    });
    this.hideCustomDatePopup();
  };

  updateFilter = (timeFrame, label) => {
    const { startTS, endTS } = timeFrame;
    this.props.updateFilter({
      label: label || this.selectedPeriod({ startTS, endTS }),
      timeFrame,
    });
  };

  render() {
    const {
      popupClose, containerClass, closeIcon, timeFrame, isTitleShow = true, customDatePopupClass,
    } = this.props;
    return (
      <div className={classnames(styles.customDateRow, containerClass)}>
        {closeIcon && (
          <button className={styles.popupClose} onClick={popupClose} aria-label="close" type="button" />
        )}
        {isTitleShow
          && (
            <div className={analyzeStyle.locals.text}>
              Custom Date
            </div>
          )}
        <div className={classnames(analyzeStyle.locals.dateSelectBlock, styles.filterActionsCustomDate)}>
          <Dropdown
            selectedKey={this.state.timeFrameLabel}
            options={getTimeframeOptions()}
            onChange={this.handleChangeTimeFrame}
            controlWidth={160}
          />
        </div>
        <div className={styles.exactDate}>
          {this.selectedPeriod(timeFrame)}
        </div>
        <Popup
          className={classnames(analyzeStyle.locals.datePopup, customDatePopupClass)}
          hidden={!this.state.chooseCustomDate}
          onClose={this.hideCustomDatePopup}
        >
          <RangeCalendar
            className={analyzeStyle.locals.calendar}
            onSelect={this.handleChangeCustomTimeFrame}
            onClose={this.hideCustomDatePopup}
            defaultSelectedValue={[
              moment(new Date()),
              moment(new Date()),
            ]}
            disabledDate={disabledDate}
          />
        </Popup>
      </div>
    );
  }
}

export default enhance(CustomDateSelect);
