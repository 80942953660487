export const nameAlreadyInUseErrorMessage = 'This name is already in use, please select another name';

export const notificationTypesTooltips = {
  slack: 'Slack',
  email: 'Email',
  report: 'Report',
  audienceList: 'Audience list',
};

export const actionTriggerTypes = {
  anomaly: 'anomaly',
  goalsTracking: 'goalsTracking',
  accountEngagement: 'accountEngagement',
  planVsActual: 'planVsActual',
};

export const actionTriggerTypesLabels = {
  anomaly: 'Anomaly detection',
  accountEngagement: 'Account engagement',
  planVsActual: 'Plan vs. Actual',
  goalsTracking: 'Goal tracking',
};

export const operatorTypes = {
  increase: 'increase',
  decrease: 'decrease',
  over: 'over',
  under: 'under',
};

export const percentageOperators = [operatorTypes.increase, operatorTypes.decrease];

export const operatorTypesLabels = {
  increase: 'Increase',
  decrease: 'Decrease',
  over: 'Is over',
  under: 'Is under',
};

export const notificationTypes = {
  infinigrowAlert: 'infinigrowAlert',
  slack: 'slack',
  email: 'email',
  audienceList: 'audienceList',
  report: 'report',
};

export const notificationTypesLabels = {
  infinigrowAlert: 'InfiniGrow alert',
  slack: 'Slack',
  email: 'Email',
  audienceList: 'Add to audience list',
  report: 'Add to report',
};

export const actionTriggerTypesOptions = [
  { value: actionTriggerTypes.accountEngagement, label: actionTriggerTypesLabels.accountEngagement },
  { value: actionTriggerTypes.goalsTracking, label: actionTriggerTypesLabels.goalsTracking },
  { value: actionTriggerTypes.anomaly, label: actionTriggerTypesLabels.anomaly },
];

export const anomalyTriggerOperatorsOptions = [
  { value: operatorTypes.increase, label: operatorTypesLabels.increase },
  { value: operatorTypes.decrease, label: operatorTypesLabels.decrease },
  { value: operatorTypes.over, label: operatorTypesLabels.over },
  { value: operatorTypes.under, label: operatorTypesLabels.under },
];

export const notificationTypeOptions = [
  { value: notificationTypes.slack, label: notificationTypesLabels.slack },
  { value: notificationTypes.email, label: notificationTypesLabels.email },
];

export const sectionTypes = {
  trigger: 'trigger',
  notification: 'notification',
  preview: 'preview',
};

export const minCharToShowTooltip = 30;

export const notificationFrequency = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const notificationFrequencyLabelMapping = {
  [notificationFrequency.daily]: 'Today',
  [notificationFrequency.weekly]: 'This Week',
  [notificationFrequency.monthly]: 'This Month',
};

export const notificationFrequencyOptions = [
  { value: notificationFrequency.daily, text: 'Daily' },
  { value: notificationFrequency.weekly, text: 'Weekly' },
  { value: notificationFrequency.monthly, text: 'Monthly' },
];

export const emptyNotification = {
  notificationType: notificationTypes.slack,
};
