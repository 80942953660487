import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import Page from 'components/Page';

import style from 'styles/onboarding/onboarding.css';

const styles = style.locals;

export default function MessagePopup({
  hidden, confirmText = 'Ok, got it', onClosePopup, popupTitle, popupSubTitle,
}) {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <Page className={styles.popupIndex} popup width="350px">
      <div className={classNames(styles.closeIcon, styles.closePopup)} onClick={onClosePopup} />
      <div className={styles.unsavedTitle}>
        <p><b>{popupTitle}</b></p>
        {popupSubTitle}
      </div>
      <div className={styles.footerConfirmPopup}>
        <Button
          type="primaryBlue"
          onClick={() => onClosePopup()}
        >
          {confirmText}
        </Button>
      </div>
    </Page>
  );
}
