exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".comparisonValue__comparisonValue-3_5{display:inline-flex;align-items:center;gap:1px;font-weight:400;font-size:12px;color:#99a4c2}.comparisonValue__comparisonValue-3_5.comparisonValue__isDesired-1M8{color:#00c875}.comparisonValue__comparisonValue-3_5.comparisonValue__isUndesired-2IB{color:#e43a54}.comparisonValue__infinityIcon-2r2{margin-left:4px}", ""]);

// exports
exports.locals = {
	"comparisonValue": "comparisonValue__comparisonValue-3_5",
	"comparisonValue": "comparisonValue__comparisonValue-3_5",
	"isDesired": "comparisonValue__isDesired-1M8",
	"isDesired": "comparisonValue__isDesired-1M8",
	"isUndesired": "comparisonValue__isUndesired-2IB",
	"isUndesired": "comparisonValue__isUndesired-2IB",
	"infinityIcon": "comparisonValue__infinityIcon-2r2",
	"infinityIcon": "comparisonValue__infinityIcon-2r2"
};