export const uiOptions = {
  impact: 'Impact',
  efficiency: 'cost',
  velocity: 'velocity',
};

export const toggleOptions = [{
  text: 'Impact',
  value: uiOptions.impact,
}, {
  text: 'Efficiency',
  value: uiOptions.efficiency,
}, {
  text: 'Velocity',
  value: uiOptions.velocity,
}];

export const averageLine = {
  label: 'Average',
  key: 'average',
};
