import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import Component from 'components/Component';
import style from 'styles/controls/tooltip.css';
import { createPortal } from 'react-dom';

class Tooltip extends Component {
  style = style;

  static defaultProps = {
    place: 'bottom',
    effect: 'solid',
    component: 'div',
    html: true,
    ComponentProps: {},
    TooltipProps: {},
    hidden: false,
    withPortal: false,
    dataTestId: 'tooltip',
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    place: PropTypes.string,
    effect: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    html: PropTypes.bool,
    TooltipProps: PropTypes.object,
    ComponentProps: PropTypes.object,
    hidden: PropTypes.bool,
    withPortal: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  render() {
    const {
      id,
      tip,
      children,
      effect,
      html,
      place,
      hidden,
      clickable,
      content,
      component: TooltipComponent,
      TooltipProps,
      withPortal,
      dataTestId,
      overridePosition,
      ...ComponentProps
    } = this.props;

    const tooltip = (
      <ReactTooltip
        place={place}
        effect={effect}
        id={id}
        html={content ? null : html}
        clickable={clickable}
        overridePosition={overridePosition}
        {...TooltipProps}
        className={classnames(
          this.classes.tooltip,
          TooltipProps.className,
          { [this.classes.tooltipWide]: TooltipProps.wide }
        )}
      >
        {content}
      </ReactTooltip>
    );

    const dataTestIdWithStatus = hidden ? `${dataTestId}-hidden` : `${dataTestId}-show`;

    return (
      <>
        <TooltipComponent data-testid={dataTestIdWithStatus} data-tip={content ? '' : tip} data-for={id} {...ComponentProps}>
          {children}
        </TooltipComponent>
        {hidden ? null : (
          <>
            {withPortal ? createPortal(tooltip, document.body) : tooltip}
          </>
        )}
      </>
    );
  }
}

export default Tooltip;
