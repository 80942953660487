export const timelineTab = 'timeline';
export const statsTab = 'stats';
export const touchpoints = 'touchpoints';
export const benchmark = 'benchmark';
export const contacts = 'contacts';

export const journeyTabsOptions = [
  { text: 'Timeline', value: timelineTab },
  { text: 'Stats', value: statsTab, isBetaVersion: true },
];
