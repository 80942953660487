import React, { useEffect, useState } from 'react';
import humanizeDuration from 'humanize-duration';

import Dropdown from 'components/controls/Dropdown';

import style from 'styles/settings/workflows/configPopup.css';

export default function DataRefreshInterval({ configuration, isViewOnly }) {
  const [duration] = useState(humanizeDuration(configuration.amount * 60000, { units: ['h'] }).split(' '));

  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <div className={style.locals.row}>
      Update data every
      <Dropdown
        disabled={isViewOnly}
        selectedKey={duration[0]}
        options={[{ label: duration[0], value: duration[0] }]}
      />
      <Dropdown
        disabled={isViewOnly}
        selectedKey={duration[1]}
        options={[{ label: duration[1], value: duration[1] }]}
      />
    </div>
  );
}
