export const attributionModelsItems = [
  {
    value: 'default',
    label: 'Full Journey',
    flag: 'preferencesAttributionModelsFullJourneyDefault',
    description: 'A data-driven model that takes into account the touchpoint’s timing and location, as well as the contact’s role in the journey.',
  },
  {
    value: 'firsttouch',
    label: 'First Touch',
    flag: 'preferencesAttributionModelsIntroducerFirsttouch',
    description: 'Full credit to the first touchpoint before the funnel transition.',
  },
  {
    value: 'lasttouch',
    label: 'Last Touch',
    flag: 'preferencesAttributionModelsConverterLasttouch',
    description: 'Full credit to the last touchpoint before the funnel transition.',
  },
  {
    value: 'linear',
    label: 'Linear',
    flag: 'preferencesAttributionModelsLinear',
    description: 'Equal credit to every touchpoint along the customer journey.',
  },
  {
    value: 'timeDecay',
    label: 'Time Decay',
    flag: 'preferencesAttributionModelsTimeDecayTimeDecay',
    description: 'Touchpoints get more credit the closer they are to the funnel transition',
  },
  {
    value: 'uShaped',
    label: 'U-Shaped',
    flag: 'preferencesAttributionModelsUShapedUShaped',
  },
  {
    value: 'wShaped',
    label: 'W-Shaped',
    flag: 'preferencesAttributionModelsWShapedWShaped',
  },
  {
    value: 'zShaped',
    label: 'Full-Path (Z-Shaped)',
    flag: 'preferencesAttributionModelsFullPathZShapedZShaped',
  },
  {
    value: 'firstcrm',
    label: 'First CRM TP',
    flag: 'preferencesAttributionModelsFirstCrmTpFirstcrm',
    description: 'Full conversion credit to the first touchpoint that was captured on your CRM (the CRM lead source).',
  },
];

export const getAttributionModelsWithFlags = (flags) => {
  const attributionModelsWithFlags = [];
  for (const modelsItem of attributionModelsItems) {
    if (flags[modelsItem.flag]) {
      attributionModelsWithFlags.push(modelsItem);
    }
  }
  return attributionModelsWithFlags;
};
