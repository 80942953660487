import { isEmpty, isNil } from 'lodash';

import userStore from 'stores/userStore';
import config from 'components/utils/Configuration';

import {
  notificationTypes, notificationFrequency, notificationFrequencyLabelMapping, actionTriggerTypes,
} from 'components/pages/settings/actions/enums';
import {
  getEmailBody, getEmailSubject, getFiltersSection, getAnomalyText,
} from 'components/pages/settings/actions/actionPopup/previewSection/logic/previewSection';
import { getDisplaySign } from 'components/pages/settings/actions/logic/actions';

export function getNotificationDetailsForServerRequest({
  notificationType, actionName, triggerType, frequency, toDoMessage, triggerRules,
}) {
  let subject;
  let body;

  if (notificationType === notificationTypes.email) {
    if (triggerType === actionTriggerTypes.anomaly) {
      subject = getEmailSubject({ actionName, triggerType, frequency });
      body = getEmailBody({
        triggerRules, toDoMessage, triggerType, asString: true,
      });
    }
  }

  if (notificationType === notificationTypes.slack) {
    if (triggerType === actionTriggerTypes.accountEngagement) {
      const filters = triggerRules?.[0]?.filters;
      let navigationUrl = `${config.clientHostName}/analyze/journeys`;
      let accountSegmentSection = 'Any account';

      if (filters?.length > 0) {
        navigationUrl += `?filters=${Buffer.from(JSON.stringify(filters)).toString('base64')}`;
        accountSegmentSection = `Accounts that answer the following filter: ${getFiltersSection({ filters, asString: true }).replaceAll('<p>', '').replaceAll('</p>', '')}`;
      }

      body = `*${notificationFrequencyLabelMapping[frequency]}'s new engagements!*
\n:bell: *${actionName}*
\n:dart: *Account segment:*
\n${accountSegmentSection}
\n:date: *Accounts that engaged ${notificationFrequencyLabelMapping[frequency]}:*
\n{EngagedAccounts}
\nExplore their <${navigationUrl}|Journeys in InfiniGrow> to learn more
\n${toDoMessage ? `What's Next? “${toDoMessage}”` : ''}`;
    }

    if (triggerType === actionTriggerTypes.goalsTracking) {
      body = `*Goal status changed ${notificationFrequencyLabelMapping[frequency]}!*
\n:dart: "{Goal}" became {trackStatus}
\n<${config.clientHostName}/insights/overview|Explore InfiniGrow> to learn more
\n${toDoMessage ? `What's Next? “${toDoMessage}”` : ''}`;
    }

    if (triggerType === actionTriggerTypes.anomaly) {
      body = `Anomaly detected ${notificationFrequencyLabelMapping[frequency]}!\n`;
      const ruleSections = [];
      for (const [ruleIndex, rule] of triggerRules.entries()) {
        const metricNickname = userStore.getMetricNickname({ metric: rule.metric });
        const ruleValue = `${rule.value}${getDisplaySign({ operator: rule.operator, metric: rule.metric })}`;
        const ruleBodyText = `\n\n:bar_chart: *Affected Metric #${ruleIndex + 1}*: ${metricNickname}
${rule.filters.length > 0 ? `\n:mag: *Filter Context:* ${getFiltersSection({ filters: rule.filters, asString: true }).replaceAll('<p>', '').replaceAll('</p>', '')}` : ''}
\n:pushpin: *Anomaly Details:* ${getAnomalyText({
    metric: metricNickname, operator: rule.operator, timeframe: rule.timeframe, ruleValue,
  })}`;
        ruleSections.push(ruleBodyText);
      }

      body += ruleSections.join('\n\n\n AND \n');
      body += `\n\n\n <${config.clientHostName}/analyze/overview|Explore InfiniGrow> to learn more
\n${toDoMessage ? `What's Next? “${toDoMessage}”` : ''}`;
    }
  }

  return { subject, body };
}

export function isNotificationDataValid({ notificationData = [], triggerFrequency }) {
  if (!Object.keys(notificationFrequency).includes(triggerFrequency) || notificationData?.length === 0) {
    return false;
  }

  return notificationData.every((notification) => {
    if (notification.notificationType === notificationTypes.slack) {
      return notification.recipients?.length > 0;
    }

    if (notification.notificationType === notificationTypes.email) {
      return isNotificationEmailValid({ notificationData: notification });
    }

    return false;
  });
}

export function isNotificationEmailValid({ notificationData }) {
  if (isEmpty(notificationData) || !notificationData.notificationType) {
    return false;
  }

  let requiredKeys = [];
  if (notificationTypes.email === notificationData.notificationType) {
    requiredKeys = ['recipients'];
  }

  const isAllRequireFieldsExists = requiredKeys.every((requireKey) => {
    const requireKeyValue = notificationData[requireKey];

    if (isNil(requireKeyValue)) {
      return false;
    }

    if (typeof requireKey === 'string' && requireKeyValue === '') {
      return false;
    }

    if (Array.isArray(requireKeyValue) && requireKeyValue.length === 0) {
      return false;
    }

    return true;
  });

  if (!isAllRequireFieldsExists) {
    return false;
  }

  return true;
}
