import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import AudienceTable from 'components/pages/journeys/AudienceTable';
import { widgetTypes } from 'components/pages/analyze/enums';
import { DEFAULT_PAGE_SIZE } from 'components/controls/Table';
import { getMappingOptions } from 'components/utils/logic/utils';
import { parseWidgetFiltersToFiltersConfig } from 'components/pages/reports/logic/reports';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

function AudienceTableWrapper({
  journeysTable = {},
  journeysOverview,
  sort,
  searchQuery,
  selectedReport,
  clientCursor,
  customFieldsIdToLabelMap,
  reportsUpdateClientCursor,
  questionsUpdateClientCursor,
  reportsUpdateSearch,
  questionsUpdateSearch,
  CRMConfig,
  updateAudienceTableSort,
  widgetHeaderConfig,
  widgetConfig,
  widgetHeaderProps,
  getWidgetRequestId,
  isInReports,
  emptyStateComponent,
}) {
  const [parentRequestIdState, setParentRequestIdState] = useState('');

  const requestId = parentRequestIdState || getWidgetRequestId({ widget: widgetTypes.journeysTable, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig, widget: widgetTypes.journeysTable }) });

  const journeysTableData = journeysTable?.[requestId]?.result;

  const isLoaded = journeysTable?.[requestId]?.status === 'finished';
  const isFailedToLoad = journeysTable?.[requestId]?.status === 'failed';

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      setIsLoadingMore(false);
    }
  }, [isLoaded]);

  const updateSearchQuery = isInReports ? reportsUpdateSearch : questionsUpdateSearch;
  const updateClientCursor = isInReports ? reportsUpdateClientCursor : questionsUpdateClientCursor;

  const reportId = selectedReport.value;
  const { widgetId } = widgetHeaderConfig;
  const filters = parseWidgetFiltersToFiltersConfig(widgetHeaderConfig.filters);

  async function handleShowMoreClick({ parentRequestId }) {
    setParentRequestIdState(parentRequestId);
    setIsLoadingMore(true);
    const newClientCursor = clientCursor + DEFAULT_PAGE_SIZE;
    await updateClientCursor({
      reportId, widgetId, clientCursor: newClientCursor, parentRequestId,
    });
  }

  function getDateRangeParams() {
    return {
      startDate: widgetHeaderConfig.timeFrame.startDate,
      endDate: widgetHeaderConfig.timeFrame.endDate,
    };
  }

  return (
    <AudienceTable
      isLoaded={isLoaded}
      isFailedToLoad={isFailedToLoad}
      data={journeysTableData}
      dateRange={getDateRangeParams()}
      limit={DEFAULT_PAGE_SIZE}
      handleShowMoreClick={() => handleShowMoreClick({ parentRequestId: requestId })}
      dataLength={journeysOverview?.total}
      updateSorting={(newSort) => updateAudienceTableSort({ reportId, widgetId, sort: newSort })}
      sort={sort}
      isLoading={isLoadingMore}
      CRMConfig={CRMConfig}
      customIdToLabelMap={getMappingOptions(customFieldsIdToLabelMap)}
      searchQuery={searchQuery}
      updateSearchQuery={(newSearchQuery) => updateSearchQuery({ reportId, widgetId, searchQuery: newSearchQuery })}
      widgetHeaderConfig={widgetHeaderConfig}
      emptyStateComponent={emptyStateComponent}
      widgetHeaderProps={{
        ...widgetHeaderProps,
        updateWidgetConfig: async (newProps) => {
          setParentRequestIdState('');
          await updateClientCursor({
            reportId, widgetId, clientCursor: 0, parentRequestId: '',
          });
          widgetHeaderProps.updateWidgetConfig(newProps);
        },
      }}
      filters={filters}
    />
  );
}

export default inject(
  ({
    reportsStore: {
      selectedReport,
      updateWidgetFilters,
      updateAudienceTableSort,
      updateAudienceTableSearchQuery: reportsUpdateSearch,
      updateAudienceTableClientCursor: reportsUpdateClientCursor,
    },
    questionsStore: {
      updateAudienceTableSearchQuery: questionsUpdateSearch,
      updateAudienceTableClientCursor: questionsUpdateClientCursor,
    },
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
      },
      userMonthPlan: {
        CRMConfig,
        fiscalYearFirstMonth,
      },
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.journeysTable]: journeysTable,
      },
    },
  }) => ({
    journeysTable,
    selectedReport,
    getWidgetRequestId,
    updateAudienceTableSort,
    customFieldsIdToLabelMap,
    updateWidgetFilters,
    CRMConfig,
    fiscalYearFirstMonth,
    reportsUpdateSearch,
    questionsUpdateSearch,
    reportsUpdateClientCursor,
    questionsUpdateClientCursor,
  }),
  observer
)(AudienceTableWrapper);
