import React from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import GeneralPopup from 'components/common/GeneralPopup';

class ChartSelect extends Component {
  state = {
    isShowPopup: false,
  };

  togglePopup = () => this.setState({ isShowPopup: !this.state.isShowPopup });

  onItemClick = (val) => (e) => {
    e.stopPropagation();
    this.props.onItemClick(val);
    this.togglePopup();
  };

  render() {
    const {
      classes, onTitleClick, activeIndicator, indicators,
    } = this.props;
    return (
      <div className={classes.selectTitle}>
        <div onClick={onTitleClick} className={classes.titleText}>
          {userStore.getMetricNickname({ metric: activeIndicator })}
        </div>
        <i
          onClick={this.togglePopup}
          className={classnames(classes.icon, {
            [classes.iconDown]: this.state.isShowPopup,
          })}
          data-icon="plan:monthNavigation"
          id="popupControl"
        />
        {this.state.isShowPopup && (
          <GeneralPopup
            popupClass={classes.popup}
            onClose={this.togglePopup}
            defaultVisible
          >
            {indicators.map((i) => (
              <div
                key={i.value}
                onClick={this.onItemClick(i.value)}
                className={classes.popupItem}
              >
                {i.label}
              </div>
            ))}
          </GeneralPopup>
        )}
      </div>
    );
  }
}

export default ChartSelect;
