import _ from 'lodash';
import React from 'react';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import { CONVERSION_RATE_MAPPING_SUFFIX, VELOCITY_MAPPING_SUFFIX } from 'components/utils/indicators';
import { makeFunnelStagesFilter, VARIANTS } from 'components/utils/filters/make';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import CohortChartLink from 'components/pages/dashboard/CohortChartLink';
import CohortChartBar from 'components/pages/dashboard/CohortChartBar';

import style from 'styles/dashboard/dashboard.css';

class CohortChart extends Component {
  style = style;

  static defaultProps = {
    withVelocity: false,
  };

  getNavigationMenuParams = (indicator) => {
    const { timeFrame, widgetHeaderConfig } = this.props;
    const includeAttributionStoreFilters = _.isNil(widgetHeaderConfig);
    const widgetFilters = widgetHeaderConfig?.filters || [];

    return [{
      title: 'View journeys',
      subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} created during the timeframe`,
      navigationFunction: () => navigateBetweenAnalyzeTabs({ filters: [makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [indicator])], tabName: 'journeys', timeFrame }),
    },
    {
      title: 'Breakdown by channel',
      subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} attribution by channel`,
      navigationFunction: () => navigateBetweenAnalyzeTabs({
        conversionIndicator: indicator, tabName: 'channels', anchor: 'attribution-table', timeFrame, includeAttributionStoreFilters, filters: widgetFilters,
      }),
    },
    {
      title: 'Breakdown by campaign',
      subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} attribution by campaign`,
      navigationFunction: () => navigateBetweenAnalyzeTabs({
        conversionIndicator: indicator, tabName: 'campaigns', anchor: 'attribution-table', timeFrame, includeAttributionStoreFilters, filters: widgetFilters,
      }),
    }];
  };

  render() {
    const {
      withVelocity,
      textClass,
      actualIndicators,
      funnelStagesToTheirGroupByType = {},
      isSimpleConversionRate = false,
      funnels,
      isLoaded,
      indicatorGrowths,
      isCompareToPreviousEnabled,
      reportSyncStatuses = {},
    } = this.props;

    const indicators = [...funnels];
    const indicatorsConversionMapping = {};
    const indicatorMapHelper = {};
    const indicatorsAmount = indicators.length;
    const isTheSameObjectToTheNextFunnel = {};
    indicators.forEach((indicator, index) => {
      if (indicatorsAmount - 1 !== index) {
        isTheSameObjectToTheNextFunnel[indicator] = funnelStagesToTheirGroupByType[`funnel${index + 1}`] === funnelStagesToTheirGroupByType[`funnel${index + 2}`];
        indicatorsConversionMapping[indicator] = `funnel${index + 1}ToFunnel${index + 2}${CONVERSION_RATE_MAPPING_SUFFIX}`;
        indicatorMapHelper[indicator] = `funnel${index + 1}ToFunnel${index + 2}${VELOCITY_MAPPING_SUFFIX}`;
      }
    });
    const currentTimeFrame = {};
    const currentGrowths = {};
    indicators.forEach((indicator) => {
      const indicatorKey = _.camelCase(`new ${indicator}`);
      currentTimeFrame[indicator] = actualIndicators[indicatorKey];
      currentTimeFrame[indicatorsConversionMapping[indicator]] = actualIndicators[indicatorsConversionMapping[indicator]];
      if (isCompareToPreviousEnabled && indicatorGrowths) {
        currentGrowths[indicator] = indicatorGrowths[indicatorKey];
      }
    });

    return (
      <div className={this.classes.miniFunnelContainer}>
        <div className={this.classes.miniFunnelTypesNames}>
          {indicators.map((indicator) => (
            <CohortChartLink
              key={`cohortChartLink-${indicator}`}
              indicator={indicator}
              textClass={textClass}
              isOutOfSync={reportSyncStatuses[indicator] && !reportSyncStatuses[indicator].isSynced}
              getNavigationMenuParams={this.getNavigationMenuParams}
              isLoaded={isLoaded || currentTimeFrame[indicator]}
            />
          ))}
        </div>
        <div className={this.classes.miniFunnelValues}>
          {indicators.map((indicator, index) => {
            const velocityIndicator = indicatorMapHelper[indicator] || '';
            currentTimeFrame[velocityIndicator] = actualIndicators[velocityIndicator];
            const velocity = currentTimeFrame[velocityIndicator];
            const conversionRate = currentTimeFrame[indicatorsConversionMapping[indicator]];
            let conversionRateData = {};
            if (actualIndicators.crStatisticsJSON && actualIndicators.crStatisticsJSON[indicator]) {
              conversionRateData = actualIndicators.crStatisticsJSON[indicator];
            }
            const keyName = `cohortChartBar-${index}`;
            const shouldUseAccountConversionRate = !isTheSameObjectToTheNextFunnel[indicator] && !isSimpleConversionRate;
            return (
              <CohortChartBar
                key={keyName}
                currentTimeFrame={currentTimeFrame}
                indicator={indicator}
                indicators={indicators}
                index={index}
                conversionRate={conversionRate || 0}
                velocity={velocity}
                withVelocity={withVelocity}
                conversionRateData={conversionRateData}
                shouldUseAccountConversionRate={shouldUseAccountConversionRate}
                getNavigationMenuParams={this.getNavigationMenuParams}
                textClass={textClass}
                isLoaded={isLoaded || currentTimeFrame[indicator]}
                isLoadedConversionRate={isLoaded || conversionRate}
                currentGrowths={currentGrowths}
                isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default CohortChart;
