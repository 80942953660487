import React, { useEffect } from 'react';
import classNames from 'classnames';

import { errorIndicationStatuses, errorIndicationTitles } from 'components/common/enums';

import style from 'styles/common/errorIndication.css';

const styles = style.locals || {};

export default function ErrorIndication({ status }) {
  useEffect(() => {
    style.use();

    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  let dataIcon;
  let title;
  let subTitle;
  if (status === errorIndicationStatuses.error) {
    dataIcon = 'errorIndication:error';
    title = errorIndicationTitles[errorIndicationStatuses.error].title;
    subTitle = errorIndicationTitles[errorIndicationStatuses.error].subTitle;
  } else {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} data-icon={dataIcon} />
      </div>
      <div className={classNames(styles.text, styles.title)}>{title}</div>
      <div className={classNames(styles.text, styles.subTitle)}>{subTitle}</div>
    </div>
  );
}
