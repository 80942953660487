import React from 'react';
import classNames from 'classnames';

import { Draggable } from 'react-beautiful-dnd';
import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { getIsValidReportUrl } from 'components/pages/createIntegration/logic/CRMReportSyncSection';
import Tooltip from 'components/controls/Tooltip';
import Loading3Dots from 'components/controls/Loading3Dots';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals || {};

function SyncIndicator({
  reportUrl,
  isFunnelSyncing,
  isReportUrlValid,
  reportSyncStatus,
  existingReportUrl,
  isReportSyncActive,
  isFunnelMappingValid,
}) {
  if (!isReportUrlValid || (isReportSyncActive && reportSyncStatus?.isSynced && !reportUrl)) {
    return (
      <div className={styles.funnelCheckedIconGreen} data-icon="platform:invalidReportUrl" />
    );
  }

  if (!isReportSyncActive) {
    if (isFunnelMappingValid) {
      return (
        <div className={styles.funnelCheckedIconGreen} data-icon="platform:checkedIcon" />
      );
    }

    return null;
  }

  if (isFunnelSyncing) {
    return (
      <Loading3Dots />
    );
  }

  if (!reportSyncStatus) {
    return null;
  }

  const isLinkChanged = reportUrl !== existingReportUrl;

  if (!isLinkChanged && reportSyncStatus?.isSynced) {
    return (
      <div className={styles.funnelCheckedIconGreen} data-icon="platform:reportSyncSuccess" />
    );
  }

  return (
    <Tooltip id="outOfSync" tip="Funnel report is out of sync.">
      <div className={styles.funnelCheckedIconGreen} data-icon="platform:reportSyncError" />
    </Tooltip>
  );
}

function CRMConfigFunnelItem({
  isSyncing,
  funnelItem,
  funnelIndex,
  removeFunnel,
  isFunnelSyncing,
  reportSyncStatus,
  selectedFunnelId,
  existingReportUrl,
  selectFunnelHandler,
}) {
  const { getFunnelTitle, funnelMapping = {}, funnelList } = useCRMCContext();

  const currentFunnelMapping = funnelMapping[funnelItem] || {};
  const {
    reportUrl = '',
    shouldUseReportSync = false,
  } = currentFunnelMapping.crmReport || {};

  const isReportUrlValid = !reportUrl || getIsValidReportUrl({ reportUrl });

  const isDeleteDisable = funnelList.length === 1 || isSyncing;

  const isFunnelMappingValid = () => {
    if (currentFunnelMapping) {
      const currentFunnelRules = currentFunnelMapping?.rules;
      return currentFunnelRules?.every((rule) => rule.objectType != null && rule.values?.length > 0 && rule.field != null);
    }
    return false;
  };

  return (
    <Draggable key={funnelItem} draggableId={funnelItem} index={funnelIndex}>
      {(provided) => (
        <div
          data-testid={`config-funnel-name-${getFunnelTitle(funnelItem)}`}
          className={classNames(styles.configFunnelBox, selectedFunnelId === funnelItem && styles.active)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={classNames(styles.dragAndDropIcon, styles.showOnHover)} data-icon="platform:dragAndDropIcon" />

          <div className={styles.configFunnelLeftTitle} onClick={() => !isSyncing && selectFunnelHandler(funnelItem)}>
            {getFunnelTitle(funnelItem)}
          </div>

          <div className={styles.funnelItemEditGroup}>
            {!isDeleteDisable ? (
              <div className={classNames(styles.funnelItemEdit, styles.showOnHover)} onClick={() => removeFunnel(funnelItem)}>
                <div data-testid="trash-button" className={styles.funnelItemEditIcon} data-icon="platform:trashIcon" />
              </div>
            ) : null}

            <SyncIndicator
              reportUrl={reportUrl}
              isFunnelSyncing={isFunnelSyncing}
              isReportUrlValid={isReportUrlValid}
              reportSyncStatus={reportSyncStatus}
              existingReportUrl={existingReportUrl}
              isReportSyncActive={shouldUseReportSync}
              isFunnelMappingValid={isFunnelMappingValid()}
            />
          </div>
        </div>

      )}
    </Draggable>
  );
}

export default CRMConfigFunnelItem;
