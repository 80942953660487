export const guidesIds = {
  journeyGuide: 'journeyGuide',
  analyzeGuide: 'analyzeGuide',
  goalsGuide: 'goalsGuide',
  channelsManagementGuide: 'channelsManagementGuide',
};

export const initialGuidesCompletionStatus = {
  [guidesIds.journeyGuide]: false,
  [guidesIds.analyzeGuide]: false,
  [guidesIds.goalsGuide]: false,
  [guidesIds.channelsManagementGuide]: false,
};

export const guideContentData = {
  [guidesIds.journeyGuide]: {
    title: 'Get a 360° view of any account journey',
    subTitle: 'See how InfiniGrow collects and structures your customer journey data, with a real life example.',
    icon: 'guide:journey',
    id: guidesIds.journeyGuide,
    isDisabled: false,
  },
  [guidesIds.analyzeGuide]: {
    title: 'Answer any marketing-related business question',
    subTitle: 'Learn how to use the Analyze pages to uncover answers to any question.',
    icon: 'guide:analyze',
    id: guidesIds.analyzeGuide,
    isDisabled: false,
  },
  [guidesIds.goalsGuide]: {
    title: 'Track your goals, and generate optimization opportunities for any goal that’s at risk',
    subTitle: 'Learn how to use InfiniGrow Insights to track your goals, and uncover optimization opportunities automatically.',
    icon: 'guide:goals',
    id: guidesIds.goalsGuide,
    isDisabled: false,
  },
  [guidesIds.channelsManagementGuide]: {
    title: 'Never worry about your UTMs or field tracking ever again',
    subTitle: 'See how InfiniGrow unifies and cleans data across sources.',
    icon: 'guide:unmapped',
    id: guidesIds.channelsManagementGuide,
    isDisabled: true,
  },
};
