import { funnelStagesKeyIndexes, HIGHER } from 'components/utils/filters';
import { VARIANTS, filterKinds, EQUALS } from 'components/utils/filters/logic';

export { VARIANTS };

/**
 example of creating filters:

 history.push({
    pathname: '/analyze/journeys',
    state: {
      filters: [
        makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF, [
          'advertising_searchMarketing_SEO',
          'social_twitterAccount',
        ]),
        makeFunnelStagesFilter(['MCL'])
      ]
    }
  })
 */

export const makeChannelsFilter = (variant, channels, isCategory, selectedFunnels, relatedEntities, comparisonOperator = EQUALS) => ({
  kind: filterKinds.CHANNELS,
  data: {
    variant,
    selectedOptions: channels,
    fieldIndex: isCategory ? 1 : 0,
    selectedFunnels,
    relatedEntities,
    comparisonOperator,
  },
});

export const makeFunnelStagesFilter = (variant, funnelStages) => ({
  kind: filterKinds.FUNNEL_STAGES,
  data: {
    variant,
    fieldIndex: funnelStagesKeyIndexes[variant],
    selectedOptions: funnelStages,
  },
});

export const makeCampaignsFilter = (variant, campaigns, selectedFunnels, comparisonOperator = EQUALS) => ({
  kind: filterKinds.CAMPAIGNS,
  data: {
    variant,
    selectedOptions: campaigns,
    selectedFunnels,
    comparisonOperator,
  },
});

export const makeMarketingVsSalesFilter = (variant, selectedOptions) => ({
  kind: filterKinds.MARKETING_VS_SALES,
  data: {
    variant,
    selectedOptions,
  },
});

export const makeCustomFieldsFilter = (fieldId, fieldIndex, variant, customFieldsData, relatedEntities, selectedFunnels, comparisonOperator = EQUALS) => ({
  kind: filterKinds.CUSTOM_FIELDS,
  data: {
    fieldId,
    fieldIndex,
    variant,
    selectedOptions: customFieldsData,
    relatedEntities,
    comparisonOperator,
    selectedFunnels,
  },
});

export const makeContentFilter = (fieldIndex, variant, contentData, comparisonOperator = EQUALS) => ({
  kind: filterKinds.CONTENT,
  data: {
    fieldIndex,
    variant,
    selectedOptions: contentData,
    comparisonOperator,
  },
});

export const makeCRMSourceFilter = (fieldId, fieldIndex, variant, sources) => ({
  kind: filterKinds.CRMSource,
  data: {
    fieldId,
    fieldIndex,
    variant,
    selectedOptions: sources,
  },
});

export const makeChannelBeforeStageFilter = (selectedOptions, selectedFunnel, variant = VARIANTS.INCLUDE_ANY_OF, isCategory, selectedFunnels, relatedEntities) => ({
  kind: filterKinds.CHANNELS_BEFORE_STAGES,
  data: {
    selectedOptions,
    selectedFunnel,
    variant,
    fieldIndex: isCategory ? 1 : 0,
    selectedFunnels,
    relatedEntities,
  },
});

export const makeCampaignBeforeStageFilter = (selectedOptions, selectedFunnel, selectedFunnels, relatedEntities, variant = VARIANTS.INCLUDE_ANY_OF) => ({
  kind: filterKinds.CAMPAIGN_BEFORE_STAGES,
  data: {
    selectedOptions,
    selectedFunnel,
    variant,
    selectedFunnels,
    relatedEntities,
  },
});

export const makeContentBeforeStageFilter = (selectedOptions, selectedFunnel, variant = VARIANTS.INCLUDE_ANY_OF, fieldIndex, selectedFunnels, relatedEntities) => ({
  kind: filterKinds.CONTENT_BEFORE_STAGES,
  data: {
    selectedOptions,
    selectedFunnel,
    variant,
    fieldIndex,
    selectedFunnels,
    relatedEntities,
  },
});

export const makeCostGraterThenFilter = (manualValue, selectedOptions = []) => ({
  kind: filterKinds.CAMPAIGN_COST,
  data: {
    comparisonOperator: HIGHER,
    manualValue,
    selectedOptions,
  },
});
