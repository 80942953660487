import {
  action, decorate, observable,
} from 'mobx';

import servicesStore from 'stores/servicesStore';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import timeFrameModule from 'modules/timeframe';

import { parseServerResponseForExplainableForecastGraph } from 'stores/logic/forecastStore';
import { getWidgetHashKey } from 'stores/logic/widgetsAnalysisStore';

class ForecastStore {
  forecastRequestFinished = false;

  alternativeForecastRequestIsLoading = false;

  formattedGraphExplainableForecast = null;

  formattedGraphExplainableAlternativeScenarioForecast = null;

  formattedGraphCumulativeForecast = null;

  formattedGraphCumulativeAlternativeScenarioForecast = null;

  forecastIndicators = null;

  isBudgetHasNewData = false;

  avgMonthlySpendData = {};

  loadedAvgMonthlySpendByMonth = {};

  forecastRequest = async (body = {}) => {
    try {
      const serverResponse = await serverCommunication.serverRequest('POST', 'forecast', JSON.stringify(body), userStore.userMonthPlan?.region);
      const forecastsResponse = await serverResponse.json();
      return forecastsResponse;
    } catch (exception) {
      servicesStore.logger.error('failed to get explainable forecaster data', { exception, UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region });
      this.updateForecastRequestFinishStatus(true);
      return {};
    }
  };

  async getExplainableForecastData() {
    this.updateForecastRequestFinishStatus(false);
    const forecastsResponse = await this.forecastRequest();
    this.formattedGraphExplainableForecast = parseServerResponseForExplainableForecastGraph(forecastsResponse.forecastedData);
    this.formattedGraphCumulativeForecast = parseServerResponseForExplainableForecastGraph(forecastsResponse.cumulatedData);
    this.forecastIndicators = forecastsResponse.indicators;
    this.updateForecastRequestFinishStatus(true);
  }

  async getAlternativeScenarioExplainableForecastData(updatedPlanBudget) {
    this.alternativeForecastRequestIsLoading = true;
    const body = {
      planBudgets: updatedPlanBudget,
    };
    const forecastsResponse = await this.forecastRequest(body);
    this.updateIsBudgetHasNewData(false);
    this.formattedGraphExplainableAlternativeScenarioForecast = parseServerResponseForExplainableForecastGraph(forecastsResponse.forecastedData);
    this.formattedGraphCumulativeAlternativeScenarioForecast = parseServerResponseForExplainableForecastGraph(forecastsResponse.cumulatedData);
    this.alternativeForecastRequestIsLoading = false;
  }

  updateIsBudgetHasNewData(isHasNewData) {
    this.isBudgetHasNewData = isHasNewData;
  }

  updateForecastRequestFinishStatus(isForecastRequestFinished) {
    this.forecastRequestFinished = isForecastRequestFinished;
  }

  resetFormattedGraphExplainableAlternativeScenarioForecast() {
    this.formattedGraphExplainableAlternativeScenarioForecast = null;
    this.formattedGraphCumulativeAlternativeScenarioForecast = null;
  }

  setLoadedAvgMonthlySpendByMonth({ isLoaded, avgMonthlySpendKey }) {
    const updatedLoadedAvgMonthlySpendByMonth = { ...this.loadedAvgMonthlySpendByMonth };
    updatedLoadedAvgMonthlySpendByMonth[avgMonthlySpendKey] = isLoaded;
    this.loadedAvgMonthlySpendByMonth = updatedLoadedAvgMonthlySpendByMonth;
  }

  setAvgMonthlySpendData({ avgMonthlySpendKey, data }) {
    const updatedAvgMonthlySpendData = { ...this.avgMonthlySpendData };
    updatedAvgMonthlySpendData[avgMonthlySpendKey] = data || {};
    this.avgMonthlySpendData = updatedAvgMonthlySpendData;
  }

  async requestAvgMonthlySpendData({ sampleTimeframeMonths, timeframe }) {
    const avgMonthlySpendKey = getWidgetHashKey({ widgetConfig: { sampleTimeframeMonths, timeframe } });
    if (this.avgMonthlySpendData[avgMonthlySpendKey]) {
      return;
    }

    this.setLoadedAvgMonthlySpendByMonth({ isLoaded: false, avgMonthlySpendKey });
    this.setAvgMonthlySpendData({ avgMonthlySpendKey });
    const { startDate, endDate } = timeFrameModule.getTimeframeParams({ ...timeframe, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
    try {
      const response = await servicesStore.serverCommunication.serverRequest({
        method: 'post',
        route: 'cost/averageMonthlyPlan',
        body: {
          startDate,
          endDate,
          sampleTimeframeMonths,
        },
        queryParams: {
          region: userStore.userMonthPlan?.region,
        },
      });
      this.setAvgMonthlySpendData({ avgMonthlySpendKey, data: response });
    } catch (exception) {
      servicesStore.logger.error('failed to get average monthly plan', { exception, UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region });
    }
    this.setLoadedAvgMonthlySpendByMonth({ isLoaded: true, avgMonthlySpendKey });
  }
}

decorate(ForecastStore, {
  forecastRequestFinished: observable.ref,
  formattedGraphExplainableForecast: observable.ref,
  formattedGraphCumulativeForecast: observable.ref,
  formattedGraphExplainableAlternativeScenarioForecast: observable.ref,
  formattedGraphCumulativeAlternativeScenarioForecast: observable.ref,
  forecastIndicators: observable.ref,
  updateForecastRequestFinishStatus: action,
  resetFormattedGraphExplainableAlternativeScenarioForecast: action.bound,
  updateIsBudgetHasNewData: action,
  isBudgetHasNewData: observable.ref,
  alternativeForecastRequestIsLoading: observable.ref,
  getExplainableForecastData: action.bound,
  getAlternativeScenarioExplainableForecastData: action.bound,
  avgMonthlySpendData: observable,
  loadedAvgMonthlySpendByMonth: observable,
  requestAvgMonthlySpendData: action.bound,
});

export default new ForecastStore();
