import React from 'react';
import classNames from 'classnames';

import InfoMarker from 'components/pages/InfoMarker';

import useStyles from 'hooks/useStyles';

import style from 'styles/common/indicationTag.css';

const styles = style.locals || {};

export default function IndicationTag({
  icon, text, tooltip, colorType, tooltipPosition,
}) {
  useStyles([style]);

  function getColorClassByType({ type }) {
    if (type === 'green') {
      return styles.green;
    }
    if (type === 'red') {
      return styles.red;
    }
    if (type === 'orange') {
      return styles.orange;
    }
    return styles.gray;
  }

  const tagColorClass = getColorClassByType({ type: colorType });

  return (
    <div className={classNames(styles.wrapper, tagColorClass)}>
      {icon && (<div className={styles.icon} data-icon={icon} />)}
      <div className={styles.text}>
        {text}
      </div>
      {tooltip && (<InfoMarker tooltipText={tooltip} place={tooltipPosition} />
      )}
    </div>
  );
}
