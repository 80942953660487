import React from 'react';

import servicesStore from 'stores/servicesStore';
import Component from 'components/Component';
import AttributionSetup from 'components/pages/AttributionSetup';
import { isPopupMode } from 'modules/popup-mode';

// eslint-disable-next-line react/prefer-stateless-function
export default class Setup extends Component {
  render() {
    const { UID } = this.props;
    const profile = servicesStore.authService.getProfileSync();

    return (
      <AttributionSetup
        UID={UID}
        isStaticPage={false}
        senderEmail={profile.email}
        isPopup={isPopupMode()}
        sendSnippetEmail={this.props.sendSnippetEmail}
        getScriptValidator={this.props.getScriptValidator}
      />
    );
  }
}
