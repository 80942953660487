import React from 'react';
import classnames from 'classnames';

import Tooltip from 'components/controls/Tooltip';
import Component from 'components/Component';
import { getChannelIcon } from 'components/utils/channels';
import { isValidUrl } from 'components/utils/utils';

import style from 'styles/channel-icon.css';

class ChannelIcon extends Component {
  style = style;

  render() {
    const {
      channel, className, channelIcon, tooltip,
    } = this.props;
    const icon = channelIcon || getChannelIcon(channel);
    const isURLIcon = isValidUrl(icon);

    return (
      <Tooltip tip={tooltip} id={`channel-icon-tooltip-${channel}`}>
        <div
          className={classnames(this.classes.channelIcon, className)}
          data-icon={!isURLIcon && icon}
          style={isURLIcon ? { backgroundImage: `url(${icon})` } : {}}
        />
      </Tooltip>
    );
  }
}

export default ChannelIcon;
