export const roundToCeilNumber = ({ value, ceilNumber = 5 }) => Math.ceil(value / ceilNumber) * ceilNumber || 0;

export const generateTicksArray = ({ ticksCount = 4, maxValue }) => {
  if (ticksCount <= 1) {
    return [0, maxValue];
  }
  const tickInterval = maxValue / (ticksCount - 1);
  const ticks = [];
  for (let i = 0; i < ticksCount; i++) {
    const tickValue = roundToCeilNumber({ value: i * tickInterval });
    ticks.push(tickValue);
  }
  return ticks;
};
