import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import style from 'styles/users/users-popup.css';
import {
  HUBSPOT_LINK_PREFIX,
} from 'components/utils/users';

const getCRMLinks = ({ salesforceInstanceUrl, hubspotPortalId, rawObjects }) => {
  return _.mapValues(rawObjects, (rawObject, externalId) => {
    const objectType = rawObject.object_type;
    const crmPlatform = rawObject.crm_platform;
    let link = '';

    if (crmPlatform === 'salesforce') {
      link = `${salesforceInstanceUrl}/${externalId}`;
    } else if (crmPlatform === 'hubspot') {
      link = `${HUBSPOT_LINK_PREFIX}${hubspotPortalId}/${objectType}/${externalId}`;
    }
    return {
      ...rawObject,
      link,
    };
  });
};

const UserPopupTooltip = ({ user, salesforceInstanceUrl, hubspotPortalId }) => {
  const { emails } = user;
  const { emptyStyle } = style.locals;

  const emailsTooltip = (!emails || emails.length <= 1) ? '' : (
    <div style={{ marginBottom: 20 }}>
      <div style={{ fontSize: 15, fontWeight: 'bold' }}>
        Users
      </div>
      {emails.map((item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {item}
        </div>
      ))}
    </div>
  );

  const rawObjects = user.rawObjects;
  const crmLinks = getCRMLinks({
    salesforceInstanceUrl,
    hubspotPortalId,
    rawObjects,
  });

  const links = crmLinks && Object.entries(crmLinks)
    .map(([external_id, {
      link, object_type, crm_platform, label,
    }]) => ({
      type: `${object_type} - ${crm_platform}`,
      link,
      label,
      external_id,
    }));

  const linksByType = _.groupBy(links, 'type');

  const linksTootlip = linksByType && Object.keys(linksByType).map((type) => {
    return (
      <div key={`${emails}-${links}`} style={{ marginBottom: 10 }}>
        <div style={{ fontSize: 15, fontWeight: 600, textTransform: 'capitalize' }}>
          {type}
        </div>
        {linksByType[type].map(({ link, label, external_id }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {label || external_id}
            <a href={link} target="_blank">
              <span className={emptyStyle} />
            </a>
          </div>
        ))}
      </div>
    );
  });

  const retVal = (
    <div>
      {emailsTooltip}
      {linksTootlip}
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(retVal);
};


export default UserPopupTooltip;
