import React from 'react';
import classnames from 'classnames';

import { frequencyMapping } from 'components/utils/frequency';
import Component from 'components/Component';
import GeneratedImpactContainer from 'components/pages/analyze/ImpactBoard/GeneratedImpactContainer';
import GeneratedImpactPieView from 'components/pages/analyze/ImpactBoard/GeneratedImpactPieView';

import style from 'styles/analyze/generated-impact.css';
import analyzeStyle from 'styles/analyze/analyze.css';

class GeneratedImpact extends Component {
  static defaultProps = {
    nameFormat: name => name,
  };

  style = style;

  styles = [analyzeStyle];

  constructor(props) {
    super(props);
  }

  get activeFrequency() {
    return frequencyMapping[this.props.currentFrequency];
  }

  render() {
    const {
      conversionIndicator,
      isMoneyFormat,
      nameFormat,
      children,
      data,
      prevData,
      getNavigationMenuPopupsParams,
    } = this.props;

    return (
      <div className={classnames(analyzeStyle.locals.item, this.classes.item)}>
        {children
          && (
          <div className={this.classes.rowBetween}>
            {children}
          </div>
          )}
        <GeneratedImpactContainer
          data={data}
          prevData={prevData}
          isMoneyFormat={isMoneyFormat}
          isCumulativeMetric
        >
          {({
            total, dataArray, optimizedDataWithTotal, prevChartData,
          }) => (
            <GeneratedImpactPieView
              prevData={prevChartData}
              total={Math.round(total)}
              dataArray={dataArray}
              optimizedDataWithTotal={optimizedDataWithTotal}
              nameFormat={nameFormat}
              isMoneyFormat={isMoneyFormat}
              indicator={conversionIndicator}
              getNavigationMenuPopupsParams={getNavigationMenuPopupsParams}
            />
          )}
        </GeneratedImpactContainer>
      </div>
    );
  }
}

export default GeneratedImpact;
