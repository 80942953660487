import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import Dropdown from 'components/controls/Dropdown';

import { getCampaignTagsOptions } from 'components/pages/plan/campaignPopups/logic/campaignTagsOptions';

import style from 'styles/plan/campaignPopups.css';

const styles = style.locals || {};

function CampaignTagsOptions({
  planStore,
  campaignTags = planStore?.campaignTags,
  updateSelectedCampaignTags = planStore?.updateSelectedCampaignTags,
  selectedCampaignTags = planStore?.selectedCampaignTags,
  onEditClick,
}) {
  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  return (
    <Dropdown
      options={getCampaignTagsOptions({ campaignTags, selectedCampaignTags })}
      controlWidth={210}
      placeholder="Select campaign tag"
      customSelectOption={(option) => (
        <>
          {option.value === 'createNew' ? (
            <div className={classnames(styles.optionTagRow, styles.createNewRow)} onClick={() => onEditClick()}>
              {option.label}
            </div>
          ) : (
            <div className={styles.optionTagRow}>
              <div className={styles.optionTagName} onClick={() => updateSelectedCampaignTags({ campaignTag: option })}>{option.label}</div>
              <div className={styles.editButton} onClick={() => onEditClick(option)} />
            </div>
          )}
        </>
      )}
    />
  );
}

export default inject(({
  planStore: {
    campaignTags,
    updateSelectedCampaignTags,
    selectedCampaignTags,
  } = {},
}) => ({
  campaignTags,
  updateSelectedCampaignTags,
  selectedCampaignTags,
}),
observer)(CampaignTagsOptions);
