import React, { useEffect } from 'react';

import style from 'styles/common/notSupportedWidget.css';

const styles = style.locals;

export default function NotSupportedWidget({ title, subTitle, children }) {
  useEffect(() => {
    style.use();

    return () => {
      style.use();
    };
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.subTitle}>
        {subTitle}
      </div>
      {children}
    </div>
  );
}
