import React, {
  useState, useRef, useEffect,
} from 'react';
import classNames from 'classnames';

import Popup from 'components/Popup';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';
import MenuButton from 'components/common/MenuButton';

import buttonWithOptions from 'styles/controls/buttonWithOptions.css';

const styles = buttonWithOptions.locals || {};

export default function ButtonWithOptions({
  options, renderMenuButton, popupClassName, buttonTitle = 'Actions', menuClassName, dataTestId = 'button-with-options',
}) {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const refPopupFilter = useRef();

  useEffect(() => {
    buttonWithOptions.use();
    return () => {
      if (!buttonWithOptions.unuse) {
        return;
      }
      buttonWithOptions.unuse();
    };
  }, []);

  const closePopup = () => {
    setIsShowPopup(false);
  };

  useOnClickOutside(refPopupFilter, () => {
    closePopup();
  });

  const onClickOptionsPopup = () => {
    setIsShowPopup((prev) => !prev);
  };

  const onClickAction = (optionAction) => {
    optionAction();
    closePopup();
  };

  return (
    <div
      ref={refPopupFilter}
      className={styles.optionsWrapper}
      data-testid={dataTestId}
    >
      {renderMenuButton ? (
        <div onClick={onClickOptionsPopup}>{renderMenuButton()}</div>
      ) : (
        <MenuButton
          title={buttonTitle}
          onClick={onClickOptionsPopup}
          isOpen={isShowPopup}
          className={menuClassName}
        />
      )}
      <Popup
        className={classNames(styles.optionsPopup, popupClassName)}
        hidden={!isShowPopup}
      >
        <div className={styles.optionsMenu}>
          {options.map((option) => (
            <div
              key={`action-${option.label}`}
              onClick={() => onClickAction(option.action)}
              data-testid={option.dataTestId}
            >
              {option.label}
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
}
