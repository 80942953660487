import React from 'react';
import merge from 'lodash/merge';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';
import Title from 'components/onboarding/Title';
import Brief from 'components/pages/campaigns/Brief';
import UnsavedPopup from 'components/UnsavedPopup';
import AddTemplatePopup from 'components/pages/campaigns/AddTemplatePopup';
import SaveButton from 'components/pages/profile/SaveButton';
import Mappings from 'components/pages/campaigns/Mappings';
import { SELECTED_FROM_OR_TO_CAMPAIGN } from 'components/utils/campaigns';
import { compose } from 'components/utils/utils';

import { amountTypes } from 'stores/plan/logic/enums';
import { updateCampaignKeyToNameMapping } from 'stores/analyze/logic/campaignsStore';

import saveButtonIcon from 'assets/save.svg';
import buttonsStyle from 'styles/onboarding/buttons.css';
import headerStyle from 'styles/header.css';
import style from 'styles/onboarding/onboarding.css';
import campaignPopupStyle from 'styles/campaigns/capmaign-popup.css';

const enhance = compose(
  inject((stores) => {
    const {
      planStore,
    } = stores;
    const {
      updateCampaignAmount,
      updateCampaignProperties,
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      startDate,
      endDate,
    } = planStore;
    return {
      updateCampaignAmount,
      updateCampaignProperties,
      sendUpdateCampaignsAmountRequestToServer,
      sendUpdateCampaignsPropertiesRequestToServer,
      startDate,
      endDate,
    };
  }),
  observer
);

class CampaignPopup extends Component {
  style = style;

  styles = [campaignPopupStyle, headerStyle, buttonsStyle];

  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.state = {
      selectedTab: 0,
      visible: this.props.visible || false,
      campaign: merge({}, CampaignPopup.defaultProps.campaign, this.props.campaign),
    };
    this.setRefName = this.setRefName.bind(this);
    this.setRefSource = this.setRefSource.bind(this);
    this.setRefDueDate = this.setRefDueDate.bind(this);
    this.save = this.save.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        visible: nextProps.visible || false,
        campaign: merge({}, CampaignPopup.defaultProps.campaign, nextProps.campaign),
      });
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  static defaultProps = {
    teamMembers: [],
    campaign: {
      index: undefined,
      name: '',
      isOneTime: false,
      owner: '',
      source: [],
      dueDate: '',
      startDate: new Date().toLocaleDateString().replace(/[/]/g, '-'),
      status: 'New',
      focus: '',
      time: {
        development: 0,
        design: 0,
        marketing: 0,
      },
      objectives: [],
      tracking: {
        baseUrl: '',
      },
      tasks: [],
      comments: [],
      assets: [],
      targetAudience: '',
      description: '',
      referenceProjects: '',
      keywords: '',
      additionalInformation: '',
    },
  };

  handleKeyPress(e) {
    /**
     if (e.key === 'Enter') {
      this.save();
    }
     * */
    if (e.key === 'Escape') {
      this.close();
    }
  }

  selectTab = (selectedIndex) => () => this.setState({ selectedTab: selectedIndex });

  updateState(newState) {
    this.setState(newState);
    this.setState({ unsaved: newState.unsaved === undefined ? true : newState.unsaved });
  }

  close() {
    const callback = (userAnswer) => {
      if (userAnswer) {
        this.setState({ selectedTab: 0, unsaved: false });
        this.props.closePopup();
      }
      this.setState({ showUnsavedPopup: false });
    };
    if (this.state.unsaved) {
      this.setState({ showUnsavedPopup: true, callback });
    } else {
      this.setState({ selectedTab: 0 });
      this.props.closePopup();
    }
  }

  openAddTemplatePopup() {
    this.setState({ showAddTemplatePopup: true });
  }

  closeAddTemplatePopup() {
    this.setState({ showAddTemplatePopup: false });
  }

  createTemplate(templateName) {
    this.props.updateCampaignsTemplates(templateName, this.state.campaign);
    this.closeAddTemplatePopup();
  }

  setRefName(input) {
    if (input) {
      this.nameInput = input;
    }
  }

  setRefSource(input) {
    if (input) {
      this.sourceInput = input;
    }
  }

  setRefDueDate(input) {
    if (input) {
      this.dueDateInput = input;
    }
  }

  validate() {
    return this.state.campaign.name && this.state.campaign.source && this.state.campaign.source.length > 0;
  }

  async save(isSaveOnlyMapping = false) {
    const { campaign } = this.state;
    const {
      campaignKeyToNameMapping, startDate, endDate, updateCampaignAmount, updateCampaignProperties,
      sendUpdateCampaignsAmountRequestToServer, sendUpdateCampaignsPropertiesRequestToServer,
    } = this.props;
    if (!isSaveOnlyMapping) {
      const campaignName = campaign.name;
      const sources = campaign.source;
      const channel = sources[0];
      const { id } = campaign;

      updateCampaignAmount({
        campaignName, channel, amount: campaign.costAmount, amountType: amountTypes.actualSpent, id, startDate, endDate,
      });
      updateCampaignAmount({
        campaignName, channel, amount: campaign.budgetAmount, amountType: amountTypes.budget, id, startDate, endDate,
      });
      updateCampaignProperties({
        name: campaignName, channel, sources, startDate: campaign.startDate, endDate: campaign.endDate, status: campaign.status, id,
      });
    }

    if (this.validate() || isSaveOnlyMapping) {
      this.updateState({ unsaved: false });
      if (campaign.newCampaignKeyToNameMapping) {
        Object.keys(campaign.newCampaignKeyToNameMapping).forEach((mappingKey) => {
          if (!campaignKeyToNameMapping[mappingKey]) {
            campaignKeyToNameMapping[mappingKey] = {};
          }
          const campaignsMappingFrom = campaign.newCampaignKeyToNameMapping[mappingKey][SELECTED_FROM_OR_TO_CAMPAIGN.FROM];
          if (campaignsMappingFrom) {
            Object.keys(campaignKeyToNameMapping[mappingKey]).forEach((key) => {
              if (campaignKeyToNameMapping[mappingKey][key] === campaign.name) {
                delete campaignKeyToNameMapping[mappingKey][key];
              }
            });
            campaignsMappingFrom.forEach((newCampaign) => {
              campaignKeyToNameMapping[mappingKey][newCampaign] = campaign.name;
            });
          }
          const campaignsMappingTo = campaign.newCampaignKeyToNameMapping[mappingKey][SELECTED_FROM_OR_TO_CAMPAIGN.TO];
          if (campaignsMappingTo) {
            Object.keys(campaignKeyToNameMapping[mappingKey]).forEach((key) => {
              if (key === campaign.name) {
                delete campaignKeyToNameMapping[mappingKey][key];
              }
            });
            isArray(campaignsMappingTo) ? campaignsMappingTo.forEach((newCampaign) => {
              campaignKeyToNameMapping[mappingKey][campaign.name] = newCampaign;
            }) : campaignKeyToNameMapping[mappingKey][campaign.name] = campaignsMappingTo;
          }
        });
        updateCampaignKeyToNameMapping(campaignKeyToNameMapping);
      }
      this.setState({ selectedTab: 0 });
      this.props.closePopup();
      if (!isSaveOnlyMapping) {
        await Promise.all([
          sendUpdateCampaignsAmountRequestToServer({ startDate, endDate }),
          sendUpdateCampaignsPropertiesRequestToServer({ startDate, endDate }),
        ]);
      }
    } else {
      this.setState(
        { selectedTab: 0 },
        () => {
          if (!campaign.name) {
            this.nameInput.focus();
          } else if (isEmpty(campaign.source)) {
            this.sourceInput.focus();
          } else {
            this.dueDateInput.focus();
          }
        }
      );
    }
  }

  render() {
    const tabs = {
      Brief,
      Mapping: Mappings,
    };

    const tabNames = Object.keys(tabs);
    const selectedName = tabNames[this.state.selectedTab];
    const selectedTab = tabs[selectedName];
    const { headTabSelected, headTab } = headerStyle.locals;

    return (
      <div>
        <Page popup width="850px" contentClassName={campaignPopupStyle.locals.content}>
          <div className={campaignPopupStyle.locals.topRight}>
            <div className={campaignPopupStyle.locals.close} onClick={this.close} />
          </div>
          <Title className={campaignPopupStyle.locals.title} title={this.state.campaign.name || 'Campaign Details'} />
          <div className={campaignPopupStyle.locals.headTabs}>
            {tabNames.map((name, i) => (
              <div
                className={i === this.state.selectedTab ? headTabSelected : headTab}
                key={i}
                onClick={this.selectTab(i)}
              >
                {name}
              </div>
            ))}
            <div style={{ marginLeft: 'auto', alignSelf: 'center' }}>
              {this.state.campaign.index !== undefined
                ? <SaveButton onClick={() => this.save(this.props.openFromAnalyze)} />
                : (
                  <Button
                    disabled={this.props.openFromAnalyze || this.state.campaign.isAutomatic}
                    type="primaryBlue"
                    icon={saveButtonIcon}
                    className={buttonsStyle.locals.planButton}
                    onClick={() => this.save(this.props.openFromAnalyze)}
                  >
                    Create
                  </Button>
                )}
            </div>
          </div>
          <div className={campaignPopupStyle.locals.inner}>
            {selectedTab ? React.createElement(selectedTab, merge({}, this.state, {
              updateState: this.updateState.bind(this),
              close: this.close,
              openAddTemplatePopup: this.openAddTemplatePopup.bind(this),
              closePopup: this.props.closePopup,
              teamMembers: this.props.teamMembers,
              channelsSources: this.props.channelsSources,
              setRefName: this.setRefName,
              setRefSource: this.setRefSource,
              setRefDueDate: this.setRefDueDate,
              save: this.save,
              campaignKeyToNameMapping: this.props.campaignKeyToNameMapping,
              startIndex: this.props.startIndex,
              endIndex: this.props.endIndex,
              openFromAnalyze: this.props.openFromAnalyze,
            })) : null}
          </div>
        </Page>
        <UnsavedPopup hidden={!this.state.showUnsavedPopup} callback={this.state.callback} />
        <AddTemplatePopup
          hidden={!this.state.showAddTemplatePopup}
          closeAddTemplatePopup={() => this.closeAddTemplatePopup}
          createTemplate={() => this.createTemplate}
          campaignName={this.state.campaign.name}
        />
      </div>
    );
  }
}

export default enhance(CampaignPopup);
