import React from 'react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Page from 'components/Page';
import SaveButton from 'components/pages/profile/SaveButton';
import Spinner from 'components/pages/journeys/Spinner';
import UnmappedPopupSteps from 'components/pages/settings/channels/tabs/UnmappedPopupSteps';
import UnmappedPopupTable from 'components/pages/settings/channels/tabs/UnmappedPopupTable';

import { getNewCondition } from 'components/utils/mappingRules';
import { unmappedPopupSteps } from 'components/pages/settings/channels/tabs/logic/enums';

import style from 'styles/settings/channels/unmapped-tab.css';

const styles = style.locals || {};

function UnmappedPopup(props) {
  const {
    children, onCancel, selectedRowsToMap = [], unmappedType, hidden,
    mappingRulesConditions, saveFail, isSaving, paramsOptions, selectedChannelForMapping,
    saveMapping, setMappingRulesConditions, resetSelectedChannel, columns, setUnmappedPopupStep, unmappedPopupStep,
  } = props;

  useStyles([style]);

  const onCancelPopup = () => {
    setUnmappedPopupStep(unmappedPopupSteps.selectChannel);
    resetSelectedChannel();
    onCancel();
  };

  const addCondition = (groupIndex) => {
    const updatedMappingRulesConditions = [...mappingRulesConditions];
    updatedMappingRulesConditions[groupIndex].push(getNewCondition());
    setMappingRulesConditions(updatedMappingRulesConditions);
  };

  const deleteCondition = (groupIndex, ruleIndex) => {
    const updatedMappingRulesConditions = [...mappingRulesConditions];
    updatedMappingRulesConditions[groupIndex].splice(ruleIndex, 1);
    setMappingRulesConditions(updatedMappingRulesConditions);
  };

  const updateCondition = (groupIndex, ruleIndex, param, value) => {
    const updatedMappingRulesConditions = [...mappingRulesConditions];
    updatedMappingRulesConditions[groupIndex][ruleIndex][param] = value;
    setMappingRulesConditions(updatedMappingRulesConditions);
  };

  if (hidden) {
    return null;
  }

  return (
    <Page popup className={styles.unmappedPopup}>
      <div
        className={styles.closeButton}
        onClick={onCancelPopup}
      />
      <div className={styles.topUnmapped}>
        <div className={styles.mapWrapper}>
          <UnmappedPopupTable tableRowsData={selectedRowsToMap[0].rowData} columns={columns} />
        </div>
      </div>

      {unmappedType === 'CAMPAIGNS'
        && (
          <>
            <div className={styles.unmappedContent}>
              {children}
            </div>

            <div className={styles.unmappedApplyButtons}>
              <Button
                type="secondaryWhite"
                style={{ width: '75px' }}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <SaveButton
                style={{ padding: '0 24px' }}
                onClick={saveMapping}
                fail={saveFail}
              />
              {(isSaving) && (<Spinner />)}
            </div>
          </>
        )}

      {unmappedType === 'CHANNELS'
        && (
          <UnmappedPopupSteps
            mappingRulesConditions={mappingRulesConditions}
            saveFail={saveFail}
            isSaving={isSaving}
            paramsOptions={paramsOptions}
            selectedChannelForMapping={selectedChannelForMapping}
            updateCondition={updateCondition}
            addCondition={addCondition}
            deleteCondition={deleteCondition}
            saveMapping={saveMapping}
            onCancel={onCancelPopup}
            unmappedPopupStep={unmappedPopupStep}
            setUnmappedPopupStep={setUnmappedPopupStep}
          >
            {children}
          </UnmappedPopupSteps>
        )}
    </Page>
  );
}

export default UnmappedPopup;
