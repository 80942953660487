exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".range__slider-2qK{box-shadow:0 2px 4px 0 #d5d8e6;background-color:#4d91fc!important;border:1px solid #4d91fc!important}.range__active-track-3GN{background:#cadefe!important}", ""]);

// exports
exports.locals = {
	"slider": "range__slider-2qK",
	"slider": "range__slider-2qK",
	"active-track": "range__active-track-3GN",
	"activeTrack": "range__active-track-3GN"
};