import React from 'react';
import { inject, observer } from 'mobx-react';

import CRMIntegrationSteps from 'components/pages/createIntegration/CRMIntegrationSteps';
import CRMConfigFunnel from 'components/pages/createIntegration/CRMConfigFunnel';
import CRMImportFields from 'components/pages/createIntegration/CRMImportFields';
import CRMIntegrationsSelect from 'components/pages/createIntegration/CRMIntegrationsSelect';
import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals;

function CreateCRMIntegration({ region }) {
  const { closePopup, currentProgressStep } = useCRMCContext();

  return (
    <div className={styles.integrationWrapper}>
      <div className={styles.backToIntegrations} onClick={closePopup}>
        {'< Back to integrations page'}
      </div>
      {currentProgressStep === 0 && <CRMIntegrationsSelect />}
      {currentProgressStep === 1 && <CRMConfigFunnel region={region} />}
      {currentProgressStep === 2 && <CRMImportFields />}
      <CRMIntegrationSteps />
    </div>
  );
}

export default inject(({
  userStore: {
    userMonthPlan: {
      region,
    } = {},
  } = {},
}) => ({
  region,
}), observer)(CreateCRMIntegration);
