import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import {
  isFinite, orderBy, sumBy, uniqueId,
} from 'lodash';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import userStore from 'stores/userStore';

import { viewStyleAccordingDataType } from 'components/pages/plan/logic/enums';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { formatBudget } from 'components/utils/budget';
import { indicatorOptions, NEW_FUNNEL_PREFIX } from 'components/utils/indicators';

import Component from 'components/Component';
import Toggle from 'components/controls/Toggle';
import Tooltip from 'components/controls/Tooltip';
import ChartSelect from 'components/pages/dashboard/dashboardNavigate/SpendVsAttributedChart/ChartSelect';
import ListItemColumn from 'components/pages/dashboard/dashboardNavigate/SpendVsAttributedChart/ListItemColumn';
import { compose } from 'components/utils/utils';

import style from 'styles/dashboard/spend-vs-acttributed-chart.css';

const SORT_BY = {
  SPENDING: 'value',
  INDICATOR: 'targetCount',
};

const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

const enhance = compose(
  inject((stores) => {
    const {
      dashboardStore,
    } = stores;
    const {
      campaignsSpendVsAttributedImpact,
      channelsSpendVsAttributedImpact,
    } = dashboardStore;
    return {
      campaignsSpendVsAttributedImpact,
      channelsSpendVsAttributedImpact,
    };
  }),
  observer
);

class SpendVsAttributedChart extends Component {
  static makeRowRenderer = ({
    items, maxLeft, maxRight, rowTooltipContent, classes,
  }) => (rowProps) => {
    const { index, key, style: rowStyle } = rowProps;
    const item = items[index];
    return (
      <div style={rowStyle} key={key}>
        <div className={classes.chartRowListItem} key={key}>
          <ListItemColumn item={item} max={maxLeft} />
          <div className={classes.chartItemColCentered}>
            <div className={classes.itemName} key={uniqueId('titles-')}>
              <div>
                <Tooltip
                  tip={rowTooltipContent(item)}
                  id={`spend-vs-attributed-navigate-${index}`}
                  place="bottom"
                  withPortal
                >
                  <div className={classes.itemTitle}>
                    {item.name}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <ListItemColumn item={item} right max={maxRight} />
        </div>
      </div>
    );
  };

  style = style;

  static defaultProps = {
    campaigns: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndicator: `${NEW_FUNNEL_PREFIX}1`,
      viewStyle: viewStyleAccordingDataType.CHANNEL,
      sortBy: SORT_BY.INDICATOR,
      sortType: SORT_TYPE.DESC,
    };
  }

  onItemClick = (val) => this.setState({ activeIndicator: val });

  campaignsData = (activeIndicator) => {
    const campaigns = this.props.campaignsSpendVsAttributedImpact || [];
    const campaignsData = [];
    for (const item of campaigns) {
      const spend = item.spending.actual || 0;
      const targetCount = (item[activeIndicator] && item[activeIndicator].actual) || 0;
      const spendPerTarget = targetCount ? spend / targetCount : 0;
      campaignsData.push({
        ...item,
        name: item.campaign,
        value: spend,
        targetCount,
        spendPerTarget,
      });
    }
    return campaignsData;
  };

  channelsData = (activeIndicator) => (this.props.channelsSpendVsAttributedImpact || this.props.channels)
    .map((item) => {
      const spend = item.spending.actual || 0;
      const targetCount = (item[activeIndicator] && item[activeIndicator].actual) || 0;
      const spendPerTarget = targetCount ? spend / targetCount : 0;
      return {
        ...item,
        name: getChannelNickname(item.channel),
        value: spend,
        targetCount,
        spendPerTarget,
      };
    });

  setSort = (isSpending) => {
    this.setState((prevState) => ({
      sortBy: isSpending ? SORT_BY.SPENDING : SORT_BY.INDICATOR,
      sortType: prevState.state.sortType === SORT_TYPE.DESC ? SORT_TYPE.ASC : SORT_TYPE.DESC,
    }));
  };

  render() {
    const {
      activeIndicator, viewStyle, sortType, sortBy,
    } = this.state;
    const indicators = indicatorOptions();
    let data = {};
    if (viewStyle === viewStyleAccordingDataType.CHANNEL) {
      data = this.channelsData(activeIndicator);
    }
    if (viewStyle === viewStyleAccordingDataType.CAMPAIGN) {
      data = this.campaignsData(activeIndicator);
    }

    const items = orderBy(data, sortBy, sortType)
      .filter((item) => {
        const haveTarget = isFinite(item.targetCount) && item.targetCount > 0;
        return item.value || haveTarget;
      });
    const itemsSum = sumBy(items, (i) => i.value);

    const targetSum = Math.round(sumBy(items, (i) => i.targetCount));
    const format = (v) => formatBudget(v, true, true);
    const totalPricePerTarget = targetSum > 0 ? itemsSum / targetSum : 0;
    const indNameSingle = userStore.getMetricNickname({ metric: activeIndicator, isSingular: true });

    const rowTooltipContent = (item) => {
      switch (this.state.viewStyle) {
        case viewStyleAccordingDataType.CHANNEL: {
          return `Cost per ${indNameSingle}: ${format(item.spendPerTarget)}`;
        }
        case viewStyleAccordingDataType.CAMPAIGN: {
          return item.name;
        }
        default: return '';
      }
    };

    const maxRight = Math.max(...items.map((i) => i.targetCount) || 0);
    const maxLeft = Math.max(...items.map((i) => i.value) || 0);
    const rowRenderer = SpendVsAttributedChart.makeRowRenderer({
      items,
      maxLeft,
      maxRight,
      rowTooltipContent,
      classes: this.classes,
    });

    return (
      <div className={classnames(this.classes.item)}>
        <div className={this.classes.titleContainer}>
          <div className={this.classes.title}>
            Spend vs Attributed Impact
          </div>
          <Toggle
            options={[{
              text: 'Channels',
              value: viewStyleAccordingDataType.CHANNEL,
            }, {
              text: 'Campaigns',
              value: viewStyleAccordingDataType.CAMPAIGN,
            }]}
            selectedValue={this.state.viewStyle}
            onClick={(_viewStyle) => this.setState({ viewStyle: _viewStyle })}
            bordered
          />
        </div>
        <div className={this.classes.titleRow}>
          <div className={this.classes.chartItemTitle}>
            <div
              className={this.classes.titleText}
              onClick={() => this.setSort(true)}
            >
              Spend
            </div>
          </div>
          <div className={this.classes.chartItemTitle}>
            <ChartSelect
              classes={this.classes}
              onTitleClick={() => this.setSort()}
              onItemClick={this.onItemClick}
              indicators={indicators}
              activeIndicator={activeIndicator}
            />
          </div>
        </div>
        <div className={this.classes.chartRow}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowCount={items.length}
                rowHeight={44}
                rowRenderer={rowRenderer}
                className={this.classes.noOutline}
              />
            )}
          </AutoSizer>
        </div>
        <div className={this.classes.totalBar}>
          <div className={this.classes.totalBarText}>
            {format(itemsSum)}
          </div>
          <div className={classnames(this.classes.totalBarText, this.classes.totalBarTextSmall)}>
            <Tooltip
              tip={`Cost per ${indNameSingle}: ${format(totalPricePerTarget)}`}
              id="spend-vs-attributed-navigate-total"
              place="bottom"
            >
              Total
            </Tooltip>
          </div>
          <div className={this.classes.totalBarText}>
            {`${targetSum?.toLocaleString()} `}
            <span className={this.classes.totalBarTextThin}>
              {userStore.getMetricNickname({ metric: activeIndicator, isSingular: targetSum === 1 })}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default enhance(SpendVsAttributedChart);
