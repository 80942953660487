import React from 'react';

import Component from 'components/Component';
import Page from 'components/Page';
import UploadOfflinePopup from 'components/pages/attribution/UploadOfflinePopup';

import style from 'styles/plan/plan.css';
import pageStyle from 'styles/page.css';

export default class Attribution extends Component {
  style = style;

  styles = [pageStyle];

  constructor(props) {
    super(props);
    this.state = {
      showOfflinePopup: false,
    };
  }

  render() {
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => React.cloneElement(child, this.props)
    );
    return (
      <div>
        <Page width="100%">
          <div className={pageStyle.locals.container}>
            <div className={pageStyle.locals.contentHead}>
              <div className={pageStyle.locals.contentHeadTitle}>Attribution</div>
            </div>
            <div>
              {childrenWithProps}
              <div hidden={!this.state.showOfflinePopup}>
                <UploadOfflinePopup
                  close={() => {
                    this.setState({ showOfflinePopup: false });
                  }}
                  setDataAsState={this.props.setDataAsState}
                />
              </div>
            </div>
          </div>
        </Page>
      </div>
    );
  }
}
