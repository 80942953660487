exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".target-audience__persona-cell-28_{display:flex;align-items:center}@media (max-width:500px){.target-audience__persona-cell-28_{flex-direction:column;align-items:flex-start}}", ""]);

// exports
exports.locals = {
	"persona-cell": "target-audience__persona-cell-28_",
	"personaCell": "target-audience__persona-cell-28_"
};