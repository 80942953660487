import React from 'react';

import AddNewCampaignSelection from 'components/pages/plan/campaignPopups/AddNewCampaignSelection';
import CostPlaceholder from 'components/pages/plan/campaignPopups/CostPlaceholder';
import ScrollableContainer from 'components/pages/plan/BudgetTable/partials/ScrollableContainer';
import Table from 'components/pages/plan/BudgetTable/partials/Table';
import CampaignPopup from 'components/pages/campaigns/CampaignPopup';
import ChooseExistingTemplate from 'components/pages/campaigns/ChooseExistingTemplate';

import { addNewOptions } from 'components/pages/plan/campaignPopups/enums';

import styles from 'styles/plan/budget-table-v2.css';
import reactTablesStylesOverride from 'styles/global/budget-table-v2-react-table.css';
import reactTablesStyles from 'react-table/react-table.css';

const classes = styles.locals;

class BudgetsTable extends React.Component {
  static defaultProps = {
    data: [],
    dates: [],
    numberOfPastDates: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      campaign: null,
      isAddNew: false,
      isAddNewCampaign: false,
      isAddNewCostPlaceholder: false,
      sourceChannel: null,
    };
  }

  componentDidMount() {
    reactTablesStyles.use();
    styles.use();
    reactTablesStylesOverride.use();
  }

  componentWillUnmount() {
    reactTablesStyles.unuse();
    styles.unuse();
    reactTablesStylesOverride.unuse();
  }

  showPopup = (campaign, sourceChannel = {}) => {
    this.setState({ campaign: { ...campaign, sourceChannel } }, () => this.setState({ showPopup: true }));
  };

  addCampaign = (channel) => this.setState({ isAddNew: true, sourceChannel: channel });

  showCampaign = (campaign) => {
    this.setState({ showPopup: true, campaign: campaign || {} });
  };

  render() {
    const {
      changeScrollPosition,
      scrollPosition,
      cellWidth,
      updateCampaign,
      numberOfPastDates,
      currentDate,
      dates,
      campaignKeyToNameMapping,
      ...restProps
    } = this.props;
    const { campaign, sourceChannel } = this.state;

    return (
      <>
        <ScrollableContainer
          cellWidth={cellWidth}
          changeScrollPosition={changeScrollPosition}
          scrollPosition={scrollPosition}
        >
          <Table
            cellWidth={cellWidth}
            {...restProps}
            showPopup={this.showPopup}
            updateCampaign={updateCampaign}
            addCampaign={this.addCampaign}
            numberOfPastDates={numberOfPastDates}
            dates={dates}
            currentDateIndex={currentDate}
          />
        </ScrollableContainer>
        <div hidden={!this.state.showPopup}>
          <CampaignPopup
            campaign={campaign}
            channelTitle="Main channel"
            closePopup={() => this.setState({ showPopup: false })}
            teamMembers={[]}
            campaignsTemplates={{}}
            updateCampaignsTemplates={() => {}}
            expenses={[]}
            campaignKeyToNameMapping={campaignKeyToNameMapping}
            openFromAnalyze
          />
        </div>
        <div>
          {this.state.isAddNew && (
          <AddNewCampaignSelection
            onClose={() => this.setState({ isAddNew: false })}
            onSave={(selectedOption) => (selectedOption === addNewOptions.newCampaign ? this.setState({ isAddNewCampaign: true, isAddNew: false }) : this.setState({ isAddNewCostPlaceholder: true, isAddNew: false }))}
          />
          )}
          {this.state.isAddNewCampaign && (
            <ChooseExistingTemplate
              showCampaign={(template) => this.showCampaign({
                ...template,
                status: 'New',
                source: [sourceChannel.channel],
              })}
              close={() => this.setState({ isAddNewCampaign: false })}
              campaignsTemplates={this.props.campaignsTemplates}
            />
          )}
          {this.state.isAddNewCostPlaceholder && (
          <CostPlaceholder
            onClose={() => this.setState({ isAddNewCostPlaceholder: false })}
            channel={sourceChannel.channel}
          />
          )}
        </div>
      </>
    );
  }
}

export { classes };
export default BudgetsTable;
