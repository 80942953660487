import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import { Button } from '@infinigrow/libs';

import RadioSelect from 'components/controls/RadioSelect';
import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';

import { folderActionsKeys, folderOfUnassignedReports, errorMessagesNameExists } from 'components/pages/reports/enums';

import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals || {};

function FolderActionsPopups({
  updateActionType,
  type,
  foldersList,
  selectedFolder,
  selectedReport,
  createNewFolderRequest,
  renameFolderRequest,
  toastSuccessMessage,
  addReportToFolderRequest,
  isAttachCurrentReportToFolder = true,
  updateReportFolder,
}) {
  if (!type) {
    return null;
  }

  const [inputFolderName, setInputFolderName] = useState({ error: null, isDisabled: true, label: selectedFolder?.label || '' });
  const [selectedFolderToMove, setSelectedFolderToMove] = useState(null);

  const folderNameRef = useRef();

  useEffect(() => {
    reportsStyle.use();

    return () => {
      if (!reportsStyle.unuse) {
        return;
      }
      reportsStyle.unuse();
    };
  }, []);

  function closePopupHandler() {
    updateActionType(null);
  }

  const foldersWithoutCurrentAndUnassigned = foldersList.filter((folder) => selectedFolder?.value !== folder.value && folder.value !== folderOfUnassignedReports.value);

  function onChangeFolderName(event) {
    const folderName = event.target.value;
    const isDuplicate = foldersWithoutCurrentAndUnassigned.some((folder) => folder.label.toLowerCase() === folderName.toLowerCase());
    if (isDuplicate) {
      setInputFolderName({ error: true, isDisabled: true });
    } else {
      setInputFolderName({ error: false, isDisabled: false });
    }
  }

  async function onCreateNewFolder() {
    const folderName = get(folderNameRef, ['current', 'refs', 'input', 'value'], '');
    await createNewFolderRequest({ name: folderName, reportId: isAttachCurrentReportToFolder ? selectedReport.value : null });
    closePopupHandler();
    toastSuccessMessage('Folder created successfully!');
  }

  function onRenameFolder() {
    const folderName = get(folderNameRef, ['current', 'refs', 'input', 'value'], '');
    renameFolderRequest({ name: folderName, folderId: selectedFolder.value });
    closePopupHandler();
  }

  function onSelectedFolderToMove() {
    addReportToFolderRequest({ reportId: selectedReport.value, folderId: selectedFolderToMove.value });
    updateReportFolder({ reportId: selectedReport.value, folderId: selectedFolderToMove.value });
    closePopupHandler();
    toastSuccessMessage('Moved to folder successfully!');
  }

  return (
    <Page wrapBoxStyle={styles.popupBox} contentClassName={styles.reportPopup} popup width="350px">
      <div className={styles.closePopupIcon} onClick={closePopupHandler} />
      {type === folderActionsKeys.renameFolder && (
      <div className={styles.reportInnerPopup} data-testid="rename-folder">
        <div className={styles.popupTitle}>
          <b>Change folder name</b>
        </div>
        <div className={styles.popupFields}>
          <Textfield
            ref={folderNameRef}
            isShowError={inputFolderName.error}
            inputErrorMessage={errorMessagesNameExists}
            placeHolder="Folder name"
            onChange={(event) => onChangeFolderName(event)}
            value={inputFolderName?.label}
          />
        </div>
        <div className={styles.popupButtons}>
          <Button type="secondaryBlue" onClick={() => closePopupHandler()}>Close</Button>
          <Button type="primaryBlue" disabled={inputFolderName.isDisabled} onClick={() => onRenameFolder()}>Save</Button>
        </div>
      </div>
      )}

      {type === folderActionsKeys.createNewFolder && (
      <div className={styles.reportInnerPopup} data-testid="create-folder">
        <div className={styles.popupTitle}>
          <b>Create new folder</b>
          <div className={styles.popupSubTitle}>
            You can always change the folder name in the Reports tab
          </div>
        </div>
        <div className={styles.popupFields}>
          <Textfield
            ref={folderNameRef}
            isShowError={inputFolderName.error}
            inputErrorMessage={errorMessagesNameExists}
            placeHolder="Folder name"
            onChange={(event) => onChangeFolderName(event)}
          />
        </div>
        <div className={styles.popupButtons}>
          <Button type="secondaryBlue" onClick={() => closePopupHandler()}>Close</Button>
          <Button type="primaryBlue" disabled={inputFolderName.isDisabled} onClick={() => onCreateNewFolder()}>Create folder</Button>
        </div>
      </div>
      )}

      {type === folderActionsKeys.moveToFolder && (
      <div className={styles.reportInnerPopup} data-testid="move-to-folder">
        <div className={styles.popupTitle}>
          <b>Move to folder</b>
          {foldersWithoutCurrentAndUnassigned.length === 0 && (
          <div className={styles.popupSubTitle}>
            There are no folders yet. Create your first folder
          </div>
          )}
        </div>
        {foldersWithoutCurrentAndUnassigned.length > 0 && (
        <div className={styles.popupFields}>
          <RadioSelect
            options={foldersWithoutCurrentAndUnassigned}
            placeholder="Search..."
            onChange={(value) => setSelectedFolderToMove(value)}
          />
        </div>
        )}
        <div className={styles.popupCenterButtons}>
          {foldersWithoutCurrentAndUnassigned.length > 0 && (
          <>
            <Button
              type="primaryBlue"
              disabled={!selectedFolderToMove}
              onClick={() => onSelectedFolderToMove()}
            >
              Add to selection
            </Button>
            <div className={styles.addOrTextOnBorder}><span>OR</span></div>
          </>
          )}
          <Button
            type="secondaryBlue"
            onClick={() => updateActionType(folderActionsKeys.createNewFolder)}
            onMouseDown={(e) => e.preventDefault()}
          >
            Add to a new folder
          </Button>
        </div>
      </div>
      )}
    </Page>
  );
}

export default inject(
  ({
    reportsStore: {
      createNewFolderRequest,
      renameFolderRequest,
      foldersList,
      addReportToFolderRequest,
      updateReportFolder,
    },
  }) => ({
    foldersList,
    createNewFolderRequest,
    renameFolderRequest,
    addReportToFolderRequest,
    updateReportFolder,
  }),
  observer
)(FolderActionsPopups);
