exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".settings-popup__settings-popup-3V4{top:55px;left:unset;right:0;width:364px;min-height:0!important;box-sizing:border-box;z-index:100;padding:10px!important}.settings-popup__dot-icon-2JF,.settings-popup__settings-icon-2Dd{width:23px;height:23px;background-position:50%;background-repeat:no-repeat;background-size:contain}.settings-popup__dot-icon-2JF:hover,.settings-popup__settings-icon-2Dd:hover{cursor:pointer;opacity:.5}.settings-popup__settings-container-1eX{display:flex;justify-content:center;align-items:center;height:32px}.settings-popup__settings-item-3Mp{display:flex;align-items:center;font-size:14px;flex-direction:row;justify-content:space-between;padding:10px 12px;border-radius:4px}.settings-popup__settings-item-3Mp:hover{background-color:#f3f5fa}.settings-popup__tooltipIcon-Hhy{margin-right:auto;margin-left:4px}.settings-popup__optionRenderer-204{display:flex;gap:4px;flex-direction:column}.settings-popup__optionDescription-9Jl{color:#737ea4;font-size:12px;font-weight:400}.settings-popup__optionLabel-1zZ{color:#2a2f45;font-size:14px;font-weight:400}.settings-popup__attributionModelBottomLink-2Ym{color:#4d91fc;text-decoration:underline;cursor:pointer;margin:6px 18px 20px;display:block}.settings-popup__attributionModelBottomLink-2Ym:hover{text-decoration:none}[data-icon=\"header:settings\"]{background-image:url(\"/assets/header/settings-icon.svg\")}[data-icon=\"header:dotmore\"]{background-image:url(\"/assets/header/dots-more.svg\")}", ""]);

// exports
exports.locals = {
	"settings-popup": "settings-popup__settings-popup-3V4",
	"settingsPopup": "settings-popup__settings-popup-3V4",
	"settings-icon": "settings-popup__settings-icon-2Dd",
	"settingsIcon": "settings-popup__settings-icon-2Dd",
	"dot-icon": "settings-popup__dot-icon-2JF",
	"dotIcon": "settings-popup__dot-icon-2JF",
	"settings-container": "settings-popup__settings-container-1eX",
	"settingsContainer": "settings-popup__settings-container-1eX",
	"settings-item": "settings-popup__settings-item-3Mp",
	"settingsItem": "settings-popup__settings-item-3Mp",
	"tooltipIcon": "settings-popup__tooltipIcon-Hhy",
	"tooltipIcon": "settings-popup__tooltipIcon-Hhy",
	"optionRenderer": "settings-popup__optionRenderer-204",
	"optionRenderer": "settings-popup__optionRenderer-204",
	"optionDescription": "settings-popup__optionDescription-9Jl",
	"optionDescription": "settings-popup__optionDescription-9Jl",
	"optionLabel": "settings-popup__optionLabel-1zZ",
	"optionLabel": "settings-popup__optionLabel-1zZ",
	"attributionModelBottomLink": "settings-popup__attributionModelBottomLink-2Ym",
	"attributionModelBottomLink": "settings-popup__attributionModelBottomLink-2Ym"
};