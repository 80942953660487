import React, { useEffect } from 'react';
import _ from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button } from '@infinigrow/libs';

import FeatureFlags from 'components/common/FeatureFlags';
import {
  getAllMappingOptions,
  getUtmsOptionsForMapping,
  operationOptions,
} from 'components/utils/mappingRules';

import style from 'styles/users/users-popup.css';

const styles = style.locals || {};

function EventsTimelineTooltip({
  data,
  isOffline = false,
  leadSourcesIdToLabelMap = {},
  customUtmsWhitelist,
  unmappedButton,
  index,
  flags,
  customFieldsIdToLabelMap = {},
}) {
  useEffect(() => {
    style.use();

    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  const offlineTooltip = {
    campaign: 'Campaign',
    mappedCampaign: 'Mapped Campaign',
    mappedByUtm: 'Mapped Using This UTM Campaign',
  };

  const defaultTooltip = {
    referrer: 'Referrer',
    device: 'Device',
    browser: 'Browser',
    os: 'Operating system',
    mappedCampaign: 'Mapped Campaign',
    mappedByUtm: 'Mapped Using This UTM Campaign',
  };

  for (const [fieldId, fieldLabel] of Object.entries(leadSourcesIdToLabelMap)) {
    offlineTooltip[`${fieldId}`] = fieldLabel;
    defaultTooltip[`${fieldId}`] = fieldLabel;
  }

  const utmsOptions = getUtmsOptionsForMapping({ customUtmsWhitelist, withUtmSuffix: true });
  for (const utm of utmsOptions) {
    offlineTooltip[`${utm.value}`] = utm.label;
    defaultTooltip[`${utm.value}`] = utm.label;
  }

  const tooltipContent = isOffline ? offlineTooltip : defaultTooltip;
  const tooltipFilteredFields = Object.keys(tooltipContent).filter((field) => data[field] || _.get(data, ['leadSources', field]) || _.get(data, ['utms', field]));

  const checkIfFieldsCanBeMapped = () => {
    const leadSources = _.get(data, ['leadSources']);
    if (leadSources) {
      return Object.values(leadSources).every((item) => item.length === 1);
    }

    const utms = _.get(data, ['utms']);
    if (utms) {
      return Object.values(utms).every((item) => item.length === 1);
    }

    return true;
  };

  const onClickTooltipButton = () => {
    const touchpointFields = {};
    const columns = [];
    const mappingOptions = getAllMappingOptions({ leadSourcesIdToLabelMap, customUtmsWhitelist });
    for (const field of tooltipFilteredFields) {
      const isFieldOptionExists = mappingOptions.some((option) => option.value === field);
      if (!isFieldOptionExists) {
        continue;
      }

      touchpointFields[field] = data[field] || data.leadSources[field]?.[0] || data.utms[field][0];
      columns.push({
        id: field,
        header: field,
        cell: touchpointFields[field],
        sortable: true,
      });
    }

    unmappedButton.action({
      touchpointFields, isShow: true, leadSourcesIdToLabelMap, data, columns, index,
    });
  };

  const isFieldsCanBeMapped = checkIfFieldsCanBeMapped();

  function getMappingRuleLine({ rule }) {
    const { param, operation, value } = rule;
    const utmOption = utmsOptions.find((option) => option.value === param);
    const parameter = leadSourcesIdToLabelMap[param] || customFieldsIdToLabelMap[param] || utmOption?.label || param;
    const operationLabel = operationOptions.find((opt) => opt.value === operation)?.label;

    return `${parameter} ${operationLabel} ${value}`;
  }

  return (
    <>
      <div className={styles.tooltipAlignLeft}>
        {data.mappingRule?.length > 0 && (
        <>
          <div>
            <strong>Mapping rule - </strong>
            {data.mappingRule.map((rule) => getMappingRuleLine({ rule })).join(' AND ')}
          </div>
          <br />
        </>
        )}
        {tooltipFilteredFields.map((field) => (
          <div>
            <b>{tooltipContent[field]}</b>
            {' - '}
            {data[field] || data.leadSources[field]?.join(', ') || data.utms[field]?.join(', ')}
          </div>
        ))}
      </div>
      <FeatureFlags flag={flags.analyzeJourneysPopupUnmappedChannelsButton}>
        {!_.isEmpty(unmappedButton) && !isFieldsCanBeMapped
          && (
          <div className={styles.tooltipMessage}>
            <div className={styles.infoIconBlue} />
            <div className={styles.tooltipMessageWithlink}>
              Map this touchpoint through
              <span onClick={() => window.open('/settings/ops/unmapped', '_blank')}>
                Ops.
              </span>
            </div>
          </div>
          )}
        {unmappedButton.disabled
          && (
          <div className={styles.tooltipMessage}>
            <div className={styles.checkedIconGreen} />
            Mapped. The new mapping will be updated within one hour.
          </div>
          )}
        {!_.isEmpty(unmappedButton) && !unmappedButton.disabled && isFieldsCanBeMapped
          && (
          <Button
            type="primaryBlue"
            className={styles.tooltipButton}
            onClick={() => onClickTooltipButton()}
          >
            {unmappedButton.text}
          </Button>
          )}
      </FeatureFlags>
    </>
  );
}

export default withLDConsumer()(EventsTimelineTooltip);
