import React, { useEffect } from 'react';
import classnames from 'classnames';

import Spinner from 'components/pages/journeys/Spinner';

import style from 'styles/common/carouselArrowNavigation.css';

const styles = style.locals;

export default function ArrowWithLoadMoreFunctionality({
  onClick, isLoadingMore, outerNavigationArrows,
}) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  if (isLoadingMore) {
    return (
      <div className={outerNavigationArrows ? styles.loaderWrapper : undefined}>
        <Spinner />
      </div>
    );
  }

  return (
    <button
      type="button"
      className={classnames(
        styles.arrow,
        outerNavigationArrows ? styles.arrowAbsolute : null,
        styles.arrowRight,
        styles.loadMoreArrow
      )}
      onClick={onClick}
      aria-label="load more"
    />
  );
}
