import * as channelsUtils from 'components/utils/channels';
import {
  UNKNOWN_CHANNEL,
  DIRECT_CHANNEL,
  UNKNOWN_LABEL,
  DIRECT_LABEL,
  getDirectIcon,
  getDirectNickname,
  getNickname,
  isDirectOrUnknown,
} from 'components/utils/channels';

export const getChannelsWithNicknames = () => [
  ...channelsUtils.getChannelsWithNicknames(), {
    value: DIRECT_CHANNEL,
    label: DIRECT_LABEL,
    category: DIRECT_LABEL,
  }, {
    value: UNKNOWN_CHANNEL,
    label: UNKNOWN_LABEL,
    category: UNKNOWN_LABEL,
  },
];
export const getChannelIcon = (channel) => (isDirectOrUnknown(channel)
  ? getDirectIcon(channel)
  : channelsUtils.getChannelIcon(channel));

export const getChannelNickname = (channel) => (isDirectOrUnknown(channel)
  ? getDirectNickname(channel)
  : getNickname(channel));

export const getCategoryIcon = (val) => {
  const categoriesWithIcons = ['social', 'email', 'business-directories', 'paid-search', 'organic-search', 'calendar'];
  const categoryName = val.toLowerCase().replace(' ', '-');
  return `category:${categoriesWithIcons.includes(categoryName) ? categoryName : 'generic'}`;
};
