import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Anomaly from 'components/pages/settings/actions/actionPopup/triggerSection/anomaly';
import GoalsTrigger from 'components/pages/settings/actions/actionPopup/triggerSection/goalsTrigger';
import AccountEngagementTrigger from 'components/pages/settings/actions/actionPopup/triggerSection/accountEngagementTrigger';

import {
  actionTriggerTypesOptions, actionTriggerTypes, sectionTypes, emptyNotification,
} from 'components/pages/settings/actions/enums';
import { isTriggerRulesAreValid } from 'components/pages/settings/actions/logic/actions';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function TriggerSection({
  triggerType,
  setTriggerType,
  isTriggerSectionOpen,
  setSectionToOpen,
  onClickContinue,
  triggerRules,
  setTriggerRules,
  setNotifications,
  isActionsAreValid,
  setIsActionsAreValid,
}) {
  useStyles([style]);

  useEffect(() => {
    const isNewTriggerRulesAreValid = isTriggerRulesAreValid({ triggerType, triggerRules });
    if (isNewTriggerRulesAreValid !== isActionsAreValid) {
      setIsActionsAreValid(isNewTriggerRulesAreValid);
    }
  }, [triggerRules, triggerType]);

  function onChangesetTriggerType(newTriggerType) {
    setTriggerType(newTriggerType);
    setTriggerRules([]);
    setNotifications([emptyNotification]);
  }

  return (
    <div className={classnames(styles.bodySection, isTriggerSectionOpen && styles.bodySectionOpen)}>
      <div
        className={styles.bodyTitleWrapper}
        onClick={() => (isTriggerSectionOpen ? setSectionToOpen(null) : setSectionToOpen(sectionTypes.trigger))}
      >
        <div
          className={classnames(styles.bodyTitle, isTriggerSectionOpen ? null : styles.collapsedTitle)}
        >
          1. Set a trigger
        </div>
        {isActionsAreValid ? (<div data-testid="checkedIconGreen" className={styles.checkedIconGreen} />) : null}
      </div>
      {isTriggerSectionOpen ? (
        <>
          <div className={styles.row}>
            <div className={styles.text}>
              Trigger type
            </div>
            <Dropdown
              selectedKey={triggerType}
              options={actionTriggerTypesOptions}
              onChange={(newTriggerType) => onChangesetTriggerType(newTriggerType.value)}
              controlWidth={300}
              placeholder="Select"
            />
          </div>
          {triggerType === actionTriggerTypes.anomaly ? (
            <Anomaly
              anomalyData={triggerRules}
              updateAnomalyData={(newAnomalyData) => setTriggerRules(newAnomalyData)}
            />
          ) : null}

          {triggerType === actionTriggerTypes.goalsTracking ? (
            <GoalsTrigger
              triggerRules={triggerRules}
              updateTriggerRules={(newTriggerRules) => setTriggerRules(newTriggerRules)}
            />
          ) : null}

          {triggerType === actionTriggerTypes.accountEngagement ? (
            <AccountEngagementTrigger
              triggerRules={triggerRules}
              updateTriggerRules={(newTriggerRules) => setTriggerRules(newTriggerRules)}
            />
          ) : null}

          <Button
            type="primaryBlue"
            onClick={() => {
              onClickContinue({
                triggerType,
                triggerRules,
              });
            }}
            disabled={!isActionsAreValid}
          >
            Continue
          </Button>
        </>
      ) : null}
    </div>
  );
}

export default TriggerSection;
