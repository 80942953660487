import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import InfoMarker from 'components/pages/InfoMarker';
import Page from 'components/Page';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import { addNewOptions } from 'components/pages/plan/campaignPopups/enums';

import style from 'styles/plan/campaignPopups.css';

const styles = style.locals || {};

export default function AddNewCampaignSelection({ onClose, onSave }) {
  const [selectedOption, setSelectedOption] = useState();

  const refPopup = useRef();

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  useOnClickOutside(refPopup, () => {
    onClose();
  });

  return (
    <Page className={styles.pageWrapper} popup>
      <div ref={refPopup} className={styles.wrapper}>
        <div className={classnames(styles.header, styles.title)}>
          Add new
          <div
            className={styles.closeButton}
            onClick={onClose}
          />
        </div>
        <div className={styles.bodyRow}>
          <div
            className={classnames(styles.selectOptionWrapper, selectedOption === addNewOptions.newCampaign ? styles.selectedOption : null)}
            onClick={() => setSelectedOption(addNewOptions.newCampaign)}
          >
            <div className={styles.icon} data-icon="campaignPopup:newCampaign" data-testid="newCampaignIcon" />
            Campaign
          </div>
          <div
            className={classnames(styles.selectOptionWrapper, selectedOption === addNewOptions.costPlaceholder ? styles.selectedOption : null)}
            onClick={() => setSelectedOption(addNewOptions.costPlaceholder)}
          >
            <div className={styles.icon} data-icon="campaignPopup:costPlaceholder" data-testid="costPlaceholderIcon" />
            <div className={styles.row}>
              Cost Placeholder
              <InfoMarker tooltipText={'Use cost placeholders to add an expected spend to a tag. For example, if you tagged campaigns that contain "US", and would like to add an expected cost to US LinkedIn campaigns, add a cost placeholder with the name "US" to the LinkedIn channel.'} />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            type="secondaryBlue"
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="primaryBlue"
            onClick={() => onSave(selectedOption)}
            disabled={!selectedOption}
          >
            Continue
          </Button>
        </div>
      </div>
    </Page>
  );
}
