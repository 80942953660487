import React from 'react';
import ReactDOMServer from 'react-dom/server';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';

import { getPills } from 'components/widgets/optimalJourney/journeyCanvas/logic/canvasPin';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function CanvasPin({ pills, currentJourneySection, previousJourneySection }) {
  useStyles([style]);

  function getTooltipAsHtml({ pillObject }) {
    return ReactDOMServer.renderToStaticMarkup(pillObject.tooltip);
  }

  const pillsArray = getPills({ pills, currentJourneySection, previousJourneySection });

  return (
    <div className={styles.pillsContainer}>
      {
        pillsArray.map((pillObject) => (
          <div className={styles.tooltipWrapper}>
            <Tooltip
              id="pill-tooltip"
              tip={getTooltipAsHtml({ pillObject })}
              html
              TooltipProps={{
                multiline: true,
                clickable: true,
                event: 'click',
                globalEventOff: 'click',
                className: classnames(styles.clickableTooltip, styles.tooltip),
              }}
            >
              <div className={styles.pillContainer} style={{ backgroundColor: pillObject.color }}>
                <div className={styles.segmentLabel}>{pillObject.label}</div>
                <div className={styles.segmentValue}>{`${pillObject.value}${pillObject.measurementUnit}`}</div>
              </div>
            </Tooltip>
          </div>
        ))
      }
      {pillsArray.length ? <div className={styles.pinVerticalLine} /> : null}
    </div>
  );
}
