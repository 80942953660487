exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".tooltip__tooltip-3pq{max-width:255px;text-transform:none;text-align:center;word-break:break-word;opacity:1!important;white-space:normal;font-weight:400}.tooltip__tooltip--wide-29y{max-width:700px}", ""]);

// exports
exports.locals = {
	"tooltip": "tooltip__tooltip-3pq",
	"tooltip": "tooltip__tooltip-3pq",
	"tooltip--wide": "tooltip__tooltip--wide-29y",
	"tooltipWide": "tooltip__tooltip--wide-29y"
};