exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".mappings__container-1Jk{display:flex;flex-direction:column;width:100%}", ""]);

// exports
exports.locals = {
	"option-select": "mappings__option-select-2Jz",
	"optionSelect": "mappings__option-select-2Jz",
	"container": "mappings__container-1Jk",
	"container": "mappings__container-1Jk"
};