import {
  camelCase, cloneDeep, isNil, isEmpty,
} from 'lodash';

import userStore from 'stores/userStore';

import { getItemLabelWithNoValue } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import {
  attributionMetrics, defaultColumnOrderPerMetricType, columnsBlackList, webVisits, pageViews, segmentsTimeOptions,
} from 'components/pages/analyze/AttribuitonTable/enums';
import { isContentSegment } from 'components/pages/analyze/OverviewTab/logic';
import { getGroupedUserMetrics } from 'components/utils/logic/utils';
import { skeletonTableRows } from 'components/common/enums';
import { isShowCostMetric, tableDataWithFiltersByColumns } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';

export const searchInAttributionTable = ({
  tableData, searchColumn, segment, searchValue, segmentLabel,
}) => {
  if (!tableData) {
    return [];
  }

  return tableData.filter((item) => {
    const itemLabel = getItemLabelWithNoValue({ value: item[searchColumn], segment, segmentLabel });
    return itemLabel?.toLowerCase().includes(searchValue.toLowerCase());
  });
};

export const defaultFirstAndSecondColumns = ['firstSegment', 'secondSegment'];

export function getReorderColumnsForStage(reorderColumns, selectedStageKey, allColumns) {
  const hasSavedColumnsForReorder = reorderColumns[selectedStageKey]?.length;
  if (hasSavedColumnsForReorder) {
    const updateReorderSavedItem = new Set([...defaultFirstAndSecondColumns, ...reorderColumns[selectedStageKey], ...allColumns]);
    return Array.from(updateReorderSavedItem);
  }
  const defaultReorderColumns = ['channels', 'cost', 'touched', 'attributed'];
  const updateReorderUnsavedItem = new Set([...defaultFirstAndSecondColumns, ...defaultReorderColumns, ...allColumns, 'estimatedCost']);
  return Array.from(updateReorderUnsavedItem);
}

export function getSavedFromLocalStorage({ localStorageKey }) {
  let savedItemsFromLocalStorage = {};
  const storageItem = localStorage.getItem(localStorageKey);
  if (storageItem) {
    savedItemsFromLocalStorage = JSON.parse(storageItem);
  }
  return savedItemsFromLocalStorage;
}

export function getAllColumnOptionsAndGroupedOptions({ isAttribution, selectedSegments }) {
  const isHavingContentSegment = isContentSegment({ segment: selectedSegments.firstSegment?.value }) || isContentSegment({ segment: selectedSegments.secondSegment?.value });
  const groupedUserMetrics = getGroupedUserMetrics({ userMetrics: userStore.userMetrics })?.filter((group) => group.groupName !== 'cost');
  const allColumnOptions = [];

  const showCostMetric = isShowCostMetric({ firstSegmentValue: selectedSegments.firstSegment?.value, secondSegmentValue: selectedSegments.secondSegment?.value });

  for (const groupMetrics of groupedUserMetrics) {
    const optionsToAddForGroup = [];

    for (const option of groupMetrics.options) {
      if (option.metricType === webVisits || option.metricType === pageViews) {
        option.value = camelCase(`attributed ${option.value}`);
      }

      if (option.metricType === 'funnelStage') {
        option.value = camelCase(`touched ${option.value}`);
        option.label = `Touched ${option.label}`;
      }

      option.id = option.value;
      option.isDisabled = isAttribution ? false : attributionMetrics.some((attributedMetric) => option.value.includes(attributedMetric));

      if (isHavingContentSegment && option.value.toLowerCase().includes('visits')) {
        option.isDisabled = true;
      }

      if (!isHavingContentSegment && option.value.toLowerCase().includes('pageviews')) {
        option.isDisabled = true;
      }

      if (option.metricType === 'funnelStage') {
        const attributedOption = cloneDeep(option);
        attributedOption.value = option.value.replace('touched', 'attributed');
        attributedOption.id = option.id.replace('touched', 'attributed');
        attributedOption.label = option.label.replace('Touched', 'Attributed');
        attributedOption.isDisabled = isAttribution ? false : attributionMetrics.some((attributedMetric) => attributedOption.value.includes(attributedMetric));
        optionsToAddForGroup.push(attributedOption);
      }
    }

    groupMetrics.options = groupMetrics.options.concat(...optionsToAddForGroup).filter((option) => !columnsBlackList.includes(option.value));

    if (!showCostMetric) {
      groupMetrics.options = groupMetrics.options.filter((option) => option.metricType !== 'costPerFunnelStage');
    }

    const orderedOptions = sortMetricsByType({ metricsArray: groupMetrics.options });
    allColumnOptions.push(...orderedOptions);
  }

  return { groupedUserMetrics, allColumnOptions };
}

export function sortMetricsByType({ metricsArray = [] }) {
  metricsArray.sort((a, b) => {
    const typeA = a.metricType;
    const typeB = b.metricType;

    const indexA = defaultColumnOrderPerMetricType.indexOf(typeA);
    const indexB = defaultColumnOrderPerMetricType.indexOf(typeB);

    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });

  return metricsArray;
}

export function mergeAllFunnelsTableData({ tableDataByFunnel = {}, selectedStageKey }) {
  if (isEmpty(tableDataByFunnel)) {
    return [];
  }

  const allTableData = new Map();
  const rowUniqueKeys = ['firstSegment', 'secondSegment'];

  for (const [funnelName, funnelDataRows] of Object.entries(tableDataByFunnel)) {
    if (!userStore.userFunnels.includes(funnelName) && funnelName !== webVisits && funnelName !== pageViews) {
      continue;
    }

    for (const funnelDataRow of funnelDataRows) {
      const rowMapKey = rowUniqueKeys.map((requireKey) => funnelDataRow[requireKey]).join('-');
      const newFunnelRow = {
        ...funnelDataRow,
        [camelCase(`attributed ${funnelName}`)]: funnelDataRow.attributed,
        [camelCase(`touched ${funnelName}`)]: funnelDataRow.touched,
        [camelCase(`cost per ${funnelName}`)]: funnelDataRow.efficiency,
        [camelCase(`growthPerMetric ${funnelName}`)]: funnelDataRow.growthPerMetric,
      };
      delete newFunnelRow.attributed;
      delete newFunnelRow.touched;
      delete newFunnelRow.efficiency;

      if (!isNil(newFunnelRow.pipeline)) {
        newFunnelRow.newPipeline = funnelDataRow.pipeline;
      }

      if (!isNil(newFunnelRow.revenue)) {
        newFunnelRow.newBookings = funnelDataRow.revenue;
      }

      let existingRow = allTableData.get(rowMapKey);

      if (funnelName !== selectedStageKey && existingRow?.channels?.length) {
        delete newFunnelRow.channels;
      }

      if (selectedStageKey && newFunnelRow.secondSegment) {
        const dataForCostBySelectedStageKey = tableDataByFunnel[selectedStageKey];
        const foundIndexCost = dataForCostBySelectedStageKey?.findIndex((row) => row.firstSegment === newFunnelRow.firstSegment && row.secondSegment === newFunnelRow.secondSegment);
        newFunnelRow.cost = dataForCostBySelectedStageKey[foundIndexCost]?.cost || 0;
      }

      if (existingRow) {
        existingRow = {
          ...existingRow,
          ...newFunnelRow,
        };
        allTableData.set(rowMapKey, existingRow);
        continue;
      }

      allTableData.set(rowMapKey, newFunnelRow);
    }
  }

  return Array.from(allTableData.values());
}

export function filterDataAccordingActiveColumns({ tableData, activeColumns, filtersByColumn = {} }) {
  const isOnlyFirstAndSecondSegment = activeColumns.every((column) => defaultFirstAndSecondColumns.includes(column.id));

  const columnsToKeep = ['growthPerMetric', 'channels'];
  if (!isOnlyFirstAndSecondSegment) {
    columnsToKeep.push(...defaultFirstAndSecondColumns);
  }
  const columnsIds = activeColumns.map((column) => column.id);
  const columnsWithoutColumnsToKeep = columnsIds.filter((columnId) => !columnsToKeep.includes(columnId));

  if (tableData.length === skeletonTableRows && tableData[0].skeletonRow) {
    return tableData;
  }

  const filteredTableData = tableData.filter((dataRow) => columnsWithoutColumnsToKeep.some((column) => dataRow[column]));

  const filtersByColumnToKeep = {};
  for (const columnId of columnsIds) {
    if (filtersByColumn[columnId]) {
      filtersByColumnToKeep[columnId] = filtersByColumn[columnId];
    }
  }
  return tableDataWithFiltersByColumns({ tableData: filteredTableData, filtersByColumn: filtersByColumnToKeep, isRemoveFunnelKey: false });
}

export function getColumnMetricNickName({ metric, isSingular }) {
  if (metric === 'attributedWebVisits') {
    return 'Visits';
  }
  if (metric === 'attributedPageViews') {
    return 'Page views';
  }
  if (metric === 'newBookings') {
    return 'Attributed revenue';
  }
  if (metric === 'newPipeline') {
    return 'Attributed pipeline';
  }

  return userStore.getMetricNickname({ metric, isSingular });
}

export function addDisabledBySegmentsToOptions({ options, selectedSegments }) {
  return options.map((option) => {
    if (option.value === selectedSegments.firstSegment || option.value === selectedSegments.secondSegment) {
      return { ...option, disabled: true };
    }
    return option;
  });
}

export function setSegmentOptionsTimeDisabled({ selectedSegments, firstSegmentOptions, secondSegmentOptions }) {
  let updatedFirstSegmentOptions = [...firstSegmentOptions];
  let updatedSecondSegmentOptions = [...secondSegmentOptions];

  if (segmentsTimeValues.includes(selectedSegments.firstSegment.value)) {
    updatedSecondSegmentOptions = disableTimeValue({ options: updatedSecondSegmentOptions });
  }

  if (selectedSegments.secondSegment && segmentsTimeValues.includes(selectedSegments.secondSegment.value)) {
    updatedFirstSegmentOptions = disableTimeValue({ options: updatedFirstSegmentOptions });
  }

  return {
    firstSegmentOptions: updatedFirstSegmentOptions,
    secondSegmentOptions: updatedSecondSegmentOptions,
  };
}

function disableTimeValue({ options }) {
  return options.map((option) => {
    if (segmentsTimeValues.includes(option.value)) {
      return { ...option, disabled: true };
    }
    return option;
  });
}

export const segmentsTimeValues = segmentsTimeOptions.map((option) => option.value);
