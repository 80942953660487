import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';
import { groupBy, sumBy } from 'lodash';
import PropTypes from 'prop-types';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { indicatorOptions, NEW_FUNNEL_PREFIX } from 'components/utils/indicators';
import Toggle from 'components/controls/Toggle';
import ChartModule from 'components/pages/plan/ChartModule';
import Tooltip from 'components/controls/Tooltip';
import ToggleButton from 'components/controls/ToggleButton';
import FeatureFlags from 'components/common/FeatureFlags';
import { viewStyleAccordingDataType } from 'components/pages/plan/logic/enums';
import { generalCampaignName } from 'stores/plan/logic/enums';

import style from 'styles/plan/planned-vs-actual-off-track.css';

const allOptions = [
  {
    text: 'Category',
    value: viewStyleAccordingDataType.CATEGORY,
  },
  {
    text: 'Channels',
    value: viewStyleAccordingDataType.CHANNEL,
  },
  {
    text: 'Campaigns',
    value: viewStyleAccordingDataType.CAMPAIGN,
  },
];

class PlannedVsActualOffTrack extends Component {
  style = style;

  static propTypes = {
    rawCampaigns: PropTypes.arrayOf(PropTypes.object),
    isCurrentMonth: PropTypes.bool.isRequired,
    containerClass: PropTypes.any,
    initialViewType: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.number),
    container: PropTypes.string,
    itemTopContainer: PropTypes.string,
    chartContainer: PropTypes.string,
    wrapperClass: PropTypes.any,
    isPlan: PropTypes.bool,
    isExpanded: PropTypes.bool,
    campaigns: PropTypes.array,
    channels: PropTypes.array,
  };

  static defaultProps = {
    campaigns: [],
    channels: [],
    isExpanded: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndicator: `${NEW_FUNNEL_PREFIX}1`,
      viewStyle: props.initialViewType,
    };
  }

  get isCategory() {
    return this.state.viewStyle === viewStyleAccordingDataType.CATEGORY;
  }

  get isChannel() {
    return this.state.viewStyle === viewStyleAccordingDataType.CHANNEL;
  }

  get isPlan() {
    return !this.props.spendingOnly;
  }

  updateIndicator = (indicator) => this.setState({ activeIndicator: indicator.value });

  parsedItems = (items, indicator, showPacing) => {
    const isSpending = indicator === 'spending';
    return items
      .map((item) => {
        const field = item[indicator] || {};
        const {
          actual, planned, planVsActual, pacing,
        } = field;
        const { channel, isAutomatic } = item;

        let { title } = item;
        if (this.isChannel) {
          title = getChannelNickname(channel);
        }

        if (field && !isNaN(actual) && !isNaN(planned)) {
          let diff = isSpending ? planVsActual : actual - planned;
          let division = actual / planned * 100;

          if (showPacing) {
            diff = pacing - planned;
            division = pacing / planned * 100;
          }
          return {
            isAutomatic,
            title,
            value: diff,
            division: planned ? division : null,
            actual: showPacing ? pacing : actual,
            planned,
          };
        }
        return {
          title,
          value: 0,
        };
      })
      .filter((item) => !!item.value)
      .filter((item) => ((!isSpending) ? item.value < 0 : true))
      .filter((item) => ((!this.isChannel && !this.isCategory) ? item.title !== generalCampaignName : true))
      .sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
      .slice(0, 5);
  };

  masterChannels = (channelsData) => this.groupBy(channelsData, 'channel');

  categoryData = (channelsData) => this.groupBy(channelsData, 'category');

  groupBy = (channelsData, field) => {
    const channelsByCategories = groupBy(channelsData, (c) => c[field]);
    return Object.keys(channelsByCategories).map((key, channelsGroup) => {
      channelsGroup = channelsByCategories[key];
      const category = channelsGroup.reduce((acc, channel) => {
        ['spending'].forEach((indicator) => {
          if (!isNaN(channel[indicator].planned) && !isNaN(channel[indicator].actual)) {
            acc[indicator] = {
              actual: sumBy(channelsGroup, (ch) => ch[indicator].actual),
              planned: sumBy(channelsGroup, (ch) => ch[indicator].planned),
              planVsActual: sumBy(channelsGroup, (ch) => ch[indicator].planVsActual || 0),
              pacingVsPlanned: sumBy(channelsGroup, (ch) => ch[indicator].pacingVsPlanned || 0),
              pacing: sumBy(channelsGroup, (ch) => ch[indicator].pacing || 0),
              isActualEmpty: channelsGroup.reduce((acc, ch) => acc && ch[indicator].isActualEmpty, true),
            };
          }
        });
        return acc;
      }, {});
      category.isAutomatic = channelsGroup.reduce((acc, ch) => acc && ch.isAutomatic, true);
      category.title = field === 'channel' ? getChannelNickname(key) : key;
      category.category = channelsGroup[0] && channelsGroup[0].category;
      return category;
    });
  };

  tooltip = `
    <div style="border-radius: 5px; display: flex; flex-direction: column; align-items: center; padding: 5px 0">
      <div style="font-size: 12px; color: #99a4c2; margin-bottom: 7px">
        Spend
      </div>
      <span style="font-size: 16px; font-weight: normal; text-transform: lowercase">
        <span style="text-transform: uppercase">P</span>lan vs pacing for
      </span>
    </div>
  `;

  render() {
    const { activeIndicator } = this.state;
    const {
      isCurrentMonth, options, spendingOnly, container,
      itemTopContainer, chartContainer, isMasterRegion,
      channels, campaigns, wrapperClass, toggleExpand, isExpanded, flags,
    } = this.props;

    const channelsParsed = isMasterRegion ? this.masterChannels(channels) : channels;

    const indicators = indicatorOptions();
    let items = campaigns;
    if (this.isCategory) {
      items = this.categoryData(channelsParsed);
    }
    if (this.isChannel) {
      items = channelsParsed;
    }

    const showPacing = isCurrentMonth;
    return (
      <div
        className={classnames(container, this.classes.container, {
          [this.classes.containerCollapsed]: !isExpanded,
        })}
      >
        <div className={wrapperClass}>
          <div className={itemTopContainer}>
            <div
              className={classnames(this.classes.itemTitle, {
                [this.classes.itemTitleBold]: spendingOnly,
              })}
            >
              {spendingOnly ? (
                <Tooltip
                  tip={this.tooltip}
                  id="offTrackNavigateTip"
                >
                  Off-Track
                </Tooltip>
              ) : `Off Track - Plans vs ${isCurrentMonth ? 'Pacing for' : 'Actual'}`}
            </div>
            {isExpanded && (
              <Toggle
                options={allOptions.filter((o) => options.includes(o.value))}
                selectedValue={this.state.viewStyle}
                onClick={(viewStyle) => this.setState({ viewStyle })}
                bordered={spendingOnly}
              />
            )}
          </div>
          {this.isPlan && (
            <ToggleButton
              toggleExpand={() => toggleExpand(!isExpanded)}
              isExpanded={isExpanded}
              title="Insights"
            />
          )}
        </div>
        {isExpanded && (
          <div className={chartContainer}>
            <ChartModule
              items={this.parsedItems(items, 'spending', showPacing)}
              indicators={indicators}
              isCurrentMonth={isCurrentMonth}
              format
              showTitle={!spendingOnly}
              small={spendingOnly}
              showPacing={showPacing}
            />
            <FeatureFlags flag={flags.offTrackPlansVsPacingForPlanPlanVsActualRightDropdown}>
              {!spendingOnly && (
                <ChartModule
                  items={this.parsedItems(items, activeIndicator, showPacing)}
                  activeIndicator={userStore.getMetricNickname({ metric: this.state.activeIndicator })}
                  indicators={indicators}
                  updateIndicator={this.updateIndicator}
                  isCurrentMonth={isCurrentMonth}
                  showPacing={showPacing}
                />
              )}
            </FeatureFlags>
          </div>
        )}
      </div>
    );
  }
}

export default withLDConsumer()(PlannedVsActualOffTrack);
