import uniq from 'lodash/uniq';
import { filterKinds } from 'components/utils/filters/logic';

export const campaignsConfig = {
  getOptions: ({ campaigns = [] }, options) => uniq(options.concat(campaigns)),
};

export const clientConfigs = {
  [filterKinds.CAMPAIGNS]: campaignsConfig,
};
