export const journeyBuildingBlocksTitle = 'Journey building blocks';

export const journeyStart = 'first';
export const keyChannels = 'channel';
export const keyJobTitles = 'jobTitle';
export const numberOfTouchpoints = 'numberOfTouchpoints';
export const keyPatterns = 'pattern';
export const stageVelocity = 'velocity';

export const buildingBlocksButtonsNameToTextMapping = {
  [journeyStart]: 'Journey start',
  [keyChannels]: 'Key Channels',
  [keyJobTitles]: 'Key Job Titles',
  [numberOfTouchpoints]: '# of Touchpoints',
  [keyPatterns]: 'Key patterns',
  [stageVelocity]: 'Stage velocity',
};

export const journeyBuildingBlocksButtonNamesMock = [
  journeyStart,
  keyChannels,
  keyJobTitles,
  numberOfTouchpoints,
  keyPatterns,
  stageVelocity,
];

export const journeyInsightsTitle = 'Journey insights';

export const questionsTypes = {
  highestConverting: 'highestConverting',
  mostCommon: 'mostCommon',
  fastest: 'fastest',
};

export const questionsTypesLabels = {
  [questionsTypes.highestConverting]: 'Highest converting',
  [questionsTypes.mostCommon]: 'Most common',
  [questionsTypes.fastest]: 'Fastest',
};

export const questionsTypesToMetricTypeMapping = {
  [questionsTypes.highestConverting]: 'conversionRate',
  [questionsTypes.mostCommon]: '',
  [questionsTypes.fastest]: 'velocity',
};

export const metricsTooltipsTypes = {
  journeys: 'journeys',
  touchpoints: 'touchpoints',
  contacts: 'contacts',
  avgSalesCycle: 'avgSalesCycle',
  avgDealSize: 'avgDealSize',
};

export const kpiFocusOptionsMockData = [
  {
    value: 'funnel1',
    label: 'Lead',
  },
  {
    value: 'funnel2',
    label: 'MQL',
  },
  {
    value: 'funnel3',
    label: 'SQL',
  },
  {
    value: 'funnel4',
    label: 'OPP',
  },
  {
    value: 'funnel5',
    label: 'Closed Won',
  },
];

export const questionsTypesOptions = Object.keys(questionsTypes).map((key) => ({
  value: key,
  label: questionsTypesLabels[key],
}));

export const unclickableItemsKeys = {
  kpiFocusLeads: 'kpiFocusLeads',
  questionKindMostCommon: 'questionKindMostCommon',
  kpiFocusMqls: 'kpiFocusMqls',
  kpiFocusSqls: 'kpiFocusSqls',
  questionKindFastest: 'questionKindFastest',
  buildingBlocksAmountTouchpoints: 'buildingBlocksAmountTouchpoints',
  kpiFocusOpps: 'kpiFocusOpps',
  buildingBlocksJourneyStart: 'buildingBlocksJourneyStart',
  buildingBlocksKeyPatterns: 'buildingBlocksKeyPatterns',
  buildingBlocksAddBlock: 'buildingBlocksAddBlock',
};

export const unclickableKpiFocusToOptionValue = {
  [unclickableItemsKeys.kpiFocusLeads]: 'funnel1',
  [unclickableItemsKeys.kpiFocusMqls]: 'funnel2',
  [unclickableItemsKeys.kpiFocusSqls]: 'funnel3',
  [unclickableItemsKeys.kpiFocusOpps]: 'funnel4',
};

export const unclickableQuestionKindToOptionValue = {
  [unclickableItemsKeys.questionKindMostCommon]: questionsTypes.mostCommon,
  [unclickableItemsKeys.questionKindFastest]: questionsTypes.fastest,
};

export const unclickableBuildingBlocksToButtonValue = {
  [unclickableItemsKeys.buildingBlocksJourneyStart]: journeyStart,
  [unclickableItemsKeys.buildingBlocksAmountTouchpoints]: numberOfTouchpoints,
  [unclickableItemsKeys.buildingBlocksKeyPatterns]: keyPatterns,
};
