export function getSelectedPreset({ presets, selectedOptions }) {
  if (selectedOptions.length === 0 || !presets || presets.length === 0) {
    return '';
  }

  for (const preset of presets) {
    const {
      label,
      options: presetOptions,
    } = preset;

    if (selectedOptions.length !== presetOptions.length) {
      continue;
    }

    if (presetOptions.every((option) => selectedOptions.includes(option))) {
      return label;
    }
  }

  return 'Custom';
}

export function getPresetDropdownOptions({
  presets,
  selectedPreset,
  selectedOptionsState,
}) {
  const presetOptions = [];

  if (!presets) {
    return presetOptions;
  }

  if (selectedPreset === 'Custom') {
    presetOptions.push({
      label: 'Custom',
      value: 'Custom',
      presetOptions: selectedOptionsState,
    });
  }

  for (const preset of presets) {
    presetOptions.push({
      label: preset.label,
      value: preset.value || preset.label,
      presetOptions: preset.options,
    });
  }

  return presetOptions;
}

export function getFilteredOptions({ options, searchValue }) {
  const result = [];

  if (!options) {
    return result;
  }

  for (const option of options) {
    const isGroup = !!option.options;

    const isLabelMatched = option.label?.toLowerCase().includes(searchValue?.toLowerCase());

    if (isLabelMatched) {
      result.push(option);
      continue;
    }

    if (isGroup) {
      const matchedGroup = {
        label: option.label,
        options: [],
      };

      for (const groupOption of option.options) {
        if (groupOption.label.toLowerCase().includes(searchValue.toLowerCase())) {
          matchedGroup.options.push(groupOption);
        }
      }

      if (matchedGroup.options.length > 0) {
        result.push(matchedGroup);
      }
    }
  }

  return result;
}

export function getIsAllOptionsSelected({ filteredOptions, selectedOptionsState }) {
  if (!filteredOptions || filteredOptions.length === 0 || !selectedOptionsState) {
    return false;
  }

  for (const { value, options: groupOptions } of filteredOptions) {
    const isGroup = !!groupOptions;

    if (!isGroup) {
      if (!selectedOptionsState.includes(value)) {
        return false;
      }
      continue;
    }

    for (const { value: groupOptionValue } of groupOptions) {
      if (!selectedOptionsState.includes(groupOptionValue)) {
        return false;
      }
    }
  }

  return true;
}

export function handleSaveSelected({
  options,
  presets,
  closePopup,
  resetSearchQuery,
  setSelectedOptions,
  selectedOptionsState,
}) {
  resetSearchQuery();
  const selectedOptionsStateSet = new Set(selectedOptionsState);

  let newSelectedOptions = [];

  for (const { options: presetOptions } of presets || []) {
    const isSelectedOptionsSameAsPreset = presetOptions.length === selectedOptionsStateSet.size && presetOptions.every((option) => selectedOptionsStateSet.has(option));
    if (isSelectedOptionsSameAsPreset) {
      newSelectedOptions = presetOptions;
    }
  }

  if (newSelectedOptions.length === 0) {
    for (const option of options) {
      const isGroup = !!option.options;

      if (isGroup) {
        for (const groupOption of option.options) {
          if (selectedOptionsStateSet.has(groupOption.value)) {
            newSelectedOptions.push(groupOption.value);
          }
        }

        continue;
      }

      if (selectedOptionsStateSet.has(option.value)) {
        newSelectedOptions.push(option.value);
      }
    }
  }

  setSelectedOptions(newSelectedOptions);
  closePopup();
}

export function handleSelectAll({
  prevSelected,
  filteredOptions,
  isAllOptionsSelected,
}) {
  const newSelectedSet = new Set(prevSelected);
  for (const { value, options: groupOptions } of filteredOptions) {
    const isGroup = !!groupOptions;

    if (!isGroup) {
      if (isAllOptionsSelected) {
        newSelectedSet.delete(value);
      } else {
        newSelectedSet.add(value);
      }
      continue;
    }

    for (const { value: groupOptionValue } of groupOptions) {
      if (isAllOptionsSelected) {
        newSelectedSet.delete(groupOptionValue);
      } else {
        newSelectedSet.add(groupOptionValue);
      }
    }
  }
  return [...newSelectedSet];
}

export function handleSelectOption({
  optionValue,
  prevSelected,
}) {
  const newSelectedOptions = new Set(prevSelected);
  if (newSelectedOptions.has(optionValue)) {
    newSelectedOptions.delete(optionValue);
  } else {
    newSelectedOptions.add(optionValue);
  }
  return [...newSelectedOptions];
}
