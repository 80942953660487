import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uniqueId } from 'lodash';

import Component from 'components/Component';
import Tooltip from 'components/controls/Tooltip';
import NumberWithArrow from 'components/NumberWithArrow';
import InfoMarker from 'components/pages/InfoMarker';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';
import { getIsGrowthDesired } from 'components/pages/analyze/utils/comparisonUtils';

import style from 'styles/stat-square.css';
import settingsPopupStyle from 'styles/analyze/settings-popup.css';
import navigationMenuPopupStyle from 'styles/analyze/navigationMenuPopup.css';

export default class StatSquare extends Component {
  static propTypes = {
    title: PropTypes.string,
    stat: PropTypes.node.isRequired,
    // eslint-disable-next-line react/require-default-props
    context: PropTypes.shape({
      stat: PropTypes.string.isRequired,
      text: PropTypes.string,
      type: PropTypes.oneOf(['positive', 'negative', 'neutral']),
      withArrow: PropTypes.bool,
      tooltipText: PropTypes.string,
    }),
    // eslint-disable-next-line react/require-default-props
    note: PropTypes.shape({
      text: PropTypes.string.isRequired,
      tooltipText: PropTypes.string,
    }),
    showEmptyStat: PropTypes.bool,
    emptyStatMessage: PropTypes.string,
    tooltipText: PropTypes.string,
    iconUrl: PropTypes.string,
    iconText: PropTypes.string,
  };

  static defaultProps = {
    iconUrl: '/assets/analyze-icons/stat-total-cost.svg',
    title: '',
    iconText: '',
    tooltipText: '',
    emptyStatMessage: '',
    showEmptyStat: false,
  };

  style = style;

  styles = [settingsPopupStyle, navigationMenuPopupStyle];

  constructor() {
    super();
    this.state = {
      showNavigationMenuPopup: false,
    };
  }

  getContext = () => {
    const {
      context: {
        stat, text, type, withArrow, tooltipText, funnelTouched,
      },
    } = this.props;
    const node = (
      <div className={this.classes.context}>
        {withArrow ? (
          <NumberWithArrow
            stat={stat}
            isNegative={type === 'negative'}
            arrowStyle={{ alignSelf: 'center', borderWidth: '0px 4px 5px 4px' }}
            statStyle={{ alignSelf: 'center', fontWeight: '500' }}
          />
        ) : (
          <div
            className={classnames(
              this.classes.contextStat,
              type === 'positive' && this.classes.positive,
              type === 'negative' && this.classes.negative
            )}
          >
            {funnelTouched === undefined ? stat : funnelTouched}
          </div>
        )}
        {text && <span className={this.classes.contextText}>{text}</span>}
      </div>
    );

    return tooltipText ? (
      <Tooltip id={`stat-context-tip-${uniqueId()}`} tip={tooltipText}>
        {node}
      </Tooltip>
    ) : node;
  };

  getNote = () => {
    const {
      note: {
        text,
        tooltipText,
      },
    } = this.props;

    const node = <div className={this.classes.note}>{text}</div>;

    return tooltipText ? (
      <Tooltip id={`stat-note-tip-${uniqueId()}`} tip={tooltipText}>
        {node}
      </Tooltip>
    ) : node;
  };

  clickOnNavigationMenuPopup(showNavigationMenuPopup) {
    this.setState({ showNavigationMenuPopup });
  }

  render() {
    const {
      title,
      titleRenderer,
      stat,
      context,
      note,
      tooltipText,
      iconUrl,
      iconText,
      showEmptyStat,
      emptyStatMessage,
      className,
      containerClassName,
      iconTooltip,
      squareTooltip,
      navigationMenuPopupParams,
      withoutHover,
      isCompareToPreviousEnabled,
      showGrowth,
      growth,
      metricName,
    } = this.props;
    const { showNavigationMenuPopup } = this.state;

    return (
      <div
        className={classnames(this.classes.container, containerClassName)}
        onClick={navigationMenuPopupParams ? () => this.clickOnNavigationMenuPopup(true) : null}
      >
        <div className={classnames(
          this.classes.item,
          className,
          showNavigationMenuPopup ? this.classes.navigationMenuOpen : null,
          withoutHover ? this.classes.withoutHover : null
        )}
        >
          {iconTooltip && (
            <InfoMarker
              containerClass={this.classes.tooltipIcon}
              tooltipText={iconTooltip}
            />
          )}
          <div className={this.classes.navigationMenu}>
            {showNavigationMenuPopup && (
              <div className={this.classes.navigationMenuPopup}>
                <NavigationMenuPopup
                  navigationOptions={navigationMenuPopupParams}
                  onClosePopup={(showNavigationPopup) => this.clickOnNavigationMenuPopup(showNavigationPopup)}
                />
              </div>
            )}
          </div>
          <div className={this.classes.header}>
            <div className={this.classes.icon} style={{ backgroundImage: `url(${iconUrl})` }}>
              {iconText && <div className={this.classes.iconText}>{iconText}</div>}
            </div>
          </div>
          <div className={this.classes.content}>
            <div className={classnames(this.props.titleClass, this.classes.title)}>
              {titleRenderer ? titleRenderer() : title}
            </div>
            {showEmptyStat ? (
              <>
                <div className={this.classes.center}>
                  <div className={this.classes.sadIcon} />
                </div>
                <div className={this.classes.noMetrics}>
                  {emptyStatMessage}
                </div>
              </>
            ) : (
              <>
                <div className={this.classes.stat}>{stat}</div>
                {context ? this.getContext() : <div className={this.classes.context} />}
              </>
            )}
            {(showGrowth && isCompareToPreviousEnabled) ? (growth === undefined ? (
              <div className={this.classes.context}>
                <Loading3Dots />
              </div>
            ) : (
              <ComparisonValue
                value={growth}
                isGrowthDesired={getIsGrowthDesired({ metricName })}
              />
            )) : null}
          </div>
          {note && this.getNote()}
        </div>
        {tooltipText
          && (
            <Tooltip
              id={`stat-tip-${uniqueId()}`}
              tip={tooltipText}
              className={this.classes.tooltipContainer}
              TooltipProps={{
                className: squareTooltip,
              }}
            >
              <div className={this.classes.tooltip} />
            </Tooltip>
          )}
      </div>
    );
  }
}
