export const getUpdateSelectedFunnels = (items) => {
  const selectedFilterAccounts = 'accounts';
  const selectedLastItem = items[items.length - 1];
  const isCurrentSelectedIsFunnelStage = selectedLastItem?.value !== selectedFilterAccounts;
  let selectedFunnels = [];
  if (isCurrentSelectedIsFunnelStage) {
    selectedFunnels = items.filter((item) => item?.value !== selectedFilterAccounts);
  } else {
    selectedFunnels = [selectedLastItem];
  }
  return selectedFunnels;
};
