exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".assets-popup__table-header-1Rs{font-size:14px;font-weight:600;color:#4a90e2}.assets-popup__table-cell-Nmf{font-size:12px;font-weight:600;color:#979797}.assets-popup__table-3Bb{table-layout:fixed;border-collapse:collapse;width:100%}.assets-popup__table-3Bb td,.assets-popup__table-3Bb th{border:2px solid #979797}.assets-popup__table-3Bb tr:first-child th{border-top:0}.assets-popup__table-3Bb tr:last-child td{border-bottom:0}.assets-popup__table-3Bb tr td:first-child,.assets-popup__table-3Bb tr th:first-child{border-left:0}.assets-popup__table-3Bb tr td:last-child,.assets-popup__table-3Bb tr th:last-child{border-right:0}.assets-popup__input-29O{border:none;height:26px;padding:0 6px}.assets-popup__input-29O:focus{outline:none}.assets-popup__add-3Xf{display:flex;justify-content:center;margin-top:6px;margin-bottom:18px}.assets-popup__add-button-2r-{width:72px;height:19px}.assets-popup__add-button-cont-3WK{font-size:13px;height:19px}", ""]);

// exports
exports.locals = {
	"table-header": "assets-popup__table-header-1Rs",
	"tableHeader": "assets-popup__table-header-1Rs",
	"table-cell": "assets-popup__table-cell-Nmf",
	"tableCell": "assets-popup__table-cell-Nmf",
	"table": "assets-popup__table-3Bb",
	"table": "assets-popup__table-3Bb",
	"input": "assets-popup__input-29O",
	"input": "assets-popup__input-29O",
	"add": "assets-popup__add-3Xf",
	"add": "assets-popup__add-3Xf",
	"add-button": "assets-popup__add-button-2r-",
	"addButton": "assets-popup__add-button-2r-",
	"add-button-cont": "assets-popup__add-button-cont-3WK",
	"addButtonCont": "assets-popup__add-button-cont-3WK"
};