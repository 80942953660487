import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import Component from 'components/Component';
import Page from 'components/Page';

import { getNewCondition } from 'components/utils/mappingRules';
import { UNMAPPED_QUERY_TYPE } from 'components/utils/settings';

import style from 'styles/plan/plan.css';
import pageStyle from 'styles/page.css';

class Channels extends Component {
  style = style;

  styles = [pageStyle];

  constructor(props) {
    super(props);
    this.state = {
      isOnline: false,
      pageHeight: 0,
    };
  }

  componentDidMount() {
    this.props.getUnmappedData({ unmappedType: UNMAPPED_QUERY_TYPE.UTMS });
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ pageHeight: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  isGivenTab = (tab) => (this.props.children ? this.props.children.type === tab : null);

  setIsOnline = (value) => {
    this.setState({ isOnline: value });
  };

  render() {
    const { isOnline, pageHeight } = this.state;
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        ...this.props,
        getNewCondition,
        isOnline,
        setIsOnline: this.setIsOnline,
        pageHeight,
      }));

    return (
      <div>
        <Page width="100%">
          <div className={pageStyle.locals.container}>
            {childrenWithProps}
          </div>
        </Page>
      </div>
    );
  }
}

export default withLDConsumer()(Channels);
