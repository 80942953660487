import timeFrameModule from 'modules/timeframe';
import userStore from 'stores/userStore';

import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { getWidgetDefinitions } from 'widgetDefinitions';

export function getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig, widget }) {
  const widgetDefinitions = getWidgetDefinitions({ widget });
  const clonedWidgetConfig = widgetDefinitions.getWidgetConfig({ widgetConfig });

  const timeFrameParams = timeFrameModule.getTimeframeParams({ ...widgetHeaderConfig.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
  const { startDate, endDate } = timeFrameParams;

  const isCustomDateTimeframe = widgetHeaderConfig.timeFrame.value === TIMEFRAME_VALUES.CUSTOM;
  if (!isCustomDateTimeframe) {
    clonedWidgetConfig.timeFrame.startDate = new Date(getTSForTimezone(startDate));
    clonedWidgetConfig.timeFrame.endDate = new Date(getTSForTimezone(endDate));

    if (widgetHeaderConfig.isCompareToPreviousEnabled) {
      const { previousEndDate, previousStartDate } = timeFrameParams;
      clonedWidgetConfig.compareToPreviousTimeFrame = true;
      clonedWidgetConfig.previousTimeFrame = {
        endDate: new Date(getTSForTimezone(previousEndDate)),
        startDate: new Date(getTSForTimezone(previousStartDate)),
      };
    }
  }

  return clonedWidgetConfig;
}
