import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/chart.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: '31 MAY 21',
    EXPECTED_MARGINAL_IMPACT: 250,
    PLAN_FORECAST: 750,
    amt: 240,
  },
  {
    name: '30 JUN 21',
    EXPECTED_MARGINAL_IMPACT: 300,
    PLAN_FORECAST: 500,
    amt: 221,
  },
  {
    name: '31 JUL 21',
    EXPECTED_MARGINAL_IMPACT: 500,
    PLAN_FORECAST: 300,
    amt: 229,
  },
  {
    name: '31 AUG 21',
    EXPECTED_MARGINAL_IMPACT: 750,
    PLAN_FORECAST: 280,
    amt: 200,
  },
  {
    name: '30 SEP 21',
    EXPECTED_MARGINAL_IMPACT: 800,
    PLAN_FORECAST: 260,
    amt: 218,
  },
  {
    name: '31 OCT 21',
    EXPECTED_MARGINAL_IMPACT: 800,
    PLAN_FORECAST: 260,
    amt: 250,
  },
  {
    name: '30 NOV 21',
    EXPECTED_MARGINAL_IMPACT: 850,
    PLAN_FORECAST: 255,
    amt: 210,
  },
  {
    name: '31 DEC 21',
    EXPECTED_MARGINAL_IMPACT: 900,
    PLAN_FORECAST: 250,
    amt: 210,
  },
  {
    name: '31 JAN 21',
    EXPECTED_MARGINAL_IMPACT: 900,
    PLAN_FORECAST: 150,
    amt: 210,
  },
  {
    name: '28 FEB 22',
    EXPECTED_MARGINAL_IMPACT: 1000,
    PLAN_FORECAST: 100,
    amt: 210,
  },
];

export default class Chart extends Component {
    style = style;

    render() {
      const {
        onCloseClick,
      } = this.props;
      return (
        <div className={this.classes.overlay}>
          <div className={this.classes.popup}>
            <div className={this.classes.closeIcon} onClick={onCloseClick} />
            <h3 className={this.classes.popupTitle}>
              Goal Forecast
            </h3>
            <ResponsiveContainer width="100%" height={235}>
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" align="right" height={36} iconType="plainline" />
                <Line type="monotone" dataKey="PLAN_FORECAST" stroke="#4D91FC" dot={false} />
                <Line type="monotone" dataKey="EXPECTED_MARGINAL_IMPACT" stroke="#4BBAFF" strokeDasharray="5 5" dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
}
