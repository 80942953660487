import React from 'react';
import style from 'styles/attribution/attribution-setup.css';

export const StepByStepGuide = () => (
  <div className={style.locals.stepGuide}>
    <div
      data-icon="icons:question-circled"
      style={{ width: 20, height: 20, marginRight: 6 }}
    />
    <div>
      <p className={style.locals.stepGuideLabel}>Step-by-step guide</p>
      <a href="https://intercom.help/infinigrow/analyze/attribution-setup/add-the-tracking-script-to-your-website"
          target="_blank"
          rel="noreferrer"
        >
        HTML
      </a>
      <a href="https://intercom.help/infinigrow/analyze/attribution-setup/add-the-tracking-script-to-your-website"
          target="_blank"
          rel="noreferrer"
      >
        Wordpress
      </a>
      <a href="https://intercom.help/infinigrow/analyze/attribution-setup/add-the-tracking-script-to-your-website"
          target="_blank"
          rel="noreferrer"
      >
        Google Tag manager
      </a>
    </div>
  </div>
);
