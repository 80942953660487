import React from 'react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';

import Spinner from 'components/pages/journeys/Spinner';

import loaderStyle from 'styles/controls/loader.css';

const style = loaderStyle.locals || {};

export default function Loader({
  newStyle = true,
  loadingText = 'Loading data...',
}) {
  useStyles([loaderStyle]);

  return (
    <div className={style.loading}>
      <div className={classNames(style.popup, style.loaderPopup)}>
        {newStyle ? (
          <img
            alt="loading"
            src="/assets/infinigrow-loading.gif"
            className={style.loadingLogo}
          />
        ) : (
          <Spinner />
        )}
        {loadingText && (
        <div className={style.spinnerText}>
          {loadingText}
        </div>
        )}
      </div>
    </div>
  );
}
