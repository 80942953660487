import React from 'react';
import Select from 'components/controls/Select';
import filterContainerStyle from 'styles/users/filters.css';
import classNames from 'classnames';
import RulesItem from './RulesItem';
import { useCampaignsContext } from './CampaignsContext';
import { getPlatformLabelFromValue } from './logic/RulesGroup';
import Dropdown from 'components/controls/Dropdown';

const styles = filterContainerStyle.locals;

function RulesGroup({ platformSubTitle, rulesSubTitle }) {
  const {
    campaignsGroupData, filtersConfig, handelPlatformChange, isPreDefineFilter = false,
  } = useCampaignsContext();

  const campaignsPlatform = campaignsGroupData?.platform || '';
  const campaignsRules = campaignsGroupData?.rules || [];
  const campaignsPlatformOptions = Object.keys(filtersConfig).map((value) => ({ value, label: getPlatformLabelFromValue(value) }));

  return (
    <div className={classNames(styles.platformGroup, isPreDefineFilter && styles.platformPreDefineGroup)}>
      {isPreDefineFilter
        ? (
          <div className={styles.platformPreDefineName}>
            {campaignsPlatform}
          </div>
        )
        : (
          <>
            {platformSubTitle && <div className={styles.filterHeader}>{platformSubTitle}</div>}
            <div className={styles.filterSpaces}>
              <Dropdown
                clearable
                options={campaignsPlatformOptions}
                selectedKey={campaignsPlatform}
                onChange={handelPlatformChange}
                className={styles.fieldCapitalize}
                isSearchable
              />
            </div>
          </>
        )}
      <div>
        {campaignsRules.length > 0
          && (
            <>
              {rulesSubTitle && <div className={styles.filterHeaderSubTitle}>{rulesSubTitle}</div>}
              {campaignsRules.map((item, index) => (
                <RulesItem
                  key={`RulesItem_${index}`}
                  rulesGroupIndex={index}
                  rulesItemData={item}
                  isSingleRulesGroup={campaignsRules.length === 1}
                  isLastGroupItem={campaignsRules.length === index + 1}
                  selectedPlatform={campaignsPlatform}
                />
              ))}
            </>
          )}
      </div>
    </div>
  );
}

export default RulesGroup;
