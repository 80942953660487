import _ from 'lodash';
import PropTypes, { oneOfType } from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';

import Component from 'components/Component';
import JourneyWithNavigationMenu from 'components/pages/analyze/JourneyWithNavigationMenu';
import Table from 'components/controls/Table';
import { formatBudget } from 'components/utils/budget';
import { isMoneyIndicator } from 'components/utils/indicators';
import {
  makeChannelsFilter, VARIANTS, makeCampaignsFilter, makeContentFilter,
} from 'components/utils/filters/make';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';

import analyzeStyle from 'styles/analyze/analyze.css';
import style from 'styles/analyze/conversion-journey.css';

class Journey extends Component {
  style = style;

  styles = [analyzeStyle];

  static defaultProps = {
    journeysData: [],
    journeysSum: 0,
    header: 'Journey',
  };

  static propTypes = {
    journeysData: PropTypes.array,
    journeysSum: PropTypes.number,
    header: oneOfType([PropTypes.string, PropTypes.func]),
  };

  getNavigationMenuParams = (items) => {
    const includeAttributionStoreFilters = _.isNil(this.props.widgetConfig);
    const { isCategoryView, widgetConfig = {}, toggleViewType } = this.props;
    const isContentTypeView = toggleViewType === 'contentType';
    const widgetFilters = widgetConfig.filters || [];

    const filters = [...widgetFilters];
    const channels = new Set();
    const categories = new Set();
    const campaigns = new Set();
    const contents = new Set();
    for (const item of items) {
      if (item.channel) {
        channels.add(item.channel);
      }

      if (item.category && isCategoryView) {
        categories.add(item.category);
      }

      if (item.campaign) {
        campaigns.add(item.campaign);
      }

      if (item.content) {
        contents.add(isContentTypeView ? item.contentChannelValue : item.content);
      }
    }

    if (channels.size > 0) {
      filters.push(makeChannelsFilter(VARIANTS.INCLUDE_ALL, Array.from(channels)));
    }

    if (categories.size > 0) {
      filters.push(makeChannelsFilter(VARIANTS.INCLUDE_ALL, Array.from(categories), true));
    }

    if (campaigns.size > 0) {
      filters.push(makeCampaignsFilter(VARIANTS.INCLUDE_ALL, Array.from(campaigns)));
    }

    if (contents.size > 0) {
      filters.push(makeContentFilter(isContentTypeView ? 2 : 0, VARIANTS.INCLUDE_ALL, Array.from(contents)));
    }

    return [{
      title: 'View journeys',
      subTitle: 'Analyze journeys that include this channel combination',
      navigationFunction: () => navigateBetweenAnalyzeTabs({
        filters,
        tabName: 'journeys',
        includeAttributionStoreFilters,
        timeFrame: widgetConfig.timeFrame,
        attributionModel: widgetConfig.attributionModel,
      }),
    }];
  }

  getJourneysColumns = (journeysData, journeysSum) => {
    const { conversionIndicator, header, isCategoryView } = this.props;
    const conversionColumnWidth = 125;
    const percentageColumnWidth = 120;
    const availableSpace = this.tableContainer
      ? this.tableContainer.clientWidth - conversionColumnWidth - percentageColumnWidth
      : 0;

    // set journey column width based on
    // the width of longest journey item
    let journeyColumnWidth = 0;
    journeysData.forEach(({ items = [] }) => {
      const horizontalPadding = 35;
      const journeysItemWidth = items.reduce((acc, { nickname }) => {
        const itemPadding = 57;
        return acc + Math.min((nickname ? nickname.length : 1) * 8 + itemPadding, 245);
      }, 0);
      const journeysItemMargin = Math.max(items.length - 1, 0) * 32;
      const width = horizontalPadding + journeysItemWidth + journeysItemMargin;
      if (width > journeyColumnWidth) {
        journeyColumnWidth = width;
      }
    });

    const isMoneyFormat = isMoneyIndicator(conversionIndicator);

    return [
      {
        id: 'items',
        header,
        cell: ({ items }) => (
          <div className={this.classes.journeyRow}>
            {
              items.map((item, index) => (
                <JourneyWithNavigationMenu
                  key={`journeyWithNavigation-${item.nickname}-${index}`}
                  index={index}
                  item={item}
                  isCategoryView={isCategoryView}
                  getNavigationMenuParams={this.getNavigationMenuParams(items)}
                />
              ))
            }
          </div>
        ),
        ...(journeyColumnWidth > 0 && journeyColumnWidth > availableSpace && { width: journeyColumnWidth }),
      },
      {
        id: 'conversion',
        header: `${userStore.getMetricNickname({ metric: conversionIndicator })} Conversion`,
        className: this.classes.sticky,
        headerClassName: this.classes.sticky,
        cell: ({ count }) => (isMoneyFormat ? formatBudget(count, true) : count),
        width: 125,
      },
      {
        id: 'percentage',
        header: '% of Total',
        className: this.classes.sticky,
        headerClassName: this.classes.sticky,
        cell: ({ count }) => {
          const fraction = journeysSum === 0 ? 0 : count / journeysSum * 100;
          return `${fraction.toFixed(1)}%`;
        },
        width: 120,
      },
    ];
  };

  render() {
    const { journeysData, journeysSum, toggleRenderer } = this.props;
    const journeysColumns = this.getJourneysColumns(journeysData, journeysSum);

    return (
      <div ref={(el) => this.tableContainer = el} className={this.classes.journey}>
        {toggleRenderer && (toggleRenderer())}
        <Table
          style={{ height: 328 }}
          className={classnames(analyzeStyle.locals.channelsImpactsTable, this.classes.journeyTable)}
          rowGroupClassName={analyzeStyle.locals.rowGroup}
          rowClassName={analyzeStyle.locals.row}
          headerClassName={analyzeStyle.locals.header}
          headRowClassName={analyzeStyle.locals.headerRow}
          footerClassName={analyzeStyle.locals.footer}
          footRowClassName={analyzeStyle.locals.footerRow}
          cellClassName={classnames(analyzeStyle.locals.cell, this.classes.cell)}
          data={journeysData}
          columns={journeysColumns}
          noPadding
          minRows={0}
          showPagination
          infiniteScroll
        />
      </div>
    );
  }
}

export default Journey;
