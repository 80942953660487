import React from 'react';
import filterStyles from 'styles/users/filters.css';
import Dropdown from 'components/controls/Dropdown';

const styles = filterStyles.locals;

export default function FilterKindWithTooltip({
  onChangeHandler, defaultIndex = 0, followingKind, showBottomInfo, variantsOptions,
}) {

  const defaultVariant = variantsOptions.filter((item) => item.value === defaultIndex)[0];

  return (
    <div>
      <div className={styles.flexCenter}>
        <Dropdown
          onChange={onChangeHandler}
          options={variantsOptions}
          selectedKey={defaultIndex}
          controlWidth={210}
        />
        {followingKind && <span className={styles.filterVariantDesc}>{followingKind}</span>}
      </div>
      {showBottomInfo
        && (
          <div className={styles.bottomInfoTooltip}>
            <div className={styles.infoMarkerIcon} />
            <div style={{ flexBasis: 'fit-content' }}>{defaultVariant.tooltip}</div>
          </div>
        )}
    </div>
  );
}
