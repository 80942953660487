import React from 'react';
import Dropzone from 'react-dropzone';
import Component from 'components/Component';
import Title from 'components/onboarding/Title';
import AuthorizationIntegrationPopup from 'components/common/AuthorizationIntegrationPopup';
import DriftPopupContainer from 'components/importCampaignsPopups/DriftPopupContainer';

import excelStyle from 'styles/plan-from-excel.css';
import style from 'styles/onboarding/onboarding.css';
import salesForceStyle from 'styles/indicators/salesforce-automatic-popup.css';

class DriftPopup extends Component {
  style = style;

  styles = [salesForceStyle, excelStyle];

  render() {
    const {
      setDataAsState, close, data, afterAuthorizationApi,
      loadingStarted, loadingFinished, updateState,
    } = this.props;
    return (
      <DriftPopupContainer
        setDataAsState={setDataAsState}
        close={close}
        data={data}
        apiEndpoint={afterAuthorizationApi}
        loadingStarted={loadingStarted}
        loadingFinished={loadingFinished}
        updateState={updateState}
      >
        {({
          afterAuthStep,
          updateDataFinal,
          afterDataRetrieved,
          state,
          objectsMapping,
          doneFirstStep,
          onFileAdd,
          deleteFile,
        }) => (
          <AuthorizationIntegrationPopup
            objectsMapping={objectsMapping}
            ref={this.props.authPopupRef}
            data={this.props.data}
            api="driftapi"
            haveStepAfterAuthorizationBeforeMapping
            afterAuthorizationBeforeMappingStep={afterAuthStep}
            afterAuthorizationApi={afterAuthorizationApi}
            afterDataRetrieved={afterDataRetrieved}
            makeServerRequest={updateDataFinal}
            width="1100px"
            innerClassName={salesForceStyle.locals.inner}
            contentClassName={salesForceStyle.locals.content}
            loadingStarted={this.props.loadingStarted}
            loadingFinished={this.props.loadingFinished}
            autorizationRef={this.refs}
            validateBeforeDone={() => true}
            doneButtonText={state.fileUploadSuccessfully ? 'Done' : 'Upload'}
            doneButtonAction={state.fileUploadSuccessfully ? null : doneFirstStep}
            platformTitle="Drift"
            isLoading={this.props.isLoading}
          >
            <div>
              <Title title="Drift - upload CSV (optional)" />
              <div className={this.classes.container}>
                <Dropzone
                  onDropAccepted={onFileAdd}
                  className={this.classes.dropZone}
                  activeClassName={excelStyle.locals.dropZoneActive}
                  rejectClassName={excelStyle.locals.dropZoneReject}
                  accept=".csv"
                >
                  <div className={excelStyle.locals.inner}>
                    <div className={excelStyle.locals.iconWrap}>
                      <div className={excelStyle.locals.icon} />
                    </div>
                    <div className={excelStyle.locals.innerText}>Drag & drop your file here, or browse.</div>
                  </div>
                </Dropzone>
                <div className={excelStyle.locals.uploadActions}>
                  {state.file && (
                  <div className={excelStyle.locals.row}>
                    <div className={this.classes.fileName}>
                      {state.file.name}
                    </div>
                    <div
                      className={this.classes.closeIcon}
                      onClick={deleteFile}
                      style={{ marginTop: 2 }}
                    />
                  </div>
                  )}
                  {state.fileUploadError && (
                  <div className={this.classes.errorText}>
                    Error in parsing CSV file
                  </div>
                  )}
                  {state.fileUploadSuccessfully && (
                  <div className={this.classes.successText}>
                    Successfully uploaded CSV file
                  </div>
                  )}
                  {state.isEmptyFileToUpload && (
                  <div className={this.classes.errorText}>
                    No CSV file to upload
                  </div>
                  )}
                </div>
              </div>
            </div>
          </AuthorizationIntegrationPopup>
        )}
      </DriftPopupContainer>
    );
  }
}

export default DriftPopup;
