import attributionStore from 'stores/attributionStore';
import userStore from 'stores/userStore';

import { getWidgetDefinitions } from 'widgetDefinitions';
import { getSortedFiltersKeys } from 'components/utils/filters/logic/index';

export function getWidgetHashKey({ widget, widgetConfig, region }) {
  let widgetRegion = region;
  if (!widgetRegion) {
    widgetRegion = attributionStore.isAccountMode ? userStore.userMonthPlan.accountViewRegion : userStore.userMonthPlan?.region;
  }

  if (!widgetConfig) {
    return Buffer.from(`${widgetRegion}${widget}`).toString('base64');
  }

  const widgetDefinitions = getWidgetDefinitions({ widget });
  const widgetConfigForHashCalculation = widgetDefinitions ? widgetDefinitions.getWidgetConfig({ widgetConfig }) : widgetConfig;

  const {
    shouldUsePredefinedFilters, filters = [], timeFrame = {}, attributionModel = {}, conversionIndicator, attributionCredit, previousTimeFrame,
  } = widgetConfigForHashCalculation;

  const mappedFilters = getSortedFiltersKeys({ filters: filters.filter((filter) => !filter.isUiOnly) });

  const attributionModelValue = attributionModel.value;
  const startDate = new Date(timeFrame.startDate).getTime();
  const endDate = new Date(timeFrame.endDate).getTime();

  const commonConfigKeys = ['shouldUsePredefinedFilters', 'filters', 'timeFrame', 'attributionModel', 'conversionIndicator', 'attributionCredit', 'id', 'type', 'inactiveColumns', 'reorderColumns', 'unifyIds', 'checkedSegments', 'uncheckedSegments', 'isSelectAll', 'sortByColumn', 'filtersByColumn', 'toggleViewType', 'defaultStageKey'];
  const customKeys = Object.keys(widgetConfigForHashCalculation).filter((configKey) => !commonConfigKeys.includes(configKey)).sort();

  const sortedWidgetConfig = {
    shouldUsePredefinedFilters,
    mappedFilters,
    attributionModelValue,
    startDate,
    endDate,
    conversionIndicator,
    attributionCredit,
  };

  if (widgetConfigForHashCalculation.compareToPreviousTimeFrame && previousTimeFrame) {
    sortedWidgetConfig.prevStartDate = previousTimeFrame.startDate;
    sortedWidgetConfig.prevEndDate = previousTimeFrame.endDate;
  }

  for (const customKey of customKeys) {
    sortedWidgetConfig[customKey] = widgetConfigForHashCalculation[customKey];
  }

  const configAsString = JSON.stringify(sortedWidgetConfig);
  return Buffer.from(`${widgetRegion}${widget}${configAsString}`).toString('base64');
}
