exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".toggle__inner-2PI{display:flex;align-items:flex-end;justify-content:center}.toggle__box-3zd{border:1px solid rgba(178,187,213,.316972);border-radius:4px;color:#707ea7;display:inline-flex;cursor:pointer;-webkit-user-select:none;user-select:none;background:#f5f6fa}.toggle__boxDisabled-151{cursor:default}.toggle__frame-1bH{display:flex;align-items:center;justify-content:center;height:38px;line-height:38px;padding:0 6.5px;border-right:1px solid rgba(178,187,213,.5)}.toggle__frame-1bH:hover{box-shadow:0 6px 6px 0 rgba(54,56,64,.04)}.toggle__frame-1bH[data-active]{background:#fff;border:1px solid rgba(178,187,213,.5);border-radius:4px;margin:-1px 0 -1px -1px}.toggle__frame-text-Oit{color:#707ea7;font-weight:400;display:flex;justify-content:center;align-items:center;gap:6px;font-size:14px}.toggle__frame-1bH:hover .toggle__frame-text-Oit{color:#707ea7}.toggle__frame-1bH[data-active] .toggle__frame-text-Oit{color:#182033;font-weight:600}.toggle__box-3zd:last-child{border-right:none}.toggle__betaTag-1ou{background:#5e8ff4;border-radius:4px;color:#fff;font-size:12px;padding:3px 6px;align-items:center;height:16px;display:flex;font-weight:400}.toggle__disabledTooltip-3m0{cursor:default;opacity:.5}", ""]);

// exports
exports.locals = {
	"inner": "toggle__inner-2PI",
	"inner": "toggle__inner-2PI",
	"box": "toggle__box-3zd",
	"box": "toggle__box-3zd",
	"boxDisabled": "toggle__boxDisabled-151",
	"boxDisabled": "toggle__boxDisabled-151",
	"frame": "toggle__frame-1bH",
	"frame": "toggle__frame-1bH",
	"frame-text": "toggle__frame-text-Oit",
	"frameText": "toggle__frame-text-Oit",
	"betaTag": "toggle__betaTag-1ou",
	"betaTag": "toggle__betaTag-1ou",
	"disabledTooltip": "toggle__disabledTooltip-3m0",
	"disabledTooltip": "toggle__disabledTooltip-3m0"
};