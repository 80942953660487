import { action, decorate, observable } from 'mobx';
import { persist } from 'mobx-persist';

import hydrate from 'stores/hydrate';

class AnalyzeStore {
  constructor() {
    this.configPerTab = {
      channels: {},
      content: {},
    };
  }

  updateTabConfig({ tabName, configKey, configValue }) {
    const configPerTab = { ...this.configPerTab };
    if (!configPerTab[tabName]) {
      configPerTab[tabName] = {};
    }
    configPerTab[tabName][configKey] = configValue;
    this.configPerTab = configPerTab;
  }
}

decorate(AnalyzeStore, {
  configPerTab: observable.ref,
  updateTabConfig: action.bound,
});

const schema = {
  configPerTab: {
    type: 'object',
  },
};

const store = persist(schema)(new AnalyzeStore());

hydrate('analyzeStore', store);

export default store;
