/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Component from 'components/Component';
import CampaignsImportPopup from 'components/importCampaignsPopups/CampaignsImportPopup';

export default class BingCampaignsPopup extends Component {
  constructor(props) {
    super(props);
    this.campaignImportRef = React.createRef();
  }

  open = () => {
    this.campaignImportRef.current.open();
  };

  render() {
    return (
      <CampaignsImportPopup
        ref={this.campaignImportRef}
        api="bingadsapi"
        title="Choose Bing Account"
        platformTitle="Bing Ads"
        multi
        {...this.props}
      />
    );
  }
}
