import React from 'react';
import StatSquare from 'components/common/StatSquare';
import { formatBudget } from 'components/utils/budget';
import AbsoluteButton from 'components/pages/dashboard/dashboardNavigate/AbsoluteButton';
import WithHoverEffect from 'components/common/WithHoverEffect';

const StatSquareWithButton = (props) => {
  const {
    sumPlannedWithFuture,
    time,
    isOnTrack,
    sumPacing,
    sumActual,
    monthlyBudgetLeftToInvest,
    classes,
    isHovered,
    redirect,
  } = props;

  return (
    <>
      <StatSquare
        titleRenderer={() => `${time} Budget`}
        stat={formatBudget(sumPlannedWithFuture, true, true)}
        note={{
          text: isOnTrack ? 'On-Track' : 'Off-Track',
          tooltipText: isOnTrack
            ? 'Actual spent on-track'
            : `Actual spent off-track. Forecasted: ${formatBudget(sumPacing, true, true)}`,
        }}
        context={{
          stat: formatBudget(sumPlannedWithFuture - sumActual, true, false, true),
          text: 'left to invest',
          type: monthlyBudgetLeftToInvest > 0 ? 'positive' : monthlyBudgetLeftToInvest < 0 ? 'negative' : 'neutral',
        }}
        containerClassName={classes.statSquareContainer}
        titleClass={classes.statSquareTitle}
        withoutHover
      />
      <AbsoluteButton
        isHovered={isHovered}
        text="Budget"
        onClick={() => redirect('/plan/annual')}
      />
    </>
  )
}

export default WithHoverEffect(StatSquareWithButton);
