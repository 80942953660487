import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import spinnerStyle from 'styles/users/users-spinner.css';

const styles = spinnerStyle.locals || {};

export default function Spinner(props) {
  useEffect(() => {
    spinnerStyle.use();

    return () => {
      if (!spinnerStyle.unuse) {
        return;
      }
      spinnerStyle.unuse();
    };
  }, []);

  return (
    <div className={classNames(styles.loader, { [styles.white]: props.white, [styles.small]: props.small })} />
  );
}

Spinner.defaultProps = {
  white: false,
  small: false,
};

Spinner.propTypes = {
  white: PropTypes.bool,
  small: PropTypes.bool,
};
