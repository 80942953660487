import React from 'react';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import classNames from 'classnames';
import {
  union, get, cloneDeep, sumBy, last,
} from 'lodash';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import { classes } from 'components/pages/plan/BudgetTable';
import { formatBudget } from 'components/utils/budget';
import EditableBudgetCell from 'components/pages/plan/EditableBudgetCell';
import CampaignTitle from 'components/pages/plan/CampaignTitle';
import planStore from 'stores/plan/planStore';
import { amountTypes } from 'stores/plan/logic/enums';
import { compose } from 'components/utils/utils';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const COLLAPSED_SIZE = 3;

const enhance = compose(
  inject(({
    planStore: {
      selectedCampaignTags,
    },
  }) => ({
    selectedCampaignTags,
  })),
  observer
);

class SubTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      deletePopup: '',
      showAll: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      showAll: !prevState.showAll,
    }));
  }

  get tableLength() {
    const { row } = this.props;
    return row.original.children.length;
  }

  haveMore = () => this.tableLength > COLLAPSED_SIZE && !this.state.showAll;

  getFooterProps = () => ({
    style: {
      backgroundColor: 'white',
      height: 40,
      paddingLeft: 120,
      display: this.haveMore() ? 'block' : 'none',
    },
  });

  getTableColumns() {
    const {
      dates, showPopup, row, numberOfPastDates,
    } = this.props;
    const { deletePopup } = this.state;

    const onCampaignClick = (data, channel) => !data.mocked && showPopup(data, channel);
    const columnsData = dates.map((date, index) => {
      const {
        isAnnual, isQuarter, realIndex, ...restData
      } = date;
      const isHistory = realIndex < numberOfPastDates;

      return ({
        Header: undefined,
        accessor: String(index),
        getFooterProps: this.getFooterProps,
        Cell: ({ value, original: { orig = {} } }) => {
          if (isAnnual || isQuarter || isHistory) {
            return formatBudget(value.budget);
          }
          return (
            <EditableBudgetCell
              value={value.budget}
              formatter={formatBudget}
              save={async (budget) => {
                const budgetArrayIndex = date.realIndex;
                const newBudget = cloneDeep(orig.budget);
                newBudget[budgetArrayIndex] = budget;
                const startDate = date.date.startOf('month').format('YYYY-MM-DD');
                const endDate = date.date.endOf('month').format('YYYY-MM-DD');
                planStore.updateCampaignAmount({
                  campaignName: orig.name, channel: orig.channel, startDate, endDate, amount: budget, amountType: amountTypes.budget, id: orig.id,
                });
                await planStore.sendUpdateCampaignsAmountRequestToServer({ startDate, endDate });
              }}
              disabled={isHistory}
            />
          );
        },
        width: 140,
        getProps: (_, rowData = {}) => {
          const { aggregated } = rowData;
          return {
            className: classNames({
              [this.props.classes.cellCategory]: aggregated,
              [this.props.classes.cellAnnual]: isAnnual,
              [this.props.classes.cellQuarter]: isQuarter,
              [this.props.classes.cellHistory]: isHistory,
            }),
          };
        },
        headerStyle: {
          display: 'none',
        },
        isAnnual,
        ...restData,
      });
    });
    columnsData.unshift({
      fixed: 'left',
      width: 335,
      accessor: 'campaign',
      Cell: (cellData) => (
        <CampaignTitle
          cellValue={cellData.value}
          onClick={() => onCampaignClick(get(cellData, 'original.orig', {}), row.original)}
          campaignTags={get(cellData, 'original.orig.tags', [])}
        />
      ),
      headerStyle: {
        display: 'none',
      },
      getFooterProps: this.getFooterProps,
      Footer: () => {
        if (!this.haveMore()) {
          return null;
        }
        return (
          <div
            className={this.props.classes.dots}
            onClick={() => this.toggle()}
          />
        );
      },
      getProps: (_, rowData = {}) => {
        const { groupedByPivot, original = {} } = rowData;
        const { channel } = original;

        return {
          className: classNames(this.props.classes.cellDefault, {
            [this.props.classes.cellCategoryWrapper]: groupedByPivot,
          }),
          style: {
            zIndex: channel === deletePopup && 2,
          },
        };
      },
    });

    return columnsData;
  }

  getTrProps = (_, rowInfo = {}) => ({
    className: classNames({
      [classes.rowCategory]: rowInfo.aggregated,
    }),
  });

  getTdProps = (_, rowInfo = {}, column = {}) => ({
    className: classNames({
      [classes.cellAnnualCategory]:
      rowInfo.aggregated && column.isAnnual,
    }),
  });

  prepareCampaignData = () => {
    const { children: allCampaigns, region } = this.props.row.original;
    const { datesInitial, numberOfPastDates } = this.props;

    return datesInitial.map((date, realIndex) => {
      const isHistory = realIndex < numberOfPastDates;

      const campaigns = allCampaigns.reduce((acc, campaign) => {
        const {
          budget, name, actualSpent, tags,
        } = campaign;
        let v = budget[realIndex] || 0;
        if (isHistory) {
          v = actualSpent[realIndex] || 0;
        }
        acc[name] = {
          budget: v,
          realIndex,
          isHistory,
          region,
          tags,
        };
        return acc;
      }, {});

      return {
        month: moment(date).format('MMM YYYY'),
        date: moment(date),
        campaigns,
        isHistory,
      };
    });
  };

  sumBudgetsData = (chunk) => {
    const campaignsInChunk = union(...chunk.map((month) => Object.keys(month.campaigns)));
    const chunkSummedCampaigns = {};
    campaignsInChunk.forEach((campaign) => {
      const budget = sumBy(chunk, (month) => get(month, ['campaigns', campaign, 'budget'], 0));
      chunkSummedCampaigns[campaign] = { budget };
    });
    return {
      campaigns: chunkSummedCampaigns,
      isHistory: last(chunk).isHistory,
      realIndex: null,
    };
  };

  getTableData = (initData) => {
    const { children: data } = this.props.row.original;
    const uniqNames = union(...initData.map((month) => Object.keys(month.campaigns)));
    return uniqNames.map((campaign) => {
      const row = initData.reduce((rowData, month = {}, index) => {
        const { campaigns } = month;
        if (campaigns[campaign]) {
          rowData[index] = {
            ...{ budget: 0 },
            ...campaigns[campaign],
          };
        }
        return rowData;
      }, {});
      row.campaign = campaign;
      const additionalData = data.find((c) => c.name === campaign) || {};
      return {
        ...row,
        orig: additionalData,
      };
    });
  };

  render() {
    const { annualOffset, quarterOffset, addQuartersAndYearSumData } = this.props;
    const pageSize = this.state.showAll || this.tableLength <= COLLAPSED_SIZE
      ? this.tableLength : COLLAPSED_SIZE;

    const tableData = this.prepareCampaignData();
    const makeAddBudgetData = (data) => (chunk) => ({ ...this.sumBudgetsData(chunk), ...data });
    const dataWithSumAddition = addQuartersAndYearSumData(
      tableData,
      makeAddBudgetData({ isQuarter: true }),
      makeAddBudgetData({ isAnnual: true }),
      quarterOffset,
      annualOffset
    );
    return (
      <ReactTableFixedColumns
        header
        columns={this.getTableColumns()}
        data={this.getTableData(dataWithSumAddition)}
        pageSize={pageSize}
        onExpandedChange={this.handleExpand}
        resizable={false}
        showPageSizeOptions={false}
        showPagination={false}
        sortable={false}
        NoDataComponent={() => null}
      />
    );
  }
}

export default enhance(SubTable);
