import React, { useCallback, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import MappingRule from 'components/pages/settings/channels/tabs/common/MappingRule';
import Spinner from 'components/pages/journeys/Spinner';
import SaveButton from 'components/pages/profile/SaveButton';
import useWindowDimensions from 'components/utils/getWindowDimensions';

import { getAllMappingOptions, getChannelRules, isRuleConditionFilled } from 'components/utils/mappingRules';
import { unmappedPopupSteps } from 'components/pages/settings/channels/tabs/logic/enums';

import style from 'styles/settings/channels/unmapped-tab.css';

const styles = style.locals;

function UnmappedPopupSteps(props) {
  const {
    children, onCancel, mappingRulesConditions, saveFail, isSaving, selectedChannelForMapping,
    updateCondition, addCondition, deleteCondition, saveMapping, setUnmappedPopupStep, unmappedPopupStep,
    attributionMappingRules, leadSourcesIdToLabelMap = {}, customUtmsWhitelist,
  } = props;

  const isSelectedChannel = selectedChannelForMapping === null;
  const channelRules = getChannelRules(attributionMappingRules, selectedChannelForMapping);
  const buttonStyle = { width: '75px' };
  const { height: screenHeight } = useWindowDimensions();
  const popupHeight = `${screenHeight - 524}px`;
  const paramsOptions = getAllMappingOptions({ leadSourcesIdToLabelMap, customUtmsWhitelist });

  const isAllConditionsFieldsAreFilled = useCallback(() => {
    for (const rulesConditions of mappingRulesConditions) {
      for (const condition of rulesConditions) {
        if (!isRuleConditionFilled({ condition })) {
          return false;
        }
      }
    }
    return true;
  }, [mappingRulesConditions]);

  const isDisabledDelete = useMemo(() => {
    const rulesWithConditions = mappingRulesConditions.filter((conditions) => conditions.length > 0);
    return rulesWithConditions.length === 1 && rulesWithConditions[0].length === 1;
  }, [mappingRulesConditions]);

  return (
    <>
      <div className={styles.unmappedSteps}>
        <div
          className={classnames(styles.stepItem, styles.stepItemLine, unmappedPopupStep === unmappedPopupSteps.selectChannel && styles.stepActive)}
          onClick={() => {
            setUnmappedPopupStep(unmappedPopupSteps.selectChannel);
          }}
        >
          <div className={styles.stepCounter} />
          <div>Select a Channel</div>
        </div>
        <div
          className={classnames(styles.stepItem, unmappedPopupStep === unmappedPopupSteps.selectRules && styles.stepActive)}
          onClick={() => {
            setUnmappedPopupStep(unmappedPopupSteps.selectRules);
          }}
        >
          <div className={styles.stepCounter} />
          <div>Set mapping rules</div>
        </div>
      </div>
      {unmappedPopupStep === unmappedPopupSteps.selectChannel
        && (
          <>
            <div className={styles.unmappedContent}>
              {children}
            </div>
            <div className={styles.unmappedApplyButtons}>
              <Button
                type="secondaryWhite"
                style={buttonStyle}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                type="primaryBlue"
                style={buttonStyle}
                onClick={() => {
                  setUnmappedPopupStep(unmappedPopupSteps.selectRules);
                }}
                disabled={isSelectedChannel}
              >
                Continue
              </Button>
            </div>
          </>
        )}
      {unmappedPopupStep === unmappedPopupSteps.selectRules
        && (
          <div className={styles.stepsContainer} style={{ maxHeight: popupHeight }}>
            <div className={styles.unmappedContent}>
              <div className={styles.unmappedContentTitle}>Create new mapping rules</div>
              {mappingRulesConditions.map((conditionGroup, groupIndex) => conditionGroup.length > 0
                && (
                <div className={styles.unmappedContentMappingBox}>
                  {conditionGroup.map((condition, ruleIndex, row) => (
                    <MappingRule
                      key={`conditionGroup-${groupIndex}-${ruleIndex}-${condition.param}`}
                      disabledDelete={isDisabledDelete}
                      paramsOptions={paramsOptions}
                      param={condition.param}
                      operation={condition.operation}
                      value={condition.value}
                      updateOperation={(e) => updateCondition(groupIndex, ruleIndex, 'operation', e.value)}
                      updateParam={(e) => updateCondition(groupIndex, ruleIndex, 'param', e.value)}
                      updateValue={(e) => updateCondition(groupIndex, ruleIndex, 'value', e.target.value)}
                      handleAdd={() => addCondition(groupIndex)}
                      handleDelete={() => deleteCondition(groupIndex, ruleIndex)}
                      isLastAddButton={row.length - 1 !== ruleIndex}
                    />
                  ))}
                </div>
                ))}

              {channelRules.length > 0 && !isSaving
                && (
                  <div className={styles.mappingDisabled}>
                    <div className={styles.unmappedContentTitle}>Existing mapping rules</div>
                    <div className={styles.unmappedContentMappingBox}>
                      {channelRules.map((rule, index) => (
                        <div key={`channelRules-${index}`}>
                          {
                            rule.conditions.map((condition, conditionIndex, row) => (
                              <MappingRule
                                key={`rule-${rule.index}-${conditionIndex}`}
                                paramsOptions={paramsOptions}
                                value={condition.value}
                                param={condition.param}
                                operation={condition.operation}
                                isMappingDisabled
                                disabled
                                isLastAddButton={row.length - 1 !== conditionIndex}
                              />
                            ))
                          }
                          <div className={styles.mappingRuleLine} hidden={index === channelRules.length - 1} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
            <div className={styles.unmappedApplyButtons}>
              <Button
                type="secondaryWhite"
                style={buttonStyle}
                onClick={() => {
                  setUnmappedPopupStep(unmappedPopupSteps.selectChannel);
                }}
              >
                Back
              </Button>
              <SaveButton
                style={{ padding: '0 24px' }}
                onClick={saveMapping}
                fail={saveFail}
                disabled={isSelectedChannel || !isAllConditionsFieldsAreFilled()}
              />
              {(isSaving) && (<Spinner />)}
            </div>
          </div>
        )}
    </>
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        leadSourcesIdToLabelMap,
      },
      attributionMappingRules,
    },
  }) => ({
    leadSourcesIdToLabelMap,
    attributionMappingRules,

  }),
  observer
)(UnmappedPopupSteps);
