import React from 'react';

import { userConfigurationTypes } from 'stores/logic/enums';

import CrmMultiTouch from 'components/pages/settings/workflows/configPopups/crmMultiTouch';
import CrmValueTranslation from 'components/pages/settings/workflows/configPopups/crmValueTranslation';
import DataRefreshInterval from 'components/pages/settings/workflows/configPopups/dataRefreshInterval';
import FunnelsBackfilling from 'components/pages/settings/workflows/configPopups/funnelsBackfilling';
import LeadToAccountMatching from 'components/pages/settings/workflows/configPopups/leadToAccountMatching';
import ObjectsDeduplication from 'components/pages/settings/workflows/configPopups/objectsDeduplication';
import OppEnrichment from 'components/pages/settings/workflows/configPopups/oppEnrichment';
import TouchPointsTimestamp from 'components/pages/settings/workflows/configPopups/touchPointsTimestamp';

export const configurationTypeProperties = {
  [userConfigurationTypes.conversionRate]: {
    icon: 'simpleConversionRate',
    title: 'Calculate standard Conversion Rates',
    subTitle: 'Shift from InfiniGrow’s account-based cohort conversion rate calculation to a standard conversion rate. [not recommended].',
    popupSubTitle: '',
    popupComponent: '',
    featureFlag: 'configStatsSimpleConversionRate',
  },
  [userConfigurationTypes.crmValueTranslation]: {
    icon: 'valueTranslation',
    title: 'Translate CRM external values',
    subTitle: 'Translate CRM field values into InfiniGrow values to improve clarity in your account.',
    popupSubTitle: 'Translate HubSpot field values into InfiniGrow values to overcome Hubspot’s limitation on internal values and ensure field values are readable in InfiniGrow.',
    popupComponent: CrmValueTranslation,
    featureFlag: 'configStatsValueTranslation',
    order: 5,
  },
  [userConfigurationTypes.crmMultiTouch]: {
    icon: 'crmMultiTouch',
    title: 'Log CRM field history',
    subTitle: 'Track the history of any CRM field and log every value change as a new separate touchpoint.',
    popupSubTitle: 'Apply history tracking to your CRM fields to log every change in their record history as a separate touchpoint.',
    popupComponent: CrmMultiTouch,
    featureFlag: 'configStatsCrmMultiTouch',
    order: 1,
  },
  [userConfigurationTypes.dataRefreshInterval]: {
    icon: 'deltaUpdate',
    title: 'Delta update',
    subTitle: 'Sync your CRM and online script at your preferred time interval to ensure that all the data you see on your account is accurate and up to date.',
    popupSubTitle: 'Sync your CRM and online script at your preferred time interval to ensure that all the data you see on your account is accurate and up to date.',
    popupComponent: DataRefreshInterval,
    featureFlag: 'configStatsDeltaUpdate',
  },
  [userConfigurationTypes.funnelsBackfilling]: {
    icon: 'backfilling',
    title: 'Backfilling',
    subTitle: 'Automatically account for any skipped transitions and ensure funnel accuracy.',
    popupSubTitle: 'Ensure no stage is missed with InfiniGrow\'s Backfilling. Define rules to automatically transition leads, accounts, or deals, through preliminary stages, even if they\'re initially skipped. For instance, by setting backfilling for the second funnel stage, leads bypassing the first stage will first transition to it before advancing to the second.',
    popupComponent: FunnelsBackfilling,
    featureFlag: 'configStatsBackfilling',
  },
  [userConfigurationTypes.leadToAccountMatching]: {
    icon: 'leadToAccountMatching',
    title: 'Match leads to accounts',
    subTitle: 'Automate lead to account matching based on advanced matching logic provided by InfiniGrow.',
    popupSubTitle: (
      <>
        Automatically associate leads to accounts based on email domains. For instance, a lead with
        &quot;
        <u>george@company.com</u>
        &quot;
        will be matched to an account under &quot;company.com&quot;, ensuring no lead remains unlinked.
      </>
    ),
    popupComponent: LeadToAccountMatching,
    featureFlag: 'configStatsLeadToAccountMatching',
    order: 2,
  },
  [userConfigurationTypes.oppEnrichment]: {
    icon: 'oppEnrichment',
    title: 'Automate Contact-to-Opportunity association',
    subTitle: 'Correct missing associations in your CRM by automatically associating Contacts to relevant Opps that they influenced.',
    popupSubTitle: 'Automatically link contacts or leads to deals based on their interactions within your chosen timeframe before a deal\'s initiation. If a contact has relevant touchpoints within this window, InfiniGrow associates them with the corresponding deal.',
    popupComponent: OppEnrichment,
    featureFlag: 'configStatsOppEnrichment',
  },
  [userConfigurationTypes.objectsDeduplication]: {
    icon: 'deduplication',
    title: 'Deduplicate CRM records',
    subTitle: 'Merge CRM records based on identical fields, to automatically keep your data clean.',
    popupSubTitle: 'Set rules to automatically merge CRM records. When two records meet your specific criteria, such as having the same account ID, they will be merged — ensuring your data remains clean and consistent.',
    popupComponent: ObjectsDeduplication,
    featureFlag: 'configStatsDeduplication',
    order: 3,
  },
  [userConfigurationTypes.touchPointsTimestamp]: {
    icon: 'touchpointTimestampEditing',
    title: 'Adjust touchpoint timestamps',
    subTitle: 'Take control of your touchpoint timestamps to overcome CRM discrepancies and manual input delays.',
    popupSubTitle: 'Take control of your touchpoint timestamps with InfiniGrow. Choose a channel and set its touchpoints back by your desired number of hours to rectify CRM discrepancies or manual changes. For instance, if leads are added to your CRM a week after an event, but a deal is registered sooner, you can manually shift Events touchpoints back, to ensure they are logged before the deal and get credit for the deal’s creation.',
    popupComponent: TouchPointsTimestamp,
    featureFlag: 'configStatsTouchpointTimestampEditing',
    order: 4,
  },
};
