import { cloneDeep } from 'lodash';

export const getFilteredOptionsForRequest = (journeyFilterOptions) => {
  const updatedFilterOptions = [];
  for (const categoryOptions of journeyFilterOptions) {
    const values = [];
    for (const value of categoryOptions.values) {
      if (value.isSelected) {
        values.push(value.value);
      }
    }
    if (values.length) {
      updatedFilterOptions.push({ ...categoryOptions, values });
    }
  }
  return updatedFilterOptions;
};

export function splitFunnelTransitions({
  funnelTransitions,
  pendingTransitions,
  dateToSplit,
}) {
  if (funnelTransitions.length === 0 && pendingTransitions.length === 0) {
    return { transitionsAfterSplittingDate: [], transitionsBeforeSplittingDate: [] };
  }
  const updatedPendingTransitions = cloneDeep(pendingTransitions);

  const allFunnelTransitions = [...updatedPendingTransitions, ...funnelTransitions];
  if (!dateToSplit) {
    return {
      transitionsAfterSplittingDate: allFunnelTransitions,
      transitionsBeforeSplittingDate: [],
    };
  }

  const transitionsAfterSplittingDate = allFunnelTransitions.filter(
    (transition) => new Date(transition.changeDate) >= new Date(dateToSplit)
  );

  const transitionsBeforeSplittingDate = allFunnelTransitions.filter(
    (transition) => new Date(transition.changeDate) < new Date(dateToSplit)
  );

  return { transitionsAfterSplittingDate, transitionsBeforeSplittingDate };
}
