import { sortBy } from 'lodash';
import moment from 'moment';

export function parseServerResponseForExplainableForecastGraph(forecastedDataResponse) {
  let formattedGraphExplainableForecast = [];
  for (const [forecastDataAsString, forecastData] of Object.entries(forecastedDataResponse)) {
    const forecastDate = new Date(forecastDataAsString);
    formattedGraphExplainableForecast.push({
      ...forecastData,
      timestamp: moment(forecastDate).utc().unix(),
      timeText: moment(forecastDate).utc().format('DD MMM YY').toUpperCase(),
    });
  }
  formattedGraphExplainableForecast = sortBy(formattedGraphExplainableForecast, (item) => moment(item.timestamp));
  return formattedGraphExplainableForecast;
}
