exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".title__box-20a{margin-top:-2px;padding-bottom:24px;margin-bottom:24px;border-bottom:1px solid #e7e9ed;display:flex;align-items:center;flex-wrap:wrap}.title__title-3Zz{font-size:32px;line-height:44px;letter-spacing:-.01em;overflow:hidden;font-weight:600;text-transform:capitalize;color:#09c;flex-shrink:0;flex-grow:0;margin-left:1px;margin-top:-4px;min-width:167px;margin-right:26px}.title__text-Jsq{font-size:18px;font-style:italic;font-weight:300;line-height:26px;letter-spacing:.025em;-webkit-font-smoothing:auto;-moz-osx-font-smoothing:auto}.title__vertical-box-2hZ{flex-direction:column;padding-bottom:27px;margin-bottom:18px;text-align:center}.title__vertical-box-2hZ .title__text-Jsq{margin-left:0;margin-top:12px;line-height:22px}@media (max-width:830px){.title__box-20a{flex-direction:column;padding-bottom:27px;margin-bottom:18px;text-align:center}.title__text-Jsq{margin-left:0;margin-top:12px;line-height:22px}}", ""]);

// exports
exports.locals = {
	"box": "title__box-20a",
	"box": "title__box-20a",
	"title": "title__title-3Zz",
	"title": "title__title-3Zz",
	"text": "title__text-Jsq",
	"text": "title__text-Jsq",
	"vertical-box": "title__vertical-box-2hZ title__box-20a",
	"verticalBox": "title__vertical-box-2hZ title__box-20a"
};