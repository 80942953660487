import React, { useEffect } from 'react';
import classNames from 'classnames';

import InfoMarker from 'components/pages/InfoMarker';

import buttonWithOptions from 'styles/controls/buttonWithOptions.css';

const styles = buttonWithOptions.locals || {};

export default function MenuButton({
  title,
  isOpen,
  onClick,
  rightIconRerender,
  withArrowIndication = true,
  className,
  tooltip,
  disabled,
  dataTestId,
  isShowError,
  tagId,
}) {
  useEffect(() => {
    buttonWithOptions.use();

    return () => {
      if (typeof buttonWithOptions === 'object') {
        return;
      }
      buttonWithOptions.unuse();
    };
  }, []);

  return (
    <div
      className={classNames(
        styles.optionsButton,
        isOpen && styles.activeButton,
        className,
        disabled && styles.disabledButton,
        isShowError && styles.errorButton
      )}
      onClick={disabled ? null : onClick}
      data-testid={dataTestId}
      id={tagId}
    >
      {title}
      {tooltip && <InfoMarker containerClass={styles.tooltipIcon} TooltipProps={{ className: styles.tooltipIcon, wide: true }} tooltipText={tooltip} />}
      {rightIconRerender}
      {!disabled && withArrowIndication && <div className={isOpen ? styles.arrowUp : styles.arrowDown} />}
    </div>
  );
}
