import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import {
  Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer,
} from 'recharts';
import classNames from 'classnames';

import exportData from 'modules/exportData';
import useStyles from 'hooks/useStyles';
import WidgetHeader from 'components/common/WidgetHeader';
import FrequencySelect from 'components/pages/analyze/FrequencySelect';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import TrendAnalysisSelectMetricsPopup from 'components/pages/analyze/OverviewTab/TrendAnalysisSelectMetricsPopup';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';
import ChartSkeleton from 'components/common/ChartSkeleton';
import servicesStore from 'stores/servicesStore';

import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import { getCurrencySymbol } from 'components/utils/budget';
import { formatBudgetShortened } from 'components/utils/budgetFormat';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { getColor } from 'components/utils/colors';
import { FREQUENCY_OPTIONS, FREQUENCY_VALUES } from 'components/utils/frequency';
import { rightY, leftY, trendAnalysisInitialMetrics } from 'components/pages/analyze/OverviewTab/logic/enums';
import { widgetsConfig, widgetTypes } from 'components/pages/analyze/enums';
import MonthToDate from 'components/common/MonthToDate';
import {
  perseChartDataUnitsForChartYAxis,
  perseDataForChart,
  getSelectedMetricsFromSavedMetrics,
  getPerformanceMetricsListItems,
  getSpendMetricsListItems,
  getColorsLookupFromChannels,
  prepareChartDataForParse,
  getLegendTitleByChannel,
  getTrendAnalysisExportColumns,
  getTrendAnalysisEventData,
  parseTrendAnalysisDataForExport,
} from 'components/pages/analyze/OverviewTab/logic/TrendAnalysis';
import Spinner from 'components/pages/journeys/Spinner';
import { getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';
import { createSheetAnalyze } from 'components/utils/excelExport';
import { getInsightsTimeframeOptions } from 'components/utils/timeframe';
import { checkIfChartDataIsEmpty } from 'components/utils/logic/trend';

import style from 'styles/analyze/trend-analysis.css';

const styles = style.locals;

function TrendAnalysis({
  isLoaded,
  metricsOptions,
  isFailedToLoad,
  getMetricNickname,
  trendAnalysis: responseData,
  trendAnalysisFrequency,
  trendAnalysisShouldUseRelativeTimeframe,
  trendAnalysisMetrics,
  updateTrendAnalysisFrequency,
  updateTrendAnalysisShouldUseRelativeTimeframe,
  updateTrendAnalysisMetrics,
  widgetHeaderConfig = {
    title: 'Trend Analysis',
    type: widgetTypes.trendAnalysis,
    trendAnalysisParams: {
      frequency: trendAnalysisFrequency,
      metrics: trendAnalysisMetrics,
      shouldUseRelativeTimeframe: trendAnalysisShouldUseRelativeTimeframe,
    },
  },
  widgetHeaderProps = {},
  isCompareToPreviousEnabled,
  flags,
  timeFrame,
  exportMode = true,
  emptyStateComponent,
}) {
  useStyles([style]);

  const [selectedMetrics, setSelectedMetrics] = useState(getSelectedMetricsFromSavedMetrics({ metrics: trendAnalysisMetrics }));
  const [isExportLoading, setIsExportLoading] = useState(false);

  useEffect(() => {
    if (trendAnalysisMetrics) {
      setSelectedMetrics(getSelectedMetricsFromSavedMetrics({ metrics: trendAnalysisMetrics }));
    }
  }, [trendAnalysisMetrics]);

  const data = useMemo(() => prepareChartDataForParse({ chartData: responseData }), [responseData]);

  const isShowOnlyChartLines = selectedMetrics.spend.length === 0 && selectedMetrics.performance.length > 0;
  const chartData = perseDataForChart({ chartData: data });

  const isHasBars = useMemo(() => {
    for (const metric of selectedMetrics?.spend || []) {
      if (responseData[metric]) {
        return true;
      }
    }
    return false;
  }, [
    responseData,
    JSON.stringify(selectedMetrics.spend),
  ]);

  const unitsForYAxis = perseChartDataUnitsForChartYAxis({
    chartData: data, selectedMetrics, isShowOnlyLines: isShowOnlyChartLines,
  });

  const formatValueFromUnit = ({ value, ydirection = leftY }) => {
    if (unitsForYAxis[ydirection] === 'day') {
      const formatValueTo2Decimals = Math.round(parseFloat(value) * 100) / 100;
      return `${formatValueTo2Decimals} ${formatValueTo2Decimals === 1 ? 'day' : 'days'}`;
    }
    if (unitsForYAxis[ydirection] === 'cost') {
      return `${getCurrencySymbol()}${formatBudgetShortened(Math.round(value))}`;
    }
    if (unitsForYAxis[ydirection] === 'percentage') {
      return `${_.round(value, 1)}%`;
    }
    return value;
  };

  const colorsLookup = getColorsLookupFromChannels();

  const getBars = () => selectedMetrics.spend.map((channel) => (
    <Bar
      key={`bars-${channel}`}
      yAxisId="left"
      xAxisId={0}
      dataKey={channel}
      stackId="channels"
      fill={getColor(colorsLookup[channel])}
      barSize={60}
      style={{ stroke: '#fff', strokeWidth: 2 }}
      isAnimationActive={false}
    />
  ));

  const getLines = () => selectedMetrics.performance.map((indicator, index) => (
    <Line
      key={`lines-${indicator}`}
      dataKey={indicator}
      yAxisId={isShowOnlyChartLines ? index === 0 ? leftY : rightY : rightY}
      stroke={index === 0 ? '#232A3F' : '#4995EC'}
      fill="#ffffff"
      stackId="indicator"
      strokeWidth={1}
      isAnimationActive={false}
    />
  ));

  const renderLegend = () => (
    <div className={styles.chartLegend}>
      {selectedMetrics.spend.map((channel) => (
        <div key={`legend-bars-${channel}`} className={styles.chartLegendItem}>
          <div className={styles.chartLegendBarIcon} style={{ background: getColor(colorsLookup[channel]) }} />
          {getLegendTitleByChannel({ channel })}
        </div>
      ))}
      {selectedMetrics.performance.map((indicator, index) => (
        <div key={`legend-line-${indicator}`} className={styles.chartLegendItem}>
          <div className={classNames(styles.chartLegendLineIcon, index === 0 ? styles.chartLegendLineFirstItem : null)} />
          {getMetricNickname({ metric: indicator })}
        </div>
      ))}
    </div>
  );

  const getChartTooltip = ({ active, payload, label }, chartDataForTooltip) => {
    if (!active || !payload) {
      return '';
    }

    const currentDataItem = chartDataForTooltip.find((item) => item.date === label);

    return (
      <div className={styles.chartTooltip}>
        {selectedMetrics.performance.length > 0 ? (
          <div className={styles.chartTooltipValues}>
            <div className={styles.chartTooltipLabel}>
              Performance
            </div>

            {selectedMetrics.performance.map((indicator, index) => {
              const growthValue = currentDataItem[`${indicator}Growth`];

              return (
                <div key={`tooltip-line-${indicator}`} className={styles.chartTooltipData}>
                  {getMetricNickname({ metric: indicator })}

                  <b>
                    {formatValueFromUnit({
                      value: currentDataItem[indicator],
                      ydirection: isShowOnlyChartLines ? index === 0 ? leftY : rightY : rightY,
                    })}
                  </b>

                  {isCompareToPreviousEnabled ? (
                    <>
                      {growthValue === undefined ? (
                        <Loading3Dots />
                      ) : (
                        <ComparisonValue
                          value={getGrowthValue({
                            metricName: indicator,
                            growthPerMetric: { [indicator]: growthValue },
                          })}
                          isGrowthDesired={getIsGrowthDesired({ metricName: indicator })}
                          className={styles.comparisonValue}
                          overrideTextColor="#FFFFFF"
                        />
                      )}
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}

        {selectedMetrics.spend.length > 0 ? (
          <div className={styles.chartTooltipValues}>
            <div className={styles.chartTooltipLabel}>
              Spend
            </div>

            {selectedMetrics.spend.map((channel) => {
              const channelData = currentDataItem[channel];
              const growthValue = currentDataItem[`${channel}Growth`];
              const isShowCompareValue = isCompareToPreviousEnabled && (!isLoaded || channelData !== undefined);

              return (
                <div key={`tooltip-bars-${channel}`} className={styles.chartTooltipData}>
                  {getChannelNickname(channel)}

                  <b>
                    {formatValueFromUnit({
                      value: channelData ?? 0,
                      ydirection: leftY,
                    })}
                  </b>

                  {isShowCompareValue ? (
                    <>
                      {growthValue === undefined ? (
                        <Loading3Dots />
                      ) : (
                        <ComparisonValue
                          value={getGrowthValue({
                            metricName: channel,
                            growthPerMetric: { [channel]: growthValue },
                          })}
                          isGrowthDesired={getIsGrowthDesired({ metricName: channel })}
                          className={styles.comparisonValue}
                          overrideTextColor="#FFFFFF"
                        />
                      )}
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const performanceListItems = useMemo(() => getPerformanceMetricsListItems({ metricsOptions, flags }), [metricsOptions,
    flags]);
  const spendListItems = getSpendMetricsListItems();

  const saveSelectedMetrics = (newMetrics) => {
    const allMetricsOptions = [...performanceListItems, ...spendListItems];
    const allNewMetrics = [...newMetrics.performance, ...newMetrics.spend];
    const metrics = allMetricsOptions.filter((item) => allNewMetrics.includes(item.metricName));
    updateTrendAnalysisMetrics(metrics);
  };

  async function onExportData() {
    setIsExportLoading(true);
    const spentNicknames = selectedMetrics.spend.map((metric) => {
      const label = metric === 'total' ? 'Total spent' : `Spend: ${getChannelNickname(metric)}`;
      return {
        value: metric,
        label,
      };
    });
    const metricNicknames = selectedMetrics.performance.map((metric) => ({
      value: metric,
      label: getMetricNickname({ metric }),
    }));
    const selectedColumns = getTrendAnalysisExportColumns({
      frequency: trendAnalysisFrequency,
      metrics: [...spentNicknames, ...metricNicknames],
    });
    await exportTrendAnalysisData({ selectedColumns });
    setIsExportLoading(false);
  }

  async function exportTrendAnalysisData({ selectedColumns }) {
    const timeFrameOptions = getInsightsTimeframeOptions();
    const timeFrameObject = timeFrameOptions.filter((option) => option.value === timeFrame?.value)?.[0];
    const timeFrameLabel = timeFrameObject?.label;

    const fileName = `trend-analysis-${timeFrameLabel}`;
    const config = getWidgetFullConfig({
      widgetConfig: {
        trendAnalysisParams: {
          frequency: trendAnalysisFrequency,
          shouldUseRelativeTimeframe: trendAnalysisShouldUseRelativeTimeframe,
          selectedColumns,
        },
        type: widgetTypes.trendAnalysis,
        timeFrameLabel,
      },
    });

    const eventData = getTrendAnalysisEventData({ widgetConfig: config });
    try {
      await exportData.exportAnalyzeWidgetData({
        widget: widgetTypes.trendAnalysis,
        config,
        selectedColumns,
        parse: parseTrendAnalysisDataForExport,
        createSheet: createSheetAnalyze,
        eventData,
        fileName,
        dataToExport: chartData,
      });
    } catch (error) {
      servicesStore.logger.error('failed to get trend analysis export', {
        error,
      });
    }
  }

  const widgetHeaderConfigsOptions = [];
  if (exportMode) {
    widgetHeaderConfigsOptions.unshift({
      label: 'Export data',
      action: () => onExportData(),
      disabled: !isLoaded || isExportLoading,
      tooltip: 'Loading data...',
    });
  }

  if (isFailedToLoad) {
    return (
      <ErrorWidgetWithBlur
        status="error"
        widgetType={widgetTypes.trendAnalysis}
      />
    );
  }

  const isChartDataIsEmpty = checkIfChartDataIsEmpty({ chartData, keyToSkip: 'date' });

  return (
    <>
      <WidgetHeader
        {...widgetHeaderProps}
        cogwheelOptions={widgetHeaderConfigsOptions}
        widgetHeaderConfig={widgetHeaderConfig}
      >
        <TrendAnalysisSelectMetricsPopup
          performanceListItems={performanceListItems}
          spendListItems={spendListItems}
          selectedMetrics={selectedMetrics}
          onSaveSelectedMetrics={(metrics) => saveSelectedMetrics(metrics)}
        />

        <FrequencySelect
          onChange={({ value }) => updateTrendAnalysisFrequency(value)}
          selected={trendAnalysisFrequency}
          options={Object.values(FREQUENCY_OPTIONS)}
          widgets={[widgetsConfig.trendAnalysis.type]}
        />

        <MonthToDate
          frequency={trendAnalysisFrequency}
          shouldUseRelativeTimeframe={trendAnalysisShouldUseRelativeTimeframe}
          updateShouldUseRelativeTimeframe={updateTrendAnalysisShouldUseRelativeTimeframe}
        />
        { isExportLoading && <Spinner /> }
      </WidgetHeader>

      {isLoaded ? (
        <>
          {isChartDataIsEmpty && emptyStateComponent ? (
            emptyStateComponent
          ) : (
            <div className={styles.widgetWrapper}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={chartData}
                  maxBarSize={85}
                >
                  <CartesianGrid
                    vertical={false}
                    strokeDasharray="3 3"
                    strokeWidth={1}
                    stroke="rgba(54, 56, 64, 0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    xAxisId={0}
                    axisLine={false}
                    tick={{ fontSize: '12px', color: '#707ea7' }}
                    tickLine={false}
                    tickMargin={10}
                  />
                  <XAxis dataKey="date" xAxisId={1} hide />
                  <YAxis
                    yAxisId="left"
                    axisLine={false}
                    tick={{ fontSize: '12px', color: '#707ea7' }}
                    tickLine={false}
                    tickMargin={15}
                    tickFormatter={(value) => formatValueFromUnit({ value, ydirection: leftY })}
                    width={70}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    axisLine={false}
                    tick={{ fontSize: '12px', color: '#707ea7' }}
                    tickLine={false}
                    tickMargin={15}
                    tickFormatter={(value) => formatValueFromUnit({ value, ydirection: rightY })}
                    width={70}
                  />
                  {getBars()}
                  {getLines()}
                  <Tooltip
                    cursor={false}
                    offset={0}
                    isAnimationActive={false}
                    content={(chartProps) => getChartTooltip(chartProps, chartData)}
                    animationDuration={50}
                    viewBox={{
                      x: 0, y: 0, width: 400, height: 400,
                    }}
                    allowEscapeViewBox={{ x: true, y: true }}
                  />
                  <Legend content={renderLegend} />
                </ComposedChart>
              </ResponsiveContainer>
              {(!isLoaded && !isHasBars) ? (
                <div className={styles.dataLoading}>
                  Loading Data
                  <Loading3Dots />
                </div>
              ) : null}
            </div>
          )}
        </>
      ) : (
        <div className={styles.widgetWrapper}>
          <ChartSkeleton />
        </div>
      )}

    </>
  );
}

TrendAnalysis.defaultProps = {
  trendAnalysisFrequency: FREQUENCY_VALUES.MONTH,
  trendAnalysis: [{ date: '', metrics: [] }],
  trendAnalysisShouldUseRelativeTimeframe: false,
  trendAnalysisMetrics: trendAnalysisInitialMetrics,
};

export default withLDConsumer()(inject(
  ({
    userStore: {
      getMetricNickname,
    },
  }) => ({
    getMetricNickname,
  }),
  observer
)(TrendAnalysis));
