import { TIMEFRAME_LABELS, TIMEFRAME_VALUES } from 'components/utils/timeframe';

export const activityStatusLabels = {
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  ALL: 'All',
};

export const activityStatusValues = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  ALL: 'all',
};

export const frequencyTypes = {
  YEAR: 'groupByYear',
  QUARTER: 'groupByQuarter',
  MONTH: 'groupByMonth',
};

export const frequencyTypesToLabel = {
  [frequencyTypes.YEAR]: TIMEFRAME_LABELS.YEAR,
  [frequencyTypes.QUARTER]: TIMEFRAME_LABELS.QUARTER,
  [frequencyTypes.MONTH]: TIMEFRAME_LABELS.MONTH,
};

export const frequencyTypesToPrevLabel = {
  [frequencyTypes.YEAR]: TIMEFRAME_LABELS.PREV_YEAR,
  [frequencyTypes.QUARTER]: TIMEFRAME_LABELS.PREV_QUARTER,
  [frequencyTypes.MONTH]: TIMEFRAME_LABELS.PREV_MONTH,
};

export const divideTypes = {
  EQUALLY: 'equally',
  MANUALLY: 'manually',
};

export const defaultMilestonesConfig = {
  frequencyType: frequencyTypes.MONTH,
  divideType: divideTypes.EQUALLY,
};

export const activityStatusOptions = [
  { label: activityStatusLabels.ACTIVE, value: activityStatusValues.ACTIVE },
  { label: activityStatusLabels.COMPLETED, value: activityStatusValues.COMPLETED },
  { label: activityStatusLabels.ALL, value: activityStatusValues.ALL },
];

export const timeframeOptions = [
  { label: TIMEFRAME_LABELS.MONTH, value: TIMEFRAME_VALUES.MONTH, disabled: false },
  { label: TIMEFRAME_LABELS.QUARTER, value: TIMEFRAME_VALUES.QUARTER, disabled: false },
  { label: TIMEFRAME_LABELS.YEAR, value: TIMEFRAME_VALUES.YEAR, disabled: false },
  { label: TIMEFRAME_LABELS.PREV_MONTH, value: TIMEFRAME_VALUES.PREV_MONTH, disabled: false },
  { label: TIMEFRAME_LABELS.PREV_QUARTER, value: TIMEFRAME_VALUES.PREV_QUARTER, disabled: false },
  { label: TIMEFRAME_LABELS.PREV_YEAR, value: TIMEFRAME_VALUES.PREV_YEAR, disabled: false },
];

export const pickerTimeframeToFrequencyType = {
  [TIMEFRAME_VALUES.YEAR]: frequencyTypes.YEAR,
  [TIMEFRAME_VALUES.PREV_YEAR]: frequencyTypes.YEAR,
  [TIMEFRAME_VALUES.QUARTER]: frequencyTypes.QUARTER,
  [TIMEFRAME_VALUES.PREV_QUARTER]: frequencyTypes.QUARTER,
  [TIMEFRAME_VALUES.MONTH]: frequencyTypes.MONTH,
  [TIMEFRAME_VALUES.PREV_MONTH]: frequencyTypes.MONTH,
};

const supportedMetricTypes = {
  funnelStage: 'funnelStage',
  velocity: 'velocity',
  conversionRate: 'conversionRate',
  revenue: 'revenue',
  averageSalesCycle: 'averageSalesCycle',
  efficiency: 'efficiency',
  ARPA: 'ARPA',
  LTV: 'LTV',
  ROI: 'ROI',
  costPerFunnelStage: 'costPerFunnelStage',
};

export const nonCumulativeMetricTypes = [
  supportedMetricTypes.conversionRate,
  supportedMetricTypes.velocity,
  supportedMetricTypes.ARPA,
  supportedMetricTypes.LTV,
  supportedMetricTypes.ROI,
  supportedMetricTypes.efficiency,
];

export const nonUpDirectionMetricTypes = [
  supportedMetricTypes.velocity,
  supportedMetricTypes.averageSalesCycle,
  supportedMetricTypes.efficiency,
  supportedMetricTypes.costPerFunnelStage,
];

export const goalSkeletonId = 'skeleton';
