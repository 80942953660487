import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';

import planButtonIcon from 'assets/plan.svg';
import style from 'styles/onboarding/buttons.css';

export default class NextButton extends Component {
  style = style;

  render() {
    return (
      <Button
        type="primaryBlue"
        onClick={this.props.onClick}
        className={this.classes.planButton}
        icon={planButtonIcon}
        style={{
          width: '128px',
          letterSpacing: 0.075,
        }}
      >
        PLAN
      </Button>
    );
  }
}
