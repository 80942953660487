import { baselineScenario } from 'components/widgets/whatIf/enums';

export function getIsAllScenarioRulesAreFilled({ scenarios }) {
  if (scenarios.length === 1) {
    return true;
  }
  return scenarios.every((scenario) => scenario.rules.every((rule) => {
    if (scenario.id === baselineScenario) {
      return true;
    }
    return scenario.type === 'channel' ? !!rule.channel : true;
  }));
}
