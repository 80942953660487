import React from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';

import { suggestionsDropdownStyle } from 'components/pages/questions/enums';
import {
  questionsTypesOptions, kpiFocusOptionsMockData, unclickableKpiFocusToOptionValue, unclickableQuestionKindToOptionValue,
} from 'components/widgets/optimalJourney/enums';
import { getJourneyQuestionTooltipMockData } from 'components/pages/analyze/optimalJourneyTab/logic/optimalJourneysTab';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function OptimalJourneyQuestion({
  metricsOptions,
  journeyQuestion,
  setJourneyQuestion,
  flags,
  // onChangeQuestion, // TODO: update onChangeQuestion
}) {
  useStyles([style]);

  function handelChangeQuestion({ type, value }) {
    if (type === 'kpiFocus') {
      for (const [unclickableKpiFocus, matchedOptionValue] of Object.entries(unclickableKpiFocusToOptionValue)) {
        if (matchedOptionValue === value && flags.optimalJourneysUnclickableItems[unclickableKpiFocus]) {
          return;
        }
      }
    }

    if (type === 'kind') {
      for (const [unclickableQuestionKind, matchedOptionValue] of Object.entries(unclickableQuestionKindToOptionValue)) {
        if (matchedOptionValue === value && flags.optimalJourneysUnclickableItems[unclickableQuestionKind]) {
          return;
        }
      }
    }

    setJourneyQuestion((prev) => ({
      ...prev,
      [type]: value,
    }));

    // onChangeQuestion({ kind: questionsTypes[value], kpiFocus: value }); // TODO: update onChangeQuestion
  }

  return (
    <div className={styles.questionContainer}>
      <span>Show me the story behind my</span>
      <Dropdown
        className={styles.questionDropdownLink}
        options={questionsTypesOptions}
        selectedKey={journeyQuestion.kind}
        onChange={(event) => handelChangeQuestion({ type: 'kind', value: event.value })}
        isDropdownAsLink
        menuMaxWidth={160}
        customControlTextStyles={{
          ...suggestionsDropdownStyle.dropdownControlText,
          fontSize: '18px',
        }}
        customDropdownIndicatorWrapperStyles={suggestionsDropdownStyle.dropdownIndicatorWrapper}
        customDropdownIndicatorArrowStyles={suggestionsDropdownStyle.dropdownIndicatorArrow}
      />
      <span>journeys to</span>
      <Dropdown
        className={styles.questionDropdownLink}
        options={flags.optimalJourneysDemoOnly ? kpiFocusOptionsMockData : metricsOptions}
        selectedKey={journeyQuestion.kpiFocus}
        onChange={(event) => handelChangeQuestion({ type: 'kpiFocus', value: event.value })}
        isDropdownAsLink
        menuMaxWidth={160}
        customControlTextStyles={{
          ...suggestionsDropdownStyle.dropdownControlText,
          fontSize: '18px',
        }}
        customDropdownIndicatorWrapperStyles={suggestionsDropdownStyle.dropdownIndicatorWrapper}
        customDropdownIndicatorArrowStyles={suggestionsDropdownStyle.dropdownIndicatorArrow}
      />
      <InfoMarker
        tooltipText={getJourneyQuestionTooltipMockData({ question: journeyQuestion })}
        TooltipProps={{
          className: styles.tooltip,
        }}
      />
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    attributionStore: {
      metricsOptions,
    },
  }) => ({
    metricsOptions,
  }),
  observer
)(OptimalJourneyQuestion));
