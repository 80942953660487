import { cloneDeep } from 'lodash';

function getWidgetConfig({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  return cloneDeep(widgetConfig);
}

function getWidgetConfigurationForQuestionResult({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  return cloneDeep(widgetConfig);
}

function processResponseFromServer({ result }) {
  return result;
}

export default {
  getWidgetConfig,
  processResponseFromServer,
  getWidgetConfigurationForQuestionResult,
};
