exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".chart__overlay-1ve{position:fixed;top:0;left:0;display:flex;width:100%;height:100%;justify-content:center;align-items:center;background:rgba(0,0,0,.63);z-index:4}.chart__popup-uQ5{width:1292px;height:278px;background:#fff;border-radius:4px;z-index:5;position:relative;padding:24px}.chart__close-icon-1eC{position:absolute;cursor:pointer;right:18px;top:18px;width:15px;height:15px;background:url(/assets/close-icon.svg);z-index:1}.chart__popupTitle-19K{font-family:Avenir Next,museo-sans,Source Sans Pro,sans-serif;font-style:normal;font-weight:600;font-size:16px;line-height:24px;color:#182033;margin-top:0}.chart__recharts-legend-wrapper-1v9{position:absolute;top:0!important;right:0!important}", ""]);

// exports
exports.locals = {
	"overlay": "chart__overlay-1ve",
	"overlay": "chart__overlay-1ve",
	"popup": "chart__popup-uQ5",
	"popup": "chart__popup-uQ5",
	"close-icon": "chart__close-icon-1eC",
	"closeIcon": "chart__close-icon-1eC",
	"popupTitle": "chart__popupTitle-19K",
	"popupTitle": "chart__popupTitle-19K",
	"recharts-legend-wrapper": "chart__recharts-legend-wrapper-1v9",
	"rechartsLegendWrapper": "chart__recharts-legend-wrapper-1v9"
};