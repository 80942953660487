import {
  useState,
  useEffect,
} from 'react';

import serverCommunication from 'data/serverCommunication';

export default function useServerRequest({
  body,
  route,
  method,
  region,
  onError,
  withoutID,
  onRequestEnd,
  customModifier,
  onRequestStart,
  processResponse = (data) => data,
  mockProperties,
}) {
  const [requestResult, setRequestResult] = useState();

  useEffect(() => {
    async function sendRequest() {
      try {
        if (onRequestStart) {
          onRequestStart();
        }
        const response = await serverCommunication.serverRequest(method, route, body, region, withoutID, customModifier);
        const responseData = await response.json();
        const processedResponse = processResponse(responseData);
        setRequestResult(processedResponse);
      } catch (error) {
        if (onError) {
          onError(error);
        }
        setRequestResult(null);
      } finally {
        if (onRequestEnd) {
          onRequestEnd();
        }
      }
    }

    if (mockProperties) {
      const {
        mockResult,
        mockResponseTime,
      } = mockProperties;

      if (onRequestStart) {
        onRequestStart();
      }

      setTimeout(() => {
        const processedResponse = processResponse(mockResult);
        setRequestResult(processedResponse);
        if (onRequestEnd) {
          onRequestEnd();
        }
      }, mockResponseTime);
    } else {
      sendRequest();
    }
  }, []);

  useEffect(() => {
  }, [requestResult]);

  return requestResult;
}
