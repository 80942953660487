export function setQueryParams({ queryParamKey, queryParamValues }) {
  if (!queryParamValues) {
    return;
  }
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.set(queryParamKey, queryParamValues);
  const pathURL = `${window.location.pathname}?${currentSearchParams.toString()}`;
  window.history.replaceState({}, '', pathURL);
}

export function removeQueryParams({ queryParamKey }) {
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.delete(queryParamKey);
  const searchParamsWithoutParams = currentSearchParams.toString();
  const pathURL = `${window.location.pathname}${searchParamsWithoutParams ? `?${searchParamsWithoutParams}` : ''}`;
  window.history.replaceState({}, '', pathURL);
}

export function getQueryParams({ queryParamKey }) {
  const currentSearchParams = new URLSearchParams(window.location.search);
  return currentSearchParams.get(queryParamKey);
}
