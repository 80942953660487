import React from 'react';
import classnames from 'classnames';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

function FilterHeader({ isShowSavedFilterTab, tabChangeHandler, tabActiveIndex }) {
  const tabsList = ['Filter'];
  if (isShowSavedFilterTab) {
    tabsList.push('Saved Filters');
  }

  const onTabChange = (e, index) => {
    tabChangeHandler(index);
  };

  return (
    <div className={styles.tabs}>
      <ul>
        {tabsList.map((item, index) => {
          const tabKey = `tab-${index}`;
          return (
            <li key={tabKey} className={classnames(styles.tabItem, tabActiveIndex === index ? styles.tabActive : '')}>
              <div onClick={(e) => onTabChange(e, index)}>{item}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default FilterHeader;
