import React, { useMemo } from 'react';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

import useStyles from 'hooks/useStyles';

import Textfield from 'components/controls/Textfield';
import MappingRule from 'components/pages/settings/customSegments/MappingRule';

import { bucketOptionsTypes, overlappingErrorMessage } from 'components/pages/settings/customSegments/enums';

import style from 'styles/settings/custom-segments/custom-segments.css';
import channelsTabStyle from 'styles/settings/channels/channels-tab.css';
import { suggestionsDropdownTypes } from '../../questions/enums';

const styles = style.locals || {};
const channelsTabStyles = channelsTabStyle.locals || {};

function SegmentBucket({
  segmentBucketData,
  isActive,
  onChangeBucketConfig,
  originalFieldSelectedKey,
  errors,
  onDeactivateBucket,
  isShowDeleteButton,
}) {
  useStyles([style, channelsTabStyle]);

  const bucketOptions = useMemo(() => Object.keys(bucketOptionsTypes).map((key) => ({
    value: key,
    label: bucketOptionsTypes[key],
  })), []);

  function onChangeBucketName({ value }) {
    const updatedCustomSegmentData = { ...segmentBucketData };
    updatedCustomSegmentData.name = value;
    onChangeBucketConfig({ newConfig: updatedCustomSegmentData });
  }

  function onAddRule({ ruleGroupIndex }) {
    const updatedCustomSegmentData = { ...segmentBucketData };
    updatedCustomSegmentData.rules[ruleGroupIndex].push({
      operation: '',
      value: '',
      id: uuidv4(),
    });
    onChangeBucketConfig({ newConfig: updatedCustomSegmentData });
  }

  function onAddRuleGroup({ ruleGroupIndex }) {
    const newRuleGroup = [{
      operation: '',
      value: '',
      id: uuidv4(),
    }];
    const updatedCustomSegmentData = cloneDeep(segmentBucketData);
    updatedCustomSegmentData.rules.splice(ruleGroupIndex + 1, 0, newRuleGroup);

    onChangeBucketConfig({ newConfig: updatedCustomSegmentData });
  }

  function onDeleteRule({ ruleGroupIndex, ruleIndex }) {
    const updatedCustomSegmentData = { ...segmentBucketData };
    const segmentRules = updatedCustomSegmentData.rules[ruleGroupIndex];
    segmentRules.splice(ruleIndex, 1);
    if (segmentRules.length === 0) {
      updatedCustomSegmentData.rules.splice(ruleGroupIndex, 1);
    }
    onChangeBucketConfig({ newConfig: updatedCustomSegmentData });
  }

  function onChangeBucketRule({
    ruleGroupIndex, value, ruleIndex, type,
  }) {
    const updatedCustomSegmentData = { ...segmentBucketData };
    updatedCustomSegmentData.rules[ruleGroupIndex][ruleIndex][type] = value;
    onChangeBucketConfig({ newConfig: updatedCustomSegmentData });
  }

  const totalRules = segmentBucketData.rules.reduce((result, ruleGroup) => result + ruleGroup.length, 0);
  const hasMultipleRules = totalRules > 1;

  const isError = errors && (errors.baseGroupId === segmentBucketData.id || errors.targetGroupId === segmentBucketData.id);

  return (
    <div className={classnames(channelsTabStyles.whiteBox, styles.bucketsWrapper, isActive && styles.activeBox, isError && styles.errorBox)}>
      <div className={styles.topBucket}>
        <Textfield
          placeHolder="Enter bucket name"
          value={segmentBucketData.name}
          onChange={(event) => onChangeBucketName({ value: event.target.value })}
        />

        {isShowDeleteButton ? (
          <div
            className={styles.removeBucket}
            onClick={() => onDeactivateBucket()}
            data-testid="delete-bucket"
          />
        ) : null}
      </div>

      Mapping rules
      <div className={styles.segmentMappingRules}>
        {segmentBucketData.rules.map((ruleGroup, ruleGroupIndex) => {
          const ruleGroupKey = `ruleGroup-${ruleGroupIndex}`;
          const isLastGroup = ruleGroupIndex === segmentBucketData.rules.length - 1;

          return (
            <div
              key={ruleGroupKey}
              className={styles.ruleGroup}
            >
              {ruleGroup.map((rule, ruleIndex) => {
                const ruleKey = `rule-${ruleGroupIndex}-${ruleIndex}-${rule.operation}-${segmentBucketData.id}`;

                return (
                  <MappingRule
                    key={ruleKey}
                    rule={rule}
                    originalFieldId={originalFieldSelectedKey}
                    filterType={suggestionsDropdownTypes.customFields}
                    onAddRule={() => onAddRule({ ruleGroupIndex })}
                    onAddRuleGroup={() => onAddRuleGroup({ ruleGroupIndex })}
                    canBeDeleted={hasMultipleRules}
                    onDeleteRule={() => onDeleteRule({ ruleGroupIndex, ruleIndex })}
                    bucketOptions={bucketOptions}
                    onChangeBucketRule={({ value, type }) => onChangeBucketRule({
                      ruleGroupIndex, value, type, ruleIndex,
                    })}
                    shouldEnableNewRule={ruleIndex === segmentBucketData.rules[ruleGroupIndex].length - 1}
                  />
                );
              })}

              {!isLastGroup ? (
                <div className={styles.ruleGroupDivider}>
                  <span className={styles.dividerText}>
                    OR
                  </span>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      {isError ? (
        <div className={styles.errorMessage}>
          {overlappingErrorMessage}
        </div>
      ) : null}
    </div>
  );
}

export default SegmentBucket;
