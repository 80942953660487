import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/analyze/collapsable-split-view.css';

const styles = style.locals || {};

export default function CollapsableSplitView({ children }) {
  useStyles([style]);

  return (
    <div className={styles.splitViewContainer}>
      {children}
    </div>
  );
}

function LeftPanel({ children, className, isRightCollapsed }) {
  return (
    <div className={classnames(styles.pane, styles.leftPanel, isRightCollapsed ? styles.expanded : styles.collapsed)}>
      <div className={classnames(styles.paneContent, className)}>
        {children}
      </div>
    </div>
  );
}

function ToggleButton({ isRightCollapsed, setIsRightCollapsed }) {
  return (
    <div
      onClick={() => setIsRightCollapsed((prevState) => (!prevState))}
      className={styles.expandButton}
      aria-label={isRightCollapsed ? 'Expand right pane' : 'Collapse right pane'}
    />
  );
}

function RightPanel({
  children, className, isRightCollapsed, setIsRightCollapsed,
}) {
  return (
    <div className={classnames(styles.fullPane, styles.rightPanel, isRightCollapsed ? styles.collapsed : styles.expanded)}>
      <ToggleButton isRightCollapsed={isRightCollapsed} setIsRightCollapsed={setIsRightCollapsed} />
      <div className={classnames(styles.paneContent, className)}>
        {children}
      </div>
    </div>
  );
}

CollapsableSplitView.LeftPanel = LeftPanel;
CollapsableSplitView.RightPanel = RightPanel;
