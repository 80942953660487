import React from 'react';
import Component from 'components/Component';
import style from 'styles/welcome/objective-popup.css';
import Textfield from 'components/controls/Textfield';
import isEmpty from 'lodash/isEmpty';
import Tooltip from 'components/controls/Tooltip';
import Dropdown from 'components/controls/Dropdown';

const DEFAULT_WIDTH = 250;

const Wrapper = ({ children, tooltip }) => (tooltip ? (
  <Tooltip tip={tooltip} id="tooltip-objective" place="right">
    {children}
  </Tooltip>
) : children);

class ObjectiveInput extends Component {
  style = style;

  static defaultProps = {
    width: 'auto',
    input: false,
    description: '',
    title: '',
    selectProps: {},
    inputProps: {},
    tooltip: null,
    containerStyle: {},
  };

  render() {
    const {
      title, description, width, defaultWidth, containerClass, containerStyle, selectProps, inputProps, children, tooltip, optional,
    } = this.props;
    return (
      <div className={containerClass} style={containerStyle}>
        <div className={this.classes.inputWrapper} style={{ maxWidth: defaultWidth ? DEFAULT_WIDTH : width }}>
          <div className={this.classes.inputTitle}>
            {title}
            {' '}
            {optional && <span>Optional</span>}
          </div>
          <Wrapper tooltip={tooltip}>
            {children || (!isEmpty(inputProps)
              ? (
                <Textfield
                  value=""
                  required
                  {...inputProps}
                />
              ) : (
                <Dropdown
                  {...selectProps}
                />
              ))}
          </Wrapper>
          <div className={this.classes.inputDescription}>
            {description}
          </div>
        </div>
      </div>

    );
  }
}

export default ObjectiveInput;
