exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".switch__switch-container-2IO{display:flex;width:32px;padding:2px;align-items:center;gap:10px;height:18px;background-color:#e6e8f0;border-radius:12px;position:relative;cursor:pointer;transition:.2s ease-out;transition-delay:.1s}.switch__switch-container--active--ym{background-color:#5e8ff4}.switch__disabled-qgj{cursor:auto;opacity:.5}.switch__switch-handle-1Jw{position:absolute;top:2px;width:18px;height:18px;background-color:#fff;border-radius:50%;transition:.2s linear}.switch__switch-handle--left-1r9{transform:translate(0)}.switch__switch-handle--right-LCP{transform:translate(14px)}.switch__switch-title-psT{font-family:FaktPro,sans-serif;font-size:14px;color:#182033;margin-left:7px}.switch__row-2FE{display:flex;flex-direction:row;align-items:center}", ""]);

// exports
exports.locals = {
	"switch-container": "switch__switch-container-2IO",
	"switchContainer": "switch__switch-container-2IO",
	"switch-container--active": "switch__switch-container--active--ym",
	"switchContainerActive": "switch__switch-container--active--ym",
	"disabled": "switch__disabled-qgj",
	"disabled": "switch__disabled-qgj",
	"switch-handle": "switch__switch-handle-1Jw",
	"switchHandle": "switch__switch-handle-1Jw",
	"switch-handle--left": "switch__switch-handle--left-1r9",
	"switchHandleLeft": "switch__switch-handle--left-1r9",
	"switch-handle--right": "switch__switch-handle--right-LCP",
	"switchHandleRight": "switch__switch-handle--right-LCP",
	"switch-title": "switch__switch-title-psT",
	"switchTitle": "switch__switch-title-psT",
	"row": "switch__row-2FE",
	"row": "switch__row-2FE"
};