exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".infoMarker-module__info-marker___3Fa2v{width:13px;height:13px;background-color:#8f9ab8;-webkit-mask-image:url(/assets/analyze-icons/stat-info.svg);mask-image:url(/assets/analyze-icons/stat-info.svg);mask-repeat:no-repeat}.infoMarker-module__info-marker___3Fa2v:hover{background-color:#4788f6}", ""]);

// exports
exports.locals = {
	"info-marker": "infoMarker-module__info-marker___3Fa2v"
};