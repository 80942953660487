import { get, uniqueId } from 'lodash';
import React, { useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';
import Tooltip from 'components/controls/Tooltip';
import Skeleton from 'components/common/Skeleton';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';

import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import { getIndicatorsWithProps, formatIndicatorDisplay } from 'components/utils/indicators';
import { skeletonSmallTextSizes, skeletonBigTextSizes } from 'components/common/enums';

import style from 'styles/analyze/performance-item.css';

const styles = style.locals || {};

function PerformanceItem({
  data,
  mode,
  showInfo,
  navigationMenuPopupParams = [],
  withoutHover,
  isLoaded,
  indicator,
  isCompareToPreviousEnabled,
  isHideComparisonValue,
  isEnableNavigationMenu = true,
}) {
  useStyles([style]);

  if (!data) {
    return null;
  }

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);

  function getTooltip() {
    const indicatorSchema = getIndicatorsWithProps() || {};
    return get(indicatorSchema, [indicator, 'description']);
  }

  function clickOnNavigationMenuPopup(isShow) {
    setShowNavigationMenuPopup(isShow);
  }

  const isDataHasValue = data.value >= 0;

  return (
    <>
      <div
        onClick={navigationMenuPopupParams.length > 0 && isDataHasValue && isEnableNavigationMenu ? () => clickOnNavigationMenuPopup(true) : null}
        className={classnames(
          styles.itemBox,
          (withoutHover || !isLoaded || navigationMenuPopupParams.length === 0 || !isEnableNavigationMenu) ? styles.withoutHover : null,
          mode ? styles[mode] : null,
          showNavigationMenuPopup ? styles.navigationMenuOpen : null
        )}
      >
        <div className={styles.navigationMenu}>
          {showNavigationMenuPopup && (
          <div className={styles.navigationMenuPopup}>
            <NavigationMenuPopup
              navigationOptions={navigationMenuPopupParams}
              onClosePopup={(showNavigationPopup) => clickOnNavigationMenuPopup(showNavigationPopup)}
            />
          </div>
          )}
        </div>
        <div className={styles.title} data-testid="metric-title">
          {(isLoaded || (isDataHasValue && data.title)) ? data.title : <Skeleton {...skeletonSmallTextSizes} isLightTheme />}
          { showInfo && (
          <Tooltip
            id={uniqueId()}
            tip={getTooltip()}
            TooltipProps={{ className: styles.indicatorTooltip }}
          >
            <span data-testid="additional-info" className={styles.info} />
          </Tooltip>
          )}
        </div>
        <div className={styles.value} data-testid="metric-value">
          {(isLoaded || isDataHasValue) ? (
            formatIndicatorDisplay(data.indicator, data.value || 0, true)
          ) : (
            <Skeleton {...skeletonBigTextSizes} />
          )}
        </div>
        {(!isHideComparisonValue && isCompareToPreviousEnabled) ? (
          data.growth === undefined ? (
            <Loading3Dots />
          ) : (
            <ComparisonValue
              value={getGrowthValue({
                metricName: data.indicator,
                growthPerMetric: { [data.indicator]: data.growth },
              })}
              isGrowthDesired={getIsGrowthDesired({ metricName: data.indicator })}
            />
          )
        ) : null}
      </div>
    </>
  );
}

export default PerformanceItem;
