import React, { useEffect, useState, useMemo } from 'react';
import Page from 'components/Page';
import integrationsStyle from 'styles/integrations/integration.css';
import { isCostCustomFieldsFilter, filterKinds } from 'components/utils/filters/logic';
import serverCommunication from 'data/serverCommunication';
import Spinner from 'components/pages/journeys/Spinner';
import useWindowDimensions from 'components/utils/getWindowDimensions';
import SaveButton from 'components/pages/profile/SaveButton';
import PreDefinedFiltersPlatform from '../../integrations/PreDefinedFiltersPlatform';
import { getEmptyRuleFields } from './CampaignsRules/logic/CampaignsContext';
import { checkAllFieldsAreFilledForSpecificPlatforms, addVariantToSelectedFilter } from './logic/CampaignCustomFieldsFilter';
import { checkAllFieldsAreFilledForAllPlatforms, getFilterConfigsFromSavedFilter, getPredefinedFiltersForRequest } from './logic/CostPreDefinedFilters';

const styles = integrationsStyle.locals;

const CostPreDefinedFilters = ({ close, filterConfigs, region }) => {
  const costCustomFieldsConfig = useMemo(() => filterConfigs.find((filterItem) => isCostCustomFieldsFilter(filterItem.kind))?.options, [filterConfigs]);
  const [costFilterConfigs, setCostFilterConfigs] = useState(costCustomFieldsConfig);
  const [isDisableFilterActions, setIsDisableFilterActions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSavedFilters, setIsLoadingSavedFilters] = useState(false);
  const [predefinedFilters, setPredefinedFilters] = useState([]);

  const setUpdatePredefinedFilters = (filterData) => {
    const filterObj = {
      config: {
        kind: filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS,
      },
      data: {
        platform: filterData.platform,
        rules: filterData.rules,
      },
      removeFormRequest: filterData.removeFormRequest,
    };
    const updatePredefinedFilters = [...predefinedFilters];
    const updatePlatformIndex = updatePredefinedFilters.findIndex((filter) => filter.data.platform === filterData.platform);
    const selectedFilterWithVariant = addVariantToSelectedFilter(filterObj);
    updatePredefinedFilters.splice(updatePlatformIndex, 1, selectedFilterWithVariant);
    setPredefinedFilters(updatePredefinedFilters);
  };

  const updateFilter = (filterData) => {
    let allFieldsAreFilled = false;
    if (filterData.removeFormRequest) {
      const filteredPredefinedFilters = predefinedFilters.filter((filter) => filter.data.platform !== filterData.platform && !filter.removeFormRequest);
      allFieldsAreFilled = checkAllFieldsAreFilledForAllPlatforms(filteredPredefinedFilters);
    } else {
      allFieldsAreFilled = checkAllFieldsAreFilledForSpecificPlatforms(filterData);
    }
    setIsDisableFilterActions(!allFieldsAreFilled);
    setUpdatePredefinedFilters(filterData);
  };

  const setPredefinedFiltersWithResponseData = (responseData, filtersConfig) => {
    const updatedPredefinedFilters = [...responseData.filters];
    for (const platform of Object.keys(filtersConfig)) {
      const findPlatformIndex = updatedPredefinedFilters.findIndex((filter) => filter.data.platform === platform);
      if (findPlatformIndex === -1) {
        const platformData = {
          config: { kind: filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS },
          data: {
            platform,
            rules: [
              [getEmptyRuleFields(filtersConfig)],
            ],
          },
          removeFormRequest: true,
        };
        updatedPredefinedFilters.push(platformData);
      }
    }
    setPredefinedFilters(updatedPredefinedFilters);
  };

  const setFilterConfigsFromSavedFilter = (filters) => {
    const filterConfigsFromSavedFilter = getFilterConfigsFromSavedFilter(filters, costFilterConfigs);
    setCostFilterConfigs(filterConfigsFromSavedFilter);
  };

  const getPredefinedFiltersRequest = async () => {
    setIsLoading(true);
    try {
      const response = await serverCommunication.serverRequest('GET', 'cost/predefinedFilters', null, region);
      if (response.ok) {
        const responseData = await response.json();
        setFilterConfigsFromSavedFilter(responseData.filters);
        setPredefinedFiltersWithResponseData(responseData, costCustomFieldsConfig);
        setIsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getPredefinedFiltersRequest();
  }, []);

  const setPredefinedFiltersRequest = async (predefinedFiltersForRequest) => {
    setIsLoadingSavedFilters(true);
    try {
      const response = await serverCommunication.serverRequest('PUT', 'cost/predefinedFilters', JSON.stringify({
        filters: predefinedFiltersForRequest,
      }), region);
      if (response.ok) {
        setIsLoadingSavedFilters(false);
        close();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleFilterApply = () => {
    const predefinedFiltersForRequest = getPredefinedFiltersForRequest(predefinedFilters);
    setPredefinedFiltersRequest(predefinedFiltersForRequest);
  };

  const { height: screenHeight } = useWindowDimensions();
  const popupHeight = `${screenHeight - 350}px`;

  return (
    <Page popup width={630}>
      <div className={styles.costPreDefinedFiltersContent}>
        <div className={styles.closePopup} onClick={close} />
        <div className={styles.headTitle}>
          Cost Predefined filters
        </div>
        <div className={styles.headSubTitle}>
          Set up default filters to further customize costs.
        </div>
        {isLoading ? (
          <div className={styles.isLoadingWrap}>
            <Spinner />
          </div>
        )
          : (
            <div className={styles.preDefinedFiltersRules} style={{ maxHeight: popupHeight }}>
              {Object.values(predefinedFilters).map((predefinedFilter) => {
                const activeFilter = predefinedFilter.data;
                return (
                  <PreDefinedFiltersPlatform
                    activeFilter={activeFilter}
                    filtersConfig={costFilterConfigs}
                    updateFilter={updateFilter}
                    key={`predefine-${predefinedFilter.data.platform}`}
                  />
                );
              })}
            </div>
          )}

      </div>
      {predefinedFilters.length
        ? (
          <div className={styles.preDefinedFiltersApply}>
            {isLoadingSavedFilters && <Spinner />}
            <SaveButton
              style={{ padding: '0 24px' }}
              disabled={isDisableFilterActions}
              onClick={handleFilterApply}
            />
          </div>
        )
        : null}
    </Page>
  );
};

export default CostPreDefinedFilters;
