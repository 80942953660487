import { reportTemplateIconMapping } from 'components/pages/reports/enums';

export function getTemplateProps({ rawTemplatesData }) {
  const cards = [];
  const configMapping = {};

  for (const templateData of rawTemplatesData) {
    const {
      name,
      title,
      description,
    } = templateData;

    cards.push({
      name,
      title,
      dataIcon: reportTemplateIconMapping[name],
      description,
    });

    configMapping[name] = templateData;
  }

  return {
    cards,
    configMapping,
  };
}
