import React, { useContext, createContext } from 'react';

export const CRMContext = createContext();

export const useCRMCContext = () => useContext(CRMContext);

const CRMContextProvider = ({ children, values }) => {
  const CRMContextObject = {
    ...values,
  };

  return (
    <CRMContext.Provider value={CRMContextObject}>
      {children}
    </CRMContext.Provider>
  );
};

export default CRMContextProvider;
