import React from 'react';

import ButtonWithOptions from 'components/common/ButtonWithOptions';
import useStyles from 'hooks/useStyles';

import style from 'styles/settings/custom-segments/custom-segments.css';

const styles = style.locals || {};

function ConditionTypeDropdown({
  onAddRule,
  onAddRuleGroup,
  shouldEnableNewRule,
}) {
  useStyles([style]);

  if (shouldEnableNewRule) {
    return (
      <ButtonWithOptions
        options={[
          {
            label: 'AND',
            action: onAddRule,
            dataTestId: 'add-and-action',
          },
          {
            label: 'OR',
            action: onAddRuleGroup,
            dataTestId: 'add-or-action',
          },
        ]}
        dataTestId="condition-type-dropdown"
        buttonTitle="ADD"
        menuClassName={styles.conditionTypeDropdown}
        popupClassName={styles.conditionTypePopup}
      />
    );
  }

  return (
    <div
      className={styles.selectedConditionType}
      data-testid="selected-condition-type"
    >
      AND
    </div>
  );
}

export default ConditionTypeDropdown;
