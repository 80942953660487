exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".actions__wrapper-23g{display:flex;flex-direction:column;padding-top:40px}.actions__header-3eP{display:flex;align-items:center;justify-content:space-between}.actions__title-2tz{color:#192032;font-size:20px;font-weight:600;line-height:20px}.actions__spaceBetween-3d4{padding-bottom:40px}.actions__headerActions-17q{display:flex;gap:8px}.actions__emptyStateImage-2MI{background:url(/assets/userActions-icons/emptyStateActions.svg) 0 no-repeat;background-size:cover;width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"wrapper": "actions__wrapper-23g",
	"wrapper": "actions__wrapper-23g",
	"header": "actions__header-3eP",
	"header": "actions__header-3eP",
	"title": "actions__title-2tz",
	"title": "actions__title-2tz",
	"spaceBetween": "actions__spaceBetween-3d4",
	"spaceBetween": "actions__spaceBetween-3d4",
	"headerActions": "actions__headerActions-17q",
	"headerActions": "actions__headerActions-17q",
	"emptyStateImage": "actions__emptyStateImage-2MI",
	"emptyStateImage": "actions__emptyStateImage-2MI"
};