exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".notifications__frame-3cO{background-color:#fafafa;border:3px solid #e6e6e6;width:400px;padding-bottom:20px}.notifications__frame-3cO:after{content:\"\";display:block;position:absolute;top:-14px;left:366px;z-index:1;width:0;height:0;border-style:solid;border-width:0 10px 14px;border-color:transparent transparent #e6e6e6}.notifications__title-gL0{background-color:#e6e6e6;font-size:16px;font-weight:600;color:#354052;display:flex;justify-content:center;padding-bottom:4px;padding-top:1px}.notifications__inner-4UO{padding:20px;display:-webkit-box;border-bottom:2px solid #f2f2f2;padding-bottom:15px}.notifications__inner-4UO[data-unread]{background-color:#eff3f6}.notifications__picture-2Ob{width:45px;height:45px;background:none 50% no-repeat;background-size:contain;border-radius:50%}.notifications__initials-3RI{display:inline-flex;text-transform:uppercase;font-size:14px;border:1px solid #a7a7a7;color:#fff;justify-content:center;box-sizing:border-box;line-height:43px}.notifications__text-wrap-1fB{margin:0 45px 0 15px}.notifications__time-sVp{font-size:12px;color:rgba(53,64,82,.5);display:block;margin-top:2px}", ""]);

// exports
exports.locals = {
	"frame": "notifications__frame-3cO",
	"frame": "notifications__frame-3cO",
	"title": "notifications__title-gL0",
	"title": "notifications__title-gL0",
	"inner": "notifications__inner-4UO",
	"inner": "notifications__inner-4UO",
	"picture": "notifications__picture-2Ob",
	"picture": "notifications__picture-2Ob",
	"initials": "notifications__initials-3RI notifications__picture-2Ob",
	"initials": "notifications__initials-3RI notifications__picture-2Ob",
	"text-wrap": "notifications__text-wrap-1fB",
	"textWrap": "notifications__text-wrap-1fB",
	"time": "notifications__time-sVp",
	"time": "notifications__time-sVp"
};