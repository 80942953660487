exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".Spinner-module__spinner___H6dg8{width:30px;height:30px;border-radius:50%;background:radial-gradient(farthest-side,var(--blue) 100%,transparent) top/4px 4px no-repeat,conic-gradient(transparent 30%,var(--blue));mask:radial-gradient(farthest-side,transparent calc(100% - 4px),var(--black) 0);animation:Spinner-module__spinner___H6dg8 1s infinite linear}.Spinner-module__white___3V9Jr{background:radial-gradient(farthest-side,var(--white) 100%,transparent) top/4px 4px no-repeat,conic-gradient(transparent 30%,var(--white))}.Spinner-module__small___37KFA{width:25px;height:25px}@keyframes Spinner-module__spinner___H6dg8{to{transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"spinner": "Spinner-module__spinner___H6dg8",
	"white": "Spinner-module__white___3V9Jr",
	"small": "Spinner-module__small___37KFA"
};