import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/data-structure.css';
import Select from 'components/controls/Select';
import Tooltip from 'components/controls/Tooltip';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';
import DeletePopup from 'components/pages/budget/DeletePopup';

export default class DataStructure extends Component {
    style = style;

    constructor() {
      super();
      this.state = {
        timeFrame: '',
        selectLabel: '',
        isRemoved: false,
        isCreate: false,
      };
    }

  selectValue = ({ value, type }) => {
    this.setState({
      timeFrame: type,
      selectLabel: value,
    });
  }

  onRemoveClick = () => {
    this.setState({
      isRemoved: true,
    });
  }

  onSaveClick = () => {
    this.setState({
      isCreate: true,
    });
  }

  onCloseClick = () => {
    this.setState({
      isRemoved: false,
      isCreate: false,
    });
  }

  render() {
    const customStyles = {
      background: 'linear-gradient(360deg, #FAFAFC 0%, #FFFFFF 100%)',
      border: '1px solid rgba(178, 187, 213, 0.5)',
      height: 40,
    };
    const options = [
      { value: 'paid search', label: 'Paid Search' },
      { value: 'business directories', label: 'Business Directories' },
      { value: 'linked in', label: 'Linked In - Paid' },
    ];
    return (
      <div className={this.classes.content}>
        <h2 className={this.classes.title}>
          Attribution Mapping
        </h2>
        <p className={this.classes.text}>
          Keep your data consistent by setting the channel&apos;s name and category. You can always make changes to your data structure, later.
        </p>
        <div className={this.classes.mainContainer}>
          <div className={this.classes.main}>
            <div className={this.classes.titleWrap}>
              <h3 className={this.classes.labelTitle}>
                Category
              </h3>
              <Tooltip
                tip="Define the channel's category to keep your data clean and consistent"
                id="category"
                place="top"
                TooltipProps={{ sm: true }}
              >
                <div className={this.classes.info} />
              </Tooltip>

            </div>
            <Select
              select={{ options }}
              selected={options[0]}
              customStyle={customStyles}
              onChange={this.selectValue}
              innerClassName={this.classes.select}
            />
            <p className={this.classes.reset}>
              Reset to default
            </p>

          </div>
          <div className={this.classes.main}>
            <div className={this.classes.titleWrap}>
              <h3 className={this.classes.labelTitle}>
                Category
              </h3>
              <Tooltip
                tip="Define the channel's name to keep your data clean and consistent"
                id="category"
                place="top"
                TooltipProps={{ sm: true }}
              >
                <div className={this.classes.info} />
              </Tooltip>

            </div>
            <Select
              select={{ options }}
              selected={options[0]}
              customStyle={customStyles}
              onChange={this.selectValue}
              innerClassName={this.classes.select}
            />
            <p className={this.classes.reset}>
              Reset to default
            </p>

          </div>
        </div>
        <div className={this.classes.btnsContainer}>
          <div className={this.classes.remove} onClick={() => this.onRemoveClick()}>
            Remove Channel
          </div>
          <div className={this.classes.btnsGroup}>
            <SetupBudgetButton
              name="Cancel"
              stylesBtn={this.classes.button}
            />
            <SetupBudgetButton
              name="Save"
              stylesBtn={this.classes.buttonPrimary}
              onClick={() => this.onSaveClick()}
            />
          </div>
        </div>
        {this.state.isRemoved
          && (
          <DeletePopup
            title="Are you sure you want to remove the channel"
            deleteName="[channel name]"
            onCloseClick={() => this.onCloseClick()}
          />
          )}
        {this.state.isCreate
        && (
        <div className={this.classes.overlay}>
          <div className={this.classes.popup}>
            <div className={this.classes.closeIcon} onClick={() => this.onCloseClick()} />
            <div className={this.classes.popupWrap}>
              <h3 className={this.classes.popupTitle}>
                <span>Congrats!</span>
                <br />
                You've just added 12 channels and
                <br />
                created your budget plan!
              </h3>
              <div className={this.classes.popupBtnsContainer}>
                <SetupBudgetButton
                  name="See my budget plan"
                  stylesWrap={this.classes.popupButtonWrap}
                  stylesBtn={this.classes.popupButton}
                  onClick={() => this.onCloseClick()}
                />
                <SetupBudgetButton
                  name="Add Campaigns"
                  stylesWrap={this.classes.popupButtonWrap}
                  stylesBtn={this.classes.popupButton}
                  onClick={() => this.onCloseClick()}
                />
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}
