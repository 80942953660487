/* eslint-disable import/no-extraneous-dependencies */
// SAFARI BUG FIX - no default fetch, need to use external library
import 'whatwg-fetch';
// SAFARI BUG FIX - no default promise, need to use external library
import Promise from 'promise-polyfill';
/* eslint-enable import/no-extraneous-dependencies */

import config from 'components/utils/Configuration';
import servicesStore from 'stores/servicesStore';

import { v4 } from 'uuid';
import moment from 'moment';

if (!window.Promise) {
  window.Promise = Promise;
}

const API_URL = config.overrideURL ? config.overrideURL : window.location.hostname;
const protocol = config.overrideProtocol ? config.overrideProtocol : window.location.protocol;

function ServerException(message, status, error) {
  this.message = message;
  this.status = status;
  this.error = error;
}

export default {
  async serverRequest(method, route, body, region, withoutUID, customModifier) {
    return new Promise((resolve, reject) => {
      const requestId = v4();
      const requestSentDate = moment();
      servicesStore.authService.getProfile()
        .then((profile) => {
          let URL = `${protocol}//${API_URL}${protocol === 'https:' ? '/api/' : `:${config.port}/`}${route}`;
          if (profile && !withoutUID) {
            URL += `/${profile.app_metadata.UID}/`;
          }
          if (region) {
            URL += `?region=${region}`;
          }
          if (customModifier) {
            URL = customModifier(URL);
          }
          const options = {
            method,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${servicesStore.authService.getApiGatewayAccessToken()}`,
            },
            credentials: 'omit',
          };
          if (body) {
            options.body = body;
          }
          servicesStore.logger.info('sending request to server', {
            requestId,
            UID: profile.app_metadata.UID,
            method,
            route,
            requestSentDate,
          });
          fetch(encodeURI(URL), options)
            .then((response) => {
              const responseReceivedDate = moment();
              if (response.status === 200 || response.ok) {
                const timeElapsedFe = responseReceivedDate.diff(requestSentDate, 'seconds', true).toFixed(2);
                const elapsedSecondsToConsiderAsDown = 60;
                let systemStatus = 'up';
                if (timeElapsedFe >= elapsedSecondsToConsiderAsDown) {
                  systemStatus = 'down';
                }
                servicesStore.logger.info('received response from server', {
                  status: response.status,
                  requestId,
                  systemStatus,
                  method,
                  route,
                  UID: profile.app_metadata.UID,
                  timeElapsedFe,
                });
                resolve(response);
              } else {
                response.json()
                  .then((res) => {
                    const error = res.error || 'Undefined error';
                    const status = response.status || 500;

                    servicesStore.logger.error('failed to receive response from server', {
                      status,
                      error,
                      systemStatus: 'down',
                      UID: profile.app_metadata.UID,
                      method,
                      route,
                      requestId,
                    });

                    reject(new ServerException(response.statusText, status, error));
                  });
              }
            })
            .catch((error) => {
              servicesStore.logger.error('received exception from server', {
                UID: profile.app_metadata.UID,
                error: {
                  name: error?.name,
                  message: error?.message,
                  status: error?.status,
                },
                traceback: error?.stack,
                requestId,
                method,
                route,
                systemStatus: 'down',
              });

              reject(error);
            });
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get profile', {
            requestId,
            method,
            route,
            systemStatus: 'down',
          });

          reject(error);
        });
    });
  },

  async serverRequestWithoutProfile(method, route, body) {
    return new Promise((resolve, reject) => {
      const requestId = v4();
      const requestSentDate = moment();
      const URL = `${protocol}//${API_URL}${protocol === 'https:' ? '/api/' : `:${config.port}/`}${route}`;

      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        credentials: 'omit',
      };
      if (body) {
        options.body = body;
      }

      servicesStore.logger.info('sending request to server', {
        requestId,
        method,
        route,
        requestSentDate,
      });

      fetch(encodeURI(URL), options)
        .then((response) => {
          const responseReceivedDate = moment();
          if (response.status === 200 || response.ok) {
            const timeElapsedFe = responseReceivedDate.diff(requestSentDate, 'seconds', true).toFixed(2);
            const elapsedSecondsToConsiderAsDown = 60;
            let systemStatus = 'up';
            if (timeElapsedFe >= elapsedSecondsToConsiderAsDown) {
              systemStatus = 'down';
            }

            servicesStore.logger.info('received response from server', {
              status: response.status,
              requestId,
              systemStatus,
              method,
              route,
              timeElapsedFe,
            });
            resolve(response);
          } else {
            response.json()
              .then((res) => {
                const error = res.error || 'Undefined error';
                const status = response.status || 500;

                servicesStore.logger.error('failed to receive response from server', {
                  status,
                  error,
                  systemStatus: 'down',
                  method,
                  route,
                  requestId,
                });

                reject(new ServerException(response.statusText, status, error));
              });
          }
        })
        .catch((error) => {
          servicesStore.logger.error('received exception from server', {
            error: {
              name: error?.name,
              message: error?.message,
              status: error?.status,
            },
            traceback: error?.stack,
            requestId,
            method,
            route,
            systemStatus: 'down',
          });

          reject(error);
        });
    });
  },
};
