exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".plan-button__tooltip-class-rpz{pointer-events:auto!important}.plan-button__tooltip-class-rpz:hover{visibility:visible!important;opacity:1!important}", ""]);

// exports
exports.locals = {
	"tooltip-class": "plan-button__tooltip-class-rpz",
	"tooltipClass": "plan-button__tooltip-class-rpz"
};