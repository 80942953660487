import { isEqual } from 'lodash';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

export function getTimeframeObjectToSave({ timeframe }) {
  if (timeframe.value === TIMEFRAME_VALUES.CUSTOM) {
    return {
      value: timeframe.value,
      startDate: timeframe.startDate,
      endDate: timeframe.endDate,
    };
  }

  if (timeframe.value === TIMEFRAME_VALUES.ROLLING) {
    return {
      value: timeframe.value,
      type: timeframe.type,
      isRelative: timeframe.isRelative,
      rollingValue: timeframe.rollingValue,
      excludeThisMonthData: timeframe.excludeThisMonthData,
      excludeToday: timeframe.excludeToday,
    };
  }

  return {
    value: timeframe.value,
  };
}

export function isFilterChangesRequireServerRequest({ existingFilters = [], updatedFilters = [] }) {
  const filtersToRemove = [];
  const newFilters = [];

  for (const filter of updatedFilters) {
    const isFilterExists = existingFilters.some((newFilter) => isEqual(filter, newFilter));
    if (isFilterExists) {
      continue;
    }
    newFilters.push(filter);
  }

  for (const filter of existingFilters) {
    const isFilterExists = updatedFilters.some((newFilter) => isEqual(filter, newFilter));
    if (isFilterExists) {
      continue;
    }
    filtersToRemove.push(filter);
  }

  const newFiltersAreUIOnly = newFilters.length ? newFilters.every((filter) => filter.isUiOnly) : true;
  const filtersToRemoveAreUIOnlyFilter = filtersToRemove.length ? filtersToRemove.every((filter) => filter.isUiOnly) : true;
  return !(newFiltersAreUIOnly && filtersToRemoveAreUIOnlyFilter);
}
