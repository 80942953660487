exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".stage-selector__outer-div-3bR{display:flex;width:100%}.stage-selector__inner-div-1j8{position:relative;flex:1 1 100%;display:flex;flex-direction:column;justify-content:center;height:79px;padding:0 16px;background-color:#fff;cursor:pointer;border-bottom:1px solid #e6e8f0}.stage-selector__inner-div-1j8:first-child{border-top-left-radius:12px}.stage-selector__inner-div-1j8:last-child{border-top-right-radius:12px}.stage-selector__inner-div-1j8+.stage-selector__inner-div-1j8{border-left:1px solid #e6e8f0;padding-left:30px}.stage-selector__inner-div-1j8+.stage-selector__inner-div-1j8:before{content:\"\";position:absolute;top:50%;left:0;transform:translate(-50%,-50%);display:block;width:32px;height:32px;border:1px solid #e6e8f0;border-radius:50%;background-color:#fff;background-image:url(/assets/analyze-icons/channels-arrow-right.svg);background-position:50%;background-repeat:no-repeat}.stage-selector__active-3ub:after{content:\"\";position:absolute;bottom:0;left:0;display:block;width:100%;height:2px;background-color:#4d91fc}.stage-selector__active-3ub .stage-selector__number-Zib{color:#182033}.stage-selector__stage-name-3VX{font-size:11px;font-weight:600;color:#99a4c2;text-transform:uppercase}.stage-selector__row-1Ir{display:flex;align-items:center;font-size:12px;font-weight:500;margin:4px 0 0;gap:8px}.stage-selector__number-Zib{font-size:16px;font-weight:500;color:#707ea7}", ""]);

// exports
exports.locals = {
	"outer-div": "stage-selector__outer-div-3bR",
	"outerDiv": "stage-selector__outer-div-3bR",
	"inner-div": "stage-selector__inner-div-1j8",
	"innerDiv": "stage-selector__inner-div-1j8",
	"active": "stage-selector__active-3ub",
	"active": "stage-selector__active-3ub",
	"number": "stage-selector__number-Zib",
	"number": "stage-selector__number-Zib",
	"stage-name": "stage-selector__stage-name-3VX",
	"stageName": "stage-selector__stage-name-3VX",
	"row": "stage-selector__row-1Ir",
	"row": "stage-selector__row-1Ir"
};