import React from 'react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/common/trendArrow.css';

const styles = style.locals || {};

function TrendArrow({
  width = 12,
  height = 9,
  className,
  isNegative,
  isGrowthDesired,
}) {
  useStyles([style]);

  return (
    <div
      style={{
        borderTopWidth: '0',
        borderRightWidth: `${width / 2}px`,
        borderBottomWidth: `${height}px`,
        borderLeftWidth: `${width / 2}px`,
      }}
      className={classNames(
        styles.trendArrow,
        isNegative && styles.isNegative,
        !isGrowthDesired && styles.isUndesired,
        className
      )}
    />
  );
}

export default TrendArrow;
