import useStyles from 'hooks/useStyles';

import { getLegendItems } from 'components/widgets/optimalJourney/journeyCanvas/logic/journeyLegend';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function JourneyLegend({ selectedBuildingBlocksNames }) {
  useStyles([style]);

  return (
    <div className={styles.legendContainer}>
      {getLegendItems({ selectedBuildingBlocksNames }).map((legendItemValue) => {
        if (!legendItemValue) {
          return null;
        }

        return (
          <div key={legendItemValue.label} className={styles.legendItem}>
            {legendItemValue.color
              ? <div className={styles.legendItemThumbnail} style={{ backgroundColor: legendItemValue.color }} />
              : legendItemValue.icon}
            <div className={styles.legendItemLabel}>
              {legendItemValue.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}
