exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".multi-row__add-button-19r{height:28px;width:28px;cursor:pointer;background:url(/assets/plus.svg) 50% no-repeat;background-size:24px auto}.multi-row__remove-button-155{height:28px;width:28px;cursor:pointer;background:url(/assets/trash.svg) 50% no-repeat;background-size:24px auto;flex-grow:0;flex-shrink:0}", ""]);

// exports
exports.locals = {
	"box": "multi-row__box-39j",
	"box": "multi-row__box-39j",
	"row": "multi-row__row-3zG",
	"row": "multi-row__row-3zG",
	"add-button": "multi-row__add-button-19r",
	"addButton": "multi-row__add-button-19r",
	"remove-button": "multi-row__remove-button-155",
	"removeButton": "multi-row__remove-button-155"
};