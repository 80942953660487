import React, { useEffect } from 'react';

import Dropdown from 'components/controls/Dropdown';

import style from 'styles/settings/workflows/configPopup.css';

export default function ObjectsDeduplication({ configuration, isViewOnly }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <>
      {Object.entries(configuration.mapping).map(([entityType, entityConfig]) => (
        <div className={style.locals.row}>
          Merge
          <Dropdown
            disabled={isViewOnly}
            selectedKey={entityType}
            options={[{ label: entityType, value: entityType }]}
            controlWidth={140}
          />
          by
          <Dropdown
            disabled={isViewOnly}
            selectedKey={entityConfig.groupByField}
            options={[{ label: entityConfig.groupByField, value: entityConfig.groupByField }]}
            controlWidth={140}
          />
        </div>
      ))}
    </>
  );
}
