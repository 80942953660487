import flow from 'lodash/fp/flow';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import get from 'lodash/get';
import { getChannelNickname } from 'components/utils/filters/channels';

const getOtherCampaign = channel => `${getChannelNickname(channel)} - other`;

const filterOtherCampaign = ({name, channels}) => {
  const channel = get(channels, [0], 'direct');
  return name !== getOtherCampaign(channel);
};

const divider = '::::';

const getFormatPeriod = (conversionIndicator) => (item) =>
  flow(
    mapKeys((key) => key.replace(/(-|_)/g, ' ')), // transform all -,_ letter to a blank space
    mapValues(campaign => campaign[conversionIndicator]),
    pickBy(value => value)
  )(item);

const getContentFormatPeriod = (conversionIndicator) => (item) => {
  const data = getFormatPeriod(conversionIndicator)(item);
  const res = {};

  Object.keys(data).forEach((key) => {
    const [_, campaign] = key.split(divider);

    res[campaign] = data[key];
  });

  return res;
};

export {
  getFormatPeriod,
  getContentFormatPeriod,
  getOtherCampaign,
  filterOtherCampaign,
};
