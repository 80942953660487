import ReactSkeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React from 'react';

import useStyles from 'hooks/useStyles';
import skeletonStyle from 'react-loading-skeleton/dist/skeleton.css';

function Skeleton({
  height, width, circle, count, padding, className, isLightTheme, borderRadius, enableAnimation,
}) {
  useStyles([skeletonStyle]);

  return (
    <SkeletonTheme
      baseColor={isLightTheme ? '#F3F5FA' : '#DCE1EF'}
      highlightColor={isLightTheme ? '#FAFBFF' : '#E3E8F7'}
    >
      <div style={{ padding }} data-testid="skeleton">
        <ReactSkeleton
          circle={circle}
          height={height}
          width={width}
          count={count}
          className={className}
          borderRadius={borderRadius}
          enableAnimation={enableAnimation}
        />
      </div>
    </SkeletonTheme>
  );
}

export default Skeleton;
