exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".dropdown__selectOptionButtons-SKj{margin-left:auto;display:flex;gap:6px;align-items:center}.dropdown__dropdownOptions-2lz{display:flex;align-items:center;background-color:#182033}.dropdown__menuButton-jW-{margin:8px;width:calc(100% - 16px)}.dropdown__arrowDown-3Bf{border-top:5px solid #99a4c2;border-left:5px solid transparent;border-right:5px solid transparent}.dropdown__optionButton-1on{border:1px solid rgba(178,187,213,.5);box-shadow:0 1px 2px #e6e8f0;border-radius:4px;width:24px;height:24px;display:none}.dropdown__optionButton-1on:hover{border:1px solid #b2bbd5}.dropdown__menuList-Qua{margin:6px}.dropdown__controlWrap-2Wd>div{flex-wrap:nowrap;padding:0 0 0 8px}.dropdown__selectOptionWrap-2tz:hover .dropdown__optionButton-1on{display:block}.dropdown__selectOptionDisabled-TPA,.dropdown__selectOptionDisabled-TPA:hover{opacity:.5;background:none;cursor:default}.dropdown__optionEdit-1SM{background:#fff url(/assets/plan_icons/edit-grey.svg) 50% no-repeat;background-size:14px}.dropdown__optionDelete-1BK{background:#fff url(/assets/platform-icons/trash-icon.svg) 50% no-repeat;background-size:12px}.dropdown__dropdownLabel-2Ti{margin-left:6px;font-weight:400}.dropdown__channelsSelectSize-2vp{width:300px;margin:0 auto}.dropdown__dropdownAsLinkControlWrap-3zB{border:none;background:none;padding:0;height:auto;min-height:auto;width:auto;transition:none}.dropdown__dropdownAsLinkControlWrap-3zB:hover{text-decoration:underline}.dropdown__dropdownAsLinkControlWrap-3zB>div,.dropdown__dropdownAsLinkControlWrap-3zB>span{padding:0;margin:0}.dropdown__dropdownAsLinkIndicator-1T7{display:none}", ""]);

// exports
exports.locals = {
	"selectOptionButtons": "dropdown__selectOptionButtons-SKj",
	"selectOptionButtons": "dropdown__selectOptionButtons-SKj",
	"dropdownOptions": "dropdown__dropdownOptions-2lz",
	"dropdownOptions": "dropdown__dropdownOptions-2lz",
	"menuButton": "dropdown__menuButton-jW-",
	"menuButton": "dropdown__menuButton-jW-",
	"arrowDown": "dropdown__arrowDown-3Bf",
	"arrowDown": "dropdown__arrowDown-3Bf",
	"optionButton": "dropdown__optionButton-1on",
	"optionButton": "dropdown__optionButton-1on",
	"menuList": "dropdown__menuList-Qua",
	"menuList": "dropdown__menuList-Qua",
	"controlWrap": "dropdown__controlWrap-2Wd",
	"controlWrap": "dropdown__controlWrap-2Wd",
	"selectOptionWrap": "dropdown__selectOptionWrap-2tz",
	"selectOptionWrap": "dropdown__selectOptionWrap-2tz",
	"selectOptionDisabled": "dropdown__selectOptionDisabled-TPA",
	"selectOptionDisabled": "dropdown__selectOptionDisabled-TPA",
	"optionEdit": "dropdown__optionEdit-1SM",
	"optionEdit": "dropdown__optionEdit-1SM",
	"optionDelete": "dropdown__optionDelete-1BK",
	"optionDelete": "dropdown__optionDelete-1BK",
	"dropdownLabel": "dropdown__dropdownLabel-2Ti",
	"dropdownLabel": "dropdown__dropdownLabel-2Ti",
	"channelsSelectSize": "dropdown__channelsSelectSize-2vp",
	"channelsSelectSize": "dropdown__channelsSelectSize-2vp",
	"dropdownAsLinkControlWrap": "dropdown__dropdownAsLinkControlWrap-3zB",
	"dropdownAsLinkControlWrap": "dropdown__dropdownAsLinkControlWrap-3zB",
	"dropdownAsLinkIndicator": "dropdown__dropdownAsLinkIndicator-1T7",
	"dropdownAsLinkIndicator": "dropdown__dropdownAsLinkIndicator-1T7"
};