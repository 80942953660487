import React from 'react';

import StatsSquaresJourneys from 'components/pages/journeys/StatsSquaresJourneys';
import attributionStore from 'stores/attributionStore';
import { getStatsLabel, getIsDealView } from 'stores/analyze/logic/journeysStore';

export default function StatsSquaresJourneysWrapper({
  isLoaded,
  journeysOverview = {},
  prevJourneysOverview = {},
  widgetHeaderConfig,
  widgetHeaderProps,
}) {
  const { groupBy } = attributionStore;
  const statsLabel = getStatsLabel(groupBy);
  const isDealView = getIsDealView(groupBy);

  return (
    <StatsSquaresJourneys
      isLoaded={isLoaded}
      statsLabel={statsLabel}
      stats={journeysOverview}
      prevStats={prevJourneysOverview}
      isDealView={isDealView}
      widgetHeaderConfig={widgetHeaderConfig}
      widgetHeaderProps={widgetHeaderProps}
    />
  );
}
