import React from 'react';
import Component from 'components/Component';
import { inject, observer } from 'mobx-react';
import { compose } from 'components/utils/utils';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import CommonFilter, { CommonPropTypes } from 'components/pages/users/Filters/CommonFilter';
import {
  includeVariants, COMPARISON_TOGGLE, CONTAINS,
} from 'components/utils/filters';
import { EQUALS } from 'components/utils/filters/logic';
import filterStyles from 'styles/users/filters.css';
import Dropdown from 'components/controls/Dropdown';
import FilterKindWithTooltip from 'components/pages/users/Filters/FilterKindWithTooltip';
import ContainsSelect from './FilterPanel/UI/ContainsSelect';

const styles = filterStyles.locals;

const enhance = compose(
  inject(({
    filterStore: {
      getFilterKindOptions,
    },
  }) => ({
    getFilterKindOptions,
  })),
  observer
);

class FormsFilter extends Component {
  static propTypes = CommonPropTypes;

  constructor(props) {
    super(props);
    const comparisonOperatorLabel = props.activeFilter ? props.activeFilter.data.comparisonOperator : EQUALS;
    this.state = {
      comparisonOperator: comparisonOperatorLabel,
      isContainsSelected: comparisonOperatorLabel === CONTAINS,
    };
  }

  changeComparisonHandler = (value) => {
    this.setState({
      comparisonOperator: value,
      isContainsSelected: value === CONTAINS,
    });
  }

  render() {
    const { config, getFilterKindOptions } = this.props;

    return (
      <CommonFilter comparisonOperator={this.state.comparisonOperator} changeComparisonHandler={this.changeComparisonHandler} {...this.props}>
        {({
          variant, onSetVariant, selectedOptions, onSelectOptions, onAddItem, onRemoveItem, onChangeComparison, containsValueHandler,
        }) => (
          <>
            <header className={styles.filterHeader}>Find journeys which</header>
            <div className={styles.filterVariant}>
              <FilterKindWithTooltip
                onChangeHandler={onSetVariant}
                variantsOptions={includeVariants}
                defaultIndex={variant}
                showBottomInfo
                followingKind="the following forms:"
              />
            </div>

            <div className={styles.filterVariant}>
              <Dropdown
                controlWidth={210}
                onChange={onChangeComparison}
                options={Object.entries(COMPARISON_TOGGLE).map(([value, label]) => ({ value, label }))}
                selectedKey={this.state.comparisonOperator}
                placeholder="Select condition"
              />
            </div>
            {this.state.isContainsSelected ? (
              <div className={styles.rowCenter} style={{ marginBottom: 15, position: 'relative' }}>
                <ContainsSelect containsValue={selectedOptions.map((item) => ({ label: item, value: item }))} containsValueHandler={containsValueHandler} />
              </div>
            ) : (
              <MultiCheckSelect
                className={styles.optionSelect}
                selected={selectedOptions.map((option) => ({ label: option, value: option }))}
                options={config.options.map((option) => ({ label: option, value: option }))}
                placeholder="Search form..."
                onChange={onSelectOptions}
                isAsyncPagination={config.isSearchable}
                searchFunction={(searchValue, offset) => getFilterKindOptions({
                  searchValue, filterKind: config.kind, filterKey: config.fieldKey, offset,
                })}
                selectAll
              />
            )}
          </>
        )}
      </CommonFilter>
    );
  }
}

export default enhance(FormsFilter);
