import React, { useRef, useState, useEffect } from 'react';
import { startCase, debounce } from 'lodash';
import classnames from 'classnames';
import { Button, Checkbox } from '@infinigrow/libs';

import SearchInput from 'components/controls/SearchInput';

import { getFilteredOptionsForRequest } from 'components/pages/users/logic/usersPopup';

import usersPopupStyle from 'styles/users/users-popup.css';

import useOnClickOutside from './useOnClickOutside';

const styles = usersPopupStyle.locals;

function UsersPopupFilter({
  journeyFilterOptions = [], fetchFilteredJourneySessions, userSelectedFilterOptions = [], setUserSelectedFilterOptions,
}) {
  const journeyFilterOptionsCategories = journeyFilterOptions.map((item) => item.category);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(journeyFilterOptionsCategories[0]);

  const refPopupFilter = useRef();
  const refSearchInput = useRef();

  useOnClickOutside(refPopupFilter, () => {
    setIsShowPopup(false);
    setSelectedFilterCategory(journeyFilterOptionsCategories[0]);
  });

  const getSelectedFilterOptions = (category = selectedFilterCategory) => userSelectedFilterOptions.find((options) => options.category === category)?.values || [];
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);

  useEffect(() => {
    setUserSelectedFilterOptions(journeyFilterOptions);
    setSelectedFilterOptions(getSelectedFilterOptions());
  }, []);

  useEffect(() => {
    setSelectedFilterOptions(getSelectedFilterOptions());
  }, [isShowPopup]);

  const onClickFilterCategory = (category) => {
    const currentSelectedFilterOptions = getSelectedFilterOptions(category);
    setSelectedFilterCategory(category);
    setSelectedFilterOptions(currentSelectedFilterOptions);
    const isAllSelected = currentSelectedFilterOptions.every((filter) => filter.isSelected);
    setIsSelectedAll(isAllSelected);
    refSearchInput.current.handleSearchQueryClear();
  };

  const onClickFilterButton = () => {
    if (!isShowPopup) {
      const firstCategory = journeyFilterOptionsCategories[0];
      setSelectedFilterCategory(firstCategory);
    }
    setIsShowPopup((prevState) => !prevState);
  };

  const debouncedRequest = useRef(
    debounce(async (updatedFilterOptions) => {
      const filteredOptionsForRequest = getFilteredOptionsForRequest(updatedFilterOptions);
      fetchFilteredJourneySessions(filteredOptionsForRequest);
    }, 1500)
  ).current;

  useEffect(() => () => {
    debouncedRequest.cancel();
  }, [debouncedRequest]);

  const checkIsAllFiltersIsUnselected = () => {
    for (const filterValues of userSelectedFilterOptions) {
      const isUnselectedAllFilters = filterValues.values.every((filter) => !filter.isSelected);
      if (!isUnselectedAllFilters) {
        return false;
      }
    }
    return true;
  };

  const selectOptionsHandler = async (isSelected, index) => {
    selectedFilterOptions[index].isSelected = !isSelected;
    const updatedFilterOptions = [...userSelectedFilterOptions];
    const isAllSelected = selectedFilterOptions.every((filter) => filter.isSelected);
    setIsSelectedAll(isAllSelected);
    setUserSelectedFilterOptions(updatedFilterOptions);
    debouncedRequest(updatedFilterOptions);
  };

  const onResetAllFilters = () => {
    setIsSelectedAll(false);
    setUserSelectedFilterOptions(journeyFilterOptions);
    refSearchInput.current?.handleSearchQueryClear();
    const currentSelectedFilterOptions = journeyFilterOptions.find((options) => options.category === selectedFilterCategory);
    setSelectedFilterOptions(currentSelectedFilterOptions.values);
    debouncedRequest(journeyFilterOptions);
  };

  const selectAllHandler = () => {
    const updatedIsSelectedAll = !isSelectedAll;
    const updatedFilterOptions = [...userSelectedFilterOptions];
    for (const filterValue of selectedFilterOptions) {
      filterValue.isSelected = updatedIsSelectedAll;
    }
    setIsSelectedAll(updatedIsSelectedAll);
    setUserSelectedFilterOptions(updatedFilterOptions);
    debouncedRequest(updatedFilterOptions);
  };

  const handleSearchFilterQueryChange = (searchQuery) => {
    const currentSelectedFilterOptions = userSelectedFilterOptions.find((options) => options.category === selectedFilterCategory);
    const filteredItems = currentSelectedFilterOptions.values.filter((filter) => filter.value.toLowerCase().includes(searchQuery.toLowerCase()));
    setSelectedFilterOptions(filteredItems);
  };

  const allFiltersOptionsIsUnSelected = checkIsAllFiltersIsUnselected();

  return (
    <div ref={refPopupFilter} className={styles.journeyFilterWrapper}>
      {!allFiltersOptionsIsUnSelected
        && (
          <div className={styles.journeyFilterReset} onClick={onResetAllFilters}>
            Reset
          </div>
        )}
      <div className={styles.journeyPopupRelative}>
        <Button
          type="secondaryBlue"
          className={styles.journeyFilterButton}
          onClick={() => onClickFilterButton()}
        >
          Filter
        </Button>
        {isShowPopup && (
          <div className={styles.journeyFilterPopup}>
            <div className={styles.journeyFilterPopupLeft}>
              {journeyFilterOptionsCategories.map((category) => (
                <div
                  key={category}
                  onClick={() => onClickFilterCategory(category)}
                  className={classnames(styles.journeyFilterPopupFilterCategory, selectedFilterCategory === category && styles.activeCategory)}
                >
                  {startCase(category)}
                </div>
              ))}
            </div>
            <div className={styles.journeyFilterPopupRight}>
              <SearchInput
                hideSearchIcon
                onSearch={handleSearchFilterQueryChange}
                placeholder="Search..."
                debounce={0}
                ref={refSearchInput}
                classes={{
                  label: styles.journeyFilterSearchLabel,
                  input: styles.journeyFilterSearchInput,
                  closeIcon: styles.journeyFilterSearchCloseIcon,
                }}
              />

              {selectedFilterOptions.length === 0
                ? (
                  <div className={styles.noFilterOptions}>
                    {`No ${selectedFilterCategory} found`}
                  </div>
                )
                : (
                  <>
                    {selectedFilterOptions.length > 1
                      && (
                        <div className={classnames(styles.customCheckboxLabel, styles.selectAllCheckbox)}>
                          <Checkbox
                            checked={isSelectedAll}
                            onChange={selectAllHandler}
                          >
                            Select all
                          </Checkbox>
                        </div>
                      )}
                    {selectedFilterOptions.map((option, index) => {
                      if (option.value) {
                        const checkboxKey = `${option}-${index}`;
                        return (
                          <div key={checkboxKey} className={styles.customCheckboxWrap}>
                            <Checkbox
                              checked={option.isSelected}
                              onChange={() => selectOptionsHandler(option.isSelected, index)}
                            >
                              {option.value}
                            </Checkbox>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersPopupFilter;
