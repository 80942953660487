exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".configPopup__popup-35L>div>div{width:920px;margin:0 auto}.configPopup__childrenWrapper-1YF{border-radius:4px}.configPopup__popupWrap-1yE{border:1px solid #dce1ef}.configPopup__row-3rv{display:flex;padding:16px;flex-direction:row;gap:8px;align-self:stretch;color:#182033;font-size:16px;font-weight:400;align-items:baseline;flex-wrap:wrap}.configPopup__tag-3e_{display:flex;padding:4px 12px;align-items:center;gap:4px;border-radius:4px;background:#e3e9fb;color:#707ea7;text-align:center;font-size:14px;font-weight:400}.configPopup__selectedItem-3Tw{display:flex;padding:10px 12px;align-items:center;gap:10px;border-radius:4px;border:1px solid #dce1ef;background:#fbfbfd}.configPopup__viewOnlyWrapper-_Uw{display:flex;flex-direction:row;color:#182033;font-size:14px;font-weight:400;padding:4px 8px 4px 28px;align-items:center;gap:4px;border-radius:4px;background:#e3e9fb url(/assets/lock.svg) 8px 5px no-repeat}.configPopup__header-2ZH{display:flex;flex-direction:row;gap:8px}.configPopup__title-vCi{color:#182033;font-size:20px;font-weight:600;line-height:20px}.configPopup__subTitle-1Wv{align-self:stretch;color:#707ea7;font-size:14px;font-weight:400;line-height:20px;padding:8px 0 32px}.configPopup__closeButton-eT7{position:absolute;top:12px;right:12px;width:28px;height:28px;background:url(/assets/close-icon-big.svg) 50% no-repeat;cursor:pointer;z-index:10}.configPopup__infoIcon-1MU:hover{cursor:auto}", ""]);

// exports
exports.locals = {
	"popup": "configPopup__popup-35L",
	"popup": "configPopup__popup-35L",
	"childrenWrapper": "configPopup__childrenWrapper-1YF",
	"childrenWrapper": "configPopup__childrenWrapper-1YF",
	"popupWrap": "configPopup__popupWrap-1yE",
	"popupWrap": "configPopup__popupWrap-1yE",
	"row": "configPopup__row-3rv",
	"row": "configPopup__row-3rv",
	"tag": "configPopup__tag-3e_",
	"tag": "configPopup__tag-3e_",
	"selectedItem": "configPopup__selectedItem-3Tw",
	"selectedItem": "configPopup__selectedItem-3Tw",
	"viewOnlyWrapper": "configPopup__viewOnlyWrapper-_Uw",
	"viewOnlyWrapper": "configPopup__viewOnlyWrapper-_Uw",
	"header": "configPopup__header-2ZH",
	"header": "configPopup__header-2ZH",
	"title": "configPopup__title-vCi",
	"title": "configPopup__title-vCi",
	"subTitle": "configPopup__subTitle-1Wv",
	"subTitle": "configPopup__subTitle-1Wv",
	"closeButton": "configPopup__closeButton-eT7",
	"closeButton": "configPopup__closeButton-eT7",
	"infoIcon": "configPopup__infoIcon-1MU",
	"infoIcon": "configPopup__infoIcon-1MU"
};