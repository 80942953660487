exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".trendArrow__trendArrow-1fe{width:0;height:0;border-style:solid;border-color:transparent transparent #00c875}.trendArrow__trendArrow-1fe.trendArrow__isNegative-2U1{transform:rotate(180deg)}.trendArrow__trendArrow-1fe.trendArrow__isUndesired-6Op{border-color:transparent transparent #e43a54}", ""]);

// exports
exports.locals = {
	"trendArrow": "trendArrow__trendArrow-1fe",
	"trendArrow": "trendArrow__trendArrow-1fe",
	"isNegative": "trendArrow__isNegative-2U1",
	"isNegative": "trendArrow__isNegative-2U1",
	"isUndesired": "trendArrow__isUndesired-6Op",
	"isUndesired": "trendArrow__isUndesired-6Op"
};