import React, { useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';

import style from 'styles/settings/custom-segments/custom-segments.css';
import channelsTabStyle from 'styles/settings/channels/channels-tab.css';

const styles = style.locals || {};
const channelsTabStyles = channelsTabStyle.locals || {};

function CustomSegmentItem({ data, setSelectedSegmentAndGroupIds }) {
  useStyles([channelsTabStyle, style]);

  const [isSegmentOpen, setIsSegmentOpen] = useState(false);

  function onToggleSegmentInformation() {
    setIsSegmentOpen((prevState) => !prevState);
    setSelectedSegmentAndGroupIds({ segmentId: data.id, groupId: null });
  }

  const segmentGroups = data.groups.filter((group) => group.name);

  return (
    <>
      <div className={channelsTabStyles.categoryBox} onClick={() => onToggleSegmentInformation()}>
        <div className={classnames(isSegmentOpen ? channelsTabStyles.arrowDown : channelsTabStyles.arrowRight, channelsTabStyles.arrow)} />
        <div>{data.name}</div>
      </div>

      <div>
        {isSegmentOpen && (
        <div className={styles.segmentsWrapper}>
          {segmentGroups.map((group) => (
            <div
              className={channelsTabStyles.channelBox}
              key={`${data.name}-${group.id}`}
              onClick={() => setSelectedSegmentAndGroupIds({ segmentId: data.id, groupId: group.id })}
            >
              {group.name}
            </div>
          ))}
          <div className={classnames(channelsTabStyles.channelBox, styles.otherSegment)} key={`${data.name}-others`}>
            Others
            <InfoMarker
              place="right"
              tooltipText="'Others' is a default grouped segment that groups all values that don’t fall under any of your existing rules."
            />
          </div>
        </div>
        )}
      </div>
    </>
  );
}

export default CustomSegmentItem;
