import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';

import style from 'styles/integrations/popup.css';
import Popup from './Popup';

export default class MultiSelectPopup extends Component {
  style = style;

  static defaultProps = {
    selected: [],
  };

  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    selected: PropTypes.array,
    selectPlaceholder: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    saveSettings: PropTypes.func.isRequired,
  };

  state = {
    selected: this.props.selected,
  };

  open = () => {
    this.setState({ selected: this.props.selected });
    this.popup.open();
  };

  close = () => {
    this.popup.close();
  };

  selectOptions = (e) => {
    this.setState({ selected: e });
  };

  saveSettings = () => {
    this.popup.close();
    this.props.saveSettings(this.state.selected);
  };

  render() {
    const {
      title,
      className,
      options,
      selectPlaceholder,
      primaryButtonText,
      secondaryButtonText,
      maxHeight
    } = this.props;
    const { selected } = this.state;

    const popupChildren = (
      <div>
        <MultiCheckSelect
          options={options}
          selected={selected}
          placeholder={selectPlaceholder || 'Search value...'}
          onChange={this.selectOptions}
          maxHeight={maxHeight}
        />
      </div>
    );

    return (
      <Popup
        ref={(el) => this.popup = el}
        title={title}
        className={className}
        primaryButton={{
          text: primaryButtonText || 'Save Settings',
          onClick: () => this.saveSettings(),
        }}
        secondaryButton={{
          text: secondaryButtonText || 'Cancel',
          onClick: () => this.close(),
        }}
        children={popupChildren}
      />
    );
  }
}
