exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".number-with-arrow__inner-2mz{display:flex;align-items:center}.number-with-arrow__stat-zjE{color:#2fcf5f;font-size:12px;font-weight:700;margin-left:4px;line-height:normal}.number-with-arrow__inner-2mz[data-negative] .number-with-arrow__stat-zjE{color:#ee2032}.number-with-arrow__inner-background-2Ob{background-color:#eafaef;border-radius:4px;padding:6px 13px}.number-with-arrow__inner-background--negative-29h{background-color:#fbd1d5}.number-with-arrow__arrow-2e0{content:\"\";width:0;height:0;border-style:solid;border-width:0 6px 9px;border-color:transparent transparent #2fcf5f}.number-with-arrow__inner-2mz[data-negative] .number-with-arrow__arrow-2e0{transform:rotate(180deg);border-color:transparent transparent #ee2032!important}", ""]);

// exports
exports.locals = {
	"inner": "number-with-arrow__inner-2mz",
	"inner": "number-with-arrow__inner-2mz",
	"stat": "number-with-arrow__stat-zjE",
	"stat": "number-with-arrow__stat-zjE",
	"inner-background": "number-with-arrow__inner-background-2Ob",
	"innerBackground": "number-with-arrow__inner-background-2Ob",
	"inner-background--negative": "number-with-arrow__inner-background--negative-29h",
	"innerBackgroundNegative": "number-with-arrow__inner-background--negative-29h",
	"arrow": "number-with-arrow__arrow-2e0",
	"arrow": "number-with-arrow__arrow-2e0"
};