import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import ButtonWithOptions from 'components/common/ButtonWithOptions';
import { folderOfUnassignedReports, dateFormat } from 'components/pages/reports/enums';

import style from 'styles/reports/reports.css';
import moment from 'moment/moment';

const styles = style.locals || {};

function FolderCard({
  folder, reportsData, optionsMenu, onSelectedReport, searchQuery,
}) {
  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  useEffect(() => {
    if (searchQuery !== '') {
      setIsFolderOpen(true);
    }
  }, [searchQuery]);

  const isFolderOfUnassignedReports = folder.value === folderOfUnassignedReports.value;
  const [isFolderOpen, setIsFolderOpen] = useState(isFolderOfUnassignedReports);

  const folderOption = optionsMenu({ folder, type: 'folder' });
  const parsedFolderDate = folder.updatedDate ? moment(folder.updatedDate).format(dateFormat) : '';

  return (
    <>
      <div
        className={classnames(styles.folderCard, styles.folderCardHover)}
        key={`folder-${folder.value}-${folder.label}`}
        onClick={() => setIsFolderOpen((prev) => !prev)}
      >
        <div className={styles.folderName}>
          <div className={isFolderOpen ? styles.arrowDown : styles.arrowRight} />
          <div className={styles.folderIcon} />
          {folder.label}
        </div>
        <div className={styles.reportCount}>{reportsData.length}</div>
        {isFolderOfUnassignedReports ? (
          <div data-testid="unassigned-folder" className={styles.unassignedFolderFields} />
        ) : (
          <>
            <div className={styles.modifiedDate}>{parsedFolderDate}</div>
            <div onClick={(e) => e.stopPropagation()}>
              <ButtonWithOptions
                options={folderOption}
                renderMenuButton={() => <div className={styles.optionMenu} />}
                popupClassName={styles.optionPopupMenu}
              />
            </div>
          </>
        )}
      </div>
      {isFolderOpen && reportsData.length > 0 && (
        <div className={styles.reportsWrapper}>
          {reportsData.map((report) => {
            const reportOption = optionsMenu({ report, folder, type: 'report' });
            const parsedReportDate = report.updatedDate ? moment(report.updatedDate).format(dateFormat) : '';
            return (
              <div
                className={styles.folderCard}
                key={`report-${folder.value}-${report.value}`}
              >
                <div
                  className={styles.reportName}
                  onClick={() => onSelectedReport(report)}
                >
                  {report.label}
                </div>
                <div className={styles.reportCount} />
                <div className={styles.modifiedDate}>{parsedReportDate}</div>
                <ButtonWithOptions
                  options={reportOption}
                  renderMenuButton={() => <div className={styles.optionMenu} />}
                  popupClassName={styles.optionPopupMenu}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default FolderCard;
