import React from 'react';
import classnames from 'classnames';

import Component from 'components/Component';

import style from 'styles/settings/goals/goals.css';

class MoreButton extends Component {

  style = style;

  render() {
    const {
      onClick = () => {}, customClass, isActive, customStyle,
    } = this.props;
    return (
      <div
        className={classnames(this.classes.moreButton, customClass)}
        onClick={onClick}
        style={customStyle}
      >
        <div className={`${this.classes.dot} ${isActive ? this.classes.dotActive : ''}`} />
        <div className={`${this.classes.dot} ${isActive ? this.classes.dotActive : ''}`} />
        <div className={`${this.classes.dot} ${isActive ? this.classes.dotActive : ''}`} />
      </div>
    );
  }
}

export default MoreButton;
