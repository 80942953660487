import React from 'react';
import classnames from 'classnames';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';

import userStore from 'stores/userStore';

import GeneratedImpactChartLegend from 'components/pages/analyze/ImpactBoard/GeneratedImpactChartLegend';
import GeneratedImpactPieChart from 'components/pages/analyze/ImpactBoard/GeneratedImpactPieChart';
import { getColor } from 'components/utils/colors';
import { formatBudget } from 'components/utils/budget';
import { chartFormatter } from 'components/utils/utils';
import Component from 'components/Component';
import TrendMark from 'components/pages/analyze/TrendMark';

import style from 'styles/analyze/generated-impact.css';
import analyzeStyle from 'styles/analyze/analyze.css';

const MAX_COLUMNS_WITHOUT_BRUSH = 31;

class GeneratedImpactPieView extends Component {
  style = style;

  styles = [analyzeStyle];

  static propTypes = {
    optimizedDataWithTotal: PropTypes.arrayOf(PropTypes.object).isRequired,
    formatter: PropTypes.func,
    valuesFormatter: PropTypes.func,
    isMoneyFormat: PropTypes.bool,
    nameFormat: PropTypes.func,
    getNavigationMenuPopupsParams: PropTypes.func,
  };

  static defaultProps = {
    formatter: (item) => item,
    valuesFormatter: chartFormatter,
    nameFormat: (name) => name,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: undefined,
    };
  }

  get formattersFunctions() {
    const {
      valuesFormatter: valuesFormatterFromProps, isMoneyFormat,
    } = this.props;
    const moneyFormatter = (v) => formatBudget(v, true);
    const valuesFormatter = isMoneyFormat ? moneyFormatter : valuesFormatterFromProps;
    return { valuesFormatter };
  }

  getBarShape = ({
    fill, x, y, width, height,
  }) => (height === 0 ? null : (
    <path
      d={`M ${x},${y + 2} h ${width} v ${height - 2} h -${width} Z`}
      stroke="none"
      fill={fill}
    />
  ));

  BarTooltip = (tooltipProps) => {
    const chosenMetric = this.getMetricName();
    const { activeItemKey } = this.state;
    const {
      nameFormat,
      optimizedDataWithTotal,
      prevData: {
        optimizedDataWithTotal: prevChart,
      } = {},
    } = this.props;
    const { valuesFormatter } = this.formattersFunctions;
    const { payload, label, active } = tooltipProps;
    if (!active || !payload) {
      return null;
    }
    const index = optimizedDataWithTotal.findIndex((period) => period.name === label);
    const total = optimizedDataWithTotal[index] ? optimizedDataWithTotal[index].total : 0;
    const prevDataForIndex = prevChart && prevChart[index];
    return (
      <div className={this.classes.impactChartTooltip}>
        <div className={this.classes.impactChartTooltipLabel}>
          {label}
          {' '}
          -
          {chosenMetric}
        </div>
        <div>
          {payload.map(({ name, value, color }) => {
            const percent = Math.round((value / total) * 100);
            const isActive = name === activeItemKey;
            const pair = prevDataForIndex && prevDataForIndex[name];
            return (
              <div
                className={classnames(this.classes.impactChartTooltipValue, {
                  [this.classes.impactChartTooltipValueActive]: isActive,
                })}
                key={name}
              >
                <div className={this.classes.row}>
                  <div
                    className={this.classes.circle}
                    style={{ backgroundColor: color }}
                  />
                  <div>{nameFormat(name)}</div>
                </div>

                <div className={this.classes.row}>
                  <span style={{ marginRight: 5 }}>
                    {valuesFormatter(value)}
                  </span>
                  (
                  {percent}
                  %)
                  <TrendMark number={value} prevNumber={pair} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  getMetricName = () => {
    const { indicator } = this.props;

    return userStore.getMetricNickname({ metric: indicator });
  };

  onMouseEnter = (activeIndex) => this.setState({ activeIndex });

  onMouseLeave = () => this.setState({ activeIndex: undefined });

  render() {
    const chosenMetric = this.getMetricName();
    const {
      total,
      dataArray,
      optimizedDataWithTotal,
      nameFormat,
      prevData,
      getNavigationMenuPopupsParams,
    } = this.props;
    const { activeIndex } = this.state;

    const { valuesFormatter } = this.formattersFunctions;
    return (
      <div className={classnames(analyzeStyle.locals.rows, this.classes.noMargin)}>
        <div className={classnames(analyzeStyle.locals.rows, this.classes.colLeft, this.classes.noMargin)}>
          <GeneratedImpactChartLegend
            dataArray={dataArray}
            total={total}
            prevDataArray={prevData.dataArray}
            activeIndex={activeIndex}
            valuesFormatter={valuesFormatter}
            containerClass={this.classes.impactChartLegendContainer}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            nameFormat={nameFormat}
            getNavigationMenuPopupsParams={getNavigationMenuPopupsParams}
          />
          <GeneratedImpactPieChart
            containerClass={this.classes.impactPieChart}
            dataArray={dataArray}
            total={total}
            prevData={prevData}
            chosenMetric={chosenMetric}
            activeIndex={activeIndex}
            valuesFormatter={valuesFormatter}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          />
        </div>
        <div className={this.classes.colRight}>
          <div className={analyzeStyle.locals.analyzeChart}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                className={this.classes.bar}
                data={optimizedDataWithTotal}
                barSize={16}
              >
                <CartesianGrid
                  vertical={false}
                  strokeDasharray="3 3"
                  strokeWidth={1}
                  stroke="rgba(54, 56, 64, 0.1)"
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tick={{ fontSize: '12px', color: '#707ea7' }}
                  tickLine
                  tickMargin={10}
                  height={40}
                  minTickGap={20}
                  interval="equidistantPreserveStart"
                />
                <YAxis
                  yAxisId="left"
                  axisLine={false}
                  tick={{ fontSize: '12px', color: '#707ea7' }}
                  tickLine={false}
                  tickMargin={15}
                  tickFormatter={(v) => valuesFormatter(v)}
                />
                {optimizedDataWithTotal.length > MAX_COLUMNS_WITHOUT_BRUSH && (
                  <Brush
                    dataKey="name"
                    height={40}
                    stroke="#3e7de3"
                    style={{ marginTop: 10 }}
                    startIndex={optimizedDataWithTotal.length - MAX_COLUMNS_WITHOUT_BRUSH}
                    endIndex={optimizedDataWithTotal.length - 1}
                  />
                )}
                {dataArray.map((item, index) => (
                  <Bar
                    key={index}
                    yAxisId="left"
                    dataKey={item.name}
                    stackId="channels"
                    fill={getColor(index)}
                    isAnimationActive={false}
                    shape={this.getBarShape}
                    onMouseEnter={() => {
                      this.setState({ activeIndex: index, activeItemKey: item.name });
                    }}
                    onMouseLeave={() => {
                      this.setState({ activeIndex: undefined, activeItemKey: undefined });
                    }}
                  >
                    {index == dataArray.length - 1 && (
                      <LabelList
                        dataKey="total"
                        position="top"
                        style={{ fontSize: '12px', fontWeight: '600', fill: '#707ea7' }}
                        formatter={valuesFormatter}
                      />
                    )}
                  </Bar>
                ))}
                <RechartsTooltip
                  cursor={false}
                  offset={0}
                  content={this.BarTooltip}
                  animationDuration={50}
                  viewBox={{
                    x: 0, y: 0, width: 400, height: 400,
                  }}
                  allowEscapeViewBox={{ x: true, y: true }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneratedImpactPieView;
