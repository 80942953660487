import React from 'react';
import Component from 'components/Component';
import classnames from 'classnames';
import style from 'styles/dashboard/spend-vs-acttributed-chart.css';
import { formatBudget } from 'components/utils/budget';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { uniqueId } from 'lodash';

class ListItemColumn extends Component {
  style = style;

  render() {
    const { item, right, max } = this.props;
    const left = !right;
    const prefix = left ? 'left_' : 'right_';
    const value = right ? item.targetCount : item.value;
    const width = max > 0 ? Math.round((value / max) * 100) : 0;
    return (
      <div className={this.classes.chartItemCol}>
        <div key={uniqueId(prefix)} className={this.classes.chartItemRowWrapper}>
          {left && (
            <div className={this.classes.metricRow}>
              {formatBudget(value, true, true)}
            </div>
          )}
          <div
            className={classnames(this.classes.rowBackground, {
              [this.classes.rowBackgroundLeft]: left,
            })}
          >
            <div
              className={this.classes.chartItemRow}
              style={{ width: `${width}%` }}
            />
          </div>
          {right && (
            <div className={classnames(this.classes.metricRow, this.classes.metricRowRight)}>
              {formatNumberWithDecimalPoint(value)?.toLocaleString()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ListItemColumn;
