import React from 'react';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import ReactDOM from 'react-dom';
import history from 'history';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import Component from 'components/Component';
import Page from 'components/Page';
import BackButton from 'components/pages/profile/BackButton';
import NextButton from 'components/pages/profile/NextButton';
import Title from 'components/onboarding/Title';
import Platform from 'components/pages/indicators/Platform';
import FacebookCampaignsPopup from 'components/importCampaignsPopups/FacebookCampaignsPopup';
import AdwordsCampaignsPopup from 'components/importCampaignsPopups/AdwordsCampaignsPopup';
import LinkedinCampaignsPopup from 'components/importCampaignsPopups/LinkedinCampaignsPopup';
import TwitterCampaignsPopup from 'components/importCampaignsPopups/TwitterCampaignPopup';
import BingCampaignsPopup from 'components/importCampaignsPopups/BingCampaignsPopup';
import PardotCampaignsPopup from 'components/importCampaignsPopups/PardotCampaignsPopup';
import ScheduleTaskConfirmationPopup from 'components/common/ScheduleTaskConfirmationPopup';
import ScheduleTaskRunningTimer from 'components/common/ScheduleTaskRunningTimer';
import CreateIntegration from 'components/pages/createIntegration/CreateIntegration';
import Tooltip from 'components/controls/Tooltip';
import DriftPopup from 'components/importCampaignsPopups/DriftPopup';
import Popup from 'components/Popup';
import DropMenuButton from 'components/header/DropMenuButton';
import FeatureFlags from 'components/common/FeatureFlags';

import { isPopupMode } from 'modules/popup-mode';

import {
  BLOG_SUBSCRIBER_FUNNEL,
  getNewFunnels,
  HUBSPOTAPI,
  SALESFORCEAPI,
} from 'components/utils/indicators';
import { compose } from 'components/utils/utils';

import planButtonIcon from 'assets/plan.svg';
import style from 'styles/onboarding/onboarding.css';
import platformsStyle from 'styles/indicators/platforms.css';

import CostPreDefinedFilters from './users/Filters/CostPreDefinedFilters';
import SalesforceCampaignsSettingsPopup from '../importCampaignsPopups/SalesforceCampaignsSettingsPopup';

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
      filterStore,
      attributionStore,
    } = stores;
    const {
      metricsOptions,
    } = attributionStore;
    const {
      funnels,
      userMonthPlan,
      userBusinessUnitsWithIds,
      integrationsConfig,
      requestUserIntegrationsConfig,
    } = userStore;
    const {
      isFilterDataLoaded,
      getUserFiltersData,
      filtersData,
    } = filterStore;

    return {
      filtersData,
      metricsOptions,
      funnels,
      userMonthPlan,
      isFilterDataLoaded,
      getUserFiltersData,
      userBusinessUnitsWithIds,
      integrationsConfig,
      requestUserIntegrationsConfig,
    };
  }),
  observer
);

class Platforms extends Component {
  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  style = style;

  styles = [platformsStyle];

  constructor(props) {
    super(props);
    this.state = {
      crm: SALESFORCEAPI,
      visibleSections: {},
      loading: [],
      isCreateIntegrationPopup: false,
      isVisibleCrmSetting: false,
      isVisiblePreDefinedFilters: false,
      isCostPredefineFilter: false,
      isSalesForceCampaignsSettingsPopup: false,
      isActiveSalesforceCampaignsFlag: false,
      funnelsNicknames: [],
    };
  }

  async componentDidMount() {
    this.getSalesforceCampaignsFlagRequest();
    await this.props.requestUserIntegrationsConfig();

    if (!this.props.isFilterDataLoaded) {
      this.props.getUserFiltersData();
    }
    const sections = ['crm'];
    const visibleSections = {};
    sections.forEach((section) => {
      visibleSections[section] = this.isTitleHidden(section);
    });
    this.setState({ visibleSections });
  }

  get isCacheAccount() {
    return this.props.accountType === 'CACHE';
  }

  isHidden(platform) {
    return !this.props.technologyStack.includes(platform);
  }

  isLoading = (platform) => this.state.loading.indexOf(platform) >= 0;

  setLoading = (platform, isLoading) => {
    const { loading } = this.state;
    if (isLoading) {
      this.setState({
        loading: [...loading, platform],
      });
    } else {
      const newLoadingArray = [...loading];
      remove(newLoadingArray, (item) => item === platform);

      this.setState({
        loading: newLoadingArray,
      });
    }
  };

  isTitleHidden = (title) => {
    // eslint-disable-next-line react/no-find-dom-node
    const domElement = ReactDOM.findDOMNode(this.refs[title]);
    let isHidden = true;
    if (domElement) {
      const childrenArray = [...domElement.children];
      childrenArray.forEach((child) => {
        isHidden = isHidden && child.hidden;
      });
    }
    return isHidden;
  };

  showScheduleTaskConfirmationPopup = () => {
    this.setState({ showScheduleTaskConfirmationPopup: true });
  };

  updateState = (newState, callback, calculateData) => {
    newState.unsaved = false;
    this.props.updateState(newState, callback, calculateData);
  };

  updateDeleteCrmDataState = (crmPlatform) => {
    const crmStateName = `${crmPlatform}UserMappings`;
    this.setState({ [crmStateName]: null });
  };

  updateUserMonthPlan = (body, region = this.props.region) => {
    const { updateUserMonthPlan } = this.props;
    return updateUserMonthPlan(body, region, false, false);
  };

  onAddPredefineFilters({ predefinedFiltersConfig }) {
    const predefinedFiltersConfigForRequest = {};
    for (const [funnelStageName, predefinedConfig] of Object.entries(predefinedFiltersConfig)) {
      predefinedFiltersConfigForRequest[funnelStageName] = {
        filters: predefinedConfig.filters?.map(({ config, data }) => ({ kind: config.kind, data })),
        logicExpression: predefinedConfig.logicExpression,
      };
    }
    return predefinedFiltersConfigForRequest;
  }

  setFunnelsNicknames = (updatedFunnelsNicknames) => this.setState({ funnelsNicknames: updatedFunnelsNicknames });

  onClickCampaignsPredefineButton = () => {
    this.setState({ isCostPredefineFilter: true });
  };

  setIsActiveSalesforceCampaignsFlag = (flag) => {
    this.setState({ isActiveSalesforceCampaignsFlag: flag });
  };

  getSalesforceCampaignsFlagRequest = async () => {
    this.setLoading('salesForceCampaigns', true);
    try {
      const response = await serverCommunication.serverRequest('GET', 'crm/salesforceCampaigns/flag', null, this.props.region);
      if (response.ok) {
        const responseData = await response.json();
        this.setState({ isActiveSalesforceCampaignsFlag: responseData.useSalesforceCampaigns });
        this.setLoading('salesForceCampaigns', false);
      }
    } catch (error) {
      this.setLoading('salesForceCampaigns', false);
      throw error;
    }
  };

  render() {
    const {
      filtersData,
      flags,
    } = this.props;

    const { integrations } = this.props.calculatedData;
    const BASE_CRM_PLATFORM_INDICATORS_MAPPING = [...this.props.funnels, ...getNewFunnels()];
    const PLATFORM_INDICATORS_MAPPING = {
      Hubspot: [...BASE_CRM_PLATFORM_INDICATORS_MAPPING, BLOG_SUBSCRIBER_FUNNEL, 'newPipeline', 'newBookings'],
      Salesforce: [...BASE_CRM_PLATFORM_INDICATORS_MAPPING, 'CAC', 'MRR', 'ARR', 'ARPA', 'newPipeline', 'newBookings'],
      'Google Analytics': ['sessions', 'bounceRate', 'averageSessionDuration', 'blogVisits'],
      LinkedIn: ['linkedinEngagement', 'linkedinFollowers'],
      Facebook: ['facebookEngagement', 'facebookLikes'],
      Twitter: ['twitterFollowers', 'twitterEngagement'],
      Youtube: ['youtubeSubscribers', 'youtubeEngagement'],
      Stripe: ['MRR', 'ARR', 'LTV', 'churnRate'],
      'Google Sheets': ['MRR', 'ARR', 'LTV', 'CAC', 'churnRate'],
      Moz: ['domainAuthority'],
    };
    const isAnyReintegrationIsRunning = this.state.salesforceapi?.isCRMIntegrationRunning
      || this.state.hubspotapi?.isCRMIntegrationRunning
      || this.state.salesforceapi?.isCampaignsIntegrationRunning
      || this.state.salesforceapi?.isTasksIntegrationRunning;
    const isUpdateDataIsRunning = this.props.isScheduleTaskRunning;
    const isEtlRunning = this.isCacheAccount;
    const isEnableCrmConfiguration = flags.integrationCrmConfiguration;

    const isFiltersConfigLoaded = filtersData.length > 0;

    return (
      <div>
        <Page
          popup={isPopupMode()}
          className={!isPopupMode() ? this.classes.static : null}
          contentClassName={this.classes.content}
          innerClassName={this.classes.pageInner}
          width="100%"
        >
          {!isPopupMode() && (
            <div style={{ display: 'flex' }}>
              <Title title="Integrations" />
              <div>
                <Tooltip
                  id={uniqueId()}
                  tip={'Update data is currently running. You will be able to run it again once it\'s done.'}
                  hidden={!(isUpdateDataIsRunning)}
                >
                  <Button
                    icon={planButtonIcon}
                    type="primaryBlue"
                    style={{ width: '135px', marginLeft: '15px' }}
                    disabled={isUpdateDataIsRunning || isAnyReintegrationIsRunning}
                    onClick={() => {
                      this.showScheduleTaskConfirmationPopup();
                    }}
                  >
                    Update data
                  </Button>
                </Tooltip>
              </div>
              <ScheduleTaskRunningTimer
                startedRunning={this.props.isScheduleTaskRunning}
                updateTimerTime={(newTime) => this.props.updateTimerTime(newTime)}
                timerCurrentTime={this.props.timerMinutes}
                doneRunning={() => {
                  this.props.doneRunningScheduleTask();
                }}
              />
            </div>
          )}
          <div>
            <ScheduleTaskConfirmationPopup
              hidden={!this.state.showScheduleTaskConfirmationPopup}
              runScheduleTask={() => {
                this.props.runScheduleTask();
              }}
              close={() => this.setState({ showScheduleTaskConfirmationPopup: false })}
            />
            {this.state.isCreateIntegrationPopup && (
            <CreateIntegration
              isVisiblePreDefinedFilters={this.state.isVisiblePreDefinedFilters}
              onAddPredefineFilters={(predefinedFiltersConfig) => this.onAddPredefineFilters(predefinedFiltersConfig)}
              filterConfigs={filtersData}
              crm={this.state.crm}
              updateDeleteCrmDataState={this.updateDeleteCrmDataState}
              isCreateIntegrationPopup={this.state.isCreateIntegrationPopup}
              region={this.props.region}
              close={() => {
                this.setState({ isVisiblePreDefinedFilters: false, isCreateIntegrationPopup: false });
              }}
              funnelsNicknames={this.state.funnelsNicknames}
              setFunnelsNicknames={this.setFunnelsNicknames}
              userBusinessUnitsWithIds={this.props.userBusinessUnitsWithIds}
            />
            )}
            <LinkedinCampaignsPopup
              updateState={this.updateState}
              ref="linkedinCampaigns"
              data={this.props.linkedinadsapi}
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('linkedinCampaigns', true)}
              loadingFinished={() => this.setLoading('linkedinCampaigns', false)}
            />
            <TwitterCampaignsPopup
              updateState={this.updateState}
              ref="twitterCampaigns"
              data={this.props.twitteradsapi}
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('twitterCampaigns', true)}
              loadingFinished={() => this.setLoading('twitterCampaigns', false)}
            />
            <FacebookCampaignsPopup
              updateState={this.updateState}
              ref="facebookCampaigns"
              data={this.props.facebookadsapi}
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('facebookCampaigns', true)}
              loadingFinished={() => this.setLoading('facebookCampaigns', false)}
            />
            <AdwordsCampaignsPopup
              updateState={this.updateState}
              ref="adwordsCampaigns"
              data={this.props.adwordsapi}
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('adwordsCampaigns', true)}
              loadingFinished={() => this.setLoading('adwordsCampaigns', false)}
            />
            {this.state.isSalesForceCampaignsSettingsPopup && (
            <SalesforceCampaignsSettingsPopup
              close={() => {
                this.setState({ isSalesForceCampaignsSettingsPopup: false });
              }}
              region={this.props.region}
              isActiveFlag={this.state.isActiveSalesforceCampaignsFlag}
              setIsActiveSalesforceCampaignsFlag={this.setIsActiveSalesforceCampaignsFlag}
            />
            )}
            <DriftPopup
              setDataAsState={this.props.setDataAsState}
              updateState={this.props.updateState}
              data={this.props.driftapi?.tokens}
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('drift', true)}
              loadingFinished={() => this.setLoading('drift', false)}
              isLoading={this.state.loading.find((key) => key === 'drift')}
              addUnknownChannel={this.props.addUnknownChannel}
              authPopupRef={(ref) => { this.driftAuthPopup = ref; }}
              afterAuthorizationApi="driftapi"
            />
            <BingCampaignsPopup
              updateState={this.updateState}
              ref="bingCampaigns"
              data={this.props.bingadsapi}
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('bingCampaigns', true)}
              loadingFinished={() => this.setLoading('bingCampaigns', false)}
            />
            <PardotCampaignsPopup
              updateState={this.updateState}
              data={this.props.pardotapi?.tokens}
              ref="pardotCampaigns"
              userAccount={this.props.userAccount}
              loadingStarted={() => this.setLoading('pardotCampaigns', true)}
              loadingFinished={() => this.setLoading('pardotCampaigns', false)}
              isLoading={this.state.loading.find((key) => key === 'pardotCampaigns')}
              api="pardotapi"
              afterAuthorizationApi="pardotapi"
            />
            {this.state.isCostPredefineFilter
                && (
                  <CostPreDefinedFilters
                    close={() => {
                      this.setState({ isCostPredefineFilter: false });
                    }}
                    filterConfigs={filtersData}
                    region={this.props.region}
                  />
                )}
            {!isPopupMode() && (
              <>
                {flags.addMorePlatformsButtonSettingsIntegrationsPage && (
                  <Button
                    type="secondaryWhite"
                    style={{
                      width: '193px',
                      marginLeft: 'auto',
                    }}
                    onClick={() => {
                      history.push('/profile/technology-stack');
                    }}
                  >
                    Add more platforms
                  </Button>
                )}
                <div hidden={this.state.visibleSections.crm}>
                  <div className={classnames(platformsStyle.locals.platformTitle, platformsStyle.locals.settingsCrmTitle)}>
                    <span>CRM</span>
                    <DropMenuButton
                      dataSelected={this.state.isVisibleCrmSetting ? true : null}
                      onClick={() => this.setState((prevState) => ({ isVisibleCrmSetting: !prevState.isVisibleCrmSetting }))}
                      className={platformsStyle.locals.settingsCrmDropdown}
                    >
                      <div
                        className={platformsStyle.locals.settingsCrmIcon}
                        data-icon="platform:settings"
                        data-testid="settingsCrmIcon-Button"
                      >
                        <Popup
                          className={platformsStyle.locals.settingsCrmMenuPopup}
                          hidden={!this.state.isVisibleCrmSetting}
                          ref="settingPopup"
                          onClose={() => this.setState({ isVisibleCrmSetting: false })}
                        >
                          <div className={platformsStyle.locals.settingsCrmMenu}>
                            <FeatureFlags flag={flags.crmConfigurationOptionInPlatformsSettingsIntegrationsPage}>
                              <div className={(isEtlRunning && !isEnableCrmConfiguration) ? platformsStyle.locals.disabled : null}>
                                <Tooltip
                                  hidden={!isEtlRunning || isEnableCrmConfiguration}
                                  id="isEtlRunning"
                                  tip="Update data is currently running. <br/> You will be able to access the CRM <br/> configuration once it's done."
                                  place="right"
                                >
                                  <div data-testid="CRM-Configuration-Button" onClick={(!isEtlRunning || isEnableCrmConfiguration) ? () => this.setState({ isVisiblePreDefinedFilters: false, isCreateIntegrationPopup: true }) : null}>
                                    CRM Configuration
                                  </div>
                                </Tooltip>
                              </div>
                            </FeatureFlags>
                            {filtersData?.length > 0
                              ? <div onClick={() => this.setState({ isVisiblePreDefinedFilters: true, isCreateIntegrationPopup: true })}>Predefined Filters</div>
                              : <div data-testid="Predefined-Filters-Button" className={platformsStyle.locals.disabled}>Predefined Filters</div>}
                          </div>
                        </Popup>
                      </div>
                    </DropMenuButton>
                  </div>
                  <div className={platformsStyle.locals.platformLine} ref="crm">
                    <Platform
                      hideAction
                      connected={integrations.isSalesforceAuto}
                      loadingData={integrations.isSalesforceLoading}
                      title="Salesforce"
                      loading={this.isLoading('salesforce')}
                      indicators={PLATFORM_INDICATORS_MAPPING.Salesforce}
                      icon="platform:salesforce"
                      open={() => this.setState({
                        crm: SALESFORCEAPI,
                        isVisiblePreDefinedFilters: false,
                        isCreateIntegrationPopup: true,
                      })}
                      hidden={this.isHidden('salesforce')}
                    />
                    <Platform
                      hideAction
                      connected={integrations.isHubspotAuto}
                      loadingData={integrations.isHubspotLoading}
                      title="Hubspot"
                      loading={this.isLoading('hubspot')}
                      indicators={PLATFORM_INDICATORS_MAPPING.Hubspot}
                      icon="platform:hubspot"
                      open={() => this.setState({
                        crm: HUBSPOTAPI,
                        isVisiblePreDefinedFilters: false,
                        isCreateIntegrationPopup: true,
                      })}
                      hidden={this.isHidden('hubspot')}
                    />
                  </div>
                </div>
              </>
            )}
            <div>
              <div className={platformsStyle.locals.platformTitle} data-testid="campaigns-label">
                Campaigns
                <div
                  className={classnames(platformsStyle.locals.settingsCrmIcon, !isFiltersConfigLoaded && platformsStyle.locals.disabledSettingsCrmIcon)}
                  data-icon="platform:settings"
                  onClick={isFiltersConfigLoaded ? this.onClickCampaignsPredefineButton : null}
                />
              </div>

              <div className={platformsStyle.locals.platformLine} ref="crm">
                <div className={this.classes.row}>
                  <Platform
                    connected={this.state.isActiveSalesforceCampaignsFlag}
                    loadingData={integrations.isSalesforceCampaignsLoading}
                    title="Salesforce Campaigns"
                    loading={this.isLoading('salesForceCampaigns')}
                    icon="platform:salesforce"
                    connectButtonText="Sync"
                    setDataAsState={this.props.setDataAsState}
                    open={() => this.setState({ isSalesForceCampaignsSettingsPopup: true })}
                  />
                </div>
                <div className={this.classes.row} />
                <Platform
                  connected={integrations.isDriftTasksAuto}
                  loadingData={false}
                  title="Drift"
                  loading={this.isLoading('drift')}
                  icon="platform:drift"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => this.driftAuthPopup.open()}
                />
                <Platform
                  connected={integrations.isAdwordsAuto}
                  loadingData={integrations.isAdwordsLoading}
                  expired={integrations.isAdwordsExpired}
                  title="Google Ads"
                  loading={this.isLoading('adwordsCampaigns')}
                  icon="platform:googleAds"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => {
                    this.refs.adwordsCampaigns.open();
                  }}
                />
                <Platform
                  connected={integrations.isFacebookAdsAuto}
                  loadingData={integrations.isFacebookAdsLoading}
                  expired={integrations.isFacebookAdsExpired}
                  title="Facebook Campaigns"
                  loading={this.isLoading('facebookCampaigns')}
                  icon="platform:facebookAds"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => {
                    this.refs.facebookCampaigns.open();
                  }}
                />
                <Platform
                  connected={integrations.isLinkedinAdsAuto}
                  loadingData={integrations.isLinkedinAdsLoading}
                  expired={integrations.isLinkedinAdsExpired}
                  title="LinkedIn Campaigns"
                  loading={this.isLoading('linkedinCampaigns')}
                  icon="platform:linkedInAds"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => {
                    this.refs.linkedinCampaigns.open();
                  }}
                />
                <Platform
                  connected={integrations.isTwitterAdsAuto}
                  loadingData={integrations.isTwitterAdsLoading}
                  title="Twitter Campaigns"
                  loading={this.isLoading('twitterCampaigns')}
                  icon="platform:twitterAds"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => {
                    this.refs.twitterCampaigns.open();
                  }}
                />
                <Platform
                  connected={integrations.isBingAdsAuto}
                  loadingData={integrations.isBingAdsLoading}
                  expired={integrations.isBingAdsExpired}
                  title="Bing Campaigns"
                  loading={this.isLoading('bingCampaigns')}
                  icon="platform:bingAds"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => {
                    this.refs.bingCampaigns.open();
                  }}
                />
                <Platform
                  connected={this.props.integrationsConfig?.pardot?.isConnected}
                  title="Pardot"
                  loading={this.isLoading('pardotCampaigns')}
                  icon="platform:pardot"
                  connectButtonText="Sync"
                  setDataAsState={this.props.setDataAsState}
                  open={() => {
                    this.refs.pardotCampaigns.open();
                  }}
                />
              </div>
            </div>
            {isPopupMode()
              ? (
                <div className={this.classes.footer} data-testid="platform-next-button">
                  <BackButton onClick={() => {
                    history.push('/settings/account');
                  }}
                  />
                  <div style={{ width: '30px' }} />
                  <NextButton onClick={() => {
                    history.push('/settings/attribution/setup');
                  }}
                  />
                </div>
              )
              : <div style={{ paddingBottom: '60px' }} />}
          </div>
        </Page>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Platforms));
