import React from 'react';
import classNames from 'classnames';
import Spinner from 'components/pages/journeys/Spinner';
import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import integrationStyle from 'styles/integrations/integration.css';
import { CRMIntegrationsOptions } from 'components/utils/indicators';
import { CRMPlatformsNickname } from 'components/pages/createIntegration/logic/enums';

const styles = integrationStyle.locals;

const CRMIntegrationsSelect = () => {
  const {
    loadingCRMData, selectedCRMItems, setParentState, isNextStepLoading,
  } = useCRMCContext();

  const selectedCRMHandler = (crmItem) => {
    const CRMindex = selectedCRMItems.indexOf(crmItem);
    if (CRMindex !== -1) {
      selectedCRMItems.splice(CRMindex, 1);
    } else {
      selectedCRMItems.push(crmItem);
    }
    setParentState({ selectedCRMItems });
  };

  return (
    <>
      <h1>Connect your CRM</h1>
      <h3>
        Pull data from your CRM (
        <i>you can select more than one</i>
        )
      </h3>
      <div className={styles.integrationsSelect}>
        {CRMIntegrationsOptions.map((crmItem) => (
          <div
            key={crmItem}
            className={classNames(styles.integrationBox, selectedCRMItems.includes(crmItem) && styles.active)}
            onClick={!isNextStepLoading ? () => selectedCRMHandler(crmItem) : null}
          >
            <div className={styles.crmItemIcon} data-icon={`platform:${crmItem}CRM`} />
            <span>{CRMPlatformsNickname[crmItem]}</span>
            {loadingCRMData === `${crmItem.toLowerCase()}api` && <Spinner />}
          </div>
        ))}
      </div>
    </>
  );
};

export default CRMIntegrationsSelect;
