/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Component from 'components/Component';
import CampaignsImportPopup from 'components/importCampaignsPopups/CampaignsImportPopup';

export default class AdwordsCampaignsPopup extends Component {
  constructor(props) {
    super(props);
    this.campaignImportRef = React.createRef();
  }

  open() {
    this.campaignImportRef.current.open();
  }

  render() {
    const noPermissionsMessage = (
      <>
        We couldn&apos;t find any Google Ads child account.
        <br />
        Please update your access to Admin and try again.
      </>
    );
    return (
      <CampaignsImportPopup
        ref={this.campaignImportRef}
        title="Google AdWords"
        message={noPermissionsMessage}
        api="googleadsapi"
        platformTitle="Google Adwords"
        multi
        {...this.props}
      />
    );
  }
}
