import React from 'react';
import {
  Cell, Pie, PieChart, ResponsiveContainer, Sector,
} from 'recharts';
import { getColor } from 'components/utils/colors';
import { formatNumber } from 'components/utils/budget';
import style from 'styles/analyze/generated-impact.css';
import Component from 'components/Component';
import TrendMark from 'components/pages/analyze/TrendMark';

class GeneratedImpactPieChart extends Component {
  style = style;

  static defaultProps = {
    keyExtractor: (item, index) => index,
  }

  getPieShape = ({
    cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill,
  }) => (
    <g>
      <defs>
        <filter id="pieShadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="6" result="blur" />
          <feOffset in="blur" dx="0" dy="4" result="offsetBlur" />
          <feFlood floodColor={fill} floodOpacity="0.3" result="offsetColor" />
          <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
        </filter>
      </defs>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 3}
        outerRadius={outerRadius + 3}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        filter="url(#pieShadow)"
        cornerRadius={35}
      />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 5}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#ffffff"
        cornerRadius={35}
      />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 3}
        outerRadius={outerRadius + 3}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={35}
      />
    </g>
  );

  onMouseEnter = (data, activeIndex) => {
    const { onMouseEnter, keyExtractor } = this.props;
    onMouseEnter(keyExtractor(data, activeIndex));
  };

  render() {
    const {
      prevData,
      dataArray,
      total,
      valuesFormatter,
      onMouseLeave,
      chosenMetric,
      activeIndex: activeKey,
      containerClass,
      keyExtractor,
    } = this.props;
    const indexOfActiveItem = dataArray.findIndex((sector, i) => keyExtractor(sector, i) === activeKey);
    return (
      <div className={containerClass}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={dataArray}
              dataKey="value"
              cx="50%"
              cy="50%"
              labelLine
              innerRadius="80%"
              outerRadius="90%"
              isAnimationActive={false}
              activeIndex={indexOfActiveItem}
              activeShape={this.getPieShape}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {dataArray.map((entry, index) => {
                const key = keyExtractor(entry, index);
                return <Cell fill={getColor(key)} key={key} />;
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className={this.classes.impactPieChartTotal}>
          <p className={this.classes.impactPieChartMetric}>{formatNumber(valuesFormatter(total))}</p>
          <p className={this.classes.impactPieChartMetricName}>{chosenMetric}</p>
          <TrendMark
            number={total}
            prevNumber={prevData && prevData.total}
            style={{ marginLeft: 0 }}
          />
        </div>
      </div>
    );
  }
}

export default GeneratedImpactPieChart;
