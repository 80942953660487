import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import history from 'history';
import { Button, Checkbox } from '@infinigrow/libs';

import Spinner from 'components/pages/journeys/Spinner';
import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';

import { regionNameAlreadyExistsErrorMessage } from 'components/enums';

import style from 'styles/integrations/business-unit-popup.css';

const styles = style.locals || {};

function CreateNewBusinessUnitPopup({
  hidden, onClose, createUserMonthPlan, userBusinessUnitsWithIds = [], userAccount, updateUserAccount,
}) {
  if (hidden) {
    return null;
  }

  const [regionData, setRegionData] = useState({ name: '', isShowError: false, isCheckedAsDefault: false });
  const [isLoading, setIsLoading] = useState(false);
  const userBusinessUnitNames = userBusinessUnitsWithIds.map((businessUnit) => businessUnit.name);

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  async function createNewBusinessUnit() {
    setIsLoading(true);

    let businessUnitConnections;
    const isFirstBusinessUnit = !userAccount?.defaultBusinessUnitId;
    if (!isFirstBusinessUnit) {
      businessUnitConnections = {
        crmBusinessUnitId: userAccount.defaultBusinessUnitId,
        analysisBusinessUnitId: userAccount.defaultBusinessUnitId,
      };
    }

    const newUserMonthPlan = await createUserMonthPlan({ nickname: regionData.name, businessUnitConnections });

    if (regionData.isCheckedAsDefault) {
      await updateUserAccount({
        defaultBusinessUnitId: newUserMonthPlan.businessUnitId,
      });
    }

    setIsLoading(false);
    history.push('/settings/profile/integrations');
    onClose(regionData.name);
  }

  function onChangeRegionName(value) {
    const regionName = value.target.value;
    const isShowError = userBusinessUnitNames.some((name) => name.toLowerCase() === regionName.toLowerCase());
    setRegionData({ ...regionData, name: regionName, isShowError });
  }

  return (
    <Page popup width="400px" contentClassName={styles.wrapper}>
      <div className={styles.header}>
        <b>New Business Unit</b>
        <div>
          Create a separate Region/Team/Product.
          <br />
          Each Business Unit can have its own funnel configuration or budget.
        </div>
      </div>
      <div className={styles.content}>
        <Textfield
          isShowError={regionData.isShowError}
          inputErrorMessage={regionNameAlreadyExistsErrorMessage}
          placeHolder="Business unit name"
          onChange={(value) => onChangeRegionName(value)}
        />
        <Checkbox
          key="setAsDefaultRegion"
          checked={regionData.isCheckedAsDefault}
          onChange={() => setRegionData({ ...regionData, isCheckedAsDefault: !regionData.isCheckedAsDefault })}
        >
          Use this Business unit  as the default preset
        </Checkbox>
      </div>
      <div className={styles.footerButtons}>
        <div>
          <Button
            dataTestId="cancel"
            type="secondaryBlue"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
        {isLoading && <Spinner />}
        <div>
          <Button
            dataTestId="create-region"
            disabled={!regionData.name || regionData.isShowError}
            type="primaryBlue"
            onClick={() => createNewBusinessUnit()}
          >
            Create
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default inject(({
  userStore: {
    userBusinessUnitsWithIds,
    userAccount,
  },

}) => ({
  userBusinessUnitsWithIds,
  userAccount,
}), observer)(CreateNewBusinessUnitPopup);
