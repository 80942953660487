import React from 'react';

import useStyles from 'hooks/useStyles';

import CarouselArrowNavigation from 'components/common/CarouselArrowNavigation';
import StatsMetricWithGoal from 'components/pages/insights/overviewTab/goals/StatsMetricWithGoal';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';

import { widgetTypes } from 'components/pages/analyze/enums';
import { goalSkeletonId } from 'components/pages/settings/goals/logic/enums';
import { setTagIdOnOptimizationMetricsByMetricType } from 'components/pages/insights/overviewTab/goals/logic/optimizationMetrics';

import style from 'styles/insightsPage/goals/goalsAnalysis.css';

const styles = style.locals;

function OptimizationMetrics({
  data,
  trendData = {},
  selectedMetric,
  setSelectedMetric,
  isLoadedTrend,
  isFailedToLoad,
  isOnboarding,
}) {
  useStyles([style]);

  if (!data) { return null; }

  if (isFailedToLoad) {
    return (
      <ErrorWidgetWithBlur
        status="error"
        widgetType={widgetTypes.historicalPerformance}
      />
    );
  }

  function parsedFlatTrendData() {
    let flatTrendData = {};
    for (const trendPerStage of Object.values(trendData)) {
      flatTrendData = {
        ...flatTrendData,
        ...trendPerStage?.totals,
      };
    }
    return flatTrendData;
  }

  const flatTrendData = parsedFlatTrendData();

  let optimizationMetricsData = data;

  if (isOnboarding) {
    optimizationMetricsData = setTagIdOnOptimizationMetricsByMetricType({ data });
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Metric drilldown
      </div>
      <div className={styles.subTitle}>
        Select a related metric to uncover actionable insights on how to improve its performance
      </div>
      <CarouselArrowNavigation
        list={optimizationMetricsData}
        maxItemsInLine={5}
        isResetListIndexOnLoad={false}
      >
        {({ availableList }) => (
          <div className={styles.goalsStatsWrapper}>
            {availableList.map((metric) => {
              const metricData = {
                trendValue: flatTrendData?.[metric.metricName],
                metricType: metric.metricType,
                actualValue: metric.total?.value,
                metricName: metric.metricName,
                id: metric._id,
                tagId: metric.tagId,
              };
              return (
                <StatsMetricWithGoal
                  key={metricData.id}
                  data={metricData}
                  isSelected={metricData.id === selectedMetric.id}
                  setSelectedGoal={() => setSelectedMetric({ value: metricData.metricName, id: metricData.id, actualValue: metricData.actualValue })}
                  showProgressBar={false}
                  showGoalTag={false}
                  showTrendTag={false}
                  isLoaded={metricData.id !== goalSkeletonId}
                  isLoadedTrend={isLoadedTrend}
                  showNoTrendBottomTag
                />
              );
            })}
          </div>
        )}
      </CarouselArrowNavigation>
    </div>
  );
}

export default OptimizationMetrics;
