exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".choose-existing-template__page-content-3Yf{padding:0;margin:0}.choose-existing-template__content-2-T{padding:0 38px;margin-top:40px}.choose-existing-template__inner-37d{display:-webkit-box;max-height:430px;overflow:auto;padding:0 38px;position:sticky}.choose-existing-template__bottom-3hg{display:flex;justify-content:flex-end;background-color:#cbcbcb;height:50px}", ""]);

// exports
exports.locals = {
	"page-content": "choose-existing-template__page-content-3Yf",
	"pageContent": "choose-existing-template__page-content-3Yf",
	"content": "choose-existing-template__content-2-T",
	"content": "choose-existing-template__content-2-T",
	"inner": "choose-existing-template__inner-37d",
	"inner": "choose-existing-template__inner-37d",
	"bottom": "choose-existing-template__bottom-3hg",
	"bottom": "choose-existing-template__bottom-3hg"
};