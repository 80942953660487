export const generalCampaignName = 'General (non-campaign)';
export const costDateFormat = 'YYYY-MM-DD';
export const amountTypes = {
  budget: 'budget',
  actualSpent: 'actualSpent',
};

export const costDependentColumnTypes = [
  'cost',
  'efficiency',
  'roi',
  'pipeline-roi',
];

export const velocityBaseId = 'velocity-stage-indicator';

export const nonEmptyRowDataKeys = [
  'stage-indicator',
  'influenced-stage-indicator',
  'pipeline',
  'revenue',
  'influenced-revenue',
];