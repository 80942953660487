import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';

import style from 'styles/common/confirmation-popup.css';

export default class ScheduleTaskConfirmationPopup extends Component {
  style = style;

  static propTypes = {
    hidden: PropTypes.bool,
    close: PropTypes.func,
    runScheduleTask: PropTypes.func,
  };

  render() {
    const {
      hidden,
      close,
      runScheduleTask,
    } = this.props;

    return (
      <div hidden={hidden}>
        <Page popup width="400px" height="315px" onClose={close}>
          <div className={this.classes.title}>
            Data Update
          </div>
          <div className={this.classes.row}>
            {'Your data is updated '}
            <b>every hour</b>
            .
            <br />
            Click on 'Update' if you wish to trigger an additional data update.
          </div>
          <div className={this.classes.row}>
            {'We recommend performing manual updates '}
            <b>only after making changes to your channel mapping.</b>
          </div>
          <div className={this.classes.row} style={{ fontSize: '12px', fontStyle: 'italic' }}>
            Please note that manual updates may take several hours to complete.
          </div>
          <div className={this.classes.footer}>
            <Button
              type="primaryBlue"
              style={{ width: '72px' }}
              onClick={() => close()}
            >
              Cancel
            </Button>
            <Button
              type="secondaryWhite"
              style={{ width: '72px', marginLeft: 'auto' }}
              onClick={() => {
                runScheduleTask();
                close();
              }}
            >
              Update
            </Button>
          </div>
        </Page>
      </div>
    );
  }
}
