exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".buttons__relative-container-4zb{position:relative;width:fit-content}.buttons__abs-text-3nb{position:absolute;top:-18px;left:0;font-size:14px;white-space:nowrap}[data-icon=\"buttons:plan\"]{background-image:url(\"/assets/plan.svg\")}[data-icon=\"buttons:plan-disabled\"]{background-image:url(\"/assets/plan-disabled.svg\")}[data-icon=\"buttons:save\"]{background-image:url(\"/assets/save.svg\")}[data-icon=\"buttons:done\"]{background-image:url(\"/assets/done-checkmark.svg\")}[data-icon=\"buttons:edit\"]{background-image:url(\"/assets/edit-blue.svg\")}[data-icon=\"buttons:editWhite\"]{background-image:url(\"/assets/edit-white.svg\")}[data-icon=\"buttons:copy\"]{background-image:url(\"/assets/copy-documents-option.svg\")}[data-icon=\"buttons:approve\"]{background-image:url(\"/assets/approve.svg\")}[data-icon=\"buttons:decline\"]{background-image:url(\"/assets/decline.svg\")}[data-icon=\"buttons:trash\"]{background-image:url(\"/assets/trash-small.svg\")}[data-icon=\"buttons:refresh\"]{background-image:url(\"/assets/refresh.svg\")}", ""]);

// exports
exports.locals = {
	"relative-container": "buttons__relative-container-4zb",
	"relativeContainer": "buttons__relative-container-4zb",
	"abs-text": "buttons__abs-text-3nb",
	"absText": "buttons__abs-text-3nb"
};