import React from 'react';
import classNames from 'classnames';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';
import ComparisonValue from 'components/common/ComparisonValue';
import ChannelIcon from 'components/common/ChannelIcon';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';

import { getNickname } from 'components/utils/channels';
import { getIsGrowthDesired } from 'components/pages/analyze/utils/comparisonUtils';

import style from 'styles/settings/compare-enriched-metrics/compare-enriched-metrics.css';

const styles = style.locals || {};

function CompareMetricsSingleSide({
  isChannelsCompare, data, formatValue, metric,
}) {
  if (isChannelsCompare) {
    return (
      <>
        <div className={styles.name}>{data.name}</div>
        <div className={styles.channels}>
          {data.channels.map((channel) => (
            <div className={styles.channelRow}>
              <ChannelIcon channel={channel} />
              <EllipsisTooltip text={getNickname(channel)} />
            </div>
          ))}
        </div>
      </>
    );
  }

  const isGrowthDesired = getIsGrowthDesired({ metricName: metric });
  return (
    <>
      <div className={styles.name}>{data.name}</div>
      <div className={styles.value}>{formatValue(data.value)}</div>
      {data.growth ? (
        <ComparisonValue
          value={data.growth}
          isGrowthDesired={isGrowthDesired}
          className={styles.growth}
          isShowTrendArrow={false}
        />
      ) : null}
    </>
  );
}

function CompareMetricsBox({
  title,
  enrichedMetrics,
  crmMetrics,
  formatValue,
  tooltip,
  metric,
}) {
  useStyles([style]);

  return (
    <div className={styles.box}>
      <InfoMarker
        place="bottom"
        tooltipText={tooltip}
        containerClass={styles.infoMarker}
        TooltipProps={{ className: styles.tooltip }}
        overridePosition={({ left, top }) => ({ left: left + 144, top })}
      />
      <div className={styles.boxTitle}>
        {title}
      </div>
      <div className={classNames(styles.boxContent, enrichedMetrics.channels && styles.channelsView)}>
        <div className={styles.boxData}>
          <CompareMetricsSingleSide
            data={enrichedMetrics}
            isChannelsCompare={enrichedMetrics.channels}
            formatValue={formatValue}
            metric={metric}
          />
        </div>
        <div className={classNames(styles.boxData, styles.crmBoxData)}>
          <CompareMetricsSingleSide
            data={crmMetrics}
            isChannelsCompare={crmMetrics.channels}
            formatValue={formatValue}
          />
        </div>
      </div>
    </div>
  );
}

export default CompareMetricsBox;
