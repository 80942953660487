import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { uniqueId } from 'lodash';

import Component from 'components/Component';
import GoalIcon from 'components/common/GoalIcon';
import GoalProgress from 'components/pages/dashboard/GoalProgress';
import Tooltip from 'components/controls/Tooltip';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { mixColors } from 'components/utils/colors';
import { getGoalPacing, getGoalProgress, getTimeData } from 'components/utils/goals';
import { getDateFromUserTZ } from 'stores/analyze/timeUtils';
import { nonCumulativeMetricTypes, nonUpDirectionMetricTypes } from 'components/pages/settings/goals/logic/enums';

import style from 'styles/dashboard/goal.css';

export default class Goal extends Component {
  style = style;

  render() {
    const {
      goal,
      container,
    } = this.props;

    const {
      metricName,
      metricType,
      title,
      targetValue: planned,
      actualValue: actual,
      timeframe,
      type,
    } = goal;

    const formatDate = 'D MMM YY';
    const startDate = moment(timeframe.startDate);
    const isMilestone = type === 'milestone';
    const localGoalStartTime = moment(getDateFromUserTZ(timeframe.startDate));
    const localGoalEndTime = moment(getDateFromUserTZ(timeframe.endDate));
    const {
      isCompleted,
      isOngoing,
      leftToFinish,
    } = getTimeData(localGoalStartTime, localGoalEndTime);

    const isNotFuture = isOngoing || isCompleted;
    const isUp = !nonUpDirectionMetricTypes.includes(metricType);
    const progress = getGoalProgress({ metricType, planned, actual });
    const percent = Math.floor(progress * 100);
    const percentRate = Math.round(Math.min(progress, 1) * 100);
    const goalColor = mixColors(percentRate);

    const usePacingForGoal = !nonCumulativeMetricTypes.includes(metricType);
    const pacing = getGoalPacing({ timeframe, actual });
    const project = usePacingForGoal ? pacing : planned;

    return (
      <div className={classnames(this.classes.inner, container)}>
        {isMilestone && (
          <div className={this.classes.milestoneFlagWrapper}>
            <Tooltip
              tip="Milestone"
              id={`milestone-note-tip-${uniqueId()}`}
              TooltipProps={{ place: 'top' }}
            >
              <div className={this.classes.milestoneFlag} />
            </Tooltip>
          </div>
        )}
        <GoalProgress
          fillValue={progress}
          style={{ marginTop: 25 }}
          goalColor={goalColor}
        >
          <text x="50%" y="35%" textAnchor="middle">
            <tspan className={this.classes.current}>
              {formatIndicatorDisplay(metricName, actual, true, false) || 0}
            </tspan>
          </text>
          <text
            className={this.classes.target}
            x="95%"
            y="50%"
            dy="20px"
            textAnchor="middle"
            alignmentBaseline="text-after-edge"
          >
            {formatIndicatorDisplay(metricName, planned, false, false) || 0}
          </text>
        </GoalProgress>
        <div className={this.classes.center}>
          <div className={this.classes.title}>
            {title}
          </div>
        </div>
        <div className={this.classes.bottom}>
          {isNotFuture && (
            <GoalIcon
              project={project}
              target={planned}
              value={actual}
              isDirectionUp={isUp}
              usePacingForGoal={usePacingForGoal}
              indicator={metricName}
              containerClass={this.classes.iconNoMargin}
              isHistory={isCompleted}
              metricType={metricType}
            />
          )}
          <div className={this.classes.wrapper}>
            <div className={this.classes.progress}>
              <div
                className={this.classes.progressFill}
                style={{
                  width: percent < 100 ? `${percent}%` : '100%',
                  backgroundColor: goalColor,
                }}
              />
            </div>
            <div className={this.classes.descriptionRow}>
              <div className={this.classes.timeLeft}>
                {startDate.format(formatDate)}
              </div>
              <div className={classnames(this.classes.timeLeft, this.classes.timeLeftBold)}>
                {isCompleted ? 'Finished!' : `${leftToFinish} left`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
