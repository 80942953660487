exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".settings__frame-XT3{background-color:#fff;width:301px;padding-bottom:15px}.settings__frame-XT3:after{content:\"\";position:absolute;width:0;height:0;z-index:1;left:94%;top:10px;border:8px solid #fff;border-color:transparent transparent #fff #fff;transform-origin:0 0;transform:rotate(135deg);box-shadow:-7px 8px 0 0 #fff}.settings__title-1ys{font-size:24px;line-height:1.33;letter-spacing:-.2px;color:#707ea7;justify-content:center;padding-bottom:4px;padding-top:5px}.settings__sub-menu-19B,.settings__title-1ys{font-weight:600;font-style:normal;font-stretch:normal;display:flex}.settings__sub-menu-19B{padding:5px 0;font-size:16px;line-height:1.5;letter-spacing:normal;flex-direction:column}.settings__sub-title-2A9{color:#707ea7;text-decoration:none;padding:7px 20px;z-index:10}.settings__sub-title-2A9:hover{background:#f3f3f3}", ""]);

// exports
exports.locals = {
	"frame": "settings__frame-XT3",
	"frame": "settings__frame-XT3",
	"title": "settings__title-1ys",
	"title": "settings__title-1ys",
	"sub-menu": "settings__sub-menu-19B",
	"subMenu": "settings__sub-menu-19B",
	"sub-title": "settings__sub-title-2A9",
	"subTitle": "settings__sub-title-2A9"
};