import React from 'react';
import Component from 'components/Component';
import filterContainerStyle from 'styles/users/filters.css';
import CampaignsContextProvider from '../users/Filters/CampaignsRules/CampaignsContext';
import RulesGroup from '../users/Filters/CampaignsRules/RulesGroup';

export default class PreDefinedFiltersPlatform extends Component {
  style = filterContainerStyle;

  render() {
    const { activeFilter, filtersConfig, updateFilter } = this.props;
    const isPreDefineFilter = true;
    return (
      <CampaignsContextProvider values={{ activeFilter, filtersConfig, updateFilter, isPreDefineFilter }}>
        <RulesGroup />
      </CampaignsContextProvider>
    );
  }
}
