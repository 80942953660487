import React from 'react';
import {
  groupBy, isEmpty, set, sortBy, uniq, get, cloneDeep, compact,
} from 'lodash';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { compose } from 'components/utils/utils';
import { toast, ToastContainer, Slide } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Textfield from 'components/controls/Textfield';
import SaveButton from 'components/pages/profile/SaveButton';
import MappingRule from 'components/pages/settings/channels/tabs/common/MappingRule';
import ChannelsSelect from 'components/common/ChannelsSelect';
import Tooltip from 'components/controls/Tooltip';
import ChannelIcon from 'components/common/ChannelIcon';
import ConfirmPopup from 'components/ConfirmPopup';
import Dropdown from 'components/controls/Dropdown';
import ChannelItem from 'components/pages/settings/channels/tabs/ChannelItem';
import CreateNewChannelPopup from 'components/pages/settings/channels/tabs/CreateNewChannelPopup';
import RangeWithSteps from 'components/controls/RangeWithSteps';
import Loader from 'components/controls/Loader';
import servicesStore from 'stores/servicesStore';

import { areAllFieldsFilled, getRulesCountByTypes, getAllUpdatedMappingRulesObjects } from 'components/pages/settings/channels/tabs/logic/ChannelsTab';
import {
  getChannelRules,
  getAllMappingOptions,
  getLeadSourcesOptionsForMapping,
  getNewCondition,
  getMergedAttributionMappingRules,
} from 'components/utils/mappingRules';
import {
  getChannelsWithProps, getNickname as getChannelNickname, DIRECT_LABEL, DIRECT_CHANNEL, UNKNOWN_LABEL, archiveChannel,
} from 'components/utils/channels';
import { attributionWeightOptions, rangeWeightTooltipMessages } from 'components/pages/settings/channels/tabs/logic/enums';

import { Events } from 'trackers/analytics/enums';

import planStore from 'stores/plan/planStore';
import forecastStore from 'stores/forecastStore';

import platformsStyle from 'styles/indicators/platforms.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import style from 'styles/settings/channels/channels-tab.css';

const defaultDepartments = ['marketing', 'sales', 'other'];
const channelsTypeOptions = [
  { value: 'channel', label: 'Channel' },
  { value: 'content', label: 'Content' },
];
const channelsKindOptions = [
  { value: 'none', label: 'None' },
  { value: 'paid', label: 'Paid' },
  { value: 'organic', label: 'Organic' },
];

const MAP_KEYS = ['campaignsMapping', 'tasksMapping'];
const WIDTH = '215px';

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
    } = stores;
    const {
      attributionMappingRules,
      userAccount: {
        leadSourcesIdToLabelMap,
        customUtmsWhitelist,
      },
      updateAttributionMappingRulesRequest,
      isLoadingAttributionMappingRules,
      getAttributionMappingRules,
    } = userStore;
    return {
      attributionMappingRules,
      leadSourcesIdToLabelMap,
      customUtmsWhitelist,
      updateAttributionMappingRulesRequest,
      isLoadingAttributionMappingRules,
      getAttributionMappingRules,
    };
  }),
  observer
);

class ChannelsTab extends Component {
  static formatObject = (mapping) => {
    if (!mapping) {
      return {};
    }
    return mapping.reduce((acc, { rules = [] }) => {
      rules.forEach((rule) => {
        const { channel, conditions = [] } = rule;
        if (!(channel in acc)) {
          acc[channel] = [];
        }
        acc[channel] = [...acc[channel], ...conditions];
      }, {});
      return acc;
    }, {});
  };

  style = style;

  styles = [platformsStyle, checkboxStyle];

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      categoryItemIndexActive: [0],
      isForecastRequestIsNeeded: false,
      saveButtonDisabled: false,
      isPopupOpen: false,
      isDeletePopupOpen: false,
      isDeleteCostPopupOpen: false,
      isDeleteLoading: false,
      rulesToOverrideWeight: null,
      updatedMappingRules: [],
      newMappingRules: [],
      deletedRuleIds: [],
    };
  }

  async componentDidMount() {
    this.props.getAttributionMappingRules();
    injectStyle();
  }

  mappingByChannel = (channelKey, key) => {
    const mappingObj = ChannelsTab.formatObject(this.props.salesforceapi[key]);
    return mappingObj[channelKey] || [];
  };

  customMapping = (channelKey) => MAP_KEYS.flatMap((dataKey) => this.mappingByChannel(channelKey, dataKey));

  editChannel = async (fields) => {
    const { userAccount } = this.props;
    const body = {
      channelName: fields.channel,
      channel: {
        channelKind: fields.channelKind,
        channelType: fields.channelType,
        department: fields.department,
        nickname: fields.name,
        category: fields.category,
        erpCategory: fields.erpCategory,
        channelIconURL: fields.channelIconURL,
        performanceChannel: fields.performanceChannel,
        crmCampaignField: fields.crmCampaignField,
        isContinuous: fields.isContinuous,
        isInbound: fields.isInbound,
        weight: fields.weight,
      },
    };
    try {
      const response = await planStore.updateChannelsSchema({ body });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(`could not update channels schema: ${responseData}`);
      }

      const {
        channelKind, channelType, department, nickname, category, erpCategory,
        channelIconURL, performanceChannel, crmCampaignField, isContinuous,
        isInbound, isMandatory, isUnknownChannel, weight,
      } = responseData;

      const mergeAttributionMappingRules = getMergedAttributionMappingRules({
        attributionMappingRules: this.props.attributionMappingRules, deletedRuleIds: this.state.deletedRuleIds, updatedMappingRules: this.state.updatedMappingRules, newMappingRules: this.state.newMappingRules,
      });

      const channelRules = getChannelRules(mergeAttributionMappingRules, fields.channel);
      const rulesTotalCount = channelRules.length;
      const rulesCountByTypes = getRulesCountByTypes({ rules: channelRules });

      servicesStore.eventTracker.track({
        eventName: Events.channelMappingRulesWeights,
        properties: {
          nickname,
          category,
          rulesCount: rulesTotalCount,
          ...rulesCountByTypes,
        },
      });

      const weightLabel = attributionWeightOptions.find((option) => option.value === weight)?.label;
      servicesStore.eventTracker.track({
        eventName: Events.channelSaveChannelSettings,
        properties: {
          channelType: channelKind,
          activityType: channelType,
          department,
          nickname,
          category,
          erpCategory,
          channelIconURL,
          performanceChannel,
          crmCampaignField,
          isContinuous,
          weight: weightLabel,
          rulesCount: rulesTotalCount,
        },
      });

      const userChannelsSchema = { ...this.props.userChannelsSchema };

      set(userChannelsSchema, [fields.channel, 'nickname'], nickname);
      set(userChannelsSchema, [fields.channel, 'category'], category);
      set(userChannelsSchema, [fields.channel, 'isInbound'], isInbound);
      set(userChannelsSchema, [fields.channel, 'department'], department);
      set(userChannelsSchema, [fields.channel, 'channelType'], channelType);
      set(userChannelsSchema, [fields.channel, 'erpCategory'], erpCategory);
      set(userChannelsSchema, [fields.channel, 'channelKind'], channelKind);
      set(userChannelsSchema, [fields.channel, 'isMandatory'], isMandatory);
      set(userChannelsSchema, [fields.channel, 'isContinuous'], isContinuous);
      set(userChannelsSchema, [fields.channel, 'channelIconURL'], channelIconURL);
      set(userChannelsSchema, [fields.channel, 'isUnknownChannel'], isUnknownChannel);
      set(userChannelsSchema, [fields.channel, 'crmCampaignField'], crmCampaignField);
      set(userChannelsSchema, [fields.channel, 'performanceChannel'], performanceChannel);
      set(userChannelsSchema, [fields.channel, 'weight'], weight);

      this.props.updateState({ userChannelsSchema, unsaved: false }, async () => {
        await this.props.updateAttributionMappingRulesRequest({ newRules: this.state.newMappingRules, updatedRules: this.state.updatedMappingRules, deletedRuleIds: this.state.deletedRuleIds });
        this.setState({ updatedMappingRules: [], newMappingRules: [], deletedRuleIds: [] });
        if (this.state.isForecastRequestIsNeeded) {
          forecastStore.getExplainableForecastData();
        }
      });
    } catch (error) {
      servicesStore.logger.error('Error editing a channel', {
        error,
        UID: userAccount.UID,
      });
    }
  };

  onAdvancedSettingsHandler = () => {
    const { isAdvancedSettings, selectedChannel } = this.state;
    const updatedIsAdvancedSettings = !isAdvancedSettings;
    this.setState({ isAdvancedSettings: updatedIsAdvancedSettings });

    if (updatedIsAdvancedSettings) {
      const channelsWithProps = getChannelsWithProps();
      const selectedChannelProps = channelsWithProps[selectedChannel];
      servicesStore.eventTracker.track({
        eventName: Events.ChannelTabShowAdvancedSettings,
        properties: {
          nickname: selectedChannelProps.nickname,
          category: selectedChannelProps.category,
        },
      });
    }
  };

  async updateRule({
    conditionIndex, key, value, ruleId, weight,
  }) {
    const { attributionMappingRules } = this.props;
    const { updatedMappingRules, deletedRuleIds, newMappingRules } = this.state;

    const { newDeletedRuleIds, newUpdatedMappingRules, updatedNewMappingRules } = getAllUpdatedMappingRulesObjects({
      conditionIndex, key, value, ruleId, weight, attributionMappingRules, updatedMappingRules, deletedRuleIds, newMappingRules,
    });

    const mergeAttributionMappingRules = getMergedAttributionMappingRules({
      attributionMappingRules: this.props.attributionMappingRules, deletedRuleIds: newDeletedRuleIds, updatedMappingRules: newUpdatedMappingRules, newMappingRules: updatedNewMappingRules,
    });
    const saveButtonDisabled = !areAllFieldsFilled({ attributionMappingRules: mergeAttributionMappingRules });

    this.setState({
      saveButtonDisabled, updatedMappingRules: newUpdatedMappingRules, deletedRuleIds: newDeletedRuleIds, newMappingRules: updatedNewMappingRules,
    });
  }

  editCategory = (value) => {
    this.setState({
      categoryEdit: value,
    });
  };

  isCategoryOpen = (index) => this.state.categoryItemIndexActive.indexOf(index) > -1;

  onClickCategoryItem = (index, channels) => {
    const categoryItemIndexActive = this.state.categoryItemIndexActive;
    let activeIndexes = [...categoryItemIndexActive, index];
    let selectedChannel = '';
    if (this.state.categoryItemIndexActive.indexOf(index) > -1) {
      activeIndexes = activeIndexes.filter((e) => e !== index);
      this.setState({ selectedChannel });
    } else if (channels.length === 1) {
      selectedChannel = channels[0].channel;
      const channelsWithProps = getChannelsWithProps();
      this.onClickChannelItem(selectedChannel, channelsWithProps);
    }
    this.setState({ categoryItemIndexActive: activeIndexes });
  };

  onClickChannelItem = (channel, channelsWithProps) => {
    const selectedChannel = channel;
    this.setState({
      selectedChannel,
      channelEdit: getChannelNickname(selectedChannel),
      channelIconURL: channelsWithProps[selectedChannel].channelIconURL || '',
      categoryEdit: channelsWithProps[selectedChannel].category,
      departmentEdit: channelsWithProps[selectedChannel].department || 'marketing',
      channelsTypeEdit: channelsWithProps[selectedChannel].channelType,
      erpCategoryEdit: channelsWithProps[selectedChannel].erpCategory || 0,
      performanceChannel: channelsWithProps[selectedChannel].performanceChannel || '',
      crmCampaignField: channelsWithProps[selectedChannel].crmCampaignField || '',
      isContinuous: channelsWithProps[selectedChannel].isContinuous,
      isInbound: channelsWithProps[selectedChannel].isInbound,
      channelKind: channelsWithProps[selectedChannel].channelKind || 'none',
      weight: channelsWithProps[selectedChannel].weight,
      isAdvancedSettings: false,
    });
  };

  onSelectArchiveChannel = () => {
    this.setState({
      selectedChannel: archiveChannel,
      channelEdit: archiveChannel,
    });
  };

  getMappingRulesOptions = (isArchive = false) => {
    const { leadSourcesIdToLabelMap, customUtmsWhitelist } = this.props;
    const mappingOptions = getAllMappingOptions({ leadSourcesIdToLabelMap, customUtmsWhitelist });

    if (isArchive) {
      mappingOptions.unshift({ label: 'Time', value: 'startTime' });
    }

    return mappingOptions;
  };

  updateDeletedChannel({ selectedChannel }) {
    const channelSchema = { ...this.props.userChannelsSchema };
    set(channelSchema, [selectedChannel, 'isDeleted'], true);
    this.props.updateState({ userChannelsSchema: channelSchema, unsaved: false });
    this.toastMessage({ type: 'success', message: 'Channel deleted successfully. It will update in your Channels in the next few hours.' });

    const channelsWithProps = getChannelsWithProps();
    const selectedChannelProps = channelsWithProps[selectedChannel];
    servicesStore.eventTracker.track({
      eventName: Events.channelTabDeleteChannel,
      properties: {
        nickname: selectedChannelProps.nickname,
        category: selectedChannelProps.category,
      },
    });
  }

  async confirmDeletePopupCallback({ abortAction, selectedChannel }) {
    if (!abortAction) {
      this.setState({ isDeleteLoading: true });
      const costAndBudgetBody = { channel: selectedChannel };
      const costAndBudget = await planStore.getChannelBudgetAndCost({ body: costAndBudgetBody });

      const isValidCostAndBudget = costAndBudget
      && (costAndBudget.costAmount > 0 || costAndBudget.budgetAmount > 0);

      if (isValidCostAndBudget) {
        this.setState({ isDeleteCostPopupOpen: true });
      } else {
        const deleteBody = {
          channel: selectedChannel,
          hasCost: false,
        };
        await planStore.deleteChannel({ body: deleteBody });
        this.updateDeletedChannel({ selectedChannel });
        this.setState({ selectedChannel: null });
      }
    }
    this.setState({ isDeleteLoading: false, isDeletePopupOpen: false });
  }

  async confirmDeleteCostPopupCallback({ abortAction, selectedChannel }) {
    const body = {
      channel: selectedChannel,
      hasCost: true,
    };
    if (!abortAction) {
      this.setState({ isDeleteLoading: true });
      await planStore.deleteChannel({ body });
      this.updateDeletedChannel({ selectedChannel });
      this.setState({ selectedChannel: null });
    }
    this.setState({ isDeleteLoading: false, isDeleteCostPopupOpen: false });
  }

  async confirmOverrideWeightPopupCallback({ abortAction }) {
    const { weight, rulesToOverrideWeight } = this.state;
    if (abortAction) {
      const previousWeight = rulesToOverrideWeight.previousWeight;
      this.setState({ weight: previousWeight, rulesToOverrideWeight: null });
      return;
    }

    for (const rule of rulesToOverrideWeight.rules) {
      await this.updateRule({ weight, ruleId: rule.id });
    }
    this.setState({ rulesToOverrideWeight: null });
  }

  toastMessage({ type, message }) {
    const options = {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: message,
      style: { width: '320px', fontSize: '14px' },
    };
    if (type === 'error') {
      toast.error(message, options);
    }
    if (type === 'success') {
      toast.success(message, options);
    }
  }

  onChangeChannelWeight(value) {
    const {
      weight: parentWeight, selectedChannel, deletedRuleIds, updatedMappingRules, newMappingRules,
    } = this.state;
    this.setState({ weight: value });
    const mergeAttributionMappingRules = getMergedAttributionMappingRules({
      attributionMappingRules: this.props.attributionMappingRules, deletedRuleIds, updatedMappingRules, newMappingRules,
    });

    const channelRules = getChannelRules(mergeAttributionMappingRules, selectedChannel);
    const maxWeightValue = attributionWeightOptions[attributionWeightOptions.length - 1].value;

    const shouldOverrideNewWeight = maxWeightValue > value && parentWeight > value;
    if (shouldOverrideNewWeight) {
      const channelRulesWithHigherWeight = channelRules.filter((rule) => rule.weight > value);
      if (!isEmpty(channelRulesWithHigherWeight)) {
        this.setState({ rulesToOverrideWeight: { rules: channelRulesWithHigherWeight, previousWeight: parentWeight } });
      }
    }
  }

  onAddNewRule() {
    const { selectedChannel, weight } = this.state;

    let parentChannelWeight = weight;
    if (!parentChannelWeight) {
      const channelsWithProps = getChannelsWithProps() || {};
      parentChannelWeight = channelsWithProps[selectedChannel]?.weight || 1;
    }

    const newRule = {
      conditions: [getNewCondition()],
      channel: selectedChannel,
      weight: parentChannelWeight,
      id: uuidv4(),
    };

    this.setState((prevState) => ({ saveButtonDisabled: true, newMappingRules: prevState.newMappingRules.concat(newRule) }));
  }

  render() {
    if (this.props.isLoadingAttributionMappingRules) {
      return (
        <div className={this.classes.loader}>
          <Loader newStyle />
        </div>
      );
    }
    const {
      search, selectedCategory, isPopupOpen, isDeletePopupOpen, isDeleteCostPopupOpen,
    } = this.state;

    const { userAccount } = this.props;
    const searchLower = search.toLowerCase();
    const channelsWithProps = getChannelsWithProps();
    const channelsWithPropsWithoutArchiveChannel = cloneDeep(channelsWithProps);
    delete channelsWithPropsWithoutArchiveChannel[archiveChannel];

    const channelsSchemaPropsArray = Object.keys(channelsWithPropsWithoutArchiveChannel).map((channel) => ({
      channel,
      ...channelsWithProps[channel],
    })).filter((channel) => !channel.isDeleted);

    let filteredChannels = [...channelsSchemaPropsArray];
    if (search) {
      filteredChannels = filteredChannels.filter((channel) => {
        if (!channel.nickname) {
          servicesStore.logger.error('failed getting channel nickname on search', {
            channel,
            UID: userAccount.UID,
          });
          return false;
        }
        return channel.nickname.toLowerCase().indexOf(searchLower) !== -1;
      });
    }
    const channelsByCategory = groupBy(sortBy(filteredChannels, ['nickname']), 'category');

    const directCategoryChannels = channelsByCategory[DIRECT_LABEL];
    if (directCategoryChannels) {
      const directCategoryChannelsWithoutDirectChannel = directCategoryChannels.filter((item) => item.channel !== DIRECT_CHANNEL);
      if (directCategoryChannelsWithoutDirectChannel.length) {
        channelsByCategory[DIRECT_LABEL] = directCategoryChannelsWithoutDirectChannel;
      } else {
        delete channelsByCategory[DIRECT_LABEL];
      }
    }

    const unmappedCategoryChannels = channelsByCategory[UNKNOWN_LABEL];
    if (unmappedCategoryChannels) {
      const categoryChannelsWithoutUnmappedChannel = unmappedCategoryChannels.filter((item) => !item.isCreatedAutomatic);
      if (categoryChannelsWithoutUnmappedChannel.length) {
        channelsByCategory[UNKNOWN_LABEL] = categoryChannelsWithoutUnmappedChannel;
      } else {
        delete channelsByCategory[UNKNOWN_LABEL];
      }
    }

    const categories = uniq(Object.keys(channelsByCategory)).sort((a, b) => a.localeCompare(b));

    const {
      selectedChannel,
      channelEdit,
      channelIconURL,
      categoryEdit,
      departmentEdit,
      channelsTypeEdit,
      erpCategoryEdit,
      performanceChannel,
      crmCampaignField,
      isContinuous,
      isInbound,
      channelKind,
      isAdvancedSettings,
      weight,
      rulesToOverrideWeight,
      deletedRuleIds,
      updatedMappingRules,
      newMappingRules,
    } = this.state;

    const { salesforceapi, pageHeight } = this.props;

    const mergeAttributionMappingRules = getMergedAttributionMappingRules({
      attributionMappingRules: this.props.attributionMappingRules, deletedRuleIds, updatedMappingRules, newMappingRules,
    });

    const channelRules = getChannelRules(mergeAttributionMappingRules, selectedChannel);

    const sortedCategories = uniq(channelsSchemaPropsArray.map((c) => c.category))
      .sort((a, b) => a.localeCompare(b));
    if (sortedCategories.indexOf(categoryEdit) === -1 && categoryEdit) {
      sortedCategories.push(categoryEdit);
    }

    const allDepartmentsFromSchema = channelsSchemaPropsArray.map((c) => c.department);
    const allDepartmentOptions = [
      ...allDepartmentsFromSchema,
      ...defaultDepartments,
      departmentEdit,
    ];
    const distinctDepartments = uniq(compact(allDepartmentOptions));
    const sortedDepartments = distinctDepartments.sort((a, b) => a.localeCompare(b));

    const noChannelSelected = !selectedChannel;
    const containerLeftHeight = `${pageHeight - 294}px`;
    const containerRightHeight = noChannelSelected ? `${pageHeight - 244}px` : `${pageHeight - 320}px`;

    const mapping = salesforceapi && this.customMapping(selectedChannel);
    const isArchiveChannel = selectedChannel === archiveChannel;

    return (
      <div className={this.classes.mappingContainer}>
        {isPopupOpen ? (
          <CreateNewChannelPopup
            actionText="Add channel"
            title="Add a New Channel"
            categories={categories}
            sortedCategories={sortedCategories}
            channelsByCategory={channelsByCategory}
            getChannelsWithProps={getChannelsWithProps}
            updateState={this.props.updateState}
            onClickChannelItem={this.onClickChannelItem}
            onClickCategoryItem={this.onClickCategoryItem}
            userChannelsSchema={this.props.userChannelsSchema}
            onClosePopup={() => this.setState({ isPopupOpen: false })}
          />
        ) : null}
        <ConfirmPopup
          isTitleBold
          confirmBtn="Delete"
          hidden={!isDeletePopupOpen}
          title={`Are you sure you want to delete ${channelEdit}?`}
          callback={(abortAction) => this.confirmDeletePopupCallback({ abortAction, selectedChannel })}
          description="Deleting this channel will remove all associated mapping rules. This action cannot be undone."
          loading={this.state.isDeleteLoading}
        />
        <ConfirmPopup
          isTitleBold
          confirmBtn="Delete"
          hidden={!isDeleteCostPopupOpen}
          title="This channel have cost/campaigns existed"
          callback={(abortAction) => this.confirmDeleteCostPopupCallback({ abortAction, selectedChannel })}
          loading={this.state.isDeleteLoading}
        />
        <ConfirmPopup
          confirmBtn="I understand"
          hidden={!rulesToOverrideWeight}
          title="Changing the channel’s weight will override existing weight settings"
          callback={(abortAction) => this.confirmOverrideWeightPopupCallback({ abortAction })}
          confirmButtontype="primaryBlue"
          isShowIcon={false}
        />
        <ToastContainer
          style={{ width: '400px', fontSize: '12px' }}
          position="bottom-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          transition={Slide}
        />
        <div className={this.classes.mapChannels}>
          <div className={this.classes.mappingHeader}>
            <div className={this.classes.search}>
              <div className={this.classes.searchIcon} />
              <input
                value={search}
                onChange={(e) => {
                  this.setState({ search: e.target.value, selectedCategory: null, selectedChannel: null });
                }}
                className={this.classes.searchInput}
                placeholder="Search..."
              />
              {this.state.search.length > 0
                && (
                  <div
                    className={this.classes.searchClear}
                    onClick={() => { this.setState({ search: '' }); }}
                  />
                )}
            </div>
            <div className={this.classes.mappingHeaderGradient} />
            <Button type="secondaryBlue" onClick={() => this.setState({ isPopupOpen: true })}>+ Add channel</Button>
          </div>
          <div className={this.classes.categoriesMenu} style={{ height: containerLeftHeight }}>
            {categories.map((category, index) => (
              <ChannelItem
                key={category}
                channelsByCategory={channelsByCategory}
                selectedCategory={category}
                selectedChannel={selectedChannel}
                onClickCategoryItem={() => this.onClickCategoryItem(index, channelsByCategory[category])}
                isActiveCategory={!!(this.isCategoryOpen(index) || search)}
                setChannelHandler={(channel) => { this.onClickChannelItem(channel, channelsWithProps); }}
              />
            ))}
            <div className={this.classes.archiveChannel} onClick={this.onSelectArchiveChannel}>
              <div className={this.classes.categoryBox}>
                <ChannelIcon
                  channel={archiveChannel}
                  className={this.classes.channelIcon}
                />
                {' '}
                Archive
              </div>
            </div>
          </div>
        </div>

        <div className={this.classes.mapChannelPreviewWrapper}>
          <div
            className={classnames(this.classes.mapChannelPreview, !selectedChannel && this.classes.noChannelSelected)}
            style={{ maxHeight: containerRightHeight }}
          >
            {noChannelSelected && <div>Choose a Channel</div>}
            {selectedChannel && (
            <div className={this.classes.inner}>
              <div className={this.classes.category}>
                {selectedCategory}
              </div>
              <div className={this.classes.channel}>
                <ChannelIcon
                  channel={selectedChannel}
                  className={this.classes.channelIcon}
                />
                {isArchiveChannel ? (
                  <div className={this.classes.archiveChannelTitle}>
                    {archiveChannel}
                  </div>
                ) : (
                  <Tooltip
                    id="channelEdit"
                    tip="Rename"
                  >
                    <Textfield
                      value={channelEdit}
                      className={this.classes.channelTitle}
                      onChange={(e) => {
                        this.setState({ channelEdit: e.target.value });
                      }}
                    />
                  </Tooltip>
                )}
              </div>

              <div className={this.classes.titleText}>
                Settings
                <div className={this.classes.advancedOptions} onClick={this.onAdvancedSettingsHandler}>
                  {isAdvancedSettings ? 'Hide' : 'Show'}
                  {' Advanced Options'}
                </div>
              </div>
              {isArchiveChannel ? (
                <div className={this.classes.whiteBox}>
                  <div className={this.classes.flexRow}>
                    <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                      Activity type
                    </div>
                    <Dropdown
                      options={[{ label: 'Hidden touchpoints', value: 'Hidden touchpoints' }]}
                      selectedKey="Hidden touchpoints"
                      className={this.classes.fieldHalf}
                      disabled
                    />
                  </div>
                </div>
              ) : (
                <div className={this.classes.whiteBox}>
                  <div className={this.classes.flexRow}>
                    <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                      Department
                    </div>
                    <Dropdown
                      options={sortedDepartments.map((value) => ({ value, label: value }))}
                      selectedKey={departmentEdit}
                      onChange={(e) => {
                        this.setState({ departmentEdit: e && e.value });
                      }}
                      className={this.classes.fieldHalf}
                      isSearchable
                      allowCreateNewOption
                    />
                  </div>
                  <div className={this.classes.flexRow}>
                    <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                      Activity type
                    </div>
                    <Dropdown
                      options={channelsTypeOptions}
                      selectedKey={channelsTypeEdit}
                      onChange={(e) => {
                        this.setState({ channelsTypeEdit: e.value });
                      }}
                      className={this.classes.fieldHalf}
                      isSearchable
                    />
                  </div>
                  <div className={this.classes.flexRow}>
                    <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                      Channel weight
                    </div>
                    <RangeWithSteps
                      onChange={(value) => this.onChangeChannelWeight(value)}
                      rangeWidth="50%"
                      options={attributionWeightOptions}
                      value={weight}
                      id="channelWeight"
                    />
                  </div>
                  {isAdvancedSettings ? (
                    <>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          Category
                        </div>
                        <Dropdown
                          options={sortedCategories.map((value) => ({ value, label: value }))}
                          selectedKey={categoryEdit}
                          onChange={(e) => {
                            if (e && e.value) {
                              this.editCategory(e.value);
                            }
                          }}
                          onNewOptionClick={(e) => {
                            if (e && e.value) {
                              this.editCategory(e.value);
                            }
                          }}
                          promptTextCreator={(value) => `Add ${value} as a category`}
                          allowCreateNewOption
                          className={this.classes.fieldHalf}
                          isSearchable
                        />
                      </div>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          Channel Type
                        </div>
                        <Dropdown
                          selectedKey={channelKind}
                          options={channelsKindOptions}
                          onChange={(e) => {
                            this.setState({
                              channelKind: get(e, 'value', ''),
                              isForecastRequestIsNeeded: true,
                            });
                          }}
                          className={this.classes.fieldHalf}
                          isSearchable
                          isClearable
                        />
                      </div>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          <Tooltip
                            id="cont-invst"
                            tip={'What\'s the type of investments in that channel? Is it one-time payments (i.e. tradeshows) or continuous payments (i.e. Google Ads)?'}
                          >
                            Continuous investments?
                          </Tooltip>
                        </div>
                        <Dropdown
                          className={this.classes.fieldHalf}
                          selectedKey={isContinuous}
                          options={[{ label: 'yes', value: true }, { label: 'no', value: false }]}
                          onChange={(e) => {
                            this.setState({ isContinuous: e.value });
                          }}
                          isSearchable
                        />
                      </div>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          ERP category
                        </div>
                        <Textfield
                          className={this.classes.fieldHalf}
                          value={erpCategoryEdit}
                          style={{ width: WIDTH }}
                          onChange={(e) => {
                            this.setState({ erpCategoryEdit: e.target.value });
                          }}
                        />
                      </div>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          Performance Analysis
                        </div>
                        <ChannelsSelect
                          className={this.classes.fieldHalf}
                          selected={performanceChannel}
                          clearable
                          withOtherChannels
                          isNonAnalyzeChannel
                          onChange={(e) => {
                            this.setState({ performanceChannel: get(e, 'value', '') });
                          }}
                        />
                      </div>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          CRM Campaign Field
                        </div>
                        <Dropdown
                          className={this.classes.fieldHalf}
                          selectedKey={crmCampaignField}
                          isClearable
                          isSearchable
                          options={getLeadSourcesOptionsForMapping({ leadSourcesIdToLabelMap: userAccount.leadSourcesIdToLabelMap })}
                          onChange={(e) => {
                            this.setState({ crmCampaignField: get(e, 'value', '') });
                          }}
                        />
                      </div>
                      <div className={this.classes.flexRow}>
                        <div className={classnames(this.classes.fieldText, this.classes.fieldHalf)}>
                          Channel icon URL
                        </div>
                        <Textfield
                          className={this.classes.fieldHalf}
                          value={channelIconURL}
                          style={{ width: WIDTH }}
                          onChange={(e) => this.setState({ channelIconURL: e.target.value })}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              )}

              <div className={this.classes.titleText}>Rules</div>
              <div className={this.classes.whiteBox}>
                {channelRules.length ? (
                  <div className={this.classes.mappingHeader}>
                    <div>Rules</div>
                    <div className={this.classes.sortByRuleWeight}>
                      Attribution weight
                    </div>
                  </div>
                ) : null}
                {!isEmpty(mapping) && mapping.map(({ value, param, operation }, conditionIndex) => {
                  const conditionIndexKey = `mapping-${conditionIndex}`;
                  return (
                    <>
                      <MappingRule
                        key={`MappingRule-${conditionIndexKey}`}
                        paramsOptions={[{ value: param, label: param }]}
                        value={value}
                        param={param}
                        operation={operation}
                        disabled
                      />
                      <div className={this.classes.mappingRuleLine} hidden={isEmpty(channelRules)} />
                    </>
                  );
                })}

                {channelRules.map((rule, index) => {
                  const channelRulesIndexKey = `channelRules-${index}`;
                  return (
                    <div className={this.classes.channelRulesMap} key={channelRulesIndexKey}>
                      <div className={this.classes.ruleGroupWrapper}>
                        <div className={this.classes.ruleGroup}>
                          {rule.conditions.map((condition, conditionIndex, row) => {
                            const conditionIndexKey = `rule-conditions-${conditionIndex}`;
                            return (
                              <MappingRule
                                key={`MappingRule-${rule.index}-${conditionIndexKey}`}
                                paramsOptions={this.getMappingRulesOptions(isArchiveChannel)}
                                updateValue={(e) => this.updateRule({
                                  conditionIndex, key: 'value', value: e.target.value, ruleId: rule.id,
                                })}
                                updateParam={(e) => this.updateRule({
                                  conditionIndex, key: 'param', value: e.value, ruleId: rule.id,
                                })}
                                updateOperation={(e) => this.updateRule({
                                  conditionIndex, key: 'operation', value: e.value, ruleId: rule.id,
                                })}
                                value={condition.value}
                                param={condition.param}
                                operation={condition.operation}
                                handleAdd={() => this.updateRule({
                                  conditionIndex: conditionIndex + 1, ruleId: rule.id,
                                })}
                                onAddRuleGroup={() => this.onAddNewRule()}
                                handleDelete={() => this.updateRule({
                                  conditionIndex, ruleId: rule.id,
                                })}
                                isLastAddButton={row.length - 1 !== conditionIndex}
                                isConditionTypeAsDropdown
                              />
                            );
                          })}
                        </div>
                        <div className={this.classes.ruleWeight}>
                          <RangeWithSteps
                            onChange={(value) => this.updateRule({
                              weight: value, ruleId: rule.id,
                            })}
                            options={attributionWeightOptions}
                            value={rule.weight}
                            disabledMaxValue={weight}
                            id={`ruleWeight-${rule.index}`}
                            tooltip={rangeWeightTooltipMessages}
                          />
                        </div>
                      </div>
                      {index !== channelRules.length - 1 ? (
                        <div className={this.classes.ruleGroupDivider}>
                          <span className={this.classes.dividerText}>
                            OR
                          </span>
                        </div>
                      ) : null}
                    </div>
                  );
                })}

                {channelRules.length === 0 ? (
                  <Button
                    className={this.classes.addNewRule}
                    type="secondaryWhite"
                    onClick={() => this.onAddNewRule()}
                  >
                    Add New rule
                  </Button>
                ) : null}

              </div>
            </div>
            )}
          </div>
          {selectedChannel ? (
            <div className={this.classes.saveSection}>
              <div className={this.classes.deleteChannel} onClick={() => { this.setState({ isDeletePopupOpen: true }); }} />
              <SaveButton
                style={{ padding: '0 16px', width: 'fit-content' }}
                onClick={() => {
                  this.setState({ saveFail: false, saveSuccess: false });
                  this.setState({ saveSuccess: true }, () => {
                    setTimeout(() => {
                      this.setState({ saveSuccess: false });
                    }, 2000);
                  });
                  this.editChannel({
                    name: channelEdit,
                    channelIconURL,
                    category: categoryEdit,
                    channel: selectedChannel,
                    department: departmentEdit,
                    channelType: channelsTypeEdit,
                    erpCategory: erpCategoryEdit,
                    performanceChannel,
                    crmCampaignField,
                    isContinuous,
                    isInbound,
                    channelKind,
                    weight,
                  });
                }}
                success={this.state.saveSuccess}
                fail={this.state.saveFail}
                disabled={this.state.saveButtonDisabled}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(ChannelsTab));
