exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".collapsable-split-view__splitViewContainer-2gI{display:flex;height:100%;gap:24px}.collapsable-split-view__pane-27O{width:100%;position:relative;display:flex;flex:1 1 0;transition:flex-basis .3s ease}.collapsable-split-view__fullPane-2R7{border-radius:12px;background:var(--colorsBackgroundsBackgroundWhite);box-shadow:var(--shadowsMedium);padding:24px}.collapsable-split-view__paneContent-3B9{width:100%;display:flex}.collapsable-split-view__rightPanel-32N.collapsable-split-view__expanded-1ZE{flex:0 0 400px;position:relative;flex-direction:column;max-width:400px;box-sizing:border-box}.collapsable-split-view__rightPanel-32N.collapsable-split-view__expanded-1ZE .collapsable-split-view__expandButton-1zU{transform:rotate(180deg);background:var(--colorsBackgroundsBackgroundWhite)}.collapsable-split-view__rightPanel-32N.collapsable-split-view__expanded-1ZE .collapsable-split-view__expandButton-1zU:after{background:var(--colorsGeneralBlue)}.collapsable-split-view__rightPanel-32N.collapsable-split-view__collapsed-1pf{flex:0 0 40px;padding:0}.collapsable-split-view__rightPanel-32N.collapsable-split-view__collapsed-1pf .collapsable-split-view__paneContent-3B9{display:none}.collapsable-split-view__expandButton-1zU{background:var(--colorsGeneralBlue);border-radius:50%;height:32px;width:32px;position:absolute;left:-16px;top:calc(50% - 16px);cursor:pointer;transition:all .3s ease-in;z-index:2}.collapsable-split-view__expandButton-1zU:after{content:\"\";background:var(--colorsBackgroundsBackgroundWhite);-webkit-mask:url(/assets/analyze-icons/expandIcon.svg) center center no-repeat;mask:url(/assets/analyze-icons/expandIcon.svg) center center no-repeat;height:32px;width:32px;display:inline-block}@media screen and (max-width:1024px){.collapsable-split-view__rightPanel-32N.collapsable-split-view__expanded-1ZE{max-width:300px;flex:0 0 300px}}", ""]);

// exports
exports.locals = {
	"splitViewContainer": "collapsable-split-view__splitViewContainer-2gI",
	"splitViewContainer": "collapsable-split-view__splitViewContainer-2gI",
	"pane": "collapsable-split-view__pane-27O",
	"pane": "collapsable-split-view__pane-27O",
	"fullPane": "collapsable-split-view__fullPane-2R7 collapsable-split-view__pane-27O",
	"fullPane": "collapsable-split-view__fullPane-2R7 collapsable-split-view__pane-27O",
	"paneContent": "collapsable-split-view__paneContent-3B9",
	"paneContent": "collapsable-split-view__paneContent-3B9",
	"rightPanel": "collapsable-split-view__rightPanel-32N",
	"rightPanel": "collapsable-split-view__rightPanel-32N",
	"expanded": "collapsable-split-view__expanded-1ZE",
	"expanded": "collapsable-split-view__expanded-1ZE",
	"expandButton": "collapsable-split-view__expandButton-1zU",
	"expandButton": "collapsable-split-view__expandButton-1zU",
	"collapsed": "collapsable-split-view__collapsed-1pf",
	"collapsed": "collapsable-split-view__collapsed-1pf"
};