exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".with-more-button__wrapper-2Ga{position:relative}.with-more-button__more-button-dFu{position:absolute;top:1px;right:5px;z-index:2}.with-more-button__popup-3P9{position:absolute;top:28px;right:80px;width:163px;min-height:0!important;box-sizing:border-box;z-index:100}.with-more-button__popup-button-3Rd{height:40px;padding-left:13px;display:flex;flex-direction:column;justify-content:center;font-size:13px;font-weight:500;color:#182033}.with-more-button__popup-button-3Rd:hover{background-color:#f5f6fa;border-radius:4px;color:#4d91fc;cursor:pointer}.with-more-button__loader-39s{background-color:rgba(132,144,188,.2);bottom:0;left:0;position:absolute;right:0;top:0;z-index:20;display:flex;align-items:center;justify-content:center;border-radius:12px}.with-more-button__moreButtonAttributionTable-2sr{top:29px}", ""]);

// exports
exports.locals = {
	"wrapper": "with-more-button__wrapper-2Ga",
	"wrapper": "with-more-button__wrapper-2Ga",
	"more-button": "with-more-button__more-button-dFu",
	"moreButton": "with-more-button__more-button-dFu",
	"popup": "with-more-button__popup-3P9",
	"popup": "with-more-button__popup-3P9",
	"popup-button": "with-more-button__popup-button-3Rd",
	"popupButton": "with-more-button__popup-button-3Rd",
	"loader": "with-more-button__loader-39s",
	"loader": "with-more-button__loader-39s",
	"moreButtonAttributionTable": "with-more-button__moreButtonAttributionTable-2sr",
	"moreButtonAttributionTable": "with-more-button__moreButtonAttributionTable-2sr"
};