import { isNil } from 'lodash';

import userStore from 'stores/userStore';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { formatToFloatWithoutZeros } from 'components/utils/logic/budget';

export function getUpliftData({
  totalAmountScenario, totalAmountBaseline, scenarioLabel, kpiFocus, isBaseline,
}) {
  if (isBaseline || isNil(totalAmountScenario) || isNil(totalAmountBaseline)) {
    return {};
  }
  const upliftAmount = (((totalAmountScenario - totalAmountBaseline) / totalAmountBaseline) * 100);
  const upliftAmountRounded = formatToFloatWithoutZeros({ number: upliftAmount, numberOfDigitsAfterPoint: 2 });
  const isDesired = upliftAmountRounded > 0;
  const difference = Math.abs(totalAmountScenario - totalAmountBaseline);
  const tooltip = `${scenarioLabel} ${isDesired ? 'increases' : 'decreases'} your ${userStore.getMetricNickname({ metric: kpiFocus })} by ${formatIndicatorDisplay(kpiFocus, difference, true)} (a ${upliftAmountRounded}% uplift vs. baseline).`;

  return { value: upliftAmountRounded, isDesired, tooltip };
}
