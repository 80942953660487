export function parseIndicatorsFromHistoricalPerformanceData({ data = {} }) {
  const historicalPerformanceData = {};
  for (const [key, value] of Object.entries(data)) {
    historicalPerformanceData[key] = value.total?.value;
  }
  return historicalPerformanceData;
}

export function getIndicatorGrowths({ data = {} }) {
  return Object.entries(data).reduce((result, [key, value]) => ({
    ...result,
    [key]: value.total?.growth,
  }), {});
}
