exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".infoCard__cardWrapper-jxw{display:flex;padding:20px;flex-direction:column;gap:16px;border-radius:12px;background:var(--white);border:1px solid transparent;box-shadow:0 4px 12px 0 #e6e8f0}.infoCard__cardWrapperClickable-1g3:hover{box-shadow:0 4px 23px 0 #cdd0db;cursor:pointer}.infoCard__cardWrapperClickable-1g3:active{border:1px solid var(--blue);box-shadow:0 4px 23px 0 #cdd0db}.infoCard__header-__G{display:flex;flex-direction:row}.infoCard__actionButtons-1Xq{margin-left:auto;display:flex;gap:8px}.infoCard__icon-30Q{width:60px;height:60px}.infoCard__bodyWrapper-2lc{display:flex;flex-direction:column;gap:8px}.infoCard__title-3r0{align-self:stretch;color:var(--black);font-size:16px;font-weight:600;line-height:20px}.infoCard__subTitle-1R6{align-self:stretch;color:var(--grayText);font-size:14px;font-weight:400;line-height:20px}.infoCard__lockIcon-1d-{width:16px;height:22px;background:url(" + require("assets/lockIcon.svg") + ") no-repeat 50%}", ""]);

// exports
exports.locals = {
	"cardWrapper": "infoCard__cardWrapper-jxw",
	"cardWrapper": "infoCard__cardWrapper-jxw",
	"cardWrapperClickable": "infoCard__cardWrapperClickable-1g3",
	"cardWrapperClickable": "infoCard__cardWrapperClickable-1g3",
	"header": "infoCard__header-__G",
	"header": "infoCard__header-__G",
	"actionButtons": "infoCard__actionButtons-1Xq",
	"actionButtons": "infoCard__actionButtons-1Xq",
	"icon": "infoCard__icon-30Q",
	"icon": "infoCard__icon-30Q",
	"bodyWrapper": "infoCard__bodyWrapper-2lc",
	"bodyWrapper": "infoCard__bodyWrapper-2lc",
	"title": "infoCard__title-3r0",
	"title": "infoCard__title-3r0",
	"subTitle": "infoCard__subTitle-1R6",
	"subTitle": "infoCard__subTitle-1R6",
	"lockIcon": "infoCard__lockIcon-1d-",
	"lockIcon": "infoCard__lockIcon-1d-"
};