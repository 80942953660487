export const maxItemsInChartTooltip = 12;

export const chartOthersIndexColor = 10;

export const othersKey = 'Others';

export const itemsNumberForSingleMetric = 2;

export const contentTypeSegmentValue = 'content_channel';
export const contentTitleSegmentValue = 'title';
export const contentURLSegmentValue = 'url';
export const contentPathSegmentValue = 'path';

export const defaultSupportedUtms = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'];

export const impactChartTypes = {
  bar: 'bar',
  line: 'line',
};

export const unSupportedMetricForImpactBy = [
  'LTV',
  'CAC',
  'churnRate',
  'marketingAttributedRevenue',
  'marketingPipelineROI',
  'marketingROI',
  'marketingAttributedPipeline',
  'totalCost',
  'webVisits',
  'pageViews',
];

export const columnTypesForExport = {
  frequency: 'frequency',
  segment: 'segment',
  metric: 'metric',
};
