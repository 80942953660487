import { generateMetricName } from 'widgetDefinitions/utils';

import base from './base';

function getWidgetConfigurationForQuestionResult({ widgetConfig }) {
  if (!widgetConfig) {
    return widgetConfig;
  }
  const baseConfig = base.getWidgetConfigurationForQuestionResult({ widgetConfig });

  if (baseConfig.indicator) {
    baseConfig.indicator = generateMetricName({ metricName: baseConfig.indicator });
  }

  return baseConfig;
}

export default {
  ...base,
  getWidgetConfigurationForQuestionResult,
};
