export const tabsPaths = {
  aiPrompts: '/insights/ai-prompts',
  campaigns: '/analyze/campaigns',
  whatIf: '/analyze/what-if',
  optimalJourney: '/analyze/optimal-journey',
};

export const tabsToFeatureFlags = {
  [tabsPaths.aiPrompts]: 'aiPromptTab',
  [tabsPaths.campaigns]: 'headerCampaignsTab',
  [tabsPaths.whatIf]: 'whatIfAnalyzeTab',
  [tabsPaths.optimalJourney]: 'optimalJourneysAnalyzeTab',
};
