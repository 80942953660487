import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';

import { guideContentData } from 'components/pages/onboarding/enums';

import style from 'styles/getting-started/getting-started.css';

const styles = style.locals || {};

function GuideProgressBox({
  guideId,
  isCompleted,
  onClick,
}) {
  useStyles([style]);

  const guideData = guideContentData[guideId];

  if (!guideData) {
    return null;
  }

  return (
    <Tooltip
      tip="Coming soon"
      id={`${guideId}-disabled-tooltip`}
      hidden={!guideData.isDisabled}
    >
      <div
        className={classnames(styles.guideBox, guideData.isDisabled && styles.guideBoxDisabled)}
        id={guideId}
        data-testid={guideData.isDisabled ? `disabled-${guideId}` : guideId}
        onClick={onClick}
      >
        <div className={styles.guideIcon} data-icon={guideData.icon} data-testid="guideIcon" />
        <div>
          <div className={styles.guideTitle}>
            {guideData.title}
          </div>
          <div className={styles.guideSubTitle}>
            {guideData.subTitle}
          </div>
        </div>
        <div
          className={classnames(styles.guideProgress, isCompleted && styles.guideProgressComplete)}
          data-testid={isCompleted ? 'guideProgressComplete' : 'guideProgress'}
        />
      </div>
    </Tooltip>
  );
}

export default GuideProgressBox;
