exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".salesforce-automatic-popup__inner-3Pa{overflow:visible}.salesforce-automatic-popup__title-3k4{color:var(--pacificBlue);font-size:32px;font-weight:600;line-height:44px;letter-spacing:-.32px;text-transform:capitalize}.salesforce-automatic-popup__message-1Ue{color:var(--black);font-size:16px;padding-bottom:24px}.salesforce-automatic-popup__textField-1Qa{padding-bottom:24px}.salesforce-automatic-popup__container-7La{display:flex;flex-direction:column;justify-content:center;padding-left:4px;padding-bottom:12px}.salesforce-automatic-popup__content-JBn{padding:35px 25px;margin:0;position:relative}.salesforce-automatic-popup__arrow-3HJ{background:url(/assets/right-arrow-outline.svg) 50% no-repeat;background-size:contain;width:92px;height:35px;position:absolute;top:0;right:-80px}.salesforce-automatic-popup__label-tNF{margin:6px 0 12px!important;width:190px!important;white-space:pre-line;text-transform:capitalize;height:30px!important}.salesforce-automatic-popup__owners-label-1NW{margin-top:48px}.salesforce-automatic-popup__button-2BP{width:10px}.salesforce-automatic-popup__or-button-3Dz{width:5px;margin-top:15px}.salesforce-automatic-popup__col-center-32P{max-width:600px;position:absolute;flex-grow:1;margin-left:45%}.salesforce-automatic-popup__col-right-6Go{margin-left:59%;flex-shrink:0;position:absolute}.salesforce-automatic-popup__meta-error-3Qp{outline:1px solid red}", ""]);

// exports
exports.locals = {
	"inner": "salesforce-automatic-popup__inner-3Pa",
	"inner": "salesforce-automatic-popup__inner-3Pa",
	"title": "salesforce-automatic-popup__title-3k4",
	"title": "salesforce-automatic-popup__title-3k4",
	"message": "salesforce-automatic-popup__message-1Ue",
	"message": "salesforce-automatic-popup__message-1Ue",
	"textField": "salesforce-automatic-popup__textField-1Qa",
	"textField": "salesforce-automatic-popup__textField-1Qa",
	"container": "salesforce-automatic-popup__container-7La",
	"container": "salesforce-automatic-popup__container-7La",
	"content": "salesforce-automatic-popup__content-JBn",
	"content": "salesforce-automatic-popup__content-JBn",
	"arrow": "salesforce-automatic-popup__arrow-3HJ",
	"arrow": "salesforce-automatic-popup__arrow-3HJ",
	"label": "salesforce-automatic-popup__label-tNF",
	"label": "salesforce-automatic-popup__label-tNF",
	"owners-label": "salesforce-automatic-popup__owners-label-1NW",
	"ownersLabel": "salesforce-automatic-popup__owners-label-1NW",
	"button": "salesforce-automatic-popup__button-2BP",
	"button": "salesforce-automatic-popup__button-2BP",
	"or-button": "salesforce-automatic-popup__or-button-3Dz",
	"orButton": "salesforce-automatic-popup__or-button-3Dz",
	"col-center": "salesforce-automatic-popup__col-center-32P",
	"colCenter": "salesforce-automatic-popup__col-center-32P",
	"col-right": "salesforce-automatic-popup__col-right-6Go",
	"colRight": "salesforce-automatic-popup__col-right-6Go",
	"meta-error": "salesforce-automatic-popup__meta-error-3Qp",
	"metaError": "salesforce-automatic-popup__meta-error-3Qp"
};