import Component from 'components/Component';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import style from 'styles/onboarding/onboarding.css';
import salesForceStyle from 'styles/indicators/salesforce-automatic-popup.css';

export const blobToBase64 = async (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => {
    const dataUrl = reader.result;
    const [, base64] = dataUrl.split(',');
    resolve(base64);
  };
  reader.readAsDataURL(blob);
});

class DriftPopupContainer extends Component {
  style = style;

  styles = [salesForceStyle];

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileUploadError: false,
      fileUploadSuccessfully: false,
      isEmptyFileToUpload: false,
      errors: {},
    };
  }

  afterAuthStep = (code) => {
    if (!code) {
      return Promise.resolve({});
    }
    return serverCommunication.serverRequest(
      'POST',
      this.props.apiEndpoint,
      code && JSON.stringify({ code }),
      userStore.userMonthPlan.region
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status == 401) {
          history.push('/');
        } else {
          throw new Error('Error getting Drift data');
        }
      });
  };

  doneFirstStep = () => () => {
    const { file } = this.state;
    if (file) {
      this.setState({
        isEmptyFileToUpload: false,
        fileUploadSuccessfully: false,
        fileUploadError: false,
      });
      this.props.loadingStarted();
      blobToBase64(file)
        .then(this.uploadFile)
        .then(() => {
          this.setState({
            file: null,
            fileUploadSuccessfully: true,
          });
        })
        .catch((error) => {
          servicesStore.logger.error('failed to load Drift CSV', { error });
          this.props.loadingFinished();
          this.setState({
            fileUploadError: true,
          });
        })
        .then(this.props.loadingFinished);
    } else {
      this.setState({ isEmptyFileToUpload: true });
    }
  };

  updateDataFinal = () => {
    this.setState({
      fileUploadSuccessfully: false,
      fileUploadError: false,
    });

    return Promise.resolve(false);
  }

  cleanErrors = () => this.setState({ errors: {} });

  onFileAdd = ([file]) => this.setState({ file, isEmptyFileToUpload: false });

  uploadFile = (base64) => serverCommunication.serverRequest(
    'post',
    'driftapi/drift',
    JSON.stringify({
      blobFile: base64,
      region: userStore.userMonthPlan.region,
    }),
    userStore.userMonthPlan.region
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status == 401) {
        history.push('/');
      } else {
        throw new Error('Error uploading CSV data');
      }
    });

  deleteFile = () => this.setState({ file: null, fileUploadError: false });

  render() {
    const { children } = this.props;
    return children({
      state: this.state,
      afterAuthStep: this.afterAuthStep,
      doneFirstStep: this.doneFirstStep,
      updateDataFinal: this.updateDataFinal,
      errors: this.state.errors,
      cleanErrors: this.cleanErrors,
      onFileAdd: this.onFileAdd,
      deleteFile: this.deleteFile,
    });
  }
}

export default DriftPopupContainer;
