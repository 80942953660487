import React from 'react';
import navigateStyle from 'styles/dashboard/dashboard-navigate.css';
import InfoMarker from 'components/pages/InfoMarker';

const TopBar = ({ titleText, children, tooltipProps }) => (
  <div className={navigateStyle.locals.topBar}>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div className={navigateStyle.locals.mainTitle}>
        {titleText}
      </div>
      <div style={{ position: 'relative' }}>
        {tooltipProps && <InfoMarker {...tooltipProps} />}
        {children}
      </div>
    </div>
  </div>
);

export default TopBar;
