import React from 'react';

import JourneysChannels from 'components/pages/analyze/ChannelsTab/JourneysChannels';

export default function JourneysChannelsWrapper({
  isLoaded,
  conversionIndicator,
  channelJourneys = [],
  contentJourneys = [],
  widgetHeaderConfig,
  isCategoryView = false,
  widgetHeaderProps,
}) {
  return (
    <JourneysChannels
      isLoaded={isLoaded}
      conversionIndicator={conversionIndicator}
      contentJourneys={contentJourneys}
      channelJourneys={channelJourneys}
      widgetHeaderConfig={widgetHeaderConfig}
      isCategoryView={isCategoryView}
      widgetHeaderProps={widgetHeaderProps}
    />
  );
}
