exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".add-channel-popup__channels-3qf{max-width:100%;width:600px;list-style-type:none;padding:0;border-radius:3px;overflow:hidden;text-align:left;text-transform:capitalize;font-size:16px;font-weight:400}.add-channel-popup__channel-1Z1{background:#e4e7ee;cursor:pointer;display:block;height:40px;line-height:40px;padding:0 10px}.add-channel-popup__channel-1Z1:hover{opacity:.8}.add-channel-popup__channel-1Z1:active{opacity:.6}.add-channel-popup__expandedChannel-1gD{background:#3aa1ee;color:#fff}.add-channel-popup__leafChannel-1rJ{background:#f8f8fa;text-decoration:underline}.add-channel-popup__title-1t4{font-size:20px;font-weight:700;color:#000;text-align:center;border-bottom:1px solid #f2f2f2;padding-bottom:14px;margin-bottom:25px;text-transform:capitalize}", ""]);

// exports
exports.locals = {
	"channels": "add-channel-popup__channels-3qf",
	"channels": "add-channel-popup__channels-3qf",
	"channel": "add-channel-popup__channel-1Z1",
	"channel": "add-channel-popup__channel-1Z1",
	"expandedChannel": "add-channel-popup__expandedChannel-1gD",
	"expandedChannel": "add-channel-popup__expandedChannel-1gD",
	"leafChannel": "add-channel-popup__leafChannel-1rJ",
	"leafChannel": "add-channel-popup__leafChannel-1rJ",
	"title": "add-channel-popup__title-1t4",
	"title": "add-channel-popup__title-1t4"
};