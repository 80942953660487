import userStore from 'stores/userStore';

import { formatBudget, formatNumber } from 'components/utils/budget';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import {
  chunk, get, groupBy, isNil, isPlainObject, mergeWith, orderBy, union,
} from 'lodash';
import { masterRegion } from 'components/enums';

export function averageFormatter(value, isBudget = true) {
  const formatter = isBudget ? formatBudget : formatNumber;
  return isFinite(value) ? formatter(Math.round(value)) : (isNaN(value) ? '0' : '-');
}

export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)));

export const mapObjToSelectOptions = (obj) => Object.keys(obj).map((key) => (
  {
    label: obj[key],
    value: key,
    originalFunnel: key,
  }
));

export function chartFormatter(n) {
  return Number.isInteger(n) ? n : formatNumberWithDecimalPoint(n);
}

export const mergeWithSum = (iteratee) => iteratee.reduce((acc, obj) => mergeWith(acc, obj, (a = 0, b = 0) => a + b), {});

export const roundAndAbs = (number) => Math.abs(Math.round(number));

export const roundIfNeeded = (float) => Math.round(float * 100) / 100;

export function DoubleScroll(element) {
  let scrollbarNode;

  const createScroll = () => {
    const scrollbar = document.createElement('div');
    scrollbar.appendChild(document.createElement('div'));
    scrollbar.setAttribute('id', 'DoubleScroll');
    scrollbar.style.overflow = 'auto';
    scrollbar.style.overflowY = 'hidden';
    scrollbar.firstChild.style.width = `${element.scrollWidth}px`;
    scrollbar.firstChild.style.paddingTop = '1px';
    scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
    let running = false;
    scrollbar.onscroll = function onScrollScrollbar() {
      if (running) {
        running = false;
        return;
      }
      running = true;
      element.scrollLeft = scrollbar.scrollLeft;
    };
    element.onscroll = function onScrollElement() {
      if (running) {
        running = false;
        return;
      }
      running = true;
      scrollbar.scrollLeft = element.scrollLeft;
    };
    element.parentNode.insertBefore(scrollbar, element);
    scrollbarNode = scrollbar;
  };

  createScroll();

  const refresh = () => {
    scrollbarNode.remove();
    createScroll();
  };
  return { refresh };
}

export const modArray = (arr, val) => {
  const index = arr.findIndex((item) => item === val);
  if (index >= 0) {
    arr.splice(index, 1);
  } else {
    arr.push(val);
  }
};

export const isMasterRegion = (region) => {
  const regionNickname = userStore.getRegionNickname({ region });
  return regionNickname === masterRegion;
};

export const getRegionsToUpdate = (region, allUserRegions) => (isMasterRegion(region) ? allUserRegions : [region]);

export const sortRegions = (regions) => regions.sort((a, b) => (isMasterRegion(a.region) ? -1 : isMasterRegion(b.region) ? 1 : a.region.localeCompare(b.region)));

export const formatAndAddExtraData = (array, chunkFormattingData, itemInQuarterMap = (item) => item) => {
  const chunkFormattingDataWithOffset = chunkFormattingData.filter(({ offset }) => !isNil(offset));
  const chunksAddition = union(...chunkFormattingDataWithOffset.map(({ offset, itemsInChunk, chunkAdditionFormatter },
    grouperIndex) => {
    const chunkSplit = [array.slice(0, offset),
      ...chunk(array.slice(offset), itemsInChunk)];

    const mapChunk = (item) => item.map((itemChunk, index) => ({
      putAfter: (offset + index * itemsInChunk - 1),
      value: chunkAdditionFormatter && chunkAdditionFormatter(itemChunk),
      orderIndex: grouperIndex,
    }));

    // If does not need to add to last chunk
    if ((array.length - offset) % itemsInChunk !== 0) {
      return mapChunk(chunkSplit.slice(0, chunkSplit.length - 1));
    } else {
      return mapChunk(chunkSplit);
    }
  }));

  const orderedChunksAddition = orderBy(chunksAddition, 'orderIndex');
  const groupedAdditions = groupBy(orderedChunksAddition, 'putAfter');
  const parsedArray = array.map((item, index) => {
    const valueOfItem = itemInQuarterMap(item);
    return isPlainObject(valueOfItem)
      ? { ...valueOfItem, realIndex: index }
      : { value: valueOfItem, realIndex: index };
  });

  let arrayWithAddition = parsedArray;
  Object.keys(groupedAdditions).forEach((putAfter) => {
    const additions = groupedAdditions[putAfter];
    const putAfterIndex = arrayWithAddition.findIndex(
      (item) => get(item, 'realIndex', null) === parseInt(putAfter, 10)
    );
    arrayWithAddition = [...arrayWithAddition.slice(0, putAfterIndex + 1),
      ...additions.map((item) => item.value),
      ...arrayWithAddition.slice(putAfterIndex + 1)];
  });

  return arrayWithAddition;
};

export const getDaysPluralName = (amount) => (amount > 1 || amount === 0 ? 'days' : 'day');

export const ATTRIBUTION_TYPES = {
  CONTENT: 'content',
  CHANNELS: 'channels',
  CAMPAIGNS: 'campaigns',
  OVERVIEW: 'overview',
};

export const ATTRIBUTION_KEY = 'attribution-table-columns';

export const isValidUrl = (string) => {
  const isURL = new RegExp('^(https?:\\/\\/)?'
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))'
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
    + '(\\?[;&a-z\\d%_.~+=-]*)?'
    + '(\\#[-a-z\\d_]*)?$', 'i');

  const isImage = (url) => url.match(/\.(jpeg|jpg|gif|svg|png)$/) != null;
  return !!isURL.test(string) && isImage(string);
};

export const formatChannelKey = (str) => str.split('.').join('');

export const removeSpecialChars = (str) => {
  const regex = /[^a-zA-Z0-9]/g;
  return str && str.replace(regex, '').toLowerCase();
};

export const FORECASTING_METHODS = {
  AI_BASED: 'AI Based',
  COST_PER_FUNNEL: 'Cost per forecasting',
};

export const PACING_METHODS = {
  PACING_FOR: 'Pacing For',
  PACING_FOR_ON_LAST_DAY: 'Pacing For Based on Last Day',
};

export const safeEvocation = (fn) => (...args) => {
  try {
    return fn(...args);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return null;
};

export const ModuleTypes = {
  STAT_SQUARES: 'STAT_SQUARES',
  HISTORICAL_PERFORMANCE: 'HISTORICAL_PERFORMANCE',
  IMPACT_BY_INDICATOR: 'IMPACT_BY_INDICATOR',
  IMPACT_BY_CRM: 'IMPACT_BY_CRM',
  TABLE_CATEGORY: 'TABLE_CATEGORY',
  TABLE_CHANNEL: 'TABLE_CHANNEL',
  TABLE_CAMPAIGN: 'TABLE_CAMPAIGN',
  TABLE_CONTENT: 'TABLE_CONTENT',
  HISTORY_OVERVIEW_TABLE: 'HISTORY_OVERVIEW_TABLE',
  CARDS_CHANNELS: 'CARDS_CHANNELS',
  IMPACT_CHANNELS: 'IMPACT_CHANNELS',
  CARDS_CAMPAIGNS: 'CARDS_CAMPAIGNS',
  IMPACT_CAMPAIGNS: 'IMPACT_CAMPAIGNS',
  JOURNEYS_CHANNELS: 'JOURNEYS_CHANNELS',
  JOURNEYS_CAMPAIGNS: 'JOURNEYS_CAMPAIGNS',
  PERFORMANCE_GRAPH: 'PERFORMANCE_GRAPH',
  STAT_SQUARES_JOURNEYS: 'STAT_SQUARES_JOURNEYS',
  STAT_SQUARES_CONTENT: 'STAT_SQUARES_CONTENT',
  IMPACT_CONTENT: 'IMPACT_CONTENT',
};

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
