import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget-channel-card.css';
import classnames from 'classnames';
import ChannelIcon from 'components/common/ChannelIcon';
import { getChannelIcon } from 'components/utils/channels';

export default class ChannelCard extends Component {
  style = style;

  render() {
    const {
      classes, title, tooltip, channels, onChoose, channelsWithCategory, defaultImg, noImage,
    } = this.props;

    return (
      <button type="button" className={classnames(this.classes.root, classes?.root)} onClick={() => onChoose(channelsWithCategory ? channelsWithCategory.options.filter((o) => o?.label?.toLowerCase().includes(title.toLowerCase())) : null)}>
        {defaultImg && !noImage && (
            <div className={this.classes.defaultImg} />
        )}
        {defaultImg && noImage && (
            <div className={this.classes.noImage} />
        )}
        {!defaultImg && (
        <ChannelIcon
          className={classnames(this.classes.icon, classes?.icon)}
          channelIcon={getChannelIcon(channels) || ''}
        />
        )}
        {title && <span className={classnames(this.classes.title, classes?.title)}>{title}</span>}
        {tooltip && (
          <span className={this.classes.tooltip}>
            <div className={this.classes.tooltipIcon} />
            <div className={this.classes.message}>
              <p className={this.classes.messageText}>Automatically added via an integration</p>
            </div>
          </span>
        )}
      </button>
    );
  }
}
