import React from 'react';
import PropTypes from 'prop-types';

export const budgetTypeMapping = {
  fiscalCurrent: 0,
  fiscalNext: 1,
  agile: 2,
};

class CommonScroll extends React.PureComponent {
    state = {
      scrollPosition: 0,
    };

    componentDidMount() {
      this.getSpecificMonthToScroll();
    }

    changeScrollPosition = (toPosition) => this.setState({ scrollPosition: toPosition });

    componentWillReceiveProps(nextProps, nextContext) {
      const {
        scrolledYear, showSumData, currentMonIdx, isCustomYear,
      } = this.props;
      if (
        scrolledYear !== nextProps.scrolledYear
        || showSumData !== nextProps.showSumData
        || currentMonIdx !== nextProps.currentMonIdx
        || isCustomYear !== nextProps.isCustomYear
      ) {
        this.getSpecificMonthToScroll(nextProps);
      }
    }

    getSpecificMonthToScroll(data = this.props) {
      const {
        cellWidth, currentYStartIdx, nextYStartIdx, scrolledYear, currentMonIdx, isCustomYear,
      } = data;
      let index = currentMonIdx;
      if (isCustomYear) {
        index = scrolledYear === budgetTypeMapping.fiscalCurrent
          ? currentYStartIdx
          : scrolledYear === budgetTypeMapping.fiscalNext ? nextYStartIdx : currentMonIdx;
      }
      this.changeScrollPosition(index * cellWidth);
    }

    render() {
      const { children } = this.props;
      const { scrollPosition } = this.state;

      return (
        <>
          {children(scrollPosition, this.changeScrollPosition)}
        </>
      );
    }
}
CommonScroll.propTypes = {
  addQuartersAndYearSumData: PropTypes.func,
  children: PropTypes.func.isRequired,
  cellWidth: PropTypes.number,
};

export default CommonScroll;
