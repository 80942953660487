import React, { useEffect } from 'react';
import classNames from 'classnames';

import Table from 'components/controls/Table';
import Spinner from 'components/pages/journeys/Spinner';
import SearchInput from 'components/controls/SearchInput';

import analyzeStyle from 'styles/analyze/analyze.css';
import style from 'styles/common/infiniteScrollTable.css';

const styles = style.locals || {};
const analyzeStyles = analyzeStyle.locals || {};

function InfiniteScrollTable({
  tableData,
  tableColumns,
  isLoadingMore,
  handleShowMoreClick,
  hasMoreData,
  defaultPageSize = 20,
  onSearch,
  isSearchIsDisabled,
  isLoaded,
}) {
  useEffect(() => {
    style.use();
    analyzeStyle.use();
    return () => {
      if (!style.unuse || !analyzeStyle.unuse) {
        return;
      }
      style.unuse();
      analyzeStyle.unuse();
    };
  }, []);
  return (
    <div className={styles.tableWrapper}>
      {onSearch && (
      <SearchInput
        placeholder="Search..."
        onSearch={(value) => onSearch(value)}
        classes={{ input: styles.inputSearch }}
        disabled={isSearchIsDisabled}
      />
      )}
      {isLoaded ? (
        <div className={styles.backTable}>
          {tableData.length === 0 ? (
            <div className={styles.noValues}>
              No values
            </div>
          ) : (
            <>
              <Table
                style={{ height: 440 }}
                bodyCustomClass={analyzeStyles.customBodyClass}
                className={analyzeStyles.channelsImpactsTable}
                rowGroupClassName={analyzeStyles.rowGroup}
                rowClassName={analyzeStyles.row}
                headerClassName={analyzeStyles.header}
                headRowClassName={analyzeStyles.headerRow}
                footerClassName={analyzeStyles.footer}
                footRowClassName={analyzeStyles.footerRow}
                cellClassName={analyzeStyles.cell}
                data={tableData}
                columns={tableColumns}
                noPadding
                minRows={0}
                showPagination
                infiniteScroll
                infiniteScrollSelector="div > div.rt-table"
                handleShowMoreClick={() => handleShowMoreClick()}
                hasMoreData={!isLoadingMore && hasMoreData}
                scrollTriggerOffset={1}
                defaultPageSize={defaultPageSize}
              />
              {isLoadingMore && (
                <div className={styles.loadingMore} data-testid="load-more-spinner">
                  <Spinner />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={classNames(styles.tableLoading, styles.backTable)} data-testid="table-loading-spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default InfiniteScrollTable;
