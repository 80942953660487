import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import CustomSegmentItem from 'components/pages/settings/customSegments/CustomSegmentItem';
import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import CustomSegmentsProperties from 'components/pages/settings/customSegments/CustomSegmentsProperties';
import AddNewCustomSegmentPopup from 'components/pages/settings/customSegments/AddNewCustomSegmentPopup';
import Loader from 'components/controls/Loader';
import servicesStore from 'stores/servicesStore';

import { emptyStateText } from 'components/pages/settings/customSegments/enums';
import { parseCustomSegmentsData } from 'components/pages/settings/customSegments/logic/CustomSegments';
import { Events } from 'trackers/analytics/enums';

import style from 'styles/settings/custom-segments/custom-segments.css';
import channelsTabStyle from 'styles/settings/channels/channels-tab.css';

const styles = style.locals || {};
const channelsTabStyles = channelsTabStyle.locals || {};

function CustomSegments({ pageHeight, customSegmentsMapping, customFieldsIdToLabelMap }) {
  useStyles([style, channelsTabStyle]);

  useEffect(() => {
    setCustomSegmentsData(parseCustomSegmentsData({ data: customSegmentsMapping, customFieldsIdToLabelMap }));
  }, [customSegmentsMapping]);

  const containerLeftHeight = `${pageHeight - 294}px`;
  const containerRightHeight = `${pageHeight - 244}px`;

  const [customSegmentsData, setCustomSegmentsData] = useState(null);
  const [isCreateNewSegmentPopup, setIsCreateNewSegmentPopup] = useState(false);
  const [selectedSegmentAndGroupIds, setSelectedSegmentAndGroupIds] = useState({
    segmentId: null,
    groupId: null,
  });

  function onCreateNewSegment({ segmentName }) {
    const segmentId = uuidv4();
    const newSegment = {
      name: segmentName,
      id: segmentId,
      crmFieldId: null,
      isNewSegment: true,
      isChanged: true,
      groups: [{
        id: uuidv4(),
        name: '',
        rules: [
          [
            {
              operation: '',
              value: '',
              id: uuidv4(),
            },
          ],
        ],
      }],
    };

    const updatedCustomSegmentsData = { ...customSegmentsData };
    updatedCustomSegmentsData[segmentId] = newSegment;
    setCustomSegmentsData(updatedCustomSegmentsData);
    setSelectedSegmentAndGroupIds({ segmentId, groupId: null });
    setIsCreateNewSegmentPopup(false);
    trackCustomSegmentCreated({ segmentId, segmentName });
  }

  function trackCustomSegmentCreated({ segmentId, segmentName }) {
    servicesStore.eventTracker.track({
      eventName: Events.customSegmentNewSegmentClicked,
      properties: {
        segmentId,
        segmentName,
      },
    });
  }

  if (!customSegmentsData) {
    return <Loader />;
  }
  return (
    <>
      {Object.values(customSegmentsData)?.length === 0 ? (
        <EmptyStateWithImage
          title={emptyStateText.title}
          subTitle={emptyStateText.subTitle}
          buttonName="+ New Segment"
          className={styles.emptyState}
          imageClassName={styles.emptyStateImage}
          onClick={() => setIsCreateNewSegmentPopup(true)}
        />
      ) : (
        <div className={channelsTabStyles.mappingContainer}>
          <div className={channelsTabStyles.mapChannels}>
            <div className={channelsTabStyles.mappingHeader}>
              <div className={channelsTabStyles.mappingHeaderGradient} />
              <Button
                type="secondaryBlue"
                onClick={() => setIsCreateNewSegmentPopup(true)}
              >
                + New Segment
              </Button>
            </div>
            <div className={channelsTabStyles.categoriesMenu} style={{ height: containerLeftHeight }}>
              {Object.values(customSegmentsData).map((segment) => (
                <CustomSegmentItem
                  key={segment.id}
                  data={segment}
                  setSelectedSegmentAndGroupIds={setSelectedSegmentAndGroupIds}
                />
              ))}
            </div>
          </div>

          <div
            className={classnames(channelsTabStyles.mapChannelPreview)}
            style={{ height: containerRightHeight }}
          >
            <CustomSegmentsProperties
              selectedSegmentAndGroupIds={selectedSegmentAndGroupIds}
              setSelectedSegmentAndGroupIds={setSelectedSegmentAndGroupIds}
              setCustomSegmentsData={(data) => setCustomSegmentsData(data)}
              customSegmentsData={customSegmentsData}
            />
          </div>
        </div>
      )}

      {isCreateNewSegmentPopup ? (
        <AddNewCustomSegmentPopup
          onClose={() => setIsCreateNewSegmentPopup(false)}
          onSubmit={({ segmentName }) => onCreateNewSegment({ segmentName })}
          customSegmentsData={customSegmentsData}
        />
      ) : null}
    </>

  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customSegmentsMapping,
      },
    } = {},
  }) => ({
    customFieldsIdToLabelMap,
    customSegmentsMapping,
  }),
  observer
)(CustomSegments);
