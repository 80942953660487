import React from 'react';
import isFunction from 'lodash/isFunction';

import Component from 'components/Component';
import AttributionSegmentsTableParseData from 'components/pages/analyze/AttribuitonTable/AttributionSegmentsTableParseData';
import TrendMark from 'components/pages/analyze/TrendMark';

import { crossFunnel } from 'components/pages/analyze/AttribuitonTable/enums';

import style from 'styles/analyze/analyze.css';

const AttributionTableWrapper = (AttributionTable) => class extends Component {
  style = style;

  formatStageNumber = (data, dataKey) => (dataKey === crossFunnel ? '' : Math.round(data[dataKey]));

  setColumnsFooter = (data, columns) => columns.map((column) => {
    if (Array.isArray(column.footer)) {
      const [current, prev] = column.footer;
      const footerFormatter = column.footerFormatter || ((item) => item);

      return {
        ...column,
        footer: (
          <div className={this.classes.flexRow}>
            {footerFormatter(Math.round(current))}
            <TrendMark number={Math.round(current)} prevNumber={prev} />
          </div>
        ),
      };
    }
    if (isFunction(column.footer)) {
      return {
        ...column,
        footer: column.footer(data),
      };
    }
    return column;
  });

  render() {
    return (
      <AttributionSegmentsTableParseData
        {...this.props}
        attributionTable={AttributionTable}
        setColumnsFooter={this.setColumnsFooter}
        formatStageNumber={this.formatStageNumber}
      />
    );
  }
};

export default AttributionTableWrapper;
