export function isCampaignTagPropertiesValid({ properties }) {
  if (properties.name === '') {
    return false;
  }

  if (properties.rules.length === 0) {
    return false;
  }

  for (const rule of properties.rules) {
    if (rule.fieldName === '') {
      return false;
    }
    if (rule.operator === '') {
      return false;
    }
    if (rule.value === '') {
      return false;
    }
  }

  return true;
}

export function getEmptyTagProperties() {
  return {
    name: '',
    rules: [
      {
        fieldName: 'name',
        operator: '',
        value: '',
      },
    ],
  };
}
