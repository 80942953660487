import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import style from 'styles/analyze/conversion-journey.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import Journey from 'components/pages/analyze/Journey';

export default class ChannelJourney extends Component {
  style = style;

  styles = [analyzeStyle];

  static propTypes = {
    conversionIndicator: PropTypes.string.isRequired,
    isCategoryView: PropTypes.bool.isRequired,
    isMoneyFormat: PropTypes.bool,
  };

  render() {
    const {
      conversionIndicator, isCategoryView, journeysData, formattedJourneys, journeysSum, widgetConfig,
    } = this.props;

    return (
      <Journey
        formattedJourneys={formattedJourneys}
        journeysSum={journeysSum}
        conversionIndicator={conversionIndicator}
        journeysData={journeysData}
        header={isCategoryView ? 'category to category' : 'channel to channel'}
        isCategoryView={isCategoryView}
        widgetConfig={widgetConfig}
      />
    );
  }
}
