import React from 'react';
import Component from 'components/Component';
import Spinner from 'components/pages/journeys/Spinner';
import style from 'styles/analyze/with-more-button.css';

const WithPersonalSpinner = () => (WrappedComponent) => class extends Component {
  style = style;

  get renderSpinner() {
    const { isLoaded } = this.props;
    return (isLoaded !== undefined) && !isLoaded;
  }

  render() {
    const { customClass } = this.props;
    return (
      <div
        className={customClass}
        style={{ position: 'relative' }}
      >
        {this.renderSpinner && (
          <div className={this.classes.loader}>
            <Spinner />
          </div>
        )}
        <WrappedComponent
          {...this.props}
        />
      </div>
    );
  }
};

export default WithPersonalSpinner;
