import { cloneDeep } from 'lodash';

export const onUpdateFunnelRuleSelectValue = (ruleKey, value, ruleIndex, funnelMapping, selectedFunnelId) => {
  const updateFunnelMapping = cloneDeep(funnelMapping);
  if (!updateFunnelMapping[selectedFunnelId]) {
    updateFunnelMapping[selectedFunnelId] = {};
  }
  if (!updateFunnelMapping[selectedFunnelId].rules) {
    updateFunnelMapping[selectedFunnelId].rules = [];
  }
  if (ruleIndex !== null || ruleIndex !== undefined) {
    if (!updateFunnelMapping[selectedFunnelId].rules[ruleIndex]) {
      updateFunnelMapping[selectedFunnelId].rules[ruleIndex] = {
        objectType: '',
        field: '',
        values: [],
      };
    }
    updateFunnelMapping[selectedFunnelId].rules[ruleIndex][ruleKey] = value;
  } else {
    updateFunnelMapping[selectedFunnelId].rules.forEach((funnel) => {
      funnel[ruleKey] = value;
    });
  }
  return updateFunnelMapping;
};
