exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".Dropdown-module__wrapper___wC_8T{display:flex;flex-direction:column;gap:20px}.Dropdown-module__selectOptionButtons___3Ml84{margin-left:auto;display:flex;gap:6px;align-items:center}.Dropdown-module__dropdownOptions___1-YBu{display:flex;align-items:center;background-color:#182033}.Dropdown-module__menuButton___1eq-7{margin:8px;width:calc(100% - 16px)}.Dropdown-module__arrowDown___1QG3G{border-top:5px solid #99a4c2;border-left:5px solid transparent;border-right:5px solid transparent}.Dropdown-module__optionButton___3Vz5d{border:1px solid rgba(178,187,213,.5);box-shadow:0 1px 2px #e6e8f0;border-radius:4px;width:24px;height:24px;display:none}.Dropdown-module__optionButton___3Vz5d:hover{border:1px solid #b2bbd5}.Dropdown-module__menuList___H6hnt{margin:6px}.Dropdown-module__controlWrap___39l_F>div{flex-wrap:nowrap;padding:0 0 0 8px}.Dropdown-module__selectOptionWrap___1-J62:hover .Dropdown-module__optionButton___3Vz5d{display:block}.Dropdown-module__selectOptionDisabled___wKZEL,.Dropdown-module__selectOptionDisabled___wKZEL:hover{opacity:.5;background:none;cursor:default}.Dropdown-module__dropdownLabel___26Xzi{margin-left:6px;font-weight:400}.Dropdown-module__channelsSelectSize___2pj9H{width:300px;margin:0 auto}.Dropdown-module__dropdownAsLinkControlWrap___3mMcR{border:none;background:none;padding:0;height:auto;min-height:auto;width:auto;&:hover{text-decoration:underline}&>div,&>span{padding:0;margin:0}}.Dropdown-module__dropdownAsLinkIndicator___-6GAE{display:none}.Dropdown-module__fieldLabel___1YVNX{display:flex}.Dropdown-module__requiredAsterisk___2L7Tp{color:var(--error-red)}", ""]);

// exports
exports.locals = {
	"wrapper": "Dropdown-module__wrapper___wC_8T",
	"selectOptionButtons": "Dropdown-module__selectOptionButtons___3Ml84",
	"dropdownOptions": "Dropdown-module__dropdownOptions___1-YBu",
	"menuButton": "Dropdown-module__menuButton___1eq-7",
	"arrowDown": "Dropdown-module__arrowDown___1QG3G",
	"optionButton": "Dropdown-module__optionButton___3Vz5d",
	"menuList": "Dropdown-module__menuList___H6hnt",
	"controlWrap": "Dropdown-module__controlWrap___39l_F",
	"selectOptionWrap": "Dropdown-module__selectOptionWrap___1-J62",
	"selectOptionDisabled": "Dropdown-module__selectOptionDisabled___wKZEL",
	"dropdownLabel": "Dropdown-module__dropdownLabel___26Xzi",
	"channelsSelectSize": "Dropdown-module__channelsSelectSize___2pj9H",
	"dropdownAsLinkControlWrap": "Dropdown-module__dropdownAsLinkControlWrap___3mMcR",
	"dropdownAsLinkIndicator": "Dropdown-module__dropdownAsLinkIndicator___-6GAE",
	"fieldLabel": "Dropdown-module__fieldLabel___1YVNX",
	"requiredAsterisk": "Dropdown-module__requiredAsterisk___2L7Tp"
};