exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".welcome__inner-page-3oM{background:#f5f6fb!important}.welcome__static-no-side-bar-2wi{margin-left:0!important}.welcome__logo-Am5{width:44px;height:44px;border-radius:4px;background:#e6eaee url(/assets/gdrive_logo.svg) 50% no-repeat;background-size:auto 28px;flex-shrink:0}.welcome__logo-wrap-17u{margin-left:40px}.welcome__logo-cell-3ir{display:flex;align-items:center}.welcome__select-1yU{width:248px}@media (max-width:450px){.welcome__logo-cell-3ir{flex-direction:column;align-items:flex-start}.welcome__logo-wrap-17u{margin-left:0;margin-top:32px}.welcome__select-1yU{width:100%}}.welcome__table-bottom-2rR{display:flex}.welcome__center-1Yr,.welcome__invite-message-2IV{display:flex;justify-content:center}.welcome__invite-message-2IV{margin-top:6px}.welcome__remove-button-2p5{padding:2px;height:28px;width:28px;cursor:pointer;background:url(/assets/trash.svg) 50% no-repeat;background-size:24px auto;flex-grow:0;flex-shrink:0}.welcome__user-picture-_Pg{width:60px;height:60px;border-radius:4px;background:50% no-repeat;background-size:auto 60px}.welcome__editButton-31I{background:var(--white) url(/assets/plan_icons/edit-grey.svg) 50% no-repeat;background-size:24px;height:24px;width:24px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"inner-page": "welcome__inner-page-3oM",
	"innerPage": "welcome__inner-page-3oM",
	"static-no-side-bar": "welcome__static-no-side-bar-2wi",
	"staticNoSideBar": "welcome__static-no-side-bar-2wi",
	"logo": "welcome__logo-Am5",
	"logo": "welcome__logo-Am5",
	"logo-wrap": "welcome__logo-wrap-17u",
	"logoWrap": "welcome__logo-wrap-17u",
	"logo-cell": "welcome__logo-cell-3ir",
	"logoCell": "welcome__logo-cell-3ir",
	"select": "welcome__select-1yU",
	"select": "welcome__select-1yU",
	"table-bottom": "welcome__table-bottom-2rR",
	"tableBottom": "welcome__table-bottom-2rR",
	"center": "welcome__center-1Yr",
	"center": "welcome__center-1Yr",
	"invite-message": "welcome__invite-message-2IV",
	"inviteMessage": "welcome__invite-message-2IV",
	"remove-button": "welcome__remove-button-2p5",
	"removeButton": "welcome__remove-button-2p5",
	"user-picture": "welcome__user-picture-_Pg",
	"userPicture": "welcome__user-picture-_Pg",
	"editButton": "welcome__editButton-31I",
	"editButton": "welcome__editButton-31I"
};