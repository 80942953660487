import _ from 'lodash';

import serverCommunication from 'data/serverCommunication';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';

import { getOtherCampaign } from 'attribution/campaigns';
import {
  makeCampaignBeforeStageFilter, makeChannelBeforeStageFilter, makeChannelsFilter, VARIANTS, makeCampaignsFilter,
} from 'components/utils/filters/make';
import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';

export const navigateFromCampaignsToJourneys = ({
  funnelStage, campaignData, includeAttributionStoreFilters, timeFrame, timeFrameParams, filters = [], attributionModel,
}) => {
  const { channels, name: campaignName } = campaignData;
  const filtersForJourneys = filters;

  if (includeAttributionStoreFilters) {
    filtersForJourneys.push(...attributionStore.rawFilters);
  }

  const firstChannel = _.get(channels, [0], 'direct');
  const isOtherCampaign = campaignName === getOtherCampaign(firstChannel);
  if (isOtherCampaign) {
    if (funnelStage) {
      filtersForJourneys.unshift(
        makeChannelBeforeStageFilter([firstChannel], funnelStage),
        makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [firstChannel])
      );
    } else {
      filtersForJourneys.unshift(makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [firstChannel]));
    }
  } else if (funnelStage) {
    filtersForJourneys.unshift(
      makeCampaignBeforeStageFilter([campaignName], funnelStage, [funnelStage], true),
      makeChannelBeforeStageFilter(channels, funnelStage)
    );
  } else {
    filtersForJourneys.unshift(makeCampaignsFilter(VARIANTS.INCLUDE_ANY_OF, [campaignName]));
  }
  navigateToJourneys({
    indicator: funnelStage, timeFrame, timeFrameParams, attributionModel,
  }, filtersForJourneys);
};

export async function updateCampaignKeyToNameMapping(campaignKeyToNameMapping) {
  const requestBody = {
    campaignKeyToNameMapping,
  };
  try {
    await serverCommunication.serverRequest('put', 'campaignKeyToNameMapping', JSON.stringify(requestBody));
  } catch (exception) {
    servicesStore.logger.error('failed to update campaigns key to name mappings');
    throw exception;
  }
}
