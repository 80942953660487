import React, { useEffect } from 'react';
import classnames from 'classnames';

import InfoMarker from 'components/pages/InfoMarker';
import Spinner from 'components/pages/journeys/Spinner';

import style from 'styles/common/metric-stats-card.css';

const styles = style.locals || {};

function MetricStatsCard({
  metricValue = 0,
  metricLabel,
  bottomMetricValue,
  bottomMetricLabel,
  metricTooltip,
  bottomMetricTooltip,
  isLoaded = true,
  dataTestId,
  children,
  className,
}) {
  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  if (!isLoaded) {
    return (
      <div className={classnames(styles.metricStatsCard, styles.metricStatsCardLoading, className)}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classnames(styles.metricStatsCard, className)} data-testid={dataTestId}>
      <div data-testid="metricStatsCard-metricValue" className={styles.metricValue}>
        {metricValue}
      </div>
      <div data-testid="metricStatsCard-metricLabel" className={styles.metricLabel}>
        {metricLabel}
        {metricTooltip && (
        <InfoMarker
          place="bottom"
          tooltipText={metricTooltip}
        />
        )}
      </div>
      {(bottomMetricLabel || bottomMetricValue) && (
        <div data-testid="metricStatsCard-bottomMetric" className={styles.bottomMetric}>
          {bottomMetricLabel}
          {bottomMetricValue}
          {bottomMetricTooltip && (
            <InfoMarker
              place="bottom"
              tooltipText={bottomMetricTooltip}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
}

export default MetricStatsCard;
