function createDynamicFieldsConditions({ row }) {
  const conditions = [];
  for (const [rowKey, rowValue] of Object.entries(row)) {
    conditions.push({
      value: rowValue,
      param: rowKey,
      operation: 'equals',
    });
  }
  return conditions;
}

function createUrlConditions({ row }) {
  return [{
    value: row.referrer,
    param: 'referrer',
    operation: 'contains',
  }];
}

export const getRulesConditionsBySelectedTab = ({ row, isOnline, isURLsTab }) => {
  const removeFromRulesConditions = ['probability', 'predictedChannel', 'lastActivityTime', 'count'];
  let rulesConditions;
  if (isOnline) {
    if (isURLsTab) {
      rulesConditions = createUrlConditions({ row });
    } else {
      rulesConditions = createDynamicFieldsConditions({ row });
    }
  } else {
    rulesConditions = createDynamicFieldsConditions({ row });
  }

  rulesConditions = rulesConditions.filter((conditionItem) => conditionItem.value !== '' && !removeFromRulesConditions.includes(conditionItem.param));
  return rulesConditions;
};
