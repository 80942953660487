exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".range-with-steps__rangeWrapper-33-{display:flex;flex-direction:column;gap:4px;position:relative}.range-with-steps__stepsLabel-1dt{font-size:12px;color:var(--black);font-weight:500}.range-with-steps__minimumStepLabel-2EL{color:var(--grayText)}.range-with-steps__range-jP1{--max:1;--valueIndex:1;display:flex;justify-content:center;background:linear-gradient(90deg,#c4c4c4 1px,transparent 0) repeat-x;background-size:calc((100% - 1px)/var(--max)) 20px;position:relative}.range-with-steps__range-jP1 input[type=range]{-webkit-appearance:none;width:100%;height:6px;background-color:#c4c4c4;background-image:linear-gradient(var(--blue),var(--blue));background-size:calc((var(--valueIndex) / var(--max) * 100%)) 100%;background-repeat:no-repeat;margin:2px 0;cursor:pointer}.range-with-steps__range-jP1 input[type=range]::-moz-range-thumb,.range-with-steps__range-jP1 input[type=range]::-ms-thumb,.range-with-steps__range-jP1 input[type=range]::-webkit-slider-thumb{-webkit-appearance:none;height:20px;width:20px;border-radius:50%;background:var(--blue);cursor:pointer;box-shadow:0 0 4px 0 rgba(0,0,0,.25)}", ""]);

// exports
exports.locals = {
	"rangeWrapper": "range-with-steps__rangeWrapper-33-",
	"rangeWrapper": "range-with-steps__rangeWrapper-33-",
	"stepsLabel": "range-with-steps__stepsLabel-1dt",
	"stepsLabel": "range-with-steps__stepsLabel-1dt",
	"minimumStepLabel": "range-with-steps__minimumStepLabel-2EL",
	"minimumStepLabel": "range-with-steps__minimumStepLabel-2EL",
	"range": "range-with-steps__range-jP1",
	"range": "range-with-steps__range-jP1"
};