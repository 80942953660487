import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function CanvasSection({
  children, topLabel, bottomLabel, isFirstSection,
}) {
  useStyles([style]);

  return (
    <div className={classnames(styles.canvasSectionWrapper, isFirstSection && styles.first)}>
      <div className={classnames(styles.canvasSectionLabel, styles.upperLabel)}>
        {topLabel}
      </div>
      <div className={styles.canvasSectionContainer}>
        <div className={styles.sectionContentWrapper}>{children}</div>
      </div>
      <div className={classnames(styles.canvasSectionLabel, styles.bottomLabel)}>
        {bottomLabel}
      </div>
    </div>
  );
}
