import React from 'react';
import RulesGroup from './RulesGroup';
import CampaignsContextProvider from './CampaignsContext';

function CampaignsRules({
  platformSubTitle, rulesSubTitle, updateFilter, filtersConfig, activeFilter,
}) {
  return (
    <CampaignsContextProvider values={{ activeFilter, filtersConfig, updateFilter }}>
      <RulesGroup platformSubTitle={platformSubTitle} rulesSubTitle={rulesSubTitle} />
    </CampaignsContextProvider>
  );
}

export default CampaignsRules;
