import React, { useEffect } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import ConfigStats from 'components/common/ConfigStats';
import FeatureFlags from 'components/common/FeatureFlags';
import { aiPromptsProperties } from 'components/pages/insights/aiPromptsTab/enums';

import style from 'styles/insightsPage/aiPrompts.css';

function AiPrompts({ flags }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <div className={style.locals.pageWrap}>
      <div className={style.locals.title}>
        AI Prompts
      </div>
      <div className={style.locals.subTitle}>
        Harness InfiniGrow’s unique data structure with AI Prompts, allowing you to quickly generate analyses that once took weeks. Select a prompt, pair it with IG’s anonymized data, copy into your code interpreter, and instantly discover new insights.
      </div>
      <div className={style.locals.promptsWrap}>
        {Object.values(aiPromptsProperties).map((promptProperties) => (
          <FeatureFlags flag={flags[promptProperties.featureFlag]}>
            <ConfigStats
              isClickable
              type={promptProperties.type}
              title={promptProperties.title}
              subTitle={promptProperties.subTitle}
              popupSubTitle={promptProperties.popupSubTitle}
              icon={`userConfigs:${promptProperties.icon}`}
              PopupComponent={promptProperties.popupComponent}
              configuration={promptProperties}
              isShowSwitchButton={false}
              isViewOnly={false}
            />
          </FeatureFlags>
        ))}
      </div>
    </div>
  );
}

export default withLDConsumer()(AiPrompts);
