exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".top-goals__top-goals-1VW{height:250px;position:relative}.top-goals__top-goals-chart-Fqp{display:flex;flex-direction:row;justify-content:space-around;height:100%}.top-goals__control-nKM{position:absolute;top:0;bottom:0;width:42px;display:flex;justify-content:center;align-items:center}.top-goals__control--left-3BM{left:-21px}.top-goals__control--right-2WK{right:-21px}.top-goals__right-control-2EJ{right:-10px}.top-goals__arrow-wrapper-3dw{cursor:pointer;z-index:5;height:36px;width:36px;overflow:hidden;display:flex;justify-content:center;align-items:center;border:1px solid #d8dde9;border-radius:50%;background-color:#fcfdfe;box-shadow:0 0 2px 1px #d8dde9}.top-goals__arrow-3D6{width:0;height:0;border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:7px solid #949fbc;margin-left:2px}.top-goals__arrow--inverted-2hP{margin-left:0;margin-right:2px;border-left:none;border-right:7px solid #949fbc}.top-goals__goal-item-container-10Y{padding:0!important}.top-goals__center-button-1m-{display:flex;justify-content:center;align-items:center;height:100%}.top-goals__placeholder-container-1ls{padding:15px 25px;display:flex;flex-direction:column;align-items:center;width:100%}.top-goals__placeholder-title-12L{font-size:13px;font-weight:600;letter-spacing:.1px;fill:#b2bbd5}.top-goals__placeholder-text-10v{font-weight:400;font-size:13px;color:#222a41;text-align:center;height:30px;width:65%}.top-goals__placeholder-btn-128{font-size:12px;font-weight:600;color:#707ea7}.top-goals__placeholder-btn-icon-3FI{background:url(/assets/plus.svg) 50% no-repeat;width:12px;height:12px;margin-right:6px}.top-goals__rect-IeN{fill:#b2bbd5}.top-goals__row-2i4,.top-goals__spinnerLoader-250{display:flex;justify-content:center}.top-goals__row-2i4{gap:200px}.top-goals__skeleton-2rx{padding-top:20px;display:flex;flex-direction:column;gap:4px}.top-goals__skeletonFooter-1OW{display:flex;justify-content:space-between;width:200px}", ""]);

// exports
exports.locals = {
	"top-goals": "top-goals__top-goals-1VW",
	"topGoals": "top-goals__top-goals-1VW",
	"top-goals-chart": "top-goals__top-goals-chart-Fqp",
	"topGoalsChart": "top-goals__top-goals-chart-Fqp",
	"control": "top-goals__control-nKM",
	"control": "top-goals__control-nKM",
	"control--left": "top-goals__control--left-3BM",
	"controlLeft": "top-goals__control--left-3BM",
	"control--right": "top-goals__control--right-2WK",
	"controlRight": "top-goals__control--right-2WK",
	"right-control": "top-goals__right-control-2EJ",
	"rightControl": "top-goals__right-control-2EJ",
	"arrow-wrapper": "top-goals__arrow-wrapper-3dw",
	"arrowWrapper": "top-goals__arrow-wrapper-3dw",
	"arrow": "top-goals__arrow-3D6",
	"arrow": "top-goals__arrow-3D6",
	"arrow--inverted": "top-goals__arrow--inverted-2hP",
	"arrowInverted": "top-goals__arrow--inverted-2hP",
	"goal-item-container": "top-goals__goal-item-container-10Y",
	"goalItemContainer": "top-goals__goal-item-container-10Y",
	"center-button": "top-goals__center-button-1m-",
	"centerButton": "top-goals__center-button-1m-",
	"placeholder-container": "top-goals__placeholder-container-1ls",
	"placeholderContainer": "top-goals__placeholder-container-1ls",
	"placeholder-title": "top-goals__placeholder-title-12L",
	"placeholderTitle": "top-goals__placeholder-title-12L",
	"placeholder-text": "top-goals__placeholder-text-10v",
	"placeholderText": "top-goals__placeholder-text-10v",
	"placeholder-btn": "top-goals__placeholder-btn-128",
	"placeholderBtn": "top-goals__placeholder-btn-128",
	"placeholder-btn-icon": "top-goals__placeholder-btn-icon-3FI",
	"placeholderBtnIcon": "top-goals__placeholder-btn-icon-3FI",
	"rect": "top-goals__rect-IeN",
	"rect": "top-goals__rect-IeN",
	"spinnerLoader": "top-goals__spinnerLoader-250",
	"spinnerLoader": "top-goals__spinnerLoader-250",
	"row": "top-goals__row-2i4",
	"row": "top-goals__row-2i4",
	"skeleton": "top-goals__skeleton-2rx",
	"skeleton": "top-goals__skeleton-2rx",
	"skeletonFooter": "top-goals__skeletonFooter-1OW",
	"skeletonFooter": "top-goals__skeletonFooter-1OW"
};