import Component from 'components/Component';
import style from 'styles/settings/channels/unmapped-tab.css';
import React from 'react';
import Spinner from 'components/pages/journeys/Spinner';
import Table, { PaginationButton } from 'components/controls/Table';
import { getUnmappedKey } from 'components/utils/settings';
import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import userStore from 'stores/userStore';
import UnmappedPopup from 'components/pages/settings/channels/tabs/UnmappedPopup';
import { unmappedPopupSteps } from 'components/pages/settings/channels/tabs/logic/enums';
import fixedColumnsStyle from 'react-table-hoc-fixed-columns/lib/styles.css';

class UnmappedTab extends Component {
  style = style;

  styles = [style, fixedColumnsStyle];

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAllLoaded: {
        OFFLINE: false,
        URLS: false,
        UTMS: false,
        ONLINE_CAMPAIGNS: false,
      },
      unmappedPopupStep: unmappedPopupSteps.selectChannel,
    };
  }

  saveMapping = async () => {
    const {
      saveMapping, selectedChannelForMapping, dataType, setFocus,
    } = this.props;
    const { mappingRulesConditions } = this.props;

    if (!selectedChannelForMapping) {
      setFocus();
    } else {
      this.setState({ isSaving: true }, async () => {
        saveMapping(selectedChannelForMapping, mappingRulesConditions, dataType);
        this.setState({ isSaving: false });
        this.setUnmappedPopupStep(unmappedPopupSteps.selectChannel);
        this.props.onCancelPopup();
      });
    }
  };

  loadMore = () => {
    const { APIMethods } = userStore;
    const {
      dataType, endpoint, searchInputValue, shouldIncludePredictedChannel,
    } = this.props;
    const { isAllLoaded } = this.state;
    const unmappedKey = getUnmappedKey(dataType);
    this.setState({ isLoading: true }, () => {
      APIMethods.getUnmappedData({
        unmappedType: dataType, endpoint, showMore: true, searchValue: searchInputValue, shouldIncludePredictedChannel,
      })
        .then((isAllDataLoaded) => {
          isAllLoaded[unmappedKey] = isAllDataLoaded;
          this.setState({ isLoading: false, isAllLoaded });
        });
    });
  };

  setUnmappedPopupStep = (currentStep) => {
    this.setState({ unmappedPopupStep: currentStep });
  };

  render() {
    const {
      tableData, columns, mappingRulesConditions,
      isShowMapPopup, selectedRowsToMap, selectedChannelForMapping, setMappingRulesConditions, dataType,
      paramsOptions, onCancelPopup, searchInputValue = '',
      children, resetSelectedChannel, unmappedType, unmappedTableHeight,
    } = this.props;
    const {
      saveFail, isLoading, isAllLoaded, isSaving, unmappedPopupStep,
    } = this.state;

    const mappKey = getUnmappedKey(dataType);
    const allDataLoaded = isAllLoaded[mappKey];

    return (
      <div>
        {(tableData.length === 0 && this.props.isLoaded) ? (
          <div className={this.classes.noData}>
            {searchInputValue !== '' ? (
              <>
                No results found. Please try a another search.
              </>
            ) : (
              <>
                <div className={this.classes.checkedIconGreen} />
                {unmappedType === 'CHANNELS' ? 'All the channels are mapped' : 'All the campaigns are merged'}
              </>
            )}
          </div>
        ) : (
          <Table
            className={this.classes.mappingTable}
            rowGroupClassName={this.classes.mappingRowGroup}
            rowClassName={this.classes.mappingRow}
            headerClassName={this.classes.mappingHeader}
            cellClassName={this.classes.mappingCell}
            data={tableData}
            columns={columns}
            showPagination
            infiniteScroll
            defaultSorted={this.props.defaultSorted}
            style={{ maxHeight: unmappedTableHeight }}
          />
        )}

        <div className={this.classes.paginationBox}>
          {(isLoading) ? (
            <Spinner />
          ) : (
            <>
              {tableData.length > 0 ? (
                <PaginationButton onClick={() => this.loadMore()} isAllLoaded={allDataLoaded} />
              ) : null}
            </>
          )}
        </div>

        <UnmappedPopup
          hidden={!isShowMapPopup}
          selectedRowsToMap={selectedRowsToMap}
          mappingRulesConditions={mappingRulesConditions}
          setMappingRulesConditions={setMappingRulesConditions}
          saveFail={saveFail}
          isSaving={isSaving}
          paramsOptions={paramsOptions}
          saveMapping={this.saveMapping}
          onCancel={onCancelPopup}
          selectedChannelForMapping={selectedChannelForMapping}
          resetSelectedChannel={resetSelectedChannel}
          unmappedType={unmappedType}
          columns={columns}
          setUnmappedPopupStep={this.setUnmappedPopupStep}
          unmappedPopupStep={unmappedPopupStep}
        >
          {children}
        </UnmappedPopup>
      </div>
    );
  }
}

export default WithPersonalSpinner()(UnmappedTab);
