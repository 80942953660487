import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import history from 'history';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Page from 'components/Page';
import Spinner from 'components/pages/journeys/Spinner';
import Tooltip from 'components/controls/Tooltip';
import Textfield from 'components/controls/Textfield';
import PopupInput from 'components/pages/reports/PopupInput';
import { toastMessage } from 'components/pages/reports/logic/reports';
import {
  getIsTitleValid,
  getIsTitleExists,
  getTemplateInitialFormData,
  getReportTemplateInputProps,
} from 'components/pages/reports/logic/TemplateConfigPopup';

import { getWidgetConfigWithPlaceHolderValues } from 'components/pages/questions/logic/askQuestion';
import { reportTemplateTypes } from 'components/pages/reports/enums';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

import reportLibraryStyle from 'styles/reports/reportLibrary.css';

const styles = reportLibraryStyle.locals || {};

function TemplateConfigPopup({
  onClosePopup,
  templateProps,
  createNewReportRequest,
  getReportsWithWidgetsMetadataRequest,
  folderId,
}) {
  useStyles([reportLibraryStyle]);

  const [formData, setFormData] = useState(() => getTemplateInitialFormData({ templateProps }));
  const [inputsValid, setInputsValid] = useState({});

  function updateFormData({ id, value }) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  }

  useEffect(() => {
    setInputsValid((prevInputsValid) => ({
      ...prevInputsValid,
      title: getIsTitleValid({ title: formData.title }),
      titleUnique: !getIsTitleExists({ title: formData.title }),
    }));
  }, [
    formData.title,
  ]);

  const titleInputRef = useRef(null);
  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  const templateInputsProps = useMemo(() => {
    const inputsProps = {};
    for (const formField of templateProps.configs) {
      inputsProps[formField.id] = getReportTemplateInputProps({ formField });
    }
    return inputsProps;
  }, [
    templateProps.configs,
  ]);

  const [isCreateNewReportLoading, setIsCreateNewReportLoading] = useState(false);

  const isSubmitButtonDisabled = useMemo(() => {
    if (isCreateNewReportLoading) {
      return true;
    }

    const isAnyFieldInvalid = Object.values(inputsValid).some((errorState) => !errorState);
    return isAnyFieldInvalid;
  }, [
    inputsValid,
  ]);

  async function onSubmit() {
    setIsCreateNewReportLoading(true);
    try {
      const {
        configs,
        widgets,
      } = templateProps;

      const placeHoldersValues = {};
      const questionData = {
        placeHoldersTypes: {},
      };

      for (const { id, type, shouldEnableMultiSelect } of configs) {
        placeHoldersValues[id] = formData[id];
        if (shouldEnableMultiSelect) {
          placeHoldersValues[id] = formData[id].join('","');
        }
        questionData.placeHoldersTypes[id] = type;
      }

      const processedWidgets = [];
      for (const widget of widgets) {
        questionData.widget = widget;
        questionData.questionText = widget.title;
        const widgetAsJson = getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData });
        processedWidgets.push(widgetAsJson);
      }

      const newReportId = await createNewReportRequest({
        name: formData.title,
        widgets: processedWidgets,
        sourceType: reportTemplateTypes.template,
        templateName: templateProps.title,
        folderId,
      });

      await getReportsWithWidgetsMetadataRequest();

      toastMessage({
        type: 'success',
        style: { width: '320px', fontSize: '16px' },
        message: 'Report Created successfully!',
      });

      servicesStore.eventTracker.track({
        eventName: Events.reportsNewReportCreated,
        properties: {
          source: reportTemplateTypes.template,
          template: templateProps.title,
          timeframe: widgets[0].configuration.timeFrame?.value,
        },
      });

      history.push(`/reports?reportId=${newReportId.value}`);
    } catch (error) {
      servicesStore.logger.error('failed to create new report', { error });
      toastMessage({
        type: 'error',
        style: { width: '320px', fontSize: '16px' },
        message: 'Failed to create new report!',
      });
    } finally {
      setIsCreateNewReportLoading(false);
    }
  }

  return (
    <Page
      popup
      width="500px"
      wrapBoxStyle={styles.popupBox}
      innerClassName={styles.popupWrapper}
      contentClassName={styles.reportPopup}
    >
      <div className={styles.popupTitle}>
        <Tooltip
          id="template-title"
          tip="Rename"
        >
          <Textfield
            ref={titleInputRef}
            value={formData.title}
            onChange={(e) => updateFormData({ id: 'title', value: e.target.value })}
            isShowError={!inputsValid.title || !inputsValid.titleUnique}
            placeholder="Input Report Name"
            inputClassName={styles.titleInput}
            inputErrorMessage={inputsValid.titleUnique ? 'Must input title' : 'Title already exists'}
          />
        </Tooltip>

        <div className={styles.popupSubTitle}>
          Customize this report using the settings below.
        </div>
      </div>

      {templateProps.configs ? (
        <div className={styles.popupFields}>
          {templateProps.configs.map((formField) => {
            const inputProps = templateInputsProps[formField.id];

            const {
              label = '',
              description = '',
            } = inputProps;

            return (
              <div
                key={formField.id}
                className={styles.inputContainer}
              >
                <div className={styles.inputLabel}>
                  {label}
                </div>

                <div className={styles.popupSubTitle}>
                  {description}
                </div>

                <PopupInput
                  value={formData[formField.id]}
                  onChange={({ id, value }) => updateFormData({ id, value })}
                  formField={formField}
                  inputProps={inputProps}
                  setIsValid={({ id, isValid }) => setInputsValid((prevInputsValid) => ({ ...prevInputsValid, [id]: isValid }))}
                />
              </div>
            );
          })}
        </div>
      ) : null}

      <div className={styles.popupRightButtons}>
        <Button
          type="secondaryBlue"
          onClick={() => onClosePopup()}
        >
          Cancel
        </Button>

        {isCreateNewReportLoading ? (
          <Spinner />
        ) : (
          <Button
            disabled={isSubmitButtonDisabled}
            type="primaryBlue"
            onClick={() => onSubmit()}
          >
            Create
          </Button>
        )}
      </div>
    </Page>
  );
}

export default inject(
  ({
    reportsStore: {
      createNewReportRequest,
      getReportsWithWidgetsMetadataRequest,
    },
  }) => ({
    createNewReportRequest,
    getReportsWithWidgetsMetadataRequest,
  }),
  observer
)(TemplateConfigPopup);
