import React, { useEffect } from 'react';

import style from 'styles/settings/workflows/configPopup.css';

export default function CrmValueTranslation({ configuration }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <>
      {Object.entries(configuration.mapping).map(([leadSourceId, translationMapping]) => Object.entries(translationMapping).map(([crmValue, crmLabel]) => (
        <div className={style.locals.row}>
          Hubspot value is
          <div className={style.locals.selectedItem}>
            {crmValue}
          </div>
          and InfiniGrow value is
          <div className={style.locals.selectedItem}>
            {crmLabel}
          </div>
        </div>
      )))}
    </>
  );
}
