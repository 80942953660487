import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';

import style from 'styles/dashboard/dashboard.css';

class AbsoluteButton extends Component {
  style = style;

  render() {
    const {
      isHovered, text, onClick, contClassName = '',
    } = this.props;
    return (
      <div className={this.classes.absoluteButton}>
        <Button
          type="secondaryBlue"
          onClick={() => onClick}
          containerClassName={contClassName}
        >
          {text}
        </Button>
      </div>
    );
  }
}

export default AbsoluteButton;
