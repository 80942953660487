import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Button } from '@infinigrow/libs';

import Dropdown from 'components/controls/Dropdown';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';

import { COMPARISON_OPERATORS_COSTS } from 'components/utils/filters';
import { EQUALS, NOT_EQUALS } from 'components/utils/filters/logic';

import filterContainerStyle from 'styles/users/filters.css';

import { useCampaignsContext } from './CampaignsContext';
import ContainsSelect from '../FilterPanel/UI/ContainsSelect';

const styles = filterContainerStyle.locals;

function RulesFields({
  selectedPlatform, rulesItem, rulesGroupIndex, rowIndex, isLastRulesItem, isHideDeleteButton,
}) {
  const {
    filtersConfig, onAddAndRulesFieldHandel, handelDeleteRules, updateRulesParam,
  } = useCampaignsContext();

  const checkIfOperatorIsEquals = (operatorValue) => operatorValue === EQUALS || operatorValue === NOT_EQUALS;

  const [isOperatorIsEquals, setIsOperatorIsEquals] = useState(checkIfOperatorIsEquals(rulesItem.comparisonOperator));

  useEffect(() => {
    const isEquals = checkIfOperatorIsEquals(rulesItem.comparisonOperator);
    setIsOperatorIsEquals(isEquals);
  }, [selectedPlatform]);

  const selectedCostObject = rulesItem.costObject;
  const selectedFieldName = rulesItem.fieldName;
  const selectedFieldOptionsValues = rulesItem.selectedOptions;
  const selectedComparisonOperator = rulesItem.comparisonOperator;

  const costObjectsDataOfSelectedPlatform = get(filtersConfig, selectedPlatform, {});
  const costObjectOptions = Object.keys(costObjectsDataOfSelectedPlatform).map((value) => ({ value, label: value }));
  const comparisonOperatorOptions = Object.entries(COMPARISON_OPERATORS_COSTS).map(([value, label]) => ({ value, label }));
  const fieldNamesOfSelectedCostObjectData = get(filtersConfig, [selectedPlatform, selectedCostObject], {});
  const fieldNameOptions = Object.keys(fieldNamesOfSelectedCostObjectData).map((value) => ({ value, label: value }));
  const fieldValuesOfSelectedField = get(filtersConfig, [selectedPlatform, selectedCostObject, selectedFieldName], []).filter((value) => value !== '');
  const fieldOptions = fieldValuesOfSelectedField.map((value) => ({ value, label: value }));

  const handelComparisonOperatorChange = ({ value }) => {
    const operatorValue = value;
    const isEquals = checkIfOperatorIsEquals(operatorValue);
    setIsOperatorIsEquals(isEquals);
    updateRulesParam('comparisonOperator', operatorValue, rulesGroupIndex, rowIndex);
    updateRulesParam('selectedOptions', [], rulesGroupIndex, rowIndex);
  };

  const selectOptions = (selectedOptions) => {
    const selected = selectedOptions?.map((opt) => opt.value) || [];
    updateRulesParam('selectedOptions', selected, rulesGroupIndex, rowIndex);
  };

  const handelCostObjectChange = ({ value }) => {
    updateRulesParam('costObject', value, rulesGroupIndex, rowIndex);
  };

  const onChangeContainsValues = (values = []) => {
    const containsItems = values.map((item) => item.label);
    updateRulesParam('selectedOptions', containsItems, rulesGroupIndex, rowIndex);
  };

  const handelFieldNameChange = ({ value }) => {
    updateRulesParam('fieldName', value, rulesGroupIndex, rowIndex);
    updateRulesParam('selectedOptions', [], rulesGroupIndex, rowIndex);
  };

  return (
    <div className={classNames(styles.rulesItem, !isLastRulesItem && styles.ruleBottomBorder)}>
      {!isHideDeleteButton
        && <div className={styles.minusIcon} onClick={() => handelDeleteRules(rulesGroupIndex, rowIndex)} />}
      <div className={styles.ruleItemFields}>
        <div className={styles.rulesFieldsDropDown}>
          <Dropdown
            disabled
            options={costObjectOptions}
            selectedKey={selectedCostObject}
            onChange={(value) => value && handelCostObjectChange(value)}
            className={classNames(styles.fieldCapitalize, styles.fieldDisabled)}
          />
          <Dropdown
            options={fieldNameOptions}
            selectedKey={selectedFieldName}
            onChange={(value) => value && handelFieldNameChange(value)}
          />
          <Dropdown
            options={comparisonOperatorOptions}
            selectedKey={selectedComparisonOperator}
            onChange={(value) => value && handelComparisonOperatorChange(value)}
            className={styles.fieldLowercase}
          />

        </div>
        {isOperatorIsEquals ? (
          <MultiCheckSelect
            selected={selectedFieldOptionsValues.map((item) => ({
              label: item, value: item,
            }))}
            options={fieldOptions}
            placeholder="Value..."
            onChange={selectOptions}
            isDropDownSelect
            maxWidth={385}
          />
        ) : (
          <ContainsSelect
            controlWidth={385}
            containsValue={selectedFieldOptionsValues.map((item) => ({
              label: item, value: item,
            }))}
            containsValueHandler={onChangeContainsValues}
          />
        )}
      </div>
      {isLastRulesItem
        ? (
          <Button
            className={styles.orButton}
            onClick={() => onAddAndRulesFieldHandel(rulesGroupIndex)}
            type="secondaryBlue"
          >
            AND
          </Button>
        )
        : (
          <Button
            className={styles.andButton}
            containerClassName={styles.andButtonText}
            disabled
            type="secondaryBlue"
          >
            AND
          </Button>
        )}
    </div>
  );
}

export default RulesFields;
