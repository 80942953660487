import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import ObjectiveProgress from 'components/pages/dashboard/GoalProgress';

import objectiveStyle from 'styles/dashboard/goal.css';
import style from 'styles/dashboard/top-goals.css';

export default class GoalPlaceholder extends Component {
  style = style;

  styles = [objectiveStyle];

  render() {
    const { redirect } = this.props;
    return (
      <div className={this.classes.placeholderContainer}>
        <div style={{ height: 105 }}>
          <ObjectiveProgress
            fillValue={0.66}
            strokeWidth={12}
            sqSize={140}
            style={{ marginTop: 10 }}
          >
            <text
              className={this.classes.placeholderTitle}
              x="50%"
              y="35%"
              dy="20px"
              textAnchor="middle"
              alignmentBaseline="text-after-edge"
            >
              Goal
            </text>
            <rect
              x="50%"
              y="27%"
              width="62"
              height="12"
              className={this.classes.rect}
              rx="3"
              transform="translate(-31, 0)"
            />
          </ObjectiveProgress>
        </div>
        <div className={this.classes.placeholderText}>
          <b>Goals</b>
          {' '}
          help you focus on what you should achieve and track your results on the way getting there.
        </div>
        <Button
          type="secondaryBlue"
          onClick={redirect}
          style={{ marginTop: 23 }}
        >
          Add a Goal
        </Button>
      </div>
    );
  }
}
