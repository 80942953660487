exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".cardRowsTable__headerRow-3jx{border-top:none;border-bottom:none;min-height:40px;background:none;box-shadow:none}.cardRowsTable__headerRow-3jx>div{background:none!important}.cardRowsTable__header-2tw{box-shadow:none!important}.cardRowsTable__header-2tw .cardRowsTable__cell-1Xk{font-weight:400;text-transform:capitalize;color:#182033;font-size:14px;background:none}.cardRowsTable__cell-1Xk{font-weight:400;color:#99a4c2;font-size:14px;gap:8px;padding-left:40px}.cardRowsTable__tableRowGroup-1ue{border-radius:12px;background:#fff;box-shadow:0 4px 24px 0 rgba(36,42,64,.06);height:68px;overflow:hidden}.cardRowsTable__table-1KW{background:none}.cardRowsTable__smallRowWrapper-1bh{width:50px}.cardRowsTable__customBodyClass-Jvj{gap:8px}", ""]);

// exports
exports.locals = {
	"headerRow": "cardRowsTable__headerRow-3jx",
	"headerRow": "cardRowsTable__headerRow-3jx",
	"header": "cardRowsTable__header-2tw",
	"header": "cardRowsTable__header-2tw",
	"cell": "cardRowsTable__cell-1Xk",
	"cell": "cardRowsTable__cell-1Xk",
	"tableRowGroup": "cardRowsTable__tableRowGroup-1ue",
	"tableRowGroup": "cardRowsTable__tableRowGroup-1ue",
	"table": "cardRowsTable__table-1KW",
	"table": "cardRowsTable__table-1KW",
	"smallRowWrapper": "cardRowsTable__smallRowWrapper-1bh",
	"smallRowWrapper": "cardRowsTable__smallRowWrapper-1bh",
	"customBodyClass": "cardRowsTable__customBodyClass-Jvj",
	"customBodyClass": "cardRowsTable__customBodyClass-Jvj"
};