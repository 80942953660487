import userStore from 'stores/userStore';
import { getTableColumnsNames } from './InspectAttributionTable';

export function concatInspectDataResult({ currentWidgetData, newWidgetData }) {
  const updateWidgetData = { ...currentWidgetData };
  const currentWidgetDataResults = currentWidgetData.results || [];
  const newWidgetDataResults = newWidgetData.results || [];

  updateWidgetData.results = [...currentWidgetDataResults, ...newWidgetDataResults];
  updateWidgetData.hasMore = newWidgetData.hasMore;
  return updateWidgetData;
}

export function parseInspectTableDataForExport({
  tableData,
  firstColumnName,
  kpiFocus,
}) {
  const columnKeys = getTableColumnsNames({ tableData });
  const columnPriority = {
    entityValue: 1,
    touched: 2,
    attributed: 3,
  };

  columnKeys.sort((a, b) => columnPriority[a] - columnPriority[b]);
  const columnNames = columnKeys.map(
    (key) => (
      key === 'entityValue'
        ? firstColumnName
        : userStore.getMetricNickname({
          segment: key,
          metric: `${key}${kpiFocus}`,
        })
    )
  );

  const exportedData = tableData.map(
    ({
      entityValue,
      touched,
      attributed,
    }) => ([entityValue ?? '', touched ?? '', attributed ?? ''])
  );

  return [columnNames, ...exportedData];
}
