import React from 'react';
import { Link } from 'react-router';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';

import style from 'styles/getting-started/getting-started.css';

const styles = style.locals || {};

function PresetLinksBox({
  title, links = [], tooltip, icon, bottomButton,
}) {
  useStyles([style]);

  return (
    <div className={styles.box}>
      <div className={styles.linksBoxTitle}>
        <div className={styles.linkTitleIcon} data-icon={icon} />
        {title}
        <InfoMarker tooltipText={tooltip} dataTestId="preset-tooltip" />
      </div>

      <div className={styles.linksWrapper}>
        {links.map((item) => {
          const { pathname, search } = new URL(item.linkTo, window.location.origin);
          return (
            <Link
              key={`${item.name}-${item.linkTo}`}
              to={{
                pathname,
                search,
                state: { isOnboarding: true },
              }}
              className={styles.linkItem}
            >
              <div data-testid={`${title}-preset-link`}>
                {item.name}
              </div>
            </Link>
          );
        })}
      </div>

      {bottomButton ? (
        <Link
          to={{
            pathname: bottomButton.linkTo,
            state: { isOnboarding: true },
          }}
          state={{ isOnboarding: true }}
          className={styles.buttonLink}
        >
          <Button
            type="secondaryBlue"
            icon="links:button-blue-arrow"
            iconPosition="right"
            iconClassName={styles.bottomButtonIcon}
          >
            {bottomButton?.name}
          </Button>
        </Link>
      ) : null}
    </div>
  );
}

export default PresetLinksBox;
