import React, { useEffect } from 'react';

import userStore from 'stores/userStore';

import style from 'styles/settings/workflows/configPopup.css';

export default function FunnelsBackfilling({ configuration }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  return (
    <div className={style.locals.row}>
      Funnel stages:
      {configuration.funnels.map((funnel) => (
        <div className={style.locals.tag}>
          {userStore.getMetricNickname({ metric: funnel })}
        </div>
      ))}
    </div>
  );
}
