import moment from 'moment';
import { isEmpty } from 'lodash';

import userStore from 'stores/userStore';

import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { getChannelNickname } from 'components/utils/filters/channels';

export const getFormName = (form) => {
  const classes = form && form.classes ? form.classes : '';
  return classes ? `#${form.id} ${classes.map((c) => `.${c}`).join(' ')}` : '';
};

export const EXTERNAL_LEAD_SOURCE = 'CRM Lead Source';
export const EXTERNAL_LEAD_SOURCE_DATA1 = 'Lead Source drilldown 1';
export const EXTERNAL_LEAD_SOURCE_DATA2 = 'Lead Source drilldown 2';

export const MARKETING_GENERATED = 'Marketing generated';
export const MARKETING_ASSISTED = 'Marketing assisted';
export const NO_MARKETING = 'Sales only';

const MARKETING_VS_SALES_ORDER = [MARKETING_GENERATED, MARKETING_ASSISTED, NO_MARKETING];

export const sortMarketingVsSalesOptions = (options) => options.slice().sort((a, b) => MARKETING_VS_SALES_ORDER.indexOf(a) - MARKETING_VS_SALES_ORDER.indexOf(b));

export const GROUP_BY = {
  USERS: 'contacts',
  ACCOUNTS: 'companies',
  DEALS: 'deals',
};

export const LABELS = {
  ACCOUNTS: 'Accounts',
  USERS: 'People',
  DEALS: 'Deals',
};

export const SINGULAR_LABELS = {
  ACCOUNTS: 'account',
  USERS: 'person',
  DEALS: 'Deal',
};

const filterBySearch = ({ displayName, emails }, search) => {
  const dp = (displayName || '').toLowerCase();

  return !search
    || dp.includes(search)
    || emails.some((email) => email.includes(search));
};

export const filter = (users, filters, search) => users
  .filter((item) => filters.every((filterItem) => filterItem.assert(item)))
  .filter((item) => filterBySearch(item, search));

// eslint-disable-next-line no-nested-ternary
export const arrayCheck = (data) => (Array.isArray(data) ? (data.length > 0 ? data[0] : '') : data);
const join = (array = []) => array.join(', ');

export const ALWAYS_PRESENT_COLUMNS = [{
  label: 'User',
  key: 'displayName',
}, {
  label: 'Email',
  getData: arrayCheck,
  key: 'emails',
}, {
  label: 'Recent Transition',
  key: 'currentStage',
  getData: (item) => userStore.getMetricNickname({ metric: item }),
}, {
  label: 'Account Stage',
  key: 'mostAdvancedStage',
  getData: (item) => userStore.getMetricNickname({ metric: item }),
}];

export const COLUMNS = [{
  value: 'Channels',
  label: 'Channels',
  getData: (arr) => join(arr.map((c) => getChannelNickname(c))),
  key: 'uniqChannels',
}, {
  value: 'sessionsCount',
  label: '# of sessions',
  key: 'sessionsCount',
}, {
  value: 'CurrentStage',
  label: 'Current stage',
  key: 'currentStageNickname',
}, {
  value: 'MostAdvancedStage',
  label: 'Most advanced stage',
  key: 'mostAdvancedStageNickname',
}, {
  value: 'Country',
  label: 'Country',
  getData: join,
  key: 'countries',
}, {
  value: 'dealNames',
  label: 'Deal name',
  getData: join,
  key: 'dealNames',
}, {
  value: 'FirstTouch',
  label: 'First Touch',
  key: 'timeSinceFirst',
}, {
  value: 'LastTouch',
  label: 'Last Touch',
  key: 'timeSinceLast',
}, {
  value: 'Device',
  label: 'Device',
  getData: join,
  key: 'devices',
}, {
  value: 'Product',
  label: 'Product',
  getData: arrayCheck,
  key: 'product',
}, {
  value: 'Region',
  label: 'Region',
  getData: arrayCheck,
  key: 'region',
}];

export const HUBSPOT_LINK_PREFIX = 'https://app.hubspot.com/contacts/';

export function getDateRange(startDate, endDate, monthsExceptThisMonth) {
  const start = startDate
    ? new Date(startDate)
    : moment()
      .subtract(monthsExceptThisMonth, 'months')
      .startOf('month')
      .toDate();
  const end = endDate
    ? new Date(endDate)
    : moment()
      .endOf('month')
      .toDate();

  return { start, end };
}

export function averageFormatter(data, length) {
  if (!length) {
    return 0;
  }
  return formatNumberWithDecimalPoint(data / length);
}

export const getCustomValue = (value) => (isEmpty(value) ? '-' : value.join(', '));

export const DEFAULT_AUDIENCE_FIELDS = {
  teamSize: null,
  salary: null,
  education: null,
  dailyOnlinePresence: null,
  age: null,
  gender: null,
};
