import { getIsValidReportUrl } from 'components/pages/createIntegration/logic/CRMReportSyncSection';

export function checkIfAllFunnelMappingFieldsAreFilled({ funnelMapping, funnelList, isLoading }) {
  for (const funnel of funnelList) {
    const funnelName = funnelMapping[funnel]?.title;
    const regexForCheckFunnelTitle = /^funnel\d+$/;
    if (funnelName?.trim() === '' || regexForCheckFunnelTitle.test(funnelName)) {
      return false;
    }
    const crmReport = funnelMapping[funnel]?.crmReport || {};
    const {
      status,
      reportUrl,
      shouldUseReportSync,
    } = crmReport;

    if (reportUrl && !getIsValidReportUrl({ reportUrl })) {
      return false;
    }

    if (shouldUseReportSync && !reportUrl) {
      return false;
    }

    if (shouldUseReportSync && !!status && status !== 'finished') {
      return false;
    }

    const isButtonEnabled = funnelMapping[funnel]?.rules?.every((rule) => rule.objectType != null && rule.values.length > 0 && rule.field != null);

    if (!isButtonEnabled || isLoading) {
      return false;
    }
  }
  return true;
}
