import React, { useRef, useState } from 'react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';

import { duplicateNameErrorMessage, addNewSegmentPopupText } from 'components/pages/settings/customSegments/enums';

import style from 'styles/settings/custom-segments/custom-segments.css';

const styles = style.locals || {};

function AddNewCustomSegmentPopup({ onClose, customSegmentsData, onSubmit }) {
  useStyles([style]);

  const segmentNameRef = useRef(null);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [inputErrorMessage, setInputErrorMessage] = useState({ isShowError: false, message: '' });

  function onChangeSegmentName({ name }) {
    const isNameEmpty = !name || name === '';
    const isDuplicateName = Object.values(customSegmentsData).some((segment) => segment.name.toLowerCase() === name.toLowerCase());
    setIsSubmitDisabled(isNameEmpty || isDuplicateName);
    setInputErrorMessage({ isShowError: isDuplicateName, message: duplicateNameErrorMessage });
  }

  return (
    <Page popup width="400px" contentClassName={styles.addNewSegmentWrapper} wrapBoxStyle={styles.popupBox}>
      <div className={styles.closePopupIcon} onClick={onClose} />
      <div className={styles.popupTitle}>{addNewSegmentPopupText.title}</div>
      <div className={styles.popupSubTitle}>
        {addNewSegmentPopupText.subTitle}
      </div>
      <div className={styles.popupFields}>
        <Textfield
          ref={segmentNameRef}
          isShowError={inputErrorMessage.isShowError}
          inputErrorMessage={inputErrorMessage.message}
          placeHolder="E.G., Grouped Job Titles"
          onChange={(event) => onChangeSegmentName({ name: event.target?.value })}
        />
      </div>
      <div className={styles.popupButtons}>
        <Button type="secondaryBlue" onClick={onClose}>Close</Button>
        <Button
          type="primaryBlue"
          disabled={isSubmitDisabled}
          onClick={() => onSubmit({ segmentName: segmentNameRef.current?.refs?.input?.value })}
        >
          Create Field
        </Button>
      </div>
    </Page>
  );
}

export default AddNewCustomSegmentPopup;
