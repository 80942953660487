import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import { cloneDeep, startCase } from 'lodash';
import { Button } from '@infinigrow/libs';

import Toggle from 'components/controls/Toggle';
import Tooltip from 'components/controls/Tooltip';
import Textfield from 'components/controls/Textfield';
import InfoMarker from 'components/pages/InfoMarker';
import CRMfunnelRules from 'components/pages/integrations/CRMfunnelRules';
import CRMReportSyncSection from 'components/pages/createIntegration/CRMReportSyncSection';
import Dropdown from 'components/controls/Dropdown';
import servicesStore from 'stores/servicesStore';

import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { funnelTransitionsModels } from 'components/pages/createIntegration/logic/enums';
import {
  funnelGroupByOptions,
  MRRFieldOptionsMonths,
  getFieldOptionsFromCRM,
  funnelsNicknamesToIgnor,
  DEFAULT_PLATFORMS_MAPPING,
} from 'components/utils/indicators';
import { isHavingRevenueEnabledOnDifferentFunnelStage } from 'components/pages/createIntegration/logic/CRMConfigFunnel';

import { Events } from 'trackers/analytics/enums';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals || {};

function CRMConfigFunnelDetails({
  isSyncing,
  onSendReportLink,
  reportSyncStatus,
  selectedFunnelId,
  existingReportUrl,
}, funnelNameRef) {
  const {
    mappingData = {},
    funnelMapping,
    getFunnelTitle,
    setParentState,
    selectedCRMItems,
    getEmptyFunnelObject,
    updateFunnelNickname,
    getFunnelObjectMapping,
  } = useCRMCContext();

  const [isAdvancedOptions, setIsAdvancedOptions] = useState(false);
  const [selectedFunnelName, setSelectedFunnelName] = useState(getFunnelTitle(selectedFunnelId));
  useEffect(() => {
    if (selectedFunnelName !== getFunnelTitle(selectedFunnelId)) {
      setSelectedFunnelName(getFunnelTitle(selectedFunnelId) || '');
      setIsAdvancedOptions(false);
    }
  }, [selectedFunnelId, funnelMapping]);

  const isFunnelRenameDisabled = funnelsNicknamesToIgnor.includes(selectedFunnelId);
  const currentFunnelMapping = funnelMapping[selectedFunnelId];

  const {
    MRR = {},
    rules = [],
    groupBy,
    isRevenue,
    isPipeline,
    amountField,
    crmPlatform,
    currencyField,
    crmReport: {
      reportUrl,
    } = {},
  } = currentFunnelMapping;

  const isSalesforce = crmPlatform === 'salesforce';

  const isReportSyncActive = currentFunnelMapping.crmReport?.shouldUseReportSync;

  const selectedCRMItemsToOptions = selectedCRMItems.map((item) => ({ value: item, label: startCase(item) }));

  const fieldOptionsFromCRM = getFieldOptionsFromCRM(crmPlatform, mappingData, rules[0]?.objectType);
  const objectFieldSelectOptions = fieldOptionsFromCRM.map(({ name: value, label }) => ({ value, label }));

  const onFinishEditFunnelName = (event) => {
    if (selectedFunnelName === null || selectedFunnelName === '' || selectedFunnelName === 'Stage name') {
      funnelNameRef.current.refs.input.focus();
    } else {
      updateFunnelNickname(selectedFunnelId, event.target.value);
    }
  };

  const onEditSelectFunnelInput = (event) => {
    if (event.key === 'Enter') {
      funnelNameRef.current.refs.input.blur();
    }
    onFinishEditFunnelName(event);
    setSelectedFunnelName(event.target.value);
  };

  const onSelectCRMPlatformItems = ({ value }) => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    const defaultMappingByFunnelId = DEFAULT_PLATFORMS_MAPPING[value][selectedFunnelId];
    const funnelMappingSelectedTitle = updatedFunnelMapping[selectedFunnelId].title;

    updatedFunnelMapping[selectedFunnelId] = {
      ...(defaultMappingByFunnelId || getEmptyFunnelObject()),
      title: funnelMappingSelectedTitle,
    };

    updatedFunnelMapping[selectedFunnelId].crmPlatform = value;
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const onUpdateGroupBy = ({ value }) => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    updatedFunnelMapping[selectedFunnelId].groupBy = value;
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const onToggleRevenueStage = (value, key) => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    updatedFunnelMapping[selectedFunnelId][key] = value;
    if (!isRevenue) {
      updatedFunnelMapping[selectedFunnelId].amountField = null;
      updatedFunnelMapping[selectedFunnelId].currencyField = null;
    }
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const onUpdateRevenueValue = (ruleKey, value) => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    updatedFunnelMapping[selectedFunnelId][ruleKey] = value;
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const onMRRFieldChange = (mrrKey, value) => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    if (!updatedFunnelMapping[selectedFunnelId].MRR) {
      updatedFunnelMapping[selectedFunnelId].MRR = {};
    }
    updatedFunnelMapping[selectedFunnelId].MRR[mrrKey] = value;

    const isAllMrrFieldsEmpty = Object.values(updatedFunnelMapping[selectedFunnelId].MRR).every((val) => !val);
    if (isAllMrrFieldsEmpty) {
      delete updatedFunnelMapping[selectedFunnelId].MRR;
    }

    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const onToggleFunnelTransitionsModel = (value) => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    updatedFunnelMapping[selectedFunnelId].funnelTransitionsModel = value;
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: true });
  };

  const onDiscardMapping = () => {
    const updatedFunnelMapping = cloneDeep(funnelMapping);
    const funnelTitle = updatedFunnelMapping[selectedFunnelId].title || null;
    updatedFunnelMapping[selectedFunnelId] = getFunnelObjectMapping(selectedFunnelId);
    updatedFunnelMapping[selectedFunnelId].title = funnelTitle;
    setParentState({ funnelMapping: updatedFunnelMapping, isFunnelMappingChanged: false });
  };

  function onUpdateReportUrl({ reportUrl: newReportUrl }) {
    const newFunnelMapping = {
      ...funnelMapping,
      [selectedFunnelId]: {
        ...funnelMapping[selectedFunnelId],
        crmReport: {
          ...funnelMapping[selectedFunnelId].crmReport,
          reportUrl: newReportUrl,
        },
      },
    };
    setParentState({ funnelMapping: newFunnelMapping });
  }

  function trackReportSyncToggled({ isActive }) {
    servicesStore.eventTracker.track({
      eventName: Events.reportSyncToggled,
      properties: {
        funnel: selectedFunnelId,
        isActive,
        reportUrl,
        crmPlatform,
      },
    });
  }

  function onToggleReportSync() {
    const newShouldUseReportSync = !isReportSyncActive;

    const newFunnelMapping = {
      ...funnelMapping,
      [selectedFunnelId]: {
        ...funnelMapping[selectedFunnelId],
        crmReport: {
          ...funnelMapping[selectedFunnelId].crmReport,
          shouldUseReportSync: newShouldUseReportSync,
        },
      },
    };

    trackReportSyncToggled({
      isActive: newShouldUseReportSync,
    });

    setParentState({ funnelMapping: newFunnelMapping, isFunnelMappingChanged: true });
  }

  function getAdvancedRevenueStageProperties() {
    return (
      <>
        <div className={styles.flexRow} data-testid="amount-field">
          <div className={classNames(styles.fieldText, styles.fieldHalf)}>
            Amount field
            <InfoMarker tooltipText="Select the field that refers to the amount of revenue/pipeline for the chosen object" TooltipProps={{ place: 'right' }} />
          </div>

          <Dropdown
            isClearable
            className={styles.fieldHalf}
            options={objectFieldSelectOptions}
            selectedKey={amountField}
            onChange={(e) => onUpdateRevenueValue('amountField', e && e.value)}
            isSearchable
          />
        </div>

        <div className={styles.flexRow} data-testid="currency-field">
          <div className={classNames(styles.fieldText, styles.fieldHalf)}>
            Currency field (optional)
            <InfoMarker tooltipText="If you use multiple currencies, chose the field that specifies the records currency" TooltipProps={{ place: 'right' }} />
          </div>

          <Dropdown
            isClearable
            className={styles.fieldHalf}
            options={objectFieldSelectOptions}
            selectedKey={currencyField}
            onChange={(e) => onUpdateRevenueValue('currencyField', e && e.value)}
            isSearchable
          />
        </div>

        <div className={classNames(styles.flexRow, MRR.field && styles.fieldDisabled)} data-testid="mrr-field">
          <div className={classNames(styles.fieldText, styles.fieldHalf)}>
            Calculate ARR/MRR
            <br />
            (Default licensing period)
            <InfoMarker tooltipText="Choose your contract subscription period" TooltipProps={{ place: 'right' }} />
          </div>

          <Dropdown
            isClearable
            className={styles.fieldHalf}
            options={MRRFieldOptionsMonths}
            selectedKey={MRR.defaultMonths}
            disabled={MRR.field != null}
            onChange={(e) => onMRRFieldChange('defaultMonths', e && e.value)}
            isSearchable
          />
        </div>

        <div className={classNames(styles.flexRow, MRR.defaultMonths && styles.fieldDisabled)} data-testid="mrr-amounts-by-field">
          <div className={classNames(styles.fieldText, styles.fieldHalf)}>
            Divide deal amounts by
            <InfoMarker tooltipText="Choose the field that distinguishes between monthly and annual contracts" TooltipProps={{ place: 'right' }} />
          </div>

          <Dropdown
            isClearable
            className={styles.fieldHalf}
            options={objectFieldSelectOptions}
            selectedKey={MRR.field}
            disabled={MRR.defaultMonths != null}
            onChange={(e) => onMRRFieldChange('field', e && e.value)}
            isSearchable
          />
        </div>
      </>
    );
  }

  return (
    <div className={styles.configFunnelRightPreview}>
      <div className={styles.configFunnelRightHead}>
        <h2>
          <Tooltip hidden={isFunnelRenameDisabled} id="channelEdit" tip="Rename">
            <Textfield
              value={selectedFunnelName}
              ref={funnelNameRef}
              disabled={isFunnelRenameDisabled}
              placeHolder="Stage name"
              className={styles.funnelInputTitle}
              onChange={onEditSelectFunnelInput}
              onBlur={onFinishEditFunnelName}
              onKeyDown={onEditSelectFunnelInput}
            />
          </Tooltip>
        </h2>

        <div data-testid="advanced-options" className={styles.configFunnelAdvanced} onClick={() => setIsAdvancedOptions(!isAdvancedOptions)}>
          {isAdvancedOptions ? 'Hide ' : 'Show '}
          Advanced Options
        </div>
      </div>

      {isSalesforce ? (
        <CRMReportSyncSection
          isSyncing={isSyncing}
          sendReportLink={() => {
            onSendReportLink({
              funnel: selectedFunnelId,
              reportUrl,
              crmPlatform,
            });
          }}
          reportSyncStatus={reportSyncStatus}
          selectedFunnelId={selectedFunnelId}
          existingReportUrl={existingReportUrl}
          onUpdateReportUrl={({ reportUrl: newReportUrl }) => onUpdateReportUrl({ reportUrl: newReportUrl })}
          isReportSyncActive={isReportSyncActive}
          setIsReportSyncActive={() => onToggleReportSync()}
        />
      ) : null}

      <div className={styles.configFunnelRightTitle}>
        CRM
        <InfoMarker tooltipText={'Select the CRM that stores the funnel stage\'s data'} TooltipProps={{ place: 'right' }} />
      </div>

      <div className={styles.whiteBox}>
        <div className={styles.flexRow}>
          <div className={classNames(styles.fieldText, styles.fieldHalf)}>CRM</div>

          <Dropdown
            className={styles.fieldHalf}
            options={selectedCRMItemsToOptions}
            selectedKey={funnelMapping[selectedFunnelId]?.crmPlatform}
            onChange={onSelectCRMPlatformItems}
            disabled={isReportSyncActive}
            isSearchable
          />
        </div>
      </div>

      {isAdvancedOptions ? (
        <>
          <div className={styles.configFunnelRightTitle}>
            Count by
            <InfoMarker tooltipText={'Select the CRM that stores the funnel stage\'s data'} TooltipProps={{ place: 'right' }} />
          </div>

          <div className={styles.whiteBox}>
            <div className={styles.flexRow}>
              <div className={classNames(styles.fieldText, styles.fieldHalf)}>
                Count by
              </div>

              <Dropdown
                className={styles.fieldHalf}
                selectedKey={groupBy}
                options={funnelGroupByOptions}
                onChange={onUpdateGroupBy}
                disabled={isReportSyncActive}
                isSearchable
              />
            </div>
          </div>
        </>
      ) : null}

      <CRMfunnelRules
        isAdvancedOptions={isAdvancedOptions}
        selectedFunnelId={selectedFunnelId}
        disabled={isReportSyncActive}
      />

      {isAdvancedOptions ? (
        <>
          <div className={styles.configFunnelRightTitle}>
            Pipeline stage
            <InfoMarker tooltipText="For stages where a pipeline number applies, such as Opportunities or Revenue, select Yes" TooltipProps={{ place: 'right' }} />
          </div>

          <div className={styles.whiteBox}>
            <div className={styles.flexRow}>
              <div className={classNames(styles.fieldText, styles.fieldHalf)}>
                Select “Yes” if this stage is defined as pipeline
              </div>
              <Tooltip
                id="pipelineToggle"
                tip="Only one funnel stage can be defined as the pipeline stage"
                hidden={isPipeline ? true : isRevenue || !isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey: 'isPipeline', funnelStage: selectedFunnelId })}
              >
                <Toggle
                  frameClass={styles.fieldToggle}
                  options={[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]}
                  selectedValue={isPipeline}
                  onClick={(newValue) => onToggleRevenueStage(newValue, 'isPipeline')}
                  disabled={isPipeline ? false : isRevenue || isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey: 'isPipeline', funnelStage: selectedFunnelId })}
                  dataTestId="pipeline-stage-toggle"
                />
              </Tooltip>
            </div>

            {isPipeline ? (
              getAdvancedRevenueStageProperties()
            ) : null}
          </div>

          <div className={styles.configFunnelRightTitle}>
            Revenue stage
            <InfoMarker tooltipText="For stages where a revenue number applies, such as Opportunities or Revenue, select Yes" TooltipProps={{ place: 'right' }} />
          </div>

          <div className={styles.whiteBox}>
            <div className={styles.flexRow}>
              <div className={classNames(styles.fieldText, styles.fieldHalf)}>
                Select “Yes” if this stage is defined as revenue
              </div>

              <Tooltip
                id="revenueToggle"
                tip="Only one funnel stage can be defined as the revenue stage"
                hidden={isRevenue ? true : isPipeline || !isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey: 'isRevenue', funnelStage: selectedFunnelId })}
              >
                <Toggle
                  frameClass={styles.fieldToggle}
                  options={[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]}
                  selectedValue={isRevenue}
                  onClick={(newValue) => onToggleRevenueStage(newValue, 'isRevenue')}
                  disabled={isRevenue ? false : isPipeline || isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey: 'isRevenue', funnelStage: selectedFunnelId })}
                  dataTestId="revenue-stage-toggle"
                />
              </Tooltip>
            </div>

            {isRevenue ? (
              getAdvancedRevenueStageProperties()
            ) : null}
          </div>

          <div className={styles.configFunnelRightTitle}>
            Transition count
            <InfoMarker
              TooltipProps={{
                className: styles.funnelTransitionsModelTooltip,
                place: 'right',
              }}
              tooltipText={`
              <div>
                <div>
                  For example, if an MQL's transition date has been updated twice, first on September 1st and again on October 1st:
                </div>
                <div>
                  <ul>
                    <li> if "First" is selected, the MQL will logged only on September 1st </li>
                    <li> if "Last" is selected, the MQL will logged only on October 1s </li>
                    <li> if "Any" is selected, the MQL will logged once on September 1st, and again on October 1st </li>
                  </ul>
                </div>
              </div>`}
            />
          </div>
          <div className={styles.whiteBox}>
            <div className={styles.flexRow}>
              <div className={classNames(styles.fieldText)}>
                Select which change should be counted as transitions
              </div>

              <Toggle
                frameClass={styles.fieldToggle}
                options={[
                  { text: 'First', value: funnelTransitionsModels.firstValue },
                  { text: 'Any', value: funnelTransitionsModels.boomerang },
                  { text: 'Last', value: funnelTransitionsModels.lastValue },
                ]}
                selectedValue={funnelMapping[selectedFunnelId].funnelTransitionsModel}
                onClick={onToggleFunnelTransitionsModel}
                disabled={isReportSyncActive}
              />
            </div>
          </div>
        </>
      ) : null}

      <div className={styles.configFunnelButtons}>
        <Button
          type="secondaryBlue"
          onClick={onDiscardMapping}
          disabled={isSyncing}
          className={styles.bottomIntegrationButton}
        >
          Discard
        </Button>
      </div>
    </div>
  );
}

export default forwardRef(CRMConfigFunnelDetails);
