import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/goal.css';

export default class Goal extends Component {
    style = style;

    render() {
      return (
        <div className={this.classes.content}>
          <div className={this.classes.borderWrap}>
            <svg className={this.classes.circle} style={{ zIndex: 3 }} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -4 34 40">
              <circle cx="16" cy="16" r="15.9155" className={this.classes.background} />
              <circle cx="16" cy="16" r="15.9155" className={this.classes.progressBlue} />
            </svg>
            <svg className={this.classes.circle} style={{ zIndex: 2 }} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -4 34 40">
              <circle cx="16" cy="16" r="15.9155" className={this.classes.background} />
              <circle cx="16" cy="16" r="15.9155" className={this.classes.progressGreen} />
            </svg>
            <svg className={this.classes.circle} style={{ zIndex: 1 }} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -4 34 40">
              <circle cx="16" cy="16" r="15.9155" className={this.classes.background} />
              <circle cx="16" cy="16" r="15.9155" className={this.classes.progressGrey} />
            </svg>
            <div className={this.classes.header}>
              <div className={this.classes.sum}>
                <span className={this.classes.currency}>$</span>
                <span className={this.classes.value}>750K</span>
              </div>
              <h2 className={this.classes.title}>
                Marketing Pipeline
              </h2>
              <div className={this.classes.icon} />
            </div>
            <div className={this.classes.totalProgress}>
              <div className={this.classes.totalBlue} style={{ width: '50%' }} />
              <div className={this.classes.totalGrey} style={{ width: '50%' }} />
            </div>
            <div className={this.classes.interval}>
              <p className={this.classes.startDate}>
                March 1. 2021
              </p>
              <p className={this.classes.endDate}>
                5mo left
              </p>
            </div>
            <div className={this.classes.footer}>
              <div className={this.classes.dot} />
              <p className={this.classes.footerText}>
                Estimated marginal impact
              </p>
            </div>
          </div>
        </div>
      );
    }
}
