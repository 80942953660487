exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".AIAnalysis__aiAnalysis-1up{--purple:#835bfe;position:relative}.AIAnalysis__aiButton-1B9{background:linear-gradient(72deg,var(--blue) 10%,var(--purple) 90%);height:40px;width:40px;border-radius:4px;display:flex;align-items:center;justify-content:center;cursor:pointer}.AIAnalysis__aiButton-1B9:hover:not(.AIAnalysis__disabled--r4){background:var(--purple)}.AIAnalysis__aiButton-1B9.AIAnalysis__disabled--r4{cursor:default}.AIAnalysis__aiButtonIcon-15x{background:url(/src/assets/header/ai-answers.svg) no-repeat 0;height:20px;width:20px;background-size:contain}.AIAnalysis__aiAnalysisPopup-3Rj{width:496px;min-height:140px;border-radius:4px;border:2px solid var(--purple);background:#f7f6ff;box-shadow:-2px 0 4px 0 rgba(131,91,254,.27);position:absolute;top:52px;right:0;z-index:5;padding:0 12px 0 24px;overflow-y:auto}.AIAnalysis__closeButton-re_{background:url(/assets/x.svg) 50% no-repeat;height:24px;width:24px;cursor:pointer;background-size:16px 16px;position:sticky;top:12px;margin-left:auto}.AIAnalysis__popupTitle-PFI{color:var(--purple);font-size:18px;font-weight:700;display:flex;gap:8px;align-items:center}.AIAnalysis__aiTitleIcon-1f0{-webkit-mask:url(/src/assets/header/ai-answers.svg) no-repeat left center;mask:url(/src/assets/header/ai-answers.svg) no-repeat left center;-webkit-mask-size:24px;mask-size:24px;background:var(--purple);height:24px;width:24px}.AIAnalysis__popupContent-1oX{padding:24px 24px 12px;font-size:14px;color:var(--black);line-height:20px}.AIAnalysis__popupContent-1oX h3{font-size:18px;color:var(--black);margin:0}.AIAnalysis__popupContent-1oX h4{color:var(--grayText);margin:0;font-size:14px}.AIAnalysis__popupContent-1oX i{background:#e0ddff;border-radius:4px;color:var(--purple);padding:2px 4px}.AIAnalysis__popupContent-1oX li{margin-bottom:12px}.AIAnalysis__popupContent-1oX ul{padding-left:16px}.AIAnalysis__aiCursor-2FR{display:none}", ""]);

// exports
exports.locals = {
	"aiAnalysis": "AIAnalysis__aiAnalysis-1up",
	"aiAnalysis": "AIAnalysis__aiAnalysis-1up",
	"aiButton": "AIAnalysis__aiButton-1B9",
	"aiButton": "AIAnalysis__aiButton-1B9",
	"disabled": "AIAnalysis__disabled--r4",
	"disabled": "AIAnalysis__disabled--r4",
	"aiButtonIcon": "AIAnalysis__aiButtonIcon-15x",
	"aiButtonIcon": "AIAnalysis__aiButtonIcon-15x",
	"aiAnalysisPopup": "AIAnalysis__aiAnalysisPopup-3Rj",
	"aiAnalysisPopup": "AIAnalysis__aiAnalysisPopup-3Rj",
	"closeButton": "AIAnalysis__closeButton-re_",
	"closeButton": "AIAnalysis__closeButton-re_",
	"popupTitle": "AIAnalysis__popupTitle-PFI",
	"popupTitle": "AIAnalysis__popupTitle-PFI",
	"aiTitleIcon": "AIAnalysis__aiTitleIcon-1f0",
	"aiTitleIcon": "AIAnalysis__aiTitleIcon-1f0",
	"popupContent": "AIAnalysis__popupContent-1oX",
	"popupContent": "AIAnalysis__popupContent-1oX",
	"aiCursor": "AIAnalysis__aiCursor-2FR",
	"aiCursor": "AIAnalysis__aiCursor-2FR"
};