import React from 'react';
import classnames from 'classnames';

import BudgetHeader from 'components/pages/budget/BudgetHeader';
import BudgetsTableV2 from 'components/pages/plan/BudgetTable';
import Channels from 'components/pages/budget/Channels';
import Component from 'components/Component';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';
import mockedData from 'components/pages/budget/mockedData';
import { formatChannels } from 'components/utils/channels';

import style from 'styles/budget/budget.css';

export default class AddChannels extends Component {
     style = style;

     constructor(props) {
       super(props);
       this.state = {
         filtered: '',
         showTable: false,
         selectedChannels: [],
       };
     }

     componentDidMount() {
       this.setState({ filtered: _.uniqBy(formatChannels(() => false, false), 'label') });
     }

  onSearch = (val) => {
    this.setState((previousState) => ({
      filtered: previousState.filtered.filter((f) => f.label?.toLowerCase().includes(val.toLowerCase())
          || f.options.some((o) => o?.label?.toLowerCase().includes(val.toLowerCase()))),
    }));
  };

  onChoose = (channel) => {
    const selectedChannels = [...this.state.selectedChannels];
    selectedChannels.push(channel);
    this.setState({ selectedChannels });
  };

  togleShowTable = () => {
    if (this.state.showTable) {
      this.setState({ showTable: false });
    } else {
      this.setState({ showTable: true });
    }
  };

  render() {
    return (
      <div>
        <div className={this.classes.page}>
          <div className={this.classes.wrapPage}>
            <div className={this.classes.closeIcon} />
            <BudgetHeader onSearch={this.onSearch} />
            <Channels
              channels={this.state.filtered}
              onChoose={this.onChoose}
              selectedChannels={this.state.selectedChannels}
            />
            <div className={classnames(this.classes.tableButtonWrap, this.state.showTable && this.classes.closed)}>
              <div className={this.classes.tableCont}>
                <div className={this.classes.tableButtonWrapDiv}>
                  <SetupBudgetButton
                    name={this.state.showTable ? 'Hide' : 'Show plan preview'}
                    stylesWrap={this.classes.showButtonWrap}
                    stylesBtn={this.state.showTable ? this.classes.showButtonHide : this.classes.showButton}
                    onClick={this.togleShowTable}
                  />
                  <div className={this.state.showTable ? this.classes.arrowTop : this.classes.arrowBottom} />
                  {!this.state.showTable
                             && (
                             <div className={this.classes.count}>
                               <p>4</p>
                             </div>
                             )}
                </div>
                <div className={classnames(this.classes.tableWrap, this.state.showTable
                  ? this.classes.opened
                  : this.classes.closed)}
                >
                  <BudgetsTableV2 {...mockedData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
