import { upperFirst } from 'lodash';
import { getWidgetDefinitions } from 'widgetDefinitions';
import userStore from 'stores/userStore';
import { suggestionsDropdownTypes } from 'components/pages/questions/enums';
import { splitSuggestionTextToObjects, getWidgetTitle } from 'components/pages/questions/logic/suggestionItem';

export function getWidgetConfigWithPlaceHolderValues({ placeHoldersValues, questionData }) {
  const questionTextArray = splitSuggestionTextToObjects({ text: questionData.questionText, placeHoldersTypes: questionData.placeHoldersTypes, placeHoldersValues });

  for (const [placeHolderName, placeHolderValue] of Object.entries(placeHoldersValues)) {
    placeHoldersValues[upperFirst(placeHolderName)] = upperFirst(placeHolderValue);
  }

  const { widget, placeHoldersTypes } = questionData;
  widget.title = getWidgetTitle({ questionTextArray });

  let timeframePlaceHolder;
  for (const [placeHolderName, placeHolderType] of Object.entries(placeHoldersTypes)) {
    if (placeHolderType === suggestionsDropdownTypes.timeframe) {
      timeframePlaceHolder = placeHolderName;
      break;
    }

    if (placeHolderType === suggestionsDropdownTypes.kpiFocus) {
      let conversionIndicator = placeHoldersValues[placeHolderName];
      if (conversionIndicator === 'pipeline') {
        conversionIndicator = userStore?.userMonthPlan?.pipelineFunnel;
      }
      if (conversionIndicator === 'revenue') {
        conversionIndicator = userStore?.userMonthPlan?.revenueFunnel;
      }
      widget.configuration.conversionIndicator = conversionIndicator;
    }
  }

  if (timeframePlaceHolder) {
    widget.configuration.timeFrame = placeHoldersValues[timeframePlaceHolder];
    for (const filter of widget.configuration.filters) {
      if (filter.data.timeFrame) {
        filter.data.timeFrame = placeHoldersValues[timeframePlaceHolder];
      }
    }
  }

  let stringifyWidget = JSON.stringify(widget);
  for (const [placeHolderName, placeHolderValue] of Object.entries(placeHoldersValues)) {
    const placeHolderType = placeHoldersTypes[placeHolderName];
    if (placeHolderType === suggestionsDropdownTypes.timeframe) {
      continue;
    }

    let valueToReplaceTo = placeHolderValue;
    let valueToReplaceFrom = `{${placeHolderName}}`;

    if (placeHolderType === suggestionsDropdownTypes.customFields) {
      valueToReplaceTo = placeHolderValue.id;
      valueToReplaceFrom = `{${placeHolderName}}:key`;
    }
    stringifyWidget = stringifyWidget.replace((new RegExp(valueToReplaceFrom, 'g')), valueToReplaceTo);

    if (placeHolderType === suggestionsDropdownTypes.customFields) {
      valueToReplaceTo = placeHolderValue.value;
      valueToReplaceFrom = `{${placeHolderName}}:value`;
      stringifyWidget = stringifyWidget.replace((new RegExp(valueToReplaceFrom, 'g')), valueToReplaceTo);
    }
  }

  const parsedWidget = JSON.parse(stringifyWidget);

  const widgetType = parsedWidget.type;
  const widgetDefinitions = getWidgetDefinitions({ widget: widgetType });
  parsedWidget.configuration = widgetDefinitions ? widgetDefinitions.getWidgetConfigurationForQuestionResult({ widgetConfig: parsedWidget.configuration }) : parsedWidget.configuration;

  return parsedWidget;
}
