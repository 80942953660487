import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/setup-budget.css';

export default class SetupBudgetButton extends Component {
    style = style;

    render() {
      const {
        name,
        stylesWrap,
        stylesBtn,
        onClick,
      } = this.props;
      return (
        <div className={stylesWrap}>
          <button
            type="button"
            className={stylesBtn}
            onClick={onClick}
          >
            {name}
          </button>
        </div>
      );
    }
}
