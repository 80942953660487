import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Popup from 'components/Popup';
import AddChannelPopup from 'components/pages/plan/AddChannelPopup';
import { output } from 'components/utils/channels';

import doneButtonIcon from 'assets/done-checkmark.svg';
import editButtonIcon from 'assets/edit-white.svg';
import style from 'styles/plan/plan.css';

class PlanControls extends Component {
  style = style;

  renderContent = () => {
    const {
      editMode,
      interactiveMode,
      onCancelClick,
      onCommit,
      commitChanges,
      addChannelPopup,
      initialChannelToOpen,
      addChannel,
      openAddChannelPopup,
      cancelPopup,
      closeAddChannel,
    } = this.props;
    if (interactiveMode) {
      return (
        <div className={this.classes.controlButtons}>
          <Button
            className={this.classes.planControlButton}
            type="secondaryWhite"
            onClick={() => onCancelClick()}
          >
            Cancel
          </Button>
          <Button
            className={this.classes.planControlButton}
            type="primaryBlue"
            onClick={() => onCommit()}
          >
            Commit
          </Button>
        </div>
      );
    }
    if (editMode) {
      return (
        <div className={this.classes.controlButtons}>
          <Button
            className={this.classes.planControlButton}
            type="primaryBlue"
            selected={editMode ? true : null}
            onClick={() => commitChanges()}
            icon={doneButtonIcon}
          >
            Commit
          </Button>
          <Popup
            className={this.classes.dropmenuEdit}
            hidden={!editMode}
          >
            <div>
              <div
                className={this.classes.dropmenuItem}
                onClick={() => openAddChannelPopup(null)}
              >
                Add Channel
              </div>
              <div
                className={this.classes.dropmenuItem}
                onClick={cancelPopup}
              >
                Cancel
              </div>
            </div>
          </Popup>
          {addChannelPopup && (
          <AddChannelPopup
            onChannelChoose={addChannel}
            channels={output()}
            close={closeAddChannel}
            initialExpandedChannel={initialChannelToOpen}
          />
          )}
        </div>
      );
    }
    return (
      <div className={this.classes.controlButtons}>
        <Button
          className={this.classes.planControlButton}
          type="primaryBlue"
          selected={editMode ? true : null}
          onClick={() => commitChanges()}
          icon={editButtonIcon}
        >
          Edit
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className={this.classes.headPlan}>
        {this.renderContent()}
      </div>
    );
  }
}

export default withLDConsumer()(PlanControls);
