import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/controls/Tooltip';
import { uniqueId } from 'lodash';
import Component from 'components/Component';
import style from 'styles/dashboard/metrics.css';
import { roundAndAbs } from 'components/utils/utils';
import classnames from 'classnames';
import icons from 'styles/icons/indicators.css';

class MetricsItem extends Component {
    style = style;

    styles = [icons];

    static propTypes = {
      formatter: PropTypes.func,
      value: PropTypes.any,
      pastValue: PropTypes.any,
      classes: PropTypes.object,
      metric: PropTypes.bool,
      big: PropTypes.any,
      containerClass: PropTypes.string,
      wrapperClass: PropTypes.string,
      textClass: PropTypes.string,
      numberClass: PropTypes.string,
      valueClass: PropTypes.string,
      bold: PropTypes.any,
      tooltipPercent: PropTypes.any,
      tipText: PropTypes.string,
      metricName: PropTypes.string,
      icon: PropTypes.any,
    };

    static defaultProps = {
      big: false,
      formatter: (v) => `${v}`,
    };

    render() {
      const {
        formatter, value, metricName, pastValue, metric,
        containerClass, big, textClass, numberClass, valueClass,
        bold, growthRenderer, wrapperClass, tipText, icon, topText,
      } = this.props;
      const { classes } = this;

      const isCorrect = (v) => v && isFinite(v);
      const formattedValue = formatter(value);
      const visibility = isCorrect(value) && isCorrect(pastValue) ? 'visible' : 'hidden';

      const fraction = pastValue === 0 ? 0 : (value / pastValue) - 1;
      const growthValue = roundAndAbs(fraction * 100);
      const isDecline = fraction < 0 ? true : null;
      return (
        <div className={wrapperClass}>
          <div className={containerClass}>
            {icon && (
              <div className={this.classes.flexCenter}>
                <div className={this.classes.picture} data-icon={icon} />
              </div>
            )}
            {topText && (
              <div className={classnames(classes.quarterText, textClass)}>
                {metricName}
              </div>
            )}
            <div
              className={classnames(classes.quarterNumber, numberClass)}
              style={{ fontWeight: bold && 600 }}
            >
              <div className={valueClass}>{formattedValue}</div>
              <div
                className={classes.center}
                style={{ visibility, marginLeft: topText && 0 }}
              >
                <div className={classes.historyArrow} data-decline={isDecline} />
                <div
                  className={classnames(classes.historyGrow, {
                    [classes.historyGrowBig]: big,
                  })}
                  data-decline={isDecline}
                  style={{ marginRight: '0' }}
                >
                  {growthRenderer ? growthRenderer(growthValue) : `${growthValue}%`}
                </div>
              </div>
            </div>
            {!topText && (
              <div
                className={classnames(classes.quarterText, textClass)}
                style={{ cursor: metric && 'pointer' }}
              >
                {metric ? (
                  <Tooltip
                    id={uniqueId('chart-tooltip-')}
                    tip={tipText}
                    place="bottom"
                  >
                    {metricName}
                  </Tooltip>
                ) : metricName}
              </div>
            )}
          </div>
        </div>
      );
    }
}

export default MetricsItem;
