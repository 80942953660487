import { capitalize } from 'lodash';

import reportsStore from 'stores/reports/reportsStore';
import { suggestionsDropdownTypes } from 'components/pages/questions/enums';
import { reportTemplatePropsMapping } from 'components/pages/reports/enums';

const initValueMapping = {
  [suggestionsDropdownTypes.timeframe]: { value: '', label: '' },
  [suggestionsDropdownTypes.customFields]: { value: '', id: '' },
};

export function getTemplateInitialFormData({ templateProps }) {
  if (!templateProps) {
    return {};
  }

  const { title, configs } = templateProps;

  let copyNumber = 1;

  let newTitle = `${title} (${`${copyNumber}`.padStart(2, '0')})`;

  while (getIsTitleExists({ title: newTitle })) {
    copyNumber++;
    newTitle = `${title} (${`${copyNumber}`.padStart(2, '0')})`;
  }

  const formData = { title: newTitle };
  for (const { id, type, shouldEnableMultiSelect } of configs) {
    formData[id] = initValueMapping[type] || '';
    if (shouldEnableMultiSelect) {
      formData[id] = [];
    }
  }

  return formData;
}

export function getIsTitleValid({ title }) {
  return title !== '';
}

export function getIsTitleExists({ title }) {
  return reportsStore.reportsList.some(
    (item) => item.label === title
  );
}

export function getReportTemplateInputProps({ formField }) {
  const {
    name,
    type,
    fieldName,
    fieldDescription,
    isComparisonChannel,
    shouldShowFieldValues,
    shouldEnableMultiSelect,
  } = formField;

  const result = {
    getIsValid: ({ value }) => value !== '',
    ...(reportTemplatePropsMapping[type] || {}),
  };

  if (type === suggestionsDropdownTypes.funnels) {
    result.label = `${fieldName} Stage`;
    result.description = 'The funnel stage that represents the initial stage of a sale';
  }

  if (type === suggestionsDropdownTypes.customFields) {
    result.label = `${fieldName} field`;
    result.description = fieldDescription;
    result.placeholder = `Select ${fieldName.toLowerCase()}`;
    result.placeholder2 = 'Search item...';
    if (!shouldShowFieldValues) {
      result.getIsValid = ({ value }) => value.id !== '';
    }
  }

  if (type === suggestionsDropdownTypes.channels) {
    if (isComparisonChannel) {
      result.label = 'Comparison Channel';
      result.description = 'The channel you\'d like to compare to the previous channel';
    } else if (name) {
      result.label = `${capitalize(name)} Channels`;
      result.description = `The ${name} channels you'd like to analyze`;
    }
  }

  if (shouldEnableMultiSelect) {
    result.getIsValid = ({ value }) => {
      if (!Array.isArray(value)) {
        return false;
      }

      if (value.length === 0) {
        return false;
      }

      return true;
    };
  }

  return result;
}
