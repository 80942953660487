import { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import WidgetHeader from 'components/common/WidgetHeader';
import CollapsableSplitView from 'components/widgets/optimalJourney/CollapsableSplitView';
import OptimalJourneyQuestion from 'components/widgets/optimalJourney/OptimalJourneyQuestion';
import SettingsRightPanelMenu from 'components/widgets/optimalJourney/SettingsRightPanelMenu';
import JourneyCanvas from 'components/widgets/optimalJourney/journeyCanvas/JourneyCanvas';
import TopMetricsBar from 'components/widgets/optimalJourney/TopMetricsBar';

import { firstJourneySection, funnelStagesObjectMock } from 'components/widgets/optimalJourney/journeyCanvas/enums';
import { questionsTypes, kpiFocusOptionsMockData } from 'components/widgets/optimalJourney/enums';
import { getMetricAbbreviation } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { widgetTypes } from 'components/pages/analyze/enums';

import userStore from 'stores/userStore';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function OptimalJourney({
  widgetHeaderConfig,
  insightsOptions,
  selectedBuildingBlocksStatuses,
  setSelectedBuildingBlocksStatuses,
  buildingBlocksOptions,
  widgetData,
  flags,
}) {
  useStyles([style]);

  const [isRightCollapsed, setIsRightCollapsed] = useState(false);
  const [journeyQuestion, setJourneyQuestion] = useState({
    kind: questionsTypes.highestConverting,
    kpiFocus: flags.optimalJourneysDemoOnly ? kpiFocusOptionsMockData[kpiFocusOptionsMockData.length - 1].value : userStore.userFunnels[userStore.userFunnels.length - 1],
  });

  const widgetHeaderConfigParams = {
    title: 'Optimal Journey',
    type: widgetTypes.optimalJourney,
    ...(widgetHeaderConfig || {}),
  };

  function getFunnelStages() {
    if (flags.optimalJourneysDemoOnly) {
      return funnelStagesObjectMock;
    }

    const funnelStages = userStore.userFunnels;
    const lastFunnelStage = funnelStages[funnelStages.length - 1];
    const funnelStagesObjects = { ...firstJourneySection };

    for (const funnelStage of funnelStages) {
      const isFunnelLast = lastFunnelStage === funnelStage;

      funnelStagesObjects[funnelStage] = {
        topLabel: getMetricAbbreviation({ metric: userStore.getMetricNickname({ metric: funnelStage, isSingular: true }) }),
        bottomLabel: isFunnelLast ? userStore.getMetricNickname({ metric: funnelStage, isSingular: true }) : null,
      };
    }

    return funnelStagesObjects;
  }

  return (
    <div className={styles.wrapper}>
      <WidgetHeader
        widgetHeaderConfig={widgetHeaderConfigParams}
      />
      <div className={styles.container}>
        <OptimalJourneyQuestion
          journeyQuestion={journeyQuestion}
          setJourneyQuestion={setJourneyQuestion}
        />
        <CollapsableSplitView>
          <CollapsableSplitView.LeftPanel>
            <div className={styles.leftPanel}>
              <TopMetricsBar
                metrics={widgetData.overview}
                kpiFocus={journeyQuestion.kpiFocus}
              />
              <div className={styles.canvasWrapper}>
                <JourneyCanvas
                  selectedBuildingBlocksStatuses={selectedBuildingBlocksStatuses}
                  canvasData={widgetData?.journeyCanvas}
                  funnelStages={getFunnelStages()}
                  journeyQuestion={journeyQuestion}
                />
              </div>
            </div>
          </CollapsableSplitView.LeftPanel>
          <CollapsableSplitView.RightPanel isRightCollapsed={isRightCollapsed} setIsRightCollapsed={setIsRightCollapsed}>
            <SettingsRightPanelMenu
              selectedBuildingBlocksStatuses={selectedBuildingBlocksStatuses}
              setSelectedBuildingBlocksStatuses={setSelectedBuildingBlocksStatuses}
              insightsOptions={insightsOptions}
              buildingBlocksOptions={buildingBlocksOptions}
            />
          </CollapsableSplitView.RightPanel>
        </CollapsableSplitView>
      </div>
    </div>
  );
}

export default withLDConsumer()(OptimalJourney);
