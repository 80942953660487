import React from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import Spinner from 'components/pages/journeys/Spinner';
import Tooltip from 'components/controls/Tooltip';

import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';
import { checkIfAllFunnelMappingFieldsAreFilled } from 'components/pages/createIntegration/logic/CRMIntegrationSteps';
import { isHavingRevenueEnabledOnDifferentFunnelStage } from 'components/pages/createIntegration/logic/CRMConfigFunnel';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals;
const stepsItems = ['Connect', 'Configure', 'Import Fields'];

function CRMIntegrationSteps() {
  const {
    currentProgressStep, handleProgressSteps, selectedCRMItems, setParentState, isNextStepLoading, funnelList,
    saveFunnelMappingConfigProgress, funnelMapping = {}, CRMImportFieldsMapping = [],
  } = useCRMCContext();

  const checkIfButtonEnabled = () => {
    let isButtonEnabled = true;
    if (currentProgressStep === 0) {
      isButtonEnabled = selectedCRMItems.length !== 0 && !isNextStepLoading;
    } else if (currentProgressStep === 1) {
      const isHavingRevenueStage = isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey: 'isRevenue' });
      const isHavingPipelineStage = isHavingRevenueEnabledOnDifferentFunnelStage({ funnelMapping, revenueKey: 'isPipeline' });
      return checkIfAllFunnelMappingFieldsAreFilled({ funnelMapping, funnelList, isLoading: isNextStepLoading }) && isHavingRevenueStage && isHavingPipelineStage;
    } else if (currentProgressStep === 2) {
      for (const importFields of CRMImportFieldsMapping) {
        if (importFields.mergingConfiguration.length > 0) {
          isButtonEnabled = importFields.mergingConfiguration.every((mergingConfig) => {
            const filterdFieldsToMerge = mergingConfig.fieldsToMerge.filter((fieldMerge) => fieldMerge);
            return mergingConfig.objectName != null && filterdFieldsToMerge.length >= 1;
          });
          if (!isButtonEnabled || isNextStepLoading) {
            return false;
          }
        }
      }
    }
    return isButtonEnabled;
  };

  const isButtonSaveEnabled = checkIfButtonEnabled();

  const setCurrentProgressStep = (index) => {
    setParentState({ currentProgressStep: index });
  };

  const isFinalStage = currentProgressStep === 2;

  return (
    <div className={styles.bottomIntegrationStepsWrapper}>
      <div className={styles.bottomIntegrationFlex}>
        {currentProgressStep !== 0 && <Button onClick={() => handleProgressSteps(false)} className={styles.bottomIntegrationButton} type="secondaryBlue">Back</Button>}
      </div>
      <div className={styles.bottomIntegrationFlex}>
        <div className={styles.bottomIntegrationSteps}>
          {stepsItems.map((stepItem, index) => {
            const isActiveStep = index <= currentProgressStep;
            const isShowLineStep = index !== stepsItems.length - 1;
            const isSClickableStep = index < currentProgressStep;
            return (
              <div
                key={stepItem}
                onClick={index < currentProgressStep ? (() => setCurrentProgressStep(index)) : undefined}
                className={classNames(
                  styles.stepItem,
                  isShowLineStep && styles.stepItemLine,
                  isActiveStep && styles.stepActive,
                  isSClickableStep && styles.stepClickable
                )}
              >
                <div className={styles.stepCounter} />
                <div>{stepItem}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames(styles.bottomIntegrationFlex, styles.bottomIntegrationFlexRight)}>
        {currentProgressStep === 0
          ? (
            <>
              {isNextStepLoading && <Spinner />}
              <Button
                onClick={() => handleProgressSteps(true)}
                disabled={!isButtonSaveEnabled}
                className={styles.bottomIntegrationButton}
                type="primaryBlue"
              >
                Continue
              </Button>
            </>
          )
          : (
            <>
              {isNextStepLoading && <Spinner />}
              <Tooltip
                tip="Fill the required information to save changes"
                id="buttonSaveDisabled"
                place="left"
                hidden={isButtonSaveEnabled}
              >
                <Button
                  onClick={isFinalStage ? saveFunnelMappingConfigProgress : () => handleProgressSteps(true)}
                  className={styles.bottomIntegrationButton}
                  type="primaryBlue"
                  disabled={!isButtonSaveEnabled}
                >
                  {isFinalStage ? 'Save' : 'Continue'}
                </Button>
              </Tooltip>
            </>
          )}
      </div>
    </div>
  );
}

export default CRMIntegrationSteps;
