import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { inject, observer } from 'mobx-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import useStyles from 'hooks/useStyles';

import CRMConfigFunnelItem from 'components/pages/createIntegration/CRMConfigFunnelItem';
import CRMConfigFunnelDetails from 'components/pages/createIntegration/CRMConfigFunnelDetails';
import useWindowDimensions from 'components/utils/getWindowDimensions';
import Dropdown from 'components/controls/Dropdown';
import Spinner from 'components/pages/journeys/Spinner';

import { mergeCustomFields, getReportSyncStatus, handleSendReportLink } from 'components/pages/createIntegration/logic/CRMConfigFunnel';
import { CRMIntegrationsOptions } from 'components/utils/indicators';
import { useCRMCContext } from 'components/pages/createIntegration/CRMContext';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

import integrationStyle from 'styles/integrations/integration.css';

const styles = integrationStyle.locals || {};

function CRMConfigFunnel({
  region,
  userBusinessUnitsWithIds,
}) {
  useStyles([integrationStyle], [injectStyle]);

  const {
    funnelList,
    onFunnelAdd,
    removeFunnel,
    funnelMapping,
    setParentState,
    getCRMMappingRequest,
    salesforceUserMappings,
    selectedBusinessUnitPreset,
    updateFunnelsMappingNaming,
    setSelectedBusinessUnitPreset,
    updatedPredefinedFiltersConfigFromSync,
    oldCustomFieldIdToMergedId,
  } = useCRMCContext();

  const funnelNameRef = useRef();
  const funnelSelectedPreview = useRef();

  const [isCRMMappingLoading, setIsCRMMappingLoading] = useState(false);
  const [selectedFunnelId, setSelectedFunnelId] = useState(funnelList[0]);
  const [funnelSyncStatus, setFunnelSyncStatus] = useState({});

  const existingReportUrls = useMemo(() => {
    const urls = {};
    for (const [funnelItem, funnelData] of Object.entries(funnelMapping)) {
      const { crmReport = {} } = funnelData;
      urls[funnelItem] = crmReport.reportUrl || '';
    }
    return urls;
  }, []);

  const [reportSyncStatus, setReportSyncStatus] = useState({});
  useEffect(() => {
    getReportSyncStatus({
      region,
      handleResponseData: ({ responseData }) => {
        setReportSyncStatus(responseData);
      },
    });
  }, [region]);

  const isSyncing = useMemo(() => Object.values(funnelSyncStatus).some((val) => !!val), [
    funnelSyncStatus,
  ]);

  const toastRef = useRef();
  useEffect(() => {
    if (isSyncing) {
      toastRef.current = toast.success(
        'Syncing to your report now! The configuration will be updated soon!',
        {
          hideProgressBar: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'light',
          toastId: 'reportIsSyncing',
        }
      );
    } else if (toastRef.current) {
      toast.dismiss(toastRef.current);
    }
  }, [isSyncing]);

  useEffect(() => {
    if (selectedFunnelId && !funnelList.includes(selectedFunnelId)) {
      setSelectedFunnelId(funnelList[0]);
    }
  }, [funnelList]);

  const selectFunnelHandler = (funnelItem) => {
    setSelectedFunnelId(funnelItem);
    funnelSelectedPreview.current.scrollTo(0, 0);
  };

  const handleDragDrop = async (droppedItem) => {
    if (!droppedItem.destination) {
      return;
    }

    const currentFunnelList = [...funnelList];
    const [movedFunnelItem] = currentFunnelList.splice(droppedItem.source.index, 1);
    currentFunnelList.splice(droppedItem.destination.index, 0, movedFunnelItem);

    const { updatedFunnelList } = await updateFunnelsMappingNaming(currentFunnelList);

    selectFunnelHandler(updatedFunnelList[0]);

    setParentState({
      isFunnelMappingChanged: true,
      isFunnelOrderChanged: true,
    });
  };

  const onRemoveFunnel = (funnelItem, withoutPopup) => {
    selectFunnelHandler(funnelList[0]);
    removeFunnel(funnelItem, withoutPopup);
  };

  const addNewFunnel = async () => {
    const funnelNewId = await onFunnelAdd();
    setSelectedFunnelId(funnelNewId);
    funnelNameRef.current.refs.input.focus();
  };

  function trackReportSentToSync({ funnel, reportUrl, crmPlatform }) {
    let reason = 'enabled sync';

    const isLinkChanged = existingReportUrls[funnel] && existingReportUrls[funnel] !== reportUrl;
    if (isLinkChanged) {
      reason = 'URL changed';
    }

    const isOutOfSync = !isLinkChanged && reportSyncStatus[funnel] && !reportSyncStatus[funnel].isSynced;
    if (isOutOfSync) {
      reason = 'out of sync';
    }

    servicesStore.eventTracker.track({
      eventName: Events.reportSentToSync,
      properties: {
        funnel,
        reason,
        reportUrl,
        crmPlatform,
      },
    });
  }

  function onSendReportLink({ funnel, reportUrl, crmPlatform }) {
    trackReportSentToSync({ funnel, reportUrl, crmPlatform });

    setFunnelSyncStatus((prevIsSyncing) => ({
      ...prevIsSyncing,
      [funnel]: true,
    }));

    handleSendReportLink({
      region,
      requestData: {
        funnel,
        reportUrl,
        crmPlatform,
      },

      handleResponseData: ({ responseData }) => {
        if (responseData.status === 'finished' || responseData.status === 'failed') {
          setFunnelSyncStatus((prevIsSyncing) => ({
            ...prevIsSyncing,
            [funnel]: false,
          }));
        }

        if (responseData.status === 'finished') {
          const funnelSyncResponseData = responseData[funnel];

          const {
            diff: {
              funnelMapping: responseFunnelMapping = {},
              customFields: funnelNewCustomFields,
              predefinedFiltersConfig: predefinedFiltersConfigFromSync,
            } = {},
          } = funnelSyncResponseData;

          const { customFields: sfCustomFields } = salesforceUserMappings.mapping;

          const newFunnelMapping = {
            ...funnelMapping,
            [funnel]: {
              ...funnelMapping[funnel],
              ...responseFunnelMapping,
            },
          };

          const mergedCustomFields = mergeCustomFields({ customFields: sfCustomFields, funnelNewCustomFields });

          const { customFields: newCustomFields, oldCustomFieldIdToMergedId: funnelOldIdToMergedId } = mergedCustomFields;

          const newSalesforceUserMappings = {
            ...salesforceUserMappings,
            mapping: {
              ...salesforceUserMappings.mapping,
              customFields: newCustomFields,
            },
          };

          const newUpdatedPredefinedFiltersConfigFromSync = { ...(updatedPredefinedFiltersConfigFromSync || {}) };
          if (predefinedFiltersConfigFromSync) {
            newUpdatedPredefinedFiltersConfigFromSync[funnel] = predefinedFiltersConfigFromSync;
          }

          serverCommunication.serverRequest('PUT', 'crm/reports/sync', JSON.stringify({ funnel }), region);

          setParentState({
            funnelMapping: newFunnelMapping,
            salesforceUserMappings: newSalesforceUserMappings,
            isFunnelMappingChanged: true,
            oldCustomFieldIdToMergedId: {
              ...oldCustomFieldIdToMergedId,
              ...funnelOldIdToMergedId,
            },
            updatedPredefinedFiltersConfigFromSync: newUpdatedPredefinedFiltersConfigFromSync,
          });
        }
      },

      handleError: () => {
        setFunnelSyncStatus((prevIsSyncing) => ({
          ...prevIsSyncing,
          [funnel]: false,
        }));
      },
    });
  }

  const { height: screenHeight } = useWindowDimensions();
  const configFunnelHeight = `${screenHeight - 418}px`;

  async function onChangeBusinessUnits(value) {
    setSelectedBusinessUnitPreset({ preset: value });
    if (value) {
      setIsCRMMappingLoading(true);
      for (const crmIntegration of CRMIntegrationsOptions) {
        await getCRMMappingRequest({ crmPlatform: crmIntegration, businessUnitId: value });
      }
      setIsCRMMappingLoading(false);
    }
  }

  const businessUnitsOptions = [];
  for (const businessUnit of userBusinessUnitsWithIds) {
    if (!businessUnit.hasCrmConfigurations) {
      continue;
    }
    businessUnitsOptions.push({ value: businessUnit.id, label: businessUnit.name });
  }

  return (
    <>
      <h1>Configure Your Funnel</h1>
      <h3>Build your funnel and customize it to fit your business.</h3>

      <div className={styles.configFunnelContainer}>
        <div style={{ maxHeight: configFunnelHeight }} className={styles.configFunnelLeft}>
          <div className={styles.configFunnelDropdown}>
            <Dropdown
              onChange={(event) => onChangeBusinessUnits(event?.value)}
              options={businessUnitsOptions}
              selectedKey={selectedBusinessUnitPreset}
              controlWidth={300}
              isSearchable
              placeholder="Select preset... (optional)"
              clearable
              dropdownLabel={selectedBusinessUnitPreset ? 'Selected preset' : null}
              isClearable
            />
          </div>

          {isCRMMappingLoading ? (
            <div className={styles.configFunnelLoading}>
              <Spinner />
            </div>
          ) : (
            <>
              <DragDropContext onDragEnd={handleDragDrop}>
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {funnelList.map((funnelItem, funnelIndex) => (
                        <CRMConfigFunnelItem
                          key={funnelItem}
                          isSyncing={isSyncing}
                          funnelItem={funnelItem}
                          funnelIndex={funnelIndex}
                          removeFunnel={onRemoveFunnel}
                          isFunnelSyncing={funnelSyncStatus[funnelItem]}
                          selectedFunnelId={selectedFunnelId}
                          reportSyncStatus={reportSyncStatus[funnelItem]}
                          existingReportUrl={existingReportUrls[funnelItem]}
                          selectFunnelHandler={selectFunnelHandler}
                        />
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className={styles.configFunnelBottom}>
                <Button
                  type="primaryBlue"
                  onClick={addNewFunnel}
                  disabled={isSyncing}
                  dataTestId="add-funnel-button"
                >
                  Add stage
                </Button>
              </div>
            </>
          )}
        </div>

        <div
          style={{ maxHeight: configFunnelHeight }}
          className={styles.configFunnelRight}
          ref={funnelSelectedPreview}
          data-testid="config-funnel-right"
        >
          <CRMConfigFunnelDetails
            ref={funnelNameRef}
            isSyncing={isSyncing}
            onSendReportLink={({ funnel, reportUrl, crmPlatform }) => onSendReportLink({ funnel, reportUrl, crmPlatform })}
            reportSyncStatus={reportSyncStatus[selectedFunnelId]}
            selectedFunnelId={selectedFunnelId}
            existingReportUrl={existingReportUrls[selectedFunnelId]}
          />
        </div>

        <ToastContainer
          limit={1}
          style={{ width: '585px', fontSize: '16px' }}
          position="bottom-center"
          autoClose={10000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          transition={Slide}
        />
      </div>
    </>
  );
}

export default inject(({
  userStore: {
    userBusinessUnitsWithIds,
  },
}) => ({
  userBusinessUnitsWithIds,
}), observer)(CRMConfigFunnel);
