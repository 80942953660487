import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/controls/Dropdown';
import { FREQUENCY_OPTIONS } from 'components/utils/frequency';

export default class FrequencySelect extends Component {
  static propTypes = {
    selected: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })),
  }

  static defaultProps = {
    selected: FREQUENCY_OPTIONS.MONTH.value,
    options: [
      FREQUENCY_OPTIONS.DAY,
      FREQUENCY_OPTIONS.WEEK,
      FREQUENCY_OPTIONS.MONTH,
      FREQUENCY_OPTIONS.QUARTER,
    ],
  }

  render() {
    return (
      <Dropdown
        disabled={this.props.disabled}
        selectedKey={this.props.selected}
        options={this.props.options}
        onChange={(event) => this.props.onChange(event, this.props.widgets)}
        dropdownLabel="Frequency"
      />
    );
  }
}
