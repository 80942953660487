import { get } from 'lodash';

export const SALESFORCE = 'salesforce';
export const HUBSPOT = 'hubspot';

export const getCRMCustomFieldsFromSavedMapping = (crmPlatform, salesforceUserMappings, hubspotUserMappings) => {
  let customFields = [];
  if (crmPlatform === SALESFORCE) {
    customFields = get(salesforceUserMappings, 'mapping.customFields', []);
  }
  if (crmPlatform === HUBSPOT) {
    customFields = get(hubspotUserMappings, 'mapping.customFields', []);
  }
  customFields = customFields.map((customField) => {
    const updateCustomField = { ...customField };
    updateCustomField.isAttribution = false;
    updateCustomField.mergingConfiguration = Object.values(updateCustomField.mergingConfiguration).map((mergingConfig) => {
      mergingConfig.crmPlatform = crmPlatform;
      return mergingConfig;
    });
    return updateCustomField;
  });
  return customFields;
};

export const getCRMLeadsSourcesFromSavedMapping = (crmPlatform, salesforceUserMappings, hubspotUserMappings) => {
  let leadSources = [];
  if (crmPlatform === SALESFORCE) {
    leadSources = get(salesforceUserMappings, 'mapping.leadSources', []);
  }
  if (crmPlatform === HUBSPOT) {
    leadSources = get(hubspotUserMappings, 'mapping.leadSources', []);
  }
  leadSources = leadSources.map((leadSource) => {
    const updateCustomField = { ...leadSource };
    updateCustomField.isAttribution = true;
    updateCustomField.mergingConfiguration = Object.values(updateCustomField.mergingConfiguration).map((mergingConfig) => {
      mergingConfig.crmPlatform = crmPlatform;
      return mergingConfig;
    });
    return updateCustomField;
  });
  return leadSources;
};
