import React, { useState, useMemo } from 'react';
import { cloneDeep, get } from 'lodash';
import { Checkbox } from '@infinigrow/libs';

import FilterActions from 'components/pages/users/Filters/FilterActions';
import filterStyles from 'styles/users/filters.css';
import Textfield from 'components/controls/Textfield';
import Range from 'components/controls/Range';
import CustomDateSelect from 'components/pages/users/Filters/CustomDateSelect';

import { COMPARISON_OPERATORS } from 'components/utils/filters';
import { filterKinds } from 'components/utils/filters/logic';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { exchangeToUSD, extractNumberFromBudget, formatBudget } from 'components/utils/budget';

import Dropdown from 'components/controls/Dropdown';
import {
  getFilterRange, getFilterIsBetweenView, getFilterIsCustomDate, maxRange, BETWEEN,
} from './logic/CampaignCostFilter';

const styles = filterStyles.locals;

function CampaignCostFilter({
  onCancel, filtersItems, updateFiltersItems, activeFilter, addFilterToSelection, onAddFilter,
}) {
  const costAmountOptions = useMemo(() => Object.entries({ ...COMPARISON_OPERATORS, [BETWEEN]: 'Is between' }).map(([value, label]) => ({ value, label })), [COMPARISON_OPERATORS, BETWEEN]);

  const emptyCampaignCostFilter = {
    data: {
      comparisonOperator: costAmountOptions[0].value,
      manualValue: '',
    },
  };

  const [selectedFilter, setSelectedFilter] = useState(activeFilter || emptyCampaignCostFilter);
  const [rangeFilter, setRangeFilter] = useState(getFilterRange(activeFilter));
  const [isBetweenView, setIsBetweenView] = useState(getFilterIsBetweenView(activeFilter));
  const [isCustomDate, setIsCustomDate] = useState(getFilterIsCustomDate(activeFilter));

  const handleFilterApply = () => {
    addFilterToSelection(selectedFilter, null, null, () => onAddFilter());
  };

  const updateFilter = (filterData) => {
    const filterObj = {
      kind: filterKinds.CAMPAIGN_COST,
      data: {
        comparisonOperator: filterData.data.comparisonOperator,
        manualValue: filterData.data.manualValue,
        selectedOptions: [],
      },
    };
    if (filterData.data?.timeFrame) {
      filterObj.data.timeFrame = filterData.data.timeFrame;
      filterObj.data.label = filterData.data.label;
    }
    updateFiltersItems(filterObj);
    setSelectedFilter(filterObj);
  };

  const onChangeComparisonOperator = (value) => {
    const comparisonOperatorValue = value.value;
    const updateSelectedFilter = cloneDeep(selectedFilter);
    updateSelectedFilter.data.comparisonOperator = comparisonOperatorValue;
    updateSelectedFilter.data.manualValue = '';
    const isBetween = comparisonOperatorValue === BETWEEN;
    setIsBetweenView(isBetween);
    updateFilter(updateSelectedFilter);
  };

  const manualValueInputChange = (event) => {
    const updateSelectedFilter = cloneDeep(selectedFilter);
    const manualValueAsNumber = extractNumberFromBudget(event.target.value);
    const manualValueAsCost = exchangeToUSD(manualValueAsNumber);
    updateSelectedFilter.data.manualValue = manualValueAsCost;
    updateFilter(updateSelectedFilter);
  };

  const rangeChangeHandle = ({ min, max }) => {
    const updateSelectedFilter = cloneDeep(selectedFilter);
    updateSelectedFilter.data.manualValue = [min, max];
    setRangeFilter({ rangeFrom: min, rangeTo: max });
    updateFilter(updateSelectedFilter);
  };

  const checkboxCustomDateChangeHandel = () => {
    if (isCustomDate) {
      const updateSelectedFilter = cloneDeep(selectedFilter);
      delete updateSelectedFilter.data.timeFrame;
      delete updateSelectedFilter.data.label;
      updateFilter(updateSelectedFilter);
    }
    setIsCustomDate((prev) => !prev);
  };

  const updateTimeFrameFilter = (selectedTimeframe) => {
    const updateSelectedFilter = { ...selectedFilter };
    updateSelectedFilter.data.timeFrame = {
      startTS: new Date(getTSForTimezone(selectedTimeframe.timeFrame.startTS)),
      endTS: new Date(getTSForTimezone(selectedTimeframe.timeFrame.endTS)),
      value: selectedTimeframe.timeFrame.value,
    };
    updateSelectedFilter.data.label = selectedTimeframe.label;
    updateFilter(updateSelectedFilter);
  };

  return (
    <div className={styles.filterConfig}>
      <div className={styles.filterContent}>
        <header className={styles.filterHeader}>
          Cost amount
        </header>
        <div className={styles.filterSpaces}>
          <div className={styles.rowCenter}>
            <Dropdown
              onChange={onChangeComparisonOperator}
              options={costAmountOptions}
              selectedKey={selectedFilter.data.comparisonOperator}
              controlWidth={210}
            />
            {!isBetweenView && (
              <Textfield
                value={selectedFilter.data.manualValue !== '' ? formatBudget(selectedFilter.data.manualValue) : ''}
                style={{ width: 200 }}
                onChange={manualValueInputChange}
              />
            )}
          </div>
          {isBetweenView && (
            <Range
              step={10}
              minValue={0}
              maxValue={maxRange}
              value={{ min: rangeFilter.rangeFrom, max: rangeFilter.rangeTo }}
              onChange={rangeChangeHandle}
              outerStyle={{
                marginTop: 15,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          )}
        </div>
        <div className={styles.funnelRow}>
          <Checkbox
            key="Custom date"
            checked={isCustomDate}
            onChange={checkboxCustomDateChangeHandel}
          >
            Custom date
          </Checkbox>
        </div>
        {isCustomDate && (
          <CustomDateSelect
            timeFrame={get(selectedFilter, 'data.timeFrame')}
            timeFrameLabel={get(selectedFilter, 'data.label')}
            updateFilter={updateTimeFrameFilter}
            isTitleShow={false}
            customDatePopupClass={styles.customDatePopup}
            timeframeSelectClass={styles.timeframeSelectClass}
          />
        )}
      </div>

      <FilterActions
        onApply={handleFilterApply}
        onCancel={onCancel}
        filtersItems={filtersItems}
      />
    </div>
  );
}

export default CampaignCostFilter;
