import React, { useEffect, useState } from 'react';
import { set } from 'lodash';
import { Button } from '@infinigrow/libs';

import Page from 'components/Page';
import Dropdown from 'components/controls/Dropdown';
import Textfield from 'components/controls/Textfield';
import Spinner from 'components/pages/journeys/Spinner';

import userStore from 'stores/userStore';
import planStore from 'stores/plan/planStore';
import servicesStore from 'stores/servicesStore';

import channelStyles from 'styles/settings/channels/channels-tab.css';

const styles = channelStyles.locals;

function CreateNewChannelPopup({
  title,
  categories,
  actionText,
  updateState,
  onClosePopup,
  sortedCategories,
  channelsByCategory,
  userChannelsSchema,
  onClickChannelItem,
  onClickCategoryItem,
  getChannelsWithProps,
}) {
  const [channelName, setChannelName] = useState('');
  const [channelCategory, setChannelCategory] = useState('');
  const [popupErrorMsg, setPopupErrorMsg] = useState(null);
  const [textFieldErrorMsg, setTextFieldErrorMsg] = useState(null);
  const [isCreateNewChannelLoading, setIsCreateNewChannelLoading] = useState(false);

  if (sortedCategories.indexOf(channelCategory) === -1 && channelCategory) {
    sortedCategories.push(channelCategory);
  }

  useEffect(() => {
    let isMounted = true;

    setPopupErrorMsg(null);
    setTextFieldErrorMsg(null);
    const selectedCategory = channelsByCategory[channelCategory];
    if (!selectedCategory) { return undefined; }

    const channelNameTrimmed = channelName.toLowerCase().trim();
    const existingChannelName = selectedCategory.some((channel) => channel.nickname.toLowerCase().trim() === channelNameTrimmed);
    if (isMounted && existingChannelName) {
      setTextFieldErrorMsg('This channel is already in use, please select another name');
    }

    return () => {
      isMounted = false;
    };
  }, [channelName, channelCategory]);

  async function addChannelRequest() {
    setPopupErrorMsg(null);
    setIsCreateNewChannelLoading(true);

    const { UID } = userStore.userMonthPlan;
    const body = {
      channelName,
      channel: { category: channelCategory },
    };

    try {
      const response = await planStore.updateChannelsSchema({ body });

      if (!response.ok) {
        setPopupErrorMsg('Failed to create a new channel.');
      }

      const responseData = await response.json();
      const {
        channelKind, channelType, department, nickname, category, erpCategory,
        channelIconURL, performanceChannel, crmCampaignField, isContinuous,
        isInbound, isMandatory, isUnknownChannel,
      } = responseData;

      const channelSchema = { ...userChannelsSchema };
      set(channelSchema, [nickname, 'category'], category);
      set(channelSchema, [nickname, 'nickname'], nickname);
      set(channelSchema, [nickname, 'isInbound'], isInbound);
      set(channelSchema, [nickname, 'department'], department);
      set(channelSchema, [nickname, 'channelType'], channelType);
      set(channelSchema, [nickname, 'erpCategory'], erpCategory);
      set(channelSchema, [nickname, 'channelKind'], channelKind);
      set(channelSchema, [nickname, 'isMandatory'], isMandatory);
      set(channelSchema, [nickname, 'isContinuous'], isContinuous);
      set(channelSchema, [nickname, 'channelIconURL'], channelIconURL);
      set(channelSchema, [nickname, 'crmCampaignField'], crmCampaignField);
      set(channelSchema, [nickname, 'isUnknownChannel'], isUnknownChannel);
      set(channelSchema, [nickname, 'performanceChannel'], performanceChannel);

      updateState({ userChannelsSchema: channelSchema, unsaved: false });

      const channelsWithProps = getChannelsWithProps();
      const selectedCategory = channelsWithProps[channelName]?.category;
      const selectedCategoryIndex = categories.indexOf(selectedCategory);

      if (selectedCategory !== undefined && selectedCategoryIndex !== -1) {
        onClickCategoryItem(selectedCategoryIndex, channelsByCategory[selectedCategory]);
      }
      onClickChannelItem(channelName, channelsWithProps);

      setPopupErrorMsg(null);
      onClosePopup();
    } catch (error) {
      setPopupErrorMsg('Something went wrong with creating a new channel.');
      servicesStore.logger.error('Error creating a new channel', {
        error,
        UID,
      });
    }
    setIsCreateNewChannelLoading(false);
  }

  const isButtonDisabled = !channelCategory || !channelName || !!textFieldErrorMsg || isCreateNewChannelLoading;

  return (
    <Page
      popup
      width="350px"
      wrapBoxStyle={styles.popupBox}
      contentClassName={styles.reportPopup}
    >
      <div className={styles.closePopupIcon} onClick={onClosePopup} />
      <div className={styles.popupTitle}>{title}</div>
      <div className={styles.popupFields}>
        <Dropdown
          isSearchable
          allowCreateNewOption
          value={channelCategory}
          selectedKey={channelCategory}
          placeholder="Select category"
          className={styles.popupDropdown}
          disabled={isCreateNewChannelLoading}
          promptTextCreator={(value) => `Add ${value} as a category`}
          options={sortedCategories.map((value) => ({ value, label: value }))}
          onChange={(e) => {
            if (e && e.value) {
              setChannelCategory(e.value);
            }
          }}
          onNewOptionClick={(e) => {
            if (e && e.value) {
              setChannelCategory(e.value);
            }
          }}
        />
        <Textfield
          value={channelName}
          placeHolder="Channel name"
          className={styles.popupTextfield}
          isShowError={!!textFieldErrorMsg}
          disabled={isCreateNewChannelLoading}
          inputErrorMessage={textFieldErrorMsg}
          onChange={(e) => setChannelName(e.target.value)}
        />
      </div>
      {isCreateNewChannelLoading && (
      <div className={styles.loadingSpinner}>
        <Spinner />
      </div>
      )}
      {popupErrorMsg && <div className={styles.popupErrorMsg}>{popupErrorMsg}</div>}
      <div className={styles.popupActionButton}>
        <Button
          type="primaryBlue"
          disabled={isButtonDisabled}
          onClick={() => addChannelRequest()}
        >
          {actionText}
        </Button>
      </div>
    </Page>
  );
}

export default CreateNewChannelPopup;
