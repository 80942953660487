import { onboardingTagsIds } from 'components/pages/analyze/enums';

export function setTagIdOnOptimizationMetricsByMetricType({ data }) {
  let taggedMetric = null;
  const conversionRateMetrics = data.filter((metric) => metric.metricType === 'conversionRate' && metric.total?.value !== 0);

  if (conversionRateMetrics.length > 0) {
    taggedMetric = conversionRateMetrics[0];
    for (const metric of conversionRateMetrics) {
      if (metric.total?.value < taggedMetric.total?.value) {
        taggedMetric = metric;
      }
    }
  } else {
    const velocityMetrics = data.filter((metric) => metric.metricType === 'velocity');
    if (velocityMetrics.length > 0) {
      taggedMetric = velocityMetrics[0];
      for (const metric of velocityMetrics) {
        if (metric.total?.value > taggedMetric.total?.value) {
          taggedMetric = metric;
        }
      }
    }
  }

  if (taggedMetric) {
    taggedMetric.tagId = onboardingTagsIds.selectedOptimizationMetric;
    const remainingMetrics = data.filter((metric) => metric._id !== taggedMetric._id);
    remainingMetrics.unshift(taggedMetric);
    return remainingMetrics;
  }

  return data;
}
