import userStore from 'stores/userStore';
import { generateMetricName } from 'widgetDefinitions/utils';
import { getPresets, metricExceptionFilter } from 'components/pages/analyze/OverviewTab/logic/HistoricalPerformance';
import base from './base';

function getWidgetConfig({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const baseConfig = base.getWidgetConfig({ widgetConfig });
  delete baseConfig.displayedMetrics;

  return baseConfig;
}

function getDisplayedMetricsForConfig({ widgetConfig }) {
  const userMetrics = userStore.getUserMetricsWithNew({
    exceptionFilter: metricExceptionFilter,
  });

  const presets = getPresets({ userMetrics });

  const { activeIndicator } = widgetConfig;
  if (activeIndicator) {
    for (const preset of presets) {
      if (preset.options.includes(activeIndicator)) {
        return preset.options;
      }
    }
    return [activeIndicator];
  }

  return presets[0].options;
}

function getWidgetConfigurationForQuestionResult({ widgetConfig }) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const baseConfig = base.getWidgetConfigurationForQuestionResult({ widgetConfig });
  if (!baseConfig.displayedMetrics) {
    baseConfig.displayedMetrics = getDisplayedMetricsForConfig({ widgetConfig: baseConfig });
  }
  baseConfig.displayedMetrics = baseConfig.displayedMetrics.map((metricName) => generateMetricName({ metricName }));

  return baseConfig;
}

export default {
  ...base,
  getWidgetConfig,
  getWidgetConfigurationForQuestionResult,
};
