import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import userStore from 'stores/userStore';

import BaseFilter from 'components/pages/users/Filters/BaseFilter';
import Component from 'components/Component';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import { ConfigPropType } from 'components/utils/filters';
import { compose } from 'components/utils/utils';
import { filterKinds } from 'components/utils/filters/logic';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

const enhance = compose(
  inject(({
    filterStore: {
      getFilterKindOptions,
    },
  }) => ({
    getFilterKindOptions,
  })),
  observer
);

class FunnelStagesFilter extends Component {
  static propTypes = {
    config: ConfigPropType.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAddFilter: PropTypes.func.isRequired,
  };

  render() {
    const { config, getFilterKindOptions } = this.props;
    const stageOptions = () => config.options.map((stage) => ({ value: stage, label: userStore.getMetricNickname({ metric: stage, isSingular: true }) }));
    return (
      <BaseFilter
        {...this.props}
        kind={filterKinds.FUNNEL_STAGES}
        isFunnelFilter
        stageOptions={stageOptions}
      >
        {({ selectedOptions, selectOptions }) => (
          <MultiCheckSelect
            className={styles.optionSelect}
            selected={selectedOptions.map((option) => ({ label: userStore.getMetricNickname({ metric: option, isSingular: true }), value: option }))}
            options={stageOptions()}
            placeholder="Search stage..."
            onChange={selectOptions}
            selectAll
            isAsyncPagination={config.isSearchable}
            searchFunction={(searchValue, offset) => getFilterKindOptions({
              searchValue, filterKind: config.kind, filterKey: config.fieldKey, offset,
            })}
          />
        )}
      </BaseFilter>
    );
  }
}

export default enhance(FunnelStagesFilter);
