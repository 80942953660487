exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".budget-channel-card__root-3EE{position:relative;display:inline-flex;width:auto;min-height:52px;padding:8px 16px;border:1px solid #b2bbd5;border-radius:4px;align-items:center;background-color:transparent;outline:none;font-family:Avenir Next,museo-sans,Source Sans Pro,sans-serif}.budget-channel-card__root-3EE:focus,.budget-channel-card__root-3EE:hover{cursor:pointer;background-color:#f6f7fb}.budget-channel-card__icon-2Yo{width:36px;height:36px}.budget-channel-card__title-2bv{font-size:14px;line-height:21px;font-weight:500;color:#222a41;text-transform:capitalize}.budget-channel-card__tooltip-2Bi{cursor:pointer;position:absolute;right:-4px;bottom:-8px}.budget-channel-card__tooltip-icon-2Q7{width:24px;height:24px;background:url(/assets/auto-integrated.svg)}.budget-channel-card__message-3S6{top:-8px;left:36px;position:absolute;display:none;background-color:#1c1c1c;padding:8px 12px;border-radius:4px;z-index:1}.budget-channel-card__message-3S6:before{position:absolute;content:\"\";width:11px;height:11px;background-color:#1c1c1c;left:-4px;top:12px;transform:rotate(45deg)}.budget-channel-card__tooltip-2Bi:hover>.budget-channel-card__message-3S6{display:block}.budget-channel-card__message-text-22g{color:#fff;font-size:13px;line-height:18px;font-weight:500;text-align:center;margin:0;white-space:nowrap;font-family:Avenir Next,museo-sans,Source Sans Pro,sans-serif}.budget-channel-card__defaultImg-346{width:36px;height:36px;background:url(/assets/channels-icons/other-icon.svg) 50% no-repeat;margin-right:10px}@media (max-width:600px){.budget-channel-card__message-text-22g{white-space:normal}}", ""]);

// exports
exports.locals = {
	"root": "budget-channel-card__root-3EE",
	"root": "budget-channel-card__root-3EE",
	"icon": "budget-channel-card__icon-2Yo",
	"icon": "budget-channel-card__icon-2Yo",
	"title": "budget-channel-card__title-2bv",
	"title": "budget-channel-card__title-2bv",
	"tooltip": "budget-channel-card__tooltip-2Bi",
	"tooltip": "budget-channel-card__tooltip-2Bi",
	"tooltip-icon": "budget-channel-card__tooltip-icon-2Q7",
	"tooltipIcon": "budget-channel-card__tooltip-icon-2Q7",
	"message": "budget-channel-card__message-3S6",
	"message": "budget-channel-card__message-3S6",
	"message-text": "budget-channel-card__message-text-22g",
	"messageText": "budget-channel-card__message-text-22g",
	"defaultImg": "budget-channel-card__defaultImg-346",
	"defaultImg": "budget-channel-card__defaultImg-346"
};