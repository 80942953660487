import React from 'react';

import useStyles from 'hooks/useStyles';

import { Checkbox } from '@infinigrow/libs';

import attributionStyles from 'styles/analyze/analyze-attribution-table.css';

const attributionStyle = attributionStyles.locals || {};

export default function CheckboxWithFrame({
  checked, onChange, text,
}) {
  useStyles([attributionStyles]);

  return (
    <div className={attributionStyle.hideNoValueWrapper}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        className={attributionStyle.checkboxLabel}
      >
        {text}
      </Checkbox>
    </div>
  );
}
