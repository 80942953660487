import attributionStore from 'stores/attributionStore';
import { makeChannelBeforeStageFilter, makeChannelsFilter, VARIANTS } from 'components/utils/filters/make';
import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';

export const navigateFromChannelsToJourneys = ({
  funnelStage, channelKey, isCategoryView, includeAttributionStoreFilters, timeFrame, timeFrameParams, filters = [], attributionModel,
}) => {
  const isDirect = channelKey === 'direct';
  const filtersForJourneys = filters;

  filtersForJourneys.push(makeChannelBeforeStageFilter([channelKey], funnelStage, VARIANTS.INCLUDE_ANY_OF, isCategoryView, [funnelStage], true));

  if (includeAttributionStoreFilters) {
    filtersForJourneys.push(...attributionStore.rawFilters);
  }
  if (isDirect) {
    filtersForJourneys.push(makeChannelsFilter(VARIANTS.INCLUDE_ANY_OF_EXACT, [channelKey]));
  }
  navigateToJourneys({
    indicator: funnelStage, timeFrame, timeFrameParams, attributionModel,
  }, filtersForJourneys);
};
