exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".popup__popup-cUS{position:absolute;left:0;top:0;width:auto;min-height:100px;background:#fff;border:1px solid #dbdce3;border-radius:4px;box-shadow:0 4px 12px rgba(24,32,51,.07);z-index:10}", ""]);

// exports
exports.locals = {
	"popup": "popup__popup-cUS",
	"popup": "popup__popup-cUS"
};