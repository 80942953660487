import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import Page from 'components/Page';
import Component from 'components/Component';
import Spinner from 'components/pages/journeys/Spinner';

import style from 'styles/onboarding/onboarding.css';

export default class ConfirmPopup extends Component {
  style = style;

  callback(userAnswer) {
    this.props.callback(userAnswer);
  }

  render() {
    const unsavedTitle = this.props.title || 'Are you sure you want to delete?';
    const description = this.props.description || null;
    const isTitleBold = this.props.isTitleBold || false;
    const cancelBtn = this.props.cancelBtn || 'Cancel';
    const confirmBtn = this.props.confirmBtn || 'Confirm';
    const loading = this.props.loading || false;
    const confirmButtonType = this.props.confirmButtonType || 'primaryRed';
    const shouldHideIcon = !isNil(this.props.isShowIcon) && !this.props.isShowIcon;

    return (
      <div data-testid={this.props.dataTestId} hidden={this.props.hidden}>
        <Page className={this.classes.popupIndex} popup width="350px">
          <div className={classNames(this.classes.closeIcon, this.classes.closePopup)} onClick={() => this.callback(true)} />
          {!shouldHideIcon ? (<div className={this.classes.unsavedPopupIcon} />) : null}
          <div className={this.classes.unsavedTitle}>
            {isTitleBold ? <b>{unsavedTitle}</b> : unsavedTitle}
          </div>
          {description && (
            <div className={this.classes.description}>
              {description}
            </div>
          )}
          {loading && (
          <div className={this.classes.spinnerCtn}>
            <Spinner />
          </div>
          )}
          <div className={this.classes.footerConfirmPopup}>
            <Button
              dataTestId={`${this.props.dataTestId}-cancel`}
              type="secondaryWhite"
              onClick={() => this.callback(true)}
            >
              {cancelBtn}
            </Button>
            <Button
              dataTestId={`${this.props.dataTestId}-confirm`}
              type={confirmButtonType}
              onClick={() => this.callback(false)}
            >
              {confirmBtn}
            </Button>
          </div>
        </Page>
      </div>
    );
  }
}
