import React from 'react';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import { calcGrowth } from 'components/utils/budget';
import { compose } from 'components/utils/utils';

import style from 'styles/analyze/trend-mark.css';

const enhance = compose(
  inject(({ attributionStore }) => ({
    isCompareToPreviousEnable: attributionStore?.previousTimeframe,
  }
  )),
  observer
);

class TrendMark extends Component {
  style = style;

  render() {
    const {
      number, prevNumber, isPercent, isLowerBetter, isCompareToPreviousEnable, showTrendMark = true,
    } = this.props;

    if (!showTrendMark || !isCompareToPreviousEnable) {
      return null;
    }

    const { growth, isDecline } = calcGrowth(number, prevNumber, isPercent);

    let dataNegative = isDecline;
    if (isLowerBetter) {
      dataNegative = isDecline ? undefined : true;
    }

    return (
      <div className={this.classes.center} style={style}>
        <div className={this.classes.historyArrow} data-decline={isDecline} data-negative={dataNegative} />
        {isFinite(growth)
        && (
        <div className={this.classes.historyGrow} data-decline={isDecline} data-negative={dataNegative}>
          {`${growth}%`}
        </div>
        )}
      </div>
    );
  }
}

export default enhance(TrendMark);
