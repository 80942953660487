import React from 'react';
import moment from 'moment';
import RcCalendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import RangeRcCalendar from 'rc-calendar/lib/RangeCalendar';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import CalendarLocaleUS from 'rc-calendar/lib/locale/en_US';
import _ from 'lodash';

import Component from 'components/Component';
import MenuButton from 'components/common/MenuButton';
import Tooltip from 'components/controls/Tooltip';
import { getDateFromUserTZ, getTSForTimezone, getStringifyDateWithUserTimeZone } from 'stores/analyze/timeUtils';

import timePickerStyle from 'rc-time-picker/assets/index.css';
import calendarStyle from 'rc-calendar/assets/index.css';
import style from 'styles/controls/calendar.css';

const format = 'MMM DD, YYYY HH:mm';
const datePlaceholder = 'Select date';

const timePickerElement = (timePickerPanelStyle) => (
  <TimePickerPanel
    className={timePickerPanelStyle}
    defaultValue={moment('00:00:00', 'HH:mm:ss')}
    showSecond={false}
  />
);

const CalendarLocale = { ...CalendarLocaleUS, monthFormat: 'MMMM' };

const now = moment().set({
  hour: 0, minute: 0, second: 0, millisecond: 0,
});
const defaultCalendarValue = now.clone().subtract(1, 'month');

const updateDateValueString = (dateValue, isDateRange = false) => {
  if (!dateValue) {
    return datePlaceholder;
  }
  if (isDateRange) {
    const [startDate, endDate] = dateValue;
    const startDateToString = `${moment(startDate).format(format)}`.replace(' 00:00', '');
    const endDateToString = `${moment(endDate).format(format)}`.replace(' 00:00', '');
    return `${startDateToString} - ${endDateToString}`;
  }
  return `${moment(dateValue).format(format)}`.replace(' 00:00', '');
};

export class CalendarWithTimePicker extends Component {
  style = style;

  styles = [calendarStyle, timePickerStyle];

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.value ? moment(getDateFromUserTZ(props.value)) : defaultCalendarValue,
    };
    if (!props.value) {
      this.onUpdateDateToValue(defaultCalendarValue);
    }
  }

  onChange = (value) => {
    this.setState({ selectedValue: value });
    this.onUpdateDateToValue(moment(value));
  };

  openCalendar = () => {
    this.refs.pickerDateAndTime.onVisibleChange(true);
  };

  onUpdateDateToValue = (datesValues) => {
    this.props.onUpdateDate({ target: { value: moment(getDateFromUserTZ(datesValues)) } });
  }

  render() {
    const dateLabel = updateDateValueString(this.state.selectedValue);
    const calendar = (
      <RcCalendar
        format={format}
        timePicker={timePickerElement(this.classes.timePickerPanel)}
        showToday={false}
        className={this.classes.rangeCalendarWithTime}
        dateInputPlaceholder={datePlaceholder}
        locale={CalendarLocale}
        showDateInput={false}
      />
    );

    return (
      <Tooltip
        place="top"
        tip={dateLabel}
        id={`toolttip-${dateLabel}`}
      >
        <DatePicker
          ref="pickerDateAndTime"
          calendar={calendar}
          value={this.state.selectedValue}
          onChange={this.onChange}
        >
          {() => (
            <MenuButton
              title={dateLabel}
              onClick={this.openCalendar}
              withArrowIndication={false}
              className={this.classes.menuButtonCalendarWithTime}
            />
          )}
        </DatePicker>
      </Tooltip>
    );
  }
}

export class RangeCalendarWithTime extends Component {
  style = style;

  styles = [calendarStyle, timePickerStyle];

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.value ? this.parseDateRangeValueToDate(props.value) : [defaultCalendarValue, now],
    };
    if (!props.value) {
      const datesValues = this.parseDateRangeToString([defaultCalendarValue, now]);
      this.onUpdateDateToValue(datesValues);
    }
  }

  parseDateRangeValueToDate = (value) => {
    const clearValue = value.replace(/'/g, '');
    const [startDate, endDate] = clearValue.split('AND');
    const clearStartDate = startDate.replace(' ', '');
    const startDateTZ = getStringifyDateWithUserTimeZone(clearStartDate);
    const endDateTZ = getStringifyDateWithUserTimeZone(endDate);
    return [moment(startDateTZ), moment(endDateTZ)];
  }

  parseDateRangeToString = (datesValues) => {
    const [startDate, endDate] = datesValues;
    const startDateTZ = getTSForTimezone(startDate);
    const endDateTZ = getTSForTimezone(endDate);
    const startDateUTC = moment(startDateTZ).utc().format();
    const endDateUTC = moment(endDateTZ).utc().format();
    return `'${startDateUTC}' AND '${endDateUTC}'`;
  }

  onSelectTimeValues = (selectedValue) => {
    this.setState({ selectedValue });
    const datesValues = this.parseDateRangeToString(selectedValue);
    this.onUpdateDateToValue(datesValues);
  };

  onUpdateDateToValue = (datesValues) => {
    this.props.onUpdateDate({ target: { value: datesValues } });
  }

  openCalendar = (isShow = true) => {
    this.refs.rangeDateAndTime.onVisibleChange(isShow);
  };

  render() {
    const dateLabel = updateDateValueString(this.state.selectedValue, true);
    const calendar = (
      <RangeRcCalendar
        ref={(ref) => this.calendar = ref}
        timePicker={timePickerElement(this.classes.timePickerPanelRange)}
        selectedValue={this.state.selectedValue}
        onChange={(value) => this.onSelectTimeValues(value)}
        onOk={() => this.openCalendar(false)}
        showToday={false}
        className={this.classes.rangeCalendarWithTime}
        dateInputPlaceholder={datePlaceholder}
        locale={CalendarLocale}
        showDateInput={false}
      />
    );

    return (
      <Tooltip
        place="top"
        tip={dateLabel}
        id={`toolttip-range-${dateLabel}`}
      >
        <DatePicker
          ref="rangeDateAndTime"
          calendar={calendar}
          value={this.state.selectedValue}
        >
          {() => (
            <MenuButton
              title={dateLabel}
              onClick={this.openCalendar}
              withArrowIndication={false}
              className={this.classes.menuButtonCalendarWithTime}
            />
          )}
        </DatePicker>
      </Tooltip>
    );
  }
}
