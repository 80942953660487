import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { compose } from 'components/utils/utils';
import { injectStyle } from 'react-toastify/dist/inject-style';

import history from 'history';
import Component from 'components/Component';
import Tooltip from 'components/controls/Tooltip';

import analyzeStyle from 'styles/analyze/analyze.css';
import selectStyle from 'styles/controls/select.css';
import setupStyle from 'styles/attribution/attribution-setup.css';
import style from 'styles/page.css';

const enhance = compose(
  inject(({
    attributionStore: {
      updateStoreByQueryParams,
    },
    analysisStore: {
      accountWithoutData,
    },
    widgetsAnalysisStore: {
      accountWithoutDataV2,
    },
  }) => ({
    accountWithoutData,
    accountWithoutDataV2,
    updateStoreByQueryParams,
  })),
  observer
);

export const AnalyzeContext = React.createContext({ permissions: {} });

class Analyze extends Component {
  style = style;

  styles = [analyzeStyle, setupStyle, selectStyle];

  componentDidMount() {
    injectStyle();
    this.props.updateStoreByQueryParams({ params: this.props.location?.query });
  }

  getTitle = () => {
    const tabName = get(this.props, 'children.props.route.tabName');
    const isJourneyTab = tabName === 'Journeys';
    if (isJourneyTab) {
      return (
        <Tooltip
          tip="In this view, you can see all journeys that had a funnel conversion in the selected time frame."
          id="analyze-journeys"
          TooltipProps={{
            className: this.classes.titleTooltip,
          }}
        >
          {`Analyze - ${tabName}`}
        </Tooltip>
      );
    }
    return `Analyze - ${tabName}`;
  };

  updateSavedFilters = (activeFilters, alias = '') => {
    const { savedFilters: prevFilters = [] } = this.props;
    const parsedFilers = activeFilters.map(({ config, data }) => ({ kind: config.kind, data }));
    const savedFilters = [...prevFilters, { filters: parsedFilers, alias }];
    this.props.updateUserMonthPlan({ savedFilters }, this.props.region);
  };

  updateUserMonthPlan = (body, dontSetState = true, isV2 = true) => {
    const { updateUserMonthPlan, region } = this.props;
    return updateUserMonthPlan(body, region, dontSetState, isV2);
  };

  toastMessageAccountWithoutData = () => (
    <>
      To start seeing actual data, please
      {' '}
      <span className={this.classes.toastWarningLink} onClick={() => history.push({ pathname: '/settings/profile/integrations' })}>connect your CRM</span>
    </>
  );

  toastWarningAccountWithoutData = () => {
    toast(this.toastMessageAccountWithoutData(), {
      className: this.classes.toastInfoWarning,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      toastId: 'toastWarning',
      icon: () => <img alt="toastWarning" src="/assets/analyze-icons/info-warning-icon.svg" />,
    });
  };

  render() {
    const {
      campaignKeyToNameMapping,
      CRMConfig,
      savedFilters = [],
      permissions,
      accountWithoutData,
      accountWithoutDataV2,
    } = this.props;

    if (accountWithoutData || accountWithoutDataV2) {
      this.toastWarningAccountWithoutData();
    }

    return (
      <AnalyzeContext.Provider
        value={{
          updateSavedFilters: this.updateSavedFilters,
          permissions,
          CRMConfig,
          savedFilters,
          campaignKeyToNameMapping,
          updateUserMonthPlan: this.updateUserMonthPlan,
        }}
      >
        <div className={classNames(this.classes.container, this.classes.analyzeContainer)}>
          {this.props.children}
        </div>
      </AnalyzeContext.Provider>
    );
  }
}

export default enhance(Analyze);
