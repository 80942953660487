import React from 'react';
import { inject, observer } from 'mobx-react';
import history from 'history';
import { isEmpty } from 'lodash';

import useStyles from 'hooks/useStyles';

import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';

import { getComponentAccordingWidgetType, getComponentProps } from 'components/pages/reports/logic/reports';

import style from 'styles/questions/ai-answers.css';
import questionsStyle from 'styles/questions/questions.css';

const styles = style.locals || {};
const questionsStyles = questionsStyle.locals || {};

function QuestionResults({ selectedQuestionWidgetsMetadataResults, questionWidgetsDataResults }) {
  useStyles([style, questionsStyle]);

  if (isEmpty(selectedQuestionWidgetsMetadataResults)) {
    history.push('/questions');
    return null;
  }

  const widgetProps = getComponentProps({ widgetData: { ...selectedQuestionWidgetsMetadataResults, ...questionWidgetsDataResults }, isInReports: false, isShowTimeframeSelect: true });
  const WidgetComponent = getComponentAccordingWidgetType({ widgetType: selectedQuestionWidgetsMetadataResults.type });

  return (
    <div className={questionsStyles.wrapper}>
      <WidgetComponent
        key={`widget-${selectedQuestionWidgetsMetadataResults.type}-${selectedQuestionWidgetsMetadataResults.id}`}
        {...widgetProps}
        isReadOnly
        isLoaded={questionWidgetsDataResults.isLoaded}
        isLoadedTrendData={questionWidgetsDataResults.isLoadedTrendData}
        emptyStateComponent={(
          <EmptyStateWithImage
            imageClassName={styles.answersEmptyStateImage}
            title="This question did not return any results"
            subTitle="Try changing the filters or the timeframe"
            height="460px"
            isContentCentered
          />
        )}
      />
    </div>
  );
}

export default inject(
  ({
    questionsStore: {
      selectedQuestionWidgetsMetadataResults,
      questionWidgetsDataResults,
    },
  }) => ({
    selectedQuestionWidgetsMetadataResults,
    questionWidgetsDataResults,
  }),
  observer
)(QuestionResults);
