exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".drawer__wrapper-2pE{display:flex;flex-direction:column;background:var(--colorsBackgroundsBackgroundWhite);gap:20px}.drawer__headerContainer-19-{display:flex;justify-content:space-between;align-items:center}.drawer__header-3wZ{display:flex;align-items:center;color:var(--colorsTextsTextGray);font-size:14px;white-space:nowrap;font-weight:600;gap:4px}.drawer__collapse-3pt{content:\"\";background:var(--colorsTextsTextGray);-webkit-mask:url(/assets/analyze-icons/expandIcon.svg) center center no-repeat;mask:url(/assets/analyze-icons/expandIcon.svg) center center no-repeat;height:20px;width:20px;transform:rotate(-90deg);cursor:pointer}.drawer__collapse-3pt.drawer__rotate-21Y{transform:rotate(90deg)}.drawer__content-3MS{display:flex;transition:all .4s ease;overflow:hidden;opacity:1}.drawer__content-3MS.drawer__collapsed-4vA{opacity:0;transition:all .4s ease}", ""]);

// exports
exports.locals = {
	"wrapper": "drawer__wrapper-2pE",
	"wrapper": "drawer__wrapper-2pE",
	"headerContainer": "drawer__headerContainer-19-",
	"headerContainer": "drawer__headerContainer-19-",
	"header": "drawer__header-3wZ",
	"header": "drawer__header-3wZ",
	"collapse": "drawer__collapse-3pt",
	"collapse": "drawer__collapse-3pt",
	"rotate": "drawer__rotate-21Y",
	"rotate": "drawer__rotate-21Y",
	"content": "drawer__content-3MS",
	"content": "drawer__content-3MS",
	"collapsed": "drawer__collapsed-4vA",
	"collapsed": "drawer__collapsed-4vA"
};