import { isNil } from 'lodash';
import userStore from 'stores/userStore';

import { indicatorsToNewMapping, VELOCITY_MAPPING_SUFFIX, CONVERSION_RATE_MAPPING_SUFFIX } from 'components/utils/indicators';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { makeFunnelStagesFilter, VARIANTS } from 'components/utils/filters/make';

export function getFunnelNameWithoutNew(funnelName) {
  if (funnelName.includes('newFunnel')) {
    return funnelName.replace('newFunnel', 'funnel');
  }
  return funnelName;
}

export function getPerformanceItemNavigationMenuParams({ indicator, widgetHeaderConfig = {}, indicatorsNewMapping = indicatorsToNewMapping() }) {
  if (!indicator) {
    return [];
  }

  const includeAttributionStoreFilters = isNil(widgetHeaderConfig);
  const widgetFilters = widgetHeaderConfig.filters || [];
  const funnelIndicators = Object.values(indicatorsNewMapping);
  const isFunnelIndicator = funnelIndicators.includes(indicator);
  const isVelocityIndicator = indicator.includes(VELOCITY_MAPPING_SUFFIX);
  const isConversionRateIndicator = indicator.includes(CONVERSION_RATE_MAPPING_SUFFIX);
  let conversionIndicator;
  let sourceFunnel;
  let destFunnel;
  const navigationMenuParams = [];

  if (isFunnelIndicator || isConversionRateIndicator || isVelocityIndicator) {
    let channelsSubtitle = `Analyze ${userStore.getMetricNickname({ metric: indicator })} attribution by channel`;
    let campaignsSubtitle = `Analyze ${userStore.getMetricNickname({ metric: indicator })} attribution by campaign`;
    for (const [funnel, newFunnel] of Object.entries(indicatorsNewMapping)) {
      if (newFunnel === indicator) {
        conversionIndicator = funnel;
        break;
      }
    }

    if (isFunnelIndicator) {
      navigationMenuParams.push({
        title: 'View journeys',
        subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} created during the timeframe`,
        navigationFunction: () => navigateBetweenAnalyzeTabs({
          filters: [...widgetFilters, makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [conversionIndicator])],
          tabName: 'journeys',
          includeAttributionStoreFilters,
          timeFrame: widgetHeaderConfig.timeFrame,
          attributionModel: widgetHeaderConfig.attributionModel,
        }),
      });
    }

    if (isVelocityIndicator) {
      channelsSubtitle = `Analyze ${userStore.getMetricNickname({ metric: sourceFunnel })} to ${userStore.getMetricNickname({ metric: destFunnel })} velocity by channel`;
      campaignsSubtitle = `Analyze ${userStore.getMetricNickname({ metric: sourceFunnel })} to ${userStore.getMetricNickname({ metric: destFunnel })} velocity by campaign`;
      conversionIndicator = destFunnel;
    }
    if (isConversionRateIndicator) {
      channelsSubtitle = `See the conversion rate from ${userStore.getMetricNickname({ metric: sourceFunnel })} to ${userStore.getMetricNickname({ metric: destFunnel })} per channel`;
      campaignsSubtitle = `Review the campaigns contributing to the conversion rate from ${userStore.getMetricNickname({ metric: sourceFunnel })} to ${userStore.getMetricNickname({ metric: destFunnel })}`;
      conversionIndicator = destFunnel;
    }

    navigationMenuParams.push(
      {
        title: 'Breakdown by channel',
        subTitle: channelsSubtitle,
        navigationFunction: () => navigateBetweenAnalyzeTabs({
          filters: widgetFilters,
          conversionIndicator,
          tabName: 'channels',
          anchor: 'attribution-table',
          includeAttributionStoreFilters,
          timeFrame: widgetHeaderConfig.timeFrame,
          attributionModel: widgetHeaderConfig.attributionModel,
        }),
      },
      {
        title: 'Breakdown by campaign',
        subTitle: campaignsSubtitle,
        navigationFunction: () => navigateBetweenAnalyzeTabs({
          filters: widgetFilters,
          conversionIndicator,
          tabName: 'campaigns',
          anchor: 'attribution-table',
          includeAttributionStoreFilters,
          timeFrame: widgetHeaderConfig.timeFrame,
          attributionModel: widgetHeaderConfig.attributionModel,
        }),
      }
    );
  }

  return navigationMenuParams;
}
