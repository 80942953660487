exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".drillDownPopup__wrapper-Tne{display:flex;flex-direction:column;gap:20px;max-height:80vh}.drillDownPopup__closeButton-1GO{position:absolute;top:12px;right:12px;width:32px;height:32px;background:url(/assets/close-icon-big.svg) 50% no-repeat;cursor:pointer;z-index:10}.drillDownPopup__row-2h_{display:flex;gap:8px;align-items:center}.drillDownPopup__text-3iz{color:#182033;line-height:20px}.drillDownPopup__header-3Jf{font-size:18px;font-weight:600}.drillDownPopup__switchText-oH8{color:#000;font-size:14px;font-weight:400}.drillDownPopup__icon-3qC{cursor:pointer;z-index:10}.drillDownPopup__arrowLeftIcon-38A{position:absolute;left:0;width:32px;height:32px;background:url(/assets/arrowLeftGrey.svg) 50% no-repeat}.drillDownPopup__arrowLeftIcon-38A:hover{cursor:pointer}.drillDownPopup__arrowLeftSmall-1Xw{width:20px;height:20px;background:url(/assets/arrowLeftBlueSmall.svg) 50% no-repeat}.drillDownPopup__arrowRightSmall-1BA{width:20px;height:20px;background:url(/assets/arrowRightBlueSmall.svg) 50% no-repeat}.drillDownPopup__directionText-1QY{color:#4d91fc;text-align:center;font-size:14px;font-weight:500;line-height:20px;-webkit-text-decoration-line:underline;text-decoration-line:underline}.drillDownPopup__eventTimeline-1SA{padding:0;overflow-y:auto}.drillDownPopup__content-7TI{margin:0;padding:32px;max-height:80vh;overflow-y:auto}.drillDownPopup__notClickableIndication-MhO{opacity:.5;cursor:default}.drillDownPopup__spinner-2fV{display:flex;justify-content:center}.drillDownPopup__viewAccountButton-2E0{margin-bottom:4px}", ""]);

// exports
exports.locals = {
	"wrapper": "drillDownPopup__wrapper-Tne",
	"wrapper": "drillDownPopup__wrapper-Tne",
	"closeButton": "drillDownPopup__closeButton-1GO",
	"closeButton": "drillDownPopup__closeButton-1GO",
	"row": "drillDownPopup__row-2h_",
	"row": "drillDownPopup__row-2h_",
	"text": "drillDownPopup__text-3iz",
	"text": "drillDownPopup__text-3iz",
	"header": "drillDownPopup__header-3Jf",
	"header": "drillDownPopup__header-3Jf",
	"switchText": "drillDownPopup__switchText-oH8",
	"switchText": "drillDownPopup__switchText-oH8",
	"icon": "drillDownPopup__icon-3qC",
	"icon": "drillDownPopup__icon-3qC",
	"arrowLeftIcon": "drillDownPopup__arrowLeftIcon-38A",
	"arrowLeftIcon": "drillDownPopup__arrowLeftIcon-38A",
	"arrowLeftSmall": "drillDownPopup__arrowLeftSmall-1Xw",
	"arrowLeftSmall": "drillDownPopup__arrowLeftSmall-1Xw",
	"arrowRightSmall": "drillDownPopup__arrowRightSmall-1BA",
	"arrowRightSmall": "drillDownPopup__arrowRightSmall-1BA",
	"directionText": "drillDownPopup__directionText-1QY",
	"directionText": "drillDownPopup__directionText-1QY",
	"eventTimeline": "drillDownPopup__eventTimeline-1SA",
	"eventTimeline": "drillDownPopup__eventTimeline-1SA",
	"content": "drillDownPopup__content-7TI",
	"content": "drillDownPopup__content-7TI",
	"notClickableIndication": "drillDownPopup__notClickableIndication-MhO",
	"notClickableIndication": "drillDownPopup__notClickableIndication-MhO",
	"spinner": "drillDownPopup__spinner-2fV",
	"spinner": "drillDownPopup__spinner-2fV",
	"viewAccountButton": "drillDownPopup__viewAccountButton-2E0",
	"viewAccountButton": "drillDownPopup__viewAccountButton-2E0"
};