import React from 'react';

import Component from 'components/Component';
import PardotPopupContainer from 'components/importCampaignsPopups/PardotPopupContainer';
import AuthorizationIntegrationPopup from 'components/common/AuthorizationIntegrationPopup';
import Textfield from 'components/controls/Textfield';
import Title from 'components/onboarding/Title';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';

import style from 'styles/onboarding/onboarding.css';
import salesForceStyle from 'styles/indicators/salesforce-automatic-popup.css';
import CRMStyle from 'styles/indicators/crm-popup.css';

export default class PardotCampaignsPopup extends Component {
  style = style;

  styles = [salesForceStyle, CRMStyle];

  constructor(props) {
    super(props);
    this.campaignImportRef = React.createRef();
    this.state = {
      pardotAccountId: '',
    };
  }

  open = () => {
    this.campaignImportRef.current.open();
  };

  sendPardotBusinessUID = () => new Promise((resolve, reject) => {
    const objectToSend = {};
    objectToSend.platform = 'pardot';
    objectToSend.accountId = this.state.pardotAccountId;

    serverCommunication.serverRequest('put',
      this.props.api,
      JSON.stringify(objectToSend),
      userStore.userMonthPlan.region)
      .then((response) => {
        if (response.ok) {
          resolve(false);
        } else if (response.status === 401) {
          history.push('/');
        } else {
          reject(new Error(`Error getting ${this.props.platformTitle} data`));
        }
      });
  });

  render() {
    const platformTitle = 'Pardot Business Unit ID';
    const popupContentText = [
      '1. Go to "Setup" in Salesforce.',
      '2. Go to "Pardot Account Setup" (using the Quick Find box).',
      <>
        3. Look for your &ldquo;Pardot Business Unit ID&ldquo;.
        <br />
        &nbsp;&nbsp;&nbsp; It will be a unique identifier string (e.g.0Uv1a0000008x6K).
      </>,
    ];
    const {
      setDataAsState, close, data, afterAuthorizationApi,
      loadingStarted, loadingFinished, updateState,
    } = this.props;
    return (
      <PardotPopupContainer
        setDataAsState={setDataAsState}
        close={close}
        data={data}
        apiEndpoint={afterAuthorizationApi}
        loadingStarted={loadingStarted}
        loadingFinished={loadingFinished}
        updateState={updateState}
      >
        {({
          afterAuthStep,
          afterDataRetrieved,
          objectsMapping,
        }) => (
          <AuthorizationIntegrationPopup
            objectsMapping={objectsMapping}
            ref={this.campaignImportRef}
            data={this.props.data}
            api={this.props.api}
            haveStepAfterAuthorizationBeforeMapping
            afterAuthorizationBeforeMappingStep={afterAuthStep}
            afterAuthorizationApi={afterAuthorizationApi}
            afterDataRetrieved={afterDataRetrieved}
            makeServerRequest={this.sendPardotBusinessUID}
            width="50%"
            innerClassName={salesForceStyle.locals.inner}
            contentClassName={salesForceStyle.locals.content}
            loadingStarted={loadingStarted}
            loadingFinished={loadingFinished}
            autorizationRef={this.refs}
            validateBeforeDone={() => true}
            doneButtonText="Save"
            platformTitle="Pardot"
            isLoading={this.props.isLoading}
          >
            <div>
              <Title className={salesForceStyle.locals.title} title={platformTitle} />
              <div className={salesForceStyle.locals.container}>
                {popupContentText.map((row) => (
                  <ui>{row}</ui>
                ))}
              </div>
              <Textfield
                placeHolder="Your Pardot Business Unit ID"
                value={this.state.pardotAccountId}
                className={salesForceStyle.locals.textField}
                onChange={(e) => {
                  this.setState({ pardotAccountId: e?.currentTarget?.value });
                }}
              />
              <div />
            </div>
          </AuthorizationIntegrationPopup>
        )}

      </PardotPopupContainer>
    );
  }
}
