exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".add-member-popup__content-270{margin:0;padding:24px 40px}.add-member-popup__inner-2iR{overflow:initial}.add-member-popup__title-649{font-size:22px;font-weight:600;color:var(--black);margin-bottom:24px}.add-member-popup__sub-title-sAA{margin-top:10px;font-size:15px;font-weight:500}.add-member-popup__errorMessage-3kX{display:flex;justify-content:center;color:#e43a54;font-size:14px;margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"content": "add-member-popup__content-270",
	"content": "add-member-popup__content-270",
	"inner": "add-member-popup__inner-2iR",
	"inner": "add-member-popup__inner-2iR",
	"title": "add-member-popup__title-649",
	"title": "add-member-popup__title-649",
	"sub-title": "add-member-popup__sub-title-sAA",
	"subTitle": "add-member-popup__sub-title-sAA",
	"errorMessage": "add-member-popup__errorMessage-3kX",
	"errorMessage": "add-member-popup__errorMessage-3kX"
};