import React, { useMemo } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { Button } from '@infinigrow/libs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import useStyles from 'hooks/useStyles';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';
import ScenarioBox from 'components/widgets/whatIf/ScenarioBox';
import EditableLabel from 'components/controls/EditableLabel';

import { getIsAllScenarioRulesAreFilled } from 'components/widgets/whatIf/logic/scenariosSettings';
import { baselineScenario, unclickableItemsKeys } from 'components/widgets/whatIf/enums';
import { getWidgetHashKey } from 'stores/logic/widgetsAnalysisStore';

import style from 'styles/analyze/what-if-scenarios.css';

const styles = style.locals || {};

function ScenariosSettings({
  isSettingsOpen,
  setIsSettingsOpen,
  scenarios,
  setScenarios,
  scenariosBaseData,
  onSaveScenarioSettings,
  kpiFocus,
  totalAmountScenariosData = {},
  isLoaded,
  isLoadedBaseData,
  sampleTimeframeMonths,
  setSampleTimeframeMonths,
  saveSampleTimeframeMonths,
  unsavedChangesByScenarioId,
  resetScenarioSettingsById,
  isEditingScenarios,
  setIsEditingScenarios,
  flags,
  timeframe,
}) {
  useStyles([style]);

  function onDeleteScenario({ scenarioIndex }) {
    const newScenarios = [...scenarios];
    newScenarios.splice(scenarioIndex, 1);
    setScenarios(newScenarios);

    const scenarioToDelete = scenarios[scenarioIndex];
    servicesStore.eventTracker.track({
      eventName: Events.whatIfDeletedScenario,
      properties: {
        scenarioType: scenarioToDelete.type,
        changesCount: scenarioToDelete.rules.length,
      },
    });

    if (scenarioToDelete.rules.length > 0) {
      for (const rule of scenarioToDelete.rules) {
        servicesStore.eventTracker.track({
          eventName: Events.whatIfSavedRuleInScenario,
          properties: {
            scenarioType: scenarioToDelete.type,
            channel: rule.channel,
            budgetChangeType: rule.budgetChangeOperator,
            budgetChangePercent: rule.budgetChange,
            isConvRateChanged: rule.conversionRate ? 'TRUE' : 'FALSE',
            isVelocityChanged: rule.velocity ? 'TRUE' : 'FALSE',
          },
        });
      }
    }
  }

  function onAddScenarioRule({ scenarioIndex, type }) {
    const newScenarios = [...scenarios];
    newScenarios[scenarioIndex].rules.push({ type });
    setScenarios(newScenarios);
  }

  function onSetScenario({ scenarioIndex, newScenario }) {
    const newScenarios = [...scenarios];
    newScenarios[scenarioIndex] = newScenario;
    setScenarios(newScenarios);
  }

  function onChangeIsSettingsOpen() {
    setIsEditingScenarios({});
    setIsSettingsOpen(!isSettingsOpen);
    if (!isEmpty(unsavedChangesByScenarioId)) {
      for (const scenarioId of Object.keys(unsavedChangesByScenarioId)) {
        resetScenarioSettingsById({ scenarioId });
      }
    }
  }

  function onClickSaveScenarioSettings() {
    onSaveScenarioSettings();

    for (const scenario of scenarios) {
      servicesStore.eventTracker.track({
        eventName: Events.whatIfSavedScenario,
        properties: {
          scenarioType: scenario.type || baselineScenario,
          changesCount: scenario.rules.length,
        },
      });
      if (scenario.rules.length > 0) {
        for (const rule of scenario.rules) {
          servicesStore.eventTracker.track({
            eventName: Events.whatIfSavedRuleInScenario,
            properties: {
              scenarioType: scenario.type || baselineScenario,
              channel: rule.channel,
              budgetChangeType: rule.budgetChangeOperator,
              budgetChangePercent: rule.budgetChange,
              isConvRateChanged: rule.conversionRate ? 'TRUE' : 'FALSE',
              isVelocityChanged: rule.velocity ? 'TRUE' : 'FALSE',
            },
          });
        }
      }
    }
  }

  const isAllScenarioRulesAreFilled = useMemo(() => getIsAllScenarioRulesAreFilled({ scenarios }), [scenarios]);
  const avgMonthlySpendKey = getWidgetHashKey({ widgetConfig: { sampleTimeframeMonths, timeframe } });

  return (
    <div className={classnames(styles.box, isSettingsOpen ? styles.settingsBoxOpen : styles.settingsBoxClose)}>
      <div
        className={styles.expandButton}
        onClick={() => onChangeIsSettingsOpen()}
        data-testid="expand-button"
      />
      <div>
        <div className={classnames(styles.sampleTimeframe, isSettingsOpen && styles.sampleTimeframeShow)}>
          <EditableLabel
            value={sampleTimeframeMonths}
            onChange={(event) => setSampleTimeframeMonths(event.target.value)}
            type="number"
            rightLabel="months"
            leftLabel="Sample period: Last"
            inputClassName={styles.sampleTimeframeInput}
            onClose={() => saveSampleTimeframeMonths()}
            isShowLabelOnEdit
            gap="4px"
            dataTestId="sample-timeframe"
            disableClicks={flags.whatIfUnclickableItems?.[unclickableItemsKeys.sampleTimeframe]}
          />
        </div>
        <div className={classnames(styles.settingsContent, isSettingsOpen && styles.settingsContentShow, isEmpty(isEditingScenarios) && styles.settingsContentFullHeight)}>
          {scenarios.map((scenario, index) => (
            <ScenarioBox
              key={scenario.id}
              scenariosBaseData={scenariosBaseData}
              scenario={scenario}
              setScenario={(newScenario) => onSetScenario({ scenarioIndex: index, newScenario })}
              onDeleted={() => onDeleteScenario({ scenarioIndex: index })}
              onAddRule={() => onAddScenarioRule({ scenarioIndex: index, type: scenario.type })}
              totalAmountScenariosData={totalAmountScenariosData}
              kpiFocus={kpiFocus}
              isLoaded={isLoaded}
              isLoadedBaseData={isLoadedBaseData}
              isEditingScenarios={isEditingScenarios}
              setIsEditingScenarios={setIsEditingScenarios}
              resetScenarioSettingsById={resetScenarioSettingsById}
              avgMonthlySpendKey={avgMonthlySpendKey}
            />
          ))}
        </div>
      </div>
      {!isEmpty(isEditingScenarios) ? (
        <div className={classnames(styles.saveButtonWrapper, isSettingsOpen && styles.saveButtonWrapperShow)}>
          <Button
            className={styles.saveButton}
            type="primaryBlue"
            disabled={!isAllScenarioRulesAreFilled || isEmpty(unsavedChangesByScenarioId)}
            onClick={() => onClickSaveScenarioSettings()}
            dataTestId="save-scenario-settings-button"
          >
            Save
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default withLDConsumer()(ScenariosSettings);
