exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".channel-list__channel-icon-14W{flex-grow:0;flex-shrink:0;width:36px;height:36px;margin:0 4px 0 0;background-size:contain;background-position:50%;background-repeat:no-repeat;border-radius:3px}.channel-list__other-icon-1Aw{display:flex;align-items:center;justify-content:center;margin:0;color:#4d91fc;font-size:11px;font-weight:600;background-color:#edf3fe}div[data-id=tooltip].channel-list__tooltip-4Z5{background-color:#fff;padding:16px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12);border:1px solid rgba(178,187,213,.5);opacity:1}div[data-id=tooltip].channel-list__tooltip-4Z5:after{content:\"\";display:block;width:8px;height:8px;position:absolute;top:-5px;left:50%;margin:0;transform:translate(-50%) rotate(135deg);background-color:#fff;border-top:none!important;border-right:none!important;border-left:1px solid rgba(178,187,213,.5)!important;border-bottom:1px solid rgba(178,187,213,.5)!important}.channel-list__tooltip-content-2U1{display:flex;align-items:center;font-size:14px}.channel-list__tooltip-content-2U1+.channel-list__tooltip-content-2U1{margin-top:8px}", ""]);

// exports
exports.locals = {
	"channel-icon": "channel-list__channel-icon-14W",
	"channelIcon": "channel-list__channel-icon-14W",
	"other-icon": "channel-list__other-icon-1Aw",
	"otherIcon": "channel-list__other-icon-1Aw",
	"tooltip": "channel-list__tooltip-4Z5",
	"tooltip": "channel-list__tooltip-4Z5",
	"tooltip-content": "channel-list__tooltip-content-2U1",
	"tooltipContent": "channel-list__tooltip-content-2U1"
};