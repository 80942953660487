export const FREQUENCY_VALUES = {
  MONTH: 0,
  DAY: 1,
  WEEK: 2,
  QUARTER: 3,
};

export const FREQUENCY_OPTIONS = {
  QUARTER: {
    value: FREQUENCY_VALUES.QUARTER,
    label: 'Quarter',
  },
  MONTH: {
    value: FREQUENCY_VALUES.MONTH,
    label: 'Month',
  },
  DAY: {
    value: FREQUENCY_VALUES.DAY,
    label: 'Day',
  },
  WEEK: {
    value: FREQUENCY_VALUES.WEEK,
    label: 'Week',
  },
};

export const FREQUENCY_MAP = {
  [FREQUENCY_VALUES.QUARTER]: 'quarter',
  [FREQUENCY_VALUES.MONTH]: 'month',
  [FREQUENCY_VALUES.WEEK]: 'week',
  [FREQUENCY_VALUES.DAY]: 'day',
};

export const frequencyMapping = {
  [FREQUENCY_VALUES.QUARTER]: 'dataPerQuarter',
  [FREQUENCY_VALUES.MONTH]: 'dataPerMonth',
  [FREQUENCY_VALUES.WEEK]: 'dataPerWeek',
  [FREQUENCY_VALUES.DAY]: 'dataPerDay',
};
