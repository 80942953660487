import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import useStyles from 'hooks/useStyles';

import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';
import ChannelIcon from 'components/common/ChannelIcon';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';

import style from 'styles/analyze/analyze.css';

const styles = style.locals || {};

export default function AttributionTableRowWithNavigationMenu({
  item,
  itemTitle,
  itemIcon,
  getNavigationMenuPopupParams,
  iconClassName,
  popupTopOffset = 40,
  isEnableNavigationMenu,
  shouldOpenNavigationMenuPopup = false,
}) {
  useStyles([style]);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(shouldOpenNavigationMenuPopup);
  const [popupOffset, setPopupOffset] = useState({
    top: undefined,
    left: undefined,
  });

  const titleRef = useRef(null);

  useEffect(() => {
    if (!shouldOpenNavigationMenuPopup) {
      setShowNavigationMenuPopup(false);
    }
  }, [getNavigationMenuPopupParams]);

  useEffect(() => {
    if (!titleRef.current) {
      return;
    }

    const { x, y } = titleRef.current.getBoundingClientRect();
    setPopupOffset({ top: y + popupTopOffset + window.scrollY, left: x });
  }, [showNavigationMenuPopup]);

  return (
    <div
      ref={titleRef}
      className={styles.wrapperTitle}
      onClick={isEnableNavigationMenu ? () => (setShowNavigationMenuPopup(true)) : null}
    >
      {itemIcon && (
        <ChannelIcon
          className={classNames(style.channelIcon, iconClassName)}
          channelIcon={itemIcon}
          channel={item}
        />
      )}

      <div className={classNames(styles.clickableTitle, showNavigationMenuPopup ? styles.clickableTitleActive : null, !isEnableNavigationMenu && styles.disableNavigationMenu)}>
        <EllipsisTooltip withPortal text={itemTitle}>
          {itemTitle}
        </EllipsisTooltip>

      </div>
      {isEnableNavigationMenu && showNavigationMenuPopup && createPortal(
        <div className={styles.navigationMenu} style={popupOffset}>
          <NavigationMenuPopup
            navigationOptions={getNavigationMenuPopupParams(item)}
            onClosePopup={() => setShowNavigationMenuPopup(false)}
          />
        </div>,
        document.body
      )}
    </div>
  );
}
