import serverCommunication from 'data/serverCommunication';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';

import { getXLSLib } from 'components/utils/excelExport';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import apiConfig from 'components/utils/Configuration';

export class ExportData {
  constructor() {
    this.exportLibs = {
      XLS: getXLSLib,
    };
    this.apiUrl = apiConfig.overrideURL ? apiConfig.overrideURL : window.location.hostname;
    this.protocol = apiConfig.overrideProtocol ? apiConfig.overrideProtocol : window.location.protocol;
  }

  async exportAnalyzeWidgetData({
    widget,
    config = {},
    selectedColumns,
    filter,
    parse,
    createSheet,
    fileName,
    dataToExport,
    eventData,
  }) {
    const { UID } = userStore.userMonthPlan;
    const region = attributionStore.isAccountMode ? userStore.userMonthPlan.accountViewRegion : userStore.userMonthPlan.region;
    const baseConfig = attributionStore.getAnalyzeBaseConfig({});
    let widgetData;

    if (dataToExport) {
      widgetData = dataToExport;
    } else {
      try {
        const body = {
          region,
          requestStoreParams: {
            attribution: baseConfig,
            widgets: [widget],
            widgetsConfig: {
              [widget]: [{ ...baseConfig, ...config }],
            },
          },
        };

        const response = await serverCommunication.serverRequest(
          'POST',
          'analysis/widgets',
          JSON.stringify(body),
          region
        );
        const responseData = await response.json();
        widgetData = responseData.data[widget];
      } catch (exception) {
        servicesStore.logger.error('failed to export widget data', {
          UID,
          region,
          widget,
          exception,
        });
      }
    }

    const filteredData = filter
      ? filter({
        data: widgetData,
        selectedColumns,
        leadSourcesIdToLabelMap: userStore.userAccount.leadSourcesIdToLabelMap,
      })
      : widgetData;
    const parsedData = parse({ data: filteredData, selectedColumns, widgetConfig: config });

    this.exportLibs.XLS().then((lib) => {
      createSheet({
        XLSX: lib, tableData: parsedData, fileName, widgetEventData: eventData,
      });
    });
  }

  async exportFunnelTransitionsData({ parse, createSheet }) {
    const { UID, region } = userStore.userMonthPlan;
    const body = {
      UID,
      region,
      startDate: new Date(getTSForTimezone(attributionStore.dateRange.startDate)),
      endDate: new Date(getTSForTimezone(attributionStore.dateRange.endDate)),
      requestStoreParams: {
        attribution: {
          filters: attributionStore.filters,
          autoFilter: attributionStore.autoFilter,
        },
      },
    };

    try {
      const response = await serverCommunication.serverRequest('POST', 'journeyExport', JSON.stringify(body));
      const responseData = await response.json();

      const parsedData = parse({ data: responseData });

      this.exportLibs.XLS().then((lib) => {
        createSheet({ XLSX: lib, tableData: parsedData });
      });
    } catch (exception) {
      servicesStore.logger.error('failed to export funnel transitions data', {
        UID,
        region,
        exception,
      });
    }
  }

  async exportPromptData({
    createSheet, promptType, isEncryptedMode, fileName,
  }) {
    const { UID, region } = userStore.userMonthPlan;
    const body = {
      prompt: promptType,
      encrypted: isEncryptedMode,
    };
    try {
      const serverResponse = await serverCommunication.serverRequest('POST', 'prompts/getPrompt', JSON.stringify(body), region);
      const exportData = await serverResponse.json();
      this.exportLibs.XLS().then((lib) => {
        createSheet({
          XLSX: lib, tableData: exportData, fileName, fileFormat: 'csv',
        });
      });
    } catch (exception) {
      servicesStore.logger.error('failed to export prompt data', {
        UID,
        region,
        exception,
      });
      throw new Error();
    }
  }

  async decryptedPromptData({
    createSheet, promptType, fileName, file,
  }) {
    const { UID, region } = userStore.userMonthPlan;
    const body = {
      prompt: promptType,
      csv: file,
    };
    try {
      const serverResponse = await serverCommunication.serverRequest('POST', 'prompts/decrypt', JSON.stringify(body), region);
      const exportData = await serverResponse.json();
      this.exportLibs.XLS().then((lib) => {
        createSheet({
          XLSX: lib, tableData: exportData, fileName, fileFormat: 'csv',
        });
      });
    } catch (exception) {
      servicesStore.logger.error('failed to decrypt prompt data', {
        UID,
        region,
        exception,
      });
      throw new Error();
    }
  }
}

export default new ExportData();
