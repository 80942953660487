export function getParseJourneyImpactByChartData({ impactByChartData = [] }) {
  const chartData = [];
  const dataNames = new Set();

  const minPercentValue = 0.03;
  for (const itemData of impactByChartData) {
    const itemPerSegment = {};
    for (const segmentData of itemData.interactionPerSegment) {
      if (segmentData.segmentValue == null) {
        continue;
      }

      if (segmentData.interactions / itemData.interactions <= minPercentValue) {
        itemPerSegment.others = (itemPerSegment.others || 0) + segmentData.interactions;
      } else {
        itemPerSegment[segmentData.segmentValue] = segmentData.interactions || 0;
        dataNames.add(segmentData.segmentValue);
      }
    }
    dataNames.add('others');
    itemPerSegment.total = itemData.interactions || 0;
    itemPerSegment.name = itemData.groupName;
    chartData.push(itemPerSegment);
  }
  return { chartData, dataNames: Array.from(dataNames) };
}
