import React, { useContext, createContext, useState } from 'react';
import { parseActiveFilter, getEmptyRuleFields } from './logic/CampaignsContext';

export const CampaignsContext = createContext();

export const useCampaignsContext = () => useContext(CampaignsContext);

const CampaignsContextProvider = ({ children, values }) => {
  const activeFilter = parseActiveFilter(values.activeFilter);
  const [campaignsGroupData, setCampaignsGroupData] = useState(activeFilter);

  const onAddAndRulesFieldHandel = (rulesGroupIndex) => {
    const updateCampaignsGroupData = { ...campaignsGroupData };
    updateCampaignsGroupData.rules[rulesGroupIndex].push(getEmptyRuleFields(values.filtersConfig));
    setCampaignsGroupData(updateCampaignsGroupData);
    values.updateFilter(updateCampaignsGroupData);
  };

  const onAddOrRulesFieldHandel = () => {
    const updateCampaignsGroupData = { ...campaignsGroupData };
    updateCampaignsGroupData.rules.push([getEmptyRuleFields(values.filtersConfig)]);
    setCampaignsGroupData(updateCampaignsGroupData);
    values.updateFilter(updateCampaignsGroupData);
  };

  const handelDeleteRules = (rulesGroupIndex, rowIndex) => {
    const updateCampaignsGroupData = { ...campaignsGroupData };
    const updateRulesItems = updateCampaignsGroupData.rules[rulesGroupIndex];
    const isHavingOneRuleInGroup = updateRulesItems.length === 1;
    if (isHavingOneRuleInGroup) {
      if (values.isPreDefineFilter && updateCampaignsGroupData.rules.length === 1) {
        updateCampaignsGroupData.rules.splice(rulesGroupIndex, 1, [getEmptyRuleFields(values.filtersConfig)]);
        updateCampaignsGroupData['removeFormRequest'] = true;
      } else {
        updateCampaignsGroupData.rules.splice(rulesGroupIndex, 1);
      }
    } else {
      updateRulesItems.splice(rowIndex, 1);
      updateCampaignsGroupData.rules[rulesGroupIndex] = updateRulesItems;
    }
    values.updateFilter(updateCampaignsGroupData);
    setCampaignsGroupData(updateCampaignsGroupData);
  };

  const handelPlatformChange = (value) => {
    const updateCampaignsGroupData = { ...campaignsGroupData };
    if (value) {
      updateCampaignsGroupData.platform = value.value;
      updateCampaignsGroupData.rules = [[getEmptyRuleFields(values.filtersConfig)]];
    } else {
      updateCampaignsGroupData.platform = null;
      updateCampaignsGroupData.rules = [];
    }
    setCampaignsGroupData(updateCampaignsGroupData);
    values.updateFilter(updateCampaignsGroupData);
  };

  const updateRulesParam = (fieldKey, value, rulesGroupIndex, rowIndex) => {
    const updateCampaignsGroupData = { ...campaignsGroupData };
    updateCampaignsGroupData.rules[rulesGroupIndex][rowIndex][fieldKey] = value;
    updateCampaignsGroupData['removeFormRequest'] = false;
    setCampaignsGroupData(updateCampaignsGroupData);
    values.updateFilter(updateCampaignsGroupData);
  };

  const campaignsContextObject = {
    ...values,
    campaignsGroupData,
    setCampaignsGroupData,
    onAddAndRulesFieldHandel,
    onAddOrRulesFieldHandel,
    handelDeleteRules,
    handelPlatformChange,
    updateRulesParam,
  };

  return (
    <CampaignsContext.Provider value={campaignsContextObject}>
      {children}
    </CampaignsContext.Provider>
  );
};

export default CampaignsContextProvider;
