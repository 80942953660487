import React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Checkbox } from '@infinigrow/libs';

import Popup, { TextContent } from 'components/pages/plan/Popup';
import PropTypes from 'prop-types';
import Component from 'components/Component';
import ManageColumnsButton from 'components/pages/plan/ManageColumnsButton';
import SearchInput from 'components/controls/SearchInput';

import style from 'styles/users/users.css';

class CheckboxListPopup extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: props.selectedOptions,
      options: props.options,
    };

    this.popupRef = React.createRef();
    this.refSearchInput = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onOutsideClick, true);
    document.addEventListener('touchstart', this.onOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideClick, true);
    document.removeEventListener('touchstart', this.onOutsideClick, true);
  }

  onOutsideClick = (e) => {
    const popupRef = this.popupRef.current;
    if (popupRef !== e.target && !popupRef.contains(e.target)) {
      this.onClose();
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.options, this.props.options)) {
      this.setState({ options: nextProps.options });
    }
    if (!isEqual(nextProps.selectedOptions, this.props.selectedOptions)) {
      this.setState({ selectedItems: nextProps.selectedOptions });
    }
  }

  handleSearchOptionsQueryChange = (searchQuery) => {
    const updatedOptions = this.props.options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
    this.setState({ options: updatedOptions });
  };

  openPopup = () => {
    this.popup.open();
  };

  onSave = () => {
    const { onSave } = this.props;
    const { selectedItems } = this.state;

    onSave(selectedItems);
    this.popup.close();
  };

  onClose = () => {
    const { selectedOptions } = this.props;

    this.setState({
      selectedItems: [...selectedOptions],
    }, this.popup.close());
  };

  handleToggleColumn = (toggledColVal) => {
    const { selectedItems } = this.state;
    const updatedSelectedItems = [...selectedItems];
    const index = updatedSelectedItems.findIndex((col) => col === toggledColVal);
    if (index >= 0) {
      updatedSelectedItems.splice(index, 1);
    } else {
      updatedSelectedItems.push(toggledColVal);
    }
    this.setState({
      selectedItems: updatedSelectedItems,
    });
  };

  render() {
    const {
      renderButton, title, subTitle, withSearch = false,
    } = this.props;
    const { selectedItems, options } = this.state;

    return (
      <div className={this.classes.popupWrapper} ref={this.popupRef}>
        {renderButton
          ? <div onClick={this.openPopup}>{renderButton}</div>
          : (
            <ManageColumnsButton
              onClick={this.openPopup}
              relative
            />
          )}

        <Popup
          ref={(el) => { this.popup = el; }}
          title={title}
          className={classNames(this.classes.tablePopup, this.props.className)}
          onClose={this.onClose}
          primaryButton={{
            text: 'Save',
            onClick: this.onSave,
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: this.onClose,
          }}
        >
          {subTitle && (
          <div className={this.classes.popupContentColumnTitle}>
            {subTitle}
          </div>
          )}
          { withSearch
            && (
              <>
                <SearchInput
                  ref={this.refSearchInput}
                  debounce={0}
                  hideSearchIcon
                  onSearch={this.handleSearchOptionsQueryChange}
                  placeholder="Search..."
                  classes={{ input: classNames(this.classes.searchInput, this.props.searchInputClass), label: this.classes.searchLabel }}
                />
                {options.length === 0 && (
                <div className={this.classes.notingFound}>
                  Noting Found
                </div>
                )}
              </>

            )}
          <TextContent>
            <div className={this.classes.popupContentContainer}>
              <div className={this.classes.popupContentColumn}>

                {options.map(({ label, value }) => (
                  <Checkbox
                    key={value}
                    checked={selectedItems.findIndex((c) => c === value) !== -1}
                    onChange={() => this.handleToggleColumn(value)}
                    className={this.classes.checkboxContainer}
                  >
                    {label}
                  </Checkbox>
                ))}
              </div>
            </div>
          </TextContent>
        </Popup>
      </div>
    );
  }
}

CheckboxListPopup.defaultProps = {
  onSave: () => {},
  options: [],
};

CheckboxListPopup.propTypes = {
  onSave: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.node,
      hide: PropTypes.bool,
    })
  ),
};

export default CheckboxListPopup;
