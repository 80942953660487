export const rightY = 'right';

export const leftY = 'left';

export const trendAnalysisInitialMetrics = [
  {
    metricType: 'funnelStage',
    metricName: 'funnel2',
  },
  {
    metricType: 'channelCost',
    metricName: 'total',
  },
];

export const CACMetrics = ['CACPaybackPeriod', 'CAC'];

export const columnTypesForExport = {
  frequency: 'frequency',
  metric: 'metric',
};
