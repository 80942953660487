import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import Tooltip from 'components/controls/Tooltip';

import checkStyle from 'styles/controls/multi-check-select.css';

const styles = checkStyle.locals || {};

function RadioSelect({
  options = [],
  selected,
  placeholder,
  maxWidth,
  maxHeight,
  onChange,
  className,
  isClearable,
  controlWidth,
  isSearchable = true,
}) {
  useEffect(() => {
    checkStyle.use();
    return () => {
      if (!checkStyle.unuse) {
        return;
      }
      checkStyle.unuse();
    };
  }, []);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: '#222a41',
      backgroundColor: 'transparent',
      padding: '7px 0px',
      fontSize: '14px',
      fontWeight: 'normal',
      display: 'flex',
    }),
    control: (provided) => ({
      ...provided,
      maxWidth,
      borderColor: '#DCE1EF',
      color: '#707EA7',
      width: controlWidth,
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'unset',
      position: 'unset',
      maxHeight: maxHeight || 400,
      overflow: 'auto',
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  function Option(props) {
    const tooltipContent = props.data.tooltip || props.label;
    return (
      <components.Option {...props}>
        <Tooltip
          tip={tooltipContent}
          id={`option-label-${tooltipContent}`}
          place="right"
        >
          <Checkbox
            key={`customCheckbox-${props.label}`}
            checked={props.isSelected || props.data.required}
            onChange={() => null}
            type="radio"
            disabled={props.isDisabled}
          >
            <label className={classNames(styles.optionLabel, props.isDisabled && styles.optionLabelDisabled)}>
              {props.label}
            </label>
          </Checkbox>
        </Tooltip>
      </components.Option>
    );
  }

  const overrideComponents = {
    Option,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  return (
    <div className={className}>
      <Select
        closeMenuOnSelect={false}
        isMulti={false}
        components={overrideComponents}
        value={selected}
        options={options}
        styles={customStyles}
        hideSelectedOptions={false}
        menuIsOpen
        placeholder={placeholder}
        onChange={onChange}
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
    </div>
  );
}

export default RadioSelect;
