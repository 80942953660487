exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".users-spinner__loader--14{width:30px;height:30px;border-radius:50%;background:radial-gradient(farthest-side,#4d91fc 100%,#0000) top/4px 4px no-repeat,conic-gradient(#0000 30%,#4d91fc);-webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);animation:users-spinner__spinner-c7wet2-Xn5 1s infinite linear}.users-spinner__white-2nf{background:radial-gradient(farthest-side,var(--white) 100%,var(--black)) top/4px 4px no-repeat,conic-gradient(var(--black) 30%,var(--black))}.users-spinner__small-3Je{width:25px;height:25px}@keyframes users-spinner__spinner-c7wet2-Xn5{to{transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"loader": "users-spinner__loader--14",
	"loader": "users-spinner__loader--14",
	"spinner-c7wet2": "users-spinner__spinner-c7wet2-Xn5",
	"spinnerC7Wet2": "users-spinner__spinner-c7wet2-Xn5",
	"white": "users-spinner__white-2nf",
	"white": "users-spinner__white-2nf",
	"small": "users-spinner__small-3Je",
	"small": "users-spinner__small-3Je"
};