import React from 'react';
import Component from 'components/Component';
import memoize from 'memoize-one';
import {
  orderBy, sum, transform, maxBy, omit, isEmpty, isNumber,
} from 'lodash';
import PropTypes from 'prop-types';

const MIN_PERCENT_VALUE = 0.01; // if value of item < 0.01, group it to others

class GeneratedImpactContainer extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
  };

  getData = memoize((rawData) => {
    const {
      isCumulativeMetric = true, totalByFrequency = {}, totalBySegment = {},
    } = this.props;

    let optimizedDataWithTotal = [];
    let dataArray = [];
    let nonCumulativeDataArray = [];
    let dataArrayWithoutOthers = [];
    let filteredTotalBySegment = {};

    if (isEmpty(totalBySegment)) {
      filteredTotalBySegment = rawData.reduce((acc, dataItem) => {
        Object.keys(omit(dataItem, 'name')).forEach((key) => {
          acc[key] = !acc[key] ? dataItem[key] : (acc[key] + dataItem[key]);
        });
        return acc;
      }, {});
    } else {
      for (const [segmentKey, segmentValue] of Object.entries(totalBySegment)) {
        const isSegmentExists = rawData.some(raw => Object.keys(raw).includes(segmentKey));
        if (!isSegmentExists) {
          continue;
        }
        filteredTotalBySegment[segmentKey] = segmentValue;
      }
    }

    const total = sum(Object.values(filteredTotalBySegment));

    if (isCumulativeMetric) {
      const totalByKeys = Object.keys(filteredTotalBySegment).reduce((acc, key) => {
        const value = filteredTotalBySegment[key];
        if (value / total <= MIN_PERCENT_VALUE) {
          acc.others = (acc.others || 0) + value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      dataArray = this.parseDataObject({ totalByKeys });
    }

    const totalByKeysWithoutOthers = Object.keys(filteredTotalBySegment).reduce((acc, key) => {
      const value = filteredTotalBySegment[key];
      acc[key] = value;
      return acc;
    }, {});

    dataArrayWithoutOthers = this.parseDataObject({ totalByKeys: totalByKeysWithoutOthers });

    const optimizedData = rawData.map(dataItem => transform(dataItem, (rs, value, key) => {
      if (key !== 'name' && !isNaN(value) && !totalByKeysWithoutOthers[key]) {
        rs.others = (rs.others || 0) + value;
      } else {
        rs[key] = value;
      }
    }));

    optimizedDataWithTotal = optimizedData.map((item) => {
      const { name, ...rest } = item;
      return {
        ...item,
        total: sum(Object.values(rest)),
      };
    });

    if (!isCumulativeMetric) {
      optimizedDataWithTotal = [];
      const isHavingOneMetricData = rawData.every(raw => Object.keys(raw).length <= 2);
      if (isHavingOneMetricData) {
        for (const raw of rawData) {
          const totalValue = Object.values(raw).find(element => isNumber(element));
          optimizedDataWithTotal.push({
            name: raw.name,
            total: totalValue,
          });
        }
      } else {
        for (const [frequency, totalValue] of Object.entries(totalByFrequency)) {
          optimizedDataWithTotal.push({
            name: frequency,
            total: totalValue,
          });
        }
      }

      nonCumulativeDataArray = [{
        name: 'total',
        value: total,
        bar: { name: 'total', value: total },
      }];
    }

    const maxValue = maxBy(dataArray, 'value');
    const maxValueWithoutOthers = maxBy(dataArrayWithoutOthers, 'value');

    return {
      total,
      optimizedDataWithTotal,
      dataArray,
      dataArrayWithoutOthers,
      maxValue,
      maxValueWithoutOthers,
      nonCumulativeDataArray,
    };
  });

  parseDataObject = ({ totalByKeys }) => {
    const updatedDataObject = Object.keys(totalByKeys).map(key => ({
      name: key,
      value: totalByKeys[key],
      bar: { name: key, value: totalByKeys[key] },
    }));
    const orderDataArray = orderBy(updatedDataObject, ['value', 'name'], ['desc', 'asc']);
    return orderDataArray;
  }

  render() {
    const {
      children, data = [], prevData = [],
    } = this.props;
    const {
      total, dataArray, optimizedDataWithTotal, maxValue, dataArrayWithoutOthers, maxValueWithoutOthers, nonCumulativeDataArray,
    } = this.getData(data || []);
    const prevChartData = this.getData(prevData || []);
    return children({
      total,
      dataArray,
      dataArrayWithoutOthers,
      optimizedDataWithTotal,
      prevChartData,
      maxValue,
      maxValueWithoutOthers,
      nonCumulativeDataArray,
    });
  }
}
export default GeneratedImpactContainer;
