import { isNil } from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import userStore from 'stores/userStore';
import { budgetTypeMapping } from 'components/utils/date';
import { formatBudgetShortened } from 'components/utils/budgetFormat';
import { numberOrZero, formatNumberWithDecimalPoint } from 'components/utils/logic/budget';

export function formatNumber(budget, round, decimalCount = 2) {
  const needsDecimalFormatting = round && budget % 1 !== 0;
  if (budget == null) {
    return '';
  }
  if (budget.toString().endsWith('.00')) {
    return budget.toString().replace('.00', '');
  }
  if (needsDecimalFormatting) {
    const decimalMultiplier = 10 ** decimalCount;
    const roundedNumber = Math.round(budget * decimalMultiplier) / decimalMultiplier;
    return roundedNumber.toLocaleString(undefined, {
      minimumFractionDigits: decimalCount,
      maximumFractionDigits: decimalCount,
    });
  }
  return budget.toLocaleString();
}

export function formatBudget(budget, withCurrenySymbol = true, withShortened = false, withSign = false, onlyNegativeSign = false, withRound = true) {
  if (isNil(budget)) {
    return '';
  }

  let sign = '';

  if (withSign) {
    sign = (budget > 0) ? `${onlyNegativeSign ? '' : '+'}` : (budget < 0 ? '-' : '');
  }

  const currencySymbol = getCurrencySymbol();

  const exchangedBudget = exchangeUSDTo(budget);

  let shortenedBudget = exchangedBudget;
  if (withShortened) {
    shortenedBudget = withRound ? formatBudgetShortened(Math.abs(Math.round(exchangedBudget))) : formatBudgetShortened(formatNumberWithDecimalPoint(Math.abs(exchangedBudget)));
  } else {
    shortenedBudget = formatNumber(Math.abs(Math.round(exchangedBudget)));
  }

  if (withCurrenySymbol) {
    return `${currencySymbol}${sign}${shortenedBudget}`;
  } else {
    return `${sign}${shortenedBudget}`;
  }
}

export function getCurrencySymbol() {
  const currency = userStore.userMonthPlan?.currency;

  if (!currency) {
    return '$';
  } else {
    const symbol = getSymbolFromCurrency(currency);
    if (!symbol) {
      return '$';
    } else {
      return symbol;
    }
  }
}

export function exchangeUSDTo(usdvalue) {
  const { currencies, userMonthPlan } = userStore;
  const rates = currencies?.rates;
  if (!userMonthPlan?.currency || !rates) {
    return usdvalue;
  } else {
    return usdvalue * rates[userMonthPlan.currency];
  }
}

export function exchangeToUSD(value) {
  const { currencies, userMonthPlan } = userStore;
  const rates = currencies?.rates;
  if (!userMonthPlan?.currency || !rates) {
    return value;
  } else {
    return value / rates[userMonthPlan.currency];
  }
}

export function extractNumberFromBudget(budget, defaultValue = 0) {
  if (budget) {
    return parseInt(budget.toString().replace(/\D+/g, ''), 10) || defaultValue;
  }
  return defaultValue;
}

export function getAnnualBudget(annualBudgetArray, budgetType, annualBudgetFiscalCurrent, annualBudgetFiscalNext) {
  const annualBudgetArrSum = annualBudgetArray.reduce((a, b) => a + b, 0);
  const budgetFiscalNext = annualBudgetFiscalNext || annualBudgetFiscalCurrent;
  return budgetType === budgetTypeMapping.agile ? annualBudgetArrSum : ((budgetType === budgetTypeMapping.fiscalNext ? budgetFiscalNext : annualBudgetFiscalCurrent) || annualBudgetArrSum);
}

export function roiFormatter(number) {
  return `${formatNumberWithDecimalPoint(numberOrZero(number))}x`;
}

export function conversionRateFormatter(number) {
  return `${formatNumberWithDecimalPoint(number)}%`;
}

export const sign = (v) => (v > 0 ? '+' : '');

export const calcGrowth = (current, prev, isPercent) => {
  if (isPercent) {
    return {
      growth: +formatNumber(Math.abs(current - prev), true),
      isDecline: current < prev ? true : undefined,
    };
  }

  const fraction = current / prev;
  const condition = prev > current;
  const isDecline = condition ? true : undefined;
  const growthRaw = Math.abs(fraction - 1) * 100;
  const growth = growthRaw < 1 ? Math.round(growthRaw * 100) / 100 : Math.round(growthRaw);
  return {
    growth, isDecline,
  };
};
