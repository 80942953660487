import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Component from 'components/Component';
import style from 'styles/controls/search-input.css';
import classnames from 'classnames';

const classes = style.locals || {};

class SearchInput extends Component {
  style = style;

  static propTypes = {
    defaultValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    debounce: PropTypes.number,
    classes: PropTypes.shape({
      label: PropTypes.string,
      searchIcon: PropTypes.string,
      input: PropTypes.string,
      closeIcon: PropTypes.string,
    }),
  };

  static defaultProps = {
    defaultValue: '',
    debounce: 800,
    classes: {
      label: '',
      searchIcon: '',
      input: '',
      closeIcon: '',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      search: this.props.defaultValue,
    };
    this.handleSearch = debounce(this._handleSearch, props.debounce);
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultValue: prevDefaultValue } = prevProps;
    const { defaultValue } = this.props;

    const { search: oldSearch } = prevState;
    const { search } = this.state;

    if (search !== oldSearch) {
      this.handleSearch(search);
    }

    if (defaultValue !== prevDefaultValue) {
      this.setState({ search: defaultValue });
    }
  }

  _handleSearch = (value) => this.props.onSearch(value.toLowerCase().trim());

  handleSearchQueryChange = ({ target: { value: search } }) => this.setState({ search });

  handleSearchQueryClear = () => this.setState({ search: '' });

  render() {
    const { search } = this.state;
    return (
      <label className={classnames(classes.search, this.props.classes.label, this.props.disabled ? classes.disabled : null)}>
        {this.props.hideSearchIcon ? null : <div className={classnames(classes.searchIcon, this.props.classes.searchIcon)} />}
        <input
          data-testid={this.props.dataTestId}
          className={classnames(classes.searchInput, this.props.classes.input)}
          value={search}
          onChange={this.handleSearchQueryChange}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
        />
        {search
        && (
        <div
          className={classnames(classes.searchClear, this.props.classes.closeIcon)}
          onClick={this.handleSearchQueryClear}
        />
        )}
      </label>
    );
  }
}

export default SearchInput;
