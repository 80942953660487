import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Area, CartesianGrid, Tooltip, XAxis, YAxis, Bar, ComposedChart, ReferenceArea,
} from 'recharts';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import CustomRadio from 'components/controls/CustomRadio';

import { formatBudgetShortened } from 'components/utils/budgetFormat';
import { getBlueColor, getLightBlueColor } from 'components/utils/colors';
import { compose } from 'components/utils/utils';
import { getFiscalYearForMonth } from 'components/utils/dates';

import updateButtonIcon from 'assets/refresh.svg';
import onboardingStyle from 'styles/onboarding/onboarding.css';
import indicatorStyle from 'styles/plan/indicators-graph.css';

import {
  addNewForecastDataToExplainableForecastData, alternative, cumulative, alternativeCumulative, getForecastDataGroupByFiscalYear, getPastDatesForForecast,
} from './logic/forecastGraph';

const enhance = compose(
  inject((stores) => {
    const {
      forecastStore,
      userStore,
    } = stores;
    const {
      userMonthPlan: {
        fiscalYearFirstMonth,
      },
      getMetricNickname,
    } = userStore;
    const {
      forecastRequestFinished,
      alternativeForecastRequestIsLoading,
      forecastIndicators,
      formattedGraphExplainableForecast,
      formattedGraphExplainableAlternativeScenarioForecast,
      isBudgetHasNewData,
      formattedGraphCumulativeForecast,
      formattedGraphCumulativeAlternativeScenarioForecast,
      getExplainableForecastData,
    } = forecastStore;

    return {
      forecastRequestFinished,
      alternativeForecastRequestIsLoading,
      forecastIndicators,
      formattedGraphExplainableForecast,
      formattedGraphExplainableAlternativeScenarioForecast,
      isBudgetHasNewData,
      formattedGraphCumulativeForecast,
      formattedGraphCumulativeAlternativeScenarioForecast,
      fiscalYearFirstMonth,
      getExplainableForecastData,
      getMetricNickname,
    };
  }),
  observer
);

function CustomTooltip({
  active, payload, label, classes, fiscalYearFirstMonth, getMetricNickname,
}) {
  if (active && payload && payload.length) {
    const mappedPayloadData = payload.map((payloadData) => {
      const { dataKey, payload: { timeText } } = payloadData;
      const isAlternative = dataKey.includes(`${alternative}_`);
      const isCumulative = dataKey.includes(`${cumulative}_`);
      const isAlternativeCumulative = dataKey.includes(`${alternativeCumulative}_`);
      let dataKeyLabel = '';
      if (isCumulative || isAlternative || isAlternativeCumulative) {
        const funnelIdFromDataKey = dataKey.split('_').pop();
        const funnelNickname = getMetricNickname({ metric: funnelIdFromDataKey });
        const timeTextAsDate = new Date(timeText);
        const fiscalYearKey = getFiscalYearForMonth(timeTextAsDate.getMonth(), fiscalYearFirstMonth, timeTextAsDate.getYear());
        const dataKeyIsContainsCurrentYear = dataKey.includes(`${fiscalYearKey}_`);
        if (isAlternativeCumulative) {
          if (!dataKeyIsContainsCurrentYear) {
            return;
          }
          dataKeyLabel = `Cumulative Alternative ${funnelNickname}`;
        }
        if (isCumulative) {
          if (!dataKeyIsContainsCurrentYear) {
            return;
          }
          dataKeyLabel = `Cumulative ${funnelNickname}`;
        }
        if (isAlternative) {
          dataKeyLabel = `Monthly Alternative ${funnelNickname}`;
        }
      } else {
        dataKeyLabel = `Monthly ${getMetricNickname({ metric: dataKey })}`;
      }

      // eslint-disable-next-line consistent-return
      return (
        <div key={dataKeyLabel}>
          <p className={classes.customTooltipIndicator}>{dataKeyLabel}</p>
          <p className={classes.customTooltipValue}>{formatBudgetShortened(payloadData.value)}</p>
        </div>
      );
    });
    return (
      <div className={classes.customTooltip}>
        <p className={classes.customTooltipHeader}>{label}</p>
        {mappedPayloadData}
      </div>
    );
  }
  return null;
}

class ForecastGraph extends Component {
  style = indicatorStyle;

  styles = [onboardingStyle];

  chartRef = null;

  constructor(props) {
    super(props);
    this.state = {
      selectedIndicator: '',
    };
  }

  handleScroll = () => {
    this.props.changeScrollPosition(this.chartRef.scrollLeft);
  };

  componentDidMount() {
    if (!this.props.forecastRequestFinished) {
      this.props.getExplainableForecastData();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const indicators = this.props.forecastIndicators || [];
    if (this.state.selectedIndicator === '' && indicators.length > 0) {
      this.setState({ selectedIndicator: indicators[0] });
    }
  }

  componentDidUpdate() {
    if (this.props.scrollPosition && this.chartRef) {
      this.chartRef.scrollLeft = this.props.scrollPosition;
    }
  }

  getMenuItems = () => {
    const indicators = this.props.forecastIndicators;
    const menuItems = indicators.map((indicator) => {
      const isChecked = this.state.selectedIndicator === indicator;
      return (
        <div className={this.classes.menuItem} key={indicator}>
          <CustomRadio checked={isChecked} indicator={indicator} onChange={() => this.setState({ selectedIndicator: indicator })}>
            {this.props.getMetricNickname({ metric: indicator })}
          </CustomRadio>
        </div>
      );
    });
    return menuItems;
  };

  getLinearGradients = () => {
    const { selectedIndicator } = this.state;
    const indicatorColor = getBlueColor();
    return (
      <linearGradient key={selectedIndicator} id={`${selectedIndicator}-gradient`} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor={indicatorColor} stopOpacity={0.2} />
        <stop offset="100%" stopColor={indicatorColor} stopOpacity={0} />
      </linearGradient>
    );
  };

  getGraphBars = () => {
    const { selectedIndicator } = this.state;
    const indicatorColor = getBlueColor();
    return (
      <Bar
        key={selectedIndicator}
        dataKey={selectedIndicator}
        stroke={indicatorColor}
        fill={indicatorColor}
        barSize={12}
        radius={[2, 2, 0, 0]}
        animationDuration={200}
      />
    );
  };

  getAlternativeBarsGraph = () => {
    const { selectedIndicator } = this.state;
    const indicatorColor = getLightBlueColor();
    return (
      <Bar
        key={`${alternative}_${selectedIndicator}`}
        dataKey={`${alternative}_${selectedIndicator}`}
        stroke={indicatorColor}
        fill={indicatorColor}
        barSize={12}
        radius={[2, 2, 0, 0]}
        animationDuration={200}
      />
    );
  };

  getGraphAreas = () => {
    const { selectedIndicator } = this.state;
    const indicatorColor = getBlueColor();
    const groupByFiscalYear = getForecastDataGroupByFiscalYear(this.props.formattedGraphExplainableForecast, this.props.fiscalYearFirstMonth);
    const graphAreas = Object.keys(groupByFiscalYear).map((year) => (
      <Area
        key={`${year}_${cumulative}_${selectedIndicator}`}
        isAnimationActive={false}
        dataKey={`${year}_${cumulative}_${selectedIndicator}`}
        stroke={indicatorColor}
        fill={`url(#${selectedIndicator}-gradient)`}
        fillOpacity={1}
        strokeWidth={2}
      />
    ));
    return graphAreas;
  };

  getAlternativeGraphAreas = () => {
    const { selectedIndicator } = this.state;
    const indicatorColor = getBlueColor();
    const groupByFiscalYear = getForecastDataGroupByFiscalYear(this.props.formattedGraphExplainableForecast, this.props.fiscalYearFirstMonth);
    const alternativeGraphAreas = Object.keys(groupByFiscalYear).map((year) => (
      <Area
        key={`${year}_${alternativeCumulative}_${selectedIndicator}`}
        isAnimationActive={false}
        dataKey={`${year}_${alternativeCumulative}_${selectedIndicator}`}
        stroke={indicatorColor}
        strokeDasharray="5 5"
        fillOpacity={0}
        strokeWidth={2}
      />
    ));
    return alternativeGraphAreas;
  };

  getXAxis = () => (
    <XAxis
      dataKey="timeText"
      tick={{ fontSize: '12px', fontWeight: 600, fill: '#b2bbd5' }}
      tickMargin={10}
      interval="preserveEnd"
      scale="point"
    />
  );

  getYAxis = (yAxisWidth) => (
    <YAxis
      axisLine={false}
      tickLine={false}
      stroke="white"
      tickFormatter={formatBudgetShortened}
      tick={{
        fontSize: '14px', fill: '#b2bbd5', fontWeight: 600, letterSpacing: '0.1px',
      }}
      tickMargin={21}
      padding={{ left: 10, right: 10 }}
      width={yAxisWidth}
      domain={['auto', 'auto']}
    />
  );

  getCombinedGraphsData = () => {
    const {
      formattedGraphExplainableForecast,
      formattedGraphExplainableAlternativeScenarioForecast,
      formattedGraphCumulativeForecast,
      formattedGraphCumulativeAlternativeScenarioForecast,
    } = this.props;
    let graphData = addNewForecastDataToExplainableForecastData(cumulative, formattedGraphExplainableForecast, formattedGraphCumulativeForecast, this.props.fiscalYearFirstMonth, true);
    const alternativeForecastRequestHasData = formattedGraphExplainableAlternativeScenarioForecast?.length > 0 && formattedGraphCumulativeAlternativeScenarioForecast?.length > 0;
    if (alternativeForecastRequestHasData) {
      graphData = addNewForecastDataToExplainableForecastData(alternative, graphData, formattedGraphExplainableAlternativeScenarioForecast);
      graphData = addNewForecastDataToExplainableForecastData(alternativeCumulative, graphData, formattedGraphCumulativeAlternativeScenarioForecast, this.props.fiscalYearFirstMonth, true);
    }
    return graphData;
  };

  render() {
    const indicators = this.props.forecastIndicators;
    if (this.props.forecastRequestFinished && indicators?.length > 0) {
      const {
        isBudgetHasNewData, alternativeForecastRequestIsLoading, formattedGraphExplainableAlternativeScenarioForecast, getMetricNickname,
      } = this.props;
      const alternativeForecastRequestHasData = formattedGraphExplainableAlternativeScenarioForecast?.length > 0;
      let updateButtonLabel = 'Update';
      if (alternativeForecastRequestIsLoading) {
        updateButtonLabel = 'Updating...';
      } else if (alternativeForecastRequestHasData && !isBudgetHasNewData) {
        updateButtonLabel = 'Updated';
      }
      const graphData = this.getCombinedGraphsData();
      const pastDatesMonths = getPastDatesForForecast(graphData);
      const chartHeight = this.props.floating ? 230 : 400;
      const chartWidth = this.props.cellWidth * (graphData.length - 1);
      const xAxisHeight = 15;
      const yAxisWidth = 80;
      const chartMargins = {
        top: 10,
        right: 0,
        left: 0,
        bottom: 21,
      };
      const menuItems = this.getMenuItems();
      const linearGradients = this.getLinearGradients();
      const barsGraph = this.getGraphBars();
      const alternativeBarsGraph = alternativeForecastRequestHasData ? this.getAlternativeBarsGraph() : null;
      const areasGraph = this.getGraphAreas();
      const alternativeAreasGraph = alternativeForecastRequestHasData ? this.getAlternativeGraphAreas() : null;
      const xAxis = this.getXAxis();
      const yAxis = this.getYAxis(yAxisWidth);

      return (
        <div className={classnames(this.classes.inner, { [this.classes.floating]: this.props.floating })}>
          <div className={this.classes.menu}>
            <div className={this.classes.menuTitle}>
              Forecasting
            </div>
            <div className={this.classes.menuItems}>
              {menuItems}
            </div>
          </div>
          <div className={this.classes.chart}>
            {this.props.budgetEditMode
              && (
                <div className={this.classes.forecastAlternative}>
                  <div className={this.classes.forecastUpdateButton}>
                    <Button
                      disabled={!isBudgetHasNewData || alternativeForecastRequestIsLoading}
                      type="primaryBlue"
                      icon={updateButtonIcon}
                      onClick={() => this.props.onUpdateAlternativeForecast()}
                    >
                      {updateButtonLabel}
                    </Button>
                    {isBudgetHasNewData && !alternativeForecastRequestIsLoading && (
                      <div className={this.classes.legendsWarnning}>
                        <div className={this.classes.legendsWarnningIcon} />
                        {' Refresh to update alternative scenario'}
                      </div>
                    )}
                  </div>
                </div>
              )}

            <div className={this.classes.legendsAlternative}>
              <div className={this.classes.legendsItemBar}>Monthly</div>
              {alternativeForecastRequestHasData && <div className={classnames(this.classes.legendsItemBar, this.classes.legendsItemAlternative)}>Monthly Alternative</div>}
              <div className={this.classes.legendsItem}>Cumulative</div>
              {alternativeForecastRequestHasData && <div className={classnames(this.classes.legendsItem, this.classes.legendsItemDashed)}>Cumulative Alternative</div>}
            </div>

            <div className={this.classes.chartScroller} onScroll={this.handleScroll} ref={(chart) => { this.chartRef = chart; }}>
              <ComposedChart
                data={graphData}
                className={this.classes.chartContent}
                height={chartHeight}
                width={chartWidth}
                margin={chartMargins}
                barGap={0}
              >
                <defs>{linearGradients}</defs>
                {xAxis}
                <Tooltip cursor={false} content={<CustomTooltip fiscalYearFirstMonth={this.props.fiscalYearFirstMonth} classes={this.classes} getMetricNickname={getMetricNickname} />} />
                <CartesianGrid vertical={false} stroke="#EBEDF5" strokeWidth={1} />
                <ReferenceArea x1={pastDatesMonths.startDate} x2={pastDatesMonths.endDate} fill="rgba(112, 126, 167, 0.15)" />
                {barsGraph}
                {alternativeBarsGraph}
                {areasGraph}
                {alternativeAreasGraph}
              </ComposedChart>
            </div>
            <ComposedChart
              data={graphData}
              className={this.classes.fixedChartOverlay}
              height={chartHeight - xAxisHeight}
              width={yAxisWidth}
              margin={chartMargins}
            >
              {yAxis}
              {barsGraph}
              {alternativeBarsGraph}
              {areasGraph}
              {alternativeAreasGraph}
            </ComposedChart>
          </div>
        </div>
      );
    } else {
      let noForecastMessage = 'Loading forecasts...';
      if (this.props.forecastRequestFinished) {
        noForecastMessage = 'There are no forecasts available';
      }

      return (
        <div className={classnames(this.classes.inner, { [this.classes.floating]: this.props.floating })}>
          <div className={this.classes.noForecast}>
            <div className={this.classes.menuTitle}>
              Forecasting
            </div>
            <div className={this.classes.noForecastContainer}>
              <div className={this.classes.noForecastMessage}>
                {noForecastMessage}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default enhance(ForecastGraph);
