import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';

import style from 'styles/settings/channels/unmapped-tab.css';

const styles = style.locals;

const UnmappedPopupTable = ({
  leadSourcesIdToLabelMap = {}, columns, tableRowsData,
}) => {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  const unmappedPopupTableRef = useRef();

  const scrollUnmappedPopupTable = (scrollOffset) => {
    unmappedPopupTableRef.current.scrollLeft += scrollOffset;
  };

  const popupTableColumns = columns.filter((column) => column.id !== 'map');

  const formatDate = (date) => moment(date).format('ll');

  return (
    <div className={styles.unmappedPopupTableControl}>
      <div onClick={() => scrollUnmappedPopupTable(-100)} className={styles.nextCircles}>
        <div className={styles.arrowLeft} />
      </div>
      <div className={styles.unmappedPopupBack}>
        <div ref={unmappedPopupTableRef} className={styles.unmappedPopupTable}>
          <div className={styles.unmappedPopupTableWrapper}>
            {popupTableColumns.map((tableColumn) => {
              let keyTitle = tableColumn.id.replaceAll('_', ' ');
              const rowValue = tableRowsData[tableColumn.id];

              if (Object.keys(leadSourcesIdToLabelMap).includes(tableColumn.id)) {
                keyTitle = leadSourcesIdToLabelMap[tableColumn.id];
              }
              const isValueDate = moment(rowValue, 'YYYY-MM-DD HH:mm:ss', true).isValid();
              return (
                <div className={styles.unmappedPopupTableContent} key={`mapped-${keyTitle}-${tableColumn.id}`}>
                  <div className={styles.tableContentTop} key={tableColumn.id}>
                    {_.startCase(keyTitle)}
                  </div>
                  <div className={styles.tableContentBottom}>
                    {isValueDate ? formatDate(rowValue) : rowValue}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div onClick={() => scrollUnmappedPopupTable(+100)} className={styles.nextCircles}>
        <div className={styles.arrowRight} />
      </div>
    </div>
  );
};

export default inject(({
  userStore: {
    userAccount: {
      leadSourcesIdToLabelMap,
    },
  },
}) => ({
  leadSourcesIdToLabelMap,
}),
observer)(UnmappedPopupTable);
