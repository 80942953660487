export const spotlightRequestLimit = 10;

export const spotlightTypes = {
  overPerforming: 'overPerforming',
  underPerforming: 'underPerforming',
  mostTrending: 'mostTrending',
  mostDownTrending: 'mostDownTrending',
};

export const spotlightTypesLabels = {
  [spotlightTypes.overPerforming]: {
    label: 'overperforming',
    isPositive: true,
    icon: 'spotlight:performance-up',
  },
  [spotlightTypes.underPerforming]: {
    label: 'underperforming',
    icon: 'spotlight:performance-down',
  },
  [spotlightTypes.mostTrending]: {
    label: 'most trending',
    isPositive: true,
    tooltip: 'The monthly trend metric calculates a trendline for the last three months of your selected timeframe.',
    isTrending: true,
    icon: 'spotlight:trend-up',
  },
  [spotlightTypes.mostDownTrending]: {
    label: 'most down trending',
    tooltip: 'The monthly trend metric calculates a trendline for the last three months of your selected timeframe.',
    isTrending: true,
    icon: 'spotlight:trend-down',
  },
};
