exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".ellipsis-tooltip__container-3ri{display:block;width:100%}.ellipsis-tooltip__with-ellipsis-12n{width:100%;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}", ""]);

// exports
exports.locals = {
	"container": "ellipsis-tooltip__container-3ri",
	"container": "ellipsis-tooltip__container-3ri",
	"with-ellipsis": "ellipsis-tooltip__with-ellipsis-12n",
	"withEllipsis": "ellipsis-tooltip__with-ellipsis-12n"
};