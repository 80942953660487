import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { exchangeToUSD, extractNumberFromBudget } from 'components/utils/budget';
import Constraint from 'components/pages/plan/Constraint';
import styles from 'styles/plan/table-cell-2.css';
import forecastStore from 'stores/forecastStore';

styles.use();
const classes = styles.locals;

class TableCell extends React.PureComponent {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      isEditMode: false,
      value,
      previousValue: value,
    };

    this.cellRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      channel,
      isEditMode: isEditModeFromProps,
      value: valueFromProps,
      showSumData,
      region,
      isConstraintsEnabled,
    } = this.props;
    const { isEditMode, previousValue, value } = this.state;

    if (prevProps.channel !== channel || prevProps.showSumData !== showSumData || prevProps.region !== region) {
      this.setState({
        value: valueFromProps,
        previousValue: valueFromProps,
      });
    }

    if (isEditModeFromProps && valueFromProps !== value) {
      this.setState({
        value: valueFromProps,
        previousValue: valueFromProps,
      });
    }

    if (
      (!isEditModeFromProps && prevProps.isEditMode)
      || (!isConstraintsEnabled && prevProps.isConstraintsEnabled)
    ) {
      if (value !== valueFromProps) {
        this.setState({
          value: valueFromProps,
          previousValue: valueFromProps,
        });
      }
      if (valueFromProps === value && value !== previousValue) {
        this.setState({
          previousValue: valueFromProps,
        });
      }
    }

    if (!prevState.isEditMode && isEditMode) {
      document.addEventListener('click', this.handleOutsideClick);
    }

    if (prevState.isEditMode && !isEditMode) {
      document.removeEventListener('click', this.handleOutsideClick);
    }
  }

  handleOpenEditMode = (event) => {
    event.preventDefault();

    this.setState({
      isEditMode: true,
    });
  };

  handleOutsideClick = (event) => {
    const { isEditMode } = this.state;
    const { target } = event;

    if (
      isEditMode
      && this.cellRef.current
      && !this.cellRef.current.contains(target)
    ) {
      this.resetCell();
    }
  };

  resetCell = () => {
    const {
      channel,
      onEdit,
      region,
      updateIndex,
      isEditMode,
      isConstraintsEnabled,
      secondaryValue,
    } = this.props;

    if (isEditMode) {
      onEdit(updateIndex, channel, this.state.previousValue, region, false, true);
    }

    if (isConstraintsEnabled) {
      this.setState({
        isEditMode: false,
      });
      return this.handleSubmitChanges(secondaryValue);
    }

    if (!forecastStore.isBudgetHasNewData) {
      forecastStore.updateIsBudgetHasNewData(true);
    }

    this.setState({
      isEditMode: false,
      value: this.state.previousValue,
    });
  };

  onChange = (event) => {
    const { isEditMode } = this.props;
    const { value } = event.target;
    const formattedValue = exchangeToUSD(extractNumberFromBudget(value));

    if (!forecastStore.isBudgetHasNewData) {
      forecastStore.updateIsBudgetHasNewData(true);
    }

    this.setState(
      {
        value: formattedValue,
      },
      () => {
        if (isEditMode) {
          this.handleSubmitChanges(formattedValue);
        }
      }
    );
  };

  onSubmit = (event) => {
    const { isEditMode, value } = this.state;
    event.preventDefault();

    if (isEditMode) {
      this.setState({
        isEditMode: false,
        previousValue: value,
      });
    }

    this.handleSubmitChanges(value);
  };

  handleSubmitChanges = (value) => {
    const {
      channel,
      date,
      onEdit,
      region,
      updateIndex,
      isEditMode,
    } = this.props;

    const withForecast = !isEditMode;
    const startDate = date.startOf('month').format('YYYY-MM-DD');
    const endDate = date.endOf('month').format('YYYY-MM-DD');
    this.setState({ value }, () => {
      onEdit(updateIndex, channel, value, region, withForecast, isEditMode, startDate, endDate);
    });
  };

  showSuggestion() {
    const {
      secondaryValue,
      value,
      interactiveMode,
    } = this.props;

    if (interactiveMode || this.isEditMode()) {
      return secondaryValue !== value;
    }
  }

  showPreviousValue() {
    const { value: valueFromProps, interactiveMode, secondaryValue } = this.props;
    const { previousValue, value } = this.state;

    if (!this.isEditMode(interactiveMode)) {
      return false;
    }

    if (
      previousValue === value
      && value === valueFromProps
      && valueFromProps === secondaryValue
    ) {
      return false;
    }

    return true;
  }

  getPreviousValue() {
    const { secondaryValue, value: valueFromProps, interactiveMode } = this.props;
    const { previousValue, value } = this.state;

    if (secondaryValue !== value) {
      return secondaryValue;
    }

    if (!this.isEditMode(interactiveMode)) {
      return null;
    }

    if (valueFromProps !== value) {
      return previousValue;
    }

    if (secondaryValue === null) {
      return 0;
    }

    return secondaryValue;
  }

  renderConstraint() {
    const {
      channel,
      constraintChange,
      isConstraint,
      isConstraintsEnabled,
      isSoft,
      updateIndex,
      region,
    } = this.props;
    const { isEditMode } = this.state;

    if (!isConstraintsEnabled) {
      return null;
    }

    return (
      <Constraint
        channel={channel}
        constraintChange={constraintChange}
        isConstraint={isConstraint}
        isEditMode={isEditMode}
        isSoft={isSoft}
        updateIndex={updateIndex}
        region={region}
      />
    );
  }

  renderEditButton() {
    return (
      <button
        className={classNames(classes.button, classes.buttonEdit)}
        onClick={this.handleOpenEditMode}
        type="button"
      >
        <i className={classes.icon} data-icon="plan:edit" />
      </button>
    );
  }

  renderActionButtons() {
    const { isEditMode: controlledIsEditMode } = this.props;
    const { isEditMode } = this.state;

    if (isEditMode && !controlledIsEditMode) {
      return (
        <>
          <button className={classes.button} type="submit">
            <i
              className={classes.icon}
              data-icon="plan:approveEdit"
            />
          </button>
          <button
            className={classes.button}
            type="button"
            onClick={this.resetCell}
          >
            <i
              className={classes.icon}
              data-icon="plan:declineEdit"
            />
          </button>
        </>
      );
    }

    if (this.showSuggestion() || this.showPreviousValue()) {
      return (
        <>
          <button
            className={classes.button}
            onClick={this.resetCell}
            type="button"
          >
            <i
              className={classes.icon}
              data-icon="plan:acceptSuggestion"
            />
          </button>
          {!this.isEditMode() && this.renderEditButton()}
        </>
      );
    }

    if (!this.isEditMode()) {
      return this.renderEditButton();
    }
  }

  renderInput() {
    const { format } = this.props;
    const { value } = this.state;
    const formattedValue = format(value);

    if (this.isEditMode()) {
      return (
        <input
          className={classNames(classes.input, classes.inputEditable)}
          type="text"
          value={formattedValue}
          onChange={this.onChange}
        />
      );
    }

    return <div className={classes.input}>{formattedValue}</div>;
  }

  isEditMode(mode) {
    const { isEditMode: isEditModeFromProps } = this.props;
    const { isEditMode } = this.state;

    return (isEditMode && !mode) || isEditModeFromProps;
  }

  render() {
    const { format, value } = this.props;
    const formattedSecondaryValue = format(this.getPreviousValue());

    return (
      <form
        className={classes.root}
        onSubmit={this.onSubmit}
        ref={this.cellRef}
      >
        <div className={classes.inputWrapper}>
          {this.renderInput()}
          <div
            className={classNames(classes.secondaryValue, {
              [classes.secondaryValueShow]: this.showSuggestion() || this.showPreviousValue(),
            })}
          >
            {formattedSecondaryValue}
          </div>
        </div>
        <div className={classes.actions}>
          {this.renderConstraint()}
          {this.renderActionButtons()}
        </div>
      </form>
    );
  }
}

TableCell.defaultProps = {
  format: () => {
  },
  onEdit: () => {
  },
  secondaryValue: 0,
};

TableCell.propTypes = {
  channel: PropTypes.string,
  format: PropTypes.func,
  onEdit: PropTypes.func,
  region: PropTypes.string,
  updateIndex: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TableCell;
