import { widgetDescriptionMaxLength } from 'components/common/enums';

export function getWidgetShortDescription({ value }) {
  if (!value) {
    return '';
  }
  const valueLinesBreaks = value.split('\n');
  const firstLineBreak = valueLinesBreaks[0];
  return firstLineBreak.substring(0, widgetDescriptionMaxLength);
}
