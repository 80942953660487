import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/setup-budget.css';
import Select from 'components/controls/Select';
import { Link } from 'react-router';
import SetupBudgetButton from 'components/pages/budget/SetupBudgetButton';
import DeletePopup from 'components/pages/budget/DeletePopup';

export default class SetupBudgetHeader extends Component {
    style = style;

    constructor() {
      super();
      this.state = {
        selectLabel: { value: '2021', label: '2021' },
        isRemoved: false,
        isNewBudget: false,
      };
    }

    selectValue = (val) => {
      this.setState({
        selectLabel: val,
      });
      if (val === '2022') {
        this.setState({
          isNewBudget: true,
        });
      }
    };

    onRemoveClick = () => {
      this.setState({
        isRemoved: true,
      });
    }

    onCloseClick = () => {
      this.setState({
        isRemoved: false,
        isNewBudget: false,
      });
    }

    render() {
      const options = [
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
      ];
      const customStyles = {
        background: 'linear-gradient(360deg, #FAFAFC 0%, #FFFFFF 100%)',
        border: '1px solid rgba(178, 187, 213, 0.5)',
        height: 40,
      };
      return (
        <div className={this.classes.contentHead}>
          <div className={this.classes.header}>
            <h1 className={this.classes.title}>
              Build your budget plan
            </h1>
            <p className={this.classes.subtitle}>
              Setup channels
            </p>
            <p className={this.classes.text}>
              Setup your added channels by adding baseline budgets out of your total budget. See the forecast impact
              with the help of tips and insights.
            </p>
            <div className={this.classes.headerSelectWrap}>
              <p className={this.classes.headerLabel}>
                Fiscal year
              </p>
              <Select
                selected={this.state.selectLabel}
                select={{ options }}
                onChange={(e) => this.selectValue(e.value)}
                innerClassName={this.classes.select}
                customStyle={customStyles}
              />
              {this.state.selectLabel === '2022'
              && (
              <div className={this.classes.removeYearContainer}>
                <div className={this.classes.removeYear} onClick={() => this.onRemoveClick()} />
              </div>
              )}
            </div>
          </div>
          <div className={this.classes.btnContainer}>
            <Link to="/add-channels">
              <SetupBudgetButton
                name="+ Add Chanel"
                stylesWrap={this.classes.buttonWrap}
                stylesBtn={this.classes.button}
              />
            </Link>
            <p className={this.classes.btnText}>
              All changes will be saved
            </p>
          </div>
          {this.state.isRemoved
          && (
          <DeletePopup
            title="Are you sure you want to delete the"
            deleteName="[2022 budget]"
            onCloseClick={() => this.onCloseClick()}
          />
          )}
          {this.state.isNewBudget
            && (
            <div className={this.classes.overlay}>
              <div className={this.classes.popup}>
                <div className={this.classes.closeIcon} onClick={() => this.onCloseClick()} />
                <div className={this.classes.popupWrap}>
                  <h3 className={this.classes.popupTitle}>
                    <br />
                    You are about to create your budget for the
                    <br />
                    fiscal year
                    <span> [2022]</span>
                  </h3>
                  <div className={this.classes.popupMain}>
                    <div className={this.classes.popupMainItem}>
                      <h4 className={this.classes.popupMainTitle}>
                        Keep the same channels
                      </h4>
                      <p className={this.classes.popupMainText}>
                        It will keep the same channels from the previous year, but without any budget.
                      </p>
                    </div>
                    <div className={this.classes.popupMainItem}>
                      <h4 className={this.classes.popupMainTitle}>
                        Start from scratch
                      </h4>
                      <p className={this.classes.popupMainText}>
                        You will start a new budget from scratch. You will have to add your channels manually.
                      </p>
                    </div>
                  </div>
                  <div className={this.classes.popupBtnsContainer}>
                    <SetupBudgetButton
                      name="Cancel"
                      stylesWrap={this.classes.popupButtonWrap}
                      stylesBtn={this.classes.popupButtonCancel}
                      onClick={() => this.onCloseClick()}
                    />
                    <SetupBudgetButton
                      name="Create"
                      stylesWrap={this.classes.popupButtonWrap}
                      stylesBtn={this.classes.popupButtonCreate}
                      onClick={() => this.onCloseClick()}
                    />
                  </div>
                </div>
              </div>
            </div>
            )}
        </div>
      );
    }
}
