import { filterKinds } from 'components/utils/filters/logic';

export const attributionFiltersOrder = [
  filterKinds.FUNNEL_STAGES,
  filterKinds.CHANNELS,
  filterKinds.CAMPAIGNS,
  filterKinds.CONTENT,
  filterKinds.FORMS,
  filterKinds.MARKETING_VS_SALES,
  filterKinds.CRMSource,
  filterKinds.CUSTOM_FIELDS,
  filterKinds.VELOCITY,
  filterKinds.REVENUE,
  filterKinds.CHANNELS_BEFORE_STAGES,
  filterKinds.CONTENT_BEFORE_STAGES,
  filterKinds.CAMPAIGN_BEFORE_STAGES,
  filterKinds.CONTENT_CHANNELS_BEFORE_STAGES,
  filterKinds.CAMPAIGN_TAGS,
];

export const costFiltersOrder = [
  filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS,
  filterKinds.CAMPAIGN_COST,
];

export const analyzeWidgetsResultKeyNames = {
  currentTimeFrame: 'current',
  previousTimeFrame: 'previous',
  insightsPage: 'insightsPage',
};

export const userConfigurationTypes = {
  conversionRate: 'conversionRate',
  crmValueTranslation: 'crmValueTranslation',
  crmMultiTouch: 'crmMultiTouch',
  dataRefreshInterval: 'dataRefreshInterval',
  funnelsBackfilling: 'funnelsBackfilling',
  leadToAccountMatching: 'leadToAccountMatching',
  oppEnrichment: 'oppEnrichment',
  objectsDeduplication: 'objectsDeduplication',
  touchPointsTimestamp: 'touchPointsTimestamp',
};
