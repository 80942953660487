import React from 'react';

import Dropdown from 'components/controls/Dropdown';
import ChannelIcon from 'components/common/ChannelIcon';

import { FilterItemTag } from 'components/pages/users/Filters/FilterParts';
import {
  getChannelIcon,
  getChannelNickname,
} from 'components/utils/filters/channels';

import useStyles from 'hooks/useStyles';
import filterStyle from 'styles/users/filters.css';
import selectStyle from 'styles/controls/select.css';
import reportLibraryStyle from 'styles/reports/reportLibrary.css';

const filterStyles = filterStyle.locals || {};
const selectStyles = selectStyle.locals || {};

function TemplateConfigChannelsMultiSelect({
  addItem,
  removeItem,
  selectedOptions,
  channelsOptionsWithoutDeleted,
  isAsyncPagination,
  searchFunction,
}) {
  useStyles([
    filterStyle,
    selectStyle,
    reportLibraryStyle,
  ]);

  return (
    <>
      <Dropdown
        onChange={addItem}
        selectedKey={-1}
        options={channelsOptionsWithoutDeleted}
        optionRenderer={(item) => (
          <div className={filterStyles.channelItemWrap}>
            <ChannelIcon
              className={filterStyles.filterItemIcon}
              channelIcon={getChannelIcon(item.value)}
              channel={item.value}
            />
            <div className={selectStyles.text}>
              {getChannelNickname(item.label)}
            </div>
          </div>
        )}
        isAsyncPagination={isAsyncPagination}
        searchFunction={searchFunction}
        placeholder="Select channel..."
        isSearchable
        withTooltip
      />

      <div className={filterStyles.filterItemTags}>
        {selectedOptions.map((channel) => (
          <FilterItemTag
            key={channel}
            channel={channel}
            title={getChannelNickname(channel)}
            onRemove={() => removeItem(channel)}
            iconRenderer={() => (
              <ChannelIcon
                className={filterStyles.filterItemIcon}
                channelIcon={getChannelIcon(channel)}
                channel={channel}
              />
            )}
          />
        ))}
      </div>
    </>
  );
}

export default TemplateConfigChannelsMultiSelect;
