import React from 'react';
import Component from 'components/Component';
import style from 'styles/budget/budget-header.css';
import SearchInput from 'components/controls/SearchInput';
import Select from 'components/controls/Select';

export default class BudgetHeader extends Component {
  style = style;

  constructor() {
    super();
    this.state = {
      timeFrame: '',
      selectLabel: '',
    };
  }

    selectValue = ({ value, type }) => {
      this.setState({
        timeFrame: type,
        selectLabel: value,
        isNewBudget: true,
      });
    };

    render() {
      const options = [
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
      ];
      const customStyles = {
        background: 'linear-gradient(360deg, #FAFAFC 0%, #FFFFFF 100%)',
        border: '1px solid rgba(178, 187, 213, 0.5)',
        height: 40,
      };
      const { onSearch } = this.props;
      return (
        <div className={this.classes.content}>
          <div className={this.classes.header}>
            <h1 className={this.classes.title}>
              Build your budget plan
            </h1>
            <p className={this.classes.subtitle}>
              Add channels
            </p>
            <p className={this.classes.text}>
              Select the channels you want to use in your plan. You can always add/remove channels later. Some channels
              may have been automatically added based on your connected integrations.
            </p>
            <div className={this.classes.headerSelectWrapChannel}>
              <p className={this.classes.headerLabel}>
                Fiscal year
              </p>
              <Select
                selected={options[0]}
                select={{ options }}
                onChange={(e) => this.selectValue(e.target.value)}
                className={this.classes.select}
                customStyle={customStyles}
              />
            </div>
          </div>
          <div className={this.classes.search}>
            <SearchInput
              onSearch={onSearch}
              classes={{
                label: this.classes.searchLabel,
                searchIcon: this.classes.searchIcon,
                closeIcon: this.classes.closeIcon,
                input: this.classes.searchInput,
              }}
            />
          </div>
        </div>
      );
    }
}
