import Component from 'components/Component';
import React from 'react';
import style from 'styles/plan/planned-vs-actual-off-track.css';
import { formatBudget } from 'components/utils/budget';
import classNames from 'classnames';
import Tooltip from 'components/controls/Tooltip';
import { uniqueId } from 'lodash';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import PropTypes from 'prop-types';
import ChartSelect from 'components/controls/ChartSelect';

const colors = ['#f68f98', '#f9bcc1', '#b0eecb', '#7ee2a9', '#2fcf5f'];


export default class ChartModule extends Component {
  style = style;

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeIndicator: PropTypes.string,
    indicators: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateIndicator: PropTypes.func,
    isCurrentMonth: PropTypes.bool.isRequired,
    format: PropTypes.bool,
    showTitle: PropTypes.bool,
    small: PropTypes.bool,
    showPacing: PropTypes.bool,
  }

  getPercent = (division) => (division ? `${Math.round(division)}%` : '');

  getToolTip = (item) => {
    const { showPacing } = this.props;

    let tip = `Planned: ${this.format(item.planned)}.`;
    if (item.actual) {
      tip += ` ${showPacing ? 'Pacing for' : 'Actual'}: ${this.format(item.actual)} `;
    }
    if (item.division) {
      tip += `(${this.getPercent(item.division)} of plan)`;
    }
    return tip;
  };

  format = (v) => (this.props.format ? formatBudget(v) : Math.round(v * 100) / 100);

  render() {
    const {
      items, activeIndicator, indicators, updateIndicator, format, showTitle, small,
    } = this.props;
    const rowWidth = (val) => {
      const fraction = Math.abs(val / items[0].value * 100);
      return `${Math.round(fraction)}%`;
    };
    const getSign = (val) => {
      if (!format) {
        return '';
      }
      return val ? Math.sign(val) > 0 ? '+' : '-' : '';
    };
    return (
      <div className={this.classes.chartModule}>
        <div className={classNames(this.classes.chartModuleTitle, {
          [this.classes.chartModuleTitleMargin]: !activeIndicator,
        })}
        >
          {activeIndicator ? (
            <ChartSelect
              updateIndicator={updateIndicator}
              indicators={indicators}
              activeIndicator={activeIndicator}
            />
          ) : showTitle ? 'spending' : ''}
        </div>
        <div>
          {items.map((item, i) => {
            const percent = this.getPercent(item.division);
            const TooltipComponent = () => (
              <>
                {`${getSign(item.value)}${this.format(item.value)}`}
                <span className={this.classes.metricsDivision}>
                  {percent && ` (${percent})`}
                </span>
              </>
            );
            return (
              <div
                className={classNames(this.classes.chartRowWrapper, {
                  [this.classes.chartRowWrapperSmall]: small,
                })}
                key={i}
              >
                <div
                  className={classNames(this.classes.chartRowTitle, {
                    [this.classes.chartRowTitleSmall]: small,
                  })}
                >
                  <EllipsisTooltip
                    text={item.title}
                    place="top"
                  />
                </div>
                <div style={{ width: '80%' }}>
                  <div
                    className={classNames(this.classes.chartRow, {
                      [this.classes.chartRowSmall]: small,
                    })}
                    style={{
                      backgroundColor: colors[i],
                      width: rowWidth(item.value),
                    }}
                  />
                </div>
                <span className={this.classes.metrics}>
                  <Tooltip
                    id={uniqueId('chart-tooltip-')}
                    tip={this.getToolTip(item)}
                    place="bottom"
                    TooltipProps={{
                      multiline: true,
                    }}
                  >
                    <TooltipComponent />
                  </Tooltip>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
