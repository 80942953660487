import React from 'react';
import style from 'styles/header.css';
import classnames from 'classnames';

const classes = style.locals;

const DropMenuButton = ({
  children, onClick, dataSelected, className,
}) => (
  <div
    className={classnames(classes.dropmenuButton, className)}
    data-selected={dataSelected}
    role="button"
    onClick={onClick}
  >
    {children}
  </div>
);

export default DropMenuButton;
