import React from 'react';
import ReactDOM from 'react-dom';
import assign from 'object-assign';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Component from 'components/Component';
import style from 'styles/popup.css';

const rootElement = document.getElementById('main');

export default class Popup extends Component {
  style = style;

  static propTypes = {
    hidden: PropTypes.bool,
  };

  static defaultProps = {
    hidden: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      hidden: !!props.hidden,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.hidden !== this.state.hidden) {
      this.setState({
        hidden: props.hidden,
      });
    }
  }

  componentWillUnmount() {
    this.unlistenGlobal();
  }

  onOutsideClick = (e) => {
    if (this.props.onClose) {
      const elem = ReactDOM.findDOMNode(this);
      const path = e.path || (e.composedPath && e.composedPath());
      const isControlButton = path && path.find((node) => node.id === 'popupControl');
      if (elem !== e.target && !elem.contains(e.target) && !isControlButton) {
        this.props.onClose();
      }
    }
  };

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  hide() {
    this.setState({
      hidden: true,
    });
  }

  listenGlobal() {
    if (rootElement) {
      rootElement.addEventListener('mousedown', this.onOutsideClick, true);
      rootElement.addEventListener('touchstart', this.onOutsideClick, true);
    }
  }

  unlistenGlobal() {
    if (rootElement) {
      rootElement.removeEventListener('mousedown', this.onOutsideClick, true);
      rootElement.removeEventListener('touchstart', this.onOutsideClick, true);
    }
  }

  render() {
    const { className, children } = this.props;
    const { hidden } = this.state;

    const popupStyle = assign({}, this.props.style);

    if (hidden) {
      this.unlistenGlobal();
      popupStyle.display = 'none';
    } else {
      popupStyle.display = '';
      this.listenGlobal();
    }

    return (
      <div
        className={classnames(this.classes.popup, className)}
        style={popupStyle}
      >
        {children}
      </div>
    );
  }
}
