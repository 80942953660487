import classNames from 'classnames';
import React from 'react';

import InfoMarker from 'components/pages/InfoMarker';

import attributionStyle from 'styles/analyze/analyze-attribution-table.css';

const style = attributionStyle.locals || {};

export default function HeaderExpendColumn({
  children, isExpendOpen, withTrend = true,
}) {
  return (
    <div className={classNames(style.headerExpend, isExpendOpen && withTrend ? style.expendOpen : null)}>
      <div className={classNames(style.headerExpendTitle, isExpendOpen ? style.expendWidthValues : null)}>
        {children}
      </div>
      {withTrend ? (
        <div className={isExpendOpen ? style.expendWidthTrends : null}>
          {isExpendOpen ? (
            <div className={style.trendInfo}>
              Trend
              <InfoMarker
                place="right"
                tooltipText="A trendline based on the last three months of your selected time frame, represented in average growth, month over month."
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
