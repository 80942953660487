exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".inspect__inspectPopupWrapper-35Z{position:relative;height:80vh;overflow-y:auto}.inspect__close-rsx{height:20px;width:20px;background-image:url(/assets/remove.svg);background-size:15px;background-repeat:no-repeat;background-position:50%;cursor:pointer;position:absolute;right:24px;top:24px;z-index:2}.inspect__title-3KP{font-size:18px;font-weight:600}.inspect__content-mH2{margin:0;padding:0}.inspect__mainTopPopup-1yL{display:flex;flex-direction:column;gap:24px;width:calc(100% - 64px);background:#fff;border-radius:4px;padding:24px 32px;box-shadow:0 4px 14px 0 rgba(24,32,51,.06);position:sticky;top:0;z-index:4}.inspect__mainContentPopup-W89{padding:32px;display:flex;gap:40px;flex-direction:column}.inspect__topMenu-3le{display:flex;align-items:center;gap:12px}.inspect__filterTagsWrapper-8En{display:flex;gap:12px;flex-direction:column}.inspect__filterTags-3B1{display:flex;gap:8px}.inspect__mainContentMetrics-b5o{display:flex;gap:24px}.inspect__mainContentMetrics-b5o>div{flex-grow:1}.inspect__metricStatsCard-YqL{height:auto!important}.inspect__segmentTags-1ki{display:flex;gap:8px;margin:12px 0 4px;flex-wrap:wrap;justify-content:center}.inspect__clickable-3yR:hover{cursor:pointer;-webkit-text-decoration-line:underline;text-decoration-line:underline}", ""]);

// exports
exports.locals = {
	"inspectPopupWrapper": "inspect__inspectPopupWrapper-35Z",
	"inspectPopupWrapper": "inspect__inspectPopupWrapper-35Z",
	"close": "inspect__close-rsx",
	"close": "inspect__close-rsx",
	"title": "inspect__title-3KP",
	"title": "inspect__title-3KP",
	"content": "inspect__content-mH2",
	"content": "inspect__content-mH2",
	"mainTopPopup": "inspect__mainTopPopup-1yL",
	"mainTopPopup": "inspect__mainTopPopup-1yL",
	"mainContentPopup": "inspect__mainContentPopup-W89",
	"mainContentPopup": "inspect__mainContentPopup-W89",
	"topMenu": "inspect__topMenu-3le",
	"topMenu": "inspect__topMenu-3le",
	"filterTagsWrapper": "inspect__filterTagsWrapper-8En",
	"filterTagsWrapper": "inspect__filterTagsWrapper-8En",
	"filterTags": "inspect__filterTags-3B1",
	"filterTags": "inspect__filterTags-3B1",
	"mainContentMetrics": "inspect__mainContentMetrics-b5o",
	"mainContentMetrics": "inspect__mainContentMetrics-b5o",
	"metricStatsCard": "inspect__metricStatsCard-YqL",
	"metricStatsCard": "inspect__metricStatsCard-YqL",
	"segmentTags": "inspect__segmentTags-1ki",
	"segmentTags": "inspect__segmentTags-1ki",
	"clickable": "inspect__clickable-3yR",
	"clickable": "inspect__clickable-3yR"
};