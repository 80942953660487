import React from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { getAttributionModelsWithFlags } from 'attribution/models';
import Dropdown from 'components/controls/Dropdown';

const WidgetAttributionConfigs = ({
  metricsOptions,
  indicator,
  attributionModel,
  flags,
  updateWidgetAttributionModel,
  updateWidgetIndicator,
  disabled,
}) => {
  const attributionModelsItems = getAttributionModelsWithFlags(flags);

  return (
    <>
      {indicator
        && (
          <Dropdown
            options={metricsOptions}
            selectedKey={indicator}
            dropdownLabel="KPI Foucs"
            onChange={(value) => updateWidgetIndicator(value.value)}
            disabled={disabled}
          />
        )}
      {attributionModel
        && (
          <Dropdown
            selectedKey={attributionModel.value}
            options={attributionModelsItems}
            dropdownLabel="Attribution model"
            onChange={(value) => updateWidgetAttributionModel(value)}
            disabled={disabled}
          />
        )}
    </>
  );
};

export default inject(({
  attributionStore: {
    metricsOptions,
  } = {},
}) => ({
  metricsOptions,
}), observer)(withLDConsumer()(WidgetAttributionConfigs));
