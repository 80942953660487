import userStore from 'stores/userStore';
import { nonUpDirectionMetricTypes } from 'components/pages/settings/goals/logic/enums';

export function getIsGrowthDesired({ metricName }) {
  if (!metricName) {
    return false;
  }

  const metricSchema = userStore.getMetricSchema({ metric: metricName });

  return !nonUpDirectionMetricTypes.includes(metricSchema.metricType);
}

export function getGrowthValue({
  metricName,
  growthPerMetric = {},
}) {
  const growthValue = growthPerMetric[metricName];

  if (growthValue === null) {
    return 'Infinity';
  }

  return growthValue ?? null;
}
