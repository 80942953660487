import { capitalize, cloneDeep, isEmpty } from 'lodash';
import { defaultSupportedUtms } from 'components/pages/analyze/SegmentsTab/logic/enums';

const operationValues = {
  equals: 'equals',
  contains: 'contains',
  empty: 'empty',
  notEquals: 'not_equals',
  notContains: 'not_contains',
  notEmpty: 'not_empty',
  isOnly: 'isOnly',
};

const operationLabels = {
  equals: 'equals',
  contains: 'contains',
  empty: 'is empty',
  notEquals: 'not equals',
  notContains: 'doesn\'t contain',
  notEmpty: 'isn\'t empty',
  isOnly: 'is only',
};

export const operationOptions = [
  { value: operationValues.equals, label: operationLabels.equals },
  { value: operationValues.contains, label: operationLabels.contains },
  { value: operationValues.empty, label: operationLabels.empty },
  { value: operationValues.notEquals, label: operationLabels.notEquals },
  { value: operationValues.notContains, label: operationLabels.notContains },
  { value: operationValues.notEmpty, label: operationLabels.notEmpty },
  { value: operationValues.isOnly, label: operationLabels.isOnly },
];

export const operationTimeOptions = [
  { value: 'between', label: 'is between' },
];

export const defaultOnlineOptions = [
  { value: 'referrer', label: 'Referrer' },
  { value: 'url', label: 'URL' },
  { value: 'link_click_url', label: 'Click URL' },
];

const operations = [...operationOptions, ...operationTimeOptions];
const permittedOperations = operations.map((operation) => operation.value);

export function getLeadSourcesOptionsForMapping({ leadSourcesIdToLabelMap = {} }) {
  const mappingOptions = [];
  for (const [leadSourceId, leadSourceLabel] of Object.entries(leadSourcesIdToLabelMap)) {
    mappingOptions.push({
      value: leadSourceId,
      label: leadSourceLabel,
    });
  }

  mappingOptions.push({
    value: 'allLeadSources',
    label: 'Every lead source',
  });
  mappingOptions.push({
    value: 'sessionType',
    label: 'Touchpoint Source',
  });

  return mappingOptions;
}

export function getUtmsOptionsForMapping({ customUtmsWhitelist = [], withUtmSuffix = true }) {
  const mappingOptions = [];
  for (const utm of defaultSupportedUtms.concat(customUtmsWhitelist)) {
    const suffix = withUtmSuffix ? ' (UTM)' : '';

    mappingOptions.push({
      value: utm,
      label: `${capitalize(utm.split('utm_')[1])}${suffix}`,
    });
  }

  return mappingOptions;
}

export function getAllMappingOptions({ leadSourcesIdToLabelMap, customUtmsWhitelist }) {
  const leadSourcesOptions = getLeadSourcesOptionsForMapping({ leadSourcesIdToLabelMap });
  const utmsOptions = getUtmsOptionsForMapping({ customUtmsWhitelist, withUtmSuffix: true });

  return [...defaultOnlineOptions, ...utmsOptions, ...leadSourcesOptions];
}

export const getChannelRules = (mappingRules = [], selectedChannel) => mappingRules
  .map((rule, index) => ({
    ...rule,
    index,
  }))
  .filter((rule) => rule.channel === selectedChannel) || [];

export const getNewCondition = () => ({
  param: '',
  operation: '',
  value: '',
});

export const isRuleConditionFilled = ({ condition }) => {
  if (isEmpty(condition.param)) {
    return false;
  }

  if (!permittedOperations.includes(condition.operation)) {
    return false;
  }

  const shouldHaveValue = !['empty', 'not_empty'].includes(condition.operation);

  return !(shouldHaveValue && condition.value === '');
};

export function getMergedAttributionMappingRules({
  attributionMappingRules = [], deletedRuleIds = [], updatedMappingRules = [], newMappingRules = [],
}) {
  const mergedAttributionMappingRules = cloneDeep(attributionMappingRules);

  for (const deletedRuleId of deletedRuleIds) {
    const mappingRuleIndex = mergedAttributionMappingRules.findIndex((rule) => rule.id === deletedRuleId);
    mergedAttributionMappingRules.splice(mappingRuleIndex, 1);
  }

  for (const updatedRule of updatedMappingRules) {
    const mappingRuleIndex = mergedAttributionMappingRules.findIndex((rule) => rule.id === updatedRule.id);
    mergedAttributionMappingRules[mappingRuleIndex] = updatedRule;
  }

  for (const newRule of newMappingRules) {
    mergedAttributionMappingRules.push(newRule);
  }

  return mergedAttributionMappingRules;
}
