import userStore from 'stores/userStore';
import { getGoalTimeframe } from 'components/utils/goals';
import base from './base';

function getWidgetConfig({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const baseConfig = base.getWidgetConfig({ widgetConfig });
  if (widgetConfig.timeFrame && !baseConfig.goalsAnalysisParams.timeframe) {
    const goalTimeframe = getGoalTimeframe({ timeframe: widgetConfig.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
    baseConfig.goalsAnalysisParams.timeframe = goalTimeframe;
  }

  if (!baseConfig.goalsAnalysisParams?.timeframe?.startDate && baseConfig.goalsAnalysisParams?.timeframe?.value) {
    const goalTimeframe = getGoalTimeframe({ timeframe: baseConfig.goalsAnalysisParams.timeframe, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
    baseConfig.goalsAnalysisParams.timeframe = goalTimeframe;
  }

  return baseConfig;
}

export default {
  ...base,
  getWidgetConfig,
};
