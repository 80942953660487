export function formatBudgetShortened(budget) {
  const absbudget = Math.abs(budget);
  if (absbudget >= 1000000000) {
    return `${(budget / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (absbudget >= 1000000) {
    return `${(budget / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (absbudget >= 1000) {
    return `${(budget / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return budget;
}
