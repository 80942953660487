exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".textArea__textArea-2up{border-radius:4px;border:1px solid rgba(178,187,213,.5);background:#fff;color:#707ea7;font-family:Avenir Next;font-size:14px;font-weight:400;padding:12px}.textArea__errorMessage-38j{color:#e43a54;font-family:Avenir Next;font-size:12px;margin-bottom:6px}", ""]);

// exports
exports.locals = {
	"textArea": "textArea__textArea-2up",
	"textArea": "textArea__textArea-2up",
	"errorMessage": "textArea__errorMessage-38j",
	"errorMessage": "textArea__errorMessage-38j"
};