import { groupBy, sortBy } from 'lodash';
import moment from 'moment';

import userStore from 'stores/userStore';
import {
  dateFormatForEventTimeline,
} from 'components/pages/analyze/inspectData/logic/enums';

export function parseServerResponse({ serverResponse, timeZone = 'UTC' }) {
  const parsedData = [];
  const dataGropedByFunnelChangeDate = groupBy(serverResponse, (item) => item.transition.changeDate);

  for (const [funnelChangeDate, changeDataSessions] of Object.entries(dataGropedByFunnelChangeDate)) {
    const transitionData = changeDataSessions[0].transition;
    const funnelTransitionTouchpoint = {
      startTime: funnelChangeDate,
      endTime: funnelChangeDate,
      isFunnelStage: true,
      funnelStage: transitionData.funnel,
      nickname: userStore.getMetricNickname({
        metric: transitionData.funnel,
        isSingular: true,
      }),
      startTimeDate: new Date(funnelChangeDate),
      amount: transitionData.amount,
      selectedChangedBy: transitionData.createdBy,
      touchpointTitle: 'Transition to',
    };

    const touchPoints = changeDataSessions.map((session, index) => ({
      ...session,
      metricThatGotCredit: userStore.getMetricNickname({
        metric: transitionData.funnel,
        isSingular: true,
      }),
      sessions: [session],
      eventIndex: index,
      startTime: session.startTime,
      startTimeDate: new Date(session.startTime),
    }));

    const allTouchpoints = sortBy([...touchPoints, funnelTransitionTouchpoint], ['startTimeDate']).reverse();
    const sessionsByDate = groupBy(allTouchpoints, (item) => moment(item.startTime).tz(timeZone).format(dateFormatForEventTimeline));

    const parsedDataAvailableIndex = parsedData.length;
    for (const [sessionDate, allTouchpointsInTheGivenSession] of Object.entries(sessionsByDate)) {
      if (!parsedData[parsedDataAvailableIndex]) {
        parsedData[parsedDataAvailableIndex] = [];
      }
      const dataKeyName = moment(sessionDate).tz(timeZone).format(dateFormatForEventTimeline);

      parsedData[parsedDataAvailableIndex][`${dataKeyName}`] = allTouchpointsInTheGivenSession;
    }
  }

  return parsedData;
}

export function filterNonAttributedTouchpoints({
  drillDownDataEntries,
  firstSegment,
  secondSegment,
}) {
  const filteredData = {};

  for (const [date, dateData] of drillDownDataEntries) {
    const filteredDateData = dateData.filter((data) => data.isFunnelStage || (data.firstSegment === firstSegment && (secondSegment ? data.secondSegment === secondSegment : true)));
    if (filteredDateData.length > 0) {
      filteredData[`${date}`] = filteredDateData;
    }
  }

  return filteredData;
}
