export const viewStyleAccordingDataType = {
  CHANNEL: 0,
  CAMPAIGN: 1,
  CATEGORY: 2,
};

export const planVsActualColumns = {
  planned: {
    label: 'Planned',
    value: 'planned',
  },
  actual: {
    label: 'Actual',
    value: 'actual',
  },
  planVsActual: {
    label: 'Plan vs actual',
    value: 'planVsActual',
  },
  pacingFor: {
    label: 'Pacing for',
    value: 'pacingFor',
  },
};

export const campaignTagsColors = [
  {
    background: '#E9F5FE',
    color: '#2196F3',
  },
  {
    background: '#FFEEE9',
    color: '#FF5722',
  },
  {
    background: '#F0EBF8',
    color: '#673AB7',
  },
  {
    background: '#E5F8FB',
    color: '#00BCD4',
  },
  {
    background: '#FDE8EF',
    color: '#E91E63',
  },
  {
    background: '#FFF6DC',
    color: '#F0B504',
  },
  {
    background: '#F2E5E5',
    color: '#800000',
  },
  {
    background: '#EDFFDE',
    color: '#56C300',
  },
  {
    background: '#FFD2D1',
    color: '#F00412',
  },
];

export const campaignTagsError = 'Add name that is associate with your existing tags.';
