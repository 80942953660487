import userStore from 'stores/userStore';

import { compareMetricsKinds, metricTypes, displayTypes } from 'components/pages/settings/workflows/logic/enums';
import { getCurrencySymbol } from 'components/utils/budget';

export function getCompareEnrichedMetricsMockData() {
  return [
    {
      title: 'Avg. touchpoints per Opp',
      kind: compareMetricsKinds.averageTouchpoints,
      metric: 'funnel5',
      enrichedMetrics: {
        name: 'InfiniGrow',
        value: 37,
        growth: 825,
      },
      crmMetrics: {
        name: 'Salesforce',
        value: 4,
      },
    }, {
      title: 'Top channels: Opps',
      kind: compareMetricsKinds.topChannel,
      enrichedMetrics: {
        name: 'InfiniGrow',
        channels: [
          'advertising_socialAds_linkedinAdvertising',
          'advertising_searchMarketing_SEM_googleAdwords',
          'events_offlineEvents_showcase',
        ],
      },
      crmMetrics: {
        name: 'Salesforce',
        channels: [
          'direct',
          'email',
          'advertising_searchMarketing_SEM_googleAdwords',
        ],
      },
    }, {
      title: '% Opps credited to Direct',
      metricType: metricTypes.conversionRate,
      kind: compareMetricsKinds.percentageOfMetric,
      enrichedMetrics: {
        name: 'InfiniGrow',
        value: 12,
        growth: -61,
      },
      crmMetrics: {
        name: 'Salesforce',
        value: 73,
      },
    }, {
      title: 'MQLs -> Opps CR',
      metricType: metricTypes.conversionRate,
      kind: compareMetricsKinds.conversionRate,
      enrichedMetrics: {
        name: 'InfiniGrow',
        value: 13,
      },
      crmMetrics: {
        name: 'Simple',
        value: 6,
      },
    },
  ];
}

export function getDisplaySignByMetricTypeOrDisplayType({ metricType }) {
  const displayType = userStore.getDisplayTypeByMetricType({ metricType });

  if (displayType === displayTypes.percentage) {
    return '%';
  }

  if (displayType === displayTypes.days) {
    return 'days';
  }

  if (displayType === displayTypes.months) {
    return 'months';
  }

  if (displayType === displayTypes.dollars) {
    return getCurrencySymbol();
  }

  return '';
}
