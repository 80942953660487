import React from 'react';
import { inject, observer } from 'mobx-react';

import Textfield from 'components/controls/Textfield';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { getMonthTitle, getQuarterTitle } from 'components/utils/goals';
import { compose } from 'components/utils/utils';

import objectivePopup from 'styles/welcome/objective-popup.css';

const objClasses = objectivePopup.locals;

const enhance = compose(
  inject(({ userStore }) => ({
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
  })),
  observer
);

const MilestoneBox = ({
  metricName,
  targetValue,
  timeframe,
  isQuarter,
  onChange,
  disabled,
  fiscalYearFirstMonth,
}) => (
  <div className={objClasses.month}>
    <div className={objClasses.monthText}>
      {
        isQuarter
          ? getQuarterTitle({ startDate: timeframe.startDate, fiscalYearFirstMonth })
          : getMonthTitle({ startDate: timeframe.startDate })
      }
    </div>
    <div className={objClasses.monthInputWrapper}>
      <Textfield
        value={metricName ? formatIndicatorDisplay(metricName, targetValue, false, false) : targetValue}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  </div>
);

export default enhance(MilestoneBox);
