export const maxRange = 10000;
export const BETWEEN = 'between';

export const getFilterIsBetweenView = (activeFilter) => {
    if (activeFilter) {
        return activeFilter.data.comparisonOperator === BETWEEN;
    }
    return false;
};

export const getFilterRange = (activeFilter) => {
    if (activeFilter && Array.isArray(activeFilter.data.manualValue)) {
        const [from, to] = activeFilter.data.manualValue;
        return { rangeFrom: from, rangeTo: to };
    }
    return { rangeFrom: 0, rangeTo: maxRange / 2 };
};

export const getFilterIsCustomDate = (activeFilter) => {
    if (activeFilter && activeFilter.data.timeFrame) {
        return true;
    }
    return false;
};