import React from 'react';
import { inject, observer } from 'mobx-react';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import global from 'global';
import history from 'history';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Popup from 'components/Popup';
import CreateNewBusinessUnitPopup from 'components/CreateNewBusinessUnitPopup';
import EditBusinessUnitNickname from 'components/EditBusinessUnitNickname';
import Notifications from 'components/pages/header/Notifications';
import Avatar from 'components/Avatar';
import Page from 'components/Page';
import HeaderMenuItem from 'components/header/HeaderMenuItem';
import DropMenuButton from 'components/header/DropMenuButton';
import FeatureFlags from 'components/common/FeatureFlags';
import Settings from 'components/pages/header/Settings';
import FiltersContainer from 'components/pages/users/Filters/FiltersContainer';
import ReportsHeaderMenu from 'components/pages/reports/ReportsHeaderMenu';
import CreateReportHeaderMenu from 'components/pages/reports/CreateReportHeaderMenu';
import WidgetBuilderHeaderMenu from 'components/pages/widgetBuilder/WidgetBuilderHeaderMenu';
import AskQuestion from 'components/pages/questions/AskQuestion';
import SettingsPopup from 'components/pages/analyze/SettingsPopup';
import Tooltip from 'components/controls/Tooltip';

import { getNumberOfDaysBetweenDates } from 'components/utils/date';
import { getMemberFullName } from 'components/utils/teamMembers';
import { compose } from 'components/utils/utils';
import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { subMenuTabsNamesWithBetaTag } from 'components/enums';

import dashboardStore from 'stores/dashboardStore';
import filterStore from 'stores/filterStore';
import forecastStore from 'stores/forecastStore';
import planStore from 'stores/plan/planStore';
import reportsStore from 'stores/reports/reportsStore';
import analysisStore from 'stores/analysisStore';
import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';

import style from 'styles/header.css';
import insightsStyle from 'styles/insights/insights.css';

import { tabsToFeatureFlags } from '../enums';

const DATE_FORMAT = 'DD MMM YYYY, h:mm a';

const enhance = compose(
  inject(({
    userStore: {
      userMonthPlan,
      integrationsLastUpdateTime,
      userBusinessUnitsWithIds,
      userRegionsNicknames,
      getRegionNickname,
    },
    reportsStore: {
      isHavingReportWithWidgets,
    },
    questionsStore: {
      aiAnswersDataResults,
      setAIAnswersDataResults,
    },
  }) => ({
    userMonthPlan,
    integrationsLastUpdateTime,
    isHavingReportWithWidgets,
    userBusinessUnitsWithIds,
    userRegionsNicknames,
    getRegionNickname,
    aiAnswersDataResults,
    setAIAnswersDataResults,
  })),
  observer
);

export class Header extends Component {
  style = style;

  styles = [insightsStyle];

  static defaultProps = {
    user: true,
    teamMembers: [],
    userAccount: {},
    notifications: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      dropmenuVisible: false,
      dropmenuVisibleBig: false,
      regionsVisible: false,
      suggestionsVisible: false,
      createNewVisible: false,
      regionsVisibleBig: false,
      lastUpdateDateVisible: false,
      settingVisible: false,
      width: 0,
    };

    window.addEventListener('resize', this.updateWidth);
  }

  componentDidMount() {
    this.updateWidth();
  }

  get isShowRegionTitle() {
    const { userRegionsNicknames } = this.props;
    return !isEmpty(userRegionsNicknames) && userRegionsNicknames.length > 1;
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  readNotifications() {
    const profile = servicesStore.authService.getProfileSync();
    const notifications = this.props.notifications.slice();
    notifications
      .filter((notification) => notification.UID === profile.user_id)
      .map((notification) => {
        notification.isRead = true;
        return notification;
      });
    this.props.updateUserMonthPlan({ notifications }, this.state.region);
  }

  openSidebar = () => {
    global.dispatchEvent('sidebar:open');
  };

  // sets always 'true' - toggle prevents from user to select indicator
  toggleSuggestion = () => {
    this.setState({
      suggestionsVisible: true,
    });
  };

  toggleChange = (object) => {
    this.setState((prevState) => ({
      [object]: !prevState[object],
    }));
  };

  isHighlighted(menuPath) {
    const menuSplit = menuPath.split('/');
    const pathSplit = this.props.path.split('/');
    return menuSplit.every((item, index) => item === pathSplit[index]);
  }

  isShowAttributionData() {
    const { userAccount: { startTime } } = this.props;
    const daysToAttributionData = 4 - getNumberOfDaysBetweenDates(new Date(), new Date(startTime));
    return daysToAttributionData <= 0;
  }

  getLastUpdateTime = (object) => get(object, 'lastUpdateTime');

  getArrayObjectsLastUpdateTime = (arr) => arr.map((platform) => this.getLastUpdateTime(platform))
    .filter((date) => date)
    .map((date) => new Date(date));

  getEarliestDate = (dates) => new Date(Math.min(...dates));

  get lastAdsIntegrationDate() {
    const {
      salesforceapi, linkedinadsapi, facebookadsapi, twitteradsapi, adwordsapi, bingadsapi, quoraadsapi, pardotapi,
    } = this.props;
    const campaignsDates = this.getArrayObjectsLastUpdateTime([linkedinadsapi, facebookadsapi, twitteradsapi, adwordsapi, bingadsapi, quoraadsapi, pardotapi]);
    const salesforceCampaignLastUpdateTime = get(salesforceapi, 'campaignsLastUpdateTime');
    if (salesforceCampaignLastUpdateTime) {
      campaignsDates.push(new Date(salesforceCampaignLastUpdateTime));
    }
    return campaignsDates.length ? this.getEarliestDate(campaignsDates) : null;
  }

  get lastCRMIntegrationDate() {
    const {
      integrationsLastUpdateTime,
    } = this.props;
    const allCrmLastUpdateTimes = Object.values(integrationsLastUpdateTime.crm);
    if (allCrmLastUpdateTimes.length === 0) {
      return null;
    }
    let earliestDate = moment(allCrmLastUpdateTimes[0]);
    for (const lastUpdateTime of allCrmLastUpdateTimes) {
      const crmLastUpdateTime = moment(lastUpdateTime);
      if (crmLastUpdateTime < earliestDate) {
        earliestDate = crmLastUpdateTime;
      }
    }

    return earliestDate;
  }

  get tabMenu() {
    const tabs = this.props.tabs.map(({ name, path }) => (
      <FeatureFlags key={`FeatureFlags-${name}-${path}`} flag={this.props.flags[tabsToFeatureFlags[path]] ?? true}>
        <Link
          to={path}
          activeClassName={this.classes.headTabSelected}
          className={this.classes.headTab}
          key={`${name}-${path}`}
        >
          {name}
          {subMenuTabsNamesWithBetaTag.includes(name) ? <div className={this.classes.betaTag}>Beta</div> : null}
        </Link>
      </FeatureFlags>
    ));
    return tabs;
  }

  menuBig = (profile, regions, user) => {
    const hasUser = this.props.user;
    const { isSecondaryRegion, flags } = this.props;
    const userNotifications = this.props.notifications.filter((notification) => notification.UID === profile.user_id);
    const isUnreadNotifications = userNotifications.some((notification) => notification.isRead === false);
    const showAttributionData = this.isShowAttributionData();
    const crmDate = this.lastCRMIntegrationDate;
    const adsIntegrationsDate = this.lastAdsIntegrationDate;
    const isOnboarding = user?.isShowGettingStartedPage;

    return (
      <div className={this.classes.menuBig} data-settings-open={this.props.isSettingsOpen}>
        <div className={this.classes.logoWrapper} onClick={() => { history.push('/dashboard/navigate'); }}>
          <div className={this.classes.logo} />
        </div>
        <div className={this.classes.menu}>
          {isOnboarding ? (
            <FeatureFlags flag={flags.onboardingGettingStartedPage}>
              <HeaderMenuItem
                icon="sidebar:getting-started-icon"
                text="Getting Started"
                link="/getting-started"
                isHighlighted={this.isHighlighted('/getting-started')}
                pagePermission="analyze"
                width={this.state.width}
              />
            </FeatureFlags>
          ) : null}
          <HeaderMenuItem
            icon="sidebar:dashboard"
            link="/dashboard/navigate"
            text="Dashboard"
            isHighlighted={this.isHighlighted('/dashboard')}
            pagePermission="dashboard"
            width={this.state.width}
            isHidden={isSecondaryRegion}
          />
          <HeaderMenuItem
            icon="sidebar:analyze"
            text="Analyze"
            link={showAttributionData ? '/analyze/overview' : '/no-analyze-data'}
            isHighlighted={this.isHighlighted('/analyze')}
            pagePermission="analyze"
            width={this.state.width}
          />
          <FeatureFlags flag={flags.headerPlanTab}>
            <HeaderMenuItem
              icon="sidebar:plan"
              text="Plan"
              link="/plan/annual"
              isHighlighted={this.isHighlighted('/plan')}
              pagePermission="plan"
              width={this.state.width}
            />
          </FeatureFlags>
          <FeatureFlags flag={flags.headerReportsTab}>
            <HeaderMenuItem
              icon="sidebar:reports"
              text="Reports"
              link={this.props.isHavingReportWithWidgets() ? '/reports' : '/reports/new'}
              isHighlighted={this.isHighlighted('/reports') || this.isHighlighted('/reports/new')}
              pagePermission="analyze"
              width={this.state.width}
            />
          </FeatureFlags>
          <FeatureFlags flag={flags.headerInsightsTab}>
            <HeaderMenuItem
              icon="sidebar:insights-header"
              text="Insights"
              link="/insights/overview"
              isHighlighted={this.isHighlighted('/insights')}
              pagePermission="analyze"
              width={this.state.width}
            />
          </FeatureFlags>
        </div>

        <Tooltip
          id="AI-Answers"
          tip="Ask any business question in natural language, and InfiniGrow will build a widget that gives you the answer"
        >
          <Link to={flags.aiAnswersDemoOnly ? '/answers' : '/questions'} className={this.classes.askAQuestion}>
            <div className={this.classes.askAQuestionIcon} />
            AI Answers
          </Link>
        </Tooltip>
        <div
          className={this.classes.updateTime}
          data-selected={this.state.lastUpdateDateVisible ? true : null}
          role="button"
          onMouseOver={() => this.toggleChange('lastUpdateDateVisible')}
          onFocus={() => this.toggleChange('lastUpdateDateVisible')}
          onMouseOut={() => {
            this.setState({
              lastUpdateDateVisible: false,
            });
          }}
          onBlur={() => {
            this.setState({
              lastUpdateDateVisible: false,
            });
          }}
        >
          <div className={this.classes.headerIcon} style={{ width: '26px' }} data-icon="header:time-left">
            <Popup
              className={this.classes.tooltipData}
              hidden={!this.state.lastUpdateDateVisible}
              ref="datePopup"
            >
              <div className={this.classes.tooltipBox}>
                <div className={this.classes.tooltipDataHead}>
                  Last data update times
                </div>
                {crmDate ? (
                  <div style={{ display: 'flex' }}>
                    <div className={this.classes.tooltipHeaders}>
                      CRM -&nbsp;
                    </div>
                    <div>{moment(crmDate).format(DATE_FORMAT)}</div>
                  </div>
                ) : null}
                {adsIntegrationsDate ? (
                  <div style={{ display: 'flex' }}>
                    <div className={this.classes.tooltipHeaders}>
                      Ad platforms -&nbsp;
                    </div>
                    <div>{moment(adsIntegrationsDate).format(DATE_FORMAT)}</div>
                  </div>
                ) : null}
                <div className={this.classes.tooltipDataFooter}>
                  *Local time
                </div>
              </div>
            </Popup>
          </div>

        </div>

        <div className={this.classes.itemsBox}>
          {/* Hidden Email, so we'll have the user id in customer labs */}
          <div className={this.classes.hiddenEmail}>
            {user && user.email}
          </div>
          <FeatureFlags flag={flags.notificationsGeneralHeader}>
            {hasUser && (
              <DropMenuButton
                dataSelected={this.state.notificationsVisible ? true : null}
                onClick={() => {
                  this.setState((prevState) => ({
                    notificationsVisible: !prevState.notificationsVisible,
                  }));
                  setTimeout(this.readNotifications.bind(this), 20000);
                }}
              >
                <Popup
                  className={this.classes.dropmenuPopup}
                  style={{ padding: '0' }}
                  hidden={!this.state.notificationsVisible}
                  onClose={() => this.setState({ notificationsVisible: false })}
                >
                  <Notifications {...this.props} userNotifications={userNotifications} />
                </Popup>
                <div
                  className={this.classes.headerIcon}
                  data-active={isUnreadNotifications ? true : null}
                  data-icon="header:notification"
                />
                <div className={this.classes.unRead} hidden={!isUnreadNotifications} />
              </DropMenuButton>
            )}
          </FeatureFlags>
          <DropMenuButton
            dataSelected={this.state.settingVisible ? true : null}
            onClick={() => this.toggleChange('settingVisible')}
          >
            <div className={this.classes.headerIcon} data-icon="header:settings">
              <Popup
                className={this.classes.dropmenuPopup}
                hidden={!this.state.settingVisible}
                ref="settingPopup"
                onClose={() => this.setState({ settingVisible: false })}
              >
                <Settings {...this.props} />
              </Popup>
            </div>
          </DropMenuButton>
          {hasUser && (
            <DropMenuButton
              dataSelected={this.state.regionsVisibleBig ? true : null}
              onClick={() => this.toggleChange('regionsVisibleBig')}
            >
              <div className={this.classes.headerIcon} data-icon="header:location" />
              {this.isShowRegionTitle && (
                <div className={this.classes.userRegion} style={{ paddingLeft: 10 }}>
                  {this.props.getRegionNickname({ region: this.props.region })}
                </div>
              )}
              <Popup
                className={this.classes.dropmenuPopup}
                hidden={!this.state.regionsVisibleBig}
                ref="regionsPopup"
                onClose={() => this.setState({ regionsVisibleBig: false })}
              >
                {regions}
              </Popup>
            </DropMenuButton>
          )}
          <DropMenuButton
            dataSelected={this.state.dropmenuVisibleBig ? true : null}
            onClick={() => this.toggleChange('dropmenuVisibleBig')}
          >
            <div className={this.classes.dropmenu}>
              <Avatar member={user} className={this.classes.userLogo} withShadow />
              <div className={this.classes.userDetails}>
                <div className={this.classes.user}>
                  {user ? getMemberFullName(user) : ''}
                </div>
                <div className={this.classes.userCompany}>
                  {this.props.userCompany}
                </div>
              </div>
              <div className={this.classes.triangle} />
              <Popup
                className={this.classes.dropmenuPopup}
                hidden={!this.state.dropmenuVisibleBig}
                onClose={() => this.setState({ dropmenuVisibleBig: false })}
              >
                <FeatureFlags flag={flags.aboutHeaderAccountMenu}>
                  <a className={this.classes.linkText} href="http://infinigrow.com/company/" target="_blank" rel="noreferrer">About</a>
                </FeatureFlags>
                <FeatureFlags flag={flags.contactHeaderAccountMenu}>
                  <a className={this.classes.linkText} href="http://infinigrow.com/contact/" target="_blank" rel="noreferrer">Contact</a>
                </FeatureFlags>
                <FeatureFlags flag={flags.supportHeaderAccountMenu}>
                  <a
                    className={this.classes.linkText}
                    href="mailto:support@infinigrow.com?&subject=Support Request"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Support
                  </a>
                </FeatureFlags>
                <div className={this.classes.linkText} onClick={() => this.logout()} style={{ color: '#2571AB' }}>
                  Logout
                  <div className={this.classes.logOutIcon} data-icon="header:log-out" />
                </div>
              </Popup>
            </div>
          </DropMenuButton>
        </div>
      </div>
    );
  };

  menuSmall = (profile, regions, user) => {
    const hasUser = this.props.user;
    const { flags } = this.props;

    return (
      <div className={this.classes.menuSmall}>
        <div className={this.classes.itemsBox}>
          {hasUser ? (
            <div className={this.classes.logoutItemOutside}>
              <Button
                type="secondaryWhite"
                onClick={() => this.logout()}
                style={{
                  width: '120px',
                }}
              >
                Log Out
                <div className={this.classes.logOutIcon} data-icon="header:log-out" />
              </Button>
            </div>
          ) : null}
          {hasUser && (
            <DropMenuButton
              dataSelected={this.state.regionsVisible ? true : null}
              onClick={() => this.toggleChange('regionsVisible')}
            >
              <div className={this.classes.headerIcon} data-icon="header:location" />
              {this.isShowRegionTitle && (
                <div className={this.classes.userRegion} style={{ paddingLeft: 10 }}>
                  {this.props.getRegionNickname({ region: this.props.region })}
                </div>
              )}
              <Popup
                className={this.classes.dropmenuPopup}
                hidden={!this.state.regionsVisible}
                ref="regionsPopup"
              >
                {regions}
              </Popup>
            </DropMenuButton>
          )}
          <DropMenuButton
            dataSelected={this.state.dropmenuVisible ? true : null}
            onClick={() => this.toggleChange('dropmenuVisible')}
          >
            <div className={this.classes.dropmenuIcon} />
            <Popup
              className={this.classes.dropmenuPopup}
              hidden={!this.state.dropmenuVisible}
              onClose={() => this.setState({ dropmenuVisible: false })}
            >
              {hasUser && (
                <div className={this.classes.userBoxInside}>
                  <div
                    className={this.classes.userLogo}
                    style={{ backgroundImage: this.props.logoURL ? `url(${this.props.logoURL})` : '' }}
                  />
                  <Avatar member={user} className={this.classes.userLogo} withShadow />
                  <div className={this.classes.logged}>
                    {this.props.userCompany}
                    <div className={this.classes.user}>
                      {user && user.name}
                    </div>
                  </div>
                </div>
              )}
              {hasUser && (
                <a className={this.classes.linkText} href="#welcome">
                  Settings
                </a>
              )}
              <FeatureFlags flag={flags.aboutHeaderAccountMenu}>
                <a className={this.classes.linkText} href="http://infinigrow.com/company/" target="_blank" rel="noreferrer">About</a>
              </FeatureFlags>
              <FeatureFlags flag={flags.contactHeaderAccountMenu}>
                <a className={this.classes.linkText} href="http://infinigrow.com/contact/" target="_blank" rel="noreferrer">Contact</a>
              </FeatureFlags>
              <FeatureFlags flag={flags.supportHeaderAccountMenu}>
                <a
                  className={this.classes.linkText}
                  href="mailto:support@infinigrow.com?&subject=Support Request"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </FeatureFlags>

              {hasUser && (
                <div className={this.classes.logoutItemInside}>
                  <Button
                    type="secondaryWhite"
                    onClick={() => this.logout()}
                    style={{
                      width: '120px',
                    }}
                  >
                    Log Out
                    <div className={this.classes.logOutIcon} data-icon="header:log-out" />
                  </Button>
                </div>
              )}
            </Popup>
          </DropMenuButton>
        </div>
        {hasUser && (
          <div className={this.classes.userBoxOutside}>
            <div
              className={this.classes.userLogo}
              style={{ backgroundImage: this.props.logoURL ? `url(${this.props.logoURL})` : '' }}
            />
            <Avatar member={user} className={this.classes.userLogo} withShadow />
            <div className={this.classes.logged}>
              {this.props.userCompany}
              <div className={this.classes.user}>
                {user ? getMemberFullName(user) : ''}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  logout() {
    attributionStore.resetToDefaultData();
    this.props.updateState({ unsaved: false }, () => {
      servicesStore.authService.logout();
    });
  }

  async changeRegion(regionNickname) {
    const regionValue = userStore.getRegionValue({ regionNickname });
    const { updateState } = this.props;
    localStorage.setItem('region', regionValue);
    analysisStore.restIsLoadedWidgetIndication();
    updateState({ regionLoad: true, unsaved: false });

    await Promise.all([this.props.getUserMonthPlan(regionValue), planStore.getCampaignsAndChannelsCostsBetweenDates({ region: regionValue })]);
    await Promise.all([userStore.getUserMetrics(), planStore.getAllCampaignTags(), dashboardStore.getDashboardData(), userStore.getCommonSegments()]);

    if (reportsStore.selectedReport.value) {
      reportsStore.getWidgetsDataForSelectedReport({ reportId: reportsStore.selectedReport.value });
    }

    attributionStore.setMetricOptions();
    filterStore.getUserFiltersData();
    updateState({ regionLoad: false, unsaved: false });

    await forecastStore.getExplainableForecastData();
  }

  render() {
    const profile = servicesStore.authService.getProfileSync();
    const hasUser = this.props.user;
    const path = this.props.path.split('/')[1];

    const isQuestionsResultsPage = this.props.path === '/questions/results';
    const isAIAnswersPage = this.props.path === '/answers';
    const isAnalyzePage = path === 'analyze';
    const isCreateNewReportPage = this.props.path.startsWith('/reports/new');
    const isReportsPage = !isCreateNewReportPage && (path === 'reports');
    const isDashboardPage = path === 'dashboard';
    const isWidgetBuilderPage = path === 'widget-builder';
    const hasAccountView = this.props.userMonthPlan?.accountViewRegion;

    let hasReportId = false;
    if (isReportsPage || isWidgetBuilderPage) {
      const reportId = getQueryParams({ queryParamKey: 'reportId' });
      if (reportId) {
        hasReportId = true;
      }
    }

    const regions = hasUser
      ? (
        <>
          <div className={classnames(this.classes.optionTagRow, this.classes.createNewRow)} onClick={() => this.setState({ createNewVisible: true })}>
            + Create new
          </div>
          {this.props.userRegionsNicknames.map((regionNickname) => (
            <div
              className={this.classes.optionTagRow}
              key={regionNickname}
            >
              <div className={this.classes.optionTagName} onClick={() => this.changeRegion(regionNickname)}>{regionNickname}</div>
              <div className={this.classes.editButton} onClick={() => this.setState({ editRegionNickname: regionNickname })} />
            </div>
          ))}
        </>
      )
      : null;
    const user = this.props.teamMembers.find((member) => member.userId === profile.user_id);

    let popup = null;
    if (this.state.suggestionPopup) {
      popup = (
        <Page
          popup
          width="825px"
          contentClassName={insightsStyle.locals.popupContent}
          innerClassName={insightsStyle.locals.popupInner}
        >
          <div style={{ position: 'relative' }}>
            <div
              style={{ left: 'calc(100% - 20px)', zIndex: '1', top: '5px' }}
              className={insightsStyle.locals.closePopup}
              onClick={() => {
                this.setState({ suggestionPopup: false });
              }}
            />
          </div>
        </Page>
      );
    }

    return (
      <div>
        <div className={this.props.tabs.length ? this.classes.box : this.classes.noSubBox}>
          <div className={this.classes.logoMenu} onClick={this.openSidebar} />
          {this.menuBig(profile, regions, user)}
          {this.menuSmall(profile, regions, user)}
          {popup}
          <CreateNewBusinessUnitPopup
            hidden={!this.state.createNewVisible}
            onClose={(region) => {
              this.setState({ createNewVisible: false }, () => {
                if (region) {
                  this.changeRegion(region);
                }
              });
            }}
            createUserMonthPlan={this.props.createUserMonthPlan}
            updateUserAccount={this.props.updateUserAccount}
          />
          {this.state.editRegionNickname ? (
            <EditBusinessUnitNickname
              regionNickname={this.state.editRegionNickname}
              onClose={() => this.setState({ editRegionNickname: null })}
            />
          ) : null}
          <div className={this.classes.headSecondMenu}>
            {this.props.tabs.length ? (
              <div className={this.classes.headTabs}>
                {this.tabMenu}
              </div>
            ) : null}

            {isReportsPage && <ReportsHeaderMenu hasReportId={hasReportId} />}
            {isCreateNewReportPage ? (
              <CreateReportHeaderMenu />
            ) : null}

            {isWidgetBuilderPage && hasReportId ? (
              <WidgetBuilderHeaderMenu />
            ) : null}

            {isAnalyzePage && this.props.userMonthPlan && (
            <div className={this.classes.headFilters}>
              <FiltersContainer
                updateUserMonthPlan={this.props.updateUserMonthPlan}
                path={this.props.path}
                region={this.state.region}
              />
            </div>
            )}

            {isDashboardPage && hasAccountView && (
              <div className={this.classes.headerDashboard}>
                <SettingsPopup
                  isShowAttributionModel={false}
                  isShowPredefinedFilters={false}
                  isShowHideOtherCampaigns={false}
                  isShowCalculateAdvancedMetrics={false}
                />
              </div>
            )}

            {isQuestionsResultsPage && (
            <div className={this.classes.headerQuestionsWrapper}>
              <AskQuestion
                shouldOpenResultsPopup={false}
                className={this.classes.headerQuestions}
                inputClassName={this.classes.headerQuestionsinput}
                displayShowMoreSuggestionsButton={false}
              />
            </div>
            )}

            {isAIAnswersPage && !isEmpty(this.props.aiAnswersDataResults) ? (
              <div className={this.classes.headerAIAnswersWrapper}>
                <Button
                  type="secondaryBlue"
                  onClick={() => { this.props.setAIAnswersDataResults({ data: {} }); }}
                >
                  + Start from scratch
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Header));
