import {
  action, decorate, observable, computed,
} from 'mobx';
import { persist } from 'mobx-persist';
import { isEmpty } from 'lodash';

import hydrate from 'stores/hydrate';
import userStore from 'stores/userStore';
import { defaultInsightTimeframe } from 'components/utils/timeframe';
import { getOptimizationMetricsOriginFunnel } from '../logic/insightsStore';

export class InsightsStore {
  constructor() {
    this.timeFrame = defaultInsightTimeframe;
    this.volumeMetric = {};
    this.optimizationMetricObject = {};
  }

  updateTimeFrame(newTimeFrame) {
    this.timeFrame = { ...newTimeFrame };
  }

  updateVolumeMetric = (newVolumeMetric) => {
    this.volumeMetric = { ...newVolumeMetric };
  };

  updateOptimizationMetric = (newOptimizationMetric) => {
    const optimizationMetric = { ...newOptimizationMetric };

    if (!isEmpty(newOptimizationMetric)) {
      const originFunnel = getOptimizationMetricsOriginFunnel({
        funnels: userStore.userFunnels,
        optimizationMetricValue: newOptimizationMetric.value,
      });
      optimizationMetric.originFunnel = originFunnel;
    }

    this.optimizationMetricObject = optimizationMetric;
  };

  get optimizationMetric() {
    return this.optimizationMetricObject;
  }
}

decorate(InsightsStore, {
  volumeMetric: observable.ref,
  timeFrame: observable.ref,
  optimizationMetricObject: observable.ref,
  optimizationMetric: computed,
  updateTimeFrame: action.bound,
  updateOptimizationMetric: action.bound,
  updateVolumeMetric: action.bound,
});

const schema = {
  topTimeFrame: {
    type: 'object',
  },
};

const store = persist(schema)(new InsightsStore());

hydrate('insightsStore', store);

export default store;
