import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

export default class CompanyLogo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }
  }

  static propTypes = {
    src: PropTypes.string,
    fallbackSrc: PropTypes.string,
    className: PropTypes.string
  }

  componentDidMount() {
    this.props.src && this.loadImage(this.props.src, this.props.fallbackSrc);
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.onload = () => {};
      this.image.onerror = () => {};
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.props.src && this.loadImage(this.props.src, this.props.fallbackSrc);
    }
  }

  loadImage = (src, fallbackSrc) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      this.setState({ loaded: true, imageUrl: src });
    };
    image.onerror = () => {
      const fallbackImage = new Image();
      fallbackImage.src = fallbackSrc;

      fallbackImage.onload = () => {
        this.setState({ loaded: true, imageUrl: fallbackSrc });
      };

      fallbackImage.onerror = () => {
        this.setState({ loaded: false });
      };
    };
    this.image = image;
  }

  render() {
    const { className } = this.props;
    const { imageUrl, loaded } = this.state;

    return (
      <Fragment>
      {
        loaded ?
        <div
          className={className}
          style={{
            backgroundImage: `url(${imageUrl})`
          }}
        />
        :
        <div
          className={className}
          style={{
            backgroundImage: 'url(/assets/organization.svg)',
            backgroundSize: '40% 70%'
          }}
        />
      }
      </Fragment>
    );
  }
}
