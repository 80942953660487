import React, { useState } from 'react';
import classnames from 'classnames';

import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';
import { getColor } from 'components/utils/colors';
import { getItemLabelWithNoValue } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';

import horizontalBarsTableStyle from 'styles/analyze/impactBySegment/horizontalBarsTable.css';

const styles = horizontalBarsTableStyle.locals;

export default function HorizontalBarsItem({
  averageWidth = 0,
  barWidth = 0,
  value,
  getNavigationMenuParams,
  firstSegment,
  currentSegmentLabel,
  colorIndexs,
  isEnableNavigationMenu,
  tagId,
}) {
  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);
  const itemLabel = getItemLabelWithNoValue({ value, segment: firstSegment, segmentLabel: currentSegmentLabel });

  return (
    <>
      <div id={tagId} className={classnames(styles.horizontalBarWrapper, (!isEnableNavigationMenu || !getNavigationMenuParams) && styles.disableNavigationMenu)} onClick={isEnableNavigationMenu && getNavigationMenuParams ? () => setShowNavigationMenuPopup(true) : null}>
        <EllipsisTooltip
          text={itemLabel}
          place="top"
          className={styles.barTitle}
        />
        <div className={styles.horizontalBar} style={{ backgroundColor: getColor(colorIndexs[value]), width: `${barWidth}%` }} />
        <div className={styles.horizontalBarOverlay} style={{ width: `${averageWidth}%` }} />
      </div>
      {isEnableNavigationMenu && getNavigationMenuParams && showNavigationMenuPopup && (
      <div className={styles.navigationMenu}>
        <NavigationMenuPopup
          navigationOptions={getNavigationMenuParams}
          onClosePopup={() => setShowNavigationMenuPopup(false)}
        />
      </div>
      )}
    </>
  );
}
