import userStore from 'stores/userStore';

import { questionTextType, suggestionsDropdownTypes } from 'components/pages/questions/enums';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';

export function splitSuggestionTextToObjects({ text = '', placeHoldersTypes = {}, placeHoldersValues = {} }) {
  const splitText = [];
  const wordsArray = text.split(' ');

  let updatedTextValue = '';
  for (const word of wordsArray) {
    if (word.includes('placeHolder')) {
      splitText.push({ text: updatedTextValue, type: questionTextType });
      const placeholderName = word.endsWith('}') ? word.slice(1, -1) : word.slice(1, -2);
      const placeholderType = placeHoldersTypes[placeholderName] || questionTextType;
      const placeholderValue = placeHoldersValues[placeholderName] || placeholderName;
      splitText.push({ text: placeholderValue, type: placeholderType, placeholderName });
      updatedTextValue = word.endsWith('}') ? '' : `${word.slice(-1)} `;
      continue;
    }
    updatedTextValue = updatedTextValue.concat(`${word} `);
  }

  splitText.push({ text: updatedTextValue.trim(), type: questionTextType });
  return splitText;
}

export function updateTextAccordingPlaceHolderType({ text, placeHolderType, placeHolderValue }) {
  const typesThatRequireTextChange = [suggestionsDropdownTypes.kpiFocus];
  if (!typesThatRequireTextChange.includes(placeHolderType)) {
    return text;
  }

  const metricType = userStore.getMetricType({ metric: placeHolderValue });
  if (metricType === 'funnelStage') {
    return 'How many';
  }

  if (metricType === 'revenue') {
    return 'How much';
  }

  if (metricType === 'velocity' || metricType === 'conversionRate') {
    return 'What was the';
  }

  return text;
}

export function getPlaceHolderLabel({ placeHolderType, placeHolderValue }) {
  const metricsTypes = [suggestionsDropdownTypes.funnels, suggestionsDropdownTypes.kpiFocus, suggestionsDropdownTypes.conversionRateOrVelocity];
  if (metricsTypes.includes(placeHolderType)) {
    return userStore.getMetricNickname({ metric: placeHolderValue });
  }

  if (placeHolderType === suggestionsDropdownTypes.timeframe) {
    return getParseDateRangeLabel({ timeFrame: placeHolderValue }).toLowerCase();
  }

  if (placeHolderType === suggestionsDropdownTypes.customFields) {
    const customFieldLabel = userStore.userAccount.customFieldsIdToLabelMap[placeHolderValue.id];
    return `${customFieldLabel} ${placeHolderValue.value}`;
  }

  if (placeHolderType === suggestionsDropdownTypes.leadSources) {
    return userStore.userAccount.leadSourcesIdToLabelMap[placeHolderValue];
  }

  if (placeHolderType === suggestionsDropdownTypes.channels) {
    return getChannelNickname(placeHolderValue);
  }

  if (placeHolderType === suggestionsDropdownTypes.segment) {
    return getSegmentLabel({ segment: placeHolderValue, customFieldsIdToLabelMap: userStore.userAccount.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount.customUtmsWhitelist });
  }

  return placeHolderValue;
}

export function getWidgetTitle({ questionTextArray }) {
  const titleArray = [];

  for (const text of questionTextArray) {
    if (text.type === questionTextType) {
      titleArray.push(text.text);
      continue;
    }
    titleArray.push(getPlaceHolderLabel({ placeHolderType: text.type, placeHolderValue: text.text }));
  }

  return titleArray.join(' ');
}
