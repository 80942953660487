import React from 'react';
import Component from 'components/Component';
import AttributionSetup from 'components/pages/AttributionSetup';

// eslint-disable-next-line react/prefer-stateless-function
export default class Setup extends Component {
  render() {
    const { UID } = this.props.params;

    return <AttributionSetup UID={UID} isPopup={false} isStaticPage />;
  }
}
