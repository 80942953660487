import { orderBy } from 'lodash';
import {
  contentTypeSegmentValue, contentTitleSegmentValue, contentPathSegmentValue, contentURLSegmentValue,
} from 'components/pages/analyze/SegmentsTab/logic/enums';
import { getMappingOptions } from 'components/utils/logic/utils';
import { getUtmsOptionsForMapping } from 'components/utils/mappingRules';

export function getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist = [] }) {
  const customFieldsOptions = getMappingOptions(customFieldsIdToLabelMap);
  const orderedCustomFieldsOptions = orderBy(customFieldsOptions, ['label'], ['asc']);
  const utmsOptions = getUtmsOptionsForMapping({ customUtmsWhitelist, withUtmSuffix: true });

  return [
    { label: 'Channel', value: 'channel' },
    { label: 'Campaign', value: 'campaign' },
    { label: 'Department', value: 'department' },
    { label: 'Category', value: 'category' },
    { label: 'Content Type', value: contentTypeSegmentValue },
    { label: 'Content Title', value: contentTitleSegmentValue },
    { label: 'Content Path', value: contentPathSegmentValue },
    { label: 'Content URL', value: contentURLSegmentValue },
    ...orderedCustomFieldsOptions,
    ...utmsOptions,
  ];
}

export function getSegmentsContentOptionsFields() {
  return [
    { label: 'Content Type', value: contentTypeSegmentValue },
    { label: 'Content Title', value: contentTitleSegmentValue },
    { label: 'Content Path', value: contentPathSegmentValue },
    { label: 'Content', value: contentURLSegmentValue },
  ];
}

export function isContentSegment({ segment }) {
  return [contentTypeSegmentValue, contentTitleSegmentValue, contentPathSegmentValue, contentURLSegmentValue].includes(segment);
}

export const parseSegmentsForRequest = (segments) => {
  const segmentsForRequest = {};
  for (const [key, value] of Object.entries(segments)) {
    segmentsForRequest[key] = value.value;
  }

  return segmentsForRequest;
};

export const getSavedSelectedSegments = (segmentsOptions, segmentsAnalysisParams) => {
  if (segmentsAnalysisParams) {
    const firstSegment = segmentsOptions.find((option) => option.value === segmentsAnalysisParams.firstSegment);
    const secondSegment = segmentsOptions.find((option) => option.value === segmentsAnalysisParams.secondSegment);
    return {
      firstSegment: firstSegment || { label: '', value: segmentsAnalysisParams.firstSegment },
      secondSegment: secondSegment || { label: 'None', value: segmentsAnalysisParams.secondSegment },
    };
  }

  return { firstSegment: segmentsOptions[0], secondSegment: { label: 'None', value: null } };
};
