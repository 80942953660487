import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';

import { getOptionValueIndexFromOptions } from 'components/controls/logic/rangeWithSteps';

import style from 'styles/controls/range-with-steps.css';

const styles = style.locals || {};

function RangeWithSteps({
  onChange,
  options,
  value,
  rangeWidth = '120px',
  disabledMaxValue,
  id,
  tooltip,
}) {
  useStyles([style]);

  const [rangeValueIndex, setRangeValueIndex] = useState(getOptionValueIndexFromOptions({ value, options }));

  useEffect(() => {
    const valueIndex = getOptionValueIndexFromOptions({ value, options });
    setRangeValueIndex(valueIndex);
  }, [value]);

  const rangeMaximum = options.length - 1;
  const rangeMaxValue = options[rangeMaximum].value;

  function handleInputChange(event) {
    const target = event.target;
    let rangeNewValue = Number(target.value);
    if (!isNil(disabledMaxValue)) {
      const disabledMaxValueIndex = getOptionValueIndexFromOptions({ value: disabledMaxValue, options });
      if (rangeNewValue >= disabledMaxValueIndex) {
        rangeNewValue = disabledMaxValueIndex;
      }
    }
    setRangeValueIndex(rangeNewValue);
    onChange(options[rangeNewValue].value);
  }

  const shouldShowTooltip = disabledMaxValue === value && value !== rangeMaxValue;

  return (
    <div className={styles.rangeWrapper} style={{ width: rangeWidth }} key={`range-${id}`} data-testid="range-with-steps">
      <Tooltip
        hidden={!shouldShowTooltip || !tooltip}
        id={`range-with-steps-${id}-tooltip`}
        tip={tooltip}
        place="top"
        dataTestId="range-with-steps-tooltip"
      >
        <div
          className={styles.range}
          style={{ '--max': rangeMaximum, '--valueIndex': rangeValueIndex }}
        >
          <input
            type="range"
            step="1"
            min={0}
            max={rangeMaximum}
            value={Number(rangeValueIndex)}
            onChange={(event) => handleInputChange(event)}
          />
        </div>
      </Tooltip>
      <div className={classnames(styles.stepsLabel, Number(rangeValueIndex) === 0 && styles.minimumStepLabel)}>
        {options[rangeValueIndex].label}
      </div>
    </div>
  );
}

export default RangeWithSteps;
