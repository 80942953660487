import React from 'react';
import history from 'history';

import Component from 'components/Component';
import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';

import { checkIfPopup } from 'modules/popup-mode';

import onboardingStyle from 'styles/onboarding/onboarding.css';
import tagsStyle from 'styles/tags.css';
import style from 'styles/signin/signin.css';

/**
 * Determines the appropriate redirect URI based on the current window's query parameters.
 *
 * @param {string} fallbackUri - The URI to redirect to if no relevant parameters are found.
 *
 * @returns {string} - The redirect URI. This could be:
 *   1. The provided `fallbackUri` if there are no query parameters.
 *   2. The `fallbackUri` appended with the current query parameters if 'redirect_to' param is not present.
 *   3. The value of the 'redirect_to' parameter with other query parameters preserved, excluding 'redirect_to'.
 *   4. Just the value of 'redirect_to' if there are no other query parameters to consider.
 */
function getRedirectUri({ fallbackUri }) {
  const { location } = window;
  const params = new URLSearchParams(location.search);

  if (!params.size) {
    return fallbackUri;
  }

  if (!params.has('redirect_to')) {
    return `${fallbackUri}${location.search}`;
  }

  const redirectUri = params.get('redirect_to');

  // preserve all existing query params except for 'redirect_to'
  const nextQueryParams = [];
  for (const param of params.entries()) {
    const [name, value] = param;
    if (name !== 'redirect_to') {
      nextQueryParams.push(encodeURI(`${name}=${value}`));
    }
  }

  if (!nextQueryParams.length) {
    return redirectUri;
  }

  return `${redirectUri}?${nextQueryParams.join('&')}`;
}

export default class SignIn extends Component {
  style = style;

  styles = [onboardingStyle, tagsStyle];

  // passLength = 8
  pattern = '(?=.*[1-9])(?=.*[a-z])(?=.*[A-Z]).{8,}';

  /*
   state = {
   login: true
   } */
  constructor(props) {
    super(props);
    this.state = { login: true };
    this.handleChange = this.handleChange.bind(this);
    this.checkUserAuthorization = this.checkUserAuthorization.bind(this);
  }

  async componentDidMount() {
    if (!servicesStore.authService.isAuthenticated()) {
      await servicesStore.authService.handleAuthentication();

      if (!servicesStore.authService.isAuthenticated()) {
        history.push(`/login${window.location.search}`);
        return;
      } else {
        attributionStore.resetToDefaultData();
      }
    }

    try {
      const profile = await servicesStore.authService.getProfile();
      if (!profile || !profile.app_metadata) {
        throw new Error('Profile data is missing');
      }

      const initialUserDataResponse = await serverCommunication.serverRequest('POST', 'homePage', JSON.stringify({
        email: profile.email,
      }), localStorage.getItem('region'));
      const initialUserData = await initialUserDataResponse.json();
      if (!initialUserData) {
        servicesStore.logger.error('SignIn.js - Error:', { error: 'Initial User Data is missing' });
      }

      const {
        region,
        isShowGettingStartedPage,
        preferences: {
          homePage: homePageId,
        } = {},
      } = initialUserData || {};

      if (homePageId && region && region !== 'undefined' && region !== 'null') {
        localStorage.setItem('region', region);
      }

      const profileResult = await checkIfPopup(profile);
      const [profileData, popup] = profileResult;

      const noUserAccount = popup === null;
      if (noUserAccount) {
        history.push({
          pathname: '/settings/account',
          query: { new: true, freePlan: !!profileData.app_metadata.freePlan },
        });
        return;
      }

      await this.identifySignedUser({ UID: profileData?.app_metadata?.UID });

      if (profileData.app_metadata && !profileData.app_metadata.isAdmin) {
        history.push('/dashboard/navigate');
        return;
      }

      if (popup) {
        history.push('/settings/account');
        return;
      }

      let fallbackUri = '/dashboard/navigate';
      if (isShowGettingStartedPage) {
        fallbackUri = '/getting-started';
      } else if (homePageId) {
        fallbackUri = `/reports?reportId=${homePageId}`;
      }
      const redirectUri = getRedirectUri({ fallbackUri });
      history.push(redirectUri);
    } catch (error) {
      servicesStore.logger.error('SignIn.js - Error:', { error });
      servicesStore.authService.logout();
      history.push('/login');
    }
  }

  async identifySignedUser({ UID }) {
    try {
      const serverResponse = await serverCommunication.serverRequest('GET', 'useraccount');
      const userAccount = await serverResponse.json();
      if (userAccount) {
        userStore.setUserAccount(userAccount);
      }
    } catch (error) {
      servicesStore.logger.error('failed to get userAccount', {
        UID,
        error,
      });
    }
  }

  handleChange(parameter, event) {
    const update = {};
    update[parameter] = event.target.value;
    this.setState(update);
  }

  checkUserAuthorization(e) {
    e.preventDefault();
    const route = this.state.login ? 'login' : 'signup';
    const self = this;
    self.setState({ isSignupError: false, isPromotionError: false });
    serverCommunication.serverRequest('POST', route, JSON.stringify({
      email: self.state.email,
      password: self.state.password,
      promotionCode: self.state.promotionCode,
    }))
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((data) => {
              if (data) {
                checkIfPopup()
                  .then((popup) => {
                    if (route === 'login' && !popup) {
                      history.push('/plan/annual');
                    } else {
                      history.push('/settings');
                    }
                  });
              } else if (route === 'login') {
                self.setState({ isLoginError: !data });
                self.refs.loginEmailInput.focus();
              } else {
                self.setState({ isSignupError: !data });
                self.refs.signupEmailInput.focus();
              }
            })
            .catch((error) => {
              servicesStore.logger.error('sign in checkUserAuthorization - failed to parse response', { error });
            });
        } else if (response.status === 500) {
          self.setState({ isPromotionError: true });
          self.refs.signupPromotionInput.focus();
        } else if (response.status === 409) {
          self.setState({ isSignupError: true });
          self.refs.signupEmailInput.focus();
        }
      })
      .catch((error) => {
        servicesStore.logger.error('sign in checkUserAuthorization - failed authenticating user', { error });
      });
  }

  render() {
    return (
      <div>
        {/**   <Header user={ false } />
         <Page sidebar={ false } width="600px" centered>
         <Title title="InfiniGrow" />
         <div className={ this.classes.switchButtons }>
         <Button type={ this.state.login ? 'accent' : 'normal' } style={{
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
         width: '80px'
         }} onClick={() => {
         this.setState({
         login: true
         });
         }}>Login</Button>
         <Button type={ this.state.login ? 'normal' : 'accent' } style={{
         borderTopLeftRadius: 0,
         borderBottomLeftRadius: 0,
         width: '80px'
         }} onClick={() => {
         this.setState({
         login: false
         });
         }}>Sign Up</Button>
         </div>
         <div className={ this.classes.item } hidden={ !this.state.login }>
         <form onSubmit={ this.checkUserAuthorization } >
         <h2>Login</h2>

         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <Label className={ this.classes.textLabel }>Email</Label>
         <Textfield type="email" required ref="loginEmailInput" defaultValue="" className={ this.classes.rightCol } onChange={ this.handleChange.bind(this, 'email')}/>
         </div>
         </div>

         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <Label className={ this.classes.textLabel } question={['']} description={['Password must contain a minimum of 1 lower case letter, 1 upper case letter, 1 numeric character, and at least 8 characters.']}>Password</Label>
         <Textfield type="password" required pattern={ this.pattern } defaultValue="" className={ this.classes.rightCol } onChange={ this.handleChange.bind(this, 'password')} />
         </div>
         </div>
         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <div className={ this.classes.leftCol }></div>
         <div className={ this.classes.enterCol }>
         <button className={ this.classes.primary2 } type="submit" >Login</button>
         <label hidden={ !this.state.isLoginError} style={{ color: 'red' }}>Wrong email or password</label>
         </div>
         </div>
         </div>
         </form>
         </div>
         <div className={ this.classes.item } hidden={ this.state.login }>
         <form onSubmit={ this.checkUserAuthorization } >
         <h2>Sign up</h2>

         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <Label className={ this.classes.textLabel }>Email</Label>
         <Textfield type="email" required ref="signupEmailInput" defaultValue="" className={ this.classes.rightCol } onChange={ this.handleChange.bind(this, 'email')} />
         </div>
         </div>
         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <Label className={ this.classes.textLabel } question={['']} description={['Password must contain a minimum of 1 lower case letter, 1 upper case letter, 1 numeric character, and at least 8 characters.']}>Password</Label>
         <Textfield type="password" required defaultValue="" pattern={ this.pattern } className={ this.classes.rightCol } onChange={ this.handleChange.bind(this, 'password')} />
         </div>
         </div>
         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <Label className={ this.classes.textLabel }>Access Code</Label>
         <Textfield ref="signupPromotionInput" type="text" required defaultValue="" className={ this.classes.rightCol } onChange={ this.handleChange.bind(this, 'promotionCode')} />
         </div>
         </div>

         <div className={ onboardingStyle.locals.row }>
         <div className={ this.classes.colsCell }>
         <div className={ this.classes.leftCol }></div>
         <div className={ this.classes.enterCol }>
         <button className={ this.classes.primary2 } type="submit" >Sign up</button>
         <label hidden={ !this.state.isSignupError} style={{ color: 'red' }}>Email already exists</label>
         <label hidden={ !this.state.isPromotionError} style={{ color: 'red' }}>Promotion code doesn't exists</label>
         </div>
         </div>
         </div>
         </form>
         </div>
         </Page>* */}
      </div>
    );
  }
}
