exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".select__text-1NT{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:block}.select__icon-tRV{min-width:20px;min-height:20px;background-size:contain;background-position:50%;background-repeat:no-repeat;margin-right:5px}.select__isDisabled-2DC{opacity:.5}.select__exact-date-1TZ{position:absolute;top:45px;left:50%;transform:translateX(-50%);color:#707ea7;font-size:16px;font-weight:500}.select__no-exact-date-1cW{left:0}.select__option-36Z{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.select__category-icon-1TK{flex-grow:0;flex-shrink:0;width:32px;height:32px;margin:0 12px 0 0;border-radius:4px;background-color:#edf3fe;background-position:50%;background-repeat:no-repeat}.select__errorField-HPc{border:1px solid #e43a54;border-radius:6px}.select__selesctWrapper-1QD{display:flex;flex-direction:column;width:100%;position:relative}.select__selectWithChannel-jUn{display:flex;align-items:center}", ""]);

// exports
exports.locals = {
	"text": "select__text-1NT",
	"text": "select__text-1NT",
	"icon": "select__icon-tRV",
	"icon": "select__icon-tRV",
	"isDisabled": "select__isDisabled-2DC",
	"isDisabled": "select__isDisabled-2DC",
	"exact-date": "select__exact-date-1TZ",
	"exactDate": "select__exact-date-1TZ",
	"no-exact-date": "select__no-exact-date-1cW",
	"noExactDate": "select__no-exact-date-1cW",
	"option": "select__option-36Z",
	"option": "select__option-36Z",
	"category-icon": "select__category-icon-1TK",
	"categoryIcon": "select__category-icon-1TK",
	"errorField": "select__errorField-HPc",
	"errorField": "select__errorField-HPc",
	"selesctWrapper": "select__selesctWrapper-1QD",
	"selesctWrapper": "select__selesctWrapper-1QD",
	"selectWithChannel": "select__selectWithChannel-jUn",
	"selectWithChannel": "select__selectWithChannel-jUn"
};