import { filterKinds } from 'components/utils/filters/logic';

export const maxOptionsOfSuggestionsResults = 5;

export const questionTextType = 'text';

export const suggestionsDropdownTypes = {
  timeframe: 'timeframes',
  segment: 'segments',
  campaigns: 'campaigns',
  channels: 'channels',
  customFields: 'customFields',
  leadSources: 'leadSources',
  funnels: 'funnels',
  kpiFocus: 'kpiFocus',
  conversionRateOrVelocity: 'conversionRateOrVelocity',
};

export const suggestionsPlaceholderTextTypes = [
  'journeys',
];

export const suggestionsTypesToFilterKind = {
  [suggestionsDropdownTypes.campaigns]: filterKinds.CAMPAIGNS,
  [suggestionsDropdownTypes.channels]: filterKinds.CHANNELS,
  [suggestionsDropdownTypes.customFields]: filterKinds.CUSTOM_FIELDS,
  [suggestionsDropdownTypes.leadSources]: filterKinds.CRMSource,
  [suggestionsDropdownTypes.kpiFocus]: filterKinds.FUNNEL_STAGES,
  [suggestionsDropdownTypes.funnels]: filterKinds.FUNNEL_STAGES,
};

export const suggestionsDropdownStyle = {
  dropdownControlText: {
    color: '#222A41',
    fontWeight: 500,
  },
  dropdownIndicatorWrapper: {
    color: '#222A41',
    padding: '0',
    marginLeft: '4px',
  },
  dropdownIndicatorArrow: {
    borderTop: '5px solid #222A41',
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
  },
};

export const askQuestionInputPlaceholderText = 'Try searching “Linkedin”';
