import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

import WithPersonalSpinner from 'components/common/withPersonalSpinner';
import WidgetHeader from 'components/common/WidgetHeader';
import CheckboxListPopup from 'components/common/CheckboxListPopup';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';
import SpotlightCard from 'components/pages/insights/overviewTab/SpotlightCard';
import CarouselArrowNavigation from 'components/common/CarouselArrowNavigation';

import { getReorderedSpotlightData, getSpotlightTypeToTag } from 'components/pages/insights/logic/Spotlight';
import { onboardingTagsIds } from 'components/pages/analyze/enums';

import spotlightStyle from 'styles/insightsPage/spotlight.css';
import widgetHeaderStyle from 'styles/analyze/widgetHeader.css';

const styles = spotlightStyle.locals;

const Spotlight = ({
  spotlightData,
  customFieldsIdToLabelMap,
  selectedOptimizationMetric,
  innerPageNavigation,
  getSpotlightData,
  UID,
  loadingMoreKey,
  handleShowMoreClick,
  timeFrame,
  isOnboarding,
}) => {
  useEffect(() => {
    spotlightStyle.use();
    widgetHeaderStyle.use();
    injectStyle();

    getSpotlightSelectedSegments();

    return () => {
      spotlightStyle.unuse();
      widgetHeaderStyle.unuse();
    };
  }, []);

  useEffect(() => {
    setSelectedSpotlightIndex({});
    setShouldResetSpotlightIndex(true);
  }, [selectedOptimizationMetric, timeFrame]);

  const [spotlightOptions, setSpotlightOptions] = useState([]);
  const [selectedSpotlightOptions, setSelectedSpotlightOptions] = useState([]);
  const [selectedSpotlightIndex, setSelectedSpotlightIndex] = useState({});
  const [shouldResetSpotlightIndex, setShouldResetSpotlightIndex] = useState(false);

  function parseSpotlightOptions({ data }) {
    const options = [];
    const selectedOptions = [];
    const dataKeys = Object.keys(data);
    for (const item of dataKeys) {
      const itemLabel = customFieldsIdToLabelMap[item] || item;
      options.push({ value: item, label: itemLabel });
      if (data[item]) {
        selectedOptions.push(item);
      }
    }
    return { options, selectedOptions };
  }

  async function onSaveSelectedOptions({ selectedItems }) {
    try {
      await serverCommunication.serverRequest('PUT', 'spotlightSelectedSegments', JSON.stringify({ spotlightSelectedSegments: selectedItems }));
      getSpotlightData();
      setSelectedSpotlightOptions(selectedItems);
    } catch (error) {
      toast.error('Failed to save selection. Please refresh the page and try again.', {
        toastId: 'failed-setSpotlightSelectedSegments',
      });
      servicesStore.logger.error('failed to set spotlightSelectedSegments', {
        error,
        UID,
      });
    }
  }

  async function getSpotlightSelectedSegments() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'spotlightSelectedSegments');
      if (response.ok) {
        const responseData = await response.json();
        const { options, selectedOptions } = parseSpotlightOptions({ data: responseData });
        setSpotlightOptions(options);
        setSelectedSpotlightOptions(selectedOptions);
      }
    } catch (error) {
      servicesStore.logger.error('failed to get spotlightSelectedSegments', {
        error,
        UID,
      });
    }
  }

  function loadMore({ offset, loadMoreKey }) {
    setShouldResetSpotlightIndex(false);
    handleShowMoreClick({ offset, loadMoreKey });
  }

  function onSelectedCard({ cardIndex, groupIndex }) {
    setShouldResetSpotlightIndex(false);
    setSelectedSpotlightIndex({ groupIndex, cardIndex });
  }

  const reorderedSpotlightData = getReorderedSpotlightData({ data: spotlightData });
  const spotlightTypeToTag = isOnboarding ? getSpotlightTypeToTag({ data: spotlightData }) : null;

  return (
    <div className={styles.spotlightWrapper}>
      <WidgetHeader
        isHideAddToReport
        widgetHeaderConfig={{
          title: 'Spotlight',
          subTitle: 'Instantly spot key optimization opportunities across segments and direct your efforts toward maximum impact.',
          subTitleClassName: styles.widgetSubTitle,
        }}
        customCogwheelPopup={(
          <CheckboxListPopup
            onSave={(items) => onSaveSelectedOptions({ selectedItems: items })}
            options={spotlightOptions}
            selectedOptions={selectedSpotlightOptions}
            renderButton={<div className={widgetHeaderStyle.locals.widgetCogwheel} />}
            title="Customize segment types"
            className={styles.widgetCogwheelPopup}
            searchInputClass={styles.searchInput}
            withSearch
          />
        )}
        classNameWidgetTitle={styles.widgetTitle}
      />
      {isEmpty(reorderedSpotlightData)
        ? (
          <div className={`${styles.spotlightCard} ${styles.spotlightCardsEmptyValues}`}>No values</div>
        )
        : (
          <div className={styles.spotlightCardsWrapper}>
            {reorderedSpotlightData.map((data, index) => {
              const carouselArrowNavigationKey = `arrow-navigation-container-${index}`;
              const tagId = spotlightTypeToTag === data[0]?.type ? onboardingTagsIds.selectedSpotlight : null;
              return (
                <CarouselArrowNavigation
                  list={Object.values(data)}
                  maxItemsInLine={1}
                  loadMore={() => loadMore({ offset: data.length, loadMoreKey: data[0]?.type })}
                  hasMore={spotlightData.hasMore}
                  isLoadingMore={loadingMoreKey === data[0]?.type}
                  key={carouselArrowNavigationKey}
                  isResetListIndexOnLoad={shouldResetSpotlightIndex}
                  outerNavigationArrows={false}
                >
                  {({ availableList, startIndex: cardIndex, Navigation }) => {
                    const currentItem = availableList[0];
                    const SpotlightCardKey = `spotlight-${currentItem?.type}-${index}`;
                    return (
                      <SpotlightCard
                        key={SpotlightCardKey}
                        spotlightType={currentItem?.type}
                        spotlightData={currentItem}
                        customFieldsIdToLabelMap={customFieldsIdToLabelMap}
                        selectedOptimizationMetric={selectedOptimizationMetric}
                        innerPageNavigation={innerPageNavigation}
                        isSelected={selectedSpotlightIndex.cardIndex === cardIndex && selectedSpotlightIndex.groupIndex === index}
                        setSelectedCard={() => onSelectedCard({ cardIndex, groupIndex: index })}
                        tagId={tagId}
                      >
                        <Navigation
                          prevArrowTooltip="See the previous over performing segment"
                          nextArrowTooltip="See the next over performing segment"
                        />
                      </SpotlightCard>
                    );
                  }}
                </CarouselArrowNavigation>
              );
            })}
          </div>
        )}
      <ToastContainer
        limit={1}
        style={{ width: '500px', fontSize: '14px' }}
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />
    </div>
  );
};

export default WithPersonalSpinner()(inject(({
  userStore: {
    userAccount: {
      customFieldsIdToLabelMap,
    },
    userMonthPlan: {
      UID,
    },
  },
}) => ({
  customFieldsIdToLabelMap,
  UID,
}),
observer)(Spotlight));
