export function getFiscalYearForMonth(month, fiscalYearFirstMonth, year) {
  return month >= fiscalYearFirstMonth ? year : year - 1;
}

export function getFiscalQuarterForMonth({ month, fiscalYearFirstMonth }) {
  const monthNumber = Number(month);
  const diff = monthNumber - fiscalYearFirstMonth;
  const monthIndexWithOffset = diff < 1 ? diff + 12 : diff;

  return Math.ceil(monthIndexWithOffset / 3);
}
