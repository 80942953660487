exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".plan-from-excel__drop-zone-1jF{margin-top:10px;width:100%;height:200px;border-radius:10px;border:2px dashed #666;cursor:pointer}.plan-from-excel__drop-zone-active-3yD{border:solid #6c6;background-color:#eee}.plan-from-excel__drop-zone-reject-3on{border:solid #ee2032}.plan-from-excel__icon-wrap-1jN{display:flex;justify-content:center}.plan-from-excel__icon-RzN{background:url(/assets/upload.svg) 50% no-repeat;height:80px;width:90px;background-size:contain}.plan-from-excel__inner-2h_{margin-top:40px}.plan-from-excel__inner-text-3dC{display:flex;justify-content:center;font-weight:700;text-align:center}.plan-from-excel__title-1Cs{font-size:20px;font-weight:700}.plan-from-excel__message-35e{margin-top:10px}.plan-from-excel__upload-actions-38l{display:flex;flex-direction:column;align-items:center;height:70px;padding-top:5px}.plan-from-excel__row-1wD{display:flex;flex-direction:row;align-items:center;justify-content:center;height:35px}", ""]);

// exports
exports.locals = {
	"drop-zone": "plan-from-excel__drop-zone-1jF",
	"dropZone": "plan-from-excel__drop-zone-1jF",
	"drop-zone-active": "plan-from-excel__drop-zone-active-3yD",
	"dropZoneActive": "plan-from-excel__drop-zone-active-3yD",
	"drop-zone-reject": "plan-from-excel__drop-zone-reject-3on",
	"dropZoneReject": "plan-from-excel__drop-zone-reject-3on",
	"icon-wrap": "plan-from-excel__icon-wrap-1jN",
	"iconWrap": "plan-from-excel__icon-wrap-1jN",
	"icon": "plan-from-excel__icon-RzN",
	"icon": "plan-from-excel__icon-RzN",
	"inner": "plan-from-excel__inner-2h_",
	"inner": "plan-from-excel__inner-2h_",
	"inner-text": "plan-from-excel__inner-text-3dC",
	"innerText": "plan-from-excel__inner-text-3dC",
	"title": "plan-from-excel__title-1Cs",
	"title": "plan-from-excel__title-1Cs",
	"message": "plan-from-excel__message-35e",
	"message": "plan-from-excel__message-35e",
	"upload-actions": "plan-from-excel__upload-actions-38l",
	"uploadActions": "plan-from-excel__upload-actions-38l",
	"row": "plan-from-excel__row-1wD",
	"row": "plan-from-excel__row-1wD"
};