import React from 'react';

import useStyles from 'hooks/useStyles';

import CompareMetricsBox from 'components/common/CompareMetricsBox';

import { TIMEFRAME_LABELS } from 'components/utils/timeframe';
import { getCompareEnrichedMetricsMockData, getDisplaySignByMetricTypeOrDisplayType } from 'components/pages/settings/workflows/logic/compareEnrichedMetrics';
import { compareMetricsTooltips } from 'components/pages/settings/workflows/logic/enums';

import style from 'styles/settings/compare-enriched-metrics/compare-enriched-metrics.css';

const styles = style.locals || {};

function CompareEnrichedMetrics({
  timeFrameLabel = TIMEFRAME_LABELS.PREV_QUARTER,
}) {
  useStyles([style]);

  const compareMetrics = getCompareEnrichedMetricsMockData();

  return (
    <div className={styles.wrapper}>
      <div className={styles.compareMetricsBoxes}>
        {compareMetrics.map((compareValues, index) => {
          const compareKey = `compareMetric-${index}-${compareValues.title}`;
          const tooltip = compareMetricsTooltips[compareValues.kind];
          const displayType = getDisplaySignByMetricTypeOrDisplayType({ metricType: compareValues.metricType });

          const compareProps = {
            title: compareValues.title,
            enrichedMetrics: compareValues.enrichedMetrics,
            crmMetrics: compareValues.crmMetrics,
            metric: compareValues.metric,
            tooltip,
            formatValue: (value) => `${value}${displayType}`,
          };

          return (
            <CompareMetricsBox
              key={compareKey}
              {...compareProps}
            />
          );
        })}
      </div>
      <div className={styles.sampleDate}>
        {'Sample date range: '}
        {timeFrameLabel}
      </div>
    </div>
  );
}

export default CompareEnrichedMetrics;
