import React, { useEffect } from 'react';

import Dropdown from 'components/controls/Dropdown';

import style from 'styles/settings/workflows/configPopup.css';

export default function OppEnrichment({ configuration, isViewOnly }) {
  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  function getAmountOptions() {
    return [{ value: 'all', label: 'any' }];
  }

  return (
    <div className={style.locals.row}>
      Associate
      <div className={style.locals.tag}>
        {configuration.baseObject}
      </div>
      to
      <div className={style.locals.tag}>
        {configuration.targetObject}
      </div>
      if the contact had an interaction
      <Dropdown
        disabled={isViewOnly}
        selectedKey={configuration.amount}
        options={[...getAmountOptions(), { label: configuration.amount, value: configuration.amount }]}
      />
      <Dropdown
        disabled={isViewOnly}
        selectedKey={configuration.amountType}
        options={[{ label: configuration.amountType, value: configuration.amountType }]}
      />
      before deal creation.
    </div>
  );
}
