import React, { useEffect } from 'react';

import loadingStyle from 'styles/common/loading-3-dots.css';

const style = loadingStyle.locals || {};

export default function Loading3Dots() {
  useEffect(() => {
    loadingStyle.use();

    return () => {
      if (!loadingStyle.unuse) {
        return;
      }
      loadingStyle.unuse();
    };
  }, []);

  return (
    <div className={style.spinner}>
      <div className={style.bounce1} />
      <div className={style.bounce2} />
      <div className={style.bounce3} />
    </div>
  );
}
