import React from 'react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Spinner from 'components/pages/journeys/Spinner';

import nextButtonIcon from 'assets/next.svg';
import style from 'styles/profile/profile.css';

export default class NextButton extends Component {
  style = style;

  render() {
    return (
      <Button
        type="primaryBlue"
        style={{
          width: '120px',
          letterSpacing: 0.075,
        }}
        onClick={() => this.props.onClick()}
        icon={!this.props.loading && nextButtonIcon}
        iconPosition="right"
        disabled={this.props.loading}
      >
        {this.props.loading ? <Spinner white small /> : 'NEXT'}
      </Button>
    );
  }
}
