import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';
import ArrowWithLoadMoreFunctionality from 'components/common/ArrowWithLoadMoreFunctionality';

import style from 'styles/common/carouselArrowNavigation.css';

const styles = style.locals;

export default function CarouselArrowNavigation({
  list = [],
  children,
  maxItemsInLine,
  showArrowsOnHover = false,
  loadMore,
  hasMore = false,
  isLoadingMore,
  isResetListIndexOnLoad = true,
  outerNavigationArrows = true,
}) {
  useStyles([style]);

  const tooltipId = useMemo(() => uniqueId('carouselArrowNavigation-tooltip'), []);

  const [navigationControl, setNavigationControl] = useState({
    startIndex: 0,
    endIndex: maxItemsInLine,
    availableList: list.slice(0, maxItemsInLine),
  });

  useEffect(() => {
    if (list.length > 0) {
      const shouldResetListIndex = navigationControl.startIndex > list.length - 1;
      if (isResetListIndexOnLoad || shouldResetListIndex) {
        resetListIndex();
      } else {
        setNavigationControl({
          ...navigationControl,
          availableList: list.slice(
            navigationControl.startIndex,
            navigationControl.endIndex
          ),
        });
      }
    }
  }, [list, isResetListIndexOnLoad]);

  function resetListIndex() {
    const startIndex = 0;
    const endIndex = maxItemsInLine;
    setNavigationControl({
      startIndex,
      endIndex,
      availableList: list.slice(startIndex, endIndex),
    });
  }

  function onClickLeft() {
    const newStartIndex = navigationControl.startIndex - 1;
    const newEndIndex = navigationControl.endIndex - 1;
    setNavigationControl({
      startIndex: newStartIndex,
      endIndex: newEndIndex,
      availableList: list.slice(newStartIndex, newEndIndex),
    });
  }

  function onClickRight() {
    const newStartIndex = navigationControl.startIndex + 1;
    const newEndIndex = navigationControl.endIndex + 1;
    setNavigationControl({
      startIndex: newStartIndex,
      endIndex: newEndIndex,
      availableList: list.slice(newStartIndex, newEndIndex),
    });
  }

  const shouldRenderLoadMoreArrow = navigationControl.endIndex === list.length && loadMore && hasMore;

  function PrevSlideButton({ tooltip }) {
    const disabled = navigationControl.startIndex === 0;

    if (outerNavigationArrows && disabled) {
      return null;
    }

    return (
      <button
        type="button"
        className={classnames(
          styles.arrow,
          outerNavigationArrows ? styles.arrowAbsolute : null,
          styles.arrowLeft
        )}
        onClick={onClickLeft}
        disabled={disabled}
        aria-label="go previous"
      >
        {tooltip && (
          <Tooltip
            tip={tooltip}
            id={`${tooltipId}-prev`}
            TooltipProps={{ className: styles.arrowTooltip }}
            withPortal
            hidden={disabled}
          >
            <div className={styles.tooltipPlaceholder} />
          </Tooltip>
        )}
      </button>
    );
  }

  function NextSlideButton({ tooltip }) {
    if (shouldRenderLoadMoreArrow) {
      return (
        <ArrowWithLoadMoreFunctionality onClick={loadMore} isLoadingMore={isLoadingMore} outerNavigationArrows={outerNavigationArrows} />
      );
    }

    const disabled = navigationControl.endIndex === list.length;

    if ((outerNavigationArrows && disabled) || list.length <= maxItemsInLine) {
      return null;
    }

    return (
      <button
        type="button"
        className={classnames(
          styles.arrow,
          outerNavigationArrows ? styles.arrowAbsolute : null,
          styles.arrowRight
        )}
        onClick={onClickRight}
        disabled={disabled}
        aria-label="go next"
      >
        {tooltip && (
          <Tooltip
            tip={tooltip}
            id={`${tooltipId}-next`}
            TooltipProps={{ className: styles.arrowTooltip }}
            withPortal
            hidden={disabled}
          >
            <div className={styles.tooltipPlaceholder} />
          </Tooltip>
        )}
      </button>
    );
  }

  function Navigation({ prevArrowTooltip, nextArrowTooltip }) {
    return (
      <div className={styles.arrowsBlock}>
        <PrevSlideButton tooltip={prevArrowTooltip} />
        <NextSlideButton tooltip={nextArrowTooltip} />
      </div>
    );
  }

  return (
    <div className={classnames(styles.wrapper, showArrowsOnHover ? styles.arrowsOnHover : null)}>
      {outerNavigationArrows && <PrevSlideButton />}
      {children({
        availableList: navigationControl.availableList,
        startIndex: navigationControl.startIndex,
        endIndex: navigationControl.endIndex,
        Navigation,
      })}
      {outerNavigationArrows && <NextSlideButton />}
    </div>
  );
}
