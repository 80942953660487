import history from 'history';
import { inject, observer } from 'mobx-react';

import attributionStore from 'stores/attributionStore';
import analysisStore from 'stores/analysisStore';
import filterStore from 'stores/filterStore';
import { makeFilters } from 'components/utils/filters';

export const navigateBetweenAnalyzeTabs = async ({
  filters,
  conversionIndicator,
  timeFrame,
  attributionModel,
  tabName,
  anchor,
  includeAttributionStoreFilters,
  segments,
  withAnimation,
  isNewFiltersUIOnly,
}) => {
  const historyPushObject = { pathname: `/analyze/${tabName}` };

  let didTimeFrameChanged = false;
  let didConversionIndicatorChanged = false;
  let didAttributionModelChanged = false;

  if (anchor) {
    historyPushObject.hash = `#${anchor}`;
    scrollIntoAnchorTag(anchor, withAnimation);
  }
  if (segments) {
    historyPushObject.state = { segments };
  }

  const filtersForNavigation = [];

  if (filters) {
    filtersForNavigation.push(...filters);
  }
  if (includeAttributionStoreFilters) {
    filtersForNavigation.push(...attributionStore.rawFilters);
  }

  let didFiltersChanged = false;
  if (!isNewFiltersUIOnly) {
    const convertedFilters = makeFilters(filtersForNavigation, filterStore.filtersData);
    didFiltersChanged = convertedFilters.some((filter) => !filter.isUiOnly);
  }

  if (timeFrame) {
    didTimeFrameChanged = timeFrame.value !== attributionStore.timeFrame?.value;
  }

  if (conversionIndicator) {
    didConversionIndicatorChanged = conversionIndicator !== attributionStore.conversionIndicator;
  }

  if (attributionModel) {
    didAttributionModelChanged = attributionModel !== attributionStore.attributionModel;
  }

  const didChangedGlobalConfiguration = didFiltersChanged || didTimeFrameChanged || didConversionIndicatorChanged || didAttributionModelChanged;
  if (didChangedGlobalConfiguration) {
    if (!historyPushObject.state) {
      historyPushObject.state = {};
    }

    historyPushObject.state.isRenderViaNavigation = true;
  }

  await history.push(historyPushObject);

  attributionStore.updateMultipleParameters({
    filters: makeFilters(filtersForNavigation, filterStore.filtersData),
    attributionModel,
    timeframeValue: timeFrame?.value,
    timeframeParams: timeFrame,
    conversionIndicator,
  });

  if (didChangedGlobalConfiguration) {
    analysisStore.restIsLoadedWidgetIndication();
  }
};

export default inject(({
  reportStore: {
    filtersData,
  },
}) => ({
  filtersData,
}),
observer)(navigateBetweenAnalyzeTabs);

export const scrollIntoAnchorTag = (anchor, withAnimation = true) => {
  setTimeout(() => {
    const anchorTag = document.getElementById(anchor);
    if (anchorTag) {
      const headerOffset = 160;
      const elementPosition = anchorTag.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: withAnimation ? 'smooth' : 'instant',
      });
    }
  }, 10);
};

export const getCurrentHashAndScrollToAnchorTag = (hash) => {
  if (hash) {
    const anchorId = hash.replace('#', '');
    scrollIntoAnchorTag(anchorId);
  }
};
