exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".buttons-set__help-wrap-3sS{position:relative}.buttons-set__validation-error-2OC{background:url(/assets/attention.svg) 50% no-repeat;background-size:contain;width:20px;height:20px;align-self:center}.buttons-set__ig-grid-row-3gJ{display:flex;margin:8px 0;flex-wrap:wrap}", ""]);

// exports
exports.locals = {
	"help-wrap": "buttons-set__help-wrap-3sS",
	"helpWrap": "buttons-set__help-wrap-3sS",
	"validation-error": "buttons-set__validation-error-2OC",
	"validationError": "buttons-set__validation-error-2OC",
	"ig-grid-row": "buttons-set__ig-grid-row-3gJ",
	"igGridRow": "buttons-set__ig-grid-row-3gJ"
};